import gql from 'graphql-tag';

import { VideoFragment, ByteArkStreamVideoFragment } from '../common/fragment';

export const CreatedVideoFragment = gql`
  fragment CreatedVideoFragment on VDMCreatedVideo {
    videoKey
    videoUploadUrl
    title
    namespace
    sourceId
  }
`;

export const CreatedByteArkStreamVideoFragment = gql`
  fragment CreatedByteArkStreamVideoFragment on VDMByteArkStreamCreatedVideo {
    videoKey
    videoUploadUrl
    title
    namespace
    sourceId
  }
`;

export const createMultipleVideos = gql`
  mutation createMultipleVideos($videos: [VideoCreationInput!]!) {
    vdmCreateVideos(videos: $videos) {
      code
      success
      createdVideos {
        ...CreatedVideoFragment
      }
    }
  }
  ${CreatedVideoFragment}
`;

export const byteArkStreamCreateMultipleVideos = gql`
  mutation vdmByteArkCreateVideos($projectKey: ID!, $videos: [VDMByteArkStreamVideoInput!]!) {
    vdmByteArkCreateVideos(projectKey: $projectKey, videos: $videos) {
      code
      success
      createdVideos {
        ...CreatedByteArkStreamVideoFragment
      }
    }
  }
  ${CreatedByteArkStreamVideoFragment}
`;

export const updateMultipleVideos = gql`
  mutation updateMultipleVideos($videos: [VideoUpdateInput!]!) {
    vdmUpdateVideos(videos: $videos) {
      code
      success
      updatedVideos {
        ...VideoFragment
      }
    }
  }
  ${VideoFragment}
`;

export const byteArkStreamUpdateMultipleVideos = gql`
  mutation updateByteArkStreamMultipleVideos($videos: [VDMByteArkStreamVideoUpdateInput!]!) {
    vdmByteArkUpdateVideos(videos: $videos) {
      code
      success
      updatedVideos {
        ...ByteArkStreamVideoFragment
      }
    }
  }
  ${ByteArkStreamVideoFragment}
`;

export const deleteMultipleVideos = gql`
  mutation deleteMultipleVideos($videoKeys: [String!]!) {
    vdmDeleteVideos(videoKeys: $videoKeys) {
      code
      success
      deletedVideos
    }
  }
`;

export const byteArkStreamDeleteMultipleVideos = gql`
  mutation deleteMultipleVideos($videoKeys: [String!]!) {
    vdmByteArkDeleteVideos(videoKeys: $videoKeys) {
      code
      success
      deletedVideos
    }
  }
`;

export const generateVideoAccessToken = gql`
  mutation generateVideoAccessToken {
    vdmGenerateAccessToken {
      accessToken
      code
      success
    }
  }
`;

export const byteArkStreamGenerateVideoAccessToken = gql`
  mutation generateVideoAccessToken {
    vdmByteArkGenerateAccessToken {
      accessToken
      code
      success
    }
  }
`;

export const generateVideoSignedUrl = gql`
  mutation generateVideoSignedUrl($videoSigningOptions: VideoSigningUrlInput!) {
    vdmGenerateVideoSignedUrl(videoSigningOptions: $videoSigningOptions) {
      signedUrl
      code
      success
    }
  }
`;

export const byteArkStreamGenerateVideoSignedUrl = gql`
  mutation generateVideoSignedUrl($videoSigningOptions: VDMByteArkStreamVideoSigningUrlInput!) {
    vdmByteArkGenerateOnlineVideoSignedUrl(videoSigningOptions: $videoSigningOptions) {
      signedUrl
      code
      success
    }
  }
`;
