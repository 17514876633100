import gql from 'graphql-tag';

import { singleExam, singleQuestion } from '../common/fragment';

export const EXAM_VERSION = {
  SKOOLDIO_V1: 'SKOOLDIO_V1',
  SKOOLDIO_V2: 'SKOOLDIO_V2',
};

export const EXAM_TYPE = {
  PRETEST: 'PRETEST',
  POSTTEST: 'POSTTEST',
  EXAM: 'EXAM',
};

export const QUESTION_TYPE = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  MULTIPLE_SELECTION: 'MULTIPLE_SELECTION',
  FILL_IN_THE_BLANK: 'FILL_IN_THE_BLANK',
  FILL_IN_THE_BLANK_SKILLSCORE: 'FILL_IN_THE_BLANK_SKILLSCORE',
};

export const exam = gql`
  query exam($courseId: ID!, $examId: ID!, $version: EEV1_EXAM_COURSE_VERSION) {
    exam: eeV1Exam(courseId: $courseId, examId: $examId, version: $version) {
      ...singleExam
    }
  }
  ${singleExam}
`;

export const exams = gql`
  query exams($courseId: ID!) {
    exams: eeV1Exams(courseId: $courseId) {
      ...singleExam
    }
  }
  ${singleExam}
`;

export const question = gql`
  query question($questionId: ID!, $version: EEV1_EXAM_COURSE_VERSION) {
    question: eeV1ExamQuestion(questionId: $questionId, version: $version) {
      ...singleQuestion
    }
  }
  ${singleQuestion}
`;

export const examTags = gql`
  query examTags($examId: ID!, $version: EEV1_EXAM_COURSE_VERSION) {
    examTags: eeV1ExamTags(examId: $examId) {
      id
      name
    }
  }
`;
