import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Icon, Container } from 'semantic-ui-react';
import urljoin from 'url-join';

import QueryTable from 'GraphQL/util/QueryTable';
import { getUserAssignmentSubsectionReports } from 'GraphQL/query/Courses.query';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { SemanticButton as Button } from 'Components/Base/Button';
import Filter from 'Util/Filter';

import AssignmentBreadcrumb from '../AssignmentBreadcrumb';

import { ASSIGNMENT_STATUS_SEARCH_OPTIONS, GRADING_TYPE } from './constant';
import AssignmentTitle from './AssignmentTitle';

const getFullName = (firstname, lastname) => [firstname, lastname].filter(Boolean).join(' ');

const getColumns = (urlPath, handleReview) => [
  {
    Header: 'Users',
    accessor: 'firstname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original: userAssignmentReport }) => (
      <Link to={{ pathname: urljoin(urlPath, userAssignmentReport.id) }}>
        {getFullName(userAssignmentReport.firstname, userAssignmentReport.lastname)}
      </Link>
    ),
  },
  {
    Header: 'First Name',
    accessor: 'firstname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    isHideColumn: true,
  },
  {
    Header: 'Last Name',
    accessor: 'lastname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    isHideColumn: true,
  },
  {
    Header: 'Result',
    accessor: 'assignmentStatus',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: ASSIGNMENT_STATUS_SEARCH_OPTIONS,
    Cell: ({ original: userAssignmentReport }) =>
      userAssignmentReport.assignmentStatus === 'GRADED'
        ? userAssignmentReport.assignmentDetail.gradingType === GRADING_TYPE.SCORE
          ? ` ${userAssignmentReport.assignmentScore} / ${userAssignmentReport.assignmentDetail.fullScore}`
          : `${userAssignmentReport.assignmentResultStatus}`
        : `${userAssignmentReport.assignmentStatus}`,
  },
  {
    Header: 'Last Submitted Date',
    accessor: 'submittedAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Last Reviewed Date',
    accessor: 'reviewedAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Review',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    type: COLUMN_TYPE.STRING,
    Cell: ({ value }) => (
      <Button basic size="mini" icon compact onClick={() => handleReview(value)}>
        <Icon name="edit" />
      </Button>
    ),
  },
];

function Assignments({ match }) {
  const { courseId, subsectionId } = match?.params ?? {};
  const history = useHistory();

  const [search, setSearch] = useState({});
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [order, setOrder] = useState({ field: 'createdAt', type: 'DESC' });

  const handlePageChange = (page) => {
    Filter.handlePageChange(page, paging.pageSize, setPaging);
  };
  const handlePageSizeChange = (pageSize) => {
    Filter.handlePageSizeChange(pageSize, setPaging);
  };
  const handleSearchChange = (search) => {
    Filter.handleSearchChange(search, paging, setSearch, setPaging);
  };
  const handleSortedChange = (sorted) => {
    Filter.handleSortedChange(sorted, paging.pageSize, setOrder, setPaging);
  };

  const handleReview = (assignmentId) => {
    history.push(urljoin(match.url, assignmentId));
  };

  const getSearchVariables = () => ({
    sourceCourseId: courseId,
    sourceCourseItemId: subsectionId,
    order,
    paging,
    search,
  });

  return (
    <Container>
      <AssignmentBreadcrumb />
      <AssignmentTitle />
      <QueryTable
        type="userAssignmentSubsectionReports"
        columns={getColumns(match.url, handleReview)}
        query={getUserAssignmentSubsectionReports}
        search={search}
        onSearchChange={handleSearchChange}
        paging={paging}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        order={order}
        onSortedChange={handleSortedChange}
        getSearchVariables={getSearchVariables}
      />
    </Container>
  );
}

export default Assignments;
