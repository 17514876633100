import React, { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const initCheckState = (value) => {
  if (value && value !== '') {
    return value;
  }
  return [];
};

const ProductTypeCheckbox = (props) => {
  const { value, onChange, productTypes = [] } = props;
  const [checkState, setCheckState] = useState(initCheckState(value));

  const isCheck = (productType) => {
    return checkState.includes(productType);
  };

  const handleChange = (productType) => {
    setCheckState((prev) => {
      const isChecked = isCheck(productType);
      if (isChecked) {
        return prev.filter((item) => item !== productType);
      } else {
        return [...prev, productType];
      }
    });
  };

  useEffect(() => {
    onChange({}, { value: checkState });
  }, [checkState]);

  return (
    <Flex flexWrap="wrap" mt={2}>
      {productTypes.map((item) => (
        <Box mr={3} key={item.productType}>
          <Checkbox
            label={item.label}
            checked={isCheck(item.productType)}
            onChange={() => handleChange(item.productType)}
          />
        </Box>
      ))}
    </Flex>
  );
};

export default withFormsy(ProductTypeCheckbox);
