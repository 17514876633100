import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { addMultipleMaterials } from 'GraphQL/mutation/Material.mutation';
import {
  updateMultipleVideos,
  byteArkStreamUpdateMultipleVideos,
} from 'GraphQL/mutation/Video.mutation';
import { addSection, addSubsection } from 'GraphQL/mutation/Courses.mutation';
import VideoManagementView from 'Components/AssetComponents/VideoManagement';
import getConfig from 'Util/Config';

import VideoBrowseContainer from './VideoBrowseContainer.gql';
import VideoEditorContainer from './VideoEditorContainer.gql';
import VideoBackbone, { transformVideo } from './VideoBackbone';

const VideoManagementContainer = (props) => {
  const { uploadVideoCompletedWaitTime, enabledByteArkStream } = getConfig();
  const {
    onCreateMaterial,
    isAllowMultiple,
    metadata: { courseCode, courseId, productVersion, sections, sectionId },
    level,
    materialDownloadable,
    defaultVideoType,
    onComplete,
    onCancel,
    newAsset,
    videoUploadMaxItems,
  } = props;

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [mutating, setMutating] = useState(false);
  const [menuItem, setMenuItem] = useState(undefined);
  const [videosData, setVideosData] = useState({ video: [], pages: [] });
  const [videoUpdatedData, setVideoUpdatedData] = useState({});

  const [createMaterials] = useMutation(addMultipleMaterials);
  const [createSection] = useMutation(addSection);
  const [createSubsection] = useMutation(addSubsection);
  const [updateVideos] = useMutation(
    enabledByteArkStream ? byteArkStreamUpdateMultipleVideos : updateMultipleVideos
  );

  const videoBackbone = new VideoBackbone({
    productVersion,
    courseId,
    sections,
    addSection: createSection,
    addSubsection: createSubsection,
    addMultipleMaterials: createMaterials,
    createMaterial: onCreateMaterial,
    updateVideos,
    materialDownloadable,
    level,
    setMutating,
  });

  const handleSelectBrowsedVideo = (browsedVideos, selectedVideoKeys, page) => {
    let videoData = videosData.video;
    if (!videosData.pages.includes(page)) {
      videoData = [...videosData.video, ...browsedVideos];
      setVideosData((prev) => {
        return { video: videoData, pages: [...prev.pages, page] };
      });
    }

    const selectedVideoLit = selectedVideoKeys
      .map((selectedKey) => {
        return transformVideo(
          videoData.find(({ videoKey }) => videoKey === selectedKey),
          defaultVideoType
        );
      })
      .filter((video) => video !== undefined);
    setSelectedVideos(selectedVideoLit);
  };

  const handleCreateMaterial = async () => {
    if (!newAsset) {
      const { videoKey } = videoUpdatedData;
      if (videoKey) {
        return videoBackbone.updateVideoMaterial(videoUpdatedData, defaultVideoType);
      } else {
        onCancel();
      }
    } else {
      if (isAllowMultiple) {
        try {
          setMutating(true);
          const result = await videoBackbone.createVideoMaterials(selectedVideos, sectionId);
          setMutating(false);
          onComplete(result.data);
        } catch (error) {
          console.error(error);
          setMutating(false);
        }
      } else {
        return videoBackbone.createVideoMaterial(selectedVideos[0]);
      }
    }
  };

  const handleCompleteUpload = () => {
    setTimeout(() => {
      setMenuItem('Browse');
    }, uploadVideoCompletedWaitTime);
  };

  const handleCustomTabChange = () => {
    setMenuItem(undefined);
  };

  const handleVideoDataChange = (data) => {
    setVideoUpdatedData(data);
  };

  const videoBrowseRenderer = () => (
    <VideoBrowseContainer
      isAllowMultiple={isAllowMultiple}
      onSelectVideo={handleSelectBrowsedVideo}
      courseCode={courseCode}
    />
  );

  const videoEditorRenderer = (props) => (
    <VideoEditorContainer onDataChange={handleVideoDataChange} {...props} />
  );

  return (
    <VideoManagementView
      {...props}
      loading={mutating}
      onSelectBrowsedVideo={handleSelectBrowsedVideo}
      onCreateMaterial={handleCreateMaterial}
      videoBrowseRenderer={videoBrowseRenderer}
      videoEditorRenderer={videoEditorRenderer}
      onCompleteUpload={handleCompleteUpload}
      menuItem={menuItem}
      onCustomTabChange={handleCustomTabChange}
      videoUploadMaxItems={videoUploadMaxItems}
    />
  );
};

export default VideoManagementContainer;
