import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

const HEX_COLOR_VALIDATION = /^#([a-f0-9]{3,4}|[a-f0-9]{4}(?:[a-f0-9]{2}){1,2})\b$/i;

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const fields = (id) => {
  return [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'SKU Code',
      inputName: 'SKUCode',
      inputProps: {
        required: true,
      },
      mode: MODE.CREATE,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Workshop Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
  ];
};

export default class WorkshopForm extends Component {
  static propTypes = {
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  validate = ({ backgroundColor }, isChanged) => {
    const error = {};
    if (backgroundColor && !HEX_COLOR_VALIDATION.test(backgroundColor) && isChanged) {
      error.backgroundColor = 'Background color must be in Hex-Color format';
    }
    return error;
  };

  getFormFields = () => {
    const { isEdit, id } = this.props;
    return fields(id).filter(
      (f) =>
        f.mode === MODE.BOTH ||
        (isEdit && f.mode === MODE.EDIT) ||
        (!isEdit && f.mode === MODE.CREATE)
    );
  };

  render() {
    const { isEdit, onSubmit } = this.props;
    return (
      <FormGenerator
        fields={this.getFormFields()}
        customValidation={this.validate}
        onSubmit={onSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
