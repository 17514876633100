import React from 'react';

import { Text, Flex } from 'Components/Base';

const DEFAULT_MIN_RANDOM_LENGTH = 4;
const DEFAULT_MAX_RANDOM_LENGTH = 10;
const RANDOM_STRING = 'AoR4Sp1aUaJ1r5ZH2XhapGaZRPjkDG';

const FONTSIZE = '1.2em';
const PREFIX_COLOR = '#ff9a00';
const RANDOM_STRING_COLOR = '#3333c2';

const generateRandomString = (
  randomLength = DEFAULT_MIN_RANDOM_LENGTH,
  min = DEFAULT_MIN_RANDOM_LENGTH,
  max = DEFAULT_MAX_RANDOM_LENGTH
) => {
  if (randomLength < min || randomLength > max) {
    return [null, 'INVALID RANDOM LENGTH'];
  }
  return [RANDOM_STRING.substring(0, randomLength), null];
};

const DiscountCodeSampler = ({ prefix, randomLength, minRandomLength, maxRandomLength }) => {
  const [randomString, error] = generateRandomString(
    randomLength,
    minRandomLength,
    maxRandomLength
  );

  return (
    <Flex>
      <Text fontSize={FONTSIZE}>ตัวอย่างโค้ด:</Text>
      {randomString && (
        <>
          <Text fontSize={FONTSIZE} ml={2} color={PREFIX_COLOR}>
            {prefix || ''}
          </Text>
          <Text fontSize={FONTSIZE} color={RANDOM_STRING_COLOR}>
            {randomString}
          </Text>
        </>
      )}
      {error && (
        <Text fontSize={FONTSIZE} ml={2} color={'red'}>
          {` ${error}`}
        </Text>
      )}
    </Flex>
  );
};

export default DiscountCodeSampler;
