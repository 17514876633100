import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { Search } from 'semantic-ui-react';
import _ from 'lodash';

import { allUsersQuery } from '../../GraphQL/query/Users.query';
import UserItem from '../../Components/UserItem';

const filterUser = (roles = []) => (user) => {
  if (!roles || roles.length === 0) return true;

  if (!user.roles || user.roles.length === 0) return false;

  return roles.some((role) => user.roles.indexOf(role) >= 0);
};

class UserSearch extends Component {
  static propTypes = {
    client: PropTypes.object,
    onSelected: PropTypes.func,
    limitRoles: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    onSelected: () => {},
    limitRoles: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      result: [],
      loading: false,
    };
    this.handleSearchChange = _.throttle(this.handleSearchChange, 1000, { trailing: true });
  }

  handleSearchChange = (event, data) => {
    const { client } = this.props;
    this.setState({ loading: true });
    client.query({ query: allUsersQuery, variables: { searchText: data.value } }).then((result) => {
      this.setState({
        result: result.data.users
          .filter(filterUser(this.props.limitRoles))
          .map((u) => ({ id: u.id, title: u.id, data: u })),
        loading: false,
      });
    });
  };

  handleResultSelect = (event, data) => {
    this.props.onSelected(data.result);
  };

  render() {
    const { result, loading } = this.state;
    return (
      <Search
        fluid
        onSearchChange={this.handleSearchChange}
        onResultSelect={this.handleResultSelect}
        results={result}
        minCharacters={3}
        loading={loading}
        resultRenderer={(item) => <UserItem key={item.id} {...item.data} />}
      />
    );
  }
}

export default withApollo(UserSearch);
