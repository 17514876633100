import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { Input } from 'formsy-semantic-ui-react';

import { Flex, Text, Box } from 'Components/Base';
import { COLOR } from 'Components/Theme';
import withFormsy from 'Components/Form/withFormsy';
import { FormField, RequiredLabel } from 'src/Components/Form/FormGenerator/FormGenerator';

const StyledCheckbox = withStyles({
  root: {
    width: 16,
    padding: 0,
    marginRight: 12,
    '&$checked': {
      color: COLOR.primary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const PrescreenFormURLTextInput = (props) => {
  const [_display, _setDisplay] = useState(!!props.value);

  const handleDisplay = (_type, value) => {
    if (!value) {
      updateValue(null);
    }

    _setDisplay(value);
  };

  const updateValue = (value) => {
    props.onChange({}, { value });
  };

  return (
    <>
      <Box mb={2}>
        <Flex alignItems="center">
          <StyledCheckbox checked={_display} onChange={handleDisplay} />
          <Text fontWeight="600">ต้องคัดเลือกผู้เรียนก่อน</Text>
        </Flex>
      </Box>
      <FormField hidden={!_display}>
        <RequiredLabel>
          <span>
            <Box alignItems={'center'} display="inline-flex">
              Form URL (e.g. HubSpot, Google Forms)
            </Box>
          </span>
        </RequiredLabel>
        <Input
          name={`prescreenFormURL`}
          defaultValue={props.value}
          onChange={(data) => updateValue(data.target.value)}
        />
      </FormField>
    </>
  );
};

export default withFormsy(PrescreenFormURLTextInput);
