import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import { Text, Flex } from 'Components/Base';
import { GET_USER_APP_ROLE_PERMISSIONS, singleUserQuery } from 'GraphQL/query/Users.query';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';

import { UNASSIGN_APP_ROLE } from '../../Graphql/mutation/Role.mutation';

import AssignedRoleList from './AssignedRoleList';
import AssignRoleModalButton from './AssignRoleModalButton';

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  PERMISSION_LIMITATION: {
    message: 'Unassign yourself or a role higher is prohibited',
    variant: VARIANTS.ERROR,
    show: true,
  },
  ROLE_NOT_FOUND: {
    message: `Role not found or has been unassigned already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Can't unassign role right now, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const RolesPermissionPanel = ({ userId }) => {
  const { data, loading, error } = useQuery(GET_USER_APP_ROLE_PERMISSIONS, {
    variables: { userId },
    skip: !userId,
  });
  const { data: { user } = {} } = useQuery(singleUserQuery, {
    variables: { userId },
    skip: !userId,
    fetchPolicy: 'cache-only',
  });

  const { AssignedHeader } = useMemo(
    () => ({
      AssignedHeader: <Text.Header>Assigned Roles</Text.Header>,
    }),
    []
  );

  const [feedback, setFeedback] = useState({});

  const onCompleted = useCallback(
    (response) => {
      const appRoleName = response?.unassignAppRoleFromUser?.name;
      const username = user?.username;
      setFeedback({
        message: `Role${appRoleName ? ` "${appRoleName}" ` : ' '}has been unassigned from user${
          username ? ` "${username}"` : ''
        }`,
        variant: VARIANTS.SUCCESS,
        show: true,
      });
    },
    [user]
  );

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('404')) {
      setFeedback(FEEDBACK.ROLE_NOT_FOUND);
      return;
    }

    if (message.includes('403')) {
      setFeedback(FEEDBACK.PERMISSION_LIMITATION);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const [unassignAppRole] = useMutation(UNASSIGN_APP_ROLE, {
    refetchQueries: [{ query: GET_USER_APP_ROLE_PERMISSIONS, variables: { userId } }],
    onCompleted,
    onError,
  });

  const handleUnassignRole = useCallback(
    (roleId) => unassignAppRole({ variables: { userId, roleId } }),
    [unassignAppRole, userId]
  );

  if (loading) return <div>loading</div>;
  if (error && !loading) return <div>{JSON.stringify(error)}</div>;
  return (
    <>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
      <Flex mb={4} alignItems={'center'}>
        {AssignedHeader} <AssignRoleModalButton userId={userId} />
      </Flex>
      <AssignedRoleList userId={userId} onUnassignRoleClick={handleUnassignRole} />
    </>
  );
};

RolesPermissionPanel.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default RolesPermissionPanel;
