import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Divider, Icon, Button as SButton } from 'semantic-ui-react';
import _ from 'lodash';

import { Box, Flex } from 'Components/Base';
import {
  SECTION_CONTENT_TYPE,
  MODE,
  MODE_STRING,
  SUBSECTION_CONTENT_TYPE_STRING,
} from 'Components/SubSection/util';
import { SemanticButton as Button } from 'Components/Base/Button';
import ConfirmationButton from 'Components/ConfirmationButton';
import Section from 'Components/Section';
import SectionContent from 'Components/SectionContent';
import Subsection from 'Containers/SubSection';

import AddSectionModal from '../../../Containers/AddSectionModal';
import ChangeSectionModal from '../../../Containers/ChangeSectionModal';
import { LEVEL } from '../utils';
import ReorderSubsectionModalButton from '../ReorderSubsectionModalButton/ReorderSubSectionModalButton';

const QuickActionBar = ({
  id,
  onDeleteClick,
  onReorderClick,
  onReorderCrossSectionClick,
  itemIndex,
  sectionId,
  sections,
  loading,
  mode,
  totalItems,
  name,
}) => {
  const isSectionLevel = [MODE.SECTION, MODE.SECTION_GROUP].includes(mode);
  return (
    //  Button.Group from semantic-ui-react can parse click event from Button -> Button.Group -> Accordion
    //  to prevent this, need to add onClick stopPropagation in Button.Group
    //  Development Bug ID: ANY-263
    <SButton.Group onClick={(e) => e.stopPropagation()}>
      {itemIndex !== 0 && (
        <Button
          icon
          compact
          basic
          onClick={(e) => onReorderClick(e, itemIndex - 1, itemIndex)}
          data-testid={`btn-move-${name}-up`}
        >
          <Icon name="angle up" />
        </Button>
      )}
      {!isSectionLevel && itemIndex === 0 && (
        <ChangeSectionModal
          id={id}
          isSection={false}
          onSubmit={onReorderCrossSectionClick}
          sections={sections}
          sectionId={sectionId}
          trigger={
            <Button icon compact basic data-testid={`btn-move-${name}-up`}>
              <Icon name="angle up" />
            </Button>
          }
        />
      )}
      {itemIndex !== totalItems - 1 && (
        <Button
          icon
          compact
          basic
          onClick={(e) => onReorderClick(e, itemIndex, itemIndex + 1)}
          data-testid={`btn-move-${name}-down`}
        >
          <Icon name="angle down" />
        </Button>
      )}
      {!isSectionLevel && itemIndex === totalItems - 1 && (
        <ChangeSectionModal
          id={id}
          isSection={false}
          onSubmit={onReorderCrossSectionClick}
          sections={sections}
          sectionId={sectionId}
          trigger={
            <Button icon compact basic data-testid={`btn-move-${name}-down`}>
              <Icon name="angle down" />
            </Button>
          }
        />
      )}
      <ConfirmationButton
        headerText={`Delete ${MODE_STRING[mode]}?`}
        contentText={'This action cannot be undone.'}
        confirmText={'Delete'}
        loading={loading}
        onConfirm={onDeleteClick}
        data-testid={`btn-delete-${name}`}
      >
        <Icon name="trash alternate outline" color="red" />
      </ConfirmationButton>
    </SButton.Group>
  );
};

const SectionComponent = ({
  id,
  courseId,
  type,
  title,
  contentType,
  description,
  children,
  product,
  version,
  lastSubsectionId,
  videoManagementButton,
  subSections,
  onReorderSubsections,
  ...restProps
}) => (
  <Box>
    <SectionContent
      id={id}
      type={type}
      title={title}
      description={description}
      product={product}
      version={version}
      {...restProps}
    />
    {!contentType && (
      <Fragment>
        <Flex>
          {videoManagementButton}
          {subSections && subSections.length > 1 && (
            <ReorderSubsectionModalButton
              sectionId={id}
              sectionTitle={title}
              subSections={subSections}
              onConfirm={onReorderSubsections}
            />
          )}
        </Flex>
        {children}
        <Divider hidden />
        <AddSectionModal
          isSection={false}
          courseId={courseId}
          afterItemId={lastSubsectionId}
          product={product}
          version={version}
          trigger={
            <Button primary circular basic fluid data-testid="btn-add-subsection">
              <Icon name="plus square" />
              Add Subsection
            </Button>
          }
        />
      </Fragment>
    )}
    <Divider hidden />
  </Box>
);

const SectionSubHeader = ({ children }) => (
  <Header.Subheader style={{ 'padding-left': '2rem' }}>{children}</Header.Subheader>
);

const SubsectionSubHeader = ({ children }) => (
  <Header.Subheader style={{ 'padding-left': '1.6rem' }}>{children}</Header.Subheader>
);

const checkSectionGroup = (contentType) => {
  return contentType === SECTION_CONTENT_TYPE.SECTION_GROUP;
};

const getMode = (isSection, contentType) => {
  return isSection
    ? checkSectionGroup(contentType)
      ? MODE.SECTION_GROUP
      : MODE.SECTION
    : MODE.SUBSECTION;
};

const renderSectionGroupSubHeader = (contentType) => {
  switch (contentType) {
    case SECTION_CONTENT_TYPE.SECTION_GROUP:
      return <SectionSubHeader>{MODE_STRING[contentType]}</SectionSubHeader>;
    default:
      return <SectionSubHeader>{`Section`}</SectionSubHeader>;
  }
};

const genTitlePrefix = (title, isSectionGroup, sectionGroupNo, sectionNo) => {
  const prefix = genNumberPrefix(isSectionGroup, sectionGroupNo, sectionNo);
  return isSectionGroup ? `บทที่ ${prefix} ${title}` : `${prefix} ${title}`;
};

const genNumberPrefix = (isSectionGroup, sectionGroupNo, sectionNo) => {
  return isSectionGroup ? sectionGroupNo : `${sectionGroupNo}.${sectionNo}`;
};

const convertContents = (
  courseId,
  contents = {},
  product,
  version,
  actions = {
    onUpdateSectionMeta: () => {},
    onDeleteSection: () => {},
    onDeleteSubSection: () => {},
    handleReorderSection: () => {},
    handleReorderSubsection: () => {},
    handleReorderMultipleSubsection: () => {},
  },
  videoManagementButton = () => {}
) => {
  const sections = contents.sections || [];

  let currentSectionGroupNo = 0;
  let currentSectionNo = 0;
  const isContainSectionGroup = sections.some(({ contentType }) => checkSectionGroup(contentType));

  const result = sections.map((section, index) => {
    const { id: sectionId, title: sectionTitle, subsections, contentType } = section;

    const isSectionGroup = checkSectionGroup(contentType);
    if (isSectionGroup) {
      currentSectionGroupNo++;
      currentSectionNo = 0;
    } else {
      currentSectionNo++;
    }

    const numberPrefix = genNumberPrefix(isSectionGroup, currentSectionGroupNo, currentSectionNo);
    const newSection = {
      id: sectionId,
      title: {
        left: (
          <Header data-testid={`section-header-${numberPrefix}`}>
            <Icon size="large" name="dropdown" />
            {isContainSectionGroup
              ? genTitlePrefix(
                  sectionTitle,
                  isSectionGroup,
                  currentSectionGroupNo,
                  currentSectionNo
                )
              : `${sectionTitle}`}
            {renderSectionGroupSubHeader(contentType)}
          </Header>
        ),
        right: (
          <QuickActionBar
            itemIndex={index}
            totalItems={sections.length}
            mode={getMode(true, contentType)}
            id={sectionId}
            onDeleteClick={(e) => {
              actions.onDeleteSection({ id: sectionId, title: sectionTitle }, subsections);
            }}
            onReorderClick={(e, sourceIndex, destinationIndex) => {
              actions.handleReorderSection(sourceIndex, destinationIndex);
            }}
            name={numberPrefix}
          />
        ),
      },
    };
    let lastSubsectionId = sectionId;
    const content = subsections.map((subsection, index) => {
      const newSubSection = {
        id: subsection.id,
        title: {
          left: (
            <Header size="small" data-testid={`subsection-header-${numberPrefix}-${index + 1}`}>
              <Icon name="dropdown" />
              {subsection.title}
              <SubsectionSubHeader>
                {SUBSECTION_CONTENT_TYPE_STRING[subsection.contentType]}
              </SubsectionSubHeader>
            </Header>
          ),
          right: (
            <QuickActionBar
              itemIndex={index}
              totalItems={subsections.length}
              mode={getMode(false, contentType)}
              id={subsection.id}
              sectionId={sectionId}
              sections={sections}
              onDeleteClick={(e) => {
                actions.onDeleteSubSection(subsection.id);
              }}
              onReorderClick={(e, sourceIndex, destinationIndex) => {
                actions.handleReorderSubsection(section.id, sourceIndex, destinationIndex);
              }}
              onReorderCrossSectionClick={(subSectionId, sourceSectionId, destinationSectionId) => {
                actions.handleReorderAcrossSection(
                  subSectionId,
                  sourceSectionId,
                  destinationSectionId
                );
              }}
            />
          ),
        },
        content: {
          content: (
            <Subsection
              id={subsection.id}
              courseId={courseId}
              product={product}
              version={version}
            />
          ),
        },
      };
      lastSubsectionId = subsection.id;
      return newSubSection;
    });
    newSection.content = {
      content: (
        <SectionComponent
          id={section.id}
          courseId={courseId}
          type={section.type}
          title={section.title}
          contentType={section.contentType}
          description={section.description}
          lastSubsectionId={lastSubsectionId}
          product={product}
          version={version}
          onUpdateMeta={(data) => {
            actions.onUpdateSectionMeta(section.id, data);
          }}
          videoManagementButton={videoManagementButton(LEVEL.SECTION, sectionId)}
          subSections={subsections}
          onReorderSubsections={(reorderedSubsection) => {
            actions.handleReorderMultipleSubsection(section.id, reorderedSubsection);
          }}
        >
          <Section sections={content} />
        </SectionComponent>
      ),
    };

    return newSection;
  });
  return result;
};

const reorder = (sourceArray, startIndex, endIndex) => {
  // do nothing when reorder out of range of array
  if (endIndex < 0 || endIndex >= sourceArray.length) return sourceArray;

  /* Example
   * const result = reorder([1,2,3,4,5], 1, 2);
   * Expected output: [1,3,2,4,5]
   */
  const result = Array.from(sourceArray);
  const [removed] = result.splice(startIndex, 1); // result should be [1,3,4,5]
  result.splice(endIndex, 0, removed); // result should be [1,3,2,4,5]
  return result;
};

class CourseSection extends Component {
  state = {
    sections: [],
  };
  handleReorderSection = (sourceIndex, destinationIndex) => {
    const sections = reorder(this.state.sections, sourceIndex, destinationIndex);
    if (sections) {
      const { onLoading, onReorderCourseItems } = this.props;
      onLoading(true);
      const result = [];
      sections.forEach((section) => {
        result.push({ id: section.id, title: section.title });
        section.subsections.forEach((subsection, subsectionIndex) => {
          result.push({ id: subsection.id, title: subsection.title });
        });
      });
      onReorderCourseItems(result);
    }
  };
  handleReorderSubsection = (sectionId, sourceIndex, destinationIndex) => {
    const { sections } = this.state;
    const sectionItems = sections.filter((section) => section.id === sectionId)[0];
    let subsections = reorder(sectionItems.subsections, sourceIndex, destinationIndex);
    if (sections && subsections) {
      const { onLoading, onReorderCourseItems } = this.props;
      onLoading(true);
      const result = [];
      sections.forEach((section) => {
        result.push({ id: section.id, title: section.title });
        if (section.id !== sectionId) {
          section.subsections.forEach((subsection) => {
            result.push({ id: subsection.id, title: subsection.title });
          });
        } else {
          subsections.forEach((subsection) => {
            result.push({ id: subsection.id, title: subsection.title });
          });
        }
      });
      onReorderCourseItems(result);
    }
  };
  handleReorderMultipleSubsection = (sectionId, reorderedSubsection) => {
    const { sections } = this.state;
    const { onLoading, onReorderCourseItems } = this.props;

    onLoading(true);
    const result = [];
    sections.forEach((section) => {
      result.push({ id: section.id, title: section.title });
      if (section.id !== sectionId) {
        section.subsections.forEach((subsection) => {
          result.push({ id: subsection.id, title: subsection.title });
        });
      } else {
        reorderedSubsection.forEach((subsection) => {
          result.push({ id: subsection.id, title: subsection.title });
        });
      }
    });

    onReorderCourseItems(result);
  };
  handleReorderAcrossSection = (subSectionId, sourceSectionId, destinationSectionId) => {
    const { sections } = this.state;
    const sourceSectionItems = sections.filter((section) => section.id === sourceSectionId)[0];
    const destinationSectionItems = sections.filter(
      (section) => section.id === destinationSectionId
    )[0];
    // get moved subSection
    const moveSubSection = sourceSectionItems.subsections.find(
      (subSection) => subSection.id === subSectionId
    );

    // remove from sourseSection
    _.remove(sourceSectionItems.subsections, moveSubSection);

    // add to destinationSection
    destinationSectionItems.subsections.push(moveSubSection);

    // reorder item
    if (sections) {
      const { onLoading, onReorderCourseItems } = this.props;
      onLoading(true);
      const result = [];
      sections.forEach((section) => {
        result.push({ id: section.id, title: section.title });
        section.subsections.forEach((subsection) => {
          result.push({ id: subsection.id, title: subsection.title });
        });
      });
      onReorderCourseItems(result);
    }
  };
  componentDidMount() {
    this.setState({ sections: this.props.contents.sections });
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { isLoading, contents } = props;
    if (!isLoading) this.setState({ sections: contents.sections });
  }
  render() {
    const {
      onUpdateSectionMeta,
      onDeleteSection,
      onDeleteSubSection,
      courseId,
      contents,
      product,
      version,
      videoManagementButton,
    } = this.props;
    return (
      <Section
        sections={convertContents(
          courseId,
          contents,
          product,
          version,
          {
            onUpdateSectionMeta,
            onDeleteSection,
            onDeleteSubSection,
            handleReorderSection: this.handleReorderSection,
            handleReorderSubsection: this.handleReorderSubsection,
            handleReorderAcrossSection: this.handleReorderAcrossSection,
            handleReorderMultipleSubsection: this.handleReorderMultipleSubsection,
          },
          videoManagementButton
        )}
      />
    );
  }
}

CourseSection.propTypes = {
  courseId: PropTypes.string.isRequired,
  onReorderCourseItems: PropTypes.func,
  onUpdateSectionMeta: PropTypes.func,
  onUpdateSubSectionMeta: PropTypes.func,
  onDeleteSection: PropTypes.func,
  onDeleteSubSection: PropTypes.func,
  onLoading: PropTypes.func,
  contents: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    ordering: PropTypes.number,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        subsections: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string,
            ordering: PropTypes.number,
          })
        ),
      })
    ),
  }),
  product: PropTypes.string,
  version: PropTypes.string,
  isLoading: PropTypes.bool,
  videoManagementButton: PropTypes.func,
};
CourseSection.defaultProps = {
  onReorderCourseItems: () => {},
  onUpdateSectionMeta: () => {},
  onUpdateSubSectionMeta: () => {},
  onDeleteSection: () => {},
  onDeleteSubSection: () => {},
  onLoading: () => {},
  videoManagementButton: () => {},
};

export default CourseSection;
