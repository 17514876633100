import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { GET_USER_APP_ROLE_PERMISSIONS } from 'GraphQL/query/Users.query';
import { Text, Flex } from 'Components/Base';

import UnAssignRoleButton from './UnAssignRoleButton';

const AssignedRoleList = ({ userId, onUnassignRoleClick }) => {
  const { data, loading, error } = useQuery(GET_USER_APP_ROLE_PERMISSIONS, {
    variables: { userId },
    skip: !userId,
  });

  const userAppRoles = data?.user?.appRoleData ?? [];
  if (Array.isArray(userAppRoles) && userAppRoles.length) {
    return (
      <>
        <Flex flexDirection="column" px={[2, 3, 4]}>
          {userAppRoles.map(({ name: role, id }, index) => (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              key={`${role}-${index}`}
              mb={2}
            >
              <Text flex="1">{role}</Text>
              <UnAssignRoleButton userId={userId} onClick={() => onUnassignRoleClick(id)} />
            </Flex>
          ))}
        </Flex>
      </>
    );
  }

  return <Text fontWeight={500}>{`This user isn't assigned any roles`}</Text>;
};

AssignedRoleList.propTypes = {
  userId: PropTypes.string.isRequired,
  onUnassignRoleClick: PropTypes.func,
};

AssignedRoleList.defaultProps = {
  onUnassignRoleClick: () => {},
};

export default AssignedRoleList;
