import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import FormGenerator from 'Components/Form/FormGenerator';
import getConfig from 'Util/Config';

import ColorSelectorInput from '../../../../Components/ColorSelectorInput';
import { SKU_STATUS_OPTIONS, SKU_STATUS } from '../../Domains/constants';
import { validateSKUCode, checkReadyToPublish, ERP_PRODUCT } from '../../Domains/ERPUtils';
import SKUCodeInput from '../SKUCodeInput';
import ERPWarningModal from '../ERPWarningModal';

const HEX_COLOR_VALIDATION = /^#([a-f0-9]{3,4}|[a-f0-9]{4}(?:[a-f0-9]{2}){1,2})\b$/i;

const { products, ERPSystem } = getConfig();

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, publishedAt, currentStatus) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Sub Title',
      inputName: 'subTitle',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputName: 'SKUCode',
      inputProps: {
        disabled: publishedAt,
      },
      customInput: (props) => (
        <SKUCodeInput publishedAt={publishedAt} status={currentStatus} {...props} />
      ),
      mode: MODE.BOTH,
    },

    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'SKUStatus',
      inputName: 'SKUStatus',
      options: products?.COLLECTION?.skuStatusOptions ?? SKU_STATUS_OPTIONS,
      inputProps: {
        required: true,
        search: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'BackgroundColor',
      inputName: 'backgroundColor',
      customInput: () => <ColorSelectorInput />,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Ordering',
      inputName: 'itemNo',
      inputProps: {
        required: products?.disabledOrdering ? false : true,
      },
      hidden: products?.disabledOrdering || false,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Duration',
      inputName: 'duration',
      mode: MODE.BOTH,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class BundleOverviewForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  state = {
    currentStatus: this.props.initialData?.SKUStatus,
    currentSKUCode: this.props.initialData?.SKUCode,
    openERPModal: false,
    ERPError: [],
  };

  validate = ({ backgroundColor, SKUStatus, SKUCode }, isChanged) => {
    const error = {};
    const { SKUStatus: prevSKUStatus, salePriceInclVAT } = this.props.initialData;
    if (backgroundColor && !HEX_COLOR_VALIDATION.test(backgroundColor) && isChanged) {
      error.backgroundColor = 'Background color must be in Hex-Color format';
    }

    if (ERPSystem.enabled) {
      const _prevSKUSTatus = prevSKUStatus.split('/')[1];
      const _skuStatus = SKUStatus.split('/')[1];
      if (
        (_prevSKUSTatus !== SKU_STATUS.SELLING) & (_skuStatus === SKU_STATUS.SELLING) &&
        isChanged
      ) {
        const isValid = validateSKUCode(SKUCode, ERPSystem.SKUCodeValidation);
        const erpCheckResult = checkReadyToPublish(
          isValid,
          salePriceInclVAT?.value,
          ERP_PRODUCT.BUNDLE
        );

        this.setState({ ERPError: erpCheckResult.error });
      } else {
        this.setState({ ERPError: [] });
      }
    }
    return error;
  };

  handleOpenERPModal = (isOpen) => {
    this.setState({ openERPModal: isOpen });
  };

  handleSubmit = (formData) => {
    if (ERPSystem.enabled && !isEmpty(this.state.ERPError)) {
      this.handleOpenERPModal(true);
      return;
    }

    this.props.onSubmit(formData);
  };

  handleChange = (value, isChanged) => {
    if (isChanged) this.setState({ currentStatus: value.SKUStatus, currentSKUCode: value.SKUCode });
  };

  render() {
    const { isEdit } = this.props;
    const overideProps = { ...this.props, onSubmit: this.handleSubmit };
    const { publishedAt, salePriceInclVAT } = this.props.initialData;
    const { currentStatus, currentSKUCode, openERPModal, ERPError } = this.state;
    return (
      <>
        <ERPWarningModal
          open={openERPModal}
          handleOpen={this.handleOpenERPModal}
          skuCode={currentSKUCode}
          salePriceInclVAT={salePriceInclVAT?.value}
          error={ERPError}
          erpProduct={ERP_PRODUCT.BUNDLE}
        />
        <FormGenerator
          fields={getFields(isEdit, publishedAt, currentStatus)}
          customValidation={this.validate}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
          submitText={isEdit ? 'Update' : 'Add'}
          cancelText="Cancel"
          showCancel
          {...overideProps}
        />
      </>
    );
  }
}

export default BundleOverviewForm;
