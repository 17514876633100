import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import urlJoin from 'url-join';

import { ComplexSideMenuLayout } from 'Components/SideMenuLayout';
import { GenSwitchRoute } from 'Util/Route';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import OnlineCoursesRoute from './Routes/OnlineCoursesRoute';
import OnlineCourseBatchesRoute from './Routes/OnlineCourseBatchesRoute';
import WorkshopsRoute from './Routes/WorkshopsRoute';
import SubscriptionsRoute from './Routes/SubscriptionsRoute';
import CollectionsRoute from './Routes/CollectionsRoute';
import OnlineCourseDetailsRoute from './Routes/OnlineCourseDetailsRoute';
import OnlineCourseBatchDetailsRoute from './Routes/OnlineCourseBatchDetailsRoute';
import WorkshopDetailsRoute from './Routes/WorkshopDetailsRoute';
import BundlesRoute from './Routes/BundlesRoute';
import BundleDetailsRoute from './Routes/BundleDetailsRoute';
import SubscriptionDetailsRoute from './Routes/SubscriptionDetailsRoute';
import CollectionDetailsRoute from './Routes/CollectionDetailsRoute';
import InstructorRoute from './Routes/InstructorRoute';
import {
  CategoriesOnlineCourseRoute,
  CategoriesWorkshopRoute,
  CategoriesCollectionRoute,
  CategoriesSubscriptionRoute,
  CategoriesBookRoute,
} from './Routes/CategoriesRoute';
import BooksRoute from './Routes/BooksRoute';
import BookDetailsRoute from './Routes/BookDetailsRoute';
import {
  onlineCoursesUrl,
  onlineCourseBatchesUrl,
  subscriptionsUrl,
  collectionsUrl,
  workshopUrl,
  bundleUrl,
  booksUrl,
  categoriesOnlineCoursesUrl,
  categoriesWorkshopsUrl,
  categoriesBundleUrl,
  categoriesSubscriptionUrl,
  categoriesBookUrl,
  categoriesInstructorUrl,
} from './Domains/resolveUrl';
import { PRODUCT_TYPE } from './Domains/constants';
import isProductEnabled from './Domains/isProductEnabled';

const RouteIndex = () => <Redirect to={{ title: '', pathname: '/products/online-courses' }} />;

const defaultRoute = {
  path: '/products',
  exact: true,
  roles: [],
  route: RouteIndex,
};

const genMenus = () => {
  const baseProductRoute = {
    onlineCourse: [
      {
        path: onlineCoursesUrl,
        title: 'Online Courses',
        menuName: 'Online Courses',
        icon: 'book',
        exact: true,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.ONLINE_COURSE.LIST.VIEW,
        ],
        route: OnlineCoursesRoute,
      },
      {
        path: urlJoin(onlineCoursesUrl, ':SKUCode'),
        title: 'Online Course Details',
        menuName: 'Online Course Details',
        icon: 'book',
        exact: false,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.ONLINE_COURSE.LIST.VIEW,
        ],
        route: OnlineCourseDetailsRoute,
        hidden: true,
      },
    ],
    onlineCourseBatch: [
      {
        path: onlineCourseBatchesUrl,
        title: 'Online Course Batches',
        menuName: 'Online Course Batches',
        icon: 'book',
        exact: true,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.ONLINE_COURSE.LIST.VIEW,
        ],
        route: OnlineCourseBatchesRoute,
      },
      {
        path: urlJoin(onlineCourseBatchesUrl, ':SKUCode'),
        title: 'Online Course Batch Details',
        menuName: 'Online Course Batch Details',
        icon: 'book',
        exact: false,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.ONLINE_COURSE.LIST.VIEW,
        ],
        route: OnlineCourseBatchDetailsRoute,
        hidden: true,
      },
    ],
    workshop: [
      {
        path: workshopUrl,
        title: 'Workshops',
        menuName: 'Workshops',
        icon: 'book',
        exact: true,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.WORKSHOP.LIST.VIEW,
        ],
        route: WorkshopsRoute,
      },
      {
        path: urlJoin(workshopUrl, ':SKUCode'),
        title: 'Workshops Details',
        menuName: 'Workshops Details',
        icon: 'book',
        exact: false,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.WORKSHOP.LIST.VIEW,
        ],
        route: WorkshopDetailsRoute,
        hidden: true,
      },
    ],
    bundle: [
      {
        path: bundleUrl,
        title: 'Bundles',
        menuName: 'Bundles',
        icon: 'book',
        exact: true,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.BUNDLE.LIST.VIEW,
        ],
        route: BundlesRoute,
      },
      {
        path: urlJoin(bundleUrl, ':SKUCode'),
        title: 'Bundles Details',
        menuName: 'Bundles Details',
        icon: 'book',
        exact: false,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.BUNDLE.LIST.VIEW,
        ],
        route: BundleDetailsRoute,
        hidden: true,
      },
    ],
    subscription: [
      {
        path: subscriptionsUrl,
        title: 'Subscriptions',
        menuName: 'Subscriptions',
        icon: 'book',
        exact: true,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.SUBSCRIPTION.LIST.VIEW,
        ],
        route: SubscriptionsRoute,
      },
      {
        path: urlJoin(subscriptionsUrl, ':SKUCode'),
        title: 'Subscription Details',
        menuName: 'Subscription Details',
        icon: 'book',
        exact: false,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.SUBSCRIPTION.LIST.VIEW,
        ],
        route: SubscriptionDetailsRoute,
        hidden: true,
      },
    ],
    collection: [
      {
        path: collectionsUrl,
        title: 'Collections',
        menuName: 'Collections',
        icon: 'book',
        exact: true,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.COLLECTION.LIST.VIEW,
        ],
        route: CollectionsRoute,
      },
      {
        path: urlJoin(collectionsUrl, ':SKUCode'),
        title: 'Collection Details',
        menuName: 'Collection Details',
        icon: 'book',
        exact: false,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.COLLECTION.LIST.VIEW,
        ],
        route: CollectionDetailsRoute,
        hidden: true,
      },
    ],
    book: [
      {
        path: booksUrl,
        title: 'Books',
        menuName: 'Books',
        icon: 'book',
        exact: true,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.BOOK.LIST.VIEW,
        ],
        route: BooksRoute,
      },
      {
        path: urlJoin(booksUrl, ':SKUCode'),
        title: 'Book Details',
        menuName: 'Book Details',
        icon: 'book',
        exact: false,
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.MARKETER,
          APP_PERMISSION.BOOK.OVERVIEW.VIEW,
        ],
        route: BookDetailsRoute,
        hidden: true,
      },
    ],
  };
  const products = [
    ...(isProductEnabled(PRODUCT_TYPE.ONLINE_COURSE) ? baseProductRoute.onlineCourse : []),
    ...(isProductEnabled(PRODUCT_TYPE.ONLINE_COURSE_BATCH)
      ? baseProductRoute.onlineCourseBatch
      : []),
    ...(isProductEnabled(PRODUCT_TYPE.WORKSHOP) ? baseProductRoute.workshop : []),
    ...(isProductEnabled(PRODUCT_TYPE.BUNDLE) ? baseProductRoute.bundle : []),
    ...(isProductEnabled(PRODUCT_TYPE.SUBSCRIPTION) ? baseProductRoute.subscription : []),
    ...(isProductEnabled(PRODUCT_TYPE.COLLECTION) ? baseProductRoute.collection : []),
    ...(isProductEnabled(PRODUCT_TYPE.BOOK) ? baseProductRoute.book : []),
  ];

  const onlineCourseRoles = [
    APP_PERMISSION.COMMON.ADMINISTRATOR,
    APP_PERMISSION.COMMON.MARKETER,
    APP_PERMISSION.ONLINE_COURSE_CATEGORY.LIST.VIEW,
  ];

  const workshopRoles = [
    APP_PERMISSION.COMMON.ADMINISTRATOR,
    APP_PERMISSION.COMMON.MARKETER,
    APP_PERMISSION.WORKSHOP_CATEGORY.LIST.VIEW,
  ];

  const collectionRoles = [
    APP_PERMISSION.COMMON.ADMINISTRATOR,
    APP_PERMISSION.COMMON.MARKETER,
    APP_PERMISSION.COLLECTION_CATEGORY.LIST.VIEW,
  ];

  const subscriptionRoles = [
    APP_PERMISSION.COMMON.ADMINISTRATOR,
    APP_PERMISSION.COMMON.MARKETER,
    APP_PERMISSION.SUBSCRIPTION_CATEGORY.LIST.VIEW,
  ];

  const bookRoles = [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.MARKETER];

  const instructorRoles = [
    APP_PERMISSION.COMMON.ADMINISTRATOR,
    APP_PERMISSION.COMMON.MARKETER,
    APP_PERMISSION.INSTRUCTOR.LIST.VIEW,
  ];

  const baseCategoryRoute = {
    onlineCourse: [
      {
        path: categoriesOnlineCoursesUrl,
        title: 'Online Course',
        menuName: 'Online Course',
        icon: 'book',
        exact: false,
        private: true,
        roles: onlineCourseRoles,
        route: (props) => CategoriesOnlineCourseRoute({ ...props, roles: onlineCourseRoles }),
      },
    ],
    workshop: [
      {
        path: categoriesWorkshopsUrl,
        title: 'Workshop',
        menuName: 'Workshop',
        icon: 'book',
        exact: false,
        private: true,
        roles: workshopRoles,
        route: (props) => CategoriesWorkshopRoute({ ...props, roles: workshopRoles }),
      },
    ],
    collection: [
      {
        path: categoriesBundleUrl,
        title: 'Collection',
        menuName: 'Collection',
        icon: 'book',
        exact: false,
        private: true,
        roles: collectionRoles,
        route: (props) => CategoriesCollectionRoute({ ...props, roles: collectionRoles }),
      },
    ],
    subscription: [
      {
        path: categoriesSubscriptionUrl,
        title: 'Subscription',
        menuName: 'Subscription',
        icon: 'book',
        exact: false,
        private: true,
        roles: subscriptionRoles,
        route: (props) => CategoriesSubscriptionRoute({ ...props, roles: subscriptionRoles }),
      },
    ],
    book: [
      {
        path: categoriesBookUrl,
        title: 'Book',
        menuName: 'Book',
        icon: 'book',
        exact: false,
        private: true,
        roles: bookRoles,
        route: (props) => CategoriesBookRoute({ ...props, roles: bookRoles }),
      },
    ],
    instructor: [
      {
        path: categoriesInstructorUrl,
        title: 'Instructor',
        menuName: 'Instructor',
        icon: 'book',
        exact: false,
        private: true,
        roles: instructorRoles,
        route: InstructorRoute,
      },
    ],
  };
  const categories = [
    ...(isProductEnabled(PRODUCT_TYPE.ONLINE_COURSE) ? baseCategoryRoute.onlineCourse : []),
    ...(isProductEnabled(PRODUCT_TYPE.WORKSHOP) ? baseCategoryRoute.workshop : []),
    ...(isProductEnabled(PRODUCT_TYPE.BUNDLE) || isProductEnabled(PRODUCT_TYPE.COLLECTION)
      ? baseCategoryRoute.collection
      : []),
    ...(isProductEnabled(PRODUCT_TYPE.SUBSCRIPTION) ? baseCategoryRoute.subscription : []),
    ...(isProductEnabled(PRODUCT_TYPE.BOOK) ? baseCategoryRoute.book : []),
    ...(isProductEnabled(PRODUCT_TYPE.ONLINE_COURSE) || isProductEnabled(PRODUCT_TYPE.WORKSHOP)
      ? baseCategoryRoute.instructor
      : []),
  ];

  return { Products: products, Categories: categories };
};

export default class ProductsRoute extends Component {
  render() {
    const { myRoles } = this.props;
    const visibleMenus = {};
    const menus = genMenus();
    Object.keys(menus).forEach((header) => {
      visibleMenus[header] = menus[header].filter(({ hidden, roles }) => {
        return !hidden && _.intersection(roles, myRoles).length > 0;
      });
    });
    const filteredMenus = {};
    Object.keys(visibleMenus).forEach((header) => {
      if (visibleMenus[header].length > 0) {
        filteredMenus[header] = visibleMenus[header];
      }
    });
    return (
      <ComplexSideMenuLayout menus={filteredMenus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute routes={[..._.flatMap(menus, (innerMenus) => innerMenus), defaultRoute]} />
      </ComplexSideMenuLayout>
    );
  }
}
