import React, { useRef } from 'react';
import { Button } from 'semantic-ui-react';
import { isNil } from 'lodash';
import { useMutation } from '@apollo/client';

import { generateDownloadRandomDiscountCodeCSVSignedURL } from 'Routes/SalesV2/GraphQL/mutation/DiscountCode.mutation';

const DownloadCSVButton = ({ downloadable, id }) => {
  const downloadButton = useRef(null);
  const [generateSignedURL, { loading }] = useMutation(
    generateDownloadRandomDiscountCodeCSVSignedURL
  );

  const generateSinedURL = async (jobId) => {
    const res = await generateSignedURL({
      variables: {
        jobId,
      },
    });
    return res?.data?.sfBoPaymentGenerateSignedURLRandomDiscountCodeCSV?.url;
  };

  const extractFilename = (inputURL) => {
    const url = new URL(inputURL);
    // its look like this ["bucket-name", "file-name"]
    const paths = url.pathname.split('/');
    return paths.pop();
  };

  const download = async () => {
    try {
      const url = await generateSinedURL(id);
      const filename = extractFilename(url);
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'text/csv' },
      });
      const blob = await response.blob();
      const fileDownloadUrl = URL.createObjectURL(blob);
      downloadButton.current.href = fileDownloadUrl;
      downloadButton.current.download = filename;
      downloadButton.current.click();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Button
        primary
        circular
        onClick={download}
        loading={loading}
        disabled={!downloadable || loading}
      >
        Download
      </Button>
      <a download href={'/#'} ref={downloadButton} style={{ display: 'none' }}>
        download
      </a>
    </>
  );
};

export default DownloadCSVButton;
