import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from 'recompose';
import { Container, Icon, Divider } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';
import { VideoManagementModalButton } from 'Containers/VideoManagement';
import getConfig from 'src/Util/Config';
import CourseTimeSummary from 'Routes/Courses/Components/CourseTimeSummary';

import { SemanticButton as Button } from '../../../../Components/Base/Button';
import { getRouteInfo } from '../utils';
import AddSectionModal from '../../Containers/AddSectionModal';
import PublishToolbar from '../../Containers/PublishToolbar';
import Loading from '../../../../Components/Loading';

import Outline from './Outline';

const Toolbar = ({ courseId, product, version }) => (
  <Flex justifyContent="space-between" alignItems="center" mb={3}>
    <Box />
    <AddSectionModal
      courseId={courseId}
      product={product}
      version={version}
      isSection={true}
      trigger={
        <Button primary circular data-testid="btn-add-section">
          <Icon name="plus square" />
          Add Section
        </Button>
      }
    />
  </Flex>
);

const VideoManagementButton = (
  { courseId, product, version, courseCode, courseOwnerId },
  enabledByteArkApi
) => (level, sectionId) => {
  return (
    enabledByteArkApi && (
      <VideoManagementModalButton
        courseId={courseId}
        product={product}
        version={version}
        level={level}
        courseCode={courseCode}
        courseOwnerId={courseOwnerId}
        sectionId={sectionId}
      />
    )
  );
};

class LessonPanel extends Component {
  static propTypes = {
    courseId: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  };
  state = {
    isLoading: false,
    isOpenPopup: false,
  };
  handleLoading = (isLoading) => {
    this.setState({ isLoading });
  };

  render() {
    const { enabledSectionGroup, enabledByteArkApi } = getConfig();
    const { courseId, product, version, courseCode, courseOwnerId } = this.props;

    const { isLoading } = this.state;
    const courseMeta = { courseId, product, version, courseCode, courseOwnerId };

    return (
      <Container>
        <Flex justifyContent="space-between" alignItems="center">
          <CourseTimeSummary courseId={courseId} />
          <PublishToolbar
            courseId={courseId}
            product={product}
            version={version}
            videoManagementButton={VideoManagementButton(courseMeta, enabledByteArkApi)}
          />
        </Flex>
        {/* <Toolbar courseId={courseId} product={product} version={version} /> */}
        <Outline
          courseId={courseId}
          product={product}
          version={version}
          isLoading={isLoading}
          onLoading={this.handleLoading}
          videoManagementButton={VideoManagementButton(courseMeta, enabledByteArkApi)}
        />
        <Box my={3}>
          {enabledSectionGroup && (
            <>
              <AddSectionModal
                courseId={courseId}
                product={product}
                version={version}
                isSection={false}
                isSectionGroup={true}
                trigger={
                  <Button secondary circular fluid basic data-testid="btn-add-section-group">
                    <Icon name="plus square" />
                    Add Section Group
                  </Button>
                }
              />
              <Divider hidden />
            </>
          )}
          <AddSectionModal
            isSection={true}
            courseId={courseId}
            product={product}
            version={version}
            trigger={
              <Button primary circular fluid basic data-testid="btn-add-section">
                <Icon name="plus square" />
                Add Section
              </Button>
            }
          />
        </Box>
        {isLoading && <Loading fullpage />}
      </Container>
    );
  }
}

export default compose(mapProps(getRouteInfo))(LessonPanel);
