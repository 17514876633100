import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon, Container } from 'semantic-ui-react';
import { compose } from 'recompose';
import { withApollo } from '@apollo/client/react/hoc';

import { Flex } from 'Components/Base';
import { getAllCourses } from 'GraphQL/query/Courses.query';
import { deleteCourse, duplicateCourse } from 'GraphQL/mutation/Courses.mutation';
import QueryTable from 'GraphQL/util/QueryTable';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';
import withAppConfig from 'Util/hoc/withAppConfig';

import withServerConfig from '../../../GraphQL/util/withServerConfig';
import AddCourseModal from '../Containers/AddCourseModal';
import ConfirmationButton from '../../../Components/ConfirmationButton';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';
import { courseOwnerEnabled, getCourseOwnerDDL, courseOwners } from '../../../Util/CourseOwner';

import { COURSE_COLUMN } from './constants';

const courseColumns = ({ deleteCourse, duplicateCourse, urlPath, appConfig }) => {
  const courseOwnerColumns = courseOwnerEnabled
    ? [
        {
          Header: 'Course Owner',
          accessor: 'courseOwnerId',
          width: 150,
          type: COLUMN_TYPE.ENUM,
          hidden: !courseOwnerEnabled,
          enumOptions: [...getCourseOwnerDDL()],
          isSearchAble: true,
          Cell: ({ original }) => {
            const courseOwner = courseOwners[original.courseOwnerId];
            return courseOwner ? courseOwner.desc : original.courseOwnerId;
          },
        },
      ]
    : [];

  return [
    COURSE_COLUMN.ID,
    {
      Header: 'Course Name',
      accessor: 'title',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      Cell: (row) => (
        <Link
          data-testid={`link-course-${row.original.courseCode}`}
          to={{ pathname: `${urlPath}/v2/${row.original.id}/overview` }}
        >
          {row.value}
        </Link>
      ),
    },
    ...courseOwnerColumns,
    COURSE_COLUMN.COURSE_CODE,
    COURSE_COLUMN.PERMALINK,
    COURSE_COLUMN.STATUS,
    {
      Header: 'Action',
      accessor: 'updatedAt',
      width: 70,
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      Cell: function renderAction({ original }) {
        return (
          <Button.Group>
            {appConfig?.courses?.enableCourseDuplication && (
              <ConfirmationButton
                onConfirm={() =>
                  duplicateCourse({
                    id: original.id,
                    courseCode: original.courseCode,
                    title: original.title,
                  })
                }
                headerText={`Duplicate Course?`}
                confirmText="Confirm"
                contentText={`This course will be duplicated into "Copy of ${original.title}".`}
                data-testid={`btn-duplicate-course-${original.courseCode}`}
              >
                <Icon name="copy outline" />
              </ConfirmationButton>
            )}
            <ConfirmationButton
              onConfirm={() => deleteCourse(original.id)}
              headerText={`Delete Course?`}
              confirmText="Delete"
              contentText="This course will be permanently deleted from the system."
              data-testid={`btn-delete-course-${original.courseCode}`}
            >
              <Icon name="trash alternate outline" color="red" />
            </ConfirmationButton>
          </Button.Group>
        );
      },
    },
  ];
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Course duplicated failed, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  COURSE_NOT_FOUND: {
    message: 'Unable to duplicate deleted course, please refresh.',
    variant: VARIANTS.ERROR,
    show: true,
  },
  IN_PROCESS: {
    message: `Copying course...`,
    variant: VARIANTS.INFO,
    show: true,
  },
};

class AllCoursesQuery extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: { version: 'SKOOLDIO_V2' },
    order: { field: 'updatedAt', type: 'DESC' },
    feedback: { ...FEEDBACK.UNEXPECTED_ERROR, show: false },
  };
  static propTypes = {
    data: PropTypes.array,
    urlPath: PropTypes.string,
    appConfig: PropTypes.object,
    config: PropTypes.object,
  };

  static defaultProps = {
    urlPath: '/courses',
  };

  handleDelete = async (courseId) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    client.mutate({
      mutation: deleteCourse,
      variables: {
        version: 'SKOOLDIO_V2',
        courseId: courseId,
      },
      refetchQueries: [
        {
          query: getAllCourses,
          variables: {
            search,
            paging,
            order,
          },
        },
      ],
      fetchPolicy: 'no-cache',
    });
  };

  handleDuplicate = async ({ id: courseId, courseCode, title }) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    this.setState({ feedback: FEEDBACK.IN_PROCESS });
    client
      .mutate({
        mutation: duplicateCourse,
        variables: {
          version: 'SKOOLDIO_V2',
          courseId: courseId,
        },
        refetchQueries: [
          {
            query: getAllCourses,
            variables: {
              search,
              paging,
              order,
            },
          },
        ],
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        const isSuccess = response?.data?.duplicateCourse;
        if (!isSuccess) this.setState({ feedback: FEEDBACK.UNEXPECTED_ERROR });

        this.setState({
          feedback: {
            message: `Course ${
              courseCode ? ` "${courseCode}" ` : ' '
            }has been duplicated as "Copy of ${title}"`,
            variant: VARIANTS.SUCCESS,
            show: true,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        const message = error?.message ?? '';

        if (message.includes('401')) {
          this.setState({ feedback: FEEDBACK.PROHIBITED });
          return;
        }

        if (message.includes('404')) {
          this.setState({ feedback: FEEDBACK.COURSE_NOT_FOUND });
          return;
        }

        this.setState({ feedback: FEEDBACK.UNEXPECTED_ERROR });
      });
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };

  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({
      search: {
        version: 'SKOOLDIO_V2',
        ...search,
      },
      paging,
    });
  };

  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };

  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };

  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };

  render() {
    const { config, appConfig, match } = this.props;
    const { search, paging, order, feedback } = this.state;
    const { urlPath } = this.props;
    return (
      <Fragment>
        <Container>
          <Flex justifyContent="flex-end" mb={2}>
            <AddCourseModal
              version={match?.params?.courseVersion}
              product={config?.product}
              variables={{ search, paging, order }}
              trigger={
                <Button primary circular data-testid="btn-add-course">
                  <Icon name="plus" size="small" />
                  Add Course
                </Button>
              }
            />
          </Flex>
          <QueryTable
            type="courses"
            columns={courseColumns({
              urlPath,
              deleteCourse: this.handleDelete,
              duplicateCourse: this.handleDuplicate,
              appConfig: appConfig,
            })}
            query={getAllCourses}
            search={search}
            onSearchChange={this.handleSearchChange}
            paging={paging}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            order={order}
            onSortedChange={this.handleSortedChange}
          />
        </Container>
        <Snackbar
          message={feedback.message}
          isOpen={feedback.show}
          onClose={() => this.setState((prev) => ({ feedback: { ...prev.feedback, show: false } }))}
          variant={feedback.variant}
          vertical={'top'}
          horizontal={'center'}
          duration={3000}
        />
      </Fragment>
    );
  }
}

export default compose(
  withAppConfig({ configProp: 'appConfig' }),
  withServerConfig(),
  withApollo
)(AllCoursesQuery);
