import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formsy-semantic-ui-react';
import { Grid } from 'semantic-ui-react';

import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import RichTextEditor from '../../../RichTextEditor';
import { MARKDOWN_EDITOR_TYPE, CHOICE_PREFIX } from '../constant';

const styles = {
  root: {
    marginTop: 18,
  },

  radioGroup: {
    display: 'flex',
    marginBottom: 18,
  },

  radio: {
    marginLeft: 4,
    marginRight: 12,
  },
};

const MultipleChoice = ({
  answerChoices,
  onAnswerChange,
  onAnswerHintChange,
  value,
  onRadioChange,
  examId,
  editorContentFormat,
  maxFileSize,
  isQuizSimpleMode,
}) => {
  const choicePrefix = `${CHOICE_PREFIX.EN} `;
  let answerRow = [];
  if (answerChoices) {
    answerChoices.map((answer, index) => {
      answerRow.push(
        <Grid.Row key={`radio_${index}`}>
          <Grid.Column width={6} textAlign="right">
            <Form.Radio
              label={`${choicePrefix} ${index + 1}`}
              value={index}
              style={styles.radio}
              onChange={(e, { value }) => onRadioChange(value)}
              checked={value === index}
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <RichTextEditor
              onChange={(data) => onAnswerChange(data, index)}
              id={`answer_${index}`}
              value={answer.value}
              contentFormat={editorContentFormat}
              returnValueAsString={false}
              customControls={[
                {
                  type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                  data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                  maxFileSize: maxFileSize,
                },
              ]}
            />
          </Grid.Column>
          {isQuizSimpleMode && (
            <Fragment>
              <Grid.Column width={6} textAlign="right">
                {`Hint: ${choicePrefix} ${index + 1}`}
              </Grid.Column>
              <Grid.Column width={10}>
                <RichTextEditor
                  onChange={(data) => onAnswerHintChange(data, index)}
                  id={`answer_${index}`}
                  value={answer.hint}
                  contentFormat={editorContentFormat}
                  returnValueAsString={false}
                  customControls={[
                    {
                      type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                      data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                      maxFileSize: maxFileSize,
                    },
                  ]}
                />
              </Grid.Column>
            </Fragment>
          )}
        </Grid.Row>
      );
    });
  }
  return <Grid divided="vertically">{answerRow}</Grid>;
};

MultipleChoice.propTypes = {
  answerChoices: PropTypes.arrayOf(
    PropTypes.shape({
      value: RichTextEditor.propTypes.value,
      isAnswer: PropTypes.bool,
    })
  ),
  onRadioChange: PropTypes.func,
  onAnswerChange: PropTypes.func,
  maxFileSize: PropTypes.number,
};

MultipleChoice.defaultProps = {
  answerChoices: [],
  onRadioChange: () => {},
  onAnswerChange: () => {},
};

export default MultipleChoice;
