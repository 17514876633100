import React, { Fragment, useMemo, useCallback, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Flex, Text, Box, ButtonFilled } from 'Components/Base';
import StatefulModal from 'Components/Modal/StatefulModal';

import RoleDetails from '../../Containers/RoleDetails';
import SearchRoleList, { DEFAULT_ROLE_COLUMNS } from '../../Containers/SearchRoleList';
import DeleteIconModal from '../../Containers/DeleteIconModal';
import { DELETE_APP_ROLE } from '../../Graphql/mutation/Role.mutation';
import Snackbar, { VARIANTS } from '../../Components/Snackbar';

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const NewRoleModal = () => {
  const history = useHistory();
  const handleCreateRoleComplete = useCallback(
    (callback) => (response) => {
      callback();
      const roleId = response?.createAppRole?.id;
      if (roleId) history.push(`/users/roles/${roleId}`);
    },
    [history]
  );
  return (
    <StatefulModal
      trigger={
        <ButtonFilled
          bg="primary"
          color="white"
          borderRadius={'100px'}
          px={3}
          py={2}
          fontWeight="600"
          fontSize="14px"
        >
          <Flex alignItems="center">
            <AddIcon style={{ width: '24px' }} />
            <span>Add New Role</span>
          </Flex>
        </ButtonFilled>
      }
    >
      {(closeModal, isOpen) => (
        <>
          <Text.Header py={3} px={4} fontSize="20px">
            Add New Role
          </Text.Header>
          <Divider />
          <Box pt={4} pb={3} px={5} mx="auto" width="100%" maxWidth="900px">
            <RoleDetails
              isEdit={false}
              onCompleted={handleCreateRoleComplete(closeModal)}
              onCancelled={closeModal}
            />
          </Box>
        </>
      )}
    </StatefulModal>
  );
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  PERMISSION_LIMITATION: {
    message: 'Delete your role or higher is prohibited',
    variant: VARIANTS.ERROR,
    show: true,
  },
  ROLE_NOT_FOUND: {
    message: `Role not found or has been delete already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Can't deleted role right now, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const AllRolesList = () => {
  const { pathname } = useLocation();
  const [feedback, setFeedback] = useState({});
  const SearchRoleListRef = useRef();

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('404')) {
      setFeedback(FEEDBACK.ROLE_NOT_FOUND);
      return;
    }

    if (message.includes('403')) {
      setFeedback(FEEDBACK.PERMISSION_LIMITATION);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const onCompleted = useCallback((response) => {
    const deletedRoleName = response?.deleteAppRole?.name;
    setFeedback({
      message: `Role${deletedRoleName ? ` "${deletedRoleName}" ` : ' '}has been deleted`,
      variant: VARIANTS.SUCCESS,
      show: true,
    });
    const SearchRoleListTable = SearchRoleListRef.current;
    if (typeof SearchRoleListTable.refetch === 'function') {
      SearchRoleListTable.refetch();
    }
  }, []);

  const [deleteRole] = useMutation(DELETE_APP_ROLE, {
    onCompleted,
    onError,
  });

  const handleDeleteRole = useCallback(
    (roleId) => {
      deleteRole({
        variables: {
          id: roleId,
        },
      });
    },
    [deleteRole]
  );

  const COLUMN = useMemo(
    () => [
      ...DEFAULT_ROLE_COLUMNS,
      {
        Header: 'Action',
        Cell: function actionComponent(row) {
          return (
            <Flex justifyContent="center">
              <Link
                to={{ pathname: `${pathname}/${row.original.id}` }}
                style={{ color: 'inherit', width: '20px', height: '20px' }}
              >
                <EditIcon />
              </Link>
              <DeleteIconModal
                title={'Delete Role?'}
                description={'This role will be permanently deleted from the system.'}
                onConfirmClick={() => handleDeleteRole(row.original.id)}
              />
            </Flex>
          );
        },
        width: 80,
        isSearchAble: false,
        sortable: false,
      },
    ],
    [pathname, handleDeleteRole]
  );

  return (
    <>
      <SearchRoleList columns={COLUMN} ref={SearchRoleListRef} />
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
};

const RolesRoute = () => {
  return (
    <Fragment>
      <Flex justifyContent="space-between" mb={2}>
        <Text.Header>Roles Management</Text.Header>
        <NewRoleModal />
      </Flex>
      <AllRolesList />
    </Fragment>
  );
};

export default RolesRoute;
