import React from 'react';
import styled from 'styled-components';

import { Flex } from 'Components/Base';

const Wrapper = styled(Flex)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }
`;

const HeaderButtonAction = ({ children, onClick, ...props }) => (
  <Wrapper onClick={onClick} p={2} justifyContent={'center'} alignItems={'center'} {...props}>
    {children}
  </Wrapper>
);

export default HeaderButtonAction;
