export const SubscriptionPermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'product.subscription.list-view',
    UPDATE: 'product.subscription.list-update',
    DELETE: 'product.subscription.list-delete',
  },
  OVERVIEW: {
    VIEW: 'product.subscription.overview-view',
    UPDATE: 'product.subscription.overview-update',
    DELETE: 'product.subscription.overview-delete',
  },
  CATEGORIES: {
    VIEW: 'product.subscription.categories-view',
    UPDATE: 'product.subscription.categories-update',
    DELETE: 'product.subscription.categories-delete',
  },
  PRICING: {
    VIEW: 'product.subscription.pricing-view',
    UPDATE: 'product.subscription.pricing-update',
    DELETE: 'product.subscription.pricing-delete',
  },
  REDEMPTION: {
    VIEW: 'product.subscription.redemption-view',
    UPDATE: 'product.subscription.redemption-update',
    DELETE: 'product.subscription.redemption-delete',
  },
  CONDITIONS: {
    VIEW: 'product.subscription.conditions-view',
    UPDATE: 'product.subscription.conditions-update',
    DELETE: 'product.subscription.conditions-delete',
  },
  ITEMS: {
    VIEW: 'product.subscription.items-view',
    UPDATE: 'product.subscription.items-update',
    DELETE: 'product.subscription.items-delete',
  },
  EXTERNAL: {
    VIEW: 'product.subscription.external-view',
    UPDATE: 'product.subscription.external-update',
    DELETE: 'product.subscription.external-delete',
  },
  USER_ENROLLMENT: {
    VIEW: 'product.subscription.userEnrollment-view',
    UPDATE: 'product.subscription.userEnrollment-update',
    DELETE: 'product.subscription.userEnrollment-delete',
  },
});
