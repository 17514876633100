import styled from 'styled-components';

import { Grid } from 'Components/Base';

const TwoColumnGrid = styled(Grid)`
  padding: 16px 32px;
  grid-gap: 20px 32px;
  grid-template-columns: 1fr 3fr;
`;

export default TwoColumnGrid;
