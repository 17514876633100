import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '@skooldio/skooldio-video-player/dist/skd-videojs.css';

import { compose } from 'recompose';

import { Box, Flex } from 'Components/Base';
import withAppConfig from 'Util/hoc/withAppConfig';

import * as videoAdapter from '../../../Util/ExternalAdapter/VideoAdapter';

import VideoDropZone from './VideoDropZone';

const fileExtension = ['.mov', '.mp4', '.wmv'];

class VideoUpload extends Component {
  static propTypes = {
    videoType: PropTypes.func,
    metadata: PropTypes.object,
    onCompleteUpload: PropTypes.func,
  };

  state = {
    mode: VideoDropZone.MODE.upload,
    uploadProgresses: [],
  };

  handleOnUploadProgress = (idx) => async (loaded, total) => {
    const { uploadProgresses } = this.state;
    uploadProgresses[idx] = { loaded, total };
    this.setState({
      uploadProgresses: uploadProgresses,
      mode: VideoDropZone.MODE.progress,
    });
  };

  handleUpload = async (files) => {
    const { metadata, videoType, onCompleteUpload, appConfig } = this.props;
    const uploadResult = await videoAdapter.uploads(
      videoType,
      files,
      metadata,
      this.handleOnUploadProgress,
      appConfig.byteArkSdkUpload
    );

    if (uploadResult) {
      onCompleteUpload();
    }
  };

  render = () => {
    const { mode } = this.state;
    const { videoUploadMaxItems, isAllowMultiple } = this.props;
    return (
      <Flex flexWrap="wrap">
        <Box width={1} p={3}>
          <VideoDropZone
            mode={mode}
            acceptFileTypes={fileExtension}
            onUpload={this.handleUpload}
            uploadProgresses={this.state.uploadProgresses}
            isAllowMultiple={isAllowMultiple}
            videoUploadMaxItems={videoUploadMaxItems}
          ></VideoDropZone>
        </Box>
      </Flex>
    );
  };
}

export default compose(withAppConfig({ configProp: 'appConfig' }))(VideoUpload);
