import React, { Component } from 'react';

import { Flex, Text } from 'Components/Base';
import QueryTable, { DATA_FORMAT } from 'GraphQL/util/QueryTable';

import COLUMN_TYPE from '../../../../Components/Search/SearchFormGenerator/constantType';
import { getUserPDPAReport } from '../../../../GraphQL/query/Users.query';

const getUsersColumn = (reportFields = []) => {
  const userDataColumns = reportFields.map((field) => {
    return {
      Header: field,
      accessor: field,
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    };
  });

  const PDPADataColumns = [
    {
      Header: 'IsUserAccept',
      accessor: 'isUserAccept',
      width: 120,
      type: COLUMN_TYPE.CUSTOM,
      isSearchAble: false,
      Cell: ({ original }) => {
        return `${original.isUserAccept}`;
      },
    },
    {
      Header: 'AcceptedAt',
      accessor: 'acceptedAt',
      width: 150,
      type: COLUMN_TYPE.DATE,
      dataFormat: DATA_FORMAT.DATETIME_FORMAT,
      isSearchAble: false,
    },
    {
      Header: 'AcceptedVersion',
      accessor: 'acceptedVersion',
      width: 150,
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
  ];

  const allColumns = [...userDataColumns, ...PDPADataColumns];

  return allColumns;
};

class UserPDPAReportRoute extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'firstname', type: 'ASC' },
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };

  render() {
    const { search, paging, order } = this.state;
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>{this.props.title}</Text.Header>
        </Flex>
        <QueryTable
          type="usGetUserPDPAReport"
          resolveData={(data) => data.usGetUserPDPAReport.users}
          columns={getUsersColumn(this.props.reportFields)}
          query={getUserPDPAReport(this.props.id)}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default UserPDPAReportRoute;
