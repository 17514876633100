import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { GenSwitchRoute } from 'Util/Route';
import SideMenuLayout from 'Components/SideMenuLayout';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import ImportSyllabusesRoute from './Routes/ImportSyllabuses';
import ImportStudentsRoute from './Routes/ImportStudents';
const ImportDataRouteIndex = () => (
  <Redirect to={{ title: '', pathname: '/lsp/import-data/syllabuses' }} />
);

const defaultRoute = {
  path: '/lsp/import-data',
  exact: true,
  roles: [],
  route: ImportDataRouteIndex,
};

const menus = [
  {
    path: '/lsp/import-data/syllabuses',
    title: 'Syllabuses',
    menuName: 'Syllabuses',
    icon: 'book',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: ImportSyllabusesRoute,
  },
  {
    path: '/lsp/import-data/students',
    title: 'Students',
    menuName: 'Students',
    icon: 'user',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: ImportStudentsRoute,
  },
];

export default class ImportDataRoute extends Component {
  render() {
    return (
      <SideMenuLayout header={'File Types'} menus={menus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute routes={[...menus, defaultRoute]} />
      </SideMenuLayout>
    );
  }
}
