import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const getLabelFromName = (value, radioList) => {
  let result = '';
  radioList.forEach(({ label, name }) => {
    if (name === value) {
      result = label;
    }
  });
  return result;
};

/**
 * @param {string} initValue - category | ex. 'NEWS'
 */

const CategoryInput = ({ value: initValue, radioList, onChange, readOnly }) => {
  const [category, setCategory] = useState(initValue ? initValue : radioList[0].name);

  useEffect(() => {
    onChange({}, { value: category });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <Flex alignItems="center">
      {radioList.map(({ label, name }, idx) => (
        <Box mr={3} key={idx}>
          <Radio
            key={idx}
            label={label}
            name="category"
            value={name}
            checked={category === name}
            onChange={(_, { value }) => setCategory(value)}
            readOnly={readOnly}
          />
        </Box>
      ))}
    </Flex>
  );
};

CategoryInput.propTypes = {
  value: PropTypes.string,
  radioList: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(CategoryInput);
