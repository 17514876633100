import React from 'react';
import { Container } from 'semantic-ui-react';

import CreateEditExamForm from '../Containers/CreateEditExamForm';

const EditExam = ({ match, ...restProps }) => {
  const examId = match?.params?.examId;
  return (
    <Container>
      <CreateEditExamForm isEdit examId={examId} />
    </Container>
  );
};

export default EditExam;
