import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, omit } from 'lodash';

import FormGenerator from 'Components/Form/FormGenerator';
import getConfig from 'Util/Config';

import TagSelector from '../SKUTagSelector/TagSelector.gql';
import DateRangeInput from '../DateRangeInput';
import TimeRangeInput from '../TimeRangeInput';
import PriceInput from '../SKUPriceInput';
import getSkuStatusOptions from '../WorkshopEditor/utils/getSkuStatusOptions';
import { validateSKUCode, checkReadyToPublish, ERP_PRODUCT } from '../../Domains/ERPUtils';
import { SKU_STATUS } from '../../Domains/constants';
import ERPWarningModal from '../ERPWarningModal';

const { ERPSystem } = getConfig();

const POSITIVE_NUMBER_VALIDATION = /^[0-9]+$|^$/;

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'SKU Code',
    inputName: 'SKUCode',
    inputProps: {
      required: true,
      disabled: isEdit,
    },
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Status',
    inputName: 'status',
    options: getSkuStatusOptions('WORKSHOP_BATCH'),
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Batch Title',
    inputName: 'title',
    inputProps: {
      required: true,
    },
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
    inputLabel: 'Maximum seats',
    inputName: 'originalStock',
    inputProps: {
      required: true,
    },
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Workshop Date',
    inputName: 'eventDateRange',
    customInput: ({ name }) => <DateRangeInput name={name} />,
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Workshop Time',
    inputName: 'eventTimeRange',
    customInput: ({ name }) => <TimeRangeInput name={name} />,
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'ราคาตั้งต้น (Price incl. VAT) ',
    inputName: 'priceInclVAT',
    customInput: ({ name }) => <PriceInput name={name} />,
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'ราคาขายจริง (SalePrice incl. VAT)',
    inputName: 'salePriceInclVAT',
    customInput: ({ name }) => <PriceInput name={name} />,
    mode: MODE.BOTH,
  },
  // {
  //   inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
  //   inputLabel: "Category (merge with Workshop's category)",
  //   inputName: 'categories',
  //   customInput: () => <TagSelector type={TagSelector.TYPE.COURSE_CATEGORY} />,
  //   mode: MODE.BOTH,
  // },
];

const convertServerDataToFormData = (initialData) => {
  if (!initialData) return initialData;
  const { eventTime, ...restData } = initialData;
  if (!eventTime) return initialData;
  const { startDate, endDate, startTime, endTime } = eventTime;
  return {
    ...restData,
    eventDateRange: { startDate, endDate },
    eventTimeRange: { startTime, endTime },
  };
};

export default class WorkshopBatchForm extends Component {
  static propTypes = {
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  state = {
    currentStatus: this.props.initialData?.SKUStatus,
    currentSKUCode: this.props.initialData?.SKUCode,
    openERPModal: false,
    ERPError: [],
    salePriceInclVAT: this.props.initialData?.salePriceInclVAT,
  };

  handleChange = (value, isChanged) => {
    if (isChanged) {
      this.setState({
        currentStatus: value.SKUStatus,
        currentSKUCode: value.SKUCode,
        salePriceInclVAT: value.salePriceInclVAT,
      });
    }
  };

  handleSubmit = (data) => {
    const { onSubmit, isEdit } = this.props;
    const { eventDateRange, eventTimeRange, salePriceInclVAT, priceInclVAT, ...restData } = data;

    if (ERPSystem.enabled && !isEmpty(this.state.ERPError)) {
      this.handleOpenERPModal(true);
      return;
    }

    if (eventDateRange && eventTimeRange) {
      const { startTime: startTimeInput, endTime: endTimeInput } = eventTimeRange;
      const { startDate: startDateInput, endDate: endDateInput } = eventDateRange;

      if (startDateInput && startTimeInput) {
        const timeInput = moment(startTimeInput);
        const startDate = moment(startDateInput).set({
          hour: timeInput.hour(),
          minute: timeInput.minute(),
          second: timeInput.second(),
          millisecond: timeInput.millisecond(),
        });
        eventDateRange.startDate = startDate.toJSON();
        eventTimeRange.startTime = startDate.toJSON();
      }

      if (endDateInput && endTimeInput) {
        const timeInput = moment(endTimeInput);
        const endDate = moment(endDateInput).set({
          hour: timeInput.hour(),
          minute: timeInput.minute(),
          second: timeInput.second(),
          millisecond: timeInput.millisecond(),
        });
        eventDateRange.endDate = endDate.toJSON();
        eventTimeRange.endTime = endDate.toJSON();
      }
    }

    const newRestData = isEdit ? omit(restData, ['SKUCode']) : restData;
    let passingData = {
      ...newRestData,
      eventTime: { ...eventDateRange, ...eventTimeRange },
    };
    if (salePriceInclVAT) {
      const { value, unit } = salePriceInclVAT;
      if (value !== undefined && unit !== undefined) {
        passingData.salePriceInclVAT = { value, unit };
      }
    }
    if (priceInclVAT) {
      const { value, unit } = priceInclVAT;
      if (value !== undefined && unit !== undefined) {
        passingData.priceInclVAT = { value, unit };
      }
    }
    onSubmit(passingData);
  };

  validate = ({ originalStock, priceInclVAT, salePriceInclVAT, SKUCode, status }, isChanged) => {
    const { isEdit } = this.props;
    const error = {};
    if (isChanged) {
      if (originalStock && !POSITIVE_NUMBER_VALIDATION.test(originalStock)) {
        error.originalStock = 'Input number must be positive';
      }

      if (priceInclVAT && !POSITIVE_NUMBER_VALIDATION.test(priceInclVAT.value)) {
        error.priceInclVAT = 'Input number must be positive';
      }

      if (salePriceInclVAT && !POSITIVE_NUMBER_VALIDATION.test(salePriceInclVAT.value)) {
        error.salePriceInclVAT = 'Input number must be positive';
      }
    }
    if (!error.priceInclVAT && !error.salePriceInclVAT) {
      if (salePriceInclVAT && priceInclVAT && salePriceInclVAT.value > priceInclVAT.value) {
        error.salePriceInclVAT = `ราคาขายจริง 'ไม่ควรมากกว่า' ราคาตั้งต้น`;
      }
    }

    if (ERPSystem.enabled) {
      const _skuStatus = status ? status.split('_')[1] : undefined;

      // Edit Workshop batch
      if (isEdit) {
        const { status: prevSKUStatus } = this.props.initialData;
        if (
          (prevSKUStatus !== SKU_STATUS.SELLING) & (_skuStatus === SKU_STATUS.SELLING) &&
          isChanged
        ) {
          const isValid = validateSKUCode(SKUCode, ERPSystem.SKUCodeValidation);
          const erpCheckResult = checkReadyToPublish(
            isValid,
            salePriceInclVAT?.value,
            ERP_PRODUCT.WORKSHOP_BATCH
          );

          this.setState({ ERPError: erpCheckResult.error });
        } else {
          this.setState({ ERPError: [] });
        }
      }
      // Create Workshop batch
      else {
        const isValid = validateSKUCode(SKUCode, ERPSystem.SKUCodeValidation);
        const erpCheckResult = checkReadyToPublish(
          isValid,
          salePriceInclVAT?.value,
          ERP_PRODUCT.WORKSHOP_BATCH
        );

        this.setState({ ERPError: erpCheckResult.error });
      }
    }
    return error;
  };

  handleOpenERPModal = (isOpen) => {
    this.setState({ openERPModal: isOpen });
  };

  render() {
    const { isEdit, onSubmit, initialData } = this.props;
    const { currentSKUCode, openERPModal, ERPError, salePriceInclVAT } = this.state;

    return (
      <>
        <ERPWarningModal
          open={openERPModal}
          handleOpen={this.handleOpenERPModal}
          skuCode={currentSKUCode}
          salePriceInclVAT={salePriceInclVAT?.value}
          error={ERPError}
          erpProduct={ERP_PRODUCT.WORKSHOP_BATCH}
        />
        <FormGenerator
          fields={getFields(isEdit).filter(
            (f) =>
              f.mode === MODE.BOTH ||
              (isEdit && f.mode === MODE.EDIT) ||
              (!isEdit && f.mode === MODE.CREATE)
          )}
          customValidation={this.validate}
          submitText={isEdit ? 'Update' : 'Add'}
          cancelText="Cancel"
          showCancel
          {...this.props}
          initialData={convertServerDataToFormData(initialData)}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
        />
      </>
    );
  }
}
