import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import { Label } from 'semantic-ui-react';
import { Input } from 'formsy-semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import withServerConfig from 'GraphQL/util/withServerConfig';
import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import FileUploadManagerModal from 'Components/Lib/UploadComponent';

import { FILE_TYPE } from '../../common';

const HiddenInput = styled(Input)`
  display: none;
`;

const errorLabel = <Label color="red" pointing />;

class FileUploadInput extends Component {
  static propTypes = {
    onCreateNewData: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateNewData: () => {},
    isEdit: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      key: _.get(props, 'defaultValue.key', ''),
      bucket: _.get(props, 'defaultValue.bucket', ''),
      filename: _.get(props, 'defaultValue.filename', ''),
    };
  }

  handleFileListRequest = async (request) => {
    const { client, type } = this.props;
    const { data } = await client.mutate({
      mutation: getAssetList,
      variables: {
        type: ASSET_TYPE.LSP_BACKOFFICE_UPLOAD,
        key: type,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { client, type } = this.props;

    const { data } = await client.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.LSP_BACKOFFICE_UPLOAD,
        key: type,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  handleSelectImage = (files = []) => {
    if (files && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      // Now Support only one file upload
      const { key, bucket } = files[0];
      const names = key.replace(/^.*[\\\/]/, '').split('_');
      names.shift();
      this.setState({
        key,
        bucket,
        filename: names.join('_'),
      });
    }
  };

  render() {
    const { serverConfig } = this.props;
    const { key, bucket, filename } = this.state;

    return (
      <Flex>
        <Box flex="1 1 auto">
          <Input
            value={filename}
            name={'file.originalFilename'}
            required={true}
            validationErrors={{
              isDefaultRequiredValue: 'File is required',
            }}
            placeholder=""
            errorLabel={errorLabel}
            disabled={true}
          />
          <HiddenInput value={key} name={'file.key'} placeholder="" disabled={true} />
          <HiddenInput value={bucket} name={'file.bucket'} placeholder="" disabled={true} />
        </Box>
        <Box>
          <FileUploadManagerModal
            keyGroup="courseImage"
            uploadUrl={'http://localhost:5000'}
            listUrl={'http://localhost:5000'}
            dimmer="inverted"
            allowedContentTypes={[
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
            defaultText={'Support .xls, or .xlsx file with less than 5MB'}
            maxFileSize={5}
            maxSelection={1}
            customFileListUrlRequest={this.handleFileListRequest}
            customUploadUrlRequest={this.handleFileUploadUrlRequest}
            mode={serverConfig.assetUploadMode}
            onFileManagerDialogClose={this.handleSelectImage}
            trigger={<Button primary size="medium" type="button" content="Upload File" />}
          />
        </Box>
      </Flex>
    );
  }
}

export default compose(
  withApollo,
  withServerConfig({ configProp: 'serverConfig' })
)(FileUploadInput);
