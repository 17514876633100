import React, { Component } from 'react';

import CreateReport from '../../Containers/CreateReport';
import { FILE_TYPE } from '../../common';

export default class CreateIndividualPDFReport extends Component {
  render() {
    return <CreateReport title={'Individual Report'} type={FILE_TYPE.CREATE_INDIVIDUAL_REPORT} />;
  }
}
