import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, Container, Tab } from 'semantic-ui-react';
import { withTheme } from 'styled-components';

import { Box } from 'Components/Base';

import PublishToolbar from '../../Containers/PublishToolbar';

import DetailsEditor from './DetailsComponent/DetailsEditor';
import SettingsEditor from './DetailsComponent/SettingsEditor';
import CourseHeader from './Components/CourseHeader';
import DiscussionsEditor from './DetailsComponent/DiscussionsEditor/DiscussionsEditor';

/**
 * @param {object} props
 * @param {object} props.course
 * @param {string} props.courseId
 * @param {object} props.product
 * @param {object} props.version
 * @param {typeof import('Util/Config').defaultConfig} props.appConfig
 * @returns
 */
const CourseDetails = ({ course, courseId, product, version, appConfig }) => {
  const tabs = [
    {
      menuItem: { key: 'Details', 'data-testid': 'tab-Details', content: 'Details' },
      render: () => <DetailsEditor courseId={courseId} product={product} version={version} />,
    },
  ];

  if (appConfig.courses.enableCourseSetting) {
    tabs.push({
      menuItem: { key: 'Settings', 'data-testid': 'tab-Settings', content: 'Settings' },
      render: () => (
        <SettingsEditor courseId={courseId} product={product} version={version} course={course} />
      ),
    });
  }

  if (appConfig.enabledCourseForumConfig) {
    tabs.push({
      menuItem: {
        key: 'Discussions',
        'data-testid': 'tab-Discussions',
        content: 'Discussions',
      },
      render: () => (
        <DiscussionsEditor
          courseId={courseId}
          version={version}
          course={course}
          product={product}
        />
      ),
    });
  }

  return <Tab menu={{ secondary: true, pointing: true }} panes={tabs} />;
};

const parseCourseProps = (course = {}, version) => {
  if (version === 'SKOOLDIO_V1' && course.v1meta) return { ...course, ...course.v1meta };
  return course;
};

const CourseOverview = withTheme(({ course, product, version, theme, appConfig }) => {
  return (
    <Fragment>
      <Container>
        <PublishToolbar courseId={course.id} product={product} version={version} />
        <Divider hidden />
      </Container>
      <Container
        fluid
        style={{
          backgroundColor: (course.v1meta && course.v1meta.theme) || theme.colors.primary,
        }}
      >
        <Container>
          <CourseHeader
            courseId={course.id}
            product={product}
            version={version}
            {...parseCourseProps(course, course.courseVersion)}
          />
        </Container>
      </Container>
      <Divider hidden />
      <Container>
        <CourseDetails
          courseId={course.id}
          course={course}
          product={product}
          version={version}
          appConfig={appConfig}
        />
      </Container>
      <Box pt={3} />
    </Fragment>
  );
});

CourseOverview.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  product: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

CourseOverview.defaultProps = {};

export default CourseOverview;
