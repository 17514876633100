import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'Components/Base';

import { ITEMS_POSITION } from './constant';

/**
 * @param {string} position - Position of header items [left or right].
 */
const HeaderNavigation = ({ position, children, ...props }) => (
  <Flex
    flex={'1 1 auto'}
    justifyContent={position === 'right' ? 'flex-end' : 'flex-start'}
    {...props}
  >
    {children}
  </Flex>
);

HeaderNavigation.propTypes = {
  hasSeparator: PropTypes.bool,
  position: PropTypes.oneOf([ITEMS_POSITION.LEFT, ITEMS_POSITION.RIGHT]),
};

HeaderNavigation.defaultProps = {
  position: ITEMS_POSITION.LEFT,
};

export default HeaderNavigation;
