import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import FormGenerator from 'Components/Form/FormGenerator';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, id, iconUrl) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'SKUCode',
      inputName: 'SKUCode',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
  ];
  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

export default class CollectionForm extends Component {
  static propTypes = {
    onCreateCollection: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateCollection: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateCollection(form);
  };

  render() {
    const { id, isEdit, initialData } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit, id, _.get(initialData, 'iconUrl'))}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
