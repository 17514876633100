import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { useSearchFilter } from '../../Utils';
import { GET_ASSIGNED_USERS_BY_TEAM } from '../../Graphql/query/Team.query';

const BASIC_COLUMN = {
  ID: {
    Header: 'User ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  USERNAME: {
    Header: 'Username',
    accessor: 'username',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  FIRST_NAME: {
    Header: 'Firstname',
    accessor: 'firstname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  LAST_NAME: {
    Header: 'Lastname',
    accessor: 'lastname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  EMAIL: {
    Header: 'Email',
    accessor: 'email',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
};

const SearchTeamUserList = React.forwardRef(({ teamId, columns, resolveData }, ref) => {
  const {
    onSortedChange,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    order,
    paging,
    search,
  } = useSearchFilter();
  const getUsersData = useCallback(resolveData, [resolveData]);

  const getSearchVariables = () => ({
    teamId,
    order,
    paging,
    search,
  });

  return (
    <QueryTable
      type={'assignedUsersByTeam'}
      columns={columns}
      query={GET_ASSIGNED_USERS_BY_TEAM}
      resolveData={getUsersData}
      onSearchChange={onSearchChange}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortedChange={onSortedChange}
      getSearchVariables={getSearchVariables}
      paging={paging}
      ref={ref}
    />
  );
});

const DEFAULT_USER_COLUMNS = Object.values(BASIC_COLUMN);

SearchTeamUserList.propTypes = {
  columns: PropTypes.object,
  resolveData: PropTypes.func,
  teamId: PropTypes.func,
};

SearchTeamUserList.defaultProps = {
  columns: DEFAULT_USER_COLUMNS,
  resolveData: (data) => data.assignedUsersByTeam.users,
};

export { BASIC_COLUMN, DEFAULT_USER_COLUMNS };
export default SearchTeamUserList;
