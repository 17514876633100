const getWireSyncError = (type, { key = '', action }) => {
  const errorActionMsg = getActionMessage(action, key);
  const errorTypeMsg = getErrorTypeMessage(action, type, key);
  return new Error(`${errorActionMsg}, ${errorTypeMsg}.`);
};

const ERROR_TYPE = {
  NODE_ALREADY_EXIST: 'NODE_ALREADY_EXIST',
  INVALID_KEY: 'INVALID_KEY',
  NODE_NOT_FOUND: 'NODE_NOT_FOUND',
};

const ACTION = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  REMOVE: 'REMOVE',
  GET: 'GET',
};

const ACTION_TEXT = {
  ADD: 'add',
  EDIT: 'edit',
  REMOVE: 'remove',
  GET: 'get',
};

const getActionMessage = (action, key) => `Can't ${ACTION_TEXT[action]} '${key}' node`;
const getErrorTypeMessage = (action, type, key) => {
  switch (type) {
    case ERROR_TYPE.NODE_ALREADY_EXIST:
      return `'${key}' node is already exist`;
    case ERROR_TYPE.INVALID_KEY:
      return action === ACTION.ADD || action === ACTION.REMOVE
        ? `node key must be sting or array of string`
        : `node key must be sting`;
    case ERROR_TYPE.NODE_NOT_FOUND:
      return `'${key}' node not found`;
    default:
      return '';
  }
};

export { ERROR_TYPE, ACTION };
export default getWireSyncError;
