import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Modal, Message } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';

import StatefulModal from 'Components/Modal/StatefulModal';
import { SFBO_EXPORT_SHIPMENT_REPORTS } from 'GraphQL/query/Shipment.query.js';
import { SHIPMENT_STATUS } from 'Routes/Shipments/Domains/constant';

import SearchExportShipmentForm from './SearchExportShipmentForm';

const ExportShipmentModal = ({ trigger, shipmentStatus }) => {
  const downloadButton = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { refetch } = useQuery(SFBO_EXPORT_SHIPMENT_REPORTS, { skip: true });

  const exportFile = async (form) => {
    try {
      setErrorMessage(null);
      const { data, loading, error } = await refetch({
        search: { status: shipmentStatus, ...form },
      });
      if (error) {
        setErrorMessage(JSON.stringify(error.message));
        return;
      }
      const base64Data = data?.sfBoExportShipmentReports?.base64ExportShipmentReportsFile;
      if (!base64Data) {
        setErrorMessage('Not have the shipment reports');
        return;
      }
      const base64Response = await fetch(`data:application/vnd.ms-excel;base64,${base64Data}`);
      const blob = await base64Response.blob();
      const fileDownloadUrl = URL.createObjectURL(blob);
      downloadButton.current.href = fileDownloadUrl;
      downloadButton.current.download = `shipmentReports-${new Date()
        .toLocaleDateString()
        .replaceAll('/', '_')}`;
      downloadButton.current.click();
      URL.revokeObjectURL(fileDownloadUrl);
      downloadButton.current.href = '#';
    } catch (error) {
      setErrorMessage(JSON.stringify(error.message));
      return { error };
    }
  };

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <>
          <Modal.Header>Export Confirmed Shipment</Modal.Header>
          <Modal.Content>
            {errorMessage && <Message negative>{errorMessage}</Message>}
            <SearchExportShipmentForm onSubmit={exportFile} onCancelled={closeModal} />
            <a download href={'/#'} ref={downloadButton} style={{ display: 'none' }}>
              download
            </a>
          </Modal.Content>
        </>
      )}
    </StatefulModal>
  );
};

ExportShipmentModal.propTypes = {
  trigger: PropTypes.element,
  shipmentStatus: PropTypes.string,
};

ExportShipmentModal.defaultProps = {
  shipmentStatus: SHIPMENT_STATUS.CONFIRMED,
};

export default ExportShipmentModal;
