import React from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

export const ORGANIZATION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Organization Name',
    inputName: 'organizationName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Email domain name',
    inputName: 'domainName',
    inputDescription: 'Domain name จะไม่สามารถแก้ไขได้หลังการสร้าง',
    inputProps: {
      required: true,
      disabled: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Status',
    inputName: 'active',
    inputProps: {
      required: true,
      options: [
        { key: 'inactive', value: ORGANIZATION_STATUS.INACTIVE, text: 'Disabled' },
        { key: 'active', value: ORGANIZATION_STATUS.ACTIVE, text: 'Enabled' },
      ],
    },
  },
];

const OrganizationDetailsForm = ({ isEdit, initialData, onSubmit, onCancelled }) => {
  return (
    <FormGenerator
      fields={fields}
      onSubmit={onSubmit}
      onCancelled={onCancelled}
      submitText={'Save Organization Details'}
      cancelText="Cancel"
      showCancel={!isEdit}
      initialData={initialData}
    />
  );
};

OrganizationDetailsForm.propTypes = {
  isEdit: PropTypes.bool,
  initialData: PropTypes.shape({
    organizationName: PropTypes.string,
    domainName: PropTypes.string,
    active: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancelled: PropTypes.func,
};

OrganizationDetailsForm.defaultProps = {
  isEdit: true,
  initialData: {
    organizationName: '',
    domainName: '',
    active: 'inactive',
  },
  onSubmit: () => {},
  onCancelled: () => {},
};

export default OrganizationDetailsForm;
