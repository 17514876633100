import React from 'react';
import PropTypes from 'prop-types';
import { Header, Grid } from 'semantic-ui-react';

import SKUImageUpload from './SKUImageUpload.gql';

const SKUHeaderWithImage = ({ id, title, subTitle, coverImage, cardImage, updateSKUMutation }) => {
  const coverImageUrl = coverImage?.assetUrl;
  const cardImageUrl = cardImage?.assetUrl;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <SKUImageUpload
            changeImgText="Change cover image"
            id={id}
            fileImageUrl={coverImageUrl ?? undefined}
            field={'coverImage'}
            updateSKUMutation={updateSKUMutation}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <SKUImageUpload
            changeImgText="Change card image"
            id={id}
            fileImageUrl={cardImageUrl ?? undefined}
            field={'cardImage'}
            updateSKUMutation={updateSKUMutation}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width={16}>
        <Grid.Row>
          <Header size="huge">{title}</Header>
        </Grid.Row>
        <Grid.Row>
          <Header size="large">{subTitle}</Header>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

SKUHeaderWithImage.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  theme: PropTypes.string,
  coverImageUrl: PropTypes.string,
  cardImageUrl: PropTypes.string,
  SKUCode: PropTypes.string,
  updateSKUMutation: PropTypes.object,
};

export default SKUHeaderWithImage;
