import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Flex } from '@skooldio/skooldio-ui-components-core';
import styled, { css } from 'styled-components';
// eslint-disable-next-line no-restricted-imports
import { Dropdown } from 'semantic-ui-react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import apolloClient from 'src/GraphQL';
import { getAllSchools } from 'GraphQL/LE/query/School.query';
import { getSchoolTerms } from 'GraphQL/LSP/query/Education.query';
import { searchCourses, searchGrades } from 'GraphQL/LE/query/ClassEnrollment.query';
import { searchSubjects } from 'GraphQL/LE/query/Education.query';
import { Button } from 'Components/Base';
import { myProfile } from 'Redux/user-profile/selectors';

const Label = styled.label`
  position: relative;
  display: grid;
  place-items: center;
  margin-right: 2rem;
  font-weight: bold;

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: red;
        position: absolute;
        right: -0.6rem;
        top: 0.5rem;
      }
    `};
`;

const getFields = (data) => {
  const { schools = [], schoolTerms = [], schoolGrades = [], subjects = [], courses = [] } = data;

  const fields = [
    {
      search: true,
      label: 'โรงเรียน',
      name: 'school',
      options: schools?.map((school) => ({
        text: `${school.code}: ${school.name}`,
        value: school.id,
        key: school.id,
      })),
      required: true,
      placeholder: 'เลือกโรงเรียน',
    },
    {
      label: 'เทอม / ปีการศึกษา',
      name: 'schoolTerm',
      options: schoolTerms.map((term) => ({
        text: term.name,
        value: term.id,
        key: term.id,
      })),
      required: true,
      placeholder: 'เลือกเทอม / ปีการศึกษา',
    },
    {
      label: 'ระดับชั้น',
      name: 'schoolGrade',
      options: schoolGrades.map((grade) => ({
        text: grade.name,
        value: grade.id,
        key: grade.id,
      })),
      required: true,
      placeholder: 'เลือกระดับชั้น',
    },
    {
      options: subjects.map((subject) => ({
        text: subject.name,
        value: subject.id,
        key: subject.id,
      })),
      multiple: true,
      label: 'Subject',
      name: 'subjects',
      placeholder: 'เลือก Subject หากไม่ต้องทั้งหมด',
    },
    {
      options: courses.map((course) => ({
        text: course.courseName,
        value: course.courseCode,
        key: course.courseCode,
      })),
      multiple: true,
      label: 'Course',
      name: 'courses',
      placeholder: 'เลือก Course หากไม่ต้องทั้งหมด',
    },
  ];

  return fields;
};

const lazyQueryOptions = {
  fetchPolicy: 'network-only',
};

function CreateIndividualReportForm({ onCancelled, userProfile, ...props }) {
  // Always load schools
  const { data: schoolsData, loading: schoolsLoading } = useQuery(getAllSchools);

  // Lazy Queries
  const [loadSchoolTerms, { data: schoolTermsData, loading: schoolTermsLoading }] = useLazyQuery(
    getSchoolTerms,
    lazyQueryOptions
  );
  const [loadSchoolGrades, { data: schoolGradesData, loading: schoolGradesLoading }] = useLazyQuery(
    searchGrades,
    lazyQueryOptions
  );
  const [loadSubjects, { data: subjectsData, loading: subjectsLoading }] = useLazyQuery(
    searchSubjects,
    lazyQueryOptions
  );
  const [loadCourses, { data: coursesData, loading: coursesLoading }] = useLazyQuery(
    searchCourses,
    lazyQueryOptions
  );

  // { school, schoolTerm, schoolGrade, subjects, courses}
  const [selection, setSelection] = useState({ subjects: [], courses: [] });

  const extractedData = {
    schools: schoolsData?.boAllSchools,
    schoolTerms: schoolTermsData?.boSchoolTerms?.schoolTerms,
    schoolGrades: schoolGradesData?.boSearchGrades?.grades,
    subjects: subjectsData?.boSyllabusSubjects?.subjects,
    courses: coursesData?.boCourses?.courses,
  };

  useEffect(() => {
    if (!selection.school) {
      return;
    }

    if (!selection.schoolTerm && selection.school) {
      loadSchoolTerms({
        variables: {
          search: {
            schoolId: selection.school,
          },
          paging: {
            currentPage: 0,
            pageSize: 100,
          },
        },
      });
      return;
    }

    if (!selection.schoolGrade && selection.schoolTerm) {
      loadSchoolGrades({
        variables: {
          search: {
            schoolTermId: selection.schoolTerm,
          },
          paging: {
            currentPage: 0,
            pageSize: 100,
          },
        },
      });
      return;
    }

    if (!selection.subjects.length > 0 && selection.schoolGrade) {
      loadSubjects({
        variables: {
          search: {
            schoolId: selection.school,
            schoolTermId: selection.schoolTerm,
            schoolGradeId: selection.schoolGrade,
          },
          paging: {
            currentPage: 0,
            pageSize: 100,
          },
        },
      });
      return;
    }

    if (!selection.courses.length > 0 && selection.subjects.length > 0) {
      loadCourses({
        variables: {
          search: {
            schoolId: selection.school,
            schoolTermId: selection.schoolTerm,
            schoolGradeId: selection.schoolGrade,
            subjectId: selection.subjects,
          },
          paging: {
            currentPage: 0,
            pageSize: 100,
          },
        },
      });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const handleSubmit = async () => {
    const { courses, subjects, school, schoolTerm, schoolGrade } = selection;
    let allCourses = [];

    if (courses?.length) {
      allCourses = extractedData.courses.filter((course) => courses.includes(course.courseCode));
    } else if (!subjects.length) {
      const { data } = await apolloClient.query({
        query: searchCourses,
        variables: {
          search: {
            schoolId: school,
            schoolTermId: schoolTerm,
            schoolGradeId: schoolGrade,
            subjectId: extractedData.subjects.map((subject) => subject.id),
          },
          paging: {
            currentPage: 0,
            pageSize: 100,
          },
        },
      });
      allCourses = data.boCourses.courses ?? [];
    } else if (!courses.length) {
      allCourses = extractedData.courses;
    }

    const findAndGetKey = (array, id, key) => array.find((item) => item.id === id)?.[key];

    const schoolName = findAndGetKey(extractedData.schools, school, 'name');
    const schoolPrefix = findAndGetKey(extractedData.schools, school, 'code');

    const reportInfo = {
      schoolPrefix,
      yearTerm: findAndGetKey(extractedData.schoolTerms, schoolTerm, 'shortName'),
      level: findAndGetKey(extractedData.schoolGrades, schoolGrade, 'code'),
      subject:
        subjects.length > 0
          ? extractedData.subjects
              .filter((subject) => subjects.includes(subject.id))
              ?.map((subject) => subject.code)
          : extractedData.subjects?.map((subject) => subject.code),
      courseCode: allCourses?.map((course) => course.courseCode),
      details: {
        school: schoolName,
        term: findAndGetKey(extractedData.schoolTerms, schoolTerm, 'name'),
        level: findAndGetKey(extractedData.schoolGrades, schoolGrade, 'name'),
        subject:
          subjects.length > 0
            ? extractedData.subjects
                .filter((subject) => subjects.includes(subject.id))
                ?.map((subject) => subject.name)
            : extractedData.subjects?.map((subject) => subject.name),
        course: allCourses?.map((course) => course.courseName),
      },
    };

    const name = `${userProfile.firstname}.${userProfile.lastname.substring(0, 3)}`;

    const boFileImportInput = {
      arg1: JSON.stringify(reportInfo),
      arg2: name,
      arg3: `${schoolPrefix}: ${schoolName}`,
    };

    props.onCreateNewData(boFileImportInput);
  };

  const handleChange = (fieldName, value) => {
    if (fieldName === 'school' && selection.school && value !== selection.school) {
      setSelection({
        school: value,
        schoolTerm: null,
        schoolGrade: null,
        subjects: [],
      });
      return;
    }

    if (fieldName === 'schoolTerm' && selection.schoolTerm && value !== selection.schoolTerm) {
      setSelection({
        ...selection,
        schoolTerm: value,
        schoolGrade: null,
        subjects: [],
      });
      return;
    }

    if (fieldName === 'schoolGrade' && selection.schoolGrade && value !== selection.schoolGrade) {
      setSelection({
        ...selection,
        schoolGrade: value,
        subjects: [],
      });
      return;
    }

    if (fieldName === 'subjects' && value.length !== selection.subjects.length) {
      setSelection({
        ...selection,
        subjects: value,
        courses: [],
      });
      return;
    }

    // Default
    setSelection({ ...selection, [fieldName]: value });
  };

  const isInCompleted = !selection.school || !selection.schoolTerm || !selection.schoolGrade;

  const isLoading =
    schoolsLoading ||
    schoolTermsLoading ||
    schoolGradesLoading ||
    subjectsLoading ||
    coursesLoading;

  return (
    <Flex flexDirection="column" ml="auto" maxWidth="85%" style={{ gap: '2rem' }}>
      {getFields(extractedData).map((field) => {
        const { name, label, options, placeholder, required, ...restFields } = field;
        return (
          <Flex key={name} sx={{ alignItems: 'center' }}>
            <Label required={required}>{label}</Label>
            <Dropdown
              fluid
              selection
              placeholder={placeholder}
              value={selection[name]}
              options={options}
              onChange={(_, { value }) => handleChange(name, value)}
              style={{ width: '80%', marginLeft: 'auto' }}
              {...restFields}
            />
          </Flex>
        );
      })}

      <Flex width={1} justifyContent="flex-end">
        <Button
          primary
          circular
          title="Create"
          loading={isLoading}
          disabled={isLoading || isInCompleted}
          onClick={handleSubmit}
          data-testid="create-individual-report-submit"
        />
        <Button
          circular
          title="Cancel"
          onClick={onCancelled}
          data-testid="create-individual-report-cancel"
          style={{
            marginLeft: '0.5rem',
          }}
        />
      </Flex>
    </Flex>
  );
}

CreateIndividualReportForm.propTypes = {
  onCreateNewData: PropTypes.func,
  onCancelled: PropTypes.func,
  userProfile: PropTypes.object,
};

export default compose(
  connect(
    (state) => ({
      userProfile: myProfile(state),
    }),
    {}
  )
)(CreateIndividualReportForm);
