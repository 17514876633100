import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getWorkshop } from '../../GraphQL/query/Products.query';
import { updateWorkshopInstructorsMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUInstructorForm from '../SKUInstructorForm';

const getInitData = (data) => {
  if (data && data.sfBoProductWorkshop && data.sfBoProductWorkshop.instructors) {
    return {
      instructorIds: data.sfBoProductWorkshop.instructors.map((instructor) => instructor.id),
    };
  }
  return [];
};

class WorkshopInstructorsEditor extends Component {
  static propTypes = {
    id: PropTypes.string,
    skuCode: PropTypes.string,
  };

  render() {
    const { id, skuCode } = this.props;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getWorkshop}
          editMutation={updateWorkshopInstructorsMutation}
          isEdit={true}
          id={skuCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode: skuCode,
          })}
          getEditVariables={(form) => ({
            id,
            instructorIds: form.instructorIds,
          })}
        >
          {({ data, onSubmit }) => {
            return <SKUInstructorForm initialData={getInitData(data)} onSubmit={onSubmit} />;
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default WorkshopInstructorsEditor;
