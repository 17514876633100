import React from 'react';
import PropTypes from 'prop-types';
import { Header, Grid } from 'semantic-ui-react';

import CourseImageUpload from './CourseImageUpload.gql';

const CourseHeader = ({ courseId, product, version, title, subTitle, coverImageUrl, editComp }) => {
  return (
    <Grid>
      <Grid.Column width={6}>
        <CourseImageUpload
          courseId={courseId}
          product={product}
          version={version}
          field={'coverImage'}
          fileImageUrl={coverImageUrl && coverImageUrl !== '' ? coverImageUrl : undefined}
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Grid>
          <Grid.Row>
            <Header size="huge">{title}</Header>
          </Grid.Row>
          <Grid.Row>
            <Header size="large">{subTitle}</Header>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={2}>{editComp}</Grid.Column>
    </Grid>
  );
};

CourseHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.string,
  coverImageUrl: PropTypes.string,
};

export default CourseHeader;
