import React from 'react';

import CreateEditForm from 'Containers/CreateEditForm';
import { sfUpdateWorkshopBatch, sfCreateWorkshopBatch } from 'GraphQL/mutation/Workshop.mutation';
import { getWorkshopBatches, resolveStatus, getStatusValue } from 'GraphQL/query/Workshop.query';
import delve from 'Util/Delve';

import WorkshopBatchForm from './WorkshopBatchForm';

const getBatchSkuFromCollectionQuery = (data, batchSkuCode) => {
  const batches = delve(data, 'sfWorkshopBySKUCode.batches');
  if (!batches) return undefined;

  const { SKUStatus, isVisible, ...batch } = batches.find((b) => b.SKUCode === batchSkuCode);
  return { status: resolveStatus(SKUStatus, isVisible), ...batch };
};

const CreateEditBatchForm = ({
  onComplete,
  onCancelled,
  batchSkuCode,
  collectionSkuCode,
  isEdit,
}) => {
  return (
    <CreateEditForm
      // TODO: Change to single batch query
      dataQuery={getWorkshopBatches}
      createMutation={sfCreateWorkshopBatch}
      createRefetchQueries={[
        {
          query: getWorkshopBatches,
          variables: {
            SKUCode: collectionSkuCode,
          },
        },
      ]}
      editRefetchQueries={[
        {
          query: getWorkshopBatches,
          variables: {
            SKUCode: collectionSkuCode,
          },
        },
      ]}
      editMutation={sfUpdateWorkshopBatch}
      isEdit={isEdit}
      id={batchSkuCode}
      getQueryVariables={({ id }) => ({
        // TODO: Change to single batch query
        SKUCode: collectionSkuCode,
      })}
      getEditVariables={(form, { id }) => ({
        SKUCode: id,
        data: form,
      })}
      getCreateVariables={(form) => ({
        SKUCode: collectionSkuCode,
        data: form,
      })}
      getEditMutationResponse={(mutateData) => delve(mutateData, 'sfUpdateWorkshopBatch')}
      getCreateMutationResponse={(mutateData) => delve(mutateData, 'sfCreateWorkshopBatch')}
      onCompleted={onComplete}
      onCancelled={onCancelled}
      checkMutationResponse
    >
      {({ data, onSubmit }) => {
        // TODO: Change to single batch query
        const initialData = getBatchSkuFromCollectionQuery(data, batchSkuCode);
        const handleOnSubmit = ({ status, ...formValue }) => {
          onSubmit({ ...getStatusValue(status), ...formValue });
        };
        return <WorkshopBatchForm initialData={initialData} onSubmit={handleOnSubmit} />;
      }}
    </CreateEditForm>
  );
};

export default CreateEditBatchForm;
