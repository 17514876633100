import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import PriceInput from '../SKUPriceInput';
import Editor from '../MarkupTextInput';

const POSITIVE_NUMBER_VALIDATION = /^[0-9]+$|^$/;

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'ราคาตั้งต้น (Price incl. VAT) ',
      inputName: 'priceInclVAT',
      customInput: ({ name }) => <PriceInput name={name} />,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'ราคาขายจริง (SalePrice incl. VAT)',
      inputName: 'salePriceInclVAT',
      customInput: ({ name }) => <PriceInput name={name} />,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Promotion Text',
      inputName: 'promotionText',
      mode: MODE.EDIT,
      customInput: ({ name }) => <Editor name={name} />,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class SKUPricingForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  handleSubmit = (data) => {
    const { onSubmit } = this.props;
    const { priceInclVAT, salePriceInclVAT, promotionText, ...restData } = data;

    let passingData = {
      ...restData,
    };
    if (salePriceInclVAT) {
      const { value, unit } = salePriceInclVAT;
      if (value !== undefined && unit !== undefined) {
        passingData.salePriceInclVAT = { value, unit };
      }
    }
    if (priceInclVAT) {
      const { value, unit } = priceInclVAT;
      if (value !== undefined && unit !== undefined) {
        passingData.priceInclVAT = { value, unit };
      }
    }
    if (promotionText) {
      const { content } = promotionText;
      if (content !== undefined) {
        passingData.promotionText = { content };
      }
    }
    onSubmit(passingData);
  };

  validate = ({ priceInclVAT, salePriceInclVAT }, isChanged) => {
    const error = {};

    if (salePriceInclVAT && priceInclVAT && salePriceInclVAT.value > priceInclVAT.value) {
      error.salePriceInclVAT = 'ราคาขายจริง "ไม่ควรมากกว่า" ราคาตั้งต้น';
    }

    if (isChanged) {
      if (priceInclVAT && !POSITIVE_NUMBER_VALIDATION.test(priceInclVAT.value)) {
        error.priceInclVAT = 'ตัวเลขที่ถูกป้อนต้องเป็นจำนวนบวก';
      }
      if (salePriceInclVAT && !POSITIVE_NUMBER_VALIDATION.test(salePriceInclVAT.value)) {
        error.salePriceInclVAT = 'ตัวเลขที่ถูกป้อนต้องเป็นจำนวนบวก';
      }
    }

    return error;
  };

  render() {
    const { isEdit, onSubmit, disabledFields = [] } = this.props;
    const fields = getFields(isEdit).filter((f) => !disabledFields.includes(f.inputName));
    return (
      <FormGenerator
        fields={fields}
        onSubmit={this.handleSubmit}
        customValidation={this.validate}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}

export default SKUPricingForm;
