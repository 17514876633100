import gql from 'graphql-tag';

export const getPurchaseLogs = gql`
  query getPurchaseLogs($search: PurchaseLogSearchInput, $paging: PageInput, $order: OrderInput) {
    purchaseLogs(search: $search, paging: $paging, order: $order) {
      purchaseLogs {
        id
        createdAt
        userId
        userName
        courseReferId
        fullPrice
        promotionPrice
        paymentMethod
        amount
        taxAmount
        paidAmount
        discountCode
        discountAmount
        invoiceId
        poId
        omiseFee
        omiseId
        serial
        runningNumber
      }
      total
    }
  }
`;

export const purchaseOrders = gql`
  query purchaseOrders(
    $status: QUERY_PO_STATUS
    $search: PurchaseOrderSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    purchaseOrders(status: $status, search: $search, paging: $paging, order: $order) {
      total
      purchaseOrders {
        id
        fullname
        updatedAt
        email
        mobile
        status
        fullPrices
        amount
        withCode
        paidAmount
        feeAmount
        paymentMethod
        referCode
        accountingNumber
        discountWithoutHoldingAmount
        courseReferId
        courseTitle
        customerType
        purchaseItems {
          id
          detail
          purchaseId
          courseReferId
          amount
          discountedAmount
          quantity
        }
        histories {
          id
          purchaseId
          status
          createdAt
          detail {
            uploadedLink
            bank
            dateTime
          }
          changedBy
        }
      }
    }
  }
`;

export const getPurchaseOrderLogs = gql`
  query purchaseOrderLogs(
    $search: PurchaseOrderLogSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    purchaseOrderLogs(search: $search, paging: $paging, order: $order) {
      total
      purchaseOrderLogs {
        id
        purchaseId
        status
        createdAt
        detail {
          uploadedLink
          dateTime
          bank
        }
        changedBy
      }
    }
  }
`;

export const getTransactionLogs = gql`
  query transactions($search: TransactionSearchInput, $paging: PageInput, $order: OrderInput) {
    transactions(search: $search, paging: $paging, order: $order) {
      total
      transactions {
        id
        createdAt
        userId
        credit
        debit
      }
    }
  }
`;
