import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import * as moment from 'moment-timezone';
import { Input } from 'semantic-ui-react';

import { Flex, Text, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

import { SCHOOL_TIMEZONE } from '../../../Domains/schoolTimeZone';

const DateInput = forwardRef(({ value, ...restProps }, ref) => {
  return <Input value={value} {...restProps} readOnly ref={ref} />;
});
class DateRangePickerInput extends React.Component {
  state = {
    startDate: this.props.value.startDate,
    endDate: this.props.value.endDate,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    errorMessages: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        startDate: PropTypes.any,
        endDate: PropTypes.any,
      }),
      PropTypes.string,
    ]),
  };

  handleChange = (name, date) => {
    const rawTime = moment(date).utc().format('HH:mm');
    const timeZone = new Date().getTimezoneOffset();
    if (rawTime !== '00:00') {
      date.subtract(timeZone, 'minutes');
    }
    this.setState({ [name]: date.toJSON() }, () => {
      this.props.onChange({}, { value: this.state });
    });
  };

  render() {
    const { startDate, endDate } = this.state;
    const { name, value, errorMessages, ...inputProps } = this.props;
    const mStartDate = startDate ? moment(startDate).tz(SCHOOL_TIMEZONE) : null;
    const mEndDate = endDate ? moment(endDate).tz(SCHOOL_TIMEZONE) : null;
    return (
      <Flex alignItems="center">
        <DatePicker
          name={`${name}.startDate`}
          selected={mStartDate}
          startDate={mStartDate}
          maxDate={mEndDate}
          placeholderText="Start Date"
          selectsStart
          dateFormat="DD/MM/YYYY"
          {...inputProps}
          customInput={<DateInput />}
          onChange={(date) => this.handleChange('startDate', date)}
        />
        <Text px={2}>-</Text>
        <DatePicker
          name={`${name}.endDate`}
          endDate={mEndDate}
          selected={mEndDate}
          minDate={mStartDate}
          placeholderText="End Date"
          selectsEnd
          dateFormat="DD/MM/YYYY"
          {...inputProps}
          customInput={<DateInput />}
          onChange={(date) => this.handleChange('endDate', date)}
        />
      </Flex>
    );
  }
}
export default withFormsy(DateRangePickerInput);
