// Ref: https://github.com/carbon-design-system/carbon-components-react/blob/master/src/components/UIShell/SideNav.js
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Box } from 'Components/Base';

const BaseSideNav = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  ${({ expanded, sideNavWidth, transitionDuration }) =>
    expanded
      ? css`
          width: ${sideNavWidth};
          transition: ${transitionDuration}ms;
          transform: translateX(0%);
        `
      : css`
          width: ${sideNavWidth};
          transition: ${transitionDuration}ms;
          transform: translateX(-100%);
        `};
`;

/**
 * SideNav Component
 * @param {string} width = Size of side navigation bar
 * @param {boolean} defaultExpanded,- initial state of side navigation (default is true)
 * @param {number} transitionDuration - time of transition animation and unit of duration is milliseconds (default is 500ms)
 */
const SideNav = ({ width, defaultExpanded, transitionDuration, children, ...props }) => (
  <BaseSideNav
    expanded={defaultExpanded}
    bg={'white'}
    height={'100vh'}
    sideNavWidth={width}
    transitionDuration={transitionDuration}
    {...props}
  >
    {children}
  </BaseSideNav>
);

SideNav.propTypes = {
  width: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  transitionDuration: PropTypes.number,
};

SideNav.defaultProps = {
  width: '256px',
  defaultExpanded: true,
  transitionDuration: 500,
};

export default SideNav;
