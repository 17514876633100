import React, { Component } from 'react';
import { Dropdown } from 'formsy-semantic-ui-react';

import { getAllTags, TAG_TYPE } from 'GraphQL/query/Tag.query';
import QueryComponent from 'GraphQL/util/QueryComponent';

const optionsConverter = (tag) => ({
  id: tag.id,
  value: tag.id,
  text: tag.slug ? `${tag.name} (${tag.slug})` : `${tag.name}`,
});

const parseValue = ({ tags = [] }) => {
  return tags.map(optionsConverter);
};

export default class CourseSelectorInputQuery extends Component {
  static propTypes = {};

  render() {
    return (
      <QueryComponent query={getAllTags} variables={{ type: TAG_TYPE.INSTRUCTOR }}>
        {(data) => (
          <Dropdown
            placeholder="Select Values"
            search
            selection
            multiple
            fluid
            allowAdditions
            options={parseValue(data)}
            {...this.props}
          />
        )}
      </QueryComponent>
    );
  }
}
