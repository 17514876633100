import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import QueryComponent from 'GraphQL/util/QueryComponent';
import { getCourseOutline } from 'GraphQL/query/Courses.query';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';
import {
  deleteSection,
  deleteSubSection,
  updateSection,
  updateSubSection,
  reorderCourseItems,
  deleteCourseItems,
} from 'GraphQL/mutation/Courses.mutation';

import Outline from './Outline';

class CourseOutline extends Component {
  static propTypes = {
    product: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    courseId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    onLoading: PropTypes.func,
    deleteSection: PropTypes.func,
    deleteSubSection: PropTypes.func,
    updateSection: PropTypes.func,
    updateSubSection: PropTypes.func,
    videoManagementButton: PropTypes.func,
  };

  static defaultProps = {
    onLoading: () => {},
    deleteSection: () => {},
    deleteSubSection: () => {},
    updateSection: () => {},
    updateSubSection: () => {},
    videoManagementButton: () => {},
  };

  handleDeleteSection = (sectionData, subsections) => {
    const { product, version, courseId } = this.props;
    const productVersion = productCourseVersionResolver(product, version);
    const courseItems = subsections.map((subsection) => ({
      id: subsection.id,
      title: subsection.title,
    }));
    courseItems.push(sectionData);
    this.props.deleteCourseItems({
      variables: { courseId, version: productVersion, courseItems },
      refetchQueries: [
        {
          query: getCourseOutline,
          variables: { version: productVersion, courseId },
        },
      ],
    });
  };

  handleDeleteSubSection = (id) => {
    const { product, version, courseId } = this.props;
    const productVersion = productCourseVersionResolver(product, version);
    // TODO: Add Loading State
    this.props.deleteSubSection({
      variables: { id, version: productVersion },
      refetchQueries: [
        {
          query: getCourseOutline,
          variables: { version: productVersion, courseId },
        },
      ],
    });
  };

  handleUpdateSectionMeta = (id, data) => {
    const { product, version, courseId, onLoading } = this.props;
    const productVersion = productCourseVersionResolver(product, version);
    // TODO: Add Loading State
    this.props
      .updateSection({
        variables: { id, version: productVersion, sectionData: data },
        refetchQueries: [
          {
            query: getCourseOutline,
            variables: { version: productVersion, courseId },
          },
        ],
      })
      .then(() => onLoading(false));
  };
  handleReorderCourseItems = (courseItems) => {
    const { product, version, courseId, onLoading } = this.props;
    const productVersion = productCourseVersionResolver(product, version);
    this.props
      .reorderCourseItems({
        variables: { courseId, version: productVersion, courseItems },
        refetchQueries: [
          {
            query: getCourseOutline,
            variables: { version: productVersion, courseId },
          },
        ],
      })
      .then(() => onLoading(false));
  };
  handleUpdateSubSectionMeta = (id, data) => {
    const { product, version, courseId, onLoading } = this.props;
    const productVersion = productCourseVersionResolver(product, version);
    // TODO: Add Loading State
    this.props
      .updateSubSection({
        variables: { id, version: productVersion, subsectionData: data },
        refetchQueries: [
          {
            query: getCourseOutline,
            variables: { version: productVersion, courseId },
          },
        ],
      })
      .then(() => onLoading(false));
  };

  render() {
    const { courseId, product, version, isLoading, onLoading, videoManagementButton } = this.props;
    return (
      <QueryComponent
        query={getCourseOutline}
        variables={{ version: productCourseVersionResolver(product, version), courseId }}
        loadingOverlay
      >
        {(data) => {
          return (
            <Outline
              courseId={courseId}
              contents={data.course.contents}
              product={product}
              version={version}
              isLoading={isLoading}
              onLoading={onLoading}
              onDeleteSection={this.handleDeleteSection}
              onDeleteSubSection={this.handleDeleteSubSection}
              onUpdateSectionMeta={this.handleUpdateSectionMeta}
              onReorderCourseItems={this.handleReorderCourseItems}
              onUpdateSubSectionMeta={this.handleUpdateSubSectionMeta}
              videoManagementButton={videoManagementButton}
            />
          );
        }}
      </QueryComponent>
    );
  }
}

export default compose(
  graphql(deleteSection, { name: 'deleteSection' }),
  graphql(deleteSubSection, { name: 'deleteSubSection' }),
  graphql(updateSection, { name: 'updateSection' }),
  graphql(updateSubSection, { name: 'updateSubSection' }),
  graphql(reorderCourseItems, { name: 'reorderCourseItems' }),
  graphql(deleteCourseItems, { name: 'deleteCourseItems' })
)(CourseOutline);
