import gql from 'graphql-tag';

export const InstructorSmallFragment = gql`
  fragment InstructorSmallFragment on SFBoProductInstructor {
    id
    name
    permalink
    status
    itemNo
    position
    expert
  }
`;

export const InstructorLargeFragment = gql`
  fragment InstructorLargeFragment on SFBoProductInstructor {
    ...InstructorSmallFragment
    description {
      type
      content
    }
    profileImage {
      key
      bucket
    }
    profileImageUrl
    expertDetails {
      type
      content
    }
    quote
    socialMedias {
      type
      value
    }
  }
  ${InstructorSmallFragment}
`;

export const OnlineCourseSmallFragment = gql`
  fragment OnlineCourseSmallFragment on SFBoProductOnlineCourse {
    id
    courseCode
    SKUCode
    title
    subTitle
    description
    SKUStatus
    publishedAt
    SKUType
    SKUCategory
    isVisible
    permalink
    itemNo
    duration
    backgroundColor
  }
`;

export const OnlineCourseLargeFragment = gql`
  fragment OnlineCourseLargeFragment on SFBoProductOnlineCourse {
    ...OnlineCourseSmallFragment
    category1Ids
    category2Ids
    category3Ids
    category4Ids
    cardImage {
      key
      bucket
    }
    cardImageUrl
    coverImage {
      key
      bucket
    }
    coverImageUrl
    instructors {
      ...InstructorSmallFragment
    }
    ratings {
      avg
    }
    details {
      content
      type
    }
    highlights {
      icon
      content
      label
    }
    salePriceInclVAT {
      value
      unit
    }
    priceInclVAT {
      value
      unit
    }
    promotionText {
      content
      type
    }
    promotionYoutubeId
    prescreenFormURL
    tags
    eventTime {
      startDate
      endDate
      startTime
      endTime
    }
    conditions {
      ... on SFProductConditionDaysValidity {
        type
        days
        watchTimeSeconds
      }
      ... on SFProductConditionExpiryDate {
        type
        expiryDate
        watchTimeSeconds
      }
    }
    FAQ {
      question
      answer {
        type
        content
      }
    }
    enableCreditPrice
    creditPrice
    comingSoonText {
      display
      launchDate
    }
  }
  ${OnlineCourseSmallFragment}
  ${InstructorSmallFragment}
`;

export const WorkshopSmallFragment = gql`
  fragment WorkshopSmallFragment on SFBoProductWorkshop {
    id
    SKUCode
    title
    SKUStatus
    publishedAt
    SKUType
    SKUCategory
    isVisible
    permalink
    itemNo
  }
`;

export const WorkshopLargeFragment = gql`
  fragment WorkshopLargeFragment on SFBoProductWorkshop {
    ...WorkshopSmallFragment
    subTitle
    description
    referSKUId
    category1Ids
    category2Ids
    category3Ids
    category4Ids
    cardImage {
      key
      bucket
    }
    # cardImageUrl
    coverImage {
      key
      bucket
    }
    # coverImageUrl
    instructors {
      ...InstructorSmallFragment
    }
  }
  ${WorkshopSmallFragment}
  ${InstructorSmallFragment}
`;

export const WorkshopBatchSmallFragment = gql`
  fragment WorkshopBatchSmallFragment on SFBoProductWorkshopBatch {
    id
    SKUCode
    title
    SKUStatus
    publishedAt
    SKUType
    SKUCategory
    isVisible
    permalink
    itemNo
  }
`;

export const WorkshopBatchLargeFragment = gql`
  fragment WorkshopBatchLargeFragment on SFBoProductWorkshopBatch {
    ...WorkshopBatchSmallFragment
    eventTime {
      startDate
      endDate
    }
    priceInclVAT_value
    priceInclVAT_unit
    salePriceInclVAT_value
    salePriceInclVAT_unit
    referId
    category1Ids
    category2Ids
    category3Ids
    category4Ids
    category1s {
      id
      title
    }
    category2s {
      id
      title
    }
    category3s {
      id
      title
    }
    category4s {
      id
      title
    }
  }
  ${WorkshopBatchSmallFragment}
`;

export const BundleSmallFragment = gql`
  fragment BundleSmallFragment on SFBoProductBundle {
    id
    SKUCode
    title
    subTitle
    SKUStatus
    publishedAt
    isVisible
    permalink
    itemNo
    duration
    backgroundColor
  }
`;

export const BundleLargeFragment = gql`
  fragment BundleLargeFragment on SFBoProductBundle {
    ...BundleSmallFragment
    category1Ids
    category2Ids
    category3Ids
    category4Ids
    stock
    originalStock
    availableStock
    cardImage {
      key
      bucket
    }
    cardImageUrl
    coverImage {
      key
      bucket
    }
    coverImageUrl
    instructors {
      ...InstructorSmallFragment
    }
    ratings {
      avg
    }
    details {
      content
      type
    }
    highlights {
      icon
      content
      label
    }
    salePriceInclVAT {
      value
      unit
    }
    priceInclVAT {
      value
      unit
    }
    promotionText {
      content
      type
    }
    tableOfContents {
      title
      description {
        type
        content
      }
    }
  }
  ${BundleSmallFragment}
  ${InstructorSmallFragment}
`;

export const SubscriptionSmallFragment = gql`
  fragment SubscriptionSmallFragment on SFBoProductSubscription {
    id
    SKUCode
    title
    SKUStatus
    publishedAt
    isVisible
    permalink
    itemNo
  }
`;

export const SubscriptionLargeFragment = gql`
  fragment SubscriptionLargeFragment on SFBoProductSubscription {
    ...SubscriptionSmallFragment
    category1Ids
    category2Ids
    category3Ids
    category4Ids
    priceInclVAT {
      value
      unit
    }
    salePriceInclVAT {
      value
      unit
    }
    promotionText {
      type
      content
    }
    conditions {
      ... on SFConditionDaysValidity {
        type
        days
        gracefulOffset
      }
      ... on SFConditionExpiryDate {
        type
        expiryDate
      }
    }
    platform
    externalId
    redemption {
      enableCredit
      creditAmount
    }
    # cardImage {
    #   key
    #   bucket
    # }
    # cardImageUrl
    # coverImage {
    #   key
    #   bucket
    # }
    # coverImageUrl
  }
  ${SubscriptionSmallFragment}
`;

export const CollectionSmallFragment = gql`
  fragment CollectionSmallFragment on SFBoProductCollection {
    id
    SKUCode
    title
    SKUStatus
    isVisible
    permalink
    itemNo
    SKUCategory
  }
`;

export const CollectionLargeFragment = gql`
  fragment CollectionLargeFragment on SFBoProductCollection {
    ...CollectionSmallFragment
    # category1Ids
    # category2Ids
    # category3Ids
    # category4Ids
  }
  ${CollectionSmallFragment}
`;

export const BookSmallFragment = gql`
  fragment BookSmallFragment on SFBoProductBook {
    id
    SKUCode
    title
    SKUStatus
    publishedAt
    isVisible
    permalink
    itemNo
  }
`;

export const BookLargeFragment = gql`
  fragment BookLargeFragment on SFBoProductBook {
    ...BookSmallFragment
    originalStock
    stock
    category1Ids
    category2Ids
    category3Ids
    category4Ids
    details {
      type
      content
    }
    priceInclVAT {
      value
      unit
    }
    salePriceInclVAT {
      value
      unit
    }
    promotionText {
      type
      content
    }
    coverImage {
      key
      bucket
      assetUrl
    }
    cardImage {
      key
      bucket
      assetUrl
    }
  }
  ${BookSmallFragment}
`;

export const CategorySmallFragment = gql`
  fragment CategorySmallFragment on SFBoProductCategory {
    id
    title
    permalink
    status
    itemNo
  }
`;

export const CategoryLargeFragment = gql`
  fragment CategoryLargeFragment on SFBoProductCategory {
    ...CategorySmallFragment
    SKUCategory
    parentId
    icon {
      key
      bucket
    }
    iconUrl
  }
  ${CategorySmallFragment}
`;

export const CollectionOnlineCourseSmallFragment = gql`
  fragment CollectionOnlineCourseSmallFragment on SFBoProductCollectionOnlineCourse {
    id
    courseCode
    SKUCode
    title
    subTitle
    description
    SKUType
    SKUStatus
    publishedAt
    SKUCategory
    itemNo
    isVisible
    backgroundColor
    duration
  }
`;

export const CollectionOnlineCourseLargeFragment = gql`
  fragment CollectionOnlineCourseLargeFragment on SFBoProductCollectionOnlineCourse {
    ...CollectionOnlineCourseSmallFragment
    permalink
    category1Ids
    category2Ids
    category3Ids
    category4Ids
    cardImage {
      key
      bucket
    }
    coverImage {
      key
      bucket
    }
    cardImageUrl
    coverImageUrl
    instructors {
      ...InstructorSmallFragment
    }
    ratings {
      avg
    }
    details {
      content
      type
    }
    highlights {
      icon
      content
      label
    }
    salePriceInclVAT {
      value
      unit
    }
    priceInclVAT {
      value
      unit
    }
    promotionText {
      content
      type
    }
    promotionYoutubeId
    tags
    conditions {
      ... on SFProductConditionDaysValidity {
        type
        days
        watchTimeSeconds
      }
      ... on SFProductConditionExpiryDate {
        type
        expiryDate
        watchTimeSeconds
      }
    }
    onlineCourses {
      ...OnlineCourseLargeFragment
    }
    tableOfContents {
      title
      description {
        type
        content
      }
    }
    FAQ {
      question
      answer {
        type
        content
      }
    }
  }
  ${CollectionOnlineCourseSmallFragment}
  ${InstructorSmallFragment}
  ${OnlineCourseLargeFragment}
`;

export const getOnlineCourses = gql`
  query sfBoProductOnlineCourses(
    $search: SFBoProductSearchOnlineCoursesInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductOnlineCourses(search: $search, paging: $paging, order: $order) {
      total
      onlineCourses {
        ...OnlineCourseSmallFragment
        category1s {
          id
          title
        }
        category2s {
          id
          title
        }
        category3s {
          id
          title
        }
        category4s {
          id
          title
        }
      }
    }
  }
  ${OnlineCourseSmallFragment}
`;

export const getOnlineCoursesWithCategories = gql`
  query sfBoProductOnlineCourses(
    $search: SFBoProductSearchOnlineCoursesInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductOnlineCourses(search: $search, paging: $paging, order: $order) {
      total
      onlineCourses {
        ...OnlineCourseLargeFragment
        category1s {
          id
          title
        }
        category2s {
          id
          title
        }
        category3s {
          id
          title
        }
        category4s {
          id
          title
        }
      }
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const getOnlineCourse = gql`
  query sfBoProductOnlineCourse($SKUCode: String!) {
    sfBoProductOnlineCourse(SKUCode: $SKUCode) {
      ...OnlineCourseLargeFragment
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const getOnlineCourseWithTableOfContents = gql`
  query sfBoProductOnlineCourse($SKUCode: String!) {
    sfBoProductOnlineCourse(SKUCode: $SKUCode) {
      ...OnlineCourseSmallFragment
      formattedTableOfContent {
        sectionGroups {
          referId
          title
          sections {
            referId
            title
            subSections {
              referId
              title
            }
          }
        }
      }
      tableOfContentMeta {
        courseItemId
        type
        preview
        previewVideo {
          url
          time
        }
        releasedAt
      }
    }
  }
  ${OnlineCourseSmallFragment}
`;

export const getWorkshops = gql`
  query sfBoProductWorkshops(
    $search: SFBoProductSearchWorkshopsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductWorkshops(search: $search, paging: $paging, order: $order) {
      total
      workshops {
        ...WorkshopSmallFragment
        category1s {
          id
          title
        }
        category2s {
          id
          title
        }
        category3s {
          id
          title
        }
        category4s {
          id
          title
        }
      }
    }
  }
  ${WorkshopSmallFragment}
`;

export const getWorkshop = gql`
  query sfBoProductWorkshop($SKUCode: String!) {
    sfBoProductWorkshop(SKUCode: $SKUCode) {
      ...WorkshopLargeFragment
    }
  }
  ${WorkshopLargeFragment}
`;

export const getWorkshopBatches = gql`
  query sfBoProductWorkshopBatches(
    $search: SFBoProductWorkshopBatchesInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductWorkshopBatches(search: $search, paging: $paging, order: $order) {
      total
      batches {
        ...WorkshopBatchLargeFragment
      }
    }
  }
  ${WorkshopBatchLargeFragment}
`;

export const getBundles = gql`
  query sfBoProductBundles(
    $search: SFBoProductSearchBundlesInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductBundles(search: $search, paging: $paging, order: $order) {
      total
      bundles {
        ...BundleSmallFragment
        category1s {
          id
          title
        }
        category2s {
          id
          title
        }
        category3s {
          id
          title
        }
        category4s {
          id
          title
        }
      }
    }
  }
  ${BundleSmallFragment}
`;

export const getBundle = gql`
  query sfBoProductBundle($SKUCode: String!) {
    sfBoProductBundle(SKUCode: $SKUCode) {
      ...BundleLargeFragment
    }
  }
  ${BundleLargeFragment}
`;

export const getBundleGallery = gql`
  query sfBoProductBundle($SKUCode: String!) {
    sfBoProductBundle(SKUCode: $SKUCode) {
      ...BundleSmallFragment
      bundleImages {
        images {
          bucket
          key
          assetUrl
        }
      }
    }
  }
  ${BundleSmallFragment}
`;

export const getBundleItems = gql`
  query sfBoProductBundleItems(
    $search: SFBoProductSearchBundleItemsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductBundleItems(search: $search, paging: $paging, order: $order) {
      total
      items {
        ... on SFBoProductOnlineCourse {
          ...OnlineCourseSmallFragment
          category1s {
            id
            title
          }
          category2s {
            id
            title
          }
          category3s {
            id
            title
          }
          category4s {
            id
            title
          }
        }
        ... on SFBoProductWorkshopBatch {
          ...WorkshopBatchSmallFragment
          category1s {
            id
            title
          }
          category2s {
            id
            title
          }
          category3s {
            id
            title
          }
          category4s {
            id
            title
          }
        }
        ... on SFBoProductSubscription {
          ...SubscriptionSmallFragment
          category1s {
            id
            title
          }
          category2s {
            id
            title
          }
          category3s {
            id
            title
          }
          category4s {
            id
            title
          }
        }
        ... on SFBoProductBook {
          ...BookSmallFragment
          category1s {
            id
            title
          }
          category2s {
            id
            title
          }
          category3s {
            id
            title
          }
          category4s {
            id
            title
          }
        }
      }
    }
  }
  ${OnlineCourseSmallFragment}
  ${WorkshopBatchSmallFragment}
  ${SubscriptionSmallFragment}
  ${BookSmallFragment}
`;

export const getSubscriptions = gql`
  query sfBoProductSubscriptions(
    $search: SFBoProductSearchSubscriptionsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductSubscriptions(search: $search, paging: $paging, order: $order) {
      total
      subscriptions {
        ...SubscriptionSmallFragment
        category1s {
          id
          title
        }
        category2s {
          id
          title
        }
        category3s {
          id
          title
        }
        category4s {
          id
          title
        }
      }
    }
  }
  ${SubscriptionSmallFragment}
`;

export const getListSubscriptions = gql`
  query sfBoProductSubscriptions(
    $search: SFBoProductSearchSubscriptionsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductSubscriptions(search: $search, paging: $paging, order: $order) {
      subscriptions {
        ...SubscriptionSmallFragment
      }
    }
  }
  ${SubscriptionSmallFragment}
`;

export const getSubscription = gql`
  query sfBoProductSubscription($SKUCode: String!) {
    sfBoProductSubscription(SKUCode: $SKUCode) {
      ...SubscriptionLargeFragment
    }
  }
  ${SubscriptionLargeFragment}
`;
export const getSubscriptionItems = gql`
  query sfBoProductSubscriptionItems(
    $search: SFBoProductSearchSubscriptionItemsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductSubscriptionItems(search: $search, paging: $paging, order: $order) {
      total
      items {
        ... on SFBoProductCollection {
          ...CollectionSmallFragment
        }
      }
    }
  }
  ${CollectionSmallFragment}
`;

export const getCollections = gql`
  query sfBoProductCollections(
    $search: SFBoProductSearchCollectionsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductCollections(search: $search, paging: $paging, order: $order) {
      total
      collections {
        ...CollectionSmallFragment
      }
    }
  }
  ${CollectionSmallFragment}
`;

export const getCollection = gql`
  query sfBoProductCollection($SKUCode: String!) {
    sfBoProductCollection(SKUCode: $SKUCode) {
      ...CollectionLargeFragment
    }
  }
  ${CollectionLargeFragment}
`;

export const getCollectionItems = gql`
  query sfBoProductCollectionItems(
    $search: SFBoProductSearchCollectionItemsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductCollectionItems(search: $search, paging: $paging, order: $order) {
      total
      items {
        ... on SFBoProductOnlineCourse {
          ...OnlineCourseLargeFragment
          category1s {
            id
            title
          }
          category2s {
            id
            title
          }
          category3s {
            id
            title
          }
          category4s {
            id
            title
          }
        }
      }
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const getBooksWithCategories = gql`
  query sfBoProductBooks(
    $search: SFBoProductSearchBooksInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductBooks(search: $search, paging: $paging, order: $order) {
      total
      books {
        ...BookSmallFragment
        category1s {
          id
          title
        }
        category2s {
          id
          title
        }
        category3s {
          id
          title
        }
        category4s {
          id
          title
        }
      }
    }
  }
  ${BookSmallFragment}
`;

export const getBooks = gql`
  query sfBoProductBooks(
    $search: SFBoProductSearchBooksInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductBooks(search: $search, paging: $paging, order: $order) {
      total
      books {
        ...BookSmallFragment
        category1s {
          id
          title
        }
        category2s {
          id
          title
        }
        category3s {
          id
          title
        }
        category4s {
          id
          title
        }
      }
    }
  }
  ${BookSmallFragment}
`;

export const getBook = gql`
  query sfBoProductBook($SKUCode: String!) {
    sfBoProductBook(SKUCode: $SKUCode) {
      ...BookLargeFragment
    }
  }
  ${BookLargeFragment}
`;

export const getInstructor = gql`
  query sfBoProductInstructor($id: ID!) {
    sfBoProductInstructor(id: $id) {
      ...InstructorLargeFragment
    }
  }
  ${InstructorLargeFragment}
`;

export const getInstructors = gql`
  query sfBoProductInstructors(
    $search: SFBoProductSearchInstructorsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductInstructors(search: $search, paging: $paging, order: $order) {
      total
      instructors {
        ...InstructorSmallFragment
      }
    }
  }
  ${InstructorSmallFragment}
`;

//base
export const getCategories = (queryName) => gql`
  query ${queryName}(
    $search: SFBoProductSearchCategoriesInput
    $paging: PageInput
    $order: OrderInput
  ) {
    ${queryName}(search: $search, paging: $paging, order: $order) {
      total
      categories {
        ...CategorySmallFragment
      }
    }
  }
  ${CategorySmallFragment}
`;

export const getCategory = (queryName) => gql`
  query ${queryName}($id: ID!) {
    ${queryName}(id: $id) {
      ...CategoryLargeFragment
    }
  }
  ${CategoryLargeFragment}
`;

export const getCategoryByPermalink = (queryName) => gql`
  query ${queryName}($permalink: String!) {
    ${queryName}(permalink: $permalink) {
      ...CategoryLargeFragment
    }
  }
  ${CategoryLargeFragment}
`;

export const getCategoryChildrenByPermalink = (queryName) => gql`
  query ${queryName}($permalink: String!) {
    ${queryName}(permalink: $permalink) {
      ...CategorySmallFragment
      children {
        ...CategorySmallFragment
      }
    }
  }
  ${CategorySmallFragment}
`;

export const getParentCategories = (queryName) => gql`
  query ${queryName} {
    ${queryName} {
      ...CategorySmallFragment
    }
  }
    ${CategorySmallFragment}
`;

export const getCollectionOnlineCourses = gql`
  query sfBoProductCollectionOnlineCourses(
    $search: SFBoProductSearchCollectionOnlineCoursesInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoProductCollectionOnlineCourses(search: $search, paging: $paging, order: $order) {
      total
      collectionOnlineCourses {
        ...CollectionOnlineCourseSmallFragment
        category1s {
          id
          title
        }
        category2s {
          id
          title
        }
        category3s {
          id
          title
        }
        category4s {
          id
          title
        }
      }
    }
  }
  ${CollectionOnlineCourseSmallFragment}
`;

export const getCollectionOnlineCourse = gql`
  query sfBoProductCollectionOnlineCourse($SKUCode: String!) {
    sfBoProductCollectionOnlineCourse(SKUCode: $SKUCode) {
      ...CollectionOnlineCourseLargeFragment
    }
  }
  ${CollectionOnlineCourseLargeFragment}
`;

export const getCollectionParentCategories = getParentCategories(
  'sfBoProductCollectionParentCategories'
);

// OnlineCourse
export const getOnlineCourseCategories = getCategories('sfBoProductOnlineCourseCategories');
export const getOnlineCourseCategory = getCategory('sfBoProductOnlineCourseCategory');
export const getOnlineCourseCategoryByPermalink = getCategoryByPermalink(
  'sfBoProductOnlineCourseCategoryByPermalink'
);
export const getOnlineCourseCategoryChildrenByPermalink = getCategoryChildrenByPermalink(
  'sfBoProductOnlineCourseCategoryByPermalink'
);

export const getOnlineCourseParentCategories = getParentCategories(
  'sfBoProductOnlineCourseParentCategories'
);

// Workshop
export const getWorkshopCategories = getCategories('sfBoProductWorkshopCategories');
export const getWorkshopCategory = getCategory('sfBoProductWorkshopCategory');
export const getWorkshopCategoryByPermalink = getCategoryByPermalink(
  'sfBoProductWorkshopCategoryByPermalink'
);
export const getWorkshopCategoryChildrenByPermalink = getCategoryChildrenByPermalink(
  'sfBoProductWorkshopCategoryByPermalink'
);

export const getWorkshopParentCategories = getParentCategories(
  'sfBoProductWorkshopParentCategories'
);

// Subscription
export const getSubscriptionCategories = getCategories('sfBoProductSubscriptionCategories');
export const getSubscriptionCategory = getCategory('sfBoProductSubscriptionCategory');
export const getSubscriptionCategoryByPermalink = getCategoryByPermalink(
  'sfBoProductSubscriptionCategoryByPermalink'
);
export const getSubscriptionCategoryChildrenByPermalink = getCategoryChildrenByPermalink(
  'sfBoProductSubscriptionCategoryByPermalink'
);

export const getSubscriptionParentCategories = getParentCategories(
  'sfBoProductSubscriptionParentCategories'
);

// Collection
export const getCollectionCategories = getCategories('sfBoProductCollectionCategories');
export const getCollectionCategory = getCategory('sfBoProductCollectionCategory');
export const getCollectionCategoryByPermalink = getCategoryByPermalink(
  'sfBoProductCollectionCategoryByPermalink'
);
export const getCollectionCategoryChildrenByPermalink = getCategoryChildrenByPermalink(
  'sfBoProductCollectionCategoryByPermalink'
);

// Book
export const getBookCategories = getCategories('sfBoProductBookCategories');
export const getBookCategory = getCategory('sfBoProductBookCategory');
export const getBookCategoryByPermalink = getCategoryByPermalink(
  'sfBoProductBookCategoryByPermalink'
);
export const getBookCategoryChildrenByPermalink = getCategoryChildrenByPermalink(
  'sfBoProductBookCategoryByPermalink'
);

export const getBookParentCategories = getParentCategories('sfBoProductBookParentCategories');

export const getUsersByActiveSubscription = gql`
  query sfBoShipmentUsersByActiveSubscription($input: SFBoShipmentUsersByActiveSubscriptionInput) {
    sfBoShipmentUsersByActiveSubscription(input: $input) {
      id
      fullName
      username
    }
  }
`;

export const getFilterUsersByActiveSubscription = gql`
  query sfBoShipmentFilterUsersByActiveSubscription(
    $input: SFBoShipmentFilterUsersByActiveSubscriptionInput!
  ) {
    sfBoShipmentFilterUsersByActiveSubscription(search: $input) {
      fullName
      orderNo
      SKUCode
      startDate
      endDate
    }
  }
`;

export const getUsersFromSubscription = gql`
  query sfBoUsersFromSubscription(
    $search: SFBoUsersFromSubscriptionInput!
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoUsersFromSubscription(search: $search, paging: $paging, order: $order) {
      total
      users {
        id
        firstname
        lastname
        email
        startDate
        endDate
      }
    }
  }
`;
