import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import FormGenerator from 'Components/Form/FormGenerator';
import TagInput from 'Components/TagInput';
import getConfig from 'Util/Config';
import { Box } from 'Components/Base';

import ColorSelectorInput from '../../../../Components/ColorSelectorInput';
import { SKU_STATUS_OPTIONS } from '../../Domains/constants';
import { default as VideoPlayerPreviewInput } from '../VideoPlayerPreviewInput';

const { products } = getConfig();

const HEX_COLOR_VALIDATION = /^#([a-f0-9]{3,4}|[a-f0-9]{4}(?:[a-f0-9]{2}){1,2})\b$/i;

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, theme, publishedAt) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Sub Title',
      inputName: 'subTitle',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: (
        <Box alignItems={'center'} display="inline-flex">
          <Box mr={1}>SKUCode</Box>
          <Tooltip
            arrow
            title="หลังจาก Publish สินค้านี้ (เปลี่ยน SKUStatus) จะไม่สามารถแก้ไข SKUCode ได้"
            placement={'top'}
          >
            <InfoOutlinedIcon fontSize={'small'} />
          </Tooltip>
        </Box>
      ),
      inputName: 'SKUCode',
      inputProps: {
        required: true,
        disabled: publishedAt,
      },
      mode: MODE.BOTH,
    },

    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'SKUStatus',
      inputName: 'SKUStatus',
      options: products?.ONLINE_COURSE?.skuStatusOptions ?? SKU_STATUS_OPTIONS,
      inputProps: {
        required: true,
        search: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'SEO Description',
      inputName: 'description',
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'BackgroundColor',
      inputName: 'backgroundColor',
      customInput: function renderColorSelectorInput() {
        return <ColorSelectorInput />;
      },
      inputProps: {
        defaultColor: `${theme.colors.primary}`,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'YouTube Video ID',
      inputName: 'promotionYoutubeId',
      customInput: function renderVideoPlayerPreviewInput() {
        return <VideoPlayerPreviewInput />;
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Ordering',
      inputName: 'itemNo',
      inputProps: {
        required: products?.disabledOrdering ? false : true,
      },
      hidden: products?.disabledOrdering || false,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Duration',
      inputName: 'duration',
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Tags',
      inputName: 'tags',
      customInput: function renderTagInput(props) {
        return <TagInput placeholderText={'Type tag and press enter'} {...props} />;
      },
      mode: MODE.BOTH,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

const OnlineCourseOverviewForm = ({ isEdit, onSubmit, theme, initialData, ...restProps }) => {
  const validate = ({ backgroundColor }, isChanged) => {
    const error = {};
    if (backgroundColor && !HEX_COLOR_VALIDATION.test(backgroundColor) && isChanged) {
      error.backgroundColor = 'Background color must be in Hex-Color format';
    }
    return error;
  };

  const publishedAt = initialData?.publishedAt;
  return (
    <FormGenerator
      fields={getFields(isEdit, theme, publishedAt)}
      customValidation={validate}
      onSubmit={onSubmit}
      submitText={isEdit ? 'Update' : 'Add'}
      cancelText="Cancel"
      showCancel
      initialData={initialData}
      theme={theme}
      {...restProps}
    />
  );
};

OnlineCourseOverviewForm.propTypes = {
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func,
  initialData: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default withTheme(OnlineCourseOverviewForm);
