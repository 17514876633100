import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CreateEditForm from 'Containers/CreateEditForm';
import { createAnnouncement, updateAnnouncement } from 'GraphQL/mutation/Announcement.mutation';
import { getAnnouncement, getAnnouncementReports } from 'GraphQL/query/Announcement.query';
import apolloClient from 'src/GraphQL';
import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import delve from '../../../Util/Delve';
import { AnnouncementForm } from '../Form';
import { STATUS } from '../Components/constants';

const GenForm = ({
  data,
  onSubmit,
  onCancelled,
  isView,
  isEdit,
  onFileListRequest,
  onFileUploadUrlRequest,
}) => {
  const mapDataIntoForm = ({ detail, audiences, ...restData }) => {
    const mapAudiences = {
      relationIdList: audiences.map((audience, index) => ({
        id: index,
        text: audience.relationId,
        value: audience.relationId,
        key: index,
      })),
      relationType: audiences[0].relationType,
    };
    const mapPublishConfig = {
      publishedTime: audiences[0].publishedTime,
      status: audiences[0].status,
    };
    const mapDetail = {
      content: detail.content,
      type: detail.type.toLowerCase(),
      title: detail.title,
      description: detail.description,
      rawContent: detail.rawContent,
    };
    return {
      detail: mapDetail,
      publishConfig: mapPublishConfig,
      audiences: mapAudiences,
      pushNotificationTitle: detail.title,
      pushNotificationDescription: detail.description,
      utmCampaignId: detail.utmCampaignId,
      utmCampaignContent: detail.utmCampaignContent,
      ...restData,
    };
  };

  const mapFormValuePublishConfig = ({ ...formValue }, isPublish) => {
    const publishConfig = { ...formValue.publishConfig };
    const status = isPublish
      ? moment() >= publishConfig.publishedTime
        ? STATUS.PUBLISHED
        : STATUS.SCHEDULED
      : STATUS.DRAFT;
    const isNow = publishConfig.isNow;
    return {
      ...formValue,
      publishConfig: {
        publishedTime: isNow ? moment() : publishConfig.publishedTime,
        status,
      },
    };
  };
  const handleOnSubmit = ({ ...formValue }) => {
    const mappedFormData = mapFormValuePublishConfig({ ...formValue }, true);
    onSubmit({ ...mappedFormData });
  };

  const handleOnSubmitDraft = ({ ...formValue }) => {
    const mappedFormData = mapFormValuePublishConfig({ ...formValue }, false);
    onSubmit({ ...mappedFormData });
  };
  return (
    <AnnouncementForm
      onCancelled={onCancelled}
      initialData={data && mapDataIntoForm(data.announcement)}
      onSubmit={handleOnSubmit}
      onSubmitDraft={handleOnSubmitDraft}
      isView={isView}
      isEdit={isEdit}
      onFileListRequest={onFileListRequest}
      onFileUploadUrlRequest={onFileUploadUrlRequest}
    />
  );
};

const CreateEditAnnouncementForm = ({
  onComplete,
  onCancelled,
  announcementId,
  isEdit,
  isView,
  searchVariables,
}) => {
  const handleFileListRequest = async () => {
    const { data } = await apolloClient.mutate({
      mutation: getAssetList,
      variables: {
        type: ASSET_TYPE.SF_ASSETS,
        key: announcementId,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  const handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { data } = await apolloClient.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.SF_ASSETS,
        key: announcementId,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  return (
    <CreateEditForm
      dataQuery={getAnnouncement}
      createMutation={createAnnouncement}
      createRefetchQueries={[{ query: getAnnouncementReports, variables: searchVariables }]}
      editRefetchQueries={[
        { query: getAnnouncementReports, variables: searchVariables },
        { query: getAnnouncement, variables: { announcementId: announcementId } },
      ]}
      editMutation={updateAnnouncement}
      isEdit={isEdit || isView}
      id={announcementId}
      getQueryVariables={({ id }) => ({
        announcementId: id,
      })}
      getEditVariables={(form, { id }) => {
        const data = {
          announcementId: id,
          announcementData: {
            ...form,
            audiences: [form.audiences],
            detail: {
              content: form.detail.content,
              type: form.detail.type.toUpperCase(),
              title: form.pushNotificationTitle,
              description: form.pushNotificationDescription,
              utmCampaignId: form.utmCampaignId,
              utmCampaignContent: form.utmCampaignContent,
              rawContent: form.detail.rawContent,
            },
          },
        };
        delete data.announcementData.pushNotificationTitle;
        delete data.announcementData.pushNotificationDescription;
        delete data.announcementData.utmCampaignId;
        delete data.announcementData.utmCampaignContent;
        return data;
      }}
      getCreateVariables={(form) => {
        const data = {
          announcementData: {
            ...form,
            audiences: [form.audiences],
            detail: {
              content: form.detail.content,
              type: form.detail.type.toUpperCase(),
              title: form.pushNotificationTitle,
              description: form.pushNotificationDescription,
              utmCampaignId: form.utmCampaignId,
              utmCampaignContent: form.utmCampaignContent,
              rawContent: form.detail.rawContent,
            },
          },
        };
        delete data.announcementData.pushNotificationTitle;
        delete data.announcementData.pushNotificationDescription;
        delete data.announcementData.utmCampaignId;
        delete data.announcementData.utmCampaignContent;
        return data;
      }}
      getEditMutationResponse={(mutateData) => delve(mutateData, 'updateAnnouncement')}
      getCreateMutationResponse={(mutateData) => delve(mutateData, 'createAnnouncement')}
      onCompleted={onComplete}
      onCancelled={onCancelled}
      checkMutationResponse
    >
      {({ data, onSubmit }) => (
        <GenForm
          data={data}
          onSubmit={onSubmit}
          onCancelled={onCancelled}
          isView={isView}
          isEdit={isEdit}
          onFileListRequest={handleFileListRequest}
          onFileUploadUrlRequest={handleFileUploadUrlRequest}
        />
      )}
    </CreateEditForm>
  );
};

CreateEditAnnouncementForm.propTypes = {
  announcementId: PropTypes.string,
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  isView: PropTypes.bool,
  searchVariables: PropTypes.shape({}),
};

export default CreateEditAnnouncementForm;
