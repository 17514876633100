import React from 'react';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';

const ModalAction = ({ onCancel, onConfirm, loading }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" mb={3}>
      <Box />
      <Flex justifyContent="flex-end" width="100%">
        <Box mr={2}>
          <Button circular onClick={onCancel} loading={loading} disabled={loading}>
            Cancel
          </Button>
        </Box>
        <Button primary circular onClick={onConfirm} loading={loading} disabled={loading}>
          Confirm
        </Button>
      </Flex>
      <Box></Box>
    </Flex>
  );
};

export default ModalAction;
