// const space = {
//   none: 0,
//   xsmall: 4,
//   small: 8,
//   medium: 16,
//   large: 32,
//   xlarge: 64,
//   xxlarge: 128,
// };

const spaceConfig = [
  { id: 'none', value: 0 },
  { id: 'xsmall', value: 4 },
  { id: 'small', value: 8 },
  { id: 'medium', value: 16 },
  { id: 'large', value: 32 },
  { id: 'xlarge', value: 64 },
  { id: 'xxlarge', value: 128 },
];

const space = spaceConfig.reduce((spaceObj, s, i) => {
  spaceObj[s.id] = i;
  return spaceObj;
}, {});

const spaceValueList = spaceConfig.map((x) => x.value);

export { space, spaceValueList };

export default space;
