import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const validate = (maxAnswers) => {
  if (maxAnswers <= 0) {
    return 0;
  } else if (maxAnswers >= 100) {
    return 100;
  }
  return maxAnswers;
};

const MaxAnswerInput = ({ value: maxAnswers, readOnly, onChange }) => {
  const handleOnChange = (maxAnswers) => {
    onChange({}, { value: validate(maxAnswers) });
  };

  return (
    <Box alignItems="center">
      <Input
        type="number"
        name="maxAnswers"
        placeholder="เลือกคำตอบได้"
        value={maxAnswers}
        onChange={(_event, { value }) => handleOnChange(value)}
        readOnly={readOnly}
      />
    </Box>
  );
};

MaxAnswerInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(MaxAnswerInput);
