import React from 'react';
import PropTypes from 'prop-types';

import CreateEditWorkshopForm from '../../../../Containers/CreateEditWorkshopForm';

const WorkshopDetails = ({ skuCode }) => {
  return <CreateEditWorkshopForm isEdit={true} skuCode={skuCode} />;
};

WorkshopDetails.propTypes = {
  skuCode: PropTypes.string.isRequired,
};

WorkshopDetails.defaultProps = {};

export default WorkshopDetails;
