import React, { useState } from 'react';
import _, { debounce, orderBy } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { Form } from 'semantic-ui-react';

import withFormsy from 'Components/Form/withFormsy';
import SelectorInput from 'Routes/Reports/Components/SelectorInput/SelectorInput';
import { getFilterUsersByActiveSubscription } from 'Routes/Products/GraphQL/query/Products.query';

import { UserDataField } from '.';

const parseValue = (data) => {
  return orderBy(
    data?.sfBoShipmentFilterUsersByActiveSubscription.map((user) => ({
      key: user.orderNo,
      value: user.orderNo,
      text: `${user.fullName} (${user.orderNo})`,
    })),
    (value) => value.text
  );
};

const getOptions = (data) => {
  const optionsFromData = parseValue(data);
  optionsFromData.unshift({
    key: 'unselected',
    value: null,
    text: `-`,
  });
  return optionsFromData;
};

const UserSelectorInputQuery = (props) => {
  const [getUsers, { data }] = useLazyQuery(getFilterUsersByActiveSubscription);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleChangeSearchQuery = debounce((event, data) => {
    const { searchQuery } = data;
    setSelectedUser(null);
    if (searchQuery.length >= 3) {
      getUsers({ variables: { input: { customerFullName: searchQuery } } });
    }
  }, 500);

  const handleChange = (event, data) => {
    setSelectedUser(data.value);
  };

  return (
    <>
      <Form.Field>
        <SelectorInput
          options={getOptions(data)}
          {...props}
          onSearchChange={handleChangeSearchQuery}
          onChange={handleChange}
          value={selectedUser?.value}
        />
      </Form.Field>
      {selectedUser && data && <UserDataField data={data} selectedUser={selectedUser} />}
    </>
  );
};

UserSelectorInputQuery.propTypes = {};

export default UserSelectorInputQuery;
export const FormsyUserSelectorInput = withFormsy(UserSelectorInputQuery);
