import React from 'react';
import PropTypes from 'prop-types';

import QueryComponent from 'GraphQL/util/QueryComponent';
import { getAllTags, TAG_TYPE } from 'GraphQL/query/Tag.query';
import { examTags } from 'GraphQL/query/ExamEditorV1.query';

import TagSelectorInput from './TagSelectorInput';

const MODE = {
  TAGS: 'TAGS',
  EXAMTAGS: 'EXAMTAGS',
};

const optionsConverter = (tag) => ({ id: tag.id, value: tag.id, text: `${tag.name}` });

const getQuery = (mode) => (mode === MODE.EXAMTAGS ? examTags : getAllTags);
const getVariables = (mode, { type, examId }) => (mode === MODE.EXAMTAGS ? { examId } : { type });
const mapData = (mode, data) =>
  mode === MODE.EXAMTAGS ? data.examTags.map(optionsConverter) : data.tags.map(optionsConverter);

const TagSelectorInputQuery = (props) => (
  <QueryComponent query={getQuery(props.mode)} variables={getVariables(props.mode, props)}>
    {(data) => <TagSelectorInput {...props} tags={mapData(props.mode, data)} />}
  </QueryComponent>
);

TagSelectorInputQuery.propTypes = {
  type: PropTypes.oneOf(Object.values(TAG_TYPE)).isRequired,
  mode: PropTypes.oneOf(Object.values(MODE)),
};

TagSelectorInputQuery.defaultProps = {
  mode: MODE.TAGS,
};

TagSelectorInputQuery.TYPE = TAG_TYPE;
TagSelectorInputQuery.MODE = MODE;

export default TagSelectorInputQuery;
