import gql from 'graphql-tag';

export const createStudentProfileMutation = gql`
  mutation boCreateStudentProfile($data: BOCreateStudentInput!) {
    boCreateStudentProfile(data: $data) {
      id
    }
  }
`;

export const updateStudentProfileMutation = gql`
  mutation boUpdateStudentProfile($userId: ID!, $data: BOUpdateStudentInput!) {
    boUpdateStudentProfile(userId: $userId, data: $data) {
      id
    }
  }
`;

export const createStudentAdvisorTeacherMutation = gql`
  mutation boCreateStudentAdvisorTeacher($advisorTeacherId: ID!, $studentId: ID!) {
    boCreateStudentAdvisorTeacher(advisorTeacherId: $advisorTeacherId, studentId: $studentId) {
      id
    }
  }
`;

// Placeholder
export const updateStudentAdvisorTeacherMutation = gql`
  mutation boUpdateStudentAdvisorTeacher($advisorTeacherId: ID!, $studentId: ID!) {
    boUpdateStudentAdvisorTeacher(advisorTeacherId: $advisorTeacherId, studentId: $studentId) {
      id
    }
  }
`;

export const deleteStudentAdvisorTeacherMutation = gql`
  mutation boDeleteStudentAdvisorTeacher($studentId: ID!) {
    boDeleteStudentAdvisorTeacher(studentId: $studentId) {
      id
    }
  }
`;

export const createTeacherProfileMutation = gql`
  mutation boCreateTeacherProfile($data: BOCreateTeacherInput!) {
    boCreateTeacherProfile(data: $data) {
      id
    }
  }
`;

export const updateTeacherProfileMutation = gql`
  mutation boUpdateTeacherProfile($userId: ID!, $data: BOUpdateTeacherInput!) {
    boUpdateTeacherProfile(userId: $userId, data: $data) {
      id
    }
  }
`;

export const createParentProfileMutation = gql`
  mutation boCreateParentProfile($data: BOCreateParentInput!) {
    boCreateParentProfile(data: $data) {
      id
    }
  }
`;

export const updateParentProfileMutation = gql`
  mutation boUpdateParentProfile($userId: ID!, $data: BOUpdateParentInput!) {
    boUpdateParentProfile(userId: $userId, data: $data) {
      id
    }
  }
`;

export const updateProfileImageMutation = gql`
  mutation boUpdateProfileImage($userId: ID!, $profileImage: BOProfileImageInput!) {
    boUpdateProfileImage(userId: $userId, profileImage: $profileImage) {
      id
    }
  }
`;

export const resetUserPasswordMutation = gql`
  mutation boResetUserPassword($userId: ID!, $password: String!) {
    boResetUserPassword(userId: $userId, password: $password) {
      id
    }
  }
`;
