const SKOOLDIO_COLORS = {
  Mustard: '#F1AD46',
  Peach: '#EF6543',
  Mint: '#48B6A3',
  MidnightBlue: '#1E4865',
  Charcoal: '#4A4A4A',
  Graphite: '#5F5F5F',
  Dust: '#9B9B9B',
  Cloud: '#D8D8D8',
  Smoke: '#F3F3F3',
  Cotton: '#FFFFFF',
  Grapefruit: '#ff5a5a',
};

const ANYWHERE_COLORS = {
  primary: '#0074ff',
};

const ALL_COLORS = {
  g5: '#4a4a4a',
  azure: '#10a7ff',
  g4: '#5f5f5f',
  g3: '#9b9b9b',
  g2: '#d8d8d8',
  g1: '#f3f3f3',
  white: '#ffffff',
  whiteTwo: '#f9f9f9',
  whiteThree: '#e2e2e2',
  skyBlue: '#5cc3ff',
  dodgerBlue: '#3fa5ff',
  facebook: '#4267B2',
  lightGold: '#ffcd4c',
  paleTeal: '#7ccab3',
  yellowOrange: '#ffb900',
  fadedOrange: '#f79e56',
  rose: '#c76167',
  grapefruit: '#ff5a5a',
  paleSalmon: '#ffabab',
  peachyPinkTwo: '#ff9393',
  orangeyRed: '#fc3925',
  orangish: '#f88b50',
  greenyBlue: '#57c0a1',
  pinkishGrey: '#dfcdcd',
  pinkishGreyTwo: '#d3b8ae',
  purpleBrown: '#311521',
  blushPink: '#ff7d87',
  peachyPink: '#ff977c',
  beige: '#f3e0d9',
  greenishTeal: '#2ecc71',
};

const GREYSCALE = {
  grey1: '#4A4A4A',
  grey2: '#5F5F5F',
  grey3: '#9B9B9B',
  grey4: '#D8D8D8',
  grey5: '#F3F3F3',
};

const colors = {
  primary: SKOOLDIO_COLORS.Mustard,
  secondary: GREYSCALE.grey3,
  success: ALL_COLORS.greenishTeal,
  successAlt: ALL_COLORS.greenyBlue,
  danger: ALL_COLORS.orangeyRed,
  warning: ALL_COLORS.yellowOrange,
  info: ALL_COLORS.dodgerBlue,
  light: ALL_COLORS.whiteTwo,
  dark: GREYSCALE.grey1,
  black: '#000',
  white: '#fff',
  greyscale: GREYSCALE,
  // required by rebass
  darken: [
    'rgba(0, 0, 0, 0.125)',
    'rgba(0, 0, 0, 0.25)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.75)',
  ],
  anywhereColors: ANYWHERE_COLORS,

  // from bootstrap 4
  bsGrays: {
    gray100: '#f8f9fa',
    gray200: '#e9ecef',
    gray300: '#dee2e6',
    gray400: '#ced4da',
    gray500: '#adb5bd',
    gray600: '#6c757d',
    gray700: '#495057',
    gray800: '#343a40',
    gray900: '#212529',
  },
  ...ALL_COLORS,
};

export { colors as COLOR };

export default colors;
