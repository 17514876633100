import PropTypes from 'prop-types';
import React from 'react';

import { SKU_CATEGORY } from 'Routes/Products/Domains/constants';
import { SFBO_SHIPMENT_METHOD } from 'GraphQL/query/Shipment.query.js';
import FormGenerator from 'Components/Form/FormGenerator';
import { SemanticButton as Button } from 'Components/Base/Button';
import getConfig from 'Util/Config';

import { SHIPMENT_PROVIDER_TEXT } from '../SearchShipments';

import DateRangeInput from './DateRangeInput';
const shipmentProviders = getConfig().shipment.shipmentProviders;
const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'User Id',
    inputName: 'userId',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Order Id',
    inputName: 'orderId',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'SKU Category',
    inputName: 'SKUCategory',
    options: Object.keys(SKU_CATEGORY).map((category) => ({
      key: category,
      value: SKU_CATEGORY[category],
      text: SKU_CATEGORY[category],
    })),
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Shipment Method',
    inputName: 'shipmentMethod',
    options: Object.keys(SFBO_SHIPMENT_METHOD).map((method) => ({
      key: method,
      value: SFBO_SHIPMENT_METHOD[method],
      text: SFBO_SHIPMENT_METHOD[method],
    })),
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Shipment Provider',
    inputName: 'shipmentProvider',
    options: shipmentProviders.map((provider) => ({
      key: provider,
      value: provider,
      text: SHIPMENT_PROVIDER_TEXT[provider],
    })),
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Created At',
    inputName: 'createdAt',
    customInput: (props) => <DateRangeInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Updated At',
    inputName: 'updatedAt',
    customInput: (props) => <DateRangeInput {...props} />,
  },
];

const SearchExportShipmentForm = (props) => {
  return (
    <FormGenerator
      fields={fields}
      submitText="export"
      cancelText="Cancel"
      showCancel
      showAction={false}
      customAction={(loading, isDirty, onSubmit, _, onCancelled) => (
        <>
          <Button primary circular>
            export
          </Button>
          <Button type="button" circular onClick={onCancelled}>
            cancel
          </Button>
        </>
      )}
      {...props}
    />
  );
};

SearchExportShipmentForm.propTypes = {
  onsubmit: PropTypes.func,
};

export default SearchExportShipmentForm;
