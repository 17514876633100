import React from 'react';
import PropTypes from 'prop-types';
import { Header, Grid } from 'semantic-ui-react';

import BundleImageUpload from './BundleImageUpload.gql';

const BundleHeader = ({ id, title, subTitle, coverImageUrl, cardImageUrl }) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <BundleImageUpload
            changeImgText="Change cover image"
            id={id}
            fileImageUrl={coverImageUrl && coverImageUrl !== '' ? coverImageUrl : undefined}
            field={'coverImage'}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <BundleImageUpload
            changeImgText="Change card image"
            id={id}
            fileImageUrl={cardImageUrl && cardImageUrl !== '' ? cardImageUrl : undefined}
            field={'cardImage'}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width={16}>
        <Grid.Row>
          <Header size="huge">{title}</Header>
        </Grid.Row>
        <Grid.Row>
          <Header size="large">{subTitle}</Header>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

BundleHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  theme: PropTypes.string,
  coverImageUrl: PropTypes.string,
  cardImageUrl: PropTypes.string,
  SKUCode: PropTypes.string,
};

export default BundleHeader;
