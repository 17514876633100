import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Divider, Container, Icon, Tab } from 'semantic-ui-react';
import { defaultProps } from 'recompose';

import APP_PERMISSION from 'Util/permissions/permissionKeys';
import Menu from 'Containers/Menu';
import { GenRoute } from 'Util/Route';
import SubscriptionUserEnrollment from 'Routes/Products/Components/SubscriptionEditor/SubscriptionUserEnrollment';

import SKUHeader from '../../Components/SKUHeader';
import {
  SubscriptionDetailsEditor,
  SubscriptionCategoriesEditor,
  SubscriptionPricingEditor,
  SubscriptionConditionsEditor,
  SubscriptionItemsEditor,
  SubscriptionPlatformAndExternalIdEditor,
  SubscriptionRedemptionEditor,
} from '../../Components/SubscriptionEditor';

const genMenu = (matchUrl) => {
  const menus = [
    {
      path: `${matchUrl}/overview`,
      title: 'Overview',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SUBSCRIPTION.OVERVIEW.VIEW,
      ],
      route: SubscriptionDetailsEditor,
    },
    {
      path: `${matchUrl}/categories`,
      title: 'Categories',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SUBSCRIPTION.CATEGORIES.VIEW,
      ],
      route: SubscriptionCategoriesEditor,
    },
    {
      path: `${matchUrl}/pricing`,
      title: 'Pricing',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SUBSCRIPTION.PRICING.VIEW,
      ],
      route: SubscriptionPricingEditor,
    },
    {
      path: `${matchUrl}/redemption`,
      title: 'Redemption',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SUBSCRIPTION.REDEMPTION.VIEW,
      ],
      route: SubscriptionRedemptionEditor,
    },
    {
      path: `${matchUrl}/conditions`,
      title: 'Conditions',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SUBSCRIPTION.CONDITIONS.VIEW,
      ],
      route: SubscriptionConditionsEditor,
    },
    {
      path: `${matchUrl}/items`,
      title: 'Items',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SUBSCRIPTION.ITEMS.VIEW,
      ],
      route: SubscriptionItemsEditor,
    },
    {
      path: `${matchUrl}/platformAndExternalId`,
      title: 'External',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SUBSCRIPTION.EXTERNAL.VIEW,
      ],
      route: SubscriptionPlatformAndExternalIdEditor,
    },
    {
      path: `${matchUrl}/userEnrollment`,
      title: 'User Enrollment',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SUBSCRIPTION.USER_ENROLLMENT.VIEW,
      ],
      route: SubscriptionUserEnrollment,
    },
  ];

  return menus;
};
const SubscriptionDetails = withTheme((props) => {
  const { subscription, match, theme } = props;
  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: theme.colors.primary,
        }}
      >
        <Container>
          <SKUHeader {...subscription} />
        </Container>
      </Container>
      <Divider hidden />
      <Menu menus={genMenu(match.url)} menuProps={{ secondary: true, pointing: true }} />
      <GenRoute menus={genMenu(match.path)} subscription={subscription} />
    </Fragment>
  );
});

export default SubscriptionDetails;
