import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import { SUBSCRIPTION_CONDITION_OPTIONS } from '../../Domains/constants';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Validity Type',
      inputName: 'type',
      options: SUBSCRIPTION_CONDITION_OPTIONS,
      inputProps: {
        required: true,
        search: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Validity Days',
      inputName: 'days',
      inputProps: {
        required: true,
        min: 0,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Validity Days Graceful Offset',
      inputName: 'gracefulOffset',
      inputProps: {
        min: 0,
      },

      mode: MODE.BOTH,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class SubscriptionConditionsForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  handleSubmit = () => {};

  validate = ({ days, gracefulOffset }, isChanged) => {
    const error = {};

    if (isChanged) {
      if (days < 0) {
        error.days = 'Validity days should be greater than or equal to 0';
      }

      if (gracefulOffset < 0) {
        error.gracefulOffset = 'Validity days graceful offset should be greater than or equal to 0';
      }
    }

    return error;
  };

  render() {
    const { isEdit, onSubmit } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit)}
        onSubmit={onSubmit}
        customValidation={this.validate}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}

export default SubscriptionConditionsForm;
