import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import { SemanticButton as Button } from '../../Base/Button';

const defaultChildrenFn = (closeModal) => (
  <Fragment>
    <Modal.Header>Header</Modal.Header>
    <Modal.Content>Content</Modal.Content>
    <Modal.Actions>
      <Button onClick={closeModal}>Close Modal</Button>
    </Modal.Actions>
  </Fragment>
);

export default class StatefulModal extends Component {
  static propTypes = {
    trigger: PropTypes.element,
    children: PropTypes.func,
    defaultOpen: PropTypes.bool,
    dimmer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  static defaultProps = {
    trigger: <Button>Show Modal</Button>,
    children: defaultChildrenFn,
    dimmer: 'inverted',
  };

  state = {
    modalOpen: this.props.defaultOpen,
  };

  closeModal = () => {
    this.setState((prevState) => ({ modalOpen: false }));
  };

  openModal = () => {
    this.setState((prevState) => ({ modalOpen: true }));
  };

  renderTrigger = () => {
    return React.cloneElement(this.props.trigger, { onClick: this.openModal });
  };

  render() {
    const { defaultOpen, trigger, dimmer, ...otherModalProps } = this.props;
    return (
      <Modal
        className={'scrolling'}
        trigger={this.renderTrigger()}
        dimmer={this.props.dimmer}
        open={this.state.modalOpen}
        {...otherModalProps}
      >
        {this.props.children(this.closeModal, this.state.modalOpen)}
      </Modal>
    );
  }
}
