import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import SubSectionView, { MATERIAL_TYPES_STRING, MATERIAL_TYPES } from '../../Components/SubSection';
import StatefulModal from '../../Components/Modal/StatefulModal';
import AssetComponent from '../AssetComponents';

class SubSection extends Component {
  state = {
    materialDialogOpen: false,
    materialType: null,
    isCreated: true,
    materialId: null,
    materialDownloadable: null,
    materialProvider: null,
  };

  handleAddMaterial = (type, downloadable, materialProvider) => {
    this.setState({
      materialDialogOpen: true,
      materialType: type,
      isCreated: true,
      materialId: null,
      materialDownloadable: downloadable,
      materialProvider: materialProvider,
    });
  };

  handleEditMaterial = (matObj) => {
    this.setState({
      materialDialogOpen: true,
      materialType: matObj.type,
      isCreated: false,
      materialId: matObj.id,
      materialDownloadable: null,
    });
  };

  handleModalAction = (closeFn) => {
    this.setState({
      materialDialogOpen: false,
      materialType: null,
      isCreated: true,
      materialId: null,
      materialDownloadable: null,
    });
    closeFn();
  };

  render() {
    const { id, product, version, courseId, contentAssets } = this.props;
    const {
      materialDialogOpen,
      isCreated,
      materialType,
      materialId,
      materialDownloadable,
      materialProvider,
    } = this.state;
    return (
      <Fragment>
        <SubSectionView
          {...this.props}
          onAddMaterial={this.handleAddMaterial}
          onMaterialEditClick={this.handleEditMaterial}
          onMaterialDeleteClick={this.props.onDeleteMaterial}
        />
        {materialDialogOpen && (
          <StatefulModal
            trigger={<div />}
            defaultOpen
            size={materialType === MATERIAL_TYPES.SLIDES ? 'fullscreen' : 'large'}
          >
            {(closeModal) => (
              <Fragment>
                <Modal.Header>
                  {isCreated ? 'New ' : 'Edit '}
                  {materialProvider ?? MATERIAL_TYPES_STRING[materialType]}
                </Modal.Header>
                <Modal.Content>
                  <AssetComponent
                    id={materialId}
                    courseId={courseId}
                    courseItemId={id}
                    newAsset={isCreated}
                    type={materialType}
                    materialProvider={materialProvider}
                    product={product}
                    version={version}
                    materialDownloadable={materialDownloadable}
                    contentAssets={contentAssets}
                    onCancel={() => this.handleModalAction(closeModal)}
                    onComplete={() => this.handleModalAction(closeModal)}
                  />
                </Modal.Content>
              </Fragment>
            )}
          </StatefulModal>
        )}
      </Fragment>
    );
  }
}

export default SubSection;
