import gql from 'graphql-tag';

export const getAllTags = gql`
  query getAllTags($type: TAG_TYPE) {
    tags(type: $type) {
      id
      type
      name
      slug
    }
  }
`;

export const TAG_TYPE = {
  QUESTION: 'QUESTION_TAG',
  INSTRUCTOR: 'INSTRUCTOR_TAG',
};
