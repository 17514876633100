import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import { MATERIAL_TYPES_STRING, MATERIAL_TYPES } from 'Components/SubSection';

import MaterialMeta from '../Common/MaterialMeta';
import ActionBar from '../Common/ActionBar';

const NoteboardEditor = ({
  title,
  description,
  materialTypeString,
  newAsset,
  loading,
  onCancel,
  onCreateMaterial,
}) => {
  const [formData, setFormData] = useState(null);
  useEffect(() => {
    setFormData({ title, description });
  }, [title, description]);

  const handleFormChange = (value) => {
    setFormData(value);
  };

  const handleAction = () => {
    const { title, description } = formData;
    onCreateMaterial({ title, description });
  };

  return (
    <Box>
      <MaterialMeta title={title} description={description} onChange={handleFormChange} />
      <Box mt={2}>
        <ActionBar
          isCreate={newAsset}
          onAction={handleAction}
          materialTypeString={materialTypeString}
          onCancel={onCancel}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

NoteboardEditor.propTypes = {
  onCancel: PropTypes.func,
  newAsset: PropTypes.bool,
  materialTypeString: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onCreateMaterial: PropTypes.func,
};

NoteboardEditor.defaultProps = {
  onCancel: () => {},
  materialTypeString: MATERIAL_TYPES_STRING[MATERIAL_TYPES.NOTEBOARD],
  title: '',
  description: '',
  content: {},
  onCreateMaterial: () => {},
};

export default NoteboardEditor;
