import { get } from '@skooldio/skooldio-classroom-components';
import { Flex } from '@skooldio/skooldio-ui-components-core';
import styled from 'styled-components';

const Card = styled(Flex)`
  box-shadow: ${get('shadows.primary')};
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ theme }) => theme?.colors?.greyscale?.grey5 ?? '#E6EDFF'};
  min-width: 100%;
`;

Card.defaultProps = { bg: 'white', flexDirection: 'column' };

export default Card;
