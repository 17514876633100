import * as ByteArkApi from '../Api/ByteArkApi';
import * as ByteArkSdk from '../Sdk/ByteArkSdk';

export const uploads = async (files, metadata, handleOnUploadProgress, byteArkSdkUpload) => {
  const { userId, courseId, courseItemId, courseOwnerId, courseCode } = metadata;
  const data = { userId, courseId, courseItemId, courseOwnerId, courseCode };

  if (byteArkSdkUpload) return ByteArkSdk.uploadVideos(files, data, handleOnUploadProgress);

  return ByteArkApi.uploadVideos(files, data, handleOnUploadProgress);
};
