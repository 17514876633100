import React from 'react';
import PropTypes from 'prop-types';

import { getAllTagV1, TG_TAG_V1_TYPES } from 'GraphQL/query/TagV1.query';
import QueryComponent from 'GraphQL/util/QueryComponent';

import TagSelectorInput from './TagSelectorInput';

const optionsConverter = (value, index) => ({
  id: index,
  value: value.tag,
  text: value.tagDisplay,
  key: index,
});

const mapValue = (tags = []) => {
  return tags.map(optionsConverter);
};

const TagSelectorInputQuery = (props) => {
  return (
    <QueryComponent query={getAllTagV1} variables={{ type: props.type }}>
      {(data) => {
        return <TagSelectorInput {...props} tags={mapValue(data.tagsV1)} />;
      }}
    </QueryComponent>
  );
};

TagSelectorInputQuery.propTypes = {
  type: PropTypes.oneOf(Object.values(TG_TAG_V1_TYPES)).isRequired,
};

TagSelectorInputQuery.TYPE = TG_TAG_V1_TYPES;

export default TagSelectorInputQuery;
