import React, { Fragment } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import CreateEditForm from 'Containers/CreateEditForm';
import { createExam, updateExam, UPDATE_EXAM_RANDOM_RULES } from 'GraphQL/mutation/Exam.mutation';
import { getExamById, getExams, getTags } from 'GraphQL/query/Exam.query';
import withAppConfig from 'Util/hoc/withAppConfig';
import Loading from 'Components/Loading';

import delve from '../../../Util/Delve';
import ExamForm from '../Components/ExamForm';
import {
  EXAM_TAG_CATEGORY,
  EXAM_GENERATOR_TYPE,
  EXAM_LOGIC_TAG_CATEGORY,
} from '../Components/constants';

import { mapExamData } from './utils';

const mapSlugsToTags = (slugs = [], category) => {
  if (slugs) {
    if (Array.isArray(slugs)) {
      return slugs.map((slug) => {
        return {
          name: slug,
          slug,
          category,
        };
      });
    }
    return [
      {
        name: slugs,
        slug: slugs,
        category,
      },
    ];
  }
  return [];
};

const mapFormValueToExamData = (formValue = {}) => {
  const {
    assignee,
    owner,
    passingCriteria,
    timeLimit,
    type = [],
    courseCode = [],
    subtopic = [],
    resultOptions = {},
    ...restFormValue
  } = formValue;

  const metadata = { passingCriteria, timeLimit, assignee, owner, resultOptions };
  const courseCodeTags = mapSlugsToTags(courseCode, EXAM_TAG_CATEGORY.COURSE_CODE);
  const subtopicTags = mapSlugsToTags(subtopic, EXAM_TAG_CATEGORY.SUBTOPIC);
  const typeTags = mapSlugsToTags(type, EXAM_TAG_CATEGORY.EXAM_TYPE);
  const tags = [...typeTags, ...courseCodeTags, ...subtopicTags];

  return {
    metadata,
    tags,
    generatorType: EXAM_GENERATOR_TYPE.RANDOM,
    ...restFormValue,
  };
};

const mapLogicFormValueToExamData = (formValue = {}, examConfig) => {
  const enableQuestionFieldConfigurable = examConfig?.enableQuestionFieldConfigurable;
  const availableTags = examConfig?.question?.tags ?? [];
  const logicCategories = enableQuestionFieldConfigurable
    ? Object.keys(EXAM_LOGIC_TAG_CATEGORY)
        .map((key) => {
          const tag = availableTags.find((tag) => key === tag.category);
          return tag ? EXAM_LOGIC_TAG_CATEGORY[tag.category] : null;
        })
        .filter((e) => !!e)
    : Object.values(EXAM_LOGIC_TAG_CATEGORY);

  const { logics, ...restFormValue } = formValue;
  const totalQuestionsPerTagGroups = logics.map(({ tagIds, totalQuestions }) => ({
    totalQuestions,
    tagIds: logicCategories.flatMap(({ category }) => tagIds[category]).filter((tagId) => tagId), // Get rid of empty strings returned from each dropdown when it has no selected value
  }));
  return {
    ...restFormValue,
    totalQuestionsPerTagGroups,
  };
};

const CreateEditExamForm = ({
  onComplete,
  onCancelled,
  examId,
  isEdit,
  searchVariables,
  appConfig,
}) => {
  const { loading: tagsLoading, data: queryData } = useQuery(getTags, {
    variables: {
      search: { category: [EXAM_TAG_CATEGORY.EXAM_TYPE, EXAM_TAG_CATEGORY.OWNER] },
    },
    fetchPolicy: 'cache-first',
  });
  const [
    updateExamLogic,
    { loading: logicLoading, error: logicError, data: logicData },
  ] = useMutation(UPDATE_EXAM_RANDOM_RULES);

  if (tagsLoading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }

  return (
    <Fragment>
      <CreateEditForm
        dataQuery={getExamById}
        createMutation={createExam}
        createRefetchQueries={[
          { query: getExams, variables: searchVariables },
          {
            query: getTags,
            variables: {
              search: { category: [EXAM_TAG_CATEGORY.COURSE_CODE, EXAM_TAG_CATEGORY.EXAM_TYPE] },
            },
          },
        ]}
        isEdit={isEdit}
        editMutation={updateExam}
        editRefetchQueries={[
          {
            query: getExamById,
            variables: {
              examId: examId,
              enabledContentTeam: appConfig.enabledContentTeam,
            },
          },
          {
            query: getTags,
            variables: {
              search: { category: [EXAM_TAG_CATEGORY.COURSE_CODE, EXAM_TAG_CATEGORY.EXAM_TYPE] },
            },
          },
        ]}
        id={examId}
        getQueryVariables={({ id }) => ({
          examId: id,
          enabledContentTeam: appConfig.enabledContentTeam,
        })}
        getCreateVariables={(form) => ({
          exam: form,
          enabledContentTeam: appConfig.enabledContentTeam,
        })}
        getEditVariables={(form, { id }) => ({
          examId: id,
          exam: form,
          enabledContentTeam: appConfig.enabledContentTeam,
        })}
        getCreateMutationResponse={(mutateData) => delve(mutateData, 'createExamExam')}
        getEditMutationResponse={(mutateData) => delve(mutateData, 'updateExamExam')}
        onCompleted={onComplete}
        onCancelled={onCancelled}
        checkMutationResponse
      >
        {({ data, onSubmit }) => {
          const handleOnSubmit = (formValue) => {
            onSubmit(mapFormValueToExamData(formValue));
          };
          const handleOnSubmitLogic = (formValue) => {
            updateExamLogic({
              variables: {
                examId: examId,
                rules: mapLogicFormValueToExamData(formValue, appConfig.exam),
              },
              refetchQueries: [
                {
                  query: getExamById,
                  variables: {
                    examId: examId,
                    enabledContentTeam: appConfig.enabledContentTeam,
                  },
                },
              ],
            });
          };

          return (
            <ExamForm
              initialData={mapExamData(data?.examExam)}
              onSubmit={handleOnSubmit}
              onSubmitLogic={handleOnSubmitLogic}
              onCancelled={onCancelled}
              tags={queryData?.examTags?.examTags}
              logicMutationResponse={{ logicLoading, logicError, logicData }}
            />
          );
        }}
      </CreateEditForm>
    </Fragment>
  );
};

CreateEditExamForm.propTypes = {
  examId: PropTypes.string,
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  searchVariables: PropTypes.shape({}),
};

export default withAppConfig({ configProp: 'appConfig' })(CreateEditExamForm);
