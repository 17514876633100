import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Header, Modal, Icon, Checkbox, Accordion } from 'semantic-ui-react';
import { Mutation } from '@apollo/client/react/components';
import _ from 'lodash';
import moment from 'moment';

import { Box, Flex, Text } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import StatefulModal from 'Components/Modal/StatefulModal';
import { DATA_FORMAT } from 'GraphQL/util/SimpleQueryTable';
import QueryTable from 'GraphQL/util/QueryTable';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { getWorkshopBatches } from '../../GraphQL/query/Products.query';
import {
  CATEGORY_TYPE,
  SKU_VISIBILITY,
  SKU_CATEGORY,
  SKU_VISIBILITY_SEARCH_OPTIONS,
  SKU_STATUS_SEARCH_OPTIONS,
  SKU_STATUS_OPTIONS,
} from '../../Domains/constants';
import resolveCategoryName from '../../Domains/resolveCategoryName';
import CategorySelectorInput from '../CategorySelectorInput';
import InstructorSelectorInput from '../InstructorSelectorInput';
import CategoryCell from '../CategoryCell';

function addOrRemove(array, item) {
  const data = [...array];
  const index = _.findIndex(data, (datum) => datum.id === item.id);

  if (index === -1) {
    data.push({ id: item.id, SKUCode: item.SKUCode });
  } else {
    data.splice(index, 1);
  }
  return data;
}

const workshopBatchColumns = (skuCategory, handleCheckbox, selectedItems) => [
  // Workshop filter
  {
    Header: 'Workshop SKUCode',
    accessor: 'workshopSKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    isHideColumn: true,
  },
  {
    Header: 'Workshop Title',
    accessor: 'workshopTitle',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    isHideColumn: true,
  },
  {
    Header: 'Workshop Permalink',
    accessor: 'workshopPermalink',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    isHideColumn: true,
  },
  {
    Header: 'Workshop Visibility',
    accessor: 'workshopIsVisible',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    isHideColumn: true,
    enumOptions: SKU_VISIBILITY_SEARCH_OPTIONS,
  },
  {
    Header: 'Workshop Status',
    accessor: 'workshopStatus',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    sortable: false,
    isSearchAble: true,
    isHideColumn: true,
    enumOptions: SKU_STATUS_SEARCH_OPTIONS,
  },
  // WorkshopBatch filter and column
  {
    Header: 'Batch SKUCode',
    accessor: 'batchSKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => original?.SKUCode,
  },
  {
    Header: 'Batch Title',
    accessor: 'batchTitle',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => original?.title,
  },
  {
    Header: 'Batch Permalink',
    accessor: 'batchPermalink',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => original?.permalink,
  },
  {
    Header: 'Batch Visibility',
    accessor: 'batchIsVisible',
    width: 40,
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    isHideColumn: true,
    enumOptions: SKU_VISIBILITY_SEARCH_OPTIONS,
  },
  {
    Header: 'Batch Status',
    accessor: 'batchStatus',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    sortable: false,
    isSearchAble: true,
    enumOptions: SKU_STATUS_SEARCH_OPTIONS,
    Cell: ({ original }) => {
      const { SKUStatus, isVisible } = original ?? {};
      const visibleStatus = isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED;
      const status =
        SKU_STATUS_OPTIONS.find(({ value }) => value === `${visibleStatus}/${SKUStatus}`)?.text ??
        '';
      return status;
    },
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1),
    accessor: 'category1Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_1}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category1s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2),
    accessor: 'category2Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_2}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category2s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3),
    accessor: 'category3Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_3}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category3s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4),
    accessor: 'category4Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_4}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category4s} />,
  },
  {
    Header: 'Batch EventDate',
    accessor: 'batchEventDate',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
    dataFormat: DATA_FORMAT.TIME_FORMAT,
    Cell: ({ original }) => {
      if (original?.eventTime) {
        const { startDate, endDate } = original.eventTime;
        return `${moment(startDate).format('LLL')} - ${moment(endDate).format('LLL')}`;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'Instructor',
    accessor: 'instructorIds',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    isHideColumn: true,
    CustomSearchComponent: (props) => <InstructorSelectorInput {...props} />,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <Fragment>
          <Button.Group>
            <Checkbox
              checked={selectedItems.find((item) => item.id === id)}
              onChange={() => handleCheckbox(original)}
            />
          </Button.Group>
        </Fragment>
      );
    },
  },
];

export default class SKUAddWorkshopModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    trigger: PropTypes.element,
    onSubmit: PropTypes.func,
    title: PropTypes.string.isRequired,
    variables: PropTypes.object,
    handleMutation: PropTypes.object,
    refetchQueries: PropTypes.arrayOf(
      PropTypes.shape({
        query: PropTypes.object.isRequired,
        variables: PropTypes.object,
      })
    ),
  };
  static defaultProps = {
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: {},
      order: { field: 'itemNo', type: 'ASC' },
      selectedItems: [],
      activeIndex: -1,
    };
  }

  handleCheckbox = (item) => {
    this.setState({ selectedItems: addOrRemove(this.state.selectedItems, item) });
  };

  handleAddItems = (mutateFn) => {
    const { selectedItems } = this.state;
    if (selectedItems.length > 0) {
      mutateFn({
        variables: {
          id: this.props.id,
          data: { addedItemIds: selectedItems.map((item) => item.id) },
        },
      });
    }
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoProductWorkshopBatches.batches;

  handleClickAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { trigger, title, variables, handleMutation, refetchQueries } = this.props;
    const { search, paging, order, selectedItems, activeIndex } = this.state;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{`Add Items to ${title}`}</Modal.Header>
            <Modal.Content>
              <Mutation
                mutation={handleMutation}
                refetchQueries={[
                  {
                    query: refetchQueries,
                    variables,
                  },
                ]}
              >
                {(editData, { loading, error, data: mutateData }) => {
                  return (
                    <Box>
                      <CheckMutationResponse
                        checkOnlyDefined={true}
                        response={mutateData}
                        loading={loading}
                        error={error}
                        onValid={closeModal}
                      />
                      <QueryTable
                        type={'sfBoProductWorkshopBatches'}
                        resolveData={this.resolveData}
                        columns={workshopBatchColumns(
                          SKU_CATEGORY.WORKSHOP,
                          this.handleCheckbox,
                          selectedItems
                        )}
                        query={getWorkshopBatches}
                        search={search}
                        onSearchChange={this.handleSearchChange}
                        paging={paging}
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        order={order}
                        onSortedChange={this.handleSortedChange}
                        ignoreSearchParams
                      />
                      <Flex mt={2}>
                        <Accordion styled fluid>
                          <Accordion.Title
                            active={activeIndex === 0}
                            index={0}
                            onClick={this.handleClickAccordion}
                          >
                            <Header textAlign="left">
                              <Icon name="dropdown" />
                              {`${selectedItems.length} item(s) to be added`}
                            </Header>
                          </Accordion.Title>
                          <Accordion.Content
                            active={activeIndex === 0}
                            style={{ textAlign: 'left' }}
                          >
                            {selectedItems.map(({ id, SKUCode }, index) => (
                              <Text key={id}>{`${index + 1}: ${SKUCode}`}</Text>
                            ))}
                          </Accordion.Content>
                        </Accordion>
                      </Flex>
                      <Flex justifyContent="flex-end" mt={2}>
                        <Button
                          primary
                          circular
                          size="medium"
                          type="button"
                          content="Update"
                          loading={loading}
                          disabled={loading}
                          onClick={() => this.handleAddItems(editData)}
                        />
                        <Button
                          circular
                          size="medium"
                          type="reset"
                          content="Cancel"
                          disabled={loading}
                          onClick={closeModal}
                        />
                      </Flex>
                    </Box>
                  );
                }}
              </Mutation>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
