import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

import StatsTable from 'Containers/Statistics';

export default class StatisticsView extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
        completedRate: PropTypes.number,
        completedSection: PropTypes.string,
        learningHours: PropTypes.number,
        pretest: PropTypes.number,
        posttest: PropTypes.number,
        exam: PropTypes.number,
        section: PropTypes.string,
        round: PropTypes.string,
      })
    ),
    courseReferId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  render() {
    return (
      <Container>
        <StatsTable {...this.props} />
      </Container>
    );
  }
}
