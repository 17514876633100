import React, { Component } from 'react';
import PropTypes from 'prop-types';

import QueryTable from '../../../GraphQL/util/QueryTable';
import { purchaseOrders } from '../../../GraphQL/query/Purchase.query';
import PAYMENT from '../../../GraphQL/common/paymentStatus';
import {
  subComponentPayMent,
  Format,
  PaymentMethodFormat,
  DateFormat,
  getPurchaseReceipt,
} from '../common';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';
import ImageUploadedModalView from '../../../Components/Modal/ImageUploadedModal';
import { defaultSearch } from '../common/constants';

const paymentColumn = [
  {
    Header: 'Date',
    accessor: 'updatedAt',
    width: 200,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
    Cell: ({ value }) => <DateFormat date={value} />,
  },
  {
    Header: 'Fullname',
    accessor: 'fullname',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Mobile',
    accessor: 'mobile',
    width: 120,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Full prices',
    accessor: 'fullPrices',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
    Cell: ({ value }) => <Format amount={value} />,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
    Cell: ({ value }) => <Format amount={value} />,
  },
  {
    Header: 'Paid amount',
    accessor: 'paidAmount',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
    Cell: ({ value }) => <Format amount={value} />,
  },
  {
    Header: 'Fee amount',
    accessor: 'feeAmount',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
    Cell: ({ value }) => <Format amount={value} />,
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod',
    width: 140,
    Cell: ({ original }) => <PaymentMethodFormat method={original} />,
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Receipt No.',
    accessor: 'accountingNumber',
    width: 120,
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Pay slip',
    accessor: 'histories[0].detail.bank',
    width: 150,
    Cell: ({ original }) => {
      const payslip = getPurchaseReceipt(original);
      if (payslip) {
        return (
          <ImageUploadedModalView
            title="Pay slip"
            link={payslip.uploadedLink}
            dateTime={payslip.dateTime}
          />
        );
      } else {
        return '';
      }
    },
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
];

class Payment extends Component {
  state = {
    status: PAYMENT.SUCCESS,
    paging: { currentPage: 0, pageSize: 10 },
    search: defaultSearch,
  };
  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, ...defaultSearch }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { status, search, paging, order } = this.state;
    return (
      <QueryTable
        type="purchaseOrders"
        columns={paymentColumn}
        query={purchaseOrders}
        subComponent={subComponentPayMent}
        status={status}
        search={search}
        onSearchChange={this.handleSearchChange}
        paging={paging}
        onPageChange={this.handlePageChange}
        onPageSizeChange={this.handlePageSizeChange}
        order={order}
        onSortedChange={this.handleSortedChange}
      />
    );
  }
}
export default Payment;
