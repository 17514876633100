import { useState, useMemo } from 'react';

const useDisclosure = ({ defaultIsOpen = false } = {}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  return useMemo(
    () => ({
      isOpen,
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
      toggle: () => setIsOpen((prevOpen) => !prevOpen),
    }),
    [isOpen]
  );
};

export default useDisclosure;
