import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import merge from 'lodash/merge';

import { getConfig } from '../query/AppConfig.query';

import QueryComponent from './QueryComponent';

const defaultConfig = { loadingOverlay: true, configProp: 'config' };

const withServerConfig = (hocConfig = {}, overrideConfig) => (WrappedComponent) => {
  const { loadingOverlay, configProp } = merge({}, defaultConfig, hocConfig);

  const ServerConfigHOC = (props) => (
    <QueryComponent query={getConfig} loadingOverlay={loadingOverlay}>
      {({ config } = {}) => {
        const finalConfig = overrideConfig ? overrideConfig : config;
        const configPropName = configProp ? configProp : 'config';
        const cProps = { [configPropName]: finalConfig };

        return <WrappedComponent {...props} {...cProps} />;
      }}
    </QueryComponent>
  );

  hoistNonReactStatics(ServerConfigHOC, WrappedComponent);

  return ServerConfigHOC;
};

export default withServerConfig;
