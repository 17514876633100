import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { flatMap } from 'lodash';

import APP_PERMISSION from 'Util/permissions/permissionKeys';
import QueryComponent from 'GraphQL/util/QueryComponent';
import { getUserPDPAList } from 'GraphQL/query/Users.query';

import { GenSwitchRoute } from '../../Util/Route';
import { ComplexSideMenuLayout } from '../../Components/SideMenuLayout';
import getConfig from '../../Util/Config';

import AllUsers from './Routes/AllUsersRoute';
import SingleUserRoute from './Routes/SingleUserRoute';
import OrganizationsRoute from './Routes/OrganizationsRoute';
import SingleOrganizationRoute from './Routes/SingleOrganizationRoute';
import BatchCreationRoute from './Routes/BatchCreationRoute';
import UserNewRoute from './Routes/SearchUserRoute';
import RolesRoute from './Routes/RolesRoute';
import SingleRoleRoute from './Routes/SingleRoleRoute';
import TeamsRoute from './Routes/TeamsRoute';
import SingleTeamRoute from './Routes/SingleTeamRoute';
import UserPDPAReportRoute from './Routes/UserPDPAReportRoute';

const UserRouteIndex = () => <Redirect to={{ title: '', pathname: '/users/search' }} />;

const { useNewUserSearch, enabledAppPermission, enabledContentTeam } = getConfig();

const defaultUser = {
  path: '/users',
  exact: true,
  roles: [],
  route: UserRouteIndex,
};

const genMenu = (data) => {
  const menus = {
    'User Management': [
      {
        path: '/users/search',
        title: 'USERS',
        menuName: 'Users',
        icon: 'user',
        exact: true,
        private: true,
        roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.LIST.VIEW],
        route: useNewUserSearch ? UserNewRoute : AllUsers,
      },
      {
        path: '/users/organizations',
        title: 'ORGANIZATIONS',
        menuName: 'Organizations',
        icon: 'building',
        exact: true,
        private: true,
        roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.ORGANIZATION.VIEW],
        route: OrganizationsRoute,
      },
      {
        path: '/users/organizations/:organizationId',
        title: 'Organization Details',
        hidden: true,
        private: true,
        roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.ORGANIZATION.VIEW],
        route: SingleOrganizationRoute,
      },
      {
        path: '/users/batch-creation',
        title: 'Batch Creation',
        menuName: 'BatchEnrollment',
        icon: 'upload',
        exact: false,
        private: true,
        roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.BATCH_ENROLLMENT.VIEW],
        route: BatchCreationRoute,
      },
      {
        path: '/users/search/:userId',
        title: 'Edit User',
        private: true,
        hidden: true,
        roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.DETAIL.VIEW],
        route: SingleUserRoute,
      },
    ],
    ...(enabledContentTeam && {
      Teams: [
        {
          path: '/users/teams',
          title: 'Content Teams',
          menuName: 'Team Management',
          private: true,
          exact: true,
          roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.CONTENT_TEAM.LIST.VIEW],
          route: TeamsRoute,
        },
        {
          path: '/users/teams/:teamId',
          title: 'Team Management',
          private: true,
          hidden: true,
          roles: [
            APP_PERMISSION.COMMON.ADMINISTRATOR,
            APP_PERMISSION.USER.CONTENT_TEAM.DETAILS.VIEW,
            APP_PERMISSION.USER.CONTENT_TEAM.USERS.VIEW,
          ],
          route: SingleTeamRoute,
        },
      ],
    }),
    ...(enabledAppPermission && {
      'Roles & Permissions': [
        {
          path: '/users/roles',
          title: 'Roles management',
          menuName: 'Roles management',
          private: true,
          exact: true,
          roles: [
            APP_PERMISSION.COMMON.ADMINISTRATOR,
            APP_PERMISSION.USER.ROLE_MANAGEMENT.LIST.VIEW,
          ],
          route: RolesRoute,
        },
        {
          path: '/users/roles/:roleId',
          title: 'Role management',
          private: true,
          hidden: true,
          roles: [
            APP_PERMISSION.COMMON.ADMINISTRATOR,
            APP_PERMISSION.USER.ROLE_MANAGEMENT.OVERVIEW.VIEW,
          ],
          route: SingleRoleRoute,
        },
      ],
    }),
  };

  const pdpaMenus = [];
  for (const userPDPA of data) {
    pdpaMenus.push({
      path: `/users/pdpa/${userPDPA.id}`,
      title: userPDPA.name,
      menuName: userPDPA.name,
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, `${userPDPA.appPermissionId}-view`],
      route: () => (
        <UserPDPAReportRoute
          id={userPDPA.id}
          title={userPDPA.name}
          reportFields={userPDPA.reportFields}
        />
      ),
    });
  }
  menus['PDPA - User Consent'] = pdpaMenus;

  const visibleMenus = {};
  Object.keys(menus).forEach((header) => {
    visibleMenus[header] = menus[header].filter(({ hidden }) => !hidden);
  });
  return { menus, visibleMenus };
};

export default class UserRoute extends Component {
  render() {
    return (
      <QueryComponent query={getUserPDPAList} loadingOverlay>
        {(data) => {
          const { menus, visibleMenus } = genMenu(data.usGetUserPDPAList.userPDPAs);
          return (
            <ComplexSideMenuLayout
              header={'User Management'}
              menus={visibleMenus}
              contentProps={{ textAlign: 'left' }}
            >
              <GenSwitchRoute
                routes={[...flatMap(menus, (innerMenus) => innerMenus), defaultUser]}
                basePath={'/users/roles'}
              />
            </ComplexSideMenuLayout>
          );
        }}
      </QueryComponent>
    );
  }
}
