import Axios from 'axios';
import handlebars from 'handlebars';
import { useEffect, useState } from 'react';

export const useCustomAnnouncementTemplate = () => {
  const [htmlTemplate, setHtmlTemplate] = useState(undefined);

  useEffect(() => {
    /**
     * TODO: - check if request failed and show error
     *       - handle error case
     */
    (async () => {
      const res = await Axios.get('/assets/templates/custom-announcement.template.html');
      const template = handlebars.compile(res.data);
      // Set state using function because template is a function and react will call it before setting state
      setHtmlTemplate(() => {
        return template;
      });
    })();
  }, []);

  return { htmlTemplate };
};
