import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const FlexInput = styled(Flex)`
  & .react-datepicker__input-container {
    width: inherit;
  }
  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

const TimeInput = ({ value: initValue, timeLabel, onChange, readOnly }) => {
  const [time, setTime] = useState(initValue ? moment(initValue) : null);

  useEffect(() => {
    onChange({}, { value: { time: time } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <Box>
      <FlexInput alignItems="center">
        <DatePicker
          selected={time !== null ? moment(time) : null}
          placeholderText={timeLabel}
          dateFormat="DD/MM/YYYY HH:mm"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          value={time !== null ? moment(time) : null}
          onChange={(value) => setTime(value)}
          readOnly={readOnly}
        />
      </FlexInput>
    </Box>
  );
};

TimeInput.propTypes = {
  value: PropTypes.object,
  timeLabel: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  status: PropTypes.string,
};

TimeInput.defaultProps = {
  timeLabel: null,
};

export default withFormsy(TimeInput);
