import { ButtonText, Flex } from '@skooldio/skooldio-ui-components-core';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { Text as BaseText } from 'Components/Base';

import Card from '../Card/Card';

function getURL(baseURL) {
  try {
    const url = new URL(baseURL);
    return url.toString();
  } catch (_) {
    return window.location.protocol + '//' + baseURL;
  }
}

const Text = styled(BaseText)`
  line-height: 1.5;
  padding-left: 8px;
  word-break: break-word;
`;

const DownloadButton = styled((props) => (
  <ButtonText variant="small" color="dodgerBlue" {...props} />
))`
  border: none;
  padding: 0;
  height: auto;
  font-family: inherit;
`;

const SubmittedAssignmentDetail = ({ fileName, url, downloadFile }) => {
  return (
    <Card>
      <Flex p={3}>
        <InsertDriveFileIcon color="primary" />
        {fileName && (
          <>
            <Text style={{ flexGrow: 1 }}>{fileName}</Text>
            <DownloadButton onClick={downloadFile}>ดูไฟล์</DownloadButton>
          </>
        )}
        {url && (
          <Text as="a" href={getURL(url)}>
            {url}
          </Text>
        )}
      </Flex>
    </Card>
  );
};

SubmittedAssignmentDetail.propTypes = {
  fileName: PropTypes.string,
  url: PropTypes.string,
  downloadFile: PropTypes.func,
};

SubmittedAssignmentDetail.defaultProps = {
  downloadFile: () => {},
};

export default SubmittedAssignmentDetail;
