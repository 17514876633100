import { VideoUploadManager } from '@byteark/video-upload-sdk';

import getConfig from '../../Config';
import apolloClient from '../../../GraphQL';
import {
  createMultipleVideos,
  deleteMultipleVideos,
  generateVideoAccessToken,
  byteArkStreamCreateMultipleVideos,
  byteArkStreamDeleteMultipleVideos,
  byteArkStreamGenerateVideoAccessToken,
} from '../../../GraphQL/mutation/Video.mutation';
import { VIDEO_TYPE } from '../VideoAdapter';
import { courseOwnerEnabled, courseOwners } from '../../CourseOwner';

const {
  byteArkProjectId: PROJECT_ID,
  enabledByteArkStream: enabledStreamApi,
  byteArkStreamProjectKey,
} = getConfig();

const BYTEARK_CHANNEL = courseOwnerEnabled ? courseOwners : {};

const handleErrors = async (error, path) => {
  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }
  console.log(error.config);
};

export const uploadVideos = async (files, metadata, handleOnUploadProgress) => {
  const { courseOwnerId, courseCode } = metadata;
  const courseOwner = BYTEARK_CHANNEL[courseOwnerId]?.name;
  const accessToken = await generateAccessToken();
  if (!accessToken) {
    console.log('Error: Cannot get ByteArk Access Token');
    return;
  }
  const videos = files.map((file) => {
    const { name, size } = file;
    let video;
    if (enabledStreamApi) {
      video = {
        title: name,
        courseCode,
      };
    } else {
      video = {
        title: name,
        size,
        courseCode,
        projectId: PROJECT_ID,
      };
    }
    if (courseOwner) video['courseOwner'] = courseOwner;
    return video;
  });

  const { data } = await apolloClient.mutate({
    mutation: enabledStreamApi ? byteArkStreamCreateMultipleVideos : createMultipleVideos,
    refetchQueries: [],
    variables: enabledStreamApi
      ? {
          projectKey: byteArkStreamProjectKey,
          videos,
        }
      : {
          videos,
        },
  });

  const { createdVideos, success } = enabledStreamApi
    ? data.vdmByteArkCreateVideos
    : data.vdmCreateVideos;

  const uploadManager = new VideoUploadManager({
    serviceName: enabledStreamApi ? 'byteark.stream' : 'byteark.qoder',
    serviceEndpoint: enabledStreamApi
      ? 'https://stream.byteark.com'
      : `https://qoder.byteark.com/apps/${createdVideos[0].namespace}/ajax`,
    authorizationToken: accessToken,
    onUploadProgress: (job, progress) => {
      const { bytesUploaded, bytesTotal } = progress;
      files.forEach((file, idx) => {
        if (file.name === job.name) {
          handleOnUploadProgress(idx)(bytesUploaded, bytesTotal);
        }
      });
    },
    onUploadFailed: (job, error) => {
      handleErrors(error);
      createdVideos.map(async (createdVideo) => {
        const { videoKey, title } = createdVideo;
        if (title === job.name) {
          await deleteVideos([videoKey]);
        }
      });
    },
  });
  if (success) {
    return Promise.all(
      createdVideos.map(async (createdVideo, idx) => {
        const { sourceId, videoKey } = createdVideo;
        uploadManager.addUploadJob(enabledStreamApi ? videoKey : sourceId, files[idx]);
      }),
      await uploadManager.start()
    );
  }
  return [];
};

export const deleteVideos = async (videoKeys) => {
  const { data } = await apolloClient.mutate({
    mutation: enabledStreamApi ? byteArkStreamDeleteMultipleVideos : deleteMultipleVideos,
    refetchQueries: [],
    variables: {
      videoKeys,
    },
  });

  const { deletedVideos, success } = enabledStreamApi
    ? data.vdmByteArkDeleteVideos
    : data.vdmDeleteVideos;

  if (success) return deletedVideos;
  return [];
};

const generateAccessToken = async () => {
  const provider = VIDEO_TYPE.BYTEARK;
  const tokenDuration = 10;
  const response = await apolloClient
    .mutate({
      mutation: enabledStreamApi ? byteArkStreamGenerateVideoAccessToken : generateVideoAccessToken,
      refetchQueries: [],
      variables: {
        provider,
        tokenDuration,
      },
    })
    .catch((err) => console.error(err));
  if (response) {
    const { data } = response;
    const { accessToken } = enabledStreamApi
      ? data.vdmByteArkGenerateAccessToken
      : data.vdmGenerateAccessToken;

    return accessToken;
  }
  return null;
};
