import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import RichTextEditor from 'Components/RichTextEditor';
import withFormsy from 'Components/Form/withFormsy';

import delve from 'Util/Delve';

const EditorBox = styled(Box)`
  & .text-editor {
    width: 100%;
  }
`;

const CONTENT_TYPE = {
  HTML: 'HTML',
  MARKDOWN: 'MARKDOWN',
};

const getRichTextType = (type) => {
  switch (type) {
    case CONTENT_TYPE.HTML:
      return RichTextEditor.contentFormat.HTML;
    case CONTENT_TYPE.MARKDOWN:
    default:
      return RichTextEditor.contentFormat.MARKDOWN;
  }
};

const MarkupTextInput = ({ value, loading, name, onChange, onUpdate, ...inputProps }) => {
  const [content, setContent] = useState(delve(value, 'content'));
  const [type, setType] = useState(delve(value, 'type', CONTENT_TYPE.MARKDOWN));

  useEffect(() => {
    if (typeof onChange === 'function') onChange({}, { value: { content, type } });
  }, [content, onChange, type]);

  const handleUpdateButtonClick = () => {
    onUpdate({ content, type });
  };

  const handleEditorChange = (updatedContent) => {
    setContent(updatedContent);
  };

  return (
    <EditorBox>
      <RichTextEditor
        value={content}
        contentFormat={getRichTextType(type)}
        onChange={handleEditorChange}
        returnValueAsString
      />
      {onUpdate && (
        <Flex justifyContent="space-between" alignItems="center" my={3}>
          <Box />
          <Box>
            <Button
              primary
              circular
              onClick={handleUpdateButtonClick}
              loading={loading}
              disabled={loading}
            >
              Update
            </Button>
          </Box>
        </Flex>
      )}
    </EditorBox>
  );
};

MarkupTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(CONTENT_TYPE)),
      content: PropTypes.string,
    }),
  ]),
};

MarkupTextInput.defaultProps = {
  onChange: () => {},
};

MarkupTextInput.contentType = CONTENT_TYPE;

export { MarkupTextInput };
export default withFormsy(MarkupTextInput);
