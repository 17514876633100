import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import { Header, Form, Input, Icon, Dropdown, Accordion } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';

import { Box, Flex } from 'Components/Base';
import 'react-datepicker/dist/react-datepicker.css';

// import './override-react-datepicker.css';
import { SemanticButton as Button } from '../../Base/Button';

import TYPE from './constantType';
import ExportFile from './components/ExportFile';

const FLOAT_PATTERN_REGEX = '[0-9]+(.[0-9]+)?';
const FLOAT_TEXT = 'This field must be a decimal! e.g. 1, 1.2, 32.17';

const FixedBox = (props) => <Box px={2} py={2} width={[1, 1 / 2, 1 / 3, 1 / 4]} {...props} />;
const FlexCenter = (props) => (
  <Flex justifyContent="space-between" alignItems="center" {...props} />
);

const parseMoment = (input) => {
  if (input && moment(input).isValid()) {
    return moment(input);
  }
  return undefined;
};

const convertMomentToString = (mnt) => {
  if (moment(mnt).isValid()) return moment(mnt).format();
  return mnt;
};

const convertStringToFloat = (flt) => {
  return parseFloat(flt);
};

const convertVariable = (data, mapper) => {
  let submitData = { ...data };
  Object.keys(data).forEach((key) => {
    if (key in mapper) {
      const { start, end } = submitData[key];
      if (start) {
        submitData[key].start = mapper[key](start);
      }
      if (end) {
        submitData[key].end = mapper[key](end);
      }
    }
  });
  return submitData;
};

const DatePickerWithRange = ({
  placeholder = { start: 'From', end: 'To' },
  accessor,
  formInput,
  onChange,
}) => {
  const startDate = parseMoment(get(formInput, `${accessor}.start`));
  const endDate = parseMoment(get(formInput, `${accessor}.end`));
  return (
    <div>
      <DatePicker
        selected={startDate}
        placeholderText={placeholder.start}
        startDate={startDate}
        endDate={endDate}
        onChange={(date) =>
          onChange(accessor, { ...formInput[accessor], start: parseMoment(date) })
        }
        selectsStart
        showTimeSelect
        popperPlacement="bottom-start"
        dateFormat="LLL"
      />
      <Box pt={1} />
      <DatePicker
        selected={endDate}
        placeholderText={placeholder.end}
        startDate={startDate}
        endDate={endDate}
        onChange={(date) => onChange(accessor, { ...formInput[accessor], end: parseMoment(date) })}
        selectsEnd
        showTimeSelect
        popperPlacement="bottom-start"
        dateFormat="LLL"
      />
    </div>
  );
};

const TextFieldWithRange = ({
  placeholder = { start: 'From', end: 'To' },
  accessor,
  formInput,
  onChange,
  inline = true,
}) => {
  const widthSize = inline ? '45%' : '100%';
  const RangeContainer = inline ? FlexCenter : Box;

  return (
    <RangeContainer>
      <Input
        style={{ width: widthSize }}
        type="text"
        pattern={FLOAT_PATTERN_REGEX}
        placeholder={placeholder.start}
        onChange={(e) => onChange(accessor, { ...formInput[accessor], start: e.target.value })}
        onInvalid={(e) => e.target.setCustomValidity(FLOAT_TEXT)}
        onInput={(e) => e.target.setCustomValidity('')}
      />
      {inline ? '-' : <Box pt={1} />}
      <Input
        style={{ width: widthSize }}
        type="text"
        pattern={FLOAT_PATTERN_REGEX}
        placeholder={placeholder.end}
        onChange={(e) => onChange(accessor, { ...formInput[accessor], end: e.target.value })}
        onInvalid={(e) => e.target.setCustomValidity(FLOAT_TEXT)}
        onInput={(e) => e.target.setCustomValidity('')}
      />
    </RangeContainer>
  );
};

const SearchForm = ({
  location,
  history,
  search,
  columns,
  exportable,
  onSearch,
  onExport,
  ignoreSearchParams = false,
}) => {
  const [formInput, setFormInput] = useState({});
  const [activeIndex, setActiveIndex] = useState(-1);
  const [exportData, setExportData] = useState(null);
  const [exportDataLoading, setExportDataLoading] = useState(false);
  const [expandSearchPanelCheck, setExpandSearchPanelCheck] = useState(false);

  const handleResetForm = () => {
    setFormInput({});
  };

  const handleObjectChange = (key, value) => {
    let setData;
    const { start, end } = value;
    if (start) {
      setData = { start: start };
    }
    if (end) {
      setData = { ...setData, end: end };
    }
    handleChange(key, setData);
  };
  const handleChange = (key, value) => {
    if (value === undefined) {
      setFormInput(omit(formInput, key));
    } else {
      setFormInput((prev) => ({ ...prev, [key]: value }));
    }
  };
  const handleSubmit = (formData) => {
    let mapper = {};
    columns.forEach((col) => {
      if (col.type == TYPE.DATE) {
        mapper[col.accessor] = convertMomentToString;
      } else if (col.type == TYPE.NUMBER) {
        mapper[col.accessor] = convertStringToFloat;
      }
    });
    const submitData = convertVariable(formData, mapper);
    onSearch(submitData);
    handleSaveSearchCriteria(submitData);
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleGenerateCSV = throttle(
    async () => {
      setExportDataLoading(true);
      const result = await onExport();
      if (!result.error) {
        setExportData(result);
        setExportDataLoading(false);
      } else {
        setExportData(null);
        setExportDataLoading(false);
      }
    },
    1000,
    { trailing: true }
  );

  const handleExportCSV = () => {
    setExportData(null);
    setExportDataLoading(false);
  };

  const handleSaveSearchCriteria = (search) => {
    const stringifiedSearchObject = {};
    for (const column of columns) {
      if (column.isSearchAble && column.accessor in search) {
        const value = search[column.accessor];
        // PAAS-3563 field Visibility in OnlineCourseRoute use boolean value (isVisible) so 'false' should be accepted
        if (value || value === false) {
          Object.assign(stringifiedSearchObject, {
            [column.accessor]: JSON.stringify(search[column.accessor]),
          });
        }
      }
    }

    if (!ignoreSearchParams) {
      history.push(
        `${location.pathname}?${new URLSearchParams(stringifiedSearchObject).toString()}`
      );
    }
  };

  const generateForm = (
    {
      Header,
      accessor,
      searchField,
      type,
      isSearchAble,
      enumOptions,
      placeholder,
      inline,
      customSearchField,
      CustomSearchComponent,
    },
    i
  ) => {
    const actualSearchField =
      searchField !== undefined && searchField !== null ? searchField : accessor;
    if (isSearchAble) {
      switch (type) {
        case TYPE.STRING:
          return (
            <FixedBox key={i + accessor}>
              <Form.Field key={i}>
                <label>{Header}</label>
                <Input
                  type="text"
                  value={formInput[accessor]}
                  placeholder={Header}
                  onChange={(e) => handleChange(actualSearchField, e.target.value)}
                />
              </Form.Field>
            </FixedBox>
          );

        case TYPE.DATE:
          return (
            <FixedBox key={i + accessor}>
              <Form.Field key={i}>
                <label>{Header}</label>
                <DatePickerWithRange
                  placeholder={placeholder}
                  accessor={actualSearchField}
                  onChange={handleObjectChange}
                  formInput={formInput}
                />
              </Form.Field>
            </FixedBox>
          );

        case TYPE.NUMBER:
        case TYPE.PERCENT_RATE:
          return (
            <FixedBox key={i + accessor}>
              <Form.Field key={i}>
                <label>{Header}</label>
                <TextFieldWithRange
                  placeholder={placeholder}
                  accessor={actualSearchField}
                  onChange={handleObjectChange}
                  formInput={formInput}
                  inline={inline}
                />
              </Form.Field>
            </FixedBox>
          );

        case TYPE.ENUM: {
          const optionsWithUnselect = [
            { key: 'unselected', value: '', text: '--- NONE ---' },
            ...enumOptions,
          ];
          return (
            <FixedBox key={i + accessor}>
              <Form.Field key={i}>
                <label>{Header}</label>
                <Dropdown
                  placeholder={Header}
                  fluid
                  search
                  selection
                  options={optionsWithUnselect}
                  value={formInput[accessor]}
                  onChange={(e, data) =>
                    handleChange(actualSearchField, data.value !== '' ? data.value : undefined)
                  }
                />
              </Form.Field>
            </FixedBox>
          );
        }

        case TYPE.CUSTOM:
          return (
            <FixedBox key={i + accessor}>
              <Form.Field>
                <label>{Header}</label>
                {customSearchField ? (
                  customSearchField({
                    placeholder: Header,
                    value: formInput[accessor],
                    onChange: (e, data) => handleChange(actualSearchField, data.value),
                    formInput: formInput,
                  })
                ) : (
                  <CustomSearchComponent
                    placeholder={Header}
                    value={formInput[accessor]}
                    onChange={(e, data) => {
                      handleChange(actualSearchField, data.value);
                    }}
                    formInput={formInput}
                  />
                )}
              </Form.Field>
            </FixedBox>
          );

        default:
          break;
      }
    }
    return;
  };

  useEffect(() => {
    setFormInput(search);
  }, [search]);

  useEffect(() => {
    setActiveIndex(expandSearchPanelCheck ? 0 : -1);
  }, [expandSearchPanelCheck]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const searchObjectFromParams = {};

    if (!ignoreSearchParams) {
      for (const column of columns) {
        if (column.isSearchAble && query.has(column.accessor)) {
          const value = query.get(column.accessor);
          const resolvedValue = JSON.parse(value);
          Object.assign(searchObjectFromParams, { [column.accessor]: resolvedValue });
        }
      }
    }

    setFormInput(searchObjectFromParams);
    setExpandSearchPanelCheck(!isEmpty(searchObjectFromParams));
    onSearch(searchObjectFromParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Accordion styled fluid>
      <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
        <Header textAlign="left">
          <Icon name="dropdown" />
          Search
        </Header>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0} style={{ textAlign: 'left' }}>
        <Form onSubmit={() => handleSubmit(formInput)}>
          <Flex flexWrap="wrap" mx={1}>
            {columns.map((column, i) => generateForm(column, i))}
          </Flex>
          <Flex pt={2} px={3}>
            {exportable && (
              <ExportFile
                data={exportData}
                loading={exportDataLoading}
                onClickGenerate={handleGenerateCSV}
                onClickExport={handleExportCSV}
                style={{ marginRight: 'auto' }}
              />
            )}
            <Form.Group style={{ marginLeft: 'auto' }}>
              <Button primary circular type="submit">
                <Icon name="search" />
                Search
              </Button>
              <Button circular type="reset" onClick={handleResetForm}>
                <Icon name="repeat" />
                Reset
              </Button>
            </Form.Group>
          </Flex>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

SearchForm.prototype = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(TYPE)).isRequired,
      isSearchAble: PropTypes.bool,
      enumOptions: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
          text: PropTypes.string.isRequired,
        })
      ),
      placeholder: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
      }),
      inline: PropTypes.bool,
      searchField: PropTypes.string,
    })
  ).isRequired,
  search: PropTypes.object,
};

export default withRouter(SearchForm);
