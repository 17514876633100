import { useEffect } from 'react';

import { convertStringToValue } from 'Components/RichTextEditor';

export const getBlogSlugFromUrl = (url) => {
  const slitted = url.split('/');
  const slug = slitted[slitted.length - 1]
    ? slitted[slitted.length - 1]
    : slitted[slitted.length - 2];
  return slug;
};

export const useRawContent = (rawContent, setCards) => {
  useEffect(() => {
    let state;
    try {
      state = JSON.parse(rawContent);
    } catch (e) {
      return;
    }
    const newCards = state.map((s, idx) => {
      switch (s.type) {
        case 'TEXT':
          return {
            id: idx,
            type: s.type,
            text: s.text,
          };
        case 'BLOG':
          return {
            id: idx,
            type: s.type,
            url: s.url,
            slug: getBlogSlugFromUrl(s.url),
          };
        case 'RTE':
          return {
            id: idx,
            type: s.type,
            value: convertStringToValue(s.html, 'html'),
            html: s.html,
          };
        case 'IMAGE':
          return {
            id: idx,
            type: s.type,
            imageUrl: s.imageUrl,
            link: s.link,
          };
        default:
          return null;
      }
    });
    setCards(newCards);
  }, [rawContent, setCards]);
};
