import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import { useTheme } from 'styled-components';

import { Box } from 'Components/Base';
import LegacyHTMLEditor, {
  unescapeAndSanitizeHTMLSection,
  preprocessLegacyHTMLEditorInput,
  LegacyHTMLEditorPreviewModal,
} from 'Components/LegacyHTMLEditor';

import MaterialMeta from '../Common/MaterialMeta';
import ActionBar from '../Common/ActionBar';
import { AssetPropTypes } from '../Common/AssetPropTypes';
import ButtonView from '../../Base/Button/ButtonView';

const ARTICLE_DATA_TYPE = {
  HTML: 'HTML',
  // MARKDOWN: 'text/markdown', // Enable when we have markdown editor
};

const convertToRawData = (article) => ({
  type: ARTICLE_DATA_TYPE.HTML,
  article,
});

const useStyle = makeStyles({
  root: ({ fonts }) => ({
    marginBottom: '16px',
    fontFamily: `${fonts?.sans}`,
  }),
});

const Alert = (props) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  return <MuiAlert classes={classes} {...props} />;
};
class ArticleEditor extends Component {
  state = {
    article: this.props.content && this.props.content.article,
    form: { title: this.props.title, description: this.props.description },
    isOpen: false,
  };
  handleFormChange = (value) => {
    this.setState({ formData: value });
  };
  handleArticleChange = (data) => {
    this.setState({ article: data });
  };
  handleAction = () => {
    const { formData, article } = this.state;
    const sanitizedArticle = unescapeAndSanitizeHTMLSection(article);
    const resultObject = {
      ...formData,
      content: convertToRawData(sanitizedArticle),
    };

    this.props.onCreateMaterial(resultObject);
  };
  setIsOpen = (bool) => {
    this.setState({ isOpen: bool });
  };

  componentDidMount() {
    // escape html in the article's HTML sections
    this.setState({ article: preprocessLegacyHTMLEditorInput(this.state.article) });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ data: this.props.content && this.props.content.article });
    }
  }
  render() {
    const { title, description, materialTypeString, newAsset, loading, onCancel } = this.props;
    const { article, isOpen } = this.state;
    return (
      <Box>
        <Box>
          <MaterialMeta title={title} description={description} onChange={this.handleFormChange} />
        </Box>
        <Divider hidden />
        <Box>
          <Alert severity="info">
            <AlertTitle>การเพิ่มส่วนสำหรับใช้งาน HTML</AlertTitle>
            <p>
              เลือก icon HTML ตรงบริเวณ toolbar เพื่อให้ได้ส่วนในการเขียน HTML ขึ้นมา
              <br />
              ให้จัดรูปแบบตัวอักษรให้บริเวณเขียน HTML ทั้งหมดให้แสดงผลเป็น normal เท่านั้น
              <br />
              หรือ ให้ทำการกำหนดขอบเขตที่ใช้แทรก HTML ขึ้นมา โดยมีรูปแบบการกำหนดขอบเขตดังนี้
              <br />
              {'<div data-testid="html-section">'}
              <br />
              {'...แทรกโค้ด HTML บริเวณนี้...'}
              <br />
              {'</div>'}
            </p>
          </Alert>
        </Box>

        <LegacyHTMLEditorPreviewModal
          trigger={<ButtonView primary icon="eye" title="Preview article" />}
          editorContent={unescapeAndSanitizeHTMLSection(this.state.article)}
          isOpen={isOpen}
          setIsOpen={this.setIsOpen}
        />
        <LegacyHTMLEditor
          value={article}
          mode={LegacyHTMLEditor.MODE.ADVANCE}
          onChange={this.handleArticleChange}
        />
        <Box mt={2}>
          <ActionBar
            isCreate={newAsset}
            onAction={this.handleAction}
            materialTypeString={materialTypeString}
            onCancel={onCancel}
            loading={loading}
          />
        </Box>
      </Box>
    );
  }
}

ArticleEditor.propTypes = {
  ...AssetPropTypes,
  content: PropTypes.shape({
    article: PropTypes.string,
    type: PropTypes.oneOf(Object.values(ARTICLE_DATA_TYPE)),
  }),
};

ArticleEditor.defaultProps = {
  materialTypeString: 'Article',
};

export default ArticleEditor;
