import { createAction } from 'redux-actions';

export const USER_PROFILE_SET_PROFILE = 'USER_PROFILE/SET_PROFILE';
export const USER_PROFILE_SET_PROFILE_LOADING = 'USER_PROFILE/SET_PROFILE_LOADING';
export const USER_PROFILE_VALIDATE_LOGIN = 'USER_PROFILE/VALIDATE_LOGIN';

export const USER_PROFILE_GETPROFILE = 'USER_PROFILE/GET_PROFILE';
export const USER_PROFILE_GETPROFILE_REQUEST = 'USER_PROFILE/GET_PROFILE/REQUEST';
export const USER_PROFILE_GETPROFILE_SUCCESS = 'USER_PROFILE/GET_PROFILE/SUCCESS';
export const USER_PROFILE_GETPROFILE_FAIL = 'USER_PROFILE/GET_PROFILE/FAIL';

export const USER_PROFILE_LOGIN = 'USER_PROFILE/LOGIN';
export const USER_PROFILE_LOGIN_REQUEST = 'USER_PROFILE/LOGIN/REQUEST';
export const USER_PROFILE_LOGIN_SUCCESS = 'USER_PROFILE/LOGIN/SUCCESS';
export const USER_PROFILE_LOGIN_FAIL = 'USER_PROFILE/LOGIN/FAIL';

export const USER_PROFILE_LOGOUT = 'USER_PROFILE/LOGOUT';
export const USER_PROFILE_LOGOUT_REQUEST = 'USER_PROFILE/LOGOUT/REQUEST';
export const USER_PROFILE_LOGOUT_SUCCESS = 'USER_PROFILE/LOGOUT/SUCCESS';
export const USER_PROFILE_LOGOUT_FAIL = 'USER_PROFILE/LOGOUT/FAIL';

export const setProfile = createAction(USER_PROFILE_SET_PROFILE);
export const setProfileLoading = createAction(USER_PROFILE_SET_PROFILE_LOADING);
export const validateLogin = createAction(USER_PROFILE_VALIDATE_LOGIN);

export const getProfile = createAction(USER_PROFILE_GETPROFILE);

export const login = createAction(USER_PROFILE_LOGIN);
// export const loginSuccess = createAction(USER_PROFILE_LOGIN_SUCCESS);
// export const loginFail = createAction(USER_PROFILE_LOGIN_FAIL);
export const logout = createAction(USER_PROFILE_LOGOUT);
