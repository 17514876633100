import moment from 'moment';
import _ from 'lodash';

import fetch2, { METHOD } from '../../fetch';
import getConfig from '../../Config';

const YOUTUBE_API = getConfig().youtubeApi;
const YOUTUBE_API_KEY = getConfig().youtubeApiKey;
const YOUTUBE_REGEX = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;

const YOUTUBE_PART = {
  snippet: 'snippet',
  contentDetails: 'contentDetails',
  statistics: 'statistics',
  player: 'player',
};

const validateLink = (url) => {
  return url.match(YOUTUBE_REGEX);
};

const getVideoById = (id, partArr) => {
  const part = partArr.join(',');
  const params = {
    id,
    part,
    key: YOUTUBE_API_KEY,
  };
  return fetch2(`${YOUTUBE_API}/videos`, params, METHOD.GET, undefined, { parseJson: true }, false);
};

export const extractIdFromUrl = (input) => {
  let youtubeId = '';
  let splitParam = [];
  if (validateLink(input)) {
    splitParam = input.split('v=');
    if (splitParam.length < 2) {
      splitParam = input.split('youtu.be/');
    }
    youtubeId = splitParam[1];
    if (youtubeId && youtubeId.indexOf('&') !== -1) {
      youtubeId = youtubeId.split('&')[0];
    }
  }
  return youtubeId;
};

export const customDurationFormat = (rawDuration) => {
  const duration = moment.duration(rawDuration);
  const h = duration.hours();
  const m = duration.minutes();
  const s = duration.seconds();
  return moment({ hour: h, minute: m, second: s }).format('HH:mm:ss');
};

export const extractVideoInfo = (videoInfo) => {
  if (!videoInfo || videoInfo.items.length === 0) return { found: false };

  const { contentDetails, snippet } = videoInfo.items[0];
  const { duration: rawDuration } = contentDetails;
  const totalTime = {
    second: moment.duration(rawDuration).asSeconds(),
    raw: rawDuration,
  };
  const { thumbnails } = snippet;
  const detail = _.omit(snippet, ['thumbnails']);
  return { totalTime, thumbnails, detail, found: true };
};

export const getVideoDetailById = async (id) => {
  const part = [YOUTUBE_PART.snippet, YOUTUBE_PART.contentDetails];
  const videoDetail = await getVideoById(id, part).then((video) => extractVideoInfo(video));
  return videoDetail;
};
