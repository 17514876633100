import _ from 'lodash';

export const PAYMENT_METHOD = {
  QRCODE: 'QRCODE',
  CREDIT_CARD: 'CC',
  ATM: 'ATM',
  IN_APP_PURCHASE_IOS: 'IN_APP_PURCHASE_IOS',
  IN_APP_PURCHASE_ANDROID: 'IN_APP_PURCHASE_ANDROID',
  COUNTER_SERVICE: 'COUNTER_SERVICE',
};

export const PAYMENT_CURRENCY = {
  THB: 'THB',
};

export const SALES_ORDER_FORM_TYPE = {
  SINGLE_CUST: 'SINGLE_CUST',
  MULTIPLE_CUST: 'MULTIPLE_CUST',
};

export const SALES_ORDER_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  VOID: 'VOID',
};

export const SKU_CATEGORY = {
  ALL: 'ALL',
  WORKSHOP: 'WORKSHOP',
  WORKSHOP_BATCH: 'WORKSHOP_BATCH',
  ONLINE_COURSE: 'ONLINE_COURSE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  COLLECTION: 'COLLECTION',
  BOOK: 'BOOK',
};

export const SKU_TYPE = {
  BASE: 'BASE',
  STANDARD: 'STANDARD',
  COLLECTION: 'COLLECTION',
  BUNDLE: 'BUNDLE',
  VIRTUAL: 'VIRTUAL',
};

export const CUSTOMER_TYPE = {
  ALL: 'ALL',
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATE: 'CORPORATE',
};

export const PRODUCT_TYPE = {
  ONLINE_COURSE: 'ONLINE_COURSE',
  BUNDLE: 'BUNDLE',
};

export const ProductTypesCheckBoxs = [
  {
    label: 'ทุกคอร์ส',
    productType: PRODUCT_TYPE.ONLINE_COURSE,
  },
  {
    label: 'ทุก Bundle',
    productType: PRODUCT_TYPE.BUNDLE,
  },
];

export const salesOrderFormTypeOptions = _.map(SALES_ORDER_FORM_TYPE, (value, key) => ({
  key,
  value,
  text: key,
}));
export const paymentMethodOptions = _.map(PAYMENT_METHOD, (value, key) => ({
  key,
  value,
  text: key,
}));
export const paymentCurrencyOptions = _.map(PAYMENT_CURRENCY, (value, key) => ({
  key,
  value,
  text: key,
}));

export const SKUCategoryOptions = _.map(SKU_CATEGORY, (value, key) => ({
  key,
  value,
  text: key,
}));

export const SKUTypeOptions = _.map(SKU_TYPE, (value, key) => ({
  key,
  value,
  text: key,
}));

export const CustomerTypeOptions = _.map(
  _.omit(CUSTOMER_TYPE, [CUSTOMER_TYPE.ALL]),
  (value, key) => ({
    key,
    value,
    text: key,
  })
);

export const EXPIRY_CONDITION = {
  DAYS_VALIDITY: 'DAYS_VALIDITY',
  EXPIRE_DATE: 'EXPIRE_DATE',
};

export const EXPIRY_CONDITION_OPTIONS = [
  { key: 'unselected', value: 'unselected', text: '--- NONE ---' },
  {
    key: EXPIRY_CONDITION.DAYS_VALIDITY,
    value: EXPIRY_CONDITION.DAYS_VALIDITY,
    text: 'จำกัดจำนวนวันในการเรียน (Day Limit)',
  },
  {
    key: EXPIRY_CONDITION.EXPIRE_DATE,
    value: EXPIRY_CONDITION.EXPIRE_DATE,
    text: 'จำกัดวันที่เรียน (Expiry Date)',
  },
];

export const CUSTOMER_TYPE_OPTIONS = [
  { key: CUSTOMER_TYPE.ALL, value: CUSTOMER_TYPE.ALL, text: 'ลูกค้าทุกประเภทสามารถใช้ได้' },
  { key: CUSTOMER_TYPE.INDIVIDUAL, value: CUSTOMER_TYPE.INDIVIDUAL, text: 'ลูกค้าบุคคลเท่านั้น' },
  { key: CUSTOMER_TYPE.CORPORATE, value: CUSTOMER_TYPE.CORPORATE, text: 'ลูกค้าองค์กรเท่านั้น' },
];

export const MINIMUM_QUANTITY_OPTIONS = [
  {
    key: 'unselected',
    value: 'unselected',
    text: 'ไม่กำหนดเงื่อนไขนี้ (ลดราคาของออเดอร์เท่ากับมูลค่าโค้ด)',
  },
  ...[1, 2, 3, 4, 5].map((item) => {
    return { key: `minimumQty${item}`, value: item, text: `สำหรับลูกค้า ${item} ท่านขึ้นไป` };
  }),
];

export const DISCOUNT_UNIT = {
  FIX: 'FIX',
  PERCENT: 'PERCENT',
};
