export const FileImportPermissionKeys = Object.freeze({
  DISCOUNT_CODE: {
    LIST: {
      VIEW: 'fileImport.discountCode.list-view',
      UPDATE: 'fileImport.discountCode.list-update',
      DELETE: 'fileImport.discountCode.list-delete',
    },
  },
  SHIPMENT_TRACKING: {
    LIST: {
      VIEW: 'fileImport.shipmentTracking.list-view',
      UPDATE: 'fileImport.shipmentTracking.list-update',
      DELETE: 'fileImport.shipmentTracking.list-delete',
    },
  },
});
