import React from 'react';
import DeleteIcon from '@material-ui/icons/Remove';

import { Flex, ButtonOutlined } from 'Components/Base';

const UnAssignRoleButton = (props) => {
  return (
    <ButtonOutlined
      color="primary"
      style={{ cursor: 'pointer' }}
      px={3}
      py={2}
      fontWeight="600"
      fontSize="14px"
      {...props}
    >
      <Flex alignItems="center">
        <DeleteIcon style={{ width: '16px', marginBottom: '2px' }} />
        <span>Unassign</span>
      </Flex>
    </ButtonOutlined>
  );
};

export default UnAssignRoleButton;
