import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { getTotalAmountSumExcludeVAT } from 'Routes/SalesV2/GraphQL/query/Payment.query';

import { TotalAmountSumBox } from '.';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(324px, 1fr));
  grid-gap: 16px;
`;

const resolveData = (data) => {
  return data?.sfBoPaymentTotalAmountSumExcludeVAT?.totalAmountExcludeVAT;
};

const TotalAmountSumContainer = ({ search }) => {
  const { data, loading, error } = useQuery(getTotalAmountSumExcludeVAT, {
    variables: {
      search,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container>
      <TotalAmountSumBox
        text={`Total Amount Exclude VAT & WHT (THB)`}
        value={resolveData(data)}
        loading={loading}
        error={error}
      />
    </Container>
  );
};

export default TotalAmountSumContainer;
