import React, { Component } from 'react';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { GenRoute } from '../../Util/Route';

import AllCourses from './AllCourses';
import EditCourse from './EditCourse';
import { coursesPath, editCoursePath } from './Domain/coursePaths';

const menus = [
  {
    path: coursesPath,
    title: 'All Courses',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.INSTRUCTOR,
      APP_PERMISSION.COURSE.LIST.VIEW,
      APP_PERMISSION.COURSE_TEAM.LIST.VIEW,
    ],
    route: AllCourses,
  },
  {
    path: editCoursePath,
    title: 'Edit Courses',
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.INSTRUCTOR,
      APP_PERMISSION.COURSE.OVERVIEW.VIEW,
      APP_PERMISSION.COURSE_TEAM.OVERVIEW.VIEW,
    ],
    route: EditCourse,
  },
];

export default class CourseRoute extends Component {
  render() {
    return <GenRoute menus={menus} />;
  }
}
