import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';
import getConfig from 'Util/Config';
import getInterestValue from 'Util/LSP/getInterestValue';

import ProfileAvatarUploadForm from '../ProfileAvatarUploadForm';

const fields = (schoolGradeData, schoolProgrammeData, careerPlanData) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'รหัสนักเรียน',
    inputName: 'code',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'คำนำหน้า',
    inputName: 'prefix',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ชื่อ',
    inputName: 'firstName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'นามสกุล',
    inputName: 'lastName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ชื่อเล่น',
    inputName: 'nickName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'ระดับชั้น',
    inputName: 'schoolGradeId',
    options: schoolGradeData.map(({ id, name }) => ({ key: id, value: id, text: name })),
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'สายการเรียน',
    inputName: 'schoolProgrammeId',
    options: schoolProgrammeData.map(({ id, name }) => ({ key: id, value: id, text: name })),
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'แผนอาชีพ',
    inputName: 'careerPlanIds',
    options: careerPlanData.map(({ id, name }) => ({ key: id, value: id, text: name })),
    inputProps: {
      required: true,
      multiple: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ผู้ปกครอง',
    inputName: 'parentDisplayName',
    inputProps: {
      disabled: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'อาจารย์ที่ปรึกษา',
    inputName: 'advisorTeacherDisplayName',
    inputProps: {
      disabled: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Classroom Username',
    inputName: 'classroomUsername',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Username',
    inputName: 'username',
    inputProps: {
      // @TODO: Update this
      // required: true,
      disabled: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Email',
    inputName: 'email',
    inputProps: {
      // @TODO: Update this
      // required: true,
      disabled: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'เบอร์โทร',
    inputName: 'phoneNumber',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
    inputLabel: 'ที่อยู่',
    inputName: 'address1',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'เขต/อำเภอ',
    inputName: 'district',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'จังหวัด',
    inputName: 'province',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'รหัสไปรษณีย์',
    inputName: 'zipCode',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'ความสนใจ',
    inputName: 'interests',
    options: getConfig().lsp.studentInterestOptions.map(({ value, iconName, iconType }) => ({
      key: value,
      value: getInterestValue({ value, iconName, iconType }),
      text: value,
    })),
    inputProps: {
      required: true,
      multiple: true,
    },
  },
];

export default class StudentProfileForm extends Component {
  static propTypes = {
    onCreateStudentProfile: PropTypes.func,
    onUploadProfileImage: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateStudentProfile: () => {},
    onUploadProfileImage: () => {},
    isEdit: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
    };
  }

  handleUploadProfileImage = ({ key, bucket, url }) => {
    this.setState({ avatar: url });
    this.props.onUploadProfileImage({ key, bucket });
  };

  handleSubmit = (form) => {
    this.props.onCreateStudentProfile(form);
  };

  render() {
    const {
      isEdit,
      initialData,
      schoolGradeData,
      schoolProgrammeData,
      careerPlanData,
    } = this.props;
    const { avatar } = this.state;
    return (
      <Fragment>
        {isEdit && (
          <ProfileAvatarUploadForm
            userId={initialData.id}
            avatar={avatar || initialData.avatar}
            editMode={true}
            onSuccess={this.handleUploadProfileImage}
          />
        )}
        <FormGenerator
          fields={fields(schoolGradeData, schoolProgrammeData, careerPlanData)}
          onSubmit={this.handleSubmit}
          submitText={isEdit ? 'Update' : 'Add'}
          cancelText="Cancel"
          showCancel
          {...this.props}
        />
      </Fragment>
    );
  }
}
