import React from 'react';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBook } from '../../GraphQL/query/Products.query';
import { updateBookCategoriesMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUCategoriesForm from '../SKUCategoriesForm';
import { SKU_CATEGORY } from '../../Domains/constants';

const BookCategoriesEditor = (props) => {
  const { book = {} } = props;
  const { id, SKUCode } = book;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getBook}
        editMutation={updateBookCategoriesMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({ SKUCode })}
        getEditVariables={(form) => ({ id, data: { ...form } })}
      >
        {({ data, onSubmit }) => (
          <SKUCategoriesForm
            skuCategory={SKU_CATEGORY.BOOK}
            initialData={data?.sfBoProductBook}
            onSubmit={onSubmit}
          />
        )}
      </CreateEditForm>
    </Box>
  );
};

export default BookCategoriesEditor;
