import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import FormGenerator from 'Components/Form/FormGenerator';

import Editor from '../../../../Components/MarkupTextInput';
import TestimonialsMediaUpload, {
  MEDIA_TYPE,
  VIDEO_TYPE,
} from '../../Components/TestimonialsContent/TestimonialsMediaUpload';
const noop = () => {};

const genFields = (skuCode) => {
  return [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Media',
      inputName: 'media',
      customInput: ({ name }) => <TestimonialsMediaUpload name={name} skuCode={skuCode} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Quote',
      inputName: 'quote',
      customInput: ({ name }) => <Editor name={name} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Reviewer',
      inputName: 'reviewer',
      customInput: ({ name }) => <Editor name={name} />,
    },
  ];
};

export default class SectionContent extends Component {
  static propTypes = {
    skuCode: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    media: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
      content: PropTypes.shape({
        videoType: PropTypes.oneOf(Object.values(VIDEO_TYPE)),
        key: PropTypes.string,
        bucket: PropTypes.string,
      }),
    }),
    quote: PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
    }),
    reviewer: PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
    }),
    onUpdateMeta: PropTypes.func,
    onClose: PropTypes.func,
    onFormDirty: PropTypes.func,
  };

  static defaultProps = {
    onUpdateMeta: noop,
    onClose: noop,
    onFormDirty: noop,
  };

  render() {
    const { skuCode, media, quote, reviewer, onUpdateMeta, onClose, onFormDirty } = this.props;
    return (
      <Flex flexDirection="column">
        <Segment vertical>
          <FormGenerator
            fields={genFields(skuCode)}
            submitText="Update Section"
            onSubmit={onUpdateMeta}
            cancelText="Reset"
            onCancelled={onClose}
            initialData={{ quote, reviewer, media }}
            onChange={onFormDirty}
            compact
          />
        </Segment>
      </Flex>
    );
  }
}
