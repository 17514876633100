import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

export const FORUM_TYPE = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

const ForumTypeInput = ({ value: initValue, radioList, onChange, readOnly }) => {
  const [type, setType] = useState(initValue);

  useEffect(() => {
    setType(initValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  useEffect(() => {
    onChange({}, { value: type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Flex alignItems="center">
      {radioList.map(({ label, value }, idx) => (
        <Box mr={3} key={idx}>
          <Radio
            key={idx}
            label={label}
            name="type"
            value={value}
            checked={type === value}
            onChange={(_, { value: newValue }) => setType(newValue)}
            readOnly={readOnly}
          />
        </Box>
      ))}
    </Flex>
  );
};

ForumTypeInput.propTypes = {
  value: PropTypes.string,
  radioList: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(ForumTypeInput);
