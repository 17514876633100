import React, { useEffect, useRef, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import Autocomplete from 'react-google-autocomplete';

import { Box } from 'Components/Base';

import GoogleMap from './GoogleMap';

const MapSearch = ({ placeId, placeName, apiKey, onVenueSelected }) => {
  const [venue, setVenue] = useState(null);
  const [googleMapLoaded, setGoogleMapLoaded] = useState(false);
  const mapRef = useRef();
  useEffect(() => {
    if (googleMapLoaded && placeId) {
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          placeId: placeId,
        },
        (results, status) => {
          // eslint-disable-next-line no-undef
          if (status === google.maps.GeocoderStatus.OK) {
            if (results.length > 0) {
              // Result will not have name
              // TODO: google.maps.places.PlacesService
              console.log(results);
              setVenue({ ...results[0], name: placeName });
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        }
      );
    }
    return () => {};
  }, [googleMapLoaded, placeId, placeName]);
  const onLoad = () => {
    setGoogleMapLoaded(true);
  };
  return (
    <Fragment>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`}
        onLoad={onLoad}
      ></Script>
      {googleMapLoaded && (
        <Box>
          <Autocomplete
            style={{ width: '100%' }}
            onPlaceSelected={(place) => {
              console.log(place);
              setVenue(place);
              onVenueSelected(place);
            }}
            types={['geocode', 'establishment']}
            componentRestrictions={{ country: 'th' }}
            fields={[
              'address_components',
              'geometry.location',
              'place_id',
              'formatted_address',
              'name',
            ]}
          />
          <GoogleMap place={venue} ref={mapRef} />
        </Box>
      )}
    </Fragment>
  );
};

MapSearch.propTypes = {
  apiKey: PropTypes.string.isRequired,
  onVenueSelected: PropTypes.func,
};

MapSearch.defaultProps = {
  apiKey: 'AIzaSyCVsg-5JYyALpRvAurDEX1V8-vwtG59m5s',
  onVenueSelected: () => {},
};

export default MapSearch;
