import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import {
  Search,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Add,
  Check,
  FilterList,
  Remove,
  Edit,
  Clear,
  Delete,
  Save,
  ArrowUpward,
} from '@material-ui/icons';
const materialTableIcons = {
  Add: Add,
  ViewColumn: ViewColumn,
  Check: Check,
  DetailPanel: ChevronRight,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  Search: Search,
  ThirdStateCheck: Remove,
  Edit: Edit,
  Delete: Delete,
  Clear: Clear,
  ResetSearch: Clear,
  SortArrow: ArrowUpward,
};

const EditTable = ({ title, columns, data, onSaveClick }) => {
  const [values, setValues] = useState(data);

  useEffect(() => {
    setValues(data);
  }, [data]);

  const handlePublish = () => {
    onSaveClick(values);
  };
  const handleRowAdd = async (newRowData) => {
    const data = [...values, newRowData];
    setValues(data);
  };
  const handleRowUpdate = async (newRowData, { tableData }) => {
    if (tableData) {
      const data = [...values];
      data[tableData.id] = newRowData;
      setValues(data);
    }
  };
  const handleRowDelete = async ({ tableData }) => {
    const data = [...values];
    data.splice(tableData.id, 1);
    setValues(data);
  };

  return (
    <MaterialTable
      title={title}
      icons={materialTableIcons}
      columns={columns}
      data={values}
      options={{
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowAdd: handleRowAdd,
        onRowUpdate: handleRowUpdate,
        onRowDelete: handleRowDelete,
      }}
      actions={[
        {
          icon: Save,
          tooltip: 'Publish',
          isFreeAction: true,
          onClick: handlePublish,
        },
      ]}
    />
  );
};
EditTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  onSaveClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
EditTable.defaultProps = { title: '', data: [], onSaveClick: () => {} };

export default EditTable;
