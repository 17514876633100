import React, { Fragment, useState, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Form,
  Input,
  Icon,
  List,
  Button,
  Checkbox,
  Radio,
  Menu,
  MenuItem,
} from 'semantic-ui-react';
import { video } from 'react-dom-factories';

import { Box, Flex, Text } from 'Components/Base';
import ConfirmationButton from 'src/Components/ConfirmationButton';
import { FONT_SIZE, COLOR } from 'Components/Theme';
import { customDurationFormat } from 'src/Util/ExternalAdapter/VideoAdapter/utils';
import getConfig from 'Util/Config';

import COLUMN_TYPE from '../../Search/SearchFormGenerator/constantType';

const FixedBox = (props) => <Box px={2} py={2} width={[1, 1 / 2, 1 / 3, 1 / 4]} {...props} />;

const ListContainer = styled(List)`
  height: 350px;
  overflow-y: auto;
  margin-bottom: 2px;
`;

const ListItem = styled(List.Item)`
  padding-top: 15px !important;
  padding-bottom: 15px !important;
`;

const ListHeader = styled(Box)`
  padding: 0.5em 0.5em;
`;

const columns = [
  {
    Header: 'Section Tag',
    accessor: 'section',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Video Name',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
];

const getStartPage = (currentPage, lastPage) => {
  if (lastPage <= 3 || currentPage === 1) {
    return 1;
  } else if (currentPage === lastPage) {
    return currentPage - 2;
  } else {
    return currentPage - 1;
  }
};

const initSelectAllValue = (selectedVideo, videos) => {
  const videosKeySet = videos
    .filter(({ duration }) => typeof duration === 'number')
    .map(({ videoKey }) => videoKey);

  const isSelectAll = videosKeySet.every((videoKey) => selectedVideo.includes(videoKey));
  return isSelectAll;
};

// eslint-disable-next-line react/prop-types
const VideoBrowsePaging = ({ currentPage, lastPage, onPageClick }) => {
  const startPage = getStartPage(currentPage, lastPage);

  const pageRange = Array.from(new Array(Math.min(3, lastPage)), (x, i) => i + startPage);
  const isShowLeft = startPage !== 1;
  const isShowRight = startPage <= lastPage - 3;
  return (
    <Menu floated="right" pagination>
      {isShowLeft && (
        <Fragment>
          <MenuItem
            icon="angle double left"
            onClick={() => {
              onPageClick(1);
            }}
          />
          <MenuItem
            icon="angle left"
            onClick={() => {
              onPageClick(currentPage - 1);
            }}
          />
        </Fragment>
      )}
      {pageRange.map((pageIndex) => (
        <MenuItem
          key={pageIndex}
          content={`${pageIndex}`}
          onClick={() => {
            onPageClick(pageIndex);
          }}
          active={pageIndex === currentPage}
          as="a"
        />
      ))}
      {isShowRight && (
        <Fragment>
          <MenuItem
            icon="angle right"
            onClick={() => {
              onPageClick(currentPage + 1);
            }}
          />
          <MenuItem
            icon="angle double right"
            onClick={() => {
              onPageClick(lastPage);
            }}
          />
        </Fragment>
      )}
    </Menu>
  );
};

const VideoSearch = ({ searchForm, onSearchChange, onSearch, onResetSearch }) => {
  return (
    <Form>
      <Flex pt={3} justifyContent="space-between">
        <Flex>
          {columns.map((column, i) => (
            <FixedBox key={i + column.accessor} flex="1">
              <Form.Field key={i}>
                <label>{column.Header}</label>
                <Input
                  type="text"
                  placeholder={column.Header}
                  onChange={(e) => onSearchChange(column.accessor, e.target.value)}
                  value={searchForm[column.accessor]}
                />
              </Form.Field>
            </FixedBox>
          ))}
        </Flex>
        <Flex pb={2} alignItems="flex-end">
          <Box>
            <Button primary circular type="submit" onClick={onSearch}>
              <Icon name="search" />
              Search
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Form>
  );
};
const VideoBrowse = ({
  videoPaging,
  onSearch,
  searchForm: propSearchForm,
  isAllowMultiple = true,
  onSelectVideo,
  onDeleteVideos,
  onPageChange,
  selectedVideos,
  setSelectedVideos,
}) => {
  const { allowedDefaultVideoCoverImage, enabledByteArkStream } = getConfig();
  const { videos, paging } = videoPaging;
  const { current, last } = paging;
  const [searchForm, setSearchForm] = useState({ ...propSearchForm });
  const [isSelectAll, setIsSelectAll] = useState(initSelectAllValue(selectedVideos, videos));
  const handleSearchChange = useCallback(
    (key, value) => {
      const { [key]: formKey, ...resSearchForm } = searchForm;
      const form = value !== undefined ? { [key]: value } : {};
      setSearchForm({ ...form, ...resSearchForm });
    },
    [searchForm]
  );

  const handleSearch = useCallback(() => {
    onSearch(searchForm);
  }, [onSearch, searchForm]);

  const handleResetSearch = useCallback(() => {
    setSearchForm({});
  }, []);

  const handleSelectVideo = (videoKey, isSelected) => {
    const videoKeySet = isAllowMultiple
      ? isSelected
        ? selectedVideos.filter((key) => key !== videoKey)
        : Array.from(new Set([...selectedVideos, videoKey]))
      : [videoKey];
    setSelectedVideos(videoKeySet);
    onSelectVideo(videos, videoKeySet, current);
  };

  const handleDelete = (videoKey) => {
    onDeleteVideos([videoKey]);
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const handleSelectAllVideo = () => {
    const videosKeySet = videos
      .filter(({ duration }) => typeof duration === 'number')
      .map(({ videoKey }) => videoKey);

    let newSelectedVideos = [];

    if (isSelectAll) {
      newSelectedVideos = selectedVideos.filter((videoKey) => !videosKeySet.includes(videoKey));
    } else {
      const additionalVideosKey = videosKeySet.filter(
        (videoKey) => !selectedVideos.includes(videoKey)
      );
      newSelectedVideos = [...selectedVideos, ...additionalVideosKey];
    }

    setSelectedVideos(newSelectedVideos);
    onSelectVideo(videos, newSelectedVideos, current);
    setIsSelectAll(!isSelectAll);
  };

  const handleDeleteSelectedVideos = () => {
    onDeleteVideos(selectedVideos);
  };

  return (
    <Flex flexWrap="wrap">
      <Box width={1} p={3}>
        <VideoSearch
          searchForm={searchForm}
          onSearchChange={handleSearchChange}
          onSearch={handleSearch}
          onResetSearch={handleResetSearch}
        />
        <ListHeader bg={COLOR.greyscale.grey5}>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex flex="2" alignItems="center">
              <Flex pr={3}>
                <Checkbox checked={isSelectAll} onClick={() => handleSelectAllVideo()} />
              </Flex>
              <Text fontSize={17} color="black">
                Select All
              </Text>
            </Flex>
            {selectedVideos.length > 1 && (
              <Flex flex="1" justifyContent="flex-end">
                <ConfirmationButton
                  buttonStyle={{
                    primary: true,
                    circular: true,
                  }}
                  headerText={`Delete?`}
                  contentText={'These items will be deleted from list.'}
                  confirmText={'Delete Selected'}
                  onConfirm={() => {
                    handleDeleteSelectedVideos();
                  }}
                >
                  Delete
                </ConfirmationButton>
              </Flex>
            )}
          </Flex>
        </ListHeader>
        <ListContainer selection>
          {videos.map((video, index) => {
            const { videoKey, title, sectionName, uploadedAt, duration, coverImageUrl } = video;
            const isSelected = selectedVideos.includes(videoKey);
            const isDefaultVideoCoverImage =
              allowedDefaultVideoCoverImage &&
              coverImageUrl?.includes('qoder.byteark.com/images/video-cover.png');
            const isProcessing =
              typeof duration !== 'number' ||
              (enabledByteArkStream ? coverImageUrl === null : isDefaultVideoCoverImage);

            return (
              <ListItem
                key={index}
                onClick={() => handleSelectVideo(videoKey, isSelected)}
                disabled={isProcessing}
              >
                <List.Content>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex flex="2" alignItems="center">
                      <Flex pr={3}>
                        {isAllowMultiple && (
                          <Checkbox checked={isSelected} readOnly={isProcessing} />
                        )}
                        {!isAllowMultiple && (
                          <Radio name="video" checked={isSelected} readOnly={isProcessing} />
                        )}
                      </Flex>
                      <Text fontSize={17} color="black">
                        {title}
                      </Text>
                    </Flex>
                    <Flex flex="1" justifyContent="space-between">
                      <Flex width={100} alignItems="center">
                        <Text color="black" textAlign="center">
                          {isProcessing ? 'กำลังประมวลผล' : customDurationFormat(duration)}
                        </Text>
                      </Flex>
                      <Flex
                        bg={COLOR.greyscale.grey4}
                        width={120}
                        py={0.5}
                        css={{
                          borderRadius: '5px',
                        }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          px={2}
                          color="black"
                          css={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {sectionName}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <Text color="black" textAlign="center">
                          {moment(uploadedAt).format('DD MMM YYYY HH:mm:ss')}
                        </Text>
                      </Flex>
                      <ConfirmationButton
                        headerText={`Delete?`}
                        contentText={'This will be deleted from list.'}
                        confirmText={'Delete'}
                        onConfirm={() => {
                          handleDelete(videoKey);
                        }}
                      >
                        <Icon name="x" />
                      </ConfirmationButton>
                    </Flex>
                  </Flex>
                </List.Content>
              </ListItem>
            );
          })}
        </ListContainer>
        <VideoBrowsePaging currentPage={current} lastPage={last} onPageClick={handlePageClick} />
      </Box>
    </Flex>
  );
};

export default VideoBrowse;
