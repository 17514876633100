import {
  TAG_CATEGORY_DICT,
  EXAM_ITEM_TAG_CATEGORY,
  EXAM_QUESTION_TYPE,
  EXAM_QUESTION_LABEL,
} from '../constants';

/** Set of categories that each question allows to contain multiple tags per category */
const ALLOW_MULTIPLE_TAGS_SET = new Set([
  EXAM_ITEM_TAG_CATEGORY.SUBJECT,
  EXAM_ITEM_TAG_CATEGORY.SUBTOPIC,
  EXAM_ITEM_TAG_CATEGORY.SECTION,
  EXAM_ITEM_TAG_CATEGORY.SUBSECTION,
  EXAM_ITEM_TAG_CATEGORY.DIFFICULTY,
  EXAM_ITEM_TAG_CATEGORY.QUESTION_EXAM_TYPE,
  EXAM_ITEM_TAG_CATEGORY.EXAM_CAMPAIGN,
  EXAM_ITEM_TAG_CATEGORY.BLOOM,
  EXAM_ITEM_TAG_CATEGORY.GRADE,
  EXAM_ITEM_TAG_CATEGORY.INDICATOR,
  EXAM_ITEM_TAG_CATEGORY.OBJECTIVE,
]);

/** Set of tag categories that show as **DROPDOWN** on **PUBLISHED** question */
const DROPDOWN_ON_PUBLISHED_QUESTION_TAGS_SET = new Set([
  EXAM_ITEM_TAG_CATEGORY.SUBJECT,
  EXAM_ITEM_TAG_CATEGORY.SUBTOPIC,
  EXAM_ITEM_TAG_CATEGORY.SECTION,
  EXAM_ITEM_TAG_CATEGORY.DIFFICULTY,
]);

const getSlugFromTag = (tag) => {
  return tag.slug;
};

const getNameFromTag = (tag) => {
  return tag.name;
};
/**
 * Generate a formatted text for dropdown's tag option.
 * @param {string} name Tag name.
 * @param {string} description Tag description.
 * @param {bool} isShowDescription If true, add description after tag name.
 * @param {bool} isDeleted If true, add "(Delete)" before tag name.
 */
const getTagOptionFormattedText = (name, description, isShowDescription, isDeleted) => {
  let formattedText = name;

  formattedText = isShowDescription ? `${formattedText} - ${description}` : formattedText;
  formattedText = isDeleted ? `(Deleted) ${formattedText}` : formattedText;

  return formattedText;
};

/**
 * - **DRAFT** QUESTION
 * - Since the **DRAFT** question mostly show dropdown component,
 * so this function will get `slug` (for mapping with options value)
 *
 * - **PUBLISHED** QUESTION
 * - Since the **PUBLISHED** question show different type of component(ex.dropdown/text_field),
 * it makes some categories show `slug`, some show `name`(becuase mapping slug with options value),
 * so this function will get `slug`(for dropdown) and get `name`(for others)
 * @param {Array} tags
 * @param {Boolean} isDraftQuestion
 */
export const getTagsData = (tags, isDraftQuestion) => {
  return isDraftQuestion ? getDraftQuestionTagData(tags) : getPublishedQuestionTagData(tags);
};

const getDraftQuestionTagData = (tags) => {
  if (tags.length === 0) return {};
  const slugs = {};
  Object.values(EXAM_ITEM_TAG_CATEGORY).forEach((tagCategory) => {
    const currentKey = TAG_CATEGORY_DICT[tagCategory];
    if (ALLOW_MULTIPLE_TAGS_SET.has(tagCategory)) {
      slugs[currentKey] = tags
        .filter(({ category }) => category === tagCategory)
        .map(getSlugFromTag);
    } else {
      slugs[currentKey] = tags.find(({ category }) => category === tagCategory);
      slugs[currentKey] = slugs[currentKey] ? slugs[currentKey].slug : '';
    }
  });
  return slugs;
};

const getPublishedQuestionTagData = (tags) => {
  if (tags.length === 0) return {};
  const tagsPreview = {};
  Object.values(EXAM_ITEM_TAG_CATEGORY).forEach((tagCategory) => {
    const currentKey = TAG_CATEGORY_DICT[tagCategory];
    const isShowAsDropdown = DROPDOWN_ON_PUBLISHED_QUESTION_TAGS_SET.has(tagCategory);
    if (ALLOW_MULTIPLE_TAGS_SET.has(tagCategory)) {
      tagsPreview[currentKey] = tags.filter(({ category }) => category === tagCategory);
      tagsPreview[currentKey] = isShowAsDropdown
        ? tagsPreview[currentKey].map(getSlugFromTag)
        : tagsPreview[currentKey].map(getNameFromTag);
    } else {
      tagsPreview[currentKey] = tags.find(({ category }) => category === tagCategory);
      if (tagsPreview[currentKey]) {
        tagsPreview[currentKey] = isShowAsDropdown
          ? tagsPreview[currentKey].slug
          : tagsPreview[currentKey].name;
      } else {
        tagsPreview[currentKey] = '';
      }
    }
  });
  return tagsPreview;
};

// TODO: Refactor this function
export const mapTagsToOptions = (tags, examItemTags = []) => {
  const options = {};
  Object.values(EXAM_ITEM_TAG_CATEGORY).forEach((tagCategory) => {
    const currentKey = TAG_CATEGORY_DICT[tagCategory];

    const tagsByCategory = tags.filter(({ category }) => category === tagCategory);
    const examItemTagsByCategory = examItemTags.filter(({ category }) => category === tagCategory);

    const tagIdByCategorySet = new Set(tagsByCategory.map((tag) => tag.id));

    const deletedTagsByCategory = examItemTagsByCategory
      .filter((examItemTag) => !tagIdByCategorySet.has(examItemTag.id))
      .map((deletedTag) => ({ ...deletedTag, isDeleted: true }));

    const allTagsByCategory = [...tagsByCategory, ...deletedTagsByCategory];

    options[currentKey] = allTagsByCategory.map(
      ({ id, slug, category, name, description, isDeleted = false }) => {
        const showDescriptionCategoryList = [
          EXAM_ITEM_TAG_CATEGORY.INDICATOR,
          EXAM_ITEM_TAG_CATEGORY.OBJECTIVE,
        ];
        const isShowDescription = showDescriptionCategoryList.includes(category) && description;

        return {
          key: id,
          value: slug,
          text: getTagOptionFormattedText(name, description, isShowDescription, isDeleted),
          isDeleted,
        };
      }
    );
  });
  return options;
};

export const mapTagIdsToOptions = (tags, tagCategories) => {
  if (tags.length === 0) return {};
  const options = {};
  Object.values(tagCategories).forEach((tagCategory) => {
    const currentKey = TAG_CATEGORY_DICT[tagCategory];
    options[currentKey] = tags
      .filter(({ category }) => category === tagCategory)
      .map(({ id, name }) => {
        return { key: id, value: id, text: name };
      });
  });
  return options;
};

export const getOptionsWithUnselectedOption = (options) => [
  { key: 'unselected', value: '', text: '- NONE -' },
  ...options,
];

export const getExamQuestionTypeOptions = (
  questionTypesConfig = [EXAM_QUESTION_TYPE.MULTIPLE_CHOICE, EXAM_QUESTION_TYPE.FILL_NUMBER]
) => {
  const examTypes = Object.values(EXAM_QUESTION_TYPE);
  const questionTypes = examTypes.filter((examType) => questionTypesConfig.includes(examType));

  return questionTypes.map((type) => {
    return { key: type, value: type, text: EXAM_QUESTION_LABEL[type] };
  });
};
