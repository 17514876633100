import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import styled from 'styled-components';

import { Box, Flex, Button } from 'Components/Base';

import TableHead from './TableHead';
import TableRow from './TableRow';

const TableWrapper = styled((props) => <Box {...props} />)`
  * {
    font-family: Rubik;
    text-transform: capitalize;
  }

  table th:first-child {
    border-radius: 8px 0 0 8px;
  }

  table th:last-child {
    border-radius: 0 8px 8px 0;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #f1ad46;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }
`;

const PermissionTable = ({
  onTableSubmit,
  submitButtonText,
  columns,
  data,
  currentPermissionData,
}) => {
  const [permissions, setPermissions] = useState(currentPermissionData);

  const handleUpdatePermission = ({ action, permissionList }) => {
    switch (action) {
      case 'add':
        setPermissions(Array.from(new Set([...permissions, ...permissionList])));
        break;
      case 'remove':
        setPermissions(permissions.filter((permission) => !permissionList.includes(permission)));
        break;
      default:
        break;
    }
  };

  const isDirty =
    permissions.length !== currentPermissionData.length ||
    permissions.some((permission) => !currentPermissionData.includes(permission));

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onTableSubmit(permissions);
      }}
    >
      <TableContainer component={TableWrapper}>
        <Table>
          <TableHead columns={columns} />
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.title} data={row} updatePermissionList={handleUpdatePermission} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Flex width={1} mt={4} justifyContent={'flex-end'}>
        <Button
          type="submit"
          primary
          title={submitButtonText}
          style={{ height: '44px' }}
          disabled={!isDirty}
        />
      </Flex>
    </form>
  );
};

PermissionTable.propTypes = {
  onTableSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string.isRequired,
    })
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      collapsedData: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          accessor: PropTypes.string.isRequired,
          view: PropTypes.shape({
            isAllow: PropTypes.bool,
            id: PropTypes.string,
          }).isRequired,
          update: PropTypes.shape({
            isAllow: PropTypes.bool,
            id: PropTypes.string,
          }).isRequired,
          delete: PropTypes.shape({
            isAllow: PropTypes.bool,
            id: PropTypes.string,
          }).isRequired,
        })
      ),
    })
  ),
  currentPermissionData: PropTypes.arrayOf(PropTypes.string),
};

PermissionTable.defaultProps = {
  onTableSubmit: () => {},
};

export default PermissionTable;
