import { SKU_CATEGORY } from 'Routes/Products/Domains/constants';
import { useAppConfig } from 'Util/hoc/withAppConfig';

const useSKUCategory = () => {
  const config = useAppConfig();
  const allowCategoryConfig = config?.shipment?.enabledCategories ?? [
    SKU_CATEGORY.ONLINE_COURSE,
    SKU_CATEGORY.SUBSCRIPTION,
    SKU_CATEGORY.BOOK,
  ];

  if (allowCategoryConfig.includes(SKU_CATEGORY.WORKSHOP)) {
    allowCategoryConfig.push(SKU_CATEGORY.WORKSHOP_BATCH);
  }

  //filtered & unique
  const filteredValidCategory = Array.from(
    new Set(
      allowCategoryConfig.filter((category) => Object.values(SKU_CATEGORY).includes(category))
    )
  );

  return filteredValidCategory;
};

export default useSKUCategory;
