/* eslint-disable no-loop-func */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Radio, Label as BaseLabel, Input } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import _ from 'lodash';
import styled from 'styled-components';
import compose from 'recompose/compose';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

import { Box, Flex, Text } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';
import ConfirmationButton from 'Components/ConfirmationButton';
import QueryComponent from 'GraphQL/util/QueryComponent';
import Section from 'Components/Section';
import withAppConfig from 'Util/hoc/withAppConfig';
import MuiDateTimePicker from 'Components/DateTimePicker';
import { COLOR } from 'Components/Theme';
import { MODE } from 'Components/SubSection/util';

import { getOnlineCourseWithTableOfContents } from '../../GraphQL/query/Products.query';
import { updateOnlineCourseTableOfContentMetaMutation } from '../../GraphQL/mutation/Products.mutation';

import OnlineCourseTableOfContentPreviewVideoForm from './OnlineCourseTableOfContentPreviewVideoForm';

const Label = styled((props) => <BaseLabel horizontal {...props} />)`
  min-width: 40px;
`;
const Toggle = styled((props) => <Radio toggle {...props} />)``;

/**
 * Edit TableOfContentMeta
 * @param {Array} array -TableOfContentMeta
 * @param {string} id - courseItemId
 * @param {stirng} key - key of value that you want to store in TableOfContentMeta
 * @param {any} value - value that you want to store in TableOfContentMeta
 * @returns tableOfContentMeta
 */
function editTableOfContentMeta(array, id, key, value) {
  const data = [...array];
  const index = _.findIndex(data, (datum) => datum.courseItemId === id);
  if (index === -1) {
    data.push({ courseItemId: id, [key]: value });
  } else {
    data[index] = { ...data[index], [key]: value };
  }
  return data;
}
/**
 * Create list of subsection JSX element from given table of content metadata
 * @param {{title:string,referId:string}[]} subSections - array of subsection
 * @param {{courseItemId:string , preview:boolean}[]} tableOfContentMeta - courseOnlineSKU.tableOfContentMeta
 * @param {(id:string,key:string,value:string)=>void} handler
 * @param {object} additionalProps
 * @return List of subsection elements
 */
function createSubsectionElements(subSections, tableOfContentMeta, handler, additionalProps) {
  const contentSubsection = [];
  for (const [index, subSectionDetails] of subSections.entries()) {
    const { title: subSectionTitle, referId: subSectionReferId } = subSectionDetails;
    contentSubsection.push({
      id: subSectionReferId,
      title: {
        left: (
          <Flex>
            {subSectionTitle}
            <Box ml={1}>{createTagLabels(subSectionReferId, tableOfContentMeta)}</Box>
          </Flex>
        ),
      },
      content: {
        content: (
          <Box>
            {createFormMeta(subSectionReferId, tableOfContentMeta, handler, additionalProps)}
          </Box>
        ),
      },
    });
  }
  return contentSubsection;
}

const StyledCheckbox = withStyles({
  root: {
    width: 25,
    height: 25,
    marginRight: 25,
    '&$checked': {
      color: COLOR.primary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

/**
 * Create List of section JSX element from given table of content metadata
 * @param {{subSections : object[] ,title:string,referId:string }[]} sections - array of section
 * @param {{courseItemId:string , preview:boolean}[]} tableOfContentMeta - courseOnlineSKU.tableOfContentMeta
 * @param {(id:string,key:string,value:string)=>void} handler
 * @param {object} additionalProps
 * @return List of section elements
 */
function createSectionElements(
  sections,
  tableOfContentMeta,
  sectionGroupNo,
  handler,
  additionalProps
) {
  const contentSection = [];
  for (const [index, sectionDetails] of sections.entries()) {
    const { subSections, referId: sectionReferId, title: sectionTitle } = sectionDetails;
    const sectionNo = index + 1;

    const contentSubsection = createSubsectionElements(
      subSections,
      tableOfContentMeta,
      handler,
      additionalProps
    );

    const subSectionsIds = subSections.map((subSection) => {
      const { referId: subSectionReferId } = subSection;
      return subSectionReferId;
    });
    const previewSectionChecked = subSectionsIds.every(
      (id) => tableOfContentMeta.find((meta) => meta.courseItemId === id)?.preview
    );

    const enabledReleasedDate = tableOfContentMeta.find(
      (meta) => subSectionsIds[0] === meta.courseItemId
    )?.enabledReleasedDate;

    const releasedAt = tableOfContentMeta.find((meta) => subSectionsIds[0] === meta.courseItemId)
      ?.releasedAt;

    const newSection = {
      id: sectionReferId,
      title: {
        left: (
          <Flex>
            {`${sectionGroupNo}.${sectionNo} ${sectionTitle}`}
            <Box ml={1}>
              {previewSectionChecked ? (
                <Label color="blue" key={`tag-preview-${sectionGroupNo}.${sectionNo}`}>
                  PreviewAll
                </Label>
              ) : null}
            </Box>
          </Flex>
        ),
        right: (
          <Fragment>
            <Flex alignItems="center" mb={1}>
              <Text mr={1}>Preview Section</Text>
              <Button.Group>
                <Toggle
                  key={`preview-all-${sectionGroupNo}.${sectionNo}`}
                  checked={previewSectionChecked}
                  onChange={async () => {
                    for (const id of subSectionsIds) {
                      await handler(id, 'preview', !previewSectionChecked);
                    }
                  }}
                />
              </Button.Group>
            </Flex>
          </Fragment>
        ),
      },
      content: {
        content: (
          <Flex flexDirection="column">
            <Flex width={1} alignItems="center">
              <Text fontWeight="bold" mr="5px">
                Enable Release Date
              </Text>
              <StyledCheckbox
                checked={enabledReleasedDate}
                onChange={(e) => {
                  additionalProps.handleEnableReleasedDateChange(sectionReferId, subSectionsIds, e);
                }}
              />
              <MuiDateTimePicker
                label="Release Date"
                format="LLL"
                inputVariant="outlined"
                // minDate={moment().add('days', 1)}
                value={releasedAt}
                onChange={(date) => {
                  additionalProps.handleReleasedDateChange(sectionReferId, subSectionsIds, date);
                }}
                disabled={!enabledReleasedDate}
                style={{ width: 250, marginTop: 28, paddingBottom: 20 }}
                InputLabelProps={{
                  shrink: true,
                }}
                // error={isExpiryDateError}
                // helperText={isExpiryDateError && 'Required expiry date'}
              />
            </Flex>
            <Section sections={contentSubsection}></Section>
          </Flex>
        ),
      },
    };
    contentSection.push(newSection);
  }
  return contentSection;
}

/**
 * Create List of section JSX element from given table of content metadata
 * @param {{section : object[] ,title:string,referId:string }[]} sectionGroups - array of sectionGroups
 * @param {{courseItemId:string , preview:boolean , releasedAt?: Date}[]} tableOfContentMeta - courseOnlineSKU.tableOfContentMeta
 * @param {(id:string,key:string,value:string)=>void} handler
 * @param {object} additionalProps
 * @return List of section group elements
 */
function createSectionGroupElements(sectionGroups, tableOfContentMeta, handler, additionalProps) {
  const sectionGroupContent = [];
  for (const [index, groupDetails] of sectionGroups.entries()) {
    const { sections, title: sectionGroupTitle } = groupDetails;
    const sectionGroupNo = index + 1;
    const contentSection = createSectionElements(
      sections,
      tableOfContentMeta,
      sectionGroupNo,
      handler,
      additionalProps
    );
    const newSectionGroup = (
      <Box mt={2}>
        <Text.Header>{`บทที่ ${sectionGroupNo} ${sectionGroupTitle}`}</Text.Header>
        <Section sections={contentSection}></Section>
      </Box>
    );
    sectionGroupContent.push(newSectionGroup);
  }
  return sectionGroupContent;
}
/**
 * Create Tag labels for track current status of table of content metadata
 * @param {string} referId - Id of CourseItems
 * @param {{courseItemId:string , preview:boolean}[]} tableOfContentMeta - courseOnlineSKU.tableOfContentMeta
 * @return List of tag label elements
 */
function createTagLabels(referId, tableOfContentMeta) {
  const meta = tableOfContentMeta.find((meta) => meta.courseItemId === referId);
  const labels = [];
  if (meta) {
    for (const [key, value] of Object.entries(meta)) {
      if (!['courseItemId', 'enabledReleasedDate'].includes(key)) {
        if (value && typeof value == 'boolean') {
          labels.push(<Label color="blue">{key}</Label>);
        }
      }
    }
  }
  return labels;
}

/**
 * Create Form for edit table of content meta data
 * @param {string} referId - Id of CourseItems
 * @param {{courseItemId:string , preview:boolean, previewVideo: {url:string, time: number}}[]} tableOfContentMeta - courseOnlineSKU.tableOfContentMeta
 * @param {(id:string,key:string,value:string)=>void} handler
 * @param {{appConfig:object}} additionalProps
 */
function createFormMeta(referId, tableOfContentMeta, handler, { appConfig }) {
  let previewVideo =
    tableOfContentMeta.find((meta) => meta.courseItemId === referId)?.previewVideo ?? {};

  const previewChecked = tableOfContentMeta.find(
    (meta) => meta.courseItemId === referId && meta.preview
  )
    ? true
    : false;

  return (
    <Fragment>
      <Flex alignItems="center" mb={1}>
        <Text mr={1}>Preview</Text>
        <Button.Group>
          <Toggle
            key={referId}
            checked={previewChecked}
            onChange={() => handler(referId, 'preview', !previewChecked)}
          />
        </Button.Group>
      </Flex>
      {appConfig?.enableProductOnlineCoursePreviewVideo && previewChecked ? (
        <OnlineCourseTableOfContentPreviewVideoForm
          previewVideo={previewVideo}
          handleFormChange={handler}
          referId={referId}
        />
      ) : (
        ''
      )}
    </Fragment>
  );
}
class OnlineCourseTableOfContentEditor extends Component {
  static propTypes = {
    onlineCourse: PropTypes.shape({
      id: PropTypes.string.isRequired,
      SKUCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tableOfContentMeta: [],
    };
  }
  async componentDidMount() {
    const { onlineCourse, client } = this.props;
    const data = await client.query({
      query: getOnlineCourseWithTableOfContents,
      variables: {
        SKUCode: onlineCourse.SKUCode,
      },
      fetchPolicy: 'no-cache',
    });

    const { formattedTableOfContent, tableOfContentMeta } =
      data?.data?.sfBoProductOnlineCourse ?? {};
    const { sectionGroups = [] } = formattedTableOfContent ?? {};

    const newSectionDataForUpdateTableOfContentMeta = this.createNewSectionDataForUpdateTableOfContentMeta(
      sectionGroups,
      tableOfContentMeta
    );

    const formattedTableOfContentMeta = this.formatTableOfContentMeta(tableOfContentMeta);

    this.setState({
      tableOfContentMeta: [
        ...formattedTableOfContentMeta,
        ...newSectionDataForUpdateTableOfContentMeta,
      ],
    });
  }

  formatTableOfContentMeta = (tableOfContentMeta) => {
    return tableOfContentMeta.map((meta) => {
      const { __typename, ...details } = meta;
      const { previewVideo, releasedAt, type: courseItemType } = details;
      if (courseItemType && courseItemType === MODE.SECTION) {
        return {
          ...details,
          enabledReleasedDate: !_.isNil(releasedAt),
          releasedAt: !_.isNil(releasedAt) ? new Date(releasedAt) : null,
        };
      }
      return {
        ...details,
        enabledReleasedDate: !_.isNil(releasedAt),
        releasedAt: !_.isNil(releasedAt) ? new Date(releasedAt) : null,
        previewVideo: previewVideo ? _.omit(previewVideo, ['__typename']) : null,
        type: MODE.SUBSECTION,
      };
    });
  };

  createNewSectionDataForUpdateTableOfContentMeta = (sectionGroups, tableOfContentMeta) => {
    const newSectionDataForUpdateTableOfContentMeta = [];
    for (const sectionGroup of sectionGroups) {
      const { sections } = sectionGroup;
      for (const section of sections) {
        const { subSections, referId: sectionReferId } = section;

        const selectedMeta = tableOfContentMeta.find(
          (meta) => meta.courseItemId === sectionReferId
        );
        if (!selectedMeta) {
          const releasedAt = tableOfContentMeta.find(
            (meta) => subSections[0].referId === meta.courseItemId
          )?.releasedAt;

          newSectionDataForUpdateTableOfContentMeta.push({
            courseItemId: sectionReferId,
            enabledReleasedDate: !_.isNil(releasedAt),
            releasedAt: new Date(releasedAt),
            type: MODE.SECTION,
          });
        }
      }
    }
    return newSectionDataForUpdateTableOfContentMeta;
  };

  handleChange = (id, key, value) => {
    this.setState({
      tableOfContentMeta: editTableOfContentMeta(this.state.tableOfContentMeta, id, key, value),
    });
  };

  setEnableReleasedDate = (sectionReferId, subSectionsIds, bool, cb) => {
    const newTableOfContentMeta = _.cloneDeep(this.state.tableOfContentMeta);
    if (sectionReferId) {
      let selectedMeta = newTableOfContentMeta.find((meta) => {
        return meta.courseItemId === sectionReferId;
      });
      selectedMeta.enabledReleasedDate = bool;
    }

    subSectionsIds.forEach((subSectionsId) => {
      let selectedMeta = newTableOfContentMeta.find((meta) => {
        return meta.courseItemId === subSectionsId;
      });
      if (!selectedMeta) {
        selectedMeta = {
          courseItemId: subSectionsId,
          preview: false,
          type: MODE.SUBSECTION,
        };
        newTableOfContentMeta.push(selectedMeta);
      }
      selectedMeta.enabledReleasedDate = bool;
    });
    this.setState(
      {
        tableOfContentMeta: newTableOfContentMeta,
      },
      cb
    );
  };

  setReleasedDate = (sectionReferId, subSectionsIds, date) => {
    const newTableOfContentMeta = _.cloneDeep(this.state.tableOfContentMeta);
    if (sectionReferId) {
      let selectedMeta = newTableOfContentMeta.find((meta) => {
        return meta.courseItemId === sectionReferId;
      });
      selectedMeta.releasedAt = date;
    }

    subSectionsIds.forEach((subSectionsId) => {
      let selectedMeta = newTableOfContentMeta.find((meta) => {
        return meta.courseItemId === subSectionsId;
      });
      if (!selectedMeta) {
        selectedMeta = {
          courseItemId: subSectionsId,
          preview: false,
          type: MODE.SUBSECTION,
        };
        newTableOfContentMeta.push(selectedMeta);
      }
      selectedMeta.releasedAt = date;
    });

    this.setState({
      tableOfContentMeta: newTableOfContentMeta,
    });
  };

  handleEnableReleasedDateChange = (sectionReferId, subSectionsIds, e) => {
    if (e.target.checked) {
      this.setEnableReleasedDate(sectionReferId, subSectionsIds, true, () => {
        this.setReleasedDate(sectionReferId, subSectionsIds, new Date());
      });
    } else {
      this.setEnableReleasedDate(sectionReferId, subSectionsIds, false);
    }
  };

  handleReleasedDateChange = (sectionReferId, subSectionsIds, date) => {
    this.setReleasedDate(sectionReferId, subSectionsIds, date);
  };

  formatReleasedDate = (tableOfContentMeta) => {
    const tocMeta = _.cloneDeep(tableOfContentMeta);
    tocMeta.forEach((meta) => {
      if (!meta.enabledReleasedDate) {
        meta.releasedAt = null;
      }
      delete meta.enabledReleasedDate;
    });
    return tocMeta;
  };
  handleUpdateTableOfContentMeta = (mutateFn) => {
    const { tableOfContentMeta } = this.state;
    if (tableOfContentMeta.length > 0) {
      mutateFn({
        variables: {
          id: this.props.onlineCourse.id,
          data: {
            tableOfContentMeta: this.formatReleasedDate(tableOfContentMeta),
          },
        },
      });
    }
  };
  handleCancel = () => {
    this.setState({ tableOfContentMeta: [] });
  };
  render() {
    const { tableOfContentMeta } = this.state;
    const variables = { SKUCode: this.props.onlineCourse.SKUCode };
    const { onlineCourse, appConfig } = this.props;
    return (
      <Mutation
        mutation={updateOnlineCourseTableOfContentMetaMutation}
        refetchQueries={[{ query: getOnlineCourseWithTableOfContents, variables }]}
      >
        {(editData, { loading, error, data: mutateData }) => {
          return (
            <Box>
              <CheckMutationResponse
                checkOnlyDefined={true}
                response={mutateData}
                loading={loading}
                error={error}
              />
              <QueryComponent query={getOnlineCourseWithTableOfContents} variables={variables}>
                {(data) => {
                  const { formattedTableOfContent } = data.sfBoProductOnlineCourse;
                  const { sectionGroups } = formattedTableOfContent;
                  const result = createSectionGroupElements(
                    sectionGroups,
                    tableOfContentMeta,
                    this.handleChange,
                    {
                      appConfig,
                      handleEnableReleasedDateChange: this.handleEnableReleasedDateChange,
                      handleReleasedDateChange: this.handleReleasedDateChange,
                    }
                  );
                  return result;
                }}
              </QueryComponent>
              <Flex justifyContent="flex-end" mt={2}>
                <ConfirmationButton
                  buttonStyle={{
                    primary: true,
                    circular: true,
                  }}
                  headerText={`Update course table of contents?`}
                  contentText={'This course table of contents will be update.'}
                  confirmText={'Update'}
                  onConfirm={() => this.handleUpdateTableOfContentMeta(editData)}
                >
                  Update
                </ConfirmationButton>
                <Button
                  circular
                  size="medium"
                  type="reset"
                  content="Cancel"
                  disabled={loading}
                  onClick={this.handleCancel}
                />
              </Flex>
            </Box>
          );
        }}
      </Mutation>
    );
  }
}
export default compose(
  withAppConfig({ configProp: 'appConfig' }),
  withApollo
)(OnlineCourseTableOfContentEditor);
