import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const SideMenu = ({ header, menus, activeItem, onMenuItemChange }) => (
  <Menu secondary vertical fluid>
    <Menu.Item>
      <Menu.Header content={header} />
      {menus
        .filter((menu) => !menu.hidden)
        .map(({ title, menuName, path, icon }, index) => (
          <Menu.Item
            key={`${menuName + path + index}`}
            as={NavLink}
            to={path}
            name={menuName}
            icon={icon}
            active={activeItem === title}
            onClick={() => onMenuItemChange(title)}
          />
        ))}
    </Menu.Item>
  </Menu>
);

SideMenu.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      menuName: PropTypes.string,
      path: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  activeItem: PropTypes.string,
  handleState: PropTypes.func,
};

SideMenu.defaultProps = {
  menus: [],
};

export default SideMenu;
