/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import { Button, Icon } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';

import { getAdjustmentOrders } from '../../GraphQL/query/Payment.query';
import { ChangeSubscriptionModal } from '../../components/AdjustmentOrderModal';

const columns = () => [
  {
    Header: 'ID',
    accessor: 'id',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'User ID',
    accessor: 'userId',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Full Name',
    accessor: 'fullName',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    sortable: false,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 200,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: 'CHANGE_SUBSCRIPTION', value: 'CHANGE_SUBSCRIPTION', text: 'CHANGE SUBSCRIPTION' },
    ],
    isSearchAble: true,
  },
  {
    Header: 'Refer ID',
    accessor: 'referId',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Reason',
    accessor: 'reason',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Order No.',
    accessor: 'saleOrderNo',
    width: 120,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    sortable: false,
  },
  {
    Header: 'Reference',
    accessor: 'reference',
    width: 150,
    type: COLUMN_TYPE.STRING, // TODO: display as markup
    isSearchAble: true,
  },
];

class AdjustmentCodeRoute extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'createdAt', type: 'DESC' },
  };
  mutateAdjustmentOrder = (mutation, discountId) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    client
      .mutate({
        mutation,
        variables: { id: discountId },
        refetchQueries: [
          {
            query: getAdjustmentOrders,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };
  resolveData = (rawData) => {
    const data = rawData.sfBoPaymentAdjustmentOrders.adjustmentOrders;
    return data.map((singleData) => {
      const { fullName } = singleData.user;
      const { id, userId, type, referId, reason, saleOrderNo } = singleData;
      return {
        id,
        userId,
        fullName,
        type,
        referId,
        reason,
        saleOrderNo,
        reference: singleData.reference?.content || '',
      };
    });
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      if (field !== 'fullName' && field !== 'saleOrderNo') {
        return { field, type };
      }
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Adjustment Order</Text.Header>
          <ChangeSubscriptionModal
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                Change Subscription
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type="sfBoPaymentAdjustmentOrders"
          resolveData={this.resolveData}
          columns={columns()}
          query={getAdjustmentOrders}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(AdjustmentCodeRoute);
