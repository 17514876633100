import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';

import Table from 'Components/DataTable';
import Text from 'Components/Base/Text';
import Button from 'Components/Base/Button/';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { mapFormatterTable } from 'GraphQL/util/QueryTable/dataUtil';
const columns = [
  {
    Header: 'UpdatedAt',
    accessor: 'updatedAt',
    width: 200,
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'UpdatedBy',
    accessor: 'updatedBy',
    width: 150,
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Reference',
    accessor: 'ref',
    width: 100,
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'PreviousStatus',
    accessor: 'previousStatus',
    width: 150,
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'CurrentStatus',
    accessor: 'currentStatus',
    width: 150,
    type: COLUMN_TYPE.STRING,
  },
];

class UpdateSalesOrderStatusModal extends Component {
  state = { open: false };

  static propTypes = {
    actionText: PropTypes.string,
    buttonColor: PropTypes.string,
    inverted: PropTypes.bool,
    onConfirm: PropTypes.func,
    header: PropTypes.string,
    icon: PropTypes.string,
    triggerButtonStyle: PropTypes.object,
    actionButtonStyle: PropTypes.object,
    modalSize: PropTypes.string,
  };

  static defaultProps = {
    actionText: 'Show',
    buttonColor: 'orange',
    inverted: false,
    onConfirm: () => {},
    header: 'Delete Confirmation',
    icon: '',
    triggerButtonStyle: {},
    actionButtonStyle: {},
    modalSize: 'small',
  };

  show = () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  handleConfirm = async () => {
    this.close();
  };
  handleCancel = () => {
    this.close();
  };
  handleButtonClick = () => {
    this.show();
  };

  render() {
    const { actionText, inverted, modalSize, data } = this.props;
    const pages = Math.ceil(data.length / 5);
    const customProps = {
      columns: columns.map((column) => mapFormatterTable(column)),
      className: 'table-center',
      manual: true, // sorting with query
      showPageSizeOptions: true,
      multiSort: false,
      defaultPageSize: 5,
    };
    return (
      <Fragment>
        <Button
          basic={'true'}
          color={'blue'}
          icon={'file alternate'}
          inverted={inverted}
          title={actionText}
          onClick={this.handleButtonClick}
        />
        <Modal size={modalSize} open={this.state.open}>
          <Modal.Header>
            <Text.PaymentTitle>Logs</Text.PaymentTitle>
          </Modal.Header>
          <Modal.Content>
            <Table pages={pages} data={data} alignItems="baseline" {...customProps} />
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="grey" title="close" onClick={this.handleCancel} />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default withApollo(UpdateSalesOrderStatusModal);
