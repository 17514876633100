import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';

import withAppConfig from 'Util/hoc/withAppConfig';

import QueryComponent from '../../../../GraphQL/util/QueryComponent';
import { getCourseMeta, getSingleCourseV1 } from '../../../../GraphQL/query/Courses.query';
import { productCourseVersionResolver } from '../../../../GraphQL/query/AppConfig.query';
import { publishCourse } from '../../../../GraphQL/mutation/Courses.mutation';

import PublishToolbar from './PublishToolbar';

const getCourseRevision = (course) =>
  course.meta && {
    currentRevision: course.meta.revision,
    publishedRevision: course.meta.latestPublishedRevision,
  };

class PublishToolbarGql extends Component {
  state = {
    publishing: false,
    isPassCourseImageValidation: true,
    courseImageBanner: null,
    isRequiredCourseImage: false,
  };

  setIsPassCourseImageValidation = (value) => {
    this.setState(() => ({ isPassCourseImageValidation: value }));
  };

  setIsRequiredCourseImage = (value) => {
    this.setState(() => ({ isRequiredCourseImage: value }));
  };

  setCourseImageBanner = (imageUrl) => {
    this.setState({ courseImageBanner: imageUrl });
  };

  handlePublishCourse = () => {
    const { courseId, product, version } = this.props;
    if (!this.state.courseImageBanner) {
      this.setIsPassCourseImageValidation(false);
      return;
    } else {
      this.setIsPassCourseImageValidation(true);
      this.setState(
        () => ({ publishing: true, error: null }),
        () => {
          this.props
            .publishCourse({
              variables: { courseId, version: productCourseVersionResolver(product, version) },
            })
            .then(() => {
              this.setState({ publishing: false });
            })
            .catch((error) => {
              this.setState({ publishing: false, error });
            });
        }
      );
    }
  };

  render() {
    const { product, version, courseId } = this.props;
    const { publishing } = this.state;

    return (
      <QueryComponent
        query={getCourseMeta}
        variables={{ version: productCourseVersionResolver(product, version), courseId }}
        loadingOverlay={false}
      >
        {(data) => {
          const courseMeta = data;
          return (
            <QueryComponent
              query={getSingleCourseV1}
              variables={{
                version: productCourseVersionResolver(product, version),
                courseId,
                isV1: version === 'v1',
                enabledContentTeam: true,
              }}
              loadingOverlay
            >
              {(data) => (
                <PublishToolbar
                  {...getCourseRevision(courseMeta.course)}
                  onPublishCourse={this.handlePublishCourse}
                  loading={publishing}
                  isPassCourseImageValidation={this.state.isPassCourseImageValidation}
                  setCourseImageBanner={this.setCourseImageBanner}
                  setIsRequiredCourseImage={this.setIsRequiredCourseImage}
                  {...data}
                  {...this.props}
                />
              )}
            </QueryComponent>
          );
        }}
      </QueryComponent>
    );
  }
}

PublishToolbarGql.propTypes = {
  product: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
};

export default compose(
  graphql(publishCourse, { name: 'publishCourse' }),
  withAppConfig({ configProp: 'appConfig' })
)(PublishToolbarGql);
