export const MonthOptions = [
  {
    key: 'm-Jan',
    text: 'มกราคม',
    value: 1,
  },
  {
    key: 'm-Feb',
    text: 'กุมภาพันธ์',
    value: 2,
  },
  {
    key: 'm-Mar',
    text: 'มีนาคม',
    value: 3,
  },
  {
    key: 'm-Apr',
    text: 'เมษายน',
    value: 4,
  },
  {
    key: 'm-May',
    text: 'พฤษภาคม',
    value: 5,
  },
  {
    key: 'm-Jun',
    text: 'มิถุนายน',
    value: 6,
  },
  {
    key: 'm-Jul',
    text: 'กรกฎาคม',
    value: 7,
  },
  {
    key: 'm-Aug',
    text: 'สิงหาคม',
    value: 8,
  },
  {
    key: 'm-Sep',
    text: 'กันยายน',
    value: 9,
  },
  {
    key: 'm-Oct',
    text: 'ตุลาคม',
    value: 10,
  },
  {
    key: 'm-Nov',
    text: 'พฤศจิกายน',
    value: 11,
  },
  {
    key: 'm-Dec',
    text: 'ธันวาคม',
    value: 12,
  },
];

export const monthList = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];
