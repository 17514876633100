import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import Slides from 'Components/AssetComponents/Slides';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import withServerConfig from 'GraphQL/util/withServerConfig';
import withAppConfig from 'Util/hoc/withAppConfig';
import useAssetManagement from 'Util/useAssetManagement';

const SKUGalleryEditor = ({
  dataQuery,
  dataQueryName,
  variableQuery,
  handleMutation,
  getHandleVariables,
  refetchQueries,
  client,
  serverConfig,
  appConfig,
}) => {
  const { data: queryData, loading: queryLoading, error: queryError } = useQuery(dataQuery, {
    variables: variableQuery,
    fetchPolicy: 'network-only',
  });
  const [updateImages, { loading: mutationLoading, error: mutationError }] = useMutation(
    handleMutation
  );

  const { handleFileUploadUrlRequest, handleFileListRequest } = useAssetManagement({
    client,
    assetKey: variableQuery.SKUCode,
    assetType: ASSET_TYPE.SF_ASSETS,
  });

  const handleUpdateGallery = (data) => {
    const imageList = data?.content?.data ?? [];
    const imageListInput = imageList.map(({ key, bucket }) => ({ key, bucket }));
    updateImages({
      variables: getHandleVariables(imageListInput),
      refetchQueries: refetchQueries,
    });
  };

  if (queryLoading || mutationLoading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }
  const bundleImages = queryData?.[dataQueryName]?.bundleImages?.images ?? [];
  const imageList = bundleImages.map((img) => ({ ...img, url: img.assetUrl }));
  return (
    <Fragment>
      {queryError && <ErrorView message={queryError} />}
      {mutationError && <ErrorView message={mutationError} />}
      <Slides
        content={{ data: imageList }}
        onFileListRequest={handleFileListRequest}
        onFileUploadUrlRequest={handleFileUploadUrlRequest}
        uploadMode={serverConfig.assetUploadMode}
        allowedContentTypes={appConfig.courseMaterialAllowContentTypes}
        onCreateMaterial={handleUpdateGallery}
      />
    </Fragment>
  );
};

SKUGalleryEditor.propTypes = {
  dataQuery: PropTypes.object,
  dataQueryName: PropTypes.string,
  variableQuery: PropTypes.object,
  handleMutation: PropTypes.object,
  getHandleVariables: PropTypes.func,
  refetchQueries: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.object.isRequired,
      variables: PropTypes.object,
    })
  ),
  serverConfig: PropTypes.any,
  appConfig: PropTypes.array,
  client: PropTypes.object,
};

export default compose(
  withServerConfig({ configProp: 'serverConfig' }),
  withAppConfig({ configProp: 'appConfig' }),
  withApollo
)(SKUGalleryEditor);
