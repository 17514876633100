import React from 'react';

import { SemanticButton as Button } from '../../Base/Button';

export default ({ history }) => (
  <Button
    circular
    onClick={() => {
      history.push('/login');
    }}
  >
    เข้าสู่ระบบ
  </Button>
);
