import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Divider } from 'semantic-ui-react';

import { Box, Flex, Text } from 'Components/Base';

import EnrollmentListGQL from '../../Containers/EnrollmentList.gql';
import EnrollCourseModal from '../../Containers/NewEnrollmentModal';
import { SemanticButton as Button } from '../../../../Components/Base/Button';

const Toolbar = ({ userId }) => (
  <Flex justifyContent="space-between" alignItems="center">
    <Text.Header>Enrollment List</Text.Header>
    <Box />
    <EnrollCourseModal
      userId={userId}
      trigger={
        <Button primary circular>
          <Icon name="plus" size="small" />
          Enroll Course
        </Button>
      }
    />
  </Flex>
);
class EnrollmentsPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { userId } = this.props;
    return (
      <Box py={3}>
        {/*<Toolbar userId={userId} />
        <Box mb={3} />*/}
        <EnrollmentListGQL userId={userId} />
        <Box pb={3} />
      </Box>
    );
  }
}

EnrollmentsPanel.propTypes = {};

export default EnrollmentsPanel;
