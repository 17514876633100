import React from 'react';
import PropTypes from 'prop-types';

import CreateEditForm from 'Containers/CreateEditForm';
import { createTag, updateTag } from 'GraphQL/mutation/Exam.mutation';
import { getTags, getTagById } from 'GraphQL/query/Exam.query';

import TagForm from '../Components/TagForm/TagForm';

const CreateEditTagForm = ({ onComplete, onCancelled, tagId, isEdit, searchVariables }) => {
  return (
    <CreateEditForm
      isEdit={isEdit}
      onCompleted={onComplete}
      onCancelled={onCancelled}
      checkMutationResponse
      // All props that handle `create` action.
      createMutation={createTag}
      getCreateVariables={(form) => ({
        tag: form,
      })}
      createRefetchQueries={[{ query: getTags, variables: searchVariables }]}
      getCreateMutationResponse={(mutateData) => mutateData?.createExamTag}
      // All props that handle `edit` action.
      dataQuery={getTagById}
      id={tagId}
      getQueryVariables={({ id }) => ({
        tagId: id,
      })}
      editMutation={updateTag}
      getEditVariables={(form, { id }) => ({
        tagId: id,
        tag: form,
      })}
      editRefetchQueries={[{ query: getTags, variables: searchVariables }]}
      getEditMutationResponse={(mutateData) => mutateData?.updateExamTag}
    >
      {({ data, onSubmit }) => {
        const handleOnSubmitData = (submitData) => {
          onSubmit({ ...submitData, slug: submitData.name });
        };
        return (
          <TagForm
            initialData={data?.examTagById}
            onSubmit={handleOnSubmitData}
            onCancelled={onCancelled}
          />
        );
      }}
    </CreateEditForm>
  );
};

CreateEditTagForm.propTypes = {
  tagId: PropTypes.string,
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  searchVariables: PropTypes.shape({}),
};

export default CreateEditTagForm;
