import React, { useState, useEffect } from 'react';
import isUrl from 'is-url';
import { isEmpty, isNil, omit } from 'lodash';
import { Icon, Segment, Label } from 'semantic-ui-react';
import styled from 'styled-components';

import { Box } from 'Components/Base';
import FormGenerator from 'Components/Form/FormGenerator';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';

import VideoPlayerWrapper, { VIDEO_TYPE } from '../VideoManagement/VideoPlayerWrapper';
import ActionBar from '../Common/ActionBar';
import { customDurationFormat } from '../../../Util/ExternalAdapter/Api/WistiaApi';

import VimeoVideoUrlInput from './VimeoVideoUrlInput';

const Divider = styled(Box)``;

const extractDisplayDetail = (rawDetail) => {
  return {
    video_id: rawDetail.video_id,
    thumbnail: rawDetail.thumbnail_url,
    title: rawDetail.title,
    totalTime: customDurationFormat(rawDetail.duration),
  };
};

const formatRawData = (data) => {
  if (data.found) {
    return {
      videoDetail: omit(data, ['found']),
      displayDetail: extractDisplayDetail(data),
      error: null,
    };
  }

  const { status, message } = data;

  return {
    videoDetail: {},
    displayDetail: {},
    error: { status, message },
  };
};

const convertToRawData = (formData, data) => {
  const { videoDetail } = data;
  const { previewUrl, videoUrl } = formData;
  return {
    url: videoUrl,
    previewUrl,
    videoDetail,
    videoType: 'VIMEO',
    videoKey: videoDetail?.video_id,
  };
};

const convertFromRawData = (data = {}) => {
  return data.url ? formatRawData({ ...data.videoDetail, found: true }) : formatRawData(data);
};

const selectInputList = (previewUrl, videoUrl, title, onLoadedVideo) => {
  return [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Vimeo Video URL',
      inputName: 'previewUrl',
      inputProps: {
        required: true,
        value: previewUrl,
      },
      customInput: (props) => <VimeoVideoUrlInput {...props} onLoadedVideo={onLoadedVideo} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Video URL (url for m3u8 file)',
      inputName: 'videoUrl',
      inputProps: {
        required: true,
        value: videoUrl,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Title',
      inputName: 'title',
      inputProps: {
        required: true,
        value: title,
      },
    },
  ];
};

const VimeoVideo = ({
  newAsset,
  onCancel,
  title,
  loading,
  materialTypeString,
  onCreateMaterial,
  content,
}) => {
  const [formData, setFormData] = useState({
    title: title || '',
    previewUrl: content?.previewUrl || '',
    videoUrl: content?.url || '',
  });
  const [videoData, setVideoData] = useState(convertFromRawData(content));
  const [submitable, setSubmitable] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const { displayDetail, error: videoError } = videoData;
  const { previewUrl, videoUrl, title: formTitle } = formData;

  const onLoadedVideo = (RawData) => {
    const formatedData = formatRawData(RawData);
    setVideoData(formatedData);
    setFormData((prev) => ({ ...prev, title: formatedData.displayDetail.title }));
  };

  const inputList = selectInputList(previewUrl, videoUrl, formTitle, onLoadedVideo);
  const requiredFields = inputList
    .filter((field) => field.inputProps?.required)
    .map((field) => field.inputName);

  const handleFormChange = (values) => {
    setFormData(values);
  };

  const someValid = (values) => {
    const validationErrors = {};

    if (!isUrl(values.previewUrl)) {
      validationErrors['previewUrl'] = 'Please enter a valid url format';
    }

    if (!isUrl(values.videoUrl)) {
      validationErrors['videoUrl'] = 'Please enter a valid url format';
    }
    requiredFields.forEach((field) => {
      if (values[field] === '') {
        validationErrors[field] = `${field} is required`;
      }
    });
    return validationErrors;
  };

  const customValidation = (currentValues) => {
    setSubmitable(false);
    const validationErrors = {};

    if (!isUrl(currentValues.previewUrl)) {
      validationErrors['previewUrl'] = 'Please enter a valid url format';
    }

    if (videoData?.error?.status) {
      validationErrors['previewUrl'] = videoData.error.message;
    }

    if (!isUrl(currentValues.videoUrl)) {
      validationErrors['videoUrl'] = 'Please enter a valid url format';
    }
    requiredFields.forEach((field) => {
      if (currentValues[field] === '') {
        validationErrors[field] = `${field} is required`;
      }
    });
    const canSubmit = isEmpty(validationErrors);
    setSubmitable(canSubmit);
    return validationErrors;
  };

  const handleAction = () => {
    const data = {
      ...formData,
      description: videoData?.videoDetail?.description,
      content: convertToRawData(formData, videoData),
    };
    if (submitable) {
      onCreateMaterial(data);
    }
  };

  useEffect(() => {
    if (!isNil(videoError?.status)) {
      setIsSnackbarOpen(true);
    }
  }, [videoData]);

  return (
    <Box>
      <Box>
        {videoError?.message && (
          <Snackbar
            variant={VARIANTS.ERROR}
            message={videoError?.message}
            isOpen={isSnackbarOpen}
            onClose={() => {
              setIsSnackbarOpen(false);
            }}
          />
        )}

        <FormGenerator
          fields={inputList}
          showAction={false}
          initialData={{ title, previewUrl: content?.previewUrl, videoUrl: content?.url }}
          onChange={handleFormChange}
          customValidation={customValidation}
          name="vimeo-video-form"
        />
      </Box>
      {displayDetail?.totalTime && (
        <Segment>
          <Label as="a" color="red" ribbon>
            <Icon className="clock outline" />
            {displayDetail.totalTime}
          </Label>
          {formData?.videoUrl && (
            <VideoPlayerWrapper
              url={formData.videoUrl}
              videoType={VIDEO_TYPE.VIMEO}
              playerProps={{
                videoOptions: { autoplay: false },
              }}
            />
          )}
        </Segment>
      )}
      <Divider pb="3" />
      <Box>
        <ActionBar
          isCreate={newAsset}
          onAction={handleAction}
          onCancel={onCancel}
          loading={loading}
          materialTypeString={materialTypeString}
        />
      </Box>
    </Box>
  );
};

export default VimeoVideo;
