import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CreateEditForm from 'Containers/CreateEditForm';
import {
  createCourseAnnouncement,
  updateCourseAnnouncement,
} from 'GraphQL/mutation/CourseAnnouncement.mutation';
import { getAnnouncement } from 'GraphQL/query/Announcement.query';
import { getCourseAnnouncementReports } from 'GraphQL/query/CourseAnnouncement.query';

import delve from '../../../../../Util/Delve';
import { RELATION_TYPE, STATUS } from '../Components/constants';
import { AnnouncementForm } from '../Form';

const GenForm = ({ data, onSubmit, onCancelled, isView, isEdit, relationId }) => {
  const mapDataIntoForm = ({ detail, audiences, ...restData }) => {
    const mapPublishConfig = {
      publishedTime: audiences[0].publishedTime,
      status: audiences[0].status,
    };
    const mapDetail = {
      content: detail.content,
      type: detail.type.toLowerCase(),
      title: detail.title,
      description: detail.description,
      rawContent: detail.rawContent,
    };
    return {
      detail: mapDetail,
      publishConfig: mapPublishConfig,
      pushNotificationTitle: detail.subtitle, // Course Announcement -> title = course name, subtitle = push notification title
      pushNotificationDescription: detail.description,
      utmCampaignId: detail.utmCampaignId,
      utmCampaignContent: detail.utmCampaignContent,
      customPushNotificationImage: detail.imageUrl,
      ...restData,
    };
  };

  const mapFormValuePublishConfig = ({ ...formValue }, isPublish) => {
    const publishConfig = { ...formValue.publishConfig };
    const isNow = publishConfig.isNow;
    const status = isPublish
      ? moment() >= publishConfig.publishedTime || isNow
        ? STATUS.PUBLISHED
        : STATUS.SCHEDULED
      : STATUS.DRAFT;

    return {
      ...formValue,
      publishConfig: {
        publishedTime:
          isNow || moment() >= publishConfig.publishedTime ? moment() : publishConfig.publishedTime,
        status,
      },
    };
  };

  const handleOnSubmit = ({ ...formValue }) => {
    const mappedFormData = mapFormValuePublishConfig({ ...formValue }, true);
    onSubmit({ ...mappedFormData });
  };

  const handleOnSubmitDraft = ({ ...formValue }) => {
    const mappedFormData = mapFormValuePublishConfig({ ...formValue }, false);
    onSubmit({ ...mappedFormData });
  };
  return (
    <AnnouncementForm
      onCancelled={onCancelled}
      initialData={data && mapDataIntoForm(data.announcement)}
      onSubmit={handleOnSubmit}
      onSubmitDraft={handleOnSubmitDraft}
      isView={isView}
      isEdit={isEdit}
      relationId={relationId}
    />
  );
};

GenForm.propTypes = {
  data: PropTypes.shape({
    announcement: PropTypes.object,
  }),
  onSubmit: PropTypes.func,
  onCancelled: PropTypes.func,
  isView: PropTypes.bool,
  isEdit: PropTypes.bool,
  relationId: PropTypes.string.isRequired,
};

const CreateEditAnnouncementForm = ({
  onComplete,
  onCancelled,
  announcementId,
  isEdit,
  isView,
  searchVariables,
  courseTitle,
  coverImageUrl,
}) => {
  const relationId = searchVariables.search.relationId;
  return (
    <CreateEditForm
      dataQuery={getAnnouncement}
      createMutation={createCourseAnnouncement}
      createRefetchQueries={[{ query: getCourseAnnouncementReports, variables: searchVariables }]}
      editRefetchQueries={[
        { query: getCourseAnnouncementReports, variables: searchVariables },
        { query: getAnnouncement, variables: { announcementId: announcementId } },
      ]}
      editMutation={updateCourseAnnouncement}
      isEdit={isEdit || isView}
      id={announcementId}
      getQueryVariables={({ id }) => ({
        announcementId: id,
      })}
      getEditVariables={({ audiences, ...form }, { id }) => {
        const data = {
          announcementId: id,
          courseAnnouncementData: {
            ...form,
            detail: {
              content: form.detail.content,
              type: form.detail.type.toUpperCase(),
              title: courseTitle,
              subtitle: form.pushNotificationTitle ?? form.title,
              description: form.pushNotificationDescription ?? '-',
              utmCampaignId: form.utmCampaignId,
              utmCampaignContent: form.utmCampaignContent,
              rawContent: form.detail.rawContent,
              imageUrl: form.includePushNotificationImage
                ? form.customPushNotificationImage ?? coverImageUrl
                : null,
            },
          },
        };

        delete data.courseAnnouncementData.isEmail;
        delete data.courseAnnouncementData.includePushNotificationImage;
        delete data.courseAnnouncementData.customPushNotificationImage;
        delete data.courseAnnouncementData.pushNotificationTitle;
        delete data.courseAnnouncementData.pushNotificationDescription;
        delete data.courseAnnouncementData.utmCampaignId;
        delete data.courseAnnouncementData.utmCampaignContent;
        return data;
      }}
      getCreateVariables={(form) => {
        const data = {
          courseAnnouncementData: {
            ...form,
            audiences: {
              relationId,
              relationType: RELATION_TYPE.COURSE,
            },
            detail: {
              content: form.detail.content,
              type: form.detail.type.toUpperCase(),
              title: courseTitle,
              subtitle: form.pushNotificationTitle ?? form.title,
              description: form.pushNotificationDescription ?? '-',
              utmCampaignId: form.utmCampaignId,
              utmCampaignContent: form.utmCampaignContent,
              rawContent: form.detail.rawContent,
              imageUrl: coverImageUrl ?? null,
              imageUrl: form.includePushNotificationImage
                ? form.customPushNotificationImage ?? coverImageUrl
                : null,
            },
          },
        };

        delete data.courseAnnouncementData.isEmail;
        delete data.courseAnnouncementData.includePushNotificationImage;
        delete data.courseAnnouncementData.customPushNotificationImage;
        delete data.courseAnnouncementData.pushNotificationTitle;
        delete data.courseAnnouncementData.pushNotificationDescription;
        delete data.courseAnnouncementData.utmCampaignId;
        delete data.courseAnnouncementData.utmCampaignContent;
        return data;
      }}
      getEditMutationResponse={(mutateData) => delve(mutateData, 'updateCourseAnnouncement')}
      getCreateMutationResponse={(mutateData) => delve(mutateData, 'createCourseAnnouncement')}
      onCompleted={onComplete}
      onCancelled={onCancelled}
      checkMutationResponse
    >
      {({ data, onSubmit }) => (
        <GenForm
          data={data}
          onSubmit={onSubmit}
          onCancelled={onCancelled}
          isView={isView}
          isEdit={isEdit}
          relationId={relationId}
        />
      )}
    </CreateEditForm>
  );
};

CreateEditAnnouncementForm.propTypes = {
  announcementId: PropTypes.string,
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  isView: PropTypes.bool,
  searchVariables: PropTypes.shape({
    search: PropTypes.object,
    paging: PropTypes.object,
    order: PropTypes.object,
  }),
};

export default CreateEditAnnouncementForm;
