import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import ErrorView from 'Components/ErrorView';
import { getUserAssignment, getUserAssignmentCourseSummary } from 'GraphQL/query/Courses.query';
import { gradeUserAssignment as gradeUserAssignmentMutation } from 'GraphQL/mutation/Courses.mutation';

import AssignmentReviewFormView from '../Components/AssignmentReviewForm';
import Title from '../Components/Title';
import WarningModal from '../Components/WarningModal';

function getGradingErrorMessage(error) {
  const rawMessage = error?.message ?? '';
  if (rawMessage.includes('401')) return `You don't have permission to do this action`;
  return `Failed to grade this user assignment, please try again`;
}

const AssignmentReviewForm = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const userAssignmentId = params?.userAssignmentId;
  const courseId = params?.courseId;

  const [openModal, setOpenModal] = useState(false);

  const { data: queryData, loading: queryLoading, refetch: refetchUserAssignment } = useQuery(
    getUserAssignment,
    {
      variables: { userAssignmentId: userAssignmentId },
      skip: !userAssignmentId,
    }
  );
  const { status, resultStatus, reviewedComment, assignmentDetail, score } =
    queryData?.userAssignmentById ?? {};

  const [gradeUserAssignment, { loading: mutationLoading, error: mutationError }] = useMutation(
    gradeUserAssignmentMutation,
    {
      refetchQueries: [
        { query: getUserAssignmentCourseSummary, variables: { sourceCourseId: courseId } },
      ],
      onCompleted: () => {
        history.push('.');
      },
    }
  );

  const handleSubmit = async ({ status, score, reviewNote }) => {
    const previousSubmittedTime = queryData.userAssignmentById.submittedAt;
    const { data: refetchData } = await refetchUserAssignment();

    if (refetchData?.userAssignmentById?.submittedAt !== previousSubmittedTime) {
      setOpenModal(true);
    } else {
      gradeUserAssignment({
        variables: {
          id: userAssignmentId,
          gradeData: {
            isPassed: status ? status === 'passed' : undefined,
            score: score ? parseFloat(score) : undefined,
            comment: reviewNote,
          },
        },
      });
    }
  };

  return (
    <>
      <Title>Reviewed</Title>
      {mutationError && <ErrorView message={getGradingErrorMessage(mutationError)} />}
      <AssignmentReviewFormView
        reviewable={status === 'SUBMITTED'}
        status={resultStatus}
        reviewNote={reviewedComment}
        gradingType={assignmentDetail?.gradingType}
        score={score}
        fullscore={assignmentDetail?.fullScore}
        onSubmit={handleSubmit}
        disabled={mutationLoading || queryLoading}
        mt={2}
      />
      <WarningModal
        isOpen={openModal}
        close={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};

export default AssignmentReviewForm;
