export const formatPermissionList = (appPermissions = [], appRolePermissions = []) => {
  // ['Course', 'Announcement', 'Exam', ....]
  const uniqueCategories = Array.from(
    new Set(appPermissions.map((permission) => permission.category))
  );

  const permissionList = uniqueCategories.map((category) => {
    // [{ id, name, description, category: 'Course'},  {...}]
    const allPermissionsInCategory = appPermissions.filter(
      (permission) => permission.category === category
    );
    const rolePermissions = appRolePermissions.filter(
      (permission) => permission.category === category
    );

    // ['List', 'Overview', 'Enrollment', 'Exam', 'Lessons', ...];
    const uniquePermissions = Array.from(
      new Set(allPermissionsInCategory.map((item) => item.name))
    );

    const collapsedData = uniquePermissions.map((item) => {
      const currentRolePermissions = rolePermissions.filter(
        (permission) => permission.name === item
      );

      const getPermissionIdByAction = (action) =>
        allPermissionsInCategory.find(
          (permission) => permission.name === item && permission.action === action
        )?.id;

      return {
        name: item,
        accessor: item,
        view: {
          isAllow: currentRolePermissions.some((permission) => permission.action === 'VIEW'),
          id: getPermissionIdByAction('VIEW'),
        },
        update: {
          isAllow: currentRolePermissions.some((permission) => permission.action === 'UPDATE'),
          id: getPermissionIdByAction('UPDATE'),
        },
        delete: {
          isAllow: currentRolePermissions.some((permission) => permission.action === 'DELETE'),
          id: getPermissionIdByAction('DELETE'),
        },
      };
    });

    return {
      title: category,
      accessor: category,
      collapsedData,
    };
  });

  return permissionList;
};
