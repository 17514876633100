import React, { Component } from 'react';
import genUniqId from 'lodash/uniqueId';
import { List } from 'semantic-ui-react';

import { Flex } from 'Components/Base';

import QueryTable from '../../../../GraphQL/util/QueryTable';
import COLUMN_TYPE from '../../../../Components/Search/SearchFormGenerator/constantType';
import { userReport as query } from '../../../../GraphQL/query/Reports.query';
import Text from '../../../../Components/Base/Text';
import getConfig from '../../../../Util/Config';

const excludeColumns = () => ['userId'];
const getColumns = () => {
  const allColumns = [
    {
      Header: 'User Id',
      accessor: 'userId',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Email',
      accessor: 'email',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'Company',
      accessor: 'organizationName',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Position',
      accessor: 'position',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'Mobile',
      accessor: 'mobileNo',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'Registered Date',
      accessor: 'createdAt',
      type: COLUMN_TYPE.DATE,
      isSearchAble: false,
    },
    {
      Header: 'Confirmation Status',
      accessor: 'emailVerified',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      Cell: ({ original }) => {
        const { emailVerified } = original;
        return emailVerified ? 'Verified' : 'Not Verified';
      },
      csvDisplay: ({ original }) => {
        const { emailVerified } = original;
        return emailVerified ? 'Verified' : 'Not Verified';
      },
    },
    {
      Header: '# Active Certificate',
      accessor: 'activeCertificatesNum',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'Certificates',
      accessor: 'activeCertificates',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      Cell: ({ original }) => {
        const { activeCertificates } = original;
        if (!activeCertificates) return null;
        if (activeCertificates.length === 0) return '-';
        return (
          <List bulleted>
            {activeCertificates.map((cert) => (
              <List.Item key={genUniqId()}>{cert.name}</List.Item>
            ))}
          </List>
        );
      },
      csvDisplay: ({ original }) => {
        const { activeCertificates } = original;
        if (!activeCertificates) return null;
        if (activeCertificates.length === 0) return '-';
        return activeCertificates.map((c) => c.name).join(' | ');
      },
    },
    {
      Header: '# Completed Courses',
      accessor: 'finishedCoursesNum',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'Completed Courses',
      accessor: 'finishedCourses',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      Cell: ({ original }) => {
        const { finishedCourses } = original;
        if (!finishedCourses) return null;
        if (finishedCourses.length === 0) return '-';
        return (
          <List bulleted>
            {finishedCourses.map((course) => (
              <List.Item key={genUniqId()}>{course.name}</List.Item>
            ))}
          </List>
        );
      },
      csvDisplay: ({ original }) => {
        const { finishedCourses } = original;
        if (!finishedCourses) return null;
        if (finishedCourses.length === 0) return '-';
        return finishedCourses.map((c) => c.name).join(' | ');
      },
    },
    {
      Header: '# Passed Exams',
      accessor: 'passedExamsNum',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
  ];
  if (getConfig().lineHideColumns) {
    return allColumns.filter((column) => !excludeColumns().includes(column.accessor));
  }
  return allColumns;
};

class UserReport extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 20 },
    search: {},
    order: { field: 'createdAt', type: 'DESC' },
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Users</Text.Header>
        </Flex>
        <QueryTable
          type="reportUser"
          resolveData={(data) => {
            return data.reportUser.users;
          }}
          columns={getColumns()}
          query={query}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          exportable
        />
      </div>
    );
  }
}

export default UserReport;
