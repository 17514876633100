import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { DatePickerInput } from 'Components/DatePickerInput';

import FormGenerator from '../../../Components/Form/FormGenerator';

import CourseSelectorInput from './CourseSelectorInput';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Promo Code (ห้ามซ้ำ)',
    inputName: 'code',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'มูลค่า',
    inputName: 'discount',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'หน่วย',
    inputName: 'discountUnit',
    options: [
      { key: 'FIX', value: 'FIX', text: 'FIX' },
      { key: 'PERCENT', value: 'PERCENT', text: 'PERCENT' },
    ],
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'คอร์ส',
    inputName: 'courseReferIds',
    customInput: (props) => (
      <CourseSelectorInput
        {...props}
        variables={{
          search: { version: CourseSelectorInput.COURSE_VERSION.SKOOLDIO_V1 },
          paging: { currentPage: 0, pageSize: 1000 }, // TODO: Workaround for Input not searchable
          order: { field: 'id', type: 'ASC' },
        }}
        placeholder="Select Courses"
        valueType={CourseSelectorInput.SELECTOR_VALUE_TYPE.REFER_ID}
        secondaryDisplayField={CourseSelectorInput.COURSE_FIELDS.REFER_ID}
      />
    ),
    // inputProps: {
    //   required: true,
    // },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel:
      'จำนวนลูกค้าขั้นต่ำที่จะใช้โค้ดได้ (ถ้ากำหนดขั้นต่ำ ระบบจะลดราคาตามมูลค่าของโค้ดคูณจำนวนคนในออเดอร์นั้น)',
    inputName: 'minimumQuantity',
    options: [
      { key: '0', value: null, text: 'ไม่กำหนดเงื่อนไขนี้ (ลดราคาของออเดอร์เท่ากับมูลค่าโค้ด)' },
      { key: '1', value: 1, text: 'สำหรับลูกค้า 1 ท่านขึ้นไป' },
      { key: '2', value: 2, text: 'สำหรับลูกค้า 2 ท่านขึ้นไป' },
      { key: '3', value: 3, text: 'สำหรับลูกค้า 3 ท่านขึ้นไป' },
      { key: '4', value: 4, text: 'สำหรับลูกค้า 4 ท่านขึ้นไป' },
      { key: '5', value: 5, text: 'สำหรับลูกค้า 5 ท่านขึ้นไป' },
    ],
    inputProps: {
      defaultValue: null,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'สำหรับลูกค้า (บุคคล หรือ องค์กร)',
    inputName: 'customerType',
    options: [
      { key: 'ALL', value: 'ALL', text: 'ลูกค้าทุกประเภทสามารถใช้ได้' },
      { key: 'INDIVIDUAL', value: 'INDIVIDUAL', text: 'ลุกค้าบุคคลเท่านั้น' },
      { key: 'CORPORATE', value: 'CORPORATE', text: 'ลูกค้าองค์กรเท่านั้น' },
    ],
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'วันที่เริ่มต้น',
    inputName: 'startDate',
    customInput: () => <DatePickerInput />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'วันหมดอายุ',
    inputName: 'endDate',
    customInput: () => <DatePickerInput isEndDate={true} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'วันหมดอายุคอร์ส',
    inputName: 'productExpiryDate',
    customInput: () => <DatePickerInput isEndDate={true} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Description',
    inputName: 'discountText',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'สกุลเงิน',
    inputName: 'currency',
    options: [{ key: 'THB', value: 'THB', text: 'THB' }],
    inputProps: {
      defaultValue: 'THB',
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'จำนวนตั้งต้น',
    inputName: 'total',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'จำนวนคงเหลือ',
    inputName: 'remain',
  },
];
export default class DiscountForm extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    discountId: PropTypes.string,
    onCreateNewDiscount: PropTypes.func,
  };

  static defaultProps = {
    onCreateNewDiscount: () => {},
    isEdit: false,
  };
  handleSubmit = (form) => {
    const discountData = {
      ...form,
      discountUnit: [form.discountUnit],
    };
    this.props.onCreateNewDiscount(discountData);
  };
  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={fields}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
