import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';
import CreateEditForm from '../../../Containers/CreateEditForm';
import {
  createCertificateItemMutation,
  updateCertificateItemMutation,
} from '../../../GraphQL/mutation/Certificate.mutation';
import {
  getCertificateItems,
  getCertificateItem,
  CERTIFICATE_ITEM_TYPE,
} from '../../../GraphQL/query/Certificate.query';

import CertificateItemForm from './CertificateItemForm';

export default class CertificateModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    certificateItemId: PropTypes.string,
    certificateId: PropTypes.string,
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { isEdit, certificateItemId, certificateId, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>
              {isEdit ? 'Edit Certificate Item' : 'Create New Certificate Item'}
            </Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getCertificateItem}
                createMutation={createCertificateItemMutation}
                createRefetchQueries={[
                  {
                    query: getCertificateItems,
                    variables,
                  },
                ]}
                editMutation={updateCertificateItemMutation}
                isEdit={isEdit}
                id={certificateItemId}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  certificateItemData: form,
                })}
                getCreateVariables={(form) => ({
                  certificateItemData: Object.assign(
                    {},
                    {
                      ...form,
                      certificateId,
                      type: CERTIFICATE_ITEM_TYPE.CONDITION,
                    }
                  ),
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <CertificateItemForm
                      initialData={data && data.certificateItem}
                      onSubmit={onSubmit}
                    />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
