import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Dimmer as BaseDimmer } from 'semantic-ui-react';
import styled from 'styled-components';

import { Box, AspectRatio, BackgroundImage } from 'Components/Base';
import FileUploadManagerModal, { UPLOAD_MODE } from 'Components/Lib/UploadComponent';

const Dimmer = styled(BaseDimmer)`
  && {
    z-index: ${({ theme }) => theme.zIndices.imageUploadOverlay};
  }
`;

class SKUImageUpload extends Component {
  static propTypes = {
    fileImageUrl: PropTypes.string,
    changeImgText: PropTypes.string,
    onFileListRequest: PropTypes.func,
    onFileUploadUrlRequest: PropTypes.func,
    onImageUpdate: PropTypes.func,
    uploadMode: PropTypes.oneOf(Object.values(UPLOAD_MODE)).isRequired,
  };

  static defaultProps = {
    changeImgText: 'Change image',
    fileImageUrl: '/square-image.png',
    onFileListRequest: () => {},
    onFileUploadUrlRequest: () => {},
    onImageUpdate: () => {},
  };

  state = {
    active: false,
  };

  handleHover = (active) => {
    this.setState({ active: active });
  };

  handleSelectImage = (files = []) => {
    if (files && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      // Now Support only one file upload
      const { key, bucket, url } = files[0];
      this.props.onImageUpdate({ key, bucket, url });
      this.handleHover(false);
    }
  };

  render() {
    const {
      fileImageUrl = '/square-image.png',
      onFileListRequest,
      onFileUploadUrlRequest,
      uploadMode,
      changeImgText,
    } = this.props;
    const { active } = this.state;
    return (
      <Box
        onMouseOver={() => {
          this.handleHover(true);
        }}
        onMouseLeave={() => {
          this.handleHover(false);
        }}
        style={{ cursor: 'pointer' }}
      >
        <FileUploadManagerModal
          keyGroup="courseImage"
          dimmer="inverted"
          uploadUrl={'http://localhost:5000'}
          listUrl={'http://localhost:5000'}
          allowedContentTypes={['image/png', 'image/jpeg', 'image/gif']}
          defaultText={'Support JPG, PNG or GIF file with less than 5MB'}
          maxFileSize={5}
          maxSelection={1}
          customFileListUrlRequest={onFileListRequest}
          customUploadUrlRequest={onFileUploadUrlRequest}
          mode={uploadMode}
          onFileManagerDialogClose={this.handleSelectImage}
          trigger={
            //Put it here to allow user to click any where in picture to change image
            <Dimmer.Dimmable dimmed={active}>
              <AspectRatio ratio={16 / 9}>
                <BackgroundImage imageUrl={fileImageUrl} bgPosition={'center'} />
                <Dimmer active={active}>
                  <Button primary>
                    <Icon name="image outline" />
                    {changeImgText}
                  </Button>
                </Dimmer>
              </AspectRatio>
            </Dimmer.Dimmable>
          }
        />
      </Box>
    );
  }
}

export default SKUImageUpload;
