import React from 'react';
import { Entity } from 'draft-js';

/**
 * This component is derived from  https://github.com/sstur/react-rte/blob/master/src/lib/ImageDecorator.js
 */
function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
  }, callback);
}

/**
 * This component is derived from  https://github.com/sstur/react-rte/blob/master/src/ui/ImageSpan.js
 */
const ImageSpan = ({ entityKey, contentState, offsetKey, children, ...props }) => {
  const [width, setWidth] = React.useState(null);
  const [height, setHeight] = React.useState(null);
  const data = contentState.getEntity(entityKey).getData();
  const { src } = data;

  React.useEffect(() => {
    const image = new Image();
    const { src } = data;
    image.src = src;
    image.onload = () => {
      if (width == null || height == null) {
        setWidth(image.width);
        setHeight(image.height);
        contentState.mergeEntityData(entityKey, {
          width: image.width,
          height: image.height,
          originalWidth: image.width,
          originalHeight: image.height,
        });
      }
    };
  });

  const imageStyle = {
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    overflow: 'hidden',
    cursor: 'pointer',
    verticalAlign: 'bottom',
    backgroundImage: `url("${src}")`,
    backgroundSize: `${width}px ${height}px`,
    lineHeight: `${height}px`,
    fontSize: `${height}px`,
    width: `${width}px`,
    height: `${height}px`,
    letterSpacing: width,
  };

  return <span style={imageStyle}>{children}</span>;
};

export default {
  strategy: findImageEntities,
  component: ImageSpan,
};
