import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';

import QueryComponent from '../../../GraphQL/util/QueryComponent';
import { getConfig, BACKOFFICE_PRODUCT } from '../../../GraphQL/query/AppConfig.query';
import { singleUserQuery } from '../../../GraphQL/query/Users.query';

import ActionPanel from './ActionPanel';

const Query = ({ userId, ...restProps }) => (
  <QueryComponent query={getConfig}>
    {({ config }) => (
      <QueryComponent query={singleUserQuery} variables={{ userId }}>
        {({ user }) => {
          const { emailVerified, isDisabled } = user;
          return (
            <ActionPanel
              userId={userId}
              {...restProps}
              showLearnXActions={config.product === BACKOFFICE_PRODUCT.LEARNX}
              isUserVerified={emailVerified}
              isDisabled={isDisabled}
            />
          );
        }}
      </QueryComponent>
    )}
  </QueryComponent>
);

export default Query;
