import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const validate = (fullScore) => {
  if (fullScore <= 1) {
    return 1;
  } else if (fullScore >= 10000) {
    return 10000;
  }
  return fullScore;
};

const FullScoreInput = ({ value: fullScore, readOnly, onChange }) => {
  const handleOnChange = (fullScore) => {
    onChange(
      {},
      {
        value: validate(fullScore),
      }
    );
  };

  return (
    <Box alignItems="center">
      <Input
        type="number"
        name="fullScore"
        placeholder={'คะแนนเต็ม'}
        value={`${fullScore}`}
        onChange={(_, { value }) => handleOnChange(parseInt(value))}
        readOnly={readOnly}
      />
    </Box>
  );
};

FullScoreInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(FullScoreInput);
