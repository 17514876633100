import React from 'react';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBook } from '../../GraphQL/query/Products.query';
import { updateBookMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY } from '../../Domains/constants';

import BookOverviewForm from './BookOverviewForm';

const resolveData = ({ sfBoProductBook }) => {
  return {
    ...sfBoProductBook,
    SKUStatus: `${sfBoProductBook.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED}/${
      sfBoProductBook.SKUStatus
    }`,
  };
};

const BookOverviewEditor = (props) => {
  const { book = {} } = props;
  const { id, SKUCode } = book;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getBook}
        editMutation={updateBookMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({ SKUCode })}
        getEditVariables={(form) => ({
          id,
          data: {
            ...form,
            SKUStatus: form.SKUStatus.split('/')[1],
            isVisible: form.SKUStatus.split('/')[0] === SKU_VISIBILITY.VISIBLE,
          },
        })}
      >
        {({ data, onSubmit }) => {
          return <BookOverviewForm initialData={resolveData(data)} onSubmit={onSubmit} />;
        }}
      </CreateEditForm>
    </Box>
  );
};

export default BookOverviewEditor;
