import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';

import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { fileImportJobs } from 'GraphQL/LE/query/FileImport.query';
import { deleteJob } from 'GraphQL/LE/mutation/FileImport.mutation';

import ResultSummariesModal from '../../Components/ResultSummariesModal';
import ReportDetailsModal from '../../Components/ReportDetailsModal';
import { FILE_TYPE, FILE_IMPORT_STATUS } from '../../common';
import CreateIndividualReportModal from '../../Components/CreateIndividualReportModal';

const fileImportJobsColumns = (handleOnClick, handleDelete, variables) => [
  {
    Header: 'School',
    accessor: 'arg3',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 116,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: FILE_IMPORT_STATUS.NEW, value: FILE_IMPORT_STATUS.NEW, text: FILE_IMPORT_STATUS.NEW },
      {
        key: FILE_IMPORT_STATUS.PROCESSING,
        value: FILE_IMPORT_STATUS.PROCESSING,
        text: FILE_IMPORT_STATUS.PROCESSING,
      },
      {
        key: FILE_IMPORT_STATUS.SUCCESSFUL,
        value: FILE_IMPORT_STATUS.SUCCESSFUL,
        text: FILE_IMPORT_STATUS.SUCCESSFUL,
      },
      {
        key: FILE_IMPORT_STATUS.FAILED,
        value: FILE_IMPORT_STATUS.FAILED,
        text: FILE_IMPORT_STATUS.FAILED,
      },
    ],
    style: { display: 'flex', justifyContent: 'center' },
    isSearchAble: true,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    style: { marginLeft: '8px' },
  },
  {
    Header: 'Created By',
    accessor: 'arg2',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    style: { marginLeft: '8px' },
  },
  {
    Header: 'Report Details',
    accessor: 'result',
    width: 120,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
    style: { display: 'flex', justifyContent: 'center' },
    Cell: function FileResultCell({ original }) {
      const { arg1 } = original;
      const { details } = JSON.parse(arg1);
      return (
        <Button.Group>
          <ReportDetailsModal
            details={details}
            trigger={
              <Button basic size="mini" icon compact style={{ margin: 'auto' }}>
                <Icon name="tasks" />
              </Button>
            }
          />
        </Button.Group>
      );
    },
  },
  {
    Header: 'Result Details',
    accessor: 'result',
    width: 120,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
    style: { display: 'flex', justifyContent: 'center' },
    Cell: function ResultDetailsCell({ original }) {
      const { id, status } = original;

      if (status === FILE_IMPORT_STATUS.SUCCESSFUL || status === FILE_IMPORT_STATUS.FAILED) {
        return (
          <Button.Group>
            <ResultSummariesModal
              jobId={id}
              trigger={
                <Button basic size="mini" icon compact style={{ margin: 'auto' }}>
                  <Icon name="tasks" />
                </Button>
              }
            />
          </Button.Group>
        );
      } else {
        return 'Processing..';
      }
    },
  },
];

class CreateReport extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(FILE_TYPE)).isRequired,
  };

  static defaultProps = {
    // pageSize: 10,
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { type: props.type },
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  fileMutate = (mutation, jobId) => {
    const { search, paging, order } = this.state;
    this.props.client
      .mutate({
        mutation,
        variables: { jobId },
        refetchQueries: [
          {
            query: fileImportJobs,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  handleDelete = (jobId) => {
    this.fileMutate(deleteJob, jobId);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, type: this.props.type }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.boFileImportJobs.fileImportJobs;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    const { title, type } = this.props;
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>{title}</Text.Header>
          <CreateIndividualReportModal
            title={`Create New ${title}`}
            type={type}
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                Create
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={'boFileImportJobs'}
          resolveData={this.resolveData}
          columns={fileImportJobsColumns(this.handleOnClick, this.handleDelete, variables)}
          query={fileImportJobs}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default withApollo(CreateReport);
