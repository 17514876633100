import React from 'react';
import styled from 'styled-components';

import { Flex } from 'Components/Base';

const BaseHeader = styled((props) => (
  <Flex width={1} bg={'current'} alignItems={'stretch'} {...props} />
))`
  z-index: ${({ theme }) => theme.zIndices.navbar};
  position: fixed;
  top: 0;
  left: 0;
`;
const Header = ({ children, ...props }) => {
  return <BaseHeader {...props}>{children}</BaseHeader>;
};

export default Header;
