import React, { useState } from 'react';
import { Input, Modal, Button, Icon, Header } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

import delve from '../../../../Util/Delve';
import WorkshopImageUpload from '../WorkshopImageUpload';

export const ImagePreviewInput = ({ skuCode, value, onChange, ...inputProps }) => {
  const [isPreview, setPreview] = useState(false);
  const [media, setMedia] = useState({
    key: delve(value, 'key', ''),
    bucket: delve(value, 'bucket', ''),
    assetUrl: delve(value, 'assetUrl', ''),
  });

  const onImageUpdate = ({ key, bucket, url: assetUrl }) => {
    const updatedMedia = {
      ...media,
      key,
      bucket,
      assetUrl,
    };
    setMedia(updatedMedia);
    onChange({}, { value: updatedMedia });
  };

  return (
    <Box>
      <Input
        action={
          <Button onClick={() => setPreview(true)} type="button">
            <Flex alignItems="center">
              <Box mr={2}>Upload</Box>
              <Icon name="upload" />
            </Flex>
          </Button>
        }
        value={media.assetUrl}
      />
      <Modal open={isPreview} onClose={() => setPreview(false)} basic size="small">
        <Header icon="browser" content="Image Upload" />
        <Modal.Content>
          <WorkshopImageUpload
            changeImgText={'Change Preview Image'}
            skuCode={skuCode}
            imageUrl={media.assetUrl}
            onImageUpdate={onImageUpdate}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => setPreview(false)} inverted>
            <Icon name="close" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Box>
  );
};

export default withFormsy(ImagePreviewInput);
