import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Image, Icon } from 'semantic-ui-react';
import isUrl from 'is-url';

import { dateFormatter } from 'GraphQL/util/QueryTable/dataUtil';

import { default as Button } from '../../Base/Button';

class ImageUploadedModalView extends Component {
  state = { open: false, margin: 0 };

  static propTypes = {
    title: PropTypes.string,
    link: PropTypes.string.isRequired,
    dateTime: PropTypes.string,
  };

  static defaultProps = {
    title: 'Show',
  };

  show = () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  handleConfirm = () => {
    const { onConfirm, value } = this.props;
    onConfirm({ ...value });
    this.close();
  };
  handleCancel = () => {
    this.close();
  };
  handleButtonClick = () => {
    this.show();
  };
  handleModalClose = () => {
    this.close();
  };

  render() {
    const { title, link, dateTime } = this.props;
    return (
      <Fragment>
        <Button
          title={title}
          color="blue"
          basic
          size="mini"
          icon="file outline"
          compact
          onClick={this.handleButtonClick}
        />
        {
          // Modal BUG CSS `comfirm by P'Pap`
          // Use dimmer={false} to wait FIX semantic css
        }
        <Modal
          dimmer={false}
          centered
          closeIcon
          onClose={this.handleModalClose}
          size="fullscreen"
          open={this.state.open}
        >
          <Modal.Header>{`Pay slip ${
            dateTime ? `- uploaded on ${dateFormatter()(dateTime)}` : ''
          }`}</Modal.Header>
          <Modal.Content image scrolling>
            {isUrl(link) ? (
              <Image centered wrapped src={link} alt={`${title + link}`} />
            ) : (
              <Fragment>Invalid Url</Fragment>
            )}
          </Modal.Content>
          <Modal.Actions>
            {isUrl(link) && (
              <a href={link} target="_blank">
                Open Image in New Tab
              </a>
            )}
            <Button basic color="grey" title="Close" onClick={this.handleCancel} />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default ImageUploadedModalView;
