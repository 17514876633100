import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Icon } from 'semantic-ui-react';
import { compose, mapProps } from 'recompose';
import { withApollo } from '@apollo/client/react/hoc';

import QueryTable from 'GraphQL/util/QueryTable';
import ConfirmationButton from 'Components/ConfirmationButton';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { classroomEnrollmentByCourseIdQuery } from 'GraphQL/query/Registrar.query';
import { deleteStudentEnrollmentMutation } from 'GraphQL/mutation/Registrar.mutation';

import { getRouteInfo } from '../utils';
import PublishToolbar from '../../Containers/PublishToolbar';
import { ImageValidationMessagePopup } from '../Overview/Components/ImageValidationMessagePopup';

import { CLASSROOM_MODE } from './constant';

const unsortedColumns = ['externalUsername', 'firstname', 'lastname', 'userType', 'action'];
const getColumns = (handleOnDelete) => [
  {
    Header: 'User Id',
    accessor: 'userId',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'External Username',
    accessor: 'externalUsername',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'First Name',
    accessor: 'firstname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Last Name',
    accessor: 'lastname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'User Type',
    accessor: 'userType',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Enrollment Date',
    accessor: 'createdAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Deleted Date',
    accessor: 'deletedAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: false,
  },
  {
    Header: 'Action',
    accessor: 'action',
    width: 100,
    isSearchAble: false,
    Cell: ({ original }) => {
      const { id: classroomId, userId, deletedAt } = original;
      if (deletedAt === null) {
        return (
          <ConfirmationButton
            headerText={`Delete?`}
            contentText={'This classroom enrollment will be permanently deleted from the system.'}
            confirmText={'Delete'}
            onConfirm={() => {
              handleOnDelete(userId, classroomId);
            }}
          >
            <Icon name="trash alternate outline" />
          </ConfirmationButton>
        );
      }
      return null;
    },
  },
];

class ClassroomEnrollment extends Component {
  static propTypes = {
    courseId: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  };

  state = {
    paging: { currentPage: 0, pageSize: 20 },
    search: {},
    order: { field: 'userId', type: 'DESC' },
    isOpenPopup: false,
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    if (unsortedColumns.indexOf(sorted[0].id) !== -1) return;
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  handleOnDelete = async (userId, classroomId) => {
    const { search, paging, order } = this.state;
    const { client, courseId } = this.props;
    try {
      await client.mutate({
        mutation: deleteStudentEnrollmentMutation,
        variables: { userId, classroomId },
        refetchQueries: [
          {
            query: classroomEnrollmentByCourseIdQuery,
            variables: {
              search: { ...search, sourceCourseId: courseId, mode: CLASSROOM_MODE.FULL },
              paging,
              order,
            },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  resolveData = (rawData) => {
    const data = rawData.classroomEnrollmentByCourseId.classroomEnrollments;
    return data.map((singleData) => {
      const { externalUsername, firstname, lastname, type } = singleData.user;
      const { id, createdAt, deletedAt } = singleData;
      const userId = singleData.user.id;
      const mappedData = {
        id,
        createdAt,
        userId,
        externalUsername,
        firstname,
        lastname,
        userType: type,
        deletedAt,
      };
      return mappedData;
    });
  };

  render() {
    const { search, paging, order } = this.state;
    const { courseId, product, version } = this.props;
    return (
      <Container>
        <ImageValidationMessagePopup
          content={'Image is required, please add course image before publish'}
          open={this.state.isOpenPopup}
          position="top right"
          trigger={<PublishToolbar courseId={courseId} product={product} version={version} />}
        />
        <QueryTable
          type="classroomEnrollmentByCourseId"
          resolveData={this.resolveData}
          columns={getColumns(this.handleOnDelete)}
          query={classroomEnrollmentByCourseIdQuery}
          search={{ ...search, sourceCourseId: courseId, mode: CLASSROOM_MODE.FULL }}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Container>
    );
  }
}

export default compose(mapProps(getRouteInfo), withApollo)(ClassroomEnrollment);
