import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import compose from 'recompose/compose';

import { editCourse, generateCourseImageUploadUrl } from 'src/GraphQL/mutation/Courses.mutation';
import { getCourseImageList } from 'src/GraphQL/query/Courses.query';
import { productCourseVersionResolver } from 'src/GraphQL/query/AppConfig.query';
import withServerConfig from 'src/GraphQL/util/withServerConfig';

import CourseImageUpload from './CourseImageUpload';

class CourseImageUploadGQL extends Component {
  static propTypes = {
    courseId: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    onImageUpdate: PropTypes.func,
  };

  handleImageUpdate = (editFn) => ({ key, bucket, url }) => {
    const { product, version, courseId, field } = this.props;
    let courseDesc = {};
    if (field === 'coverImage') {
      if (url) {
        courseDesc.coverImageUrl = url;
      } else {
        courseDesc.coverImageUrl = null;
      }
    } else if (field === 'logo') {
      if (key && bucket) {
        courseDesc.logo = { key, bucket };
      } else {
        courseDesc.logo = null;
      }
    }

    editFn({
      variables: {
        version: productCourseVersionResolver(product, version),
        courseId: courseId,
        courseDesc,
      },
    });
  };

  handleFileListRequest = async (request) => {
    const { client, courseId } = this.props;
    const { data } = await client.query({
      query: getCourseImageList,
      variables: {
        courseId,
      },
      fetchPolicy: 'no-cache',
    });
    console.log(data);
    return data.listCourseImages;
  };

  handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { courseId, client } = this.props;
    const { data } = await client.mutate({
      mutation: generateCourseImageUploadUrl,
      variables: {
        courseId: courseId,
        imageFileName: filename,
        imageContentType: contentType,
      },
      fetchPolicy: 'no-cache',
    });
    console.log(data);
    return data.generateCourseImageUploadUrl.url;
  };

  render() {
    const { config, onImageUpdate } = this.props;
    return (
      <Mutation mutation={editCourse}>
        {(editCourse, { data, loading }) => (
          <CourseImageUpload
            {...this.props}
            loading={loading}
            onImageUpdate={onImageUpdate ?? this.handleImageUpdate(editCourse)}
            onFileListRequest={this.handleFileListRequest}
            onFileUploadUrlRequest={this.handleFileUploadUrlRequest}
            uploadMode={config.assetUploadMode}
          />
        )}
      </Mutation>
    );
  }
}

export default compose(withApollo, withServerConfig())(CourseImageUploadGQL);
