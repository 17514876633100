import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import UsersList from './UsersList';

class CourseUsersList extends Component {
  render() {
    return (
      <Fragment>
        <UsersList {...this.props} />
      </Fragment>
    );
  }
}

CourseUsersList.defaultProps = {
  header: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    })
  ),
  emptyMessage: PropTypes.string,
  addUserCoursePermission: PropTypes.func,
  onRemoveUser: PropTypes.func,
};

CourseUsersList.defaultProps = {
  header: 'User List',
  emptyMessage: 'No Data',
  users: [],
  onRemoveUser: () => {},
};

export default CourseUsersList;
