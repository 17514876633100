import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import getConfig from 'Util/Config';

import ColorSelectorInput from '../../../../Components/ColorSelectorInput';
import FormGenerator from '../../../../Components/Form/FormGenerator';
import Editor from '../MarkupTextInput';
import ImagePreviewInput from '../SKUImagePreviewInput';
import { default as VideoPlayerPreviewInput } from '../VideoPlayerPreviewInput';
import { SKU_STATUS } from '../../Domains/constants';

import getSkuStatusOptions from './utils/getSkuStatusOptions';

const HEX_COLOR_VALIDATION = /^#([a-f0-9]{3,4}|[a-f0-9]{4}(?:[a-f0-9]{2}){1,2})\b$/i;

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const { products } = getConfig();

const fields = (id, publishedAt) => {
  return [
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Status',
      inputName: 'status',
      options: getSkuStatusOptions('WORKSHOP'),
      mode: MODE.EDIT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'SKU Code',
      inputName: 'SKUCode',
      inputProps: {
        required: true,
        disabled: publishedAt,
      },
      mode: MODE.CREATE,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Workshop Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Workshop Sub Title',
      inputName: 'subTitle',
      mode: MODE.EDIT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'BackgroundColor',
      inputName: 'backgroundColor',
      customInput: () => <ColorSelectorInput />,
      mode: MODE.EDIT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Sharing Image',
      inputName: 'sharingImage',
      customInput: () => <ImagePreviewInput skuCode={id} />,
      mode: MODE.EDIT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'YouTube Video ID',
      inputName: 'promotionYoutubeId',
      customInput: () => <VideoPlayerPreviewInput />,
      mode: MODE.EDIT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Workshop Request URL (e.g. Google Form)',
      inputName: 'workshopRequestUrl',
      mode: MODE.EDIT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'SEO Descriptions',
      inputName: 'SEODescriptions',
      mode: MODE.EDIT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'SEO Keywords',
      inputName: 'SEOKeywords',
      mode: MODE.EDIT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Contact',
      inputName: 'contactInfo',
      mode: MODE.EDIT,
      customInput: ({ name }) => <Editor name={name} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Promotion',
      inputName: 'promotionText',
      mode: MODE.EDIT,
      customInput: ({ name }) => <Editor name={name} />,
    },
  ];
};

export default class WorkshopDetailForm extends Component {
  static propTypes = {
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  validate = ({ backgroundColor }, isChanged) => {
    const error = {};
    if (backgroundColor && !HEX_COLOR_VALIDATION.test(backgroundColor) && isChanged) {
      error.backgroundColor = 'Background color must be in Hex-Color format';
    }

    return error;
  };

  getFormFields = () => {
    const { isEdit, id } = this.props;
    const publishedAt = this.props.initialData?.publishedAt;
    return fields(id, publishedAt).filter(
      (f) =>
        f.mode === MODE.BOTH ||
        (isEdit && f.mode === MODE.EDIT) ||
        (!isEdit && f.mode === MODE.CREATE)
    );
  };

  render() {
    const { isEdit, onSubmit } = this.props;
    return (
      <FormGenerator
        fields={this.getFormFields()}
        customValidation={this.validate}
        onSubmit={onSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
