import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import { userDeviceTokensQuery } from '../../../GraphQL/query/DeviceToken.query';
import { deleteUserDeviceTokenMutation } from '../../../GraphQL/mutation/DeviceToken.mutation';
import QueryComponent from '../../../GraphQL/util/QueryComponent';
import DeviceTokenList from '../Components/DeviceTokenList';

export default class DeviceTokenListGQL extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  handleRemoveClick(deleteToken, data) {
    const { userId } = this.props;
    deleteToken({ variables: { deviceTokenId: data.id } });
  }

  render() {
    const { userId, ...restProps } = this.props;
    return (
      <QueryComponent query={userDeviceTokensQuery} variables={{ userId }}>
        {({ userDeviceTokens }) => (
          <Mutation mutation={deleteUserDeviceTokenMutation}>
            {(deleteToken, { data, loading, error }) => (
              <Fragment>
                {error && <Message negative>{JSON.stringify(error.message)}</Message>}
                {data && data.deleteUserDeviceToken && !data.deleteUserDeviceToken.success && (
                  <Message negative>{JSON.stringify(data.deleteUserDeviceToken.message)}</Message>
                )}
                <DeviceTokenList
                  data={userDeviceTokens.deviceTokens}
                  {...restProps}
                  loading={loading}
                  onRemoveClick={(data) => this.handleRemoveClick(deleteToken, data)}
                />
              </Fragment>
            )}
          </Mutation>
        )}
      </QueryComponent>
    );
  }
}
