import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createAssignmentMutation,
  updateAssignmentMutation,
} from 'GraphQL/LSP/mutation/Syllabus.mutation';
import { getAllSyllabuses, getAssignments, getAssignment } from 'GraphQL/LSP/query/Syllabus.query';
import { getAllSchoolTerms, getAllSchoolGrades } from 'GraphQL/LSP/query/Education.query';
import { getAllStudentProfiles } from 'GraphQL/LSP/query/Profile.query';
import Loading from 'Components/Loading';
import ASSIGNMENT_TYPE from 'Util/LSP/assignmentType';

import SingleAssignmentForm from './SingleAssignmentForm';

function SingleAssignmentModal(props) {
  const { isEdit, id, trigger, variables } = props;
  const { loading: syllabusLoading, error: syllabusError, data: syllabusData } = useQuery(
    getAllSyllabuses,
    {
      fetchPolicy: 'network-only',
    }
  );

  const { loading: schoolTermLoading, error: schoolTermError, data: schoolTermData } = useQuery(
    getAllSchoolTerms,
    {
      fetchPolicy: 'network-only',
    }
  );
  const { loading: studentLoading, error: studentError, data: studentData } = useQuery(
    getAllStudentProfiles,
    {
      fetchPolicy: 'network-only',
    }
  );

  if (syllabusLoading || schoolTermLoading || studentLoading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{isEdit ? 'แก้ไขแผนการเรียนรายคน' : 'เพิ่มแผนการเรียนรายคน'}</Modal.Header>
          <Modal.Content>
            <CreateEditForm
              dataQuery={getAssignment}
              createMutation={createAssignmentMutation}
              createRefetchQueries={[
                {
                  query: getAssignments,
                  variables,
                },
              ]}
              editRefetchQueries={[
                {
                  query: getAssignments,
                  variables,
                },
              ]}
              editMutation={updateAssignmentMutation}
              isEdit={isEdit}
              id={id}
              getQueryVariables={({ id }) => ({
                id,
              })}
              getEditVariables={(form, { id }) => ({
                id,
                syllabusAssignmentData: {
                  ...form,
                  type: ASSIGNMENT_TYPE.PERSON,
                },
              })}
              getCreateVariables={(form) => ({
                syllabusAssignmentData: {
                  ...form,
                  type: ASSIGNMENT_TYPE.PERSON,
                },
              })}
              onCompleted={closeModal}
              onCancelled={closeModal}
            >
              {({ data, onSubmit }) => {
                return (
                  <SingleAssignmentForm
                    initialData={data && data.boSyllabusAssignment}
                    syllabusData={syllabusData && syllabusData.boAllSyllabuses}
                    schoolTermData={schoolTermData && schoolTermData.boAllSchoolTerms}
                    studentData={studentData && studentData.boAllStudentProfiles}
                    onSubmit={onSubmit}
                  />
                );
              }}
            </CreateEditForm>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default SingleAssignmentModal;
