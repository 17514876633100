import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from 'recompose';
import { Box } from '@skooldio/skooldio-ui-components-core';

import APP_PERMISSION from 'Util/permissions/permissionKeys';
import withAppConfig from 'Util/hoc/withAppConfig';

import Menu from '../../../Containers/Menu';
import { GenRoute } from '../../../Util/Route';
import withServerConfig from '../../../GraphQL/util/withServerConfig';
import { BACKOFFICE_PRODUCT } from '../../../GraphQL/query/AppConfig.query';
import {
  courseOverviewPath,
  courseLessonsPath,
  courseStatisticsPath,
  courseUsersPath,
  courseEnrollmentPath,
  courseAssignmentsPath,
  courseSubsectionAssignmentsPath,
  assignmentsPath,
  userAssignmentPath,
  courseAnnouncementsPath,
  getCourseOverviewUrl,
  getCourseLessonsUrl,
  getCourseStatisticsUrl,
  getCourseUsersUrl,
  getCourseEnrollmentUrl,
  getCourseAssignmentsUrl,
  getCourseAnnouncementsUrl,
} from '../Domain/coursePaths';

import Users from './Users';
import Lessons from './Lessons';
import Statistics from './Statistics';
import Overview from './Overview';
import { getRouteInfo } from './utils';
import Enrollment from './Enrollment';
import AssignmentLesson from './AssignmentLesson';
import AssignmentSubSection from './AssignmentSubSection';
import Assignments from './Assignments';
import UserAssignment from './UserAssignment';
import CourseAnnouncement from './Announcement';
import CourseTitle from './CourseTitle';

const GEN_MENU_MODE = { URL: 0, ROUTE: 1 };

const genMenu = (
  isGenRoute = GEN_MENU_MODE.ROUTE,
  params,
  {
    showStat = false,
    showUser = false,
    showLesson = false,
    showEnrollment = false,
    showAssignment = false,
    showAnnouncement = false,
  }
) => {
  const menus = [];
  menus.push({
    path: isGenRoute ? courseOverviewPath : getCourseOverviewUrl(params),
    title: 'Overview',
    private: true,
    exact: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.INSTRUCTOR,
      APP_PERMISSION.COURSE.OVERVIEW.VIEW,
      APP_PERMISSION.COURSE_TEAM.OVERVIEW.VIEW,
      'ce2.course.get',
    ],
    route: Overview,
  });

  if (showLesson) {
    menus.push({
      path: isGenRoute ? courseLessonsPath : getCourseLessonsUrl(params),
      title: 'Lessons',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.COURSE.LESSON.VIEW,
        APP_PERMISSION.COURSE_TEAM.LESSON.VIEW,
        'ce2.course.listItems',
      ],
      route: Lessons,
    });
  }

  if (showStat) {
    menus.push({
      path: isGenRoute ? courseStatisticsPath : getCourseStatisticsUrl(params),
      title: 'Statistics',
      exact: true,
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
      route: Statistics,
    });
  }
  if (showUser) {
    menus.push({
      path: isGenRoute ? courseUsersPath : getCourseUsersUrl(params),
      title: 'Users',
      exact: true,
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
      route: Users,
    });
  }

  if (showEnrollment) {
    menus.push({
      path: isGenRoute ? courseEnrollmentPath : getCourseEnrollmentUrl(params),
      title: 'Enrollment',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.COURSE.ENROLLMENT.VIEW,
        APP_PERMISSION.COURSE_TEAM.ENROLLMENT.VIEW,
        'cr.enrollment.list',
      ],
      route: Enrollment,
    });
  }

  if (showAssignment) {
    menus.push({
      path: isGenRoute ? courseAssignmentsPath : getCourseAssignmentsUrl(params),
      title: 'Assignments',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.COURSE.ASSIGNMENT.VIEW,
        APP_PERMISSION.COURSE_TEAM.ASSIGNMENT.VIEW,
      ],
      route: AssignmentLesson,
      exact: true,
    });
    menus.push({
      path: courseSubsectionAssignmentsPath,
      title: 'Assignment Subsection',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.COURSE.ASSIGNMENT.VIEW,
        APP_PERMISSION.COURSE_TEAM.ASSIGNMENT.VIEW,
      ],
      route: AssignmentSubSection,
      hideMenu: true,
      exact: true,
    });
    menus.push({
      path: assignmentsPath,
      title: 'Assignment',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.COURSE.ASSIGNMENT.VIEW,
        APP_PERMISSION.COURSE_TEAM.ASSIGNMENT.VIEW,
      ],
      route: Assignments,
      hideMenu: true,
      exact: true,
    });
    menus.push({
      path: userAssignmentPath,
      title: 'User Assignment',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.COURSE.ASSIGNMENT.VIEW,
        APP_PERMISSION.COURSE_TEAM.ASSIGNMENT.VIEW,
      ],
      route: UserAssignment,
      hideMenu: true,
      exact: true,
    });
  }

  if (showAnnouncement) {
    menus.push({
      path: isGenRoute ? courseAnnouncementsPath : getCourseAnnouncementsUrl(params),
      title: 'Announcements',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.COURSE.ANNOUNCEMENT.VIEW,
        APP_PERMISSION.COURSE_TEAM.ANNOUNCEMENT.VIEW,
      ],
      route: CourseAnnouncement,
    });
  }

  return menus;
};

/**
 * @param {object} props
 * @param {object} props.match
 * @param {string} props.config
 * @param {object} props.version
 * @param {typeof import('Util/Config').defaultConfig} props.appConfig
 * @returns
 */
const EditCourseRoute = ({ match, config, version, appConfig }) => {
  const { url } = match;
  const isAssignmentDetailRoute = window.location.pathname.match(/^.*assignments.+$/);

  return (
    <Box pb={4}>
      <CourseTitle />
      {!isAssignmentDetailRoute && (
        <Menu
          menus={genMenu(GEN_MENU_MODE.URL, match.params, {
            showStat: config.product === BACKOFFICE_PRODUCT.SKOOLDIO && version === 'v1',
            showUser: config.product === BACKOFFICE_PRODUCT.LEARNX,
            showLesson:
              (config.product === BACKOFFICE_PRODUCT.SKOOLDIO && version === 'v2') ||
              config.product === BACKOFFICE_PRODUCT.LEARNX,
            showEnrollment: config.product === BACKOFFICE_PRODUCT.SKOOLDIO && version === 'v2',
            showAssignment:
              config.product === BACKOFFICE_PRODUCT.SKOOLDIO &&
              version === 'v2' &&
              appConfig.courses.enableAssignmentSubsection,
            showAnnouncement:
              config.product === BACKOFFICE_PRODUCT.SKOOLDIO &&
              appConfig.courses.announcement?.enable,
          })}
          menuProps={{ secondary: true, pointing: true }}
        />
      )}
      <GenRoute
        menus={genMenu(GEN_MENU_MODE.ROUTE, match.params, {
          showStat: config.product === BACKOFFICE_PRODUCT.SKOOLDIO,
          showUser: config.product === BACKOFFICE_PRODUCT.LEARNX,
          showLesson:
            (config.product === BACKOFFICE_PRODUCT.SKOOLDIO && version === 'v2') ||
            config.product === BACKOFFICE_PRODUCT.LEARNX,
          showEnrollment: config.product === BACKOFFICE_PRODUCT.SKOOLDIO && version === 'v2',
          showAssignment:
            config.product === BACKOFFICE_PRODUCT.SKOOLDIO &&
            version === 'v2' &&
            appConfig.courses.enableAssignmentSubsection,
          showAnnouncement:
            config.product === BACKOFFICE_PRODUCT.SKOOLDIO &&
            appConfig.courses.announcement?.enable,
        })}
        product={config && config.product}
        version={version}
      />
    </Box>
  );
};

EditCourseRoute.propTypes = {
  match: PropTypes.object,
  config: PropTypes.shape({
    product: PropTypes.string,
    assetUploadMode: PropTypes.string,
  }),
  version: PropTypes.string,
  appConfig: PropTypes.object,
};

export default compose(
  mapProps(getRouteInfo),
  withServerConfig(),
  withAppConfig({ configProp: 'appConfig' })
)(EditCourseRoute);
