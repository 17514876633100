import React from 'react';

import {
  createOnlineCourseCategoryMutation,
  updateOnlineCourseCategoryMutation,
} from 'Routes/Products/GraphQL/mutation/Products.mutation';
import {
  getOnlineCourseCategoryByPermalink,
  getOnlineCourseCategories,
  getOnlineCourseCategory,
} from 'Routes/Products/GraphQL/query/Products.query';
import { SKU_CATEGORY } from 'Routes/Products/Domains/constants';

import CategoriesBase from './BaseComponent/CategoriesBase';
export const CategoriesOnlineCourseRoute = (props) => {
  return (
    <CategoriesBase
      {...props}
      skuCategory={SKU_CATEGORY.ONLINE_COURSE}
      getCategoryByPermalinkQueryName="sfBoProductOnlineCourseCategoryByPermalink"
      getCategoryByPermalink={getOnlineCourseCategoryByPermalink}
      getCategoriesQueryName="sfBoProductOnlineCourseCategories"
      getCategories={getOnlineCourseCategories}
      getCategory={getOnlineCourseCategory}
      getCategoryQueryName="sfBoProductOnlineCourseCategory"
      createMutation={createOnlineCourseCategoryMutation}
      updateMutation={updateOnlineCourseCategoryMutation}
    />
  );
};
