import React from 'react';
import Styled, { withTheme } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import urlJoin from 'url-join';
import { lighten } from 'polished';

import { Box, Flex } from 'Components/Base';

import { SemanticButton as Button } from '../../../../Components/Base/Button';

const PREVIEW_ROUTE = '_preview';

const StyledA = Styled.a`
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
     color: ${({ theme }) => lighten('0.1', theme.colors.primary)};
    }
`;

const ProductPreviewButton = withTheme(({ platformURL, permalink }) => {
  const URL = permalink && platformURL ? urlJoin(platformURL, PREVIEW_ROUTE, permalink) : '';
  return (
    <Button
      style={{ backgroundColor: '#FFFFFF' }}
      circular
      size="medium"
      type="button"
      disabled={!permalink || !platformURL}
    >
      <StyledA href={URL} target={'_blank'}>
        <Flex alignItems="center" justifyContent="center">
          <Box mr={2}>Preview</Box>
          <Icon name="eye" />
        </Flex>
      </StyledA>
    </Button>
  );
});

export default ProductPreviewButton;
