import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const useGoogleTagManager = (googleTagManagerInfo) => {
  const sendDataToGTM = (payload) => {
    TagManager.dataLayer({
      dataLayer: payload,
    });
  };

  const initGTM = (gtmInfo) => {
    if (gtmInfo?.gtmId) {
      TagManager.initialize({
        gtmId: gtmInfo?.gtmId,
        auth: gtmInfo?.auth,
        preview: gtmInfo?.preview,
      });
    }
  };

  useEffect(() => {
    if (!googleTagManagerInfo) return;
    if (Array.isArray(googleTagManagerInfo)) {
      googleTagManagerInfo.forEach((gtmConfig) => initGTM(gtmConfig));
      return;
    }
    initGTM(googleTagManagerInfo);
  }, [googleTagManagerInfo]);

  return sendDataToGTM;
};

export default useGoogleTagManager;
