import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar } from 'rebass';

import getImagePath from 'Util/LSP/getImagePath';

const StyledAvatar = styled(Avatar)`
  object-fit: cover;
`;

const CustomAvatar = (props) => {
  const { src, ...restProps } = props;
  let defaultPic = src;
  // gateway will always return null if there is no profilePic
  if (src === null) {
    defaultPic = '/profile-empty.png';
  }
  return <StyledAvatar {...restProps} src={getImagePath(defaultPic)} />;
};

CustomAvatar.propTypes = {
  src: PropTypes.string,
};

CustomAvatar.defaultProps = {
  src: '/profile-empty.png',
};

export default CustomAvatar;
