import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Header, Grid, Image, Icon, Label, List } from 'semantic-ui-react';

import { Box } from 'Components/Base';

export const RolesLabel = ({ roles, size }) =>
  roles.map((role) => (
    <Label key={role} color="blue" size={size}>
      <Icon name="user" /> {role}
    </Label>
  ));

RolesLabel.defaultProps = {
  roles: [],
  size: 'medium',
};
