import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import compose from 'recompose/compose';

import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import { sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation';
import { getWorkshopBySKUCode } from 'GraphQL/query/Workshop.query';
import withServerConfig from 'GraphQL/util/withServerConfig';

import SKUImageUpload from '../SKUImageUpload';

class SKUImageUploadGQL extends Component {
  static propTypes = {
    onImageUpload: PropTypes.func,
    changeImgText: SKUImageUpload.propTypes.changeImgText,
    skuCode: PropTypes.string.isRequired,
    serverConfig: PropTypes.any,
  };

  state = {
    updating: false,
  };

  handleImageUpdate = async ({ key, bucket, url }) => {
    const { client, skuCode, accessor, onImageUpdate } = this.props;
    this.setState({ updating: true }, async () => {
      try {
        if (typeof onImageUpdate === 'function') {
          onImageUpdate({ key, bucket, url });
        } else {
          const { data } = await client.mutate({
            mutation: sfUpdateWorkshop,
            variables: {
              data: { [accessor]: { key, bucket } },
              SKUCode: skuCode,
            },
            refetchQueries: [{ query: getWorkshopBySKUCode, variables: { SKUCode: skuCode } }],
          });
          this.setState({ updating: false });
        }
      } catch (error) {
        this.setState({ updating: false });
      }
    });
  };

  handleFileListRequest = async (request) => {
    const { client, skuCode } = this.props;
    const { data } = await client.mutate({
      mutation: getAssetList,
      variables: {
        type: ASSET_TYPE.SF_ASSETS,
        key: skuCode,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;

    const { client, skuCode } = this.props;
    const { data } = await client.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.SF_ASSETS,
        key: skuCode,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  render() {
    const { serverConfig, changeImgText, imageUrl, fileImageUrl } = this.props;
    return (
      <SKUImageUpload
        {...this.props}
        fileImageUrl={fileImageUrl || imageUrl}
        changeImgText={changeImgText}
        onImageUpdate={this.handleImageUpdate}
        onFileListRequest={this.handleFileListRequest}
        onFileUploadUrlRequest={this.handleFileUploadUrlRequest}
        UploadMode={serverConfig.assetUploadMode}
      />
    );
  }
}

export default compose(
  withApollo,
  withServerConfig({ configProp: 'serverConfig' })
)(SKUImageUploadGQL);
