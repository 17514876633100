import gql from 'graphql-tag';

import { PersonalizedDiscountCodeFragment } from '../query/PersonalizedDiscountCode.query';

export const createPersonalizedDiscountCodeTemplate = gql`
  mutation sfBoCreatePersonalizedDiscountCodeTemplate(
    $input: SFBoCreatePersonalizedDiscountCodeInput!
  ) {
    sfBoCreatePersonalizedDiscountCodeTemplate(input: $input) {
      ...PersonalizedDiscountCodeFragment
    }
  }
  ${PersonalizedDiscountCodeFragment}
`;

export const updatePersonalizedDiscountCode = gql`
  mutation sfBoUpdatePersonalizedDiscountCodeTemplate(
    $id: String!
    $input: SFBoUpdatePersonalizedDiscountCodeInput!
  ) {
    sfBoUpdatePersonalizedDiscountCodeTemplate(id: $id, input: $input) {
      ...PersonalizedDiscountCodeFragment
    }
  }
  ${PersonalizedDiscountCodeFragment}
`;

export const deletePersonalizedDiscountCode = gql`
  mutation sfBoRemovePersonalizedDiscountCodeTemplateById($id: String!) {
    sfBoRemovePersonalizedDiscountCodeTemplateById(id: $id) {
      id
      deletedAt
    }
  }
`;
