import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar as MuiSnackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { CheckCircle, Warning, ErrorOutline, Info } from '@material-ui/icons/';

import { Text as BaseText } from 'Components/Base';
const NOTIFICATION_VARIANT = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

const ALERT_ICON = {
  [NOTIFICATION_VARIANT.SUCCESS]: <CheckCircle />,
  [NOTIFICATION_VARIANT.WARNING]: <Warning />,
  [NOTIFICATION_VARIANT.ERROR]: <ErrorOutline />,
  [NOTIFICATION_VARIANT.INFO]: <Info />,
};

const useSnackbarStyles = makeStyles({
  root: { position: 'fixed' },
});

const shadow = `0px 10px 10px rgba(0, 0, 0, 0.2)`;
const useAlertStyles = makeStyles({
  root: ({ style }) => ({
    alignItems: 'center',
    height: 'auto',
    padding: '14px 20px',
    borderRadius: '8px',
    width: '100%',
    ...style,
  }),
  icon: { padding: 0, fontSize: '20px' },
  message: { padding: 0 },
  filledSuccess: ({ theme }) => ({
    backgroundColor: theme.colors.successAlt,
    filter: `drop-shadow(${shadow})`,
  }),
  filledWarning: ({ theme }) => ({
    backgroundColor: theme.colors.warning,
    filter: `drop-shadow(${shadow})`,
  }),
  filledError: ({ theme }) => ({
    backgroundColor: theme.colors.danger,
    filter: `drop-shadow(${shadow})`,
  }),
  filledInfo: ({ theme }) => ({
    backgroundColor: theme.colors.info,
    filter: `drop-shadow(${shadow})`,
  }),
});

const Text = styled(BaseText)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Alert = (props) => {
  const { style } = props;
  const theme = useContext(ThemeContext);
  const classes = useAlertStyles({ theme, style });
  return <MuiAlert variant="filled" classes={classes} iconMapping={ALERT_ICON} {...props} />;
};

const Snackbar = (props) => {
  const {
    variant,
    message,
    onClose,
    duration,
    isOpen,
    vertical,
    horizontal,
    action,
    isPersistMode,
    innerProps,
    ...restProps
  } = props;

  const alertPropsBuilder = {
    onClose: isPersistMode ? onClose : undefined,
    action: isPersistMode ? action : undefined,
  };

  const classes = useSnackbarStyles();

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <MuiSnackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      autoHideDuration={duration}
      onClose={!isPersistMode ? onClose : undefined}
      classes={classes}
      {...restProps}
    >
      <Alert severity={variant} {...innerProps} {...alertPropsBuilder}>
        <Text color="white">{message}</Text>
      </Alert>
    </MuiSnackbar>,
    document.querySelector('#app')
  );
};

Snackbar.propTypes = {
  variant: PropTypes.oneOf(Object.values(NOTIFICATION_VARIANT)),
  message: PropTypes.string,
  onClose: PropTypes.func,
  duration: PropTypes.number,
  isOpen: PropTypes.bool,
  vertical: PropTypes.oneOf(['top', 'bottom']),
  horizontal: PropTypes.oneOf(['center', 'right,', 'left']),
  action: PropTypes.node,
  isPersistMode: PropTypes.bool,
};

Snackbar.defaultProps = {
  message: '',
  onClose: () => {},
  duration: 5000,
  isOpen: false,
  vertical: 'top',
  horizontal: 'center',
  isPersistMode: false,
};
export { NOTIFICATION_VARIANT };
export default Snackbar;
