import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, List } from 'semantic-ui-react';

import DataTable from '../../../Components/DataTable';
import { SemanticButton as Button } from '../../../Components/Base/Button';

const DeviceFingerPrint = ({ data }) => {
  const { __typename, ...filteredData } = data;
  return (
    <List bulleted>
      {Object.entries(filteredData).map(([key, value]) => (
        <List.Item key={key}>
          <strong>{key}</strong> - {value ? value : '(No Data)'}
        </List.Item>
      ))}
    </List>
  );
};

DeviceFingerPrint.defaultProps = {
  data: {},
};

class DeviceTokenList extends Component {
  render() {
    return (
      <DataTable
        data={this.props.data}
        defaultPageSize={5}
        minRows={5}
        columns={[
          {
            Header: 'id',
            accessor: 'id',
            maxWidth: 200,
          },
          {
            Header: 'Information',
            accessor: 'deviceFingerprint',
            Cell: (row) => <DeviceFingerPrint data={row.value} />,
          },
          {
            Header: 'Created At',
            accessor: 'createdAt',
            Cell: (row) => <span>{new Date(row.value).toLocaleString()}</span>,
            maxWidth: 200,
          },
          {
            Header: 'Latest Used At',
            accessor: 'latestUsedAt',
            Cell: (row) => <span>{new Date(row.value).toLocaleString()}</span>,
            maxWidth: 200,
          },
          {
            Header: '',
            Cell: (row) => (
              <Fragment>
                <Button compact negative onClick={() => this.props.onRemoveClick(row.original)}>
                  <Icon name="delete" /> Remove
                </Button>
              </Fragment>
            ),
            maxWidth: 200,
          },
        ]}
        showPagination={false}
      />
    );
  }
}

DeviceTokenList.propTypes = {
  data: PropTypes.array,
  onRemoveClick: PropTypes.func,
};

DeviceTokenList.defaultProps = {
  data: [],
  onRemoveClick: () => {},
};

export default DeviceTokenList;
