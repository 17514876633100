import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Input as SemanticInput, Dropdown, TextArea } from 'formsy-semantic-ui-react';
import { Label, Popup, Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import numeral from 'numeral';
import { isEqual } from 'lodash/lang';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';

import { Box, Flex, Text } from 'Components/Base';
import ConfirmationButton from 'Components/ConfirmationButton';

import { SemanticButton as Button } from '../../Base/Button';

const errorLabel = <Label color="red" pointing />;

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const RedLabel = styled.span`
  color: red !important;
`;

export const RequiredLabel = styled.label`
  position: relative;
  .label-right {
    padding-left: 4px;
  }
  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: red;
        position: absolute;
        right: 0.7em;
        top: 0;
      }
    `};
`;

const DescriptionTooltip = ({ content }) => (
  <Popup
    content={content}
    trigger={<Icon name="question circle outline" className="label-right" />}
  ></Popup>
);

const INPUT_TYPE = {
  TEXT_FIELD: 'TEXT_FIELD',
  PASSWORD_FIELD: 'PASSWORD_FIELD',
  NUMBER_FIELD: 'NUMBER_FIELD', // This type will return the value as int/float
  DROPDOWN: 'DROPDOWN',
  TEXT_AREA: 'TEXT_AREA',
  CUSTOM_TYPE: 'CUSTOM_TYPE',
};

export const FormField = styled(({ compact, readOnly, hidden, ...restProps }) => (
  <Form.Field {...restProps} />
))`
  ${({ compact }) =>
    compact &&
    css`
      &.field {
        display: flex;
        align-items: baseline;
        && > label {
          flex: 1 0 25%;
          text-align: right;
          padding-right: 1.3em;
        }
        & .field {
          flex: 3 0 75%;
        }
      }
    `};
  ${({ readOnly }) =>
    readOnly &&
    css`
      &.field {
        .ui.form & .field.disabled {
          opacity: 1 !important;
        }
      }
      & .disabled.input {
        opacity: 1 !important;
      }
    `};
  ${({ hidden }) =>
    hidden &&
    css`
      display: none !important;
    `};
`;

const Input = styled(SemanticInput)`
  ${({ helperText }) =>
    helperText &&
    css`
      margin-bottom: 5px !important;
    `}
`;

const generateInput = (
  formName,
  inputType,
  inputLabel,
  inputName,
  inputProps = { fieldProps: {} },
  value,
  inputDescription,
  options,
  loading,
  compact,
  readOnly,
  hidden,
  customInputRenderer,
  getCurrentFormValues
) => {
  const fieldProps = inputProps?.fieldProps ?? {};
  const dataTestId = formName ? `input-${formName}-${inputName}` : `input-${inputName}`;
  const isInputLabelFunctionType = typeof inputLabel === 'function';
  const label = isInputLabelFunctionType ? inputLabel({ getCurrentFormValues }) : inputLabel;

  switch (inputType) {
    case INPUT_TYPE.TEXT_FIELD:
      return (
        <FormField compact={compact} readOnly={readOnly} hidden={hidden} {...fieldProps}>
          <RequiredLabel required={inputProps.required}>
            <span>{inputLabel}</span>
            {inputDescription && <DescriptionTooltip content={inputDescription} />}
          </RequiredLabel>
          <Input
            name={inputName}
            required={inputProps.required}
            validationErrors={{
              isDefaultRequiredValue: `${inputLabel} is required`,
            }}
            placeholder=""
            errorLabel={errorLabel}
            disabled={loading || readOnly}
            transparent={readOnly}
            data-testid={dataTestId}
            {...inputProps}
            defaultValue={value}
          />
          {inputProps?.helperText && (
            <Text fontSize="0.9em" color="gray" fontWeight={300}>
              {inputProps.helperText}
            </Text>
          )}
        </FormField>
      );
    case INPUT_TYPE.NUMBER_FIELD:
      return (
        <FormField compact={compact} readOnly={readOnly} hidden={hidden} {...fieldProps}>
          <RequiredLabel required={inputProps.required}>
            <span>{inputLabel}</span>
            {inputDescription && <DescriptionTooltip content={inputDescription} />}
          </RequiredLabel>
          <Input
            name={inputName}
            required={inputProps.required}
            type="number"
            validationErrors={{
              isDefaultRequiredValue: `${inputLabel} is required`,
            }}
            validations={'isNumeric'}
            placeholder=""
            errorLabel={errorLabel}
            disabled={loading || readOnly}
            transparent={readOnly}
            data-testid={dataTestId}
            {...inputProps}
            defaultValue={value !== null && value !== undefined ? value + '' : undefined}
          />
          {inputProps?.helperText && (
            <Text fontSize="0.9em" color="gray" fontWeight={300}>
              {inputProps.helperText}
            </Text>
          )}
        </FormField>
      );
    case INPUT_TYPE.PASSWORD_FIELD:
      return (
        <FormField compact={compact} readOnly={readOnly} hidden={hidden} {...fieldProps}>
          <RequiredLabel required={inputProps.required}>
            <span>{inputLabel}</span>
            {inputDescription && <DescriptionTooltip content={inputDescription} />}
          </RequiredLabel>
          <Input
            name={inputName}
            required={inputProps.required}
            type="password"
            validationErrors={{
              isDefaultRequiredValue: `${inputLabel} is required`,
            }}
            placeholder=""
            errorLabel={errorLabel}
            disabled={loading || readOnly}
            transparent={readOnly}
            data-testid={dataTestId}
            {...inputProps}
            defaultValue={value}
          />
          {inputProps?.helperText && (
            <Text fontSize="0.9em" color="gray" fontWeight={300}>
              {inputProps.helperText}
            </Text>
          )}
        </FormField>
      );
    case INPUT_TYPE.DROPDOWN:
      return (
        <FormField compact={compact} readOnly={readOnly} hidden={hidden} {...fieldProps}>
          <RequiredLabel required={inputProps.required}>
            <span>{inputLabel}</span>
            {inputDescription && <DescriptionTooltip content={inputDescription} />}
          </RequiredLabel>
          <Dropdown
            name={inputName}
            required={inputProps.required}
            validationErrors={{
              isDefaultRequiredValue: `${inputLabel} is required`,
            }}
            placeholder="Select Values"
            errorLabel={errorLabel}
            fluid
            selection
            defaultValue={value}
            options={options}
            value={value}
            disabled={loading || readOnly}
            data-testid={dataTestId}
            {...inputProps}
          />
          {inputProps?.helperText && (
            <Text fontSize="0.9em" color="gray" fontWeight={300}>
              {inputProps.helperText}
            </Text>
          )}
        </FormField>
      );
    case INPUT_TYPE.TEXT_AREA:
      return (
        <FormField compact={compact} readOnly={readOnly} hidden={hidden} {...fieldProps}>
          <RequiredLabel required={inputProps.required}>
            <span>{inputLabel}</span>
            {inputDescription && <DescriptionTooltip content={inputDescription} />}
          </RequiredLabel>
          {!readOnly && (
            <TextArea
              name={inputName}
              required={inputProps.required}
              type="password"
              validationErrors={{
                isDefaultRequiredValue: `${inputLabel} is required`,
              }}
              placeholder=""
              errorLabel={errorLabel}
              disabled={loading || readOnly}
              data-testid={dataTestId}
              {...inputProps}
              defaultValue={value}
            />
          )}
          {readOnly && <Box className="field">{value}</Box>}
        </FormField>
      );
    case INPUT_TYPE.CUSTOM_TYPE:
      return (
        <FormField compact={compact} readOnly={readOnly} hidden={hidden} {...fieldProps}>
          {inputProps.hideLabel ? null : (
            <RequiredLabel required={inputProps.required}>
              <span>{label}</span>
              {inputDescription && <DescriptionTooltip content={inputDescription} />}
            </RequiredLabel>
          )}
          {React.cloneElement(customInputRenderer({ name: inputName, getCurrentFormValues }), {
            name: inputName,
            required: inputProps.required,
            errorLabel,
            defaultValue: value,
            disabled: loading || readOnly,
            'data-testid': dataTestId,
            ...inputProps,
          })}
        </FormField>
      );
    default:
      break;
  }
};

const generateDivider = ({ key, text }) => {
  return (
    <Fragment key={`divider-${key}`}>
      <Divider mb={2} />
      <Text fontSize={[3]} mb={2}>
        {text}
      </Text>
    </Fragment>
  );
};

const formatValues = (values, fields) => {
  Object.keys(values).forEach((key) => {
    const field = fields.find((f) => {
      return f.inputName === key;
    });
    if (field.inputType === INPUT_TYPE.NUMBER_FIELD) {
      const inputOptions = field?.inputOptions ?? {};
      let value = values[key];
      //TODO: improve format value of number field https://skooldio.atlassian.net/browse/SKOOLDIO-5294
      if (!value && 'defaultNumberValue' in inputOptions) {
        value = inputOptions.defaultNumberValue;
      }
      if (!isNaN(numeral(value).value())) {
        values[key] = numeral(value).value();
      } else {
        throw new Error(
          `${field.inputName} field is defined as a NUMBER_FIELD but its value is not a number`
        );
      }
    }
  });
  return values;
};

class FormGenerator extends Component {
  static propTypes = {
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        inputType: PropTypes.string.isRequired,
        inputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        inputName: PropTypes.string.isRequired,
      })
    ).isRequired,
    onSubmit: PropTypes.func,
    onAdditionSubmit: PropTypes.func,
    customValidation: PropTypes.func,
    customAction: PropTypes.func,
    showAction: PropTypes.bool,
    submitText: PropTypes.string,
    showAddition: PropTypes.bool,
    additionText: PropTypes.string,
    showCancel: PropTypes.bool,
    cancelText: PropTypes.string,
    cancelButtonType: PropTypes.string,
    onCancelled: PropTypes.func,
    initialData: PropTypes.object,
    onChange: PropTypes.func,
    isRequiredConfirmation: PropTypes.bool,
    submitContent: PropTypes.string,
    additionContent: PropTypes.string,
    compact: PropTypes.bool,
    readOnly: PropTypes.bool,
    name: PropTypes.string,
    withDebounce: PropTypes.bool,
    dividers: PropTypes.array,
  };

  static defaultProps = {
    onSubmit: () => {},
    onAdditionSubmit: () => {},
    onCancelled: () => {},
    onChange: () => {},
    loading: false,
    showAction: true,
    showAddition: false,
    submitText: 'Submit',
    additionText: 'Addition',
    cancelText: 'Close',
    cancelButtonType: 'reset',
    isRequiredConfirmation: false,
    submitContent: undefined,
    additionContent: undefined,
    initialData: {},
    withDebounce: false,
    dividers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      validationErrors: null,
      isDirty: false,
      submitType: 'submit',
    };
  }

  renderInput(fields = [], data = {}, loading, compact, readOnly, getCurrentFormValues, dividers) {
    const generatedInput = fields.map((field, index) =>
      React.cloneElement(
        generateInput(
          this.props.name,
          field.inputType,
          field.inputLabel,
          field.inputName,
          field.inputProps,
          data[field.inputName],
          field.inputDescription,
          field.options,
          loading,
          compact,
          readOnly,
          field.hidden,
          field.customInput,
          getCurrentFormValues
        ),
        {
          key: field.inputName,
          getCurrentFormValues,
        }
      )
    );

    for (const divider of uniqBy(dividers, 'key')) {
      const { key: dividerKey, text, isAfter } = divider;
      const index = generatedInput.findIndex(({ key }) => key === dividerKey);
      if (index >= 0) {
        generatedInput.splice(
          isAfter ? index + 1 : index,
          0,
          generateDivider({ key: dividerKey, text })
        );
      }
    }

    return generatedInput;
  }

  renderAction(
    isRequiredConfirmation,
    submitContent,
    additionContent,
    submitText,
    showAddition,
    additionText,
    showCancel,
    cancelText,
    cancelButtonType,
    loading,
    isDirty,
    readOnly
  ) {
    const dataTestIdPrefix = this.props.name ? `btn-${this.props.name}` : 'btn';
    if (!isRequiredConfirmation) {
      return (
        <Flex>
          <Button
            primary
            circular
            size="medium"
            type="button"
            content={submitText}
            loading={loading}
            disabled={loading || !isDirty || readOnly}
            onClick={this.handleDefaultSubmit}
            data-testid={`${dataTestIdPrefix}-submit`}
          />

          {showAddition && (
            <Button
              primary
              circular
              size="medium"
              type="button"
              content={additionText}
              loading={loading}
              disabled={loading || !isDirty || readOnly}
              onClick={this.handleAdditionSubmit}
              data-testid={`${dataTestIdPrefix}-additional-submit`}
            />
          )}
          {showCancel && (
            <Button
              circular
              size="medium"
              type="button"
              content={cancelText}
              disabled={loading}
              onClick={this.handleCancelled}
              data-testid={`${dataTestIdPrefix}-cancel`}
            />
          )}
        </Flex>
      );
    }
    return (
      <Flex>
        <ConfirmationButton
          buttonStyle={{
            primary: true,
            circular: true,
            size: 'medium',
            type: 'button',
            disabled: loading || !isDirty || readOnly,
            loading: loading,
          }}
          disabled={loading || !isDirty || readOnly}
          onConfirm={this.handleDefaultSubmit}
          headerText={`${submitText} ?`}
          confirmText="Save"
          contentText={submitContent}
          data-testid={`${dataTestIdPrefix}-submit`}
        >
          {submitText}
        </ConfirmationButton>
        {showAddition && (
          <ConfirmationButton
            buttonStyle={{
              primary: true,
              circular: true,
              size: 'medium',
              type: 'button',
              disabled: loading || !isDirty || readOnly,
              loading: loading,
            }}
            disabled={loading || !isDirty || readOnly}
            onConfirm={this.handleAdditionSubmit}
            headerText={`${additionText} ?`}
            confirmText="Save"
            contentText={additionContent}
            data-testid={`${dataTestIdPrefix}-additional-submit`}
          >
            {additionText}
          </ConfirmationButton>
        )}
        {showCancel && (
          <ConfirmationButton
            buttonStyle={{
              primary: false,
              circular: true,
              size: 'medium',
              type: cancelButtonType ?? 'reset',
              disabled: loading,
            }}
            exitWithoutConfirm={readOnly || !isDirty}
            onConfirm={this.handleCancelled}
            headerText={`Close without Save`}
            confirmText="Confirm"
            contentText="Are you sure to close this form without save ?"
            data-testid={`${dataTestIdPrefix}-cancel`}
          >
            {cancelText}
          </ConfirmationButton>
        )}
      </Flex>
    );
  }

  renderCustomAction(customAction, loading, isDirty) {
    return (
      <Flex>
        {customAction(
          loading,
          isDirty,
          this.handleDefaultSubmit,
          this.handleAdditionSubmit,
          this.handleCancelled
        )}
      </Flex>
    );
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.fields, this.props.fields)) {
      this.setState({
        validationErrors: null,
        isDirty: true,
      });
    }
  }

  handleDefaultSubmit = () => {
    this.setState({ submitType: 'submit' }, this.form.submit);
  };

  handleAdditionSubmit = () => {
    this.setState({ submitType: 'addition' }, this.form.submit);
  };

  handleValidSubmit = (values) => {
    const formattedValues = formatValues(values, this.props.fields);
    if (this.state.submitType === 'submit') {
      this.props.onSubmit(formattedValues);
    } else {
      this.props.onAdditionSubmit(formattedValues);
    }
  };

  handleChange = (currentValues, isChanged) => {
    if (this.props.customValidation) {
      this.setState({
        validationErrors: this.props.customValidation(currentValues, isChanged),
      });
    }
    if (isChanged) {
      this.setState({ isDirty: true }, () => {
        this.props.onChange(currentValues, isChanged);
      });
    }
  };

  handleCancelled = () => {
    this.form.reset();
    this.props.onCancelled();
  };

  getCurrentFormValues = () => {
    if (this.form) {
      return this.form.getModel();
    } else {
      return {};
    }
  };

  /**
   * Return an instance of [Formsy.Form](https://github.com/christianalfoni/formsy-react/blob/master/src/main.js)
   * @memberof FormGenerator
   */
  getForm = () => {
    return this.form;
  };

  render() {
    const {
      fields,
      loading,
      customAction,
      submitText,
      showAddition,
      additionText,
      showCancel,
      cancelText,
      cancelButtonType,
      initialData,
      showAction,
      isRequiredConfirmation,
      submitContent,
      additionContent,
      compact,
      readOnly,
      withDebounce,
      dividers,
    } = this.props;
    const { validationErrors, isDirty } = this.state;
    return (
      <Form
        onValidSubmit={this.handleValidSubmit}
        onChange={withDebounce ? debounce(this.handleChange, 400) : this.handleChange}
        validationErrors={validationErrors}
        ref={(form) => (this.form = form)}
      >
        <Flex flexDirection="column">
          {this.renderInput(
            fields,
            initialData,
            loading,
            compact,
            readOnly,
            this.getCurrentFormValues,
            dividers
          )}
        </Flex>
        {showAction && (
          <Flex justifyContent="flex-end" mt={3}>
            {this.renderAction(
              isRequiredConfirmation,
              submitContent,
              additionContent,
              submitText,
              showAddition,
              additionText,
              showCancel,
              cancelText,
              cancelButtonType,
              loading,
              isDirty,
              readOnly
            )}
          </Flex>
        )}
        {!showAction && customAction && (
          <Flex justifyContent="flex-end" mt={3}>
            {this.renderCustomAction(customAction, loading, isDirty)}
          </Flex>
        )}
      </Form>
    );
  }
}

FormGenerator.INPUT_TYPE = INPUT_TYPE;

export default FormGenerator;
