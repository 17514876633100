import React from 'react';
import * as ReactIs from 'react-is';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Menu as SUIMenu } from 'semantic-ui-react';

import {
  Header,
  HeaderLogo,
  HeaderNavigation,
  HeaderItem as BaseHeaderItem,
  Text,
  Image,
} from 'Components/Base';

const SUIMenuItem = styled(SUIMenu.Item)`
  & .text {
    text-overflow: ellipsis;
    width: 20px;
    overflow: hidden;
  }

  @media screen and (min-width: 768px) {
    & .text {
      text-overflow: ellipsis;
      width: inherit;
    }
  }
`;

const Link = styled(NavLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const HeaderItem = styled(BaseHeaderItem)`
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: rgba(255, 255, 255, 0.08);
    `}
`;

const Logo = ({ logoSrc, logoSrcSet, logoStyle }) => {
  if (logoSrc) {
    return <Image src={logoSrc} style={logoStyle} />;
  } else if (logoSrcSet) {
    return <Image src={logoSrcSet?.['1x']} srcSet={logoSrcSet} style={logoStyle} />;
  }

  return <Image src={'/assets/skooldio_logo.svg'} style={logoStyle} />;
};

/**
 * Navbar Component
 * @param {boolean} isBrandHidden - Condition for open or close logo
 * @param {string} logoSrc - Logo image of project
 * @param {Object} logoStyle - Make a styling of logo image
 * @param {Array} menuItems - List of header item in navigation bar
 * @param {boolean} isAuthHidden - Condition for open or close authentication menu
 * @param {React.ReactNode} authComponent - A component of authentication to show in navigation bar
 */
const Navbar = ({
  isBrandHidden,
  logoSrc,
  logoSrcSet,
  logoStyle,
  menuItems,
  menuProps,
  isAuthHidden,
  authComponent,
  location,
}) => {
  const { pathname } = location;

  return (
    <Header bg={'primary'} px={[0, 3]} {...menuProps}>
      {!isBrandHidden && (
        <HeaderLogo flex={'0 0 auto'} py={2} hasSeparator={menuItems.length > 0}>
          <Logo logoSrc={logoSrc} logoSrcSet={logoSrcSet} logoStyle={logoStyle} />
        </HeaderLogo>
      )}
      <HeaderNavigation overflowX={'auto'} px={2}>
        {menuItems.map((menu, index) => (
          <HeaderItem
            key={menu.path}
            isActive={pathname === menu.path}
            flex={'0 0 auto'}
            data-testid={`menu-${menu.title}`}
            alignItems="center"
          >
            <Link to={menu.path}>
              <Text>{menu.title}</Text>
            </Link>
          </HeaderItem>
        ))}
      </HeaderNavigation>
      <HeaderNavigation position={'right'} flex={'1 0 auto'} ml={[1, 2]} alignSelf={'center'}>
        {!isAuthHidden && ReactIs.isElement(authComponent) && (
          <SUIMenu.Menu position="right" style={{ marginRight: '5px' }}>
            <SUIMenuItem name="logout" data-testid="menu-logout">
              {authComponent}
            </SUIMenuItem>
          </SUIMenu.Menu>
        )}
      </HeaderNavigation>
    </Header>
  );
};

Navbar.propTypes = {
  logoSrc: PropTypes.string,
  logoSrcSet: PropTypes.object,
  logoStyle: PropTypes.object,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
      // roles: PropTypes.array,
      // private: PropTypes.bool,
    })
  ),
  isAuthHidden: PropTypes.bool,
  isBrandHidden: PropTypes.bool,
  authComponent: PropTypes.node,
};

Navbar.defaultProps = {
  menuItems: [],
};

export default withRouter(Navbar);
