import { takeLatest, put, call, take, fork, takeEvery } from 'redux-saga/effects';

import { callApi } from '../callApi/actions';
import getConfig from '../../Util/Config';
import fetch2, { METHOD } from '../../Util/fetch';

import {
  setProfile,
  setProfileLoading,
  // loginSuccess,
  // loginFail,
  USER_PROFILE_VALIDATE_LOGIN,
  USER_PROFILE_LOGIN,
  USER_PROFILE_LOGOUT,
  USER_PROFILE_LOGIN_SUCCESS,
  USER_PROFILE_LOGIN_REQUEST,
  USER_PROFILE_LOGIN_FAIL,
  USER_PROFILE_LOGOUT_SUCCESS,
  USER_PROFILE_LOGOUT_REQUEST,
  USER_PROFILE_LOGOUT_FAIL,
  USER_PROFILE_GETPROFILE_REQUEST,
  USER_PROFILE_GETPROFILE_SUCCESS,
  USER_PROFILE_GETPROFILE_FAIL,
  USER_PROFILE_GETPROFILE,
} from './actions';

const USER_API = getConfig().userApi;

function fetchUserInfo() {
  return callApi('myProfile', `${USER_API}/me`, {}, [
    USER_PROFILE_GETPROFILE_REQUEST,
    USER_PROFILE_GETPROFILE_SUCCESS,
    USER_PROFILE_GETPROFILE_FAIL,
  ]);
}

function login(username, password) {
  return callApi(
    'login',
    `${USER_API}/login`,
    {
      path: 'http://localhost:4000/api/users/login',
      method: METHOD.POST,
      body: {
        email: username,
        password,
      },
    },
    [USER_PROFILE_LOGIN_REQUEST, USER_PROFILE_LOGIN_SUCCESS, USER_PROFILE_LOGIN_FAIL]
  );
}

function logout() {
  return callApi(
    'logout',
    `${USER_API}/logout`,
    {
      method: METHOD.POST,
    },
    [USER_PROFILE_LOGOUT_REQUEST, USER_PROFILE_LOGOUT_SUCCESS, USER_PROFILE_LOGOUT_FAIL]
  );
}

export function* onLoginPass() {
  yield takeEvery(USER_PROFILE_LOGIN_SUCCESS, function* () {
    yield put(fetchUserInfo());
  });
}

export function* clearUserInfo() {
  yield takeEvery(USER_PROFILE_LOGOUT_SUCCESS, function* () {
    yield put(setProfile(null));
    yield put(setProfileLoading(false));
  });
}

export function* requestUserInfo() {
  yield take(USER_PROFILE_GETPROFILE);
  yield put(fetchUserInfo());
}

export function* performLogin() {
  yield takeLatest(USER_PROFILE_LOGIN, function* doLogin({ payload }) {
    yield put(login(payload.username, payload.password));
  });
}

export function* performLogout() {
  yield takeLatest(USER_PROFILE_LOGOUT, function* doLogout() {
    yield put(logout());
  });
}

export default [onLoginPass(), performLogin(), performLogout(), requestUserInfo(), clearUserInfo()];
