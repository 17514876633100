import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from 'Components/Base';

import SideNavItems from './SideNavItems';

const SideNavItemWrapper = styled((props) => (
  <Flex as={'a'} width={1} alignItems={'center'} {...props} />
))`
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

/**
 * SideNavItem Component
 * @param {string} linkUrl - URL to navigate the route
 */
const SideNavItem = ({ children, linkUrl, ...props }) => (
  <SideNavItemWrapper width={1} py={1} px={2} bg={'white'} href={linkUrl} {...props}>
    {children}
  </SideNavItemWrapper>
);

SideNavItems.propTypes = {
  linkUrl: PropTypes.string,
};

export default SideNavItem;
