import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import { isEmpty } from 'lodash';

import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import Filter from 'src/Util/Filter';

import { getBooks } from '../../GraphQL/query/Products.query';
import { CATEGORY_TYPE, SKU_CATEGORY, SKU_STATUS_SEARCH_OPTIONS } from '../../Domains/constants';
import resolveCategoryName from '../../Domains/resolveCategoryName';
import CategorySelectorInput from '../../Components/CategorySelectorInput';
import { getBookDetailsOverviewUrl } from '../../Domains/resolveUrl';
import BookModal from '../../Components/BookModal';
import CategoryCell from '../../Components/CategoryCell';

const booksColumns = (skuCategory, handleEdit) => [
  {
    Header: 'SKU Code',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    fromParam: (value) => String(value),
    toParam: (value) => String(value),
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: (row) => (
      <Link to={{ pathname: getBookDetailsOverviewUrl(row.original.SKUCode) }}>{row.value}</Link>
    ),
    fromParam: (value) => String(value),
    toParam: (value) => String(value),
  },
  {
    Header: 'Status',
    accessor: 'SKUStatus',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    sortable: false,
    enumOptions: SKU_STATUS_SEARCH_OPTIONS,
    fromParam: (value) => String(value),
    toParam: (value) => String(value),
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1),
    accessor: 'category1Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_1}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category1s} />,
    fromParam: (value) => JSON.parse(value),
    toParam: (value) => JSON.stringify(value),
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2),
    accessor: 'category2Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_2}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category2s} />,
    fromParam: (value) => JSON.parse(value),
    toParam: (value) => JSON.stringify(value),
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3),
    accessor: 'category3Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_3}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category3s} />,
    fromParam: (value) => JSON.parse(value),
    toParam: (value) => JSON.stringify(value),
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4),
    accessor: 'category4Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_4}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category4s} />,
    fromParam: (value) => JSON.parse(value),
    toParam: (value) => JSON.stringify(value),
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      return (
        <Fragment>
          <Button.Group>
            <Button basic size="mini" icon compact onClick={() => handleEdit(original.SKUCode)}>
              <Icon name="edit" />
            </Button>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

const BooksRoute = (props) => {
  const { skuCategory, history, location } = props;
  const [search, setSearch] = useState({});
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [order, setOrder] = useState({ field: 'createdAt', type: 'DESC' });

  const handlePageChange = (page) => {
    Filter.handlePageChange(page, paging.pageSize, setPaging);
  };
  const handlePageSizeChange = (pageSize) => {
    Filter.handlePageSizeChange(pageSize, setPaging);
  };
  const handleSearchChange = (search) => {
    Filter.handleSearchChange(search, paging, setSearch, setPaging);
  };
  const handleSortedChange = (sorted) => {
    Filter.handleSortedChange(sorted, paging.pageSize, setOrder, setPaging);
  };

  const handleEdit = (skuCode) => {
    history.push(getBookDetailsOverviewUrl(skuCode));
  };

  const resolveData = (data) => data.sfBoProductBooks.books;

  const columns = booksColumns(skuCategory, handleEdit);

  return (
    <Fragment>
      <Flex justifyContent="space-between" mb={2}>
        <Text.Header>Books</Text.Header>
        <BookModal
          variables={{ search, paging, order }}
          trigger={
            <Button primary circular>
              <Icon name="plus" size="small" />
              New Book
            </Button>
          }
        />
      </Flex>
      <QueryTable
        type={'sfBoProductBooks'}
        resolveData={resolveData}
        columns={columns}
        query={getBooks}
        search={search}
        paging={paging}
        order={order}
        onSearchChange={handleSearchChange}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortedChange={handleSortedChange}
      />
    </Fragment>
  );
};

BooksRoute.propTypes = {
  skuCategory: PropTypes.oneOf(Object.values(SKU_CATEGORY)),
};

BooksRoute.defaultProps = {
  skuCategory: SKU_CATEGORY.BOOK,
};

export default compose(withApollo, withRouter)(BooksRoute);
