import React, { Fragment, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import BaseMenu from 'Containers/Menu';
import { GenSwitchRoute } from 'Util/Route';
import { Box, Flex, Text } from 'Components/Base';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { GET_APP_ROLE } from '../../Graphql/query/Role.query';
import RoleDetails from '../../Containers/RoleDetails';
import PermissionTableContainer from '../../Containers/PermissionTableContainer/PermissionTableContainer';
import RoleUsersPanel from '../../Containers/RoleUsersPanel/RoleUsersPanel';

const Menu = styled(BaseMenu)`
  &&&& {
    margin: 36px 0;
    border-bottom: 1px solid #d9d9d9;
    .active.item {
      border-color: ${({ theme: { colors } }) => colors?.primary};
      border-width: 4px;
    }
    & > :first-child {
      overflow: inherit;
    }
  }
`;

const Divider = styled(Box)`
  border-bottom: 1px solid #f2f2f2;
`;

const genMenu = (matchUrl) => [
  {
    path: `${matchUrl}`,
    title: 'Role Details',
    private: true,
    exact: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.ROLE_MANAGEMENT.OVERVIEW.VIEW],
    route: RoleDetails,
  },
  {
    path: `${matchUrl}/permission`,
    title: 'Permissions',
    private: true,
    exact: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.USER.ROLE_MANAGEMENT.PERMISSION.VIEW,
    ],
    route: PermissionTableContainer,
  },
  {
    path: `${matchUrl}/user`,
    title: 'User',
    private: true,
    exact: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.ROLE_MANAGEMENT.USERS.VIEW],
    route: RoleUsersPanel,
  },
];

const HeaderBlock = () => {
  const match = useRouteMatch();
  const roleId = match?.params?.roleId;
  const { data, loading, error } = useQuery(GET_APP_ROLE, {
    variables: { id: roleId },
    skip: !roleId,
    fetchPolicy: 'network-only',
  });
  const roleName = data?.appRole?.name;
  return (
    <Flex mt={2}>
      <Text.Header fontSize="20px">Edit Role {roleName ? `| ${roleName}` : ``}</Text.Header>
    </Flex>
  );
};

const SingleRoleRoute = ({ match, basePath }) => {
  const roleId = match?.params?.roleId;
  const menus = useMemo(() => genMenu(match.url), [match.url]);

  const NavigationBlock = useMemo(
    () => (
      <Link to={basePath}>
        <Text>
          <Flex alignItems="center">
            <ArrowBackIosIcon style={{ width: '14px' }} />
            <span>Roles Management</span>
          </Flex>
        </Text>
      </Link>
    ),
    [basePath]
  );

  return (
    <Fragment>
      {NavigationBlock}
      <HeaderBlock />
      <Divider />
      <Menu menus={menus} menuProps={{ secondary: true, pointing: true }} />
      <GenSwitchRoute routes={menus} roleId={roleId} />
    </Fragment>
  );
};

export default SingleRoleRoute;
