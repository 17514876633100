import gql from 'graphql-tag';

export const searchGrades = gql`
  query boSearchGrades($order: OrderInput, $search: BOAdminGradesInput, $paging: PageInput) {
    boSearchGrades(order: $order, search: $search, paging: $paging) {
      grades {
        id
        name
        shortName
        code
        schoolId
      }
    }
  }
`;

export const searchCourses = gql`
  query boCourses($search: BOCoursesInput!) {
    boCourses(search: $search) {
      courses {
        courseCode
        courseName
      }
    }
  }
`;
