import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';

import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import RichTextEditor from '../../../RichTextEditor';

const FillInTheBlankSkillScore = ({
  answerChoices,
  onAnswerChange,
  url,
  examId,
  editorContentFormat,
}) => {
  let answerRow = [];
  if (answerChoices) {
    answerChoices.map((answer, index) =>
      answerRow.push(
        <Grid.Row key={`checkbox_${index}`}>
          <Grid.Column verticalAlign={'middle'} width={6} textAlign="right">
            <Header color={'grey'} size={'tiny'}>
              Blank {index + 1}
            </Header>
          </Grid.Column>
          <Grid.Column width={10}>
            <RichTextEditor
              onChange={(data) => onAnswerChange(data, index)}
              id={`answer_${index}`}
              value={answer.value}
              contentFormat={editorContentFormat}
              returnValueAsString={false}
              customControls={[
                {
                  type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                  data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      )
    );
  }

  return answerRow;
};

FillInTheBlankSkillScore.propTypes = {
  answerChoices: PropTypes.arrayOf(
    PropTypes.shape({
      value: RichTextEditor.propTypes.value,
      isAnswer: PropTypes.bool,
    })
  ),
  onAnswerChange: PropTypes.func,
};

FillInTheBlankSkillScore.defaultProps = {
  answerChoices: [],
  onAnswerChange: () => {},
};

export default FillInTheBlankSkillScore;
