import React, { useState, useCallback } from 'react';
import { Form, Input, Dropdown } from 'formsy-semantic-ui-react';
import { Label } from 'semantic-ui-react';
import Check from '@material-ui/icons/Check';
import styled from 'styled-components';

import { FormField, RequiredLabel } from 'Components/Form/FormGenerator/FormGenerator';
import { Flex, Box, Text, ButtonFilled } from 'Components/Base';
import { DatePickerInput as BaseDatePickerInput } from 'Components/DatePickerInput';
import LegacyHTMLEditor from 'Components/LegacyHTMLEditor';
import withFormsy from 'Components/Form/withFormsy';

function fileSizeLimitValidation(_, value) {
  const sizeLimit = parseInt(value);
  if (sizeLimit < 1) return 'file size limit must be greater than 0';
  if (sizeLimit > 25) return 'file size limit must not exceed 25';
  return true;
}

const FlexFieldWrapper = styled(Flex)`
  .field {
    flex: 1 0 auto;
  }
`;

const DatePickerInput = styled(BaseDatePickerInput)`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
  .react-datepicker-popper {
    z-index: 101;
  }
`;

const errorLabel = <Label color="red" pointing />;

const Button = styled((props) => (
  <ButtonFilled borderRadius={'100px'} px={3} py={2} fontWeight="600" {...props} />
))`
  border: none;
`;

const AssignmentDetailsEditor = withFormsy((props) => {
  return (
    <LegacyHTMLEditor
      mode={LegacyHTMLEditor.MODE.ADVANCE}
      {...props}
      onChange={(updatedValue) => props.onChange({}, { value: updatedValue })}
    />
  );
});

const ASSIGNMENT_TYPE = {
  FILE: 'FILE',
  URL: 'URL',
};

const GRADING_TYPE_TEXT = {
  PASS_FAIL: 'Pass / Fail',
  SCORE: 'Score',
};

const GRADING_TYPE = {
  PASS_FAIL: 'PASS_FAIL',
  SCORE: 'SCORE',
};

const POSSIBLE_FILE_TYPE = {
  ZIP: 'ZIP',
  DOCX: 'DOCX',
  DOC: 'DOC',
  XLS: 'XLS',
  XLSX: 'XLSX',
  PDF: 'PDF',
};

const Assignment = ({
  onCancel,
  loading,
  onValidSubmit,
  title,
  content,
  isCreateMode,
  courseMeta,
}) => {
  const [assignmentType, setAssignmentType] = useState(content.type ?? ASSIGNMENT_TYPE.URL);
  const [gradingType, setGradingType] = useState(content.gradingType ?? GRADING_TYPE.SCORE);

  const handleSubmit = useCallback(
    (formValues) => {
      const formattedFormValues = {
        title: formValues.title,
        content: {
          type: formValues.content.type,
          dueDate: formValues.content.dueDate,
          detail: {
            type: 'HTML',
            content: formValues.content.detail ?? '',
          },
          gradingType: formValues.content.gradingType,
          fullScore: formValues.content.gradingType === 'SCORE' ? formValues.content.fullScore : 0,
        },
      };

      const fullScore = parseInt(formValues.content.fullScore);
      const submissionLimit = parseInt(formValues.content.submissionLimit);

      if (Number.isInteger(fullScore) && formValues.content.gradingType === 'SCORE') {
        formattedFormValues.content.fullScore = fullScore;
      }

      if (Number.isInteger(submissionLimit)) {
        formattedFormValues.content.submissionLimit = submissionLimit;
      }

      if (formValues.content.type === ASSIGNMENT_TYPE.FILE) {
        formattedFormValues.content.fileTypes = formValues.content.fileTypes;
        const fileLimit = parseInt(formValues.content.fileLimit);
        const fileSizeLimit = parseInt(formValues.content.fileSizeLimit);

        if (Number.isInteger(fileLimit)) {
          formattedFormValues.content.fileLimit = fileLimit;
        }

        if (Number.isInteger(fileSizeLimit)) {
          formattedFormValues.content.fileSizeLimit = fileSizeLimit;
        }
      }

      onValidSubmit(formattedFormValues);
    },
    [onValidSubmit]
  );

  function fullScoreValidations(values, value) {
    const { 'content.gradingType': gradingType } = values;
    const fullscore = value;
    if (values['content.gradingType'] !== 'SCORE') return true;
    if (!fullscore) return 'Full score is required';
    return fullscore > 0 && fullscore <= 100
      ? true
      : 'Full score greater than 0 and not exceed 100';
  }

  const isCoursePublished = courseMeta?.status === 'PUBLISH';
  return (
    <Form onValidSubmit={handleSubmit}>
      <FormField>
        <RequiredLabel required>
          <span>{"Assignment's Title"}</span>
        </RequiredLabel>
        <Input
          name={'title'}
          required
          placeholder="กรอกชื่อแบบฝึกหัด"
          disabled={loading}
          data-testid={'assignment-title-input'}
          defaultValue={title ?? ''}
          validationErrors={{ isDefaultRequiredValue: 'Title is required' }}
          errorLabel={errorLabel}
        />
      </FormField>
      <FormField>
        <RequiredLabel required>
          <span>{"Assignment's Type"}</span>
        </RequiredLabel>
        <Dropdown
          onChange={(e, props) => setAssignmentType(props.value)}
          upward
          name={'content.type'}
          fluid
          selection
          required
          disabled={loading || (!isCreateMode && isCoursePublished)}
          data-testid={'assignment-type-input'}
          defaultValue={content.type ?? assignmentType}
          options={Object.values(ASSIGNMENT_TYPE).map((e) => ({ text: e, value: e }))}
          validationErrors={{ isDefaultRequiredValue: 'Title is required' }}
          errorLabel={errorLabel}
        />
      </FormField>
      <FormField>
        <RequiredLabel>
          <span>{"Assignment's Details"}</span>
        </RequiredLabel>
        <AssignmentDetailsEditor
          name={'content.detail'}
          data-testid={'assignment-details-input'}
          defaultValue={content?.detail?.content ?? ''}
        />
      </FormField>
      <FormField>
        <RequiredLabel>
          <span>{"Assignment's Due Date"}</span>
        </RequiredLabel>
        <DatePickerInput
          placeholder="กำหนดส่ง"
          name={'content.dueDate'}
          data-testid={'assignment-due-date-input'}
          defaultValue={content?.dueDate ?? ''}
          isEndDate
        />
      </FormField>
      {/* <FormField>
        <RequiredLabel>
          <span>{'Resubmission Limit'}</span>
        </RequiredLabel>
        <Input
          name={'content.submissionLimit'}
          type="number"
          placeholder="จำนวนการส่งซ้ำที่รองรับ"
          data-testid={'assignment-submission-limit-input'}
          defaultValue={content?.submissionLimit ?? ''}
        />
      </FormField> */}
      {assignmentType === ASSIGNMENT_TYPE.FILE && (
        <>
          {/* <FormField>
            <RequiredLabel>
              <span>{'File Limit'}</span>
            </RequiredLabel>
            <Input
              defaultValue={content?.fileLimit ?? ''}
              name={'content.fileLimit'}
              type="number"
              placeholder="จำนวนไฟล์ที่รองรับ"
              data-testid={'assignment-file-limit-input'}
            />
          </FormField> */}
          <FormField>
            <RequiredLabel>
              <span>{'File Type Limit'}</span>
            </RequiredLabel>
            <Dropdown
              defaultValue={content?.fileTypes ?? Object.values(POSSIBLE_FILE_TYPE)}
              fluid
              selection
              multiple
              options={Object.values(POSSIBLE_FILE_TYPE).map((e) => ({ text: e, value: e }))}
              name={'content.fileTypes'}
              placeholder="ประเภทไฟล์ที่รองรับ"
              data-testid={'assignment-file-type-limit-input'}
            />
          </FormField>
          <FormField>
            <RequiredLabel>
              <span>{'File Size Limit (1 - 25 MB)'}</span>
            </RequiredLabel>
            <FlexFieldWrapper alignItems="baseline">
              <Input
                defaultValue={isCreateMode ? 25 : content?.fileSizeLimit ?? ''}
                name={'content.fileSizeLimit'}
                type="number"
                placeholder="ขนาดไฟล์ที่รองรับ (ไม่เกิน 25 MB)"
                data-testid={'assignment-file-size-limit-input'}
                min={1}
                max={25}
                onInput={(e) => {
                  const integerValue = parseInt(e.target.value);
                  const isNumber = !isNaN(integerValue);
                  if (isNumber) {
                    if (integerValue >= 1 && integerValue <= 25) {
                      e.target.value = integerValue.toString();
                    } else {
                      e.target.value = integerValue < 1 ? '1' : '25';
                    }
                  } else {
                    e.target.value = '1';
                  }
                }}
                validations={{ fileSizeLimitValidation }}
                errorLabel={errorLabel}
              />
              <Text fontWeight="600" ml={2} style={{ flex: '0 0 auto' }}>
                MB
              </Text>
            </FlexFieldWrapper>
          </FormField>
        </>
      )}
      <Flex>
        <FormField
          style={{
            width: '120px',
          }}
        >
          <RequiredLabel required>
            <span>{'Grading'}</span>
          </RequiredLabel>
          <Dropdown
            onChange={(e, props) => setGradingType(props.value)}
            upward
            name={'content.gradingType'}
            fluid
            selection
            required
            disabled={loading || (!isCreateMode && isCoursePublished)}
            data-testid={'assignment-grading-type-input'}
            defaultValue={content.gradingType ?? gradingType}
            options={Object.values(GRADING_TYPE).map((e) => ({
              text: GRADING_TYPE_TEXT[e],
              value: e,
            }))}
            validationErrors={{ isDefaultRequiredValue: 'Grading Type is required' }}
            errorLabel={errorLabel}
          />
        </FormField>
        <FormField
          style={{
            marginLeft: '16px',
            visibility: gradingType === 'SCORE' ? 'visible' : 'hidden',
            width: '200px',
          }}
        >
          <RequiredLabel required>
            <span>{'Full score'}</span>
          </RequiredLabel>
          <Input
            instantValidation
            name={'content.fullScore'}
            placeholder="กรอกคะแนนเต็ม"
            type="number"
            step={1}
            min={1}
            max={100}
            onInput={(e) => {
              const integerValue = parseInt(e.target.value);
              const isNumber = !isNaN(integerValue);
              if (isNumber) {
                if (integerValue >= 1 && integerValue <= 100) {
                  e.target.value = integerValue.toString();
                } else {
                  e.target.value = integerValue < 1 ? '1' : '100';
                }
              } else {
                e.target.value = '1';
              }
            }}
            disabled={loading || (!isCreateMode && isCoursePublished)}
            data-testid={'fullscore-input'}
            defaultValue={content.fullScore ?? ''}
            validations={{ fullScoreValidations }}
            validationErrors={{ isDefaultRequiredValue: 'Full Score is required' }}
            errorLabel={errorLabel}
          />
        </FormField>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mt={2} mb={3}>
        <Box />
        <Box>
          <Button
            data-testid={'assignment-submit-button'}
            bg="primary"
            color="white"
            type="submit"
            disabled={loading}
          >
            <Flex alignItems="center">
              <Check />
              <Text ml={2} color="inherit">
                {isCreateMode ? `Create Assignment` : `Update Assignment`}
              </Text>
            </Flex>
          </Button>
          <Button
            data-testid={'assignment-cancel-button'}
            ml={2}
            onClick={onCancel}
            bg="whiteThree"
            disabled={loading}
          >
            Cancel
          </Button>
        </Box>
      </Flex>
    </Form>
  );
};

export default Assignment;
