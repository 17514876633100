import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import chunk from 'lodash/chunk';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import apolloClient from 'src/GraphQL';
import { FONT_SIZE, COLOR } from 'Components/Theme';
import { Box, Flex, Text, ButtonFilled } from 'Components/Base';
import FormGenerator from 'Components/Form/FormGenerator';
import withFormsy from 'Components/Form/withFormsy';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import RichTextEditor, { convertValueToString } from 'Components/RichTextEditor';
import ConfirmationButton from 'Components/ConfirmationButton';
import { getTags, getExamById } from 'GraphQL/query/Exam.query';
import { duplicateExamItem, deleteExamItem } from 'src/GraphQL/mutation/Exam.mutation';
import { useAppConfig } from 'Util/hoc/withAppConfig';

import {
  EXAM_QUESTION_STATUS,
  EXAM_ITEM_TAG_CATEGORY,
  EXAM_GENERATOR_TYPE,
  EXAM_QUESTION_TYPE,
} from '../constants';
import { QuestionPreview } from '../QuestionPreviewV2';

import { mapTagsToOptions, getExamQuestionTypeOptions } from './util';
import RichTextEditorInput from './RichTextEditorInput';
import { getQuestionType } from './QuestionType';

const MODE = {
  DRAFT: EXAM_QUESTION_STATUS.DRAFT,
  PUBLISHED: EXAM_QUESTION_STATUS.PUBLISHED,
  BOTH: 'BOTH',
};
/**
 * Render tag as red label if the tag has been deleted.
 */
const renderTagLabel = ({ text, isDeleted }) => {
  if (isDeleted) {
    return {
      color: 'red',
      content: text,
    };
  }
  return {
    content: text,
  };
};

const basicInfoField = (tags, isPublished = false, publishMode = false, questionTypesConfig) => [
  {
    inputType: !isPublished
      ? FormGenerator.INPUT_TYPE.DROPDOWN
      : FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Owner',
    inputName: 'owner',
    inputProps: {
      search: true,
      required: publishMode,
    },
    options: tags.owner || [],
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ผู้รับผิดชอบ',
    inputName: 'assignee',
    mode: MODE.BOTH,
  },
  {
    inputType: !isPublished
      ? FormGenerator.INPUT_TYPE.DROPDOWN
      : FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ประเภทข้อสอบ',
    inputName: 'questionExamType',
    inputProps: {
      search: true,
      multiple: true,
      required: publishMode,
      renderLabel: renderTagLabel,
    },
    options: tags.questionExamType || [],
    mode: MODE.BOTH,
  },
  {
    inputType: !isPublished
      ? FormGenerator.INPUT_TYPE.DROPDOWN
      : FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'สนามสอบ',
    inputName: 'examCampaign',
    inputProps: {
      search: true,
      multiple: true,
      required: publishMode,
      renderLabel: renderTagLabel,
    },
    options: tags.examCampaign || [],
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'วิชา',
    inputName: 'subject',
    inputProps: {
      search: true,
      multiple: true,
      required: publishMode,
      renderLabel: renderTagLabel,
    },
    options: tags.subject || [],
    mode: MODE.BOTH,
  },
  {
    inputType: !isPublished
      ? FormGenerator.INPUT_TYPE.DROPDOWN
      : FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ระดับชั้น',
    inputName: 'grade',
    inputProps: {
      search: true,
      multiple: true,
      required: publishMode,
      renderLabel: renderTagLabel,
    },
    options: tags.grade || [],
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'สาระการเรียนรู้',
    inputName: 'section',
    inputProps: {
      search: true,
      multiple: true,
      required: publishMode,
      renderLabel: renderTagLabel,
    },
    options: tags.section || [],
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'หน่วยการเรียนรู้',
    inputName: 'subsection',
    inputProps: {
      search: true,
      multiple: true,
      renderLabel: renderTagLabel,
    },
    options: tags.subsection || [],
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'เรื่อง',
    inputName: 'subtopic',
    inputProps: {
      search: true,
      multiple: true,
      required: publishMode,
      renderLabel: renderTagLabel,
    },
    options: tags.subtopic || [],
    mode: MODE.BOTH,
  },
  {
    inputType: !isPublished
      ? FormGenerator.INPUT_TYPE.DROPDOWN
      : FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ตัวชี้วัด',
    inputName: 'indicator',
    inputProps: {
      search: true,
      multiple: true,
      renderLabel: renderTagLabel,
    },
    options: tags.indicator || [],
    mode: MODE.BOTH,
  },
  {
    inputType: !isPublished
      ? FormGenerator.INPUT_TYPE.DROPDOWN
      : FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'จุดประสงค์',
    inputName: 'objective',
    inputProps: {
      search: true,
      multiple: true,
      renderLabel: renderTagLabel,
    },
    options: tags.objective || [],
    mode: MODE.BOTH,
  },
  {
    inputType: !isPublished
      ? FormGenerator.INPUT_TYPE.DROPDOWN
      : FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Bloom',
    inputName: 'bloom',
    inputProps: {
      search: true,
      multiple: true,
      required: publishMode,
      renderLabel: renderTagLabel,
    },
    options: tags.bloom || [],
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'ความยาก',
    inputName: 'difficulty',
    inputProps: {
      search: true,
      multiple: true,
      required: publishMode,
      renderLabel: renderTagLabel,
    },
    options: tags.difficulty || [],
    mode: MODE.BOTH,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'ประเภทโจทย์',
    inputName: 'type',
    inputProps: {
      search: true,
      required: publishMode,
      renderLabel: renderTagLabel,
      value: EXAM_QUESTION_TYPE.MULTIPLE_CHOICE,
    },
    options: getExamQuestionTypeOptions(questionTypesConfig) || [],
    mode: MODE.BOTH,
  },
];

const PublishedQuestionPreview = withFormsy((props) => {
  const values = props?.value ?? {};
  const type = values?.type ?? props.type;
  const { choice, question, solution } = values;
  const { choices, correctAnswer } = choice || {};
  const { value: correctAnswerValue } = correctAnswer || {};
  const combinedProps = {
    values,
    choices,
    correctAnswer: correctAnswerValue,
    question,
    solution,
    index: props?.index ?? '',
    total: props?.total ?? '',
    timeRemaining: props?.timeRemaining ?? '',
    type,
  };

  return (
    <Box maxHeight="500px" overflow="auto">
      <QuestionPreview {...combinedProps} />
    </Box>
  );
});

const PublishedQuestionPreviewForm = (props) => {
  return (
    <Fragment>
      <Text as={'label'} fontWeight={'bold'}>
        Preview
      </Text>
      <PublishedQuestionPreview {...props} />
    </Fragment>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogLayout = (props) => {
  return (
    <Dialog
      open={true}
      fullScreen={true}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      {...props}
    />
  );
};

const DraftQuestionPreviewModal = (props) => {
  const { type, getCurrentFormValues, index, total } = props;
  const [isOpen, setIsOpen] = useState(false);

  const values = getCurrentFormValues() ?? {};
  const { choice, question, solution, type: typeValue } = values;
  const _type = values?.type ?? type;

  const isFillNumberType = _type === EXAM_QUESTION_TYPE.FILL_NUMBER;
  const isMultipleSubQuestionsConnectionAnswersType =
    _type === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS;
  const isYesNoAnswersType = _type === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER;
  const isDisabledPreview =
    isFillNumberType || isMultipleSubQuestionsConnectionAnswersType || isYesNoAnswersType;

  if (!isOpen) {
    return (
      <ButtonFilled
        style={{
          border: 'none',
          color: 'rgba(0,0,0,.6)',
          background: '#e0e1e2 none',
          boxShadow: 'inset 0 0 0 1px transparent, inset 0 0 0 0 rgb(34 36 38 / 15%)',
          fontSize: '16px',
          fontWeight: '500',
          fontFamily: 'inherit',
        }}
        // FIXME: Get initial value of exam question type to multiple choice
        disabled={isDisabledPreview}
        onClick={() => setIsOpen(true)}
      >
        Preview
      </ButtonFilled>
    );
  }

  const { choices, correctAnswer } = choice || {};
  const { value: correctAnswerValue } = correctAnswer || {};
  const combinedProps = {
    ...props,
    choices,
    question,
    solution,
    correctAnswer: correctAnswerValue,
    type: typeValue,
    index: index ?? '',
    total: total ?? '',
  };

  return (
    <DialogLayout>
      <QuestionPreview {...combinedProps} onClose={() => setIsOpen(false)} />
    </DialogLayout>
  );
};

const fields = (
  tags,
  examId,
  isPublished,
  publishMode,
  index,
  total,
  time,
  type,
  questionTypesConfig
) => [
  ...basicInfoField(tags, isPublished, publishMode, questionTypesConfig),
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: '',
    inputName: 'preview-modal',
    customInput: (props) => (
      <DraftQuestionPreviewModal
        {...props}
        index={index}
        total={total}
        timeRemaining={time}
        type={type}
      />
    ),
    mode: MODE.DRAFT,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: ({ getCurrentFormValues }) => {
      const currentTypes = getCurrentFormValues().type ?? type;
      const isConnectionAnswersType =
        currentTypes === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS;
      const label = isConnectionAnswersType ? 'บทความ' : 'Question';
      return label;
    },
    inputName: 'question',
    inputProps: {
      required: true,
    },
    customInput: (props) => <RichTextEditorInput examId={examId} {...props} />,
    mode: MODE.DRAFT,
  },
  ...getQuestionType(examId, publishMode, type),
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Solution',
    inputName: 'solution',
    inputProps: {
      required: publishMode,
    },
    customInput: (props) => <RichTextEditorInput examId={examId} {...props} />,
    mode: MODE.DRAFT,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputName: 'preview',
    inputProps: {
      hideLabel: true,
      disabled: false,
    },
    customInput: (props) => {
      // No preview for fill number and MultipleSubQuestionsConnectionAnswers
      const isFillNumberType = type === EXAM_QUESTION_TYPE.FILL_NUMBER;
      const isMultipleSubQuestionsConnectionAnswersType =
        type === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS;
      const isYesNoAnswersType = type === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER;
      const isDisabledPreview =
        isFillNumberType || isMultipleSubQuestionsConnectionAnswersType || isYesNoAnswersType;

      if (isDisabledPreview) {
        return <Fragment />;
      }
      return (
        <PublishedQuestionPreviewForm
          {...props}
          index={index}
          total={total}
          timeRemaining={time}
          type={type}
        />
      );
    },
    mode: MODE.PUBLISHED,
  },
];

const handleCustomDraftValidation = (formValue, isChanged) => {
  const error = {};
  if (isChanged) {
    const { question, type, fieldNumber, numberResult } = formValue;

    if (type === EXAM_QUESTION_TYPE.FILL_NUMBER) {
      if (fieldNumber) {
        const _fieldNumber = `${fieldNumber}`;
        if (_fieldNumber.includes('.')) {
          error.fieldNumber = 'Setting precision number answer must be integer';
        }
      }

      if (fieldNumber && numberResult) {
        const _splitNumberResult = numberResult?.toString().split('.');
        const _precision = _splitNumberResult[1]?.length ?? 0;
        if (_precision !== Number.parseInt(fieldNumber)) {
          error.numberResult = 'Number answer is not match';
        }
      }
    }

    if (!question) {
      error.question = 'Question is required';
    }
  }
  return error;
};

const handleCustomPublishValidation = (formValue, isChanged) => {
  const error = {};
  const {
    owner,
    questionExamType,
    examCampaign,
    bloom,
    grade,
    subject,
    section,
    subsection,
    subtopic,
    difficulty,
    question,
    choice,
    solution,
    fieldNumber,
    numberResult,
    type,
  } = formValue;

  if (isChanged) {
    if (!owner) {
      error.owner = 'Owner is required';
    }
    if (!Array.isArray(questionExamType) || questionExamType.length === 0) {
      error.questionExamType = 'ประเภทข้อสอบ is required';
    }
    if (!Array.isArray(examCampaign) || examCampaign.length === 0) {
      error.examCampaign = 'สนามสอบ is required';
    }
    if (!Array.isArray(bloom) || bloom.length === 0) {
      error.bloom = 'Bloom is required';
    }
    if (!Array.isArray(grade) || grade.length === 0) {
      error.grade = 'ระดับชั้น is required';
    }
    if (!Array.isArray(subject) || subject.length === 0) {
      error.subject = 'วิชา is required';
    }
    if (!Array.isArray(section) || section.length === 0) {
      error.section = 'สาระการเรียนรู้ is required';
    }
    if (!Array.isArray(subtopic) || subtopic.length === 0) {
      error.subtopic = 'เรื่อง is required';
    }
    if (!Array.isArray(difficulty) || difficulty.length === 0) {
      error.difficulty = 'ความยาก is required';
    }
    if (!question) {
      error.question = 'Question is required';
    }
    if (type === EXAM_QUESTION_TYPE.FILL_NUMBER) {
      if (!fieldNumber && !numberResult) {
        error.fieldNumber = 'Number answer is required';
      } else if (!fieldNumber) {
        error.fieldNumber = 'Setting precision number answer is required';
      } else if (!numberResult) {
        error.fieldNumber = 'Answer number field is required';
      }
    }
    if (type === EXAM_QUESTION_TYPE.MULTIPLE_CHOICE) {
      if (!choice) {
        error.choice = 'Choice is required';
      } else {
        const correctAnswer = choice?.correctAnswer?.value ?? null;
        const choices = choice?.choices ?? [];
        const hasChoices = choices && choices.length > 0;
        const hasLabel = choices.every(({ label }) =>
          convertValueToString(label, RichTextEditor.contentFormat.HTML)
        );

        if (!correctAnswer) {
          error.correctAnswer = 'Correct answer is required';
        }
        if (!hasChoices || !hasLabel) {
          error.choice = 'Choices is required and all choices must have description';
        }
      }
    }
    if (type === EXAM_QUESTION_TYPE.MULTIPLE_CHOICE_DIFFERENT_SCORE) {
      if (!choice) {
        error.choice = 'Choice is required';
      } else {
        const choices = choice?.choices ?? [];
        const hasChoices = choices && choices.length > 0;
        const hasScoreRatio = choices.every(({ scoreRatio }) => scoreRatio !== undefined);
        const hasMaxScoreRatio = choices.some(({ scoreRatio }) => scoreRatio === 1);
        const hasLabel = choices.every(({ label }) =>
          convertValueToString(label, RichTextEditor.contentFormat.HTML)
        );

        if (!hasScoreRatio) {
          error.hasScoreRatio = 'คะแนน is required';
        }
        if (!hasMaxScoreRatio) {
          error.hasMaxScoreRatio = 'ต้องมีอย่างน้อย 1 ตัวเลือก ที่คะแนนเท่ากับ 1';
        }
        if (!hasChoices || !hasLabel) {
          error.choice = 'Choices is required and all choices must have description';
        }
      }
    }
    if (
      type === EXAM_QUESTION_TYPE.MULTIPLE_SELECT ||
      type === EXAM_QUESTION_TYPE.MULTIPLE_SELECT_WITH_CORRECT_ANSWERS
    ) {
      if (!choice) {
        error.choice = 'Choice is required';
      } else {
        const correctAnswer = choice?.correctAnswer?.value ?? [];
        const choices = choice?.choices ?? [];
        const hasChoices = choices && choices.length > 0;
        const hasCorrectAnswer = correctAnswer && correctAnswer.length > 0;
        const hasLabel = choices.every(({ label }) =>
          convertValueToString(label, RichTextEditor.contentFormat.HTML)
        );

        if (!hasCorrectAnswer) {
          error.correctAnswer = 'ต้องเลือกคำตอบที่ถูกต้องอย่างน้อย 1 ตัวเลือก';
        }
        if (!hasChoices || !hasLabel) {
          error.choice = 'Choices is required and all choices must have description';
        }
      }
    }
    if (type === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS) {
      if (!choice) {
        error.choice = 'Choice is required';
      } else {
        const correctAnswer = choice?.correctAnswer?.value ?? [];
        const subQuestions = choice?.subQuestions ?? [];
        const hasSubQuestions = subQuestions && subQuestions.length > 0;
        const hasLabel = subQuestions.every(({ label }) =>
          convertValueToString(label, RichTextEditor.contentFormat.HTML)
        );

        const hasCorrectAnswer = correctAnswer.every((item) =>
          item.correctAnswerValue.every((answer) => {
            const answers = answer.split('');
            const hasAnswers = answers.every((items) => items !== '_');

            return hasAnswers;
          })
        );

        if (!hasCorrectAnswer) {
          error.correctAnswer = 'ต้องระบุทุกคำตอบในเลขกำกับ';
        }
        if (!hasSubQuestions || !hasLabel) {
          error.choice = 'เลขกำกับ is required and all of them must have description';
        }
      }
    }
    if (type === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER) {
      if (!choice) {
        error.choice = 'Choice is required';
      } else {
        const subQuestions = choice?.subQuestions ?? [];
        const hasSubQuestions = subQuestions && subQuestions.length > 0;

        if (!hasSubQuestions) {
          error.choice = 'Choice is required';
        }
      }
    }
    if (!solution) {
      error.solution = 'Solution is required';
    }
  }
  return error;
};

const QuestionForm = ({
  initialData: question = {},
  onCancelled,
  onSubmit,
  isEdit,
  publishMode,
  examId,
  index,
  total,
  time,
  ...props
}) => {
  const { exam } = useAppConfig();
  const examConfig = exam ?? {};
  const enabledQuestionTypes = examConfig?.enabledQuestionTypes;
  const questionTypesConfig = Array.isArray(enabledQuestionTypes) ? enabledQuestionTypes : [];

  const handleOnSubmit = (formValue) => {
    onSubmit(formValue);
  };
  const { data } = useQuery(getExamById, { variables: { examId } });
  const handleOnDelete = async () => {
    await apolloClient.mutate({
      mutation: deleteExamItem,
      variables: { examId: question.examId, examItemId: question.id },
    });
    onCancelled();
  };

  const handleOnDuplicate = async () => {
    await apolloClient.mutate({
      mutation: duplicateExamItem,
      variables: { examId: question.examId, examItemId: question.id },
      refetchQueries: [
        {
          query: getExamById,
          variables: {
            examId: examId,
          },
        },
      ],
    });
    onCancelled();
  };

  const tagCategories = chunk(Object.values(EXAM_ITEM_TAG_CATEGORY), 3);
  const { loading: firstTagsLoading, error: firstTagsError, data: firstTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[0] } },
      fetchPolicy: 'cache-first',
    }
  );

  const { loading: secondTagsLoading, error: secondTagsError, data: secondTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[1] } },
      fetchPolicy: 'cache-first',
    }
  );

  const { loading: thirdTagsLoading, error: thirdTagsError, data: thirdTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[2] } },
      fetchPolicy: 'cache-first',
    }
  );

  const { loading: fourthTagsLoading, error: fourthTagsError, data: fourthTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[3] } },
      fetchPolicy: 'cache-first',
    }
  );

  const { loading: fifthTagsLoading, error: fifthTagsError, data: fifthTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[4] } },
      fetchPolicy: 'cache-first',
    }
  );

  const isLoading =
    firstTagsLoading ||
    secondTagsLoading ||
    thirdTagsLoading ||
    fourthTagsLoading ||
    fifthTagsLoading;

  if (isLoading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }

  const tags = [
    ...(firstTagsData?.examTags?.examTags ?? []),
    ...(secondTagsData?.examTags?.examTags ?? []),
    ...(thirdTagsData?.examTags?.examTags ?? []),
    ...(fourthTagsData?.examTags?.examTags ?? []),
    ...(fifthTagsData?.examTags?.examTags ?? []),
  ];

  const errors = [
    ...(firstTagsError ?? []),
    ...(secondTagsError ?? []),
    ...(thirdTagsError ?? []),
    ...(fourthTagsError ?? []),
    ...(fifthTagsError ?? []),
  ];

  const { status = EXAM_QUESTION_STATUS.DRAFT } = question;
  const isPublished = status === EXAM_QUESTION_STATUS.PUBLISHED;
  const initialValidationErrors = publishMode && handleCustomPublishValidation(question, true);

  /** Show delete button only when the user edit DRAFT question */
  const showDeleteButton = !isPublished && isEdit && !publishMode;
  /** Hide duplicate button only fixed type exam was published  */
  const examGeneratorType = data?.examExam?.generatorType;
  const hideDuplicateButton = isPublished && examGeneratorType === EXAM_GENERATOR_TYPE.FIXED;

  return (
    <Fragment>
      {errors.length >= 1 &&
        errors.map((error, index) => <ErrorView key={index} message={error} />)}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        pb={2}
        style={{ borderBottom: `1px solid ${COLOR.greyscale.grey4}` }}
      >
        <Text.Header>{`${
          isPublished ? 'View' : isEdit ? (!publishMode ? 'Edit' : 'Publish') : 'Create'
        } Question`}</Text.Header>
        <Flex alignItems="center">
          <Text fontSize={FONT_SIZE.semilarge} color={COLOR.black} fontWeight="bold">
            {`Status: ${status}`}
          </Text>
          {!hideDuplicateButton && (
            <Box alignSelf="center" ml={3}>
              <ConfirmationButton
                buttonStyle={{
                  basic: true,
                  size: 'medium',
                  color: 'primary',
                  disabled: isLoading,
                }}
                onConfirm={handleOnDuplicate}
                headerText={`Confirm Duplicate`}
                confirmText="Confirm"
                contentText={'Do you want to duplicate this question?'}
              >
                {`Duplicate`}
              </ConfirmationButton>
            </Box>
          )}
          {showDeleteButton && (
            <Box alignSelf="center" ml={3}>
              <ConfirmationButton
                buttonStyle={{
                  basic: true,
                  size: 'medium',
                  color: 'red',
                  disabled: isLoading,
                }}
                onConfirm={handleOnDelete}
                headerText={`Confirm Delete`}
                confirmText="Confirm"
                contentText={'Do you want to delete this question?'}
              >
                {`Delete`}
              </ConfirmationButton>
            </Box>
          )}
        </Flex>
      </Flex>
      <FormGenerator
        withDebounce
        compact
        initialData={{
          ...question,
          ...(isPublished && {
            preview: {
              question: question.question,
              solution: question.solution,
              choice: question.choice,
              fieldNumber: question.fillNumber,
              numberResult: question.numberResult,
            },
          }),
        }}
        fields={fields(
          mapTagsToOptions(tags, question.examItemTags),
          examId,
          isPublished,
          publishMode,
          index,
          total,
          time,
          question.type,
          questionTypesConfig
        ).filter(
          ({ mode }) =>
            mode === MODE.BOTH ||
            (isPublished && mode === MODE.PUBLISHED) ||
            (!isPublished && mode === MODE.DRAFT)
        )}
        isRequiredConfirmation={!isPublished}
        showCancel
        cancelText={`Cancel`}
        submitText={!publishMode && !isPublished ? `Save Draft` : `Save and Publish`}
        submitContent={
          !publishMode
            ? `This Question will be saved as draft.`
            : `This Question will be saved and published.`
        }
        onSubmit={handleOnSubmit}
        onCancelled={onCancelled}
        readOnly={isPublished || publishMode}
        customValidation={publishMode ? handleCustomPublishValidation : handleCustomDraftValidation}
        showAction={!publishMode}
        customAction={(
          loading,
          isDirty,
          handleDefaultSubmit,
          handleAdditionSubmit,
          handleCancelled
        ) => {
          return (
            <Box>
              {initialValidationErrors && Object.keys(initialValidationErrors).length >= 1 && (
                <Message negative>
                  <Message.List>
                    {Object.values(initialValidationErrors).map((value, index) => (
                      <Message.Item key={index}>{`${value}`}</Message.Item>
                    ))}
                  </Message.List>
                </Message>
              )}
              <Flex justifyContent="flex-end">
                <ConfirmationButton
                  buttonStyle={{
                    primary: true,
                    circular: true,
                    size: 'medium',
                    type: 'button',
                    loading: loading,
                  }}
                  disabled={Object.keys(initialValidationErrors).length >= 1}
                  onConfirm={handleDefaultSubmit}
                  headerText={`Save and Publish ?`}
                  confirmText={`Save`}
                  contentText={`This Question will be saved and published.`}
                >
                  {`Save and Publish`}
                </ConfirmationButton>
                <ConfirmationButton
                  buttonStyle={{
                    primary: false,
                    circular: true,
                    size: 'medium',
                    type: 'button',
                  }}
                  disabled={loading}
                  onConfirm={handleCancelled}
                  headerText={`Close without Save`}
                  confirmText={'Confirm'}
                  contentText={'Are you sure to close this form without save ?'}
                >
                  {`Cancel`}
                </ConfirmationButton>
              </Flex>
            </Box>
          );
        }}
        {...props}
      />
    </Fragment>
  );
};

QuestionForm.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  publishMode: PropTypes.bool,
  examId: PropTypes.string,
};

QuestionForm.defaultProps = {
  initialData: {},
  onSubmit: () => {},
  onCancelled: () => {},
  isEdit: false,
  publishMode: false,
};

export default QuestionForm;
