import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';

import { getSubscriptions, getSubscription } from '../../GraphQL/query/Products.query';
import {
  createSubscriptionMutation,
  updateSubscriptionMutation,
} from '../../GraphQL/mutation/Products.mutation';

import SubscriptionForm from './SubscriptionForm';

export default class SubscriptionModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };

  render() {
    const { isEdit, id, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'Edit Subscription' : 'Create Subscription'}</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getSubscription}
                createMutation={createSubscriptionMutation}
                createRefetchQueries={[
                  {
                    query: getSubscriptions,
                    variables,
                  },
                ]}
                editMutation={updateSubscriptionMutation}
                isEdit={isEdit}
                id={id}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  data: { ...form },
                })}
                getCreateVariables={(form) => ({
                  data: { ...form },
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <SubscriptionForm
                      initialData={data && data.sfBoProductSubscription}
                      onSubmit={onSubmit}
                    />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
