import React from 'react';
import { Header, Divider, Icon, Button as SButton } from 'semantic-ui-react';

import { SemanticButton as Button } from 'Components/Base/Button';
import ConfirmationButton from 'Components/ConfirmationButton';

const QuickActionBar = ({
  onDeleteClick,
  onReorderClick,
  itemIndex,
  loading,
  isSection,
  totalItems,
}) => (
  <SButton.Group>
    <Button
      icon
      compact
      basic
      onClick={(e) => onReorderClick(e, itemIndex, itemIndex - 1)}
      disabled={itemIndex === 0}
      data-testid={`btn-move-${itemIndex}-up`}
    >
      <Icon name="angle up" />
    </Button>
    <Button
      icon
      compact
      basic
      onClick={(e) => onReorderClick(e, itemIndex, itemIndex + 1)}
      disabled={itemIndex === totalItems - 1}
      data-testid={`btn-move-${itemIndex}-down`}
    >
      <Icon name="angle down" />
    </Button>
    <ConfirmationButton
      headerText={`Delete ${isSection ? 'Section' : 'Subsection'}?`}
      contentText={'This action cannot be undone.'}
      confirmText={'Delete'}
      loading={loading}
      onConfirm={onDeleteClick}
      data-testid={`btn-delete-${isSection ? 'section' : 'subsection'}`}
    >
      <Icon name="trash alternate outline" color="red" />
    </ConfirmationButton>
  </SButton.Group>
);

export default QuickActionBar;
