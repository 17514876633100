import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from 'Components/Base';

const BaseVideoSlideLayout = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  ${({ showFooter }) =>
    showFooter
      ? css`
          grid-template-rows: 66px auto 66px;
          grid-template-areas:
            'header'
            'main'
            'footer';
        `
      : css`
          grid-template-rows: 66px auto;
          grid-template-areas:
            'header'
            'main';
        `}}
`;

const HeaderSection = styled(Box)`
  grid-area: header;
`;

const FooterSection = styled(Box)`
  grid-area: footer;
`;

const MainSection = styled(Box)`
  overflow: auto;
  height: 100%;
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ showFooter }) => (showFooter ? `auto calc(50vh - 66px)` : 'auto auto')};
  grid-template-areas:
    'top'
    'bottom';
  @media screen and (min-width: 512px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'top bottom';
    max-height: ${({ showFooter }) => (showFooter ? `calc(80vh - 132px)` : 'auto')};
  }
`;

const LeftSubsection = styled(Box)`
  grid-area: top;
  margin-bottom: 12px;
  overflow: hidden;
  @media screen and (min-width: 512px) {
    margin-right: 12px;
    margin-bottom: 0px;
  }
`;

const RightSubsection = styled(Box)`
  grid-area: bottom;
  overflow: auto;
`;

const VideoSlideLayout = ({ header, left, right, footer }) => {
  return (
    <BaseVideoSlideLayout showFooter={footer}>
      <HeaderSection>{header}</HeaderSection>
      <MainSection showFooter={footer}>
        <LeftSubsection>{left}</LeftSubsection>
        <RightSubsection>{right}</RightSubsection>
      </MainSection>
      <FooterSection>{footer}</FooterSection>
    </BaseVideoSlideLayout>
  );
};

export default VideoSlideLayout;
