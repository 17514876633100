import React, { Fragment } from 'react';
import { Input } from 'semantic-ui-react';

import { Box, Flex, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const NumberField = withFormsy(({ value, onChange, readOnly }) => {
  return (
    <Box width={1}>
      <Flex alignItems={'center'}>
        <Input
          type={'number'}
          value={value}
          defaultValue={value}
          onChange={onChange}
          readOnly={readOnly}
          min={0}
        />
        <Text ml={'16px'} fontWeight={'bold'}>
          ตำแหน่ง
        </Text>
      </Flex>
    </Box>
  );
});

const NumberFieldForm = (props) => {
  return (
    <Fragment>
      <Box as={'label'} alignSelf={'center'}>
        <Text>ตั้งค่าคำตอบ</Text>
        <Text fontWeight={'normal'}>(จำนวนทศนิยม)</Text>
      </Box>
      <NumberField {...props} />
    </Fragment>
  );
};

const AnswerNumberField = withFormsy(({ value, onChange, readOnly }) => {
  return (
    <Box width={1}>
      <Input
        type={'text'}
        value={value}
        defaultValue={value}
        onChange={onChange}
        readOnly={readOnly}
      />
    </Box>
  );
});

const AnswerNumberFieldForm = (props) => {
  return (
    <Fragment>
      <Text as={'label'} fontWeight={'bold'}>
        เฉลย
      </Text>
      <AnswerNumberField {...props} />
    </Fragment>
  );
};

export { AnswerNumberFieldForm, NumberFieldForm };
