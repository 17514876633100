import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import { Flex, Box, Text } from 'Components/Base';
import HTMLView from 'Components/HTMLView';
import { useAppConfig } from 'Util/hoc/withAppConfig';

import StaticQuestionModal from './QuestionForm/QuestionModal';
import ConfigurableQuestionModal from './QuestionForm/ConfigurableQuestionModal';
import { EXAM_QUESTION_TYPE, EXAM_QUESTION_STATUS } from './constants';

const QuestionView = styled(HTMLView)`
  text-align: left;
`;

const SingleExamItem = ({ examId, index, total, time, examItem, assignee, owner }) => {
  const { exam } = useAppConfig();
  const enableQuestionFieldConfigurable = exam?.enableQuestionFieldConfigurable;
  const { id, question, status } = examItem;
  const QuestionModal = enableQuestionFieldConfigurable
    ? ConfigurableQuestionModal
    : StaticQuestionModal;

  return (
    <Button fluid basic>
      <Flex width={1} justifyContent="space-between">
        <QuestionModal
          examId={examId}
          examItemId={id}
          index={index}
          total={total}
          time={time}
          assignee={assignee}
          owner={owner}
          isEdit
          trigger={
            <Flex width={1}>
              <Box>
                <QuestionView rawContent={`${index}.${question}`} />
              </Box>
            </Flex>
          }
        />
        {status === EXAM_QUESTION_STATUS.PUBLISHED ? (
          <Box
            alignSelf="center"
            flex="0 0 auto"
            p={`12px 20px`}
            border={`1px solid rgba(34,36,38,.5)`}
            borderRadius={'4px'}
          >
            {status}
          </Box>
        ) : (
          <QuestionModal
            examId={examId}
            examItemId={id}
            index={index}
            total={total}
            time={time}
            assignee={assignee}
            owner={owner}
            isEdit
            publishMode
            trigger={
              <Box alignSelf="center">
                <Button primary circular basic>
                  Publish now
                </Button>
              </Box>
            }
          />
        )}
      </Flex>
    </Button>
  );
};

const ExamItemSection = ({ examId, examItems = [], time, assignee, owner }) => {
  if (examItems.length === 0) return <Box>Please add question</Box>;

  return (
    <Box>
      <Button.Group basic vertical fluid>
        {examItems.length > 0 &&
          examItems.map((examItem, idx) => (
            <SingleExamItem
              key={examItem.id}
              index={idx + 1}
              total={examItems.length}
              time={time}
              examId={examId}
              examItem={examItem}
              assignee={assignee}
              owner={owner}
            />
          ))}
      </Button.Group>
    </Box>
  );
};

const QuestionListView = ({
  examItems,
  examId,
  readOnly,
  assignee,
  owner,
  time,
  hideAddQuestion,
}) => {
  const { exam } = useAppConfig();
  const enableQuestionFieldConfigurable = exam?.enableQuestionFieldConfigurable;
  const QuestionModal = enableQuestionFieldConfigurable
    ? ConfigurableQuestionModal
    : StaticQuestionModal;

  return (
    <Box>
      <Text.Title mb={2} color={`black`} fontWeight={'bold'}>
        Questions
      </Text.Title>
      <ExamItemSection
        examId={examId}
        examItems={examItems}
        assignee={assignee}
        owner={owner}
        time={time}
      />
      {!hideAddQuestion && (
        <Flex justifyContent="flex-end" mt={3}>
          <QuestionModal
            examId={examId}
            assignee={assignee}
            owner={owner}
            time={time}
            trigger={
              <Button secondary circular disabled={readOnly} type="button" onClick={() => {}}>
                <Icon name="plus" size="small" />
                Add New Question
              </Button>
            }
          />
        </Flex>
      )}
    </Box>
  );
};

QuestionListView.propTypes = {
  examItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      questionId: PropTypes.string,
      type: PropTypes.oneOf(Object.values(EXAM_QUESTION_TYPE)),
      question: PropTypes.string,
      status: PropTypes.oneOf(Object.values(EXAM_QUESTION_STATUS)),
    })
  ),
  examId: PropTypes.string,
  readOnly: PropTypes.bool,
  assignee: PropTypes.object,
  owner: PropTypes.object,
  time: PropTypes.string,
  hideAddQuestion: PropTypes.bool,
};

export default QuestionListView;
