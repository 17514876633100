import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import withFormsy from 'Components/Form/withFormsy';

export class DatePickerInput extends React.Component {
  handleChange = (date) => {
    const value = this.props.isEndDate ? moment(date).hours(23).minutes(59).seconds(59) : date;
    this.props.onChange({}, date ? { value } : '');
  };

  render() {
    return (
      <DatePicker
        className={this.props.className}
        selected={this.props.value !== '' ? moment(this.props.value) : ''}
        placeholderText="Select Date"
        dateFormat="LLL"
        value={this.props.value !== '' ? moment(this.props.value) : ''}
        onChange={this.handleChange}
      />
    );
  }
}

const formsy = withFormsy(DatePickerInput);

export default formsy;
