import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import getConfig from 'Util/Config';
import QueryComponent from 'GraphQL/util/QueryComponent';
import { VideoEditor } from 'Components/AssetComponents/VideoManagement';
import { getSingleVideo, byteArkStreamGetSingleVideo } from 'GraphQL/query/Video.query';
import {
  generateVideoSignedUrl,
  byteArkStreamGenerateVideoSignedUrl,
} from 'GraphQL/mutation/Video.mutation';

const VideoEditorContainer = (props) => {
  const {
    content: { videoKey },
    onDataChange,
  } = props;

  const { enabledByteArkStream } = getConfig();

  const [duration, setDuration] = useState(0);
  const [signedUrl, setSignedUrl] = useState('');
  const [getVideoSignedUrl] = useMutation(
    enabledByteArkStream ? byteArkStreamGenerateVideoSignedUrl : generateVideoSignedUrl
  );
  const query = enabledByteArkStream ? byteArkStreamGetSingleVideo : getSingleVideo;

  useEffect(() => {
    if (!duration) return;

    const videoSigningOptions = { videoKey, timeToLive: duration };
    getVideoSignedUrl({ variables: { videoSigningOptions } }).then(({ data }) => {
      const response = enabledByteArkStream
        ? data?.vdmByteArkGenerateOnlineVideoSignedUrl
        : data?.vdmGenerateVideoSignedUrl;

      const { signedUrl, success } = response;
      if (success) setSignedUrl(signedUrl);
    });
  }, [duration, enabledByteArkStream, getVideoSignedUrl, videoKey]);

  return (
    <QueryComponent query={query} variables={{ videoKey }} fetchPolicy={'network-only'}>
      {(data) => {
        const video = enabledByteArkStream ? data?.vdmByteArkVideo : data?.vdmVideo;
        const snapshots = enabledByteArkStream ? data?.vdmByteArkSnapshots : data?.vdmSnapshots;
        const { duration: videoDuration } = video;
        setDuration(videoDuration);

        return (
          <VideoEditor
            video={video}
            snapshots={snapshots}
            onDataChange={onDataChange}
            videoSignedUrl={signedUrl}
          />
        );
      }}
    </QueryComponent>
  );
};

VideoEditorContainer.propTypes = {
  content: PropTypes.shape({
    videoKey: PropTypes.string,
    videoType: PropTypes.string,
  }),
  onDataChange: PropTypes.func,
};

export default VideoEditorContainer;
