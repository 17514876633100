import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

const defaultLocation = {
  lat: 13.7453082,
  lng: 100.53450099999998,
};

function geolocate(map) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      // eslint-disable-next-line no-undef
      const initialLocation = new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      map.setCenter(initialLocation);
    });
  }
}

const GoogleMap = ({ place }, ref) => {
  const [map, setMap] = useState(null);
  const [markerInfo, setMarkerInfo] = useState(null);
  const mapRef = useRef();
  useImperativeHandle(
    ref,
    () => {
      return {
        instance: () => {
          return mapRef.current;
        },
      };
    },
    [mapRef]
  );
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const newMap = new google.maps.Map(mapRef.current, {
      center: defaultLocation,
      zoom: 17,
      fullscreenControl: false,
      streetViewControl: false,
      controlSize: 25,
    });
    setMap(newMap);
    // eslint-disable-next-line no-undef
    const marker = new google.maps.Marker({
      map: newMap,
    });
    // eslint-disable-next-line no-undef
    const infowindow = new google.maps.InfoWindow();
    marker.addListener('click', function () {
      infowindow.open(map, marker);
    });
    setMarkerInfo({ marker, infowindow });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!place) return;
    if (!place.geometry) {
      return;
    }

    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      console.log(place.geometry.location.lat(), place.geometry.location.lng());
      map.setZoom(17);
    }
    const { marker, infowindow } = markerInfo;
    // Set the position of the marker using the place ID and location.
    marker.setPlace({
      placeId: place.place_id,
      location: place.geometry.location,
    });
    marker.setVisible(true);
    const content = `<div>${place.name ? `<strong>${place.name}</strong><br/>` : ''}${
      place.formatted_address
    }</div>`;
    infowindow.setContent(content);
    infowindow.open(map, marker);
    return () => {};
  }, [map, place, markerInfo]);
  return <div ref={mapRef} style={{ width: '100%', height: 300, borderRadius: 6 }}></div>;
};

GoogleMap.propTypes = {
  place: PropTypes.shape({
    place_id: PropTypes.string,
    geometry: PropTypes.shape({
      location: PropTypes.any,
      viewport: PropTypes.array,
    }),
    name: PropTypes.string,
    formatted_address: PropTypes.string,
  }),
};

export default forwardRef(GoogleMap);
