import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { getTeacherProfiles } from 'GraphQL/LSP/query/Profile.query';
import ROLE from 'Util/LSP/roles';

import TeacherProfileModal from '../../Components/TeacherProfileModal';
import UserPasswordModal from '../../Components/UserPasswordModal';

const profilesColumns = (handleEdit, variables) => [
  {
    Header: 'ชื่อ',
    accessor: 'firstName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'นามสกุล',
    accessor: 'lastName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Role',
    accessor: 'role',
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: ROLE.ADVISOR_TEACHER, value: ROLE.ADVISOR_TEACHER, text: ROLE.ADVISOR_TEACHER },
      { key: ROLE.SUBJECT_TEACHER, value: ROLE.SUBJECT_TEACHER, text: ROLE.SUBJECT_TEACHER },
    ],
    isSearchAble: true,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const userId = original.id;
      const isAdvisorTeacher = original.role === ROLE.ADVISOR_TEACHER;
      return (
        <Fragment>
          <Button.Group>
            <TeacherProfileModal
              isEdit
              id={userId}
              variables={variables}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="edit" />
                </Button>
              }
            />
            {isAdvisorTeacher && (
              <Button basic size="mini" icon compact onClick={() => handleEdit(userId)}>
                <Icon name="tasks" />
              </Button>
            )}
            <UserPasswordModal
              id={userId}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="key" />
                </Button>
              }
            />
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class TeacherProfilesRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: {},
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  profileMutate = (mutation, userId) => {
    const { search, paging, order } = this.state;
    this.props.client
      .mutate({
        mutation,
        variables: { userId },
        refetchQueries: [
          {
            query: getTeacherProfiles,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  handleEdit = (userId) => {
    this.props.history.push(`/lsp/users/teachers/${userId}`);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.boTeacherProfiles.profiles;

  handleButtonOnClick = () => {
    // this.props.history.push('/lsp/import-data/teachers'); // @TODO: This needs to be form instead of import
  };

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>ครู</Text.Header>
          <TeacherProfileModal
            variables={{ search, paging, order }}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                Add Teacher
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={'boTeacherProfiles'}
          resolveData={this.resolveData}
          columns={profilesColumns(this.handleEdit, variables)}
          query={getTeacherProfiles}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(TeacherProfilesRoute);
