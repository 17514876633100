export const SalesOrderPermissionKeys = Object.freeze({
  PENDING: {
    LIST: {
      VIEW: 'sales.pendingSalesOrder.list-view',
      UPDATE: 'sales.pendingSalesOrder.list-update',
      DELETE: 'sales.pendingSalesOrder.list-delete',
    },
  },
  SUCCESS: {
    LIST: {
      VIEW: 'sales.successSalesOrder.list-view',
      UPDATE: 'sales.successSalesOrder.list-update',
      DELETE: 'sales.successSalesOrder.list-delete',
    },
  },
  VOID: {
    LIST: {
      VIEW: 'sales.voidSalesOrder.list-view',
      UPDATE: 'sales.voidSalesOrder.list-update',
      DELETE: 'sales.voidSalesOrder.list-delete',
    },
  },
  FAILED: {
    LIST: {
      VIEW: 'sales.failedSalesOrder.list-view',
      UPDATE: 'sales.failedSalesOrder.list-update',
      DELETE: 'sales.failedSalesOrder.list-delete',
    },
  },
});
