import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Container } from 'semantic-ui-react';
import urljoin from 'url-join';

import Table from 'Components/DataTable';
import Loading from 'Components/Loading';
import { Text } from 'Components/Base';
import { getUserAssignmentCourseSummary } from 'GraphQL/query/Courses.query';

import AssignmentBreadcrumb from '../AssignmentBreadcrumb';
import { getCourseSection } from '../utils';

const getColumns = (currentUrl) => [
  {
    Header: 'Assignment Title',
    accessor: 'id',
    Cell: ({ original: userAssignmentReport }) => (
      <Link to={{ pathname: urljoin(currentUrl, userAssignmentReport.sourceCourseItemId) }}>
        {userAssignmentReport.title}
      </Link>
    ),
  },
  {
    Header: 'Submissions',
    accessor: 'totalSubmitted',
    width: 150,
    Cell: ({ value }) => (value ? value : 0),
  },
  {
    Header: 'Reviewed',
    accessor: 'totalGraded',
    width: 150,
    Cell: ({ value }) => (value ? value : 0),
  },
];

function AssignmentSubSection({ match }) {
  const courseId = match?.params?.courseId;
  const sectionId = match?.params?.sectionId;

  const { data: queryData, loading: queryLoading } = useQuery(getUserAssignmentCourseSummary, {
    variables: { sourceCourseId: courseId },
    skip: !courseId,
  });
  const section = getCourseSection(queryData?.userAssignmentCourseSummary, sectionId);

  if (queryLoading) return <Loading />;
  return (
    <Container>
      <AssignmentBreadcrumb />
      <Text.Header mb={2}>{section?.title}</Text.Header>
      <Table
        columns={getColumns(match?.url)}
        data={section?.userAssignmentReports}
        defaultPageSize={10}
      />
    </Container>
  );
}

export default AssignmentSubSection;
