import React, { Component } from 'react';

import FileImport from '../../Containers/FileImport';
import { FILE_TYPE } from '../../common';

export default class ImportSyllabusesRoute extends Component {
  render() {
    return <FileImport title="Import Syllabus" type={FILE_TYPE.SYLLABUS} />;
  }
}
