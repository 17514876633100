import React, { useState, useEffect, useCallback } from 'react';
import { Input } from 'formsy-semantic-ui-react';
import { Label } from 'semantic-ui-react';
import { debounce } from 'lodash';

import withFormsy from 'Components/Form/withFormsy';

import { getVideoDetails } from '../../../Util/ExternalAdapter/Api/VimeoApi';

const errorLabel = <Label color="red" pointing />;

const VimeoVideoUrlInput = ({ onChange, name, value: initValue, onLoadedVideo }) => {
  const [value, setValue] = useState();

  const handleChange = (value) => {
    setValue(value);
  };

  const handleFormChange = useCallback(
    debounce(async (value) => {
      const videoRawAPIData = await getVideoDetails(value);
      onLoadedVideo(videoRawAPIData);
      onChange({}, { value });
    }, 600),
    []
  );

  useEffect(() => {
    if (value && value !== '') handleFormChange(value);
  }, [value]);

  return (
    <Input
      defaultValue={initValue}
      value={value}
      name={name}
      errorLabel={errorLabel}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default withFormsy(VimeoVideoUrlInput);
