import urlJoin from 'url-join';

const routePrefix = '/sales-v2';
const salesOrderPrefix = 'sales-orders';
const subscriptionManagementPrefix = 'subscription-management';

export const pendingSalesOrdersUrl = urlJoin(routePrefix, salesOrderPrefix, 'pending');
export const voidSalesOrdersUrl = urlJoin(routePrefix, salesOrderPrefix, 'void');
export const successSalesOrdersUrl = urlJoin(routePrefix, salesOrderPrefix, 'success');
export const failedSalesOrdersUrl = urlJoin(routePrefix, salesOrderPrefix, 'failed');
export const redemptionOrdersUrl = urlJoin(routePrefix, 'redemption-orders');
export const trialOrdersUrl = urlJoin(routePrefix, 'trial-orders');
export const adjustmentOrderUrl = urlJoin(
  routePrefix,
  subscriptionManagementPrefix,
  'adjustment-orders'
);
export const discountCodesUrl = urlJoin(routePrefix, 'discount-codes');
export const personalizedDiscountCodesUrl = urlJoin(routePrefix, 'personalized-discount-codes');
export const randomDiscountCodeJobUrl = urlJoin(routePrefix, 'random-discount-code');
export const SubscriptionUrl = urlJoin(routePrefix, subscriptionManagementPrefix, 'subscription');
