import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

import { getAssignments, getAllSyllabuses } from 'GraphQL/LSP/query/Syllabus.query';
import { deleteAssignmentMutation } from 'GraphQL/LSP/mutation/Syllabus.mutation';
import { Flex, Box } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import ASSIGNMENT_TYPE from 'Util/LSP/assignmentType';
import QueryComponent from 'GraphQL/util/QueryComponent';
import getDisplayName from 'Util/LSP/getDisplayName';
import ConfirmationButton from 'Components/ConfirmationButton';

import { SingleAssignmentModal, GroupAssignmentModal } from '../../Components/AssignmentModal';

const AssignmentDetails = ({
  type,
  schoolGrade,
  schoolTerm,
  schoolProgramme,
  careerPlan,
  students,
}) => {
  return (
    <Fragment>
      {schoolTerm && <Box>เทอม: {schoolTerm.name}</Box>}
      {schoolGrade && <Box>ระดับชั้น: {schoolGrade.name}</Box>}
      {type === ASSIGNMENT_TYPE.GROUP && (
        <Box>แผนการเรียน: {schoolProgramme ? schoolProgramme.name : 'ทุกแผนการเรียน'}</Box>
      )}
      {type === ASSIGNMENT_TYPE.GROUP && (
        <Box>แผนอาชีพ: {careerPlan ? careerPlan.name : 'ทุกแผนอาชีพ'}</Box>
      )}
      {type === ASSIGNMENT_TYPE.PERSON && (
        <Box>
          นักเรียน: {students ? students.map((student) => getDisplayName(student)).join(', ') : ''}
        </Box>
      )}
    </Fragment>
  );
};

const assignmentColumns = (syllabusData, handleDelete, userMode) => [
  {
    Header: 'แผนการเรียน',
    accessor: 'syllabusId',
    type: COLUMN_TYPE.ENUM,
    enumOptions: syllabusData.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    })),
    isSearchAble: true,
    Cell: ({ original }) => original.syllabus.name,
  },
  {
    Header: 'กำหนดแบบ',
    accessor: 'type',
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: ASSIGNMENT_TYPE.GROUP, value: ASSIGNMENT_TYPE.GROUP, text: 'กลุ่ม' },
      { key: ASSIGNMENT_TYPE.PERSON, value: ASSIGNMENT_TYPE.PERSON, text: 'คน' },
    ],
    isSearchAble: true,
    Cell: ({ value }) => (value === ASSIGNMENT_TYPE.GROUP ? 'กลุ่ม' : 'คน'),
  },
  {
    Header: 'เงื่อนไข',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => AssignmentDetails(original),
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    type: COLUMN_TYPE.CUSTOM,
    Cell: function ActionCell({ original }) {
      const { id, type } = original;
      const showButton = !userMode || (userMode && type === ASSIGNMENT_TYPE.PERSON);
      return (
        <Fragment>
          {showButton && (
            <ConfirmationButton
              headerText={`ลบแผนการเรียน?`}
              contentText={'คุณต้องการลบแผนการเรียนนี้'}
              confirmText={'Delete'}
              onConfirm={() => {
                handleDelete(id);
              }}
            >
              <Icon name="trash alternate outline" />
            </ConfirmationButton>
          )}
        </Fragment>
      );
    },
  },
];

class AllAssignmentsRoute extends Component {
  constructor(props) {
    super(props);
    const search = props.userId ? { studentId: props.userId } : {};
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search,
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  syllabusAssignmentMutate = (mutation, id) => {
    const { search, paging, order } = this.state;
    this.props.client
      .mutate({
        mutation,
        variables: { id },
        refetchQueries: [
          {
            query: getAssignments,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  handleDelete = (syllabusAssignmentId) => {
    this.syllabusAssignmentMutate(deleteAssignmentMutation, syllabusAssignmentId);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    const newSearch = this.props.userId ? { ...search, studentId: this.props.userId } : search;
    this.setState({ search: newSearch, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.boSyllabusAssignments.syllabusAssignments;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    const userMode = Boolean(this.props.userId);
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Syllabus Assignments</Text.Header>
          {!userMode && (
            <Box>
              <SingleAssignmentModal
                variables={variables}
                trigger={
                  <Button primary circular>
                    <Icon name="plus" size="small" />
                    เพิ่มแผนการเรียนรายคน
                  </Button>
                }
              />
              <GroupAssignmentModal
                variables={variables}
                trigger={
                  <Button primary circular>
                    <Icon name="plus" size="small" />
                    เพิ่มแผนการเรียนรายกลุ่ม
                  </Button>
                }
              />
            </Box>
          )}
        </Flex>
        <QueryComponent query={getAllSyllabuses}>
          {(syllabusData) => {
            return (
              <QueryTable
                type={'boSyllabusAssignments'}
                resolveData={this.resolveData}
                columns={assignmentColumns(
                  syllabusData.boAllSyllabuses,
                  this.handleDelete,
                  userMode
                )}
                query={getAssignments}
                search={search}
                onSearchChange={this.handleSearchChange}
                paging={paging}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageSizeChange}
                order={order}
                onSortedChange={this.handleSortedChange}
              />
            );
          }}
        </QueryComponent>
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(AllAssignmentsRoute);
