import React from 'react';
import dayjs from 'dayjs';
import { Input } from 'semantic-ui-react';

import { FormField } from 'Components/Form/FormGenerator/FormGenerator';

const dateFormat = 'DD/MM/YYYY HH:mm';

const formatDate = (date, format) => {
  return dayjs(date).format(format);
};

const UserDataField = ({ data, selectedUser }) => {
  const { sfBoShipmentFilterUsersByActiveSubscription: usersData } = data;
  const currentUser = usersData.find((user) => user.orderNo === selectedUser);
  const startDate = formatDate(currentUser.startDate, dateFormat);
  const endDate = currentUser?.endDate ? formatDate(currentUser.endDate, dateFormat) : '-';
  return (
    <>
      <FormField readOnly>
        <label>SKU Code</label>
        <Input disabled value={currentUser.SKUCode} />
      </FormField>
      <FormField readOnly>
        <label>Start Date</label>
        <Input disabled value={startDate} />
      </FormField>
      <FormField readOnly>
        <label>End Date</label>
        <Input disabled value={endDate} />
      </FormField>
    </>
  );
};

export default UserDataField;
