import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';

import QueryComponent from 'GraphQL/util/QueryComponent';
import { exam, EXAM_VERSION } from 'GraphQL/query/ExamEditorV1.query';
import { createQuestion, removeQuestion } from 'GraphQL/mutation/ExamEditorV1.mutation';

import QuestionList from './QuestionList';

class QuestionListQuery extends Component {
  static propTypes = {};

  handleDeleteQuestion = async (questionId) => {
    const { courseId, examId, client } = this.props;
    await client.mutate({
      mutation: removeQuestion,

      variables: {
        examId: examId,
        questionId,
        version: EXAM_VERSION.SKOOLDIO_V2,
      },

      refetchQueries: [
        {
          query: exam,
          variables: {
            courseId,
            examId,
            version: EXAM_VERSION.SKOOLDIO_V2,
          },
        },
      ],
    });
  };

  render() {
    const { courseId, examId } = this.props;
    return (
      <QueryComponent
        query={exam}
        variables={{ courseId, examId, version: EXAM_VERSION.SKOOLDIO_V2 }}
        fetchPolicy={'cache-and-network'}
      >
        {({ exam }, refetch) => {
          return (
            <QuestionList
              questionList={exam.questionOrder}
              {...this.props}
              onDeleteQuestion={this.handleDeleteQuestion}
              onUpdateQuestion={() => refetch()}
            />
          );
        }}
      </QueryComponent>
    );
  }
}

export default withApollo(QuestionListQuery);
