import React from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';

import { Box } from 'Components/Base';

const RatioWrapper = styled(Box)`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
`;

const SlideImage = ({ src, style }) => (
  <RatioWrapper>
    <Image
      style={{
        position: 'absolute',
        objectFit: 'contain',
        width: '100%',
        height: '100%',
        ...style,
      }}
      src={src}
    />
  </RatioWrapper>
);

export default SlideImage;
