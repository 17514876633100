import React, { useEffect, useState } from 'react';
import { Button, Icon, Container } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

import { Flex } from 'Components/Base';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import Loading from 'Components/Loading';
import QueryTable from 'GraphQL/util/QueryTable';
import { getCourseAnnouncementReports } from 'GraphQL/query/CourseAnnouncement.query';
import { getSingleCourseV1 } from 'GraphQL/query/Courses.query';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';
import { deleteCourseAnnouncement } from 'src/GraphQL/mutation/CourseAnnouncement.mutation';
import apolloClient from 'src/GraphQL';
import ErrorView from 'Components/ErrorView';

import AnnouncementModal from '../Containers/AnnouncementModal';
import { AnnouncementActionCell } from '../Components';
import { STATUS } from '../Components/constants';

const columns = (handleOnDelete, variables) => {
  return [
    {
      Header: 'Topic Name',
      accessor: 'title',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      type: COLUMN_TYPE.ENUM,
      isSearchAble: true,
      enumOptions: [
        { key: STATUS.PUBLISHED, value: STATUS.PUBLISHED, text: 'Published' },
        { key: STATUS.SCHEDULED, value: STATUS.SCHEDULED, text: 'Scheduled' },
        { key: STATUS.DRAFT, value: STATUS.DRAFT, text: 'Draft' },
      ],
    },
    {
      Header: 'Publish Date',
      accessor: 'publishedTime',
      type: COLUMN_TYPE.DATE,
      isSearchAble: true,
      placeholder: { start: 'From', end: 'To' },
    },
    {
      Header: 'Action',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      Cell: (props) => (
        <AnnouncementActionCell handleOnDelete={handleOnDelete} variables={variables} {...props} />
      ),
    },
  ];
};

const resultMapper = (data) => {
  const result = { ...data, category: 'ข่าวสาร' };
  return result;
};

const CourseAnnouncement = ({ match }) => {
  const courseId = match?.params?.courseId;
  // Get course code to use with `getCourseAnnouncementReports`
  const { data, error: courseError } = useQuery(getSingleCourseV1, {
    variables: {
      courseId: courseId,
      version: productCourseVersionResolver('SKOOLDIO', 'v2'),
    },
  });
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [search, setSearch] = useState({});
  const [order, setOrder] = useState({ field: 'createdAt', type: 'DESC' });

  const { courseCode, title: courseTitle, coverImageUrl } = data?.course ?? {};
  useEffect(() => {
    if (courseCode) setSearch({ relationId: courseCode });
  }, [courseCode]);

  const genPaging = (page, pageSize = paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  const handlePageChange = (page) => {
    const paging = genPaging(page);
    setPaging(paging);
  };
  const handlePageSizeChange = (pageSize) => {
    setPaging(genPaging(0, pageSize));
  };
  const handleSearchChange = (search) => {
    const paging = genPaging(0);
    setPaging(paging);
    setSearch({
      ...search,
      relationId: courseCode,
    });
  };
  const genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  const handleSortedChange = (sorted) => {
    if (!sorted[0].id) return;
    const order = genOrder(sorted);
    const paging = genPaging(0);
    setOrder(order);
    setPaging(paging);
  };

  const handleOnDelete = async (announcementId) => {
    try {
      await apolloClient.mutate({
        mutation: deleteCourseAnnouncement,
        variables: { announcementId: announcementId },
        refetchQueries: [
          {
            query: getCourseAnnouncementReports,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (courseError) return <ErrorView message={`Cannot get course info. -> ${courseError}`} />;

  // `courseAnnouncementReports` requires `relationId` -> if `search` is empty, it will fail.
  const isSearchEmpty = isEmpty(search);
  const variables = { search, paging, order };
  return (
    <Container>
      {isSearchEmpty ? (
        <Loading />
      ) : (
        <>
          <Flex justifyContent="flex-end" mb={2}>
            <AnnouncementModal
              variables={variables}
              courseTitle={courseTitle}
              coverImageUrl={coverImageUrl}
              trigger={
                <Button primary circular>
                  <Icon name="plus" size="small" />
                  Add Announcement
                </Button>
              }
            />
          </Flex>
          <QueryTable
            type="courseAnouncementReports"
            columns={columns(handleOnDelete, variables)}
            query={getCourseAnnouncementReports}
            skip={isSearchEmpty}
            paging={paging}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            search={search}
            onSearchChange={handleSearchChange}
            order={order}
            onSortedChange={handleSortedChange}
            resultMapper={resultMapper}
            resolveData={(data) => data?.courseAnnouncementReports?.courseAnnouncementReports}
          />
        </>
      )}
    </Container>
  );
};

export default CourseAnnouncement;
