import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { Header, Modal, Icon, Checkbox, Accordion } from 'semantic-ui-react';
import { Mutation } from '@apollo/client/react/components';
import _ from 'lodash';

import { Box, Flex, Text } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import StatefulModal from 'Components/Modal/StatefulModal';
import QueryTable from 'GraphQL/util/QueryTable';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import Filter from 'src/Util/Filter';

import {
  CATEGORY_TYPE,
  SKU_TYPE,
  SKU_CATEGORY,
  SKU_VISIBILITY,
  SKU_VISIBILITY_SEARCH_OPTIONS,
  SKU_STATUS_SEARCH_OPTIONS,
  SKU_STATUS_OPTIONS,
} from '../../Domains/constants';
import resolveCategoryName from '../../Domains/resolveCategoryName';
import CategorySelectorInput from '../CategorySelectorInput';
import CategoryCell from '../CategoryCell';

function addOrRemove(array, item) {
  const data = [...array];
  const index = _.findIndex(data, (datum) => datum.id === item.id);

  if (index === -1) {
    data.push({ id: item.id, SKUCode: item.SKUCode });
  } else {
    data.splice(index, 1);
  }
  return data;
}

const skuColumns = (skuCategory, handleCheckbox, selectedItems) => [
  {
    Header: 'SKUCode',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Visibility',
    accessor: 'isVisible',
    width: 40,
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    isHideColumn: true,
    enumOptions: SKU_VISIBILITY_SEARCH_OPTIONS,
  },
  {
    Header: 'Status',
    accessor: 'SKUStatus',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    sortable: false,
    isSearchAble: true,
    enumOptions: SKU_STATUS_SEARCH_OPTIONS,
    Cell: ({ original }) => {
      const { SKUStatus, isVisible } = original ?? {};
      const visibleStatus = isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED;
      const status =
        SKU_STATUS_OPTIONS.find(({ value }) => value === `${visibleStatus}/${SKUStatus}`)?.text ??
        '';
      return status;
    },
  },
  {
    Header: 'Permalink',
    accessor: 'permalink',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1),
    accessor: 'category1Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_1}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category1s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2),
    accessor: 'category2Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_2}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category2s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3),
    accessor: 'category3Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_3}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category3s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4),
    accessor: 'category4Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_4}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category4s} />,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <Fragment>
          <Button.Group>
            <Checkbox
              checked={selectedItems.find((item) => item.id === id)}
              onChange={() => handleCheckbox(original)}
            />
          </Button.Group>
        </Fragment>
      );
    },
  },
];

const SKUAddItemModal = (props) => {
  const {
    trigger,
    title,
    variables,
    handleMutation,
    refetchQueries,
    getSKUWithCategoriesQuery,
    skuCategory,
    queryName,
    dataPath,
  } = props;
  const [search, setSearch] = useState({});
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [order, setOrder] = useState({ field: 'itemNo', type: 'ASC' });
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleCheckbox = (item) => {
    setSelectedItems(addOrRemove(selectedItems, item));
  };

  const handleAddItems = (mutateFn) => {
    if (selectedItems.length > 0) {
      mutateFn({
        variables: {
          id: props.id,
          data: { addedItemIds: selectedItems.map((item) => item.id) },
        },
      });
    }
  };

  const handlePageChange = (page) => {
    Filter.handlePageChange(page, paging.pageSize, setPaging);
  };
  const handlePageSizeChange = (pageSize) => {
    Filter.handlePageSizeChange(pageSize, setPaging);
  };
  const handleSearchChange = (search) => {
    Filter.handleSearchChange(search, paging, setSearch, setPaging);
  };
  const handleSortedChange = (sorted) => {
    Filter.handleSortedChange(sorted, paging.pageSize, setOrder, setPaging);
  };

  const resolveData = (data, queryName, dataPath) => data?.[queryName]?.[dataPath] ?? [];

  const handleClickAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{`Add Items to ${title}`}</Modal.Header>
          <Modal.Content>
            <Mutation
              mutation={handleMutation}
              refetchQueries={[
                {
                  query: refetchQueries,
                  variables,
                },
              ]}
            >
              {(editData, { loading, error, data: mutateData }) => {
                return (
                  <Box>
                    <CheckMutationResponse
                      checkOnlyDefined={true}
                      response={mutateData}
                      loading={loading}
                      error={error}
                      onValid={closeModal}
                    />
                    <QueryTable
                      type={dataPath}
                      resolveData={(data) => resolveData(data, queryName, dataPath)}
                      columns={skuColumns(skuCategory, handleCheckbox, selectedItems)}
                      query={getSKUWithCategoriesQuery}
                      search={search}
                      onSearchChange={handleSearchChange}
                      paging={paging}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                      order={order}
                      onSortedChange={handleSortedChange}
                      ignoreSearchParams
                    />
                    <Flex mt={2}>
                      <Accordion styled fluid>
                        <Accordion.Title
                          active={activeIndex === 0}
                          index={0}
                          onClick={handleClickAccordion}
                        >
                          <Header textAlign="left">
                            <Icon name="dropdown" />
                            {`${selectedItems.length} item(s) to be added`}
                          </Header>
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0} style={{ textAlign: 'left' }}>
                          {selectedItems.map(({ id, SKUCode }, index) => (
                            <Text key={id}>{`${index + 1}: ${SKUCode}`}</Text>
                          ))}
                        </Accordion.Content>
                      </Accordion>
                    </Flex>
                    <Flex justifyContent="flex-end" mt={2}>
                      <Button
                        primary
                        circular
                        size="medium"
                        type="button"
                        content="Update"
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleAddItems(editData)}
                      />
                      <Button
                        circular
                        size="medium"
                        type="reset"
                        content="Cancel"
                        disabled={loading}
                        onClick={closeModal}
                      />
                    </Flex>
                  </Box>
                );
              }}
            </Mutation>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
};

SKUAddItemModal.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.element,
  onSubmit: PropTypes.func,
  title: PropTypes.string.isRequired,
  variables: PropTypes.object,
  skuType: PropTypes.oneOf(Object.values(SKU_TYPE)),
  skuCategory: PropTypes.objectOf(Object.values(SKU_CATEGORY)),
  handleMutation: PropTypes.object,
  refetchQueries: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.object.isRequired,
      variables: PropTypes.object,
    })
  ),
  getSKUWithCategoriesQuery: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.object.isRequired,
      variables: PropTypes.object,
    })
  ),
  queryName: PropTypes.string,
  dataPath: PropTypes.string,
};

SKUAddItemModal.defaultProps = {
  onSubmit: () => {},
  queryName: 'sfBoProductOnlineCourses',
  dataPath: 'onlineCourses',
  skuCategory: SKU_CATEGORY.ONLINE_COURSE,
  skuType: SKU_TYPE.VIRTUAL,
};

export default SKUAddItemModal;
