import gql from 'graphql-tag';

export const createSchoolTermMutation = gql`
  mutation boCreateSchoolTerm($schoolTermData: BOCreateSchoolTermInput!) {
    boCreateSchoolTerm(schoolTermData: $schoolTermData) {
      id
      code
      name
      startDate
      endDate
      schoolYear
    }
  }
`;

export const updateSchoolTermMutation = gql`
  mutation boUpdateSchoolTerm($id: ID!, $schoolTermData: BOUpdateSchoolTermInput!) {
    boUpdateSchoolTerm(id: $id, schoolTermData: $schoolTermData) {
      id
      code
      name
      startDate
      endDate
      schoolYear
    }
  }
`;

export const createSchoolGradeMutation = gql`
  mutation boCreateSchoolGrade($schoolGradeData: BOCreateSchoolConstantInput!) {
    boCreateSchoolGrade(schoolGradeData: $schoolGradeData) {
      id
      code
      name
    }
  }
`;

export const updateSchoolGradeMutation = gql`
  mutation boUpdateSchoolGrade($id: ID!, $schoolGradeData: BOUpdateSchoolConstantInput!) {
    boUpdateSchoolGrade(id: $id, schoolGradeData: $schoolGradeData) {
      id
      code
      name
    }
  }
`;

export const createSchoolProgrammeMutation = gql`
  mutation boCreateSchoolProgramme($schoolProgrammeData: BOCreateSchoolConstantInput!) {
    boCreateSchoolProgramme(schoolProgrammeData: $schoolProgrammeData) {
      id
      code
      name
    }
  }
`;

export const updateSchoolProgrammeMutation = gql`
  mutation boUpdateSchoolProgramme($id: ID!, $schoolProgrammeData: BOUpdateSchoolConstantInput!) {
    boUpdateSchoolProgramme(id: $id, schoolProgrammeData: $schoolProgrammeData) {
      id
      code
      name
    }
  }
`;

export const createCareerPlanMutation = gql`
  mutation boCreateCareerPlan($careerPlanData: BOCreateSchoolConstantInput!) {
    boCreateCareerPlan(careerPlanData: $careerPlanData) {
      id
      code
      name
    }
  }
`;

export const updateCareerPlanMutation = gql`
  mutation boUpdateCareerPlan($id: ID!, $careerPlanData: BOUpdateSchoolConstantInput!) {
    boUpdateCareerPlan(id: $id, careerPlanData: $careerPlanData) {
      id
      code
      name
    }
  }
`;
