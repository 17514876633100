export const studentInterestOptions = [
  { iconName: 'MdInfo', iconType: 'material-icon', value: 'Rugby' },
  { iconName: 'MdInfo', iconType: 'material-icon', value: 'Marathon' },
  { iconName: 'MdInfo', iconType: 'material-icon', value: 'Cycling' },
];
export const teacherPositionOptions = [
  'ครูที่ปรึกษา',
  'ครูประจำวิชาคณิตศาสตร์',
  'ครูประจำวิชาฟิสิกส์',
  'ครูประจำวิชาเคมี',
  'ครูประจำวิชาชีววิทยา',
  'ครูประจำวิชาภาษาไทย',
  'ครูประจำวิชาวิทยาศาสตร์พื้นฐาน',
];
