import React, { Component } from 'react';

import FileImport from '../../Containers/FileImport';
import { FILE_TYPE } from '../../common';

export default class UpdateShipmentTracking extends Component {
  render() {
    return <FileImport title="Update Shipment Tracking" type={FILE_TYPE.SHIPMENT_REPORTS} />;
  }
}
