import React from 'react';

import { useWireSync } from 'src/Util/WireSync';

import { SlideImage } from './Components';

const SlidePreview = ({ slides = [], syncKey }) => {
  const { role, value, setValue, reset, node } = useWireSync(syncKey);
  const lastIndex = slides.length - 1;
  const slide = slides.find((slide, index) => {
    const nextSlide = index !== lastIndex ? slides[index + 1] : {};
    return (
      (value >= slide?.time && value <= nextSlide?.time) ||
      (value >= slide?.time && index === lastIndex)
    );
  });

  return slide?.url ? <SlideImage src={slide.url} /> : 'No slide found this moment';
};

export default SlidePreview;
