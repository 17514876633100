import React from 'react';
import { Icon } from 'semantic-ui-react';

import { Flex, Text } from 'Components/Base';
import SearchShipments from 'Routes/Shipments/Containers/SearchShipments';
import { SemanticButton as Button } from 'Components/Base/Button';

import { SHIPMENT_STATUS } from '../Domains/constant';
import { ExportShipmentModal } from '../Containers/ExportShipmentModal';

const Shipments = (props) => {
  return (
    <>
      <Flex justifyContent="space-between" mb={2}>
        <Text.Header>Shipment</Text.Header>
        <ExportShipmentModal
          shipmentStatus={SHIPMENT_STATUS.CONFIRMED}
          trigger={
            <Button primary circular>
              <Icon name="file archive outline" />
              Export Confirmed Shipment
            </Button>
          }
        />
      </Flex>
      <SearchShipments />
    </>
  );
};

export default Shipments;
