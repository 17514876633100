import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { useSearchFilter } from '../../Utils';
import { GET_ROLE_ASSIGNED_USERS } from '../../Graphql/query/Role.query';

const BASIC_COLUMN = {
  ID: {
    Header: 'User ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  USERNAME: {
    Header: 'Username',
    accessor: 'username',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  FIRST_NAME: {
    Header: 'Firstname',
    accessor: 'firstname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  LAST_NAME: {
    Header: 'Lastname',
    accessor: 'lastname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  EMAIL: {
    Header: 'Email',
    accessor: 'email',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
};

const SearchRoleUsers = React.forwardRef(({ appRoleId, columns, resolveData }, ref) => {
  const {
    onSearchChange,
    onPageChange,
    onSortedChange,
    onPageSizeChange,
    order,
    paging,
    search,
  } = useSearchFilter();

  const getUsersData = useCallback(resolveData, [resolveData]);

  const getSearchVariables = () => ({
    appRoleId,
    order,
    paging,
    search,
  });

  return (
    <QueryTable
      ref={ref}
      type={'assignedUsersByAppRole'}
      columns={columns}
      query={GET_ROLE_ASSIGNED_USERS}
      resolveData={getUsersData}
      onSearchChange={onSearchChange}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortedChange={onSortedChange}
      getSearchVariables={getSearchVariables}
      paging={paging}
    />
  );
});

const DEFAULT_USER_COLUMNS = Object.values(BASIC_COLUMN);
SearchRoleUsers.propTypes = {
  columns: PropTypes.object,
  resolveData: PropTypes.func,
  appRoleId: PropTypes.string,
};

SearchRoleUsers.defaultProps = {
  columns: DEFAULT_USER_COLUMNS,
  resolveData: (data) => data.assignedUsersByAppRole.users,
};

export { BASIC_COLUMN, DEFAULT_USER_COLUMNS };
export default SearchRoleUsers;
