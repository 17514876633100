import gql from 'graphql-tag';

const resultActionPurchaseOrder = gql`
  fragment ResultActionPurchaseOrder on PurchaseOrder {
    id
    updatedAt
    email
    mobile
    status
    fullPrices
    amount
    paidAmount
    feeAmount
    paymentMethod
  }
`;

export const confirmPurchaseOrder = gql`
  mutation confirmPurchaseOrder($actionPurchaseOrder: ActionPurchaseOrder!) {
    confirmPurchaseOrder(actionPurchaseOrder: $actionPurchaseOrder) {
      ...ResultActionPurchaseOrder
    }
  }
  ${resultActionPurchaseOrder}
`;

export const rejectPurchaseOrder = gql`
  mutation rejectPurchaseOrder($actionPurchaseOrder: ActionPurchaseOrder!) {
    rejectPurchaseOrder(actionPurchaseOrder: $actionPurchaseOrder) {
      ...ResultActionPurchaseOrder
    }
  }
  ${resultActionPurchaseOrder}
`;
