import React from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

export const ImageValidationMessagePopup = styled((props) => <Popup {...props} />)`
  /* !important for over ride Popup's default styles */
  background-color: #ef6453 !important;
  border-color: #ef6453 !important;
  color: #fff !important;
  &::before {
    /* for override arrow style */
    background-color: #ef6453 !important;
    box-shadow: 1px 1px 0 0 #ef6453 !important;
  }
`;
