import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Flex } from 'Components/Base';

const Card = styled(Box)`
  margin: 32px 32px 32px 0px;
  padding: 24px 16px;
  border: 1px solid #595959;
  box-sizing: border-box;
  border-radius: 16px;
  background: #fafafa;
`;

const NotificationContentEditModalActionCard = ({
  onClickAddTextCard,
  onClickAddBlogCard,
  onClickAddRTECard,
  onClickAddImageCard,
}) => {
  return (
    <Card>
      <Flex flexDirection="row" justifyContent="center">
        <Flex flexDirection="row" justifyContent="center">
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <img
              src="/assets/code-block-icon.svg"
              alt="text-card"
              style={{ width: '64px', height: '64px' }}
              onClick={onClickAddRTECard}
            ></img>
            <div>Rich Text</div>
          </Flex>
          <Flex
            flexDirection="column"
            style={{ marginLeft: '54px' }}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src="/assets/announcement-text.svg"
              alt="text-card"
              style={{ width: '64px', height: '64px' }}
              onClick={onClickAddTextCard}
            ></img>
            <div>Text</div>
          </Flex>
          <Flex
            flexDirection="column"
            style={{ marginLeft: '54px' }}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src="/assets/blog-card.svg"
              alt="blog-card"
              style={{ width: '64px', height: '64px' }}
              onClick={onClickAddBlogCard}
            ></img>
            <div>Blog Card</div>
          </Flex>
          <Flex
            flexDirection="column"
            style={{ marginLeft: '54px' }}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src="/assets/announcement-image.svg"
              alt="blog-card"
              style={{ width: '64px', height: '64px' }}
              onClick={onClickAddImageCard}
            ></img>
            <div>Image</div>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

NotificationContentEditModalActionCard.propTypes = {
  onClickAddTextCard: PropTypes.func,
  onClickAddBlogCard: PropTypes.func,
  onClickAddRTECard: PropTypes.func,
  onClickAddImageCard: PropTypes.func,
};

export default NotificationContentEditModalActionCard;
