import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Message } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';

import { Button } from 'Components/Base';

import { updateSalesOrderStatus } from '../../GraphQL/mutation/Payment.mutation';
import { getSalesOrders } from '../../GraphQL/query/Payment.query';

import UpdateSalesOrderStatusForm from './UpdateSalesOrderStatusForm';

class UpdateSalesOrderStatusModal extends Component {
  state = { open: false };

  static propTypes = {
    actionText: PropTypes.string,
    buttonColor: PropTypes.string,
    inverted: PropTypes.bool,
    onConfirm: PropTypes.func,
    header: PropTypes.string,
    icon: PropTypes.string,
    triggerButtonStyle: PropTypes.object,
    actionButtonStyle: PropTypes.object,
    modalSize: PropTypes.string,
    descriptionText: PropTypes.string,
    descriptionTextColor: PropTypes.oneOf(['red', 'yellow', 'green']),
  };

  static defaultProps = {
    actionText: 'Show',
    buttonColor: 'orange',
    inverted: false,
    onConfirm: () => {},
    header: 'Delete Confirmation',
    icon: '',
    triggerButtonStyle: {},
    actionButtonStyle: {},
    modalSize: 'tiny',
    descriptionText: '',
    descriptionTextColor: 'green',
  };

  show = () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  handleConfirm = async (formattedValues) => {
    const { client, value, variables } = this.props;
    const { ref, receiptNo } = formattedValues;
    const modifiedData = {
      status: value.status,
      log: {
        ref,
      },
    };
    // currently unused field (receiptNo)
    if (receiptNo) {
      modifiedData.receiptNo = receiptNo;
    }
    await client.mutate({
      mutation: updateSalesOrderStatus,
      variables: {
        id: value.id,
        data: modifiedData,
      },
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: getSalesOrders,
          variables,
          fetchPolicy: 'network-only',
        },
      ],
    });

    this.close();
  };
  handleCancel = () => {
    this.close();
  };
  handleButtonClick = () => {
    this.show();
  };

  render() {
    const {
      disabled,
      actionText,
      buttonColor,
      inverted,
      children,
      icon,
      triggerButtonStyle,
      actionButtonStyle,
      headerContent,
      modalSize,
      fields,
      descriptionText,
      descriptionTextColor,
    } = this.props;
    return (
      <Fragment>
        <Button
          {...triggerButtonStyle}
          icon={icon}
          inverted={inverted}
          title={actionText}
          onClick={this.handleButtonClick}
          disabled={disabled}
        />
        <Modal size={modalSize} open={this.state.open}>
          {headerContent && <Modal.Header>{headerContent}</Modal.Header>}
          <Modal.Content>
            {descriptionText ? (
              <Message color={descriptionTextColor}>{descriptionText}</Message>
            ) : null}
            <UpdateSalesOrderStatusForm
              fields={fields}
              onSubmit={this.handleConfirm}
              onCancelled={this.handleCancel}
            ></UpdateSalesOrderStatusForm>
          </Modal.Content>
        </Modal>
      </Fragment>
    );
  }
}

export default withApollo(UpdateSalesOrderStatusModal);
