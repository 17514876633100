import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Checkbox } from 'semantic-ui-react';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const FlexInput = styled(Flex)`
  & .react-datepicker__input-container {
    width: inherit;
  }
  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

/**
 * @param {string} initValue - publishConfig | ex. {publishedTime: '11/02/2020 12:10'}
 */
const PublishTimeInput = ({
  value: initValue,
  publishTimeLabel,
  checkBoxLabel,
  onChange,
  readOnly,
}) => {
  const [publishedTime, setPublishedTime] = useState(
    initValue ? moment(initValue.publishedTime) : moment()
  );
  const [checked, setChecked] = useState(initValue ? false : true);

  const getMinMaxTimeProps = (date) => {
    if (moment(date).isSame(moment(), 'day')) {
      return {
        minTime: moment(),
        maxTime: moment().hours(23).minutes(59),
      };
    } else if (moment(date).isBefore(moment(), 'day')) {
      /* Enter an invalid date to disable time input field */
      return {
        minTime: moment().hours(25).minutes(59),
        maxTime: moment().hours(25).minutes(59),
      };
    }
  };

  useEffect(() => {
    onChange({}, { value: { publishedTime: publishedTime, isNow: checked } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, publishedTime]);
  const disableKeyboardInput = (e) => {
    e.preventDefault();
  };

  return (
    <Box>
      <Flex>
        <Checkbox
          label={checkBoxLabel}
          checked={checked}
          onChange={() => setChecked(!checked)}
          readOnly={readOnly}
        />
      </Flex>
      <Box ml={3} mt={2} />
      {!checked && (
        <FlexInput alignItems="center">
          <DatePicker
            {...getMinMaxTimeProps(publishedTime)}
            minDate={moment().toDate()}
            selected={publishedTime !== null ? moment(publishedTime) : null}
            placeholderText={publishTimeLabel}
            dateFormat="DD/MM/YYYY HH:mm"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={1}
            value={publishedTime !== null ? moment(publishedTime) : null}
            onChange={(value) => setPublishedTime(value)}
            readOnly={readOnly || checked}
            onKeyDown={disableKeyboardInput}
          />
        </FlexInput>
      )}
    </Box>
  );
};

PublishTimeInput.propTypes = {
  value: PropTypes.object,
  publishTimeLabel: PropTypes.string,
  checkBoxLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  status: PropTypes.string,
};

PublishTimeInput.defaultProps = {
  publishTimeLabel: null,
};

export default withFormsy(PublishTimeInput);
