import React from 'react';
import PropTypes from 'prop-types';

import ButtonView from 'Components/Base/Button/ButtonView';

import TagModal from '../Containers/TagModal';

const AddTagButton = (props) => {
  const { variables } = props;
  return (
    <TagModal
      variables={variables}
      trigger={<ButtonView primary circular icon="plus" title="Add New Tag" />}
    />
  );
};

AddTagButton.propTypes = {
  variables: PropTypes.object,
};

export default AddTagButton;
