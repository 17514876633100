import React, { Fragment, Component } from 'react';
import { Button, Icon, Radio, Container, Modal } from 'semantic-ui-react';

import { Flex, Text } from 'src/Components/Base';
import apolloClient from 'src/GraphQL';
import QueryTable from 'src/GraphQL/util/QueryTable';
import ConfirmationButton from 'src/Components/ConfirmationButton';
import COLUMN_TYPE from 'src/Components/Search/SearchFormGenerator/constantType';
import { getCertificates, CERTIFICATE_TYPE } from 'src/GraphQL/query/Certificate.query';
import {
  deleteCertificateMutation,
  toggleActiveCertificateMutation,
} from 'src/GraphQL/mutation/Certificate.mutation';

import {
  getCertificateTypeOptions,
  getNumberFormat,
  NO_EXPIRATION_TEXT,
  NO_EXPIRATION_DAYS,
} from './CertificateUtil';
import CertificateModal from './CertificateModal';
import CertificateItemModal from './CertificateItemsModal';
import GenerateManualCertificateModal from './Components/GenerateManualCertificateModal';

const getCertificateColumns = (handleDelete, handleToggleActive, variables) => [
  {
    Header: 'Certificate ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Certificate Name',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Certificate Code',
    accessor: 'code',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    type: COLUMN_TYPE.ENUM,
    enumOptions: getCertificateTypeOptions(),
    isSearchAble: true,
    Cell: ({ original }) => {
      const certType = Object.entries(CERTIFICATE_TYPE).find(([key, value]) => {
        return value === original.type;
      });
      if (certType) {
        return certType[0];
      } else {
        return original.type;
      }
    },
  },
  {
    Header: 'Certificate Age (Days)',
    accessor: 'validTimePeriodInDays',
    isSearchAble: false,
    type: COLUMN_TYPE.NUMBER,
    Cell: ({ original }) => {
      const { validTimePeriodInDays } = original;
      return validTimePeriodInDays === NO_EXPIRATION_DAYS
        ? NO_EXPIRATION_TEXT
        : getNumberFormat(validTimePeriodInDays);
    },
  },
  {
    Header: 'Issuer Name',
    accessor: 'issuerName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Issuer Signature',
    accessor: 'issuerSignature',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => {
      return <img width="100px" src={original.issuerSignature} alt="" />;
    },
  },
  {
    Header: 'Enable/Disable',
    accessor: 'active',
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: 'active', value: true, text: 'Enable' },
      { key: 'inactive', value: false, text: 'Disable' },
    ],
    isSearchAble: true,
    Cell: ({ original }) => {
      const { active: isActive, id: certificateId, template, type } = original;
      return (
        <Fragment>
          <Button.Group>
            <Radio
              toggle
              checked={isActive}
              onChange={() => {
                handleToggleActive(certificateId, !isActive);
              }}
              disabled={type === CERTIFICATE_TYPE.MANUAL && !template}
            />
          </Button.Group>
        </Fragment>
      );
    },
  },
  {
    Header: 'Certificate Conditions',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => {
      const { id: certificateId, type } = original;
      return (
        <Fragment>
          <Button.Group>
            {type !== CERTIFICATE_TYPE.MANUAL ? (
              <CertificateItemModal
                isEdit
                variables={variables}
                certificateId={certificateId}
                trigger={
                  <Button basic icon compact>
                    <Icon name="list" />
                  </Button>
                }
              />
            ) : (
              <GenerateManualCertificateModal
                certificateId={certificateId}
                trigger={
                  <Button basic icon compact>
                    <Icon name="list" />
                  </Button>
                }
              />
            )}
          </Button.Group>
        </Fragment>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => {
      const certificateId = original.id;
      return (
        <Fragment>
          <Button.Group>
            <CertificateModal
              isEdit
              variables={variables}
              certificateId={certificateId}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="edit" />
                </Button>
              }
            />

            <ConfirmationButton
              headerText={`Delete?`}
              contentText={'This will be permanently deleted from the system.'}
              confirmText={'Delete'}
              onConfirm={() => {
                handleDelete(certificateId);
              }}
            >
              <Icon name="trash alternate outline" />
            </ConfirmationButton>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class Certificates extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'updatedAt', type: 'DESC' },
    error: null,
  };

  handleDelete = async (certificateId) => {
    const { search, paging, order } = this.state;
    try {
      await apolloClient.mutate({
        mutation: deleteCertificateMutation,
        variables: { id: certificateId },
        refetchQueries: [
          {
            query: getCertificates,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };
  handleToggleActive = async (certificateId, active) => {
    const { search, paging, order } = this.state;
    try {
      await apolloClient.mutate({
        mutation: toggleActiveCertificateMutation,
        variables: { id: certificateId, certificateData: { active } },
        refetchQueries: [
          {
            query: getCertificates,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
      this.setState({ error: true });
    }
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <Container>
        <Modal open={this.state.error} onClose={() => this.setState({ error: null })}>
          <Modal.Header>Certificate นี้ไม่สามารถเปิดใช้งานได้</Modal.Header>
          <Modal.Content>
            <p>
              โปรดระบุ <b>“Certificate Condition”</b> ก่อนเปิดใช้งาน
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ error: false })}>Close</Button>
          </Modal.Actions>
        </Modal>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Certificate Management</Text.Header>
          <CertificateModal
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New
              </Button>
            }
          />
        </Flex>
        <QueryTable
          showSearchPanel={true}
          type="certificates"
          columns={getCertificateColumns(this.handleDelete, this.handleToggleActive, variables)}
          query={getCertificates}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          alignItems="center"
        />
      </Container>
    );
  }
}

export default Certificates;
