import React from 'react';

import Resizable from './Resizable';

const ImageBlock = ({ contentState, block }) => {
  const [width, setWidth] = React.useState(null);
  const [height, setHeight] = React.useState(null);

  const entityKey = block.getEntityAt(0);
  const entity = contentState.getEntity(entityKey);

  const { src, width: customWidth } = entity.getData();
  const type = entity.getType();

  const updateImageSize = (newWidth, newHeight) => {
    contentState.mergeEntityData(entityKey, {
      width: `${newWidth}px`,
      height: `${newHeight}px`,
      originalWidth: `${newWidth}px`,
      originalHeight: `${newHeight}px`,
    });
  };

  const image = new Image();
  image.src = src;

  image.onload = () => {
    if (width == null || height == null) {
      const initialWidth = image.naturalWidth;
      const initialHeight = image.naturalHeight;
      const aspectRatio = initialWidth / initialHeight;
      const currentWidth = parseFloat(customWidth);
      const currentHeight = parseFloat(currentWidth) / aspectRatio;
      setWidth(currentWidth);
      setHeight(currentHeight);
    }
  };

  const handleResize = (width, height) => {
    updateImageSize(width, height);
  };

  if (type === 'IMAGE') {
    return (
      <Resizable key={entityKey} width={width} height={height} onResizeStop={handleResize}>
        <img src={src} width={'100%'} heigh={'100%'} />
      </Resizable>
    );
  }

  return;
};

export default ImageBlock;
