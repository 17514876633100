import gql from 'graphql-tag';

export const updateSyllabusMutation = gql`
  mutation boUpdateSyllabus($id: ID!, $syllabusData: BOUpdateSyllabusInput!) {
    boUpdateSyllabus(id: $id, syllabusData: $syllabusData) {
      id
      name
      code
      startDate
      endDate
    }
  }
`;

export const createSubjectMutation = gql`
  mutation boCreateSubject($subjectData: BOCreateSchoolConstantInput!) {
    boCreateSubject(subjectData: $subjectData) {
      id
      name
      shortName
      code
    }
  }
`;

export const updateSubjectMutation = gql`
  mutation boUpdateSubject($id: ID!, $subjectData: BOUpdateSchoolConstantInput!) {
    boUpdateSubject(id: $id, subjectData: $subjectData) {
      id
      name
      shortName
      code
    }
  }
`;

export const createAssignmentMutation = gql`
  mutation boCreateSyllabusAssignment($syllabusAssignmentData: BOCreateSyllabusAssignmentInput!) {
    boCreateSyllabusAssignment(syllabusAssignmentData: $syllabusAssignmentData) {
      id
    }
  }
`;

// Placeholder, not being used
export const updateAssignmentMutation = gql`
  mutation boUpdateSyllabusAssignment(
    $id: ID!
    $syllabusAssignmentData: BOUpdateSyllabusAssignmentInput!
  ) {
    boUpdateSyllabusAssignment(id: $id, syllabusAssignmentData: $syllabusAssignmentData) {
      id
    }
  }
`;

export const deleteAssignmentMutation = gql`
  mutation boDeleteSyllabusAssignment($id: ID!) {
    boDeleteSyllabusAssignment(id: $id) {
      id
    }
  }
`;
