import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Flex, Text } from 'Components/Base';
import { ImageValidationMessagePopup } from 'Routes/Courses/EditCourse/Overview/Components/ImageValidationMessagePopup';

import ConfirmationButton from '../../../../Components/ConfirmationButton';
import { LEVEL } from '../../EditCourse/Lessons/utils';

const TEXT_NOT_PUBLISH = 'Course is not published.';
const TEXT_OLDER_REVISION = 'Edited content is not yet published.';
const TEXT_CURRENT_REVISION = 'Course published.';

const TEXT_CONFIRMATION_BUTTON = 'Publish';
const TEXT_CONFIRMATION_TITLE = 'Publish Course';
const TEXT_CONFIRMATION_DESCRIPTION = 'The latest content will be visible to everyone.';

const getStatusText = (currentRevision, publishedRevision) =>
  publishedRevision === null
    ? TEXT_NOT_PUBLISH
    : currentRevision <= publishedRevision
    ? TEXT_CURRENT_REVISION
    : TEXT_OLDER_REVISION;

const getButtonPublishedStatus = (disabled, currentRevision, publishedRevision) =>
  disabled || currentRevision <= publishedRevision;

const PublishToolbar = ({
  loading,
  disabled,
  currentRevision,
  publishedRevision,
  onPublishCourse,
  videoManagementButton,
  appConfig,
  isPassCourseImageValidation,
  setCourseImageBanner,
  setIsRequiredCourseImage,
  ...props
}) => {
  const {
    courses: { isRequiredCourseImage },
  } = appConfig;

  const {
    course: { coverImageUrl },
  } = props;

  useEffect(() => {
    setCourseImageBanner(coverImageUrl || null);
    setIsRequiredCourseImage(coverImageUrl || false);
  }, [coverImageUrl, coverImageUrl]);

  return (
    <Flex justifyContent="flex-end" alignItems="center" mb={3}>
      <Text mr={1}>{getStatusText(currentRevision, publishedRevision)}</Text>
      <ImageValidationMessagePopup
        content={'Course image is required, please add course image before publish'}
        open={!isPassCourseImageValidation}
        position="top center"
        trigger={
          <ConfirmationButton
            buttonStyle={{
              primary: true,
              circular: true,
              disabled: getButtonPublishedStatus(disabled, currentRevision, publishedRevision),
            }}
            loading={loading}
            confirmText={TEXT_CONFIRMATION_BUTTON}
            headerText={TEXT_CONFIRMATION_TITLE}
            contentText={TEXT_CONFIRMATION_DESCRIPTION}
            onConfirm={onPublishCourse}
            data-testid="btn-publish-course"
          >
            Publish Course
          </ConfirmationButton>
        }
      />

      {videoManagementButton(LEVEL.COURSE)}
    </Flex>
  );
};

PublishToolbar.propTypes = {
  currentRevision: PropTypes.number.isRequired,
  publishedRevision: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onPublishCourse: PropTypes.func,
  videoManagementButton: PropTypes.func,
};

PublishToolbar.defaultProps = {
  onPublishCourse: () => {},
  videoManagementButton: () => {},
};

export default PublishToolbar;
