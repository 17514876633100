import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { omit, pick } from 'lodash';

import CreateEditForm from 'Containers/CreateEditForm';
import { createAnnouncement, updateAnnouncement } from 'GraphQL/mutation/Announcement.mutation';
import { getAnnouncement, getAnnouncementReports } from 'GraphQL/query/Announcement.query';
import apolloClient from 'src/GraphQL';
import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import getConfig from 'Util/Config';
import { uploadAnnouncement } from 'Util/UserAPI/UploadApi';

import delve from '../../../Util/Delve';
import { PopupForm } from '../Form';
import { ANNOUNCEMENT_CATEGORY, RELATION_TYPE, STATUS } from '../Components/constants';

const FILE_PROPERTIES = ['name', 'originalFilename', 'provider', 'container', 'type', 'refCode'];

const GenForm = ({
  data,
  onSubmit,
  onCancelled,
  isView,
  isEdit,
  isUploading,
  onFileListRequest,
  onFileUploadUrlRequest,
}) => {
  const mapDataIntoForm = ({ detail, audiences, ...restData }) => {
    const { audienceFile } = detail ?? {};
    const hasAudienceFile = !!audienceFile;
    const mapAudiences = {
      relationIdList: [],
      relationType: RELATION_TYPE.GLOBAL,
    };
    const mapPublishConfig = {
      publishedTime: moment(),
      unpublishedTime: moment(),
      status: STATUS.DRAFT,
    };

    if (hasAudienceFile) {
      mapAudiences.files = [
        {
          ...audienceFile,
          uploadedFilename: audienceFile.name,
          name: audienceFile.originalFilename,
        },
      ];
      mapAudiences.relationType = audienceFile.relationType;
      mapAudiences.relationIdList = [];

      mapPublishConfig.publishedTime = audienceFile.publishedTime;
      mapPublishConfig.unpublishedTime = audienceFile.unpublishedTime;
      mapPublishConfig.status = audienceFile.status;
    } else {
      mapAudiences.relationIdList = audiences.map((audience, index) => ({
        id: index,
        text: audience.relationId,
        value: audience.relationId,
        key: index,
      }));
      mapAudiences.relationType = audiences[0].relationType;
      mapPublishConfig.publishedTime = audiences[0].publishedTime;
      mapPublishConfig.unpublishedTime = audiences[0].unpublishedTime;
      mapPublishConfig.status = audiences[0].status;
    }

    const mapDetail = { content: detail.content, type: detail.type.toLowerCase() };
    return {
      ...restData,
      detail: mapDetail,
      publishConfig: mapPublishConfig,
      audiences: mapAudiences,
    };
  };

  const mapFormValuePublishConfig = ({ ...formValue }, isPublish) => {
    const publishConfig = { ...formValue.publishConfig };
    const status = isPublish
      ? moment() >= publishConfig.publishedTime
        ? STATUS.PUBLISHED
        : STATUS.SCHEDULED
      : STATUS.DRAFT;
    const isNow = publishConfig.isNow;
    return {
      ...formValue,
      publishConfig: {
        publishedTime: isNow ? moment() : publishConfig.publishedTime,
        unpublishedTime: publishConfig.unpublishedTime,
        status,
      },
    };
  };

  const handleOnSubmit = ({ ...formValue }) => {
    const mappedFormData = mapFormValuePublishConfig({ ...formValue }, true);
    onSubmit({ ...mappedFormData });
  };

  const handleOnSubmitDraft = ({ ...formValue }) => {
    const mappedFormData = mapFormValuePublishConfig({ ...formValue }, false);
    onSubmit({ ...mappedFormData });
  };
  return (
    <PopupForm
      onCancelled={onCancelled}
      initialData={data && mapDataIntoForm(data.announcement)}
      onSubmit={handleOnSubmit}
      onSubmitDraft={handleOnSubmitDraft}
      isView={isView}
      isEdit={isEdit}
      isUploading={isUploading}
      onFileListRequest={onFileListRequest}
      onFileUploadUrlRequest={onFileUploadUrlRequest}
    />
  );
};

const CreateEditPopupForm = ({
  onComplete,
  onCancelled,
  announcementId,
  isEdit,
  isView,
  searchVariables,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleFileListRequest = async () => {
    const { data } = await apolloClient.mutate({
      mutation: getAssetList,
      variables: {
        type: ASSET_TYPE.SF_ASSETS,
        key: announcementId,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  const handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { data } = await apolloClient.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.SF_ASSETS,
        key: announcementId,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  const handleFileUpload = async (uploadList) => {
    const isNewFile = uploadList.every((file) => file.preview && file.size);
    if (isNewFile) {
      const ANM_STORAGE_PROVIDER = getConfig().announcement.announcementStorageProvider;
      const data = await uploadAnnouncement(uploadList);
      const fileResults =
        data?.result?.files?.fileResult?.map((fr) => {
          return {
            ...omit(fr, ['field', 'size', 'providerResponse']),
            provider: ANM_STORAGE_PROVIDER,
          };
        }) ?? [];
      return fileResults;
    }
    return uploadList.map((file) => ({
      ...file,
      originalFilename: file.name,
      name: file.uploadedFilename,
    }));
  };

  const handleUpload = async (formValue) => {
    let uploadResult;
    if (formValue.audiences?.files?.length) {
      setIsUploading(true);
      uploadResult = await handleFileUpload(formValue.audiences.files);
      setIsUploading(false);
    }
    return uploadResult;
  };

  return (
    <CreateEditForm
      dataQuery={getAnnouncement}
      createMutation={createAnnouncement}
      createRefetchQueries={[{ query: getAnnouncementReports, variables: searchVariables }]}
      editRefetchQueries={[
        { query: getAnnouncementReports, variables: searchVariables },
        { query: getAnnouncement, variables: { announcementId } },
      ]}
      editMutation={updateAnnouncement}
      isEdit={isEdit || isView}
      id={announcementId}
      getQueryVariables={({ id }) => ({
        announcementId: id,
      })}
      getEditVariables={(form, { id }) => ({
        announcementId: id,
        category: ANNOUNCEMENT_CATEGORY.POPUP,
        announcementData: {
          ...omit(form, ['fileResults']),
          audiences: [omit(form.audiences, 'files')],
          audienceFile: form.fileResults?.[0]
            ? pick(form.fileResults[0], FILE_PROPERTIES)
            : undefined,
          detail: { content: form.detail.content, type: form.detail.type.toUpperCase() },
        },
      })}
      getCreateVariables={(form) => ({
        announcementData: {
          ...omit(form, ['fileResults']),
          category: ANNOUNCEMENT_CATEGORY.POPUP,
          audiences: [omit(form.audiences, 'files')],
          audienceFile: form.fileResults?.[0]
            ? pick(form.fileResults[0], FILE_PROPERTIES)
            : undefined,
          detail: { content: form.detail.content, type: form.detail.type.toUpperCase() },
        },
      })}
      getEditMutationResponse={(mutateData) => delve(mutateData, 'updateAnnouncement')}
      getCreateMutationResponse={(mutateData) => delve(mutateData, 'createAnnouncement')}
      onCompleted={onComplete}
      onCancelled={onCancelled}
      checkMutationResponse
    >
      {({ data, onSubmit }) => (
        <GenForm
          data={data}
          onSubmit={async (value) => {
            const fileResults = await handleUpload(value);
            return onSubmit({ ...value, fileResults });
          }}
          onCancelled={onCancelled}
          isView={isView}
          isEdit={isEdit}
          isUploading={isUploading}
          onFileListRequest={handleFileListRequest}
          onFileUploadUrlRequest={handleFileUploadUrlRequest}
        />
      )}
    </CreateEditForm>
  );
};

CreateEditPopupForm.propTypes = {
  announcementId: PropTypes.string,
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  isView: PropTypes.bool,
  searchVariables: PropTypes.shape({}),
};

export default CreateEditPopupForm;
