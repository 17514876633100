import _ from 'lodash';

import getConfig from 'Util/Config';

import { CATEGORY_TYPE, SKU_CATEGORY } from './constants';

const categoryNames = {};
Object.values(SKU_CATEGORY).forEach((skuCategory) => {
  const configCategoryNames = _.defaults(
    _.get(getConfig(), ['products', skuCategory, 'categoryNames'], {}),
    {
      CATEGORY_1: 'CATEGORY_1',
      CATEGORY_2: 'CATEGORY_2',
      CATEGORY_3: 'CATEGORY_3',
      CATEGORY_4: 'CATEGORY_4',
    }
  );
  categoryNames[skuCategory] = {
    [CATEGORY_TYPE.CATEGORY_1]: configCategoryNames.CATEGORY_1,
    [CATEGORY_TYPE.CATEGORY_2]: configCategoryNames.CATEGORY_2,
    [CATEGORY_TYPE.CATEGORY_3]: configCategoryNames.CATEGORY_3,
    [CATEGORY_TYPE.CATEGORY_4]: configCategoryNames.CATEGORY_4,
  };
});

const resolveCategoryName = (skuCategory, categoryType) => {
  return categoryNames[skuCategory][categoryType];
};

export default resolveCategoryName;
