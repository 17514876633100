import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import { Label, Button, Icon, Dimmer as BaseDimmer } from 'semantic-ui-react';
import { Input } from 'formsy-semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import { Box, Flex, AspectRatio, BackgroundImage } from 'Components/Base';
import withServerConfig from 'GraphQL/util/withServerConfig';
import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import FileUploadManagerModal from 'Components/Lib/UploadComponent';

const HiddenInput = styled(Input)`
  display: none;
`;

const Dimmer = styled(BaseDimmer)`
  && {
    z-index: ${({ theme }) => theme.zIndices.imageUploadOverlay};
  }
`;

const errorLabel = <Label color="red" pointing />;

class FileUploadInput extends Component {
  static propTypes = {
    onCreateNewData: PropTypes.func,
    isEdit: PropTypes.bool,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
  };

  static defaultProps = {
    onCreateNewData: () => {},
    isEdit: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      key: _.get(props, 'defaultValue.key', ''),
      bucket: _.get(props, 'defaultValue.bucket', ''),
      filename: _.get(props, 'defaultValue.filename', ''),
      url: _.get(props, 'imageUrl', '/square-image.png'),
      active: false,
    };
  }

  handleFileListRequest = async (request) => {
    const { client, id } = this.props;
    const { data } = await client.mutate({
      mutation: getAssetList,
      variables: {
        type: ASSET_TYPE.STOREFRONT_CATEGORY,
        key: id,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { client, id } = this.props;

    const { data } = await client.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.STOREFRONT_CATEGORY,
        key: id,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  handleSelectImage = (files = []) => {
    if (files && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      // Now Support only one file upload
      const { key, bucket, url } = files[0];
      const names = key.replace(/^.*[\\\/]/, '').split('_');
      names.shift();
      this.setState({
        key,
        bucket,
        filename: names.join('_'),
        url,
      });
    }
  };

  handleHover = (active) => {
    this.setState({ active: active });
  };

  render() {
    const { serverConfig } = this.props;
    const { key, bucket, url, active } = this.state;

    return (
      <Fragment>
        <Flex>
          <Box flex="1 1 auto">
            {/* <Input
              value={filename}
              name={'file.originalFilename'}
              required={true}
              validationErrors={{
                isDefaultRequiredValue: 'File is required',
              }}
              placeholder=""
              errorLabel={errorLabel}
              disabled={true}
            /> */}
            <HiddenInput
              value={key}
              name={`${this.props.name}.key`}
              placeholder=""
              disabled={true}
            />
            <HiddenInput
              value={bucket}
              name={`${this.props.name}.bucket`}
              placeholder=""
              disabled={true}
            />
          </Box>
        </Flex>
        <Box
          onMouseOver={() => {
            this.handleHover(true);
          }}
          onMouseLeave={() => {
            this.handleHover(false);
          }}
          width={'300px'}
          style={{ cursor: 'pointer' }}
        >
          <FileUploadManagerModal
            keyGroup="courseImage"
            dimmer="inverted"
            uploadUrl={'http://localhost:5000'}
            listUrl={'http://localhost:5000'}
            allowedContentTypes={['image/png', 'image/jpeg', 'image/gif']}
            defaultText={'Support JPG, PNG or GIF file with less than 5MB'}
            maxFileSize={5}
            maxSelection={1}
            customFileListUrlRequest={this.handleFileListRequest}
            customUploadUrlRequest={this.handleFileUploadUrlRequest}
            mode={serverConfig.assetUploadMode}
            onFileManagerDialogClose={this.handleSelectImage}
            trigger={
              //Put it here to allow user to click any where in picture to change image
              <Dimmer.Dimmable dimmed={active}>
                <AspectRatio ratio={16 / 9}>
                  <BackgroundImage imageUrl={url} bgPosition={'center'} />
                  <Dimmer active={active}>
                    <Button primary type="button">
                      <Icon name="image outline" />
                      Change icon
                    </Button>
                  </Dimmer>
                </AspectRatio>
              </Dimmer.Dimmable>
            }
          />
        </Box>
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
  withServerConfig({ configProp: 'serverConfig' })
)(FileUploadInput);
