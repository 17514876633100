import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CERTIFICATE_TYPE } from 'GraphQL/query/Certificate.query';
import {
  getCertificateTypeOptions,
  mapCertificateToFormValues,
  mapFormValuesToCertificate,
} from 'Routes/Certificates/CertificateUtil';
import getConfig from 'Util/Config';

import FormGenerator from '../../Components/Form/FormGenerator';

import ImageReaderInput from './Components/ImageReaderInput';
import CertificateAgeInput from './Components/CertificateAgeInput';
import CertificateTemplateUpload from './Components/CertificateTemplateUpload';
import CertificateCheckBoxInput from './Components/CertificateCheckBoxInput';

const { enableCustomCertificateTemplate = false, defaultCertificateTemplateUrl = '' } =
  getConfig()?.certificateTemplate ?? {};

const fixedSignatureImageSize = {
  width: 210,
  height: 100,
};

const fields = (formData) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Certificate Name',
    inputName: 'name',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Certificate Code',
    inputName: 'code',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Type',
    inputName: 'type',
    options: getCertificateTypeOptions(),
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Certificate Age (Days)',
    inputName: 'validTimePeriodInDays',
    inputProps: {
      inputLabel: 'Certificate Age (Days)',
      checkboxInputLabel: 'No expiration date',
      validationErrors: {
        isDefaultRequiredValue: `Certificate Age (Days) is required`,
      },
      required: true,
    },
    customInput: (props) => <CertificateAgeInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Issuer Name (Hide Issuer name, If field is empty)',
    inputName: 'issuerName',
    inputProps: {
      required: false,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Issuer Signature (210x100px) (Hide Issuer signature, If field is empty)',
    inputName: 'issuerSignature',
    inputProps: {
      required: false,
      instantValidation: true,
      validations: {
        checkImageError: (_values, { error } = {}) => {
          if (error) {
            return error;
          }
          return true;
        },
        checkImageResolution: (_values, { width, height } = {}) => {
          if (
            width &&
            height &&
            (width !== fixedSignatureImageSize.width || height !== fixedSignatureImageSize.height)
          ) {
            return 'signature image must have exactly 210x100px size';
          }
          return true;
        },
      },
      imageWidth: fixedSignatureImageSize.width,
      imageHeight: fixedSignatureImageSize.height,
    },
    customInput: (props) => <ImageReaderInput {...props} />,
  },

  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputName: 'shouldHideCourse',
    inputLabel: '',
    inputProps: {
      label: 'Hide the "About This Course" section on the Certification page',
      required: false,
    },
    customInput: (props) => <CertificateCheckBoxInput {...props} />,
  },

  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputName: 'shouldGenerateQRCode',
    inputLabel: '',
    inputProps: {
      label: 'Generate a QR code on the certificate',
      required: false,
    },
    customInput: (props) => <CertificateCheckBoxInput {...props} />,
  },

  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel:
      formData?.type === CERTIFICATE_TYPE.MANUAL
        ? 'Template (ไฟล์ .JPG, .JPEG, .PNG และขนาด 1600 × 1190px เท่านั้น)'
        : 'Certificate Template',
    inputName: 'template',
    inputDescription:
      'สามารถอัปโหลดไฟล์ .JPG, .JPEG, .PNG ขนาด 1600 × 1190px สำหรับสร้าง Certificate โดยชื่อ นามสกุล ลายเซ็น ยังจะ fixed ตำแหน่งเสมอ',
    hidden: !(
      formData?.type === CERTIFICATE_TYPE.MANUAL ||
      (formData?.type === CERTIFICATE_TYPE.SKOOLDIO_ONLINE && enableCustomCertificateTemplate)
    ),
    customInput: (props) => (
      <CertificateTemplateUpload
        certificateId={formData?.id}
        type={formData?.type}
        defaultCertificateTemplateUrl={defaultCertificateTemplateUrl}
        {...props}
      />
    ),
    mode: 'EDIT',
  },
];

export default class CertificateForm extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    onSubmit: PropTypes.func,
    initialData: PropTypes.object,
  };

  static defaultProps = {
    onSubmit: () => {},
    isEdit: false,
  };

  state = {
    formData: mapCertificateToFormValues(this.props.initialData),
  };

  handleOnSubmit = (formValue) => {
    const certificate = mapFormValuesToCertificate(formValue, ImageReaderInput);
    this.props.onSubmit(certificate);
  };

  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={fields(this.state.formData)}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        onChange={(currentValue) =>
          this.setState({ formData: { ...this.state.formData, currentValue } })
        }
        showCancel
        {...this.props}
        initialData={this.state.formData}
        onSubmit={this.handleOnSubmit}
      />
    );
  }
}
