import gql from 'graphql-tag';

export const addOrganizationEmail = gql`
  mutation addOrganizationEmail(
    $organizationId: ID!
    $organizationEmailData: NewOrganizationEmailInput!
  ) {
    addOrganizationEmail(
      organizationId: $organizationId
      organizationEmailData: $organizationEmailData
    ) {
      code
      success
      message
      organizationEmail {
        id
        email
        isBlacklist
        organizationId
      }
    }
  }
`;

export const deleteOrganizationEmail = gql`
  mutation deleteOrganizationEmail($organizationId: ID!, $organizationEmailId: ID!) {
    deleteOrganizationEmail(
      organizationId: $organizationId
      organizationEmailId: $organizationEmailId
    ) {
      code
      success
      message
      organizationEmail {
        id
        email
        isBlacklist
        organizationId
      }
    }
  }
`;
