import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';

import { FILE_TYPE } from '../../common';

import FileUploadInput from './FileUploadInput';

const getFields = (type, schoolGradeData, schoolProgrammeData) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Code',
      inputName: 'arg1',
      inputProps: {
        required: true,
      },
    },
  ];
  if (type === FILE_TYPE.STUDENT) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'ชั้นปี',
      inputName: 'arg2',
      options: schoolGradeData.map(({ id, name }) => ({
        text: name,
        value: id,
        key: id,
      })),
      inputProps: {
        required: true,
      },
    });
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'แผนอาชีพ',
      inputName: 'arg3',
      options: schoolProgrammeData.map(({ id, name }) => ({
        text: name,
        value: id,
        key: id,
      })),
      inputProps: {
        required: true,
      },
    });
  }
  fields.push({
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'File',
    inputName: 'file',
    inputProps: {
      required: true,
    },
    customInput: (props) => <FileUploadInput type={type} {...props} />,
  });
  return fields;
};

export default class DataForm extends Component {
  static propTypes = {
    onCreateNewData: PropTypes.func,
    isEdit: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(FILE_TYPE)).isRequired,
    schoolGradeData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    schoolProgrammeData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    onCreateNewData: () => {},
    isEdit: false,
    schoolGradeData: [],
    schoolProgrammeData: [],
  };

  handleSubmit = (form) => {
    this.props.onCreateNewData(form);
  };

  render() {
    const { isEdit, type, schoolGradeData, schoolProgrammeData } = this.props;
    return (
      <FormGenerator
        fields={getFields(type, schoolGradeData, schoolProgrammeData)}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
