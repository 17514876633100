import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Container } from 'semantic-ui-react';
import urljoin from 'url-join';

import Table from 'Components/DataTable';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import {
  getUserAssignmentCourseSummary,
  getExportUserAssignmentCourseGradingResultReport,
} from 'GraphQL/query/Courses.query';
import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';

import UnpublishCourseWarning from './Components/UnpublishCourseWarning';

function getFetchErrorMessage(error) {
  const rawMessage = error?.message ?? '';
  if (rawMessage.includes('401')) return `You don't have permission to do this action`;
  return `Failed to fetch Assignments, please try again`;
}

const getColumns = (currentUrl) => [
  {
    Header: 'Section',
    accessor: 'id',
    Cell: ({ original: section }) => (
      <Link to={{ pathname: urljoin(currentUrl, section.id) }}>{section.title}</Link>
    ),
  },
];

const ExportAssignment = ({ courseId }) => {
  const [exportLoading, setExportLoading] = useState(false);

  const { refetch } = useQuery(getExportUserAssignmentCourseGradingResultReport, {
    variables: { sourceCourseId: courseId },
    skip: true,
    fetchPolicy: 'network-only',
  });

  const onExportExcel = async () => {
    setExportLoading(true);
    const { data: exportData, error: exportError } = await refetch();

    if (exportError) {
      window.alert(`Error: ${exportError}`);
      return;
    }

    const base64Data = exportData?.exportUserAssignmentCourseGradingResultReport?.body;
    if (!base64Data) {
      window.alert('Error: Not have the Assignment reports');
      return;
    }
    const base64Response = await fetch(
      `data:${exportData?.exportUserAssignmentCourseGradingResultReport?.contentType};base64,${base64Data}`
    );
    const blob = await base64Response.blob();
    const fileDownloadUrl = URL.createObjectURL(blob);

    const downloadElement = document.createElement('a');
    downloadElement.setAttribute('href', fileDownloadUrl);
    downloadElement.setAttribute(
      'download',
      `${exportData?.exportUserAssignmentCourseGradingResultReport?.fileName}`
    );
    downloadElement.click();
    downloadElement.remove();

    setExportLoading(false);
  };

  return (
    <Button primary circular onClick={onExportExcel} loading={exportLoading}>
      Export as Excel
    </Button>
  );
};

function AssignmentLesson({ match }) {
  const courseId = match?.params?.courseId;
  const history = useHistory();
  const { data: queryData, loading: queryLoading, error: queryError } = useQuery(
    getUserAssignmentCourseSummary,
    {
      variables: { sourceCourseId: courseId },
      skip: !courseId,
      fetchPolicy: 'network-only',
    }
  );

  const sections = queryData?.userAssignmentCourseSummary?.sections ?? [];

  const gotoCourse = () => {
    const path = match.url.replace('/assignments', '/overview');
    history.push(path);
  };

  if (queryError) {
    if (queryError.message && queryError.message.includes('404')) {
      // assume the course is unpublished
      return <UnpublishCourseWarning goToCourse={gotoCourse} />;
    } else {
      return <ErrorView message={getFetchErrorMessage(queryError.message)} />;
    }
  }

  return (
    <Container>
      <Flex justifyContent="end" mb="3">
        <ExportAssignment courseId={courseId} />
      </Flex>
      {queryLoading ? (
        <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />
      ) : (
        <Table columns={getColumns(match?.url)} data={sections} defaultPageSize={10} />
      )}
    </Container>
  );
}

export default AssignmentLesson;
