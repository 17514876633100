/**
 * Enum for passing criteria type
 * @enum {string}
 */
export const EXAM_SCORE_TYPE = {
  /** Pass if user score is more than or equal to the criteria */
  SCORE: 'SCORE',
  /** Pass if the percentage of user score dividing by full score is more than or equal to the criteria */
  PERCENT: 'PERCENT',
};

export const EXAM_STATUS = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  CANCELLED: 'CANCELLED',
};

export const TAG_CATEGORY = {
  EXAM_TYPE: 'EXAM_TYPE',
};

export const EXAM_TYPE_TAG = {
  OFFICIAL_EXAM: 'OFFICIAL EXAM',
};
