import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';

import { getSubscriptions } from '../../GraphQL/query/Products.query';
import { CATEGORY_TYPE, SKU_CATEGORY, SKU_STATUS_SEARCH_OPTIONS } from '../../Domains/constants';
import resolveCategoryName from '../../Domains/resolveCategoryName';
import CategorySelectorInput from '../../Components/CategorySelectorInput';
import { getSubscriptionDetailsOverviewUrl } from '../../Domains/resolveUrl';
import SubscriptionModal from '../../Components/SubscriptionModal';
import CategoryCell from '../../Components/CategoryCell';

const subscriptionsColumns = (skuCategory, pathname, handleEdit) => [
  {
    Header: 'SKU Code',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: (row) => (
      <Link to={{ pathname: `${pathname}/${row.original.SKUCode}/overview` }}>{row.value}</Link>
    ),
  },
  {
    Header: 'Status',
    accessor: 'SKUStatus',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    sortable: false,
    enumOptions: SKU_STATUS_SEARCH_OPTIONS,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1),
    accessor: 'category1Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_1}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category1s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2),
    accessor: 'category2Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_2}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category2s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3),
    accessor: 'category3Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_3}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category3s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4),
    accessor: 'category4Ids',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_4}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category4s} />,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      return (
        <Fragment>
          <Button.Group>
            <Button basic size="mini" icon compact onClick={() => handleEdit(original.SKUCode)}>
              <Icon name="edit" />
            </Button>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class SubscriptionsRoute extends Component {
  static propTypes = {
    skuCategory: PropTypes.oneOf(Object.values(SKU_CATEGORY)), // If possible, will reuse this component with Workshop
  };

  static defaultProps = {
    skuCategory: SKU_CATEGORY.SUBSCRIPTION,
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: {},
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  handleEdit = (skuCode) => {
    this.props.history.push(getSubscriptionDetailsOverviewUrl(skuCode));
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoProductSubscriptions.subscriptions;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };

    const { location } = this.props;
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Subscriptions</Text.Header>
          <SubscriptionModal
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New Subscription
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={'sfBoProductSubscriptions'}
          resolveData={this.resolveData}
          columns={subscriptionsColumns(this.props.skuCategory, location.pathname, this.handleEdit)}
          query={getSubscriptions}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(SubscriptionsRoute);
