import gql from 'graphql-tag';

export const getCourseAnnouncementReports = gql`
  query getCourseAnnouncementReports(
    $search: CourseAnnouncementReportSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    courseAnnouncementReports(search: $search, paging: $paging, order: $order) {
      courseAnnouncementReports {
        id
        title
        category
        publishedTime
        status
      }
      total
    }
  }
`;
