import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import getConfig from 'Util/Config';
import QueryComponent from 'GraphQL/util/QueryComponent';
import { VideoBrowse } from 'Components/AssetComponents/VideoManagement';
import { getAllVideos, byteArkStreamGetAllVideos } from 'GraphQL/query/Video.query';
import {
  deleteMultipleVideos,
  byteArkStreamDeleteMultipleVideos,
} from 'GraphQL/mutation/Video.mutation';

const pageSize = 10;
const { enabledByteArkStream: enabledStreamApi, byteArkStreamProjectKey } = getConfig();

const VideoBrowseContainer = (props) => {
  const { isAllowMultiple, onSelectVideo, courseCode } = props;
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({ courseCode });
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [deleteVideos] = useMutation(
    enabledStreamApi ? byteArkStreamDeleteMultipleVideos : deleteMultipleVideos
  );

  const handleBrowseSearch = (searchForm) => {
    setFilters({ ...searchForm, courseCode });
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const getFilters = useCallback(() => {
    return { ...filters, page, size: pageSize };
  }, [filters, page]);

  const handleDeleteVideos = (videoKeys) => {
    deleteVideos({
      variables: {
        videoKeys: videoKeys,
      },
      refetchQueries: [
        {
          query: enabledStreamApi ? byteArkStreamGetAllVideos : getAllVideos,
          variables: enabledStreamApi
            ? { projectKey: byteArkStreamProjectKey, filters: getFilters() }
            : { filters: getFilters() },
        },
      ],
    });
  };

  return (
    <QueryComponent
      query={enabledStreamApi ? byteArkStreamGetAllVideos : getAllVideos}
      variables={
        enabledStreamApi
          ? { projectKey: byteArkStreamProjectKey, filters: getFilters() }
          : { filters: getFilters() }
      }
      fetchPolicy={'network-only'}
    >
      {(data) => (
        <VideoBrowse
          videoPaging={enabledStreamApi ? data.vdmByteArkVideos : data.vdmVideos}
          onSearch={handleBrowseSearch}
          searchForm={filters}
          isAllowMultiple={isAllowMultiple}
          onSelectVideo={onSelectVideo}
          onDeleteVideos={handleDeleteVideos}
          onPageChange={handlePageChange}
          selectedVideos={selectedVideos}
          setSelectedVideos={setSelectedVideos}
        />
      )}
    </QueryComponent>
  );
};

VideoBrowseContainer.propTypes = {
  isAllowMultiple: PropTypes.bool,
  onSelectVideo: PropTypes.func,
  courseCode: PropTypes.string,
};

export default VideoBrowseContainer;
