import gql from 'graphql-tag';

export const createDiscountCodeFileImportJob = gql`
  mutation sfBoCreateDiscountCodeFileImportJob($input: SFBoCreateDiscountCodeFileImportInput!) {
    sfBoCreateDiscountCodeFileImportJob(input: $input) {
      id
      type
      file {
        key
        bucket
      }
      originalFilename
      arg1
    }
  }
`;

export const createShipmentsTrackingFileImportJob = gql`
  mutation sfBoCreateShipmentsTrackingFileImportJob(
    $input: SFBoCreateShipmentsTrackingFileImportInput!
  ) {
    sfBoCreateShipmentsTrackingFileImportJob(input: $input) {
      id
      type
      file {
        key
        bucket
      }
      originalFilename
      arg1
    }
  }
`;

export const deleteFileImportJob = gql`
  mutation sfBoDeleteFileImportJob($id: ID!) {
    sfBoDeleteFileImportJob(id: $id)
  }
`;

export const requestAssetUrl = gql`
  mutation asRequestAssetUrl($type: AS_ASSET_TYPE!, $bucket: String!, $key: String!) {
    asRequestAssetUrl(type: $type, bucket: $bucket, key: $key) {
      code
      success
      message
      url {
        url
      }
    }
  }
`;
