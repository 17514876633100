import React from 'react';

import { SKU_CATEGORY } from 'Routes/Products/Domains/constants';
import {
  createSubscriptionCategoryMutation,
  updateSubscriptionCategoryMutation,
} from 'Routes/Products/GraphQL/mutation/Products.mutation';
import {
  getSubscriptionCategoryByPermalink,
  getSubscriptionCategories,
  getSubscriptionCategory,
} from 'Routes/Products/GraphQL/query/Products.query';

import CategoriesBase from './BaseComponent/CategoriesBase';
export const CategoriesSubscriptionRoute = (props) => {
  return (
    <CategoriesBase
      {...props}
      skuCategory={SKU_CATEGORY.SUBSCRIPTION}
      getCategoryByPermalinkQueryName="sfBoProductSubscriptionCategoryByPermalink"
      getCategoryByPermalink={getSubscriptionCategoryByPermalink}
      getCategoriesQueryName="sfBoProductSubscriptionCategories"
      getCategories={getSubscriptionCategories}
      getCategory={getSubscriptionCategory}
      getCategoryQueryName="sfBoProductSubscriptionCategory"
      createMutation={createSubscriptionCategoryMutation}
      updateMutation={updateSubscriptionCategoryMutation}
    />
  );
};
