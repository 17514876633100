import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';
import FormGenerator from 'Components/Form/FormGenerator';

import { getCollectionOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateCollectionOnlineCourseRatingMutation } from '../../GraphQL/mutation/Products.mutation';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Average',
      inputName: 'avg',
      mode: MODE.BOTH,
    },
  ];
  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

const OnlineCourseRatingEditor = ({ onlineCourse = {}, isEdit = true, ...restProps }) => {
  const { id, SKUCode } = onlineCourse;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getCollectionOnlineCourse}
        // TODO: insert mutation function here
        editMutation={updateCollectionOnlineCourseRatingMutation}
        isEdit={isEdit}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({
          SKUCode,
        })}
        getEditVariables={(form) => ({
          id,
          data: {
            avg: form.avg,
          },
        })}
      >
        {({ data, onSubmit }) => {
          return (
            <FormGenerator
              initialData={data?.sfBoProductCollectionOnlineCourse?.ratings || {}}
              fields={getFields(isEdit)}
              onSubmit={onSubmit}
              submitText={isEdit ? 'Update' : 'Add'}
              cancelText="Cancel"
              showCancel
              {...restProps}
            />
          );
        }}
      </CreateEditForm>
    </Box>
  );
};

OnlineCourseRatingEditor.propTypes = {
  onlineCourse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
  isEdit: PropTypes.bool,
};

export default OnlineCourseRatingEditor;
