import React from 'react';
import { Modal, Divider, Input, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Flex, Text } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import { ERP_ERROR_STATUS, ERP_PRODUCT } from 'Routes/Products/Domains/ERPUtils';

const ModalHeader = styled.h4`
  padding-top: 20px;
  font-size: 20px;
  font-weight: 500;
`;

const ContentHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const SKUDescription = styled.div`
  font-size: 14px;
  font-weight: 100;
`;

const ModalDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const EmailText = styled.span`
  color: blue;
`;

const SalesPriceText = styled.span`
  font-weight: 300;
  font-size: 14px;
`;

const PricingHint = styled.div`
  font-weight: 100;
  font-size: 14px;
  color: red;
  margin-top: 5px;
`;

const ValuePreview = ({ value, error, placeHolder = '' }) => {
  const style = {
    width: '100%',
    marginTop: '10px',
    border: `1px solid ${error ? 'red' : 'currentcolor'}`,
    borderRadius: '.28571429rem',
  };

  return <Input style={style} value={value} disabled={true} placeHolder={placeHolder} />;
};

const StatusIcon = ({ isValid }) => {
  return (
    <Icon
      color={isValid ? 'green' : 'red'}
      name={`${isValid ? 'check' : 'times'} circle`}
      size="large"
    />
  );
};

const getERPProductText = (productType) => {
  switch (productType) {
    case ERP_PRODUCT.ONLINE_COURSE:
      return { capitalize: 'Course', lower: 'course' };
    case ERP_PRODUCT.WORKSHOP_BATCH:
      return { capitalize: 'Workshop Batch', lower: 'workshop batch' };
    case ERP_PRODUCT.BUNDLE:
      return { capitalize: 'Bundle', lower: 'bundle' };
    default:
      return { capitalize: 'Course', lower: 'course' };
  }
};

const ERPWarningModal = ({
  open,
  handleOpen,
  error,
  skuCode,
  salePriceInclVAT = 'No price yet.',
  erpProduct,
}) => {
  const isInvalidSKUCode = error.includes(ERP_ERROR_STATUS.INVALID_SKUCODE);
  const isNoPrice =
    error.includes(ERP_ERROR_STATUS.NO_PRICING) || error.includes(ERP_ERROR_STATUS.ZERO_PRICING);
  const productText = getERPProductText(erpProduct);
  return (
    <Modal
      open={open}
      onClose={() => handleOpen(false)}
      dimmer="inverted"
      style={{ maxWidth: 600 }}
    >
      <Modal.Content>
        <ModalHeader>{`${productText.capitalize} can't be published.`}</ModalHeader>
        <ModalDescription>
          {`Please fill all SKUCode and Pricing in order to published the ${productText.lower}.`}
        </ModalDescription>
        <Divider />
        <Flex justifyContent="space-between" alignItems="center" mb="10px" mt="30px">
          <ContentHeader>{`SKUCode`}</ContentHeader>
          <StatusIcon isValid={!isInvalidSKUCode} />
        </Flex>
        <SKUDescription>
          {`Once you update this ${productText.lower}, SKUCode can't be changed. To change, please contacts email`}
          <EmailText>{` it@skooldio.com`}</EmailText>
        </SKUDescription>
        <ValuePreview value={skuCode} error={isInvalidSKUCode} />

        <Flex justifyContent="space-between" alignItems="center" mb="10px" mt="30px">
          <ContentHeader>
            {`Pricing ราคาขายจริง`}
            <SalesPriceText>{` (SalePrice incl. VAT)`}</SalesPriceText>
          </ContentHeader>
          <StatusIcon isValid={!isNoPrice} />
        </Flex>
        <ValuePreview value={salePriceInclVAT} error={isNoPrice} />

        {isNoPrice && <PricingHint>{`Fill Pricing in tab "Pricing"`}</PricingHint>}
        <Flex justifyContent="center" alignItems="center" mt={4}>
          <Button
            primary
            circular
            size="medium"
            type="button"
            content={'Close'}
            onClick={() => handleOpen(false)}
          />
        </Flex>
      </Modal.Content>
    </Modal>
  );
};

export default ERPWarningModal;
