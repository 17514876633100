import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { GenSwitchRoute } from 'Util/Route';
import SideMenuLayout from 'Components/SideMenuLayout';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import ImportSchoolsRoute from './Routes/ImportSchools';
const ImportDataRouteIndex = () => (
  <Redirect to={{ title: '', pathname: '/le/import-data/schools' }} />
);

const defaultRoute = {
  path: '/le/import-data',
  exact: true,
  roles: [],
  route: ImportDataRouteIndex,
};

const menus = [
  {
    path: '/le/import-data/schools',
    title: 'Schools',
    menuName: 'Schools',
    icon: 'university',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: ImportSchoolsRoute,
  },
];

export default class ImportDataRoute extends Component {
  render() {
    return (
      <SideMenuLayout header={'File Types'} menus={menus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute routes={[...menus, defaultRoute]} />
      </SideMenuLayout>
    );
  }
}
