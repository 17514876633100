import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import { ComplexSideMenuLayout } from 'Components/SideMenuLayout';
import { GenSwitchRoute } from 'Util/Route';
import APP_PERMISSION from 'Util/permissions/permissionKeys';
import getConfig from 'Util/Config';

import {
  pendingSalesOrdersUrl,
  successSalesOrdersUrl,
  failedSalesOrdersUrl,
  redemptionOrdersUrl,
  trialOrdersUrl,
  adjustmentOrderUrl,
  voidSalesOrdersUrl,
  discountCodesUrl,
  personalizedDiscountCodesUrl,
  randomDiscountCodeJobUrl,
  SubscriptionUrl,
} from './Domains/resolveUrl';
import {
  FailedSalesOrderRoute,
  PendingSalesOrderRoute,
  SuccessSalesOrderRoute,
  RedemptionOrderRoute,
  TrialOrderRoute,
  AdjustmentOrderRoute,
  VoidSalesOrderRoute,
  DiscountCodeRoute,
  SubscriptionRoute,
  PersonalizedDiscountRoute,
} from './Routes';
import RandomDiscountCodeJobRoute from './Routes/RandomDiscountCodeJobRoute';

const { enabledPersonalizedDiscountCode } = getConfig();

const RouteIndex = () => (
  <Redirect to={{ title: '', pathname: '/sales-v2/sales-orders/success' }} />
);

const defaultRoute = {
  path: '/sales-v2',
  exact: true,
  roles: [],
  route: RouteIndex,
};

const menus = {
  'Sales Orders': [
    {
      path: pendingSalesOrdersUrl,
      title: 'Pending',
      menuName: 'Pending',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SALES_ORDER.PENDING.LIST.VIEW,
      ],
      route: PendingSalesOrderRoute,
    },
    {
      path: successSalesOrdersUrl,
      title: 'Success',
      menuName: 'Success',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SALES_ORDER.SUCCESS.LIST.VIEW,
      ],
      route: SuccessSalesOrderRoute,
    },
    {
      path: voidSalesOrdersUrl,
      title: 'Void',
      menuName: 'Void',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SALES_ORDER.VOID.LIST.VIEW,
      ],
      route: VoidSalesOrderRoute,
    },
    {
      path: failedSalesOrdersUrl,
      title: 'Failed',
      menuName: 'Failed',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SALES_ORDER.FAILED.LIST.VIEW,
      ],
      route: FailedSalesOrderRoute,
    },
  ],
  'Redemption Orders': [
    {
      path: redemptionOrdersUrl,
      title: 'Redemption Orders',
      menuName: 'Redemption Orders',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.REDEMPTION_ORDER.LIST.VIEW,
      ],
      route: RedemptionOrderRoute,
    },
  ],
  'Trial Orders': [
    {
      path: trialOrdersUrl,
      title: 'Trial Orders',
      menuName: 'Trial Orders',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.TRIAL_ORDER.LIST.VIEW,
      ],
      route: TrialOrderRoute,
    },
  ],
  'Subscription Management': [
    {
      path: adjustmentOrderUrl,
      title: 'Adjustment Orders',
      menuName: 'Adjustment Orders',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT.ADJUSTMENT_ORDER.LIST.VIEW,
      ],
      route: AdjustmentOrderRoute,
    },
    {
      path: SubscriptionUrl,
      title: 'Subscription',
      menuName: 'Subscription',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT.SUBSCRIPTION.LIST.VIEW,
      ],
      route: SubscriptionRoute,
    },
  ],
  'Discount Codes': [
    {
      path: discountCodesUrl,

      title: 'Discount Codes',
      menuName: 'Discount Codes',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.DISCOUNT_CODE.LIST.VIEW,
      ],
      route: DiscountCodeRoute,
    },
    {
      path: personalizedDiscountCodesUrl,
      title: 'Personalized Discount Code Templates',
      menuName: 'Personalized Discount Code Templates',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.DISCOUNT_CODE.LIST.VIEW,
      ],
      route: PersonalizedDiscountRoute,
    },
    {
      path: randomDiscountCodeJobUrl,
      title: 'Random Discount code',
      menuName: 'Random Discount code',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.DISCOUNT_CODE.LIST.VIEW,
      ],
      route: RandomDiscountCodeJobRoute,
    },
  ],
};

class SalesRoute extends Component {
  render() {
    const { myRoles } = this.props;
    const visibleMenus = {};
    Object.keys(menus).forEach((header) => {
      visibleMenus[header] = menus[header].filter(({ hidden, roles }) => {
        return !hidden && _.intersection(roles, myRoles).length > 0;
      });
    });
    const filteredMenus = {};
    Object.keys(visibleMenus).forEach((header) => {
      if (visibleMenus[header].length > 0) {
        filteredMenus[header] = visibleMenus[header];
      }
    });
    return (
      <ComplexSideMenuLayout menus={filteredMenus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute routes={[..._.flatMap(menus, (innerMenus) => innerMenus), defaultRoute]} />
      </ComplexSideMenuLayout>
    );
  }
}
export default connect()(SalesRoute);
