import React, { useState } from 'react';
import ReactTagInput from '@pathofdev/react-tag-input';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import withFormsy from 'Components/Form/withFormsy';
import { Flex } from 'Components/Base';

const StyledFlex = styled(Flex)`
  & .react-tag-input {
    border: none;
    padding: 0px;
    width: 100%
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  & .react-tag-input__tag {
    font-size: 16px;
    margin: 3px;
    position: relative;
    display: flex;
    align-items: center;
    background: #e1e1e1;
    border-radius: 3px;
    line-height: 1;
  }

  & .react-tag-input__tag__content {
    padding: 0 8px;
  }

  & .react-tag-input__tag__remove {
    position: relative;
    height: 32px;
    width: 32px;
    font-size: 14px;
    cursor: pointer;
    background: #d4d4d4;
  }

  & .react-tag-input__tag__remove:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  & .react-tag-input__tag__remove:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  & .react-tag-input__tag__remove:before, .react-tag-input__tag__remove:after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: .9em;
    width: .15em;
    background-color: #333;
    content: " ";
  }

  & .react-tag-input__input {
    height: auto;
    margin-top: 2px;
    padding 3px;
    font-size: 16px;
    border: 1px solid rgba(34,36,38,.15);
  }
`;

function TagInput({ value, placeholderText, onChange }) {
  const [tags, setTags] = useState(value ? value : []);

  return (
    <StyledFlex>
      <ReactTagInput
        tags={tags}
        editable={false}
        placeholder={placeholderText}
        onChange={(newTags) => {
          setTags(newTags);
          onChange({}, { value: newTags });
        }}
      />
    </StyledFlex>
  );
}

TagInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
};

export default withFormsy(TagInput);
