import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { getUserAssignment } from 'GraphQL/query/Courses.query';

import AssignmentSubmissionLogView from '../Components/AssignmentSubmissionLog';
import Title from '../Components/Title';

const AssignmentSubmissionLog = () => {
  const { params } = useRouteMatch();
  const userAssignmentId = params?.userAssignmentId;

  const { data: queryData } = useQuery(getUserAssignment, {
    variables: { userAssignmentId: userAssignmentId },
    skip: !userAssignmentId,
  });
  const { submittedAt, reviewedAt } = queryData?.userAssignmentById ?? {};

  return (
    <>
      <Title>History</Title>
      <AssignmentSubmissionLogView submittedDate={submittedAt} reviewedDate={reviewedAt} mt={2} />
    </>
  );
};

export default AssignmentSubmissionLog;
