import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { Segment } from 'semantic-ui-react';
import compose from 'recompose/compose';
import { withApollo } from '@apollo/client/react/hoc';

import Slides from 'Components/AssetComponents/Slides';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import { getWorkshopGallery } from 'GraphQL/query/Workshop.query';
import { sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import withServerConfig from 'GraphQL/util/withServerConfig';
import withAppConfig from 'Util/hoc/withAppConfig';
import delve from 'Util/Delve';

import useAssetManagement from './utils/useAssetManagement';

function Gallery({ skuCode, appConfig, serverConfig, client }) {
  const { loading, error, data: queryData } = useQuery(getWorkshopGallery, {
    variables: { SKUCode: skuCode },
    fetchPolicy: 'network-only',
  });
  const [
    updateWorkshop,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(sfUpdateWorkshop);

  const { handleFileUploadUrlRequest, handleFileListRequest } = useAssetManagement(
    client,
    skuCode,
    ASSET_TYPE.SF_ASSETS
  );
  const handleUpdateGallery = (data) => {
    const imageList = delve(data, 'content.data', []);
    const imageListInput = imageList.map(({ key, bucket }) => ({ key, bucket }));
    updateWorkshop({
      variables: {
        SKUCode: skuCode,
        data: {
          workshopImages: { images: imageListInput },
        },
      },
      refetchQueries: [{ query: getWorkshopGallery, variables: { SKUCode: skuCode } }],
    });
  };

  if (loading || mutationLoading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }
  const workshopImages = delve(queryData, 'sfWorkshopBySKUCode.workshopImages.images', []);
  const imageList = workshopImages.map((img) => ({ ...img, url: img.assetUrl }));
  return (
    <Segment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <Slides
        content={{ data: imageList }}
        onFileListRequest={handleFileListRequest}
        onFileUploadUrlRequest={handleFileUploadUrlRequest}
        uploadMode={serverConfig.assetUploadMode}
        allowedContentTypes={appConfig.courseMaterialAllowContentTypes}
        onCreateMaterial={handleUpdateGallery}
      />
    </Segment>
  );
}
Gallery.propTypes = {
  skuCode: PropTypes.string.isRequired,
  appConfig: PropTypes.array,
  serverConfig: PropTypes.any,
  client: PropTypes.any,
};

export default compose(
  withServerConfig({ configProp: 'serverConfig' }),
  withAppConfig({ configProp: 'appConfig' }),
  withApollo
)(Gallery);
