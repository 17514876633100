import gql from 'graphql-tag';

const APP_ROLE_FRAGMENT = gql`
  fragment APP_ROLE_FRAGMENT on AppRole {
    id
    name
    description
    level
  }
`;

const GET_APP_ROLE = gql`
  query getAppRole($id: ID!) {
    appRole(id: $id) {
      ...APP_ROLE_FRAGMENT
    }
  }
  ${APP_ROLE_FRAGMENT}
`;

const GET_ROLE_ASSIGNED_USERS = gql`
  query getAssignedUsersByAppRole(
    $appRoleId: ID!
    $search: AppRoleSearchUserInput
    $paging: PageInput
    $order: OrderInput
  ) {
    assignedUsersByAppRole(appRoleId: $appRoleId, search: $search, paging: $paging, order: $order) {
      total
      users {
        id
        username
        firstname
        lastname
        email
      }
    }
  }
`;

const GET_ROLE_UNASSIGNED_USERS = gql`
  query getUnassignedUsersByAppRole(
    $appRoleId: ID!
    $search: AppRoleSearchUserInput
    $paging: PageInput
    $order: OrderInput
  ) {
    unassignedUsersByAppRole(
      appRoleId: $appRoleId
      search: $search
      paging: $paging
      order: $order
    ) {
      total
      users {
        id
        username
        firstname
        lastname
        email
      }
    }
  }
`;

const GET_ALL_APP_ROLES = gql`
  query getAllAppRole($search: AppRoleSearchInput, $paging: PageInput, $order: OrderInput) {
    appRoles(search: $search, paging: $paging, order: $order) {
      appRoles {
        ...APP_ROLE_FRAGMENT
      }
      total
    }
  }
  ${APP_ROLE_FRAGMENT}
`;

export { APP_ROLE_FRAGMENT };
export { GET_ALL_APP_ROLES, GET_APP_ROLE, GET_ROLE_ASSIGNED_USERS, GET_ROLE_UNASSIGNED_USERS };
