import React, { Component } from 'react';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getSubscription } from '../../GraphQL/query/Products.query';
import { updateSubscriptionConditionsMutation } from '../../GraphQL/mutation/Products.mutation';

import SubscriptionConditionsForm from './SubscriptionConditionsForm';

const resolveData = ({ sfBoProductSubscription }) => {
  // TODO: Recheck
  return {
    type: _.get(sfBoProductSubscription, 'conditions[0].type'),
    days: _.get(sfBoProductSubscription, 'conditions[0].days'),
    gracefulOffset: _.get(sfBoProductSubscription, 'conditions[0].gracefulOffset'),
  };
};

class SubscriptionConditionsEditor extends Component {
  render() {
    const { subscription = {} } = this.props;
    const { id, SKUCode } = subscription;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getSubscription}
          editMutation={updateSubscriptionConditionsMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              // TODO: Recheck
              conditions: [{ ...form }],
            },
          })}
        >
          {({ data, onSubmit }) => {
            return (
              <SubscriptionConditionsForm initialData={resolveData(data)} onSubmit={onSubmit} />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default SubscriptionConditionsEditor;
