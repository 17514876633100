import React from 'react';
import log from 'loglevel';

import withFormsy from 'Components/Form/withFormsy';
import SelectorInput from 'Routes/Reports/Components/SelectorInput/SelectorInput';
import { PRODUCT_TYPE } from 'Routes/Products/Domains/constants';
import Loading from 'Components/Loading';
import { SKU_CATEGORY } from 'Routes/SalesV2/Domains/constants';

import useProductListData from '../../Domains/useProductListData';

const mapProductToOptions = (products, type) => {
  if (!Array.isArray(products) || products.length === 0) return [];

  const options = products.map((product) => ({
    key: product.id,
    value: product.id,
    text: `[${type}] - ${product.SKUCode} - ${product.title}`,
  }));
  return options;
};

const mapProductCourseCodeToOptions = (products) => {
  if (!Array.isArray(products) || products.length === 0) return [];

  const options = products.map((subscription) => ({
    key: subscription.id,
    value: subscription.courseCode,
    text: subscription.courseCode,
  }));
  return options;
};

const parseValue = ({ onlineCourses, workshopBatches, books, bundles, subscriptions }) => {
  const onlineCoursesOptions = mapProductToOptions(onlineCourses, PRODUCT_TYPE.ONLINE_COURSE);
  const workshopBatchesOptions = mapProductToOptions(workshopBatches, PRODUCT_TYPE.WORKSHOP);
  const booksOptions = mapProductToOptions(books, PRODUCT_TYPE.BOOK);
  const bundlesOptions = mapProductToOptions(bundles, PRODUCT_TYPE.BUNDLE);
  const subscriptionsOptions = mapProductToOptions(subscriptions, PRODUCT_TYPE.SUBSCRIPTION);
  const productOptions = [
    onlineCoursesOptions,
    workshopBatchesOptions,
    booksOptions,
    bundlesOptions,
    subscriptionsOptions,
  ].flat();
  return productOptions;
};

const ProductSelectorInputQuery = (props) => {
  const {
    onlineCourses,
    subscriptions,
    books,
    bundles,
    workshopBatches,
    loading,
    error,
  } = useProductListData();

  if (loading) return <Loading />;
  if (error) log.warn(error);

  const options = parseValue({
    onlineCourses,
    subscriptions,
    books,
    bundles,
    workshopBatches,
  });
  return <SelectorInput options={options} {...props} />;
};

const OnlineCourseSelectorInputQuery = (props) => {
  const { onlineCourses, loading, error } = useProductListData();

  if (loading) return <Loading />;
  if (error) log.warn(error);

  const options = mapProductCourseCodeToOptions(onlineCourses);
  return <SelectorInput options={options} {...props} />;
};

export default ProductSelectorInputQuery;
export { OnlineCourseSelectorInputQuery };
export const FormsyProductCourseCodeSelectorInput = withFormsy(OnlineCourseSelectorInputQuery);
export const FormsyProductSelectorInput = withFormsy(ProductSelectorInputQuery);
