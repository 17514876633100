import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';

import ErrorView from 'Components/ErrorView';
import Loading from 'Components/Loading';
import Menu from 'Containers/Menu';
import {
  getBookParentCategories,
  getCollectionParentCategories,
  getOnlineCourseParentCategories,
  getWorkshopParentCategories,
  getSubscriptionParentCategories,
} from 'Routes/Products/GraphQL/query/Products.query';
import { GenRoute } from 'Util/Route';

import { SKU_CATEGORY } from '../../../Domains/constants';

import CategoryRoute from './CategoryRoute';

const CategoriesBase = (props) => {
  const { skuCategory, match, roles } = props;
  let query = undefined;
  let queryName = '';
  switch (skuCategory) {
    case SKU_CATEGORY.ONLINE_COURSE:
      query = getOnlineCourseParentCategories;
      queryName = 'sfBoProductOnlineCourseParentCategories';
      break;
    case SKU_CATEGORY.WORKSHOP:
    case SKU_CATEGORY.WORKSHOP_BATCH:
      query = getWorkshopParentCategories;
      queryName = 'sfBoProductWorkshopParentCategories';
      break;
    case SKU_CATEGORY.COLLECTION:
      query = getCollectionParentCategories;
      queryName = 'sfBoProductCollectionParentCategories';
      break;
    case SKU_CATEGORY.BOOK:
      query = getBookParentCategories;
      queryName = 'sfBoProductBookParentCategories';
      break;
    case SKU_CATEGORY.SUBSCRIPTION:
      query = getSubscriptionParentCategories;
      queryName = 'sfBoProductSubscriptionParentCategories';
      break;
    default:
  }

  const { data: categoriesData, loading, error } = useQuery(query);

  if (loading) {
    return <Loading />;
  } else if (!loading && error) {
    return <ErrorView message={error.message ?? ''} />;
  }

  const genMenu = (matchUrl) => {
    if (!Array.isArray(categoriesData[queryName])) {
      return [];
    }
    const menus = categoriesData[queryName].map((categoryData) => ({
      path: `${matchUrl}/${categoryData.permalink}`,
      title: categoryData.title,
      private: true,
      exact: true,
      roles: roles,
      route: () => CategoryRoute({ ...props, ...categoryData }),
    }));

    return menus.concat({
      path: match.url,
      exact: true,
      roles: [],
      route: () => <Redirect to={{ title: '', pathname: menus?.[0]?.path }} />,
    });
  };

  return (
    <Fragment>
      <Menu menus={genMenu(match.url)} menuProps={{ secondary: true, pointing: true }} />
      <GenRoute menus={genMenu(match.path)} />
    </Fragment>
  );
};

CategoriesBase.propTypes = {
  skuCategory: PropTypes.oneOf(Object.values(SKU_CATEGORY)).isRequired,
  getCategoryByPermalink: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getCategoryByPermalinkQueryName: PropTypes.string.isRequired,
  getCategoriesQueryName: PropTypes.string.isRequired,
  getCategory: PropTypes.func.isRequired,
  getCategoryQueryName: PropTypes.string.isRequired,
  createMutation: PropTypes.func.isRequired,
  updateMutation: PropTypes.func.isRequired,
};

export default CategoriesBase;
