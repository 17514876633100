import gql from 'graphql-tag';

import {
  SmallCourseFragment,
  MediumCourseFragment,
  CourseItemMetaFragment,
  UserAssignmentFragment,
  COURSE_TEAM_FRAGMENT,
} from '../query/Courses.query';

export const addUserCoursePermission = gql`
  mutation addUserCoursePermission(
    $version: COURSE_VERSION!
    $courseId: ID!
    $permission: USER_COURSE_PERMISSION!
    $userId: ID!
  ) {
    addCourseUserPermission(
      version: $version
      courseId: $courseId
      userId: $userId
      permission: $permission
    ) {
      id
      userId
      courseId
      permission
    }
  }
`;

export const removeUserCoursePermission = gql`
  mutation removeUserCoursePermission(
    $version: COURSE_VERSION!
    $courseId: ID!
    $permission: USER_COURSE_PERMISSION!
    $userId: ID!
  ) {
    removeCourseUserPermission(
      version: $version
      courseId: $courseId
      userId: $userId
      permission: $permission
    ) {
      id
      userId
      courseId
      permission
    }
  }
`;

export const addSection = gql`
  mutation addSection(
    $version: COURSE_VERSION!
    $courseId: ID!
    $afterItemId: ID
    $sectionData: CourseSectionNewInput!
  ) {
    addCourseSection(
      version: $version
      courseId: $courseId
      afterItemId: $afterItemId
      sectionData: $sectionData
    ) {
      id
      ...CourseItemMetaFragment
    }
  }
  ${CourseItemMetaFragment}
`;

export const addSubsection = gql`
  mutation addSubSection(
    $version: COURSE_VERSION!
    $courseId: ID!
    $afterItemId: ID
    $subsectionData: CourseSubsectionNewInput!
  ) {
    addCourseSubsection(
      version: $version
      courseId: $courseId
      afterItemId: $afterItemId
      subsectionData: $subsectionData
    ) {
      id
      ...CourseItemMetaFragment
    }
  }
  ${CourseItemMetaFragment}
`;

export const createCourse = gql`
  mutation createCourse(
    $version: COURSE_VERSION!
    $courseDesc: NewCourseInput!
    $enabledContentTeam: Boolean = false
  ) {
    createCourse(version: $version, courseDesc: $courseDesc) {
      id
      ...SmallCourseFragment
      ...COURSE_TEAM_FRAGMENT @include(if: $enabledContentTeam)
    }
  }
  ${SmallCourseFragment}
  ${COURSE_TEAM_FRAGMENT}
`;

export const deleteCourse = gql`
  mutation deleteCourse($version: COURSE_VERSION!, $courseId: ID!) {
    deleteCourse(version: $version, courseId: $courseId)
  }
`;

export const duplicateCourse = gql`
  mutation duplicateCourse($version: COURSE_VERSION!, $courseId: ID!) {
    duplicateCourse(version: $version, courseId: $courseId)
  }
`;

export const editCourse = gql`
  mutation updateCourseDescription(
    $version: COURSE_VERSION!
    $courseId: ID!
    $courseDesc: EditCourseInput!
    $enabledContentTeam: Boolean = false
  ) {
    updateCourseDescription(version: $version, courseId: $courseId, courseDesc: $courseDesc) {
      id
      ...MediumCourseFragment
      ...COURSE_TEAM_FRAGMENT @include(if: $enabledContentTeam)
    }
  }
  ${MediumCourseFragment}
  ${COURSE_TEAM_FRAGMENT}
`;

export const deleteSection = gql`
  mutation deleteSection($version: COURSE_VERSION!, $id: ID!) {
    deleteCourseSection(version: $version, id: $id)
  }
`;

export const deleteSubSection = gql`
  mutation deleteSubSection($version: COURSE_VERSION!, $id: ID!) {
    deleteCourseSubsection(version: $version, id: $id)
  }
`;

export const updateSection = gql`
  mutation updateSection(
    $version: COURSE_VERSION!
    $id: ID!
    $sectionData: CourseSectionEditInput!
  ) {
    updateCourseSection(version: $version, id: $id, sectionData: $sectionData) {
      id
      ...CourseItemMetaFragment
    }
  }
  ${CourseItemMetaFragment}
`;

export const updateSubSection = gql`
  mutation updateSubSection(
    $version: COURSE_VERSION!
    $id: ID!
    $subsectionData: CourseSubsectionEditInput!
  ) {
    updateCourseSubsection(version: $version, id: $id, subsectionData: $subsectionData) {
      id
      ...CourseItemMetaFragment
      contentType
      canPreview
    }
  }
  ${CourseItemMetaFragment}
`;

export const reorderCourseItems = gql`
  mutation reorderCourseItems(
    $version: COURSE_VERSION!
    $courseId: ID!
    $courseItems: [CourseItemInput]!
  ) {
    reorderCourseItems(version: $version, courseId: $courseId, courseItems: $courseItems) {
      id
    }
  }
`;

// courseItem is section or subsection
export const deleteCourseItems = gql`
  mutation deleteCourseItems(
    $version: COURSE_VERSION!
    $courseId: ID!
    $courseItems: [CourseItemInput]!
  ) {
    deleteCourseItems(version: $version, courseId: $courseId, courseItems: $courseItems) {
      id
    }
  }
`;

export const publishCourse = gql`
  mutation publishCourse($version: COURSE_VERSION!, $courseId: ID!) {
    publishCourse(version: $version, courseId: $courseId) {
      id
      ...SmallCourseFragment
    }
  }
  ${SmallCourseFragment}
`;

export const generateCourseImageUploadUrl = gql`
  mutation generateCourseImageUploadUrl(
    $courseId: ID!
    $imageFileName: String!
    $imageContentType: String!
  ) {
    generateCourseImageUploadUrl(
      courseId: $courseId
      imageFileName: $imageFileName
      imageContentType: $imageContentType
    ) {
      url
    }
  }
`;

export const generateCourseAssetSignedUrls = gql`
  mutation generateCourseAssetSignedUrls(
    $assetList: [StorageObjectInput!]!
    $filename: String
    $downloadable: Boolean
  ) {
    generateCourseAssetSignedUrls(
      assetList: $assetList
      filename: $filename
      downloadable: $downloadable
    ) {
      code
      success
      message
      urls {
        key
        signedUrl
      }
    }
  }
`;

export const gradeUserAssignment = gql`
  mutation gradeUserAssignment($id: ID!, $gradeData: GradeUserAssignmentInput!) {
    gradeUserAssignment(userAssignmentId: $id, gradeUserAssignmentData: $gradeData) {
      ...UserAssignmentFragment
    }
  }
  ${UserAssignmentFragment}
`;
