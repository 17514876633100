import React, { useState } from 'react';
import { Input, Modal, Button, Icon, Header } from 'semantic-ui-react';
import { VideoPlayer } from '@skooldio/skooldio-video-player';

import withFormsy from 'Components/Form/withFormsy';
import { Box, Flex } from 'Components/Base';

const getVideo = (type, id) => {
  switch (type) {
    //case VIDEO_TYPE.BYTEARK:
    case VIDEO_TYPE.YOUTUBE:
    default:
      return { src: `https://www.youtube.com/embed/${id}`, type: 'video/youtube' };
  }
};

export const VideoPlayerPreviewInput = ({ value, onChange, type, ...inputProps }) => {
  const [videoId, setVideoId] = useState(value);
  const [isPreview, setPreview] = useState(false);

  const { DEFAULT_VIDEO_OPTIONS } = VideoPlayer || {};
  const { controlBar } = DEFAULT_VIDEO_OPTIONS || {};
  const videoOptions = {
    aspectRatio: '16:9',
    controlBar: { children: [...controlBar.children, 'fullscreenToggle'] },
  };
  const handleChangeVideoId = (e) => {
    setVideoId(e.target.value);
    onChange({}, { value: e.target.value });
  };
  return (
    <Box>
      <Input
        action={
          <Button onClick={() => setPreview(true)} type="button">
            <Flex alignItems="center">
              <Box mr={2}>Preview</Box>
              <Icon name="eye" />
            </Flex>
          </Button>
        }
        value={videoId}
        onChange={handleChangeVideoId}
        placeholder="YouTube Video ID eg. s8QGGe3hMxU"
      />
      <Modal open={isPreview} onClose={() => setPreview(false)} basic size="small">
        <Header icon="browser" content="Video Preview" />
        <Modal.Content>
          <VideoPlayer src={getVideo(type, videoId)} videoOptions={videoOptions} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => setPreview(false)} inverted>
            <Icon name="close" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Box>
  );
};

export const VIDEO_TYPE = {
  //BYTEARK: 'BYTEARK',
  YOUTUBE: 'YOUTUBE',
};
export default withFormsy(VideoPlayerPreviewInput);
