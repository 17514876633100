import gql from 'graphql-tag';

import {
  RoleDataFragment,
  UserDetailSmallFragment,
  MutationResponseFragment,
} from '../query/Users.query';

export const createUserMutation = gql`
  mutation createUser($newUser: NewUserInput!) {
    createUser(newUser: $newUser) {
      id
      email
    }
  }
`;

export const genUpdateUserInput = (firstname, lastname, email, organizationId, referenceId) => ({
  firstname,
  lastname,
  email,
  organizationId,
  referenceId,
});

export const updateUserProfileMutation = gql`
  mutation updateUserProfile($userId: ID!, $updateUser: UserInput!) {
    updateUserProfile(userId: $userId, updateUser: $updateUser) {
      id
      ...UserDetailSmallFragment
    }
  }
  ${UserDetailSmallFragment}
`;

export const assignRolesMutation = gql`
  mutation assignRoles($userId: ID!, $roles: [RoleInput]!) {
    assignRoles(userId: $userId, roles: $roles) {
      id
      roles
      rolesData {
        id
        ...RoleDataFragment
      }
    }
  }
  ${RoleDataFragment}
`;

export const verifyEmail = gql`
  mutation verifyEmail($userId: ID!) {
    verifyEmail(userId: $userId) {
      id
      email
      emailVerified
    }
  }
`;

export const disableStatus = gql`
  mutation disableStatus($userId: ID!, $isDisabled: Boolean!) {
    disableStatus(userId: $userId, isDisabled: $isDisabled) {
      ...MutationResponseFragment
      user {
        id
        isDisabled
      }
    }
  }
  ${MutationResponseFragment}
`;

export const manualVerifyEmail = gql`
  mutation manualVerifyEmail($userId: ID!) {
    manualVerifyEmail(userId: $userId) {
      ...MutationResponseFragment
      user {
        id
        email
        emailVerified
      }
    }
  }
  ${MutationResponseFragment}
`;

export const createOrganizationMutation = gql`
  mutation createOrganization($organizationData: OrganizationNewInput!) {
    createOrganization(organizationData: $organizationData) {
      id
      organizationName
      domainName
      active
    }
  }
`;

export const updateOrganizationMutation = gql`
  mutation updateOrganization($id: ID!, $organizationData: OrganizationEditInput!) {
    updateOrganization(id: $id, organizationData: $organizationData) {
      id
      organizationName
      domainName
      active
    }
  }
`;

export const deleteOrganizationMutation = gql`
  mutation deleteOrganization($id: ID!) {
    deleteOrganization(id: $id)
  }
`;

export const evaluateUserCertificates = gql`
  mutation evaluateUserCertificates($userId: ID!) {
    evaluateUserCertificates(userId: $userId) {
      code
      success
      message
    }
  }
`;

export const createUserFileImportJobs = gql`
  mutation createUserFileImportJobs($fileImportJobData: [UserFileImportJobCreateInput]!) {
    createUserFileImportJobs(fileImportJobData: $fileImportJobData) {
      code
      success
      message
      fileImportJobs {
        id
        refCode
        provider
        container
        name
        originalFilename
        type
        status
        detail {
          error
        }
      }
    }
  }
`;

export const UNASSIGN_TEAM = gql`
  mutation unassignTeamFromUser($userId: ID!, $teamId: ID!) {
    unassignTeamFromUser(userId: $userId, teamId: $teamId) {
      id
      name
      description
    }
  }
`;

export const deleteUserCertificate = gql`
  mutation deleteUserCertificate($userId: String!, $userCertificateId: String!) {
    deleteUserCertificate(userId: $userId, userCertificateId: $userCertificateId) {
      id
      studentId
      certificate {
        id
        name
        code
        active
        type
        validTimePeriodInDays
        examTimeLimitInDays
        revision
      }
      user {
        id
        firstname
        lastname
        username
        email
      }
      certificateKeyPath
      issuedAt
      expiredAt
      isExpired
      certificateUrl
    }
  }
`;
