import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Divider, Container, Icon, Tab } from 'semantic-ui-react';
import { defaultProps } from 'recompose';

import APP_PERMISSION from 'Util/permissions/permissionKeys';
import Menu from 'Containers/Menu';
import { GenRoute } from 'Util/Route';

import SKUHeader from '../../Components/SKUHeader';
import { CollectionDetailsEditor, CollectionSKUsEditor } from '../../Components/CollectionEditor';

const genMenu = (matchUrl) => {
  const menus = [
    {
      path: `${matchUrl}/overview`,
      title: 'Overview',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.COLLECTION.OVERVIEW.VIEW,
      ],
      route: CollectionDetailsEditor,
    },
    {
      path: `${matchUrl}/skus`,
      title: 'SKUs',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.COLLECTION.ITEMS.VIEW,
      ],
      route: CollectionSKUsEditor,
    },
  ];

  return menus;
};
const CollectionDetails = withTheme((props) => {
  const { collection, match, theme } = props;
  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: theme.colors.primary,
        }}
      >
        <Container>
          <SKUHeader {...collection} />
        </Container>
      </Container>
      <Divider hidden />
      <Menu menus={genMenu(match.url)} menuProps={{ secondary: true, pointing: true }} />
      <GenRoute menus={genMenu(match.path)} collection={collection} />
    </Fragment>
  );
});

export default CollectionDetails;
