import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  List,
  Button as SButton,
  Divider,
  Header,
  Message,
  Icon,
  Segment,
} from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import { UserSearchModal } from 'Containers/UserSearch2';
import Loading from 'Components/Loading';
import { SemanticButton as Button } from 'Components/Base/Button';

const ListContentUpper = styled(List.Content)`
  &.content {
    text-transform: capitalize;
  }
`;

const getDisplayName = (firstname = '', lastname = '') =>
  `${firstname ? firstname : '<No Firstname>'} ${lastname ? lastname : '<No Lastname>'}`;

const Toolbar = ({ title, onAddUserClick, loading }) => (
  <Flex justifyContent="space-between" alignItems="baseline">
    <Header size="small">{title}</Header>
    <SButton.Group>
      <UserSearchModal
        onUserSelected={(user) => onAddUserClick(user.id)}
        trigger={
          <Button icon compact primary disabled={loading} loading={loading}>
            <Icon name="add user" />
          </Button>
        }
      />
    </SButton.Group>
  </Flex>
);

const UsersList = ({ header, onRemoveUser, users, emptyMessage, onAddUser, loading }) => (
  <Segment.Group>
    <Segment>
      <Toolbar title={header} onAddUserClick={onAddUser} loading={loading} />
    </Segment>
    <Segment>
      <List verticalAlign="middle">
        {loading && <Loading />}
        {!loading && users.length === 0 && <Message size="tiny">{emptyMessage}</Message>}
        {!loading &&
          users.map((user) => (
            <List.Item key={user.id}>
              <List.Content floated="right">
                <SButton.Group>
                  <Button
                    compact
                    basic
                    onClick={() => {
                      onRemoveUser(user.userId);
                    }}
                    icon
                  >
                    <Icon name="trash alternate outline" color="red" />
                  </Button>
                </SButton.Group>
              </List.Content>
              <List.Icon name="user" />
              <ListContentUpper>
                {getDisplayName(user.user.firstname, user.user.lastname)}
              </ListContentUpper>
            </List.Item>
          ))}
      </List>
    </Segment>
  </Segment.Group>
);

UsersList.defaultProps = {
  onAddUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  header: PropTypes.string,
  buttonText: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    })
  ),
  emptyMessage: PropTypes.string,
};

UsersList.defaultProps = {
  onAddUser: () => {},
  onRemoveUser: () => {},
  header: 'User List',
  emptyMessage: 'No Data',
  users: [],
};

export default UsersList;
