import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

import { Flex, ButtonOutlined } from 'Components/Base';

const AssignRoleButton = ({ roleId, userId, ...props }) => {
  return (
    <>
      <ButtonOutlined
        color="primary"
        style={{ cursor: 'pointer' }}
        px={3}
        py={2}
        fontWeight="600"
        fontSize="14px"
        {...props}
      >
        <Flex alignItems="center">
          <AddIcon style={{ width: '16px' }} />
          <span>Assign</span>
        </Flex>
      </ButtonOutlined>
    </>
  );
};

AssignRoleButton.propTypes = {
  roleId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default AssignRoleButton;
