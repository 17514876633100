import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { useSearchFilter } from '../../Utils';
import { GET_ALL_TEAMS } from '../../Graphql/query/Team.query';

const BASIC_COLUMN = {
  ID: {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 150,
  },
  TEAM_NAME: {
    Header: 'Team Name',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    width: 250,
    isSearchAble: true,
  },
  DESCRIPTION: {
    Header: 'Description',
    accessor: 'description',
    type: COLUMN_TYPE.STRING,
  },
  // USER: {
  //   Header: 'User',
  //   accessor: 'user',
  //   width: 80,
  //   type: COLUMN_TYPE.NUMBER,
  // },
};

const SearchTeam = React.forwardRef(({ columns, resolveData }, ref) => {
  const {
    onSortedChange,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    search,
    order,
    paging,
  } = useSearchFilter();
  const getTeamsData = useCallback(resolveData, [resolveData]);
  return (
    <QueryTable
      ref={ref}
      type={'teams'}
      columns={columns}
      query={GET_ALL_TEAMS}
      resolveData={getTeamsData}
      search={search}
      onSearchChange={onSearchChange}
      paging={paging}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      order={order}
      onSortedChange={onSortedChange}
    />
  );
});

const DEFAULT_TEAM_COLUMNS = Object.values(BASIC_COLUMN);

SearchTeam.displayName = 'SearchTeam';

SearchTeam.propTypes = {
  columns: PropTypes.object,
  resolveData: PropTypes.func,
};

SearchTeam.defaultProps = {
  columns: DEFAULT_TEAM_COLUMNS,
};

export { BASIC_COLUMN, DEFAULT_TEAM_COLUMNS };
export default SearchTeam;
