import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';

import withAppConfig from 'Util/hoc/withAppConfig';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';
import ErrorView from 'Components/ErrorView';
import { Box } from 'Components/Base';
import { COLOR } from 'Components/Theme';
import FormGenerator from 'Components/Form/FormGenerator';
import delve from 'Util/Delve';

import {
  EXAM_GENERATOR_TYPE,
  EXAM_QUESTION_STATUS,
  EXAM_SCORE_TYPE,
  EXAM_STATUS,
  EXAM_TAG_CATEGORY,
  TAG_CATEGORY_DICT,
  EXAM_TYPE_TAG,
} from '../Components/constants';
import QuestionListView from '../Components/QuestionListView';
import CourseCodeInput from '../Components/CourseCodeInput';
import SubtopicInput from '../Components/SubtopicInput';
import LogicListInput from '../Components/LogicListInput';
import FullScoreInput from '../Components/FullScoreInput';
import TimeLimitInput from '../Components/TimeLimitInput';
import PassingCriteriaInput from '../Components/PassingCriteriaInput';
import ResultOptionsInput from '../Components/ResultOptionsInput';
import ExamHeader from '../Containers/ExamHeader';

import { secondsToTime } from './DateTime';
import ExamTeamSelectorInput from './ExamTeamSelectorInput';
import QuestionOrder from './QuestionOrder';

/**
 * Generate a formatted text for dropdown's tag option.
 * @param {string} name Tag name.
 * @param {bool} isDeleted If true, add "(Delete)" before tag name.
 */
const getTagOptionFormattedText = (name, isDeleted) => {
  return isDeleted ? `(Deleted) ${name}` : name;
};

const mapTagsToOptions = (tags, examTags = []) => {
  const options = {};
  Object.values(EXAM_TAG_CATEGORY).forEach((tagCategory) => {
    const currentKey = TAG_CATEGORY_DICT[tagCategory];

    const tagsByCategory = tags.filter(({ category }) => category === tagCategory);
    const examTagsByCategory = examTags.filter(({ category }) => category === tagCategory);

    const tagIdByCategorySet = new Set(tagsByCategory.map((tag) => tag.id));

    const deletedTagsByCategory = examTagsByCategory
      .filter((examTag) => !tagIdByCategorySet.has(examTag.id))
      .map((deletedTag) => ({ ...deletedTag, isDeleted: true }));

    const allTagsByCategory = [...tagsByCategory, ...deletedTagsByCategory];

    options[currentKey] = allTagsByCategory.map(({ id, slug, name, isDeleted = false }) => {
      return {
        key: id,
        value: slug,
        text: getTagOptionFormattedText(name, isDeleted),
      };
    });
  });
  return options;
};

const generateFormFields = (tags, examItemSubtopics, options) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Owner',
      inputName: 'owner',
      inputProps: {
        search: true,
      },
      options: tags.owner || [],
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'ผู้รับผิดชอบ',
      inputName: 'assignee',
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'ชื่อชุดคำถาม',
      inputName: 'title',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'ประเภท',
      inputName: 'type',
      options: tags.examType ?? [],
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Course Code',
      inputName: 'courseCode',
      customInput: (props) => <CourseCodeInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'เรื่อง',
      inputName: 'subtopic',
      inputProps: {
        options: examItemSubtopics,
      },
      customInput: (props) => <SubtopicInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'คะแนนเต็ม',
      inputName: 'fullScore',
      customInput: (props) => <FullScoreInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'เกณฑ์ผ่าน',
      inputName: 'passingCriteria',
      inputProps: {
        typeOptions: [
          { key: EXAM_SCORE_TYPE.SCORE, value: EXAM_SCORE_TYPE.SCORE, text: 'คะแนน' },
          {
            key: EXAM_SCORE_TYPE.PERCENT,
            value: EXAM_SCORE_TYPE.PERCENT,
            text: '%',
          },
        ],
      },
      customInput: (props) => <PassingCriteriaInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'เวลา',
      inputName: 'timeLimit',
      customInput: (props) => <TimeLimitInput {...props} />,
      inputProps: {
        fieldProps: {
          style: { alignItems: 'center' },
        },
      },
    },
  ];

  const { enabledContentTeam, currentData, examConfig } = options;
  if (examConfig.allowGeneratorTypeByUser) {
    const generatorTypeOptions = [
      {
        key: EXAM_GENERATOR_TYPE.RANDOM,
        value: EXAM_GENERATOR_TYPE.RANDOM,
        text: 'สุ่มตามเงื่อนไข',
      },
    ];
    if (examConfig.enabledFixedType) {
      generatorTypeOptions.push({
        key: EXAM_GENERATOR_TYPE.FIXED,
        value: EXAM_GENERATOR_TYPE.FIXED,
        text: 'เรียงตามลำดับ',
      });
    }
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'การจัดเรียงคำถาม',
      inputName: 'generatorType',
      options: generatorTypeOptions,
      inputProps: { required: true },
    });
  }

  if (enabledContentTeam) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Team',
      inputName: 'teamIds',
      customInput: () => <ExamTeamSelectorInput initialData={currentData} />,
      inputProps: {
        required: true,
      },
    });
  }

  if (
    examConfig.enabledOfficialExam &&
    currentData.type &&
    currentData.type.toLowerCase() === EXAM_TYPE_TAG.OFFICIAL_EXAM.toLowerCase()
  ) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'เฉลย และผลสอบ',
      inputName: 'resultOptions',
      inputDescription: 'สำหรับเปิด/ปิด และกำหนดวัน, เวลาสำหรับการแสดงเฉลย/ผลสอบ',
      customInput: (props) => <ResultOptionsInput {...props} />,
      inputProps: {
        validationErrors: {
          isDefaultRequiredValue: `Time is required`,
        },
        required: true,
      },
    });
  }

  return fields;
};

const logicField = (logicOptions) => [
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Condition',
    inputName: 'logics',
    inputProps: {
      logicOptions: logicOptions,
    },
    customInput: (props) => <LogicListInput {...props} />,
  },
];

const MutationView = ({ error, loading, data, keyPath }) => {
  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      <CheckMutationResponse
        checkOnlyDefined={false}
        response={delve(data, keyPath)}
        loading={loading}
        error={error}
        notificationTime={2000}
      />
    </Fragment>
  );
};

const getExamItemSubtopics = (examItems) => {
  return Array.from(
    new Set(
      examItems
        .filter(({ tags }) => tags !== null)
        .flatMap(({ tags }) => {
          return tags
            .filter(({ category }) => category === EXAM_TAG_CATEGORY.SUBTOPIC)
            .map(({ slug }) => slug);
        })
    )
  );
};

const handleCustomValidation = (allowTeam) => (formValue, isChanged) => {
  const error = {};
  const { teamIds } = formValue;

  if (isChanged) {
    if (allowTeam && (!Array.isArray(teamIds) || teamIds.length === 0)) {
      error.teamIds = `Should has at least 1 team`;
    }
  }
  return error;
};

const ExamForm = ({
  initialData: exam = {},
  isEdit,
  onSubmit,
  onSubmitLogic,
  onPublish,
  onCancelled,
  logicMutationResponse: { logicLoading, logicError, logicData },
  appConfig,
  tags,
  ...props
}) => {
  const status = exam?.status ?? EXAM_STATUS.DRAFT;
  const examItems = exam?.examItems
    ? [...exam.examItems].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    : [];

  const publishedExamItems = examItems.filter(
    (item) => item.status === EXAM_QUESTION_STATUS.PUBLISHED
  );
  const questionOrder = exam?.questionOrder ?? [];
  const logicOptions = exam?.logicOptions ?? {};
  const examGeneratorType = exam?.generatorType;
  const examItemSubtopics = examItems && getExamItemSubtopics(examItems);

  const [fields, setFields] = useState([]);

  useEffect(() => {
    setFields(
      generateFormFields(mapTagsToOptions(tags, exam.tags), examItemSubtopics, {
        enabledContentTeam: appConfig.enabledContentTeam,
        examConfig: appConfig?.exam ?? {},
        currentData: exam,
      })
    );
  }, [tags, exam, appConfig]);

  const handleSubmitBasicInfo = (formValue) => {
    onSubmit({ ...formValue });
  };

  const handleSubmitLogic = (formValue) => {
    onSubmitLogic({ ...formValue });
  };

  const handleChange = (formValue, isChanged) => {
    if (isChanged) {
      setFields(
        generateFormFields(mapTagsToOptions(tags, exam.tags), examItemSubtopics, {
          enabledContentTeam: appConfig.enabledContentTeam,
          examConfig: appConfig?.exam ?? {},
          currentData: { ...formValue },
        })
      );
    }
  };

  return (
    <Box p={2}>
      {isEdit && <ExamHeader examId={exam.id} />}
      <Box p={4} bg={COLOR.greyscale.grey5} css={{ borderRadius: '5px' }}>
        <FormGenerator
          {...props}
          initialData={exam}
          fields={fields}
          onChange={handleChange}
          customValidation={handleCustomValidation(appConfig.enabledContentTeam)}
          isRequiredConfirmation
          compact={!isEdit}
          submitText={'Save Basic Info'}
          submitContent={`This Exam information will be saved.`}
          onSubmit={handleSubmitBasicInfo}
          onCancelled={onCancelled}
          showCancel={!isEdit}
          dividers={[{ key: 'resultOptions', text: 'สำหรับ Official Exam' }]}
        />
      </Box>
      {isEdit && (
        <Fragment>
          <Box p={4}>
            <QuestionListView
              examItems={examItems}
              examId={exam.id}
              assignee={exam.assignee}
              owner={exam.owner}
              time={exam.timeLimit ? secondsToTime(exam.timeLimit) : null}
              hideAddQuestion={
                examGeneratorType === EXAM_GENERATOR_TYPE.FIXED && status === EXAM_STATUS.PUBLISHED
              }
              {...props}
            />
          </Box>
          {examGeneratorType === EXAM_GENERATOR_TYPE.FIXED && (
            <Box p={4} bg={COLOR.greyscale.grey5} css={{ borderRadius: '5px' }}>
              <QuestionOrder
                examItemsOrder={questionOrder}
                examItems={uniq([
                  ...questionOrder
                    .map((id) => publishedExamItems.find((question) => id === question.questionId))
                    .filter((question) => question),
                  ...publishedExamItems,
                ])}
                examId={exam.id}
                disabled={status === EXAM_STATUS.PUBLISHED}
              />
            </Box>
          )}
          {examGeneratorType !== EXAM_GENERATOR_TYPE.FIXED && (
            <Box p={4} bg={COLOR.greyscale.grey5} css={{ borderRadius: '5px' }}>
              <MutationView
                keyPath={`updateExamExamRandomRules`}
                error={logicError}
                loading={logicLoading}
                data={logicData}
              />
              <FormGenerator
                {...props}
                withDebounce
                initialData={exam}
                fields={logicField(logicOptions)}
                isRequiredConfirmation
                submitText={'Save conditions'}
                submitContent={`This conditions will be saved.`}
                onSubmit={handleSubmitLogic}
                showCancel={!isEdit}
                loading={logicLoading}
              />
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  );
};

ExamForm.propTypes = {
  initialData: PropTypes.object,
  logicMutationResponse: PropTypes.shape({
    logicError: PropTypes.object,
    logicData: PropTypes.object,
    logicLoading: PropTypes.bool,
  }),
  publishMutationResponse: PropTypes.shape({
    publishError: PropTypes.object,
    publishData: PropTypes.object,
    publishLoading: PropTypes.bool,
  }),
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSubmitLogic: PropTypes.func,
  onPublish: PropTypes.func,
  onCancelled: PropTypes.func,
  tags: PropTypes.array,
};

ExamForm.defaultProps = {
  initialData: {},
  logicMutationResponse: { logicData: {}, logicError: null, logicLoading: false },
  publishMutationResponse: { publishError: null, publishData: null, publishLoading: false },
  isEdit: false,
  onSubmit: () => {},
  onSubmitLogic: () => {},
  onPublish: () => {},
  onCancelled: () => {},
  tags: [],
};

export default withAppConfig({ configProp: 'appConfig' })(ExamForm);
