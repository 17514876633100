import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import ReactDragList from 'react-drag-list';
import { Button as SButton, Icon, Input } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';
import { useWireSync } from 'src/Util/WireSync';

import { SemanticButton as Button } from '../../Base/Button';

import { SlideImage } from './Components';

const getFileName = ({ key }) => {
  if (!key) return 'No File Name';
  const splittedUrl = key.split('/');
  const fileName = splittedUrl[splittedUrl.length - 1];
  return fileName;
};

const imageItemStyle = {
  boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
  borderRadius: '8px',
};

const ImageItem = withTheme(
  ({
    theme: { colors },
    fileAsset,
    url,
    onClick,
    showSnap,
    handleTimeChanged,
    handleSortList,
    isSlideActive,
    ...props
  }) => {
    const borderStyle =
      isSlideActive && showSnap ? `2px solid ${colors?.primary}` : '1px solid rgba(34,36,38,.15)';
    return (
      <Box width={1} p={2} style={{ border: borderStyle, ...imageItemStyle }} {...props}>
        <SlideImage src={url} />
        <Box py={2} style={{ overflow: 'auto', textAlign: 'center' }}>
          {getFileName(fileAsset)}
        </Box>
        <Flex alignItems="stretch" justifyContent="center">
          {showSnap && (
            <Button icon compact basic onClick={() => handleTimeChanged(null, true)}>
              <Flex alignItems="baseline">
                <Icon name="clock outline" style={{ paddingRight: '4px' }} />
                Mark Time
              </Flex>
            </Button>
          )}
          <Input
            style={{ width: '90px' }}
            type="number"
            value={fileAsset?.time?.toString()}
            placeholder="Time (second)"
            onChange={(_event, { value }) => handleTimeChanged(value)}
            onBlur={handleSortList}
            step="0.1"
          />
          <SButton.Group>
            <Button icon compact basic onClick={onClick}>
              <Icon name="trash alternate outline" color="red" />
            </Button>
          </SButton.Group>
        </Flex>
      </Box>
    );
  }
);

const DragableList = ({ syncKey, showActiveSlide, disabledDragList, onChange, initialValue }) => {
  const { role, value, setValue, reset, node } = useWireSync(syncKey);
  const handleUpdate = (evt, updated) => {
    onChange([...updated]);
  };

  const handleTimeChanged = (time, index, sort) => {
    // allow number 5 digit and 1 decimal place
    const regExp = new RegExp(`^[0-9]{0,4}(\.[0-9]{1})?$`);
    if (regExp.test(time) && time < 10000) {
      const updatedData = [...initialValue];
      updatedData[index] = { ...updatedData[index], time: time };
      onChange(sort ? handleSortList(updatedData) : updatedData);
    }
  };

  const handleSortList = (slideList = initialValue) => {
    const updatedData = [...slideList];
    updatedData.sort((a, b) => a.time - b.time);
    return updatedData;
  };

  const handleRemoveVideoSlide = (index) => {
    console.log('handle video slides remove', index);
    const updatedData = [...initialValue];
    onChange(
      updatedData.filter((_, idx) => {
        return idx !== index;
      })
    );
  };

  return (
    <Fragment>
      {initialValue.length === 0 && <Box>No Slides.</Box>}
      <ReactDragList
        dataSource={initialValue}
        rowKey="key"
        disabled={disabledDragList}
        row={(record, index) => {
          const lastIndex = initialValue.length - 1;
          const nextRecord = index !== lastIndex ? initialValue[index + 1] : {};
          const isSlideActive =
            (value >= record?.time && value <= nextRecord?.time) ||
            (value >= record?.time && index === lastIndex);
          return (
            <ImageItem
              mb={index !== lastIndex ? 2 : 0}
              isSlideActive={isSlideActive}
              fileAsset={record}
              url={record.url}
              showSnap={showActiveSlide}
              handleSortList={() => onChange(handleSortList())}
              handleTimeChanged={(inputValue, sort) => {
                const currentValue = value ? parseFloat(value).toFixed(1) : 0;
                handleTimeChanged(inputValue ?? currentValue, index, sort);
              }}
              onClick={() => handleRemoveVideoSlide(index)}
            />
          );
        }}
        handles={true}
        className="simple-drag"
        rowClassName="simple-drag-row"
        onUpdate={handleUpdate}
      />
    </Fragment>
  );
};

DragableList.propTypes = {
  initialValue: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  disabledDragList: PropTypes.bool,
};
DragableList.defaultProps = {
  initialValue: [],
  onChange: () => {},
};
export default DragableList;
