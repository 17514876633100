import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import withFormsy from 'Components/Form/withFormsy';

const PLATFORM = {
  WINDOWS: 'WINDOWS',
  MAC: 'MAC',
  LINUX: 'LINUX',
  ANDROID: 'ANDROID',
  IOS: 'IOS',
};

const PLATFORM_GROUP = {
  PC_ONLY: [PLATFORM.WINDOWS, PLATFORM.LINUX, PLATFORM.MAC],
  ALL_PLATFORMS: [PLATFORM.WINDOWS, PLATFORM.LINUX, PLATFORM.MAC, PLATFORM.ANDROID, PLATFORM.IOS],
};

const PopupByPlatform = ({ value: platforms, onChange, readOnly }) => {
  const hasPlatforms = platforms && platforms.length > 0;
  const isEqualLength = PLATFORM_GROUP.PC_ONLY.length === platforms?.length;
  const platformIsPCOnly =
    hasPlatforms && isEqualLength
      ? PLATFORM_GROUP.PC_ONLY.every((platform) => platforms.includes(platform))
      : false;
  const [isPCOnly, setIsPCOnly] = useState(platformIsPCOnly);

  const updateValueOnChange = useCallback(() => {
    onChange({}, { value: isPCOnly ? PLATFORM_GROUP.PC_ONLY : PLATFORM_GROUP.ALL_PLATFORMS });
  }, [isPCOnly, onChange]);

  useEffect(() => {
    updateValueOnChange();
  }, [updateValueOnChange]);

  return (
    <Checkbox
      checked={isPCOnly}
      onChange={() => setIsPCOnly(!isPCOnly)}
      readOnly={readOnly}
      style={{ paddingTop: 4 }}
    />
  );
};

PopupByPlatform.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(PopupByPlatform);
