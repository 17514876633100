import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

import { Flex } from 'Components/Base';

const CategoryCell = ({ categories }) => (
  <Flex flexWrap="wrap" m={-1}>
    {categories.map((category) => (
      <Flex key={category.id} p={1}>
        <Label>{category.title}</Label>
      </Flex>
    ))}
  </Flex>
);

CategoryCell.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

CategoryCell.defaultProps = {
  categories: [],
};

export default CategoryCell;
