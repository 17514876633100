import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import RTE from 'react-rte';
import { Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';

import { Box, Button, Flex } from 'Components/Base';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import { convertStringToValue, convertValueToString } from 'Components/RichTextEditor';
import ImageUpload from 'Components/RichTextEditor/Components/ImageUpload';

import StatefulModal from '../../../../../Components/Modal/StatefulModal';
import { useCustomAnnouncementTemplate, useRawContent } from '../../../../../Util/Hooks';
import { NotificationContentEditModalActionCard } from '../Components';

const CardType = {
  BLOG: 'BLOG',
  TEXT: 'TEXT',
  RTE: 'RTE',
  IMAGE: 'IMAGE',
};

const Card = styled(Box)`
  margin: 32px 32px 32px 0px;
  padding: 24px 16px;
  border: 1px solid #595959;
  box-sizing: border-box;
  border-radius: 16px;
  background: #fafafa;
`;

const TextArea = styled.textarea`
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 4px;
  resize: none;
  width: 100%;
  height: 2em;
`;

const mapToRawContent = (cards) => {
  const newRawContent = JSON.stringify(
    cards.map((c) => {
      switch (c.type) {
        case CardType.TEXT:
          return {
            type: c.type,
            text: c.text,
          };
        case CardType.BLOG:
          return {
            type: c.type,
            url: c.url,
          };
        case CardType.RTE:
          return {
            type: c.type,
            html: c.html,
          };
        case CardType.IMAGE:
          return {
            type: c.type,
            imageUrl: c.imageUrl,
            link: c.link,
          };
        default:
          return null;
      }
    })
  );
  return newRawContent;
};

const ContentCard = (props) => {
  const { id, onClickUp, onClickDown, onClickDelete, onChange, value, type } = props;
  // setting asset key here to store image in same month in same folder
  const assetKey = `notification-${moment().format('YYYYMM')}`;
  return (
    <Card key={id}>
      <Flex flexDirection="row" justifyContent="space-between">
        <div style={{ fontSize: '20px', fontWeight: '500' }}>
          {type === CardType.TEXT
            ? 'Text'
            : type === CardType.BLOG
            ? 'Blog card'
            : type === CardType.IMAGE
            ? 'Image'
            : 'Rich text editor'}
        </div>
        <div>
          <Icon name="angle up" size="large" onClick={onClickUp} />
          <Icon name="angle down" size="large" onClick={onClickDown} />
          <Icon name="trash alternate" color="red" size="large" onClick={onClickDelete} />
        </div>
      </Flex>
      <Flex flexDirection="row" style={{ marginTop: '12px' }}>
        {type === CardType.TEXT && (
          <TextArea
            placeholder="กรอกคำอธิบาย"
            name="description"
            style={{
              height: '5em',
            }}
            onChange={(e) => onChange(e.target.value)}
            value={value}
          />
        )}
        {type === CardType.BLOG && (
          <TextArea
            placeholder="กรอก Blog URL เช่น https://blog.skooldio.com/product-management-terminology/"
            name="description"
            onChange={(e) => onChange(e.target.value)}
            value={value}
          />
        )}
        {type === CardType.IMAGE && (
          <div style={{ width: '100%' }}>
            <Flex flexDirection="row" alignItems="center" style={{ margin: '5px 0' }}>
              <TextArea
                disabled
                wrap="off"
                placeholder="กดปุ่ม Upload Image เพื่อเลือกรูป"
                name="description"
                style={{ flexGrow: 1, overflow: 'hidden', marginRight: '5px' }}
                onChange={(e) => onChange({ imageUrl: e.target.value })}
                value={value.imageUrl}
              />
              <ImageUpload
                key={'image-upload-button'}
                uploadConfig={{
                  assetType: ASSET_TYPE.ANNOUNCEMENT,
                  assetKey: assetKey,
                }}
                onImageUpload={(data) => {
                  const { url } = data || {};
                  if (url) {
                    onChange({ imageUrl: url });
                  }
                }}
              />
            </Flex>
            <TextArea
              placeholder="กรอก Link ที่จะเปิดเมื่อกดที่รูป เช่น https://blog.skooldio.com/product-management-terminology/"
              name="description"
              onChange={(e) => onChange({ link: e.target.value })}
              value={value.link}
            />
          </div>
        )}
        {type === CardType.RTE && <RTE onChange={onChange} value={value} />}
      </Flex>
    </Card>
  );
};

ContentCard.propTypes = {
  id: PropTypes.number,
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func,
  onClickDelete: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.oneOf([CardType.BLOG, CardType.TEXT, CardType.RTE]),
  value: PropTypes.string,
};

const NotificationContentEditModal = (props) => {
  const { trigger, onChange, rawContent } = props;
  const [cards, setCards] = useState([]);

  const renderCard = (card) => {
    const onClickUp = () => {
      const currentIdx = cards.findIndex((c) => c.id === card.id);
      if (currentIdx > 0) {
        const currentCard = { ...cards[currentIdx] };
        const beforeCard = { ...cards[currentIdx - 1] };
        cards.splice(currentIdx - 1, 2, currentCard, beforeCard);
        setCards([...cards]);
      }
    };
    const onClickDown = () => {
      const currentIdx = cards.findIndex((c) => c.id === card.id);
      if (currentIdx < cards.length - 1) {
        const currentCard = { ...cards[currentIdx] };
        const afterCard = { ...cards[currentIdx + 1] };
        cards.splice(currentIdx, 2, afterCard, currentCard);
        setCards([...cards]);
      }
    };
    const onClickDelete = () => {
      setCards(cards.filter((c) => c.id !== card.id));
    };
    const onChange = (value) => {
      const currentIdx = cards.findIndex((c) => c.id === card.id);
      const newCard = { ...card };
      if (card.type === CardType.BLOG) {
        const slug = useRawContent.getBlogSlugFromUrl(value);
        newCard.url = value;
        newCard.slug = slug || '';
      }
      if (card.type === CardType.TEXT) {
        newCard.text = value;
      }
      if (card.type === CardType.RTE) {
        newCard.value = value;
        newCard.html = convertValueToString(value, 'html');
      }
      if (card.type === CardType.IMAGE) {
        const { imageUrl, link } = value;
        newCard.imageUrl = imageUrl ?? newCard.imageUrl;
        newCard.link = link ?? newCard.link;
      }
      cards.splice(currentIdx, 1, newCard);
      setCards([...cards]);
    };
    const genericProps = { onClickUp, onClickDown, onClickDelete, onChange };
    const { type, id } = card;
    switch (card.type) {
      case CardType.BLOG:
        return ContentCard({ ...genericProps, ...card.prop, value: card.url, type, id });
      case CardType.TEXT:
        return ContentCard({ ...genericProps, ...card.prop, value: card.text, type, id });
      case CardType.RTE:
        return ContentCard({ ...genericProps, ...card.prop, value: card.value, type, id });
      case CardType.IMAGE:
        return ContentCard({
          ...genericProps,
          ...card.prop,
          value: { imageUrl: card.imageUrl, link: card.link },
          type,
          id,
        });
      default:
        return null;
    }
  };

  const onClickAddTextCard = () => {
    const id = +new Date();
    setCards([
      ...cards,
      {
        id,
        type: CardType.TEXT,
        text: '',
      },
    ]);
  };

  const onClickAddBlogCard = () => {
    const id = +new Date();
    setCards([
      ...cards,
      {
        id,
        type: CardType.BLOG,
        url: '',
        slug: '',
      },
    ]);
  };

  const onClickAddRTECard = () => {
    const id = +new Date();
    setCards([
      ...cards,
      {
        id,
        type: CardType.RTE,
        value: RTE.createEmptyValue(),
        html: '',
      },
    ]);
  };

  const onClickAddImageCard = () => {
    const id = +new Date();
    setCards([
      ...cards,
      {
        id,
        type: CardType.IMAGE,
        imageUrl: '',
        link: '',
      },
    ]);
  };

  useRawContent(rawContent, setCards);
  const { htmlTemplate } = useCustomAnnouncementTemplate();
  const htmlString = htmlTemplate ? htmlTemplate({ cards }) : '';
  const newRawContent = mapToRawContent(cards);
  return (
    <StatefulModal trigger={trigger} dimmer="inverted" style={{ width: '1200px' }}>
      {(closeModal) => (
        <Fragment>
          <Modal.Header>Generate announcement content</Modal.Header>
          <Modal.Content>
            <Flex flexDirection="row" style={{ padding: '0 16px', overflow: 'hidden' }}>
              <div
                style={{
                  border: '1px solid #595959',
                  boxSizing: 'border-box',
                  borderRadius: '16px',
                  padding: '32px',
                  flex: 1,
                  overflow: 'hidden',
                }}
              >
                {cards.map(renderCard)}
                <NotificationContentEditModalActionCard
                  onClickAddBlogCard={onClickAddBlogCard}
                  onClickAddTextCard={onClickAddTextCard}
                  onClickAddRTECard={onClickAddRTECard}
                  onClickAddImageCard={onClickAddImageCard}
                />
              </div>
              <iframe
                title="preview"
                srcDoc={htmlString}
                style={{
                  marginLeft: '32px',
                  width: '375px',
                  border: '1px solid #595959',
                  boxSizing: 'border-box',
                  borderRadius: '16px',
                }}
              ></iframe>
            </Flex>
            <Flex justifyContent="flex-end" style={{ paddingTop: '32px' }}>
              <Button
                circular
                primary
                title="Done"
                onClick={() => {
                  onChange({ content: htmlString, rawContent: newRawContent });
                  closeModal();
                }}
              />
            </Flex>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
};

NotificationContentEditModal.propTypes = {
  trigger: PropTypes.element,
  onChange: PropTypes.func,
  rawContent: PropTypes.string,
};

export default NotificationContentEditModal;
