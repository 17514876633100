import gql from 'graphql-tag';

import { UserDeviceTokenFragment } from '../query/DeviceToken.query';

export const deleteUserDeviceTokenMutation = gql`
  mutation deleteUserDeviceToken($deviceTokenId: ID!) {
    deleteUserDeviceToken(deviceTokenId: $deviceTokenId) {
      code
      success
      message
      userDeviceTokens {
        id
        deviceTokens {
          id
          ...UserDeviceTokenFragment
        }
      }
    }
  }
  ${UserDeviceTokenFragment}
`;
