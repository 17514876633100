import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Label, Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { Flex } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const Wrapper = styled(Flex)`
  margin-left: 26px;
`;

const errorLabel = <Label color="red" pointing />;

/**
 * CourseCode = Audience = RelationID
 * @param {string} initValue - audiences | ex. {relationIdList: ["COURSE1","COURSE2","COURSE3"], relationType: 'GLOBAL'}
 */
const CustomConditionInput = (props) => {
  const {
    value,
    checkBoxLabel,
    onChange,
    readOnly,
    name,
    loading,
    dataTestId,
    type,
    checkBoxValue,
    ...inputProps
  } = props;
  const [checked, setChecked] = useState(value === checkBoxValue);
  const [val, setVal] = useState(checked ? undefined : value);

  useEffect(() => {
    if (checked) {
      onChange(
        {},
        {
          value: checkBoxValue,
        }
      );
    } else {
      onChange(
        {},
        {
          value: val,
        }
      );
    }
  }, [checked, val]);

  const handleChange = (_, { value: val }) => {
    setVal(type === 'number' ? Number(val) : val);
  };

  return (
    <>
      <Checkbox
        label={checkBoxLabel}
        checked={checked}
        onChange={() => setChecked(!checked)}
        readOnly={readOnly}
      />
      <Wrapper alignItems="center" mt={3}>
        <Input
          {...inputProps}
          type={type}
          name={name}
          onChange={handleChange}
          required={!checked}
          validationErrors={{
            isDefaultRequiredValue: `${name} is required`,
          }}
          placeholder=""
          errorLabel={errorLabel}
          disabled={loading || readOnly || checked}
          transparent={readOnly}
          data-testid={dataTestId}
          defaultValue={val}
        />
      </Wrapper>
    </>
  );
};

CustomConditionInput.propTypes = {
  value: PropTypes.string,
  checkBoxLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  checkBoxValue: PropTypes.any,
  name: PropTypes.string,
  loading: PropTypes.any,
  dataTestId: PropTypes.string,
  type: PropTypes.oneOf(['string', 'number']),
};

CustomConditionInput.defaultProps = {
  value: undefined,
  onChange: () => {},
  readOnly: false,
  checkBoxValue: undefined,
  type: 'string',
  dataTestId: undefined,
  name: undefined,
  loading: false,
};

export default withFormsy(CustomConditionInput);
