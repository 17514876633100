import React from 'react';
import { Message, Accordion, Icon } from 'semantic-ui-react';

const renderError = (message) => {
  if (typeof message === 'string') return message;
  return JSON.stringify(message);
};

function ErrorView({ message }) {
  const panels = [
    {
      key: `1`,
      title: {
        content: 'Unexpected Error',
      },
      content: {
        content: renderError(message),
      },
    },
  ];
  return (
    <Message negative>
      <Accordion fluid panels={panels}></Accordion>
    </Message>
  );
}

export default ErrorView;
