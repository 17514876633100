import { SKU_CATEGORY, SKU_TYPE, PRODUCT_TYPE, PRODUCT_TYPE_TEXT } from './constants';

const SKU_TYPE_AND_CATEGORY = {
  VIRTUAL_ONLINE_COURSE: `${SKU_TYPE.VIRTUAL} ${SKU_CATEGORY.ONLINE_COURSE}`,
  VIRTUAL_SUBSCRIPTION: `${SKU_TYPE.VIRTUAL} ${SKU_CATEGORY.SUBSCRIPTION}`,
  BUNDLE_COLLECTION: `${SKU_TYPE.BUNDLE} ${SKU_CATEGORY.COLLECTION}`,
  STANDARD_WORKSHOP_BATCH: `${SKU_TYPE.STANDARD} ${SKU_CATEGORY.WORKSHOP_BATCH}`,
};

const resolveProducTypeText = (type, category) => {
  const categoryAndType = `${type} ${category}`;

  switch (categoryAndType) {
    case SKU_TYPE_AND_CATEGORY.VIRTUAL_ONLINE_COURSE:
      return PRODUCT_TYPE_TEXT[PRODUCT_TYPE.ONLINE_COURSE];
    case SKU_TYPE_AND_CATEGORY.VIRTUAL_SUBSCRIPTION:
      return PRODUCT_TYPE_TEXT[PRODUCT_TYPE.SUBSCRIPTION];
    case SKU_TYPE_AND_CATEGORY.BUNDLE_COLLECTION:
      return PRODUCT_TYPE_TEXT[PRODUCT_TYPE.BUNDLE];
    case SKU_TYPE_AND_CATEGORY.STANDARD_WORKSHOP_BATCH:
      return PRODUCT_TYPE_TEXT[PRODUCT_TYPE.WORKSHOP_BATCH];
    default:
      return category;
  }
};

export default resolveProducTypeText;
