import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../../Components/Modal/StatefulModal';
import CreateEditForm from '../../../../Containers/CreateEditForm';
import {
  createOrganizationMutation,
  updateOrganizationMutation,
} from '../../../../GraphQL/mutation/Users.mutation';
import { getOrganizations, getOrganization } from '../../../../GraphQL/query/Users.query';

import OrganizationForm from './OrganizationForm';

export default class OrganizationModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    organizationId: PropTypes.string,
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { isEdit, organizationId, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>
              {isEdit ? 'Edit Organization Information' : 'Create New Organization'}
            </Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getOrganization}
                createMutation={createOrganizationMutation}
                createRefetchQueries={[
                  {
                    query: getOrganizations,
                    variables,
                  },
                ]}
                editMutation={updateOrganizationMutation}
                isEdit={isEdit}
                id={organizationId}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  organizationData: form,
                })}
                getCreateVariables={(form) => ({
                  organizationData: form,
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <OrganizationForm initialData={data && data.organization} onSubmit={onSubmit} />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
