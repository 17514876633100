import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon, Container, Label } from 'semantic-ui-react';
import { compose } from 'recompose';
import { withApollo } from '@apollo/client/react/hoc';
import urlJoin from 'url-join';

import { Flex } from 'Components/Base';
import { searchWorkshops, SKUFields, SKUStatus, SKUText } from 'GraphQL/query/Workshop.query';
import QueryTable from 'GraphQL/util/QueryTable';
import withServerConfig from 'GraphQL/util/withServerConfig';
import withAppConfig from 'src/Util/hoc/withAppConfig';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import WorkshopModal from '../../Containers/WorkshopModal';
import CategoriesSearchSelectorInput from '../../Components/CategoriesSearchSelectorInput';

const workshopColumns = (handleOnDelete, urlPath, workshopUrlPrefix) => {
  return [
    {
      Header: 'Id',
      accessor: 'id',
      width: 50,
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'SKU Code',
      accessor: 'SKUCode',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      width: 200,
    },
    {
      Header: 'Title',
      accessor: 'title',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      Cell: ({ value, original }) => (
        <Link to={{ pathname: `${urlPath}/${original.SKUCode}/details` }}>{value}</Link>
      ),
    },
    {
      Header: 'Category',
      accessor: 'categories',
      width: 200,
      Cell: ({ value }) => {
        return value.map((category, index) => {
          return (
            <Label key={`${category}_${index}`} size="mini">
              {category}
            </Label>
          );
        });
      },
      type: COLUMN_TYPE.CUSTOM,
      CustomSearchComponent: CategoriesSearchSelectorInput,
      isSearchAble: true,
    },
    {
      Header: 'Status',
      accessor: 'SKUStatus',
      type: COLUMN_TYPE.ENUM,
      isSearchAble: true,
      enumOptions: Object.values(SKUStatus).map((skuStatus) => ({
        key: skuStatus,
        value: skuStatus,
        text: SKUText[skuStatus],
      })),
      Cell: ({ value }) => SKUText[value],
      width: 120,
    },
    {
      Header: 'Visibility',
      accessor: 'isVisible',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      Cell: ({ value }) => value.toString(),
      width: 50,
    },
    {
      Header: 'Permalink',
      accessor: 'permalink',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      isSearchAble: false,
      type: COLUMN_TYPE.CUSTOM,
      Cell: ({ value, original }) => {
        const { SKUCode, permalink } = original;
        return (
          <Button.Group>
            <Button basic size="mini" icon compact>
              <Link to={`${urlPath}/${SKUCode}/details`}>
                <Icon name="edit" />
              </Link>
            </Button>

            {/* <ConfirmationButton
              headerText={`Delete Workshop?`}
              //contentText={`SKU "${SKUCode}" will be permanently deleted from the system.`}
              confirmText={'Delete'}
              onConfirm={() => {}}
            >
              <Icon name="trash alternate outline" />
            </ConfirmationButton> */}
            <Button basic size="mini" icon compact>
              <a href={urlJoin(workshopUrlPrefix, permalink)} target="_blank">
                <Icon name="world" />
              </a>
            </Button>
          </Button.Group>
        );
      },
      width: 90,
    },
  ];
};
class AllWorkshopQuery extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: SKUFields.SKUCode, type: 'ASC' },
  };
  static propTypes = {
    data: PropTypes.array,
    urlPath: PropTypes.string,
  };

  static defaultProps = {
    urlPath: '/workshop',
  };

  handleOnDelete = async (courseId) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    // client.mutate({
    //   mutation: deleteCourse,
    //   variables: {
    //     version: 'SKOOLDIO_V2',
    //     courseId: courseId,
    //   },
    //   refetchQueries: [
    //     {
    //       query: getAllCourses,
    //       variables: {
    //         search,
    //         paging,
    //         order,
    //       },
    //     },
    //   ],
    //   fetchPolicy: 'no-cache',
    // });
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };

  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({
      search: {
        ...search,
      },
      paging,
    });
  };

  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };

  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };

  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };

  render() {
    const { search, paging, order } = this.state;
    const { urlPath, appConfig } = this.props;
    const { workshopUrlPrefix } = appConfig;
    return (
      <Container>
        <Flex justifyContent="flex-end" mb={2}>
          <WorkshopModal
            variables={{ search, paging, order }}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                Add Workshop
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type="sfWorkshops"
          columns={workshopColumns(this.handleOnDelete, urlPath, workshopUrlPrefix)}
          query={searchWorkshops}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          resolveData={(data) => {
            return data.sfWorkshops.workshops;
          }}
        />
      </Container>
    );
  }
}

export default compose(
  withApollo,
  withServerConfig(),
  withAppConfig({ configProp: 'appConfig' })
)(AllWorkshopQuery);
