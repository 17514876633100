import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { GenSwitchRoute } from '../../Util/Route';
import SideMenuLayout from '../../Components/SideMenuLayout';

import AllExam from './AllExam';
import AllTag from './AllTag';
import EditExam from './EditExam';

const ExamRouteIndex = () => <Redirect to={{ title: '', pathname: '/exams/search' }} />;

const defaultExam = {
  path: '/exams',
  exact: true,
  roles: [],
  route: ExamRouteIndex,
};

const menus = [
  {
    path: '/exams/search',
    title: 'ExamsList',
    menuName: 'Exams List',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.INSTRUCTOR,
      APP_PERMISSION.EXAM.LIST.VIEW,
      APP_PERMISSION.EXAM_TEAM.LIST.VIEW,
    ],
    route: AllExam,
  },
  {
    path: '/exams/tags/search',
    title: 'TagList',
    menuName: 'Tag List',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.INSTRUCTOR,
      APP_PERMISSION.EXAM.EXAM_TAG.VIEW,
      APP_PERMISSION.EXAM_TEAM.EXAM_TAG.VIEW,
    ],
    route: AllTag,
  },
  {
    path: '/exams/:examId',
    title: 'Exam Detail',
    hidden: true,
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.INSTRUCTOR,
      APP_PERMISSION.EXAM.DETAIL.VIEW,
      APP_PERMISSION.EXAM_TEAM.DETAIL.VIEW,
    ],
    route: EditExam,
  },
];
export default class ExamsRoute extends Component {
  render() {
    return (
      <SideMenuLayout header={'Exams'} menus={menus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute routes={[...menus, defaultExam]} />
      </SideMenuLayout>
    );
  }
}
