import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import ConfirmationButton from 'Components/ConfirmationButton';

import AnnouncementModal from '../Containers/AnnouncementModal';

const ActionCell = ({ handleOnDelete, original }) => {
  const announcementId = original.id;

  return (
    <Flex>
      <Button.Group style={{ flex: 1 }}>
        <AnnouncementModal
          isView
          announcementId={announcementId}
          trigger={
            <Button basic icon>
              <Icon name="eye" />
            </Button>
          }
        />
        <AnnouncementModal
          isEdit
          announcementId={announcementId}
          trigger={
            <Button basic icon>
              <Icon name="edit" />
            </Button>
          }
        />
        <ConfirmationButton
          onConfirm={() => handleOnDelete(announcementId)}
          headerText={`Delete Announcement?`}
          confirmText="Delete"
          contentText="This announcement will be soft deleted from the system."
        >
          <Icon name="trash alternate" color="red" />
        </ConfirmationButton>
      </Button.Group>
    </Flex>
  );
};

ActionCell.propTypes = {
  original: PropTypes.object,
  handleOnDelete: PropTypes.func,
};

export default ActionCell;
