import { getAssetList, getUploadUrl } from 'GraphQL/mutation/Asset.mutation';

export default function useAssetManagement({ client, assetKey, assetType }) {
  const handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { data } = await client.mutate({
      mutation: getUploadUrl,
      variables: {
        type: assetType,
        key: assetKey,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  const handleFileListRequest = async () => {
    const { data } = await client.mutate({
      mutation: getAssetList,
      variables: {
        type: assetType,
        key: assetKey,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  return { handleFileUploadUrlRequest, handleFileListRequest };
}
