import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getCollectionOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateCollectionOnlineCourseInstructorsMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUInstructorForm from '../SKUInstructorForm';

const getInitData = (data) => {
  if (data?.sfBoProductCollectionOnlineCourse?.instructors) {
    return {
      instructorIds: data.sfBoProductCollectionOnlineCourse.instructors.map(
        (instructor) => instructor.id
      ),
    };
  }
  return [];
};

const OnlineCourseInstructorsEditor = ({ onlineCourse = {} }) => {
  const { id, SKUCode } = onlineCourse;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getCollectionOnlineCourse}
        editMutation={updateCollectionOnlineCourseInstructorsMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({
          SKUCode,
        })}
        getEditVariables={(form) => ({
          id,
          instructorIds: form.instructorIds,
        })}
      >
        {({ data, onSubmit }) => {
          return <SKUInstructorForm initialData={getInitData(data)} onSubmit={onSubmit} />;
        }}
      </CreateEditForm>
    </Box>
  );
};

OnlineCourseInstructorsEditor.propTypes = {
  onlineCourse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default OnlineCourseInstructorsEditor;
