import React, { useState, useEffect, useCallback } from 'react';
import { isNil } from 'lodash';
import { Divider, Dropdown, Message } from 'semantic-ui-react';
import { Checkbox, TextField, InputAdornment, CircularProgress, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Autocomplete } from '@material-ui/lab';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import isUrl from 'is-url';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import styled from 'styled-components';

import MuiDatePicker from 'Components/DatePicker';
import { Text, Flex, Box } from 'Components/Base';
import { COLOR, FONT_SIZE } from 'Components/Theme';
import { SemanticButton as Button } from 'Components/Base/Button';
import { COURSE_VERSION } from 'GraphQL/query/Courses.query';
import getConfig from 'Util/Config';

import FileUpload from '../../../../Components/FileUpload';

import { CHECKABLE_FIELDS } from './constants';

const {
  batchEnrollmentTemplateUrl,
  enabledOrganizationBatchEnrollment,
  batchEnrollment,
} = getConfig();

const acceptFileTypes = ['.xlsx', '.csv'];

const notAffiliatedOrganization = {
  key: 'NotAffiliated',
  text: 'Not affiliated with organization',
  value: 'NotAffiliated',
};

const mapOrganizationsDropDown = (organizations = []) => {
  if (!organizations || organizations?.length === 0) return [];
  const organizationDropdown = organizations.map((organization) => {
    return { key: organization?.id, text: organization?.organizationName, value: organization?.id };
  });
  return [notAffiliatedOrganization, ...organizationDropdown];
};

const StyledCheckbox = withStyles({
  root: {
    width: 25,
    height: 25,
    marginRight: 12,
    '&$checked': {
      color: COLOR.primary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const helperStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-contained': {
      position: 'absolute',
      bottom: '-20px',
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const CourseToEnrollComponent = (props) => (
  <Flex width={1} flexDirection="column" py={10}>
    <Text fontWeight="bold" mr="5px" mb="2">
      Courses to Enroll
    </Text>
    <Autocomplete
      id="course-autocomplete"
      multiple
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        if (option.courseVersion === COURSE_VERSION.SKOOLDIO_V1) {
          return `[V1] ${option.title} [${option.meta?.referId}]`;
        }
        return `${option.title} [${option.courseCode}]`;
      }}
      options={props.coursesOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          id="courses-textfield"
          variant="outlined"
          placeholder="Courses"
          error={props.isCoursesToEnrollError}
          helperText={
            props.isCoursesToEnrollError && 'Required minimum 1 course is selected to enroll'
          }
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <>
                {props.loading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={20} color="inherit" />
                  </InputAdornment>
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
        />
      )}
      onOpen={() => props.setOpen(true)}
      onChange={props.handleCoursesToEnrollChange}
    />
  </Flex>
);

const SubscriptionToEnrollComponent = (props) => (
  <Flex width={1} flexDirection="column" py={10}>
    <Text fontWeight="bold" mr="5px" mb="2">
      Subscription to Enroll
    </Text>
    <Autocomplete
      id="subscription-autocomplete"
      getOptionLabel={(option) => {
        return `${option.title} [${option.SKUCode}]`;
      }}
      options={props.subscriptionsOption}
      renderInput={(params) => (
        <TextField
          id="subscription-textfield"
          {...params}
          variant="outlined"
          placeholder="Subscription"
          error={props.isSubscriptionToEnrollError}
          helperText={
            props.isSubscriptionToEnrollError &&
            'Required minimum 1 Subscription is selected to enroll'
          }
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <>
                {props.loading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={20} color="inherit" style={{ display: 'block' }} />
                  </InputAdornment>
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
        />
      )}
      onOpen={() => props.setOpen(true)}
      onChange={props.handleSubscriptionToEnrollChange}
    />
  </Flex>
);

function checkRefCodeError(refCode, validationError) {
  const {
    refCodeRequired,
    refCodeTextRequired,
    refCodeLength,
    refCodeValidations,
  } = validationError;
  return refCodeRequired || refCodeTextRequired || refCodeLength || refCodeValidations;
}

const EnrollmentInputWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    margin: 28px 0px;
  }
`;

const InputWithCheckboxWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
`;

const StyledEnrollmentReasonTextField = styled((props) => <TextField {...props} />)`
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
  flex-grow: 3;
`;

const StyledDropdown = styled((props) => <Dropdown {...props} />)`
  width: 100%;

  @media (min-width: 768px) {
    max-width: 120px;
  }

  @media (min-width: 1024px) {
    max-width: 200px;
  }
`;

const ReferenceCodeComponent = ({ isError, error, onChange, value }) => {
  const helperClasses = helperStyles();
  const [enrollmentReason, setEnrollmentReason] = useState(null);
  const [enrollmentReasonText, setEnrollmentReasonText] = useState(null);
  const getErrorText = (error) => {
    if (error.refCodeRequired) return 'Please Select Reason from Dropdown';
    if (error.refCodeTextRequired) return 'Please Provide Reason';
    if (error.refCodeLength) return 'Reference code should be less than or equal 50 characters';
    if (error.refCodeValidations) return 'Reference code should be in a correct format';
  };
  const refCodeOptions = batchEnrollment?.refCodeOptions ?? [
    { key: 'OTHERS', text: 'อื่นๆ', value: 'OTHERS' },
  ];
  const options = refCodeOptions.map((option) => ({
    key: option.key,
    text: option.text,
    value: option.value,
    additionalText: option.additionalText,
  })) ?? [{ key: 'OTHERS', text: 'อื่นๆ', value: 'OTHERS' }];
  const option = options.find((option) => option.key === enrollmentReason);
  const additionalText = option?.additionalText ?? 'รายละเอียดเพิ่มเติม';

  const handleTextFieldChange = (e) => {
    const value = e.target.value;
    setEnrollmentReasonText(value);
    onChange({ type: enrollmentReason, reason: value });
  };

  useEffect(() => {
    if (value) {
      const [type, ...reason] = value.split(':');
      setEnrollmentReason(type);
      setEnrollmentReasonText(reason.join(':'));
    }

    return () => {};
  }, [value]);

  return (
    <>
      <EnrollmentInputWrapper>
        <Text fontWeight="bold" mr="5px" style={{ width: '100%', maxWidth: 165 }}>
          Enrollment Reason
        </Text>
        <StyledDropdown
          placeholder="Select Reason"
          selection
          options={options}
          loading={false}
          defaultValue={undefined}
          onChange={(e, { value }) => {
            setEnrollmentReason(value);
            onChange({ type: value, reason: enrollmentReasonText });
          }}
          error={isError}
          helperText={isError && getErrorText(error)}
        />
        <StyledEnrollmentReasonTextField
          id="refcode-textfield"
          variant="outlined"
          placeholder={additionalText}
          error={isError}
          helperText={isError && getErrorText(error)}
          classes={helperClasses}
          onChange={handleTextFieldChange}
          // value={value}
        />
      </EnrollmentInputWrapper>
    </>
  );
};

function BatchCreationUploadView(props) {
  const {
    organizations,
    organizationLoading,
    onUpload,
    setOrganizationId,
    uploadBatchEnrollmentStatus,
    uploadBatchEnrollmentErrorMessage,
    getCoursesOptions,
    coursesOptions,
    canExpire,
    expiryDate,
    isEnableCredit,
    canOverrideCredit,
    subscriptionCreditAmount,
    canInsertEnterWebsiteUrl,
    canInsertEmailTemplateId,
    enterWebsiteUrl,
    setCoursesToEnroll,
    coursesToEnroll,
    uploadList,
    setUploadList,
    validationError,
    getSubscriptions,
    subscriptionsOption = [],
    setEnrollProductType,
    enrollProductType,
    setSubscriptionToEnroll,
    subscriptionToEnroll,
    refCode,
    handleRefCodeChange,
    handleEnableFields,
    handleCheckableFieldsValueChange,
    emailTemplateId,
  } = props;

  const helperClasses = helperStyles();
  const [selectedOrganization, setSelectOrganization] = useState(notAffiliatedOrganization.value);

  const [open, setOpen] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const loading = open && coursesOptions.length === 0 && subscriptionsOption.length === 0;
  const getCoursesOptionsCallback = useCallback(getCoursesOptions, []);
  const getSubscriptionsCallback = useCallback(getSubscriptions, []);

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    getCoursesOptionsCallback();
  }, [getCoursesOptionsCallback, loading]);

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }
    getSubscriptionsCallback();
  }, [getSubscriptionsCallback, loading]);

  const handleCoursesToEnrollChange = (event, newValue) => {
    setCoursesToEnroll(newValue);
  };

  const handleSubscriptionToEnrollChange = (event, newValue) => {
    setSubscriptionToEnroll(newValue?.SKUCode ?? '');
  };

  const UploadSuccessMessage = () => {
    return (
      <Message positive>
        <Message.Header>Upload was successful</Message.Header>
        <p>File are stored in storage, For waiting process </p>
      </Message>
    );
  };

  const UploadFailMessage = (props) => {
    return (
      <Message negative>
        <Message.Header>Upload has failed</Message.Header>
        <p>Upload has failed, Please check error message at batch creation status</p>
        <p>message: {props?.error?.message ?? ''}</p>
      </Message>
    );
  };

  const handleUpload = async () => {
    setIsUploading(true);
    await onUpload(uploadList);
    setIsUploading(false);
  };

  const isCoursesToEnrollError = coursesToEnroll.length === 0 && validationError.coursesToEnroll;
  const isRefCodeError = checkRefCodeError(refCode, validationError);
  const isSubscriptionToEnrollError =
    subscriptionToEnroll.length === 0 && validationError.subscriptionToEnroll;
  const isExpiryDateError = canExpire && !expiryDate && validationError.expiryDate;
  const isOverrideCreditError =
    canOverrideCredit && (subscriptionCreditAmount < 0 || isNil(subscriptionCreditAmount));
  const isEnterWebsiteUrlError =
    canInsertEnterWebsiteUrl && !isUrl(enterWebsiteUrl) && validationError.enterWebsiteUrl;
  const isEmailTemplateIdError =
    emailTemplateId.length === 0 && validationError.emailTemplateIdLength;
  const overrideValueTextStyle = { width: '100%', maxWidth: 165 };

  return (
    <div>
      {uploadBatchEnrollmentStatus === 'success' ? <UploadSuccessMessage /> : null}
      {uploadBatchEnrollmentStatus === 'error' ? (
        <UploadFailMessage error={uploadBatchEnrollmentErrorMessage} />
      ) : null}
      <Box py={16}>
        <Text fontSize={FONT_SIZE.semilarge} fontWeight={'bold'} mb={12}>
          Step 1: Defines Users to Enroll
        </Text>
        <Box mb={28}>
          {isUrl(batchEnrollmentTemplateUrl) ? (
            <Text>
              No template?{' '}
              <a target="_blank" rel="noopener noreferrer" href={batchEnrollmentTemplateUrl}>
                Download user list example template (.csv)
              </a>
            </Text>
          ) : (
            <Text>No example template available because the link is not provided or invalid.</Text>
          )}
        </Box>
        <FileUpload
          acceptFileTypes={acceptFileTypes}
          setUploadList={setUploadList}
          uploadList={uploadList}
          isUploading={!!isUploading}
        />
      </Box>
      <Divider />
      <Box py={16}>
        <Text fontSize={FONT_SIZE.semilarge} fontWeight={'bold'} mb={12}>
          Step 2: Choose Course(s)/Subscription to Enroll
        </Text>
        <FormControl component="fieldset" style={{ margin: '8px 0px' }}>
          <FormLabel component="legend" style={{ fontWeight: 'bold', color: 'black' }}>
            Enroll With
          </FormLabel>
          <RadioGroup
            aria-label="Enroll With"
            defaultValue="courses"
            name="radio-buttons-group"
            onChange={(e) => {
              setEnrollProductType(e.target.value);
              setCoursesToEnroll([]);
              setSubscriptionToEnroll('');
            }}
            row
          >
            <FormControlLabel value="courses" control={<Radio />} label="Courses" />
            <FormControlLabel value="subscription" control={<Radio />} label="Subscription" />
          </RadioGroup>
        </FormControl>
        {enabledOrganizationBatchEnrollment ? (
          <Text fontWeight="bold">
            Organization
            <Dropdown
              placeholder="Select Organization"
              fluid
              selection
              options={mapOrganizationsDropDown(organizations)}
              loading={organizationLoading}
              defaultValue={selectedOrganization}
              onChange={async (e, { value }) => {
                await setSelectOrganization(value);
                await setOrganizationId(value);
              }}
            />
          </Text>
        ) : (
          ''
        )}
        {enrollProductType === 'courses' ? (
          <CourseToEnrollComponent
            coursesOptions={coursesOptions}
            isCoursesToEnrollError={isCoursesToEnrollError}
            setOpen={setOpen}
            loading={loading}
            handleCoursesToEnrollChange={handleCoursesToEnrollChange}
          />
        ) : (
          <SubscriptionToEnrollComponent
            subscriptionsOption={subscriptionsOption}
            isSubscriptionToEnrollError={isSubscriptionToEnrollError}
            setOpen={setOpen}
            loading={loading}
            handleSubscriptionToEnrollChange={handleSubscriptionToEnrollChange}
          />
        )}
        <EnrollmentInputWrapper>
          <Text fontWeight="bold" mr="5px" style={overrideValueTextStyle}>
            Expiry Date
          </Text>
          <InputWithCheckboxWrapper>
            <StyledCheckbox
              onChange={(e) => handleEnableFields(e.target.checked, CHECKABLE_FIELDS.EXPIRY_DATE)}
              checked={canExpire}
              disabled={coursesToEnroll.length === 0 && subscriptionToEnroll === ''}
            />
            <MuiDatePicker
              label="Enrollment Expiry Date (Inclusive)"
              format="DD/MM/YYYY"
              inputVariant="outlined"
              minDate={moment().add('days', 1)}
              value={expiryDate}
              onChange={(date) =>
                handleCheckableFieldsValueChange(date, CHECKABLE_FIELDS.EXPIRY_DATE)
              }
              disabled={!canExpire}
              style={{ width: 250, marginTop: 8 }}
              InputLabelProps={{
                shrink: true,
              }}
              error={isExpiryDateError}
              helperText={isExpiryDateError && 'Required expiry date'}
              classes={helperClasses}
            />
          </InputWithCheckboxWrapper>
        </EnrollmentInputWrapper>
        {enrollProductType === 'subscription' ? (
          <EnrollmentInputWrapper>
            <Flex mr="5px" style={{ ...overrideValueTextStyle }}>
              <Text fontWeight="bold" mr="5px">
                Credits
              </Text>
              {!isEnableCredit ? (
                <Tooltip
                  arrow
                  title="หากต้องการแก้ไข Credits กรุณาเปิดใช้งาน Use Subscription Credit ที่เมนู Products > Subscriptions > Redemption"
                  placement={'top'}
                >
                  <InfoOutlinedIcon fontSize={'small'} style={{ color: 'inherit' }} />
                </Tooltip>
              ) : null}
            </Flex>
            <InputWithCheckboxWrapper>
              <StyledCheckbox
                onChange={(e) => handleEnableFields(e.target.checked, CHECKABLE_FIELDS.CREDIT)}
                checked={canOverrideCredit}
                disabled={!isEnableCredit}
              />
              <TextField
                type="number"
                variant="outlined"
                value={subscriptionCreditAmount}
                onChange={(e) => handleCheckableFieldsValueChange(e, CHECKABLE_FIELDS.CREDIT)}
                style={{ width: '100%' }}
                disabled={!canOverrideCredit}
                InputProps={{ inputProps: { min: 0 } }}
                error={isOverrideCreditError}
                helperText={
                  isOverrideCreditError && 'Credit amount should be greater than or equal to 0'
                }
                classes={helperClasses}
              />
            </InputWithCheckboxWrapper>
          </EnrollmentInputWrapper>
        ) : null}
        <EnrollmentInputWrapper>
          <Flex mr="5px" style={overrideValueTextStyle}>
            <Text fontWeight="bold" mr="5px">
              Enter Website URL
            </Text>
            <Tooltip arrow title="ลิงก์สำหรับเข้าระบบผ่าน Invitation Email" placement={'top'}>
              <InfoOutlinedIcon fontSize={'small'} style={{ color: 'inherit' }} />
            </Tooltip>
          </Flex>
          <InputWithCheckboxWrapper>
            <StyledCheckbox
              onChange={(e) => handleEnableFields(e.target.checked, CHECKABLE_FIELDS.WEBSITE_URL)}
              checked={canInsertEnterWebsiteUrl}
              disabled={coursesToEnroll.length === 0 && subscriptionToEnroll === ''}
            />
            <TextField
              type="text"
              variant="outlined"
              placeholder={'e.g. https://www.skooldio.com/dashboard'}
              value={enterWebsiteUrl}
              onChange={(e) => handleCheckableFieldsValueChange(e, CHECKABLE_FIELDS.WEBSITE_URL)}
              style={{
                width: '100%',
              }}
              disabled={!canInsertEnterWebsiteUrl}
              InputProps={{ inputProps: { min: 0 } }}
              error={isEnterWebsiteUrlError}
              helperText={isEnterWebsiteUrlError && 'Required valid URL'}
              classes={helperClasses}
            />
          </InputWithCheckboxWrapper>
        </EnrollmentInputWrapper>

        {enrollProductType === 'subscription' && (
          <EnrollmentInputWrapper>
            <Flex mr="5px" style={overrideValueTextStyle}>
              <Text fontWeight="bold" mr="5px">
                Email Template ID
              </Text>
              <Tooltip arrow title="id ของ template ที่จะส่ง email" placement={'top'}>
                <InfoOutlinedIcon fontSize={'small'} style={{ color: 'inherit' }} />
              </Tooltip>
            </Flex>
            <InputWithCheckboxWrapper>
              <StyledCheckbox
                onChange={(e) =>
                  handleEnableFields(e.target.checked, CHECKABLE_FIELDS.EMAIL_TEMPLATE_ID)
                }
                checked={canInsertEmailTemplateId}
                disabled={coursesToEnroll.length === 0 && subscriptionToEnroll === ''}
              />
              <TextField
                type="text"
                variant="outlined"
                value={emailTemplateId}
                onChange={(e) =>
                  handleCheckableFieldsValueChange(e, CHECKABLE_FIELDS.EMAIL_TEMPLATE_ID)
                }
                style={{
                  width: '100%',
                }}
                disabled={!canInsertEmailTemplateId}
                InputProps={{ inputProps: { min: 0 } }}
                error={isEmailTemplateIdError}
                helperText={
                  isEmailTemplateIdError && 'Email template id should more than 0 character'
                }
                classes={helperClasses}
              />
            </InputWithCheckboxWrapper>
          </EnrollmentInputWrapper>
        )}
        <ReferenceCodeComponent
          isError={isRefCodeError}
          error={validationError}
          onChange={handleRefCodeChange}
          value={refCode}
        />
        <Divider />
        <Flex justifyContent="flex-end">
          <Button
            primary
            circular
            loading={!!isUploading}
            disabled={!!isUploading}
            onClick={handleUpload}
          >
            Start Enrollment Process
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

export default BatchCreationUploadView;
