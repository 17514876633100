import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import compose from 'recompose/compose';
import { IconButton as BaseIconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Delete as DeleteIcon,
  KeyboardArrowUp as ArrowUpIcon,
  KeyboardArrowDown as ArrowDownIcon,
} from '@material-ui/icons';

import { Flex, Box, Text, AspectRatio, BackgroundImage } from 'Components/Base';
import withServerConfig from 'GraphQL/util/withServerConfig';
import withFormsy from 'Components/Form/withFormsy';
import { COLOR } from 'Components/Theme';
import ConfirmationModal from 'Components/ConfirmationModal';

import { ANNOUNCEMENT_CONTENT_TYPE } from '../Components/constants';

import BannerImageUpload from './BannerImageUpload';

const BannerFieldWrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme: { colors } }) => colors.bsGrays.gray400};
  border-radius: 4px;
  padding: 20px 0;
  text-align: center;
  position: relative;

  &:not(:first-of-type) {
    margin-top: ${({ theme: { space } }) => space[3]}px;
  }
`;

const AddButton = styled.div`
  margin-top: 20px;
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  background-color: ${({ theme: { colors } }) => colors.primary};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.warning};
  }
`;

const BannerItem = styled(Flex)`
  margin-bottom: 10px;
`;

const IconButton = withStyles({
  root: {
    padding: '8px',
  },
})(BaseIconButton);

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
})(Tooltip);

const BannerInput = ({
  onChange,
  value: initValue,
  readOnly,
  announcementId,
  serverConfig,
  onFileListRequest,
  onFileUploadUrlRequest,
}) => {
  const [bannerDetails, setBannerDetails] = useState(
    initValue && initValue.type === ANNOUNCEMENT_CONTENT_TYPE.BANNER
      ? JSON.parse(initValue.content)
      : [{ imageURL: '', linkURL: '' }]
  );

  const [shouldOpenConfirmationDeleteModal, setShouldOpenConfirmationDeleteModal] = useState(false);
  const selectedIndex = useRef();

  const updateValueOnChange = useCallback(() => {
    onChange(
      {},
      { value: { content: JSON.stringify(bannerDetails), type: ANNOUNCEMENT_CONTENT_TYPE.BANNER } }
    );
  }, [bannerDetails, onChange]);

  useEffect(() => {
    updateValueOnChange();
  }, [updateValueOnChange]);

  const handleAddingButton = () => {
    const updatedBannerDetails = [...bannerDetails, { imageURL: '', linkURL: '' }];
    setBannerDetails(updatedBannerDetails);
  };

  const handleDeleteButton = (itemIndex) => {
    const updatedBannerDetails = bannerDetails.filter((_, ind) => itemIndex !== ind);
    setBannerDetails(updatedBannerDetails);
  };

  const handleBannerItemOnChange = (e, index, type) => {
    const newBannerDetails = [...bannerDetails];
    newBannerDetails[index] = { ...bannerDetails[index] };
    newBannerDetails[index][type] = e.target.value;
    setBannerDetails(newBannerDetails);
  };

  const handleBannerImageOnChange = (fileObj, index) => {
    const { key, bucket, url } = fileObj;
    const newBannerDetails = [...bannerDetails];
    newBannerDetails[index].imageURL = url;
    newBannerDetails[index].bucket = bucket;
    newBannerDetails[index].key = key;

    setBannerDetails(newBannerDetails);
  };

  const handleReorderBannerItem = (destinationIndex, currentIndex) => {
    const newBannerDetails = [...bannerDetails];
    const currentBannerItem = newBannerDetails[currentIndex];
    newBannerDetails[currentIndex] = newBannerDetails[destinationIndex];
    newBannerDetails[destinationIndex] = currentBannerItem;
    setBannerDetails(newBannerDetails);
  };

  const renderBannerImageBlock = (imageURL, index) => {
    if (readOnly) {
      if (imageURL) {
        return (
          <AspectRatio ratio={16 / 9}>
            <BackgroundImage imageUrl={imageURL} bgPosition={'center'} />
          </AspectRatio>
        );
      }
      return <Text color="red">No banner image is uploaded.</Text>;
    }
    return (
      <BannerImageUpload
        fileImageUrl={imageURL}
        onFileListRequest={onFileListRequest}
        onFileUploadUrlRequest={onFileUploadUrlRequest}
        uploadMode={serverConfig.assetUploadMode}
        onImageUpdate={(fileObj) => handleBannerImageOnChange(fileObj, index)}
      />
    );
  };

  if (!announcementId) {
    return <Text>Available on Edit mode</Text>;
  }

  return (
    <>
      {bannerDetails.map(({ imageURL, linkURL }, index) => {
        return (
          <BannerFieldWrapper key={index}>
            <Box width={1} pr={readOnly ? 4 : 2}>
              <BannerItem alignItems="center">
                <label htmlFor={`BannerLink${index}`}>Link URL</label>
                <input
                  type="text"
                  id={`BannerLink${index}`}
                  value={linkURL}
                  onChange={(e) => handleBannerItemOnChange(e, index, 'linkURL')}
                  readOnly={readOnly}
                />
              </BannerItem>
              <Flex>
                <label htmlFor={`BannerImage${index}`}>Banner Image</label>
                <Box width="75%">{renderBannerImageBlock(imageURL, index)}</Box>
              </Flex>
            </Box>
            {!readOnly && (
              <Flex flex="1 0 auto" flexDirection="column" pr={2}>
                <StyledTooltip title="Move up" arrow>
                  <IconButton
                    onClick={() => handleReorderBannerItem(index - 1, index)}
                    disabled={index === 0}
                  >
                    <ArrowUpIcon />
                  </IconButton>
                </StyledTooltip>

                <StyledTooltip title="Move down" arrow>
                  <IconButton
                    onClick={() => handleReorderBannerItem(index + 1, index)}
                    disabled={index === bannerDetails.length - 1}
                  >
                    <ArrowDownIcon />
                  </IconButton>
                </StyledTooltip>

                <StyledTooltip title="Delete banner" arrow>
                  <IconButton
                    onClick={() => {
                      selectedIndex.current = index;
                      setShouldOpenConfirmationDeleteModal(true);
                    }}
                  >
                    <DeleteIcon style={{ color: COLOR.danger }} />
                  </IconButton>
                </StyledTooltip>
              </Flex>
            )}
          </BannerFieldWrapper>
        );
      })}
      {!readOnly && <AddButton onClick={handleAddingButton}>Add Banner Item</AddButton>}
      <ConfirmationModal
        variant="danger"
        isOpen={shouldOpenConfirmationDeleteModal}
        headerText="Delete Banner Item"
        contentText="Are you sure to delete this banner item?"
        confirmText="Yes, delete"
        onConfirm={() => {
          handleDeleteButton(selectedIndex.current);
          setShouldOpenConfirmationDeleteModal(false);
        }}
        onDismiss={() => setShouldOpenConfirmationDeleteModal(false)}
      />
    </>
  );
};

BannerInput.propTypes = {
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  getCurrentFormValues: PropTypes.func,
  inputLabel: PropTypes.string,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    content: PropTypes.string,
    type: PropTypes.string,
  }),
  announcementId: PropTypes.string,
  serverConfig: PropTypes.object,
  onFileListRequest: PropTypes.func,
  onFileUploadUrlRequest: PropTypes.func,
};

export default compose(withFormsy, withServerConfig({ configProp: 'serverConfig' }))(BannerInput);
