import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';

import { Flex, Box, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

import { RELATION_TYPE } from './constants';
/**
 * CourseCode = Audience = RelationID
 * @param {string} initValue - audiences | ex. {relationIdList: ["COURSE1","COURSE2","COURSE3"], relationType: 'GLOBAL'}
 */
const AudienceInput = ({ value: initValue, checkBoxLabel, onChange, readOnly }) => {
  const [currentAudience, setCurrentAudience] = useState([]);
  const [tempAudience, setTempAudience] = useState(
    initValue.relationIdList
      ? initValue.relationIdList.map((relationId) => (relationId ? relationId.value : null))
      : []
  );
  const [checked, setChecked] = useState(
    initValue.relationType === RELATION_TYPE.GLOBAL ? true : false
  );
  const [options, setOptions] = useState(
    initValue.relationType
      ? initValue.relationType !== RELATION_TYPE.GLOBAL
        ? [...initValue.relationIdList]
        : []
      : []
  );

  const handleAddition = (value) => {
    if (value.trim().length >= 1) {
      setOptions([
        {
          id: options.length,
          text: value,
          value: value,
          key: options.length,
        },
        ...options,
      ]);
    }
  };
  const handleChange = (value) => {
    const isAdd = value.length > currentAudience.length;

    if (!isAdd || value[value.length - 1].trim().length >= 1) {
      setCurrentAudience([...value]);
      onChange(
        {},
        {
          value: {
            relationIdList: [...value],
            relationType: checked ? RELATION_TYPE.GLOBAL : RELATION_TYPE.COURSE,
          },
        }
      );
    }
  };

  /** UseEffect
   * if checked => relationType = 'GLOBAL' & relationID = ''
   * if not checked => relationType = 'COURSE' & relationID = ...
   */
  useEffect(() => {
    if (checked) {
      setTempAudience([...currentAudience]);
      setCurrentAudience([]);
      onChange(
        {},
        {
          value: {
            relationIdList: [...tempAudience],
            relationType: RELATION_TYPE.GLOBAL,
          },
        }
      );
    } else {
      setTempAudience([]);
      setCurrentAudience([...tempAudience]);
      const tempAudiencesValue =
        tempAudience.length >= 1
          ? {
              relationIdList: [...tempAudience],
              relationType: RELATION_TYPE.COURSE,
            }
          : null;
      onChange({}, { value: tempAudiencesValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <Flex alignItems="center">
      <Dropdown
        fluid
        multiple
        search
        selection
        allowAdditions
        options={options}
        value={currentAudience}
        onChange={(_, { value }) => handleChange(value)}
        onAddItem={(_, { value }) => handleAddition(value)}
        disabled={readOnly || checked}
      />
      <Box ml={3} />
      <Checkbox
        label={checkBoxLabel}
        checked={checked}
        onChange={() => setChecked(!checked)}
        readOnly={readOnly}
      />
    </Flex>
  );
};

AudienceInput.propTypes = {
  value: PropTypes.string,
  checkBoxLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(AudienceInput);
