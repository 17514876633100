/* eslint-disable react/display-name */
import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import { Button } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import ConfirmationButton from 'Components/ConfirmationButton';
import FormGenerator from 'Components/Form/FormGenerator';
import SelectorInput from 'Routes/Reports/Components/SelectorInput/SelectorInput';

import { getUserSubscriptions } from '../../GraphQL/query/Shipment.query';
import { cancelSubscription } from '../../GraphQL/mutation/Payment.mutation';
import { ChangeSubscriptionModal } from '../../components/AdjustmentOrderModal';
import { CustomerTypeOptions, SALES_ORDER_STATUS } from '../../Domains/constants';

import UpdateSalesOrderStatusModal from './Containers/UpdateSalesOrderStatusModal';
import SubscriptionTypeFilterCheckBox from './Containers/SubscriptionTypeFilterCheckBox';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};
const voidFields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ref',
    inputName: 'ref',
    mode: MODE.BOTH,
    inputProps: {
      required: true,
    },
  },
];
function canCancelRecurring(subscription) {
  const { recurring } = subscription;
  return recurring && recurring.platform === 'DESKTOP' && !recurring.cancellationDate;
}

function isActiveOrIncoming(subscription, date = new Date()) {
  return new Date(subscription.endDate) >= date;
}

const columns = (variables, cancelRecurring) => [
  {
    Header: 'Full Name',
    accessor: 'customerFullName',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Mobile',
    accessor: 'customerMobile',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Customer Type',
    accessor: 'customerType',
    width: 200,
    type: COLUMN_TYPE.ENUM,
    enumOptions: CustomerTypeOptions,
    isSearchAble: true,
  },
  {
    Header: 'SKU Code',
    accessor: 'SKUCode',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'SKUTitle',
    accessor: 'title',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    width: 120,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Recurring Expiration Date',
    accessor: 'recurring.expirationDate',
    width: 120,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Order No',
    accessor: 'orderNo',
    width: 120,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Payment Amount',
    accessor: 'paymentAmount',
    width: 60,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod',
    width: 60,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 500,
    type: COLUMN_TYPE.CUSTOM,
    Cell: ({ original }) => {
      return (
        <Fragment>
          <Flex justifyContent="center">
            <Button.Group>
              <Flex justifyContent="center">
                <ConfirmationButton
                  headerText={'Cancel recurring'}
                  contentText={`This will cancel 2C2P recurring for user: ${original.customerFullName}.`}
                  buttonStyle={{
                    negative: true,
                    basic: true,
                    size: 'medium',
                    disabled: !canCancelRecurring(original),
                  }}
                  onConfirm={() => {
                    cancelRecurring(original.userId);
                  }}
                >
                  Cancel Recurring
                </ConfirmationButton>
                <ChangeSubscriptionModal
                  variables={variables}
                  initialData={{ userId: original.userId, endDate: original.endDate }}
                  trigger={
                    <Button basic color="blue" disabled={!isActiveOrIncoming(original)}>
                      Change Subscription
                    </Button>
                  }
                />
                <UpdateSalesOrderStatusModal
                  icon="times"
                  triggerButtonStyle={{ negative: true, basic: true }}
                  actionButtonStyle={{ negative: true }}
                  actionText="VOID"
                  disabled={!isActiveOrIncoming(original)}
                  value={{
                    id: original.orderId,
                    status: SALES_ORDER_STATUS.VOID,
                  }}
                  fields={voidFields}
                  variables={variables}
                  headerContent={
                    <Text.PaymentTitle>Set Success SalesOrder to Void</Text.PaymentTitle>
                  }
                ></UpdateSalesOrderStatusModal>
              </Flex>
            </Button.Group>
          </Flex>
        </Fragment>
      );
    },
  },
  {
    Header: 'SubscriptionType',
    accessor: 'subscriptionType',
    searchField: 'subscriptionType',
    type: COLUMN_TYPE.CUSTOM,
    width: 85,
    isHideColumn: true,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => {
      return <SubscriptionTypeFilterCheckBox {...props}></SubscriptionTypeFilterCheckBox>;
    },
  },
];

class SubscriptionRoute extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'createdAt', type: 'DESC' },
  };

  resolveData = (rawData) => rawData.sfBoShipmentUserSubscriptions.userSubscriptions;

  handleCancelRecurring = (userId) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    client.mutate({
      mutation: cancelSubscription,
      variables: {
        data: { userId: userId },
      },
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: getUserSubscriptions,
          variables: { search, paging, order },
          fetchPolicy: 'network-only',
        },
      ],
    });
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      if (field !== 'fullName' && field !== 'saleOrderNo') {
        return { field, type };
      }
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    const { subscriptionType, otherSearch } = search;
    this.setState({
      search: {
        ...otherSearch,
        expired: subscriptionType?.expired,
        active: subscriptionType?.active,
        inComing: subscriptionType?.incoming,
      },
      paging,
    });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Subscription</Text.Header>
        </Flex>
        <QueryTable
          type="sfBoShipmentUserSubscriptions"
          resolveData={this.resolveData}
          columns={columns(variables, this.handleCancelRecurring)}
          query={getUserSubscriptions}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default compose(withApollo, withRouter)(SubscriptionRoute);
