import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import CheckCloseComponent from '../../../Util/CheckDefinedComponent';
import {
  createDiscountMutation,
  updateDiscountMutation,
} from '../../../GraphQL/mutation/Sales.mutation';
import { discounts } from '../../../GraphQL/query/Sales.query';
import QueryComponent from '../../../GraphQL/util/QueryComponent';

import DiscountForm from './DiscountForm';

export default class NewDiscountMutation extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    discountId: PropTypes.string,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onComplete: () => {},
  };

  renderEdit() {
    const { discountId, data, variables } = this.props;
    return (
      <Mutation
        mutation={updateDiscountMutation}
        refetchQueries={[{ query: discounts, variables }]}
      >
        {(updateDiscount, { loading, error, data: mutateData }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent
                checkDefinedObject={mutateData}
                onDefined={this.props.onComplete}
              />
              <DiscountForm
                onCreateNewDiscount={(value) => {
                  // SKOOLDIO-4096 ensure discount is string
                  if (value.discount && typeof value.discount === 'number') {
                    value.discount = value.discount.toString();
                  }
                  if (value.total && typeof value.total === 'number') {
                    value.total = value.total.toString();
                  }
                  if (value.remain && typeof value.remain === 'number') {
                    value.remain = value.remain.toString();
                  }
                  updateDiscount({ variables: { discountId, updateDiscount: value } });
                }}
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                initialData={data}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  renderCreate() {
    const { variables } = this.props;
    return (
      <Mutation
        mutation={createDiscountMutation}
        refetchQueries={[{ query: discounts, variables }]}
      >
        {(createDiscount, { loading, error, data }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent checkDefinedObject={data} onDefined={this.props.onComplete} />
              <DiscountForm
                onCreateNewDiscount={(value) =>
                  createDiscount({ variables: { newDiscount: value } })
                }
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  render() {
    const { isEdit } = this.props;
    if (!isEdit) return this.renderCreate();
    return this.renderEdit();
  }
}
