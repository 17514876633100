import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createSchoolGradeMutation,
  updateSchoolGradeMutation,
} from 'GraphQL/LSP/mutation/Education.mutation';
import { getSchoolGrades, getSchoolGrade } from 'GraphQL/LSP/query/Education.query';

import SchoolGradeForm from './SchoolGradeForm';

export default class SchoolGradeModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { isEdit, id, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'แก้ไขระดับชั้น' : 'สร้างระดับชั้น'}</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getSchoolGrade}
                createMutation={createSchoolGradeMutation}
                createRefetchQueries={[
                  {
                    query: getSchoolGrades,
                    variables,
                  },
                ]}
                editMutation={updateSchoolGradeMutation}
                isEdit={isEdit}
                id={id}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  schoolGradeData: form,
                })}
                getCreateVariables={(form) => ({
                  schoolGradeData: form,
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <SchoolGradeForm initialData={data && data.boSchoolGrade} onSubmit={onSubmit} />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
