import React from 'react';
import styled, { css } from 'styled-components';

import { Flex } from 'Components/Base';

const HeaderLogoWrapper = styled((props) => (
  <Flex py={1} px={3} justifyContent={'center'} alignItems={'center'} {...props} />
))`
  position: relative;

  ${({ hasSeparator }) =>
    hasSeparator &&
    css`
      &:before {
        content: '';
        border-left: 1px solid rgba(255, 255, 255, 0.36);
        position: absolute;
        height: 50%;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    `}
`;

/**
 * @param {boolean} hasSeparator - The left line separated between logo and header items.
 */
const HeaderLogo = ({ hasSeparator, children, ...props }) => {
  return (
    <HeaderLogoWrapper hasSeparator={hasSeparator} {...props}>
      {children}
    </HeaderLogoWrapper>
  );
};

export default HeaderLogo;
