import React, { useRef, useState, forwardRef } from 'react';
import { Dialog, Slide } from '@material-ui/core';

import { ButtonFilled } from 'Components/Base';

import { QuestionPreview } from '../../QuestionPreviewV2';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogLayout = (props) => {
  return (
    <Dialog
      fullScreen={true}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
      {...props}
    />
  );
};

const QuestionPreviewButton = forwardRef((props, ref) => {
  const formValues = useRef({});
  const [isOpen, setIsOpen] = useState(false);

  const setPreviewValues = () => {
    const values = ref?.current?.getCurrentValues?.() ?? {};
    const { choice, question, solution, fieldNumber, type } = values;
    const { choices, correctAnswer } = choice || {};
    const { value: correctAnswerValue } = correctAnswer || {};

    formValues.current = {
      values,
      choices,
      correctAnswer: correctAnswerValue,
      precision: fieldNumber,
      question,
      solution,
      index: props?.index ?? '',
      total: props?.total ?? '',
      timeRemaining: props?.time ?? '',
      type,
      ...props,
    };

    setIsOpen(true);
  };

  return (
    <>
      <ButtonFilled
        style={{
          border: 'none',
          color: 'rgba(0,0,0,.6)',
          background: '#e0e1e2 none',
          boxShadow: 'inset 0 0 0 1px transparent, inset 0 0 0 0 rgb(34 36 38 / 15%)',
          fontSize: '16px',
          fontWeight: '500',
          fontFamily: 'inherit',
        }}
        type="button"
        // disabled={type === EXAM_QUESTION_TYPE.FILL_NUMBER}
        onClick={setPreviewValues}
      >
        Preview
      </ButtonFilled>
      <DialogLayout open={isOpen}>
        <QuestionPreview {...formValues.current} onClose={() => setIsOpen(false)} />
      </DialogLayout>
    </>
  );
});

export default QuestionPreviewButton;
