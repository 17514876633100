import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

import { EXPIRY_CONDITION, CUSTOMER_TYPE } from 'Routes/SalesV2/Domains/constants';

import CheckCloseComponent from '../../../../Util/CheckDefinedComponent';
import {
  createDiscountCode,
  updateDiscountCode,
} from '../../GraphQL/mutation/DiscountCode.mutation';
import { getDiscountCodes } from '../../GraphQL/query/DiscountCode.query';

import DiscountForm from './DiscountForm';

const transformDiscount = (value) => {
  // SKOOLDIO-4096 ensure discount is string
  const newValue = _.cloneDeep(value);
  if (value.discount) {
    newValue.discount = parseFloat(value.discount);
  }
  if (value.total) {
    newValue.total = parseFloat(value.total);
  }
  if (value.remain) {
    newValue.remain = parseFloat(value.remain);
  }
  if (value.productTypes === '') {
    newValue.productTypes = [];
  }
  if (value.exceptSKUIds === '') {
    newValue.exceptSKUIds = [];
  }

  // will automatically calculate by backend
  delete newValue.remain;
  delete newValue.validationType;
  const checkAndReturnAndDeleteKey = (key) => {
    const data = newValue[key];
    if (key in value) delete newValue[key];
    return data;
  };
  const daysBeforeExpiry = checkAndReturnAndDeleteKey('daysBeforeExpiry');
  const expiryDate = checkAndReturnAndDeleteKey('expiryDate');
  if (daysBeforeExpiry) newValue.conditions = [{ days: daysBeforeExpiry }];
  else if (expiryDate) newValue.conditions = [{ expiryDate: expiryDate }];
  else value.conditions = null;

  if (!value.customerType || value.customerType === CUSTOMER_TYPE.ALL) {
    newValue.customerType = null;
  }

  if (!value.minimumQuantity || value.minimumQuantity === 'unselected') {
    newValue.minimumQuantity = null;
  }

  return newValue;
};

export default class NewDiscountMutation extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    discountId: PropTypes.string,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onComplete: () => {},
  };

  renderEdit() {
    const { discountId, data, variables } = this.props;
    let initialData = { ...data };
    if (initialData.conditions) {
      const val = initialData.conditions[0];
      if ('days' in val) {
        initialData.validationType = EXPIRY_CONDITION.DAYS_VALIDITY;
        initialData.daysBeforeExpiry = val.days;
      } else if ('expiryDate' in val) {
        initialData.validationType = EXPIRY_CONDITION.EXPIRE_DATE;
        initialData.expiryDate = val.expiryDate;
      }
    }

    return (
      <Mutation
        mutation={updateDiscountCode}
        refetchQueries={[{ query: getDiscountCodes, variables }]}
      >
        {(updateDiscount, { loading, error, data: mutateData }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent
                checkDefinedObject={mutateData}
                onDefined={this.props.onComplete}
              />
              <DiscountForm
                onCreateNewDiscount={(value) => {
                  updateDiscount({
                    variables: { input: transformDiscount(value), id: discountId },
                  });
                }}
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                initialData={initialData}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  renderCreate() {
    const { variables } = this.props;
    return (
      <Mutation
        mutation={createDiscountCode}
        refetchQueries={[{ query: getDiscountCodes, variables }]}
      >
        {(createDiscount, { loading, error, data }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent checkDefinedObject={data} onDefined={this.props.onComplete} />
              <DiscountForm
                onCreateNewDiscount={(value) => {
                  createDiscount({ variables: { input: transformDiscount(value) } });
                }}
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  render() {
    const { isEdit } = this.props;
    if (!isEdit) return this.renderCreate();
    return this.renderEdit();
  }
}
