import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

import { Box } from 'Components/Base';
import getConfig from 'Util/Config';

const Delta = Quill.import('delta');

const Embed = Quill.import('blots/block/embed');
class Hr extends Embed {}
Hr.blotName = 'hr';
Hr.tagName = 'hr';

Quill.register({
  'formats/hr': Hr,
});
Quill.register('modules/imageResize', ImageResize);

// The functionality require usage of various function under LegacyHtmlEditor (ex: unescapeAndSanitizeHTMLSection, preprocessLegacyHTMLEditorInput, etc.)
class HTMLSection extends Embed {}
HTMLSection.blotName = 'html-section';
HTMLSection.tagName = 'div';
function handleHTMLSection() {
  if (!this.quill) return;
  const text = `
<div data-testid="html-section">
...แทรกโค้ด HTML บริเวณนี้...
</div>
`;

  const range = this.quill.getSelection(true);
  this.quill.insertText(range.index, text);
}
Quill.register(HTMLSection);

const HTMLEditorWrapper = styled.div`
  .ql-tooltip {
    z-index: 102;
  }
`;

const MODE = {
  SIMPLE: 'SIMPLE',
  ADVANCE: 'ADVANCE',
};

const CustomToolbar = ({ id, handleInsert, examId, mode }) => {
  return (
    <div id={`toolbar_${id}`}>
      {mode === MODE.ADVANCE && (
        <select className="ql-header" defaultValue={''} onChange={(e) => e.persist()}>
          <option value="1" />
          <option value="2" />
          <option selected />
        </select>
      )}
      <div className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
      </div>
      <div className="ql-formats">
        {mode === MODE.ADVANCE && (
          <Fragment>
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
          </Fragment>
        )}
        <select className="ql-align" />
      </div>
      <div className="ql-formats">
        <button className="ql-image" />
      </div>
      {mode === MODE.ADVANCE && (
        <div className="ql-formats">
          <button className="ql-link" />
          <button className="ql-code-block" />
          <button className="ql-video" />
          <button className="ql-hr">hr</button>
          <button className="ql-htmlSection">html</button>
        </div>
      )}
    </div>
  );
};

class LegacyHTMLEditor extends Component {
  constructor(props) {
    super(props);
    this.insertToEditor = this.insertToEditor.bind(this);
    this.removeStylingOnPastingHTML = this.removeStylingOnPastingHTML.bind(this);
  }

  state = {
    value: '',
  };

  modules = {
    toolbar: {
      container: `#toolbar_${this.props.id}`,
      handlers: {
        hr: function (value) {
          this.quill.format('hr', true);
        },
        htmlSection: handleHTMLSection,
      },
    },
    imageResize: {
      parchment: Quill.import('parchment'),
    },
    clipboard: { matchVisual: false },
  };

  formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
  ];

  componentDidMount() {
    const editor = this.reactQuillRef.getEditor();
    if (this.props.value && this.props.value !== '' && this.props.focusOnMount) {
      editor.setSelection(this.props.value.length);
    }
    editor.root.addEventListener('paste', this.removeStylingOnPastingHTML);
  }

  removeStylingOnPastingHTML(e) {
    const htmlTagPattern = /\s*<[^/].*?>[\s\S]*<\/.*?>\s*/g;
    const text = e.clipboardData.getData('text/plain');
    // if detect any opening and closing html tag, remove any styling
    if (htmlTagPattern.test(text)) {
      e.preventDefault();
      const quillRef = this.reactQuillRef;
      const editor = quillRef.getEditor();
      const range = editor.getSelection(true);
      // NOTE: the timeout is required or else the paste behavior will be really weird (0 is not enough)
      setTimeout(() => {
        const delta = new Delta().retain(range.index).delete(range.length).insert(text);
        editor.updateContents(delta);
      }, 1);

      // NOTE: the timeout is required or else the cursor won't change it's position https://stackoverflow.com/a/61256177
      setTimeout(() => {
        editor.setSelection(range.index + text.length, 0, 'api');
      }, 1);
    }
  }

  insertToEditor(elements) {
    let ref = this.reactQuillRef;
    const editor = ref.getEditor();
    editor.focus();
    const range = editor.getSelection();

    if (elements && elements.length !== 0) {
      elements.map((e, i) => {
        editor.insertEmbed(range.index + i, 'image', e.url);
      });
      editor.setSelection(range.index + elements.length);
    }
  }
  getNavBarHeight() {
    const element = document.getElementById('navbar');
    if (element) {
      return element.getBoundingClientRect().height;
    }
  }
  render() {
    const { value, onChange, id, assetId, mode } = this.props;
    return (
      <HTMLEditorWrapper className="text-editor">
        <Sticky top={this.getNavBarHeight() || 0} innerZ={101}>
          <Box bg="white">
            <CustomToolbar
              id={id}
              handleInsert={this.insertToEditor}
              assetId={assetId}
              mode={mode}
            />
          </Box>
        </Sticky>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          modules={this.modules}
          onChange={onChange}
          value={value}
        />
      </HTMLEditorWrapper>
    );
  }
}

LegacyHTMLEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assetId: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(MODE)),
  focusOnMount: PropTypes.bool,
};

LegacyHTMLEditor.defaultProps = {
  onChange: () => {},
  value: '',
  id: 0,
  assetId: '',
  mode: MODE.SIMPLE,
};

LegacyHTMLEditor.MODE = MODE;

export default LegacyHTMLEditor;

// This section is for adding edit HTML capability to the Quill editor
export const unescapeAndSanitizeHTMLSection = (text) => {
  const htmlSections = extractMatchingHTMLTags(text);

  htmlSections.forEach((section) => {
    const { content } = section;
    const { allowedIframeSourcesRegex } = getConfig();

    // remove unaccepted iframe source before unescape the html to reduce risk of attacking. The order here is important!
    const unescapedString = unescapeHTML(
      removeUnacceptedIframe(content, allowedIframeSourcesRegex)
    );

    // clear other stuff before unescaping (just in case something bad happened prior to sanitization)
    const sanitizedString = DOMPurify.sanitize(unescapedString, {
      ADD_TAGS: ['iframe'], // additionally allow usage of iframe. The source filtration is done using removeUnacceptedIframe() function.
      ADD_ATTR: [
        'allow',
        'frameborder',
        'referrerpolicy',
        'height',
        'width',
        'allowfullscreen',
        'webkitallowfullscreen',
        'mozallowfullscreen',
        'src',
        'title',
        'style',
        'sandbox',
      ],
      FORBID_TAGS: ['script', 'p', 'a'], // script is forbidden to prevent xss, p is forbidden to prevent interpretation of HTML as plain text,
    });

    text = text.replace(content, sanitizedString);
  });

  return text;
};
export const removeUnacceptedIframe = (htmlString, allowedIframeSourcesRegex) => {
  // the regex pattern of &lt; and &gt; are intentional since the string would be escaped by Quill editor first
  const iframeRegex = /&lt;iframe[\s\S]*?\/iframe&gt;/gi;
  const iframeMatches = htmlString.match(iframeRegex);
  if (iframeMatches) {
    iframeMatches.forEach((iframe) => {
      const srcRegex = /src="([^"]+)"/i;
      const srcMatch = srcRegex.exec(iframe);
      if (srcMatch && srcMatch[1]) {
        const srcUrl = srcMatch[1];
        const matchingResults = allowedIframeSourcesRegex.filter((iframeSourceRegex) =>
          iframeSourceRegex.test(srcUrl)
        );

        // replace iframe from unacceptable source with warning/info text
        if (matchingResults.length <= 0) {
          htmlString = htmlString.replace(iframe, 'Unsupported Iframe source');
        }
      }
    });
  }
  return htmlString;
};

// escape all incoming HTML in the editor's HTML sections for usage in Quill editor
export const preprocessLegacyHTMLEditorInput = (editorBody) => {
  const htmlSections = extractMatchingHTMLTags(
    editorBody,
    '<div',
    '</div>',
    '<div data-testid="html-section">'
  );
  htmlSections.forEach((section) => {
    const { content } = section;
    editorBody = editorBody.replace(content, escapeHTML(content));
  });

  return editorBody;
};
export const escapeHTML = (unescapedHTMLString) => {
  return unescapedHTMLString
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/&nbsp;/g, ' ');
};
export const unescapeHTML = (htmlString) => {
  return htmlString
    .replace(/&lt;script&gt;.*&lt;\/script&gt;/g, '')
    .replace(/&lt;script&gt;/g, '')
    .replace(/&lt;\/script&gt;/g, '')
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
};

const extractMatchingHTMLTags = (
  htmlContent = '',
  openingDiv = '&lt;div',
  closingDiv = '&lt;/div&gt;',
  specificOpeningDiv = '&lt;div data-testid="html-section"&gt;'
) => {
  const stack = [];
  let startIndex = -1;

  const result = [];

  for (let i = 0; i < htmlContent.length; i++) {
    if (htmlContent.substring(i, i + openingDiv.length) === openingDiv) {
      if (stack.length === 0) {
        startIndex = i;
      }
      stack.push(i);
    } else if (htmlContent.substring(i, i + closingDiv.length) === closingDiv && stack.length > 0) {
      stack.pop();
      if (
        stack.length === 0 &&
        htmlContent.substring(startIndex, startIndex + specificOpeningDiv.length) ===
          specificOpeningDiv
      ) {
        result.push({
          startIndex,
          content: htmlContent.substring(startIndex, i + closingDiv.length),
        });
      }
    }
  }

  return result;
};
