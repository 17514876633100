import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import FormGenerator from 'Components/Form/FormGenerator';
import ConfirmationButton from 'Components/ConfirmationButton';

const EditorBox = styled(Box)`
  & .text-editor {
    width: 100%;
  }
`;

const inputList = (isEdit) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Code',
    inputName: 'code',
    inputProps: {
      disabled: !isEdit,
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ชื่อ',
    inputName: 'name',
    inputProps: {
      disabled: !isEdit,
      required: true,
    },
  },
];

function SyllabusDetails(props) {
  const { loading, onSubmit, initialData } = props;

  const [isEdit, setIsEdit] = useState(false);
  const myRef = useRef(null);

  const handleOnSubmit = (form) => {
    onSubmit(form);
    setIsEdit(false);
  };
  return (
    <EditorBox>
      <Box>
        <Flex justifyContent="flex-end">
          <Button
            primary
            circular
            onClick={() => {
              if (isEdit) {
                myRef.current.handleDefaultSubmit();
              } else {
                setIsEdit(true);
              }
            }}
            loading={loading}
            disabled={loading}
          >
            {isEdit ? 'Save' : 'Edit'}
          </Button>
          {isEdit && (
            <ConfirmationButton
              buttonStyle={{
                primary: false,
                circular: true,
                size: 'medium',
                type: 'reset',
                disabled: loading,
              }}
              onConfirm={() => {
                myRef.current.handleCancelled();
                setIsEdit(false);
              }}
              headerText={`Close without Save`}
              confirmText={'Confirm'}
              contentText={'Are you sure to close this form without save ?'}
            >
              {`Cancel`}
            </ConfirmationButton>
          )}
        </Flex>
      </Box>
      <FormGenerator
        ref={myRef}
        fields={inputList(isEdit)}
        showAction={false}
        readOnly={!isEdit}
        initialData={initialData}
        onSubmit={handleOnSubmit}
      />
    </EditorBox>
  );
}

export default SyllabusDetails;
