import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormGenerator from '../../../Components/Form/FormGenerator';

const fields = (courseList = []) => [
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Course',
    inputName: 'courseId',
    inputProps: {
      required: true,
      search: true,
      options: courseList,
    },
  },
];

const mappingFn = (course) => ({
  key: course.id,
  value: course.id,
  text: course.title,
});

export default class EnrollCoursePanel extends Component {
  static propTypes = {
    courseList: PropTypes.array,
    onEnrollCourse: PropTypes.func,
  };

  static defaultProps = {
    courseList: [],
    onEnrollCourse: () => {},
  };

  handleSubmit = (form) => {
    this.props.onEnrollCourse(form.courseId);
  };

  render() {
    const { courseList } = this.props;
    return (
      <FormGenerator
        fields={fields(courseList.map(mappingFn))}
        onSubmit={this.handleSubmit}
        submitText="Enroll Course"
        showCancel
        {...this.props}
      />
    );
  }
}
