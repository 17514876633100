import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';

import NewDiscountMutation from './NewDiscount.gql';

export default class DiscountModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    discountId: PropTypes.string,
    data: PropTypes.shape({
      code: PropTypes.string,
      discount: PropTypes.string,
      discountUnit: PropTypes.string,
      discountText: PropTypes.string,
    }),
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { isEdit, discountId, data, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>
              {isEdit ? 'Edit Discount Information' : 'Create New Discount'}
            </Modal.Header>
            <Modal.Content>
              <NewDiscountMutation
                variables={variables}
                isEdit={isEdit}
                discountId={discountId}
                data={data}
                onComplete={closeModal}
                onCancelled={closeModal}
                {...this.props}
              />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
