export const MARKDOWN_TAG = {
  normal: 'p',
  italic: 'em',
  bold: 'strong',
  underline: 'u',
  space: 'br',
  cursor: '<span class="ql-cursor">﻿</span>',
};

function tagExclusion(tag) {
  return `<[\/]*${tag}[^>]*>`; //eslint-disable-line
}

export function markdownToText(markdown) {
  const text = markdown
    .replace(new RegExp(tagExclusion('img'), 'gi'), '[Image]')
    .replace(new RegExp(tagExclusion(MARKDOWN_TAG.normal), 'gi'), ' ')
    .replace(new RegExp(tagExclusion(MARKDOWN_TAG.bold), 'gi'), ' ')
    .replace(new RegExp(tagExclusion(MARKDOWN_TAG.italic), 'gi'), ' ')
    .replace(new RegExp(tagExclusion(MARKDOWN_TAG.underline), 'gi'), ' ')
    .replace(new RegExp(tagExclusion(MARKDOWN_TAG.space), 'gi'), ' ')
    .replace(/\\s+/g, ' ')
    .replace(new RegExp(MARKDOWN_TAG.cursor, 'gi'), ' ')
    .trim();
  return text;
}
