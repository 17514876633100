import React from 'react';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBook } from '../../GraphQL/query/Products.query';
import { updateBookStockMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUStockForm from '../SKUStockForm';

const BookStocksEditor = (props) => {
  const { book = {} } = props;
  const { id, SKUCode } = book;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getBook}
        editMutation={updateBookStockMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({ SKUCode })}
        getEditVariables={(form) => {
          return {
            id,
            data: {
              originalStock: form?.originalStock ?? '',
            },
          };
        }}
        editRefetchQueries={[
          {
            query: getBook,
            variables: {
              SKUCode: SKUCode,
            },
          },
        ]}
      >
        {({ data, onSubmit }) => (
          <SKUStockForm initialData={data?.sfBoProductBook} onSubmit={onSubmit} />
        )}
      </CreateEditForm>
    </Box>
  );
};

export default BookStocksEditor;
