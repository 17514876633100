import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { compose, branch, renderComponent, mapProps } from 'recompose';

import { withUserStatus } from '../Redux/user-profile/hoc';
import Loading from '../Components/Loading';

const CannotAccess = () => <div>You do not have permission to access this page</div>;

const redirectLoginHoc = branch(
  (props) => !props.isLogin,
  renderComponent(
    mapProps((props) => ({
      to: {
        pathname: '/login',
        state: { from: props.location },
      },
    }))(Redirect)
  )
);

const notAccessHoc = (roles = []) =>
  branch(
    ({ isLogin, myRoles }) =>
      isLogin && roles && roles.length > 0 && !roles.some((role) => myRoles.indexOf(role) >= 0),
    renderComponent(CannotAccess)
  );

const AuthenHOC = (enabledRoles = []) =>
  compose(
    withUserStatus,
    branch((props) => props.isLoading, renderComponent(Loading)),
    redirectLoginHoc,
    notAccessHoc(enabledRoles)
  );

export const PrivateRoute = ({ component: Component, roles, componentProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const AuthComp = AuthenHOC(roles)(Component);
      return <AuthComp {...props} {...componentProps} />;
    }}
  />
);

const GenRoute = ({ menus, ...restProps }) =>
  menus.map((menu) =>
    menu.private ? (
      <PrivateRoute
        key={'route' + menu.path}
        path={menu.path}
        exact={menu.exact}
        component={menu.route}
        roles={menu.roles}
        componentProps={restProps}
      />
    ) : (
      <PublicRoute
        key={'route' + menu.path}
        path={menu.path}
        exact
        component={menu.route}
        componentProps={restProps}
      />
    )
  );

const GenSwitchRoute = ({ routes, ...restProps }) => {
  return (
    <Switch>
      {routes.map((item) => {
        if (item.noMatchRoute) {
          return <Route key="noMatch" component={item.noMatchRoute} />;
        }
        return item.private ? (
          <PrivateRoute
            key={'route' + item.path}
            path={item.path}
            exact={item.exact}
            component={item.route}
            roles={item.roles}
            componentProps={restProps}
          />
        ) : (
          <PublicRoute
            key={'route' + item.path}
            path={item.path}
            exact
            component={item.route}
            componentProps={restProps}
          />
        );
      })}
    </Switch>
  );
};

export const PublicRoute = Route;

export { GenRoute, GenSwitchRoute };

export default { PrivateRoute, PublicRoute };
