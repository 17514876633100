import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import withServerConfig from 'GraphQL/util/withServerConfig';
import FileUploadManagerModal from 'Components/Lib/UploadComponent';
import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import { SemanticButton as Button } from 'Components/Base/Button';
import { Box } from 'Components/Base';
import Avatar from 'Components/Avatar';

class ProfileAvatarUploadForm extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    avatar: 'profile-empty.png',
    onSuccess: () => {},
  };

  handleFileListRequest = async (request) => {
    const { client, userId } = this.props;
    const { data } = await client.mutate({
      mutation: getAssetList,
      variables: {
        type: ASSET_TYPE.PROFILE_IMAGES,
        key: userId,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { client, userId } = this.props;

    const { data } = await client.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.PROFILE_IMAGES,
        key: userId,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  handleSelectImage = (files = []) => {
    if (files && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      // Now Support only one file upload
      const { key, bucket, url } = files[0];
      this.props.onSuccess({ key, bucket, url });
    }
  };

  render() {
    const { avatar, serverConfig } = this.props;
    return (
      <Box style={{ textAlign: 'center' }} mb={4}>
        <Box>
          <Avatar size={100} src={avatar} />
        </Box>
        <Box>
          <FileUploadManagerModal
            keyGroup="profile-images"
            uploadUrl={'http://localhost:5000'}
            listUrl={'http://localhost:5000'}
            dimmer="inverted"
            allowedContentTypes={['image/png', 'image/jpeg', 'image/gif']}
            defaultText={'Support .png, .jpeg and .gif image with less than 5MB'}
            maxFileSize={5}
            maxSelection={1}
            customFileListUrlRequest={this.handleFileListRequest}
            customUploadUrlRequest={this.handleFileUploadUrlRequest}
            mode={serverConfig.assetUploadMode}
            onFileManagerDialogClose={this.handleSelectImage}
            trigger={<Button primary size="medium" type="button" content="Upload Image" />}
          />
        </Box>
      </Box>
    );
  }
}

export default compose(
  withApollo,
  withServerConfig({ configProp: 'serverConfig' })
)(ProfileAvatarUploadForm);
