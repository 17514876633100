import styled from 'styled-components';

const RichTextEditorStyleWrapper = styled.div`
  .toolbar-code-block > span {
    width: 22px;
    height: 22px;
    display: inline-block;
    background-image: url('/assets/code-block-icon.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .Dropdown__root___3ALmx > select {
    height: 30px;
    padding: 0px;
    border: 4px solid transparent;
    background: none transparent;
    color: inherit;
  }

  > .RichTextEditor__root___2QXK- {
    word-break: break-all;
  }

  .RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz {
    background-color: #ffffff;
    padding: 15px 9px 12px 9px;
    .ButtonGroup__root___3lEAn .ButtonWrap__root___1EO_R {
      z-index: 0; // Fix show editor toolbar while selecting dropdown
    }
  }

  .DraftEditor-editorContainer {
    max-height: 400px;
    overflow: auto;
  }

  .public-DraftEditor-content {
    min-height: 120px;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
`;

export default RichTextEditorStyleWrapper;
