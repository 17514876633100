export const SubscriptionManagementPermissionKeys = Object.freeze({
  ADJUSTMENT_ORDER: {
    LIST: {
      VIEW: 'sales.adjustmentOrderSubscriptionManagement.list-view',
      UPDATE: 'sales.adjustmentOrderSubscriptionManagement.list-update',
      DELETE: 'sales.adjustmentOrderSubscriptionManagement.list-delete',
    },
  },
  SUBSCRIPTION: {
    LIST: {
      VIEW: 'sales.subscriptionSubscriptionManagement.list-view',
      UPDATE: 'sales.subscriptionSubscriptionManagement.list-update',
      DELETE: 'sales.subscriptionSubscriptionManagement.list-delete',
    },
  },
});
