import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Box } from 'Components/Base';
import { addOrganizationEmail as addOrganizationEmailMutation } from 'GraphQL/mutation/Organization.mutation';

import Snackbar, { VARIANTS } from '../../Components/Snackbar';
import OrganizationEmailForm, {
  ORGANIZATION_EMAIL_STATUS,
} from '../../Components/Organization/OrganizationEmailForm';

const formatToData = (organizationId, form) => {
  return {
    organizationId,
    organizationEmailData: {
      email: form?.email,
      isBlacklist: form?.isBlacklist === ORGANIZATION_EMAIL_STATUS.BLACKLIST ? true : false,
    },
  };
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  EMAIL_ALREADY_EXISTS: {
    message: `Email already exists, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot add email, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const AddEmailToOrganization = ({ organizationId, onCancel, onCompleted: onCompletedProps }) => {
  const [feedback, setFeedback] = useState({});

  const onCompleted = useCallback(
    (response) => {
      const data = response?.addOrganizationEmail ?? {};
      if (data?.success) {
        const addedEmail = data?.organizationEmail.email;
        setFeedback({
          message: `Successfully add ${addedEmail} to the organization!`,
          variant: VARIANTS.SUCCESS,
          show: true,
        });
        if (typeof onCompletedProps === 'function') onCompletedProps();
        return;
      }

      const message = data?.message ?? '';
      if (message.includes('400')) {
        setFeedback(FEEDBACK.EMAIL_ALREADY_EXISTS);
        return;
      }
      if (message.includes('401')) {
        setFeedback(FEEDBACK.PROHIBITED);
        return;
      }
      setFeedback(FEEDBACK.UNEXPECTED_ERROR);
    },
    [onCompletedProps]
  );

  const [addOrganizationEmail] = useMutation(addOrganizationEmailMutation, { onCompleted });

  const handleAddEmail = (form) => {
    addOrganizationEmail({ variables: formatToData(organizationId, form) });
  };

  return (
    <>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
      <Box pt={4} pb={3} px={4} mx={'auto'} width={'100%'}>
        <OrganizationEmailForm onCancelled={onCancel} onSubmit={handleAddEmail} isEdit={false} />
      </Box>
    </>
  );
};

AddEmailToOrganization.propTypes = {
  onCancel: PropTypes.func,
  onCompleted: PropTypes.func,
  organizationId: PropTypes.string,
  organizationEmailId: PropTypes.string,
};

AddEmailToOrganization.defaultProps = {
  onCancel: () => {},
  onCompleted: () => {},
};

export default AddEmailToOrganization;
