import React, { useState } from 'react';
import { Box } from '@skooldio/skooldio-ui-components-core';

import withFormsy from 'Components/Form/withFormsy';

import CourseImageUpload from '../../Components/CourseImageUpload.gql';

const CourseLogoEditor = ({ value, onChange, logoUrl: defaultLogoUrl, ...props }) => {
  const [logoUrl, setLogoUrl] = useState(defaultLogoUrl);

  const handleLogoChange = ({ key, bucket, url }) => {
    let updatedValue = null;
    if (key && bucket) updatedValue = { key, bucket };
    onChange({}, { value: updatedValue });
    setLogoUrl(url);
  };

  return (
    <Box width={[1, 0.3]}>
      <CourseImageUpload {...props} fileImageUrl={logoUrl} onImageUpdate={handleLogoChange} />
    </Box>
  );
};

export default withFormsy(CourseLogoEditor);
