import gql from 'graphql-tag';

export const prices = gql`
  query prices($search: PriceSearchInput, $paging: PageInput, $order: OrderInput) {
    prices(search: $search, paging: $paging, order: $order) {
      total
      prices {
        id
        itemId
        fullPrice
        promotionPrice
        promotionText
        currency
        createdAt
      }
    }
  }
`;

export const discounts = gql`
  query discounts($search: DiscountSearchInput, $paging: PageInput, $order: OrderInput) {
    discounts(search: $search, paging: $paging, order: $order) {
      total
      discounts {
        id
        code
        total
        remain
        userIds
        courseReferIds
        paymentMethods
        discount
        discountUnit
        discountText
        startDate
        endDate
        productExpiryDate
        currency
        customerType
        minimumQuantity
      }
    }
  }
`;
