import gql from 'graphql-tag';

export const SFBO_SHIPMENTS_DELIVERY_FRAGMENT = gql`
  fragment SFBO_SHIPMENTS_DELIVERY_FRAGMENT on SFBoShipment {
    delivery {
      shipmentProvider
      trackingNumber
      trackingUrl
    }
  }
`;

export const SFBO_SHIPMENTS_ADDRESS_FRAGMENT = gql`
  fragment SFBO_SHIPMENTS_ADDRESS_FRAGMENT on SFBoShipment {
    shipmentAddress {
      fullName
      phoneNumber
      address
      subDistrict
      district
      district
      province
      postalCode
      country
      note
    }
  }
`;

export const SFBO_SHIPMENTS_ORDER_FRAGMENT = gql`
  fragment SFBO_SHIPMENTS_ORDER_FRAGMENT on SFBoShipment {
    order {
      ... on SFBoPaymentSalesOrder {
        id
        orderNo
      }
      ... on SFBoPaymentRedemptionOrder {
        id
        orderNo
      }
      ... on SFBoPaymentTrialOrder {
        id
        orderNo
      }
    }
  }
`;

export const SFBO_SHIPMENTS = gql`
  query sfBoShipments($search: SFBoSearchShipmentInput, $paging: PageInput, $order: OrderInput) {
    sfBoShipments(search: $search, paging: $paging, order: $order) {
      total
      shipments {
        id
        userId
        shipmentMethod
        SKUCode
        SKUCategory
        orderItemId
        orderId
        status
        createdAt
        updatedAt
        ...SFBO_SHIPMENTS_DELIVERY_FRAGMENT
        ...SFBO_SHIPMENTS_ORDER_FRAGMENT
      }
    }
  }
  ${SFBO_SHIPMENTS_DELIVERY_FRAGMENT}
  ${SFBO_SHIPMENTS_ORDER_FRAGMENT}
`;

export const SFBO_SHIPMENT = gql`
  query sfBoShipment($id: String) {
    sfBoShipment(id: $id) {
      id
      userId
      shipmentMethod
      SKUCode
      SKUCategory
      orderItemId
      orderId
      status
      ...SFBO_SHIPMENTS_DELIVERY_FRAGMENT
      ...SFBO_SHIPMENTS_ADDRESS_FRAGMENT
    }
  }
  ${SFBO_SHIPMENTS_DELIVERY_FRAGMENT}
  ${SFBO_SHIPMENTS_ADDRESS_FRAGMENT}
`;

export const SFBO_EXPORT_SHIPMENT_REPORTS = gql`
  query exportShipmentReports($search: SFBoExportShipmentReportsInput) {
    sfBoExportShipmentReports(search: $search) {
      base64ExportShipmentReportsFile
    }
  }
`;

export const SFBO_SHIPMENT_METHOD = {
  DELIVER_BY_SUPPLIER: 'DELIVER_BY_SUPPLIER',
  ONLINE: 'ONLINE',
};

export const SFBO_SHIPMENT_PROVIDER = {
  FLASH_EXPRESS: 'FLASH_EXPRESS',
  KERRY: 'KERRY',
  TP_EMS: 'TP_EMS',
  TP_REG: 'TP_REG',
  BEST: 'BEST',
  DHL: 'DHL',
  ALP: 'ALP',
  NINJA: 'NINJA',
  JT: 'JT',
  SCG: 'SCG',
  NIM: 'NIM',
  OTHER: 'OTHER',
  //Official is  Customer's Platform such as Learnneo
  OFFICIAL: 'OFFICIAL',
};
