import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { Icon, List } from 'semantic-ui-react';
import prettyBytes from 'pretty-bytes';

import { FONT_SIZE, COLOR } from 'Components/Theme';
import { Box, Flex, Text } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import ConfirmationButton from 'Components/ConfirmationButton';

const DropzoneButton = styled(Dropzone)`
  height: 0%;
  cursor: pointer;
`;

const AddFileButton = ({ children, ...props }) => (
  <DropzoneButton {...props} multiple>
    <Button {...props} primary circular>
      {children}
    </Button>
  </DropzoneButton>
);

const NoFileUploadText = () => (
  <Box>
    <Icon name="file" /> No File Upload
  </Box>
);

function FileUpload(props) {
  const { acceptFileTypes, uploadList, setUploadList, isUploading } = props;
  // FYI: rejectList stored upload file is rejected while dropping that nowhere use for now
  const [rejectedList, setRejectedList] = useState([]);

  const handleDrop = (accepted, rejected) => {
    setUploadList([...uploadList, ...accepted]);
    setRejectedList([...rejectedList, ...rejected]);
  };

  const handleClear = () => {
    setUploadList([]);
    setRejectedList([]);
  };

  const handleRemoveFileFromList = (index) => {
    setUploadList([...uploadList.slice(0, index), ...uploadList.slice(index + 1)]);
  };

  const UploadFileList = (props) => {
    const { uploadList } = props;
    return (
      uploadList.length > 0 && (
        <List divided verticalAlign="middle">
          {uploadList.map((ul, index) => (
            <List.Item key={index}>
              <List.Content floated="right">
                <ConfirmationButton
                  buttonStyle={{
                    primary: false,
                    circular: true,
                    size: 'small',
                    type: 'button',
                  }}
                  headerText={`Delete?`}
                  contentText={`File ${ul.name} will be deleted from list.`}
                  confirmText={'Delete'}
                  onConfirm={() => {
                    handleRemoveFileFromList(index);
                  }}
                >
                  <Icon name="x" /> Remove
                </ConfirmationButton>
              </List.Content>
              <Icon name="file alternate outline" size="large" />
              <List.Content>
                <List.Header>{ul.name}</List.Header>
                {prettyBytes(ul.size)}
              </List.Content>
            </List.Item>
          ))}
        </List>
      )
    );
  };

  return (
    <Flex flexWrap="wrap">
      <Box width={['100%']}>
        <Flex justifyContent="space-between">
          <Text fontSize={FONT_SIZE.normal} color={COLOR.warning}>
            <Icon name="warning circle" />
            Support {acceptFileTypes.map((ext) => ext.replace('.', '')).join(', ')} type file only
          </Text>
          <Flex>
            <AddFileButton
              onDrop={handleDrop}
              loading={!!isUploading}
              disabled={!!isUploading}
              accept={acceptFileTypes}
              multiple
            >
              <Icon name="upload" />
              Add a User List From File
            </AddFileButton>
            <ConfirmationButton
              loading={!!isUploading}
              headerText={`Clear?`}
              contentText={'All files will be cleared from the list.'}
              confirmText={'Clear'}
              buttonStyle={{ circular: true, disabled: !!isUploading }}
              onConfirm={handleClear}
            >
              Remove All User Lists
            </ConfirmationButton>
          </Flex>
        </Flex>
      </Box>
      <Box width={['100%']}>
        <Text fontSize={FONT_SIZE.normal} fontWeight={'bold'} mb={10}>
          File List
        </Text>
        {uploadList.length > 0 ? (
          <UploadFileList uploadList={uploadList} acceptFileTypes={acceptFileTypes} />
        ) : (
          <NoFileUploadText />
        )}
      </Box>
    </Flex>
  );
}

FileUpload.propTypes = {
  acceptFileTypes: PropTypes.array,
  onUpload: PropTypes.func,
  uploadList: PropTypes.array,
  setUploadList: PropTypes.func,
  isUploading: PropTypes.bool,
};

export default FileUpload;
