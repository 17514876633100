export const STATUS = {
  PUBLISHED: 'PUBLISHED',
  SCHEDULED: 'SCHEDULED',
  DRAFT: 'DRAFT',
};

export const ANNOUNCEMENT_CATEGORY = {
  COURSE: 'COURSE',
  COURSE_WITH_PUSH_NOTIFICATION: 'COURSE_WITH_PUSH_NOTIFICATION',
  COURSE_WITH_EMAIL: 'COURSE_WITH_EMAIL',
  COURSE_WITH_PUSH_NOTIFICATION_AND_EMAIL: 'COURSE_WITH_PUSH_NOTIFICATION_AND_EMAIL',
  NEWS: 'NEWS',
  REMINDER: 'REMINDER',
  BROWSE_BANNERS: 'BROWSE_BANNERS',
  NEW_CONTENT: 'NEW_CONTENT',
  MARKETING: 'MARKETING',
};

export const ANNOUNCEMENT_INPUT_LABEL = {
  BANNER: 'Banner',
  DESCRIPTION: 'Message',
};

export const RELATION_TYPE = {
  GLOBAL: 'GLOBAL',
  COURSE: 'COURSE',
};

export const ANNOUNCEMENT_CONTENT_TYPE = {
  HTML: 'html',
  MARKDOWN: 'markdowm',
  BANNER: 'banner',
};

export const ANNOUNCEMENT_PUBLISH_TYPE = {
  EMAIL: 'EMAIL',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
};

export const PDPA_TEXT = {
  DESCRIPTION:
    'ไม่ควรแจ้งผู้เรียน ข่าวสารหรือข้อความทางการตลาด เพราะผู้เรียนบางคนเลือกที่จะไม่ยินยอมให้ประมวลข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์ทางการตลาด ตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล (PDPA)',
};
