import React from 'react';
import { compose, mapProps } from 'recompose';
import _ from 'lodash';

import QueryComponent from '../../../../GraphQL/util/QueryComponent';
import {
  getStudentProgressReports,
  getSingleCourseV1,
} from '../../../../GraphQL/query/Courses.query';
import { getRouteInfo } from '../utils';

import StatisticsView from './Statistics.view';

const convertToHourAndMinute = (hour) => {
  return {
    hour: Math.floor(hour),
    minute: Math.floor((hour * 60) % 60),
  };
};

const mapTableData = (tableData) => {
  if (!Array.isArray(tableData)) return [];

  return tableData.map((data) => {
    const learningTime = convertToHourAndMinute(data.learningHours);
    return {
      fullName: data.fullName,
      completedSection: data.completedSection,
      section: data.section,
      round: data.round,
      completedRate: `${data.completedRate}%`,
      learningHours: `${learningTime.hour}h ${learningTime.minute}m`,
      pretest: `${data.pretest}%`,
      posttest: `${data.posttest}%`,
      exam: `${data.exam}%`,
    };
  });
};

const StatisticsQuery = ({ courseId }) => (
  <QueryComponent
    query={getSingleCourseV1}
    variables={{ version: 'SKOOLDIO_V1', courseId: courseId }}
    loadingOverlay
  >
    {(courseData) => {
      const courseReferId = courseData.course.meta.referId;
      return (
        <QueryComponent
          query={getStudentProgressReports}
          variables={{ courseReferId }}
          loadingOverlay
        >
          {(data) => {
            return (
              <StatisticsView
                data={mapTableData(data.studentProgressReports)}
                courseReferId={courseReferId}
              />
            );
          }}
        </QueryComponent>
      );
    }}
  </QueryComponent>
);

export default compose(mapProps(getRouteInfo))(StatisticsQuery);
