import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Flex, Box, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const timeOptions = (maxNumber) => {
  const temp = [];
  for (let i = 0; i <= maxNumber; i++) {
    temp.push({ text: `${('0' + i).slice(-2)}`, value: `${i}` });
  }
  return temp;
};

const formatTimeToInput = (timeLimit) => {
  if (timeLimit) {
    const hour = Math.floor(timeLimit / (60 * 60));
    const minute = Math.floor((timeLimit - hour * 60 * 60) / 60);
    const second = Math.floor(timeLimit - hour * 60 * 60 - minute * 60);
    return { hour, minute, second };
  }
  return { hour: 0, minute: 0, second: 0 };
};

const getTimeLimit = ({ hour, minute, second }) => {
  const timeSecond = hour * 60 * 60 + minute * 60 + second;
  return isNaN(timeSecond) ? 0 : timeSecond;
};

const HiddenCheckboxInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: none;
`;

HiddenCheckboxInput.defaultProps = {
  type: 'checkbox',
};

const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
`;

const SwitchDisplay = styled((props) => <Box as="span" {...props} />)`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  ${({ checked, theme }) =>
    checked &&
    css`
      background-color: ${theme?.colors?.primary ?? '#2196f3'};
      &:before {
        -webkit-transform: translateX(36px);
        -ms-transform: translateX(36px);
        transform: translateX(36px);
      }
    `}
`;

const Switch = (props) => {
  const { checked, value, disabled, onChange = () => {} } = props;
  return (
    <SwitchWrapper>
      <HiddenCheckboxInput
        checked={checked}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <SwitchDisplay checked={checked} />
    </SwitchWrapper>
  );
};

const TimeLimitInput = ({ value: timeLimit, readOnly, onChange }) => {
  const [allowTime, setAllowTime] = useState(!!timeLimit);
  const timeLimitRef = useRef(timeLimit);
  const { hour, minute, second } = formatTimeToInput(timeLimit);

  const handleOnChange = useCallback(
    ({ hour, minute, second } = {}) => {
      const updatedTimeLimit = getTimeLimit({ hour, minute, second });
      onChange(
        {},
        {
          value: updatedTimeLimit,
        }
      );
    },
    [onChange]
  );

  const handleFillTime = () => {
    if (allowTime) {
      const { hour, minute, second } = formatTimeToInput(timeLimitRef.current);
      handleOnChange({ hour: hour, minute: minute, second: second });
    } else {
      timeLimitRef.current = timeLimit;
      handleOnChange();
    }
  };

  useEffect(handleFillTime, [allowTime]);

  const disabled = !allowTime || readOnly;
  return (
    <Flex flexWrap="wrap" alignItems="center" m={-2}>
      <Flex alignItems="center" p={2}>
        <Switch checked={allowTime} onChange={() => setAllowTime((allowTime) => !allowTime)} />
        <Box ml={1} />
        <Text>จับเวลา</Text>
      </Flex>
      {allowTime && (
        <>
          <Flex alignItems="center" p={2}>
            <Dropdown
              name="hour"
              compact
              search
              selection
              options={timeOptions(23)}
              disabled={disabled}
              value={`${hour}`}
              onChange={(_, { value }) => handleOnChange({ hour: parseInt(value), minute, second })}
            />
            <Box ml={1} />
            <Text>ชั่วโมง</Text>
          </Flex>
          <Flex alignItems="center" p={2}>
            <Dropdown
              name="minute"
              compact
              search
              selection
              value={`${minute}`}
              options={timeOptions(59)}
              disabled={disabled}
              onChange={(_, { value }) => handleOnChange({ hour, minute: parseInt(value), second })}
            />
            <Box ml={1} />
            <Text>นาที</Text>
          </Flex>
          <Flex alignItems="center" p={2}>
            <Dropdown
              name="second"
              compact
              search
              selection
              value={`${second}`}
              options={timeOptions(59)}
              disabled={disabled}
              onChange={(_, { value }) => handleOnChange({ hour, minute, second: parseInt(value) })}
            />
            <Box ml={1} />
            <Text>วินาที</Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};

TimeLimitInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(TimeLimitInput);
