import React, { Component } from 'react';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getSubscription } from '../../GraphQL/query/Products.query';
import { updateSubscriptionPlatformAndExternalIdMutation } from '../../GraphQL/mutation/Products.mutation';

import SubscriptionPlatformAndExternalIdForm from './SubscriptionPlatformAndExternalIdForm';

const resolveData = ({ sfBoProductSubscription }) => {
  return {
    externalConfig: {
      platform: _.get(sfBoProductSubscription, 'platform'),
      externalId: _.get(sfBoProductSubscription, 'externalId'),
    },
  };
};

class SubscriptionPlatformAndExternalIdEditor extends Component {
  render() {
    const { subscription = {} } = this.props;
    const { id, SKUCode } = subscription;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getSubscription}
          editMutation={updateSubscriptionPlatformAndExternalIdMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              platform: form.externalConfig.platform,
              externalId: form.externalConfig.platform ? form.externalConfig.externalId : '',
            },
          })}
        >
          {({ data, onSubmit }) => {
            return (
              <SubscriptionPlatformAndExternalIdForm
                initialData={resolveData(data)}
                onSubmit={onSubmit}
              />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default SubscriptionPlatformAndExternalIdEditor;
