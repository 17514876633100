export const CoursePermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'course.list-view',
    UPDATE: 'course.list-update',
    DELETE: 'course.list-delete',
  },
  OVERVIEW: {
    VIEW: 'course.overview-view',
    UPDATE: 'course.overview-update',
    DELETE: 'course.overview-delete',
  },
  ENROLLMENT: {
    VIEW: 'course.enrollment-view',
    UPDATE: 'course.enrollment-update',
    DELETE: 'course.enrollment-delete',
  },
  EXAM: {
    VIEW: 'course.exam-view',
    UPDATE: 'course.exam-update',
    DELETE: 'course.exam-delete',
  },
  LESSON: {
    VIEW: 'course.lesson-view',
    UPDATE: 'course.lesson-update',
    DELETE: 'course.lesson-delete',
  },
  USER_GROUP: {
    VIEW: 'course.userGroup-view',
    UPDATE: 'course.userGroup-update',
    DELETE: 'course.userGroup-delete',
  },
  ASSIGNMENT: {
    VIEW: 'course.assignment-view',
    UPDATE: 'course.assignment-update',
    DELETE: 'course.assignment-delete',
  },
  ANNOUNCEMENT: {
    VIEW: 'course.announcement-view',
    UPDATE: 'course.announcement-update',
    DELETE: 'course.announcement-delete',
  },
});
