import React, { Component } from 'react';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateOnlineCourseCreditPricingMutation } from '../../GraphQL/mutation/Products.mutation';

import OnlineCourseCreditPricingForm from './OnlineCourseCreditPricingForm';

const resolveData = ({ sfBoProductOnlineCourse }) => {
  return sfBoProductOnlineCourse;
};

class OnlineCourseCreditPricingEditor extends Component {
  render() {
    const { onlineCourse = {} } = this.props;
    const { id, SKUCode } = onlineCourse;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getOnlineCourse}
          editMutation={updateOnlineCourseCreditPricingMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={({ creditPrice }) => {
            return {
              id,
              data: {
                creditPrice,
                enableCreditPrice: !_.isNil(creditPrice),
              },
            };
          }}
        >
          {({ data, onSubmit }) => {
            return (
              <OnlineCourseCreditPricingForm initialData={resolveData(data)} onSubmit={onSubmit} />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default OnlineCourseCreditPricingEditor;
