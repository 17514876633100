import React, { useState, useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { isNil } from 'lodash';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const initialize = (value) => {
  return isNil(value) || value === '' ? false : value;
};

const PrerequisiteCheckbox = ({ value: initvalue, onChange }) => {
  const [isCheck, setIsCheck] = useState(initialize(initvalue));

  const handleChange = (value) => {
    onChange({}, { value });
  };

  useEffect(() => {
    handleChange(isCheck);
  }, [isCheck]);

  return (
    <Box width="100%" pl={3}>
      <Flex justifyContent="flex-start">
        <Checkbox
          label="ผู้เรียนต้องผ่านแบบทดสอบนี้"
          checked={isCheck}
          onChange={(_, data) => {
            setIsCheck(data.checked);
          }}
        />
      </Flex>
    </Box>
  );
};

export default withFormsy(PrerequisiteCheckbox);
