import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import { Button } from 'react-rte';
import numeral from 'numeral';

import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import withServerConfig from 'GraphQL/util/withServerConfig';
import withAppConfig from 'Util/hoc/withAppConfig';

import FileUploadManagerModal from '../../Lib/UploadComponent';

export const imageUploadPropTypes = {
  onImageUpload: PropTypes.func,
  uploadConfig: PropTypes.shape({
    assetType: PropTypes.oneOf(Object.values(ASSET_TYPE)).isRequired,
    assetKey: PropTypes.string.isRequired, // folder name on s3
  }),
  maxFileSize: PropTypes.number,
  serverConfig: PropTypes.any,
};

const RECOMMENDED_WIDTH = '300';
class ImageUpload extends Component {
  static propTypes = imageUploadPropTypes;

  static defaultProps = {
    maxFileSize: 5,
  };

  handleFileListRequest = async (request) => {
    const { client, uploadConfig } = this.props;
    const { data } = await client.mutate({
      mutation: getAssetList,
      variables: {
        type: uploadConfig.assetType,
        key: uploadConfig.assetKey,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { client, uploadConfig } = this.props;

    const { data } = await client.mutate({
      mutation: getUploadUrl,
      variables: {
        type: uploadConfig.assetType,
        key: uploadConfig.assetKey,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  handleSelectImage = (files = []) => {
    if (files && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      // uploadedImageWidth is a value of image width attribute in image dom
      const imageWidth = this.props.config?.richTextEditor?.uploadedImageWidth ?? RECOMMENDED_WIDTH;
      const options = {
        width: imageWidth,
      };
      // Now Support only one file upload
      const { key, bucket, url } = files[0];
      this.props.onImageUpload(
        {
          key,
          bucket,
          url,
        },
        options
      );
    }
  };

  getMaxFileSizeStr = () => {
    const { maxFileSize } = this.props;
    const fileSizeBytes = maxFileSize * 1000 * 1000;
    return numeral(fileSizeBytes).format('0b');
  };

  render() {
    const { serverConfig, maxFileSize } = this.props;
    return (
      <FileUploadManagerModal
        /**
         * uploadUrl, and listUrl are required in the FileUploadManagerModal component,
         * but they are only applied when uploading via REST api.
         * In this gql case, these values are used just to prevent errors in the component and the values can be anything.
         */
        uploadUrl={''}
        listUrl={''}
        keyGroup={this.props.uploadConfig.assetKey}
        dimmer="inverted"
        allowedContentTypes={['image/png', 'image/jpeg', 'image/gif']}
        defaultText={`Support JPG, PNG or GIF file with less than ${this.getMaxFileSizeStr()}`}
        maxFileSize={maxFileSize}
        maxSelection={1}
        customFileListUrlRequest={this.handleFileListRequest}
        customUploadUrlRequest={this.handleFileUploadUrlRequest}
        mode={serverConfig.assetUploadMode}
        onFileManagerDialogClose={this.handleSelectImage}
        // TODO: Replace UploadButton with iconButton for better UI
        trigger={<Button key={this.props.key}>Upload Image</Button>}
      />
    );
  }
}

export default compose(
  withApollo,
  withAppConfig(),
  withServerConfig({ configProp: 'serverConfig' })
)(ImageUpload);
