import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import ErrorView from 'Components/ErrorView';
import FormGenerator from 'Components/Form/FormGenerator';
import { CERTIFICATE_ITEM_OBJECT_TYPE, getCertificates } from 'GraphQL/query/Certificate.query';
import { getAllCourses } from 'GraphQL/query/Courses.query';
import {
  convertCertificateItems,
  getCertificateItemTypeOptions,
} from 'Routes/Certificates/CertificateUtil';

const genFields = (items, certificateCode, isLoading, isEdit) => {
  const typeFieldInputProps = { required: true };
  if (!isEdit) {
    typeFieldInputProps.defaultValue = CERTIFICATE_ITEM_OBJECT_TYPE.COURSE; // if create new certificate conditions, set default selected option to course
  }
  return [
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Type',
      inputName: 'objectType',
      options: getCertificateItemTypeOptions(),
      inputProps: typeFieldInputProps,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Code',
      inputName: 'objectCode',
      inputProps: {
        required: true,
        search: true,
        defaultValue: certificateCode,
        options: items,
        loading: isLoading,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Display Name',
      inputName: 'displayName',
      inputProps: {
        required: true,
      },
    },
  ];
};

const CertificateForm = (props) => {
  const { isEdit = false, onSubmit = () => {}, initialData } = props;
  const [certificateObjectType, setCertificateObjectType] = useState(
    initialData?.objectType ?? CERTIFICATE_ITEM_OBJECT_TYPE.COURSE
  );
  const [certificateCode, setCertificateCode] = useState(initialData?.objectCode);

  let query;
  let queryName;
  let variables;
  let objectItems = [];

  switch (certificateObjectType) {
    case CERTIFICATE_ITEM_OBJECT_TYPE.COURSE:
      query = getAllCourses;
      queryName = 'courses';
      variables = {
        search: { version: 'SKOOLDIO_V2' },
      };
      break;
    case CERTIFICATE_ITEM_OBJECT_TYPE.CERTIFICATE:
      query = getCertificates;
      queryName = 'certificates';
      variables = {
        search: {},
        order: { field: 'name', type: 'DESC' },
      };
      break;
    default:
  }

  const { data, loading, error } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
  });

  if (!loading && error) {
    return <ErrorView message={error.message} />;
  } else if (!loading && data && data.length !== 0 && !error) {
    objectItems = convertCertificateItems(data[queryName][queryName], certificateObjectType);
  }

  const handleFormChange = (formValue) => {
    setCertificateObjectType(formValue.objectType);
    if (!loading && objectItems.includes(formValue.objectCode)) {
      setCertificateCode(formValue.objectCode);
    } else {
      setCertificateCode(''); // to empty form value
    }
  };

  return (
    <FormGenerator
      fields={genFields(objectItems, certificateCode, loading, isEdit)}
      onSubmit={onSubmit}
      submitText={isEdit ? 'Update' : 'Add'}
      cancelText="Cancel"
      showCancel
      onChange={handleFormChange}
      {...props}
    />
  );
};

CertificateForm.propTypes = {
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default CertificateForm;
