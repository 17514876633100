import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { getParentStudents } from 'GraphQL/LSP/query/Profile.query'; // @TODO: Change query

const columns = (handleEdit, variables) => [
  {
    Header: 'ระดับชั้น',
    accessor: 'schoolGrade.name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Code',
    accessor: 'code',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'คำนำหน้า',
    accessor: 'prefix',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'ชื่อ',
    accessor: 'firstName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'นามสกุล',
    accessor: 'lastName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'ชื่อเล่น',
    accessor: 'nickName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  // @TODO: Optional
  // {
  //   Header: 'Action',
  //   accessor: 'id',
  //   width: 100,
  //   isSearchAble: false,
  //   Cell: function ActionCell({ original }) {
  //     const { id } = original;
  //     // @TODO: Modal here
  //     return (
  //       <Fragment>
  //         <Button.Group>
  //           <Button basic size="mini" icon compact>
  //             <Icon name="edit" />
  //           </Button>
  //         </Button.Group>
  //       </Fragment>
  //     );
  //   },
  // },
];

class ParentStudentsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { parentId: props.userId },
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  profileMutate = (mutation, userId) => {
    const { search, paging, order } = this.state;
    this.props.client
      .mutate({
        mutation,
        variables: { userId },
        refetchQueries: [
          {
            query: getParentStudents,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, parentId: this.props.userId }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.boParentStudents.profiles;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <Box>
        <QueryTable
          type={'boParentStudents'}
          showSearchPanel={false}
          resolveData={this.resolveData}
          columns={columns(this.handleEdit, variables)}
          query={getParentStudents}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Box>
    );
  }
}

export default withApollo(ParentStudentsDetails);
