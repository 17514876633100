import { Box, ButtonFilled, Flex } from '@skooldio/skooldio-ui-components-core';
import React from 'react';
import PropTypes from 'prop-types';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Formsy, { withFormsy } from 'formsy-react';
import { Input } from 'formsy-semantic-ui-react';
import { Label } from 'semantic-ui-react';

import { COLOR } from 'Components/Theme';
import { Text as BaseText } from 'Components/Base';

import Card from '../Card/Card';
import TwoColumnGrid from '../TwoColumnGrid/TwoColumnGrid';

const getStatusText = (status) => {
  switch (status) {
    case 'PASSED':
      return 'ผ่าน';
    case 'FAILED':
      return 'ไม่ผ่าน';
    default:
      return '-';
  }
};

const GRADING_TYPE = {
  PASS_FAIL: 'PASS_FAIL',
  SCORE: 'SCORE',
};

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: '8px 16px',
      lineHeight: 1.5,
      fontFamily: 'inherit',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: COLOR.greyscale.grey4,
      },
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'inherit',
    },
  },
});

const Text = (props) => <BaseText lineHeight="1.5" {...props} />;

const RadioForm = withFormsy((props) => {
  const classes = useStyles();
  return (
    <Box pl={3}>
      <FormControl component="fieldset">
        <RadioGroup
          name="status"
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          row={true}
        >
          <FormControlLabel value="passed" control={<Radio />} label="ผ่าน" classes={classes} />
          <FormControlLabel value="failed" control={<Radio />} label="ไม่ผ่าน" classes={classes} />
        </RadioGroup>
      </FormControl>
      {!props.isPristine && !props.isValid && <Text color="danger">กรุณากรอกสถานะ</Text>}
    </Box>
  );
});

const FormTextArea = withFormsy((props) => {
  const classes = useStyles();
  return (
    <TextField
      multiline
      classes={classes}
      variant="outlined"
      rows={3}
      maxRows={4}
      placeholder={'ระบุความเห็น...'}
      onChange={(e) => props.setValue(e.target.value)}
    />
  );
});

const errorLabel = <Label color="red" pointing />;

function AssignmentReviewForm({
  reviewable,
  reviewNote,
  status,
  score,
  onSubmit,
  disabled,
  gradingType,
  fullscore,
  ...restProps
}) {
  function scoreValidations(_, value) {
    const score = value;
    if (!score) return 'Score is required';
    return score > -1 && score <= fullscore
      ? true
      : `Score must be greater than 0 and not exceed ${fullscore}`;
  }

  return (
    <Card {...restProps}>
      <Formsy onValidSubmit={onSubmit}>
        <TwoColumnGrid>
          {/* line 1 */}
          <Flex pt={2}>
            <ListAltOutlinedIcon />
            <Text pl={3}>{gradingType === GRADING_TYPE.PASS_FAIL ? 'สถานะ' : 'คะแนน'}</Text>
          </Flex>
          {reviewable ? (
            gradingType === GRADING_TYPE.PASS_FAIL ? (
              <RadioForm name="status" required />
            ) : (
              <Flex width={180}>
                <Input
                  instantValidation
                  style={{ display: 'grid', width: 80 }}
                  name={'score'}
                  type="number"
                  step={1}
                  data-testid={'score-input'}
                  defaultValue={0}
                  min={0}
                  max={fullscore}
                  onInput={(e) => {
                    const integerValue = parseInt(e.target.value);
                    const isNumber = !isNaN(integerValue);
                    if (isNumber) {
                      if (integerValue >= 0 && integerValue <= fullscore) {
                        e.target.value = integerValue.toString();
                      } else {
                        e.target.value = integerValue < 0 ? '0' : fullscore;
                      }
                    } else {
                      e.target.value = '0';
                    }
                  }}
                  validations={{ scoreValidations }}
                  validationErrors={{ isDefaultRequiredValue: 'Score is required' }}
                  errorLabel={errorLabel}
                />
                <Text fontSize="2rem" mx={2}>
                  /
                </Text>
                <Input
                  name={'fullscore'}
                  data-testid={'fullscore-input'}
                  defaultValue={fullscore}
                  style={{ width: 80 }}
                  disabled
                />
              </Flex>
            )
          ) : gradingType === GRADING_TYPE.PASS_FAIL ? (
            <Text px={3} pt={2}>
              {getStatusText(status)}
            </Text>
          ) : (
            <Text px={3} pt={2}>
              {`${score} / ${fullscore}`}
            </Text>
          )}
          {/* line 2*/}
          <Flex pt={2}>
            <RateReviewOutlinedIcon />
            <Text pl={3}>ความคิดเห็น</Text>
          </Flex>
          {reviewable ? <FormTextArea name="reviewNote" /> : <Text px={3}>{reviewNote}</Text>}
          {/* line 3*/}
          <Box />
          <ButtonFilled
            type="submit"
            maxWidth="165px"
            color={!reviewable ? 'greyscale.grey3' : 'primary'}
            disabled={!reviewable || disabled}
            fontFamily="inherit"
          >
            <Text color="white">ส่ง</Text>
          </ButtonFilled>
        </TwoColumnGrid>
      </Formsy>
    </Card>
  );
}

AssignmentReviewForm.propTypes = {
  reviewable: PropTypes.bool,
  reviewNote: PropTypes.string,
  status: PropTypes.string,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

AssignmentReviewForm.defaultProps = {
  isReviewed: false,
  disabled: false,
  onSubmit: () => {},
};

export default AssignmentReviewForm;
