import React, { Component } from 'react';

import FileImport from '../../Containers/FileImport';
import { FILE_TYPE } from '../../common';

export default class ImportStudentsRoute extends Component {
  render() {
    return <FileImport title="Create School" type={FILE_TYPE.NEW_SCHOOL} />;
  }
}
