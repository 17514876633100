import { Provider } from 'react-redux';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';

import { HTMLMeta } from 'Containers/HTMLMeta';
import useGoogleTagManager from 'Util/Hooks/useGoogleTagManager';

import getConfig from './Util/Config';
import RouteApp from './Routes/Main';
import store from './Redux/store';
import apolloClient from './GraphQL';

const App = () => {
  const { googleTagManager: gtmConfigs } = getConfig();

  useGoogleTagManager(gtmConfigs);

  const { theme } = getConfig();

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme} style={{ height: '100%' }}>
          <>
            <HTMLMeta />
            <RouteApp />
          </>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
