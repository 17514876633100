import '../public/styles/semantic.min.css';
import 'react-quill/dist/quill.snow.css';
import 'dropzone/dist/min/dropzone.min.css';
import '../public/assets/fonts/fontface.css';

import './index.css';

import React from 'react';
import { render } from 'react-dom';

import App from './App';

render(<App />, document.querySelector('#app'));
