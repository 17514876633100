import gql from 'graphql-tag';

import { ShortMaterialFragment } from './Material.query';

export const COURSE_VERSION = {
  SKOOLDIO_V1: 'SKOOLDIO_V1',
  SKOOLDIO_V2: 'SKOOLDIO_V2',
};

const SkooldioV1MetaFragment = gql`
  fragment SkooldioV1MetaFragment on Course {
    v1meta {
      imageUrl
      videoUrl
      theme
      shortDescription
      type
    }
  }
`;

export const SmallCourseFragment = gql`
  fragment SmallCourseFragment on Course {
    id
    courseVersion
    title
    language
    courseCode
    instructors {
      id
      name
      title
      position
      longDescription
    }
    v1meta {
      type
    }
    meta {
      id
      referId
      permalink
      status
      createdAt
      updatedAt
      revision
      latestPublishedRevision
      courseOwnerId
      status
      instructors {
        id
        name
        title
        position
        longDescription
      }
      themeColor {
        primaryColor
      }
      logoUrl
      forum {
        type
        url
      }
      videoControllerMode
    }
  }
`;

export const MediumCourseFragment = gql`
  fragment MediumCourseFragment on Course {
    id
    ...SmallCourseFragment
    subTitle
    language
    coverImageUrl
    description
    courseCode
    courseLevel
    courseOwnerId
    instructors {
      id
      name
      title
      position
      longDescription
    }
  }
  ${SmallCourseFragment}
`;

export const CourseItemMetaFragment = gql`
  fragment CourseItemMetaFragment on CourseItem {
    id
    title
    description
    type
    ordering
    isPrerequisite
  }
`;

export const CourseContentFragment = gql`
  fragment CourseContentFragment on CourseContent {
    id
    sections {
      id
      ...CourseItemMetaFragment
      contentType
      subsections {
        id
        ...CourseItemMetaFragment
        contentType
        canPreview
      }
    }
  }
  ${CourseItemMetaFragment}
`;

export const UserAssignmentFragment = gql`
  fragment UserAssignmentFragment on UserAssignment {
    id
    userId
    fullName
    type
    content {
      ... on UserAssignmentUrl {
        url
      }
      ... on UserAssignmentFile {
        key
        bucket
        provider
        fileName
      }
    }
    assignmentDetail {
      id
      title
      description
      detail {
        type
        content
      }
      dueDate
      fullScore
      gradingType
    }
    status
    score
    studentComment
    submittedAt
    resultStatus
    reviewedComment
    reviewedAt
  }
`;

export const getSubSectionWithMaterials = gql`
  query courseItem($version: COURSE_VERSION!, $id: ID!) {
    courseSubsection(version: $version, id: $id) {
      id
      ...CourseItemMetaFragment
      contentType
      assets {
        contentAssets {
          id
          rawContent
          ...ShortMaterialFragment
        }
        materials {
          id
          ...ShortMaterialFragment
        }
      }
    }
  }
  ${ShortMaterialFragment}
  ${CourseItemMetaFragment}
`;

export const getAllCourses = gql`
  query getAllCourses($search: CoursesSearchInput, $paging: PageInput, $order: OrderInput) {
    courses(search: $search, paging: $paging, order: $order) {
      total
      courses {
        id
        title
        courseCode
        courseLevel
        courseOwnerId
        ...SmallCourseFragment
      }
    }
  }
  ${SmallCourseFragment}
`;

export const COURSE_TEAM_FRAGMENT = gql`
  fragment COURSE_TEAM_FRAGMENT on Course {
    teams {
      name
      id
      description
    }
  }
`;

export const getSingleCourseV1 = gql`
  query course(
    $version: COURSE_VERSION!
    $courseId: ID!
    $isV1: Boolean = false
    $enabledContentTeam: Boolean = false
  ) {
    course(version: $version, courseId: $courseId) {
      id
      ...MediumCourseFragment
      ...SkooldioV1MetaFragment @include(if: $isV1)
      ...COURSE_TEAM_FRAGMENT @include(if: $enabledContentTeam)
    }
  }
  ${MediumCourseFragment}
  ${SkooldioV1MetaFragment}
  ${COURSE_TEAM_FRAGMENT}
`;

export const getCourseOutline = gql`
  query courseOutline($version: COURSE_VERSION!, $courseId: ID!) {
    course(version: $version, courseId: $courseId) {
      id
      ...SmallCourseFragment
      contents {
        id
        ...CourseContentFragment
      }
    }
  }
  ${SmallCourseFragment}
  ${CourseContentFragment}
`;

export const getCourseMeta = gql`
  query getCourseMeta($version: COURSE_VERSION!, $courseId: ID!) {
    course(version: $version, courseId: $courseId) {
      id
      ...SmallCourseFragment
    }
  }
  ${SmallCourseFragment}
`;

export const getCourseUsers = gql`
  query getCourseUsers(
    $version: COURSE_VERSION!
    $courseId: ID!
    $permission: USER_COURSE_PERMISSION!
  ) {
    courseUsers(version: $version, courseId: $courseId, permission: $permission) {
      id
      userId
      permission
      user {
        id
        firstname
        lastname
      }
    }
  }
`;

export const getStudentProgressReports = gql`
  query getStudentProgressReports($courseReferId: ID!) {
    studentProgressReports(courseReferId: $courseReferId) {
      fullName
      completedRate
      completedSection
      learningHours
      pretest
      posttest
      exam
      section
      round
    }
  }
`;

export const getCourseImageList = gql`
  query getCourseImageList($courseId: ID!) {
    listCourseImages(courseId: $courseId) {
      list
      groupKey
      bucket
      raw
    }
  }
`;

export const getCourseTotalVideoTime = gql`
  query getCourseVideoTotalTime($courseId: ID!) {
    courseTotalVideoTime(courseId: $courseId) {
      duration
    }
  }
`;

export const getUserAssignment = gql`
  query getUserAssignmentById($userAssignmentId: ID!) {
    userAssignmentById(userAssignmentId: $userAssignmentId) {
      ...UserAssignmentFragment
    }
  }
  ${UserAssignmentFragment}
`;

export const downloadSignedURL = gql`
  query downloadSignedURL($userAssignmentId: ID!) {
    downloadSignedURL(userAssignmentId: $userAssignmentId) {
      url
    }
  }
`;

export const getUserAssignmentSubsectionReports = gql`
  query userAssignmentSubsectionReports(
    $sourceCourseId: String!
    $sourceCourseItemId: String!
    $search: UserAssignmentSubsectionReportSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    userAssignmentSubsectionReports(
      sourceCourseId: $sourceCourseId
      sourceCourseItemId: $sourceCourseItemId
      search: $search
      paging: $paging
      order: $order
    ) {
      total
      userAssignmentSubsectionReports {
        id
        sourceCourseItemId
        userId
        firstname
        lastname
        assignmentStatus
        assignmentResultStatus
        assignmentScore
        submittedAt
        reviewedAt
        assignmentDetail {
          id
          title
          description
          detail {
            type
            content
          }
          dueDate
          fullScore
          gradingType
        }
      }
    }
  }
`;

export const getUserAssignmentCourseSummary = gql`
  query userAssignmentCourseSummary($sourceCourseId: ID!) {
    userAssignmentCourseSummary(sourceCourseId: $sourceCourseId) {
      id
      title
      sections {
        id
        title
        userAssignmentReports {
          sourceCourseItemId
          title
          totalGraded
          totalPassed
          totalFailed
          totalSubmitted
        }
      }
    }
  }
`;

export const getExportUserAssignmentCourseGradingResultReport = gql`
  query exportUserAssignmentCourseGradingResultReport($sourceCourseId: String!) {
    exportUserAssignmentCourseGradingResultReport(sourceCourseId: $sourceCourseId) {
      body
      contentType
      fileName
    }
  }
`;
