import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';

import CreateEditTagForm from './CreateEditTagForm';

export default class TagModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isView: PropTypes.bool,
    isOpen: PropTypes.bool,
    trigger: PropTypes.element,
    tagId: PropTypes.string,
    variables: PropTypes.object,
  };
  static defaultProps = {
    isEdit: false,
    isView: false,
  };
  render() {
    const { variables, isEdit, isView, trigger, tagId } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'Edit Tag' : 'Create Tag'}</Modal.Header>
            <Modal.Content>
              <CreateEditTagForm
                isEdit={isEdit}
                isView={isView}
                onComplete={closeModal}
                onCancelled={closeModal}
                searchVariables={variables}
                tagId={tagId}
              />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
