import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-rte';
import { AtomicBlockUtils } from 'draft-js';
import { ENTITY_TYPE } from 'draft-js-utils';

import ImageUpload, { imageUploadPropTypes } from './ImageUpload';

const UploadImageButton = ({ editorState, onImageUpload, uploadConfig, maxFileSize }) => {
  return (
    <ButtonGroup>
      <ImageUpload
        key={'image-button'}
        uploadConfig={uploadConfig}
        onImageUpload={(data, options) => {
          const { url } = data || {};
          const contentState = editorState.getCurrentContent();
          contentState.createEntity(ENTITY_TYPE.IMAGE, 'IMMUTABLE', {
            src: url,
            width: options?.width,
          });
          const entityKey = contentState.getLastCreatedEntityKey();

          const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

          onImageUpload(newEditorState);
        }}
        maxFileSize={maxFileSize}
      />
    </ButtonGroup>
  );
};

UploadImageButton.propTypes = {
  editorState: PropTypes.object,
  onImageUpload: PropTypes.func,
  uploadConfig: imageUploadPropTypes.uploadConfig,
  maxFileSize: PropTypes.number,
};

export default UploadImageButton;
