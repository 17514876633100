import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import AppRoute, { GenRoute } from '../../Util/Route';

import PaymentLayout from './Components/PaymentLayout';
import ApprovalPayment from './Approval';
import ApprovedPayment from './Approved';
import OrderPayment from './Order';
import RejectedPayment from './Rejected';
import ExpiredPayment from './Expired';
import PurchaseLog from './PurchaseLog';
import PurchaseOrderLog from './PurchaseOrderLog';
import TransactionLog from './TransactionLog';
const PaymentIndex = () => <Redirect to={{ pathname: '/payments/waiting' }} />;
const defaultPayment = {
  path: '/payments',
  exact: true,
  private: true,
  roles: [
    APP_PERMISSION.COMMON.ADMINISTRATOR,
    APP_PERMISSION.COMMON.MARKETER,
    APP_PERMISSION.SALESV1.LIST.VIEW,
  ],
  route: PaymentIndex,
};
const paymentMenus = [
  {
    path: '/payments/order',
    title: 'PLACE ORDER',
    menuName: 'Place Order',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.MARKETER,
      APP_PERMISSION.SALESV1.LIST.VIEW,
    ],
    route: OrderPayment,
    icon: 'shopping cart',
  },
  {
    path: '/payments/waiting',
    title: 'NEED APPROVAL',
    menuName: 'Need Approval',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.MARKETER,
      APP_PERMISSION.SALESV1.LIST.VIEW,
    ],
    route: ApprovalPayment,
    icon: 'hourglass start',
  },
  {
    path: '/payments/approved',
    title: 'APPROVED',
    menuName: 'Approved',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.MARKETER,
      APP_PERMISSION.SALESV1.LIST.VIEW,
    ],
    route: ApprovedPayment,
    icon: 'check',
  },
  {
    path: '/payments/rejected',
    title: 'REJECTED',
    menuName: 'Rejected',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.MARKETER,
      APP_PERMISSION.SALESV1.LIST.VIEW,
    ],
    route: RejectedPayment,
    icon: 'times',
  },
  {
    path: '/payments/expired',
    title: 'EXPIRED',
    menuName: 'Expired',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.MARKETER,
      APP_PERMISSION.SALESV1.LIST.VIEW,
    ],
    route: ExpiredPayment,
    icon: 'calendar',
  },
];

export default class PaymentRoute extends Component {
  render() {
    return (
      <PaymentLayout menus={paymentMenus}>
        <GenRoute menus={[...paymentMenus, defaultPayment]} />
      </PaymentLayout>
    );
  }
}
