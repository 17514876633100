import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';
import getDisplayName from 'Util/LSP/getDisplayName';

const fields = (syllabusData, studentData, schoolTermData) => [
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Syllabus',
    inputName: 'syllabusId',
    options: syllabusData.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    })),
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'นักเรียน',
    inputName: 'studentId',
    options: studentData.map((student) => ({
      key: student.id,
      value: student.id,
      text: getDisplayName(student),
    })),
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'เทอม',
    inputName: 'schoolTermId',
    options: schoolTermData.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    })),
    inputProps: {
      required: true,
    },
  },
];

export default class SingleAssignmentForm extends Component {
  static propTypes = {
    onCreateSingleAssignment: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateSingleAssignment: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateSingleAssignment(form);
  };

  render() {
    const { isEdit, syllabusData, studentData, schoolTermData } = this.props;
    return (
      <FormGenerator
        fields={fields(syllabusData, studentData, schoolTermData)}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
