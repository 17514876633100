import gql from 'graphql-tag';

export const getSchoolTerms = gql`
  query boSchoolTerms($search: BOSearchSchoolTermsInput, $paging: PageInput, $order: OrderInput) {
    boSchoolTerms(search: $search, paging: $paging, order: $order) {
      total
      schoolTerms {
        id
        name
        code
        startDate
        endDate
        schoolYear
        shortName
      }
    }
  }
`;

export const getAllSchoolTerms = gql`
  query boAllSchoolTerms {
    boAllSchoolTerms {
      id
      name
      code
      startDate
      endDate
      schoolYear
    }
  }
`;

export const getSchoolTerm = gql`
  query boSchoolTerm($id: ID!) {
    boSchoolTerm(id: $id) {
      id
      name
      code
      startDate
      endDate
      schoolYear
    }
  }
`;

export const getSchoolGrades = gql`
  query boSchoolGrades($search: BOSearchSchoolGradesInput, $paging: PageInput, $order: OrderInput) {
    boSchoolGrades(search: $search, paging: $paging, order: $order) {
      total
      schoolGrades {
        id
        name
        code
      }
    }
  }
`;

export const getAllSchoolGrades = gql`
  query boAllSchoolGrades {
    boAllSchoolGrades {
      id
      name
      code
    }
  }
`;

export const getSchoolGrade = gql`
  query boSchoolGrade($id: ID!) {
    boSchoolGrade(id: $id) {
      id
      name
      code
    }
  }
`;

export const getSchoolProgrammes = gql`
  query boSchoolProgrammes(
    $search: BOSearchSchoolProgrammesInput
    $paging: PageInput
    $order: OrderInput
  ) {
    boSchoolProgrammes(search: $search, paging: $paging, order: $order) {
      total
      schoolProgrammes {
        id
        name
        code
      }
    }
  }
`;

export const getAllSchoolProgrammes = gql`
  query boAllSchoolProgrammes {
    boAllSchoolProgrammes {
      id
      name
      code
    }
  }
`;

export const getSchoolProgramme = gql`
  query boSchoolProgramme($id: ID!) {
    boSchoolProgramme(id: $id) {
      id
      name
      code
    }
  }
`;

export const getCareerPlans = gql`
  query boCareerPlans($search: BOSearchCareerPlansInput, $paging: PageInput, $order: OrderInput) {
    boCareerPlans(search: $search, paging: $paging, order: $order) {
      total
      careerPlans {
        id
        name
        code
      }
    }
  }
`;

export const getAllCareerPlans = gql`
  query boAllCareerPlans {
    boAllCareerPlans {
      id
      name
      code
    }
  }
`;

export const getCareerPlan = gql`
  query boCareerPlan($id: ID!) {
    boCareerPlan(id: $id) {
      id
      name
      code
    }
  }
`;
