import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ApolloConsumer } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import { myProfile } from 'Redux/user-profile/selectors';
import { getProfile } from 'Redux/user-profile/actions';
import withAppConfig from 'src/Util/hoc/withAppConfig';
import { getSingleMaterial, getAssetList } from 'GraphQL/query/Material.query';
import { getSubSectionWithMaterials, getCourseMeta } from 'GraphQL/query/Courses.query';
import { getConfig, productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';
import QueryComponent from 'GraphQL/util/QueryComponent';
import withServerConfig from 'GraphQL/util/withServerConfig';
import { generateCourseAssetSignedUrls } from 'GraphQL/mutation/Courses.mutation';
import { generateSignedUrl } from 'src/Util/ExternalAdapter/Api/ByteArkApi';
import WistiaVideo from 'Components/AssetComponents/WistiaVideo/WistiaVideo';
import VimeoVideo from 'Components/AssetComponents/VimeoVideo/VimeoVideo';

import { SemanticButton as Button } from '../../Components/Base/Button';
import {
  editMaterial,
  addMaterial,
  deleteMaterial,
  genCourseAssetInput,
  genCourseAssetEditInput,
  getAssetFilesUploadUrl,
  MATERIAL_TYPE,
} from '../../GraphQL/mutation/Material.mutation';
import { MATERIAL_TYPES, MATERIAL_TYPES_STRING } from '../../Components/SubSection';
import { resolveMaterialDataKey } from '../../Components/SubSection/util';
import VideoSlides from '../../Components/AssetComponents/VideoSlides';
import YoutubeVideo from '../../Components/AssetComponents/YoutubeVideo';
import MindstampVideo from '../../Components/AssetComponents/MindstampVideo';
import Article from '../../Components/AssetComponents/Article';
import { VideoManagement } from '../VideoManagement';
import FileManagement from '../../Components/AssetComponents/FileManagement';
import Quiz from '../../Components/AssetComponents/Quiz';
import QuizSimple from '../../Components/AssetComponents/QuizSimple';
import LiveCode from '../../Components/AssetComponents/LiveCode';
import LiveVideo from '../../Components/AssetComponents/LiveVideo';
import { VIDEO_TYPE } from '../../Util/ExternalAdapter/VideoAdapter';
import Noteboard from '../../Components/AssetComponents/Noteboard';
import Assignment from '../../Components/AssetComponents/Assignment';
class AssetContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    courseItemId: PropTypes.string.isRequired,
    courseId: PropTypes.string.isRequired,
    type: PropTypes.string,
    newAsset: PropTypes.bool,
    materialDownloadable: PropTypes.bool,
    onCancel: PropTypes.func,
    onComplete: PropTypes.func,
    subsectionVideoAsset: PropTypes.object,
  };

  static defaultProps = {
    onCancel: () => {},
    onComplete: () => {},
  };

  state = {
    mutating: false,
    error: null,
  };

  handleMaterialAction = (data) => {
    const {
      courseId,
      newAsset,
      courseItemId,
      id,
      materialDownloadable,
      type,
      product,
      version,
    } = this.props;
    const { title, description, content } = data;
    const productVersion = productCourseVersionResolver(product, version);
    this.setState({ mutating: true });
    const promiseAction = newAsset
      ? this.createMaterial(
          productVersion,
          courseId,
          courseItemId,
          type,
          title,
          description,
          materialDownloadable,
          content
        )
      : this.updateMaterial(
          productVersion,
          courseId,
          courseItemId,
          id,
          type,
          title,
          description,
          content
        );

    promiseAction
      .then((result) => {
        this.setState(
          (prevState) => ({ mutating: false, error: null }),
          () => {
            this.props.onComplete(result.data);
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ mutating: false, error });
      });
  };

  updateMaterial(productVersion, courseId, courseItemId, id, type, title, description, content) {
    return this.props.editMaterial({
      variables: {
        version: productVersion,
        assetId: id,
        type,
        [resolveMaterialDataKey(type)]: genCourseAssetEditInput(
          title,
          description,
          undefined,
          undefined,
          content
        ),
      },
      refetchQueries: [
        {
          query: getSubSectionWithMaterials,
          variables: { version: productVersion, id: courseItemId },
        },
        {
          query: getCourseMeta,
          variables: { version: productVersion, courseId: courseId },
        },
      ],
    });
  }

  createMaterial(
    productVersion,
    courseId,
    courseItemId,
    type,
    title,
    description,
    materialDownloadable,
    content
  ) {
    return this.props.addMaterial({
      variables: {
        version: productVersion,
        courseItemId: courseItemId,
        type: type,
        [resolveMaterialDataKey(type)]: genCourseAssetInput(
          title,
          description,
          !materialDownloadable,
          materialDownloadable,
          content
        ),
      },
      refetchQueries: [
        {
          query: getSubSectionWithMaterials,
          variables: { version: productVersion, id: courseItemId },
        },
        {
          query: getCourseMeta,
          variables: { version: productVersion, courseId: courseId },
        },
      ],
    });
  }

  handleFileListRequest = async (request) => {
    const { client, product, courseItemId, version } = this.props;
    const { data } = await client.query({
      query: getAssetList,
      variables: {
        version: productCourseVersionResolver(product, version),
        courseItemId,
      },
      fetchPolicy: 'no-cache',
    });
    console.log(data);
    return data.listAssetFiles;
  };

  handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { getAssetFilesUploadUrl, product, version, courseItemId } = this.props;
    const { data } = await getAssetFilesUploadUrl({
      variables: {
        version: productCourseVersionResolver(product, version),
        courseItemId,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    console.log(data);
    return data.getAssetFilesUploadUrl.url;
  };

  handleGetSignedUrl = async (assetRequestList = []) => {
    if (!Array.isArray(assetRequestList) || !assetRequestList.length) return [];
    const { client } = this.props;
    const { data } = await client.mutate({
      mutation: generateCourseAssetSignedUrls,
      variables: {
        assetList: assetRequestList,
      },
    });
    return data.generateCourseAssetSignedUrls.urls;
  };

  handleGetVideoSignedUrl = async (videoKey) => {
    const result = await generateSignedUrl(videoKey);
    return result;
  };

  getVideoAssetFromContentAssets = (contentAssets = []) => {
    const ASSETS_PRIORITY = [MATERIAL_TYPE.VIDEO, MATERIAL_TYPE.YOUTUBE];

    let videoAssets = [];
    if (Array.isArray(contentAssets) && contentAssets.length) {
      videoAssets = contentAssets.filter(
        (asset) => asset?.type === MATERIAL_TYPE.YOUTUBE || asset?.type === MATERIAL_TYPE.VIDEO
      );

      //sort by content priority
      videoAssets.sort(
        (item, nextItem) =>
          ASSETS_PRIORITY.indexOf(item.type) - ASSETS_PRIORITY.indexOf(nextItem.type)
      );
    }

    const singleVideoAsset = videoAssets?.[0] ?? {};
    return singleVideoAsset;
  };

  //TODO: provide MATERIAL_TYPES.LIVECODING asset case
  renderAsset(type, id, data = {}, courseMeta = { meta: {} }) {
    const {
      newAsset,
      onCancel,
      courseId,
      courseItemId,
      myProfile: { id: userId },
      appConfig,
      serverConfig,
      product,
      version,
      materialDownloadable,
      contentAssets,
      materialProvider,
    } = this.props;
    const { mutating, error } = this.state;
    const {
      courseCode,
      meta: { courseOwnerId },
    } = courseMeta;
    const materialTypeString = MATERIAL_TYPES_STRING[type];
    switch (type) {
      case MATERIAL_TYPES.SLIDES: {
        const subsectionVideoAsset = this.getVideoAssetFromContentAssets(contentAssets);
        return (
          <VideoSlides
            id={id}
            videoAsset={subsectionVideoAsset}
            onGetVideoSignedUrl={this.handleGetVideoSignedUrl}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            onFileListRequest={this.handleFileListRequest}
            onFileUploadUrlRequest={this.handleFileUploadUrlRequest}
            onGetSignedUrl={this.handleGetSignedUrl}
            materialTypeString={materialTypeString}
            uploadMode={serverConfig.assetUploadMode}
            allowedContentTypes={appConfig.courseMaterialAllowContentTypes}
            disabledDragList={true}
          />
        );
      }
      case MATERIAL_TYPES.VIDEO:
        if (materialProvider === 'WISTIA' || contentAssets[0]?.rawContent?.videoType === 'WISTIA') {
          return (
            <WistiaVideo
              id={id}
              title={data.title}
              content={data.rawContent}
              onCreateMaterial={this.handleMaterialAction}
              onCancel={onCancel}
              newAsset={newAsset}
              loading={mutating}
              materialTypeString={materialTypeString}
            />
          );
        } else if (
          materialProvider === 'VIMEO' ||
          contentAssets[0]?.rawContent?.videoType === 'VIMEO'
        ) {
          return (
            <VimeoVideo
              id={id}
              title={data.title}
              content={data.rawContent}
              onCreateMaterial={this.handleMaterialAction}
              onCancel={onCancel}
              newAsset={newAsset}
              loading={mutating}
              materialTypeString={materialTypeString}
            />
          );
        } else if (
          materialProvider === 'MINDSTAMP' ||
          contentAssets[0]?.rawContent?.videoType === 'MINDSTAMP'
        ) {
          return (
            <MindstampVideo
              title={data.title}
              description={data.description}
              content={data.rawContent}
              onCreateMaterial={this.handleMaterialAction}
              newAsset={newAsset}
              onCancel={onCancel}
              loading={mutating}
              materialTypeString={materialTypeString}
            />
          );
        } else {
          return (
            <VideoManagement
              id={id}
              title={data.title}
              description={data.description}
              content={data.rawContent}
              onCreateMaterial={this.handleMaterialAction}
              onCancel={onCancel}
              newAsset={newAsset}
              loading={mutating}
              materialTypeString={materialTypeString}
              metadata={{
                courseId,
                userId,
                courseItemId,
                courseOwnerId,
                courseCode,
                productVersion: productCourseVersionResolver(product, version),
              }}
              defaultVideoType={VIDEO_TYPE.BYTEARK}
              isAllowMultiple={false}
              materialDownloadable={materialDownloadable}
            />
          );
        }
      case MATERIAL_TYPES.YOUTUBE:
        return (
          <YoutubeVideo
            id={id}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            materialTypeString={materialTypeString}
          />
        );
      case MATERIAL_TYPES.ARTICLE:
        return (
          <Article
            id={id}
            title={data.title}
            description={data.description}
            content={data.content}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            materialTypeString={materialTypeString}
          />
        );
      case MATERIAL_TYPES.ASSIGNMENT:
        return (
          <Assignment
            id={id}
            title={data.title}
            content={data.rawContent}
            onValidSubmit={this.handleMaterialAction}
            onCancel={onCancel}
            isCreateMode={newAsset}
            loading={mutating}
            courseMeta={courseMeta.meta}
          />
        );
      case MATERIAL_TYPES.OTHER:
        return (
          <FileManagement
            id={id}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            onFileListRequest={this.handleFileListRequest}
            onFileUploadUrlRequest={this.handleFileUploadUrlRequest}
            materialTypeString={materialTypeString}
            maxFileSize={appConfig.courseMaterialAllowContentSize}
            uploadMode={serverConfig.assetUploadMode}
            allowedContentTypes={appConfig.courseMaterialAllowContentTypes}
          />
        );
      case MATERIAL_TYPES.QUIZ:
        return (
          <Quiz
            id={id}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            newAsset={newAsset}
            loading={mutating}
            materialTypeString={materialTypeString}
            courseCode={courseCode}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
          />
        );
      case MATERIAL_TYPES.QUIZ_SIMPLE: {
        return (
          <QuizSimple
            id={id}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            materialTypeString={materialTypeString}
            maximumQuizzes={15}
            uploadMode={serverConfig.assetUploadMode}
            error={error}
            metadata={{
              courseItemId,
            }}
          />
        );
      }
      case MATERIAL_TYPES.LIVE_VIDEO: {
        return (
          <LiveVideo
            id={id}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            defaultVideoType={VIDEO_TYPE.BYTEARK}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            materialTypeString={materialTypeString}
          />
        );
      }
      case MATERIAL_TYPES.LIVECODING: {
        return (
          <LiveCode
            id={id}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            materialTypeString={materialTypeString}
          />
        );
      }

      case MATERIAL_TYPES.NOTEBOARD: {
        return (
          <Noteboard
            id={id}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            materialTypeString={materialTypeString}
          />
        );
      }
      case MATERIAL_TYPES.PDF: {
        return (
          <FileManagement
            id={id}
            title={data.title}
            description={data.description}
            content={data.rawContent}
            onCreateMaterial={this.handleMaterialAction}
            onCancel={onCancel}
            newAsset={newAsset}
            loading={mutating}
            onFileListRequest={this.handleFileListRequest}
            onFileUploadUrlRequest={this.handleFileUploadUrlRequest}
            materialTypeString={materialTypeString}
            maxFileSize={appConfig.courseMaterialAllowContentSize}
            uploadMode={serverConfig.assetUploadMode}
            allowedContentTypes={['application/pdf']}
          />
        );
      }
      default:
        return (
          <div>
            <div style={{ paddingBottom: '1em' }}>
              {`Currently we're not support this type of material.
              Please contact Skooldio to request and prioritize.`}
            </div>
            <div style={{ paddingBottom: '1em' }}>{JSON.stringify(data)}</div>
            <div>
              <Button onClick={onCancel}>OK</Button>
            </div>
          </div>
        );
    }
  }

  render() {
    const { id, newAsset, type, product, version, courseId, ...restProps } = this.props;
    return (
      <QueryComponent
        query={getCourseMeta}
        variables={{ version: productCourseVersionResolver(product, version), courseId }}
      >
        {({ course: { courseCode, meta = {} } = {} }) => {
          if (!newAsset) {
            return (
              <QueryComponent
                query={getSingleMaterial}
                skip={newAsset}
                variables={{ id, version: productCourseVersionResolver(product, version) }}
              >
                {({ courseMaterial }) => {
                  return this.renderAsset(courseMaterial.type, id, courseMaterial, {
                    courseCode,
                    meta,
                  });
                }}
              </QueryComponent>
            );
          }

          return this.renderAsset(type, null, { rawContent: {} }, { courseCode, meta });
        }}
      </QueryComponent>
    );
  }
}

const AssetContainerWithApollo = (props) => (
  <ApolloConsumer>
    {(client) => {
      return (
        <QueryComponent query={getConfig}>
          {({ config }) => <AssetContainer {...props} product={config.product} client={client} />}
        </QueryComponent>
      );
    }}
  </ApolloConsumer>
);

const mapStateToProps = (state) => ({
  myProfile: myProfile(state),
});

export default compose(
  connect(mapStateToProps, { getProfile }),
  graphql(deleteMaterial, {
    name: 'deleteMaterial',
  }),
  graphql(editMaterial, {
    name: 'editMaterial',
  }),
  graphql(addMaterial, {
    name: 'addMaterial',
  }),
  graphql(getAssetFilesUploadUrl, {
    name: 'getAssetFilesUploadUrl',
  }),
  withServerConfig({ configProp: 'serverConfig' }),
  withAppConfig({ configProp: 'appConfig' })
)(AssetContainerWithApollo);
