import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as SButton, Header, List, Icon } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';

import { Box } from 'Components/Base';
import { getSingleMaterial } from 'GraphQL/query/Material.query';
import { generateCourseAssetSignedUrls as generateCourseAssetSignedUrlsMutation } from 'GraphQL/mutation/Courses.mutation';

import ConfirmationButton from '../ConfirmationButton';
import { SemanticButton as Button } from '../Base/Button';

import ActionList from './ActionList.view';
import {
  MATERIAL_FILE_TYPE,
  MATERIAL_BUTTON_MODE,
  getAssetSignedUrl,
  resolveIconType,
} from './util';

const noop = () => {};

// Fix for List under <Accordion />
const NoPaddingList = styled(List)`
  &.ui.list.list > .item > .icon + .content,
  &.ui.list.list > .item > .image + .content,
  &.ui.list > .item > .icon + .content,
  &.ui.list > .item > .image + .content {
    display: table-cell;
    padding: 0 0 0 0.5em;
    vertical-align: top;
  }

  &.ui.list .list > .item [class*='right floated'],
  &.ui.list > .item [class*='right floated'] {
    float: right;
    margin: 0 0 0 1em !important;
  }

  &.ui.list .list > .item > .content,
  &.ui.list > .item > .content {
    padding: 0;
  }
`;

const MaterialList = ({ productVersion, materialList, onEditClick, onDeleteClick, loading }) => {
  const { refetch: getSingleMaterialData } = useQuery(getSingleMaterial, { skip: true });
  const [generateCourseAssetSignedUrls] = useMutation(generateCourseAssetSignedUrlsMutation);

  return (
    <NoPaddingList divided relaxed>
      {false && materialList.length === 0 && (
        <List.Item>
          {/* <List.Icon name={'info'} /> */}
          <List.Content>
            <List.Description>
              <i>{'No Material'}</i>
            </List.Description>
          </List.Content>
        </List.Item>
      )}
      {materialList?.map((mat) => {
        const isTypePdf = mat.type === MATERIAL_FILE_TYPE.PDF;
        const isTypeOther = mat.type === MATERIAL_FILE_TYPE.OTHER;
        const isShowPreviewDownloadFileButton = isTypePdf || isTypeOther;

        const metadata = {
          mat,
          productVersion,
          getSingleMaterialData,
          generateCourseAssetSignedUrls,
        };

        return (
          <List.Item key={mat.id}>
            <List.Content floated="right">
              <SButton.Group>
                {isShowPreviewDownloadFileButton && (
                  <>
                    <Button
                      basic
                      icon
                      compact
                      size="mini"
                      data-tooltip="Preview"
                      loading={loading}
                      onClick={() => {
                        getAssetSignedUrl({ ...metadata, mode: MATERIAL_BUTTON_MODE.PREVIEW });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      basic
                      icon
                      compact
                      size="mini"
                      data-tooltip="Download"
                      loading={loading}
                      onClick={() => {
                        getAssetSignedUrl({ ...metadata, mode: MATERIAL_BUTTON_MODE.DOWNLOAD });
                      }}
                    >
                      <Icon name="save" />
                    </Button>
                  </>
                )}
                <Button
                  basic
                  size="mini"
                  icon
                  loading={loading}
                  compact
                  onClick={() => {
                    onEditClick(mat);
                  }}
                  data-testid={`btn-edit-${mat.id.toLowerCase()}`}
                >
                  <Icon name="edit" />
                </Button>
                <ConfirmationButton
                  headerText={`Delete ${mat.title || <i>No Title</i>}`}
                  contentText={'This action cannot be undone.'}
                  confirmText={'Delete'}
                  loading={loading}
                  onConfirm={() => {
                    onDeleteClick(mat);
                  }}
                  data-testid={`btn-delete-${mat.id.toLowerCase()}`}
                >
                  <Icon name="trash alternate outline" color="red" />
                </ConfirmationButton>
              </SButton.Group>
            </List.Content>
            <List.Icon name={resolveIconType(mat.type)} />
            <List.Content>
              <List.Header>{mat.title || <i>No Title</i>}</List.Header>
              <List.Description>{mat.description || <i>No Description</i>}</List.Description>
            </List.Content>
          </List.Item>
        );
      })}
    </NoPaddingList>
  );
};

const MaterialSectionList = ({
  productVersion,
  title,
  actions,
  materialList,
  onAddClick,
  onEditClick,
  onDeleteClick,
}) => (
  <Fragment>
    <Header size="small">{title}</Header>
    <ActionList actions={actions} onClick={onAddClick} />
    <Box mx={3} mt={3}>
      <MaterialList
        productVersion={productVersion}
        materialList={materialList}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
    </Box>
  </Fragment>
);

MaterialList.defaultProps = {
  materialList: [],
  onEditClick: noop,
  onDeleteClick: noop,
};

MaterialList.propTypes = {
  productVersion: PropTypes.string,
  materialList: PropTypes.array,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  loading: PropTypes.bool,
};

MaterialSectionList.propTypes = {
  productVersion: PropTypes.string,
  title: PropTypes.string,
  materialList: PropTypes.array,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
};

export default MaterialSectionList;
