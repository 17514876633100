import getConfig from 'Util/Config';
import { status, statusText } from 'GraphQL/query/Workshop.query';

const { products } = getConfig();

const getSkuStatusOptions = (productType) => {
  if (
    Array.isArray(products?.[productType]?.skuStatusOptions) &&
    products?.[productType]?.skuStatusOptions.length > 0
  ) {
    return products?.[productType]?.skuStatusOptions?.map(({ value }) => {
      let formattedSkuStatus = value.replace('/', '_').replace('UNLISTED', 'INVISIBLE');
      if (formattedSkuStatus.includes('UNPUBLISHED')) {
        formattedSkuStatus = 'UNPUBLISHED';
      }
      return {
        key: formattedSkuStatus,
        value: formattedSkuStatus,
        text: statusText[formattedSkuStatus],
      };
    });
  }

  return Object.values(status).map((skuStatus) => ({
    key: skuStatus,
    value: skuStatus,
    text: statusText[skuStatus],
  }));
};

export default getSkuStatusOptions;
