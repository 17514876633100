import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getCollectionOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateCollectionOnlineCourseCategoriesMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUCategoriesForm from '../SKUCategoriesForm';
import { SKU_CATEGORY } from '../../Domains/constants';

const OnlineCourseCategoriesEditor = ({ onlineCourse = {} }) => {
  const { id, SKUCode } = onlineCourse;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getCollectionOnlineCourse}
        editMutation={updateCollectionOnlineCourseCategoriesMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({ SKUCode })}
        getEditVariables={(form) => ({
          id,
          data: {
            ...form,
          },
        })}
      >
        {({ data, onSubmit }) => {
          return (
            <SKUCategoriesForm
              skuCategory={SKU_CATEGORY.ONLINE_COURSE}
              initialData={data?.sfBoProductCollectionOnlineCourse}
              onSubmit={onSubmit}
            />
          );
        }}
      </CreateEditForm>
    </Box>
  );
};

OnlineCourseCategoriesEditor.propTypes = {
  onlineCourse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default OnlineCourseCategoriesEditor;
