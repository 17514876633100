import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BaseMenu from 'Containers/Menu';
import APP_PERMISSION from 'Util/permissions/permissionKeys';
import { GenSwitchRoute } from 'Util/Route';

import getConfig from '../../../../Util/Config';
import ActionPanel from '../../ActionPanel/ActionPanel.gql';
import RolesPanel from '../../Containers/RolesPanel.gql';
import RolesPermissionPanel from '../../Containers/RolesPermissionPanel';
import UserTeamsPanel from '../../Containers/UserTeamsPanel';
import UserCertificatePanel from '../../Containers/UserCertificatePanel';

import EnrollmentsPanel from './EnrollmentsPanel';
import DeviceTokenPanel from './DeviceTokenPanel';
import UserDevicePanel from './UserDevicePanel';

const isValueInArray = (array = []) => (value) => {
  return array.some((singleValue) => singleValue === value);
};

const isConfigAllowPane = (configArray) => (pane) => isValueInArray(configArray)(pane.config);

const { allowedManageUser, enabledAppPermission } = getConfig();

const genMenu = (matchUrl) =>
  [
    {
      path: `${matchUrl}`,
      title: 'Actions',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.DETAIL.VIEW],
      route: ActionPanel,
      config: 'userActions',
      active: false,
      'aria-current': 'true',
    },
    {
      path: `${matchUrl}/userDevice`,
      title: 'User Devices',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.HELPDESK,
        APP_PERMISSION.USER.DEVICE.VIEW,
      ],
      route: UserDevicePanel,
      config: 'userDevices',
      'aria-current': 'true',
    },
    {
      path: `${matchUrl}/device`,
      title: 'Device Token',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.HELPDESK,
        APP_PERMISSION.USER.DEVICE.VIEW,
      ],
      route: DeviceTokenPanel,
      config: 'userDeviceTokens',
      'aria-current': 'true',
    },
    {
      path: `${matchUrl}/roles`,
      title: 'Roles',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.ROLE.VIEW],
      route: enabledAppPermission ? RolesPermissionPanel : RolesPanel,
      config: 'userRoles',
      'aria-current': 'true',
    },
    {
      path: `${matchUrl}/enrollments`,
      title: 'Enrollments',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.ENROLLMENT.VIEW],
      route: EnrollmentsPanel,
      config: 'userEnrollments',
      'aria-current': 'true',
    },
    {
      path: `${matchUrl}/teams`,
      title: 'Teams',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.TEAM.VIEW],
      route: UserTeamsPanel,
      config: 'userTeams',
      'aria-current': 'true',
    },
    {
      path: `${matchUrl}/certificates`,
      title: 'Certificates',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.DETAIL.VIEW],
      route: UserCertificatePanel,
      config: 'userTeams',
      'aria-current': 'true',
    },
  ].filter(isConfigAllowPane(allowedManageUser));

const Menu = styled(BaseMenu)`
  &&&& {
    margin: 36px 0;
    border-bottom: 1px solid #d9d9d9;
    .active.item {
      border-color: ${({ theme: { colors } }) => colors?.primary};
      border-width: 4px;
    }
    & > :first-child {
      overflow: inherit;
    }
  }
`;

const UserTabs = ({ userId }) => {
  const menus = useMemo(() => genMenu(`/users/search/${userId}`), [userId]);
  return (
    <>
      <Menu menus={menus} menuProps={{ secondary: true, pointing: true }} />
      <GenSwitchRoute routes={menus} userId={userId} />
    </>
  );
};

UserTabs.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserTabs;
