export const OnlineCoursePermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'product.onlineCourse.list-view',
    UPDATE: 'product.onlineCourse.list-update',
    DELETE: 'product.onlineCourse.list-delete',
  },
  OVERVIEW: {
    VIEW: 'product.onlineCourse.overview-view',
    UPDATE: 'product.onlineCourse.overview-update',
    DELETE: 'product.onlineCourse.overview-delete',
  },
  CATEGORIES: {
    VIEW: 'product.onlineCourse.categories-view',
    UPDATE: 'product.onlineCourse.categories-update',
    DELETE: 'product.onlineCourse.categories-delete',
  },
  TABLE_OF_CONTENTS: {
    VIEW: 'product.onlineCourse.tableOfContents-view',
    UPDATE: 'product.onlineCourse.tableOfContents-update',
    DELETE: 'product.onlineCourse.tableOfContents-delete',
  },
  INSTRUCTORS: {
    VIEW: 'product.onlineCourse.instructors-view',
    UPDATE: 'product.onlineCourse.instructors-update',
    DELETE: 'product.onlineCourse.instructors-delete',
  },
  DETAILS: {
    VIEW: 'product.onlineCourse.details-view',
    UPDATE: 'product.onlineCourse.details-update',
    DELETE: 'product.onlineCourse.details-delete',
  },
  PRICING: {
    VIEW: 'product.onlineCourse.pricing-view',
    UPDATE: 'product.onlineCourse.pricing-update',
    DELETE: 'product.onlineCourse.pricing-delete',
  },
  CREDIT_PRICING: {
    VIEW: 'product.onlineCourse.creditPricing-view',
    UPDATE: 'product.onlineCourse.creditPricing-update',
    DELETE: 'product.onlineCourse.creditPricing-delete',
  },
  HIGHLIGHTS: {
    VIEW: 'product.onlineCourse.highlights-view',
    UPDATE: 'product.onlineCourse.highlights-update',
    DELETE: 'product.onlineCourse.highlights-delete',
  },
  RATINGS: {
    VIEW: 'product.onlineCourse.ratings-view',
    UPDATE: 'product.onlineCourse.ratings-update',
    DELETE: 'product.onlineCourse.ratings-delete',
  },
  CONDITIONS: {
    VIEW: 'product.onlineCourse.conditions-view',
    UPDATE: 'product.onlineCourse.conditions-update',
    DELETE: 'product.onlineCourse.conditions-delete',
  },
  FAQ: {
    VIEW: 'product.onlineCourse.FAQ-view',
    UPDATE: 'product.onlineCourse.FAQ-update',
    DELETE: 'product.onlineCourse.FAQ-delete',
  },
});
