import gql from 'graphql-tag';

export const getAnnouncementReports = gql`
  query getAnnouncementReports(
    $search: AnnouncementReportSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    announcementReports(search: $search, paging: $paging, order: $order) {
      announcementReports {
        id
        title
        category
        publishedTime
        unpublishedTime
        announcedBy
        status
      }
      total
    }
  }
`;

export const getAnnouncement = gql`
  query getAnnouncement($announcementId: ID!) {
    announcement(announcementId: $announcementId) {
      id
      title
      platforms
      detail {
        content
        type
        title
        description
        rawContent
        utmCampaignId
        utmCampaignContent
        imageUrl
        subtitle
        audienceFile {
          name
          originalFilename
          type
          provider
          container
          relationType
          publishedTime
          unpublishedTime
          status
          fileImportJobId
        }
      }
      category
      announcedBy
      audiences {
        id
        relationId
        relationType
        status
        publishedTime
        unpublishedTime
      }
    }
  }
`;

export const getAnnouncementRelations = gql`
  query getAnnouncementRelations(
    $search: AnnouncementRelationSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    announcementRelations(search: $search, paging: $paging, order: $order) {
      announcementRelations {
        id
        announcementId
        relationId
        relationType
        publishedTime
        unpublishedTime
        status
      }
      total
    }
  }
`;

export const getAnnouncementFileImportJobs = gql`
  query announcementFileImportJobs(
    $search: AnnouncementFileImportJobSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    announcementFileImportJobs(search: $search, paging: $paging, order: $order) {
      total
      fileImportJobs {
        id
        refCode
        provider
        container
        name
        originalFilename
        type
        status
        detail {
          eventType
          announcement {
            title
          }
          relationType
          publishedTime
          unpublishedTime
          status
        }
      }
    }
  }
`;
