import gql from 'graphql-tag';

export const singleExam = gql`
  fragment singleExam on EEV1Exam {
    id
    type
    title
    detail
    courseId
    duration
    passingScore {
      type
      value
    }
    examLimit
    status
    randomChoice
    examCode
    questionOrder {
      id
      type
      title
    }
    questionRandomConditions {
      tagIds
      randomNum
    }
  }
`;

export const singleQuestion = gql`
  fragment singleQuestion on EEV1ExamQuestion {
    id
    type
    title
    score
    tags
    solution {
      value
    }
    contentFormat
    ... on EEV1ExamChoiceQuestion {
      choices {
        value
        isAnswer
      }
    }
    ... on EEV1ExamFillInTheBlankQuestion {
      inputs {
        id
        value
        gradeOption {
          isCaseSensitive
        }
      }
    }
  }
`;

export const VideoFragment = gql`
  fragment VideoFragment on VDMVideo {
    videoKey
    title
    shortDescription
    description
    channelName
    sectionName
    coverImageUrl
    duration
    uploadedAt
  }
`;

export const ByteArkStreamVideoFragment = gql`
  fragment ByteArkStreamVideoFragment on VDMByteArkStreamVideo {
    videoKey
    title
    shortDescription
    description
    channelName
    sectionName
    coverImageUrl
    duration
    uploadedAt
  }
`;
