import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import { getListSubscriptions } from 'Routes/Products/GraphQL/query/Products.query';
import {
  createDiscountCodeFileImportJob,
  createShipmentsTrackingFileImportJob,
} from 'Routes/SalesV2/GraphQL/mutation/FileImport.mutation';
import { getFileImportJobs } from 'Routes/SalesV2/GraphQL/query/FileImport.query';
import CheckCloseComponent from 'Util/CheckDefinedComponent';
import Loading from 'Components/Loading';

import { FILE_TYPE } from '../../common';

import DataForm from './DataForm';

const getMutationImportJob = (type) => {
  switch (type) {
    case FILE_TYPE.NEW_DISCOUNT_CODE:
      return createDiscountCodeFileImportJob;
    case FILE_TYPE.SHIPMENT_REPORTS:
      return createShipmentsTrackingFileImportJob;
    default:
      throw new Error('file type not support');
  }
};

class NewDataMutation extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    priceId: PropTypes.string,
    type: PropTypes.oneOf(Object.values(FILE_TYPE)).isRequired,
  };

  static defaultProps = {
    onComplete: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      schoolGradeData: null,
      schoolProgrammeData: null,
    };
  }

  componentDidMount() {
    const { type, client } = this.props;
    if (type === FILE_TYPE.NEW_DISCOUNT_CODE) {
      Promise.all([
        client.query({
          query: getListSubscriptions,
          variables: { paging: { currentPage: 0, pageSize: 100 } },
        }),
      ]).then((data) => {
        const { data: skuData } = data[0];
        this.setState({
          skuData: skuData.sfBoProductSubscriptions.subscriptions,
        });
      });
    }
  }

  convertFormValue = (form) => {
    const data = {
      // type: this.props.type,
      ...form,
      originalFilename: form.file.originalFilename,
      file: {
        key: form.file.key,
        bucket: form.file.bucket,
      },
    };
    return data;
  };

  render() {
    const { variables, type } = this.props;
    const { skuData } = this.state;
    if (type === FILE_TYPE.NEW_DISCOUNT_CODE && !skuData) {
      return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
    }

    return (
      <Mutation
        mutation={getMutationImportJob(type)}
        refetchQueries={[{ query: getFileImportJobs, variables }]}
      >
        {(createData, { loading, error, data }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent checkDefinedObject={data} onDefined={this.props.onComplete} />
              <DataForm
                type={type}
                onCreateNewData={(form) =>
                  createData({ variables: { input: this.convertFormValue(form) } })
                }
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                skuData={skuData}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default withApollo(NewDataMutation);
