import React from 'react';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';

import './Resizable.css';

const Resizable = ({ width, height, onResizeStop, ...restProps }) => {
  return (
    <ResizableBox
      className="box"
      width={width}
      height={height}
      draggableOpts={{ grid: [50, 50] }}
      resizeHandles={['e', 'w']}
      lockAspectRatio={true}
      onResizeStop={(e, data) => {
        const { size } = data;
        const { width, height } = size;
        onResizeStop(width, height);
      }}
      {...restProps}
    />
  );
};

export default Resizable;
