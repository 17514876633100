import React, { Component } from 'react';

import withFormsy from 'Components/Form/withFormsy';
import QueryComponent from 'GraphQL/util/QueryComponent';
import SelectorInput from 'Routes/Reports/Components/SelectorInput/SelectorInput';

import { getInstructors } from '../../GraphQL/query/Products.query';

const parseValue = (data) => {
  const instructors = data.sfBoProductInstructors.instructors
    ? data.sfBoProductInstructors.instructors
    : [];
  return instructors.map((instructor) => ({
    key: instructor.id,
    value: instructor.id,
    text: `${instructor.name} (${instructor.permalink})`,
  }));
};

class InstructorSelectorInputQuery extends Component {
  render() {
    return (
      <QueryComponent
        query={getInstructors}
        variables={{ paging: { currentPage: 0, pageSize: 100 } }}
      >
        {(data) => {
          return <SelectorInput options={parseValue(data)} {...this.props} />;
        }}
      </QueryComponent>
    );
  }
}

export default InstructorSelectorInputQuery;
export const FormsyInstructorSelectorInput = withFormsy(InstructorSelectorInputQuery);
