import React, { useMemo, useCallback, useState, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { Flex, Text, Box, ButtonFilled } from 'Components/Base';
import StatefulModal from 'Components/Modal/StatefulModal';
import { singleUserQuery } from 'GraphQL/query/Users.query';
import { UNASSIGN_TEAM } from 'GraphQL/mutation/Users.mutation';

import Snackbar, { VARIANTS } from '../Components/Snackbar';
import TeamDetails from '../Containers/Team/TeamDetails';

import DeleteIconModal from './DeleteIconModal';
import SearchUserTeams, { DEFAULT_USER_COLUMNS } from './SearchUserTeams';
import AssignTeamsToUser from './AssignTeamsToUser';

const ModalContentContainer = styled((props) => <Box {...props} />)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #f1ad46;
  }
`;

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const CreateTeamModal = ({ onCompleted }) => {
  return (
    <StatefulModal
      trigger={
        <ButtonFilled
          bg="primary"
          color="white"
          borderRadius={'100px'}
          my={2}
          mx={4}
          fontWeight="600"
          fontSize="14px"
        >
          <Flex alignItems="center">
            <AddIcon style={{ width: '24px' }} />
            <span>Add New Team</span>
          </Flex>
        </ButtonFilled>
      }
    >
      {(closeModal, isOpen) => (
        <>
          <Text.Header py={3} px={4} fontSize="20px">
            Add New Team
          </Text.Header>
          <Divider />
          <Box pt={4} pb={3} px={5} mx="auto" width="100%" maxWidth="900px">
            <TeamDetails
              isEdit={false}
              onCompleted={() => {
                onCompleted();
                setTimeout(closeModal, 500);
              }}
              onCancelled={closeModal}
            />
          </Box>
        </>
      )}
    </StatefulModal>
  );
};

CreateTeamModal.propTypes = {
  onCompleted: PropTypes.func,
};
CreateTeamModal.defaultProps = {
  onCompleted: () => {},
};

const AddTeamModal = ({ userId, onCompleted }) => {
  const TeamListRef = useRef(null);

  const handleCreateTeamComplete = useCallback(() => {
    const TeamListTable = TeamListRef.current;
    if (TeamListTable && typeof TeamListTable.refetch === 'function') {
      TeamListTable.refetch();
    }
  }, []);

  return (
    <StatefulModal
      trigger={
        <ButtonFilled
          bg="primary"
          color="white"
          borderRadius={'100px'}
          px={3}
          py={2}
          fontWeight="600"
          fontSize="14px"
        >
          <Flex alignItems="center">
            <AddIcon style={{ width: '24px' }} />
            <span>Assign Team</span>
          </Flex>
        </ButtonFilled>
      }
    >
      {(closeModal, isOpen) => (
        <ModalContentContainer>
          <Flex width={1} justifyContent={'space-between'}>
            <Text.Header py={3} px={4} fontSize="20px">
              Add Team To User
            </Text.Header>
            <CreateTeamModal onCompleted={handleCreateTeamComplete} />
          </Flex>
          <Divider />
          <AssignTeamsToUser
            userId={userId}
            onCancel={closeModal}
            onCompleted={() => {
              onCompleted();
              setTimeout(closeModal, 1500);
            }}
            ref={TeamListRef}
          />
        </ModalContentContainer>
      )}
    </StatefulModal>
  );
};

AddTeamModal.propTypes = {
  userId: PropTypes.string.isRequired,
  onCompleted: PropTypes.func,
};
AddTeamModal.defaultProps = {
  onCompleted: () => {},
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  TEAM_NOT_FOUND: {
    message: `Team not found or has been unassigned already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot unassign team, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const TeamList = React.forwardRef(({ userId }, ref) => {
  const [feedback, setFeedback] = useState({});
  const { data: { user } = {} } = useQuery(singleUserQuery, {
    variables: { userId },
    skip: !userId,
    fetchPolicy: 'cache-only',
  });

  const onCompleted = useCallback(
    (response) => {
      const username = user?.username;
      const removedTeamName = response?.unassignTeamFromUser?.name;
      setFeedback({
        message: `Team${
          removedTeamName ? ` "${removedTeamName}" ` : ' '
        }has been unassigned from user${username ? ` "${username}"` : ''}`,
        variant: VARIANTS.SUCCESS,
        show: true,
      });
      const refetchAssignedTeamListTable = ref?.current?.refetch;
      if (typeof refetchAssignedTeamListTable === 'function') refetchAssignedTeamListTable();
    },
    [user, ref]
  );

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('404')) {
      setFeedback(FEEDBACK.TEAM_NOT_FOUND);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const [unassignTeam] = useMutation(UNASSIGN_TEAM, {
    onCompleted,
    onError,
  });

  const handleUnassignTeam = useCallback(
    (teamId, userId) => {
      unassignTeam({ variables: { userId, teamId } });
    },
    [unassignTeam]
  );

  const COLUMN = useMemo(
    () => [
      ...DEFAULT_USER_COLUMNS,
      {
        Header: 'Action',
        Cell: function actionComponent(row) {
          return (
            <Flex justifyContent="center">
              <DeleteIconModal
                title={'Unassign Team?'}
                description={'This team will be unassigned from the user.'}
                onConfirmClick={() => handleUnassignTeam(row.original.id, userId)}
              />
            </Flex>
          );
        },
        width: 60,
        isSearchAble: false,
        sortable: false,
      },
    ],
    [handleUnassignTeam, userId]
  );

  return (
    <>
      <SearchUserTeams columns={COLUMN} userId={userId} ref={ref} />
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
});

TeamList.propTypes = {
  userId: PropTypes.string.isRequired,
};

const UserTeamsPanel = ({ userId }) => {
  const AssignedTeamListRef = useRef(null);

  const handleAssignTeamComplete = useCallback(() => {
    const AssignedTeamListTable = AssignedTeamListRef.current;
    if (AssignedTeamListTable && typeof AssignedTeamListTable.refetch === 'function') {
      AssignedTeamListTable.refetch();
    }
  }, []);

  return (
    <>
      <Text>This user will only see the content available to the team(s) in this list.</Text>
      <Flex justifyContent="space-between" alignItems="center" flexDirection={'row-reverse'} py={3}>
        <AddTeamModal userId={userId} onCompleted={handleAssignTeamComplete} />
      </Flex>
      <TeamList userId={userId} ref={AssignedTeamListRef} />
    </>
  );
};

UserTeamsPanel.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserTeamsPanel;
