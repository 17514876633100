import numeral from 'numeral';

import { CERTIFICATE_TYPE, CERTIFICATE_ITEM_OBJECT_TYPE } from 'GraphQL/query/Certificate.query';

import getConfig from '../../Util/Config';

const certificateTypeOptions = [
  {
    key: CERTIFICATE_TYPE.LINE_BASIC,
    value: CERTIFICATE_TYPE.LINE_BASIC,
    text: 'LINE Basic Certificate',
  },
  {
    key: CERTIFICATE_TYPE.LINE_ADVANCED,
    value: CERTIFICATE_TYPE.LINE_ADVANCED,
    text: 'LINE Advanced Certificate',
  },
  {
    key: CERTIFICATE_TYPE.SKOOLDIO_ONLINE,
    value: CERTIFICATE_TYPE.SKOOLDIO_ONLINE,
    text: 'Skooldio Online Certificate',
  },
  {
    key: CERTIFICATE_TYPE.TAT_ONLINE,
    value: CERTIFICATE_TYPE.TAT_ONLINE,
    text: 'TAT Online Certificate',
  },
  {
    key: CERTIFICATE_TYPE.MANUAL,
    value: CERTIFICATE_TYPE.MANUAL,
    text: 'MANUAL (Ex. bootcamp)',
  },
];

const certficateItemOptions = [
  {
    key: CERTIFICATE_ITEM_OBJECT_TYPE.EXAM,
    value: CERTIFICATE_ITEM_OBJECT_TYPE.EXAM,
    text: 'Exam',
    disabled: true,
  },
  {
    key: CERTIFICATE_ITEM_OBJECT_TYPE.CERTIFICATE,
    value: CERTIFICATE_ITEM_OBJECT_TYPE.CERTIFICATE,
    text: 'Certificate',
  },
  {
    key: CERTIFICATE_ITEM_OBJECT_TYPE.COURSE_V1,
    value: CERTIFICATE_ITEM_OBJECT_TYPE.COURSE_V1,
    text: 'Course Version 1',
    disabled: true,
  },
  {
    key: CERTIFICATE_ITEM_OBJECT_TYPE.COURSE,
    value: CERTIFICATE_ITEM_OBJECT_TYPE.COURSE,
    text: 'Course',
  },
];

export const NO_EXPIRATION_TEXT = 'No expiration';
export const NO_EXPIRATION_DAYS = 999 * 365;

export function isCertificateNoExpiration(days) {
  return days === NO_EXPIRATION_DAYS;
}

export function getCertificateTypeOptions() {
  return certificateTypeOptions.filter((certificateTypeOption) =>
    getConfig()
      .certificateTypes.map((certType) => CERTIFICATE_TYPE[certType])
      .includes(certificateTypeOption.key)
  );
}

export function getCertificateItemTypeOptions() {
  return certficateItemOptions.filter(
    (certficateItemOption) =>
      getConfig().certificateItemTypes.includes(certficateItemOption.key) &&
      !certficateItemOption.disabled
  );
}

export function getNumberFormat(amount) {
  return numeral(amount).format('0,0');
}

export const convertCertificateItems = (items, certificateItemType) => {
  let convertedItems = [];
  const index = certificateItemType === CERTIFICATE_ITEM_OBJECT_TYPE.COURSE ? 'courseCode' : 'code';
  const title = certificateItemType === CERTIFICATE_ITEM_OBJECT_TYPE.COURSE ? 'title' : 'name';
  items.forEach((item) => {
    // check if code is not empty because there are empty data
    if (item[index]) {
      convertedItems.push({
        key: `${item[title]} [${item[index]}]`,
        text: `${item[title]} [${item[index]}]`,
        value: item[index],
      });
    }
  });
  return convertedItems;
};

// PAAS-3667 shouldHideCourse sometimes display in form as "" (empty string), if there's a way to guarantee that it's a boolean then this function would be removed
export const convertValueToBoolean = (value) => {
  return typeof value === 'boolean' ? value : false;
};

export const mapCertificateToFormValues = (certificateData) => {
  if (!certificateData) {
    return {
      shouldHideCourse: false,
      shouldGenerateQRCode: false,
    };
  }

  return {
    ...certificateData,
    shouldHideCourse: convertValueToBoolean(certificateData.metadata?.shouldHideCourse),
    shouldGenerateQRCode: convertValueToBoolean(certificateData.metadata?.shouldGenerateQRCode),
  };
};
export const mapFormValuesToCertificate = (formValue, ImageReaderInput) => {
  const { issuerSignature, validTimePeriodInDays, ...restValue } = formValue;
  const submitValue = { ...restValue };

  if (issuerSignature) {
    submitValue.issuerSignature = ImageReaderInput.getBase64Data(issuerSignature);
  }

  // SKOOLDIO-4708 Temporaly fix validTimePeriodInDays, as form generator will not covert custom type to Int/Float
  if (validTimePeriodInDays && typeof validTimePeriodInDays === 'string') {
    submitValue.validTimePeriodInDays = parseInt(validTimePeriodInDays, 10);
  }

  // PAAS-3667 => rebuild form data into graphql shape (undo flattening)
  const shouldHideCourse = convertValueToBoolean(submitValue.shouldHideCourse);
  const shouldGenerateQRCode = convertValueToBoolean(submitValue.shouldGenerateQRCode);
  submitValue.metadata = { shouldHideCourse, shouldGenerateQRCode };
  delete submitValue.shouldHideCourse;
  delete submitValue.shouldGenerateQRCode;

  // PAAS-3789 remove completion period (examTimeLimitInDays) field, set as default to 100
  submitValue.examTimeLimitInDays = 100;
  return submitValue;
};
