export const POPUP_CONFIRMATION = {
  QUESTION: {
    EN: 'Are you sure?',
    TH: 'คุณแน่ใจว่าจะลบ?',
  },
  ANSWER: {
    YES: {
      EN: 'Yes',
      TH: 'ใช่',
    },
    NO: {
      EN: 'No',
      TH: 'ไม่',
    },
  },
};

export const EXAM_TYPE = {
  PRETEST: 'PRETEST',
  POSTTEST: 'POSTTEST',
  EXAM: 'EXAM',
  TEMPLATE: 'TEMPLATE',
};

export const EXAM_STATE = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
};
