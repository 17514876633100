import React, { Fragment, useMemo, useCallback, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Flex, Text, Box, ButtonFilled } from 'Components/Base';
import StatefulModal from 'Components/Modal/StatefulModal';

import TeamDetails from '../../Containers/Team/TeamDetails';
import SearchTeam, { DEFAULT_TEAM_COLUMNS } from '../../Containers/Team/SearchTeam';
import DeleteIconModal from '../../Containers/DeleteIconModal';
import { DELETE_TEAM } from '../../Graphql/mutation/Team.mutation';
import Snackbar, { VARIANTS } from '../../Components/Snackbar';

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const NewTeamModal = () => {
  const history = useHistory();
  const handleCreateTeamCompleted = useCallback(
    (callback) => (response) => {
      callback();
      const teamId = response?.createTeam?.id;
      if (teamId) history.push(`/users/teams/${teamId}`);
    },
    [history]
  );
  return (
    <StatefulModal
      trigger={
        <ButtonFilled
          bg="primary"
          color="white"
          borderRadius={'100px'}
          px={3}
          py={2}
          fontWeight="600"
          fontSize="14px"
        >
          <Flex alignItems="center">
            <AddIcon style={{ width: '24px' }} />
            <span>Add New Team</span>
          </Flex>
        </ButtonFilled>
      }
    >
      {(closeModal, isOpen) => (
        <>
          <Text.Header py={3} px={4} fontSize="20px">
            Add New Team
          </Text.Header>
          <Divider />
          <Box pt={4} pb={3} px={5} mx="auto" width="100%" maxWidth="900px">
            <TeamDetails
              isEdit={false}
              onCompleted={handleCreateTeamCompleted(closeModal)}
              onCancelled={closeModal}
            />
          </Box>
        </>
      )}
    </StatefulModal>
  );
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  TEAM_NOT_FOUND: {
    message: `Team not found or has been delete already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot delete team, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const TeamList = () => {
  const { pathname } = useLocation();
  const [feedback, setFeedback] = useState({});
  const SearchTeamListRef = useRef();

  const onCompleted = useCallback((response) => {
    const deletedTeamName = response?.deleteTeam?.name;
    setFeedback({
      message: `Team${deletedTeamName ? ` "${deletedTeamName}" ` : ' '}has been deleted`,
      variant: VARIANTS.SUCCESS,
      show: true,
    });
    const SearchTeamListTable = SearchTeamListRef.current;
    if (typeof SearchTeamListTable.refetch === 'function') {
      SearchTeamListTable.refetch();
    }
  }, []);

  const onError = useCallback((response) => {
    const message = response?.message ?? '';
    if (message.includes('404')) {
      setFeedback(FEEDBACK.TEAM_NOT_FOUND);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const [deleteTeam] = useMutation(DELETE_TEAM, {
    onCompleted,
    onError,
  });

  const handleDeleteTeam = useCallback((teamId) => deleteTeam({ variables: { id: teamId } }), [
    deleteTeam,
  ]);

  const COLUMN = useMemo(
    () => [
      ...DEFAULT_TEAM_COLUMNS,
      {
        Header: 'Action',
        Cell: function actionComponent(row) {
          return (
            <Flex justifyContent="center">
              <Link
                to={{ pathname: `${pathname}/${row.original.id}` }}
                style={{ color: 'inherit', width: '20px', height: '20px' }}
              >
                <EditIcon />
              </Link>
              <DeleteIconModal
                title={'Delete Team?'}
                description={'This team will be permanently deleted from the system.'}
                onConfirmClick={() => handleDeleteTeam(row.original.id)}
              />
            </Flex>
          );
        },
        width: 80,
        isSearchAble: false,
        sortable: false,
      },
    ],
    [pathname, handleDeleteTeam]
  );

  return (
    <>
      <SearchTeam columns={COLUMN} ref={SearchTeamListRef} />
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
};

const TeamsRoute = () => {
  return (
    <Fragment>
      <Flex justifyContent="space-between" mb={2}>
        <Text.Header>Team Management</Text.Header>
        <NewTeamModal />
      </Flex>
      <TeamList />
    </Fragment>
  );
};

export default TeamsRoute;
