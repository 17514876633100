import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Label, Segment, Accordion } from 'semantic-ui-react';
import prettyByte from 'pretty-bytes';

import { Box, Flex } from 'Components/Base';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';

import FileUploadManagerModal, { UPLOAD_MODE } from '../../Lib/UploadComponent';
import ActionBar from '../Common/ActionBar';
import MaterialMeta from '../Common/MaterialMeta';
import { SemanticButton as Button } from '../../Base/Button';

const convertToDisplayData = (files) =>
  files.map((file) => {
    const { key, prefix } = file;
    const filename = key.replace(`${prefix}/`, '');
    return {
      ...file,
      filename,
    };
  });

const convertFromRawData = (content) => (content && content.data ? content.data : undefined);

// The valid filename regex is followed from skooldio-backoffice-api
// Ref: https://bitbucket.org/skooldio/skooldio-backoffice-api/pull-requests/1276
const VALID_FILENAME_REGEX = /^([^\/\\?%*:|"<>]{1,200})\.([a-zA-Z0-9]{1,10})$/;

class FileManagement extends Component {
  static propTypes = {
    onCreateMaterial: PropTypes.func,
    content: PropTypes.object.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    newAsset: PropTypes.bool,
    materialTypeString: PropTypes.string,
    metadata: PropTypes.object,
    onCancel: PropTypes.func,
    onFileUploadUrlRequest: PropTypes.func,
    onFileListRequest: PropTypes.func,
    uploadMode: PropTypes.oneOf(Object.values(UPLOAD_MODE)).isRequired,
  };

  static defaultProps = {
    onCreateMaterial: () => {},
    onCancel: () => {},
    onFileUploadUrlRequest: () => {},
    onFileListRequest: () => {},
    content: {},
  };

  state = {
    formData: {},
    data: convertFromRawData(this.props.content),
    showInfo: false,
    disableSubmit: true,
    alert: {},
  };

  handleAddFile = (files = []) => {
    if (files && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      // Now Support only one file upload
      this.setState({ disableSubmit: false });
      this.setState({ data: convertToDisplayData(files)[0] });
    }
  };

  handleFormChange = (value) => {
    this.setState({ formData: value });
  };

  handleAction = () => {
    const { formData, data } = this.state;

    const resultObject = {
      ...formData,
      content: { data },
    };
    this.props.onCreateMaterial(resultObject);
  };

  handleClick = (e) => {
    this.setState({ showInfo: true });
  };

  validateFilename = (file) => {
    if (!VALID_FILENAME_REGEX.test(file.name)) {
      this.setState({
        alert: {
          show: true,
          message: `Filename: ${file.name} - cannot contain special characters as follows: / \ ? % * : | " < >`,
        },
      });
    }
  };

  render() {
    const {
      title,
      description,
      newAsset,
      loading,
      materialTypeString,
      onCancel,
      onFileUploadUrlRequest,
      onFileListRequest,
      maxFileSize,
      uploadMode,
      allowedContentTypes,
    } = this.props;
    const { data, disableSubmit, alert } = this.state;
    return (
      <Box>
        <Snackbar
          message={alert.message}
          isOpen={alert.show}
          onClose={() => {
            this.setState({ alert: { show: false } });
          }}
          variant={VARIANTS.ERROR}
          vertical={'top'}
          horizontal={'center'}
        />
        <Box p={3}>
          <MaterialMeta title={title} description={description} onChange={this.handleFormChange} />
        </Box>
        <Box p={3}>
          <FileUploadManagerModal
            dimmer="inverted"
            keyGroup="courseItemFile"
            uploadUrl={'http://localhost:5000'}
            listUrl={'http://localhost:5000'}
            allowedContentTypes={allowedContentTypes}
            defaultText={`Support PDF, JPG, PNG or GIF file with less than ${maxFileSize} MB`}
            maxFileSize={maxFileSize}
            maxSelection={1}
            customFileListUrlRequest={onFileListRequest}
            customUploadUrlRequest={onFileUploadUrlRequest}
            mode={uploadMode}
            onFileManagerDialogClose={this.handleAddFile}
            customDjsConfig={{ renameFile: this.validateFilename }}
            trigger={
              <Button
                primary
                circular
                data-testid={
                  data ? 'btn-file-upload-manager-change-file' : 'btn-file-upload-manager-add-file'
                }
              >
                <Icon name="plus" size="small" />
                {data ? 'Change file' : 'Add file'}
              </Button>
            }
          />
          {data && (
            <Segment>
              <Accordion>
                <Accordion.Title index={0} onClick={this.handleClick}>
                  <Icon name="dropdown" />
                  <Label size="large" horizontal>
                    File Name
                  </Label>
                  {decodeURIComponent(data.filename)}
                </Accordion.Title>
                <Accordion.Content active={this.state.showInfo}>
                  <Flex mx={-3} flexWrap="wrap">
                    <Box width={[1]} p={3}>
                      <Label size="large" horizontal>
                        Key
                      </Label>
                      {decodeURIComponent(data.key)}
                    </Box>
                    <Box width={[1, 1 / 2]} p={3}>
                      <Label size="large" horizontal>
                        Size
                      </Label>
                      {prettyByte(data.size)}
                    </Box>
                    <Box width={[1, 1 / 2]} p={3}>
                      <Label size="large" horizontal>
                        Last Modified
                      </Label>
                      {new Date(data.lastModified).toLocaleString()}
                    </Box>
                  </Flex>
                </Accordion.Content>
              </Accordion>
            </Segment>
          )}
        </Box>

        <Box>
          <ActionBar
            isCreate={newAsset}
            onAction={this.handleAction}
            onCancel={onCancel}
            loading={loading}
            materialTypeString={materialTypeString}
            disableSubmit={disableSubmit}
          />
        </Box>
      </Box>
    );
  }
}

export default FileManagement;
