import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import QueryComponent from '../../GraphQL/util/QueryComponent';
import { getSubSectionWithMaterials, getCourseMeta } from '../../GraphQL/query/Courses.query';
import { productCourseVersionResolver } from '../../GraphQL/query/AppConfig.query';
import { deleteMaterial } from '../../GraphQL/mutation/Material.mutation';
import { updateSubSection } from '../../GraphQL/mutation/Courses.mutation';
import { MATERIAL_TYPES } from '../../Components/SubSection/util';

import SubSection from './SubSection';

const getAssets = (courseSubsection = {}) =>
  courseSubsection.assets ? courseSubsection.assets : {};

class SubSectionContainer extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    courseId: PropTypes.string.isRequired,
    deleteMaterial: PropTypes.func,
    updateSubSection: PropTypes.func,
    product: PropTypes.string,
    version: PropTypes.string,
  };

  static defaultProps = {
    deleteMaterial: () => {},
  };

  handleDeleteMaterial = (matObj) => {
    const { product, version, id, courseId } = this.props;
    this.props.deleteMaterial({
      variables: {
        version: productCourseVersionResolver(product, version),
        assetId: matObj.id,
      },
      refetchQueries: [
        {
          query: getSubSectionWithMaterials,
          variables: { version: productCourseVersionResolver(product, version), id },
        },
        {
          query: getCourseMeta,
          variables: { version: productCourseVersionResolver(product, version), courseId },
        },
      ],
    });
  };

  handleUpdateSubSectionMeta = (data) => {
    const { product, version, id, courseId } = this.props;
    this.props.updateSubSection({
      variables: {
        version: productCourseVersionResolver(product, version),
        id: id,
        subsectionData: data,
      },
      refetchQueries: [
        {
          query: getSubSectionWithMaterials,
          variables: { version: productCourseVersionResolver(product, version), id },
        },
        {
          query: getCourseMeta,
          variables: { version: productCourseVersionResolver(product, version), courseId },
        },
      ],
    });
  };

  render() {
    const { id, product, version, ...restProps } = this.props;
    return (
      <QueryComponent
        query={getSubSectionWithMaterials}
        variables={{ version: productCourseVersionResolver(product, version), id }}
      >
        {(data) => {
          return (
            <SubSection
              {...data.courseSubsection}
              {...getAssets(data.courseSubsection)}
              product={product}
              version={version}
              onDeleteMaterial={this.handleDeleteMaterial}
              onUpdateMeta={this.handleUpdateSubSectionMeta}
              {...restProps}
            />
          );
        }}
      </QueryComponent>
    );
  }
}

export default compose(
  graphql(deleteMaterial, {
    name: 'deleteMaterial',
  }),
  graphql(updateSubSection, {
    name: 'updateSubSection',
  })
)(SubSectionContainer);
