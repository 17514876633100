import gql from 'graphql-tag';

export const ShortMaterialFragment = gql`
  fragment ShortMaterialFragment on Material {
    id
    title
    description
    type
    downloadable
  }
`;

export const DetailsMaterialFragment = gql`
  fragment DetailsMaterialFragment on Material {
    id
    ...ShortMaterialFragment
    rawContent
    content {
      ... on ArticleMaterial {
        type
        article
      }
    }
  }
  ${ShortMaterialFragment}
`;

export const getSingleMaterial = gql`
  query getSingleMaterial($version: COURSE_VERSION!, $id: ID!) {
    courseMaterial(version: $version, id: $id) {
      id
      ...DetailsMaterialFragment
    }
  }
  ${DetailsMaterialFragment}
`;

export const getAssetList = gql`
  query getAssetList($version: COURSE_VERSION!, $courseItemId: ID!) {
    listAssetFiles(version: $version, courseItemId: $courseItemId) {
      list
      groupKey
      bucket
      raw
    }
  }
`;
