import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input, Icon, Table } from 'semantic-ui-react';

import delve from 'Util/Delve';
import { Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';
import { SemanticButton as Button } from 'Components/Base/Button';
import { useAppConfig } from 'Util/hoc/withAppConfig';

import { EXAM_LOGIC_TAG_CATEGORY } from './constants';
import { getOptionsWithUnselectedOption } from './QuestionForm/util';

const TagIdDropdown = ({
  category,
  options,
  value,
  onTagIdsChange,
  readOnly,
  multiple = false,
}) => {
  return (
    <Dropdown
      fluid
      search
      selection
      multiple={multiple}
      options={multiple ? options : getOptionsWithUnselectedOption(options)}
      value={value}
      onChange={(_, { value }) => onTagIdsChange(value, category)}
      disabled={readOnly}
    />
  );
};

const SingleLogic = ({
  logic,
  logicOptions,
  readOnly,
  onDeleteLogic,
  onChangeLogic,
  logicCategories = [],
}) => {
  const tagIds = delve(logic, 'tagIds', []);
  const totalQuestions = delve(logic, 'totalQuestions', 0);

  const parseTotalQuestionsToInt = (totalQuestionsStr) => {
    const totalQuestions = parseInt(totalQuestionsStr, 10);
    if (totalQuestions < 0 || isNaN(totalQuestions)) {
      return 0;
    }
    return totalQuestions;
  };

  const handleTagIdsChange = (tagId, category) => {
    const updatedTagIds = { ...tagIds };
    updatedTagIds[category] = tagId;
    onChangeLogic({
      tagIds: updatedTagIds,
      totalQuestions,
    });
  };

  const handleTotalQuestionsChange = (totalQuestions) => {
    onChangeLogic({
      tagIds,
      totalQuestions: parseTotalQuestionsToInt(totalQuestions),
    });
  };

  return (
    <Table.Row verticalAlign="top">
      {logicCategories.map(({ category }) => {
        const isMultipleSelect = category === EXAM_LOGIC_TAG_CATEGORY.SECTION.category;
        const value = delve(tagIds, category, isMultipleSelect ? [] : '');
        const options = delve(logicOptions, category, []);
        return (
          <Table.Cell key={category}>
            <TagIdDropdown
              multiple={isMultipleSelect}
              category={category}
              options={options}
              value={value}
              onTagIdsChange={handleTagIdsChange}
              readOnly={readOnly}
            />
          </Table.Cell>
        );
      })}
      <Table.Cell>
        <Box width={1} mr={`-120px`}>
          <Input
            value={totalQuestions}
            type="number"
            onChange={(_, { value }) => handleTotalQuestionsChange(value)}
            readOnly={readOnly}
          />
        </Box>
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button icon type="button" onClick={onDeleteLogic} disabled={readOnly}>
          <Icon name="close" />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

const LogicListInput = ({ value: logics, logicOptions, onChange, readOnly }) => {
  const { exam } = useAppConfig();

  const currentLogics = Array.isArray(logics) ? [...logics] : [];
  const enableQuestionFieldConfigurable = exam?.enableQuestionFieldConfigurable;
  const availableTags = exam?.question?.tags ?? [];
  const logicCategories = enableQuestionFieldConfigurable
    ? Object.keys(EXAM_LOGIC_TAG_CATEGORY)
        .map((key) => {
          const tag = availableTags.find((tag) => key === tag.category);
          return tag ? EXAM_LOGIC_TAG_CATEGORY[tag.category] : null;
        })
        .filter((e) => !!e)
    : Object.values(EXAM_LOGIC_TAG_CATEGORY);

  const onDeleteLogic = (index) => {
    handleOnChange(currentLogics.filter((_, idx) => index !== idx));
  };

  const onAddLogic = () => {
    handleOnChange([...currentLogics, { tagIds: {}, totalQuestions: 1 }]);
  };

  const onChangeLogic = (index, updatedLogic) => {
    currentLogics[index] = updatedLogic;
    handleOnChange(currentLogics);
  };

  const handleOnChange = (logics) => {
    onChange(
      {},
      {
        value: logics,
      }
    );
  };
  const logicsLength = logics.length;

  return (
    <Fragment>
      <Box mb={3}>
        {logicsLength === 0 && <Box>Please add condition</Box>}
        {logicsLength >= 1 && (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                {logicCategories.map(({ label, columnWidth }, index) => (
                  <Table.HeaderCell key={index} width={columnWidth}>
                    {label}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell width={1}>{`จำนวนข้อ`}</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {logics.map((logic, idx) => {
                return (
                  <SingleLogic
                    key={`${idx}-${logicsLength}`}
                    logic={logic}
                    readOnly={readOnly}
                    onDeleteLogic={() => onDeleteLogic(idx)}
                    onChangeLogic={(value) => onChangeLogic(idx, value)}
                    logicOptions={logicOptions}
                    logicCategories={logicCategories}
                  />
                );
              })}
            </Table.Body>
          </Table>
        )}
      </Box>
      {!readOnly && (
        <Button secondary circular fluid disabled={readOnly} type="button" onClick={onAddLogic}>
          <Icon name="plus square" />
          Add new condition
        </Button>
      )}
    </Fragment>
  );
};

LogicListInput.propTypes = {
  value: PropTypes.arrayOf({
    tagIds: PropTypes.shape({
      [EXAM_LOGIC_TAG_CATEGORY.SECTION.category]: PropTypes.arrayOf(PropTypes.string),
      [EXAM_LOGIC_TAG_CATEGORY.SUBTOPIC.category]: PropTypes.string,
      [EXAM_LOGIC_TAG_CATEGORY.BLOOM.category]: PropTypes.string,
      [EXAM_LOGIC_TAG_CATEGORY.INDICATOR.category]: PropTypes.string,
      [EXAM_LOGIC_TAG_CATEGORY.OBJECTIVE.category]: PropTypes.string,
    }),
    totalQuestions: PropTypes.number,
  }),
  logicOptions: PropTypes.shape({
    [EXAM_LOGIC_TAG_CATEGORY.SECTION.category]: PropTypes.arrayOf(PropTypes.string),
    [EXAM_LOGIC_TAG_CATEGORY.SUBTOPIC.category]: PropTypes.arrayOf(PropTypes.string),
    [EXAM_LOGIC_TAG_CATEGORY.BLOOM.category]: PropTypes.arrayOf(PropTypes.string),
    [EXAM_LOGIC_TAG_CATEGORY.INDICATOR.category]: PropTypes.arrayOf(PropTypes.string),
    [EXAM_LOGIC_TAG_CATEGORY.OBJECTIVE.category]: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

LogicListInput.defaultProps = {
  value: [],
  logicOptions: {
    [EXAM_LOGIC_TAG_CATEGORY.SECTION.category]: [],
    [EXAM_LOGIC_TAG_CATEGORY.SUBTOPIC.category]: [],
    [EXAM_LOGIC_TAG_CATEGORY.BLOOM.category]: [],
    [EXAM_LOGIC_TAG_CATEGORY.INDICATOR.category]: [],
    [EXAM_LOGIC_TAG_CATEGORY.OBJECTIVE.category]: [],
  },
  onChange: () => {},
  readOnly: false,
};

export default withFormsy(LogicListInput);
