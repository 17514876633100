import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import SubscriptionExternalInput from '../SubscriptionExternalInput';

const getFields = (props) => [
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Platform',
    inputName: 'externalConfig',
    customInput: (props) => <SubscriptionExternalInput {...props} />,
  },
];

class SubscriptionPlatformAndExternalIdForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  handleSubmit = () => {};

  render() {
    const { isEdit, onSubmit } = this.props;
    return (
      <FormGenerator
        fields={getFields(this.props)}
        onSubmit={onSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}

export default SubscriptionPlatformAndExternalIdForm;
