import { useState, useEffect, useCallback, useRef } from 'react';

const genPaging = (page, pageSize = 10) => ({ currentPage: page, pageSize: pageSize });
const genOrder = (sorted) => {
  if (sorted.length > 0) {
    const field = sorted[0].id;
    const type = sorted[0].desc ? 'DESC' : 'ASC';
    return { field, type };
  }
  return;
};

const useSearchFilter = (defaultFilter = {}) => {
  const {
    defaultPaging = { currentPage: 0, pageSize: 10 },
    defaultSearch = {},
    defaultOrder = null,
  } = defaultFilter;
  const [paging, setPaging] = useState(defaultPaging);
  const [search, setSearch] = useState(defaultSearch);
  const [order, setOrder] = useState(defaultOrder);
  const searchVariables = useRef(null);
  useEffect(() => {
    searchVariables.current = { paging, search, order };
  }, [order, paging, search]);

  const handlePageChange = useCallback((page) => {
    const pageSize = searchVariables.current?.paging?.pageSize;
    const paging = genPaging(page, pageSize);
    setPaging(paging);
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPaging(genPaging(0, pageSize));
  }, []);

  const handleSearchChange = useCallback((search) => {
    const pageSize = searchVariables.current?.paging?.pageSize;
    const paging = genPaging(0, pageSize);
    setPaging(paging);
    setSearch((prev) => ({ ...prev, ...search }));
  }, []);

  const handleSortedChange = useCallback((sorted) => {
    const pageSize = searchVariables.current?.paging?.pageSize;
    const order = genOrder(sorted, pageSize);
    const paging = genPaging(0);
    setOrder(order);
    setPaging(paging);
  }, []);

  return {
    onSortedChange: handleSortedChange,
    onSearchChange: handleSearchChange,
    onPageChange: handlePageChange,
    onPageSizeChange: handlePageSizeChange,
    search,
    order,
    paging,
  };
};

export { useSearchFilter };
