import React, { useState, createContext, useEffect, useContext, useCallback } from 'react';

import { ROLES, addNode, removeNode, editNode } from './WireSyncDomain';
const WireSyncContext = createContext();

const WireSyncContextProvider = ({ children, initialNodes }) => {
  const initializeState = (overrideNodes) =>
    addNode({}, overrideNodes?.keys, overrideNodes?.defaultValue, overrideNodes?.defaultRole);

  const [node, setNode] = useState(() => (initialNodes ? initializeState(initialNodes) : {}));

  const handleAddNode = (currentNode = node, keys, defaultValues, defaultRole) => {
    try {
      const newNode = addNode(currentNode, keys, defaultValues, defaultRole);
      if (JSON.stringify(newNode) !== JSON.stringify(node)) {
        setNode(newNode);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateNodeValue = (currentNode = node, nodeKey, value, forceRole, forceSyncAll) => {
    try {
      const newNode = editNode(currentNode, nodeKey, value, forceRole, forceSyncAll);
      if (JSON.stringify(newNode) !== JSON.stringify(node)) {
        setNode(newNode);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleReset = (overrideNodes = initialNodes) => {
  //   try {
  //     const initialNode = initializeState(overrideNodes);
  //     setNode(initialNode);
  //   } catch (error) {
  //     setNode({});
  //   }
  // };

  const handleRemoveNode = (keys) => {
    if (!node[keys]) return;
    try {
      const newNode = removeNode(node, keys);
      if (JSON.stringify(newNode) !== JSON.stringify(node)) {
        setNode(newNode);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <WireSyncContext.Provider
      value={{
        node,
        addNode: handleAddNode,
        updateNode: handleUpdateNodeValue,
        removeNode: handleRemoveNode,
        ROLES,
      }}
    >
      {children}
    </WireSyncContext.Provider>
  );
};

const useWireSync = (nodeId, { initAs } = {}) => {
  const context = useContext(WireSyncContext);
  if (context === undefined) {
    throw new Error('useWireSync must be used within a WireSyncProvider');
  }
  const { addNode, removeNode, updateNode, node, ROLES } = context;
  const addCallback = useCallback(addNode, []);
  const removeCallback = useCallback(removeNode, []);
  useEffect(() => {
    if (typeof nodeId === 'string' && !node[nodeId]) {
      addCallback(node, nodeId, {}, { [nodeId]: initAs });
    }
  }, [addCallback, initAs, node, nodeId]);
  useEffect(() => {
    return () => {
      removeCallback(nodeId);
    };
  }, [nodeId, removeCallback]);
  return {
    role: node?.[nodeId]?.role,
    value: node?.[nodeId]?.value,
    setValue: (value, forceRole, forceSyncAll) =>
      updateNode(node, nodeId, value, forceRole, forceSyncAll),
    node,
    ROLES,
  };
};

export { useWireSync, ROLES };
export default WireSyncContextProvider;
