import React from 'react';

import { SKU_CATEGORY } from 'Routes/Products/Domains/constants';
import {
  createBookCategoryMutation,
  updateBookCategoryMutation,
} from 'Routes/Products/GraphQL/mutation/Products.mutation';
import {
  getBookCategoryByPermalink,
  getBookCategories,
  getBookCategory,
} from 'Routes/Products/GraphQL/query/Products.query';

import CategoriesBase from './BaseComponent/CategoriesBase';
export const CategoriesBookRoute = (props) => {
  return (
    <CategoriesBase
      {...props}
      skuCategory={SKU_CATEGORY.BOOK}
      getCategoryByPermalinkQueryName="sfBoProductBookCategoryByPermalink"
      getCategoryByPermalink={getBookCategoryByPermalink}
      getCategoriesQueryName="sfBoProductBookCategories"
      getCategories={getBookCategories}
      getCategory={getBookCategory}
      getCategoryQueryName="sfBoProductBookCategory"
      createMutation={createBookCategoryMutation}
      updateMutation={updateBookCategoryMutation}
    />
  );
};
