import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { isNil, omit } from 'lodash';
import isUrl from 'is-url';

import { ONLINE_COURSE_CONDITION } from 'Routes/Products/Domains/constants';
import { getAllCourses, COURSE_VERSION } from 'GraphQL/query/Courses.query';
import { getOrganizations } from 'GraphQL/query/Users.query';
import { createUserFileImportJobs as createUserFileImportJobsMutation } from 'GraphQL/mutation/Users.mutation';
import { uploadBatchEnrollment } from 'Util/UserAPI/UploadApi';
import {
  getSubscription,
  getSubscriptions as getSubscriptionsQuery,
} from 'Routes/Products/GraphQL/query/Products.query';
import getConfig from 'Util/Config';

import { CHECKABLE_FIELDS } from './constants';
import BatchCreationView from './BatchCreation.view';
function BatchCreation(props) {
  const [organizationId, setOrganizationId] = useState(null);
  const [uploadBatchEnrollmentStatus, setUploadBatchEnrollmentStatus] = useState(null);
  const [uploadBatchEnrollmentErrorMessage, setUploadBatchEnrollmentErrorMessage] = useState(null);
  const { loading: organizationLoading, data: organizationData } = useQuery(getOrganizations, {
    variables: {
      search: {},
      paging: { currentPage: 0, pageSize: 10 },
    },
    fetchPolicy: 'network-only',
  });
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [coursesToEnroll, setCoursesToEnroll] = useState([]);
  const [canExpire, setCanExpire] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [canOverrideCredit, setCanOverrideCredit] = useState(false);
  const [subscriptionCreditAmount, setSubscriptionCreditAmount] = useState(0);
  const [canInsertEnterWebsiteUrl, setCanInsertEnterWebsiteUrl] = useState(false);
  const [canInsertEmailTemplateId, setCanInsertEmailTemplateId] = useState(false);
  const [enterWebsiteUrl, setEnterWebsiteUrl] = useState('');
  const [uploadList, setUploadList] = useState([]);
  const [refCode, setRefCode] = useState('');
  const [validationError, setValidationErrors] = useState({});
  const [subscriptionsOption, setSubscriptionsOption] = useState([]);
  const [enrollProductType, setEnrollProductType] = useState('courses');
  const [subscriptionToEnroll, setSubscriptionToEnroll] = useState('');
  const [emailTemplateId, setEmailTemplateId] = useState('');

  const [createUserFileImportJobs, {}] = useMutation(createUserFileImportJobsMutation);
  const [getCoursesOptionsV2, { data: coursesOptionsV2 }] = useLazyQuery(getAllCourses, {
    variables: {
      search: { version: COURSE_VERSION.SKOOLDIO_V2 },
    },
  });
  const [getSubscriptionsFn, { data: subscriptionsOptionData }] = useLazyQuery(
    getSubscriptionsQuery,
    {
      variables: { paging: { currentPage: 0, pageSize: 200 } },
    }
  );

  const [getSubscriptionsDataFn, { data: subscriptionsData }] = useLazyQuery(getSubscription, {
    variables: {
      SKUCode: subscriptionToEnroll,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setCanExpire(false);
    setCanOverrideCredit(false);
    setCanInsertEnterWebsiteUrl(false);
    setCanInsertEmailTemplateId(false);
    setExpiryDate(null);
    setSubscriptionCreditAmount(0);
    setEnterWebsiteUrl('');
  }, [enrollProductType]);

  useEffect(() => {
    getSubscriptionsDataFn();
  }, [subscriptionToEnroll]);

  const creditAmount = subscriptionsData?.sfBoProductSubscription?.redemption?.creditAmount;
  const isEnableCredit = subscriptionsData?.sfBoProductSubscription?.redemption?.enableCredit;
  const limitDays = subscriptionsData?.sfBoProductSubscription?.conditions?.[0].days;
  const conditionType = subscriptionsData?.sfBoProductSubscription?.conditions?.[0].type;

  useEffect(() => {
    if (isEnableCredit && !isNil(creditAmount)) {
      setSubscriptionCreditAmount(creditAmount);
    } else {
      setSubscriptionCreditAmount(0);
    }

    if (conditionType === ONLINE_COURSE_CONDITION.DAYS_VALIDITY && !isNil(limitDays)) {
      setExpiryDate(moment().add(limitDays, 'days'));
    } else {
      setExpiryDate(null);
    }

    setCanExpire(false);
    setCanOverrideCredit(false);
    setCanInsertEnterWebsiteUrl(false);
    setCanInsertEmailTemplateId(false);
  }, [subscriptionsData]);

  const handleUpload = async (uploadList) => {
    if (enrollProductType === 'courses' && coursesToEnroll.length === 0) {
      setValidationErrors({ coursesToEnroll: true });
      return null;
    }

    if (enrollProductType === 'subscription' && !subscriptionToEnroll) {
      setValidationErrors({ subscriptionToEnroll: true });
      return null;
    }

    if (canExpire && !expiryDate) {
      setValidationErrors({ expiryDate: true });
      return null;
    }

    if (canOverrideCredit && (subscriptionCreditAmount < 0 || isNil(subscriptionCreditAmount))) {
      setValidationErrors({ subscriptionCreditAmount: true });
      return null;
    }

    if (canInsertEnterWebsiteUrl && !isUrl(enterWebsiteUrl)) {
      setValidationErrors({ enterWebsiteUrl: true });
      return null;
    }

    if (canInsertEmailTemplateId && (emailTemplateId === '' || emailTemplateId.length === 0)) {
      setValidationErrors({ emailTemplateIdLength: true });
      return null;
    }

    if (refCode.length === 0) {
      setValidationErrors({ refCodeRequired: true });
      return null;
    }

    if (refCode.length > 50) {
      setValidationErrors({ refCodeLength: true });
      return null;
    }

    setValidationErrors({});
    setUploadList([]);
    try {
      const USER_STORAGE_PROVIDER = getConfig().userStorageProvider;
      const data = await uploadBatchEnrollment(uploadList);
      const fileResult = data?.result?.files?.fileResult ?? [];
      const fileResultIncludeUserDetail =
        fileResult.length > 0
          ? fileResult.map((fr) => {
              return {
                ...omit(fr, ['field', 'size', 'providerResponse']),
                refCode,
                provider: USER_STORAGE_PROVIDER ?? 'filesystem',
                organizationId,
                expiryDate: canExpire ? expiryDate?.toISOString() : undefined,
                subscriptionCreditAmount: canOverrideCredit ? subscriptionCreditAmount : undefined,
                enterWebsiteUrl: canInsertEnterWebsiteUrl ? enterWebsiteUrl : undefined,
                emailTemplateId: canInsertEmailTemplateId ? emailTemplateId : undefined,
                enrollProductType,
                subscriptionToEnroll,
                coursesToEnroll: coursesToEnroll.map((course) => {
                  if (typeof course === 'string') {
                    return course;
                  }
                  return course.courseVersion === COURSE_VERSION.SKOOLDIO_V1
                    ? course.meta?.referId
                    : course.courseCode;
                }),
              };
            })
          : [];
      await createUserFileImportJobs({
        variables: { fileImportJobData: fileResultIncludeUserDetail },
      });
      setUploadBatchEnrollmentStatus('success');
    } catch (err) {
      setUploadBatchEnrollmentStatus('error');
      setUploadBatchEnrollmentErrorMessage(err);
    }
  };

  const handleEnableFields = (isChecked, field) => {
    switch (field) {
      case CHECKABLE_FIELDS.EXPIRY_DATE:
        setCanExpire(isChecked);
        break;
      case CHECKABLE_FIELDS.CREDIT:
        setCanOverrideCredit(isChecked);
        break;
      case CHECKABLE_FIELDS.WEBSITE_URL:
        setCanInsertEnterWebsiteUrl(isChecked);
        break;
      case CHECKABLE_FIELDS.EMAIL_TEMPLATE_ID:
        setCanInsertEmailTemplateId(isChecked);
        break;
      default:
        return;
    }
  };
  const handleExpiryDateChange = (date) => {
    setExpiryDate(date);
  };

  const handleOverrideCreditChange = (e) => {
    const { value } = e.target;
    setSubscriptionCreditAmount(value ? Number(value) : null);
  };

  const handleEnterWebsiteUrlChange = (e) => {
    const { value } = e.target;
    setEnterWebsiteUrl(value);
  };

  const handleEmailTemplateIdChange = (e) => {
    const { value } = e.target;
    setEmailTemplateId(value);
  };

  const handleCheckableFieldsValueChange = (input, field) => {
    switch (field) {
      case CHECKABLE_FIELDS.EXPIRY_DATE:
        handleExpiryDateChange(input);
        break;
      case CHECKABLE_FIELDS.CREDIT:
        handleOverrideCreditChange(input);
        break;
      case CHECKABLE_FIELDS.WEBSITE_URL:
        handleEnterWebsiteUrlChange(input);
        break;
      case CHECKABLE_FIELDS.EMAIL_TEMPLATE_ID:
        handleEmailTemplateIdChange(input);
        break;
      default:
        return;
    }
  };

  const handleRefCodeChange = ({ type, reason }) => {
    setValidationErrors({
      refCodeRequired: undefined,
      refCodeTextRequired: undefined,
      refCodeLength: undefined,
      refCodeValidations: undefined,
    });
    if (type === null || type === undefined) {
      setValidationErrors({ refCodeRequired: true });
    } else if (reason === null || reason === undefined || reason.trim().length === 0) {
      setValidationErrors({ refCodeTextRequired: true });
    } else {
      if (type.length + reason.length > 50) {
        setValidationErrors({ refCodeLength: true });
      } else {
        const refCodeOptions = getConfig().batchEnrollment?.refCodeOptions ?? [];
        const refCodeValidations = refCodeOptions.find((rco) => rco.key === type)?.validation;
        if (refCodeValidations) {
          const result = refCodeValidations.test(reason);
          if (result) {
            const value = `${type}:${reason}`;
            setRefCode(value);
          } else {
            setValidationErrors({ refCodeValidations: true });
          }
        } else {
          const value = `${type}:${reason}`;
          setRefCode(value);
        }
      }
    }
  };

  const getCoursesOptions = () => {
    getCoursesOptionsV2();
  };

  const getSubscriptions = () => {
    getSubscriptionsFn();
  };

  useEffect(() => {
    if (coursesOptionsV2) {
      const coursesDataV2 = coursesOptionsV2?.courses?.courses ?? [];
      setCoursesOptions(coursesDataV2);
    }
  }, [coursesOptionsV2]);

  useEffect(() => {
    if (subscriptionsOptionData) {
      const data = subscriptionsOptionData?.sfBoProductSubscriptions?.subscriptions ?? [];
      setSubscriptionsOption(data);
    }
  }, [subscriptionsOptionData]);

  return (
    <BatchCreationView
      organizations={organizationData?.organizations?.organizations ?? []}
      organizationLoading={organizationLoading ?? false}
      onUpload={handleUpload}
      setOrganizationId={setOrganizationId}
      uploadBatchEnrollmentStatus={uploadBatchEnrollmentStatus}
      uploadBatchEnrollmentErrorMessage={uploadBatchEnrollmentErrorMessage}
      setUploadBatchEnrollmentStatus={setUploadBatchEnrollmentStatus}
      getCoursesOptions={getCoursesOptions}
      getSubscriptions={getSubscriptions}
      coursesOptions={coursesOptions}
      subscriptionsOption={subscriptionsOption}
      canExpire={canExpire}
      expiryDate={expiryDate}
      isEnableCredit={isEnableCredit}
      canOverrideCredit={canOverrideCredit}
      subscriptionCreditAmount={subscriptionCreditAmount}
      canInsertEnterWebsiteUrl={canInsertEnterWebsiteUrl}
      canInsertEmailTemplateId={canInsertEmailTemplateId}
      enterWebsiteUrl={enterWebsiteUrl}
      setCoursesToEnroll={setCoursesToEnroll}
      coursesToEnroll={coursesToEnroll}
      uploadList={uploadList}
      setUploadList={setUploadList}
      validationError={validationError}
      enrollProductType={enrollProductType}
      setEnrollProductType={setEnrollProductType}
      setSubscriptionToEnroll={setSubscriptionToEnroll}
      subscriptionToEnroll={subscriptionToEnroll}
      refCode={refCode}
      handleRefCodeChange={handleRefCodeChange}
      handleEnableFields={handleEnableFields}
      handleCheckableFieldsValueChange={handleCheckableFieldsValueChange}
      emailTemplateId={emailTemplateId}
    ></BatchCreationView>
  );
}

export default BatchCreation;
