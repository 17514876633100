import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import withAppConfig from 'Util/hoc/withAppConfig';

import StatefulModal from '../../../Components/Modal/StatefulModal';
import CreateEditForm from '../../../Containers/CreateEditForm';
import { createCourse, editCourse } from '../../../GraphQL/mutation/Courses.mutation';
import { getSingleCourseV1, getAllCourses } from '../../../GraphQL/query/Courses.query';
import AddCourseForm from '../Components/AddCourseForm';
import { productCourseVersionResolver } from '../../../GraphQL/query/AppConfig.query';
import { transformCourseEditorErrorToMessage } from '../Domain/ErrorHandler';

const convertCourseToForm = (course = {}) => ({
  ...course,
  ...course.meta,
  instructorIds: course.instructors ? course.instructors.map(({ id }) => parseInt(id)) : [],
  teamIds: course?.teams ? course.teams.map(({ id }) => id) : [],
});

const AddCourseModal = ({ variables, isEdit, trigger, courseId, product, version, appConfig }) => {
  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{isEdit ? 'Edit Course Information' : 'Create New Course'}</Modal.Header>
          <Modal.Content>
            <CreateEditForm
              dataQuery={getSingleCourseV1}
              createMutation={createCourse}
              createRefetchQueries={[
                {
                  query: getAllCourses,
                  variables,
                },
              ]}
              editMutation={editCourse}
              isEdit={isEdit}
              id={courseId}
              getQueryVariables={({ id }) => ({
                courseId: id,
                version: productCourseVersionResolver(product, version),
                enabledContentTeam: appConfig?.enabledContentTeam,
              })}
              getEditVariables={(form, { id }) => ({
                courseId: id,
                version: productCourseVersionResolver(product, version),
                courseDesc: form,
                enabledContentTeam: appConfig?.enabledContentTeam,
              })}
              getCreateVariables={(form) => ({
                version: productCourseVersionResolver(product, version),
                courseDesc: form,
                enabledContentTeam: appConfig?.enabledContentTeam,
              })}
              onCompleted={closeModal}
              onCancelled={closeModal}
              transformErrorToMessage={transformCourseEditorErrorToMessage}
            >
              {({ data, onSubmit }) => {
                return (
                  <AddCourseForm
                    initialData={data && convertCourseToForm(data.course)}
                    onSubmit={onSubmit}
                  />
                );
              }}
            </CreateEditForm>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
};

AddCourseModal.propTypes = {
  isEdit: PropTypes.bool,
  trigger: PropTypes.element,
  product: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  courseId: PropTypes.string,
  variables: PropTypes.object,
};

AddCourseModal.defaultProps = {
  isEdit: false,
};

export default withAppConfig({ configProp: 'appConfig' })(AddCourseModal);
