import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';

import { getCollections } from '../../GraphQL/query/Products.query';
import { COLLECTION_STATUS_SEARCH_OPTIONS } from '../../Domains/constants';
import { getCollectionDetailsOverviewUrl } from '../../Domains/resolveUrl';
import CollectionModal from '../../Components/CollectionModal';

const collectionsColumns = (pathname, handleEdit) => [
  {
    Header: 'SKU Code',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: (row) => (
      <Link to={{ pathname: `${pathname}/${row.original.SKUCode}/overview` }}>{row.value}</Link>
    ),
  },
  {
    Header: 'Status',
    accessor: 'SKUStatus',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: COLLECTION_STATUS_SEARCH_OPTIONS,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      return (
        <Fragment>
          <Button.Group>
            <Button basic size="mini" icon compact onClick={() => handleEdit(original.SKUCode)}>
              <Icon name="edit" />
            </Button>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class CollectionsRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: {},
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  handleEdit = (skuCode) => {
    this.props.history.push(getCollectionDetailsOverviewUrl(skuCode));
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoProductCollections.collections;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };

    const { location } = this.props;
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Collections</Text.Header>
          <CollectionModal
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New Collection
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={'sfBoProductCollections'}
          resolveData={this.resolveData}
          columns={collectionsColumns(location.pathname, this.handleEdit)}
          query={getCollections}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(CollectionsRoute);
