import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createParentProfileMutation,
  updateParentProfileMutation,
  updateProfileImageMutation,
} from 'GraphQL/LSP/mutation/Profile.mutation';
import { getParentProfiles, getParentProfile } from 'GraphQL/LSP/query/Profile.query';

import ParentProfileForm from './ParentProfileForm';

function ParentProfileModal(props) {
  const { isEdit, id, trigger, variables } = props;
  const [updateProfileImage] = useMutation(updateProfileImageMutation);

  const handleUpdateProfileImage = ({ key, bucket }) => {
    updateProfileImage({
      variables: {
        userId: id,
        profileImage: {
          key,
          bucket,
        },
      },
    });
  };

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{isEdit ? 'แก้ไขโปรไฟล์' : 'สร้างโปรไฟล์'}</Modal.Header>
          <Modal.Content>
            <CreateEditForm
              dataQuery={getParentProfile}
              createMutation={createParentProfileMutation}
              createRefetchQueries={[
                {
                  query: getParentProfiles,
                  variables,
                },
              ]}
              editRefetchQueries={[
                {
                  query: getParentProfiles,
                  variables,
                },
              ]}
              editMutation={updateParentProfileMutation}
              isEdit={isEdit}
              id={id}
              getQueryVariables={({ id }) => ({
                userId: id,
              })}
              getEditVariables={(form, { id }) => ({
                userId: id,
                data: {
                  code: form.code,
                  firstName: form.firstName,
                  lastName: form.lastName,
                  prefix: form.prefix,
                  nickName: form.nickName,
                  phoneNumber: form.phoneNumber,
                  address1: form.address1,
                  district: form.district,
                  province: form.province,
                  zipCode: form.zipCode,
                },
              })}
              getCreateVariables={(form) => ({
                data: form,
              })}
              onCompleted={closeModal}
              onCancelled={closeModal}
            >
              {({ data, onSubmit }) => {
                const initialData = data
                  ? { ...data.boParentProfile, ...data.boParentProfile.address }
                  : {};
                return (
                  <ParentProfileForm
                    initialData={initialData}
                    onUploadProfileImage={handleUpdateProfileImage}
                    onSubmit={onSubmit}
                  />
                );
              }}
            </CreateEditForm>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default ParentProfileModal;
