import gql from 'graphql-tag';

export const UserDeviceTokenFragment = gql`
  fragment UserDeviceTokenFragment on UserDeviceToken {
    id
    userId
    tokenNo
    tokenStatus
    createdAt
    latestUsedAt
    deviceFingerprint {
      os
      platform
      cpuCore
      deviceMemory
    }
  }
`;

export const userDeviceTokensQuery = gql`
  query getUserDeviceTokens($userId: ID!) {
    userDeviceTokens(userId: $userId) {
      id
      deviceTokens {
        id
        ...UserDeviceTokenFragment
      }
    }
  }
  ${UserDeviceTokenFragment}
`;
