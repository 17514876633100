import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getTransactionLogs } from '../../../GraphQL/query/Purchase.query';
import QueryTable from '../../../GraphQL/util/QueryTable';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';
import { DateFormat } from '../common';

const logColumns = [
  {
    Header: 'Transaction id',
    accessor: 'id',
    width: 300,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'User id',
    accessor: 'userId',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Time stamp',
    accessor: 'createdAt',
    width: 200,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
    Cell: ({ value }) => <DateFormat date={value} />,
  },
  {
    Header: 'Credit',
    accessor: 'credit',
    width: 150,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: 'Debit',
    accessor: 'debit',
    width: 150,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
];
class TransactionLog extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
  };
  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    return (
      <QueryTable
        type="transactions"
        columns={logColumns}
        query={getTransactionLogs}
        search={search}
        onSearchChange={this.handleSearchChange}
        paging={paging}
        onPageChange={this.handlePageChange}
        onPageSizeChange={this.handlePageSizeChange}
        order={order}
        onSortedChange={this.handleSortedChange}
      />
    );
  }
}

export default TransactionLog;
