import React, { Fragment, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import BaseMenu from 'Containers/Menu';
import { GenSwitchRoute } from 'Util/Route';
import { Box, Flex, Text } from 'Components/Base';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { GET_TEAM } from '../../Graphql/query/Team.query';
import TeamDetails from '../../Containers/Team/TeamDetails';
import TeamUserPanel from '../../Containers/Team/TeamUserPanel';

const Menu = styled(BaseMenu)`
  &&&& {
    margin: 36px 0;
    border-bottom: 1px solid #d9d9d9;
    .active.item {
      border-color: ${({ theme: { colors } }) => colors?.primary};
      border-width: 4px;
    }
    & > :first-child {
      overflow: inherit;
    }
  }
`;

const Divider = styled(Box)`
  border-bottom: 1px solid #f2f2f2;
`;

const genMenu = (matchUrl) => [
  {
    path: `${matchUrl}`,
    title: 'Team Details',
    private: true,
    exact: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.CONTENT_TEAM.DETAILS.VIEW],
    route: TeamDetails,
  },
  {
    path: `${matchUrl}/users`,
    title: 'Users',
    private: true,
    exact: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.CONTENT_TEAM.USERS.VIEW],
    route: TeamUserPanel,
  },
];

const HeaderBlock = () => {
  const match = useRouteMatch();
  const teamId = match?.params?.teamId;
  const { data, loading, error } = useQuery(GET_TEAM, {
    variables: { id: teamId },
    skip: !teamId,
    fetchPolicy: 'network-only',
  });
  const teamName = data?.team?.name;
  return (
    <Flex mt={2}>
      <Text.Header fontSize="20px">Edit Team {teamName ? `| ${teamName}` : ``}</Text.Header>
    </Flex>
  );
};

const SingleTeamRoute = ({ match }) => {
  const teamId = match?.params?.teamId;
  const menus = useMemo(() => genMenu(match.url), [match.url]);

  const NavigationBlock = useMemo(
    () => (
      <Link to={'/users/teams'}>
        <Text>
          <Flex alignItems="center">
            <ArrowBackIosIcon style={{ width: '14px' }} />
            <span>Team Management</span>
          </Flex>
        </Text>
      </Link>
    ),
    []
  );

  return (
    <Fragment>
      {NavigationBlock}
      <HeaderBlock />
      <Divider />
      <Menu menus={menus} menuProps={{ secondary: true, pointing: true }} />
      <GenSwitchRoute routes={menus} teamId={teamId} />
    </Fragment>
  );
};

export default SingleTeamRoute;
