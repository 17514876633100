import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Carousel, Progress } from 'rebass';
import styled from 'styled-components';
import { height } from 'styled-system';
import { Segment as BaseSegment, List, Modal, Icon } from 'semantic-ui-react';
import prettyBytes from 'pretty-bytes';

import { Box, Flex, Text, AspectRatio } from 'Components/Base';
import { FONT_SIZE, COLOR } from 'Components/Theme';
import { SemanticButton as Button } from 'Components/Base/Button';
import ConfirmationButton from 'Components/ConfirmationButton';

const DropzoneView = styled(Dropzone)`
  text-align: center;
  background-color: ${COLOR.greyscale.grey5};
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: ${COLOR.greyscale.grey4};
  }
`;

const DropzoneButton = styled(Dropzone)`
  height: 0%;
  cursor: pointer;
`;
const AddVideoButton = ({ children, ...props }) => (
  <DropzoneButton {...props} multiple>
    <Button {...props} primary circular>
      {children}
    </Button>
  </DropzoneButton>
);

const Segment = styled(BaseSegment)`
  ${height};
`;

const ListContainer = styled(List)`
  height: 350px;
  overflow-y: auto;
  margin-bottom: 2px;
`;

const MODE = {
  upload: `upload`,
  progress: `progress`,
  preview: `preview`,
  loading: `loading`,
};

const VideoOutOfLimitModal = ({ isOpen, onClose, videoUploadMaxItems }) => (
  <Modal open={isOpen} closeIcon closeOnDimmerClick size="tiny" onClose={onClose}>
    <Modal.Header>Video uploading exceed limit</Modal.Header>
    <Modal.Content>{`Do not upload videos more than ${videoUploadMaxItems} files at a time`}</Modal.Content>
  </Modal>
);

class VideoDropZone extends Component {
  static MODE = MODE;

  static propTypes = {
    videoUploadMaxItems: PropTypes.number,
    acceptFileTypes: PropTypes.array,
    mode: PropTypes.oneOf(Object.values(MODE)),
    uploadProgresses: PropTypes.number,
    onFileDropped: PropTypes.func,
  };

  static defaultProps = {
    onFileDropped: () => {},
    videoUploadMaxItems: 10,
  };

  state = { videoUploadList: [], rejectedList: [], isReachUploadLimit: false };

  handleDrop = (accepted, rejected) => {
    const { videoUploadList, rejectedList } = this.state;
    const { videoUploadMaxItems } = this.props;
    const uploadCount = videoUploadList.length + accepted.length;
    if (videoUploadMaxItems < uploadCount) {
      this.setState({
        isReachUploadLimit: true,
      });
    } else {
      this.setState({
        isReachUploadLimit: false,
        videoUploadList: [...videoUploadList, ...accepted],
        rejectedList: [...rejectedList, ...rejected],
      });
    }
  };

  handleUpload = () => {
    const { videoUploadList } = this.state;
    this.props.onUpload(videoUploadList);
    this.setState({ uploaded: true, loading: true });
  };

  handleClear = () => {
    this.setState({ videoUploadList: [], rejectedList: [] });
  };

  handleRemoveVideoFromList = (index) => {
    const { videoUploadList } = this.state;
    videoUploadList.splice(index, 1);
    this.setState({
      videoUploadList: videoUploadList,
    });
  };

  handleCloseVideoOutOfLimitModal = () => {
    this.setState({
      isReachUploadLimit: false,
    });
  };

  render() {
    const {
      acceptFileTypes,
      mode,
      uploadProgresses,
      isAllowMultiple = true,
      videoUploadMaxItems,
    } = this.props;
    const { videoUploadList, loading, uploaded, isReachUploadLimit } = this.state;
    return (
      <Carousel>
        <AspectRatio ratio={16 / 9}>
          <Box width={1}>
            <VideoOutOfLimitModal
              isOpen={isReachUploadLimit}
              onClose={this.handleCloseVideoOutOfLimitModal}
              videoUploadMaxItems={videoUploadMaxItems}
            />
            {videoUploadList.length <= 0 && (
              <DropzoneView
                onDrop={this.handleDrop}
                accept={acceptFileTypes.join(',')}
                multiple={isAllowMultiple}
              >
                <Flex p={6} justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                  <div>
                    {this.state.rejectedList.length > 0 && <div>This file type is not support</div>}
                    <div>Drag file and drop here, or click to chose file</div>
                    <div>
                      (Support only {acceptFileTypes.map((ext) => ext.replace('.', '')).join(', ')})
                    </div>
                  </div>
                </Flex>
              </DropzoneView>
            )}
            {videoUploadList.length > 0 && (
              <Box width={1} p={1} pb={0} overflowY="scroll">
                <Flex justifyContent="flex-end" py={1}>
                  {isAllowMultiple && (
                    <AddVideoButton
                      onDrop={this.handleDrop}
                      accept={acceptFileTypes.join(',')}
                      loading={loading}
                      disabled={loading || uploaded}
                      multiple
                    >
                      + More videos
                    </AddVideoButton>
                  )}
                  <Button
                    primary
                    circular
                    loading={loading}
                    disabled={loading || uploaded}
                    onClick={this.handleUpload}
                  >
                    <Icon name="upload" />
                    Upload
                  </Button>
                  <ConfirmationButton
                    loading={loading}
                    headerText={`Clear?`}
                    contentText={'All videos will be cleared from the list.'}
                    confirmText={'Clear'}
                    buttonStyle={{ circular: true, loading, disabled: loading }}
                    onConfirm={this.handleClear}
                  >
                    Cancel
                  </ConfirmationButton>
                </Flex>
                <Flex justifyContent="space-between" my={3}>
                  <Text fontSize={FONT_SIZE.semilarge} color="black" textAlign="center">
                    ไฟล์
                  </Text>
                  <Text fontSize={FONT_SIZE.semilarge} color="black" textAlign="center">
                    {videoUploadList.length} ไฟล์
                  </Text>
                </Flex>
                <Flex flexDirection="column">
                  <ListContainer>
                    {videoUploadList.map((video, index) => {
                      const { name, size } = video;
                      const isProgress =
                        mode === MODE.progress && uploadProgresses && uploadProgresses[index];
                      const { loaded, total, progress } = isProgress
                        ? (() => {
                            const { loaded, total } = uploadProgresses[index];
                            return { loaded, total, progress: loaded / total };
                          })()
                        : { loaded: 0, total: size, progress: 0 };
                      return (
                        <List.Item key={index}>
                          <List.Content>
                            <Flex justifyContent="space-between" alignItems="center">
                              <Flex flex="2">
                                <Text fontSize={FONT_SIZE.normal} color="black" textAlign="center">
                                  {name}
                                </Text>
                              </Flex>
                              <Flex flex="1" justifyContent="space-between">
                                <Flex
                                  bg={COLOR.greyscale.grey4}
                                  width={90}
                                  py={0.5}
                                  css={{ borderRadius: '5px' }}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Text color="black" textAlign="center">
                                    {prettyBytes(size)}
                                  </Text>
                                </Flex>
                                {!isProgress && (
                                  <ConfirmationButton
                                    headerText={`Delete?`}
                                    contentText={`File ${name} will be deleted from list.`}
                                    confirmText={'Delete'}
                                    onConfirm={() => {
                                      this.handleRemoveVideoFromList(index);
                                    }}
                                  >
                                    <Icon name="x" />
                                  </ConfirmationButton>
                                )}
                              </Flex>
                            </Flex>
                            <Flex py={2} flexDirection="column" style={{ height: '100%' }}>
                              {isProgress && (
                                <Fragment>
                                  <Text pb={2}>{`${
                                    progress < 1
                                      ? `uploading ${prettyBytes(loaded)} from ${prettyBytes(
                                          total
                                        )}`
                                      : 'uploaded'
                                  } ${(progress * 100).toFixed()} %`}</Text>
                                  <Progress value={progress} color={COLOR.primary} />
                                </Fragment>
                              )}
                            </Flex>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </ListContainer>
                </Flex>
              </Box>
            )}
          </Box>

          {mode === MODE.loading && <Segment loading style={{ width: '100%', height: '100%' }} />}
        </AspectRatio>
      </Carousel>
    );
  }
}

export default VideoDropZone;
