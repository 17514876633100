import React from 'react';

import QueryComponent from 'GraphQL/util/QueryComponent';

import { getCollection } from '../../GraphQL/query/Products.query';

import CollectionDetails from './CollectionDetails';

const CollectionDetailsRoute = (props) => {
  const { match = {} } = props;
  const { params = {} } = match;
  const { SKUCode } = params;
  return (
    <QueryComponent
      query={getCollection}
      variables={{
        SKUCode,
      }}
      loadingOverlay
    >
      {(data) => {
        return <CollectionDetails {...props} collection={data && data.sfBoProductCollection} />;
      }}
    </QueryComponent>
  );
};

export default CollectionDetailsRoute;
