export const MENU_AUTH_HIDE = 'MENU:AUTH_HIDE';
export const MENU_AUTH_SHOW = 'MENU:AUTH_SHOW';

export const hideAuth = () => ({
  type: MENU_AUTH_HIDE,
});

export const showAuth = () => ({
  type: MENU_AUTH_SHOW,
});
