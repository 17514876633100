import { isArray } from 'util';

import React, { Component } from 'react';
import genUniqId from 'lodash/uniqueId';

import { Flex } from 'Components/Base';

import QueryTable from '../../../../GraphQL/util/QueryTable';
import COLUMN_TYPE from '../../../../Components/Search/SearchFormGenerator/constantType';
import { userExamQuestionReport as query } from '../../../../GraphQL/query/Reports.query';
import Text from '../../../../Components/Base/Text';
import { CourseSelectorInput, TagSelectorInput } from '../../Components/SelectorInput';
import { QUESTION_TYPE } from '../../../../GraphQL/query/ExamEditorV1.query';

const getQuestionChoiceType = (questionType, choices) => {
  if (
    questionType !== QUESTION_TYPE.MULTIPLE_CHOICE &&
    questionType !== QUESTION_TYPE.MULTIPLE_SELECTION
  ) {
    return questionType;
  } else if (!choices && !isArray(choices)) {
    return questionType;
  }
  return `${choices.length} Choices`;
};

const getColumns = () => [
  {
    Header: 'Course',
    accessor: 'courseTitle',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Question',
    accessor: 'questionTitle',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Courses',
    accessor: 'courseIds',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    show: false,
    CustomSearchComponent: (props) => (
      <CourseSelectorInput
        {...props}
        variables={{
          search: { version: CourseSelectorInput.COURSE_VERSION.SKOOLDIO_V2 },
          paging: { currentPage: 0, pageSize: 100 }, // TODO: Workaround for Input not searchable
          order: { field: 'id', type: 'ASC' },
        }}
        placeholder="Select Courses"
      />
    ),
  },
  {
    Header: 'Question Tag',
    accessor: 'questionTags',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => {
      const { questionTags } = original;
      if (!questionTags && !isArray(questionTags)) return null;
      return questionTags.map((tag) => <div key={tag}>{tag}</div>);
    },
    csvDisplay: ({ original }) => {
      const { questionTags } = original;
      if (!questionTags && !isArray(questionTags)) return null;
      return questionTags.join('|');
    },
  },
  {
    Header: 'Question Tag',
    accessor: 'questionTags',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    show: false,
    CustomSearchComponent: TagSelectorInput,
  },
  {
    Header: 'Question Type',
    accessor: 'questionType',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => {
      const { questionType, choices } = original;
      return getQuestionChoiceType(questionType, choices);
    },
    csvDisplay: ({ original }) => {
      const { questionType, choices } = original;
      return getQuestionChoiceType(questionType, choices);
    },
  },
  {
    Header: 'Answers',
    accessor: 'choices',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => {
      const { choices } = original;
      if (!choices && !isArray(choices)) return null;
      return choices.map((examAnswer, index) => (
        <div key={genUniqId()}>
          {index + 1}. {examAnswer.detail}
        </div>
      ));
    },
    csvDisplay: ({ original }) => {
      const { choices } = original;
      if (!choices && !isArray(choices)) return null;
      return choices.map((c, index) => `${index + 1}. ${c.detail}`).join(' | ');
    },
  },
  {
    Header: 'Correct Answer',
    accessor: 'correctResponse',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => {
      const { correctResponse } = original;
      if (!correctResponse && !isArray(correctResponse)) return null;
      return correctResponse.map((examAnswer, index) => (
        <div key={genUniqId()}>
          {index + 1}. {examAnswer.detail}
        </div>
      ));
    },
    csvDisplay: ({ original }) => {
      const { correctResponse } = original;
      if (!correctResponse && !isArray(correctResponse)) return null;
      return correctResponse.map((c, index) => `${index + 1}. ${c.detail}`).join(' | ');
    },
  },
  {
    Header: 'Number Of Show Up',
    accessor: 'numberOfShowUp',
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: false,
  },
  {
    Header: 'Total Correct',
    accessor: 'totalCorrect',
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: false,
  },
  {
    Header: 'Total Incorrect',
    accessor: 'totalIncorrect',
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: false,
  },
];

class UserExamQuestionReport extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 20 },
    search: {},
    order: { field: 'courseTitle', type: 'DESC' },
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Exam Question</Text.Header>
        </Flex>
        <QueryTable
          type="reportExamQuestion"
          resolveData={(data) => {
            return data.reportExamQuestion.examQuestions;
          }}
          columns={getColumns()}
          query={query}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          exportable
        />
      </div>
    );
  }
}

export default UserExamQuestionReport;
