import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';

const defaultChildrenFn = (data) => <div>{JSON.stringify(data)}</div>;
const defaultLoadingFn = (loadingOverlay) => (
  <Loading
    dimmer={loadingOverlay}
    loaderProps={!loadingOverlay ? { active: true, inline: 'centered' } : {}}
  />
);
const defaultErrorFn = (error) => <ErrorView message={error} />;

const QueryComponent = React.forwardRef(
  (
    {
      query,
      renderLoading,
      renderError,
      variables,
      skip,
      delay,
      children,
      loadingOverlay,
      fetchPolicy,
      pollInterval,
    },
    ref
  ) => {
    const {
      data,
      error,
      loading,
      refetch,
      fetchMore,
      startPolling,
      stopPolling,
      subscribeToMore,
      updateQuery,
    } = useQuery(query, {
      variables,
      skip,
      delay,
      fetchPolicy,
      pollInterval: pollInterval ?? 0,
    });
    useImperativeHandle(ref, () => ({
      refetch,
      fetchMore,
      startPolling,
      stopPolling,
      subscribeToMore,
      updateQuery,
    }));
    if (loading) return renderLoading(loadingOverlay);
    if (error) return renderError(error);
    return children(data, refetch);
  }
);

const FETCH_POLICY = {
  CACHE_FIRST: 'cache-first',
  CACHE_ONLY: 'cache-only',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  NO_CACHE: 'no-cache',
  STANDBY: 'standby',
};
QueryComponent.displayName = 'QueryComponent';
QueryComponent.propTypes = {
  query: PropTypes.object.isRequired,
  children: PropTypes.func,
  renderLoading: PropTypes.func,
  renderError: PropTypes.func,
  variables: PropTypes.object,
  skip: PropTypes.bool,
  delay: PropTypes.bool,
  loadingOverlay: PropTypes.bool,
  fetchPolicy: PropTypes.oneOf(Object.values(FETCH_POLICY)),
  pollInterval: PropTypes.number,
};

QueryComponent.defaultProps = {
  children: defaultChildrenFn,
  renderLoading: defaultLoadingFn,
  renderError: defaultErrorFn,
  loadingOverlay: false,
};

export default QueryComponent;
