import {
  EXAM_GENERATOR_TYPE,
  EXAM_LOGIC_TAG_CATEGORY,
  EXAM_QUESTION_STATUS,
  EXAM_TAG_CATEGORY,
} from '../Components/constants';
import { mapTagIdsToOptions } from '../Components/QuestionForm/util';

const getSlugsFromTags = (tags, targetCategory) => {
  const targetTags = tags.filter(({ category }) => category === targetCategory);
  return targetTags.map(({ slug }) => slug);
};

const getPublishedExamItemTags = (examItems) => {
  const tagCategories = Object.keys(EXAM_LOGIC_TAG_CATEGORY);
  return mapTagIdsToOptions(
    Array.from(
      new Set(
        examItems
          .filter(({ tags, status }) => tags !== null && status === EXAM_QUESTION_STATUS.PUBLISHED)
          .flatMap(({ tags }) => {
            return tags;
          })
      )
    ),
    tagCategories
  );
};

const getTagIdFromOptions = (tagIds, logicOptions) => {
  const tagId = {};
  Object.values(EXAM_LOGIC_TAG_CATEGORY).forEach(({ category }) => {
    const isMultipleSelect = category === EXAM_LOGIC_TAG_CATEGORY.SECTION.category;
    const categoryTagId = tagIds.filter(
      (tagId) =>
        logicOptions[category] && logicOptions[category].some(({ value }) => value === tagId)
    );
    tagId[category] = !isMultipleSelect ? categoryTagId.join('') : categoryTagId;
  });
  return tagId;
};

export const mapExamData = (examData = {}) => {
  const { metadata = {}, examItems = [], tags = [], ...restExam } = examData;
  const {
    timeLimit = 0,
    passingCriteria = {},
    generatorMetadata = {},
    owner,
    assignee,
    resultOptions = {},
  } = metadata;
  const { totalQuestionsPerTagGroups = [], questions: questionOrder = [] } = generatorMetadata;
  const courseCode = getSlugsFromTags(tags, EXAM_TAG_CATEGORY.COURSE_CODE);
  const type = getSlugsFromTags(tags, EXAM_TAG_CATEGORY.EXAM_TYPE);
  const subtopic = getSlugsFromTags(tags, EXAM_TAG_CATEGORY.SUBTOPIC);

  const logicOptions = examItems && getPublishedExamItemTags(examItems);
  const logics = totalQuestionsPerTagGroups.map(({ tagIds, totalQuestions }) => ({
    totalQuestions,
    tagIds: getTagIdFromOptions(tagIds, logicOptions),
  }));
  return {
    examItems: examItems.filter((examItem) => !examItem.deletedAt),
    courseCode,
    type: type && type[0],
    teamIds: restExam?.teams ? restExam.teams.map(({ id }) => id) : [],
    subtopic,
    timeLimit,
    passingCriteria,
    logics,
    logicOptions,
    tags,
    owner,
    assignee,
    generatorType: EXAM_GENERATOR_TYPE.RANDOM,
    questionOrder,
    resultOptions,
    ...restExam,
  };
};
