import COLOR from './colors';
import fs, { fontSizesValueList } from './fontSizes';
import sp, { spaceValueList } from './space';

const BS4_NATIVE_FONT_STACK =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

// Ref: https://chakra-ui.com/docs/theming/theme#z-index-values
const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  imageUploadOverlay: 800,
  navbar: 900,
  dropdown: 1000,
  drawer: 1100,
  modal: 1200, // Modal or Overlay or Dimmer
  snackbar: 1300, // Snackbar or Toast
  tooltip: 1400,
};

const rebassTheme = {
  breakpoints: ['32em', '48em', '64em', '80em'],
  space: spaceValueList,
  fonts: {
    sans: `Rubik, Kanit, ${BS4_NATIVE_FONT_STACK}`,
    mono: 'Pridi, Menlo, monospace',
  },
  fontSizes: fontSizesValueList,
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 500,
  },
  colors: COLOR,
  radii: [0, 2, 4, 8],
  zIndices,
};

export { COLOR, fs as FONT_SIZE, sp as SPACE };

export default rebassTheme;
