import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { getModules } from 'GraphQL/LSP/query/Syllabus.query';
import secondToMinute from 'Util/LSP/secondToMinute';

const columns = [
  {
    Header: 'Start Date',
    accessor: 'startDate',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Subject Code',
    accessor: 'subject.code',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Chapter',
    accessor: 'courseName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Name',
    accessor: 'subsectionName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Homework',
    accessor: 'isHomework',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: function ActionCell({ value }) {
      return value ? 'Yes' : 'No';
    },
  },
  {
    Header: 'AN CourseCode',
    accessor: 'courseCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'AN CourseItemCode',
    accessor: 'subsectionCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Type',
    accessor: 'contentType',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Duration',
    accessor: 'contentDuration',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: function ActionCell({ value }) {
      if (!value || value < 0) return '-';
      const { minutes, seconds } = secondToMinute(parseInt(value, 10));
      return `${minutes}:${seconds} นาที`;
    },
  },
];

class SyllabusModules extends Component {
  static propTypes = {
    syllabusId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { syllabusId: props.syllabusId },
      order: { field: 'createdAt', type: 'ASC' },
    };
  }

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, syllabusId: this.props.syllabusId }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.boModules.modules;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <Box>
        <QueryTable
          type={'boModules'}
          showSearchPanel={false}
          resolveData={this.resolveData}
          columns={columns}
          query={getModules}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Box>
    );
  }
}

export default withApollo(SyllabusModules);
