import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import omitDeep from 'Util/omitDeep';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import { getCollectionOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateCollectionOnlineCourseDetailsMutation } from '../../GraphQL/mutation/Products.mutation';
import { MarkupTextInput } from '../MarkupTextInput';

const OnlineCourseDetails = ({ onlineCourse = {} }) => {
  const { SKUCode, id } = onlineCourse;
  const { loading, error, data: queryData } = useQuery(getCollectionOnlineCourse, {
    variables: { SKUCode },
    fetchPolicy: 'network-only',
  });

  const [
    updateCollectionOnlineCourse,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(updateCollectionOnlineCourseDetailsMutation);

  const handleUpdate = (data) => {
    const clonedData = JSON.parse(JSON.stringify(data));
    const formatData = omitDeep(clonedData, ['id', '__typename', 'assetUrl']);
    if (formatData) {
      formatData.content = formatData.content ?? '';
    }
    updateCollectionOnlineCourse({
      variables: {
        id,
        details: formatData,
      },
    });
  };

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  const details = queryData?.sfBoProductCollectionOnlineCourse.details ?? '';

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={true}
        response={mutationResponse?.sfBoProductUpdateCollectionOnlineCourseDetails}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <MarkupTextInput loading={mutationLoading} value={details} onUpdate={handleUpdate} />
    </Fragment>
  );
};

OnlineCourseDetails.propTypes = {
  onlineCourse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default OnlineCourseDetails;
