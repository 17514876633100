import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import log from 'loglevel';

import NewUserForm from '../../../Components/Form/UserInfo';
import {
  createUserMutation,
  updateUserProfileMutation,
} from '../../../GraphQL/mutation/Users.mutation';
import { allUsersQuery, singleUserQuery } from '../../../GraphQL/query/Users.query';
import Snackbar, { VARIANTS } from '../Components/Snackbar';

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  MISMATCH_ORGANIZATION: {
    message: `User's email doesn't match with any organization, please check`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  USER_ALREADY_EXIST: {
    message: `User's email already exist, please check`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  UPDATE_UNEXPECTED_ERROR: {
    message: `Cannot update user, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  CREATE_UNEXPECTED_ERROR: {
    message: `Cannot create user, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};
const EditUser = ({ onComplete, onCancelled, userId, ...props }) => {
  const [feedback, setFeedback] = useState({});

  const onCompleted = useCallback(
    (response) => {
      const email = response?.updateUserProfile?.email;
      setFeedback({
        message: `User${email ? ` "${email}" ` : ' '}has been updated`,
        variant: VARIANTS.SUCCESS,
        show: true,
      });
      typeof onComplete === 'function' && setTimeout(onComplete, 1500);
    },
    [onComplete]
  );

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UPDATE_UNEXPECTED_ERROR);
  }, []);

  const [editUser, { loading }] = useMutation(updateUserProfileMutation, {
    onCompleted,
    onError,
    refetchQueries: [{ query: singleUserQuery, variables: { userId } }],
  });

  const { data, error, loading: userLoading } = useQuery(singleUserQuery, {
    variables: { userId },
    skip: !userId,
    onError: () => log.error(error),
  });

  return (
    <>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
      <NewUserForm
        onCreateNewUser={(value) => editUser({ variables: { userId, updateUser: value } })}
        loading={loading || userLoading}
        showCancel
        onCancelled={onCancelled}
        initialData={data?.user}
        {...props}
      />
    </>
  );
};

const CreateUser = ({ onComplete, onCancelled, ...props }) => {
  const [feedback, setFeedback] = useState({});

  const onCompleted = useCallback(
    (response) => {
      const email = response?.createUser?.email;
      setFeedback({
        message: `User${email ? ` "${email}" ` : ' '}has been added`,
        variant: VARIANTS.SUCCESS,
        show: true,
      });
      typeof onComplete === 'function' && setTimeout(onComplete, 1500);
    },
    [onComplete]
  );

  const onError = useCallback((response) => {
    log.error(response);
    const message = response?.message ?? '';

    if (message.includes('422') && message.includes('EMAIL_NOT_ALLOWED')) {
      setFeedback(FEEDBACK.MISMATCH_ORGANIZATION);
      return;
    }

    if (message.includes('422') && message.includes('already exist')) {
      setFeedback(FEEDBACK.USER_ALREADY_EXIST);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.CREATE_UNEXPECTED_ERROR);
  }, []);

  const [createUser, { loading }] = useMutation(createUserMutation, {
    onCompleted,
    onError,
    refetchQueries: [{ query: allUsersQuery }],
  });

  return (
    <>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
      <NewUserForm
        onCreateNewUser={(value) => createUser({ variables: { newUser: value } })}
        loading={loading}
        showCancel
        onCancelled={onCancelled}
        {...props}
      />
    </>
  );
};

const NewUserMutation = ({ isEdit, userId, onComplete, onCancelled, ...props }) => {
  if (!isEdit) return <CreateUser onComplete={onComplete} onCancelled={onCancelled} {...props} />;
  return (
    <EditUser isEdit userId={userId} onComplete={onComplete} onCancelled={onCancelled} {...props} />
  );
};

NewUserMutation.propTypes = {
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  userId: PropTypes.string,
  isEdit: PropTypes.bool,
};

NewUserMutation.defaultProps = {
  onComplete: () => {},
  onCancelled: () => {},
};

export default NewUserMutation;
