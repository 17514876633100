import gql from 'graphql-tag';

import { DiscountCodeFragment } from '../query/DiscountCode.query';

export const createDiscountCode = gql`
  mutation sfBoPaymentCreateDiscountCode($input: SFBoPaymentCreateDiscountCodeInput!) {
    sfBoPaymentCreateDiscountCode(input: $input) {
      ...DiscountCodeFragment
    }
  }
  ${DiscountCodeFragment}
`;

export const updateDiscountCode = gql`
  mutation sfBoPaymentUpdateDiscountCode($id: ID!, $input: SFBoPaymentUpdateDiscountCodeInput!) {
    sfBoPaymentUpdateDiscountCode(id: $id, input: $input) {
      ...DiscountCodeFragment
    }
  }
  ${DiscountCodeFragment}
`;

export const deleteDiscountCode = gql`
  mutation sfBoPaymentDeleteDiscountCode($id: ID!) {
    sfBoPaymentDeleteDiscountCode(id: $id)
  }
`;

export const createRandomizedDiscountCodeJob = gql`
  mutation sfBoPaymentCreateRandomDiscountCodeJob(
    $input: SFBoPaymentCreateRandomDiscountCodeJobInput!
  ) {
    sfBoPaymentCreateRandomDiscountCodeJob(input: $input) {
      id
      createdAt
      updatedAt
      status
      retriesLeft
      input {
        discountText
        amount
        useableTimes
        randomLength
        prefix
        discount
        discountUnit
        SKUIds
        productTypes
        exceptSKUIds
        startDate
        endDate
      }
      result {
        error
      }
    }
  }
`;

export const generateDownloadRandomDiscountCodeCSVSignedURL = gql`
  mutation sfBoPaymentGenerateSignedURLRandomDiscountCodeCSV($jobId: String!) {
    sfBoPaymentGenerateSignedURLRandomDiscountCodeCSV(jobId: $jobId) {
      url
    }
  }
`;
