import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Input, Icon as SemanticIcon, Form } from 'semantic-ui-react';
import MUIRadio from '@material-ui/core/Radio';
import { isNil } from 'lodash';

import { Flex, Box, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

import { DISCOUNT_UNIT } from '../../Domains/constants';

const Radio = withStyles({
  root: {
    '&$checked': {
      color: '#FAA919',
    },
  },
  checked: {},
})((props) => <MUIRadio color="default" {...props} />);

const RadioLabel = styled(Text)`
  &:hover {
    cursor: default;
  }
`;

const CustomIcon = ({ className }) => <i className={className + ' icon'}></i>;

const Icon = styled(CustomIcon)`
  &:before {
    content: '${({ content }) => content}';
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    margin-top: -0.5em;
  }
`;

const DEFAULT_DISCOUNT_VALUE = {
  [DISCOUNT_UNIT.FIX]: 0,
  [DISCOUNT_UNIT.PERCENT]: 0,
};

const initDiscountValue = (initValue) => {
  if (!initValue) return DEFAULT_DISCOUNT_VALUE;

  const { unit, value } = initValue;
  return { ...DEFAULT_DISCOUNT_VALUE, [unit]: value };
};

const isError = (unit, selectedUnit, value, isDirty) => {
  if (isDirty && unit === selectedUnit) {
    return value === '';
  }
  return false;
};

const DiscountUnitValueInput = ({ onChange, value: initValue, errorMessages, ...restprops }) => {
  const [unit, setUnit] = useState(initValue?.unit || DISCOUNT_UNIT.FIX);
  const [discountValue, setDiscountValue] = useState(initDiscountValue(initValue));

  const isFixValueError = isError(
    DISCOUNT_UNIT.FIX,
    unit,
    discountValue[DISCOUNT_UNIT.FIX],
    !isNil(errorMessages)
  );

  const isPercentValueError = isError(
    DISCOUNT_UNIT.PERCENT,
    unit,
    discountValue[DISCOUNT_UNIT.PERCENT],
    !isNil(errorMessages)
  );

  const handleChangeUnit = (event) => {
    setUnit(event.target.value);
  };

  const handleClickLabel = (value) => {
    setUnit(value);
  };

  const handleChangeDiscountValue = (key, value) => {
    if (key === DISCOUNT_UNIT.PERCENT) {
      if (value > 100) {
        setDiscountValue((prev) => ({ ...prev, [key]: 100 }));
        return;
      } else if (value < 0) {
        setDiscountValue((prev) => ({ ...prev, [key]: 0 }));
        return;
      }
    }

    if (key === DISCOUNT_UNIT.FIX) {
      if (value < 0) {
        setDiscountValue((prev) => ({ ...prev, [key]: 0 }));
        return;
      }
    }
    setDiscountValue((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    onChange({}, { value: { unit, value: discountValue[unit] } });
  }, [unit, discountValue]);

  return (
    <Box>
      <Flex alignItems="center" mb={2}>
        <Flex alignItems="center" width="150px">
          <Radio
            checked={unit === DISCOUNT_UNIT.FIX}
            onChange={handleChangeUnit}
            value={DISCOUNT_UNIT.FIX}
            name="discount-unit-radio"
            inputProps={{ 'aria-label': DISCOUNT_UNIT.FIX }}
          />
          <RadioLabel onClick={() => handleClickLabel(DISCOUNT_UNIT.FIX)}>
            มูลค่าส่วนลด (฿)
          </RadioLabel>
        </Flex>

        <Box width="129px" ml="1em">
          <Form.Input
            icon
            error={isFixValueError}
            onChange={(event) => handleChangeDiscountValue(DISCOUNT_UNIT.FIX, event.target.value)}
            value={discountValue[DISCOUNT_UNIT.FIX]}
            type="number"
          >
            <input />
            <Icon content="฿" />
          </Form.Input>
        </Box>
      </Flex>
      <Flex alignItems="center">
        <Flex alignItems="center" width="150px">
          <Radio
            checked={unit === DISCOUNT_UNIT.PERCENT}
            onChange={handleChangeUnit}
            value={DISCOUNT_UNIT.PERCENT}
            name="discount-unit-radio"
            inputProps={{ 'aria-label': DISCOUNT_UNIT.PERCENT }}
          />
          <RadioLabel onClick={() => handleClickLabel(DISCOUNT_UNIT.PERCENT)}>
            เปอร์เซ็นต์ (%)
          </RadioLabel>
        </Flex>

        <Box width="129px" ml="1em">
          <Form.Input
            icon
            error={isPercentValueError}
            onChange={(event) =>
              handleChangeDiscountValue(DISCOUNT_UNIT.PERCENT, event.target.value)
            }
            value={discountValue[DISCOUNT_UNIT.PERCENT]}
            type="number"
          >
            <input />
            <SemanticIcon name="percent" />
          </Form.Input>
        </Box>
      </Flex>
    </Box>
  );
};

export default withFormsy(DiscountUnitValueInput);
