import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';

import { Flex } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const FlexInput = styled(Flex)`
  & .react-datepicker__input-container {
    width: inherit;
  }
  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

const Wrapper = styled(Flex)`
  width: 50px;
`;

const PublishTimeRangeInput = ({ value: initValue, onChange, readOnly }) => {
  const [publishedTime, setPublishedTime] = useState(
    !_.isNil(initValue.publishedTime) ? moment(initValue.publishedTime) : moment()
  );
  const [unpublishedTime, setUnpublishedTime] = useState(
    !_.isNil(initValue.unpublishedTime) ? moment(initValue.unpublishedTime) : moment()
  );

  useEffect(() => {
    onChange({}, { value: { publishedTime, unpublishedTime } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedTime, unpublishedTime]);

  return (
    <>
      <FlexInput alignItems="center" mb={2}>
        <Wrapper>{'Start'}</Wrapper>
        <DatePicker
          selected={publishedTime}
          dateFormat="DD/MM/YYYY HH:mm"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          value={publishedTime}
          onChange={(value) => setPublishedTime(value)}
          readOnly={readOnly}
        />
      </FlexInput>
      <FlexInput alignItems="center">
        <Wrapper>{'End'}</Wrapper>
        <DatePicker
          selected={unpublishedTime}
          dateFormat="DD/MM/YYYY HH:mm"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          value={unpublishedTime}
          onChange={(value) => setUnpublishedTime(value)}
          readOnly={readOnly}
        />
      </FlexInput>
    </>
  );
};

PublishTimeRangeInput.propTypes = {
  value: PropTypes.object,
  checkBoxLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  status: PropTypes.string,
};

export default withFormsy(PublishTimeRangeInput);
