import React, { Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';

import { EXAM_QUESTION_STATUS, EXAM_QUESTION_TYPE } from '../constants';

import MultipleChoiceForm from './MultipleChoiceForm';
import MultipleSelectForm, { MaxAnswerInput } from './MultipleSelectForm';
import MultipleChoiceWithScoreRatioForm from './MultipleChoiceWithScoreRatioForm';
import MultipleSelectWithCorrectAnswersForm from './MultipleSelectWithCorrectAnswersForm';
import { AnswerNumberFieldForm, NumberFieldForm } from './FillNumberInput';
import MultipleSubQuestionsConnectionAnswersForm from './MultipleSubQuestionsConnectionAnswersForm';
import MultipleSubQuestionsYesNoAnswerForm from './MultipleSubQuestionsYesNoAnswerForm';

const MODE = {
  DRAFT: EXAM_QUESTION_STATUS.DRAFT,
  PUBLISHED: EXAM_QUESTION_STATUS.PUBLISHED,
  BOTH: 'BOTH',
};

const getQuestionType = (examId, publishMode, type) => {
  return [
    // FIXME: Fix form generator to render UI base on form value without unused spacing
    // Question Type: FILL_NUMBER
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputName: 'fieldNumber',
      inputProps: {
        required: publishMode,
        readonly: publishMode,
        hideLabel: true,
      },
      customInput: ({ getCurrentFormValues, ...props }) => {
        const _type = getCurrentFormValues()?.type ?? type;
        const isFillNumber = _type === EXAM_QUESTION_TYPE.FILL_NUMBER;

        return isFillNumber ? <NumberFieldForm {...props} /> : <Fragment />;
      },
      mode: MODE.DRAFT,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputName: 'numberResult',
      inputProps: {
        required: publishMode,
        readonly: publishMode,
        hideLabel: true,
      },
      customInput: ({ getCurrentFormValues, ...props }) => {
        const _type = getCurrentFormValues()?.type ?? type;
        const isFillNumber = _type === EXAM_QUESTION_TYPE.FILL_NUMBER;

        return isFillNumber ? <AnswerNumberFieldForm {...props} /> : <Fragment />;
      },
      mode: MODE.DRAFT,
    },

    // Question type MULTIPLE_SELECT with max answer
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: ({ getCurrentFormValues }) => {
        const _type = getCurrentFormValues().type ?? type;
        const isMultipleSelect = _type === EXAM_QUESTION_TYPE.MULTIPLE_SELECT;
        const label = isMultipleSelect ? 'เลือกคำตอบได้' : '';
        return label;
      },
      inputName: 'maxAnswers',
      inputProps: { readonly: publishMode },
      customInput: ({ getCurrentFormValues, ...props }) => {
        const _type = getCurrentFormValues()?.type ?? type;
        const isMultipleSelect = _type === EXAM_QUESTION_TYPE.MULTIPLE_SELECT;

        return isMultipleSelect ? <MaxAnswerInput {...props} /> : <Fragment />;
      },
      mode: MODE.DRAFT,
    },

    // Question Type: MULTIPLE_CHOICE
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputName: 'choice',
      inputProps: {
        required: publishMode,
        readonly: publishMode,
        hideLabel: true,
      },
      // FIXME: Get initial value of exam question type to multiple choice
      customInput: ({ getCurrentFormValues, ...props }) => {
        const _type = getCurrentFormValues()?.type ?? type;
        const isMultipleChoice = _type !== EXAM_QUESTION_TYPE.FILL_NUMBER;

        if (isMultipleChoice) {
          switch (_type) {
            case EXAM_QUESTION_TYPE.MULTIPLE_CHOICE_DIFFERENT_SCORE:
              return <MultipleChoiceWithScoreRatioForm examId={examId} {...props} />;
            case EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS:
              return <MultipleSubQuestionsConnectionAnswersForm examId={examId} {...props} />;
            case EXAM_QUESTION_TYPE.MULTIPLE_SELECT_WITH_CORRECT_ANSWERS:
              return <MultipleSelectWithCorrectAnswersForm examId={examId} {...props} />;
            case EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER:
              return <MultipleSubQuestionsYesNoAnswerForm examId={examId} {...props} />;
            case EXAM_QUESTION_TYPE.MULTIPLE_SELECT:
              return <MultipleSelectForm examId={examId} {...props} />;
            case EXAM_QUESTION_TYPE.MULTIPLE_CHOICE:
            default:
              return <MultipleChoiceForm examId={examId} {...props} />;
          }
        } else {
          return <Fragment />;
        }
      },
      mode: MODE.DRAFT,
    },
  ];
};

export { getQuestionType };
