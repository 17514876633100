import gql from 'graphql-tag';

import { EnrollmentDetailsFragment } from '../query/Registrar.query';

export const deleteEnrollmentMutation = gql`
  mutation deleteEnrollment($enrollmentId: ID!) {
    deleteEnrollment(enrollmentId: $enrollmentId)
  }
`;

export const enrollCourseMutation = gql`
  mutation enrollUserCourse(
    $userId: ID!
    $courseId: ID!
    $courseVersion: COURSE_VERSION = LEARNX_V1
  ) {
    enrollUserCourse(userCourse: { userId: $userId, courseId: $courseId }) {
      userId
      enrollments {
        id
        ...EnrollmentDetailsFragment
      }
    }
  }
  ${EnrollmentDetailsFragment}
`;

export const deleteStudentEnrollmentMutation = gql`
  mutation deleteStudentEnrollment($userId: ID!, $classroomId: ID!) {
    deleteStudentEnrollment(userId: $userId, classroomId: $classroomId)
  }
`;
