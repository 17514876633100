import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
const COURSE_COLUMN = {
  ID: {
    Header: 'Id',
    accessor: 'id',
    width: 170,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  COURSE_NAME: {
    Header: 'Course Name',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  COURSE_CODE: {
    Header: 'Course Code',
    accessor: 'courseCode',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  PERMALINK: {
    Header: 'Permalink',
    accessor: 'permalink',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      const permalink = original.meta.permalink;
      return permalink;
    },
  },
  STATUS: {
    Header: 'Status',
    accessor: 'status',
    width: 80,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: 'DRAFT', value: 'DRAFT', text: 'Draft' },
      { key: 'PUBLISH', value: 'PUBLISH', text: 'Publish' },
    ],
    isSearchAble: true,
    Cell: ({ original }) => {
      const { status } = original.meta;
      return status;
    },
  },
};

export { COURSE_COLUMN };
