import gql from 'graphql-tag';

const SFWorkshopBatchFragment = gql`
  fragment SFWorkshopBatchFragment on SFWorkshopBatch {
    id
    SKUCode
    SKUType
    SKUStatus
    isVisible
    title
    originalStock
    stock
    eventTime {
      startDate
      endDate
      startTime
      endTime
    }
    priceInclVAT {
      value
      unit
    }
    salePriceInclVAT {
      value
      unit
    }
    venueDisplayName
    venueLocation {
      displayAddress
      addressLocality
      addressRegion
      googlePlaceId
      location {
        lat
        lng
      }
      streetAddress
      postalCode
      addressCountry
      note
    }
    categories
  }
`;

export const getWorkshopBatches = gql`
  query getWorkshopBatches($SKUCode: String!) {
    sfWorkshopBySKUCode(SKUCode: $SKUCode) {
      id
      SKUCode
      SKUType
      SKUStatus
      isVisible
      batches {
        ...SFWorkshopBatchFragment
      }
    }
  }
  ${SFWorkshopBatchFragment}
`;

export const SFWorkshopTableOfContentsFragment = gql`
  fragment SFWorkshopTableOfContentsFragment on SFWorkshop {
    tableOfContents {
      title
      description {
        type
        content
      }
    }
  }
`;

export const getWorkshopTableOfContents = gql`
  query getWorkshopTableOfContents($SKUCode: String!) {
    sfWorkshopBySKUCode(SKUCode: $SKUCode) {
      id
      SKUCode
      SKUType
      SKUStatus
      ...SFWorkshopTableOfContentsFragment
    }
  }
  ${SFWorkshopTableOfContentsFragment}
`;

export const SFWorkshopFAQFragment = gql`
  fragment SFWorkshopFAQFragment on SFWorkshop {
    FAQ {
      question
      answer {
        type
        content
      }
    }
  }
`;

export const getWorkshopFAQ = gql`
  query getWorkshopFAQ($SKUCode: String!) {
    sfWorkshopBySKUCode(SKUCode: $SKUCode) {
      id
      SKUCode
      SKUType
      SKUStatus
      ...SFWorkshopFAQFragment
    }
  }
  ${SFWorkshopFAQFragment}
`;

export const SFWorkshopTestimonialsFragment = gql`
  fragment SFWorkshopTestimonialsFragment on SFWorkshop {
    testimonials {
      quote {
        type
        content
      }
      reviewer {
        type
        content
      }
      media {
        type
        assetUrl
        content {
          videoType
          key
          bucket
        }
      }
    }
  }
`;

export const getWorkshopTestimonials = gql`
  query getWorkshopTestimonials($SKUCode: String!) {
    sfWorkshopBySKUCode(SKUCode: $SKUCode) {
      id
      SKUCode
      SKUType
      SKUStatus
      ...SFWorkshopTestimonialsFragment
    }
  }
  ${SFWorkshopTestimonialsFragment}
`;

export const SFWorkshopSKUShortFragment = gql`
  fragment SFWorkshopSKUShortFragment on SFWorkshop {
    id
    title
    subTitle
    SKUCode
    SKUType
    SKUCategory
    permalink
    SKUStatus
    isVisible
  }
`;

export const SFWorkshopSKUFragment = gql`
  fragment SFWorkshopSKUFragment on SFWorkshop {
    promotionText {
      type
      content
    }
    contactInfo {
      type
      content
    }
    sharingImage {
      key
      bucket
      assetUrl
    }
    categories
    SEODescriptions
    SEOKeywords
    promotionYoutubeId
    workshopRequestUrl
    backgroundColor
    instructors
  }
`;

export const SFWorkshopHeaderFragment = gql`
  fragment SFWorkshopHeaderFragment on SFWorkshop {
    title
    subTitle
    SKUCode
    backgroundColor
    coverImage {
      key
      bucket
      assetUrl
    }
    cardImage {
      key
      bucket
      assetUrl
    }
  }
`;

export const getWorkshopBySKUCode = gql`
  query getWorkshopBySKUCode($SKUCode: String!) {
    sfWorkshopBySKUCode(SKUCode: $SKUCode) {
      ...SFWorkshopSKUShortFragment
      ...SFWorkshopSKUFragment
      ...SFWorkshopHeaderFragment
    }
  }
  ${SFWorkshopSKUFragment}
  ${SFWorkshopSKUShortFragment}
  ${SFWorkshopHeaderFragment}
`;

export const SFWorkshopHighlightFragment = gql`
  fragment SFWorkshopHighlightFragment on SFWorkshop {
    highlights {
      icon
      content
    }
  }
`;

export const getWorkshopHighlight = gql`
  query getWorkshopHighlight($SKUCode: String!) {
    sfWorkshopBySKUCode(SKUCode: $SKUCode) {
      ...SFWorkshopSKUShortFragment
      ...SFWorkshopHighlightFragment
    }
  }
  ${SFWorkshopSKUShortFragment}
  ${SFWorkshopHighlightFragment}
`;

export const SFWorkshopDescriptionFragment = gql`
  fragment SFWorkshopDescriptionFragment on SFWorkshop {
    details {
      type
      content
    }
  }
`;
export const getWorkshopDescription = gql`
  query getWorkshopDescription($SKUCode: String!) {
    sfWorkshopBySKUCode(SKUCode: $SKUCode) {
      ...SFWorkshopSKUShortFragment
      ...SFWorkshopDescriptionFragment
    }
  }
  ${SFWorkshopSKUShortFragment}
  ${SFWorkshopDescriptionFragment}
`;

export const getWorkshopGallery = gql`
  query getWorkshopGallery($SKUCode: String!) {
    sfWorkshopBySKUCode(SKUCode: $SKUCode) {
      ...SFWorkshopSKUShortFragment
      workshopImages {
        images {
          bucket
          key
          assetUrl
        }
      }
    }
  }
  ${SFWorkshopSKUShortFragment}
`;

export const SFSearchWorkshopFragment = gql`
  fragment SFSearchWorkshopFragment on SFSearchWorkshop {
    id
    title
    subTitle
    SKUCode
    SKUType
    SKUCategory
    permalink
    SKUStatus
    isVisible
    categories
  }
`;

export const searchWorkshops = gql`
  query searchWorkshops($search: SearchWorkshopInput!, $paging: PageInput!, $order: OrderInput!) {
    sfWorkshops(search: $search, paging: $paging, order: $order) {
      total
      workshops {
        ...SFSearchWorkshopFragment
      }
    }
  }
  ${SFSearchWorkshopFragment}
`;

export const getStatusValue = (status) => {
  return statusValue[status];
};

export const resolveStatus = (status, visible) => {
  let result;
  Object.entries(statusValue).forEach(([key, { SKUStatus, isVisible }]) => {
    if (isVisible === visible && SKUStatus === status) {
      result = key;
    }
  });
  return result;
};

export const SKUStatus = {
  COMING_SOON: 'COMING_SOON',
  SELLING: 'SELLING',
  SOLDOUT: 'SOLDOUT',
  UNPUBLISHED: 'UNPUBLISHED',
};

export const SKUText = {
  COMING_SOON: 'Coming Soon',
  SELLING: 'Selling',
  SOLDOUT: 'Soldout',
  UNPUBLISHED: 'Unpublish',
};

export const SKUFields = {
  id: 'id',
  SKUCode: 'SKUCode',
  title: 'title',
  SKUStatus: 'SKUStatus',
};

export const status = {
  VISIBLE_SELLING: 'VISIBLE_SELLING',
  VISIBLE_COMING_SOON: 'VISIBLE_COMING_SOON',
  VISIBLE_SOLDOUT: 'VISIBLE_SOLDOUT',
  INVISIBLE_SELLING: 'INVISIBLE_SELLING',
  INVISIBLE_COMING_SOON: 'INVISIBLE_COMING_SOON',
  INVISIBLE_SOLDOUT: 'INVISIBLE_SOLDOUT',
  UNPUBLISHED: 'UNPUBLISHED',
};

const statusValue = {
  VISIBLE_SELLING: { SKUStatus: SKUStatus.SELLING, isVisible: true },
  VISIBLE_COMING_SOON: { SKUStatus: SKUStatus.COMING_SOON, isVisible: true },
  VISIBLE_SOLDOUT: { SKUStatus: SKUStatus.SOLDOUT, isVisible: true },
  INVISIBLE_SELLING: { SKUStatus: SKUStatus.SELLING, isVisible: false },
  INVISIBLE_COMING_SOON: { SKUStatus: SKUStatus.COMING_SOON, isVisible: false },
  INVISIBLE_SOLDOUT: { SKUStatus: SKUStatus.SOLDOUT, isVisible: false },
  UNPUBLISHED: { SKUStatus: SKUStatus.UNPUBLISHED, isVisible: false },
};

export const statusText = {
  [status.VISIBLE_SELLING]: 'Visible Selling ผู้ใช้กดซื้อได้',
  [status.VISIBLE_COMING_SOON]: 'Visible Coming Soon ผู้ใช้เห็นว่ามีรอบ แต่กดซื้อไม่ได้',
  [status.VISIBLE_SOLDOUT]: 'Visible Soldout บังคับให้ผู้ใช้เห็นเป็น Soldout',
  [status.INVISIBLE_SELLING]: 'Invisible Selling  ผู้ใช้จะไม่เห็น แต่ผู้ใช้กดซื้อได้',
  [status.INVISIBLE_COMING_SOON]: 'Invisible Coming soon ผู้ใช้จะไม่เห็นและกดซื้อไม่ได้',
  [status.INVISIBLE_SOLDOUT]: 'Invisible Soldout ผู้ใช้จะไม่เห็นและกดซื้อไม่ได้',
  [status.UNPUBLISHED]: 'Unpublished ยังไม่วางจำหน่าย ผู้ใช้ไม่เห็น ผู้ใช้กดซื้อไม่ได้',
};
