import { getCourseOutline } from 'GraphQL/query/Courses.query';
import { genMultipleCourseAssetInput } from 'GraphQL/mutation/Material.mutation';
import { MATERIAL_TYPES } from 'Components/SubSection';
import { LEVEL } from 'Routes/Courses/EditCourse/Lessons/utils';
import getConfig from 'Util/Config';

export const transformVideo = (video, videoType) => {
  if (video) {
    const { videoKey, title, description = '' } = video;
    return {
      title,
      description,
      content: { videoKey, videoType, videoDetail: video },
    };
  }
  return undefined;
};

class VideoBackbone {
  constructor({
    productVersion,
    courseId,
    sections,
    addSection,
    addSubsection,
    addMultipleMaterials,
    createMaterial,
    materialDownloadable,
    updateVideos,
    courseOutline,
    level,
  }) {
    this.productVersion = productVersion;
    this.courseId = courseId;
    this.sections = sections;
    this.addSection = addSection;
    this.addSubsection = addSubsection;
    this.addMultipleMaterials = addMultipleMaterials;
    this.materialDownloadable = materialDownloadable;
    this.createMaterial = createMaterial;
    this.updateVideos = updateVideos;
    this.courseOutline = courseOutline;
    this.level = level;
  }

  createSection = async (sectionName) => {
    const { data } = await this.addSection({
      variables: {
        version: this.productVersion,
        courseId: this.courseId,
        sectionData: { title: sectionName, description: null, canPreview: true },
      },
    });
    return data.addCourseSection;
  };

  createSubsection = async (title, description, afterItemId) => {
    const { data } = await this.addSubsection({
      variables: {
        version: this.productVersion,
        courseId: this.courseId,
        afterItemId,
        subsectionData: {
          title,
          description,
          contentType: MATERIAL_TYPES.VIDEO,
          canPreview: true,
        },
      },
    });
    return data.addCourseSubsection;
  };

  createVideoMaterials = async (selectedVideos, sectionId) => {
    const courseAssetInput = genMultipleCourseAssetInput(
      selectedVideos,
      !this.materialDownloadable,
      this.materialDownloadable
    );

    const subSectionTitleList = [];
    const sectionTitleList = this.sections.map((section) => {
      section.subsections.forEach((subsection) => {
        subSectionTitleList.push(subsection.title);
      });
      return section.title;
    });

    const createdSection = [];
    const createdSubsection = [];
    const courseAssetInputs = [];
    for (const courseAsset of courseAssetInput) {
      const {
        title,
        description,
        content: {
          videoDetail: { sectionName },
        },
      } = courseAsset;
      // check if we need to create section or not
      if (this.level === LEVEL.COURSE && !sectionTitleList.includes(sectionName)) {
        sectionTitleList.push(sectionName);
        const addCourseSection = await this.createSection(sectionName);
        createdSection.push({
          id: addCourseSection.id,
          title: sectionName,
          subsections: [],
        });
      }

      // find their own section (from createdSection/Subsection or courseAssetInput)
      const AllSections = [...this.sections, ...createdSection];
      const section = AllSections.find((section) =>
        sectionId && section.id === sectionId ? true : section.title === sectionName
      );

      const afterItemId =
        section.subsections.length > 0
          ? section.subsections[section.subsections.length - 1].id
          : section.id;
      const addCourseSubsection = await this.createSubsection(title, description, afterItemId);
      courseAssetInputs.push({
        ...courseAsset,
        courseItemId: addCourseSubsection.id,
      });
      createdSubsection.push({ id: addCourseSubsection.id, title });
    }
    return this.addMultipleMaterials({
      variables: {
        type: MATERIAL_TYPES.VIDEO,
        data: courseAssetInputs,
      },
      refetchQueries: [
        {
          query: getCourseOutline,
          variables: { version: this.productVersion, courseId: this.courseId },
        },
      ],
    });
  };
  createVideoMaterial = async (video) => {
    return this.createMaterial(video);
  };
  updateVideoMaterial = async (videoUpdatedData, videoType) => {
    const { enabledByteArkStream } = getConfig();
    const {
      title,
      videoKey,
      description,
      shortDescription,
      thumbnailId: coverImageId,
    } = videoUpdatedData;
    const { data } = await this.updateVideos({
      variables: {
        videos: [{ title, videoKey, description, shortDescription, coverImageId }],
      },
    });

    const { success, updatedVideos } = enabledByteArkStream
      ? data?.vdmByteArkUpdateVideos
      : data?.vdmUpdateVideos;
    if (success) {
      return this.createMaterial(transformVideo(updatedVideos[0], videoType));
    }
  };
}

export default VideoBackbone;
