import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox, Button, Icon } from 'semantic-ui-react';

import { Flex, Box, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';
import RichTextEditor from 'Components/RichTextEditor';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

const EditorBox = styled(Box)`
  flex: 1;
  & .text-editor {
    width: 100%;
  }
`;

const SubQuestionInput = withFormsy(
  ({ value: { subQuestions = [], correctAnswer = { value: [] } }, onChange, examId, readonly }) => {
    const handleOnChange = ({ subQuestions, correctAnswer }) => {
      onChange({}, { value: { subQuestions, correctAnswer } });
    };

    const getHandleCheckboxChange = (checkboxNo) => {
      return (_event, checkboxValue) => {
        const newAnswers = [...correctAnswer.value];
        const answers = newAnswers.map((answer) => {
          if (answer.subQuestionValue === checkboxNo) {
            return { ...answer, correctAnswerValue: checkboxValue.checked };
          }
          return answer;
        });

        handleOnChange({ subQuestions, correctAnswer: { value: answers } });
      };
    };

    const handleLabelChange = (subQuestionData, index) => {
      const newSubQuestions = [...subQuestions];
      newSubQuestions[index] = { ...newSubQuestions[index], label: subQuestionData };

      handleOnChange({ subQuestions: newSubQuestions, correctAnswer });
    };

    const handleAddSubQuestion = () => {
      const correctAnswerValue =
        correctAnswer && Array.isArray(correctAnswer.value) ? correctAnswer.value : [];

      handleOnChange({
        subQuestions: [...subQuestions, { label: '', value: `${subQuestions.length + 1}` }],
        correctAnswer: {
          value: [
            ...correctAnswerValue,
            { subQuestionValue: `${subQuestions.length + 1}`, correctAnswerValue: false },
          ],
        },
      });
    };

    const handleDeleteSubQuestion = () => {
      const newSubQuestions = [...subQuestions];
      const newAnswers = [...correctAnswer.value];
      newSubQuestions.pop();
      newAnswers.pop();

      handleOnChange({ subQuestions: newSubQuestions, correctAnswer: { value: newAnswers } });
    };

    const hasSubQuestions = subQuestions.length >= 1;
    const correctAnswers = useMemo(
      () => correctAnswer?.value.map((answer) => answer.correctAnswerValue),
      [correctAnswer]
    );

    const subQuestionRow = [];

    if (hasSubQuestions) {
      subQuestions.forEach((subQuestion, index) => {
        const checkboxNo = `${index + 1}`;

        return subQuestionRow.push(
          <Flex my={2} key={`subQuestion_${index}`} id={`subQuestion_${index}`}>
            <Flex minWidth="84px">
              <Checkbox
                label={`choice ${checkboxNo}`}
                onChange={getHandleCheckboxChange(checkboxNo)}
                checked={correctAnswers[index]}
                style={{ height: '16px' }}
              />
            </Flex>
            <EditorBox ml={2}>
              <RichTextEditor
                onChange={(data) => handleLabelChange(data, index)}
                value={subQuestion.label}
                contentFormat={`html`}
                returnValueAsString
                customControls={[
                  {
                    type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                    data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                  },
                ]}
              />
            </EditorBox>
          </Flex>
        );
      });
      subQuestionRow.push(
        <Flex justifyContent="flex-end" key={`subQuestion_delete`}>
          <Button icon compact basic type="button" color="red" onClick={handleDeleteSubQuestion}>
            <Icon name="trash alternate outline" />
          </Button>
        </Flex>
      );
    }
    subQuestionRow.push(
      <Box my={2} key={`subQuestion_add`}>
        <Button
          disabled={readonly}
          secondary
          circular
          fluid
          type="button"
          onClick={handleAddSubQuestion}
        >
          <Icon name="plus square" />
          Add New Choice
        </Button>
      </Box>
    );
    return subQuestionRow;
  }
);

/** The name of the component is derived from the solution check pattern. */
const MultipleSubQuestionsYesNoAnswerForm = (props) => {
  return (
    <Fragment>
      <Text as={'label'} fontWeight={'bold'}>
        Choice
      </Text>
      <SubQuestionInput {...props} />
    </Fragment>
  );
};

MultipleSubQuestionsYesNoAnswerForm.propTypes = {
  value: PropTypes.shape({
    subQuestions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: RichTextEditor.propTypes.value,
      })
    ),
    correctAnswer: PropTypes.shape({
      value: PropTypes.arrayOf({
        subQuestionValue: PropTypes.string,
        correctAnswerValue: PropTypes.bool,
      }),
    }),
  }),
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};

export default MultipleSubQuestionsYesNoAnswerForm;
