import React, { useRef, useState } from 'react';
import { Form, Input, Dropdown } from 'formsy-semantic-ui-react';
import { Confirm, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { isEqual } from 'lodash/lang';
import { pick } from 'lodash';

import {
  FormField as BaseFormField,
  RequiredLabel,
} from 'Components/Form/FormGenerator/FormGenerator';
import { Flex, Box, ButtonFilled, Text } from 'Components/Base';
import { SemanticButton } from 'Components/Base/Button';
import ConfirmationButton from 'Components/ConfirmationButton';
import RichTextEditor, { convertValueToString } from 'Components/RichTextEditor';

import { ChoiceInput } from '../MultipleChoiceForm';
import RichTextEditorInput from '../RichTextEditorInput';
import { EXAM_ITEM_TAG_CATEGORY, EXAM_QUESTION_STATUS, EXAM_QUESTION_TYPE } from '../../constants';

import QuestionPreviewButton from './QuestionPreviewButton';

const errorLabel = <Label color="red" pointing />;

const Button = styled(ButtonFilled)`
  border: none;
`;
Button.defaultProps = {
  borderRadius: '100px',
  px: 3,
  py: 2,
  fontWeight: '600',
};

const FormField = styled(BaseFormField)`
  && {
    align-items: flex-start;
  }
  .RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz .ButtonWrap__root___1EO_R {
    z-index: 0;
  }
`;

const MultipleSearchSelectionFormField = ({ label, readAsTextField, required, ...props }) => {
  return (
    <FormField compact>
      <RequiredLabel required={required}>
        <span>{label}</span>
      </RequiredLabel>
      {readAsTextField ? (
        <Input {...props} readOnly transparent />
      ) : (
        <Dropdown
          selection
          search
          multiple
          required={required}
          renderLabel={({ text, isDeleted }) => {
            if (isDeleted) return { color: 'red', content: text };
            return { content: text };
          }}
          {...props}
        />
      )}
    </FormField>
  );
};

const isDefineValue = (name, _message) => (formValues, value) => {
  const message = _message ? _message : name + ' is required';

  if (value === null || value === undefined) return message;
  if (typeof value === 'string' && value.trim().length === 0) return message;
  if (Array.isArray(value) && value.length === 0) return message;

  return true;
};

const choiceValidation = (formValues, choice) => {
  if (!choice) return 'Choice is required';

  const correctAnswer = choice?.correctAnswer?.value ?? null;
  if (!correctAnswer) return 'Correct Answer is required';

  const choices = choice?.choices ?? [];
  const isSomeChoiceEmpty = choices.some(
    ({ label }) => !convertValueToString(label, RichTextEditor.contentFormat.HTML)
  );

  if (choices.length === 0 || isSomeChoiceEmpty) {
    return 'Choices is required and all choices must have description';
  }

  return true;
};

const precisionValidation = (formValues, precision) => {
  const requireMessage = 'คำตอบ is required';
  const _isDefineValue = isDefineValue('fieldNumber', requireMessage)(formValues, precision);
  if (_isDefineValue !== true) return _isDefineValue;

  const _fieldNumber = `${precision}`;
  if (_fieldNumber.includes('.')) return 'Setting precision number answer must be integer';

  return true;
};

const numberResultValidation = (formValues, numberResult) => {
  const precision = formValues?.fieldNumber;

  const requireMessage = 'เฉลย is required';
  const _isDefineValue = isDefineValue('numberResult', requireMessage)(formValues, numberResult);
  if (_isDefineValue !== true) return _isDefineValue;

  const _splitNumberResult = numberResult?.toString().split('.');
  const _precision = _splitNumberResult[1]?.length ?? 0;
  if (_precision !== Number.parseInt(precision)) return 'Number answer is not match';

  return true;
};

const ConfigurableQuestionFormFields = ({
  field = [],
  questionTypeOptions,
  onCancel,
  loading,
  onValidSubmit,
  question,
  tags,
  publishMode,
  examId,
}) => {
  const [questionType, setQuestionType] = useState(
    question?.type ?? EXAM_QUESTION_TYPE.MULTIPLE_CHOICE
  );
  const [isConfirmOpen, setIsConfirmOpen] = useState();
  const [isDirty, setIsDirty] = useState();
  const isPublished = question?.status === EXAM_QUESTION_STATUS.PUBLISHED;
  const formRef = useRef();

  let solutionField = null;
  if (field.solution) {
    const required = field.solution.required;
    const validation = required
      ? {
          validations: { isDefineValue: isDefineValue('solution') },
          validationErrors: { isDefaultRequiredValue: 'Solution is required' },
        }
      : {};

    solutionField = (
      <FormField compact>
        <RequiredLabel required={required}>
          <span>{'Solution'}</span>
        </RequiredLabel>
        <RichTextEditorInput
          name="solution"
          disabled={loading || isPublished}
          defaultValue={question.solution ?? ''}
          errorLabel={errorLabel}
          examId={examId}
          {...validation}
        />
      </FormField>
    );
  }

  const formFields = (field?.tags ?? []).map(({ category, required }) => {
    if (category === EXAM_ITEM_TAG_CATEGORY.OWNER) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('owner') },
            validationErrors: { isDefaultRequiredValue: 'owner is required' },
          }
        : {};

      return (
        <FormField compact>
          <RequiredLabel required={required}>
            <span>{'Owner'}</span>
          </RequiredLabel>
          {isPublished ? (
            <Input
              name={'owner'}
              required={required}
              readOnly
              transparent
              defaultValue={question.owner ?? ''}
            />
          ) : (
            <Dropdown
              name={'owner'}
              options={tags.owner || []}
              fluid
              selection
              required={required}
              disabled={loading}
              defaultValue={question.owner ?? ''}
              errorLabel={errorLabel}
              {...validation}
            />
          )}
        </FormField>
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.ASSIGNEE) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('assignee') },
            validationErrors: { isDefaultRequiredValue: 'ผู้รับผิดชอบ is required' },
          }
        : {};

      return (
        <FormField compact>
          <RequiredLabel required={required}>
            <span>{'ผู้รับผิดชอบ'}</span>
          </RequiredLabel>
          <Input
            name={'assignee'}
            required={required}
            disabled={loading || isPublished}
            defaultValue={question.assignee ?? ''}
            errorLabel={errorLabel}
            {...validation}
          />
        </FormField>
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.QUESTION_EXAM_TYPE) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('questionExamType') },
            validationErrors: { isDefaultRequiredValue: 'ประเภทข้อสอบ is required' },
          }
        : {};

      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="ประเภทข้อสอบ"
          name="questionExamType"
          options={tags.questionExamType || []}
          disabled={loading}
          defaultValue={question.questionExamType ?? ''}
          readAsTextField={isPublished}
          errorLabel={errorLabel}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.EXAM_CAMPAIGN) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('examCampaign') },
            validationErrors: { isDefaultRequiredValue: 'สนามสอบ is required' },
          }
        : {};

      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="สนามสอบ"
          name="examCampaign"
          options={tags.examCampaign || []}
          disabled={loading}
          defaultValue={question.examCampaign ?? ''}
          readAsTextField={isPublished}
          errorLabel={errorLabel}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.SUBJECT) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('subject') },
            validationErrors: { isDefaultRequiredValue: 'วิชา is required' },
          }
        : {};

      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="วิชา"
          name="subject"
          options={tags.subject || []}
          disabled={loading || isPublished}
          defaultValue={question.subject ?? ''}
          errorLabel={errorLabel}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.GRADE) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('grade') },
            validationErrors: { isDefaultRequiredValue: 'ระดับชั้น is required' },
          }
        : {};

      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="ระดับชั้น"
          name="grade"
          options={tags.grade || []}
          disabled={loading}
          defaultValue={question.grade ?? ''}
          readAsTextField={isPublished}
          errorLabel={errorLabel}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.SECTION) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('section') },
            validationErrors: { isDefaultRequiredValue: 'สาระการเรียนรู้ is required' },
          }
        : {};
      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="สาระการเรียนรู้"
          name="section"
          options={tags.section || []}
          disabled={loading || isPublished}
          defaultValue={question.section ?? ''}
          validations={{ isDefineValue: isDefineValue('section') }}
          validationErrors={{ isDefaultRequiredValue: 'สาระการเรียนรู้ is required' }}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.SUBSECTION) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('subsection') },
            validationErrors: { isDefaultRequiredValue: 'หน่วยการเรียนรู้ is required' },
          }
        : {};
      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="หน่วยการเรียนรู้"
          name="subsection"
          options={tags.subsection || []}
          disabled={loading || isPublished}
          defaultValue={question.subsection ?? ''}
          errorLabel={errorLabel}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.SUBTOPIC) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('subtopic') },
            validationErrors: { isDefaultRequiredValue: 'เรื่อง is required' },
          }
        : {};
      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="เรื่อง"
          name="subtopic"
          options={tags.subtopic || []}
          disabled={loading || isPublished}
          defaultValue={question.subtopic ?? ''}
          errorLabel={errorLabel}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.INDICATOR) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('indicator') },
            validationErrors: { isDefaultRequiredValue: 'ตัวชี้วัด is required' },
          }
        : {};
      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="ตัวชี้วัด"
          name="indicator"
          options={tags.indicator || []}
          disabled={loading}
          defaultValue={question.indicator ?? ''}
          readAsTextField={isPublished}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.OBJECTIVE) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('objective') },
            validationErrors: { isDefaultRequiredValue: 'จุดประสงค์ is required' },
          }
        : {};
      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="จุดประสงค์"
          name="objective"
          options={tags.objective || []}
          disabled={loading}
          defaultValue={question.objective ?? ''}
          readAsTextField={isPublished}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.BLOOM) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('bloom') },
            validationErrors: { isDefaultRequiredValue: 'Bloom is required' },
          }
        : {};
      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="Bloom"
          name="bloom"
          options={tags.bloom || []}
          disabled={loading}
          defaultValue={question.bloom ?? ''}
          readAsTextField={isPublished}
          errorLabel={errorLabel}
          {...validation}
        />
      );
    }

    if (category === EXAM_ITEM_TAG_CATEGORY.DIFFICULTY) {
      const validation = required
        ? {
            validations: { isDefineValue: isDefineValue('difficulty') },
            validationErrors: { isDefaultRequiredValue: 'ความยาก is required' },
          }
        : {};
      return (
        <MultipleSearchSelectionFormField
          required={required}
          label="ความยาก"
          name="difficulty"
          options={tags.difficulty || []}
          disabled={loading || isPublished}
          defaultValue={question.difficulty ?? ''}
          errorLabel={errorLabel}
          {...validation}
        />
      );
    }

    return null;
  });

  return (
    <>
      <Form
        onValidSubmit={publishMode ? () => setIsConfirmOpen(true) : onValidSubmit}
        ref={formRef}
        onChange={(model, isChanged) => {
          const fields = pick(question, Object.keys(model));
          const isDirty = !isEqual(model, fields);
          setIsDirty(isDirty);
        }}
      >
        {formFields}
        <FormField compact>
          <RequiredLabel required>
            <span>{'ประเภทโจทย์'}</span>
          </RequiredLabel>
          <Dropdown
            name={'type'}
            options={questionTypeOptions || []}
            fluid
            selection
            required
            disabled={loading || isPublished}
            defaultValue={question.type ?? ''}
            onChange={(e, { value }) => setQuestionType(value)}
            validations={{ isDefineValue: isDefineValue('type') }}
            validationErrors={{ isDefaultRequiredValue: 'ประเภทโจทย์ is required' }}
            errorLabel={errorLabel}
          />
        </FormField>
        <FormField compact>
          <RequiredLabel required>
            <span>{'Question'}</span>
          </RequiredLabel>
          <RichTextEditorInput
            name="question"
            disabled={loading || isPublished}
            defaultValue={question.question ?? ''}
            validations={{ isDefineValue: isDefineValue('question') }}
            validationErrors={{ isDefaultRequiredValue: 'Question is required' }}
            errorLabel={errorLabel}
            examId={examId}
          />
        </FormField>
        {questionType === EXAM_QUESTION_TYPE.FILL_NUMBER ? (
          <>
            <FormField compact>
              <RequiredLabel required>
                <span>
                  {'ตั้งค่าคำตอบ'}
                  <Text fontWeight={'normal'} as="p">
                    (จำนวนทศนิยม)
                  </Text>
                </span>
              </RequiredLabel>
              <Box width={1}>
                <Flex alignItems={'center'}>
                  <Input
                    type={'number'}
                    name="fieldNumber"
                    disabled={loading || isPublished}
                    defaultValue={question.fieldNumber}
                    validations={{ precisionValidation }}
                    validationErrors={{ isDefaultRequiredValue: 'คำตอบ is required' }}
                    errorLabel={errorLabel}
                    min={0}
                  />
                  <Text ml={'16px'} fontWeight={'bold'}>
                    ตำแหน่ง
                  </Text>
                </Flex>
              </Box>
            </FormField>
            <FormField compact>
              <RequiredLabel required>
                <span>{'เฉลย'}</span>
              </RequiredLabel>
              <Input
                name="numberResult"
                disabled={loading || isPublished}
                defaultValue={question.numberResult}
                validations={{ numberResultValidation }}
                validationErrors={{ isDefaultRequiredValue: 'เฉลย is required' }}
                errorLabel={errorLabel}
              />
            </FormField>
          </>
        ) : (
          <FormField compact>
            <RequiredLabel required>
              <span>{'Choice'}</span>
            </RequiredLabel>
            <ChoiceInput
              name="choice"
              disabled={loading || isPublished}
              defaultValue={question.choice ?? ''}
              validations={{ choiceValidation }}
              validationErrors={{ isDefaultRequiredValue: 'Choice is required' }}
              errorLabel={errorLabel}
              examId={examId}
            />
          </FormField>
        )}
        {solutionField}
        <Flex justifyContent="space-between" alignItems="center" mt={2} mb={3}>
          <QuestionPreviewButton
            ref={formRef}
            index={question.index}
            total={question.total}
            time={question.time}
          />
          <Box>
            <Button
              bg="primary"
              color="white"
              type="submit"
              mr={2}
              disabled={loading || isPublished || (!publishMode && !isDirty)}
            >
              {publishMode ? `Save and Publish` : `Save Draft`}
            </Button>
            {isDirty ? (
              <ConfirmationButton
                buttonStyle={{
                  primary: false,
                  circular: true,
                  size: 'medium',
                  type: 'reset',
                  disabled: loading,
                }}
                onConfirm={onCancel}
                headerText={`Close without Save`}
                confirmText={'Confirm'}
                contentText={'Are you sure to close this form without save ?'}
              >
                {`Cancel`}
              </ConfirmationButton>
            ) : (
              <SemanticButton
                onClick={onCancel}
                primary={false}
                circular={true}
                size="medium"
                type="reset"
                disabled={loading}
              >
                Cancel
              </SemanticButton>
            )}
          </Box>
        </Flex>
      </Form>
      <Confirm
        open={isConfirmOpen}
        cancelButton={'Cancel'}
        confirmButton={'Save and Publish'}
        header={'Save and Publish ?'}
        content={'This Question will be saved and published.'}
        onCancel={() => setIsConfirmOpen(false)}
        onConfirm={() => onValidSubmit(formRef?.current?.getCurrentValues?.() ?? {})}
        dimmer="inverted"
      />
    </>
  );
};

export default ConfigurableQuestionFormFields;
