import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PropTypes from 'prop-types';

function SyntaxHighlighterView(props) {
  const { codeString, language } = props;
  return (
    <SyntaxHighlighter language={language ?? 'javascript'} style={atomDark}>
      {codeString}
    </SyntaxHighlighter>
  );
}

SyntaxHighlighterView.propTypes = {
  codeString: PropTypes.string,
  language: PropTypes.string,
};

export default SyntaxHighlighterView;
