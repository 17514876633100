import React, { Fragment } from 'react';
import { Modal, Icon } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import { fileImportJob } from 'GraphQL/LE/query/FileImport.query';
import Loading from 'Components/Loading';
import { Flex } from 'Components/Base';
import Table from 'Components/DataTable';
import { SemanticButton as Button } from 'Components/Base/Button';
import QueryComponent from 'GraphQL/util/QueryComponent';

import { FILE_IMPORT_STATUS } from '../../common';

function handleSimpleResult(result) {
  return result.target || result.value;
}

function handleNestedResult(result) {
  const data = {
    ...result,
    constraints: result.constraints
      ? Object.values(JSON.parse(result.constraints)).join(', ')
      : null,
  };
  return JSON.stringify(
    Object.keys(data).reduce((obj, key) => {
      if (data[key] !== null && data[key] !== undefined && key !== 'type' && key !== '__typename') {
        obj[key] = data[key];
      }
      return obj;
    }, {})
  );
}

function SummaryTable({ data }) {
  if (!data || !data.boFileImportJob) {
    return <Loading />;
  }

  const { status, result } = data.boFileImportJob;
  let results = [];
  const columns = [
    { Header: 'No.', accessor: 'index', width: 100 },
    {
      Header: 'Type',
      accessor: 'type',
      width: 100,
    },
    {
      Header: 'Target',
      accessor: 'target',
    },
  ];
  if (status === FILE_IMPORT_STATUS.SUCCESSFUL) {
    results = result.resultSummaries.map((result) => {
      let target;
      switch (result.type) {
        case 'create':
        case 'update':
        case 'fileResult':
        case 'remove':
          target = handleSimpleResult(result);
          break;
        default:
          target = handleNestedResult(result);
      }
      return {
        ...result,
        target,
      };
    });
  } else if (status === FILE_IMPORT_STATUS.FAILED) {
    results = result.validationErrors.map((result) => {
      return {
        type: 'error',
        target: handleNestedResult(result),
      };
    });
  }
  return (
    <Table
      columns={columns}
      data={results.map((result, index) => ({ ...result, index: index + 1 }))}
      alignItems="baseline"
    />
  );
}

function ResultSummariesModal(props) {
  const { id, trigger } = props;

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>Result Details</Modal.Header>
          <Modal.Content>
            <QueryComponent query={fileImportJob} variables={{ id }} fetchPolicy={'network-only'}>
              {(data) => <SummaryTable data={data} />}
            </QueryComponent>

            <Flex mt={2} justifyContent="flex-end" alignItems="center">
              <Button primary circular onClick={closeModal}>
                <Icon name="close" size="small" />
                Close
              </Button>
            </Flex>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default ResultSummariesModal;
