import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import omitDeep from 'Util/omitDeep';
import delve from 'Util/Delve';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import { getBundle } from '../../GraphQL/query/Products.query';
import { updateBundleDetailsMutation } from '../../GraphQL/mutation/Products.mutation';
import { MarkupTextInput } from '../MarkupTextInput';

const BundleDetails = ({ bundle }) => {
  const { SKUCode, id } = bundle;
  const { loading, error, data: queryData } = useQuery(getBundle, {
    variables: { SKUCode },
    fetchPolicy: 'network-only',
  });

  const [
    updateBundle,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(updateBundleDetailsMutation);

  const handleUpdate = (data) => {
    const clonedData = JSON.parse(JSON.stringify(data));
    const formatData = omitDeep(clonedData, ['id', '__typename', 'assetUrl']);
    if (formatData) {
      formatData.content = formatData.content ?? '';
    }
    updateBundle({
      variables: {
        id,
        details: formatData,
      },
    });
  };

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  const details = delve(queryData, 'sfBoProductBundle.details', '');

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={true}
        response={delve(mutationResponse, 'sfBoProductUpdateBundleDetails')}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <MarkupTextInput loading={mutationLoading} value={details} onUpdate={handleUpdate} />
    </Fragment>
  );
};

BundleDetails.propTypes = {
  bundle: PropTypes.object.isRequired,
};

BundleDetails.defaultProps = {};

export default BundleDetails;
