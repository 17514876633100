import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal, Icon } from 'semantic-ui-react';

import StatefulModal from '../../Components/Modal/StatefulModal';
import { SemanticButton as Button } from '../../Components/Base/Button';

import UserSearch from './UserSearch';

export default class NewUserModal extends Component {
  static propTypes = {
    onUserSelected: PropTypes.func,
    trigger: PropTypes.node,
    limitRoles: PropTypes.arrayOf(PropTypes.string),
  };
  static defaultProps = {
    onUserSelected: () => {},
    limitRoles: [],
  };
  render() {
    const { limitRoles } = this.props;
    return (
      <StatefulModal closeIcon trigger={this.props.trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>Select User</Modal.Header>
            <Modal.Content>
              <UserSearch
                onSelected={(user) => {
                  this.props.onUserSelected(user);
                  closeModal();
                }}
                onCancelled={() => closeModal()}
                limitRoles={limitRoles}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => closeModal()}>
                <Icon name="close" /> Close
              </Button>
            </Modal.Actions>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
