import moment from 'moment';

import { extractDisplayDetail } from 'src/Components/AssetComponents/WistiaVideo/WistiaVideo';

import getConfig from '../../Config';

const WISTIA_API = getConfig().wistiaApi;
const WISTIA_REGEX = /https?:\/\/(.+)?(wistia\.com|wi\.st)\/.*/;

export const isWistiaLink = (url) => {
  return url.match(WISTIA_REGEX);
};

const getVideoByUrl = (url) => {
  const params = {
    url,
    format: 'json',
  };
  const data = fetch(`${WISTIA_API}/?url=${params.url}&format=${params.format}`)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return data;
};

const defaultVideoDetail = {
  url: '',
  previewUrl: '',
  videoDetail: '',
  thumbnail: '',
  title: '',
  totalTime: '',
};

export const customDurationFormat = (rawDuration) => {
  const duration = moment.duration(rawDuration, 'seconds');
  const h = duration.hours();
  const m = duration.minutes();
  const s = duration.seconds();
  return moment({ hour: h, minute: m, second: s }).format('HH:mm:ss');
};

export const extractVideoInfo = (videoInfo) => {
  if (!videoInfo) return { found: false };
  const { duration, title, thumbnail_url } = videoInfo;
  const thumbnails = {
    url: thumbnail_url,
  };
  const detail = videoInfo;
  return { duration, title, thumbnails, detail, found: true };
};

export const getVideoDetailByUrl = async (url) => {
  const videoDetail = await getVideoByUrl(url).then((video) => extractVideoInfo(video));
  return videoDetail;
};

export const getVideoDetails = async (wistiaLink, prevId, videoDetail) => {
  let encodedUrl = encodeURIComponent(wistiaLink);
  if (wistiaLink !== prevId && isWistiaLink(wistiaLink)) {
    const rawDetail = await getVideoDetailByUrl(encodedUrl);
    if (rawDetail && rawDetail.found) {
      return {
        url: wistiaLink,
        rawDetail: rawDetail,
        ...extractDisplayDetail(rawDetail),
      };
    } else {
      return defaultVideoDetail;
    }
  }
  return videoDetail;
};
