import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Divider } from 'semantic-ui-react';

import { Box } from 'Components/Base';

import { SemanticButton as Button } from '../../../Components/Base/Button';

import {
  VerifyEmailButton,
  EvaluateCertificatesButton,
  ToggleUserStatusRadio,
  ManualVerifyEmailButton,
} from './Buttons.gql';

class ActionPanel extends Component {
  render() {
    const { showLearnXActions, isUserVerified, isDisabled, userId } = this.props;
    return (
      <Card.Group>
        {/* <Card>
          <Card.Content>
            <Card.Header>Admin</Card.Header>
            <Card.Description>
              <Button disabled fluid>
                <Icon name="mail" />
                Change Email
              </Button>
              <Divider />
              <Button disabled fluid>
                <Icon name="id card outline" />
                Reset Password
              </Button>
              {showLearnXActions && (
                <Fragment>
                  <Divider />
                  {isUserVerified
                    ? 'This user has been verified'
                    : `This user hasn't been verified from email. `}
                  <VerifyEmailButton isUserVerified={isUserVerified} userId={userId} />
                </Fragment>
              )}
            </Card.Description>
          </Card.Content>
        </Card> */}
        {/* <Card>
          <Card.Content>
            <Card.Header>Delete User</Card.Header>
            <Card.Description>This action cannot be undone</Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button negative disabled fluid>
              <Icon name="user delete" />
              Delete User
            </Button>
          </Card.Content>
        </Card> */}
        {!isUserVerified ? (
          <Card>
            <Card.Content>
              <Card.Header>Manual Verify Email</Card.Header>
              <Card.Description>Click to manual verify user’s e-mail address</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <ManualVerifyEmailButton userId={userId}></ManualVerifyEmailButton>
            </Card.Content>
          </Card>
        ) : (
          ''
        )}

        <Card>
          <Card.Content>
            <Card.Header>{`Account Status`}</Card.Header>
          </Card.Content>
          <Card.Content extra>
            <Card.Description>Current : {isDisabled ? 'Disabled' : 'Enabled'}</Card.Description>
            <ToggleUserStatusRadio userId={userId} isDisabled={isDisabled}></ToggleUserStatusRadio>
          </Card.Content>
        </Card>
      </Card.Group>
    );
  }
}

ActionPanel.propTypes = {
  showLearnXActions: PropTypes.bool,
  isUserVerified: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

ActionPanel.defaultProps = {
  showLearnXActions: false,
  isUserVerified: false,
  isDisabled: false,
};

export default ActionPanel;
