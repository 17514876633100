import React, {
  Fragment,
  useMemo,
  useCallback,
  useState,
  useRef,
  useImperativeHandle,
} from 'react';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Flex, Text, Box, ButtonFilled } from 'Components/Base';
import StatefulModal from 'Components/Modal/StatefulModal';
import { deleteOrganizationEmail } from 'GraphQL/mutation/Organization.mutation';

import Snackbar, { VARIANTS } from '../../Components/Snackbar';
import DeleteIconModal from '../DeleteIconModal';

import SearchOrganizationEmails, { DEFAULT_EMAIL_COLUMNS } from './SearchOrganizationEmails';
import AddEmailToOrganization from './AddEmailToOrganization';

const ModalContentContainer = styled((props) => <Box {...props} />)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #f1ad46;
  }
`;

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const AddEmailModal = ({ organizationId, onCompleted }) => {
  return (
    <StatefulModal
      trigger={
        <ButtonFilled
          bg="primary"
          color="white"
          borderRadius={'100px'}
          px={3}
          fontWeight="600"
          fontSize="14px"
        >
          <Flex alignItems="center">
            <AddIcon style={{ width: '24px' }} />
            <span>Add Email</span>
          </Flex>
        </ButtonFilled>
      }
    >
      {(closeModal) => (
        <ModalContentContainer>
          <Text.Header py={3} px={4} fontSize="20px">
            Add Email
          </Text.Header>
          <Divider />
          <AddEmailToOrganization
            organizationId={organizationId}
            onCancel={closeModal}
            onCompleted={() => {
              onCompleted();
              setTimeout(closeModal, 1500);
            }}
          />
        </ModalContentContainer>
      )}
    </StatefulModal>
  );
};

AddEmailModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onCompleted: PropTypes.func,
};
AddEmailModal.defaultProps = {
  onCompleted: () => {},
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot remove email, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const EmailList = React.forwardRef(({ organizationId }, ref) => {
  const [feedback, setFeedback] = useState({});
  const SearchOrganizationEmailsRef = useRef(null);
  useImperativeHandle(SearchOrganizationEmailsRef, () => ref.current);

  const onCompleted = useCallback((response) => {
    const data = response?.deleteOrganizationEmail ?? {};
    if (data?.success) {
      const removedEmail = data?.organizationEmail?.email;
      setFeedback({
        message: `Email ${removedEmail} has been removed`,
        variant: VARIANTS.SUCCESS,
        show: true,
      });
      const SearchOrganizationEmailsTable = SearchOrganizationEmailsRef.current;
      if (typeof SearchOrganizationEmailsTable.refetch === 'function') {
        SearchOrganizationEmailsTable.refetch();
      }
      return;
    }

    const message = data?.message ?? '';
    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }
    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const [removeOrganizationEmail] = useMutation(deleteOrganizationEmail, { onCompleted });

  const handleRemoveEmail = useCallback(
    (organizationId, organizationEmailId) =>
      removeOrganizationEmail({ variables: { organizationId, organizationEmailId } }),
    [removeOrganizationEmail]
  );

  const COLUMN = useMemo(
    () => [
      ...DEFAULT_EMAIL_COLUMNS,
      {
        Header: 'Action',
        accessor: 'createdAt',
        Cell: function actionComponent(row) {
          return (
            <Flex justifyContent="center">
              <DeleteIconModal
                title={`Remove Email: ${row.original.email}?`}
                description={'This email will be removed from organization.'}
                onConfirmClick={() => handleRemoveEmail(organizationId, row.original.id)}
              />
            </Flex>
          );
        },
        width: 60,
        isSearchAble: false,
      },
    ],
    [handleRemoveEmail, organizationId]
  );

  return (
    <>
      <SearchOrganizationEmails columns={COLUMN} organizationId={organizationId} ref={ref} />
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
});

EmailList.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

const OrganizationEmailPanel = ({ organizationId }) => {
  const OrganizationEmailsRef = useRef(null);

  const handleAddEmailComplete = useCallback(() => {
    const OrganizationEmailsTable = OrganizationEmailsRef.current;
    if (OrganizationEmailsTable && typeof OrganizationEmailsTable.refetch === 'function') {
      OrganizationEmailsTable.refetch();
    }
  }, []);

  return (
    <Fragment>
      <Text
        fontSize={2}
        mb="2"
      >{`• Whitelist email จะถูกใช้งานเมื่อ Organization ปิดใช้งานอยู่(Disabled).`}</Text>
      <Text
        fontSize={2}
        mb="2"
      >{`• Blacklist email จะถูกใช้งานเมื่อ Organization เปิดใช้งานอยู่(Enabled).`}</Text>
      <Flex justifyContent="space-between" alignItems="center" flexDirection={'row-reverse'} py={3}>
        <AddEmailModal organizationId={organizationId} onCompleted={handleAddEmailComplete} />
      </Flex>
      <EmailList organizationId={organizationId} ref={OrganizationEmailsRef} />
    </Fragment>
  );
};

OrganizationEmailPanel.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

export default OrganizationEmailPanel;
