import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from 'Components/Base';

const ItemWrapper = styled((props) => (
  <Flex as={'a'} justifyContent={'center'} alignItems={'center'} {...props} />
))`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

/**
 *
 * @param {string} linkUrl - URL to navigate the route
 */
const HeaderItem = ({ linkUrl, children, ...props }) => (
  <ItemWrapper py={1} px={2} href={linkUrl} {...props}>
    {children}
  </ItemWrapper>
);

HeaderItem.propTypes = {
  linkUrl: PropTypes.string,
};

export default HeaderItem;
