import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import getDisplayName from 'Util/LSP/getDisplayName';
import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createAssignmentMutation,
  updateAssignmentMutation,
} from 'GraphQL/LSP/mutation/Syllabus.mutation';
import { getAllSyllabuses, getAssignments, getAssignment } from 'GraphQL/LSP/query/Syllabus.query';
import {
  getAllSchoolTerms,
  getAllSchoolGrades,
  getAllSchoolProgrammes,
  getAllCareerPlans,
} from 'GraphQL/LSP/query/Education.query';
import Loading from 'Components/Loading';
import ASSIGNMENT_TYPE from 'Util/LSP/assignmentType';

import GroupAssignmentForm from './GroupAssignmentForm';

function GroupAssignmentModal(props) {
  const { isEdit, id, trigger, variables } = props;
  const { loading: syllabusLoading, error: syllabusError, data: syllabusData } = useQuery(
    getAllSyllabuses,
    {
      fetchPolicy: 'network-only',
    }
  );
  const { loading: schoolTermLoading, error: schoolTermError, data: schoolTermData } = useQuery(
    getAllSchoolTerms,
    {
      fetchPolicy: 'network-only',
    }
  );
  const { loading: schoolGradeLoading, error: schoolGradeError, data: schoolGradeData } = useQuery(
    getAllSchoolGrades,
    {
      fetchPolicy: 'network-only',
    }
  );
  const {
    loading: schoolProgrammeLoading,
    error: schoolProgrammeError,
    data: schoolProgrammeData,
  } = useQuery(getAllSchoolProgrammes, {
    fetchPolicy: 'network-only',
  });
  const { loading: careerPlanLoading, error: careerPlanError, data: careerPlanData } = useQuery(
    getAllCareerPlans,
    {
      fetchPolicy: 'network-only',
    }
  );
  if (
    syllabusLoading ||
    schoolTermLoading ||
    schoolGradeLoading ||
    schoolProgrammeLoading ||
    careerPlanLoading
  ) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>
            {isEdit ? 'แก้ไขแผนการเรียนแบบกลุ่ม' : 'เพิ่มแผนการเรียนแบบกลุ่ม'}
          </Modal.Header>
          <Modal.Content>
            <CreateEditForm
              dataQuery={getAssignment}
              createMutation={createAssignmentMutation}
              createRefetchQueries={[
                {
                  query: getAssignments,
                  variables,
                },
              ]}
              editRefetchQueries={[
                {
                  query: getAssignments,
                  variables,
                },
              ]}
              editMutation={updateAssignmentMutation}
              isEdit={isEdit}
              id={id}
              getQueryVariables={({ id }) => ({
                id,
              })}
              getEditVariables={(form, { id }) => ({
                id,
                syllabusAssignmentData: {
                  ...form,
                  type: ASSIGNMENT_TYPE.GROUP,
                },
              })}
              getCreateVariables={(form) => ({
                syllabusAssignmentData: {
                  ...form,
                  type: ASSIGNMENT_TYPE.GROUP,
                },
              })}
              onCompleted={closeModal}
              onCancelled={closeModal}
            >
              {({ data, onSubmit }) => {
                return (
                  <GroupAssignmentForm
                    initialData={data && data.boSyllabusAssignment}
                    syllabusData={syllabusData && syllabusData.boAllSyllabuses}
                    schoolTermData={schoolTermData && schoolTermData.boAllSchoolTerms}
                    schoolGradeData={schoolGradeData && schoolGradeData.boAllSchoolGrades}
                    schoolProgrammeData={
                      schoolProgrammeData && schoolProgrammeData.boAllSchoolProgrammes
                    }
                    careerPlanData={careerPlanData && careerPlanData.boAllCareerPlans}
                    onSubmit={onSubmit}
                  />
                );
              }}
            </CreateEditForm>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default GroupAssignmentModal;
