import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';

import { getUsersFromSubscription } from '../../GraphQL/query/Products.query';

const subscriptionItemColumns = () => [
  {
    Header: 'Id',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'First name',
    accessor: 'firstname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Last name',
    accessor: 'lastname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Start date',
    accessor: 'startDate',
    type: COLUMN_TYPE.DATE,
    isSearchAble: false,
  },
  {
    Header: 'End date',
    accessor: 'endDate',
    type: COLUMN_TYPE.DATE,
    isSearchAble: false,
  },
];

class SubscriptionUserEnrollment extends Component {
  static propTypes = {
    subscription: PropTypes.shape({
      id: PropTypes.string.isRequired,
      SKUCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { SKUId: props.subscription.id },
      order: { field: 'createdAt', type: 'ASC' },
      deselectedItems: [],
      activeIndex: -1,
    };
  }

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({
      search: { ...search, SKUId: this.props.subscription.id },
      paging,
    });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };

  resolveData = (data) => data?.sfBoUsersFromSubscription?.users;

  render() {
    const { search, paging, order } = this.state;
    return (
      <>
        <Text.Header>User Enrollment</Text.Header>
        <QueryTable
          type={'sfBoUsersFromSubscription'}
          resolveData={this.resolveData}
          columns={subscriptionItemColumns()}
          query={getUsersFromSubscription}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </>
    );
  }
}

export default SubscriptionUserEnrollment;
