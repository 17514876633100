import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getSubscription } from '../../GraphQL/query/Products.query';
import { updateSubscriptionMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY } from '../../Domains/constants';

import SubscriptionDetailsForm from './SubscriptionDetailsForm';

const resolveData = ({ sfBoProductSubscription }) => {
  return {
    ...sfBoProductSubscription,
    SKUStatus: `${
      sfBoProductSubscription.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED
    }/${sfBoProductSubscription.SKUStatus}`,
  };
};

class SubscriptionDetailsEditor extends Component {
  render() {
    const { subscription = {} } = this.props;
    const { id, SKUCode } = subscription;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getSubscription}
          editMutation={updateSubscriptionMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              ...form,
              SKUStatus: form.SKUStatus.split('/')[1],
              isVisible: form.SKUStatus.split('/')[0] === SKU_VISIBILITY.VISIBLE,
            },
          })}
        >
          {({ data, onSubmit }) => {
            return <SubscriptionDetailsForm initialData={resolveData(data)} onSubmit={onSubmit} />;
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default SubscriptionDetailsEditor;
