import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import apolloClient from 'src/GraphQL';
import FormGenerator from 'Components/Form/FormGenerator';
import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import ImageUrlUpload from '../Components/ImageUrlUpload';

const assetKey = `popup-${moment().format('YYYYMM')}`;

const PopupSingleSlideForm = ({ isEdit, onSubmit, ...props }) => {
  const handleFileListRequest = async () => {
    const { data } = await apolloClient.mutate({
      mutation: getAssetList,
      variables: {
        type: ASSET_TYPE.ANNOUNCEMENT,
        key: assetKey,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  const handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { data } = await apolloClient.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.ANNOUNCEMENT,
        key: assetKey,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'รูป',
      inputName: 'imageUrl',
      inputProps: {
        required: true,
      },
      customInput: (props) => (
        <ImageUrlUpload
          onFileListRequest={handleFileListRequest}
          onFileUploadUrlRequest={handleFileUploadUrlRequest}
          {...props}
        />
      ),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'ข้อความ Heading',
      inputName: 'title',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
      inputLabel: 'ข้อความในเนื้อหา',
      inputName: 'description',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'ชื่อปุ่ม',
      inputName: 'buttonTitle',
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'หน้าปลายทาง (URL)',
      inputName: 'buttonHref',
      inputProps: {
        placeholder: 'https://www.google.com',
      },
    },
  ];

  const handleSubmit = (formValue) => {
    onSubmit({ ...formValue });
  };

  return (
    <FormGenerator
      fields={fields}
      compact
      showCancel
      cancelButtonType={'button'}
      isRequiredConfirmation
      submitText={'Save this slide'}
      submitContent={`This slide will be saved.`}
      cancelText="Cancel"
      onSubmit={handleSubmit}
      dividers={[{ key: 'buttonTitle', text: 'รายละเอียดปุ่ม' }]}
      {...props}
    />
  );
};

PopupSingleSlideForm.propTypes = {
  isEdit: PropTypes.bool,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default PopupSingleSlideForm;
