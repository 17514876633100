import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';
import CreateEditForm from '../../../Containers/CreateEditForm';
import AddSectionForm from '../Components/AddSectionForm';
import { addSection, addSubsection } from '../../../GraphQL/mutation/Courses.mutation';
import { productCourseVersionResolver } from '../../../GraphQL/query/AppConfig.query';
import { getCourseOutline } from '../../../GraphQL/query/Courses.query';
import { MODE, MODE_STRING } from '../../../Components/SubSection/util';

const genHeader = (isEdit, mode) => `${isEdit ? 'Edit' : 'Add'} ${MODE_STRING[mode]}`;

const getMode = (isSection, isSectionGroup) => {
  return isSection ? MODE.SECTION : isSectionGroup ? MODE.SECTION_GROUP : MODE.SUBSECTION;
};
export default class AddSectionModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isSection: PropTypes.bool.isRequired,
    isSectionGroup: PropTypes.bool,
    trigger: PropTypes.element,
    courseId: PropTypes.string.isRequired,
    afterItemId: PropTypes.string,
    version: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
  };
  static defaultProps = {
    isEdit: false,
    isSectionGroup: false,
  };
  render() {
    const {
      isEdit,
      trigger,
      isSection,
      isSectionGroup,
      courseId,
      afterItemId,
      product,
      version,
    } = this.props;
    const mode = getMode(isSection, isSectionGroup);
    const isAddSection = [MODE.SECTION, MODE.SECTION_GROUP].includes(mode);
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{genHeader(isEdit, mode)}</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={null}
                createMutation={isAddSection ? addSection : addSubsection}
                createRefetchQueries={[
                  {
                    query: getCourseOutline,
                    variables: {
                      version: productCourseVersionResolver(product, version),
                      courseId,
                    },
                  },
                ]}
                getCreateVariables={(form) => ({
                  version: productCourseVersionResolver(product, version),
                  afterItemId,
                  courseId,
                  [isAddSection ? 'sectionData' : 'subsectionData']: form,
                })}
                editMutation={null}
                isEdit={isEdit}
                id={courseId}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => (
                  <AddSectionForm
                    mode={mode}
                    product={product}
                    onSubmit={onSubmit}
                    initialData={data}
                  />
                )}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
