import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const SpacingClass = {
  n: 0,
  s: 4,
  m: 8,
  l: 12,
  xl: 16,
};

const SPACING_SIZE = {
  none: 'none',
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
};

const resolveSpacing = (spacingClass = SPACING_SIZE.none) => {
  switch (spacingClass) {
    case SPACING_SIZE.xsmall:
      return 2;
    case SPACING_SIZE.small:
      return 4;
    case SPACING_SIZE.medium:
      return 8;
    case SPACING_SIZE.large:
      return 12;
    case SPACING_SIZE.xlarge:
      return 16;
    case SPACING_SIZE.none:
      return 0;
    default:
      return undefined;
  }
};

const resolvePaddingStyle = (p, px, py, pt, pr, pb, pl) => {
  const padding = {
    paddingTop: py || pt || p,
    paddingRight: px || pr || p,
    paddingBottom: py || pb || p,
    paddingLeft: px || pl || p,
  };
  return padding;
};

const resolvePaddingCss = (p, px, py, pt, pr, pb, pl) => {
  return css`
    padding-top: ${py || pt || p}px;
    padding-right: ${px || pr || p}px;
    padding-bottom: ${py || pb || p}px;
    padding-left: ${px || pl || p}px;
  `;
};

const resolveMarginCss = (p, px, py, pt, pr, pb, pl) => {
  return css`
    margin-top: ${py || pt || p}px;
    margin-right: ${px || pr || p}px;
    margin-bottom: ${py || pb || p}px;
    margin-left: ${px || pl || p}px;
  `;
};

const resolvePadding = ({ p, px, py, pt, pr, pb, pl }) =>
  resolvePaddingCss(
    resolveSpacing(p),
    resolveSpacing(px),
    resolveSpacing(py),
    resolveSpacing(pt),
    resolveSpacing(pr),
    resolveSpacing(pb),
    resolveSpacing(pl)
  );

const resolveMargin = ({ m, mx, my, mt, mr, mb, ml }) =>
  resolveMarginCss(
    resolveSpacing(m),
    resolveSpacing(mx),
    resolveSpacing(my),
    resolveSpacing(mt),
    resolveSpacing(mr),
    resolveSpacing(mb),
    resolveSpacing(ml)
  );

const View = styled.div`
  ${(props) => resolvePadding(props)};
  ${(props) => resolveMargin(props)};
`;

View.propTypes = {
  m: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  mx: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  my: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  mt: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  mr: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  mb: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  ml: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  p: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  px: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  py: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  pt: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  pr: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  pb: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
  pl: PropTypes.oneOf(Object.keys(SPACING_SIZE)),
};

// margin and padding is a reserved word for View
View.defaultProps = {
  m: SPACING_SIZE.none,
  p: SPACING_SIZE.none,
};

export default {
  View,
  SPACING_SIZE,
};
