import React, { useState, useEffect } from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import delve from '../../../Util/Delve';

const CheckMutationResponse = ({
  loading,
  error,
  response,
  checkOnlyDefined,
  onValid,
  transformErrorToMessage,
  notificationTime,
}) => {
  const [alert, setAlert] = useState({
    shouldShow: false,
    message: '',
  });

  useEffect(() => {
    if (!loading && error) {
      setAlert({
        shouldShow: true,
        isSuccess: false,
        message: transformErrorToMessage
          ? transformErrorToMessage(error)
          : JSON.stringify(error.message),
      });
    }

    if (response && response !== undefined && !error) {
      const isSuccess = delve(response, 'success') === true;
      if (checkOnlyDefined || isSuccess) {
        setAlert({
          shouldShow: true,
          // message: `Successfully`,
          message: `Saved`,
          isSuccess: true,
        });
        setTimeout(() => {
          setAlert({
            shouldShow: false,
            message: '',
          });
          onValid(response);
        }, notificationTime);
      } else {
        const code = delve(response, 'code');
        const message = delve(response, 'message');
        const messages = delve(response, 'messages');

        const messagesList = [
          <p key={0}>{`Error ${code ? 'Code : ' + code : ''} ${message || ''}`}</p>,
        ];
        if (messages) {
          messages.forEach(({ code, field, message: msg }, index) =>
            messagesList.push(<p key={index + 1}>{`code : ${code}, field: ${field}, ${msg}`}</p>)
          );
        }
        setAlert({ shouldShow: true, isSuccess, message: messagesList });
      }
    }
  }, [response, error, loading, onValid, checkOnlyDefined, notificationTime]);

  const { shouldShow, isSuccess, message } = alert;
  return shouldShow ? (
    <Message positive={isSuccess} negative={!isSuccess}>
      {message}
    </Message>
  ) : null;
};

CheckMutationResponse.propTypes = {
  response: PropTypes.object,
  onValid: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  checkOnlyDefined: PropTypes.bool,
  notificationTime: PropTypes.number,
  transformErrorToMessage: PropTypes.func,
};

CheckMutationResponse.defaultProps = {
  onValid: (validResponse) => validResponse,
  response: undefined,
  loading: false,
  error: null,
  checkOnlyDefined: true,
  notificationTime: 1000,
};

export default CheckMutationResponse;
