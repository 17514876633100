import gql from 'graphql-tag';

export const getOrganizationEmail = gql`
  query organizationEmail($organizationId: ID!, $organizationEmailId: ID!) {
    organizationEmail(organizationId: $organizationId, organizationEmailId: $organizationEmailId) {
      id
      email
      organizationId
      isBlacklist
    }
  }
`;

export const getOrganizationEmails = gql`
  query organizationEmails(
    $organizationId: ID!
    $search: OrganizationEmailSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    organizationEmails(
      organizationId: $organizationId
      search: $search
      paging: $paging
      order: $order
    ) {
      total
      organizationEmails {
        id
        email
        organizationId
        isBlacklist
      }
    }
  }
`;
