import React from 'react';

export const MARKDOWN_EDITOR_TYPE = {
  QUESTION: 'question',
  SOLUTION: 'solution',
  ANSWER: 'answer',
};

export const CHOICE_PREFIX = {
  EN: 'Answer',
  TH: 'คำตอบที่',
};

export const VALIDATION_WARNING = {
  QUESTION: {
    EN: 'Question is required',
    TH: 'กรุณากรอกคำถาม',
  },
  ANSWER: {
    EN: (
      <div>
        <div>*At least 2 answers</div>
        <div>*At least 1 correct answer</div>
      </div>
    ),
    TH: (
      <div>
        <div>*อย่างน้อย 2 คำตอบ</div>
        <div>*เลือกข้อถูกอย่างน้อย 1 ข้อ</div>
      </div>
    ),
  },
};
