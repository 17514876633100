import React from 'react';
import _ from 'lodash';

import { SKU_TYPE } from 'Routes/Products/Domains/constants';
import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBundle } from '../../GraphQL/query/Products.query';
import { updateBundleStockMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUStockForm from '../SKUStockForm';

const BookStocksEditor = (props) => {
  const { bundle = {} } = props;
  const { id, SKUCode } = bundle;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getBundle}
        editMutation={updateBundleStockMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({ SKUCode })}
        getEditVariables={(form) => {
          return {
            id,
            data: {
              originalStock: form?.originalStock ?? '',
            },
          };
        }}
        editRefetchQueries={[
          {
            query: getBundle,
            variables: {
              SKUCode: SKUCode,
            },
          },
        ]}
      >
        {({ data, onSubmit }) => (
          <SKUStockForm
            initialData={data?.sfBoProductBundle}
            onSubmit={onSubmit}
            SKUType={SKU_TYPE.BUNDLE}
          />
        )}
      </CreateEditForm>
    </Box>
  );
};

export default BookStocksEditor;
