import React, { Fragment, Component } from 'react';
import MaterialUICheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
export default class SubscriptionTypeFilterCheckBox extends Component {
  state = {
    expriedChecked: this.props.expriedChecked ?? false,
    activeChecked: this.props.activeChecked ?? false,
    incomingChecked: this.props.incomingChecked ?? false,
  };
  handlePropChange(event) {
    this.props.onChange(event, {
      value: {
        expired: this.state.expriedChecked,
        active: this.state.activeChecked,
        incoming: this.state.incomingChecked,
      },
    });
  }
  handleOnExpiredCheckedChange(event) {
    this.setState(
      (state) => ({ ...state, expriedChecked: !state.expriedChecked }),
      () => {
        this.handlePropChange.bind(this)(event);
      }
    );
  }
  handleOnActiveCheckedChange(event) {
    this.setState(
      (state) => ({ ...state, activeChecked: !state.activeChecked }),
      () => {
        this.handlePropChange.bind(this)(event);
      }
    );
  }
  handleOnIncomingCheckedChange(event) {
    this.setState(
      (state) => ({ ...state, incomingChecked: !state.incomingChecked }),
      () => {
        this.handlePropChange.bind(this)(event);
      }
    );
  }
  render() {
    return (
      <Fragment>
        <FormGroup row>
          <FormControlLabel
            label="Expired"
            control={
              <MaterialUICheckBox
                checked={this.state.expriedChecked}
                onChange={this.handleOnExpiredCheckedChange.bind(this)}
              ></MaterialUICheckBox>
            }
          ></FormControlLabel>
          <FormControlLabel
            label="Active"
            control={
              <MaterialUICheckBox
                checked={this.state.activeChecked}
                onChange={this.handleOnActiveCheckedChange.bind(this)}
              ></MaterialUICheckBox>
            }
          ></FormControlLabel>
          <FormControlLabel
            label="Incoming"
            control={
              <MaterialUICheckBox
                checked={this.state.incomingChecked}
                onChange={this.handleOnIncomingCheckedChange.bind(this)}
              ></MaterialUICheckBox>
            }
          ></FormControlLabel>
        </FormGroup>
      </Fragment>
    );
  }
}
