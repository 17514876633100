import React from 'react';
import PropTypes from 'prop-types';

import CreateEditForm from 'Containers/CreateEditForm';
import { createExamItem, updateExamItem } from 'GraphQL/mutation/Exam.mutation';
import { getExamById, getExamItem } from 'GraphQL/query/Exam.query';
import { useAppConfig } from 'Util/hoc/withAppConfig';

import { EXAM_ITEM_TAG_CATEGORY, EXAM_QUESTION_TYPE, EXAM_QUESTION_STATUS } from '../../constants';
import { getExamQuestionTypeOptions, getTagsData } from '../util';

import ConfigurableQuestionForm from './ConfigurableQuestionForm';

const mapSlugsToTags = (slugs = [], category) => {
  if (slugs) {
    if (Array.isArray(slugs)) {
      return slugs.map((slug) => {
        return {
          name: slug,
          slug,
          category,
        };
      });
    }
    return [
      {
        name: slugs,
        slug: slugs,
        category,
      },
    ];
  }
  return [];
};

const mapCorrectAnswer = (formValue) => {
  const { type, choice, numberResult } = formValue;
  switch (type) {
    case EXAM_QUESTION_TYPE.MULTIPLE_CHOICE: {
      const { correctAnswer: correctChoiceAnswer } = choice ?? {};
      return correctChoiceAnswer;
    }
    case EXAM_QUESTION_TYPE.FILL_NUMBER: {
      return { value: Number.parseFloat(numberResult) };
    }
    default:
      break;
  }
};

const mapMetaData = (formValue) => {
  const { type, choice, fieldNumber } = formValue;

  switch (type) {
    case EXAM_QUESTION_TYPE.MULTIPLE_CHOICE: {
      const { choices } = choice ?? {};
      return { choices, precision: null };
    }
    case EXAM_QUESTION_TYPE.FILL_NUMBER: {
      return { precision: Number.parseInt(fieldNumber), choices: [] };
    }
    default:
      break;
  }
};

const mapFormValueToExamItem = (formValue = {}, fieldConfig) => {
  const {
    owner,
    assignee,
    questionExamType = [],
    examCampaign = [],
    bloom = [],
    grade = [],
    indicator = [],
    objective = [],
    subject = [],
    section = [],
    subsection = [],
    subtopic = [],
    difficulty = [],
    type,
    question,
    solution,
    ...restFormValue
  } = formValue;

  const metadata = mapMetaData(formValue);

  const ownerTag = mapSlugsToTags(owner, EXAM_ITEM_TAG_CATEGORY.OWNER);
  const assigneeTag = mapSlugsToTags(assignee, EXAM_ITEM_TAG_CATEGORY.ASSIGNEE);
  const questionExamTypeTag = mapSlugsToTags(
    questionExamType,
    EXAM_ITEM_TAG_CATEGORY.QUESTION_EXAM_TYPE
  );
  const examCampaignTag = mapSlugsToTags(examCampaign, EXAM_ITEM_TAG_CATEGORY.EXAM_CAMPAIGN);
  const bloomTag = mapSlugsToTags(bloom, EXAM_ITEM_TAG_CATEGORY.BLOOM);
  const gradeTag = mapSlugsToTags(grade, EXAM_ITEM_TAG_CATEGORY.GRADE);
  const indicatorTag = mapSlugsToTags(indicator, EXAM_ITEM_TAG_CATEGORY.INDICATOR);
  const objectiveTag = mapSlugsToTags(objective, EXAM_ITEM_TAG_CATEGORY.OBJECTIVE);
  const subjectTags = mapSlugsToTags(subject, EXAM_ITEM_TAG_CATEGORY.SUBJECT);
  const sectionTags = mapSlugsToTags(section, EXAM_ITEM_TAG_CATEGORY.SECTION);
  const subsectionTags = mapSlugsToTags(subsection, EXAM_ITEM_TAG_CATEGORY.SUBSECTION);
  const subtopicTags = mapSlugsToTags(subtopic, EXAM_ITEM_TAG_CATEGORY.SUBTOPIC);
  const difficultyTags = mapSlugsToTags(difficulty, EXAM_ITEM_TAG_CATEGORY.DIFFICULTY);
  const tags = [
    ...ownerTag,
    ...assigneeTag,
    ...questionExamTypeTag,
    ...examCampaignTag,
    ...bloomTag,
    ...gradeTag,
    ...indicatorTag,
    ...objectiveTag,
    ...subjectTags,
    ...sectionTags,
    ...subsectionTags,
    ...subtopicTags,
    ...difficultyTags,
  ];

  return {
    type,
    question,
    solution: solution ?? fieldConfig?.solution?.defaultValue ?? '-',
    metadata,
    correctAnswer: mapCorrectAnswer(formValue),
    ...(tags.length >= 1 && { tags }),
  };
};

const mapExamItemToFormValue = (examItem, meta = {}) => {
  const { assignee, owner } = meta;
  if (!examItem) return { assignee, owner };

  const {
    metadata: { choices, precision },
    correctAnswer,
    tags,
    status,
    ...restExamItem
  } = examItem;
  const isDraftQuestion = status === EXAM_QUESTION_STATUS.DRAFT;
  const { assignee: examItemAssignee, owner: examItemOwner, ...other } = getTagsData(
    tags,
    isDraftQuestion
  );

  return {
    // EXAM QUESTION MULTIPLE CHOICES
    choice: { choices, correctAnswer },
    // EXAM QUESTION FILL NUMBER
    fieldNumber: precision,
    numberResult: correctAnswer?.value?.toFixed?.(precision),

    status,
    assignee: examItemAssignee || assignee,
    owner: examItemOwner || owner,
    ...other,
    ...restExamItem,
    examItemTags: tags,
  };
};

const ConfigurableQuestion = ({
  examId,
  examItemId,
  publishMode,
  onComplete,
  onCancelled,
  isEdit,
  assignee,
  owner,
  ...restProps
}) => {
  const { exam } = useAppConfig();
  const fieldConfiguration = exam?.question;
  const questionTypesConfig = exam?.enabledQuestionTypes;

  return (
    <CreateEditForm
      dataQuery={getExamItem}
      createMutation={createExamItem}
      createRefetchQueries={[{ query: getExamById, variables: { examId: examId } }]}
      editMutation={updateExamItem}
      editRefetchQueries={[
        { query: getExamItem, variables: { examId: examId, examItemId: examItemId } },
        { query: getExamById, variables: { examId: examId } },
      ]}
      isEdit={isEdit}
      id={examItemId}
      getQueryVariables={({ id }) => ({
        examId: examId,
        examItemId: id,
      })}
      getCreateVariables={({ form, isPublish }) => ({
        examId: examId,
        examItem: form,
        isPublish: isPublish,
      })}
      getEditVariables={({ form, isPublish }, { id }) => ({
        examId: examId,
        examItemId: id,
        examItem: form,
        isPublish: isPublish,
      })}
      getCreateMutationResponse={(mutateData) => mutateData?.createExamExamItem}
      getEditMutationResponse={(mutateData) => mutateData?.updateExamExamItem}
      onCompleted={onComplete}
      onCancelled={onCancelled}
      checkMutationResponse
    >
      {({ data, onSubmit }) => {
        const handleOnPublishSubmit = (formValue) => {
          onSubmit({
            form: mapFormValueToExamItem(formValue, fieldConfiguration),
            isPublish: true,
          });
        };

        const handleOnDraftSubmit = (formValue) => {
          onSubmit({
            form: mapFormValueToExamItem(formValue, fieldConfiguration),
            isPublish: false,
          });
        };

        const question = mapExamItemToFormValue(data?.examExamItem, { assignee, owner });
        return (
          <ConfigurableQuestionForm
            questionTypeOptions={getExamQuestionTypeOptions(questionTypesConfig)}
            field={fieldConfiguration}
            question={question}
            onCancelled={onCancelled}
            isEdit={isEdit}
            examId={examId}
            onSubmit={publishMode ? handleOnPublishSubmit : handleOnDraftSubmit}
            publishMode={publishMode}
            {...restProps}
          />
        );
      }}
    </CreateEditForm>
  );
};

ConfigurableQuestion.propTypes = {
  examId: PropTypes.string,
  examItemId: PropTypes.string,
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  publishMode: PropTypes.bool,
};
export default ConfigurableQuestion;
