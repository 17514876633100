import React, { Component } from 'react';
import PropTypes from 'prop-types';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import AppRoute, { GenRoute } from '../../Util/Route';

import AllWorkshop from './Routes/AllWorkshop';
import EditWorkshop from './Routes/EditWorkshop';
const menus = (matchUrl = '/workshop') => [
  {
    path: `${matchUrl}/`,
    title: 'All Workshops',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
    route: AllWorkshop,
  },
  {
    path: `${matchUrl}/:skuCode`,
    title: 'Edit Workshop',
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
    route: EditWorkshop,
  },
];

export default class WorkshopRoute extends Component {
  render() {
    const { match } = this.props;
    return <GenRoute menus={menus(match.path)} />;
  }
}
