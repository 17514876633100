import React from 'react';
import styled from 'styled-components';
import { VideoPlayer } from '@skooldio/skooldio-video-player';
import '@skooldio/skooldio-video-player/dist/skd-videojs.css';
import '@skooldio/skooldio-video-player/dist/fontello.css';
import '@skooldio/skooldio-video-player/dist/animation.css';

import { COLOR } from '../../Theme';

const VideoWrapper = styled.div`
  .video-js {
    font-family: inherit;
    height: 0 !important;
  }
  .video-js .vjs-slider.vjs-progress-holder .vjs-play-progress,
  .video-js .vjs-volume-bar.vjs-slider-vertical .vjs-volume-level {
    background-color: ${COLOR.primary};
  }
  /* icons */
  .video-js .vjs-button > .vjs-icon-placeholder:before {
    font-size: 2.2em;
  }
  /* time and playback speed text */
  .video-js .vjs-time-control,
  .video-js .vjs-playback-rate .vjs-playback-rate-value {
    font-size: 1.4em;
    font-weight: 400;
  }
  .video-js iframe {
    border: none;
  }
`;

const { DEFAULT_VIDEO_OPTIONS } = VideoPlayer || {};
const { controlBar } = DEFAULT_VIDEO_OPTIONS || {};
const videoOptions = {
  aspectRatio: '16:9',
  controlBar: { children: [...controlBar.children, 'fullscreenToggle'] },
};
const isUriAllowHeaders = (uri) => {
  return uri.includes('/license/drm/'); // TODO use configuration
};
export const VIDEO_TYPE = {
  BYTEARK: 'application/x-mpegURL',
  VIMEO: 'application/x-mpegURL',
  YOUTUBE: 'video/youtube',
};

const VideoPlayerWrapper = ({ url, videoType = VIDEO_TYPE.BYTEARK, playerProps }) => {
  const { videoOptions: overrideOptions, ...otherPlayerProps } = playerProps ?? {};
  const src = {
    src: url,
    type: videoType,
  };
  return (
    <VideoWrapper>
      <VideoPlayer
        src={src}
        videoOptions={{ ...videoOptions, ...overrideOptions }}
        isUriAllowHeaders={isUriAllowHeaders}
        authHeaders={{}}
        {...otherPlayerProps}
      />
    </VideoWrapper>
  );
};
VideoPlayerWrapper.VIDEO_TYPE = VIDEO_TYPE;
export default VideoPlayerWrapper;
