import React, { Fragment, useState } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import QueryComponent from 'GraphQL/util/QueryComponent';
import { getCourseOutline } from 'GraphQL/query/Courses.query';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';
import StatefulModal from 'Components/Modal/StatefulModal';
import { MATERIAL_TYPES_STRING, MATERIAL_TYPES } from 'Components/SubSection';
import { VIDEO_TYPE } from 'Util/ExternalAdapter/VideoAdapter';
import { LEVEL } from 'Routes/Courses/EditCourse/Lessons/utils';
import withAppConfig from 'src/Util/hoc/withAppConfig';

import VideoManagement from './VideoManagement.gql';
const Text = styled.span`
  ${'' /* color: ${COLOR.primary}; */};
`;

const VideoManagementModalButton = ({
  productVersion,
  courseId,
  courseCode,
  courseOwnerId,
  sections,
  sectionId,
  level,
  appConfig,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleModalAction = (closeFn) => {
    setDialogOpen(false);
    closeFn();
  };
  return (
    <Fragment>
      <Box m={1}>
        <Button
          circular
          onClick={() => setDialogOpen(true)}
          size="small"
          data-testid={`btn-add-video-in-${level === LEVEL.COURSE ? 'course' : 'section'}`}
        >
          <Icon name={'plus'} />
          <Icon color="orange" name="video" />
          <Text color="primary">Video in {level === LEVEL.COURSE ? 'Course' : 'Section'}</Text>
        </Button>
      </Box>
      {dialogOpen && (
        <StatefulModal trigger={<div />} defaultOpen>
          {(closeModal) => (
            <Fragment>
              <Modal.Header>New {MATERIAL_TYPES_STRING[MATERIAL_TYPES.VIDEO]}</Modal.Header>
              <Modal.Content>
                <VideoManagement
                  newAsset={true}
                  materialTypeString={MATERIAL_TYPES_STRING[MATERIAL_TYPES.VIDEO]}
                  metadata={{
                    courseId,
                    courseOwnerId,
                    courseCode,
                    productVersion,
                    sections,
                    sectionId,
                  }}
                  defaultVideoType={VIDEO_TYPE.BYTEARK}
                  isAllowMultiple={true}
                  materialDownloadable={false}
                  level={level}
                  onCancel={() => handleModalAction(closeModal)}
                  onComplete={() => handleModalAction(closeModal)}
                  videoUploadMaxItems={appConfig.videoUploadMaxItems}
                />
              </Modal.Content>
            </Fragment>
          )}
        </StatefulModal>
      )}
    </Fragment>
  );
};

VideoManagementModalButton.propTypes = {
  productVersion: PropTypes.string,
  courseId: PropTypes.string,
  courseCode: PropTypes.string,
  courseOwnerId: PropTypes.string,
  sections: PropTypes.string,
  sectionId: PropTypes.string,
  level: PropTypes.string,
};

const getCourseData = (course) =>
  course &&
  course.meta && {
    courseCode: course.courseCode,
    courseOwnerId: course.meta.courseOwnerId,
    sections: course.contents ? course.contents.sections : [],
  };

const VideoManagementModalButtonWithApollo = (props) => {
  const { product, version, courseId } = props;
  const productVersion = productCourseVersionResolver(product, version);
  return (
    <QueryComponent
      query={getCourseOutline}
      variables={{ version: productVersion, courseId }}
      loadingOverlay={false}
    >
      {(data) => (
        <VideoManagementModalButton
          productVersion={productVersion}
          {...props}
          {...getCourseData(data.course)}
        />
      )}
    </QueryComponent>
  );
};

VideoManagementModalButtonWithApollo.propTypes = {
  product: PropTypes.string,
  version: PropTypes.string,
  courseId: PropTypes.string,
};

export default withAppConfig({ configProp: 'appConfig' })(VideoManagementModalButtonWithApollo);
