import dlv from 'dlv';

const delve = (object, keypath, defaultValue) => {
  const result = dlv(object, keypath, defaultValue);
  if (result === null) {
    return defaultValue === undefined ? result : defaultValue;
  }
  return result;
};

export default delve;
