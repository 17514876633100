import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Checkbox, Header, Accordion } from 'semantic-ui-react';
import { Mutation } from '@apollo/client/react/components';
import _ from 'lodash';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';
import ConfirmationButton from 'Components/ConfirmationButton';

import { getCollectionItems } from '../../GraphQL/query/Products.query';
import { updateCollectionItemsMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUAddOnlineCourseModal from '../SKUAddOnlineCourseModal';
import { CATEGORY_TYPE, SKU_CATEGORY } from '../../Domains/constants';
import resolveCategoryName from '../../Domains/resolveCategoryName';
import CategoryCell from '../../Components/CategoryCell';
function addOrRemove(array, item) {
  const data = [...array];
  const index = _.findIndex(data, (datum) => datum.id === item.id);

  if (index === -1) {
    data.push({ id: item.id, SKUCode: item.SKUCode });
  } else {
    data.splice(index, 1);
  }
  return data;
}

const collectionItemColumns = (handleCheckbox, deselectedItems) => [
  {
    Header: 'SKUCode',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: resolveCategoryName(SKU_CATEGORY.ONLINE_COURSE, CATEGORY_TYPE.CATEGORY_1),
    accessor: 'category1Ids',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    sortable: false,
    Cell: ({ original }) => <CategoryCell categories={original?.category1s} />,
  },
  {
    Header: resolveCategoryName(SKU_CATEGORY.ONLINE_COURSE, CATEGORY_TYPE.CATEGORY_2),
    accessor: 'category2Ids',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    sortable: false,
    Cell: ({ original }) => <CategoryCell categories={original?.category2s} />,
  },
  {
    Header: resolveCategoryName(SKU_CATEGORY.ONLINE_COURSE, CATEGORY_TYPE.CATEGORY_3),
    accessor: 'category3Ids',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    sortable: false,
    Cell: ({ original }) => <CategoryCell categories={original?.category3s} />,
  },
  {
    Header: resolveCategoryName(SKU_CATEGORY.ONLINE_COURSE, CATEGORY_TYPE.CATEGORY_4),
    accessor: 'category4Ids',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    sortable: false,
    Cell: ({ original }) => <CategoryCell categories={original?.category4s} />,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <Fragment>
          <Button.Group>
            <Checkbox
              checked={deselectedItems.find((item) => item.id === id)}
              onChange={() => handleCheckbox(original)}
            />
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class CollectionSKUsEditor extends Component {
  static propTypes = {
    collection: PropTypes.shape({
      id: PropTypes.string.isRequired,
      SKUCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { collectionSKUCode: props.collection.SKUCode },
      order: { field: 'createdAt', type: 'ASC' },
      deselectedItems: [],
      activeIndex: -1,
    };
  }

  handleCheckbox = (id) => {
    this.setState({ deselectedItems: addOrRemove(this.state.deselectedItems, id) });
  };

  handleDeleteItems = (mutateFn) => {
    const { deselectedItems } = this.state;
    if (deselectedItems.length > 0) {
      mutateFn({
        variables: {
          id: this.props.collection.id,
          data: { removedItemIds: deselectedItems.map(({ id }) => id) },
        },
      });
    }
  };

  handleCancel = () => {
    this.setState({ deselectedItems: [] });
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({
      search: { ...search, collectionSKUCode: this.props.collection.SKUCode },
      paging,
    });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoProductCollectionItems.items;

  handleClickAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { search, paging, order, deselectedItems, activeIndex } = this.state;
    const variables = { search, paging, order };
    const { collection } = this.props;
    const { id, title } = collection;
    return (
      <Mutation
        mutation={updateCollectionItemsMutation}
        refetchQueries={[{ query: getCollectionItems, variables }]}
      >
        {(editData, { loading, error, data: mutateData }) => {
          return (
            <Box>
              <CheckMutationResponse
                checkOnlyDefined={true}
                response={mutateData}
                loading={loading}
                error={error}
                onValid={this.handleCancel}
              />
              <Flex justifyContent="space-between" mb={2}>
                <Text.Header>Collection SKUs</Text.Header>
                <SKUAddOnlineCourseModal
                  id={id}
                  title={title}
                  variables={variables}
                  handleMutation={updateCollectionItemsMutation}
                  refetchQueries={getCollectionItems}
                  trigger={
                    <Button primary circular>
                      <Icon name="plus" size="small" />
                      Add SKUs
                    </Button>
                  }
                />
              </Flex>
              <QueryTable
                type={'sfBoProductCollectionItems'}
                resolveData={this.resolveData}
                columns={collectionItemColumns(this.handleCheckbox, deselectedItems)}
                query={getCollectionItems}
                search={search}
                onSearchChange={this.handleSearchChange}
                paging={paging}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageSizeChange}
                order={order}
                onSortedChange={this.handleSortedChange}
              />
              <Flex mt={2}>
                <Accordion styled fluid>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleClickAccordion}
                  >
                    <Header textAlign="left">
                      <Icon name="dropdown" />
                      {`${deselectedItems.length} item(s) to be removed`}
                    </Header>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0} style={{ textAlign: 'left' }}>
                    {deselectedItems.map(({ id, SKUCode }, index) => (
                      <Text key={id}>{`${index + 1}: ${SKUCode}`}</Text>
                    ))}
                  </Accordion.Content>
                </Accordion>
              </Flex>
              <Flex justifyContent="flex-end" mt={2}>
                <ConfirmationButton
                  buttonStyle={{
                    primary: true,
                    circular: true,
                  }}
                  headerText={`Delete?`}
                  contentText={`These SKUs will be deleted from ${title}`}
                  confirmText={'Delete Selected'}
                  onConfirm={() => this.handleDeleteItems(editData)}
                >
                  Delete
                </ConfirmationButton>
                <Button
                  circular
                  size="medium"
                  type="reset"
                  content="Cancel"
                  disabled={loading}
                  onClick={this.handleCancel}
                />
              </Flex>
            </Box>
          );
        }}
      </Mutation>
    );
  }
}

export default CollectionSKUsEditor;
