import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { Text } from 'Components/Base';
import { FONT_SIZE } from 'Components/Theme';

const Crumb = ({ crumb = {} }) => {
  if (crumb.path) return <Link to={crumb.path}>{crumb.title}</Link>;
  return <Text as="span">{crumb.title}</Text>;
};

const Breadcrumb = ({ crumbs = [], ...restProps }) => {
  return (
    <Text fontSize={FONT_SIZE.xsmall} fontWeight="300" {...restProps}>
      {crumbs.map((crumb, index) => (
        <Fragment key={crumb.title}>
          {index < crumbs.length - 1 ? (
            <>
              <Crumb crumb={crumb} />
              <Icon name="chevron right" size="small" style={{ margin: '0 8px' }} />
            </>
          ) : (
            <Crumb crumb={crumb} />
          )}
        </Fragment>
      ))}
    </Text>
  );
};

Breadcrumb.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default Breadcrumb;
