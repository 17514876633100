import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';

import NewEnrollment from './NewEnrollment.gql';

export default class NewUserModal extends Component {
  render() {
    return (
      <StatefulModal trigger={this.props.trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>Enroll a Course</Modal.Header>
            <Modal.Content>
              <NewEnrollment onComplete={closeModal} onCancelled={closeModal} {...this.props} />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
