import React, { Component } from 'react';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { GenRoute } from '../../Util/Route';

import AllCourses from './AllCourses';
const menus = (matchUrl = '/courses') => [
  {
    path: `${matchUrl}/`,
    title: 'All Courses',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
    route: AllCourses,
  },
];

export default class CourseRoute extends Component {
  render() {
    const { match } = this.props;
    return <GenRoute menus={menus(match.path)} />;
  }
}
