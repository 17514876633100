import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBundles, getBundle } from '../../GraphQL/query/Products.query';
import { createBundleMutation } from '../../GraphQL/mutation/Products.mutation';

import BundleForm from './BundleForm';

export default class BundleModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };

  render() {
    const { isEdit, id, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'Edit Bundle' : 'Create Bundle'}</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getBundle}
                createMutation={createBundleMutation}
                createRefetchQueries={[
                  {
                    query: getBundles,
                    variables,
                  },
                ]}
                isEdit={isEdit}
                id={id}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getCreateVariables={(form) => ({
                  data: { ...form },
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <BundleForm initialData={data && data.sfBoProductBundle} onSubmit={onSubmit} />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
