import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { Flex, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const DateInput = forwardRef(({ value, ...restProps }, ref) => {
  return <Input value={value} {...restProps} readOnly ref={ref} />;
});
class TimeRangePickerInput extends React.Component {
  state = {
    startTime: this.props.value.startTime,
    endTime: this.props.value.endTime,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    errorMessages: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        startTime: PropTypes.any,
        endTime: PropTypes.any,
      }),
      PropTypes.string,
    ]),
  };

  handleChange = (name, date) => {
    this.setState({ [name]: date.toJSON() }, () => {
      this.props.onChange({}, { value: this.state });
    });
  };

  render() {
    const { startTime, endTime } = this.state;
    const { name, value, errorMessages, ...inputProps } = this.props;

    const mStartTime = startTime ? moment(startTime) : null;
    const mEndTime = endTime ? moment(endTime) : null;

    return (
      <Flex alignItems="center">
        <DatePicker
          name={`${name}.startTime`}
          selected={mStartTime}
          placeholderText="Start Time"
          minTime={moment(moment().format('YYYY-MM-DD') + '00:00:00')}
          maxTime={mEndTime || moment(moment().format('YYYY-MM-DD') + ' 23:59:00')}
          showTimeSelect
          showTimeSelectOnly
          selectsStart
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
          {...inputProps}
          customInput={<DateInput />}
          onChange={(date) => this.handleChange('startTime', date)}
        />
        <Text px={2}>-</Text>
        <DatePicker
          name={`${name}.endTime`}
          selected={mEndTime}
          placeholderText="End Time"
          minTime={mStartTime || moment(moment().format('YYYY-MM-DD') + '00:00:00')}
          maxTime={moment(moment().format('YYYY-MM-DD') + ' 23:59:00')}
          showTimeSelect
          showTimeSelectOnly
          selectsEnd
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
          {...inputProps}
          customInput={<DateInput />}
          onChange={(date) => this.handleChange('endTime', date)}
        />
      </Flex>
    );
  }
}
export default withFormsy(TimeRangePickerInput);
