import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Segment, Embed, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';
import isUrl from 'is-url';

import { Box, Flex, Image } from 'Components/Base';

import ActionBar from '../Common/ActionBar';
import { AssetPropTypes } from '../Common/AssetPropTypes';
import {
  getVideoDetailById,
  extractIdFromUrl,
  customDurationFormat,
} from '../../../Util/ExternalAdapter/Api/YoutubeApi';
import FormGenerator from '../../Form/FormGenerator';

const defaultVideoDetail = {
  url: '',
  youtubeId: '',
  videoDetail: '',
  thumbnail: '',
  title: '',
  description: '',
  totalTime: '',
};

const convertToRawData = (data) => {
  const { url, youtubeId, rawDetail } = data;
  const videoDetail = _.omit(rawDetail, ['found']);
  return {
    url,
    youtubeId,
    videoDetail,
  };
};

const extractDisplayDetail = (rawDetail) => {
  const { thumbnails, detail, totalTime } = rawDetail;
  const { high: highThumbnail } = thumbnails;
  return {
    thumbnail: highThumbnail.url,
    title: detail.title,
    description: detail.description,
    totalTime: customDurationFormat(totalTime.raw),
  };
};

const convertFromRawData = (content = {}) => {
  if (content.url) {
    const { url, youtubeId, videoDetail: rawDetail } = content;
    return {
      url,
      youtubeId,
      rawDetail,
      ...extractDisplayDetail(rawDetail),
    };
  }
  return defaultVideoDetail;
};

const getVideoDetails = async (youtubeLink, prevId, videoDetail) => {
  const youtubeId = extractIdFromUrl(youtubeLink);
  if (!youtubeId) {
    return defaultVideoDetail;
  }
  if (youtubeId !== prevId) {
    const rawDetail = await getVideoDetailById(youtubeId);
    if (rawDetail && rawDetail.found) {
      return {
        url: youtubeLink,
        youtubeId: youtubeId,
        rawDetail: rawDetail,
        ...extractDisplayDetail(rawDetail),
      };
    } else {
      return defaultVideoDetail;
    }
  }
  return videoDetail;
};

const Divider = styled(Box)`
  border-top: 1px solid #d9d9d9;
`;

class YoutubeVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      data: convertFromRawData(props.content),
      loadDataFromYoutube: false,
      canSubmit: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.selectInputList = this.selectInputList.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ ...this.state, data: convertFromRawData(this.props.content), formData: {} });
    }
  }

  handleAction = () => {
    const { formData, data } = this.state;
    Object.keys(formData).map(
      (field) =>
        (formData[field] =
          typeof formData[field] == 'string' ? formData[field].trim() : formData[field])
    );
    const resultObject = {
      ...formData,
      content: convertToRawData(data),
    };
    if (this.state.canSubmit) {
      this.props.onCreateMaterial(resultObject);
    }
  };

  selectInputList = () => {
    const { formData } = this.state;
    const { title, description, youtubeLink } = formData;
    return [
      {
        inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
        inputLabel: 'YouTube Link',
        inputName: 'youtubeLink',
        inputProps: {
          required: true,
          value: youtubeLink,
        },
      },
      {
        inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
        inputLabel: 'Title',
        inputName: 'title',
        inputProps: {
          required: true,
          value: title,
        },
      },
      {
        inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
        inputLabel: 'Description',
        inputName: 'description',
        inputProps: {
          value: description,
        },
      },
    ];
  };

  handleFormChange = async (value) => {
    const { formData, data } = this.state;
    const { youtubeLink: prevLink } = formData;
    const { youtubeLink: currentLink } = value;
    const { youtubeId: prevId } = data;
    this.setState({ ...this.state, formData: value });
    if (prevLink !== currentLink) {
      const videoDetail = await getVideoDetails(currentLink, prevId, data);
      this.setState({ ...this.state, data: videoDetail });
      const { title, description } = value;
      if (!title && !description) {
        this.setState({
          ...this.state,
          formData: {
            ...this.state.formData,
            title: videoDetail.title,
            description: videoDetail.description,
          },
        });
      }
    }
  };

  render() {
    const { newAsset, onCancel, title, description, loading, materialTypeString } = this.props;
    // const { data } = this.state;
    const { data: videoDetail } = this.state;
    const { url: youtubeLink } = videoDetail;
    const inputList = this.selectInputList();
    const requiredFields = inputList
      .filter((field) => field.inputProps?.required)
      .map((field) => field.inputName);
    return (
      <Box>
        <Box>
          <FormGenerator
            fields={inputList}
            showAction={false}
            initialData={{ title, description, youtubeLink }}
            onChange={this.handleFormChange}
            customValidation={(currentValues) => {
              this.setState({ ...this.state, canSubmit: false });
              const validationErrors = {};

              if (!isUrl(currentValues.youtubeLink)) {
                validationErrors['youtubeLink'] = 'Please enter a valid url format';
              }
              requiredFields.forEach((field) => {
                if (currentValues[field] === '') {
                  validationErrors[field] = `${field} is required`;
                }
              });
              const canSubmit = _.isEmpty(validationErrors);
              this.setState({ ...this.state, canSubmit });
              return validationErrors;
            }}
            name="youtube-video-form"
          />
        </Box>
        <Flex alignItems="center" justifyContent="space-between" my={3}>
          <Box>
            {'Your use of this service is subject to '}
            <a href="https://www.youtube.com/t/terms" target="_new">
              YouTube Terms and Conditions
            </a>
            {'.'}
          </Box>
          <Image flex="0 0 170px" src="/assets/developed-with-youtube-lowercase-dark.png" />
        </Flex>

        {videoDetail.thumbnail && (
          <Segment>
            <Label as="a" color="red" ribbon>
              <Icon className="clock outline" />
              {videoDetail.totalTime}
            </Label>
            <Embed
              id={videoDetail.youtubeId}
              placeholder={videoDetail.thumbnail}
              source="youtube"
            />
          </Segment>
        )}
        <Divider pb="3" />
        <Box>
          <ActionBar
            isCreate={newAsset}
            onAction={this.handleAction}
            onCancel={onCancel}
            loading={loading}
            materialTypeString={materialTypeString}
          />
        </Box>
      </Box>
    );
  }
}

YoutubeVideo.propTypes = {
  ...AssetPropTypes,
  content: PropTypes.object.isRequired,
};

YoutubeVideo.defaultProps = {
  onCreateMaterial: () => {},
  content: {},
};

export default YoutubeVideo;
