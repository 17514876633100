import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';

import CreateEditBatchForm from './WorkshopCreateEditBatchForm';

export default class WorkshopBatchModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isOpen: PropTypes.bool,
    trigger: PropTypes.element,
    batchSkuCode: PropTypes.string,
    collectionSkuCode: PropTypes.string.isRequired,
    variables: PropTypes.object,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { variables, isEdit, trigger, batchSkuCode, collectionSkuCode } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'Edit Batch Information' : 'Create New Batch'}</Modal.Header>
            <Modal.Content>
              <CreateEditBatchForm
                isEdit={isEdit}
                onComplete={closeModal}
                onCancelled={closeModal}
                variables={variables}
                batchSkuCode={batchSkuCode}
                collectionSkuCode={collectionSkuCode}
              />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
