import React from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Team Name',
    inputName: 'name',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
    inputLabel: 'Description',
    inputName: 'description',
  },
];

const TeamDetailsForm = ({ isEdit, initialData, onSubmit, onCancelled }) => (
  <FormGenerator
    fields={fields}
    onSubmit={onSubmit}
    onCancelled={onCancelled}
    submitText={isEdit ? 'Save Team Details' : 'Save Team'}
    cancelText="Cancel"
    showCancel={!isEdit}
    initialData={initialData}
  />
);

TeamDetailsForm.propTypes = {
  isEdit: PropTypes.bool,
  initialData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancelled: PropTypes.func,
};

TeamDetailsForm.defaultProps = {
  isEdit: true,
  initialData: {
    name: '',
    description: '',
  },
  onSubmit: () => {},
  onCancelled: () => {},
};

export default TeamDetailsForm;
