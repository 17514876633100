import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from '../../Form/FormGenerator';

const inputList = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Title',
    inputName: 'title',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Description',
    inputName: 'description',
  },
];

const MaterialMeta = ({ title, description, onChange }) => (
  <FormGenerator
    fields={inputList}
    showAction={false}
    initialData={{ title, description }}
    onChange={onChange}
    name="material-meta-form"
  />
);

export default MaterialMeta;
