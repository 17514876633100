import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import omitDeep from 'Util/omitDeep';
import { Modal } from 'semantic-ui-react';

import delve from 'Util/Delve';
import getConfig from 'Util/Config';
import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import { sfCreateWorkshop, sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation';
import {
  getWorkshopBySKUCode,
  // searchWorkshops,
  getStatusValue,
  resolveStatus,
} from 'GraphQL/query/Workshop.query';

import { getWorkshops } from '../../GraphQL/query/Products.query';

import WorkshopForm from './WorkshopForm';

export default class AddWorkshopModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isOpen: PropTypes.bool,
    trigger: PropTypes.element,
    skuCode: PropTypes.string,
    variables: PropTypes.object,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { variables, isEdit, trigger, skuCode } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>
              {isEdit ? 'Edit Workshop Information' : 'Create New Workshop'}
            </Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getWorkshopBySKUCode}
                createMutation={sfCreateWorkshop}
                createRefetchQueries={[{ query: getWorkshops, variables }]}
                editMutation={sfUpdateWorkshop}
                isEdit={isEdit}
                id={skuCode}
                getQueryVariables={({ id }) => ({
                  SKUCode: id,
                })}
                getEditVariables={(form, { id }) => ({
                  SKUCode: id,
                  data: form,
                })}
                getCreateVariables={(form) => ({
                  data: form,
                })}
                getEditMutationResponse={(mutateData) => delve(mutateData, 'sfUpdateWorkshop')}
                getCreateMutationResponse={(mutateData) => delve(mutateData, 'sfCreateWorkshop')}
                onCompleted={closeModal}
                onCancelled={closeModal}
                checkMutationResponse
              >
                {({ data, onSubmit }) => {
                  const mapDataIntoForm = ({ SKUStatus, isVisible, ...restData }) => {
                    return { status: resolveStatus(SKUStatus, isVisible), ...restData };
                  };
                  const handleOnSubmit = ({ status, ...formValue }) => {
                    let workshopInfo = { ...getStatusValue(status), ...formValue };
                    if (!isEdit) {
                      const { basefaq } = getConfig();
                      const enabledBaseFaq = delve(basefaq, 'enabled');
                      if (enabledBaseFaq) {
                        const faq = delve(basefaq, 'faq', []);
                        workshopInfo = { ...workshopInfo, FAQ: faq };
                      }
                    }

                    const formatData = omitDeep(workshopInfo, ['__typename', 'assetUrl']);

                    const updatedContactInfo = delve(formatData, 'contactInfo');
                    const updatedPromotionText = delve(formatData, 'promotionText');

                    const contactInfo = delve(updatedContactInfo, 'content')
                      ? updatedContactInfo
                      : null;
                    const promotionText = delve(updatedPromotionText, 'content')
                      ? updatedPromotionText
                      : null;

                    onSubmit({ ...formatData, contactInfo, promotionText });
                  };
                  return (
                    <WorkshopForm
                      initialData={data && mapDataIntoForm(data.sfWorkshopBySKUCode)}
                      onSubmit={handleOnSubmit}
                    />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
