import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import { FormsyInstructorSelectorInput } from '../../Components/InstructorSelectorInput';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Instructors',
      inputName: 'instructorIds',
      mode: MODE.BOTH,
      customInput: (props) => <FormsyInstructorSelectorInput {...props} />,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class SKUInstructorForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  handleSubmit = () => {};

  render() {
    const { isEdit, onSubmit } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit)}
        onSubmit={onSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}

export default SKUInstructorForm;
