import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBundle } from '../../GraphQL/query/Products.query';
import { updateBundleMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY } from '../../Domains/constants';

import BundleOverviewForm from './BundleOverviewForm';

const resolveData = ({ sfBoProductBundle }) => {
  return {
    ...sfBoProductBundle,
    SKUStatus: `${sfBoProductBundle.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED}/${
      sfBoProductBundle.SKUStatus
    }`,
  };
};

class BundleOverviewEditor extends Component {
  render() {
    const { bundle = {} } = this.props;
    const { id, SKUCode } = bundle;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getBundle}
          editMutation={updateBundleMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              ...form,
              SKUStatus: form.SKUStatus.split('/')[1],
              isVisible: form.SKUStatus.split('/')[0] === SKU_VISIBILITY.VISIBLE,
            },
          })}
        >
          {({ data, onSubmit }) => {
            return <BundleOverviewForm initialData={resolveData(data)} onSubmit={onSubmit} />;
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default BundleOverviewEditor;
