import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'formsy-semantic-ui-react';

export default class TagSelectorInput extends Component {
  static propTypes = {
    tags: PropTypes.array,
    defaultValue: PropTypes.array,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: 'Select Value(s)',
  };

  state = {
    options: this.props.tags,
    currentValue: this.props.defaultValue,
  };

  handleAddition = (e, { value }) => {
    this.setState({
      options: [
        {
          id: this.state.options.length,
          text: value,
          value: value,
          key: this.state.options.length,
        },
        ...this.state.options,
      ],
      currentValue: [...this.state.currentValue],
    });
  };
  handleChange = (e, { value }) => {
    this.setState({ currentValue: [...value] });
  };

  render() {
    const { currentValue, options } = this.state;
    return (
      <Dropdown
        search
        selection
        allowAdditions
        multiple
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
        options={options}
        value={currentValue}
        {...this.props}
      />
    );
  }
}
