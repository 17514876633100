import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withApollo } from '@apollo/client/react/hoc';

import FormGenerator from 'Components/Form/FormGenerator';

const fields = (
  syllabusData,
  schoolTermData,
  schoolGradeData,
  schoolProgrammeData,
  careerPlanData
) => {
  const schoolProgrammes = schoolProgrammeData.map(({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  }));
  schoolProgrammes.splice(0, 0, {
    key: 'all',
    value: 'all',
    text: 'ทุกแผนการเรียน',
  });
  const careerPlans = careerPlanData.map(({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  }));
  careerPlans.splice(0, 0, {
    key: 'all',
    value: 'all',
    text: 'ทุกแผนอาชีพ',
  });
  return [
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Syllabus',
      inputName: 'syllabusId',
      options: syllabusData.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      })),
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'เทอม',
      inputName: 'schoolTermId',
      options: schoolTermData.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      })),
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'ระดับชั้น',
      inputName: 'schoolGradeId',
      options: schoolGradeData.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      })),
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'แผนการเรียน',
      inputName: 'schoolProgrammeId',
      options: schoolProgrammes,
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'แผนอาชีพ',
      inputName: 'careerPlanId',
      options: careerPlans,
      inputProps: {
        required: true,
      },
    },
  ];
};

class GroupAssignmentForm extends Component {
  static propTypes = {
    onCreateGroupAssignment: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateGroupAssignment: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateGroupAssignment(form);
  };

  render() {
    const {
      isEdit,
      syllabusData,
      schoolTermData,
      schoolGradeData,
      schoolProgrammeData,
      careerPlanData,
    } = this.props;
    return (
      <FormGenerator
        fields={fields(
          syllabusData,
          schoolTermData,
          schoolGradeData,
          schoolProgrammeData,
          careerPlanData
        )}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}

export default withApollo(GroupAssignmentForm);
