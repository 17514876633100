import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import {
  DescriptionInput,
  BannerCategoryInput,
  BannerAudienceInput,
  PublishTimeInput,
  BannerInput,
} from '../Components';
import { ANNOUNCEMENT_CATEGORY, ANNOUNCEMENT_INPUT_LABEL } from '../Components/constants';

const Form = ({
  isEdit,
  isView,
  onSubmit,
  onSubmitDraft,
  onFileListRequest,
  onFileUploadUrlRequest,
  ...props
}) => {
  const getCategory = isEdit || isView ? props.initialData?.category : ANNOUNCEMENT_CATEGORY.NEWS;
  const [category, setCategory] = useState(getCategory);
  const announcementId = props.initialData?.id;

  const fieldsWith = (isView, category) => [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Topic',
      inputName: 'title',
      inputProps: {
        required: true,
        readOnly: isView,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Category',
      inputName: 'category',
      inputProps: {
        radioList: [
          {
            label: 'Banner',
            name: ANNOUNCEMENT_CATEGORY.BROWSE_BANNERS,
          },
        ],
        validationErrors: {
          isDefaultRequiredValue: `Category is required`,
        },
        required: true,
        readOnly: isView,
      },
      customInput: (props) => <BannerCategoryInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel:
        category === ANNOUNCEMENT_CATEGORY.BROWSE_BANNERS
          ? ANNOUNCEMENT_INPUT_LABEL.BANNER
          : ANNOUNCEMENT_INPUT_LABEL.DESCRIPTION,
      inputName: 'detail',
      inputProps: {
        required: true,
        readOnly: isView,
      },
      customInput:
        category === ANNOUNCEMENT_CATEGORY.BROWSE_BANNERS
          ? (props) => (
              <BannerInput
                announcementId={announcementId}
                onFileListRequest={onFileListRequest}
                onFileUploadUrlRequest={onFileUploadUrlRequest}
                {...props}
              />
            )
          : (props) => <DescriptionInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Audience',
      inputName: 'audiences',
      inputProps: {
        checkBoxLabel: 'All',
        validationErrors: {
          isDefaultRequiredValue: `Audience is required`,
        },
        required: true,
        readOnly: isView,
      },
      customInput: (props) => <BannerAudienceInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Publish Time',
      inputName: 'publishConfig',
      inputProps: {
        // publishTimeLabel: 'กรุณาเลือกวันที่และเวลา',
        checkBoxLabel: 'Now',
        validationErrors: {
          isDefaultRequiredValue: `Publish Time is required`,
        },
        required: true,
        readOnly: isView,
      },
      customInput: (props) => <PublishTimeInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Announced by',
      inputName: 'announcedBy',
      inputProps: {
        required: true,
        readOnly: isView,
      },
    },
  ];

  const handleFormChange = (formValue) => {
    const { category } = formValue;
    if (!category) return;

    setCategory(category);
  };

  const handleSubmit = (formValue) => {
    onSubmit({ ...formValue });
  };

  const handleSubmitDraft = (formValue) => {
    onSubmitDraft({ ...formValue });
  };

  return (
    <FormGenerator
      fields={fieldsWith(isView, category)}
      compact
      showCancel
      isRequiredConfirmation
      submitText={'Save & Publish'}
      submitContent={`This announcement will be saved and publish.`}
      cancelText="Cancel"
      showAddition
      additionText={'Save Draft'}
      additionContent={`This announcement will be saved as draft.`}
      onSubmit={handleSubmit}
      onAdditionSubmit={handleSubmitDraft}
      onChange={handleFormChange}
      {...props}
    />
  );
};

Form.propTypes = {
  isEdit: PropTypes.bool,
  isView: PropTypes.bool,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitDraft: PropTypes.func,
  onFileListRequest: PropTypes.func,
  onFileUploadUrlRequest: PropTypes.func,
};

export default Form;
