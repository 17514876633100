import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';

import { Flex, Box, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';
import RichTextEditor from 'Components/RichTextEditor';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

const EditorBox = styled(Box)`
  flex: 1;
  & .text-editor {
    width: 100%;
  }
`;

const ChoiceInput = withFormsy(
  ({ value: { choices = [], correctAnswer = { value: [] } }, onChange, examId, readonly }) => {
    const handleOnChange = ({ choices, correctAnswer }) => {
      onChange({}, { value: { choices, correctAnswer } });
    };

    const handleCheckboxChange = (correctAnswers, checkboxNo) => {
      const correctAnswer = [...correctAnswers];
      const index = _.findIndex(correctAnswer, (data) => data === checkboxNo);

      if (index === -1) {
        correctAnswer.push(checkboxNo);
      } else {
        correctAnswer.splice(index, 1);
      }

      handleOnChange({ choices, correctAnswer: { value: correctAnswer } });
    };

    const handleChoiceLabelChange = (choiceData, index) => {
      const newChoices = [...choices];
      newChoices[index] = { ...newChoices[index], label: choiceData };

      handleOnChange({ choices: newChoices, correctAnswer });
    };

    const handleAddChoice = () => {
      handleOnChange({
        choices: [...choices, { label: '', value: `${choices.length + 1}` }],
        correctAnswer,
      });
    };

    const handleDeleteChoice = () => {
      const correctAnswerValue = correctAnswer ? correctAnswer.value : [];
      const newCorrectAnswer = [...correctAnswerValue];
      const newChoices = [...choices];
      newChoices.pop();

      // Also remove correct answer if choice being deleted is one of correct answers.
      const lastChoicesValue = choices[choices.length - 1].value;
      const lastCorrectAnswerValue = correctAnswerValue[correctAnswerValue.length - 1];

      if (lastChoicesValue === lastCorrectAnswerValue) {
        newCorrectAnswer.pop();
      }

      handleOnChange({ choices: newChoices, correctAnswer: { value: newCorrectAnswer } });
    };

    const hasChoices = choices.length >= 1;
    const [selectedChoices, correctAnswerValue] = useMemo(() => {
      const hasCorrectAnswerValue = Array.isArray(correctAnswer?.value);
      const correctAnswerDefault = hasCorrectAnswerValue ? correctAnswer : { value: [] };
      const correctAnswerValue = correctAnswerDefault.value ?? [];
      const selectedChoices = choices.map((_, index) =>
        correctAnswerValue.includes(`${index + 1}`)
      );

      return [selectedChoices, correctAnswerValue];
    }, [choices, correctAnswer]);

    const choiceRow = [];
    if (hasChoices) {
      choices.forEach((choice, index) => {
        const checkboxNo = `${index + 1}`;

        return choiceRow.push(
          <Flex my={2} key={`choice_${index}`} id={`choice_${index}`}>
            <Flex minWidth="84px">
              <Checkbox
                label={`choice ${checkboxNo}`}
                onChange={() => handleCheckboxChange(correctAnswerValue, checkboxNo)}
                checked={selectedChoices[index]}
                style={{ height: '16px' }}
              />
            </Flex>
            <EditorBox ml={2}>
              <RichTextEditor
                onChange={(data) => handleChoiceLabelChange(data, index)}
                value={choice.label}
                contentFormat={`html`}
                returnValueAsString
                customControls={[
                  {
                    type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                    data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                  },
                ]}
              />
            </EditorBox>
          </Flex>
        );
      });
      choiceRow.push(
        <Flex justifyContent="flex-end" key={`choice_delete`}>
          <Button icon compact basic type="button" color="red" onClick={handleDeleteChoice}>
            <Icon name="trash alternate outline" />
          </Button>
        </Flex>
      );
    }
    choiceRow.push(
      <Box my={2} key={`choice_add`}>
        <Button
          disabled={readonly}
          secondary
          circular
          fluid
          type="button"
          onClick={handleAddChoice}
        >
          <Icon name="plus square" />
          Add New Choice
        </Button>
      </Box>
    );
    return choiceRow;
  }
);

const MultipleSelectForm = (props) => {
  return (
    <Fragment>
      <Text as={'label'} fontWeight={'bold'}>
        Choice
      </Text>
      <ChoiceInput {...props} />
    </Fragment>
  );
};

MultipleSelectForm.propTypes = {
  value: PropTypes.shape({
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: RichTextEditor.propTypes.value,
      })
    ),
    correctAnswer: PropTypes.shape({
      value: PropTypes.array,
    }),
  }),
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};

export default MultipleSelectForm;
