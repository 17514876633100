export const UserPermissionKey = Object.freeze({
  LIST: {
    VIEW: 'user.list-view',
    UPDATE: 'user.list-update',
    DELETE: ' user.list-delete',
  },
  DETAIL: {
    VIEW: 'user.detail-view',
    UPDATE: 'user.detail-update',
    DELETE: ' user.detail-delete',
  },
  ROLE: {
    VIEW: 'user.role-view',
    UPDATE: 'user.role-update',
    DELETE: ' user.role-delete',
  },
  ENROLLMENT: {
    VIEW: 'user.enrollment-view',
    UPDATE: 'user.enrollment-update',
    DELETE: ' user.enrollment-delete',
  },
  ORGANIZATION: {
    VIEW: 'user.organization-view',
    UPDATE: 'user.organization-update',
    DELETE: ' user.organization-delete',
  },
  BATCH_ENROLLMENT: {
    VIEW: 'user.batchEnrollment-view',
    UPDATE: 'user.batchEnrollment-update',
    DELETE: ' user.batchEnrollment-delete',
  },
  TEAM: {
    VIEW: 'user.team-view',
    UPDATE: 'user.team-update',
    DELETE: ' user.team-delete',
  },
  ROLE_MANAGEMENT: {
    LIST: {
      VIEW: 'user.role.list-view',
      UPDATE: 'user.role.list-update',
      DELETE: ' user.role.list-delete',
    },
    OVERVIEW: {
      VIEW: 'user.role.overview-view',
      UPDATE: 'user.role.overview-update',
      DELETE: ' user.role.overview-delete',
    },
    PERMISSION: {
      VIEW: 'user.role.permission-view',
      UPDATE: 'user.role.permission-update',
      DELETE: ' user.role.permission-delete',
    },
    USERS: {
      VIEW: 'user.role.users-view',
      UPDATE: 'user.role.users-update',
      DELETE: ' user.role.users-delete',
    },
  },
  CONTENT_TEAM: {
    LIST: {
      VIEW: 'user.team.list-view',
      UPDATE: 'user.team.list-update',
      DELETE: ' user.team.list-delete',
    },
    DETAILS: {
      VIEW: 'user.team.details-view',
      UPDATE: 'user.team.details-update',
      DELETE: ' user.team.details-delete',
    },
    USERS: {
      VIEW: 'user.team.users-view',
      UPDATE: 'user.team.users-update',
      DELETE: ' user.team.users-delete',
    },
  },
  DEVICE: {
    VIEW: 'user.device-view',
    UPDATE: 'user.device-update',
    DELETE: ' user.device-delete',
  },
});
