import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { compose } from 'recompose';

import { Flex, Image } from 'Components/Base';
import withAppConfig from 'Util/hoc/withAppConfig';

import { SemanticButton as Button } from '../Base/Button';

import { MATERIAL_TYPES } from './util';

const Text = styled.span`
  ${'' /* color: ${COLOR.primary}; */};
`;

const YoutubeButton = styled((props) => (
  <Flex {...props} height="34px" px={3} alignItems="center" />
))`
  background-color: #212121;
  border-radius: 10em;
  :hover {
    cursor: pointer;
  }
`;

const MindstampButton = styled((props) => <Button {...props} />)`
  display: flex !important;
  align-items: center;
  background-color: #231f81 !important;
  :hover {
    background-color: #4744a1 !important;
  }
`;

const renderSwitch = (
  action,
  loading,
  onClick,
  {
    enabledMindstamp = false,
    enabledByteArkApi = true,
    enabledVimeoApi = false,
    enabledWistiaApi = false,
  }
) => {
  switch (action.id) {
    case MATERIAL_TYPES.YOUTUBE: {
      return (
        <YoutubeButton
          circular
          onClick={() => onClick(action.id)}
          loading={loading}
          disabled={action.disabled}
          size="small"
          data-testid={`btn-add-${action.id.toLowerCase()}`}
        >
          <Image height="17px" src="/assets/yt_logo_rgb_dark.png" />
        </YoutubeButton>
      );
    }

    case MATERIAL_TYPES.VIDEO: {
      return (
        <>
          {enabledByteArkApi && (
            <Button
              circular
              onClick={() => onClick(action.id)}
              loading={loading}
              disabled={action.disabled}
              size="small"
              data-testid={`btn-add-${action.id.toLowerCase()}`}
            >
              <Icon name={'plus'} />
              {action.icon && <Icon color="orange" name={action.icon} />}
              <Text color="primary">{action.title}</Text>
            </Button>
          )}
          {enabledWistiaApi && (
            <Button
              circular
              onClick={() => onClick(action.id, 'WISTIA')}
              loading={loading}
              disabled={action.disabled}
              size="small"
              data-testid={`btn-add-${action.id.toLowerCase()}`}
            >
              <Icon name={'plus'} />
              {action.icon && <Icon color="orange" name={action.icon} />}
              <Text color="primary">Wistia</Text>
            </Button>
          )}
          {enabledVimeoApi && (
            <Button
              circular
              onClick={() => onClick(action.id, 'VIMEO')}
              loading={loading}
              disabled={action.disabled}
              size="small"
              data-testid={`btn-add-${action.id.toLowerCase()}`}
            >
              <Icon name={'plus'} />
              {action.icon && <Icon color="orange" name={action.icon} />}
              <Text color="primary">Vimeo</Text>
            </Button>
          )}
          {enabledMindstamp && (
            <MindstampButton
              circular
              onClick={() => onClick(action.id, 'MINDSTAMP')}
              loading={loading}
              disabled={action.disabled}
              size="small"
              compact
              data-testid={`btn-add-${action.id.toLowerCase()}-mindstamp`}
            >
              <Image height="17px" src="/assets/mindstamp_logo.png" />
            </MindstampButton>
          )}
        </>
      );
    }

    default: {
      return (
        <Button
          circular
          onClick={() => onClick(action.id)}
          loading={loading}
          disabled={action.disabled}
          size="small"
          data-testid={`btn-add-${action.id.toLowerCase()}`}
        >
          <Icon name={'plus'} />
          {action.icon && <Icon color="orange" name={action.icon} />}
          <Text color="primary">{action.title}</Text>
        </Button>
      );
    }
  }
};

class ActionList extends Component {
  static propTypes = {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.string,
        disabled: PropTypes.bool,
      })
    ),
    appConfig: PropTypes.object,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    actions: [],
    onClick: () => {},
  };

  render() {
    const { actions, onClick, loading, appConfig } = this.props;
    return (
      <Flex flexWrap="wrap" mx={2}>
        {actions.map((action) => (
          <Flex m={1} key={action.id}>
            {renderSwitch(action, loading, onClick, {
              enabledMindstamp: appConfig?.courses?.lessons?.enableMindstampAsset,
              enabledByteArkApi: appConfig?.enabledByteArkApi,
              enabledVimeoApi: appConfig?.enabledVimeoApi,
              enabledWistiaApi: appConfig?.enabledWistiaApi,
            })}
          </Flex>
        ))}
      </Flex>
    );
  }
}

export default compose(withAppConfig({ configProp: 'appConfig' }))(ActionList);
