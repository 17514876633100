import gql from 'graphql-tag';

import { DetailsMaterialFragment } from '../query/Material.query';

export const MATERIAL_TYPE = {
  SLIDES: 'SLIDES',
  VIDEO: 'VIDEO',
  EXTERNAL: 'EXTERNAL',
  YOUTUBE: 'YOUTUBE',
  ARTICLE: 'ARTICLE',
  LIVE_VIDEO: 'LIVE_VIDEO',
  LIVECODING: 'LIVECODING',
  PDF: 'PDF',
  ASSIGNMENT: 'ASSIGNMENT',
  OTHER: 'OTHER',
};

export const VIDEO_PROVIDER = {
  BYTEARK: 'BYTEARK',
  YOUTUBE: 'YOUTUBE',
  VIMEO: 'VIMEO',
};

export const genCourseAssetInput = (
  title = '',
  description,
  isMainContent = true,
  downloadable = false,
  content = {}
) => {
  return {
    title,
    description,
    isMainContent,
    downloadable,
    content,
  };
};

export const genCourseAssetEditInput = (
  title,
  description,
  isMainContent,
  downloadable,
  content
) => {
  return {
    title,
    description,
    isMainContent,
    downloadable,
    content,
  };
};

export const genMultipleCourseAssetInput = (inputData, isMainContent, downloadable) => {
  return inputData.map((item) => {
    const { content, title, description } = item;
    return genCourseAssetInput(title, description, isMainContent, downloadable, content);
  });
};

export const editMaterial = gql`
  mutation editMaterial(
    $version: COURSE_VERSION!
    $assetId: ID!
    $type: MATERIAL_TYPE!
    $data: CourseAssetEditInput
    $videoData: CourseAssetVideoEditInput
    $articleData: CourseAssetArticleEditInput
    $externalData: CourseAssetExternalEditInput
    $youtubeData: CourseAssetYoutubeEditInput
    $quizSimpleData: CourseAssetQuizSimpleEditInput
    $liveVideoData: CourseAssetLiveVideoEditInput
    $liveCodingData: CourseAssetLiveCodingEditInput
    $otherData: CourseAssetOtherEditInput
    $slidesData: SlidesMaterialEditInput
    $noteboardData: CourseAssetNoteboardEditInput
    $pdfData: CourseAssetPDFEditInput
    $assignmentData: CourseAssetAssignmentEditInput
  ) {
    updateCourseMaterial(
      version: $version
      assetId: $assetId
      type: $type
      data: $data
      videoData: $videoData
      articleData: $articleData
      externalData: $externalData
      youtubeData: $youtubeData
      quizSimpleData: $quizSimpleData
      liveVideoData: $liveVideoData
      liveCodingData: $liveCodingData
      otherData: $otherData
      slidesData: $slidesData
      noteboardData: $noteboardData
      pdfData: $pdfData
      assignmentData: $assignmentData
    ) {
      id
      ...DetailsMaterialFragment
    }
  }
  ${DetailsMaterialFragment}
`;

export const deleteMaterial = gql`
  mutation deleteMaterial($version: COURSE_VERSION!, $assetId: ID!) {
    deleteCourseMaterial(version: $version, assetId: $assetId)
  }
`;

export const addMaterial = gql`
  mutation addMaterial(
    $version: COURSE_VERSION!
    $courseItemId: ID!
    $type: MATERIAL_TYPE!
    $data: CourseAssetInput
    $videoData: CourseAssetVideoInput
    $articleData: CourseAssetArticleInput
    $externalData: CourseAssetExternalInput
    $youtubeData: CourseAssetYoutubeInput
    $quizSimpleData: CourseAssetQuizSimpleInput
    $liveVideoData: CourseAssetLiveVideoInput
    $liveCodingData: CourseAssetLiveCodingInput
    $otherData: CourseAssetOtherInput
    $slidesData: SlidesMaterialInput
    $noteboardData: CourseAssetNoteboardInput
    $pdfData: CourseAssetPDFInput
    $assignmentData: CourseAssetAssignmentInput
  ) {
    addCourseMaterial(
      version: $version
      courseItemId: $courseItemId
      type: $type
      data: $data
      videoData: $videoData
      articleData: $articleData
      externalData: $externalData
      youtubeData: $youtubeData
      quizSimpleData: $quizSimpleData
      liveVideoData: $liveVideoData
      liveCodingData: $liveCodingData
      noteboardData: $noteboardData
      otherData: $otherData
      slidesData: $slidesData
      pdfData: $pdfData
      assignmentData: $assignmentData
    ) {
      id
      ...DetailsMaterialFragment
    }
  }
  ${DetailsMaterialFragment}
`;

export const addMultipleMaterials = gql`
  # @TODO: Recheck this
  mutation addMultipleMaterials($type: MATERIAL_TYPE!, $data: [MultipleCourseAssetInput!]) {
    addMultipleCourseMaterials(type: $type, data: $data) {
      id
      ...DetailsMaterialFragment
    }
  }
  ${DetailsMaterialFragment}
`;

export const getAssetFilesUploadUrl = gql`
  mutation getAssetFilesUploadUrl(
    $version: COURSE_VERSION!
    $courseItemId: ID!
    $filename: String!
    $contentType: String!
  ) {
    getAssetFilesUploadUrl(
      version: $version
      courseItemId: $courseItemId
      filename: $filename
      contentType: $contentType
    ) {
      url
    }
  }
`;
