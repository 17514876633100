import React, { Component, Fragment } from 'react';
import { Icon } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import AllUsers from '../../Containers/AllUsers.gql';
import NewUserModal from '../../Containers/NewUserModal';
import ControlledSearchInput from '../../Components/ControlledSearchInput';
import { SemanticButton as Button } from '../../../../Components/Base/Button';

const Toolbar = ({ onSearchUser }) => (
  <Flex justifyContent="space-between" alignItems="center">
    <ControlledSearchInput onSearch={onSearchUser} />
    <Box />
    <NewUserModal
      trigger={
        <Button primary circular>
          <Icon name="plus" size="small" />
          Add User
        </Button>
      }
    />
  </Flex>
);

export default class UsersRoute extends Component {
  static propTypes = {};

  state = {
    searchText: null,
  };

  handleSearchUser = (searchObj) => {
    this.setState({ searchText: searchObj.searchText });
  };

  render() {
    const { searchText } = this.state;
    return (
      <Fragment>
        <Toolbar onSearchUser={this.handleSearchUser} />
        <Box mb={2} />
        <AllUsers searchText={searchText} />
      </Fragment>
    );
  }
}
