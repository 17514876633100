import React from 'react';
import PropTypes from 'prop-types';
import { Header, Grid as BaseGrid } from 'semantic-ui-react';
import styled from 'styled-components';

const Grid = styled(BaseGrid)`
  &.ui.grid > .column:not(.row) {
    padding-left: 3em;
  }
`;

const SKUHeader = ({ title, SKUCode, editComp }) => {
  return (
    <Grid>
      <Grid.Column width={14}>
        <Grid>
          <Grid.Row>
            <Header size="huge" pl={3}>
              {title}
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Header size="large" pl={3}>
              {SKUCode}
            </Header>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={2}>{editComp}</Grid.Column>
    </Grid>
  );
};

SKUHeader.propTypes = {
  title: PropTypes.string,
  SKUCode: PropTypes.string,
};

export default SKUHeader;
