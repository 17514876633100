import gql from 'graphql-tag';

export const classroomReport = gql`
  query reportClassroom(
    $search: ReportClassroomSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    reportClassroom(search: $search, paging: $paging, order: $order) {
      classrooms {
        userId
        firstName
        lastName
        email
        organizationName
        courseId
        courseTitle
        completionRate
        firstEnteredAt
        lastEnteredAt
        finishedAt
      }
      total
    }
  }
`;

export const userExamReport = gql`
  query reportUserExam($search: ReportUserExamSearchInput, $paging: PageInput, $order: OrderInput) {
    reportUserExam(search: $search, paging: $paging, order: $order) {
      userExams {
        sourceExamId
        examTitle
        examStartedAt
        examFinishedAt
        sourceCourseId
        courseTitle
        courseExamType
        userId
        firstName
        lastName
        organizationId
        organizationName
        score
        fullScore
        isPassed
        passedExamAt
        expirationDate
      }
      total
    }
  }
`;
export const userExamQuestionReport = gql`
  query reportExamQuestion(
    $search: ReportExamQuestionSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    reportExamQuestion(search: $search, paging: $paging, order: $order) {
      examQuestions {
        examTitle
        courseTitle
        questionTitle
        questionTags
        questionType
        choices {
          detail
        }
        correctResponse {
          detail
        }
        numberOfShowUp
        totalCorrect
        totalIncorrect
      }
      total
    }
  }
`;

export const certificateReport = gql`
  query reportUserCertificate(
    $search: ReportUserCertificateSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    reportUserCertificate(search: $search, paging: $paging, order: $order) {
      userCertificates {
        userCertId
        certificateId
        certificateName
        certificateCode
        userId
        firstName
        lastName
        organizationId
        organizationName
        issuedAt
        expiredAt
      }
      total
    }
  }
`;

export const userReport = gql`
  query reportUser($search: ReportUserSearchInput, $paging: PageInput, $order: OrderInput) {
    reportUser(search: $search, paging: $paging, order: $order) {
      users {
        userId
        firstName
        lastName
        email
        organizationId
        organizationName
        gender: additional1
        position: additional2
        mobileNo: additional4
        emailVerified
        createdAt
        activeCertificatesNum
        activeCertificates {
          name
          issuedAt
        }
        finishedCoursesNum
        finishedCourses {
          name
          finishedAt
        }
        passedExamsNum
        passedExams {
          name
          passedAt
        }
      }
      total
    }
  }
`;

export const successSalesOrdersReport = gql`
  query reportSuccessSalesOrders(
    $search: SFBoPaymentExportSuccessSalesOrderInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoPaymentExportSuccessSalesOrderReport(search: $search, paging: $paging, order: $order) {
      base64ExportSuccessSalesOrderReportFile
    }
  }
`;
