import gql from 'graphql-tag';

export const MutationResponseFragment = gql`
  fragment MutationResponseFragment on AnnouncementMutationResponse {
    code
    success
    message
  }
`;

export const AnnouncementFragment = gql`
  fragment AnnouncementFragment on AnnouncementDataMutationResponse {
    announcement {
      title
      category
      platforms
      detail {
        content
        type
        title
        description
        rawContent
        utmCampaignId
        utmCampaignContent
        imageUrl
        audienceFile {
          name
          originalFilename
          type
          relationType
          provider
          container
        }
      }
      announcedBy
    }
  }
`;

export const createAnnouncement = gql`
  mutation createAnnouncement($announcementData: AnnouncementCreateInput!) {
    createAnnouncement(announcementData: $announcementData) {
      ...MutationResponseFragment
      ...AnnouncementFragment
    }
  }
  ${MutationResponseFragment}
  ${AnnouncementFragment}
`;

export const deleteAnnouncement = gql`
  mutation deleteAnnouncement($announcementId: ID!) {
    deleteAnnouncement(announcementId: $announcementId) {
      ...MutationResponseFragment
      ...AnnouncementFragment
    }
  }
  ${MutationResponseFragment}
  ${AnnouncementFragment}
`;

export const updateAnnouncement = gql`
  mutation updateAnnouncement($announcementId: ID!, $announcementData: AnnouncementUpdateInput!) {
    updateAnnouncement(announcementId: $announcementId, announcementData: $announcementData) {
      ...MutationResponseFragment
      ...AnnouncementFragment
    }
  }
  ${MutationResponseFragment}
  ${AnnouncementFragment}
`;
