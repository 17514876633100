import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Box } from '@skooldio/skooldio-ui-components-core';

const BGImage = styled(Box)`
  ${({ imageUrl, bgPosition, bgSize, bgRepeat }) =>
    imageUrl &&
    css`
      background-image: ${`url("${imageUrl}")`};
      background-position: ${bgPosition};
      background-size: ${bgSize};
      background-repeat: ${bgRepeat};
    `}
`;

const BackgroundImage = ({ imageUrl, bgPosition, bgSize, bgRepeat }) => (
  <BGImage imageUrl={imageUrl} bgPosition={bgPosition} bgSize={bgSize} bgRepeat={bgRepeat} />
);

BackgroundImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  bgPosition: PropTypes.string,
  bgSize: PropTypes.string,
  bgRepeat: PropTypes.string,
};

BackgroundImage.defaultProps = {
  bgPosition: '0% 0%',
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
};

export default BackgroundImage;
