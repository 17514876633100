import genUniqId from 'lodash/uniqueId';

export const resolveRawData = (type, rawData, resolveFn, mapperFn) => {
  const data = resolveFn ? resolveFn(rawData) : rawData[type][type];
  return data.map((singleData) => {
    const mappedData = mapperFn ? mapperFn(singleData) : singleData;
    if (mappedData.id) return mappedData;
    return {
      ...mappedData,
      id: genUniqId(),
    };
  });
};
