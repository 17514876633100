import React from 'react';
import PropTypes from 'prop-types';

import SKUGalleryEditor from '../SKUGalleryEditor';
import { getBundleGallery } from '../../GraphQL/query/Products.query';
import { updateBundleImagesMutation } from '../../GraphQL/mutation/Products.mutation';

function BundleGalleryEditor(props) {
  const { bundle } = props;
  return (
    <SKUGalleryEditor
      {...props}
      dataQuery={getBundleGallery}
      dataQueryName={'sfBoProductBundle'}
      variableQuery={{ SKUCode: bundle.SKUCode }}
      handleMutation={updateBundleImagesMutation}
      getHandleVariables={(data) => ({
        id: bundle.id,
        bundleImages: { images: data },
      })}
      refetchQueries={[{ query: getBundleGallery, variables: { SKUCode: bundle.SKUCode } }]}
    />
  );
}

BundleGalleryEditor.propTypes = {
  bundle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default BundleGalleryEditor;
