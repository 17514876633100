import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import { fileImport } from 'GraphQL/LSP/mutation/FileImport.mutation';
import { fileImportJobs } from 'GraphQL/LSP/query/FileImport.query';
import { getAllSchoolGrades, getAllSchoolProgrammes } from 'GraphQL/LSP/query/Education.query';
import CheckCloseComponent from 'Util/CheckDefinedComponent';
import Loading from 'Components/Loading';

import { FILE_TYPE } from '../../common';

import DataForm from './DataForm';

class NewDataMutation extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    priceId: PropTypes.string,
    type: PropTypes.oneOf(Object.values(FILE_TYPE)).isRequired,
  };

  static defaultProps = {
    onComplete: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      schoolGradeData: null,
      schoolProgrammeData: null,
    };
  }

  componentDidMount() {
    const { type, client } = this.props;
    if (type === FILE_TYPE.STUDENT) {
      Promise.all([
        client.query({
          query: getAllSchoolGrades,
        }),
        client.query({
          query: getAllSchoolProgrammes,
        }),
      ]).then((data) => {
        const { data: schoolGradeData } = data[0];
        const { data: schoolProgrammeData } = data[1];
        this.setState({
          schoolGradeData: schoolGradeData.boAllSchoolGrades,
          schoolProgrammeData: schoolProgrammeData.boAllSchoolProgrammes,
        });
      });
    }
  }

  convertFormValue = (form) => {
    const data = {
      type: this.props.type,
      ...form,
      originalFilename: form.file.originalFilename,
      file: {
        key: form.file.key,
        bucket: form.file.bucket,
      },
    };
    return data;
  };

  render() {
    const { variables, type } = this.props;
    const { schoolGradeData, schoolProgrammeData } = this.state;
    if (type === FILE_TYPE.STUDENT && (!schoolGradeData || !schoolProgrammeData)) {
      return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
    }
    return (
      <Mutation mutation={fileImport} refetchQueries={[{ query: fileImportJobs, variables }]}>
        {(createData, { loading, error, data }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent checkDefinedObject={data} onDefined={this.props.onComplete} />
              <DataForm
                type={type}
                onCreateNewData={(form) =>
                  createData({ variables: { input: this.convertFormValue(form) } })
                }
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                schoolGradeData={schoolGradeData}
                schoolProgrammeData={schoolProgrammeData}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default withApollo(NewDataMutation);
