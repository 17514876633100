import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import { getSingleCourseV1 } from 'GraphQL/query/Courses.query';
import { editCourse } from 'GraphQL/mutation/Courses.mutation';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';
import CreateEditForm from 'Containers/CreateEditForm';
import withAppConfig from 'Util/hoc/withAppConfig';
import { transformCourseEditorErrorToMessage } from 'Routes/Courses/Domain/ErrorHandler';

import AddCourseForm from '../../../../Components/AddCourseForm';

const convertCourseToForm = (course = {}) => ({
  ...course,
  ...course.meta,
  instructorIds: course.instructors ? course.instructors.map(({ id }) => parseInt(id)) : [],
  themeColor: course?.meta?.themeColor?.primaryColor ?? undefined,
  teamIds: course?.teams ? course.teams.map(({ id }) => id) : [],
});

const DetailsEditor = ({ courseId, product, version, appConfig }) => {
  return (
    <Box>
      <CreateEditForm
        dataQuery={getSingleCourseV1}
        editMutation={editCourse}
        isEdit={true}
        id={courseId}
        getQueryVariables={({ id }) => ({
          courseId: id,
          version: productCourseVersionResolver(product, version),
          enabledContentTeam: appConfig?.enabledContentTeam,
        })}
        getEditVariables={(form, { id }) => ({
          courseId: id,
          version: productCourseVersionResolver(product, version),
          courseDesc: { ...form, themeColor: { primaryColor: form.themeColor } },
          enabledContentTeam: appConfig?.enabledContentTeam,
        })}
        transformErrorToMessage={transformCourseEditorErrorToMessage}
      >
        {({ data, onSubmit }) => {
          return (
            <AddCourseForm
              initialData={data && convertCourseToForm(data.course)}
              onSubmit={onSubmit}
            />
          );
        }}
      </CreateEditForm>
    </Box>
  );
};

DetailsEditor.propTypes = {
  courseId: PropTypes.string,
  product: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export default withAppConfig({ configProp: 'appConfig' })(DetailsEditor);
