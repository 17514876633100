import React from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import { TAG_CATEGORY, TAG_CATEGORY_LABEL } from '../constants';

/**
 * This function return category dropdown-options that exclude "COURSE_CODE" and "ASSIGNEE".
 * @return {{text: string, value: string}[]}
 */
const getCategoryOptions = () => {
  const categoryEntries = Object.entries(TAG_CATEGORY);

  const filteredCategories = categoryEntries.filter(
    ([_, value]) => value !== TAG_CATEGORY.COURSE_CODE && value !== TAG_CATEGORY.ASSIGNEE
  );

  const categoryOptions = filteredCategories.map(([key, value]) => ({
    text: TAG_CATEGORY_LABEL[key],
    value,
  }));

  return categoryOptions;
};

const generateFormFields = () => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ชื่อ Tag',
    inputName: 'name',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'ประเภท Tag',
    inputName: 'category',
    inputProps: {
      required: true,
      options: getCategoryOptions(),
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
    inputLabel: 'Description',
    inputName: 'description',
    inputProps: {
      required: false,
    },
  },
];

const TagForm = (props) => {
  return (
    <FormGenerator
      fields={generateFormFields()}
      compact
      showCancel
      isRequiredConfirmation
      submitText={'Save'}
      submitContent={`This Tag will be saved.`}
      cancelText="Cancel"
      {...props}
    />
  );
};

export default TagForm;
