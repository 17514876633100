import React, { useState, Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Link, withRouter } from 'react-router-dom';
import { Button, Icon, Container, Label } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import apolloClient from 'src/GraphQL';
import Filter from 'src/Util/Filter';
import { Flex, Text } from 'Components/Base';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import ConfirmationButton from 'Components/ConfirmationButton';
import QueryTable from 'GraphQL/util/QueryTable';
import { getExams, getTags } from 'src/GraphQL/query/Exam.query';
import { duplicateExam, deleteExam } from 'src/GraphQL/mutation/Exam.mutation';

import { CourseCodeSearch } from '../Components';
import { EXAM_STATUS, EXAM_TAG_CATEGORY } from '../Components/constants';

import AddExamModal from './AddExamModal.view';

const mapTagsToOptions = (tags) => {
  return tags.map(({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  }));
};

const filterTagsByCategory = (tags = [], category) => {
  return tags.filter((tag) => tag.category === category);
};

const getColumns = (examTags, onDuplicate, onDelete) => {
  const courseCodeTags = filterTagsByCategory(examTags, EXAM_TAG_CATEGORY.COURSE_CODE);
  const examTypeTags = filterTagsByCategory(examTags, EXAM_TAG_CATEGORY.EXAM_TYPE);
  return [
    {
      Header: 'ID',
      accessor: 'id',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      width: 200,
      fromParam: (value) => String(value),
      toParam: (value) => String(value),
    },
    {
      Header: 'ชื่อชุดคำถาม',
      accessor: 'title',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      width: 200,
      Cell: function ExamTitle({ original }) {
        return <Link to={{ pathname: `/exams/${original.id}` }}>{original.title}</Link>;
      },
      fromParam: (value) => String(value),
      toParam: (value) => String(value),
    },
    {
      Header: 'Course Code',
      accessor: 'courseCodes',
      type: COLUMN_TYPE.CUSTOM,
      isSearchAble: true,
      sortable: false,
      CustomSearchComponent: (props) => (
        <CourseCodeSearch options={mapTagsToOptions(courseCodeTags)} {...props} />
      ),
      Cell: function ExamCourseCodes({ original }) {
        const { tags } = original;
        const courseCodes = filterTagsByCategory(tags, EXAM_TAG_CATEGORY.COURSE_CODE);
        return (
          <Flex flexWrap="wrap" m={-1}>
            {courseCodes.map((courseCode) => (
              <Flex key={courseCode.id} p={1}>
                <Label>{courseCode.name}</Label>
              </Flex>
            ))}
          </Flex>
        );
      },
      fromParam: (value) => JSON.parse(value),
      toParam: (value) => JSON.stringify(value),
    },
    {
      Header: 'ประเภท',
      accessor: 'examType',
      type: COLUMN_TYPE.ENUM,
      width: 100,
      isSearchAble: true,
      sortable: false,
      enumOptions: mapTagsToOptions(examTypeTags),
      Cell: function ExamType({ original }) {
        const { tags } = original;
        const examType = filterTagsByCategory(tags, EXAM_TAG_CATEGORY.EXAM_TYPE);
        const { name } = examType[0] || {};
        return name;
      },
      fromParam: (value) => String(value),
      toParam: (value) => String(value),
    },
    {
      Header: 'สถานะ',
      accessor: 'status',
      type: COLUMN_TYPE.ENUM,
      isSearchAble: true,
      width: 100,
      enumOptions: [
        { key: EXAM_STATUS.PUBLISHED, value: EXAM_STATUS.PUBLISHED, text: EXAM_STATUS.PUBLISHED },
        { key: EXAM_STATUS.DRAFT, value: EXAM_STATUS.DRAFT, text: EXAM_STATUS.DRAFT },
        { key: EXAM_STATUS.CANCELLED, value: EXAM_STATUS.CANCELLED, text: EXAM_STATUS.CANCELLED },
      ],
      fromParam: (value) => String(value),
      toParam: (value) => String(value),
    },
    {
      Header: 'Action',
      accessor: 'createdAt',
      isSearchAble: false,
      width: 120,
      Cell: function ExamActionCell({ original: exam }) {
        return (
          <Fragment>
            <Flex justifyContent="center">
              <Button.Group>
                <Button basic icon style={{ padding: '8.25px' }}>
                  <Link to={{ pathname: `/exams/${exam.id}` }}>
                    <Icon name="edit" color="grey" style={{ margin: 0 }} />
                  </Link>
                </Button>
                <ConfirmationButton
                  headerText={`Duplicate Exam?`}
                  confirmText="Duplicate"
                  contentText={`This Exam will be duplicated into "Copy of ${exam.title}".`}
                  onConfirm={() => onDuplicate(exam.id)}
                >
                  <Icon name="copy outline" />
                </ConfirmationButton>
                {exam.status === EXAM_STATUS.DRAFT && (
                  <ConfirmationButton
                    headerText={`Delete Exam?`}
                    confirmText="Delete"
                    contentText={`Do you want to delete this exam "${exam.title}"?`}
                    onConfirm={() => onDelete(exam.id)}
                  >
                    <Icon name="trash alternate outline" color="red" />
                  </ConfirmationButton>
                )}
              </Button.Group>
            </Flex>
          </Fragment>
        );
      },
    },
  ];
};

const AllExam = ({ location, history }) => {
  const [search, setSearch] = useState({});
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [order, setOrder] = useState({ field: 'createdAt', type: 'DESC' });

  const { loading: isTagsLoading, error: tagsError, data: tagsData } = useQuery(getTags, {
    variables: {
      search: {
        category: [EXAM_TAG_CATEGORY.COURSE_CODE, EXAM_TAG_CATEGORY.EXAM_TYPE],
      },
    },
  });
  const examTags = tagsData?.examTags?.examTags ?? [];

  const handlePageChange = (page) => {
    Filter.handlePageChange(page, paging.pageSize, setPaging);
  };
  const handlePageSizeChange = (pageSize) => {
    Filter.handlePageSizeChange(pageSize, setPaging);
  };
  const handleSearchChange = (search) => {
    Filter.handleSearchChange(search, paging, setSearch, setPaging);
  };
  const handleSortedChange = (sorted) => {
    Filter.handleSortedChange(sorted, paging.pageSize, setOrder, setPaging);
  };
  const handleDuplicate = async (examId) => {
    try {
      await apolloClient.mutate({
        mutation: duplicateExam,
        variables: { examId },
        refetchQueries: [
          {
            query: getExams,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (examId) => {
    try {
      await apolloClient.mutate({
        mutation: deleteExam,
        variables: { examId },
        refetchQueries: [
          {
            query: getExams,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = getColumns(examTags, handleDuplicate, handleDelete);

  return (
    <Container>
      <Flex justifyContent="space-between" my={2}>
        <Text.Header>ExamList</Text.Header>
        <AddExamModal variables={{ search, paging, order }} />
      </Flex>
      <QueryTable
        type="examExams"
        columns={columns}
        query={getExams}
        paging={paging}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        search={search}
        onSearchChange={handleSearchChange}
        order={order}
        onSortedChange={handleSortedChange}
        resolveData={(data) => data.examExams.exams}
      />
    </Container>
  );
};

export default withRouter(AllExam);
