import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import CreateEditForm from 'Containers/CreateEditForm';

import { getInstructor, getInstructors } from '../../GraphQL/query/Products.query';
import StatefulModal from '../../../../Components/Modal/StatefulModal';
import {
  createInstructorMutation,
  updateInstructorMutation,
} from '../../GraphQL/mutation/Products.mutation';

import InstructorForm from './InstructorForm';

export default class InstructorModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
    variables: PropTypes.object,
  };
  static defaultProps = {
    isEdit: false,
  };

  formatMarkdown = (form) => {
    const { description, expertDetails } = form;
    if (description && description.type && !description.content) {
      form.description.content = '';
    }
    if (expertDetails && expertDetails.type && !expertDetails.content) {
      form.expertDetails.content = '';
    }
    return form;
  };

  render() {
    const { isEdit, id, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'Edit Instructor' : 'Create New Instructor'}</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getInstructor}
                createMutation={createInstructorMutation}
                createRefetchQueries={[
                  {
                    query: getInstructors,
                    variables,
                  },
                ]}
                editMutation={updateInstructorMutation}
                isEdit={isEdit}
                id={id}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  data: this.formatMarkdown(form),
                })}
                getCreateVariables={(form) => ({
                  data: this.formatMarkdown(form),
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <InstructorForm
                      initialData={data && data.sfBoProductInstructor}
                      onSubmit={onSubmit}
                    />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
