import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Text } from 'Components/Base';
import {
  getUserAssignment,
  downloadSignedURL,
  getUserAssignmentCourseSummary,
} from 'GraphQL/query/Courses.query';

import { getCourseSection, getUserAssignmentReport } from '../../utils';
import Attachment from '../Components/Attachment';

function getAssignmentDetail(params, course, userAssignment) {
  const section = getCourseSection(course, params.sectionId);
  const userAssignmentReport = getUserAssignmentReport(
    course,
    params.sectionId,
    params.subsectionId
  );

  return {
    sectionTitle: section?.title,
    assignmentTitle: userAssignmentReport?.title,
  };
}

const UserAssignmentDetail = () => {
  const { params } = useRouteMatch();
  const userAssignmentId = params?.userAssignmentId;
  const courseId = params?.courseId;

  const { data: queryData } = useQuery(getUserAssignment, {
    variables: { userAssignmentId: userAssignmentId },
    skip: !userAssignmentId,
  });
  const { fullName, content, studentComment } = queryData?.userAssignmentById ?? {};

  const { data: queryUACS } = useQuery(getUserAssignmentCourseSummary, {
    variables: { sourceCourseId: courseId },
    skip: !courseId,
  });
  const { data: queryUA } = useQuery(getUserAssignment, {
    variables: { userAssignmentId: userAssignmentId },
    skip: !userAssignmentId,
  });

  const { refetch } = useQuery(downloadSignedURL, { skip: true });
  const getDownloadFile = async () => {
    const { data } = await refetch({ userAssignmentId: userAssignmentId });
    if (data?.downloadSignedURL?.url) {
      const downloadElement = document.createElement('a');
      downloadElement.setAttribute('href', data.downloadSignedURL.url);
      downloadElement.click();
      downloadElement.remove();
    }
  };

  const detail = getAssignmentDetail(
    params,
    queryUACS?.userAssignmentCourseSummary,
    queryUA?.userAssignmentById
  );

  return (
    <>
      <Text.Header pl={3}>{fullName}</Text.Header>
      <Attachment
        attachmentName={content?.fileName}
        downloadFile={getDownloadFile}
        url={content?.url}
        note={studentComment}
        mt={2}
        sectionTitle={detail.sectionTitle}
        assignmentTitle={detail.assignmentTitle}
      />
    </>
  );
};

export default UserAssignmentDetail;
