import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';

import NewPriceContainer from './NewPrice.gql';

export default class PriceModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    priceId: PropTypes.string,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { isEdit, priceId, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'Edit Price Information' : 'Create New Price'}</Modal.Header>
            <Modal.Content>
              <NewPriceContainer
                variables={variables}
                isEdit={isEdit}
                priceId={priceId}
                onComplete={closeModal}
                onCancelled={closeModal}
                {...this.props}
              />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
