import React from 'react';
import { Text as RText } from 'rebass';
import { withTheme } from 'styled-components';

import { FONT_SIZE } from '../../Theme';

// const Text = RText;

const Text = (props) => <RText color="greyscale.grey1" lineHeight="1.35" {...props} />;

const Title = withTheme(({ heading, ...props }) => (
  <Text
    color={props.theme.colors.primary}
    fontSize={heading === true ? FONT_SIZE.semilarge : undefined}
    {...props}
  />
));

const HintText = ({ color, ...props }) => (
  <Text color={color ? color : 'greyscale.grey3'} fontSize={FONT_SIZE.xsmall} {...props} />
);

const Score = ({ color, ...props }) => (
  <Text color={color ? color : 'greyscale.grey3'} fontSize={FONT_SIZE.xlarge} {...props} />
);

const PaymentTitle = ({ color, ...props }) => (
  <Text
    color={color ? color : 'greyscale.grey1'}
    fontSize={FONT_SIZE.semilarge}
    fontWeight="bold"
    {...props}
  />
);

const SubHeader = (props) => <Text fontSize={FONT_SIZE.xsmall} fontWeight="light" {...props} />;

const Header = ({ color, ...props }) => (
  <Text color={color ? color : 'black'} fontSize={FONT_SIZE.large} fontWeight="bold" {...props} />
);

export { Title, HintText, Score, SubHeader };

Text.Title = Title;
Text.HintText = HintText;
Text.Score = Score;
Text.PaymentTitle = PaymentTitle;
Text.SubHeader = SubHeader;
Text.Header = Header;

export default Text;
