import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';

class ControlledSearchInput extends Component {
  handleValidSubmit = (formValue) => {
    this.props.onSearch(formValue);
  };
  render() {
    return (
      <Form onValidSubmit={this.handleValidSubmit}>
        <Input
          name="searchText"
          action={{ icon: 'search' }}
          placeholder="First name, last name, email, ref. id"
        />
      </Form>
    );
  }
}

ControlledSearchInput.propTypes = {
  onSearch: PropTypes.func,
};

ControlledSearchInput.defaultProps = {
  onSearch: () => {},
};

export default ControlledSearchInput;
