import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import delve from 'Util/Delve';

import MapSearchInput from './MapSearchInput';

const convertServerDataToFormData = (data = {}) => {
  const venueLocation = delve(data, 'venueLocation', {});
  if (!venueLocation) return null;

  const { venueDisplayName } = data || {};
  const { googlePlaceId: placeId, displayAddress, note, ...restLocationData } = venueLocation;

  const placeData = {
    ...restLocationData,
    placeId,
    name: venueDisplayName,
  };

  return {
    venueDisplayName,
    displayAddress,
    placeData,
    note,
  };
};

const getMapEditor = (apiKey) => {
  return <MapSearchInput apiKey={apiKey} />;
};

const converDataToAPI = (formData) => {
  const { venueDisplayName, displayAddress, placeData, note } = formData;
  const {
    placeId: googlePlaceId,
    addressLocality,
    addressRegion,
    location,
    streetAddress,
    postalCode,
    addressCountry,
  } = placeData;

  return {
    venueDisplayName: venueDisplayName,
    venueLocation: {
      googlePlaceId,
      displayAddress,
      addressLocality,
      addressRegion,
      location,
      streetAddress,
      postalCode,
      addressCountry,
      note,
    },
  };
};

const fields = ({ apiKey }) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Venue Display Name',
    inputName: 'venueDisplayName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Venue Display Address',
    inputName: 'displayAddress',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Notes',
    inputName: 'note',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Venue Maps',
    inputName: 'placeData',
    customInput: () => getMapEditor(apiKey),
  },
];

const VenueEditorForm = ({ onSubmit, apiKey, initialData, ...restProps }) => {
  const handleValidation = (fieldValue, isChanged) => {
    return {};
  };
  const handleSubmit = (formValue) => {
    const data = converDataToAPI(formValue);
    onSubmit(data);
  };
  return (
    <FormGenerator
      fields={fields({ apiKey })}
      customValidation={handleValidation}
      onSubmit={handleSubmit}
      submitText={'Update'}
      cancelText="Cancel"
      showCancel
      initialData={convertServerDataToFormData(initialData)}
      {...restProps}
    />
  );
};
VenueEditorForm.propTypes = {
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
};

export default VenueEditorForm;
