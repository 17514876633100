import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox } from 'semantic-ui-react';

import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import RichTextEditor from '../../../RichTextEditor';
import { MARKDOWN_EDITOR_TYPE, CHOICE_PREFIX } from '../constant';

const styles = {
  root: {
    marginTop: 18,
  },

  radioGroup: {
    display: 'flex',
    marginBottom: 18,
  },

  radio: {
    marginLeft: 4,
    marginRight: 12,
  },
};

const MultipleSelect = ({
  answerChoices,
  onChecked,
  onAnswerChange,
  url,
  examId,
  editorContentFormat,
  maxFileSize,
}) => {
  const choicePrefix = `${CHOICE_PREFIX.EN} `;
  let answerRow = [];
  if (answerChoices) {
    answerChoices.map((answer, index) =>
      answerRow.push(
        <Grid.Row key={`checkbox_${index}`}>
          <Grid.Column width={6} textAlign="right">
            <Checkbox
              name="terms"
              label={`${choicePrefix} ${index + 1}`}
              onChange={() => onChecked(index)}
              checked={answer.isAnswer}
              style={styles.radio}
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <RichTextEditor
              onChange={(data) => onAnswerChange(data, index)}
              id={`answer_${index}`}
              value={answer.value}
              contentFormat={editorContentFormat}
              returnValueAsString={false}
              customControls={[
                {
                  type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                  data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                  maxFileSize: maxFileSize,
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      )
    );
  }

  return answerRow;
};

MultipleSelect.propTypes = {
  answerChoices: PropTypes.arrayOf(
    PropTypes.shape({
      value: RichTextEditor.propTypes.value,
      isAnswer: PropTypes.bool,
    })
  ),
  onAnswerChange: PropTypes.func,
  onChecked: PropTypes.func,
  maxFileSize: PropTypes.number,
};

MultipleSelect.defaultProps = {
  answerChoices: [],
  onAnswerChange: () => {},
  onChecked: () => {},
};

export default MultipleSelect;
