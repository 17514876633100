import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import CourseSection from './CourseSection';

class CourseOutline extends Component {
  static propTypes = {
    courseId: PropTypes.string,
    contents: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      ordering: PropTypes.number,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          subsections: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              title: PropTypes.string,
              ordering: PropTypes.number,
            })
          ),
        })
      ),
    }),
    product: PropTypes.string,
    version: PropTypes.string,
    isLoading: PropTypes.bool,
    onDeleteSection: PropTypes.func,
    onDeleteSubSection: PropTypes.func,
    onUpdateSectionMeta: PropTypes.func,
    onUpdateSubSectionMeta: PropTypes.func,
    onReorderCourseItems: PropTypes.func,
    onLoading: PropTypes.func,
    videoManagementButton: PropTypes.func,
  };

  render() {
    const {
      courseId,
      contents,
      product,
      version,
      isLoading,
      onLoading,
      onDeleteSection,
      onDeleteSubSection,
      onUpdateSectionMeta,
      onUpdateSubSectionMeta,
      onReorderCourseItems,
      videoManagementButton,
    } = this.props;
    return (
      <Fragment>
        <CourseSection
          courseId={courseId}
          contents={contents}
          isLoading={isLoading}
          onLoading={onLoading}
          onUpdateSectionMeta={onUpdateSectionMeta}
          onUpdateSubSectionMeta={onUpdateSubSectionMeta}
          onReorderCourseItems={onReorderCourseItems}
          onDeleteSection={onDeleteSection}
          onDeleteSubSection={onDeleteSubSection}
          product={product}
          version={version}
          videoManagementButton={videoManagementButton}
        />
        {/* <StatefulModal trigger={<div />} defaultOpen /> */}
      </Fragment>
    );
  }
}

export default CourseOutline;
