import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import { Text } from 'Components/Base';

const Row = ({ data, open, title, updatePermissionList }) => {
  const [checkBoxesChecked, setCheckBoxesChecked] = useState(() => {
    const defaultCheckBoxesValue = {};
    data.forEach((rowData) => {
      const dataKeys = ['view', 'update', 'delete'];
      dataKeys.forEach((key) => {
        defaultCheckBoxesValue[`${rowData.accessor}-${key}`] = rowData[key].isAllow;
      });
    });
    return defaultCheckBoxesValue;
  });

  const handleCheckBoxChange = (action, checked, rowData) => {
    const values = {
      action: checked ? 'add' : 'remove',
      permissionList: [],
    };

    const viewKey = `${rowData.accessor}-view`;
    const updateKey = `${rowData.accessor}-update`;
    const deleteKey = `${rowData.accessor}-delete`;

    if (checked) {
      switch (action) {
        case 'delete':
          setCheckBoxesChecked({
            ...checkBoxesChecked,
            [deleteKey]: true,
            [updateKey]: true,
            [viewKey]: true,
          });
          values.permissionList = [rowData.view.id, rowData.update.id, rowData.delete.id];
          break;
        case 'update':
          setCheckBoxesChecked({
            ...checkBoxesChecked,
            [updateKey]: true,
            [viewKey]: true,
          });
          values.permissionList = [rowData.view.id, rowData.update.id];
          break;
        case 'view':
          setCheckBoxesChecked({
            ...checkBoxesChecked,
            [viewKey]: true,
          });
          values.permissionList = [rowData.view.id];
          break;
        default:
          break;
      }
    } else {
      switch (action) {
        case 'view':
          setCheckBoxesChecked({
            ...checkBoxesChecked,
            [deleteKey]: false,
            [updateKey]: false,
            [viewKey]: false,
          });
          values.permissionList = [rowData.delete.id, rowData.update.id, rowData.view.id];
          break;
        case 'update':
          setCheckBoxesChecked({
            ...checkBoxesChecked,
            [updateKey]: false,
            [deleteKey]: false,
          });
          values.permissionList = [rowData.delete.id, rowData.update.id];
          break;
        case 'delete':
          setCheckBoxesChecked({
            ...checkBoxesChecked,
            [deleteKey]: false,
          });
          values.permissionList = [rowData.delete.id];
          break;
        default:
          break;
      }
    }
    updatePermissionList(values);
  };

  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>
              {data.map((rowData) => {
                const dataKeys = Object.keys(rowData);
                return (
                  <TableRow key={`${title}-${rowData.accessor}-row`}>
                    {dataKeys.map((key) => {
                      if (key === 'name') {
                        return (
                          <TableCell key={`${title}-${rowData.accessor}-${key}`} width={'368px'}>
                            <Text fontSize="14px" fontWeight={400}>
                              {rowData[key]}
                            </Text>
                          </TableCell>
                        );
                      } else if (key !== 'accessor') {
                        return (
                          <TableCell
                            key={`${title}-${rowData.accessor}-${key}`}
                            width={'151px'}
                            align="right"
                          >
                            <Checkbox
                              checked={checkBoxesChecked[`${rowData.accessor}-${key}`]}
                              onChange={(e) => handleCheckBoxChange(key, e.target.checked, rowData)}
                              style={{ padding: 0, marginRight: '8px' }}
                            />
                          </TableCell>
                        );
                      } else {
                        return null;
                      }
                    })}
                    <TableCell width={'151px'} align="right" />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      view: PropTypes.shape({
        isAllow: PropTypes.bool,
        id: PropTypes.string,
      }).isRequired,
      update: PropTypes.shape({
        isAllow: PropTypes.bool,
        id: PropTypes.string,
      }).isRequired,
      delete: PropTypes.shape({
        isAllow: PropTypes.bool,
        id: PropTypes.string,
      }).isRequired,
    })
  ),
  updatePermissionList: PropTypes.func,
};

Row.defaultProps = {
  open: false,
  data: [],
};

export default Row;
