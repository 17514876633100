import getConfig from './Config';

const { enabled: courseOwnerEnabled, courseOwners } = getConfig().externalCourseOwner;

const getCourseOwnerDDL = () => {
  return Object.keys(courseOwners).map((key) => ({
    key,
    value: key,
    text: courseOwners[key].desc,
  }));
};

export { courseOwnerEnabled, getCourseOwnerDDL, courseOwners };
