import styled from 'styled-components';
import { grid, space } from 'styled-system';
import propTypes from '@styled-system/prop-types';

const Grid = styled.div`
  display: grid;
  ${grid}
  ${space}
`;

Grid.displayName = 'Grid';

Grid.propTypes = {
  ...propTypes.grid,
  ...propTypes.space,
};

export default Grid;
