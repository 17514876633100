import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckDefinedComponent extends Component {
  static propTypes = {
    checkDefinedObject: PropTypes.object,
    onDefined: PropTypes.func,
  };

  static defaultProps = {
    onDefined: () => {},
    checkDefinedObject: undefined,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.checkDefinedObject && this.props.checkDefinedObject) {
      this.props.onDefined(this.props.checkDefinedObject);
    }
  }
  render() {
    return null;
  }
}

export default CheckDefinedComponent;
