import React from 'react';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';
import withAppConfig from 'Util/hoc/withAppConfig';
import { PRODUCT_TYPE } from 'Routes/Products/Domains/constants';

import { getBundle } from '../../GraphQL/query/Products.query';
import { updateBundlePricingMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUPricingForm from '../SKUPricingForm';

const BundlePricingEditor = ({ bundle = {}, config }) => {
  const fieldsConfig = config?.products?.[PRODUCT_TYPE.BUNDLE]?.pricingEditor?.disabledFields;
  const disabledFields = Array.isArray(fieldsConfig) ? fieldsConfig : [];
  const { id, SKUCode } = bundle;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getBundle}
        editMutation={updateBundlePricingMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({
          SKUCode,
        })}
        getEditVariables={(form) => ({
          id,
          data: {
            ...form,
            priceInclVAT: _.omit(form.priceInclVAT, '__typename'),
            salePriceInclVAT: _.omit(form.salePriceInclVAT, '__typename'),
            promotionText: form.promotionText?.content,
          },
        })}
      >
        {({ data, onSubmit }) => {
          return (
            <SKUPricingForm
              initialData={data && data.sfBoProductBundle}
              onSubmit={onSubmit}
              disabledFields={disabledFields ?? ['promotionText']}
            />
          );
        }}
      </CreateEditForm>
    </Box>
  );
};

export default withAppConfig()(BundlePricingEditor);
