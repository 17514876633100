import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import InputWithCheckbox from '../InputWithCheckbox';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, { enableCredit = false, creditAmount }) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Credit Amount',
      inputName: 'creditAmount',
      customInput: Object.assign(
        (props) => (
          <InputWithCheckbox
            initChecked={enableCredit}
            type="number"
            checkBoxLabel="Use Subscription Credit"
            initValue={creditAmount}
            {...props}
          />
        ),
        {
          displayName: 'EnableCreditInput',
        }
      ),
      mode: MODE.BOTH,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class SubscriptionRedemptionForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  handleSubmit = () => {};

  validate = ({ creditAmount }, isChanged) => {
    const error = {};
    if (isChanged && (isNaN(Number(creditAmount)) || creditAmount < 0)) {
      error.creditAmount = 'creditAmount value must not be negative';
    }
    return error;
  };

  render() {
    const { isEdit, onSubmit, initialData = {} } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit, initialData)}
        onSubmit={onSubmit}
        customValidation={this.validate}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}

export default SubscriptionRedemptionForm;
