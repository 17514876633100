import gql from 'graphql-tag';

import {
  SFWorkshopSKUFragment,
  SFWorkshopTableOfContentsFragment,
  SFWorkshopFAQFragment,
  SFWorkshopTestimonialsFragment,
  SFWorkshopHighlightFragment,
  SFWorkshopHeaderFragment,
  SFWorkshopDescriptionFragment,
} from '../query/Workshop.query';

export const sfCreateWorkshop = gql`
  mutation sfCreateWorkshop($data: SFWorkshopCreateInput!) {
    sfCreateWorkshop(data: $data) {
      workshop {
        title
        SKUCode
      }
      message
      messages {
        message
        field
        code
      }
      success
      code
    }
  }
`;

export const sfUpdateWorkshop = gql`
  mutation sfUpdateWorkshop($data: SFWorkshopEditInput!, $SKUCode: String!) {
    sfUpdateWorkshop(data: $data, SKUCode: $SKUCode) {
      workshop {
        ...SFWorkshopSKUFragment
        ...SFWorkshopTableOfContentsFragment
        ...SFWorkshopFAQFragment
        ...SFWorkshopTestimonialsFragment
        ...SFWorkshopHighlightFragment
        ...SFWorkshopHeaderFragment
        ...SFWorkshopDescriptionFragment
      }
      message
      messages {
        message
        field
        code
      }
      success
      code
    }
  }
  ${SFWorkshopSKUFragment}
  ${SFWorkshopTableOfContentsFragment}
  ${SFWorkshopFAQFragment}
  ${SFWorkshopTestimonialsFragment}
  ${SFWorkshopHighlightFragment}
  ${SFWorkshopHeaderFragment}
  ${SFWorkshopDescriptionFragment}
`;

export const sfUpdateWorkshopBatch = gql`
  mutation sfUpdateWorkshopBatch($data: SFWorkshopBatchEditInput!, $SKUCode: String!) {
    sfUpdateWorkshopBatch(SKUCode: $SKUCode, data: $data) {
      code
      success
      message
      messages {
        message
        field
        code
      }
    }
  }
`;

export const sfDeleteWorkshopBatch = gql`
  mutation sfDeleteWorkshopBatch($id: String!) {
    sfDeleteWorkshopBatch(id: $id) {
      code
      success
      message
    }
  }
`;

export const sfCreateWorkshopBatch = gql`
  mutation sfCreateWorkshopBatch($SKUCode: String!, $data: SFWorkshopBatchCreateInput!) {
    sfCreateWorkshopBatch(SKUCode: $SKUCode, data: $data) {
      code
      success
      message
      messages {
        message
        field
        code
      }
    }
  }
`;
