import React from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { Flex, Text } from 'Components/Base';

import { getCourseTotalVideoTime } from '../../../GraphQL/query/Courses.query';

dayjs.extend(duration);

const formatDuration = (duration) => {
  return dayjs.duration(duration, 'seconds').format('H[h] m[m]');
};

const genTimeSummaryText = (data) => {
  const duration = data?.courseTotalVideoTime?.duration;
  return isNaN(duration) ? ' ' : `Total Video Length ${formatDuration(duration)}`;
};

const CourseTimeSummary = ({ courseId }) => {
  const { data, loading } = useQuery(getCourseTotalVideoTime, {
    variables: {
      courseId,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Flex mb={3} ml={3}>
      {!loading && <Text color="rgba(0,0,0,.6)">{genTimeSummaryText(data)}</Text>}
    </Flex>
  );
};

export default CourseTimeSummary;
