import gql from 'graphql-tag';

export const getSyllabuses = gql`
  query boSyllabuses($search: BOSearchSyllabusesInput, $paging: PageInput, $order: OrderInput) {
    boSyllabuses(search: $search, paging: $paging, order: $order) {
      total
      syllabuses {
        id
        code
        name
        startDate
        endDate
      }
    }
  }
`;

export const getAllSyllabuses = gql`
  query boAllSyllabuses {
    boAllSyllabuses {
      id
      code
      name
    }
  }
`;

export const getSyllabus = gql`
  query boSyllabus($id: ID!) {
    boSyllabus(id: $id) {
      id
      code
      name
      startDate
      endDate
    }
  }
`;

export const getSubjects = gql`
  query boSubjects($search: BOSearchSubjectsInput, $paging: PageInput, $order: OrderInput) {
    boSubjects(search: $search, paging: $paging, order: $order) {
      total
      subjects {
        id
        name
        shortName
        code
      }
    }
  }
`;

export const getSubject = gql`
  query boSubject($id: ID!) {
    boSubject(id: $id) {
      id
      name
      shortName
      code
    }
  }
`;

export const getAssignments = gql`
  query boSyllabusAssignments(
    $search: BOSearchSyllabusAssignmentsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    boSyllabusAssignments(search: $search, paging: $paging, order: $order) {
      total
      syllabusAssignments {
        id
        syllabusId
        syllabus {
          name
        }
        type
        schoolTerm {
          name
        }
        schoolGrade {
          name
        }
        schoolProgramme {
          name
        }
        careerPlan {
          name
        }
        students {
          prefix
          firstName
          lastName
        }
      }
    }
  }
`;

export const getAssignment = gql`
  query boSyllabusAssignment($id: ID!) {
    boSyllabusAssignment(id: $id) {
      id
      syllabus {
        name
      }
      type
      classEnrollments {
        id
      }
    }
  }
`;

export const getModules = gql`
  query boModules($search: BOSearchModulesInput, $paging: PageInput, $order: OrderInput) {
    boModules(search: $search, paging: $paging, order: $order) {
      total
      modules {
        syllabusId
        subjectId
        subject {
          code
          name
        }
        courseCode
        sectionCode
        subsectionCode
        courseName
        sectionName
        subsectionName
        contentType
        contentDuration
        startDate
        isHomework
      }
    }
  }
`;
