import React, { Component } from 'react';

import { Box } from 'Components/Base';

export default class ReportRouteDefault extends Component {
  static propTypes = {};

  render() {
    return <Box>Please select report from side menu</Box>;
  }
}
