import React, { Component } from 'react';
import PropTypes from 'prop-types';

import omitDeep from 'Util/omitDeep';
import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';
import { sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation';
import { getWorkshopBySKUCode, getStatusValue, resolveStatus } from 'GraphQL/query/Workshop.query';
import delve from 'Util/Delve';
import getConfig from 'Util/Config';

import WorkshopDetailForm from './WorkshopDetailsForm';

class SubscriptionDeWorkshopDetailsEditortailsEditor extends Component {
  static propTypes = {
    skuCode: PropTypes.string.isRequired,
    isEdit: PropTypes.bool,
    isOpen: PropTypes.bool,
    trigger: PropTypes.element,
    variables: PropTypes.object,
  };

  render() {
    const { isEdit, skuCode } = this.props;

    return (
      <Box>
        <CreateEditForm
          dataQuery={getWorkshopBySKUCode}
          editRefetchQueries={[
            {
              query: getWorkshopBySKUCode,
              variables: {
                SKUCode: skuCode,
              },
            },
          ]}
          editMutation={sfUpdateWorkshop}
          isEdit={true}
          id={skuCode}
          getQueryVariables={({ id }) => ({
            SKUCode: id,
          })}
          getEditVariables={(form, { id }) => ({
            SKUCode: id,
            data: form,
          })}
          getCreateVariables={(form) => ({
            data: form,
          })}
          getEditMutationResponse={(mutateData) => delve(mutateData, 'sfUpdateWorkshop')}
          checkMutationResponse
        >
          {({ data, onSubmit }) => {
            const mapDataIntoForm = ({ SKUStatus, isVisible, ...restData }) => {
              return { status: resolveStatus(SKUStatus, isVisible), ...restData };
            };
            const handleOnSubmit = ({ status, ...formValue }) => {
              let workshopInfo = { ...getStatusValue(status), ...formValue };
              if (!isEdit) {
                const { basefaq } = getConfig();
                const enabledBaseFaq = delve(basefaq, 'enabled');
                if (enabledBaseFaq) {
                  const faq = delve(basefaq, 'faq', []);
                  workshopInfo = { ...workshopInfo, FAQ: faq };
                }
              }

              const formatData = omitDeep(workshopInfo, ['__typename', 'assetUrl']);

              const updatedContactInfo = delve(formatData, 'contactInfo');
              const updatedPromotionText = delve(formatData, 'promotionText');

              const contactInfo = delve(updatedContactInfo, 'content') ? updatedContactInfo : null;
              const promotionText = delve(updatedPromotionText, 'content')
                ? updatedPromotionText
                : null;

              onSubmit({ ...formatData, contactInfo, promotionText });
            };
            return (
              <WorkshopDetailForm
                initialData={data && mapDataIntoForm(data.sfWorkshopBySKUCode)}
                onSubmit={handleOnSubmit}
              />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default SubscriptionDeWorkshopDetailsEditortailsEditor;
