import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import omitDeep from 'Util/omitDeep';

import { Flex, Box, Text } from 'Components/Base';
import Loading from 'Components/Loading';
import { getWorkshopHighlight } from 'GraphQL/query/Workshop.query';
import { sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation';

import delve from 'Util/Delve';

import EditTable from './Components/EditTable';

const columns = [
  {
    title: 'Icon',
    field: 'icon',
    render: (rowData) => (
      <Flex>
        <Box mx={2} className={`mdi ${rowData.icon}`} />
        {rowData.icon}
      </Flex>
    ),
  },
  { title: 'Description', field: 'content' },
];

const Highlight = ({ skuCode }) => {
  const { loading, error, data } = useQuery(getWorkshopHighlight, {
    variables: { SKUCode: skuCode },
    fetchPolicy: 'network-only',
  });
  const [
    updateHighlight,
    { loading: mutationLoading, error: mutationError, data: mutationResponse },
  ] = useMutation(sfUpdateWorkshop);
  const handleHighlightSave = (updatedIconList) => {
    const clonedData = JSON.parse(JSON.stringify(updatedIconList));
    const highlightsInput = omitDeep(clonedData, ['__typename', 'tableData']);
    updateHighlight({
      variables: { SKUCode: skuCode, data: { highlights: highlightsInput } },
      refetchQueries: [{ query: getWorkshopHighlight, variables: { SKUCode: skuCode } }],
    });
  };

  const tableData = delve(data, 'sfWorkshopBySKUCode.highlights', []);

  return (
    <Fragment>
      <link
        rel="stylesheet"
        href="https://cdn.materialdesignicons.com/4.5.95/css/materialdesignicons.min.css"
      />
      {loading || mutationLoading ? (
        <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />
      ) : (
        <EditTable
          title={
            <Box>
              <Box mb={1}>
                <Text fontSize={17}>Icon Highlight</Text>
              </Box>
              <a
                href={'https://materialdesignicons.com/cdn/2.0.46/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text fontSize={12} color="blue">
                  View Material Design Icon Collection
                </Text>
              </a>
            </Box>
          }
          data={tableData}
          columns={columns}
          onSaveClick={handleHighlightSave}
        />
      )}
    </Fragment>
  );
};

export default Highlight;
