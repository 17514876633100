import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { compose } from 'recompose';

import { ComplexSideMenuLayout } from 'Components/SideMenuLayout';
import { GenSwitchRoute } from 'Util/Route';
import APP_PERMISSION from 'Util/permissions/permissionKeys';
import withAppConfig from 'Util/hoc/withAppConfig';

import AllAnnouncements from './AllAnnouncements';
import AllBanners from './AllBanners';
import AllPopups from './AllPopups';
import FileImportStatus from './FileImportStatus';

const RouteIndex = () => <Redirect to={{ title: '', pathname: '/announcements/announcements' }} />;

const defaultRoute = {
  path: '/announcements',
  exact: true,
  roles: [],
  route: RouteIndex,
};

const MENU_NAME = {
  ALL_ANNOUNCEMENTS: 'All Announcements',
  BANNERS: 'Banners',
  POPUPS: 'Pop-ups',

  STATUS: 'Status',
};

const menus = {
  Announcements: [
    {
      path: `/announcements/announcements`,
      title: MENU_NAME.ALL_ANNOUNCEMENTS,
      menuName: MENU_NAME.ALL_ANNOUNCEMENTS,
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ANNOUNCEMENT.LIST.VIEW,
      ],
      route: AllAnnouncements,
    },
    {
      path: `/announcements/banners`,
      title: MENU_NAME.BANNERS,
      menuName: MENU_NAME.BANNERS,
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ANNOUNCEMENT.LIST.VIEW,
      ],
      route: AllBanners,
    },
    {
      path: `/announcements/popups`,
      title: MENU_NAME.POPUPS,
      menuName: MENU_NAME.POPUPS,
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ANNOUNCEMENT.LIST.VIEW,
      ],
      route: AllPopups,
    },
  ],

  FileImport: [
    {
      path: `/announcements/status`,
      title: MENU_NAME.STATUS,
      menuName: MENU_NAME.STATUS,
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ANNOUNCEMENT.LIST.VIEW,
      ],
      route: FileImportStatus,
    },
  ],
};

class AnnouncementsRoute extends Component {
  render() {
    const { myRoles, appConfig } = this.props;
    const visibleMenus = {};
    Object.keys(menus).forEach((header) => {
      visibleMenus[header] = menus[header].filter(({ hidden, roles }) => {
        return !hidden && _.intersection(roles, myRoles).length > 0;
      });
    });
    const {
      enabledPopUpAnnouncementRoute,
      enabledBannerAnnouncementRoute,
      enabledAnnouncementFileImportRoute,
    } = appConfig?.announcement ?? {};
    const notIncludedMenuNames = [];
    if (!enabledPopUpAnnouncementRoute) notIncludedMenuNames.push(MENU_NAME.POPUPS);
    if (!enabledBannerAnnouncementRoute) notIncludedMenuNames.push(MENU_NAME.BANNERS);
    if (!enabledAnnouncementFileImportRoute) notIncludedMenuNames.push(MENU_NAME.STATUS);

    const filteredMenus = {};
    Object.keys(visibleMenus).forEach((header) => {
      const submenus = visibleMenus[header].filter(
        (menu) => !notIncludedMenuNames.includes(menu.menuName)
      );
      if (submenus.length > 0) {
        filteredMenus[header] = submenus;
      }
    });
    return (
      <ComplexSideMenuLayout menus={filteredMenus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute
          routes={[..._.flatMap(filteredMenus, (innerMenus) => innerMenus), defaultRoute]}
        />
      </ComplexSideMenuLayout>
    );
  }
}

export default compose(connect(), withAppConfig({ configProp: 'appConfig' }))(AnnouncementsRoute);
