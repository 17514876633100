import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'formsy-semantic-ui-react';

import FormGenerator from 'Components/Form/FormGenerator';
import { SKU_TYPE } from 'Routes/Products/Domains/constants';

const POSITIVE_NUMBER_VALIDATION = /^[0-9]+$|^$/;

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, SKUType) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'จำนวนทั้งหมด (Original stock)',
      inputName: 'originalStock',
      mode: MODE.BOTH,
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'จำนวนคงเหลือ (Stock)',
      inputName: 'stock',
      mode: MODE.BOTH,
      inputProps: {
        disabled: true,
      },
    },
  ];

  if (SKUType === SKU_TYPE.BUNDLE) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'จำนวนที่ขายได้ (Available stock)',
      inputName: 'availableStock',
      inputDescription:
        'จำนวนที่ขายได้จะขึ้นอยู่กับจำนวนคงเหลือทั้งหมดของตัวเองและ item ที่อยู่ภายใน',
      mode: MODE.BOTH,
      inputProps: {
        disabled: true,
      },
    });
  }

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

const SKUStockForm = (props) => {
  const { isEdit, onSubmit, SKUType } = props;
  const fields = getFields(isEdit, SKUType);

  const handleSubmit = (data) => {
    const { originalStock, ...restData } = data;

    let passingData = {
      ...restData,
    };
    if (originalStock !== undefined) {
      passingData.originalStock = originalStock;
    }
    onSubmit(passingData);
  };

  const validate = ({ originalStock }, isChanged) => {
    const error = {};

    if (isChanged) {
      if (originalStock && !POSITIVE_NUMBER_VALIDATION.test(originalStock)) {
        error.originalStock = 'Input number must be positive';
      }
    }

    return error;
  };

  return (
    <FormGenerator
      fields={fields}
      onSubmit={handleSubmit}
      customValidation={validate}
      submitText={isEdit ? 'Update' : 'Add'}
      cancelText="Cancel"
      showCancel
      {...props}
    />
  );
};

SKUStockForm.propTypes = {
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  SKUType: PropTypes.oneOf(Object.values(SKU_TYPE)),
};

SKUStockForm.defaultProps = {
  onSubmit: () => {},
};

export default SKUStockForm;
