import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { Flex } from 'Components/Base';
import SimpleQueryTable, { DATA_FORMAT } from 'GraphQL/util/SimpleQueryTable';
// import { updateCollectionOnlineCourseMutation } from '../../GraphQL/mutation/Products.mutation';
import { getCollectionOnlineCourse } from 'Routes/Products/GraphQL/query/Products.query';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import ConfirmationButton from 'Components/ConfirmationButton';
import { getOnlineCourseDetailsOverviewUrl } from 'Routes/Products/Domains/resolveUrl';

const getColumns = (collectionSkuCode, onEditCourse) => [
  {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    width: 50,
  },
  {
    Header: 'SKUCode',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Status',
    accessor: 'SKUStatus',
    type: COLUMN_TYPE.STRING,
    width: 120,
  },
  {
    Header: 'Visibility',
    accessor: 'isVisible',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ value }) => value.toString(),
    width: 50,
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Start Date',
    accessor: 'eventTime.startDate',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATE_FORMAT,
    width: 100,
  },
  {
    Header: 'End Date',
    accessor: 'eventTime.endDate',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATE_FORMAT,
    width: 100,
  },
  {
    Header: 'Start Time',
    accessor: 'eventTime.startTime',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.TIME_FORMAT,
    width: 100,
  },
  {
    Header: 'End Time',
    accessor: 'eventTime.endTime',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.TIME_FORMAT,
    width: 100,
  },
  {
    Header: 'Price (incl.VAT)',
    accessor: 'priceInclVAT.value',
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    width: 120,
  },
  {
    Header: 'Sale Price (incl.VAT)',
    accessor: 'salePriceInclVAT.value',
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    width: 120,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    type: COLUMN_TYPE.CUSTOM,
    Cell: function rendarAction({ original }) {
      const skuCode = original.SKUCode;
      return (
        <Button.Group>
          <Button basic size="mini" icon compact onClick={() => onEditCourse(skuCode)}>
            <Icon name="edit" />
          </Button>
        </Button.Group>
      );
    },
    width: 100,
  },
];

const OnlineCourseBatches = ({ onlineCourse = {} }) => {
  const { SKUCode } = onlineCourse;
  const history = useHistory();

  const handleEditCourse = (courseSkuCode) => {
    history.push(getOnlineCourseDetailsOverviewUrl(courseSkuCode));
  };

  return (
    <Container>
      <SimpleQueryTable
        type={'sfBoProductCollectionOnlineCourses'}
        columns={getColumns(SKUCode, handleEditCourse)}
        query={getCollectionOnlineCourse}
        getSearchVariables={() => ({ SKUCode })}
        getResultData={(data) => data.sfBoProductCollectionOnlineCourse?.onlineCourses ?? []}
      />
    </Container>
  );
};

export default OnlineCourseBatches;
