import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import _ from 'lodash';

const DEBOUNCE_TYPE = {
  leading: 'leading',
  trailing: 'trailing',
  both: 'both',
};

const getDebounceType = (debounceType) => {
  switch (debounceType) {
    case DEBOUNCE_TYPE.leading:
      return { leading: true, trailing: false };

    case DEBOUNCE_TYPE.trailing:
      return { leading: false, trailing: true };

    case DEBOUNCE_TYPE.both:
      return { leading: true, trailing: true };

    default:
      return { leading: true, trailing: false };
  }
};

const withDebounceOnClick = (onClickProps = 'onClick') => (OnClickComponent) => {
  class OnClickComponentHOC extends Component {
    handleDebounce = (e) => {
      this.props[onClickProps](e);
    };

    debounced = _.debounce(
      this.handleDebounce,
      this.props.debounceTime,
      getDebounceType(this.props.debounceType)
    );

    render() {
      const { debounceType, debounceTime, ...restProps } = this.props;
      return React.createElement(OnClickComponent, {
        ...restProps,
        [onClickProps]: this.debounced,
      });
    }
  }

  OnClickComponentHOC.propTypes = {
    onClick: PropTypes.func,
    debounceTime: PropTypes.number,
    debounceType: PropTypes.oneOf(Object.values(DEBOUNCE_TYPE)),
  };

  OnClickComponentHOC.defaultProps = {
    onClick: () => {},
    debounceTime: 1000,
  };

  hoistNonReactStatics(OnClickComponentHOC, OnClickComponent);

  return OnClickComponentHOC;
};

export default withDebounceOnClick;
