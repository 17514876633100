import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import merge from 'lodash/merge';

import getConfig from '../Config';

const defaultConfig = { configProp: 'config' };

const withAppConfig = (hocConfig = {}, overrideConfig) => (WrappedComponent) => {
  const { configProp } = merge({}, defaultConfig, hocConfig);

  const AppConfigHOC = (props) => {
    const config = overrideConfig ? overrideConfig : getConfig();
    const configPropName = configProp ? configProp : 'config';
    const cProps = { [configPropName]: config };

    return <WrappedComponent {...props} {...cProps} />;
  };

  hoistNonReactStatics(AppConfigHOC, WrappedComponent);

  return AppConfigHOC;
};

const useAppConfig = (overrideConfig) => {
  const config = overrideConfig ? overrideConfig : getConfig();
  return config;
};

export { useAppConfig };
export default withAppConfig;
