import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Checkbox } from 'semantic-ui-react';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';
import { NO_EXPIRATION_DAYS, isCertificateNoExpiration } from 'Routes/Certificates/CertificateUtil';

class CertificateAgeInput extends Component {
  static propTypes = {
    inputLabel: PropTypes.string,
    checkboxInputLabel: PropTypes.string,
  };

  state = {
    noExpiration: isCertificateNoExpiration(this.props.value) ? true : false,
    value: isCertificateNoExpiration(this.props.value) ? '' : this.props.value,
  };

  handleNoExpirationCheck = (e, data) => {
    this.setState({ noExpiration: data.checked });
    if (data.checked) {
      this.handleValidTimePeriodChange(NO_EXPIRATION_DAYS);
    } else {
      this.handleValidTimePeriodChange(this.state.value);
    }
  };

  handleValueChange = (e, data) => {
    this.setState({ value: data.value });
    this.handleValidTimePeriodChange(data.value);
  };

  handleValidTimePeriodChange = (data) => {
    this.props.onChange({}, { value: data.toString() });
  };

  render() {
    const { checkboxInputLabel } = this.props;
    const { noExpiration, value } = this.state;
    return (
      <Flex flexDirection="column">
        <Input
          type="number"
          validations={'isNumeric'}
          disabled={noExpiration}
          value={value}
          onChange={this.handleValueChange}
        />
        <Box mt={2} />
        <Checkbox
          label={checkboxInputLabel}
          checked={noExpiration}
          onChange={this.handleNoExpirationCheck}
        />
      </Flex>
    );
  }
}

const EnhancedCertificateAgeInput = withFormsy(CertificateAgeInput);

export default EnhancedCertificateAgeInput;
