export const AnnouncementPermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'announcement.list-view',
    UPDATE: 'announcement.list-update',
    DELETE: 'announcement.list-delete',
  },
  OVERVIEW: {
    VIEW: 'announcement.overview-view',
    UPDATE: 'announcement.overview-update',
    DELETE: 'announcement.overview-delete',
  },
});
