import React, { Fragment, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Button, Icon, Container, List } from 'semantic-ui-react';
import { omit } from 'lodash';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import { Flex, Text, Box, ButtonFilled } from 'Components/Base';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import StatefulModal from 'Components/Modal/StatefulModal';
import QueryTable from 'GraphQL/util/QueryTable';
import { getTags, getAttachedExams } from 'src/GraphQL/query/Exam.query';
import { deleteTag } from 'src/GraphQL/mutation/Exam.mutation';
import useDisclosure from 'Util/useDisclosure';
import Filter from 'Util/Filter';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';

import TagModal from '../Containers/TagModal';
import { TAG_CATEGORY, TAG_CATEGORY_LABEL } from '../Components/constants';

import AddTagButton from './AddTagButton.view';

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const getCategoryEnumOptions = () => {
  const filteredCategories = Object.entries(omit(TAG_CATEGORY, ['COURSE_CODE', 'ASSIGNEE']));
  const enumCategoryOptions = filteredCategories.map(([key, value]) => ({
    key,
    value,
    text: TAG_CATEGORY_LABEL[key],
  }));
  return enumCategoryOptions;
};

/**
 * This function generate a filter that exclude “COURSE_CODE“ and “ASSIGNEE“ categories
 * unless any specific category is specified.
 * @param {{ name: string, category: string[] }} search An object for filtering tags.
 */
const generateSearchFilter = (search) => {
  const name = search?.name;
  const category = search?.category;
  const description = search?.description;

  return {
    name,
    description,
    category:
      category ??
      Object.values(omit(TAG_CATEGORY, [TAG_CATEGORY.COURSE_CODE, TAG_CATEGORY.ASSIGNEE])),
  };
};

const DEFAULT_COLUMNS = [
  {
    Header: 'ชื่อ Tag',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 220,
  },
  {
    Header: 'ประเภท Tag',
    accessor: 'category',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    width: 220,
    enumOptions: getCategoryEnumOptions(),
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 220,
  },
];

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot find attached question, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const DeleteTagConfirmation = ({ id, name, search, onCancel, onCompleted }) => {
  const [feedback, setFeedback] = useState({});

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const { data, loading, error } = useQuery(getAttachedExams, {
    variables: { tagId: id },
    onError,
  });

  const [handleDeleteTag] = useMutation(deleteTag, {
    onCompleted: onCompleted,
    refetchQueries: [
      {
        query: getTags,
        variables: search,
        fetchPolicy: 'network-only',
      },
    ],
    variables: { tagId: id },
  });

  const ModalHeader = useMemo(
    () => (
      <Text.Header px={4} py={3} fontWeight="600">
        Confirm Delete
      </Text.Header>
    ),
    []
  );

  const ModalFooter = useMemo(
    () => (
      <Flex justifyContent="flex-end" p={3} bg="#f9fafb">
        <ButtonFilled
          px={3}
          py={2}
          bg="#e0e1e2"
          color="rgba(0,0,0,.6)"
          style={{ cursor: 'pointer', border: 0 }}
          onClick={onCancel}
          fontWeight="600"
          fontSize="14px"
        >
          Cancel
        </ButtonFilled>
        <Box mx={1} />
        <ButtonFilled
          px={3}
          py={2}
          bg="primary"
          color="white"
          style={{ cursor: 'pointer', border: 0 }}
          onClick={handleDeleteTag}
          fontWeight="600"
          fontSize="14px"
        >
          Confirm
        </ButtonFilled>
      </Flex>
    ),
    [handleDeleteTag, onCancel]
  );

  const { totalAttachedExams, attachedExams } = useMemo(() => {
    const attachedExamsResult = data?.findAttachedExamExamsByExamTagId ?? {};

    const { directlyAttachedExams = [], indirectlyAttachedExams = [] } = attachedExamsResult;

    const attachedExams = [...directlyAttachedExams, ...indirectlyAttachedExams];
    const totalAttachedExams = attachedExams.length;
    return { totalAttachedExams, attachedExams };
  }, [data]);

  if (loading) return null;

  return (
    <>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={onCancel}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
      <StatefulModal open={!loading && !error} trigger={<></>} onClose={onCancel}>
        {() => (
          <Box>
            {ModalHeader}
            <Divider />
            <Box p={4}>
              {totalAttachedExams > 0 && (
                <>
                  This tag has been assigned to {totalAttachedExams} exams/questions
                  <br />
                  <List bulleted>
                    {attachedExams.map((exam) => (
                      <List.Item key={exam.id}>
                        <Link to={{ pathname: `/exams/${exam.id}` }}>{exam.title}</Link>
                      </List.Item>
                    ))}
                  </List>
                  <br />
                  Delete this tag will affect those exams/questions <br /> <br />
                </>
              )}
              <Text lineHeight="1.75">
                Do you want to delete tag <b>{name}</b>
              </Text>
            </Box>
            <Divider />
            {ModalFooter}
          </Box>
        )}
      </StatefulModal>
    </>
  );
};

const AllTag = () => {
  const { isOpen, open, close } = useDisclosure();
  const [search, setSearch] = useState(generateSearchFilter({}));
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [order, setOrder] = useState({ field: 'createdAt', type: 'DESC' });

  const handlePageChange = (page) => {
    Filter.handlePageChange(page, paging.pageSize, setPaging);
  };
  const handlePageSizeChange = (pageSize) => {
    Filter.handlePageSizeChange(pageSize, setPaging);
  };
  const handleSearchChange = (search) => {
    Filter.handleSearchChange(generateSearchFilter(search), paging, setSearch, setPaging);
  };
  const handleSortedChange = (sorted) => {
    Filter.handleSortedChange(sorted, paging.pageSize, setOrder, setPaging);
  };
  const tagRef = useRef();
  const searchVariablesRef = useRef({ search, paging, order });
  useEffect(() => {
    searchVariablesRef.current = { search, paging, order };
  }, [search, paging, order]);

  const columns = useMemo(
    () => [
      ...DEFAULT_COLUMNS,
      {
        Header: 'Action',
        accessor: 'createdAt',
        isSearchAble: false,
        width: 100,
        Cell: function TagActionCell({ original: tag }) {
          return (
            <Fragment>
              <Button.Group>
                <TagModal
                  isEdit
                  tagId={tag.id}
                  trigger={
                    <Button basic icon>
                      <Icon name="edit" />
                    </Button>
                  }
                />
                <Button
                  basic
                  icon
                  onClick={() => {
                    tagRef.current = tag;
                    open();
                  }}
                >
                  <Icon name="trash alternate outline" color="red" />
                </Button>
              </Button.Group>
            </Fragment>
          );
        },
      },
    ],
    [open]
  );

  return (
    <>
      <Container>
        <Flex justifyContent="space-between" my={2}>
          <Text.Header>Tag List</Text.Header>
          <AddTagButton variables={searchVariablesRef.current} />
        </Flex>
        <QueryTable
          type="examTags"
          columns={columns}
          query={getTags}
          paging={paging}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          search={search}
          onSearchChange={handleSearchChange}
          order={order}
          onSortedChange={handleSortedChange}
          resolveData={(data) =>
            data.examTags.examTags.map((examTag) => ({
              ...examTag,
              category: TAG_CATEGORY_LABEL[examTag.category],
            }))
          }
        />
      </Container>
      {isOpen && (
        <DeleteTagConfirmation
          {...tagRef.current}
          search={searchVariablesRef.current}
          onCompleted={close}
          onCancel={close}
        />
      )}
    </>
  );
};

export default AllTag;
