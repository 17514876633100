import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import { ComplexSideMenuLayout } from 'Components/SideMenuLayout';
import { GenSwitchRoute } from 'Util/Route';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { IMPORT_TRACKING_URL, SHIPMENT_URL } from './Domains/constant';
import Shipments from './Routes/Shipments';

const RouteIndex = () => <Redirect to={{ title: '', pathname: SHIPMENT_URL }} />;

const ImportTrackingRedirectRoute = () => (
  <Redirect to={{ title: '', pathname: '/sf/file-import/shipment-tracking' }} />
);

const defaultRoute = {
  path: '/shipments',
  exact: true,
  roles: [],
  route: RouteIndex,
};

const menus = {
  Shipments: [
    {
      path: SHIPMENT_URL,
      title: 'Shipments',
      menuName: 'Shipments',
      icon: 'book',
      exact: true,
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.SHIPMENT.LIST.VIEW],
      route: Shipments,
    },
  ],
  'Shipment Management': [
    {
      path: IMPORT_TRACKING_URL,
      title: 'Import tracking',
      menuName: 'Import tracking',
      icon: 'book',
      exact: true,
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.FILE_IMPORT.SHIPMENT_TRACKING.LIST.VIEW,
      ],
      route: ImportTrackingRedirectRoute,
    },
  ],
};

const ShipmentRoute = ({ myRoles }) => {
  const visibleMenus = {};
  Object.keys(menus).forEach((header) => {
    visibleMenus[header] = menus[header].filter(({ hidden, roles }) => {
      return !hidden && _.intersection(roles, myRoles).length > 0;
    });
  });
  const filteredMenus = {};
  Object.keys(visibleMenus).forEach((header) => {
    if (visibleMenus[header].length > 0) {
      filteredMenus[header] = visibleMenus[header];
    }
  });

  return (
    <ComplexSideMenuLayout menus={filteredMenus} contentProps={{ textAlign: 'left' }}>
      <GenSwitchRoute routes={[..._.flatMap(menus, (innerMenus) => innerMenus), defaultRoute]} />
    </ComplexSideMenuLayout>
  );
};

export default connect()(ShipmentRoute);
