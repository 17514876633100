import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import FormGenerator from 'Components/Form/FormGenerator';

import { CATEGORY_STATUS_OPTIONS } from '../../Domains/constants';

import FileUploadInput from './FileUploadInput';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, id, iconUrl, parentId) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
      hidden: !parentId && id,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Status',
      inputName: 'status',
      inputProps: {
        required: true,
        search: true,
        options: CATEGORY_STATUS_OPTIONS,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Ordering',
      inputName: 'itemNo',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Icon',
      inputName: 'icon',
      customInput: (props) => <FileUploadInput id={id} {...props} imageUrl={iconUrl} name="icon" />,
      mode: MODE.EDIT,
      hidden: !parentId && id,
    },
  ];
  return fields.filter(
    (f) =>
      (f.mode === MODE.BOTH && !f.hidden) ||
      (isEdit && f.mode === MODE.EDIT && !f.hidden) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

export default class CategoryForm extends Component {
  static propTypes = {
    onCreateCategory: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateCategory: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateCategory(form);
  };

  render() {
    const { id, isEdit, initialData } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit, id, initialData?.iconUrl, initialData?.parentId)}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
