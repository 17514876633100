import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const FlexInput = styled(Flex)`
  & .react-datepicker__input-container {
    width: inherit;
  }
  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

const DateRangeInput = ({ value: initValue, onChange }) => {
  const [startDate, setStartDate] = useState(initValue ? dayjs(initValue) : null);
  const [endDate, setEndDate] = useState(initValue ? dayjs(initValue) : null);

  useEffect(() => {
    onChange({}, { value: { start: startDate, end: endDate } });
  }, [startDate, endDate]);

  return (
    <Box>
      <FlexInput alignItems="center">
        <DatePicker
          selected={startDate}
          placeholderText="from"
          dateFormat="DD/MM/YYYY"
          onChange={(value) => setStartDate(value)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <Box pr={2} />
        <DatePicker
          selected={endDate}
          placeholderText="to"
          dateFormat="DD/MM/YYYY"
          onChange={(value) => setEndDate(value)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </FlexInput>
    </Box>
  );
};

DateRangeInput.propTypes = {
  value: PropTypes.object,
  timeLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default withFormsy(DateRangeInput);
