import React, { useRef, useCallback, useEffect } from 'react';

import { useWireSync } from 'src/Util/WireSync';

import VideoPlayerWrapper from '../VideoManagement/VideoPlayerWrapper';

const videoOptions = { playbackRates: [0.5, 0.75, 1.0] };

const VideoPreview = ({ syncKey, markers = [], url, type, ...videoAsset }) => {
  const { role, value, setValue, reset, node } = useWireSync(syncKey);
  const videoPlayerRef = useRef(null);
  const setValueCallback = useCallback(setValue, []);

  useEffect(() => {
    const player = videoPlayerRef?.current?.player ?? null;
    if (player) {
      player.on('timeupdate', () => setValueCallback(player?.currentTime()));
    }
  }, [setValueCallback]);

  return (
    <VideoPlayerWrapper
      url={url}
      videoType={VideoPlayerWrapper.VIDEO_TYPE[type]}
      playerProps={{
        markers: markers,
        ref: videoPlayerRef,
        videoOptions: videoOptions,
      }}
    />
  );
};

export default VideoPreview;
