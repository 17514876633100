import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import { editCourse } from 'GraphQL/mutation/Courses.mutation';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';

import DetailsEditor from './DetailsEditor';

class DetailsEditorGQL extends Component {
  static propTypes = {
    courseId: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  };

  handleDetailsUpdate = (editFn) => (data) => {
    const { product, version, courseId } = this.props;
    editFn({
      variables: {
        version: productCourseVersionResolver(product, version),
        courseId: courseId,
        courseDesc: data,
      },
    });
  };

  render() {
    return (
      <Mutation mutation={editCourse}>
        {(editCourse, { data, loading }) => (
          <DetailsEditor
            courseId={this.props.courseId}
            version={this.props.version}
            product={this.props.product}
            loading={loading}
          />
        )}
      </Mutation>
    );
  }
}

export default DetailsEditorGQL;
