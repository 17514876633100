import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Box, Flex } from 'Components/Base';

import DeviceTokenList from '../../Containers/DeviceTokenList.gql';

class DeviceTokenPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { userId } = this.props;
    return (
      <Box py={3}>
        <Box mb={3} />
        <DeviceTokenList userId={userId} />
        <Box pb={3} />
      </Box>
    );
  }
}

DeviceTokenPanel.propTypes = {};

export default DeviceTokenPanel;
