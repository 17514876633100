export function readImageFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const height = image.height;
          const width = image.width;
          resolve({ base64Data: reader.result, width, height });
          return true;
        };

        image.onerror = (e) => {
          reject('cannot load signature image');
          return false;
        };
      },
      true
    );

    reader.readAsDataURL(file);
  });
}

export function readImageFromBase64(base64Data) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = base64Data;

    image.onload = () => {
      const height = image.height;
      const width = image.width;
      resolve({ base64Data, width, height });
      return true;
    };

    image.onerror = (e) => {
      reject(e);
      return false;
    };
  });
}

export function isBase64String(value) {
  if (!value || typeof value !== 'string') {
    return false;
  }

  const values = value.split(',');

  if (values[0].startsWith('data:') && values[0].endsWith(';base64')) {
    return true;
  }

  return false;
}
