import { isNil, isEmpty } from 'lodash';

export const ERP_ERROR_STATUS = {
  INVALID_SKUCODE: 'INVALID_SKUCODE',
  NO_PRICING: 'NO_PRICING',
  ZERO_PRICING: 'ZERO_PRICING',
};

export const ERP_PRODUCT = {
  ONLINE_COURSE: 'ONLINE_COURSE',
  WORKSHOP_BATCH: 'WORKSHOP_BATCH',
  BUNDLE: 'BUNDLE',
  BOOTCAMP: 'BOOTCAMP',
};

export function validateSKUCode(SKUCode = '', validationPattern) {
  return validationPattern.some((validatePattern) => {
    const regex = new RegExp(validatePattern);
    return regex.test(SKUCode);
  });
}

export function checkReadyToPublish(isValidSKUCode, pricing, productType) {
  let error = [];
  if (productType === ERP_PRODUCT.ONLINE_COURSE) {
    // if pricing more than 0, SKUCode must be in the correct pattern
    if (!isNil(pricing) && (pricing > 0) & !isValidSKUCode) {
      error.push(ERP_ERROR_STATUS.INVALID_SKUCODE);
    }
  } else {
    // if not an online course SKUCode must be in the correct pattern
    if (!isValidSKUCode) error.push(ERP_ERROR_STATUS.INVALID_SKUCODE);

    // if not an online course the price must not equal to 0
    if (!isNil(pricing) && pricing === 0) {
      error.push(ERP_ERROR_STATUS.ZERO_PRICING);
    }
  }

  // Has no pricing
  if (isNil(pricing)) {
    error.push(ERP_ERROR_STATUS.NO_PRICING);
  }

  return { isReady: isEmpty(error), error };
}
