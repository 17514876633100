import React from 'react';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';

const ModalAction = ({ onCancel, onConfirm, loading = false, isEdit = true }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onConfirm();
  };

  return (
    <Flex justifyContent="space-between" alignItems="center" mb={3}>
      <Flex justifyContent="flex-end">
        <Box mr={2}>
          {isEdit === true && (
            <Button primary circular onClick={handleSubmit} loading={loading} disabled={loading}>
              Generate code
            </Button>
          )}
        </Box>
        <Button circular onClick={() => onCancel()} loading={loading} disabled={loading}>
          Close
        </Button>
      </Flex>
    </Flex>
  );
};

export default ModalAction;
