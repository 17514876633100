import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RTE, { EditorValue } from 'react-rte';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateToHTML } from 'draft-js-export-html';
import { ENTITY_TYPE } from 'draft-js-utils';

import RichTextEditorStyleWrapper from './RichTextEditorStyleWrapper';
import UploadImageButton from './Components/UploadImagebutton';
import ImageBlock from './Components/ImageBlock';
import customDecorators from './decorators';

/**
 * RichTextEditor is built from react-rte.
 * value in this component is the instance of EditorValue which is built from draft's editorState and contentState.
 * more EditorValue info: https://github.com/sstur/react-rte/blob/master/src/lib/EditorValue.js
 *
 * stateFromMarkdown, stateFromHTML, stateToMarkdown, stateToHTML receive value as draft's contentState.
 */

// Note that lowercase 'markdown' and 'html' are used in react-rte. Modifying these values will cause errors in react-rte.
const CONTENT_FORMAT = {
  MARKDOWN: 'markdown',
  HTML: 'html',
};

const INLINE_STYLE_BUTTONS = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Strikethrough', style: 'STRIKETHROUGH' },
  { label: 'Monospace', style: 'CODE' },
  { label: 'Underline', style: 'UNDERLINE' },
];

const BLOCK_TYPE_DROPDOWN = [
  { label: 'Normal', style: 'unstyled' },
  { label: 'Heading Large', style: 'header-one' },
  { label: 'Heading Medium', style: 'header-two' },
  { label: 'Heading Small', style: 'header-three' },
];
const BLOCK_TYPE_BUTTONS = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'Code Block', style: 'code-block', className: 'toolbar-code-block' },
];

const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'IMAGE_BUTTON',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS,
  BLOCK_TYPE_BUTTONS,
  BLOCK_TYPE_DROPDOWN,
};

const CUSTOM_CONTROL_TYPE = {
  IMAGE_UPLOAD: 'IMAGE_UPLOAD',
};

function mediaBlockRenderer(block) {
  if (block.getType() === 'atomic') {
    return {
      component: ImageBlock,
      editable: false,
    };
  }
  return null;
}

const getCustomControlElement = (controlObj, handleFunctions) => {
  const { type, data, maxFileSize } = controlObj;
  const { onImageUpload } = handleFunctions;
  switch (type) {
    case CUSTOM_CONTROL_TYPE.IMAGE_UPLOAD:
      // eslint-disable-next-line no-unused-vars
      return (setValue, getValue, editorState) => {
        return (
          <UploadImageButton
            key="image-upload"
            editorState={editorState}
            onImageUpload={onImageUpload}
            uploadConfig={data}
            maxFileSize={maxFileSize}
          />
        );
      };
    default:
      return () => {};
  }
};

const getCustomControls = (customControls, handleFunctions) => {
  if (customControls && customControls.length > 0) {
    return customControls.map((controlObj) => {
      return getCustomControlElement(controlObj, handleFunctions);
    });
  } else {
    return [];
  }
};

const convertValueToString = (value, contentFormat, options) => {
  if (value instanceof EditorValue) {
    const editorState = value.getEditorState();
    const contentState = editorState.getCurrentContent();
    let formattedValue = null;
    if (contentState.hasText()) {
      switch (contentFormat) {
        case CONTENT_FORMAT.HTML:
          formattedValue = stateToHTML(contentState, options);
          break;
        case CONTENT_FORMAT.MARKDOWN:
        default:
          formattedValue = stateToMarkdown(contentState, options);
      }
    }
    return formattedValue;
  } else {
    return value;
  }
};

const convertStringToValue = (string, contentFormat, decorator, options) => {
  const value = EditorValue.createFromString(string, contentFormat, decorator, {
    // Should return a Style() or Entity() or null/undefined
    // More info about customInlineFn: https://github.com/sstur/draft-js-utils/tree/master/packages/draft-js-import-element
    customInlineFn: (element, { Style, Entity }) => {
      if (element.tagName === 'IMG') {
        return Entity(ENTITY_TYPE.IMAGE, {
          src: element.getAttribute('src'),
          width: element.getAttribute('width'),
          height: element.getAttribute('height'),
        });
      }
    },
  });
  return value;
};

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.RTERef = React.createRef();
  }
  static propTypes = {
    onChange: PropTypes.func,
    contentFormat: PropTypes.oneOf(Object.values(CONTENT_FORMAT)),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(EditorValue)]),
    returnValueAsString: PropTypes.bool,
    options: PropTypes.object,
    readOnly: PropTypes.bool,
    imageUploadConfig: UploadImageButton.propTypes.uploadConfig,
    customControls: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(Object.values(CUSTOM_CONTROL_TYPE)),
        data: PropTypes.object,
      })
    ),
  };
  static defaultProps = {
    contentFormat: CONTENT_FORMAT.MARKDOWN,
    returnValueAsString: true,
    readOnly: false,
  };

  state = {
    value: RTE.createEmptyValue(),
  };

  componentDidMount() {
    const { value, contentFormat, options } = this.props;
    if (value) {
      let formattedValue;
      if (typeof value === 'string') {
        formattedValue = convertStringToValue(value, contentFormat, customDecorators, options);
      } else {
        formattedValue = value;
      }

      this.setState({
        value: formattedValue,
      });
    }
  }

  handleChange = (value) => {
    const { contentFormat, options } = this.props;
    this.setState({ value: value });
    if (this.props.onChange) {
      const formattedValue = this.props.returnValueAsString
        ? convertValueToString(value, contentFormat, options)
        : value;

      this.props.onChange(formattedValue);
    }
  };

  handleImageUpload = (newEditorState) => {
    // Call RTE onChange to make sure that all onChange process in RTE will remain the same
    this.RTERef.current._onChange(newEditorState);
  };

  getNavBarHeight() {
    const element = document.getElementById('navbar');
    if (element) {
      return element.getBoundingClientRect().height;
    }
  }

  render() {
    const { readOnly, className } = this.props;

    return (
      <RichTextEditorStyleWrapper top={this.getNavBarHeight() || 0} className={className}>
        <RTE
          ref={this.RTERef}
          value={this.state.value}
          onChange={this.handleChange}
          toolbarConfig={toolbarConfig}
          readOnly={readOnly}
          blockRendererFn={mediaBlockRenderer}
          customControls={getCustomControls(this.props.customControls, {
            onImageUpload: this.handleImageUpload,
          })}
        />
      </RichTextEditorStyleWrapper>
    );
  }
}

RichTextEditor.contentFormat = CONTENT_FORMAT;
RichTextEditor.customControlType = CUSTOM_CONTROL_TYPE;

export { convertStringToValue, convertValueToString };
export default RichTextEditor;
