import React from 'react';
import { withTheme } from 'styled-components';
import { Divider, Container } from 'semantic-ui-react';

import APP_PERMISSION from 'Util/permissions/permissionKeys';
import Menu from 'Containers/Menu';
import { GenRoute } from 'Util/Route';

import SKUHeaderWithImage from '../../Components/SKUHeaderWithImage';
import {
  BookOverviewEditor,
  BookDetailsEditor,
  BookCategoriesEditor,
  BookPricingEditor,
  BookStocksEditor,
} from '../../Components/BookEditor';
import { updateBookMutation } from '../../GraphQL/mutation/Products.mutation';

const genMenu = (matchUrl) => {
  const menus = [
    {
      path: `${matchUrl}/overview`,
      title: 'Overview',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BOOK.OVERVIEW.VIEW,
      ],
      route: BookOverviewEditor,
    },
    {
      path: `${matchUrl}/categories`,
      title: 'Categories',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BOOK.CATEGORIES.VIEW,
      ],
      route: BookCategoriesEditor,
    },
    {
      path: `${matchUrl}/details`,
      title: 'Details',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BOOK.DETAILS.VIEW,
      ],
      route: BookDetailsEditor,
    },
    {
      path: `${matchUrl}/pricing`,
      title: 'Pricing',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BOOK.PRICING.VIEW,
      ],
      route: BookPricingEditor,
    },
    {
      path: `${matchUrl}/stock`,
      title: 'Stock',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BOOK.STOCK.VIEW,
      ],
      route: BookStocksEditor,
    },
  ];

  return menus;
};
const BookDetails = withTheme((props) => {
  const { book, match, theme } = props;
  return (
    <>
      <Container fluid style={{ backgroundColor: theme.colors.primary }}>
        <Container>
          <SKUHeaderWithImage {...book} updateSKUMutation={updateBookMutation} />
        </Container>
      </Container>
      <Divider hidden />
      <Menu menus={genMenu(match.url)} menuProps={{ secondary: true, pointing: true }} />
      <GenRoute menus={genMenu(match.path)} book={book} />
    </>
  );
});

export default BookDetails;
