import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

import { SUBSCRIPTION_PLATFORM_OPTIONS } from '../../Domains/constants';

/**
 * @param {string} initValue - externalConfig | ex. { platform: 'IOS', externalId: '30_days_mid_sci_math_subscription'}
 */
const SubscriptionExternalInput = ({ value: initValue, onChange }) => {
  const [externalId, setExternalId] = useState(initValue ? initValue.externalId : null);
  const [platform, setPlatform] = useState(initValue ? initValue.platform : null);

  useEffect(() => {
    onChange({}, { value: { platform: platform, externalId: externalId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, externalId]);

  return (
    <Box>
      <Flex>
        <Dropdown
          options={SUBSCRIPTION_PLATFORM_OPTIONS}
          onChange={(e, { value }) => setPlatform(value)}
          value={platform}
          search
          fluid
          selection
          defaultValue={null}
          placeholder="NONE"
        />
      </Flex>
      <Box mt={3} />
      {platform && (
        <Flex alignItems="baseline" flexDirection="column">
          <label>External ID</label>
          <Input
            type="text"
            onChange={(e, { value }) => setExternalId(value)}
            defaultValue={externalId}
          />
        </Flex>
      )}
    </Box>
  );
};

SubscriptionExternalInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  status: PropTypes.string,
};

export default withFormsy(SubscriptionExternalInput);
