import React, { Component } from 'react';
import queryString from 'query-string';
import { Card, Grid, Container, Icon } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';

import fetch2, { METHOD } from 'src/Util/fetch';
import getConfig from 'src/Util/Config';
import ConfirmationButton from 'src/Components/ConfirmationButton';

import { SemanticButton as Button } from '../../../Components/Base/Button';

const USER_API = getConfig().userApi;

class ResetPassword extends Component {
  state = {
    showPassword: false,
    showConfirmPassword: false,
  };

  handleResetPassword = (form) => {
    const { password, passwordVerify } = form;

    if (password.length < 6) {
      return this.setState({ error: 'Password must be at least 6 characters long.' });
    }

    if (password !== passwordVerify) {
      return this.setState({ error: 'Password mismatch' });
    }
    const parsed = queryString.parse(this.props.location.search);
    fetch2(`${USER_API}/resetpassword/${parsed.token}`, {}, METHOD.POST, {
      password,
    })
      .then(() => {
        this.setState({ success: 'Reset password successful' });
      })
      .catch((reason) => this.setState({ error: reason.message }));
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowConfirmPassword = () => {
    this.setState((state) => ({ showConfirmPassword: !state.showConfirmPassword }));
  };

  render() {
    const { error, success } = this.state;
    return (
      <Container>
        <Grid centered columns={2}>
          <Grid.Column>
            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Form onValidSubmit={this.handleResetPassword}>
                  <Form.Field>
                    <label>Password</label>
                    <Input
                      required
                      name="password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      placeholder="At least 6 charactors"
                      icon={
                        <Icon
                          name={this.state.showPassword ? 'eye slash' : 'eye'}
                          link
                          onClick={this.handleClickShowPassword}
                        />
                      }
                      iconPosition="end"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Confirm password</label>
                    <Input
                      name="passwordVerify"
                      type={this.state.showConfirmPassword ? 'text' : 'password'}
                      placeholder="At least 6 charactors"
                      icon={
                        <Icon
                          name={this.state.showConfirmPassword ? 'eye slash' : 'eye'}
                          link
                          onClick={this.handleClickShowConfirmPassword}
                        />
                      }
                      iconPosition="end"
                    />
                  </Form.Field>

                  {error && (
                    <Form.Field>
                      <label style={{ color: 'red' }}>{error}</label>
                    </Form.Field>
                  )}

                  <Button primary circular type="submit">
                    Submit
                  </Button>
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        {success && (
          <ConfirmationButton
            defaultOpen={true}
            cancelText={null}
            confirmText={'OK'}
            contentText={success}
            onConfirm={() => this.props.history.push('/login')}
          ></ConfirmationButton>
        )}
      </Container>
    );
  }
}

export default ResetPassword;
