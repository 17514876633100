import React, { useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex, Text, Box, ButtonFilled, ButtonText } from 'Components/Base';
import StatefulModal from 'Components/Modal/StatefulModal';
import useDisclosure from 'Util/useDisclosure';

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const DeleteIconModal = ({ title, description, onConfirmClick }) => {
  const { isOpen, open, close } = useDisclosure();

  const MemoDeleteIcon = useMemo(
    () => (
      <DeleteIcon
        style={{ color: 'inherit', width: '20px', height: '20px', cursor: 'pointer' }}
        onClick={open}
      />
    ),
    [open]
  );

  return (
    <>
      {MemoDeleteIcon}
      {isOpen && (
        <StatefulModal open={isOpen} trigger={<></>}>
          {() => (
            <Box py={3}>
              <Box px={4} py={4}>
                <Text.Header fontSize="18px" lineHeight="24px" mb={1}>
                  {title}
                </Text.Header>
                <Text fontSize="16px" lineHeight="24px">
                  {description}
                </Text>
              </Box>
              <Divider />
              <Flex justifyContent="flex-end" px={4} pt={'24px'}>
                <ButtonText onClick={close} color="primary" px={3} py={2}>
                  Cancel
                </ButtonText>
                <ButtonFilled
                  onClick={() => {
                    onConfirmClick();
                    close();
                  }}
                  bg="primary"
                  color="white"
                  borderRadius={'100px'}
                  px={3}
                  py={2}
                >
                  Confirm
                </ButtonFilled>
              </Flex>
            </Box>
          )}
        </StatefulModal>
      )}
    </>
  );
};

DeleteIconModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};

export default DeleteIconModal;
