import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { useSearchFilter } from '../Utils';
import { GET_ALL_APP_ROLES } from '../Graphql/query/Role.query';

const BASIC_COLUMN = {
  ID: {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 150,
  },
  NAME: {
    Header: 'Role Name',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 250,
  },
  DESCRIPTION: {
    Header: 'Description',
    accessor: 'description',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  // {
  //   Header: 'User',
  //   accessor: 'user',
  //   type: COLUMN_TYPE.STRING,
  //   isSearchAble: false,
  // }
};

const SearchRoleList = React.forwardRef(({ columns, resolveData }, ref) => {
  const {
    onSortedChange,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    search,
    order,
    paging,
  } = useSearchFilter();
  const getRolesData = useCallback(resolveData, [resolveData]);
  return (
    <QueryTable
      ref={ref}
      type={'appRoles'}
      columns={columns}
      query={GET_ALL_APP_ROLES}
      resolveData={getRolesData}
      search={search}
      onSearchChange={onSearchChange}
      paging={paging}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      order={order}
      onSortedChange={onSortedChange}
    />
  );
});

const DEFAULT_ROLE_COLUMNS = Object.values(BASIC_COLUMN);
SearchRoleList.displayName = 'SearchRoleList';
SearchRoleList.propTypes = {
  columns: PropTypes.object,
  resolveData: PropTypes.func,
};

SearchRoleList.defaultProps = {
  columns: DEFAULT_ROLE_COLUMNS,
};

export { BASIC_COLUMN, DEFAULT_ROLE_COLUMNS };
export default SearchRoleList;
