import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import ConfirmationButton from 'Components/ConfirmationButton';

import AnnouncementModal from '../Containers/AnnouncementModal';

import { STATUS } from './constants';

const ActionCell = ({ handleOnDelete, original, variables }) => {
  const announcementId = original.id;
  const publishDate = original.publishedTime;
  const isPast = new Date() > new Date(publishDate) && original.status !== STATUS.DRAFT;
  const isPublished = original.status === STATUS.PUBLISHED;
  return (
    <Flex alignItems="center" justifyContent="flex-start">
      <Button.Group style={{ flex: 1 }}>
        <AnnouncementModal
          isView
          variables={variables}
          announcementId={announcementId}
          trigger={
            <Button basic icon>
              <Icon name="eye" />
            </Button>
          }
        />
        <AnnouncementModal
          isEdit
          variables={variables}
          announcementId={announcementId}
          trigger={
            <Button basic icon disabled={isPublished || isPast}>
              <Icon name="edit" />
            </Button>
          }
        />
        <ConfirmationButton
          onConfirm={() => handleOnDelete(announcementId)}
          headerText={`Delete Announcement?`}
          confirmText="Delete"
          contentText="This announcement will be soft deleted from the system."
          disabled={isPublished || isPast}
        >
          <Icon name="trash alternate" color="red" />
        </ConfirmationButton>
      </Button.Group>
    </Flex>
  );
};

ActionCell.propTypes = {
  original: PropTypes.object,
  handleOnDelete: PropTypes.func,
  variables: PropTypes.object,
};

export default ActionCell;
