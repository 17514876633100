/* eslint-disable react/display-name */
import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import _ from 'lodash';
import { Button, Icon, Label } from 'semantic-ui-react';

import getConfig from 'Util/Config';
import { Box, Flex } from 'Components/Base';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable, { DATA_FORMAT } from 'GraphQL/util/QueryTable';
import ConfirmationButton from 'Components/ConfirmationButton';
import { getFormatter } from 'GraphQL/util/QueryTable/dataUtil';
import withAppConfig from 'Util/hoc/withAppConfig';
import { ROUTES } from 'Routes/Main/constants';

import { randomDiscountCodeJobUrl } from '../../Domains/resolveUrl';
import { getDiscountCodes } from '../../GraphQL/query/DiscountCode.query';
import { deleteDiscountCode } from '../../GraphQL/mutation/DiscountCode.mutation';
import { DiscountCodeModal } from '../../components/DiscountCodeModal';
import { RandomizedDiscountCodeModal } from '../../components/RandomizedDiscountCodeModal';
import { ProductSelectorInput } from '../../components/ProductSelectorInput';
import { genProductTypesSKUText } from '../../Domains/utils';
import { TextAlignRight } from '../../components/TextAlignRight';

const { discountCode: discountConfig } = getConfig();
const moneyFormatter = getFormatter(COLUMN_TYPE.NUMBER, DATA_FORMAT.NUMBER_FORMAT_FLOAT);
const columns = (handleDelete, variables) => [
  {
    Header: 'Promo Code',
    accessor: 'code',
    width: 120,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'มูลค่า',
    accessor: 'discount',
    width: 150,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
    Cell: ({ original }) => {
      if (original.discountUnit === 'FIX') {
        const value = original.discount;
        return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
      }
      return <TextAlignRight>{original.discount}</TextAlignRight>;
    },
  },
  {
    Header: 'หน่วย',
    accessor: 'discountUnit',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: 'FIX', value: 'FIX', text: 'FIX' },
      { key: 'PERCENT', value: 'PERCENT', text: 'PERCENT' },
    ],
    isSearchAble: true,
  },
  {
    Header: 'SKUs',
    accessor: 'SKUIds',
    width: 200,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    CustomSearchComponent: (props) => <ProductSelectorInput {...props} />,
    Cell: ({ original }) => (
      <Flex flexWrap="wrap" m={-1}>
        {original?.productTypes?.map((productType) => (
          <Flex key={productType} p={1}>
            <Label>{genProductTypesSKUText(productType)}</Label>
          </Flex>
        ))}
        {original?.SKUCodes.map((SKUCode) => (
          <Flex key={SKUCode} p={1}>
            <Label>{SKUCode}</Label>
          </Flex>
        ))}
      </Flex>
    ),
  },
  {
    Header: 'Except SKUs',
    accessor: 'exceptSKUIds',
    width: 200,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    CustomSearchComponent: (props) => <ProductSelectorInput {...props} />,
    Cell: ({ original }) => (
      <Flex flexWrap="wrap" m={-1}>
        {original?.exceptSKUCodes?.map((SKUCode) => (
          <Flex key={SKUCode} p={1}>
            <Label>{SKUCode}</Label>
          </Flex>
        ))}
      </Flex>
    ),
  },
  {
    Header: 'วันที่เริ่มต้น',
    accessor: 'startDate',
    width: 100,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'วันหมดอายุ',
    accessor: 'endDate',
    width: 100,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'เงื่อนไข',
    // accessor: 'expiryDate',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ original }) => (
      <>
        {original?.conditions
          ?.map((val) => {
            if ('days' in val) return `จำนวนวันก่อนหมดอายุ: ${val.days}`;
            if ('expiryDate' in val) return `วันหมดอายุ: ${val.expiryDate}`;
            return '';
          })
          .map((val, index) => (
            <div key={index}>{val}</div>
          ))}
      </>
    ),
  },
  {
    Header: 'จุดประสงค์ในการออก Discount Code',
    accessor: 'discountText',
    width: 240,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Reference Code',
    accessor: 'refCode',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: discountConfig?.enabledRefCode,
    isHideColumn: !discountConfig?.enabledRefCode,
  },
  {
    Header: 'สกุลเงิน',
    accessor: 'currency',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [{ key: 'THB', value: 'THB', text: 'THB' }],
    isSearchAble: true,
  },
  {
    Header: 'จำนวนตั้งต้น',
    accessor: 'total',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: 'จำนวนคงเหลือ',
    accessor: 'remain',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: 'จำนวนลูกค้า',
    accessor: 'minimumQuantity',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: 'ประเภทลูกค้า',
    accessor: 'customerType',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: ({ original }) => {
      const discountId = original.id;
      return (
        <Fragment>
          <Button.Group>
            <DiscountCodeModal
              isEdit
              variables={variables}
              discountId={discountId}
              data={original}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="edit" />
                </Button>
              }
            />
            <ConfirmationButton
              headerText={`Delete?`}
              contentText={'This will be permanently deleted from the system.'}
              confirmText={'Delete'}
              onConfirm={() => {
                handleDelete(discountId);
              }}
            >
              <Icon name="trash alternate outline" />
            </ConfirmationButton>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class DiscountCodeRoute extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'code', type: 'ASC' },
  };
  mutateDiscount = (mutation, discountId) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    client
      .mutate({
        mutation,
        variables: { id: discountId },
        refetchQueries: [
          {
            query: getDiscountCodes,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };
  resolveData = (data) => {
    return data.sfBoPaymentSearchDiscountCodes.discountCodes;
  };

  handleDelete = (discountId) => {
    this.mutateDiscount(deleteDiscountCode, discountId);
  };
  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };

  handleButtonOnClick = () => this.props.history.push('/sf/file-import/discount-codes');
  handleSuccessCreateRandomJob = () => this.props.history.push(randomDiscountCodeJobUrl);

  render() {
    const { search, paging, order } = this.state;
    const { appConfig } = this.props;
    const variables = { search, paging, order };
    const { featuredRoutes } = appConfig ?? {};

    const enabledImport = featuredRoutes?.includes(ROUTES.SALES_V2_FILE_IMPORT) ?? false;

    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Discount code</Text.Header>
          <Box>
            {/* <RandomizedDiscountCodeModal
              trigger={
                <Button primary circular>
                  <Icon name="plus" size="small" />
                  Random code
                </Button>
              }
              onSuccess={this.handleSuccessCreateRandomJob}
            /> */}
            <DiscountCodeModal
              variables={variables}
              trigger={
                <Button primary circular>
                  <Icon name="plus" size="small" />
                  New
                </Button>
              }
            />
            {enabledImport ? (
              <Button primary circular onClick={this.handleButtonOnClick}>
                <Icon name="plus" size="small" />
                Import
              </Button>
            ) : null}
          </Box>
        </Flex>
        <QueryTable
          type="sfBoPaymentSearchDiscountCodes"
          resolveData={this.resolveData}
          columns={columns(this.handleDelete, variables)}
          query={getDiscountCodes}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  withAppConfig({ configProp: 'appConfig' })
)(DiscountCodeRoute);
