import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Header, Grid, Icon, Label, List, Card } from 'semantic-ui-react';

import { Box, Flex, Image } from 'Components/Base';
import { RolesLabel } from 'Components/UserItem';
import getConfig from 'src/Util/Config';

const PROFILE_IMAGE_EMPTY = '/profile-empty.png';

const TitleSection = ({ title, referenceId }) => (
  <Fragment>
    <Header size="large">{title}</Header>
    {referenceId}
  </Fragment>
);

const ProfileImageSection = ({ imageUrl }) => (
  <Image
    src={getDefaultValue(imageUrl, PROFILE_IMAGE_EMPTY)}
    width={'150px'}
    borderRadius={'50%'}
    style={{ height: 150 }}
  />
);

const getDefaultValue = (value, defaultValue = '') =>
  value !== null && value !== undefined ? value : defaultValue;

const getPositionAndCompany = (position, company) => {
  if (!position && !company) return '-';
  return `${getDefaultValue(position, '-')}, ${getDefaultValue(company, 'No Company')}`;
};

const AdditionalDataSection = ({ data }) => {
  const { additionalDataLabels, additionalDataIndex } = getConfig();
  return (
    <List>
      {additionalDataIndex.map((idx) => (
        <List.Item key={`additionalData${idx}`}>
          <List.Header>
            {getDefaultValue(
              additionalDataLabels[`additional${idx}`].value,
              `additionalData${idx}`
            )}
          </List.Header>
          <List.Content>{getDefaultValue(data[`additional${idx}`], '-')}</List.Content>
        </List.Item>
      ))}
    </List>
  );
};

const InfoSection = ({
  organizationName,
  email,
  emailVerified,
  position,
  phoneNumber,
  citizenId,
}) => (
  <List>
    <List.Item>
      <List.Icon name="building" />
      <List.Content>{getPositionAndCompany(position, organizationName)}</List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name="id card outline" />
      <List.Content>{getDefaultValue(citizenId, '-')}</List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name="mail" />
      <List.Content verticalAlign="middle">
        {email}
        {typeof emailVerified === 'boolean' && (
          <List.Description>
            <Label color={emailVerified ? 'green' : 'red'} size="mini">
              {emailVerified ? 'Verified' : 'Pending Verification'}
            </Label>
          </List.Description>
        )}
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name="phone" />
      <List.Content>{getDefaultValue(phoneNumber, '-')}</List.Content>
    </List.Item>
  </List>
);

const UserHeader = ({
  title,
  imageUrl,
  email,
  emailVerified,
  roles,
  referenceId,
  organizationName,
  position,
  phoneNumber,
  citizenId,
  additionalData,
}) => {
  return (
    <Grid textAlign="left">
      <Grid.Column mobile={16} tablet={4} computer={4}>
        <Grid.Row>
          <Flex justifyContent="center">
            <ProfileImageSection imageUrl={imageUrl} />
          </Flex>
        </Grid.Row>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={12} computer={12}>
        <Grid.Row>
          <TitleSection title={title} referenceId={referenceId} />
        </Grid.Row>
        <Grid.Row>
          <Box mt={1}>
            <RolesLabel roles={roles} />
          </Box>
        </Grid.Row>
        <Grid.Row>
          <Box mt={3}>
            <Grid>
              <Grid.Column mobile={16} tablet={7} computer={7}>
                <InfoSection
                  email={email}
                  emailVerified={emailVerified}
                  organizationName={organizationName}
                  position={position}
                  phoneNumber={phoneNumber}
                  citizenId={citizenId}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={9} computer={9}>
                <AdditionalDataSection data={additionalData} />
              </Grid.Column>
            </Grid>
          </Box>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

UserHeader.propTypes = {
  title: PropTypes.string,
  referenceId: PropTypes.string,
  imageUrl: PropTypes.string,
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  userId: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  organizationName: PropTypes.string,
  position: PropTypes.string,
  phoneNumber: PropTypes.string,
  citizenId: PropTypes.string,
  additionalData: PropTypes.object,
};

UserHeader.defaultProps = {
  imageUrl: PROFILE_IMAGE_EMPTY,
};

export default UserHeader;
