import React from 'react';
import PropTypes from 'prop-types';
import { Button as SemanticButtonView, Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { width } from 'styled-system';
import { darken } from 'polished';

import withDebounceOnClick from 'Util/hoc/withDebounceOnClick';

import { COLOR } from '../../Theme';

const SEMANTIC_COLORS = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
  'facebook',
  'google plus',
  'instagram',
  'linkedin',
  'twitter',
  'vk',
  'youtube',
];

const SButton = styled(({ customColor, ...restProps }) => <SemanticButtonView {...restProps} />)`
  &.ui.button {
    padding: 0.6em 1.8em;
    font-size: 16px;
    font-weight: 500;
    ${width};
    ${({ customColor, inverted }) =>
      customColor &&
      css`
        color: ${inverted ? customColor : `white`};
        background: ${inverted ? `white` : customColor};
        box-shadow: 0 0 0 2px ${({ customColor }) => customColor} inset !important;
        :focus {
          box-shadow: 0 0 0 2px ${({ customColor }) => customColor} inset !important;
          color: ${inverted ? customColor : `white`};
          background: ${inverted ? `white` : customColor};
        }
        :hover {
          color: white;
          background: ${({ customColor, inverted }) =>
            inverted ? customColor : darken('0.1', customColor)};
        }
        :disabled {
          background: ${COLOR.bsGrays.gray500};
          box-shadow: 0 0 0 2px ${COLOR.bsGrays.gray500} inset !important;
        }
      `};
  }
`;

const ButtonView = ({ icon, title, color, ...restProps }) => {
  const iconComponent = React.isValidElement(icon) ? icon : <Icon name={icon} />;
  let colorProp = {};
  if (color) {
    if (SEMANTIC_COLORS.includes(color)) {
      colorProp = { color };
    } else {
      colorProp = { customColor: color };
    }
  }
  return (
    <SButton circular {...colorProp} {...restProps}>
      {icon && iconComponent}
      {title}
    </SButton>
  );
};

ButtonView.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  fluid: PropTypes.bool,
};

ButtonView.defaultProps = {
  title: 'Default Title',
  icon: '',
  inverted: false,
  onClick: () => {},
  type: 'button',
  size: 'medium',
  className: null,
  fluid: false,
};

const Button = withDebounceOnClick()(ButtonView);
const SemanticButton = withDebounceOnClick()(SemanticButtonView);

export { Button, SemanticButton };
export default Button;
