import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import Loading from 'Components/Loading';
import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createStudentAdvisorTeacherMutation,
  updateStudentAdvisorTeacherMutation,
} from 'GraphQL/LSP/mutation/Profile.mutation';
import {
  getTeacherStudents,
  getTeacherStudent,
  getAllStudentProfiles,
} from 'GraphQL/LSP/query/Profile.query';

import TeacherStudentForm from './TeacherStudentForm';

function TeacherStudentModal(props) {
  const { isEdit, id, trigger, variables, advisorTeacherId } = props;
  const { loading, error, data: studentData } = useQuery(getAllStudentProfiles, {
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{isEdit ? 'แก้ไขนักเรียนที่ดูแล' : 'เพิ่มนักเรียนที่ดูแล'}</Modal.Header>
          <Modal.Content>
            <CreateEditForm
              dataQuery={getTeacherStudent}
              createMutation={createStudentAdvisorTeacherMutation}
              createRefetchQueries={[
                {
                  query: getTeacherStudents,
                  variables,
                },
                {
                  query: getAllStudentProfiles,
                  fetchPolicy: 'network-only',
                },
              ]}
              editRefetchQueries={[
                {
                  query: getTeacherStudents,
                  variables,
                },
                {
                  query: getAllStudentProfiles,
                  fetchPolicy: 'network-only',
                },
              ]}
              updatetation={updateStudentAdvisorTeacherMutation}
              isEdit={isEdit}
              id={id}
              getQueryVariables={({ id }) => ({
                userId: id,
              })}
              getEditVariables={(form, { id }) => ({
                // Not being used for now
                id,
                advisorTeacherId,
                studentId: form.studentId,
              })}
              getCreateVariables={(form) => ({
                advisorTeacherId,
                studentId: form.studentId,
              })}
              onCompleted={closeModal}
              onCancelled={closeModal}
            >
              {({ data, onSubmit }) => {
                const initialData = data
                  ? { ...data.boTeacherStudent, ...data.boTeacherStudent.address }
                  : {};
                return (
                  <TeacherStudentForm
                    studentData={studentData && studentData.boAllStudentProfiles}
                    initialData={initialData}
                    onSubmit={onSubmit}
                  />
                );
              }}
            </CreateEditForm>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default TeacherStudentModal;
