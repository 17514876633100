import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../Components/Modal/StatefulModal';
import CreateEditForm from '../../Containers/CreateEditForm';
import {
  createCertificateMutation,
  updateCertificateMutation,
} from '../../GraphQL/mutation/Certificate.mutation';
import { getCertificates, getCertificate } from '../../GraphQL/query/Certificate.query';

import CertificateForm from './CertificateForm';

export default class CertificateModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    certificateId: PropTypes.string,
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { isEdit, certificateId, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>
              {isEdit ? 'Edit Certificate Information' : 'Create New Certificate'}
            </Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getCertificate}
                createMutation={createCertificateMutation}
                createRefetchQueries={[
                  {
                    query: getCertificates,
                    variables,
                  },
                ]}
                editMutation={updateCertificateMutation}
                isEdit={isEdit}
                id={certificateId}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  certificateData: form,
                })}
                getCreateVariables={(form) => ({
                  certificateData: form,
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <CertificateForm initialData={data && data.certificate} onSubmit={onSubmit} />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
