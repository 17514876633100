import React, { Fragment, Component } from 'react';
import { Icon } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import { SemanticButton as Button } from '../../../Components/Base/Button';
import Text from '../../../Components/Base/Text';
import apolloClient from '../../../GraphQL';
import ConfirmationButton from '../../../Components/ConfirmationButton';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';
import QueryTable from '../../../GraphQL/util/QueryTable';
import { deletePrice } from '../../../GraphQL/mutation/Sales.mutation';
import { prices } from '../../../GraphQL/query/Sales.query';
import { Format } from '../common';

import PriceModal from './PriceModal';
const pricesColumns = (handleDelete, variables) => [
  {
    Header: 'Course Refer Id',
    accessor: 'itemId',
    width: 300,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Full Price',
    accessor: 'fullPrice',
    width: 200,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
    Cell: ({ value }) => <Format amount={value} />,
  },
  {
    Header: 'Promotion Price',
    accessor: 'promotionPrice',
    width: 200,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
    Cell: ({ value }) => <Format amount={value} />,
  },
  {
    Header: 'Promotion Text',
    accessor: 'promotionText',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [{ key: 'THB', value: 'THB', text: 'THB' }],
    isSearchAble: true,
  },
  {
    Header: 'Actions',
    accessor: 'id',
    width: 100,
    Cell: ({ original }) => {
      const { id: priceId, ...data } = original;
      return (
        <Fragment>
          <Button.Group>
            <PriceModal
              isEdit
              data={data}
              priceId={priceId}
              variables={variables}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="edit" />
                </Button>
              }
            />
            <ConfirmationButton
              headerText={`Delete?`}
              contentText={'This will be permanently deleted from the system.'}
              confirmText={'Delete'}
              onConfirm={() => handleDelete(priceId)}
            >
              <Icon name="trash alternate outline" />
            </ConfirmationButton>
          </Button.Group>
        </Fragment>
      );
    },
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
  },
];

class PriceSales extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'createdAt', type: 'DESC' },
  };

  priceMutate = (mutation, priceId) => {
    const { search, paging, order } = this.state;
    apolloClient
      .mutate({
        mutation,
        variables: { priceId },
        refetchQueries: [
          {
            query: prices,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  handleDelete = (priceId) => {
    this.priceMutate(deletePrice, priceId);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Price</Text.Header>
          <PriceModal
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type="prices"
          columns={pricesColumns(this.handleDelete, variables)}
          query={prices}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default PriceSales;
