import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Code',
    inputName: 'code',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ชื่อระดับชั้น',
    inputName: 'name',
    inputProps: {
      required: true,
    },
  },
];

export default class SchoolGradeForm extends Component {
  static propTypes = {
    onCreateSchoolGrade: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateSchoolGrade: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateSchoolGrade(form);
  };

  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={fields}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
