import gql from 'graphql-tag';

export const getUserSubscriptions = gql`
  query sfBoShipmentUserSubscriptions(
    $search: SFBoUserSubscriptionInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoShipmentUserSubscriptions(search: $search, paging: $paging, order: $order) {
      total
      userSubscriptions {
        id
        userId
        SKUCode
        title
        referSKUId
        endDate
        customerType
        customerFullName
        customerMobile
        orderId
        orderNo
        paymentAmount
        unit
        paymentMethod
        recurring {
          expirationDate
          cancellationDate
          platform
        }
      }
    }
  }
`;
