import React, { Fragment } from 'react';
import moment from 'moment';

import delve from '../../../Util/Delve';
import Table from '../../../Components/DataTable';

const subPayment = [
  {
    Header: 'CourseRefer Id',
    accessor: 'courseReferId',
  },
  { Header: 'Course Title', accessor: 'detail' },
  {
    Header: 'Discounted Amount',
    accessor: 'discountedAmount',
  },
  {
    Header: 'Amount Ratio',
    accessor: 'amountRatio',
  },
];

export const PURCHASE_STATUS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN-PROGRESS',
  WAITING_FOR_APPROVAL: 'WAITING-FOR-APPROVAL',
  PAID: 'PAID',
  SUCCESS: 'SUCCESS',
  EXPIRED: 'VOID',
};

export const DateFormat = ({ date }) => <Fragment> {moment(date).format('LLL')} </Fragment>;
export const Format = ({ amount }) => (
  <Fragment>{typeof amount === 'number' ? amount.toFixed(2) : amount}</Fragment>
);
export const PaymentMethodFormat = ({ method = {} }) => (
  <Fragment>{(delve(method, 'paymentMethod') || '').replace(/_/g, ' ')}</Fragment>
);

const getPurchaseHistoryByStatus = (histories, status = PURCHASE_STATUS.WAITING_FOR_APPROVAL) => {
  if (!Array.isArray(histories)) {
    return null;
  }
  return histories.find((purchaseHistory) => purchaseHistory.status === status);
};

export const getPurchaseReceipt = (originalData) => {
  const purchaseHistory = delve(originalData, 'histories');
  const waitingForApprovalHistory = getPurchaseHistoryByStatus(purchaseHistory);
  return delve(waitingForApprovalHistory, 'detail');
};

export const getExpiredAt = (originalData) => {
  const purchaseHistory = delve(originalData, 'histories');
  const expiredHistory = getPurchaseHistoryByStatus(purchaseHistory, PURCHASE_STATUS.EXPIRED);
  return delve(expiredHistory, 'createdAt');
};

export const getCreatedAt = (originalData) => {
  const purchaseHistory = delve(originalData, 'histories');
  const newHistory = getPurchaseHistoryByStatus(purchaseHistory, PURCHASE_STATUS.NEW);
  return delve(newHistory, 'createdAt');
};

export const subComponentPayMent = (row) => {
  if (!row.original.purchaseItems) {
    return;
  } else {
    return (
      <div style={{ padding: '20px' }}>
        <Table
          data={row.original.purchaseItems}
          columns={subPayment}
          showPagination={false}
          defaultPageSize={2}
        />
      </div>
    );
  }
};
