import React from 'react';
import { VideoPlayer } from '@skooldio/skooldio-video-player';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import _ from 'lodash';

import { Box, Flex } from 'Components/Base';

const OnlineCourseTableOfContentPreviewVideo = ({ previewVideoUrl }) => {
  let source = {
    src: previewVideoUrl,
    type: 'application/x-mpegURL',
  };

  const youtubeRegexUrl = new RegExp(/^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/);
  if (youtubeRegexUrl.test(previewVideoUrl)) {
    source.type = 'video/youtube';
  }

  return <VideoPlayer width="100%" src={source} />;
};

const OnlineCourseTableOfContentPreviewVideoForm = ({
  previewVideo,
  handleFormChange,
  referId,
}) => {
  return (
    <>
      {previewVideo?.url ? (
        <Box height="500px">
          <OnlineCourseTableOfContentPreviewVideo previewVideoUrl={previewVideo?.url} />
        </Box>
      ) : (
        ''
      )}
      <Flex alignItems="center" mt={1}>
        Preview Video Url (รองรับ Public Video ในรูปแบบ Youtube หรือ HLS เท่านั้น):
        <Input
          style={{ margin: '5px 10px', width: '400px' }}
          key={`previewVideo` + previewVideo?.courseItemId}
          value={previewVideo?.url}
          onChange={(e) => {
            previewVideo.url = e.target.value;
            handleFormChange(referId, 'previewVideo', _.omit(previewVideo, ['__typename']));
          }}
        />
      </Flex>
      <Flex alignItems="center" mt={1}>
        Time (seconds):
        <Input
          type="number"
          style={{ margin: '5px 10px', width: '100px' }}
          key={`time` + previewVideo?.courseItemId}
          value={previewVideo?.time}
          onChange={(e) => {
            previewVideo.time = parseInt(e.target.value);
            handleFormChange(referId, 'previewVideo', _.omit(previewVideo, ['__typename']));
          }}
        />
      </Flex>
    </>
  );
};

OnlineCourseTableOfContentPreviewVideo.propTypes = {
  previewVideoUrl: PropTypes.string,
};

OnlineCourseTableOfContentPreviewVideoForm.propTypes = {
  previewVideo: PropTypes.object,
  handleFormChange: PropTypes.func,
  referId: PropTypes.string,
};

export default OnlineCourseTableOfContentPreviewVideoForm;
