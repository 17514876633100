import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { Box, Text, Flex } from 'Components/Base';
import { COLOR, FONT_SIZE } from 'Components/Theme';
import { getExamById } from 'GraphQL/query/Exam.query';

import { EXAM_GENERATOR_TYPE, EXAM_QUESTION_STATUS } from '../../constants';

import DeleteQuestionButton from './DeleteQuestionButton';
import DuplicateQuestionButton from './DuplicateQuestionButton';

const ConfigurableQuestionFormHeader = ({
  question,
  examId,
  isEdit,
  publishMode,
  examItemId,
  onComplete,
}) => {
  const { data: examData } = useQuery(getExamById, { variables: { examId } });
  const status = question?.status ?? EXAM_QUESTION_STATUS.DRAFT;
  const isPublished = status === EXAM_QUESTION_STATUS.PUBLISHED;
  const showDeleteButton = !isPublished && isEdit && !publishMode;
  const examGeneratorType = examData?.examExam?.generatorType;
  const hideDuplicateButton =
    !isEdit || (isPublished && examGeneratorType === EXAM_GENERATOR_TYPE.FIXED);

  let title = 'Create Question';
  if (isEdit) title = 'Edit Question';
  if (isPublished) title = 'View Question';
  else if (publishMode) title = 'Publish Question';

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      mb={4}
      pb={2}
      style={{ borderBottom: `1px solid ${COLOR.greyscale.grey4}` }}
    >
      <Text.Header>{title}</Text.Header>
      <Flex alignItems="center">
        {isEdit || isPublished ? (
          <Text fontSize={FONT_SIZE.semilarge} color={COLOR.black} fontWeight="bold">
            {`Status: ${status}`}
          </Text>
        ) : (
          <Box />
        )}
        {!hideDuplicateButton && (
          <Box alignSelf="center" ml={3}>
            <DuplicateQuestionButton
              examId={examId}
              onCompleted={onComplete}
              examItemId={examItemId}
            />
          </Box>
        )}
        {showDeleteButton && (
          <Box alignSelf="center" ml={3}>
            <DeleteQuestionButton
              examId={examId}
              onCompleted={onComplete}
              examItemId={examItemId}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

ConfigurableQuestionFormHeader.propTypes = {
  question: PropTypes.object,
  onComplete: PropTypes.func,
  isEdit: PropTypes.bool,
  publishMode: PropTypes.bool,
  examId: PropTypes.string,
  examItemId: PropTypes.string,
};

ConfigurableQuestionFormHeader.defaultProps = {
  question: {},
  onComplete: () => {},
  isEdit: false,
  publishMode: false,
};

export default ConfigurableQuestionFormHeader;
