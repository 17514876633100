import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';
import { generateDataTestId } from 'Util/generateDataTestId';

import { SemanticButton as Button } from '../../Base/Button';

const ActionBar = ({
  isCreate,
  onAction,
  onCancel,
  loading,
  materialTypeString,
  disableSubmit,
  containerProps,
}) => (
  <Flex justifyContent="space-between" alignItems="center" mb={3} {...containerProps}>
    <Box />
    <Box>
      <Button
        primary
        circular
        onClick={onAction}
        loading={loading}
        disabled={(loading || disableSubmit) && isCreate}
        data-testid={`${generateDataTestId(
          isCreate ? `btn-create-${materialTypeString}` : `btn-update-${materialTypeString}`
        )}`}
      >
        <Icon name="check" size="small" />
        {isCreate ? `Create ${materialTypeString}` : `Update ${materialTypeString}`}
      </Button>
      <Button
        circular
        onClick={onCancel}
        loading={loading}
        disabled={loading}
        data-testid={generateDataTestId(`btn-cancel-${materialTypeString}`)}
      >
        <Icon size="small" />
        Cancel
      </Button>
    </Box>
  </Flex>
);

ActionBar.propTypes = {
  isCreate: PropTypes.bool,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  materialTypeString: PropTypes.string,
};

ActionBar.defaultProps = {
  onAction: () => {},
  onCancel: () => {},
  materialTypeString: 'Data',
};

export default ActionBar;
