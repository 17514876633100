import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';

import omitDeep from 'Util/omitDeep';
import { Flex, Box, Text } from 'Components/Base';
import Loading from 'Components/Loading';
import delve from 'Util/Delve';

import EditTable from '../SKUTableEditor';

const columns = [
  {
    title: 'Icon',
    field: 'icon',
    render: (rowData) => (
      <Flex>
        <Box mx={2} className={`mdi ${rowData.icon}`} />
        {rowData.icon}
      </Flex>
    ),
  },
  { title: 'Label', field: 'label' },
  { title: 'Description', field: 'content' },
];

const BaseHighlightEditor = ({
  dataQuery,
  dataQueryName,
  variableQuery,
  handleMutation,
  getHandleVariables,
  refetchQueries,
}) => {
  const { loading, error, data } = useQuery(dataQuery, {
    variables: variableQuery,
    fetchPolicy: 'network-only',
  });

  const [
    updateHighlight,
    { loading: mutationLoading, error: mutationError, data: mutationResponse },
  ] = useMutation(handleMutation);

  const handleHighlightSave = (updatedIconList) => {
    const clonedData = _.cloneDeep(updatedIconList);
    const highlightsInput = omitDeep(clonedData, ['__typename', 'tableData']);
    updateHighlight({
      variables: getHandleVariables(highlightsInput),
      refetchQueries: refetchQueries,
    });
  };

  // useQuery return freeze object, but table trying to add field
  // https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
  const tableData = _.cloneDeep(delve(data, `${dataQueryName}.highlights`, []));

  return (
    <Fragment>
      <link
        rel="stylesheet"
        href="https://cdn.materialdesignicons.com/4.5.95/css/materialdesignicons.min.css"
      />
      {loading || mutationLoading ? (
        <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />
      ) : (
        <EditTable
          title={
            <Box>
              <Box mb={1}>
                <Text fontSize={17}>Icon Highlight</Text>
              </Box>
              <a
                href={'https://materialdesignicons.com/cdn/2.0.46/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text fontSize={12} color="blue">
                  View Material Design Icon Collection
                </Text>
              </a>
            </Box>
          }
          data={tableData}
          columns={columns}
          onSaveClick={handleHighlightSave}
        />
      )}
    </Fragment>
  );
};

BaseHighlightEditor.propTypes = {
  dataQuery: PropTypes.object,
  dataQueryName: PropTypes.string,
  variableQuery: PropTypes.object,
  handleMutation: PropTypes.object,
  getHandleVariables: PropTypes.func,
  refetchQueries: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.object.isRequired,
      variables: PropTypes.object,
    })
  ),
};

export default BaseHighlightEditor;
