import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';

import StatefulModal from 'Components/Modal/StatefulModal';
import { ASSET_TYPE } from 'src/GraphQL/mutation/Asset.mutation';
import { SemanticButton as Button } from 'Components/Base/Button';
import { requestAssetUrl as requestAssetUrlMutation } from 'Routes/SalesV2/GraphQL/mutation/FileImport.mutation';

function ModalContent({ setIsOpen, isModalOpen, mutationData, closeModal }) {
  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [setIsOpen, isModalOpen]);

  return (
    <Fragment>
      <Modal.Header>Download File Result</Modal.Header>
      <Modal.Content>
        {mutationData && (
          <a href={mutationData.asRequestAssetUrl.url.url}>
            <Icon name="file outline" />
            Download File
          </a>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Close Modal</Button>
      </Modal.Actions>
    </Fragment>
  );
}

function FileResultModal({ trigger, data: { type, bucket, key } }) {
  const [requestAssetUrl, { data: mutationData }] = useMutation(requestAssetUrlMutation);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      requestAssetUrl({ variables: { type, bucket, key } });
    }
  }, [bucket, key, requestAssetUrl, type, isOpen]);

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal, isModalOpen) => {
        return (
          <ModalContent
            setIsOpen={setIsOpen}
            isModalOpen={isModalOpen}
            mutationData={mutationData}
            closeModal={closeModal}
          />
        );
      }}
    </StatefulModal>
  );
}

FileResultModal.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(ASSET_TYPE)),
    bucket: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
};

export default FileResultModal;
