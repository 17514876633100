import gql from 'graphql-tag';

import { DiscountCodeFragment } from './DiscountCode.query';

export const SalesOrderFragment = gql`
  fragment SalesOrderFragment on SFBoPaymentSalesOrder {
    id
    type
    formType
    orderNo
    status
    userId
    customerType
    customerFullName
    customerAddress
    customerMobile
    customerEmail
    discountCode
    priceInclVAT
    discountInclVAT
    totalAmount
    VAT
    withholdingTax
    paymentAmount
    paymentMethod
    unit
    expirationDate
    paymentRef1
    paymentRef2
    otherDetails
    voidable
    createdAt
    discountCodeDetails {
      ...DiscountCodeFragment
    }
  }
  ${DiscountCodeFragment}
`;

export const SalesOrderLargeFragment = gql`
  fragment SalesOrderLargeFragment on SFBoPaymentSalesOrder {
    ...SalesOrderFragment
    items {
      id
      SKUCode
      SKUCategory
      SKUType
      title
    }
  }
  ${SalesOrderFragment}
`;
export const RedemptionOrderFragment = gql`
  fragment RedemptionOrderFragment on SFBoPaymentRedemptionOrder {
    type
    orderNo
    status
    userId
    customerFullName
    customerAddress
    customerMobile
    customerEmail
    otherDetails
    items {
      id
      SKUCode
      title
    }
  }
`;

export const TrialOrderFragment = gql`
  fragment TrialOrderFragment on SFBoPaymentTrialOrder {
    type
    orderNo
    status
    userId
    customerFullName
    customerAddress
    customerMobile
    customerEmail
    otherDetails
    items {
      id
      SKUCode
      title
    }
  }
`;

export const AdjustmentOrderFragment = gql`
  fragment AdjustmentOrderFragment on SFBoPaymentAdjustmentOrder {
    id
    userId
    user {
      id
      fullName
    }
    type
    referId
    reason
    saleOrderNo
    reference {
      type
      content
    }
  }
`;

export const getSalesOrders = gql`
  query sfBoPaymentSalesOrders(
    $search: SFBoPaymentSearchSalesOrdersInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoPaymentSalesOrders(search: $search, paging: $paging, order: $order) {
      total
      salesOrders {
        ...SalesOrderLargeFragment
      }
    }
  }
  ${SalesOrderLargeFragment}
`;

export const getRedemptionOrders = gql`
  query sfBoPaymentRedemptionOrders(
    $search: SFBoPaymentSearchRedemptionOrdersInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoPaymentRedemptionOrders(search: $search, paging: $paging, order: $order) {
      total
      redemptionOrders {
        ...RedemptionOrderFragment
      }
    }
  }
  ${RedemptionOrderFragment}
`;

export const getTrialOrders = gql`
  query sfBoPaymentTrialOrders(
    $search: SFBoPaymentSearchTrialOrdersInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoPaymentTrialOrders(search: $search, paging: $paging, order: $order) {
      total
      trialOrders {
        ...TrialOrderFragment
      }
    }
  }
  ${TrialOrderFragment}
`;

export const getAdjustmentOrders = gql`
  query sfBoPaymentAdjustmentOrders(
    $search: SFBoPaymentSearchAdjustmentOrdersInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoPaymentAdjustmentOrders(search: $search, paging: $paging, order: $order) {
      total
      adjustmentOrders {
        ...AdjustmentOrderFragment
      }
    }
  }
  ${AdjustmentOrderFragment}
`;

export const getTotalAmountSumExcludeVAT = gql`
  query sfBoPaymentTotalAmountSumExcludeVAT($search: SFBoPaymentSearchSalesOrdersInput) {
    sfBoPaymentTotalAmountSumExcludeVAT(search: $search) {
      totalAmountExcludeVAT
    }
  }
`;
