import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getSubscription } from '../../GraphQL/query/Products.query';
import { updateSubscriptionPricingMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY } from '../../Domains/constants';
import SKUPricingForm from '../SKUPricingForm';

const resolveData = ({ sfBoProductSubscription }) => {
  // TODO: Recheck
  return {
    ...sfBoProductSubscription,
    SKUStatus: `${
      sfBoProductSubscription.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED
    }/${sfBoProductSubscription.SKUStatus}`,
  };
};

class SubscriptionPricingEditor extends Component {
  render() {
    const { subscription = {} } = this.props;
    const { id, SKUCode } = subscription;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getSubscription}
          editMutation={updateSubscriptionPricingMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              // TODO: Recheck
              ...form,
              priceInclVAT: _.omit(form.priceInclVAT, '__typename'),
              salePriceInclVAT: _.omit(form.salePriceInclVAT, '__typename'),
              promotionText: form.promotionText.content,
            },
          })}
        >
          {({ data, onSubmit }) => {
            return <SKUPricingForm initialData={resolveData(data)} onSubmit={onSubmit} />;
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default SubscriptionPricingEditor;
