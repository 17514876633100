import React from 'react';
import PropTypes from 'prop-types';

import CreateEditForm from 'Containers/CreateEditForm';

import { getOrganization } from '../../../../GraphQL/query/Users.query';
import {
  createOrganizationMutation,
  updateOrganizationMutation,
} from '../../../../GraphQL/mutation/Users.mutation';
import OrganizationDetailsForm, {
  ORGANIZATION_STATUS,
} from '../../Components/Organization/OrganizationDetailsForm';

const formatDataToForm = (data) => ({
  organizationName: data?.organization?.organizationName ?? '',
  domainName: data?.organization?.domainName ?? '',
  active: data?.organization?.active ? ORGANIZATION_STATUS.ACTIVE : ORGANIZATION_STATUS.INACTIVE,
});

const formatToEdit = (form, { id } = {}) => {
  return {
    id: id,
    organizationData: {
      organizationName: form?.organizationName,
      domainName: form?.domainName,
      active: form?.active === ORGANIZATION_STATUS.ACTIVE ? true : false,
    },
  };
};

const formatToCreate = (form, { id } = {}) => {
  return {
    organizationData: {
      organizationName: form?.organizationName,
      domainName: form?.domainName,
      active: form?.active === ORGANIZATION_STATUS.ACTIVE ? true : false,
    },
  };
};

const OrganizationDetails = ({ organizationId, isEdit, variables, ...props }) => (
  <CreateEditForm
    dataQuery={getOrganization}
    editMutation={updateOrganizationMutation}
    getEditVariables={formatToEdit}
    createMutation={createOrganizationMutation}
    createRefetchQueries={[{ query: getOrganization, variables: { id: organizationId } }]}
    getCreateVariables={formatToCreate}
    isEdit={isEdit}
    id={organizationId}
    idVariable={'id'}
    fetchPolicy={'cache-first'}
    {...props}
  >
    {({ data, onSubmit }) => (
      <OrganizationDetailsForm
        initialData={formatDataToForm(data)}
        isEdit={isEdit}
        onSubmit={onSubmit}
      />
    )}
  </CreateEditForm>
);

OrganizationDetails.propTypes = {
  organizationId: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
};

OrganizationDetails.defaultProps = {
  isEdit: true,
};

export default OrganizationDetails;
