import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Menu as SUIMenu } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Image as BaseImage, Flex } from 'Components/Base';

const Image = styled(BaseImage)`
  max-width: initial;

  @media screen and (min-width: 768px) {
    max-width: auto;
  }
`;

const MenuStyled = styled(SUIMenu)`
  &&& {
    ${({ secondary }) =>
      !secondary &&
      css`
        border-radius: 0;
        background-color: ${(props) => props.theme.colors.primary};
      `};
    .item {
      padding-top: 8px;
      padding-bottom: 8px;

      & .text {
        text-overflow: ellipsis;
        width: 20px;
        overflow: hidden;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &&& {
      .item {
        & .text {
          text-overflow: ellipsis;
          width: inherit;
        }
      }
    }
  }
`;

const LogoBlock = styled(SUIMenu.Header)`
  overflow: hidden;
  width: 45px;

  @media screen and (min-width: 768px) {
    width: inherit;
  }
`;

const MenuList = styled(Flex)`
  overflow-x: auto;
`;

const Logo = ({ imageSrc, imageStyle }) => {
  return (
    <Image
      srcSet={imageSrc}
      // src={imageSrc}
      style={imageStyle}
    />
  );
};

class Menu extends Component {
  render() {
    const {
      logoUrl,
      logoStyle,
      menuItems,
      showAuth,
      showLogo,
      menuProps,
      authComponent,
      className,
      ...restProps
    } = this.props;
    return (
      <MenuStyled {...menuProps} className={className} borderless>
        {showLogo && (
          <SUIMenu.Item>
            <LogoBlock>
              <Logo imageSrc={logoUrl} imageStyle={logoStyle} />
            </LogoBlock>
          </SUIMenu.Item>
        )}
        <MenuList>
          {menuItems.map((menu) => (
            <SUIMenu.Item
              //PAAS-996 need margin bottom 1 to prevent vertical scrollbar
              style={{ marginBottom: 1 }}
              key={menu.path}
              as={NavLink}
              exact={menu.path === '/' || menu.exact}
              to={menu.path}
              content={menu.title}
              data-testid={`menu-${menu.title}`}
            />
          ))}
        </MenuList>
        {showAuth && authComponent && (
          <SUIMenu.Menu position="right" style={{ marginRight: '5px' }}>
            <SUIMenu.Item name="logout" data-testid="menu-logout">
              {authComponent}
            </SUIMenu.Item>
          </SUIMenu.Menu>
        )}
      </MenuStyled>
    );
  }
}

Menu.propTypes = {
  logoUrl: PropTypes.string,
  showAuth: PropTypes.bool,
  showLogo: PropTypes.bool,
  authComponent: PropTypes.node,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
      // roles: PropTypes.array,
      // private: PropTypes.bool,
    })
  ),
};

Menu.defaultProps = {
  logoUrl: 'https://assets.skooldio.com/logo/logo_forInstructor.png',
  menuItems: [],
};

export default Menu;
