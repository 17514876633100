import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { getTeacherStudents } from 'GraphQL/LSP/query/Profile.query';
import { deleteStudentAdvisorTeacherMutation } from 'GraphQL/LSP/mutation/Profile.mutation';
import ConfirmationButton from 'Components/ConfirmationButton';

import TeacherStudentModal from '../TeacherStudentModal';

const columns = (handleDelete, variables) => [
  {
    Header: 'ระดับชั้น',
    accessor: 'schoolGrade.name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Code',
    accessor: 'code',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'คำนำหน้า',
    accessor: 'prefix',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'ชื่อ',
    accessor: 'firstName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'นามสกุล',
    accessor: 'lastName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'ชื่อเล่น',
    accessor: 'nickName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'แผนการเรียน',
    accessor: 'schoolProgramme.name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'แผนอาชีพ',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const { careerPlans } = original;
      return careerPlans ? careerPlans.map(({ name }) => name).join(', ') : '';
    },
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <Fragment>
          <Button.Group>
            <ConfirmationButton
              buttonStyle={{
                primary: true,
                circular: true,
                size: 'medium',
                type: 'button',
              }}
              onConfirm={() => handleDelete(id)}
              headerText="ย้ายออก"
              confirmText="Confirm"
              contentText="ย้ายนักเรียนออกจากการดูแลของครูคนนี้"
            >
              ย้ายออก
            </ConfirmationButton>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class TeacherStudentsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { advisorTeacherId: props.userId },
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  profileMutate = (mutation, studentId) => {
    const { search, paging, order } = this.state;
    this.props.client
      .mutate({
        mutation,
        variables: { studentId },
        refetchQueries: [
          {
            query: getTeacherStudents,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  handleDelete = (studentId) => {
    this.profileMutate(deleteStudentAdvisorTeacherMutation, studentId);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, advisorTeacherId: this.props.userId }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.boTeacherStudents.profiles;

  render() {
    const { search, paging, order } = this.state;
    const { userId } = this.props;
    const variables = { search, paging, order };
    return (
      <Box>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>นักเรียนภายใต้การดูแล</Text.Header>
          <TeacherStudentModal
            advisorTeacherId={userId}
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                เพิ่ม
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={'boTeacherStudents'}
          showSearchPanel={false}
          resolveData={this.resolveData}
          columns={columns(this.handleDelete, variables)}
          query={getTeacherStudents}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Box>
    );
  }
}

export default withApollo(TeacherStudentsDetails);
