import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import omitDeep from 'Util/omitDeep';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';
import { updateOnlineCourseFAQMutation } from 'Routes/Products/GraphQL/mutation/Products.mutation';

import FAQView from '../WorkshopContent';
import { getOnlineCourse } from '../../GraphQL/query/Products.query';

const OnlineCourseFAQ = ({ onlineCourse }) => {
  const { SKUCode, id } = onlineCourse;
  const { loading, error, data: queryData } = useQuery(getOnlineCourse, {
    variables: { SKUCode },
    fetchPolicy: 'network-only',
  });

  const [
    updateOnlineCourse,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(updateOnlineCourseFAQMutation);

  const handleUpdateContents = (data) => {
    const clonedData = JSON.parse(JSON.stringify(data));
    const formatData = omitDeep(clonedData, ['id', '__typename']);
    updateOnlineCourse({
      variables: {
        id,
        faq: formatData,
      },
    });
  };

  if (loading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }

  const contents = queryData?.sfBoProductOnlineCourse?.FAQ ?? [];

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={false}
        response={mutationResponse?.updateOnlineCourseFAQMutation}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <FAQView
        loading={mutationLoading}
        contents={contents}
        accessor={{ title: 'question', description: 'answer' }}
        onUpdateContents={handleUpdateContents}
        addSectionText={'Add FAQ'}
      />
    </Fragment>
  );
};

export default OnlineCourseFAQ;
