import React, {
  Fragment,
  useRef,
  useMemo,
  useCallback,
  useState,
  useImperativeHandle,
} from 'react';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Flex, Text, Box, ButtonFilled } from 'Components/Base';
import StatefulModal from 'Components/Modal/StatefulModal';

import Snackbar, { VARIANTS } from '../../Components/Snackbar';
import { UNASSIGN_APP_ROLE } from '../../Graphql/mutation/Role.mutation';
import DeleteIconModal from '../DeleteIconModal';

import AssignUsersToRole from './AssignUsersToRole';
import SearchRoleUsers, { DEFAULT_USER_COLUMNS } from './SearchRoleUsers';

const ModalContentContainer = styled((props) => <Box {...props} />)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #f1ad46;
  }
`;

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const AssignUserModal = ({ roleId, onCompleted }) => {
  return (
    <StatefulModal
      trigger={
        <ButtonFilled
          bg="primary"
          color="white"
          borderRadius={'100px'}
          px={3}
          py={2}
          fontWeight="600"
          fontSize="14px"
        >
          <Flex alignItems="center">
            <AddIcon style={{ width: '24px' }} />
            <span>Assign User</span>
          </Flex>
        </ButtonFilled>
      }
    >
      {(closeModal, isOpen) => (
        <ModalContentContainer>
          <Text.Header py={3} px={4} fontSize="20px">
            Assign User
          </Text.Header>
          <Divider />
          <AssignUsersToRole
            appRoleId={roleId}
            onCancel={closeModal}
            onCompleted={() => {
              onCompleted();
              setTimeout(() => closeModal(), 1500);
            }}
          />
        </ModalContentContainer>
      )}
    </StatefulModal>
  );
};

AssignUserModal.propTypes = {
  roleId: PropTypes.string.isRequired,
  onCompleted: PropTypes.func,
};
AssignUserModal.defaultProps = {
  onCompleted: () => {},
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  PERMISSION_LIMITATION: {
    message: 'Unassign user with your role or higher is prohibited',
    variant: VARIANTS.ERROR,
    show: true,
  },
  USER_NOT_FOUND: {
    message: `User not found or has been unassigned already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot unassign role, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};
const UserList = React.forwardRef(({ roleId }, ref) => {
  const [feedback, setFeedback] = useState({});
  const willUnassignUserRef = useRef();
  const SearchAssignedRoleUsersRef = useRef(null);
  useImperativeHandle(SearchAssignedRoleUsersRef, () => ref.current);

  const onCompleted = useCallback((response) => {
    const removedRoleName = response?.unassignAppRoleFromUser?.name;
    const removedUserName = willUnassignUserRef?.current?.username;
    const message = `User${
      removedUserName ? ` "${removedUserName}" ` : ''
    }has been removed from role${removedRoleName ? ` "${removedRoleName}"` : ''}`;
    setFeedback({
      message: message,
      variant: VARIANTS.SUCCESS,
      show: true,
    });
    const SearchAssignedRoleUsersTable = SearchAssignedRoleUsersRef.current;
    if (typeof SearchAssignedRoleUsersTable.refetch === 'function') {
      SearchAssignedRoleUsersTable.refetch();
    }
  }, []);

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('404')) {
      setFeedback(FEEDBACK.USER_NOT_FOUND);
      return;
    }

    if (message.includes('403')) {
      setFeedback(FEEDBACK.PERMISSION_LIMITATION);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
    return;
  }, []);

  const [removeUser] = useMutation(UNASSIGN_APP_ROLE, {
    onCompleted,
    onError,
  });

  const handleRemoveUser = useCallback(
    (user, roleId) => {
      willUnassignUserRef.current = user;
      removeUser({ variables: { userId: user?.id, roleId } });
    },
    [removeUser]
  );

  const COLUMN = useMemo(
    () => [
      ...DEFAULT_USER_COLUMNS,
      {
        Header: 'Action',
        Cell: function actionComponent(row) {
          return (
            <Flex justifyContent="center">
              <DeleteIconModal
                title={'Remove User?'}
                description={'This user will be removed from this role.'}
                onConfirmClick={() => handleRemoveUser(row.original, roleId)}
              />
            </Flex>
          );
        },
        width: 60,
        isSearchAble: false,
        sortable: false,
      },
    ],
    [roleId, handleRemoveUser]
  );
  return (
    <>
      <SearchRoleUsers columns={COLUMN} appRoleId={roleId} ref={ref} />
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
});

UserList.propTypes = {
  roleId: PropTypes.string.isRequired,
};

const RoleUsersPanel = ({ roleId }) => {
  const AssignedUserListRef = useRef(null);

  const handleAssignComplete = useCallback(() => {
    const AssignedUserListTable = AssignedUserListRef.current;
    if (AssignedUserListTable && typeof AssignedUserListTable.refetch === 'function') {
      AssignedUserListTable.refetch();
    }
  }, []);

  return (
    <Fragment>
      <Text>
        {`User(s) will be saved to the back office once you clicked “Assign User” in the process.`}
      </Text>
      <Flex justifyContent="space-between" alignItems="center" flexDirection={'row-reverse'} py={3}>
        <AssignUserModal roleId={roleId} onCompleted={handleAssignComplete} />
      </Flex>
      <UserList roleId={roleId} ref={AssignedUserListRef} />
    </Fragment>
  );
};

RoleUsersPanel.propTypes = {
  roleId: PropTypes.string.isRequired,
};

export default RoleUsersPanel;
