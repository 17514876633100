const ROLE = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  APPLICATION: 'APPLICATION',
  STUDENT: 'STUDENT',
  ADVISOR_TEACHER: 'ADVISOR_TEACHER',
  SUBJECT_TEACHER: 'SUBJECT_TEACHER',
  PARENT: 'PARENT',
};

export default ROLE;
