import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';

import { getAdjustmentOrders } from '../../GraphQL/query/Payment.query';
import { changeSubscription } from '../../GraphQL/mutation/Payment.mutation';

import ChangeSubscriptionForm from './ChangeSubscriptionForm';

export default class ChangeSubscriptionModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
    variables: PropTypes.object,
    initialData: PropTypes.object,
  };
  static defaultProps = {
    isEdit: false,
  };

  render() {
    const { isEdit, id, trigger, variables, initialData } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>Change Subscription</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                createMutation={changeSubscription}
                createRefetchQueries={[
                  {
                    query: getAdjustmentOrders,
                    variables,
                  },
                ]}
                isEdit={isEdit}
                id={id}
                getCreateVariables={(form) => {
                  const { endDate, reference, ...data } = form;
                  const input = {
                    ...data,
                    changedSubscriptionEndDate: endDate.time,
                    reference: reference.content,
                  };
                  return { input };
                }}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return <ChangeSubscriptionForm initialData={initialData} onSubmit={onSubmit} />;
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
