/* eslint-disable react/display-name */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import _ from 'lodash';

import { Flex } from 'Components/Base';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable, { DATA_FORMAT } from 'GraphQL/util/QueryTable';
import FormGenerator from 'Components/Form/FormGenerator';
import getConfig from 'Util/Config';
import resolveProducTypeText from 'Routes/Products/Domains/resolveProducTypeText';
import { getFormatter } from 'GraphQL/util/QueryTable/dataUtil';

import { LogsModal } from '../../components/LogsModal';
import { UpdateSalesOrderStatusModal } from '../../components/UpdateSalesOrderModal';
import { TextAlignRight } from '../../components/TextAlignRight';
import { TotalAmountSumContainer } from '../../components/TotalAmountSum';
import { getSalesOrders } from '../../GraphQL/query/Payment.query';
import {
  salesOrderFormTypeOptions,
  paymentMethodOptions,
  paymentCurrencyOptions,
  SKUCategoryOptions,
  SKUTypeOptions,
  SALES_ORDER_STATUS,
  CustomerTypeOptions,
  CUSTOMER_TYPE,
} from '../../Domains/constants';
import { filterListedColumns, calcSalesExclVAT, calcSalesExclWHT } from '../../Domains/utils';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};
const config = getConfig();
function canVoid(original) {
  const isInAppPurchase = original.paymentMethod.includes('IN_APP_PURCHASE');
  return (config.allowedVoidInAppPurchase || !isInAppPurchase) && original.voidable;
}
const voidFields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ref',
    inputName: 'ref',
    mode: MODE.BOTH,
    inputProps: {
      required: true,
    },
  },
];
const moneyFormatter = getFormatter(COLUMN_TYPE.NUMBER, DATA_FORMAT.NUMBER_FORMAT_FLOAT);
const columns = (variables) => [
  {
    Header: 'CreatedAt',
    accessor: 'createdAt',
    width: 150,
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATETIME_FORMAT,
    isSearchAble: true,
  },
  {
    Header: 'OrderId',
    accessor: 'id',
    width: 120,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'OrderNo',
    accessor: 'orderNo',
    width: 120,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'SKUCode',
    accessor: 'SKUCode',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      return _.get(original, 'items[0].SKUCode');
    },
    csvDisplay: ({ original }) => {
      return _.get(original, 'items[0].SKUCode');
    },
  },
  {
    Header: 'SKUTitle',
    accessor: 'title',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      return _.get(original, 'items[0].title');
    },
    csvDisplay: ({ original }) => {
      return _.get(original, 'items[0].title');
    },
  },
  {
    Header: 'Product Type',
    accessor: 'SKUCategory',
    width: 110,
    type: COLUMN_TYPE.ENUM,
    enumOptions: SKUCategoryOptions,
    isSearchAble: true,
    Cell: ({ original }) => {
      const category = _.get(original, 'items[0].SKUCategory');
      const type = _.get(original, 'items[0].SKUType');
      return resolveProducTypeText(type, category);
    },
    csvDisplay: ({ original }) => {
      const category = _.get(original, 'items[0].SKUCategory');
      const type = _.get(original, 'items[0].SKUType');
      return resolveProducTypeText(type, category);
    },
  },
  {
    Header: 'UserId',
    accessor: 'userId',
    width: 120,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Full Name',
    accessor: 'customerFullName',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Mobile',
    accessor: 'customerMobile',
    width: 110,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Email',
    accessor: 'customerEmail',
    width: 220,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Customer Type',
    accessor: 'customerType',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: CustomerTypeOptions,
    isSearchAble: true,
  },
  {
    Header: 'PriceInclVAT',
    accessor: 'priceInclVAT',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: false,
    Cell: ({ original }) => {
      const value = original.priceInclVAT;
      return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
    },
    csvDisplay: ({ original }) => {
      const value = original.priceInclVAT;
      return moneyFormatter(value);
    },
  },
  {
    Header: 'Discount Code',
    accessor: 'discountCode',
    width: 110,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Discount Code Reference',
    accessor: 'refCode',
    width: 110,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      return original?.discountCodeDetails?.refCode;
    },
    isHideColumn: !config?.discountCode?.enabledRefCode,
  },
  {
    Header: 'DiscountInclVAT',
    accessor: 'discountInclVAT',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: true,
    Cell: ({ original }) => {
      const value = original.discountInclVAT;
      return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
    },
    csvDisplay: ({ original }) => {
      const value = original.discountInclVAT;
      return moneyFormatter(value);
    },
  },
  {
    Header: 'TotalAmount',
    accessor: 'totalAmount',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: false,
    Cell: ({ original }) => {
      const value = original.totalAmount;
      return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
    },
    csvDisplay: ({ original }) => {
      const value = original.totalAmount;
      return moneyFormatter(value);
    },
  },
  {
    Header: 'VAT',
    accessor: 'VAT',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: false,
    Cell: ({ original }) => {
      const value = original.VAT;
      return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
    },
    csvDisplay: ({ original }) => {
      const value = original.VAT;
      return moneyFormatter(value);
    },
  },
  {
    Header: 'WithholdingTax',
    accessor: 'withholdingTax',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: false,
    Cell: ({ original }) => {
      const value = original.withholdingTax;
      return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
    },
    csvDisplay: ({ original }) => {
      const value = original.withholdingTax;
      return moneyFormatter(value);
    },
  },
  {
    Header: 'PaymentAmount',
    accessor: 'paymentAmount',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: true,
    Cell: ({ original }) => {
      const value = original.paymentAmount;
      return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
    },
    csvDisplay: ({ original }) => {
      const value = original.paymentAmount;
      return moneyFormatter(value);
    },
  },
  {
    Header: 'Sales excl. VAT',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: true,
    Cell: ({ original }) => {
      const { customerType, priceInclVAT, discountInclVAT, VAT } = original;
      if (customerType === CUSTOMER_TYPE.INDIVIDUAL) {
        const value = calcSalesExclVAT(priceInclVAT, discountInclVAT, VAT);
        return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
      }
      return null;
    },
    csvDisplay: ({ original }) => {
      const { customerType, priceInclVAT, discountInclVAT, VAT } = original;
      if (customerType === CUSTOMER_TYPE.INDIVIDUAL) {
        const value = calcSalesExclVAT(priceInclVAT, discountInclVAT, VAT);
        return moneyFormatter(value);
      }
      return null;
    },
  },
  {
    Header: 'Sales excl. WHT',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    Cell: ({ original }) => {
      const { customerType, priceInclVAT, discountInclVAT, withholdingTax } = original;
      if (customerType === CUSTOMER_TYPE.CORPORATE) {
        const value = calcSalesExclWHT(priceInclVAT, discountInclVAT, withholdingTax);
        return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
      }
      return null;
    },
    csvDisplay: ({ original }) => {
      const { customerType, priceInclVAT, discountInclVAT, withholdingTax } = original;
      if (customerType === CUSTOMER_TYPE.CORPORATE) {
        const value = calcSalesExclWHT(priceInclVAT, discountInclVAT, withholdingTax);
        return moneyFormatter(value);
      }
      return null;
    },
  },
  {
    Header: 'PaymentMethod',
    accessor: 'paymentMethod',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: paymentMethodOptions,
    isSearchAble: true,
  },
  {
    Header: 'Unit',
    accessor: 'unit',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: paymentCurrencyOptions,
    isSearchAble: true,
  },
  {
    Header: 'PaymentRef1',
    accessor: 'paymentRef1',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'PaymentRef2',
    accessor: 'paymentRef2',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Logs',
    accessor: 'id',
    width: 150,
    type: COLUMN_TYPE.CUSTOM,
    Cell: ({ original }) => {
      const {
        otherDetails: { logs = [] },
      } = original;
      return (
        <Fragment>
          <Flex justifyContent={'center'}>
            <LogsModal actionText="Logs" data={logs}></LogsModal>
          </Flex>
        </Fragment>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 200,
    type: COLUMN_TYPE.CUSTOM,
    Cell: ({ original }) => {
      return canVoid(original) ? (
        <Fragment>
          <Flex justifyContent="center">
            <UpdateSalesOrderStatusModal
              icon="times"
              triggerButtonStyle={{ negative: true, basic: true }}
              actionButtonStyle={{ negative: true }}
              actionText="VOID"
              value={{
                id: original.id,
                status: SALES_ORDER_STATUS.VOID,
              }}
              fields={voidFields}
              variables={variables}
              headerContent={<Text.PaymentTitle>Set Success SalesOrder to Void</Text.PaymentTitle>}
              descriptionText="โปรดตรวจสอบการจัดส่งสินค้าทุกครั้งก่อนทำการยกเลิก เพื่อให้คำแนะนำลูกค้าได้อย่างถูกต้อง"
              descriptionTextColor="red"
            ></UpdateSalesOrderStatusModal>
          </Flex>
        </Fragment>
      ) : null;
    },
  },
];

class SuccessSalesOrderRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { status: SALES_ORDER_STATUS.SUCCESS },
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  handleEdit = (id) => {
    // this.props.history.push(`/lsp/syllabuses/list/${id}`);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  isSearching = () => {
    const searchData = _.omit(this.state.search, ['status']);
    return !_.isEmpty(searchData);
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, status: SALES_ORDER_STATUS.SUCCESS }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoPaymentSalesOrders.salesOrders;

  renderMiddleContent = (search) => {
    if (config.enabledTotalAmountExcludeVATDisplay && this.isSearching()) {
      return <TotalAmountSumContainer search={search} />;
    }
    return;
  };

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Success Sales Orders</Text.Header>
        </Flex>
        <QueryTable
          type={'sfBoPaymentSalesOrders'}
          resolveData={this.resolveData}
          columns={filterListedColumns(columns(variables))}
          query={getSalesOrders}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          exportable
          middleContent={this.renderMiddleContent(this.state.search)}
        />
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(SuccessSalesOrderRoute);
