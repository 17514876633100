export const FILE_TYPE = {
  NEW_SCHOOL: 'NEW_SCHOOL',
};

export const FILE_IMPORT_STATUS = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
};
