const fontSizeConfig = [
  { id: 'xsmall', value: 12 },
  { id: 'small', value: 14 },
  { id: 'normal', value: 16 },
  { id: 'semilarge', value: 20 },
  { id: 'large', value: 24 },
  { id: 'larger', value: 32 },
  { id: 'xlarge', value: 48 },
  { id: 'xxlarge', value: 64 },
  { id: 'xxxlarge', value: 72 },
  { id: 'xxxxlarge', value: 96 },
];

const fontSizes = fontSizeConfig.reduce((fontSizesObj, fs, i) => {
  fontSizesObj[fs.id] = i;
  return fontSizesObj;
}, {});

const fontSizesValueList = fontSizeConfig.map((x) => x.value);

export { fontSizes, fontSizesValueList };

export default fontSizes;
