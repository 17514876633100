import gql from 'graphql-tag';

export const BACKOFFICE_PRODUCT = {
  SKOOLDIO: 'SKOOLDIO',
  LEARNX: 'LEARNX',
};

export const productCourseVersionResolver = (product = 'SKOOLDIO', version = 'v1') => {
  if (product === BACKOFFICE_PRODUCT.SKOOLDIO) {
    return `SKOOLDIO_${version.toUpperCase()}`;
  }
  if (product === BACKOFFICE_PRODUCT.LEARNX) {
    return `LEARNX_V1`; // LEARNx Only have 1 version
  }
  return null;
};

export const getConfig = gql`
  query getApplicationProduct {
    config {
      product
      assetUploadMode
    }
  }
`;
