import React, { useState } from 'react';
import { Input } from 'formsy-semantic-ui-react';
import { SketchPicker } from 'react-color';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import { COLOR } from '../Theme';

const getPresetColor = () => {
  const { greyscale, bsGrays, ...colors } = COLOR;
  const { darken, ...other } = colors;
  const color = [...Object.values(other), ...Object.values(bsGrays), ...Object.values(greyscale)];
  const presetColor = Array.from(new Set(color));
  return presetColor;
};

const ColorSelectorInput = ({ defaultValue, defaultColor, ...inputProps }) => {
  const [color, setColor] = useState(defaultValue || defaultColor);
  const [isPreview, setPreview] = useState(false);
  return (
    <Flex>
      <Box flex="0 1 40px" bg={color} border="4px solid black" mr={2} />
      <Box flex="1 1">
        <Input
          {...inputProps}
          value={color || ''}
          onChange={(e) => setColor(e.target.value)}
          action={
            <Button onClick={() => setPreview(true)} type="button">
              <Flex alignItems="center">
                <Box mr={2}>แก้ไข</Box>
                <Icon name="edit" />
              </Flex>
            </Button>
          }
        />
      </Box>
      <Modal open={isPreview} onClose={() => setPreview(false)} basic size="small">
        <Header icon="browser" content="Select Background Color" />
        <Modal.Content>
          <Flex justifyContent="center" alignItems="center">
            <SketchPicker
              color={color || ''}
              onChange={(newColor) => setColor(newColor.hex)}
              width="100%"
              presetColors={getPresetColor()}
              disableAlpha
            />
          </Flex>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => setPreview(false)} inverted>
            <Icon name="check" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Flex>
  );
};

export default ColorSelectorInput;
