import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, Divider, Icon } from 'semantic-ui-react';
import getUniqId from 'lodash/uniqueId';

import { SemanticButton as Button } from 'Components/Base/Button';
import { Box, Flex } from 'Components/Base';
import SectionList from 'Components/Section';

import QuickActionBar from './QuickActionBar';
import SectionContent from './SectionContent';

const SectionComponent = ({ id, title, description, children, accessor, ...restProps }) => (
  <Box>
    <SectionContent
      id={id}
      title={title}
      description={description}
      accessor={accessor}
      {...restProps}
    />
    {children}
    <Divider hidden />
  </Box>
);

SectionComponent.propTypes = {
  ...SectionContent.propTypes,
};

const createSectionData = (
  sections = [],
  activeSectionIndex,
  actions = {
    onUpdateSectionMeta: () => {},
    onDeleteSection: () => {},
    onReorderSection: () => {},
    onCloseSection: () => {},
    onFormDirty: () => {},
  },
  accessor = {}
) => {
  const result = sections.map((section, index) => {
    const id = section.id;
    const newSection = {
      key: id,
      id: id,
      active: index === activeSectionIndex ? true : undefined,
      title: {
        left: (
          <Header>
            <Icon size="large" name="dropdown" />
            {section[accessor.title]}
          </Header>
        ),
        right: (
          <QuickActionBar
            itemIndex={index}
            totalItems={sections.length}
            isSection={true}
            id={id}
            onDeleteClick={(e) => {
              actions.onDeleteSection(index);
            }}
            onReorderClick={(e, sourceIndex, destinationIndex) => {
              actions.onReorderSection(sourceIndex, destinationIndex);
              e.stopPropagation();
            }}
          />
        ),
      },
      content: {
        content: (
          <SectionComponent
            id={id}
            title={section[accessor.title]}
            description={section[accessor.description]}
            onUpdateMeta={(data) => {
              const passingData = { ...data, id: id };
              actions.onUpdateSectionMeta(index, passingData);
            }}
            onClose={() => actions.onCloseSection(index)}
            onFormDirty={() => actions.onFormDirty(index)}
            accessor={accessor}
          />
        ),
      },
    };

    return newSection;
  });
  return result;
};

const mappingContents = (contents = []) => {
  return contents.map((c) => {
    return {
      id: c.id || getUniqId(),
      ...c,
    };
  });
};

const WorkshopContentView = ({
  contents: initialContents,
  onUpdateContents = () => {},
  loading,
  accessor,
  addSectionText,
}) => {
  const [contents, setContents] = useState(mappingContents(initialContents));
  const [activeSectionIndex, setActiveSectionIndex] = useState(null);
  const [forceActiveCurrentSection, setForceActiveCurrentSection] = useState(false);
  const [tocDirty, setTocDirty] = useState(false);

  useEffect(() => {
    setContents(mappingContents(initialContents));
    setForceActiveCurrentSection(false);
    setActiveSectionIndex(null);
    setTocDirty(false);
    return () => {};
  }, [initialContents]);

  /* Section Component */
  function handleUpdateContent(index, data) {
    console.log(index, data);
    const newContents = [...contents];
    newContents[index] = data;
    setContents(newContents);
    setActiveSectionIndex(null);
    setForceActiveCurrentSection(false);
    setTocDirty(true);
  }

  function handleReorderSection(sourceIndex, destinationIndex) {
    const newContents = [...contents];
    [newContents[destinationIndex], newContents[sourceIndex]] = [
      newContents[sourceIndex],
      newContents[destinationIndex],
    ];
    setTocDirty(true);
    setContents(newContents);
  }

  function handleDeleteSection(index) {
    const newContents = [...contents];
    newContents.splice(index, 1);
    console.log('delete index', index, newContents);
    setTocDirty(true);
    setContents(newContents);
  }

  const getNewContent = () => ({
    id: getUniqId(),
    [accessor.title]: 'Section',
    [accessor.description]: {
      type: 'MARKDOWN',
      content: '',
    },
  });
  /* This Component */
  function handleAddSection() {
    setTocDirty(true);
    setContents([...contents, getNewContent()]);
  }

  function handleReset() {
    setForceActiveCurrentSection(false);
    setActiveSectionIndex(null);
    setContents(mappingContents(initialContents));
    setTocDirty(false);
  }

  function handleSectionClick(index) {
    if (!forceActiveCurrentSection) {
      setForceActiveCurrentSection(false);
      setActiveSectionIndex(index);
    }
  }

  function handleOnFormDirty(index) {}

  function handleOnCloseSection() {
    // setActiveSectionIndex(null);
  }

  /* Props */
  function handleOnUpdateContents() {
    console.log(contents);
    onUpdateContents(contents);
  }
  return (
    <Box>
      <SectionList
        sections={createSectionData(
          contents,
          forceActiveCurrentSection ? activeSectionIndex : undefined,
          {
            onUpdateSectionMeta: handleUpdateContent,
            onDeleteSection: handleDeleteSection,
            onReorderSection: handleReorderSection,
            onFormDirty: handleOnFormDirty,
          },
          accessor
        )}
        onSectionClick={handleSectionClick}
      />
      <Divider hidden />
      <Button primary circular basic fluid onClick={handleAddSection} debounceTime={300}>
        <Icon name="plus square" />
        {addSectionText}
      </Button>
      <Divider />
      <Flex justifyContent="flex-end" mt={3}>
        <Button
          primary
          circular
          size="medium"
          type="submit"
          content={'Update'}
          loading={loading}
          disabled={!tocDirty}
          onClick={handleOnUpdateContents}
        />
        <Button
          circular
          size="medium"
          type="reset"
          content={'Reset'}
          disabled={false}
          onClick={handleReset}
        />
      </Flex>
    </Box>
  );
};

WorkshopContentView.propTypes = {
  accessor: SectionComponent.propTypes.accessor,
  addSectionText: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.object),
};

WorkshopContentView.defaultProps = {
  contents: [],
  addSectionText: 'Add Section',
};

export default WorkshopContentView;
