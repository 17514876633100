import React, { Component } from 'react';
import PropTypes from 'prop-types';

import QueryComponent from '../../../../GraphQL/util/QueryComponent';
import { getCertificates } from '../../../../GraphQL/query/Certificate.query';

import SelectorInput from './SelectorInput';

const parseValue = ({ certificates }) => {
  return certificates.certificates.map((certificate) => ({
    key: certificate.id,
    value: certificate.id,
    text: `${certificate.name}`,
  }));
};

export default class CertificateSelectorInputQuery extends Component {
  static propTypes = {};

  render() {
    return (
      <QueryComponent
        query={getCertificates}
        variables={{
          search: {},
          paging: { pageSize: 0, currentPage: 0 },
          order: { field: 'name', type: 'ASC' },
        }}
      >
        {(data) => <SelectorInput options={parseValue(data)} {...this.props} />}
      </QueryComponent>
    );
  }
}
