import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

const getDisplayName = (str = '') => {
  if (str.length > 9) {
    return str.slice(0, 9) + '...';
  }
  return str;
};

const SDropdown = styled(Dropdown)`
  &.ui.dropdown > .text {
    font-weight: 400;
  }
`;

class UserMenu extends Component {
  render() {
    const { myProfile, logout } = this.props;
    return (
      <SDropdown
        text={getDisplayName(myProfile.firstname)}
        button
        floating
        className="red circular" // TODO: get a better color from theme?
      >
        <Dropdown.Menu>
          <Dropdown.Item text="ออกจากระบบ" onClick={logout} />
        </Dropdown.Menu>
      </SDropdown>
    );
  }
}

UserMenu.propTypes = {
  myProfile: PropTypes.shape({
    firstname: PropTypes.string,
  }),
  logout: PropTypes.func,
};

UserMenu.defaultProps = {
  myProfile: {},
  logout: () => {},
};

export default UserMenu;
