import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Popup, Button, List } from 'semantic-ui-react';
import omitBy from 'lodash/omitBy';

import omitDeep from 'Util/omitDeep';
import withFormsy from 'Components/Form/withFormsy';
import delve from 'Util/Delve';

import MapSearch from './MapSearch';

const findAddressComponent = (addressComponents, findType, property) => {
  const addressComponent = addressComponents.find(({ types = [] }) => types.includes(findType));
  return delve(addressComponent, property, null);
};

export function MapSearchInput({ placeId, apiKey, value, onChange }) {
  const [venueData, setVenueData] = useState(value === '' ? null : omitDeep(value, ['__typename']));
  const handleVenueSeleted = (venue) => {
    console.log('venue selected', venue);
    const { formatted_address, address_components, geometry, name, place_id } = venue;
    const location = {
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
    };
    const result = {
      streetAddress: formatted_address,
      addressLocality: findAddressComponent(address_components, 'sublocality_level_1', 'long_name'),
      addressRegion: findAddressComponent(
        address_components,
        'administrative_area_level_1',
        'long_name'
      ),
      postalCode: findAddressComponent(address_components, 'postal_code', 'short_name'),
      addressCountry: findAddressComponent(address_components, 'country', 'short_name'),
      location: location,
      name,
      placeId: place_id,
    };
    const resultNoNull = omitBy(result, (v) => v === null || v === undefined);
    setVenueData(resultNoNull);
    onChange({}, { value: resultNoNull });
  };
  return (
    <Fragment>
      <MapSearch
        placeId={placeId || delve(venueData, 'placeId')}
        placeName={delve(venueData, 'name')}
        apiKey={apiKey}
        onVenueSelected={handleVenueSeleted}
      />
      {venueData && (
        <Popup
          content={
            <List bulleted>
              {Object.entries(venueData).map(([key, value]) => (
                <List.Item key={key}>
                  <List.Content>
                    <List.Header>{key}</List.Header>
                    <List.Description>{JSON.stringify(value)}</List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          }
          trigger={
            <Button
              circular
              icon="info"
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          }
        />
      )}
    </Fragment>
  );
}

MapSearchInput.propTypes = {
  placeId: PropTypes.string,
  apiKey: PropTypes.string,
  value: PropTypes.func,
  onChange: PropTypes.func,
};

export default withFormsy(MapSearchInput);
