import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

const optionsConverter = (category, index) => ({
  id: index,
  value: category,
  text: category,
  key: index,
});

const parseValue = (categories = []) => {
  return categories.map(optionsConverter);
};

const CategoriesSearchSelectorInput = (props) => {
  const { formInput, defaultValue, value, ...restProps } = props;
  const [options, setOptions] = useState(parseValue(defaultValue));
  const handleAddItem = (e, { value }) => {
    setOptions([...options, { id: options.length, text: value, value, key: options.length }]);
  };

  return (
    <Dropdown
      placeholder="Select Values"
      search
      selection
      multiple
      fluid
      allowAdditions
      onAddItem={handleAddItem}
      options={options}
      value={value || []}
      {...restProps}
    />
  );
};

export default CategoriesSearchSelectorInput;
