import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';

import ProfileAvatarUploadForm from '../ProfileAvatarUploadForm';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'รหัสประจำตัวผู้ปกครอง',
    inputName: 'code',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'คำนำหน้า',
    inputName: 'prefix',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ชื่อ',
    inputName: 'firstName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'นามสกุล',
    inputName: 'lastName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ชื่อเล่น',
    inputName: 'nickName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'OD Account',
    inputName: 'account',
    inputProps: {
      // @TODO: Update this
      // required: true,
      disabled: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Username',
    inputName: 'username',
    inputProps: {
      // @TODO: Update this
      // required: true,
      disabled: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Email',
    inputName: 'email',
    inputProps: {
      // @TODO: Update this
      // required: true,
      disabled: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'เบอร์โทร',
    inputName: 'phoneNumber',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
    inputLabel: 'ที่อยู่',
    inputName: 'address1',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'เขต/อำเภอ',
    inputName: 'district',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'จังหวัด',
    inputName: 'province',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'รหัสไปรษณีย์',
    inputName: 'zipCode',
    inputProps: {
      required: true,
    },
  },
];

export default class ParentProfileForm extends Component {
  static propTypes = {
    onCreateParentProfile: PropTypes.func,
    onUploadProfileImage: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateParentProfile: () => {},
    onUploadProfileImage: () => {},
    isEdit: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
    };
  }

  handleUploadProfileImage = ({ key, bucket, url }) => {
    this.setState({ avatar: url });
    this.props.onUploadProfileImage({ key, bucket });
  };

  handleSubmit = (form) => {
    this.props.onCreateParentProfile(form);
  };

  render() {
    const { isEdit, initialData } = this.props;
    const { avatar } = this.state;
    return (
      <Fragment>
        {isEdit && (
          <ProfileAvatarUploadForm
            userId={initialData.id}
            avatar={avatar || initialData.avatar}
            editMode={true}
            onSuccess={this.handleUploadProfileImage}
          />
        )}
        <FormGenerator
          fields={fields}
          onSubmit={this.handleSubmit}
          submitText={isEdit ? 'Update' : 'Add'}
          cancelText="Cancel"
          showCancel
          {...this.props}
        />
      </Fragment>
    );
  }
}
