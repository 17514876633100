const TYPE = {
  STRING: 'string',
  ENUM: 'enum',
  DATE: 'date',
  NUMBER: 'number',
  PERCENT_RATE: 'percent',
  CUSTOM: 'custom',
};

export default TYPE;
