import React, { Component, Fragment } from 'react';

import withFormsy from 'Components/Form/withFormsy';

import CourseSelectorInputQuery from '../../Reports/Components/SelectorInput/CourseSelectorInput.gql';

class CourseSelectorInput extends Component {
  render() {
    return (
      <Fragment>
        <CourseSelectorInputQuery {...this.props} />
      </Fragment>
    );
  }
}
const formsy = withFormsy(CourseSelectorInput);
formsy.SELECTOR_VALUE_TYPE = CourseSelectorInputQuery.SELECTOR_VALUE_TYPE;
formsy.COURSE_VERSION = CourseSelectorInputQuery.COURSE_VERSION;
formsy.COURSE_FIELDS = CourseSelectorInputQuery.COURSE_FIELDS;

export default formsy;
