import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';

import { Box, Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { CATEGORY_STATUS, CATEGORY_TYPE, SKU_CATEGORY } from 'Routes/Products/Domains/constants';

import CategoryModal from '../../../Components/CategoryModal';

const categoriesColumns = (
  variables,
  {
    skuCategory,
    categoryType,
    categoryName,
    parentId,
    getCategories,
    getCategoriesQueryName,
    getCategory,
    getCategoryQueryName,
    createMutation,
    updateMutation,
  }
) => [
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Permalink',
    accessor: 'permalink',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: [
      { key: CATEGORY_STATUS.VISIBLE, value: CATEGORY_STATUS.VISIBLE, text: 'Visible' },
      { key: CATEGORY_STATUS.INVISIBLE, value: CATEGORY_STATUS.INVISIBLE, text: 'Invisible' },
    ],
  },
  {
    Header: 'Item Number',
    accessor: 'itemNo',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <CategoryModal
          isEdit
          id={id}
          getCategories={getCategories}
          getCategoriesQueryName={getCategoriesQueryName}
          getCategory={getCategory}
          getCategoryQueryName={getCategoryQueryName}
          createMutation={createMutation}
          updateMutation={updateMutation}
          variables={variables}
          skuCategory={skuCategory}
          categoryType={categoryType}
          categoryName={categoryName}
          parentId={parentId}
          trigger={
            <Button basic size="mini" icon compact>
              <Icon name="edit" />
            </Button>
          }
        />
      );
    },
  },
];

class categoriesList extends Component {
  static propTypes = {
    getCategory: PropTypes.func.isRequired,
    getCategoryQueryName: PropTypes.string.isRequired,
    createMutation: PropTypes.func.isRequired,
    updateMutation: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    getCategoriesQueryName: PropTypes.string.isRequired,
    skuCategory: PropTypes.oneOf(Object.values(SKU_CATEGORY)).isRequired,
    categoryType: PropTypes.oneOf(Object.values(CATEGORY_TYPE)).isRequired,
    categoryName: PropTypes.string,
    parentId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { parentId: props.parentId },
      order: { field: 'itemNo', type: 'ASC' },
      parentId: props.parentId,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.parentId !== state.parentId) {
      return {
        paging: { currentPage: 0, pageSize: 10 },
        search: { parentId: props.parentId },
        order: { field: 'itemNo', type: 'ASC' },
        parentId: props.parentId,
      };
    }
    return state;
  }

  profileMutate = (mutation, id) => {
    const { search, paging, order } = this.state;
    const { getCategories } = this.props;
    this.props.client
      .mutate({
        mutation,
        variables: { id },
        refetchQueries: [
          {
            query: getCategories,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, parentId: this.props.parentId }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => {
    const { getCategoriesQueryName } = this.props;
    return data[getCategoriesQueryName].categories;
  };

  render() {
    const { search, paging, order } = this.state;
    const {
      getCategories,
      skuCategory,
      categoryType,
      categoryName,
      parentId,
      getCategory,
      getCategoryQueryName,
      getCategoriesQueryName,
      createMutation,
      updateMutation,
      id,
    } = this.props;
    const variables = { search, paging, order };
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Flex alignItems="center">
            <Text.Header>{categoryName}</Text.Header>
            <Box ml={2} />
            <CategoryModal
              isEdit
              getCategories={getCategories}
              getCategoriesQueryName={getCategoriesQueryName}
              getCategory={getCategory}
              getCategoryQueryName={getCategoryQueryName}
              createMutation={createMutation}
              updateMutation={updateMutation}
              title={categoryName}
              variables={variables}
              skuCategory={skuCategory}
              categoryType={categoryType}
              categoryName={categoryName}
              parentId={parentId}
              id={id}
              trigger={
                <Button.Group>
                  <Button basic size="mini" icon compact>
                    <Icon name="edit" />
                  </Button>
                </Button.Group>
              }
            />
          </Flex>
          <CategoryModal
            getCategories={getCategories}
            getCategoriesQueryName={getCategoriesQueryName}
            getCategory={getCategory}
            getCategoryQueryName={getCategoryQueryName}
            createMutation={createMutation}
            updateMutation={updateMutation}
            title={categoryName}
            variables={variables}
            skuCategory={skuCategory}
            categoryType={categoryType}
            categoryName={categoryName}
            parentId={parentId}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New {categoryName}
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={getCategoriesQueryName}
          resolveData={this.resolveData}
          columns={categoriesColumns(variables, this.props)}
          query={getCategories}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default withApollo(categoriesList);
