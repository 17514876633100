import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';
import { SemanticButton as Button } from '../../../Components/Base/Button';

import CertificateItems from './CertificateItems.gql';

export default class CertificateItemModal extends Component {
  static propTypes = {
    certificateId: PropTypes.string,
    trigger: PropTypes.element,
  };
  render() {
    const { certificateId, trigger } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>Certificate Conditions</Modal.Header>
            <Modal.Content>
              <CertificateItems certificateId={certificateId} />
            </Modal.Content>
            <Modal.Actions>
              <Button circular onClick={closeModal}>
                Close
              </Button>
            </Modal.Actions>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
