import urlJoin from 'url-join';

const routePrefix = '/products';
const categoriesRoutePrefix = 'categories';

//#region Products
export const onlineCoursesUrl = urlJoin(routePrefix, 'online-courses');
export const onlineCourseBatchesUrl = urlJoin(routePrefix, 'online-course-batches');
export const workshopUrl = urlJoin(routePrefix, 'workshops');
export const bundleUrl = urlJoin(routePrefix, 'bundles');
export const subscriptionsUrl = urlJoin(routePrefix, 'subscriptions');
export const collectionsUrl = urlJoin(routePrefix, 'collections');
export const booksUrl = urlJoin(routePrefix, 'books');
//#endregion

//#region Product detail
export const onlineCourseDetailsUrl = urlJoin(onlineCoursesUrl, ':SKUCode');

export const onlineCourseBatchDetailsUrl = urlJoin(onlineCourseBatchesUrl, ':SKUCode');

export const getOnlineCourseDetailsOverviewUrl = (skuCode) =>
  urlJoin(onlineCoursesUrl, skuCode, 'overview');

export const getOnlineCourseBatchDetailsOverviewUrl = (skuCode) =>
  urlJoin(onlineCourseBatchesUrl, skuCode, 'overview');

export const getWorkshopDetailsOverviewUrl = (skuCode) => urlJoin(workshopUrl, skuCode, 'overview');

export const getBundleDetailsOverviewUrl = (skuCode) => urlJoin(bundleUrl, skuCode, 'overview');

export const getSubscriptionDetailsOverviewUrl = (skuCode) =>
  urlJoin(subscriptionsUrl, skuCode, 'overview');

export const getCollectionDetailsOverviewUrl = (skuCode) =>
  urlJoin(collectionsUrl, skuCode, 'overview');

export const getBookDetailsOverviewUrl = (skuCode) => urlJoin(booksUrl, skuCode, 'overview');
//#endregion

//#region Categories
export const categoriesOnlineCoursesUrl = urlJoin(
  routePrefix,
  categoriesRoutePrefix,
  'online-course'
);
export const categoriesWorkshopsUrl = urlJoin(routePrefix, categoriesRoutePrefix, 'workshop');
export const categoriesBundleUrl = urlJoin(routePrefix, categoriesRoutePrefix, 'bundle');
export const categoriesSubscriptionUrl = urlJoin(
  routePrefix,
  categoriesRoutePrefix,
  'subscription'
);
export const categoriesBookUrl = urlJoin(routePrefix, categoriesRoutePrefix, 'book');
export const categoriesInstructorUrl = urlJoin(routePrefix, categoriesRoutePrefix, 'instructor');
//#endregion
