import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import withFormsy from 'Components/Form/withFormsy';
import { Image } from 'Components/Base';

import { readImageFromFile, readImageFromBase64, isBase64String } from './util';

class ImageReaderInput extends Component {
  static propTypes = {
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
  };

  static defaultProps = {
    imageWidth: 100,
    imageHeight: 100,
  };

  handleSignatureImageChange = async (event, _data) => {
    if (event.target.files[0]) {
      try {
        const imageObject = await readImageFromFile(event.target.files[0]);
        this.props.onChange({}, { value: imageObject });
      } catch (e) {
        this.props.onChange({}, { value: { error: e } });
      }
    }
  };

  componentDidMount() {
    if (isBase64String(this.props.value)) {
      readImageFromBase64(this.props.value).then((imageObject) => {
        this.props.onChange({}, { value: imageObject });
      });
    }
  }

  componentDidUpdate() {
    if (isBase64String(this.props.value)) {
      readImageFromBase64(this.props.value).then((imageObject) => {
        this.props.onChange({}, { value: imageObject });
      });
    }
  }

  render() {
    const {
      value,
      onChange,
      validations,
      name,
      imageWidth,
      imageHeight,
      ...restProps
    } = this.props;

    return (
      <Fragment>
        <Image width={imageWidth} src={value.base64Data} alt="" style={{ height: imageHeight }} />
        <input type="file" onChange={this.handleSignatureImageChange} />
      </Fragment>
    );
  }
}

const EnhancedImageReaderInput = withFormsy(ImageReaderInput);

EnhancedImageReaderInput.getBase64Data = (imageObject) => {
  return imageObject.base64Data;
};

export default EnhancedImageReaderInput;
