import React from 'react';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBook } from '../../GraphQL/query/Products.query';
import { updateBookPricingMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY } from '../../Domains/constants';
import SKUPricingForm from '../SKUPricingForm';

const resolveData = ({ sfBoProductBook }) => {
  return {
    ...sfBoProductBook,
    SKUStatus: `${sfBoProductBook.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED}/${
      sfBoProductBook.SKUStatus
    }`,
  };
};

const BookPricingEditor = (props) => {
  const { book = {} } = props;
  const { id, SKUCode } = book;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getBook}
        editMutation={updateBookPricingMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({ SKUCode })}
        getEditVariables={(form) => ({
          id,
          data: {
            ...form,
            priceInclVAT: _.omit(form.priceInclVAT, '__typename'),
            salePriceInclVAT: _.omit(form.salePriceInclVAT, '__typename'),
            promotionText: form.promotionText.content,
          },
        })}
      >
        {({ data, onSubmit }) => (
          <SKUPricingForm initialData={resolveData(data)} onSubmit={onSubmit} />
        )}
      </CreateEditForm>
    </Box>
  );
};

export default BookPricingEditor;
