import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import { GET_APP_ROLE } from '../../Graphql/query/Role.query';
import {
  GET_APP_PERMISSIONS,
  GET_APP_ROLE_PERMISSIONS,
} from '../../Graphql/query/Permission.query';
import { UPDATE_APP_ROLE_PERMISSION } from '../../Graphql/mutation/Permission.mutation';
import PermissionTable from '../../Components/PermissionTable';
import Snackbar, { VARIANTS } from '../../Components/Snackbar';

import { formatPermissionList } from './utils';

const TABLE_DATA = {
  columns: [
    {
      header: 'Feature Name',
      accessor: 'feature_name',
    },
    {
      header: 'View',
      accessor: 'view',
    },
    {
      header: 'Update',
      accessor: 'update',
    },
    {
      header: 'Delete',
      accessor: 'delete',
    },
  ],
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  PERMISSION_LIMITATION: {
    message: 'Assign permission to your role or higher is prohibited',
    variant: VARIANTS.ERROR,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Can't assign permission right now, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const PermissionTableContainer = ({ roleId, ...props }) => {
  const [feedback, setFeedback] = useState({});

  const { data: roleData } = useQuery(GET_APP_ROLE, {
    variables: { id: roleId },
    skip: !roleId,
    fetchPolicy: 'cache-only',
  });

  const onCompleted = useCallback(() => {
    const roleName = roleData?.appRole?.name;
    setFeedback({
      message: `Assign permission to role${roleName ? ` "${roleName}" ` : ' '}successfully!`,
      variant: VARIANTS.SUCCESS,
      show: true,
    });
  }, [roleData]);

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('403')) {
      setFeedback(FEEDBACK.PERMISSION_LIMITATION);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const { loading: appPermissionLoading, data: appPermissionData } = useQuery(GET_APP_PERMISSIONS);
  const { loading: rolePermissionLoading, data: rolePermissionData } = useQuery(
    GET_APP_ROLE_PERMISSIONS,
    {
      variables: {
        id: roleId,
      },
    }
  );

  const [updateAppRolePermission] = useMutation(UPDATE_APP_ROLE_PERMISSION, {
    refetchQueries: [{ query: GET_APP_ROLE_PERMISSIONS, variables: { id: roleId } }],
    onCompleted,
    onError,
  });

  const handleUpdatePermission = useCallback(
    (permissionList) => {
      updateAppRolePermission({
        variables: {
          id: roleId,
          appPermissionIds: permissionList,
        },
      });
    },
    [updateAppRolePermission, roleId]
  );

  const { tableData, currentPermissionData } = useMemo(() => {
    const appPermissions = appPermissionData?.appPermissions ?? [];
    const appRolePermissions = rolePermissionData?.appRolePermissions ?? [];
    const tableData = formatPermissionList(appPermissions, appRolePermissions);
    const currentPermissionData = appRolePermissions.map((permission) => permission.id);
    return { currentPermissionData, tableData };
  }, [appPermissionData, rolePermissionData]);

  if (appPermissionLoading || rolePermissionLoading) {
    return <h2 style={{ textAlign: 'center' }}> Loading </h2>;
  }

  return (
    <>
      <PermissionTable
        columns={TABLE_DATA.columns}
        data={tableData}
        currentPermissionData={currentPermissionData}
        submitButtonText={'Save Permission'}
        onTableSubmit={handleUpdatePermission}
      />
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
};

PermissionTableContainer.propTypes = {
  roleId: PropTypes.string.isRequired,
};

export default PermissionTableContainer;
