import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { GenSwitchRoute } from 'Util/Route';
import SideMenuLayout from 'Components/SideMenuLayout';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import AllSyllabusesRoute from './Routes/AllSyllabusesRoute';
import AllSubjectsRoute from './Routes/AllSubjectsRoute';
import AllAssignmentsRoute from './Routes/AllAssignmentsRoute';
import SyllabusDetailsRoute from './Routes/SyllabusDetailsRoute';
const RouteIndex = () => <Redirect to={{ title: '', pathname: '/lsp/syllabuses/list' }} />;

const defaultRoute = {
  path: '/lsp/syllabuses',
  exact: true,
  roles: [],
  route: RouteIndex,
};

const menus = [
  {
    path: '/lsp/syllabuses/list',
    title: 'Syllabuses',
    menuName: 'Syllabuses',
    icon: 'book',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: AllSyllabusesRoute,
  },
  {
    path: '/lsp/syllabuses/list/:id',
    title: 'Syllabus Details',
    menuName: 'Syllabus Details',
    icon: 'book',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: SyllabusDetailsRoute,
    hidden: true,
  },
  {
    path: '/lsp/syllabuses/subjects',
    title: 'Subjects',
    menuName: 'Subjects',
    icon: 'pencil',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: AllSubjectsRoute,
  },
  {
    path: '/lsp/syllabuses/assignments',
    title: 'Assignments',
    menuName: 'Assignments',
    icon: 'edit',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: AllAssignmentsRoute,
  },
];

export default class SyllabusesRoute extends Component {
  render() {
    return (
      <SideMenuLayout
        header={'Types'}
        menus={menus.filter(({ hidden }) => !hidden)}
        contentProps={{ textAlign: 'left' }}
      >
        <GenSwitchRoute routes={[...menus, defaultRoute]} />
      </SideMenuLayout>
    );
  }
}
