import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createSchoolTermMutation,
  updateSchoolTermMutation,
} from 'GraphQL/LSP/mutation/Education.mutation';
import { getSchoolTerms, getSchoolTerm } from 'GraphQL/LSP/query/Education.query';

import SchoolTermForm from './SchoolTermForm';

export default class SchoolTermModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };
  convertFormValue(form) {
    const data = {
      ...form,
      startDate: form.dateRange.startDate,
      endDate: form.dateRange.endDate,
    };
    delete data.dateRange;
    return data;
  }

  render() {
    const { isEdit, id, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'แก้ไขเทอม' : 'สร้างเทอม'}</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getSchoolTerm}
                createMutation={createSchoolTermMutation}
                createRefetchQueries={[
                  {
                    query: getSchoolTerms,
                    variables,
                  },
                ]}
                editMutation={updateSchoolTermMutation}
                isEdit={isEdit}
                id={id}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  schoolTermData: this.convertFormValue(form),
                })}
                getCreateVariables={(form) => ({
                  schoolTermData: this.convertFormValue(form),
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <SchoolTermForm initialData={data && data.boSchoolTerm} onSubmit={onSubmit} />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
