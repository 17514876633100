import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactDragList from 'react-drag-list';
import { useMutation } from '@apollo/client';

import HTMLView from 'Components/HTMLView';
import { Box, Flex, ButtonFilled, Text } from 'Components/Base';
import { UPDATE_EXAM_FIXED_RULES } from 'GraphQL/mutation/Exam.mutation';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';

const QuestionView = styled(HTMLView)`
  text-align: left;
`;

const QuestionWrapper = styled(Box)`
  .rc-draggable-list > div {
    & > .rc-draggable-list-draggableRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 20px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      cursor: pointer;
      & > .rc-draggable-list-handles {
        order: 1;
        margin-left: 4px;
      }
    }
    & > :not(:last-child) {
      border-bottom: none;
    }
    & > :first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    & > :last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
`;

const FEEDBACK = {
  SUCCESS: {
    message: `Save conditions successfully`,
    variant: VARIANTS.SUCCESS,
    show: true,
  },
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot save conditions, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const QuestionOrder = ({ examItems, examItemsOrder, examId, disabled }) => {
  const [questions, setQuestions] = useState(examItems);
  useEffect(() => setQuestions(examItems), [examItems]);

  const [feedback, setFeedback] = useState({});

  const onCompleted = useCallback((response) => setFeedback(FEEDBACK.SUCCESS), []);

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const [updateExamRule] = useMutation(UPDATE_EXAM_FIXED_RULES, { onCompleted, onError });

  const handleChangeOrder = useCallback(
    (e, updatedQuestions) => setQuestions([...updatedQuestions]),
    []
  );

  const questionIds = questions.map((question) => question.questionId);
  const isChanged = questionIds.some((update, index) => update !== examItemsOrder[index]);

  const handleUpdateRules = async (e) =>
    await updateExamRule({ variables: { examId, rules: { questions: questionIds } } });

  return (
    <>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
      <Text mb={2} fontWeight={'bold'}>
        Conditions
      </Text>
      {examItems.length === 0 ? (
        <Text>Please publish question</Text>
      ) : (
        <>
          <QuestionWrapper>
            <ReactDragList
              // FIXME: this is workaround, disabled props doesn't work with props update ref: https://github.com/alcat2008/react-drag-list/issues/12
              key={disabled}
              dataSource={questions}
              row={({ question }, index) => (
                <QuestionView rawContent={`${index + 1}.${question}`} />
              )}
              rowKey="id"
              onUpdate={handleChangeOrder}
              disabled={disabled}
            />
          </QuestionWrapper>
          <Flex justifyContent="flex-end" mt={3} alignItems="center">
            {isChanged && (
              <Text color={'red'} mr={2}>
                Edited conditions is not yet published.
              </Text>
            )}
            <ButtonFilled
              px={3}
              py={2}
              bg="primary"
              color="white"
              style={{ cursor: 'pointer', border: 0 }}
              fontWeight="600"
              fontSize="14px"
              disabled={!isChanged || disabled}
              onClick={handleUpdateRules}
            >
              Save conditions
            </ButtonFilled>
          </Flex>
        </>
      )}
    </>
  );
};

export default QuestionOrder;
