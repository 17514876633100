import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import CustomConditionInput from '../CustomConditionInput';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Available Watch Time (Minutes)',
      inputName: 'watchTimeMinutes',
      customInput: Object.assign(
        (props) => (
          <CustomConditionInput
            type="number"
            checkBoxLabel="ดูได้ตลอดไม่จำกัดเวลา"
            checkBoxValue={-1}
            {...props}
          />
        ),
        {
          displayName: 'AvailableWatchTimeInput',
        }
      ),
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Expires in (Days)',
      inputName: 'days',
      customInput: Object.assign(
        (props) => (
          <CustomConditionInput
            type="number"
            checkBoxLabel="คอร์สไม่มีวันหมดอายุ"
            checkBoxValue={-1}
            {...props}
          />
        ),
        {
          displayName: 'DaysLimitInput',
        }
      ),
      mode: MODE.BOTH,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class OnlineCourseConditionsForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  handleSubmit = () => {};

  validate = ({ watchTimeMinutes, days }, isChanged) => {
    const error = {};
    if (
      isChanged &&
      (isNaN(Number(watchTimeMinutes)) || watchTimeMinutes === 0 || watchTimeMinutes < -1)
    ) {
      error.watchTimeMinutes = 'Available Watch Time value must contain number higher than 0';
    }
    if (isChanged && (isNaN(Number(days)) || days === 0 || days < -1)) {
      error.days = 'Expires in value must contain number higher than 0';
    }
    return error;
  };

  render() {
    const { isEdit, onSubmit } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit)}
        onSubmit={onSubmit}
        customValidation={this.validate}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}

export default OnlineCourseConditionsForm;
