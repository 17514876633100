import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';

import CreateEditQuestionForm from './CreateEditQuestionForm';

const QuestionModal = ({
  examId,
  examItemId,
  isEdit,
  publishMode,
  isOpen,
  trigger,
  ...restProps
}) => {
  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Content>
            <CreateEditQuestionForm
              examId={examId}
              examItemId={examItemId}
              isEdit={isEdit}
              publishMode={publishMode}
              onComplete={closeModal}
              onCancelled={closeModal}
              {...restProps}
            />
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
};

QuestionModal.propTypes = {
  examId: PropTypes.string,
  examItemId: PropTypes.string,
  isEdit: PropTypes.bool,
  publishMode: PropTypes.bool,
  isOpen: PropTypes.bool,
  trigger: PropTypes.element,
};
QuestionModal.defaultProps = {
  isEdit: false,
  publishMode: false,
};

export default QuestionModal;
