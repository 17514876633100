import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, Divider, Icon } from 'semantic-ui-react';
import getUniqId from 'lodash/uniqueId';

import { SemanticButton as Button } from 'Components/Base/Button';
import { Box, Flex } from 'Components/Base';
import SectionList from 'Components/Section';
import delve from 'Util/Delve';

import QuickActionBar from './QuickActionBar';
import SectionContent from './SectionContent';

const SectionComponent = ({ skuCode, id, quote, reviewer, media, children, ...restProps }) => (
  <Box>
    <SectionContent
      skuCode={skuCode}
      id={id}
      quote={quote}
      reviewer={reviewer}
      media={media}
      {...restProps}
    />
    {children}
    <Divider hidden />
  </Box>
);

SectionComponent.propTypes = {
  ...SectionContent.propTypes,
};

const createSectionData = (
  skuCode,
  sections = [],
  activeSectionIndex,
  actions = {
    onUpdateSectionMeta: () => {},
    onDeleteSection: () => {},
    onReorderSection: () => {},
    onCloseSection: () => {},
    onFormDirty: () => {},
  }
) => {
  const result = sections.map((section, index) => {
    const id = section.id;
    const newSection = {
      key: id,
      id: id,
      active: index === activeSectionIndex ? true : undefined,
      title: {
        left: (
          <Header>
            <Icon size="large" name="dropdown" />
            Testimonial #{index + 1}
          </Header>
        ),
        right: (
          <QuickActionBar
            itemIndex={index}
            totalItems={sections.length}
            isSection={true}
            id={id}
            onDeleteClick={(e) => {
              actions.onDeleteSection(index);
            }}
            onReorderClick={(e, sourceIndex, destinationIndex) => {
              actions.onReorderSection(sourceIndex, destinationIndex);
              e.stopPropagation();
            }}
          />
        ),
      },
      content: {
        content: (
          <SectionComponent
            skuCode={skuCode}
            id={id}
            media={delve(section, 'media', {})}
            quote={delve(section, 'quote', {})}
            reviewer={delve(section, 'reviewer', {})}
            onUpdateMeta={(data) => {
              const passingData = { ...data, id: id };
              actions.onUpdateSectionMeta(index, passingData);
            }}
            onClose={() => actions.onCloseSection(index)}
            onFormDirty={() => actions.onFormDirty(index)}
          />
        ),
      },
    };

    return newSection;
  });
  return result;
};

const mappingContents = (contents = []) => {
  return contents.map((c) => {
    return {
      id: c.id || getUniqId(),
      ...c,
    };
  });
};
const TestimonialsContentView = ({
  skuCode,
  contents: initialContents,
  onUpdateContents = () => {},
  loading,
}) => {
  const [contents, setContents] = useState(mappingContents(initialContents));
  const [activeSectionIndex, setActiveSectionIndex] = useState(null);
  const [forceActiveCurrentSection, setForceActiveCurrentSection] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setContents(mappingContents(initialContents));
    setForceActiveCurrentSection(false);
    setActiveSectionIndex(null);
    setIsDirty(false);
    return () => {};
  }, [initialContents]);

  /* Section Component */
  function handleUpdateContent(index, data) {
    console.log(index, data);
    const newContents = [...contents];
    newContents[index] = data;
    setContents(newContents);
    setActiveSectionIndex(null);
    setForceActiveCurrentSection(false);
    setIsDirty(true);
  }

  function handleReorderSection(sourceIndex, destinationIndex) {
    const newContents = [...contents];
    [newContents[destinationIndex], newContents[sourceIndex]] = [
      newContents[sourceIndex],
      newContents[destinationIndex],
    ];
    setIsDirty(true);
    setContents(newContents);
  }

  function handleDeleteSection(index) {
    const newContents = [...contents];
    newContents.splice(index, 1);
    console.log('delete index', index, newContents);
    setIsDirty(true);
    setContents(newContents);
  }

  const getNewContent = () => ({
    id: getUniqId(),
    quote: {
      type: 'MARKDOWN',
      content: '',
    },
    reviewer: {
      type: 'MARKDOWN',
      content: '',
    },
    media: null,
  });
  /* This Component */
  function handleAddSection() {
    setIsDirty(true);
    setContents([...contents, getNewContent()]);
  }

  function handleReset() {
    setForceActiveCurrentSection(false);
    setActiveSectionIndex(null);
    setContents(mappingContents(initialContents));
    setIsDirty(false);
  }

  function handleSectionClick(index) {
    if (!forceActiveCurrentSection) {
      setForceActiveCurrentSection(false);
      setActiveSectionIndex(index);
    }
  }

  function handleOnFormDirty(index) {}

  function handleOnCloseSection() {
    // setActiveSectionIndex(null);
  }

  /* Props */
  function handleOnUpdateContents() {
    console.log(contents);
    onUpdateContents(contents);
  }
  return (
    <Box>
      <SectionList
        sections={createSectionData(
          skuCode,
          contents,
          forceActiveCurrentSection ? activeSectionIndex : undefined,
          {
            onUpdateSectionMeta: handleUpdateContent,
            onDeleteSection: handleDeleteSection,
            onReorderSection: handleReorderSection,
            onFormDirty: handleOnFormDirty,
          }
        )}
        onSectionClick={handleSectionClick}
      />
      <Divider hidden />
      <Button primary circular basic fluid onClick={handleAddSection} debounceTime={300}>
        <Icon name="plus square" />
        Add a Testimonial
      </Button>
      <Divider />
      <Flex justifyContent="flex-end" mt={3}>
        <Button
          primary
          circular
          size="medium"
          type="submit"
          content={'Update'}
          loading={loading}
          disabled={!isDirty}
          onClick={handleOnUpdateContents}
        />
        <Button
          circular
          size="medium"
          type="reset"
          content={'Reset'}
          disabled={false}
          onClick={handleReset}
        />
      </Flex>
    </Box>
  );
};

TestimonialsContentView.propTypes = {
  skuCode: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.object),
};

TestimonialsContentView.defaultProps = {
  contents: [],
};

export default TestimonialsContentView;
