import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const SelectorInput = (props) => {
  const { options, multiple, value, placeholder, onChange, onSearchChange } = props;
  const [search, setSearch] = useState('');

  const handleDropdownChange = (event, data) => {
    setSearch('');
    onChange(event, data);
  };

  const handleSearchChange = (event, data) => {
    setSearch(data.searchQuery);
    if (typeof onSearchChange === 'function') {
      onSearchChange(event, data);
    }
  };

  return (
    <Dropdown
      placeholder={placeholder}
      fluid
      multiple={multiple}
      search
      searchQuery={search}
      selection
      value={value}
      options={options}
      onChange={handleDropdownChange}
      onSearchChange={handleSearchChange}
    />
  );
};

SelectorInput.propTypes = {
  placeholder: PropTypes.string,
  accessor: PropTypes.string,
  formInput: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  multiple: PropTypes.bool,
  onSearchChange: PropTypes.func,
};

SelectorInput.defaultProps = {
  onChange: () => {},
  options: [],
  multiple: true,
  value: null,
  onSearchChange: null,
};

export default SelectorInput;
