import React from 'react';
import PropTypes from 'prop-types';
import { Header, Modal } from 'semantic-ui-react';

import KatexView from 'Components/KatexView/KatexView';

const LegacyHTMLEditorPreviewModal = ({ trigger, editorContent, isOpen, setIsOpen }) => {
  const triggerElement = {
    ...trigger,
    props: { ...trigger.props, onClick: () => setIsOpen(true) },
  };

  return (
    <Modal
      closeIcon
      size="large"
      trigger={triggerElement}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Header style={{ textAlign: 'center' }} content="Preview for classroom" />
      <Modal.Content>
        <KatexView rawContent={editorContent} />
      </Modal.Content>
    </Modal>
  );
};

LegacyHTMLEditorPreviewModal.propTypes = {
  trigger: PropTypes.node,
  editorContent: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default LegacyHTMLEditorPreviewModal;
