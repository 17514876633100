import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { Text, ButtonText, ButtonFilled as Button } from 'Components/Base';

const CONFIRMATION_MODAL_VARIANT_TYPE = {
  DEFAULT: 'default',
  DANGER: 'danger',
};

const CONFIRMATION_MODAL_VARIANT = {
  [CONFIRMATION_MODAL_VARIANT_TYPE.DEFAULT]: {
    confirmButtonColor: 'primary',
  },
  [CONFIRMATION_MODAL_VARIANT_TYPE.DANGER]: {
    confirmButtonColor: 'danger',
  },
};

const ConfirmationModal = ({
  isOpen,
  headerText,
  contentText,
  confirmText,
  dismissText,
  onConfirm,
  onDismiss,
  variant,
  ...props
}) => {
  const handleDismissClick = () => {
    onDismiss();
  };

  const handleConfirmClick = () => {
    onConfirm();
  };

  const { confirmButtonColor } = CONFIRMATION_MODAL_VARIANT[variant];
  return (
    <Dialog open={isOpen} fullWidth {...props}>
      <DialogTitle>{headerText}</DialogTitle>
      <DialogContent dividers>{contentText}</DialogContent>
      <DialogActions>
        <ButtonText
          type="button"
          fontSize="inherit"
          fontWeight="inherit"
          onClick={handleDismissClick}
        >
          <Text>{dismissText}</Text>
        </ButtonText>
        <Button
          type="button"
          fontSize="inherit"
          fontWeight="inherit"
          color={confirmButtonColor}
          borderRadius={2}
          px={3}
          py={2}
          onClick={handleConfirmClick}
        >
          <Text color="white">{confirmText}</Text>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  headerText: PropTypes.string,
  contentText: PropTypes.string,
  confirmText: PropTypes.string,
  dismissText: PropTypes.string,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(CONFIRMATION_MODAL_VARIANT_TYPE)),
};

ConfirmationModal.defaultProps = {
  isOpen: false,
  confirmText: 'Confirm',
  dismissText: 'Cancel',
  onConfirm: () => {},
  onDismiss: () => {},
  variant: CONFIRMATION_MODAL_VARIANT_TYPE.DEFAULT,
};

export default ConfirmationModal;
