import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import omitDeep from 'Util/omitDeep';
import delve from 'Util/Delve';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import { updateBundleTableOfContentMutation } from '../../GraphQL/mutation/Products.mutation';
import { getBundle } from '../../GraphQL/query/Products.query';
import TableOfContentView from '../WorkshopContent';

export default function BundleTableOfContentEditor({ bundle }) {
  const { id, SKUCode } = bundle;
  const { loading, error, data: queryData } = useQuery(getBundle, {
    variables: { SKUCode: SKUCode },
    fetchPolicy: 'network-only',
  });
  const [
    updateBundleTableOfContent,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(updateBundleTableOfContentMutation);

  function handleUpdateContents(data) {
    const clonedData = JSON.parse(JSON.stringify(data));
    const formatData = omitDeep(clonedData, ['id', '__typename']);
    updateBundleTableOfContent({
      variables: {
        id: id,
        data: {
          tableOfContents: formatData,
        },
      },
    });
  }

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  const contents = delve(queryData, 'sfBoProductBundle.tableOfContents', []);

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={true}
        response={delve(mutationResponse, 'sfBoProductUpdateBundleTableOfContents')}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <TableOfContentView
        loading={mutationLoading}
        contents={contents}
        onUpdateContents={handleUpdateContents}
        accessor={{ title: 'title', description: 'description' }}
      />
    </Fragment>
  );
}
