import { generatePath } from 'react-router';

export const coursesPath = '/courses/:courseVersion';
export const editCoursePath = `${coursesPath}/:courseId`;
export const courseOverviewPath = `${editCoursePath}/overview`;
export const courseLessonsPath = `${editCoursePath}/lessons`;
export const courseStatisticsPath = `${editCoursePath}/statistics`;
export const courseUsersPath = `${editCoursePath}/users`;
export const courseEnrollmentPath = `${editCoursePath}/enrollment`;
export const courseAssignmentsPath = `${editCoursePath}/assignments`;
export const courseSubsectionAssignmentsPath = `${courseAssignmentsPath}/:sectionId`;
export const assignmentsPath = `${courseSubsectionAssignmentsPath}/:subsectionId`;
export const userAssignmentPath = `${assignmentsPath}/:userAssignmentId`;
export const courseAnnouncementsPath = `${editCoursePath}/announcement`;

export const getCoursesUrl = ({ courseVersion }) => generatePath(coursesPath, { courseVersion });
export const getCourseOverviewUrl = ({ courseVersion, courseId }) =>
  generatePath(courseOverviewPath, { courseVersion, courseId });
export const getCourseLessonsUrl = ({ courseVersion, courseId }) =>
  generatePath(courseLessonsPath, { courseVersion, courseId });
export const getCourseStatisticsUrl = ({ courseVersion, courseId }) =>
  generatePath(courseStatisticsPath, { courseVersion, courseId });
export const getCourseUsersUrl = ({ courseVersion, courseId }) =>
  generatePath(courseUsersPath, { courseVersion, courseId });
export const getCourseEnrollmentUrl = ({ courseVersion, courseId }) =>
  generatePath(courseEnrollmentPath, { courseVersion, courseId });
export const getCourseAssignmentsUrl = ({ courseVersion, courseId }) =>
  generatePath(courseAssignmentsPath, { courseVersion, courseId });
export const getCourseSubsectionAssignmentsPath = ({ courseVersion, courseId, sectionId }) =>
  generatePath(courseSubsectionAssignmentsPath, { courseVersion, courseId, sectionId });
export const getAssignmentsPath = ({ courseVersion, courseId, sectionId, subsectionId }) =>
  generatePath(assignmentsPath, { courseVersion, courseId, sectionId, subsectionId });
export const getCourseAnnouncementsUrl = ({ courseVersion, courseId }) =>
  generatePath(courseAnnouncementsPath, { courseVersion, courseId });
export const getUserAssignmentPath = ({
  courseVersion,
  courseId,
  sectionId,
  subsectionId,
  userAssignmentId,
}) =>
  generatePath(userAssignmentPath, {
    courseVersion,
    courseId,
    sectionId,
    subsectionId,
    userAssignmentId,
  });
