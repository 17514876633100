import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import FormGenerator from 'Components/Form/FormGenerator';
import Loading from 'Components/Loading';
import { getAnnouncementFileImportJobs } from 'GraphQL/query/Announcement.query';
import { Flex, Text } from 'Components/Base';

import { PublishTimeRangeInput, PopupAudienceInput, PopupSlideInput } from '../Components';
import { ANNOUNCEMENT_INPUT_LABEL } from '../Components/constants';
import PopupByPlatform from '../Components/PopupByPlatform';

const fieldsWith = (isView, isUploading) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Topic',
    inputName: 'title',
    inputProps: {
      required: true,
      readOnly: isView,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: ANNOUNCEMENT_INPUT_LABEL.SLIDE,
    inputName: 'detail',
    inputProps: {
      readOnly: isView,
    },
    customInput: (props) => <PopupSlideInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Audience',
    inputName: 'audiences',
    inputProps: {
      checkBoxLabel: 'All',
      validationErrors: {
        isDefaultRequiredValue: `Audience is required`,
      },
      required: true,
      readOnly: isView,
      isUploading,
    },
    customInput: (props) => <PopupAudienceInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'PC Only',
    inputName: 'platforms',
    inputProps: { readOnly: isView },
    customInput: (props) => <PopupByPlatform {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Publish Date/Time',
    inputName: 'publishConfig',
    inputProps: {
      validationErrors: {
        isDefaultRequiredValue: `Publish Date/Time is required`,
      },
      required: true,
      readOnly: isView,
    },
    customInput: (props) => <PublishTimeRangeInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Announced by',
    inputName: 'announcedBy',
    inputProps: {
      required: true,
      readOnly: isView,
    },
  },
];

const Form = ({
  isEdit,
  isView,
  isUploading,
  onSubmit,
  onSubmitDraft,
  onFileListRequest,
  onFileUploadUrlRequest,
  initialData,
  readOnly,
  ...props
}) => {
  const file = initialData?.audiences?.files?.[0] ?? {};
  const { fileImportJobId } = file;
  const { loading: fileImportJobLoading, data: fileImportJobData } = useQuery(
    getAnnouncementFileImportJobs,
    {
      variables: {
        search: { id: fileImportJobId },
        paging: { pageSize: 1, currentPage: 0 },
        order: { field: 'createdAt', type: 'DESC' },
      },
      skip: !fileImportJobId,
      fetchPolicy: 'network-only',
    }
  );

  const handleSubmit = (formValue) => {
    onSubmit({ ...formValue });
  };

  const handleSubmitDraft = (formValue) => {
    onSubmitDraft({ ...formValue });
  };

  if (fileImportJobLoading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }

  const { status: fileImportJobStatus } =
    fileImportJobData?.announcementFileImportJobs?.fileImportJobs?.[0] ?? {};

  return (
    <>
      {fileImportJobStatus && (
        <Flex justifyContent="flex-end" mb={1}>
          <Text fontSize={17}>{`Import File Status: ${fileImportJobStatus}`}</Text>
        </Flex>
      )}
      <FormGenerator
        fields={fieldsWith(isView, isUploading)}
        compact
        showCancel
        showAction
        isRequiredConfirmation
        submitText={'Save & Publish'}
        submitContent={`This announcement will be saved and publish.`}
        cancelText="Cancel"
        showAddition
        additionText={'Save Draft'}
        additionContent={`This announcement will be saved as draft.`}
        onSubmit={handleSubmit}
        onAdditionSubmit={handleSubmitDraft}
        initialData={initialData}
        {...props}
      />
    </>
  );
};

Form.propTypes = {
  isEdit: PropTypes.bool,
  isView: PropTypes.bool,
  isUploading: PropTypes.bool,
  readOnly: PropTypes.bool,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitDraft: PropTypes.func,
  onFileListRequest: PropTypes.func,
  onFileUploadUrlRequest: PropTypes.func,
};

export default Form;
