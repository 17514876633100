import { BACKOFFICE_PRODUCT } from '../../GraphQL/query/AppConfig.query';

export const MODE = {
  SECTION: 'SECTION',
  SECTION_GROUP: 'SECTION_GROUP',
  SUBSECTION: 'SUBSECTION',
};

export const MODE_STRING = {
  [MODE.SECTION]: 'Section',
  [MODE.SECTION_GROUP]: 'Section Group',
  [MODE.SUBSECTION]: 'Subsection',
};

export const SECTION_CONTENT_TYPE = {
  SECTION_GROUP: 'SECTION_GROUP',
};

export const SUBSECTION_CONTENT_TYPE = {
  VIDEOCHAT: 'VIDEOCHAT',
  VIDEO: 'VIDEO',
  VIDEO_SLIDE: 'VIDEO_SLIDE',
  // SLIDE: 'SLIDE', // possible ???
  QUIZ: 'QUIZ',
  QUIZ_SIMPLE: 'QUIZ_SIMPLE',
  ARTICLE: 'ARTICLE',
  LIVECODING: 'LIVECODING',
  LIVE_VIDEO: 'LIVE_VIDEO',
  NOTEBOARD: 'NOTEBOARD',
  FULL_LESSON: 'FULL_LESSON',
  SUMMARY_LESSON: 'SUMMARY_LESSON',
  EBOOK: 'EBOOK',
  ASSIGNMENT: 'ASSIGNMENT',
};

export const SUBSECTION_CONTENT_TYPE_STRING = {
  [SUBSECTION_CONTENT_TYPE.VIDEOCHAT]: 'Video Chat (Ignite Talks)',
  [SUBSECTION_CONTENT_TYPE.VIDEO]: 'Lecture (Video Only)',
  [SUBSECTION_CONTENT_TYPE.VIDEO_SLIDE]: 'Lecture (Video And Slides)',
  [SUBSECTION_CONTENT_TYPE.QUIZ]: 'Quiz',
  [SUBSECTION_CONTENT_TYPE.QUIZ_SIMPLE]: 'Quiz (Non-Gradable)',
  [SUBSECTION_CONTENT_TYPE.ARTICLE]: 'Article',
  [SUBSECTION_CONTENT_TYPE.LIVECODING]: 'Live Coding',
  [SUBSECTION_CONTENT_TYPE.LIVE_VIDEO]: 'Live Video',
  [SUBSECTION_CONTENT_TYPE.NOTEBOARD]: 'Post-its Board',
  [SUBSECTION_CONTENT_TYPE.FULL_LESSON]: 'Full Lesson (Video and PDF)',
  [SUBSECTION_CONTENT_TYPE.SUMMARY_LESSON]: 'Summary Lesson (Video and PDF)',
  [SUBSECTION_CONTENT_TYPE.EBOOK]: 'E-Book (PDF Only)',
  [SUBSECTION_CONTENT_TYPE.ASSIGNMENT]: 'Assignment',
};

export const MATERIAL_TYPES = {
  SLIDES: 'SLIDES',
  VIDEO: 'VIDEO',
  ARTICLE: 'ARTICLE',
  EXTERNAL: 'EXTERNAL', // URL
  YOUTUBE: 'YOUTUBE',
  QUIZ: 'QUIZ',
  QUIZ_SIMPLE: 'QUIZ_SIMPLE',
  LIVECODING: 'LIVECODING',
  LIVE_VIDEO: 'LIVE_VIDEO',
  NOTEBOARD: 'NOTEBOARD',
  PDF: 'PDF',
  ASSIGNMENT: 'ASSIGNMENT',
  OTHER: 'OTHER', // Uploaded file,
};

export const MATERIAL_TYPES_ICON = {
  [MATERIAL_TYPES.SLIDES]: 'image',
  [MATERIAL_TYPES.VIDEO]: 'video',
  [MATERIAL_TYPES.ARTICLE]: 'file text outline',
  [MATERIAL_TYPES.YOUTUBE]: 'youtube play',
  [MATERIAL_TYPES.EXTERNAL]: 'external',
  [MATERIAL_TYPES.QUIZ]: 'play circle',
  [MATERIAL_TYPES.QUIZ_SIMPLE]: 'play circle',
  [MATERIAL_TYPES.LIVECODING]: 'code',
  [MATERIAL_TYPES.LIVE_VIDEO]: 'video',
  [MATERIAL_TYPES.NOTEBOARD]: 'sticky note',
  [MATERIAL_TYPES.PDF]: 'file pdf outline',
  [MATERIAL_TYPES.OTHER]: 'file',
};

export const MATERIAL_TYPES_STRING = {
  [MATERIAL_TYPES.SLIDES]: 'slides',
  [MATERIAL_TYPES.VIDEO]: 'Video',
  [MATERIAL_TYPES.ARTICLE]: 'Article',
  [MATERIAL_TYPES.YOUTUBE]: 'YouTube Video',
  [MATERIAL_TYPES.EXTERNAL]: 'Link',
  [MATERIAL_TYPES.QUIZ]: 'Quiz',
  [MATERIAL_TYPES.QUIZ_SIMPLE]: 'Quiz (Simple)',
  [MATERIAL_TYPES.LIVECODING]: 'Live Coding',
  [MATERIAL_TYPES.LIVE_VIDEO]: 'Live Video',
  [MATERIAL_TYPES.NOTEBOARD]: 'Post-its Board',
  [MATERIAL_TYPES.PDF]: 'PDF',
  [MATERIAL_TYPES.OTHER]: 'File',
};

const MATERIAL_TYPE_DATA_KEY = {
  [MATERIAL_TYPES.SLIDES]: 'slidesData',
  [MATERIAL_TYPES.VIDEO]: 'videoData',
  [MATERIAL_TYPES.ARTICLE]: 'articleData',
  [MATERIAL_TYPES.QUIZ_SIMPLE]: 'quizSimpleData',
  [MATERIAL_TYPES.YOUTUBE]: 'youtubeData',
  [MATERIAL_TYPES.EXTERNAL]: 'externalData',
  [MATERIAL_TYPES.LIVE_VIDEO]: 'liveVideoData',
  [MATERIAL_TYPES.LIVECODING]: 'liveCodingData',
  [MATERIAL_TYPES.NOTEBOARD]: 'noteboardData',
  [MATERIAL_TYPES.PDF]: 'pdfData',
  [MATERIAL_TYPES.ASSIGNMENT]: 'assignmentData',
  [MATERIAL_TYPES.OTHER]: 'otherData',
};

export const resolveMaterialDataKey = (type) => MATERIAL_TYPE_DATA_KEY[type] || 'data'; // Make use of default case, content for 'data' will be JSONObject

export const resolveIconType = (type) => {
  if (MATERIAL_TYPES_ICON[type]) return MATERIAL_TYPES_ICON[type];
  return 'file outline';
};

export const actionList = [
  {
    id: MATERIAL_TYPES.SLIDES,
    title: 'Slides',
    icon: 'image',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [
      { type: SUBSECTION_CONTENT_TYPE.VIDEOCHAT, multiple: true },
      { type: SUBSECTION_CONTENT_TYPE.VIDEO_SLIDE, multiple: false },
    ],
  },
  {
    id: MATERIAL_TYPES.VIDEO,
    title: 'Video',
    icon: 'video',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [
      { type: SUBSECTION_CONTENT_TYPE.VIDEOCHAT, multiple: true }, // Teacher can send video to student (so support multiple)
      { type: SUBSECTION_CONTENT_TYPE.VIDEO, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.VIDEO_SLIDE, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.FULL_LESSON, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.SUMMARY_LESSON, multiple: false },
    ],
  },
  {
    id: MATERIAL_TYPES.ARTICLE,
    title: 'Article',
    icon: 'file text outline',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [
      { type: SUBSECTION_CONTENT_TYPE.VIDEO_SLIDE, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.VIDEO, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.ARTICLE, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.QUIZ, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.QUIZ_SIMPLE, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.LIVECODING, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.NOTEBOARD, multiple: false },
    ],
  },
  {
    id: MATERIAL_TYPES.YOUTUBE,
    title: 'YouTube Video',
    icon: 'youtube play',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [
      { type: SUBSECTION_CONTENT_TYPE.VIDEOCHAT, multiple: true }, // Teacher can send video to student (so support multiple)
      { type: SUBSECTION_CONTENT_TYPE.VIDEO, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.VIDEO_SLIDE, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.FULL_LESSON, multiple: false },
      { type: SUBSECTION_CONTENT_TYPE.SUMMARY_LESSON, multiple: false },
    ],
  },
  {
    id: MATERIAL_TYPES.QUIZ,
    title: 'Quiz',
    icon: 'play circle',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [{ type: SUBSECTION_CONTENT_TYPE.QUIZ, multiple: false }],
  },
  {
    id: MATERIAL_TYPES.QUIZ_SIMPLE,
    title: 'Quiz (Simple)',
    icon: 'play circle',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [{ type: SUBSECTION_CONTENT_TYPE.QUIZ_SIMPLE, multiple: false }],
  },
  {
    id: MATERIAL_TYPES.LIVECODING,
    title: 'Live Coding',
    icon: 'code',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [{ type: SUBSECTION_CONTENT_TYPE.LIVECODING, multiple: false }],
  },
  {
    id: MATERIAL_TYPES.LIVE_VIDEO,
    title: 'Live Video',
    icon: 'video',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [{ type: SUBSECTION_CONTENT_TYPE.LIVE_VIDEO, multiple: false }],
  },
  {
    id: MATERIAL_TYPES.NOTEBOARD,
    title: 'Post-its board',
    icon: 'sticky note',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [{ type: SUBSECTION_CONTENT_TYPE.NOTEBOARD, multiple: false }],
  },
  {
    id: MATERIAL_TYPES.PDF,
    title: 'PDF',
    icon: 'file pdf outline',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [{ type: SUBSECTION_CONTENT_TYPE.EBOOK, multiple: false }],
  },
  {
    id: MATERIAL_TYPES.ASSIGNMENT,
    title: 'Assignment',
    icon: 'file text outline',
    canBeMain: true,
    canBeMaterial: false,
    mainSupportTypes: [{ type: SUBSECTION_CONTENT_TYPE.ASSIGNMENT, multiple: false }],
  },
  // {
  //   id: MATERIAL_TYPES.EXTERNAL,
  //   title: 'Link',
  //   icon: 'external',
  //   canBeMain: false,
  //   canBeMaterial: true,
  // },
  {
    id: MATERIAL_TYPES.OTHER,
    title: 'File',
    icon: 'file',
    canBeMain: false,
    canBeMaterial: true,
  },
];

export const typeList = [
  {
    id: SUBSECTION_CONTENT_TYPE.VIDEOCHAT,
    title: SUBSECTION_CONTENT_TYPE_STRING.VIDEOCHAT,
    product: BACKOFFICE_PRODUCT.LEARNX,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.VIDEO,
    title: SUBSECTION_CONTENT_TYPE_STRING.VIDEO,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.VIDEO_SLIDE,
    title: SUBSECTION_CONTENT_TYPE_STRING.VIDEO_SLIDE,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.ARTICLE,
    title: SUBSECTION_CONTENT_TYPE_STRING.ARTICLE,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.QUIZ,
    title: SUBSECTION_CONTENT_TYPE_STRING.QUIZ,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.QUIZ_SIMPLE,
    title: SUBSECTION_CONTENT_TYPE_STRING.QUIZ_SIMPLE,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.LIVECODING,
    title: SUBSECTION_CONTENT_TYPE_STRING.LIVECODING,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.NOTEBOARD,
    title: SUBSECTION_CONTENT_TYPE_STRING.NOTEBOARD,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.LIVE_VIDEO,
    title: SUBSECTION_CONTENT_TYPE_STRING.LIVE_VIDEO,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.FULL_LESSON,
    title: SUBSECTION_CONTENT_TYPE_STRING.FULL_LESSON,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.SUMMARY_LESSON,
    title: SUBSECTION_CONTENT_TYPE_STRING.SUMMARY_LESSON,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.EBOOK,
    title: SUBSECTION_CONTENT_TYPE_STRING.EBOOK,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
  {
    id: SUBSECTION_CONTENT_TYPE.ASSIGNMENT,
    title: SUBSECTION_CONTENT_TYPE_STRING.ASSIGNMENT,
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
];

export const getTypeList = (allowedSubsectionTypes = []) => {
  if (allowedSubsectionTypes && allowedSubsectionTypes.length) {
    return allowedSubsectionTypes.map((subsectionType) =>
      typeList.find(({ id }) => id === subsectionType)
    );
  }
  return typeList;
};

export const sectionTypeList = [
  {
    id: SECTION_CONTENT_TYPE.SECTION_GROUP,
    title: 'Section Group',
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
  },
];

export const filterTypeList = (product) => (type) =>
  type.product ? type.product === product : true;

export const filterAction = (conditions = {}, contentType, contentAssets) => (
  singleAction = {}
) => {
  for (let cond in conditions) {
    if (singleAction[cond] !== conditions[cond]) return false;
  }
  if (contentType && singleAction.mainSupportTypes && singleAction.mainSupportTypes.length > 0) {
    const mainSupportCond = singleAction.mainSupportTypes.find((st) => st.type === contentType);
    if (mainSupportCond) {
      if (mainSupportCond.multiple === true) return true;
      const onlyOneContent = contentAssets.filter((content) => singleAction.id === content.type);

      if (onlyOneContent.length > 0) return false;
      return true;
    } else {
      return false;
    }
  }

  return true;
};

export const mappingFn = (type) => ({
  key: type.id,
  value: type.id,
  text: type.title,
});

export const MATERIAL_FILE_TYPE = {
  PDF: 'PDF',
  OTHER: 'OTHER',
};

export const MATERIAL_BUTTON_MODE = {
  DOWNLOAD: 'DOWNLOAD',
  PREVIEW: 'PREVIEW',
};

export const getAssetSignedUrl = async ({
  mat,
  productVersion,
  getSingleMaterialData,
  generateCourseAssetSignedUrls,
  mode,
}) => {
  let assetRequest = {};
  let filename = '';

  if (!mat) return;

  if (mat.type === MATERIAL_FILE_TYPE.OTHER) {
    const { data } = await getSingleMaterialData({ id: mat.id, version: productVersion });
    assetRequest = {
      key: data?.courseMaterial.rawContent.data.key,
      bucket: data?.courseMaterial.rawContent.data.bucket,
    };
    filename = decodeURIComponent(data?.courseMaterial.rawContent.data.filename);
  } else {
    assetRequest = {
      key: mat.rawContent.data.key,
      bucket: mat.rawContent.data.bucket,
    };
    filename = mat.rawContent.data.filename;
  }

  let variables = { assetList: [assetRequest] };

  if (mode === MATERIAL_BUTTON_MODE.DOWNLOAD) {
    variables.filename = filename;
    variables.downloadable = true;
  }

  const { data } = await generateCourseAssetSignedUrls({ variables });

  const signedUrl = await data?.generateCourseAssetSignedUrls?.urls[0].signedUrl;

  if (signedUrl) {
    const downloadElement = document.createElement('a');
    downloadElement.setAttribute('href', signedUrl);
    downloadElement.setAttribute('target', '_blank');
    downloadElement.click();
    downloadElement.remove();
  }
};
