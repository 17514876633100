import gql from 'graphql-tag';

export const PersonalizedDiscountCodeFragment = gql`
  fragment PersonalizedDiscountCodeFragment on SFBoPersonalizedDiscountCodeTemplate {
    id
    refCode
    SKUCodes
    discount
    discountText
    discountUnit
    currency
    customerType
  }
`;

export const getDiscountCodeTemplates = gql`
  query sfBoSearchPersonalizedDiscountCodeTemplates(
    $search: SFBoSearchPersonalizedDiscountCodeTemplateInput
    $paging: PagingInput
    $order: OrderInput
  ) {
    sfBoSearchPersonalizedDiscountCodeTemplates(search: $search, paging: $paging, order: $order) {
      ...PersonalizedDiscountCodeFragment
    }
  }
  ${PersonalizedDiscountCodeFragment}
`;
