import gql from 'graphql-tag';

import {
  OnlineCourseLargeFragment,
  CollectionOnlineCourseLargeFragment,
  WorkshopLargeFragment,
  BundleSmallFragment,
  BundleLargeFragment,
  SubscriptionLargeFragment,
  CollectionLargeFragment,
  CategoryLargeFragment,
  InstructorSmallFragment,
  BookLargeFragment,
} from '../query/Products.query';

export const createOnlineCourseMutation = gql`
  mutation sfBoProductCreateOnlineCourse($data: SFBoProductCreateOnlineCourseInput!) {
    sfBoProductCreateOnlineCourse(data: $data) {
      ...OnlineCourseLargeFragment
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const updateOnlineCourseMutation = gql`
  mutation sfBoProductUpdateOnlineCourse($id: ID!, $data: SFBoProductUpdateOnlineCourseInput!) {
    sfBoProductUpdateOnlineCourse(id: $id, data: $data) {
      ...OnlineCourseLargeFragment
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const updateOnlineCourseCategoriesMutation = gql`
  mutation sfBoProductUpdateOnlineCourseCategories(
    $id: ID!
    $data: SFBoProductUpdateOnlineCourseCategoriesInput!
  ) {
    sfBoProductUpdateOnlineCourseCategories(id: $id, data: $data) {
      ...OnlineCourseLargeFragment
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const updateOnlineCourseTableOfContentMetaMutation = gql`
  mutation sfBoProductUpdateOnlineCourseTableOfContentMeta(
    $id: ID!
    $data: SFBoProductUpdateOnlineCourseTableOfContentMetaInput!
  ) {
    sfBoProductUpdateOnlineCourseTableOfContentMeta(id: $id, data: $data) {
      ...OnlineCourseLargeFragment
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const updateOnlineCourseInstructorsMutation = gql`
  mutation sfBoProductUpdateOnlineCourseInstructors($id: ID!, $instructorIds: [String!]!) {
    sfBoProductUpdateOnlineCourseInstructors(id: $id, instructorIds: $instructorIds) {
      ...OnlineCourseLargeFragment
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const updateOnlineCourseRatingMutation = gql`
  mutation sfBoProductUpdateOnlineCourseRatings($id: ID!, $data: SFRatingsInput!) {
    sfBoProductUpdateOnlineCourseRatings(id: $id, data: $data) {
      id
    }
  }
`;

export const updateOnlineCourseDetailsMutation = gql`
  mutation sfBoProductUpdateOnlineCourseDetails($id: ID!, $details: SFMarkupTextInput!) {
    sfBoProductUpdateOnlineCourseDetails(id: $id, details: $details) {
      id
    }
  }
`;

export const updateOnlineCourseHighlightsMutation = gql`
  mutation sfBoProductUpdateOnlineCourseHighlights(
    $id: ID!
    $highlights: [SFHighlightObjectInput!]!
  ) {
    sfBoProductUpdateOnlineCourseHighlights(id: $id, highlights: $highlights) {
      id
    }
  }
`;

export const updateOnlineCoursePricingMutation = gql`
  mutation sfBoProductUpdateOnlineCoursePricing(
    $id: ID!
    $data: SFBoProductUpdateOnlineCoursePricingInput!
  ) {
    sfBoProductUpdateOnlineCoursePricing(id: $id, data: $data) {
      id
    }
  }
`;

export const updateOnlineCourseCreditPricingMutation = gql`
  mutation sfBoProductUpdateOnlineCourseCreditPrice(
    $id: ID!
    $data: SFBoProductUpdateOnlineCourseCreditPriceInput!
  ) {
    sfBoProductUpdateOnlineCourseCreditPrice(id: $id, data: $data) {
      id
    }
  }
`;

export const updateOnlineCourseConditionsMutation = gql`
  mutation sfBoProductUpdateOnlineCourseConditions(
    $id: ID!
    $condition: SFBoProductUpdateConditionInput!
  ) {
    sfBoProductUpdateOnlineCourseConditions(id: $id, condition: $condition) {
      ...OnlineCourseLargeFragment
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const updateOnlineCourseFAQMutation = gql`
  mutation sfBoProductUpdateOnlineCourseFAQ($id: ID!, $faq: [SFQuestionAnswerItemInput!]!) {
    sfBoProductUpdateOnlineCourseFAQ(id: $id, faq: $faq) {
      ...OnlineCourseLargeFragment
    }
  }
  ${OnlineCourseLargeFragment}
`;

export const updateCollectionOnlineCourseMutation = gql`
  mutation sfBoProductUpdateCollectionOnlineCourse(
    $id: ID!
    $data: SFBoProductUpdateCollectionOnlineCourseInput!
  ) {
    sfBoProductUpdateCollectionOnlineCourse(id: $id, data: $data) {
      ...CollectionOnlineCourseLargeFragment
    }
  }
  ${CollectionOnlineCourseLargeFragment}
`;

export const updateCollectionOnlineCourseCategoriesMutation = gql`
  mutation sfBoProductUpdateCollectionOnlineCourseCategories(
    $id: ID!
    $data: SFBoProductUpdateCollectionOnlineCourseCategoriesInput!
  ) {
    sfBoProductUpdateCollectionOnlineCourseCategories(id: $id, data: $data) {
      ...CollectionOnlineCourseLargeFragment
    }
  }
  ${CollectionOnlineCourseLargeFragment}
`;

export const updateCollectionOnlineCourseInstructorsMutation = gql`
  mutation sfBoProductUpdateCollectionOnlineCourseInstructors(
    $id: ID!
    $instructorIds: [String!]!
  ) {
    sfBoProductUpdateCollectionOnlineCourseInstructors(id: $id, instructorIds: $instructorIds) {
      ...CollectionOnlineCourseLargeFragment
    }
  }
  ${CollectionOnlineCourseLargeFragment}
`;

export const updateCollectionOnlineCourseRatingMutation = gql`
  mutation sfBoProductUpdateCollectionOnlineCourseRatings($id: ID!, $data: SFRatingsInput!) {
    sfBoProductUpdateCollectionOnlineCourseRatings(id: $id, data: $data) {
      id
    }
  }
`;

export const updateCollectionOnlineCourseDetailsMutation = gql`
  mutation sfBoProductUpdateCollectionOnlineCourseDetails($id: ID!, $details: SFMarkupTextInput!) {
    sfBoProductUpdateCollectionOnlineCourseDetails(id: $id, details: $details) {
      id
    }
  }
`;

export const updateCollectionOnlineCourseHighlightsMutation = gql`
  mutation sfBoProductUpdateCollectionOnlineCourseHighlights(
    $id: ID!
    $highlights: [SFHighlightObjectInput!]!
  ) {
    sfBoProductUpdateCollectionOnlineCourseHighlights(id: $id, highlights: $highlights) {
      id
    }
  }
`;

export const updateCollectionOnlineCourseTableOfContentsMutation = gql`
  mutation sfBoProductUpdateCollectionOnlineCourseTableOfContents(
    $id: ID!
    $data: SFBoProductUpdateCollectionOnlineCourseTOCInput!
  ) {
    sfBoProductUpdateCollectionOnlineCourseTableOfContents(id: $id, data: $data) {
      id
    }
  }
`;

export const updateCollectionOnlineCourseFAQMutation = gql`
  mutation sfBoProductUpdateCollectionOnlineCourseFAQ(
    $id: ID!
    $faq: [SFQuestionAnswerItemInput!]!
  ) {
    sfBoProductUpdateCollectionOnlineCourseFAQ(id: $id, faq: $faq) {
      ...CollectionOnlineCourseLargeFragment
    }
  }
  ${CollectionOnlineCourseLargeFragment}
`;

export const updateWorkshopCategoriesMutation = gql`
  mutation sfBoProductUpdateWorkshopCategories(
    $id: ID!
    $data: SFBoProductUpdateWorkshopCategoriesInput!
  ) {
    sfBoProductUpdateWorkshopCategories(id: $id, data: $data) {
      ...WorkshopLargeFragment
    }
  }
  ${WorkshopLargeFragment}
`;

export const updateWorkshopInstructorsMutation = gql`
  mutation sfBoProductUpdateWorkshopInstructor($id: ID!, $instructorIds: [String!]!) {
    sfBoProductUpdateWorkshopInstructor(id: $id, instructorIds: $instructorIds) {
      ...WorkshopLargeFragment
    }
  }
  ${WorkshopLargeFragment}
`;

export const createBundleMutation = gql`
  mutation sfBoProductCreateBundle($data: SFBoProductCreateBundleInput!) {
    sfBoProductCreateBundle(data: $data) {
      id
    }
  }
`;

export const updateBundleMutation = gql`
  mutation sfBoProductUpdateBundle($id: ID!, $data: SFBoProductUpdateBundleInput!) {
    sfBoProductUpdateBundle(id: $id, data: $data) {
      ...BundleLargeFragment
    }
  }
  ${BundleLargeFragment}
`;

export const updateBundleCategoriesMutation = gql`
  mutation sfBoProductUpdateBundleCategories(
    $id: ID!
    $data: SFBoProductUpdateBundleCategoriesInput!
  ) {
    sfBoProductUpdateBundleCategories(id: $id, data: $data) {
      id
    }
  }
`;

export const updateBundleInstructorsMutation = gql`
  mutation sfBoProductUpdateBundleInstructors($id: ID!, $instructorIds: [String!]!) {
    sfBoProductUpdateBundleInstructors(id: $id, instructorIds: $instructorIds) {
      id
    }
  }
`;

export const updateBundleDetailsMutation = gql`
  mutation sfBoProductUpdateBundleDetails($id: ID!, $details: SFMarkupTextInput!) {
    sfBoProductUpdateBundleDetails(id: $id, details: $details) {
      id
    }
  }
`;

export const updateBundlePricingMutation = gql`
  mutation sfBoProductUpdateBundlePricing($id: ID!, $data: SFBoProductUpdateBundlePricingInput!) {
    sfBoProductUpdateBundlePricing(id: $id, data: $data) {
      id
    }
  }
`;

export const updateBundleHighlightsMutation = gql`
  mutation sfBoProductUpdateBundleHighlights($id: ID!, $highlights: [SFHighlightObjectInput!]!) {
    sfBoProductUpdateBundleHighlights(id: $id, highlights: $highlights) {
      id
    }
  }
`;

export const updateBundleTableOfContentMutation = gql`
  mutation sfBoProductUpdateBundleTableOfContents(
    $id: ID!
    $data: SFBoProductUpdateBundleTableOfContentsInput!
  ) {
    sfBoProductUpdateBundleTableOfContents(id: $id, data: $data) {
      id
    }
  }
`;

export const updateBundleItemsMutation = gql`
  mutation sfBoProductUpdateBundleItems($id: ID!, $data: SFBoProductUpdateBundleItemsInput!) {
    sfBoProductUpdateBundleItems(id: $id, data: $data) {
      id
    }
  }
`;

export const updateBundleImagesMutation = gql`
  mutation sfBoProductUpdateBundleImages($id: ID!, $bundleImages: SFImageListInput!) {
    sfBoProductUpdateBundleImages(id: $id, bundleImages: $bundleImages) {
      ...BundleSmallFragment
      bundleImages {
        images {
          key
          bucket
        }
      }
    }
  }
  ${BundleSmallFragment}
`;

export const updateBundleStockMutation = gql`
  mutation sfBoProductUpdateBundleStock($id: ID!, $data: SFBoProductUpdateBundleStockInput!) {
    sfBoProductUpdateBundleStock(id: $id, data: $data) {
      id
    }
  }
`;

export const createSubscriptionMutation = gql`
  mutation sfBoProductCreateSubscription($data: SFBoProductCreateSubscriptionInput!) {
    sfBoProductCreateSubscription(data: $data) {
      ...SubscriptionLargeFragment
    }
  }
  ${SubscriptionLargeFragment}
`;

export const updateSubscriptionMutation = gql`
  mutation sfBoProductUpdateSubscription($id: ID!, $data: SFBoProductUpdateSubscriptionInput!) {
    sfBoProductUpdateSubscription(id: $id, data: $data) {
      ...SubscriptionLargeFragment
    }
  }
  ${SubscriptionLargeFragment}
`;

export const updateSubscriptionPricingMutation = gql`
  mutation sfBoProductUpdateSubscriptionPricing($id: ID!, $data: SFBoProductUpdatePricingInput!) {
    sfBoProductUpdateSubscriptionPricing(id: $id, data: $data) {
      ...SubscriptionLargeFragment
    }
  }
  ${SubscriptionLargeFragment}
`;

export const updateSubscriptionRedemptionMutation = gql`
  mutation sfBoProductUpdateSubscriptionRedemption(
    $id: ID!
    $data: SFBoProductSubscriptionRedemptionInput!
  ) {
    sfBoProductUpdateSubscriptionRedemption(id: $id, data: $data) {
      ...SubscriptionLargeFragment
    }
  }
  ${SubscriptionLargeFragment}
`;

export const updateSubscriptionCategoriesMutation = gql`
  mutation sfBoProductUpdateSubscriptionCategories(
    $id: ID!
    $data: SFBoProductUpdateSubscriptionCategoriesInput!
  ) {
    sfBoProductUpdateSubscriptionCategories(id: $id, data: $data) {
      ...SubscriptionLargeFragment
    }
  }
  ${SubscriptionLargeFragment}
`;

export const updateSubscriptionConditionsMutation = gql`
  mutation sfBoProductUpdateSubscriptionConditions(
    $id: ID!
    $data: SFBoProductUpdateSubscriptionConditionsInput!
  ) {
    sfBoProductUpdateSubscriptionConditions(id: $id, data: $data) {
      ...SubscriptionLargeFragment
    }
  }
  ${SubscriptionLargeFragment}
`;

export const updateSubscriptionPlatformAndExternalIdMutation = gql`
  mutation sfBoProductUpdateSubscriptionPlatformAndExternalId(
    $id: ID!
    $data: SFBoProductUpdatePlatformAndExternalIdInput!
  ) {
    sfBoProductUpdateSubscriptionPlatformAndExternalId(id: $id, data: $data) {
      ...SubscriptionLargeFragment
    }
  }
  ${SubscriptionLargeFragment}
`;

export const updateSubscriptionItemsMutation = gql`
  mutation sfBoProductUpdateSubscriptionItems(
    $id: ID!
    $data: SFBoProductUpdateSubscriptionItemsInput!
  ) {
    sfBoProductUpdateSubscriptionItems(id: $id, data: $data) {
      ...SubscriptionLargeFragment # TODO: Check this
    }
  }
  ${SubscriptionLargeFragment}
`;

export const createCollectionMutation = gql`
  mutation sfBoProductCreateCollection($data: SFBoProductCreateCollectionInput!) {
    sfBoProductCreateCollection(data: $data) {
      ...CollectionLargeFragment
    }
  }
  ${CollectionLargeFragment}
`;

export const updateCollectionMutation = gql`
  mutation sfBoProductUpdateCollection($id: ID!, $data: SFBoProductUpdateCollectionInput!) {
    sfBoProductUpdateCollection(id: $id, data: $data) {
      ...CollectionLargeFragment
    }
  }
  ${CollectionLargeFragment}
`;

export const updateCollectionCategoriesMutation = gql`
  mutation sfBoProductUpdateCollectionCategories(
    $id: ID!
    $data: SFBoProductUpdateCollectionCategoriesInput!
  ) {
    sfBoProductUpdateCollectionCategories(id: $id, data: $data) {
      ...CollectionLargeFragment
    }
  }
  ${CollectionLargeFragment}
`;

export const createInstructorMutation = gql`
  mutation sfBoProductCreateInstructor($data: SFBoProductCreateInstructorInput!) {
    sfBoProductCreateInstructor(data: $data) {
      ...InstructorSmallFragment
    }
  }
  ${InstructorSmallFragment}
`;

export const createBookMutation = gql`
  mutation sfBoProductCreateBook($data: SFBoProductCreateBookInput!) {
    sfBoProductCreateBook(data: $data) {
      ...BookLargeFragment
    }
  }
  ${BookLargeFragment}
`;

export const updateBookMutation = gql`
  mutation sfBoProductUpdateBook($id: ID!, $data: SFBoProductUpdateBookInput!) {
    sfBoProductUpdateBook(id: $id, data: $data) {
      ...BookLargeFragment
    }
  }
  ${BookLargeFragment}
`;

export const updateBookCategoriesMutation = gql`
  mutation sfBoProductUpdateBookCategories($id: ID!, $data: SFBoProductUpdateBookCategoriesInput!) {
    sfBoProductUpdateBookCategories(id: $id, data: $data) {
      id
    }
  }
`;

export const updateBookDetailsMutation = gql`
  mutation sfBoProductUpdateBookDetails($id: ID!, $data: SFBoProductUpdateBookDetailsInput!) {
    sfBoProductUpdateBookDetails(id: $id, data: $data) {
      id
    }
  }
`;

export const updateBookPricingMutation = gql`
  mutation sfBoProductUpdateBookPricing($id: ID!, $data: SFBoProductUpdateBookPricingInput!) {
    sfBoProductUpdateBookPricing(id: $id, data: $data) {
      id
    }
  }
`;

export const updateBookStockMutation = gql`
  mutation sfBoProductUpdateBookStock($id: ID!, $data: SFBoProductUpdateBookStockInput!) {
    sfBoProductUpdateBookStock(id: $id, data: $data) {
      id
    }
  }
`;

export const updateInstructorMutation = gql`
  mutation sfBoProductUpdateInstructor($id: ID!, $data: SFBoProductUpdateInstructorInput!) {
    sfBoProductUpdateInstructor(id: $id, data: $data) {
      ...InstructorSmallFragment
    }
  }
  ${InstructorSmallFragment}
`;

export const updateCollectionItemsMutation = gql`
  mutation sfBoProductUpdateCollectionItems(
    $id: ID!
    $data: SFBoProductUpdateCollectionItemsInput!
  ) {
    sfBoProductUpdateCollectionItems(id: $id, data: $data) {
      ...CollectionLargeFragment # TODO: Check this
    }
  }
  ${CollectionLargeFragment}
`;

//base
export const createCategoryMutation = (mutationName) => gql`
  mutation ${mutationName}($data: SFBoProductCreateCategoryInput!) {
    ${mutationName}(data: $data) {
      ...CategoryLargeFragment
    }
  }
  ${CategoryLargeFragment}
`;

export const updateCategoryMutation = (mutationName) => gql`
  mutation ${mutationName}($id: ID!, $data: SFBoProductUpdateCategoryInput!) {
    ${mutationName}(id: $id, data: $data) {
      ...CategoryLargeFragment
    }
  }
  ${CategoryLargeFragment}
`;

// OnlineCourse
export const createOnlineCourseCategoryMutation = createCategoryMutation(
  'sfBoProductOnlineCourseCreateCategory'
);

export const updateOnlineCourseCategoryMutation = updateCategoryMutation(
  'sfBoProductOnlineCourseUpdateCategory'
);

// Workshop
export const createWorkshopCategoryMutation = createCategoryMutation(
  'sfBoProductWorkshopCreateCategory'
);

export const updateWorkshopCategoryMutation = updateCategoryMutation(
  'sfBoProductWorkshopUpdateCategory'
);

// Subscription
export const createSubscriptionCategoryMutation = createCategoryMutation(
  'sfBoProductSubscriptionCreateCategory'
);

export const updateSubscriptionCategoryMutation = updateCategoryMutation(
  'sfBoProductSubscriptionUpdateCategory'
);

// Collection
export const createCollectionCategoryMutation = createCategoryMutation(
  'sfBoProductCollectionCreateCategory'
);

export const updateCollectionCategoryMutation = updateCategoryMutation(
  'sfBoProductCollectionUpdateCategory'
);

// Book
export const createBookCategoryMutation = createCategoryMutation('sfBoProductBookCreateCategory');

export const updateBookCategoryMutation = updateCategoryMutation('sfBoProductBookUpdateCategory');
