import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { IconButton } from '@material-ui/core';

import { Flex, Text } from 'Components/Base';
import ConfirmationModal from 'Components/ConfirmationModal';

const DropzoneButton = styled(Dropzone)`
  height: 0%;
  cursor: pointer;
`;

const PopupAudienceFileUpload = ({
  acceptFileTypes,
  uploadDescription,
  uploadList,
  setUploadList,
  isUploading,
}) => {
  const [isOpenConfirmationDeleteModal, setIsOpenConfirmationDeleteModal] = useState(false);
  const selectedIndex = useRef();

  const handleDrop = (accepted) => {
    setUploadList([...uploadList, ...accepted]);
  };

  const handleRemoveFileFromList = (index) => {
    setUploadList([...uploadList.slice(0, index), ...uploadList.slice(index + 1)]);
  };

  return (
    <>
      <Flex>
        {uploadList?.length ? (
          <>
            {uploadList.map((file, index) => (
              <Flex key={`${index}_${file.name}`} alignItems="baseline">
                <Text>{file.name}</Text>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    selectedIndex.current = index;
                    setIsOpenConfirmationDeleteModal(true);
                  }}
                >
                  <Icon name="trash alternate" color="red" />
                </IconButton>
              </Flex>
            ))}
          </>
        ) : (
          <Flex alignItems="baseline">
            <DropzoneButton
              onDrop={handleDrop}
              loading={!!isUploading}
              disabled={!!isUploading}
              accept={acceptFileTypes}
              multiple={false}
            >
              <Text color="azure" mr="5px" style={{ textDecoration: 'underline' }}>
                {'อัพโหลด'}
              </Text>
            </DropzoneButton>
            <Text color="grey" mr="5px">{`ไฟล์ ${acceptFileTypes.join(
              ', '
            )} ${uploadDescription}`}</Text>
            {/* <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/'}>
              <Text color="azure">{`(ตัวอย่างไฟล์)`}</Text>
            </a> */}
          </Flex>
        )}
      </Flex>
      <ConfirmationModal
        variant="danger"
        isOpen={isOpenConfirmationDeleteModal}
        headerText={`Delete File ?`}
        contentText={`Are you sure to delete file ${uploadList[selectedIndex.current]?.name}?`}
        confirmText="Yes, delete"
        onConfirm={() => {
          handleRemoveFileFromList(selectedIndex.current);
          setIsOpenConfirmationDeleteModal(false);
        }}
        onDismiss={() => setIsOpenConfirmationDeleteModal(false)}
      />
    </>
  );
};

PopupAudienceFileUpload.propTypes = {
  acceptFileTypes: PropTypes.array,
  onUpload: PropTypes.func,
  uploadList: PropTypes.array,
  setUploadList: PropTypes.func,
  isUploading: PropTypes.bool,
  uploadDescription: PropTypes.string,
};

export default PopupAudienceFileUpload;
