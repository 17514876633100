import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Dimmer as BaseDimmer } from 'semantic-ui-react';
import styled from 'styled-components';

import { Box, Flex, AspectRatio, BackgroundImage } from 'Components/Base';
import FileUploadManagerModal, { UPLOAD_MODE } from 'Components/Lib/UploadComponent';

const Dimmer = styled(BaseDimmer)`
  && {
    z-index: ${({ theme }) => theme.zIndices.imageUploadOverlay};
  }
`;

const DeleteBox = styled(Flex)`
  color: ${({ theme }) => theme?.colors?.danger};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

class CourseImageUpload extends Component {
  static propTypes = {
    fileImageUrl: PropTypes.string,
    onFileListRequest: PropTypes.func,
    onFileUploadUrlRequest: PropTypes.func,
    onImageUpdate: PropTypes.func,
    uploadMode: PropTypes.oneOf(Object.values(UPLOAD_MODE)).isRequired,
  };

  static defaultProps = {
    fileImageUrl: '/square-image.png',
    onFileListRequest: () => {},
    onFileUploadUrlRequest: () => {},
    onImageUpdate: () => {},
  };

  state = {
    active: false,
  };

  handleHover = (active) => {
    this.setState({ active: active });
  };

  handleRemoveImage = () => {
    if (window.confirm('Are you sure you want to remove this image?')) {
      this.props.onImageUpdate({});
    }
  };

  handleSelectImage = (files = []) => {
    if (files && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      // Now Support only one file upload
      const { key, bucket, url } = files[0];
      this.props.onImageUpdate({ key, bucket, url });
      this.handleHover(false);
    }
  };

  render() {
    const {
      fileImageUrl = '/square-image.png',
      onFileListRequest,
      onFileUploadUrlRequest,
      uploadMode,
    } = this.props;
    const { active } = this.state;
    return (
      <Box position={'relative'}>
        <Box
          onMouseOver={() => {
            this.handleHover(true);
          }}
          onMouseLeave={() => {
            this.handleHover(false);
          }}
          style={{
            cursor: 'pointer',
          }}
        >
          <FileUploadManagerModal
            keyGroup="courseImage"
            dimmer="inverted"
            uploadUrl={'http://localhost:5000'}
            listUrl={'http://localhost:5000'}
            allowedContentTypes={['image/png', 'image/jpeg', 'image/gif']}
            defaultText={'Support JPG, PNG or GIF file with less than 5MB'}
            maxFileSize={5}
            maxSelection={1}
            customFileListUrlRequest={onFileListRequest}
            customUploadUrlRequest={onFileUploadUrlRequest}
            mode={uploadMode}
            onFileManagerDialogClose={this.handleSelectImage}
            trigger={
              //Put it here to allow user to click any where in picture to change image
              <Dimmer.Dimmable dimmed={active}>
                <AspectRatio ratio={16 / 9}>
                  <BackgroundImage imageUrl={fileImageUrl} bgPosition={'center'} />
                  <Dimmer active={active}>
                    <Button primary>
                      <Icon name="image outline" />
                      Change course image
                    </Button>
                  </Dimmer>
                </AspectRatio>
              </Dimmer.Dimmable>
            }
          />
        </Box>
        <DeleteBox onClick={this.handleRemoveImage} mt={2}>
          <Icon name="trash alternate" />
          Remove Image
        </DeleteBox>
      </Box>
    );
  }
}

export default CourseImageUpload;
