import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

import { CATEGORY_TYPE, SKU_CATEGORY, SKU_TYPE } from '../../Domains/constants';
import { FormsyCategorySelectorInput } from '../../Components/CategorySelectorInput';
import resolveCategoryName from '../../Domains/resolveCategoryName';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, skuCategory, skuType) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1),
      inputName: 'category1Ids',
      mode: MODE.BOTH,
      customInput: (props) => (
        <FormsyCategorySelectorInput
          {...props}
          skuCategory={skuCategory}
          skuType={skuType}
          categoryType={CATEGORY_TYPE.CATEGORY_1}
        />
      ),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2),
      inputName: 'category2Ids',
      mode: MODE.BOTH,
      customInput: (props) => (
        <FormsyCategorySelectorInput
          {...props}
          skuCategory={skuCategory}
          skuType={skuType}
          categoryType={CATEGORY_TYPE.CATEGORY_2}
        />
      ),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3),
      inputName: 'category3Ids',
      mode: MODE.BOTH,
      customInput: (props) => (
        <FormsyCategorySelectorInput
          {...props}
          skuCategory={skuCategory}
          skuType={skuType}
          categoryType={CATEGORY_TYPE.CATEGORY_3}
        />
      ),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4),
      inputName: 'category4Ids',
      mode: MODE.BOTH,
      customInput: (props) => (
        <FormsyCategorySelectorInput
          {...props}
          skuCategory={skuCategory}
          skuType={skuType}
          categoryType={CATEGORY_TYPE.CATEGORY_4}
        />
      ),
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class SKUCategoriesForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
    skuCategory: PropTypes.oneOf(Object.values(SKU_CATEGORY)).isRequired,
    skuType: PropTypes.oneOf(Object.values(SKU_TYPE)),
    onSubmit: PropTypes.func,
  };

  handleSubmit = () => {};

  render() {
    const { isEdit, skuCategory, skuType, onSubmit } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit, skuCategory, skuType)}
        onSubmit={onSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}

export default SKUCategoriesForm;
