import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from 'recompose';
import { Icon } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';
import { withUserStatus } from 'Redux/user-profile/hoc';

import { SemanticButton as Button } from '../../../../Components/Base/Button';
import UserHeaderContainer from '../../Containers/UserHeader.gql';
import NewUserModal from '../../Containers/NewUserModal';

import UserTabs from './UserTabs';

const Toolbar = ({ userId }) => (
  <Flex justifyContent="flex-end" alignItems="center" mb={3}>
    <Box />
    <NewUserModal
      userId={userId}
      isEdit={true}
      trigger={
        <Button primary circular>
          <Icon name="edit" size="small" />
          Edit User
        </Button>
      }
    />
  </Flex>
);

const SingleUserRoute = ({ userId }) => (
  <Fragment>
    <Box mb={4}>
      <Toolbar userId={userId} />
      <UserHeaderContainer userId={userId} />
    </Box>

    <UserTabs userId={userId} />
  </Fragment>
);

SingleUserRoute.propTypes = {
  userId: PropTypes.string.isRequired,
  myRoles: PropTypes.array,
};

const getUserId = mapProps((props) => {
  const { match } = props;
  const { params } = match || {};
  const { userId } = params || {};
  return { userId };
});

export default compose(getUserId, withUserStatus)(SingleUserRoute);
