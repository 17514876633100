import React from 'react';
import PropTypes from 'prop-types';

import UserDeviceListGQL from '../../Containers/UserDeviceList.gql';

const UserDevicePanel = ({ userId }) => <UserDeviceListGQL userId={userId} />;

UserDevicePanel.propTypes = {
  userId: PropTypes.string,
};

export default UserDevicePanel;
