import React, { Fragment, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Box, Flex, Text } from 'Components/Base';
import APP_PERMISSION from 'Util/permissions/permissionKeys';
import BaseMenu from 'Containers/Menu';
import { GenSwitchRoute } from 'Util/Route';

import { getOrganization } from '../../../../GraphQL/query/Users.query';
import OrganizationDetails from '../../Containers/Organization/OrganizationDetails';
import OrganizationEmailPanel from '../../Containers/Organization/OrganizationEmailPanel';

const Divider = styled(Box)`
  border-bottom: 1px solid #f2f2f2;
`;

const Menu = styled(BaseMenu)`
  &&&& {
    margin: 36px 0;
    border-bottom: 1px solid #d9d9d9;
    .active.item {
      border-color: ${({ theme: { colors } }) => colors?.primary};
      border-width: 4px;
    }
    & > :first-child {
      overflow: inherit;
    }
  }
`;

const genMenu = (matchUrl) => [
  {
    path: `${matchUrl}`,
    title: 'Details',
    private: true,
    exact: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.ORGANIZATION.VIEW],
    route: OrganizationDetails,
  },
  {
    path: `${matchUrl}/emails`,
    title: 'Emails',
    private: true,
    exact: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.USER.ORGANIZATION.VIEW],
    route: OrganizationEmailPanel,
  },
];

const HeaderBlock = () => {
  const match = useRouteMatch();
  const { organizationId } = match?.params ?? {};
  const { data } = useQuery(getOrganization, {
    variables: { id: organizationId },
    skip: !organizationId,
    fetchPolicy: 'network-only',
  });
  const { organizationName } = data?.organization ?? {};
  return (
    <Flex mt={2}>
      <Text.Header>Organization: {organizationName}</Text.Header>
    </Flex>
  );
};

const SingleOrganizationRoute = ({ match }) => {
  const { organizationId } = match?.params ?? {};
  const menus = useMemo(() => genMenu(match.url), [match.url]);

  const NavigationBlock = useMemo(
    () => (
      <Link to={'/users/organizations'}>
        <Text>
          <Flex alignItems="center">
            <ArrowBackIosIcon style={{ width: '14px' }} />
            <span>Organizations</span>
          </Flex>
        </Text>
      </Link>
    ),
    []
  );
  return (
    <Fragment>
      {NavigationBlock}
      <HeaderBlock />
      <Divider />
      <Menu menus={menus} menuProps={{ secondary: true, pointing: true }} />
      <GenSwitchRoute routes={menus} organizationId={organizationId} />
    </Fragment>
  );
};

export default SingleOrganizationRoute;
