import React, { Fragment, useState } from 'react';
import { Modal, Icon, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Box } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import StatefulModal from 'Components/Modal/StatefulModal';
import ConfirmationButton from 'Components/ConfirmationButton';

import ModalAction from './ModalAction';
import SubsectionCustomRow from './SubsectionCustomRow';

const Text = styled.span`
  ${'' /* color: ${COLOR.primary}; */};
`;
const ModalHeader = styled.h4`
  padding-top: 20px;
  font-size: 20px;
  font-weight: 500;
`;
const SectionName = styled.h5`
  font-size: 16px;
  font-weight: 500;
`;
const DragableWrapper = styled.div`
  max-height: 384px;
  overflow: auto;
  padding-right: 10px;
`;

const ModalTrigger = ({ onClick }) => {
  return (
    <Box m={1}>
      <Button circular onClick={onClick} size="small" data-testid={`btn-reorder-subsection`}>
        <Icon name={'bars'} />
        <Text color="primary">Reorder Subsection</Text>
      </Button>
    </Box>
  );
};

const SubsectionList = React.memo(function SubsectionList({ subSections }) {
  return subSections.map((subsection, index) => (
    <Draggable draggableId={subsection.id} index={index} key={subsection.id}>
      {(provided, snapshot) => (
        <SubsectionCustomRow provided={provided} subsection={subsection} snapshot={snapshot} />
      )}
    </Draggable>
  ));
});

const ModalContent = ({ onClose, sectionName, subSections, onConfirm }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [data, setData] = useState([...subSections]);

  const handleCancelAction = (closeFn) => {
    closeFn();
  };

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmReorder = () => {
    onConfirm(data);
    onClose();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedData = reorder([...data], result.source.index, result.destination.index);

    setData(reorderedData);
  };

  const getPortal = () => {
    return document.querySelector('.content');
  };

  return (
    <Fragment>
      <Modal.Content>
        <ModalHeader>Reorder Subsection</ModalHeader>
        <Divider />
        <SectionName>{sectionName}</SectionName>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="list"
            renderClone={(provided, snapshot, rubric) => (
              <SubsectionCustomRow
                provided={provided}
                subsection={data[rubric.source.index]}
                snapshot={snapshot}
              />
            )}
          >
            {(provided) => (
              <DragableWrapper ref={provided.innerRef} {...provided.droppableProps}>
                <SubsectionList subSections={data} />
                {provided.placeholder}
              </DragableWrapper>
            )}
          </Droppable>
        </DragDropContext>
        <Divider />
        <Box>
          <ModalAction
            onCancel={handleOpenConfirmModal}
            onConfirm={handleConfirmReorder}
            loading={false}
          />
        </Box>
      </Modal.Content>
      {openConfirmModal && (
        <ConfirmationButton
          defaultOpen={true}
          onConfirm={() => {
            handleCancelAction(onClose);
          }}
          onCancel={() => {
            setOpenConfirmModal(false);
          }}
          confirmText="Confirm"
          contentText="This order will be cancel from Reorder Subsection"
        />
      )}
    </Fragment>
  );
};

const ReorderSubsectionModalButton = ({ sectionTitle = '', subSections, onConfirm }) => {
  return (
    <Fragment>
      <StatefulModal trigger={<ModalTrigger />}>
        {(closeModal, modalOpen) =>
          modalOpen && (
            <ModalContent
              onClose={closeModal}
              sectionName={sectionTitle}
              subSections={subSections}
              onConfirm={onConfirm}
            />
          )
        }
      </StatefulModal>
    </Fragment>
  );
};

export default ReorderSubsectionModalButton;
