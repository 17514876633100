import React from 'react';
import PropTypes from 'prop-types';

import omitDeep from 'Util/omitDeep';
import CreateEditForm from 'Containers/CreateEditForm';
import { sfCreateWorkshop, sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation';
import {
  getWorkshopBySKUCode,
  searchWorkshops,
  getStatusValue,
  resolveStatus,
} from 'GraphQL/query/Workshop.query';

import delve from '../../../Util/Delve';
import WorkshopForm from '../Components/WorkshopForm';
import getConfig from '../../../Util/Config';

const CreateEditWorkshopForm = ({ onComplete, onCancelled, skuCode, isEdit, searchVariables }) => {
  return (
    <CreateEditForm
      dataQuery={getWorkshopBySKUCode}
      createMutation={sfCreateWorkshop}
      createRefetchQueries={[{ query: searchWorkshops, variables: searchVariables }]}
      editRefetchQueries={[
        {
          query: getWorkshopBySKUCode,
          variables: {
            SKUCode: skuCode,
          },
        },
      ]}
      editMutation={sfUpdateWorkshop}
      isEdit={isEdit}
      id={skuCode}
      getQueryVariables={({ id }) => ({
        SKUCode: id,
      })}
      getEditVariables={(form, { id }) => ({
        SKUCode: id,
        data: form,
      })}
      getCreateVariables={(form) => ({
        data: form,
      })}
      getEditMutationResponse={(mutateData) => delve(mutateData, 'sfUpdateWorkshop')}
      getCreateMutationResponse={(mutateData) => delve(mutateData, 'sfCreateWorkshop')}
      onCompleted={onComplete}
      onCancelled={onCancelled}
      checkMutationResponse
    >
      {({ data, onSubmit }) => {
        const mapDataIntoForm = ({ SKUStatus, isVisible, ...restData }) => {
          return { status: resolveStatus(SKUStatus, isVisible), ...restData };
        };
        const handleOnSubmit = ({ status, ...formValue }) => {
          let workshopInfo = { ...getStatusValue(status), ...formValue };
          if (!isEdit) {
            const { basefaq } = getConfig();
            const enabledBaseFaq = delve(basefaq, 'enabled');
            if (enabledBaseFaq) {
              const faq = delve(basefaq, 'faq', []);
              workshopInfo = { ...workshopInfo, FAQ: faq };
            }
          }

          const formatData = omitDeep(workshopInfo, ['__typename', 'assetUrl']);

          const updatedContactInfo = delve(formatData, 'contactInfo');
          const updatedPromotionText = delve(formatData, 'promotionText');

          const contactInfo = delve(updatedContactInfo, 'content') ? updatedContactInfo : null;
          const promotionText = delve(updatedPromotionText, 'content')
            ? updatedPromotionText
            : null;

          onSubmit({ ...formatData, contactInfo, promotionText });
        };
        return (
          <WorkshopForm
            onCancelled={onCancelled}
            initialData={data && mapDataIntoForm(data.sfWorkshopBySKUCode)}
            onSubmit={handleOnSubmit}
          />
        );
      }}
    </CreateEditForm>
  );
};

CreateEditWorkshopForm.propTypes = {
  skuCode: PropTypes.string,
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  searchVariables: PropTypes.shape({}),
};

export default CreateEditWorkshopForm;
