import gql from 'graphql-tag';

import { singleExam, singleQuestion } from '../common/fragment';

export const createExam = gql`
  mutation createExam(
    $courseId: ID!
    $createExamInput: EEV1ExamCreateInput!
    $version: EEV1_EXAM_COURSE_VERSION
  ) {
    createExam: createEeV1Exam(courseId: $courseId, exam: $createExamInput, version: $version) {
      ...singleExam
    }
  }
  ${singleExam}
`;

export const copyExam = gql`
  mutation copyExam($courseId: ID!, $examId: ID!, $examType: String!) {
    copyExam: copyEeV1Exam(courseId: $courseId, examId: $examId, examType: $examType)
  }
`;

export const updateExam = gql`
  mutation updateExam(
    $examInput: EEV1ExamEditInput!
    $examId: ID!
    $courseId: ID
    $version: EEV1_EXAM_COURSE_VERSION
  ) {
    updateExamDesc: updateEeV1ExamDesc(
      exam: $examInput
      examId: $examId
      courseId: $courseId
      version: $version
    ) {
      ...singleExam
    }
  }
  ${singleExam}
`;

export const removeExam = gql`
  mutation removeExam($courseId: ID!, $examId: ID!, $version: EEV1_EXAM_COURSE_VERSION) {
    removeExam: removeEeV1Exam(courseId: $courseId, examId: $examId, version: $version)
  }
`;

export const publishExam = gql`
  mutation publishExam($courseId: ID!, $examId: ID!, $version: EEV1_EXAM_COURSE_VERSION) {
    publishExam: publishEeV1Exam(courseId: $courseId, examId: $examId, version: $version) {
      id
      status
    }
  }
`;

export const unpublishExam = gql`
  mutation unpublishExam($courseId: ID!, $examId: ID!, $version: EEV1_EXAM_COURSE_VERSION) {
    unpublishExam: unpublishEeV1Exam(courseId: $courseId, examId: $examId, version: $version) {
      id
      status
    }
  }
`;

export const createQuestion = gql`
  mutation createQuestion(
    $examId: ID!
    $questionReqInput: EEV1QuestionCreateInput!
    $version: EEV1_EXAM_COURSE_VERSION
  ) {
    createExamQuestion: createEeV1ExamQuestion(
      examId: $examId
      questionDesc: $questionReqInput
      version: $version
    ) {
      ...singleQuestion
    }
  }
  ${singleQuestion}
`;

export const updateQuestion = gql`
  mutation updateQuestion(
    $examId: ID!
    $questionId: ID!
    $questionInput: EEV1QuestionEditInput!
    $version: EEV1_EXAM_COURSE_VERSION
  ) {
    updateExamQuestion: updateEeV1ExamQuestion(
      examId: $examId
      questionId: $questionId
      question: $questionInput
      version: $version
    ) {
      ...singleQuestion
    }
  }
  ${singleQuestion}
`;

export const removeQuestion = gql`
  mutation removeQuestion($examId: ID!, $questionId: ID!, $version: EEV1_EXAM_COURSE_VERSION) {
    removeExamQuestion: removeEeV1ExamQuestion(
      examId: $examId
      questionId: $questionId
      version: $version
    )
  }
`;
