export const ExamPermissionKey = Object.freeze({
  LIST: {
    VIEW: 'exam.list-view',
    UPDATE: 'exam.list-update',
    DELETE: ' exam.list-delete',
  },
  DETAIL: {
    VIEW: 'exam.detail-view',
    UPDATE: 'exam.detail-update',
    DELETE: ' exam.detail-delete',
  },
  EXAM_TAG: {
    VIEW: 'exam.examTag-view',
    UPDATE: 'exam.examTag-update',
    DELETE: ' exam.examTag-delete',
  },
});
