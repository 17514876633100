import React from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

export const ORGANIZATION_EMAIL_STATUS = {
  WHITELIST: 'whitelist',
  BLACKLIST: 'blacklist',
};

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Email Address',
    inputName: 'email',
    inputDescription: 'Email จะต้องเป็น Domain เดียวกับ Organization',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Status',
    inputName: 'isBlacklist',
    inputProps: {
      required: true,
      options: [
        { key: 'whitelist', value: ORGANIZATION_EMAIL_STATUS.WHITELIST, text: 'Whitelist' },
        { key: 'blacklist', value: ORGANIZATION_EMAIL_STATUS.BLACKLIST, text: 'Blacklist' },
      ],
    },
  },
];

const OrganizationEmailForm = ({ isEdit, initialData, onSubmit, onCancelled }) => {
  return (
    <FormGenerator
      fields={fields}
      onSubmit={onSubmit}
      onCancelled={onCancelled}
      submitText={isEdit ? 'Update Email' : 'Add Email'}
      cancelText="Cancel"
      showCancel={true}
      initialData={initialData}
    />
  );
};

OrganizationEmailForm.propTypes = {
  isEdit: PropTypes.bool,
  initialData: PropTypes.shape({
    email: PropTypes.string,
    isBlacklist: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancelled: PropTypes.func,
};

OrganizationEmailForm.defaultProps = {
  isEdit: true,
  initialData: {
    email: '',
    isBlacklist: 'whitelist',
  },
  onSubmit: () => {},
  onCancelled: () => {},
};

export default OrganizationEmailForm;
