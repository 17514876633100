import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';

import CreateEditExamForm from './CreateEditExamForm';

const ExamModal = (props) => {
  const { variables, trigger } = props;
  const history = useMemo(() => props.history, [props.history]);

  const handleCreateExamComplete = useCallback(
    (callback) => (response) => {
      callback();
      const examId = response?.exam?.id;
      if (examId) history.push(`/exams/${examId}`);
    },
    [history]
  );

  return (
    <StatefulModal trigger={trigger}>
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{'Create New Exam'}</Modal.Header>
          <Modal.Content>
            <CreateEditExamForm
              onComplete={handleCreateExamComplete(closeModal)}
              onCancelled={closeModal}
              searchVariables={variables}
            />
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
};

ExamModal.propTypes = {
  isOpen: PropTypes.bool,
  trigger: PropTypes.element,
  variables: PropTypes.object,
};

export default withRouter(ExamModal);
