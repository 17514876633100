import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import QueryComponent from '../../../GraphQL/util/QueryComponent';
import { userRolesQuery, allRolesQuery } from '../../../GraphQL/query/Users.query';
import { assignRolesMutation } from '../../../GraphQL/mutation/Users.mutation';
import RolesPanel from '../Components/RolesPanel';

class RolesPanelGQL extends Component {
  constructor(props) {
    super(props);
    this.handleAssignRoles = this.handleAssignRoles.bind(this);
  }

  handleAssignRoles(roleIds = [], mutateFn) {
    const rolesInput = roleIds.map((r) => ({ roleId: parseInt(r, 10) }));
    const { userId } = this.props;
    mutateFn({ variables: { userId, roles: rolesInput } });
  }

  render() {
    const { userId } = this.props;
    return (
      <QueryComponent query={allRolesQuery}>
        {({ roles }) => (
          <Mutation mutation={assignRolesMutation}>
            {(assignRoles, { loading, error, data }) => {
              return (
                <Fragment>
                  {error && <Message negative>{JSON.stringify(error.message)}</Message>}
                  <QueryComponent query={userRolesQuery} variables={{ userId: userId }}>
                    {({ user }) => (
                      <RolesPanel
                        userId={userId}
                        roles={user.rolesData}
                        allRoles={roles}
                        onAssignRoles={(roles) => this.handleAssignRoles(roles, assignRoles)}
                      />
                    )}
                  </QueryComponent>
                </Fragment>
              );
            }}
          </Mutation>
        )}
      </QueryComponent>
    );
  }
}

RolesPanelGQL.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default RolesPanelGQL;
