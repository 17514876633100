import { createSelector } from 'reselect';

const userProfileSelector = (state) => state.userProfile;

export const isLogin = createSelector(
  userProfileSelector,
  (userProfile) => !userProfile.loading && userProfile.data !== null
);

export const isLoading = createSelector(userProfileSelector, (userProfile) => userProfile.loading);

export const myProfile = createSelector(userProfileSelector, (userProfile) => userProfile.data);

export const myRoles = createSelector(
  userProfileSelector,
  (userProfile) => userProfile?.data?.roles ?? []
);

export const myPermission = createSelector(
  userProfileSelector,
  (userProfile) => userProfile?.data?.appPermissions ?? []
);
