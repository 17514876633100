import { Flex } from '@skooldio/skooldio-ui-components-core';
import React from 'react';
import PropTypes from 'prop-types';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import LinkIcon from '@material-ui/icons/Link';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';

import { Text as BaseText } from 'Components/Base';
import { FONT_SIZE } from 'Components/Theme';

import Breadcrumb from '../../../../Components/Breadcrumb';
import Card from '../Card/Card';
import SubmittedAssignmentDetail from '../SubmittedAssignmentDetail/SubmittedAssignmentDetail';
import TwoColumnGrid from '../TwoColumnGrid/TwoColumnGrid';

const Text = (props) => <BaseText lineHeight="1.5" {...props} />;

const Attachment = ({
  attachmentName,
  sectionTitle,
  assignmentTitle,
  downloadFile,
  url,
  note,
  ...restProps
}) => {
  return (
    <Card {...restProps}>
      <TwoColumnGrid>
        <Flex pt={3}>
          <AssignmentOutlinedIcon />
          <Text pl={3}>บทเรียน</Text>
        </Flex>
        <Breadcrumb
          crumbs={[{ title: sectionTitle }, { title: assignmentTitle }]}
          pt={3}
          fontSize={FONT_SIZE.normal}
          fontWeight={'800'}
        />
        <Flex pt={3}>
          {attachmentName ? (
            <>
              <AttachFileIcon />
              <Text pl={3}>ไฟล์แนบ</Text>
            </>
          ) : (
            <>
              <LinkIcon />
              <Text pl={3}>URL</Text>
            </>
          )}
        </Flex>
        {attachmentName ? (
          <SubmittedAssignmentDetail fileName={attachmentName} downloadFile={downloadFile} />
        ) : (
          <SubmittedAssignmentDetail url={url} />
        )}
        <Flex>
          <CommentOutlinedIcon />
          <Text pl={3}>โน้ต</Text>
        </Flex>
        <Text px={3}>{note}</Text>
      </TwoColumnGrid>
    </Card>
  );
};

Attachment.propTypes = {
  attachmentName: PropTypes.string,
  url: PropTypes.string,
  note: PropTypes.string,
  downloadFile: PropTypes.func,
  sectionTitle: PropTypes.string,
  assignmentTitle: PropTypes.string,
};

Attachment.defaultProps = {
  downloadFile: () => {},
};

export default Attachment;
