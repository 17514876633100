import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Header } from 'semantic-ui-react';

import { Box, Text } from 'Components/Base';
import { withUserStatus } from 'Redux/user-profile/hoc';

import Loading from '../../Components/Loading';

class Landing extends Component {
  render() {
    const { isLoading, isLogin, myRoles } = this.props;
    if (isLoading) {
      return (
        <Box>
          <Loading />
        </Box>
      );
    }

    if (!isLogin) return <Redirect to={'/login'} />;

    if (myRoles.includes('administrator') || myRoles.includes('instructor')) {
      return <Redirect to={'/courses/v2'} />;
    }

    return (
      <Container>
        <Box p={4}>
          <Header textAlign="center" size="large">
            Welcome back!
          </Header>
          <Box mt={2}>
            <Text>Please navigate from menu above</Text>
          </Box>
        </Box>
      </Container>
    );
  }
}

export default withUserStatus(Landing);
