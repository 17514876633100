import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { allUsersQuery } from '../../../GraphQL/query/Users.query';
import QueryComponent from '../../../GraphQL/util/QueryComponent';
import AllUserView from '../Components/AllUsers';

export default class AllUsersQuery extends Component {
  static propTypes = {
    searchText: PropTypes.string,
  };

  render() {
    const { searchText } = this.props;
    return (
      <QueryComponent query={allUsersQuery} variables={{ searchText }}>
        {(data) => <AllUserView data={data.users} />}
      </QueryComponent>
    );
  }
}
