import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import { Form } from 'semantic-ui-react';

import { filterSuirElementProps } from './FormsyUtils';

const formsyWrapper = (FieldComponent) => {
  class Formsy extends Component {
    static propTypes = {
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      width: PropTypes.number,
      className: PropTypes.string,
      inputClassName: PropTypes.string,
      disabled: PropTypes.bool,
      inline: PropTypes.bool,
      passRequiredToField: PropTypes.bool,
      errorLabel: PropTypes.element,
      required: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      instantValidation: PropTypes.bool,
      defaultValue: PropTypes.array,
      onBlur: PropTypes.func,
      isValid: PropTypes.bool.isRequired,
      setValue: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
      onChange: PropTypes.func,
      isPristine: PropTypes.bool.isRequired,
      errorMessage: PropTypes.oneOfType([PropTypes.string, null]).isRequired,
      validationError: PropTypes.string,
      validationErrors: PropTypes.object,
      validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    static defaultProps = {
      passRequiredToField: true,
    };

    state = { allowError: false };

    componentDidMount() {
      const { defaultValue, setValue } = this.props;
      if (defaultValue) setValue(defaultValue);
    }

    componentDidUpdate() {
      if (this.props.isFormSubmitted && !this.state.allowError) {
        this.showError();
      }
    }

    handleChange = (e, data) => {
      const { value } = data;
      this.props.setValue(value);
      if (this.props.onChange) this.props.onChange(e, data);
      if (this.props.instantValidation) this.showError();
    };

    handleBlur = (e, data) => {
      this.showError();
      if (this.props.onBlur) this.props.onBlur(e, data);
    };

    showError = () => this.setState({ allowError: true });

    render() {
      const {
        id,
        inputClassName,
        required,
        label,
        defaultValue,
        value,
        isValid,
        isPristine,
        errorMessage,
        errorLabel,
        // Form.Field props
        as,
        width,
        className,
        disabled,
        inline,
        passRequiredToField,
      } = this.props;

      const { allowError } = this.state;
      const error = !isPristine && !isValid && allowError;

      const inputProps = {
        ...filterSuirElementProps(this.props),
        value: value || (isPristine && defaultValue) || '',
        onChange: this.handleChange,
        onBlur: this.handleBlur,
        className: inputClassName,
        error: !disabled && error,
        label,
        id,
      };

      return (
        <Form.Field
          as={as}
          className={className}
          required={required && passRequiredToField}
          error={!disabled && error}
          width={width}
          inline={inline}
          disabled={disabled}
        >
          {React.createElement(FieldComponent, { ...inputProps })}
          {!disabled && error && errorLabel && React.cloneElement(errorLabel, {}, errorMessage)}
        </Form.Field>
      );
    }
  }

  return withFormsy(Formsy);
};

export default formsyWrapper;
