import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Dropdown } from 'semantic-ui-react';
import dayjs from 'dayjs';
import _ from 'lodash';

import { COLOR } from 'Components/Theme';
import { Flex, Text, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

import { monthList } from './constants';

const StyledCheckbox = withStyles({
  root: {
    width: 16,
    padding: 0,
    marginRight: 12,
    '&$checked': {
      color: COLOR.primary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const getYear = (date) => {
  if (date) {
    const year = dayjs(date).locale('th').year();
    return year + 543;
  }
  return new dayjs().locale('th').year() + 543;
};

const getMonth = (date) => {
  const month = dayjs(date).locale('th').month();
  return date ? month : undefined;
};

const getYearOptions = (optionsAmount) => {
  const currentYear = getYear();
  const options = [];

  for (let i = 0; i < optionsAmount; i++) {
    options.push({
      key: `year${i}`,
      text: `${currentYear + i}`,
      value: currentYear + i,
    });
  }
  return options;
};

const getMonthOptions = (year) => {
  const currentMonth = getMonth(new Date());
  const currentYear = getYear();
  const options = [];

  for (let i = 0; i < monthList.length; i++) {
    options.push({
      key: `m-${i}`,
      text: monthList[i],
      value: `${i}`,
      disabled: i < currentMonth && year === currentYear,
    });
  }
  return options;
};

const yearOptionsAmount = 2;

const ComingSoonTextInput = ({ display = false, launchDate, onChange, prevValue, ...props }) => {
  const [_display, setDisplay] = useState(display);
  const [month, setMonth] = useState(getMonth(launchDate));
  const [year, setYear] = useState(getYear(launchDate));

  const yearOptions = useMemo(() => getYearOptions(yearOptionsAmount));
  const monthOptions = useMemo(() => getMonthOptions(year), [year]);

  const handleDisplay = () => {
    setDisplay(!_display);
  };

  const handleChangeYear = (year) => {
    const currentMonth = getMonth(new Date());
    const currentYear = getYear();
    if (parseInt(month) < currentMonth && currentYear === year) {
      setMonth('');
      setYear(year);
      return;
    }

    setYear(year);
  };

  const handleChange = (type, data) => {
    switch (type) {
      case 'month':
        setMonth(data);
        break;
      case 'year':
        handleChangeYear(data);
        break;
      default:
        return;
    }
  };

  const updateValue = () => {
    // check if doesn't have value before and not update anything. so the form should not update anything to this value
    if (prevValue === null && !_display && !month) {
      onChange({}, { value: null });
      return;
    }

    // check if cheked display but didn't select month. so we return value without launchDate
    // the validator will throw error to this component
    if (_.isNil(month) || month === '') {
      onChange({}, { value: { display: _display } });
      return;
    }
    const monthNumber = parseInt(month);
    const launchDate = new Date(year - 543, monthNumber, 1).toISOString();
    let value = { display: _display, launchDate };

    onChange({}, { value });
  };

  useEffect(() => {
    updateValue();
  }, [_display, month, year]);

  return (
    <>
      <Box mb={1}>
        <Flex alignItems="center">
          <StyledCheckbox checked={_display} onChange={handleDisplay} />
          <Text fontWeight="600" onClick={handleDisplay}>
            แสดงเดือนและปีที่เปิดให้สมัครในรายละเอียดคอร์ส
          </Text>
        </Flex>
      </Box>
      <Flex alignItems="center">
        <Box width="170px" mr="20px">
          <Box mb={1}>
            <Text>เดือน</Text>
          </Box>
          <Dropdown
            placeholder="เลือกเดือน"
            fluid
            selection
            options={monthOptions}
            disabled={!_display}
            value={`${month}`}
            onChange={(e, data) => handleChange('month', data.value)}
          />
        </Box>
        <Box width="120px">
          <Box mb={1}>
            <Text>ปี</Text>
          </Box>
          <Dropdown
            fluid
            selection
            options={yearOptions}
            disabled={!_display}
            value={year}
            onChange={(e, data) => handleChange('year', data.value)}
          />
        </Box>
      </Flex>
    </>
  );
};

export default withFormsy(ComingSoonTextInput);
