export const FILE_TYPE = {
  NEW_DISCOUNT_CODE: 'NEW_DISCOUNT_CODE',
  SHIPMENT_REPORTS: 'SHIPMENT_REPORTS',
};

export const FILE_IMPORT_STATUS = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
};

export const SHIPMENT_PROVIDERS_OPTIONS = [
  {
    key: 'FLASH_EXPRESS',
    text: 'FLASH EXPRESS',
    value: 'FLASH_EXPRESS',
  },
];
