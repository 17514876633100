/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import withAppConfig from 'Util/hoc/withAppConfig';
import { Box } from 'Components/Base';

import FormGenerator from '../../../Components/Form/FormGenerator';
import { courseOwnerEnabled, getCourseOwnerDDL } from '../../../Util/CourseOwner';
import ColorSelectorInput from '../../../Components/ColorSelectorInput';

import InstructorSelectorInput from './InstructorSelectorInput.gql';
import CourseTeamSelectorInput from './CourseTeamSelectorInput';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (courseOwnerEnabled, levelList = [], isEdit, options) => {
  const { enabledContentTeam, initialData, disableInstructors, enableCourseColor } = options;
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Course Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Sub Title',
      inputName: 'subTitle',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: (
        <Box alignItems={'center'} display="inline-flex">
          <Box mr={1}>Course Code</Box>
          <Tooltip
            arrow
            title="หลังจาก Publish คอร์สนี้ จะไม่สามารถแก้ไข Course Code ได้"
            placement={'top'}
          >
            <InfoOutlinedIcon fontSize={'small'} />
          </Tooltip>
        </Box>
      ),
      inputName: 'courseCode',
      inputProps: {
        required: true,
        disabled: !!initialData?.latestPublishedRevision || false,
        validationErrors: {
          isDefaultRequiredValue: `Course Code is required`,
        },
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Level/Subject',
      inputName: 'courseLevel',
      options: levelList.map((level) => ({
        key: level.value,
        value: level.value,
        text: level.text,
      })),
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Language',
      inputName: 'language',
      options: [
        { key: 'TH', value: 'th', text: 'Thai' },
        { key: 'EN', value: 'en', text: 'English' },
      ],
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
  ];

  if (enableCourseColor) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Theme Color',
      inputName: 'themeColor',
      customInput: () => <ColorSelectorInput />,
      mode: MODE.EDIT,
    });
  }

  if (!disableInstructors) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Instructor',
      inputName: 'instructorIds',
      customInput: () => <InstructorSelectorInput />,
      mode: MODE.BOTH,
    });
  }

  if (courseOwnerEnabled) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Course Owner',
      inputName: 'courseOwnerId',
      options: [...getCourseOwnerDDL()],
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    });
  }
  if (enabledContentTeam) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      customInput: () => <CourseTeamSelectorInput initialData={initialData} />,
      inputLabel: 'Team',
      inputName: 'teamIds',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    });
  }
  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

const handleCustomValidation = (allowTeam) => (formValue, isChanged) => {
  const error = {};
  const { courseCode, permalink, teamIds } = formValue;

  const regex = /^[A-Za-z0-9_-]+$/g;
  const regexErrorText = 'Use only letters, numbers, hyphens (-), and underscores (_)';

  if (isChanged) {
    if (courseCode && !courseCode.match(regex)) {
      error.courseCode = regexErrorText;
    }
    if (permalink && !permalink.match(regex)) {
      error.permalink = regexErrorText;
    }
    if (allowTeam && (!Array.isArray(teamIds) || teamIds.length === 0)) {
      error.teamIds = `Should has at least 1 team`;
    }
  }
  return error;
};

/**
 * @param {object}      props
 * @param {typeof import('Util/Config').defaultConfig} props.appConfig
 * @param {function}    props.onSubmit
 * @param {boolean}     props.isEdit
 * @param {object}      props.userProfile
 * @param {object}      props.initialData
 * @returns
 */
const AddCourseForm = ({ appConfig, onSubmit, isEdit, userProfile, initialData, ...props }) => {
  return (
    <FormGenerator
      fields={getFields(courseOwnerEnabled, appConfig.courseLevels, isEdit, {
        enabledContentTeam: appConfig.enabledContentTeam,
        initialData: initialData,
        disableInstructors: appConfig?.courses?.disableInstructors,
        enableCourseColor: appConfig?.courses?.enableCourseColor,
      })}
      onSubmit={onSubmit}
      submitText={isEdit ? 'Update' : 'Add'}
      cancelText="Cancel"
      showCancel
      customValidation={handleCustomValidation(appConfig.enabledContentTeam)}
      name="add-course-form"
      initialData={initialData}
      {...props}
    />
  );
};

AddCourseForm.propTypes = {
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default withAppConfig({ configProp: 'appConfig' })(AddCourseForm);
