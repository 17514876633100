import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const mapSubtopicsToOptions = (subtopics) => {
  return subtopics.map((subtopic, idx) => ({
    id: idx,
    key: idx,
    value: subtopic,
    text: subtopic,
  }));
};

const SubtopicInput = ({ value: subtopics, options: examItemSubtopics, readOnly, onChange }) => {
  const options = mapSubtopicsToOptions(examItemSubtopics || []);

  const handleOnChange = (subtopics) => {
    onChange(
      {},
      {
        value: subtopics,
      }
    );
  };

  return (
    <Box alignItems="center">
      <Dropdown
        fluid
        multiple
        search
        selection
        options={options}
        value={subtopics || []}
        onChange={(_, { value }) => handleOnChange(value)}
        disabled={readOnly}
      />
    </Box>
  );
};

SubtopicInput.propTypes = {
  value: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(SubtopicInput);
