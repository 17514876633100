import React, { Fragment, Component } from 'react';
import { Icon, List, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Flex, Text, Box } from 'Components/Base';
import QueryTable from 'GraphQL/util/QueryTable';

import { SemanticButton as Button } from '../../../../Components/Base/Button';
import COLUMN_TYPE from '../../../../Components/Search/SearchFormGenerator/constantType';
import { RolesLabel } from '../../../../Components/UserItem';
import { getUsersNew } from '../../../../GraphQL/query/Users.query';
import NewUserModal from '../../Containers/NewUserModal';
import getConfig from '../../../../Util/Config';

const getUsersNewColumn = (variables) => {
  const { additionalDataLabels, enabledAppPermission } = getConfig();
  const allColumns = [
    {
      Header: 'User ID',
      accessor: 'id',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      isHideColumn: false,
    },
    {
      Header: 'Ref. ID',
      accessor: 'referenceId',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      isHideColumn: false,
    },
    {
      Header: 'Username',
      accessor: 'username',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      isHideColumn: false,
    },
    {
      Header: 'First Name',
      accessor: 'firstname',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      isHideColumn: false,
    },
    {
      Header: 'Last Name',
      accessor: 'lastname',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      isHideColumn: false,
    },
    {
      Header: 'Email',
      accessor: 'email',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      isHideColumn: false,
    },
    {
      Header: 'Verified',
      accessor: 'emailVerified',
      Cell: (row) => (
        <List>
          <List.Item>
            <List.Content verticalAlign="middle">
              {!row.original.isPassportUser && (
                <List.Description>
                  <Label color={row.original.emailVerified ? 'green' : 'red'} size="mini">
                    {row.original.emailVerified ? 'Verified' : 'Pending Verification'}
                  </Label>
                </List.Description>
              )}
            </List.Content>
          </List.Item>
        </List>
      ),
      maxWidth: 120,
    },
    {
      Header: 'Role',
      accessor: enabledAppPermission ? 'appRoles' : 'roles',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      isHideColumn: false,
      width: 150,
      Cell: (row) => (
        <Flex flexWrap="wrap">
          <RolesLabel roles={row.value} size={'small'} />
        </Flex>
      ),
    },
    {
      Header: 'Action',
      accessor: 'action',
      isSearchAble: false,
      isHideColumn: false,
      Cell: (row) => (
        <Fragment>
          <Link to={{ pathname: `/users/search/${row.original.id}` }}>
            <Button size="small">
              <Icon name="user" /> View
            </Button>
          </Link>
        </Fragment>
      ),
      maxWidth: 100,
    },
    {
      Header: additionalDataLabels?.additional1?.value ?? 'additional1',
      accessor: 'additional1',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional1?.isSearchAble ?? true,
      Cell: (row) => null,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional2?.value ?? 'additional2',
      accessor: 'additional2',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional2?.isSearchAble ?? true,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional3?.value ?? 'additional3',
      accessor: 'additional3',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional3?.isSearchAble ?? true,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional4?.value ?? 'additional4',
      accessor: 'additional4',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional4?.isSearchAble ?? true,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional5?.value ?? 'additional5',
      accessor: 'additional5',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional5?.isSearchAble ?? true,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional6?.value ?? 'additional6',
      accessor: 'additional6',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional6?.isSearchAble ?? true,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional7?.value ?? 'additional7',
      accessor: 'additional7',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional7?.isSearchAble ?? true,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional8?.value ?? 'additional8',
      accessor: 'additional8',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional8?.isSearchAble ?? true,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional9?.value ?? 'additional9',
      accessor: 'additional9',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional9?.isSearchAble ?? true,
      isHideColumn: true,
    },
    {
      Header: additionalDataLabels?.additional10?.value ?? 'additional10',
      accessor: 'additional10',
      type: COLUMN_TYPE.STRING,
      isSearchAble: additionalDataLabels?.additional10?.isSearchAble ?? true,
      isHideColumn: true,
    },
  ];

  return allColumns;
};

class UserNew extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'firstname', type: 'ASC' },
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>User Search</Text.Header>
          <Box />
          <NewUserModal
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                Add User
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type="usUsers"
          resolveData={(data) => data.usUsers.users}
          columns={getUsersNewColumn(variables)}
          query={getUsersNew}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default UserNew;
