import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Container } from 'semantic-ui-react';
import { compose } from 'recompose';
import { withApollo } from '@apollo/client/react/hoc';

import { Flex } from 'Components/Base';
import { getAllCourses, COURSE_VERSION } from 'GraphQL/query/Courses.query';
import { deleteCourse } from 'GraphQL/mutation/Courses.mutation';
import QueryTable from 'GraphQL/util/QueryTable';

import withServerConfig from '../../../GraphQL/util/withServerConfig';
import getConfig from '../../../Util/Config';
import ConfirmationButton from '../../../Components/ConfirmationButton';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';

const unsortedColumns = ['title', 'type'];
const courseColumns = (handleOnDelete, backofficeV1Url) => [
  {
    Header: 'Ref. Id',
    accessor: 'referId',
    width: 170,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      const referId = original.meta.referId;
      return referId;
    },
  },
  {
    Header: 'Permalink',
    accessor: 'permalink',
    width: 170,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      const permalink = original.meta.permalink;
      return permalink;
    },
  },
  {
    Header: 'Title',
    accessor: 'title',
    width: 170,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: (row) => {
      return <a href={`${backofficeV1Url}/courses/edit/${row.original.id}`}>{row.value}</a>;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 170,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      const status = original.meta.status;
      return status;
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 170,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      const type = original.v1meta.type;
      return type;
    },
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 170,
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: (row) => {
      return (
        <Fragment>
          <Button.Group>
            <ConfirmationButton
              onConfirm={() => handleOnDelete(row.value)}
              headerText={`Delete Course?`}
              confirmText="Delete"
              contentText="This course will be permanently deleted from the system."
            >
              <Icon name="trash alternate outline" color="red" />
            </ConfirmationButton>
          </Button.Group>
        </Fragment>
      );
    },
  },
];
class AllCoursesQuery extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: { version: COURSE_VERSION.SKOOLDIO_V1 },
    order: { field: 'id', type: 'ASC' },
  };
  static propTypes = {
    data: PropTypes.array,
    urlPath: PropTypes.string,
  };

  static defaultProps = {
    urlPath: '/courses',
  };

  handleOnDelete = async (courseId) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    client.mutate({
      mutation: deleteCourse,
      variables: {
        version: COURSE_VERSION.SKOOLDIO_V1,
        courseId: courseId,
      },
      refetchQueries: [
        {
          query: getAllCourses,
          variables: {
            search,
            paging,
            order,
          },
        },
      ],
      fetchPolicy: 'no-cache',
    });
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };

  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({
      search: {
        version: COURSE_VERSION.SKOOLDIO_V1,
        ...search,
      },
      paging,
    });
  };

  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };

  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };

  handleSortedChange = (sorted) => {
    if (unsortedColumns.indexOf(sorted[0].id) !== -1) return;
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };

  render() {
    const { search, paging, order } = this.state;
    const { backofficeV1Url } = getConfig();
    return (
      <Container>
        <Flex justifyContent="flex-end" mb={2}>
          <Button primary circular href={`${backofficeV1Url}/courses/add`}>
            <Icon name="plus" size="small" />
            Add Course
          </Button>
        </Flex>
        <QueryTable
          type="courses"
          columns={courseColumns(this.handleOnDelete, backofficeV1Url)}
          query={getAllCourses}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Container>
    );
  }
}

export default compose(withApollo, withServerConfig())(AllCoursesQuery);
