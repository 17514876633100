import React from 'react';
import { Checkbox } from 'formsy-semantic-ui-react';
import { useMutation } from '@apollo/client';

import { Box } from 'Components/Base';
import FormGenerator from 'Components/Form/FormGenerator';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';
import { editCourse } from 'GraphQL/mutation/Courses.mutation';
import { useAppConfig } from 'Util/hoc/withAppConfig';

import CourseLogoEditor from './CourseLogoEditor';

export const VIDEO_CONTROLLER_MODE = {
  FULL_CONTROL: 'FULL_CONTROL',
  RESTRICTED_FORWARD_PLAYBACK: 'RESTRICTED_FORWARD_PLAYBACK',
};

const SettingsEditor = ({ courseId, course, product, version }) => {
  const { courses } = useAppConfig();
  const [updateCourse, { loading }] = useMutation(editCourse);

  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Forward-seeking in Videos',
      inputName: 'hideSeeking',
      customInput: Object.assign((props) => {
        const hideSeeking =
          course.meta?.videoControllerMode === VIDEO_CONTROLLER_MODE.RESTRICTED_FORWARD_PLAYBACK;
        return (
          <Checkbox label="ป้องกันการกรอวิดีโอไปข้างหน้า" defaultChecked={hideSeeking} {...props} />
        );
      }),
    },
  ];

  if (courses?.enableAssetsEditor) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Logo',
      inputName: 'logo',
      customInput: Object.assign((props) => {
        const courseLogoUrl = course?.meta?.logoUrl;
        const logoUrl = courseLogoUrl && courseLogoUrl !== '' ? courseLogoUrl : undefined;
        const bucketLocator = { courseId, course, product, version };
        return <CourseLogoEditor logoUrl={logoUrl} {...props} {...bucketLocator} />;
      }),
    });
  }

  const submit = ({ hideSeeking, ...value }) => {
    const videoControllerMode = hideSeeking
      ? VIDEO_CONTROLLER_MODE.RESTRICTED_FORWARD_PLAYBACK
      : VIDEO_CONTROLLER_MODE.FULL_CONTROL;

    updateCourse({
      variables: {
        courseId,
        version: productCourseVersionResolver(product, version),
        courseDesc: {
          ...value,
          videoControllerMode,
        },
      },
    });
  };

  return (
    <Box>
      <FormGenerator fields={fields} submitText="Update" onSubmit={submit} loading={loading} />
    </Box>
  );
};

export default SettingsEditor;
