import React from 'react';
import PropTypes from 'prop-types';

import QueryComponent from 'GraphQL/util/QueryComponent';
import { SKU_CATEGORY } from 'Routes/Products/Domains/constants';

import CategoriesList from './CategoriesList';

const CategoryRoute = (props) => {
  const {
    skuCategory,
    getCategoryByPermalink,
    getCategories,
    getCategoryByPermalinkQueryName,
    getCategoriesQueryName,
    getCategory,
    getCategoryQueryName,
    createMutation,
    updateMutation,
    permalink,
    title,
    id,
  } = props;

  return (
    <>
      <QueryComponent query={getCategoryByPermalink} variables={{ permalink: permalink }}>
        {(data) => {
          return (
            <CategoriesList
              createMutation={createMutation}
              updateMutation={updateMutation}
              getCategory={getCategory}
              getCategoryQueryName={getCategoryQueryName}
              getCategoriesQueryName={getCategoriesQueryName}
              getCategories={getCategories}
              skuCategory={skuCategory}
              categoryType={permalink}
              categoryName={title}
              parentId={data[getCategoryByPermalinkQueryName].id}
              id={id}
            />
          );
        }}
      </QueryComponent>
    </>
  );
};

CategoryRoute.propTypes = {
  skuCategory: PropTypes.oneOf(Object.values(SKU_CATEGORY)).isRequired,
  getCategoryByPermalink: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getCategoryByPermalinkQueryName: PropTypes.string.isRequired,
  getCategoriesQueryName: PropTypes.string.isRequired,
  getCategory: PropTypes.func.isRequired,
  getCategoryQueryName: PropTypes.string.isRequired,
  createMutation: PropTypes.func.isRequired,
  updateMutation: PropTypes.func.isRequired,
  permalink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default CategoryRoute;
