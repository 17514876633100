import React from 'react';
import PropTypes from 'prop-types';

import { getBundle } from '../../GraphQL/query/Products.query';
import { updateBundleHighlightsMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUBaseHighlightEditor from '../SKUBaseHighlightEditor';

const BundleHighlightEditor = (props) => {
  const { bundle } = props;
  return (
    <SKUBaseHighlightEditor
      {...props}
      dataQuery={getBundle}
      dataQueryName={'sfBoProductBundle'}
      variableQuery={{ SKUCode: bundle.SKUCode }}
      handleMutation={updateBundleHighlightsMutation}
      getHandleVariables={(data) => ({
        id: bundle.id,
        highlights: data,
      })}
      refetchQueries={[{ query: getBundle, variables: { SKUCode: bundle.SKUCode } }]}
    />
  );
};

BundleHighlightEditor.propTypes = {
  bundle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default BundleHighlightEditor;
