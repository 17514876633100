export const DiscountCodePermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'sales.discountCode.list-view',
    UPDATE: 'sales.discountCode.list-update',
    DELETE: 'sales.discountCode.list-delete',
  },
  OVERVIEW: {
    VIEW: 'sales.discountCode.overview-view',
    UPDATE: 'sales.discountCode.overview-update',
    DELETE: 'sales.discountCode.overview-delete',
  },
});
