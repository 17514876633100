import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import { resetUserPasswordMutation } from 'GraphQL/LSP/mutation/Profile.mutation';

import UserPasswordForm from './UserPasswordForm';

function UserPasswordModal(props) {
  const { id, trigger } = props;

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>Change Password</Modal.Header>
          <Modal.Content>
            <CreateEditForm
              createMutation={resetUserPasswordMutation}
              id={id}
              getCreateVariables={(form) => ({
                userId: id,
                password: form.password,
              })}
              onCompleted={closeModal}
              onCancelled={closeModal}
            >
              {({ onSubmit }) => {
                return <UserPasswordForm onSubmit={onSubmit} />;
              }}
            </CreateEditForm>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default UserPasswordModal;
