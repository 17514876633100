import React from 'react';
import { Helmet } from 'react-helmet';
import urlJoin from 'url-join';

import getConfig from 'Util/Config';

const HTMLMeta = (props) => {
  const { htmlMeta } = getConfig();

  const resolveFavicon = (filename) => {
    if (htmlMeta?.faviconPath) {
      return urlJoin(htmlMeta.faviconPath, filename);
    } else if (filename === 'favicon.ico' && htmlMeta?.faviconURL) {
      // this case for handle `faviconURL` config this should delete when no one use this config
      return htmlMeta.faviconURL;
    }
    // default favicon
    return urlJoin('/favicon', filename);
  };

  return (
    <Helmet defaultTitle={htmlMeta.title} {...props}>
      <title itemProp="name" lang="en">
        {htmlMeta.title ?? `Skooldio Backoffice`}
      </title>
      <link rel="apple-touch-icon" sizes="180x180" href={resolveFavicon('apple-touch-icon.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={resolveFavicon('favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={resolveFavicon('favicon-16x16.png')} />
      <link rel="manifest" href={resolveFavicon('site.webmanifest')} />
      <link rel="mask-icon" href={resolveFavicon('safari-pinned-tab.svg')} color="#5bbad5" />
      <link rel="shortcut icon" href={resolveFavicon('favicon.ico')} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-config" content={resolveFavicon('browserconfig.xml')} />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

HTMLMeta.propTypes = {};

export default HTMLMeta;
