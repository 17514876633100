import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Input } from 'semantic-ui-react';
import maxBy from 'lodash/maxBy';

import { Flex, Box, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';
import RichTextEditor from 'Components/RichTextEditor';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

const EditorBox = styled(Box)`
  flex: 1;
  & .text-editor {
    width: 100%;
  }
`;

const ScoreRatioInput = styled(Input)`
  height: 30px;
  caret-color: transparent;
  input:hover {
    cursor: default;
  }

  // Customizing Increment Arrows on Input of Type Number Using CSS
  // https://stackoverflow.com/questions/45396280/customizing-increment-arrows-on-input-of-type-number-using-css
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
    cursor: pointer;
  }
`;

const ChoiceInput = withFormsy(({ value: { choices = [] }, onChange, examId, readonly }) => {
  const handleOnChange = ({ choices, correctAnswer }) => {
    onChange({}, { value: { choices, correctAnswer } });
  };

  useEffect(() => {
    const correctAnswer = { value: maxBy(choices, 'scoreRatio')?.value ?? null };
    handleOnChange({ choices, correctAnswer });
  }, [choices]);

  const handleScoreRatioChange = (event, index) => {
    const newChoices = [...choices];
    const currentValue = event.target.value;
    const isDecimal = currentValue.indexOf('.') >= 0;
    event.target.value = isDecimal
      ? currentValue.substr(0, currentValue.indexOf('.')) +
        currentValue.substr(currentValue.indexOf('.'), 3)
      : currentValue;

    const scoreRatio = Number(event.target.value);
    newChoices[index] = { ...newChoices[index], scoreRatio };

    handleOnChange({ choices: newChoices });
  };

  const handleChoiceLabelChange = (choiceData, index) => {
    const newChoices = [...choices];
    newChoices[index] = { ...newChoices[index], label: choiceData };

    handleOnChange({ choices: newChoices });
  };

  const handleAddChoice = () => {
    handleOnChange({
      choices: [...choices, { label: '', value: `${choices.length + 1}`, scoreRatio: 0 }],
    });
  };

  const handleDeleteChoice = () => {
    const newChoices = [...choices];
    newChoices.pop();

    handleOnChange({ choices: newChoices });
  };

  const choicesDefault = useMemo(() => {
    return choices.map((choice) => {
      return {
        ...choice,
        scoreRatio: choice.scoreRatio ? choice.scoreRatio : 0,
      };
    });
  }, [choices]);
  const hasChoices = choicesDefault.length >= 1;

  const choiceRow = [];
  if (hasChoices) {
    choicesDefault.forEach((choice, index) => {
      choiceRow.push(
        <Flex my={2} mt={index > 0 ? 4 : 0} key={`choice_${index}`} id={`choice_${index}`}>
          <Flex minWidth="84px">
            <Text as="label" color="black" mr={2}>
              {`choice ${index + 1}`}
            </Text>
          </Flex>

          <Flex ml={2} flexDirection="column">
            <EditorBox>
              <RichTextEditor
                onChange={(data) => handleChoiceLabelChange(data, index)}
                value={choice.label}
                contentFormat={`html`}
                returnValueAsString
                customControls={[
                  {
                    type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                    data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                  },
                ]}
              />
            </EditorBox>
            <Flex mt={2} width="80px" alignItems="center">
              <ScoreRatioInput
                required
                type="number"
                name="scoreRatio"
                min={0}
                max={1}
                step={0.25}
                onKeyDown={(e) => e.preventDefault()}
                value={choice.scoreRatio}
                onChange={(_event) => handleScoreRatioChange(_event, index)}
                disabled={readonly}
              />
              <Text color="black" ml={2}>
                คะแนน
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    });
    choiceRow.push(
      <Flex justifyContent="flex-end" key={`choice_delete`}>
        <Button icon compact basic type="button" color="red" onClick={handleDeleteChoice}>
          <Icon name="trash alternate outline" />
        </Button>
      </Flex>
    );
  }
  choiceRow.push(
    <Box my={2} key={`choice_add`}>
      <Button disabled={readonly} secondary circular fluid type="button" onClick={handleAddChoice}>
        <Icon name="plus square" />
        Add New Choice
      </Button>
    </Box>
  );
  return choiceRow;
});

const MultipleChoiceWithScoreRatioForm = (props) => {
  return (
    <Fragment>
      <Text as="label" fontWeight="bold">
        Choice
      </Text>
      <ChoiceInput {...props} />
    </Fragment>
  );
};

MultipleChoiceWithScoreRatioForm.propTypes = {
  value: PropTypes.shape({
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: RichTextEditor.propTypes.value,
        scoreRatio: PropTypes.number,
      })
    ),
    correctAnswer: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};

export default MultipleChoiceWithScoreRatioForm;
