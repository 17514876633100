import React from 'react';

import { Text } from 'Components/Base';

const Title = ({ children, ...restProps }) => (
  <Text fontSize={2} fontWeight="bold" pl={3} {...restProps}>
    {children}
  </Text>
);

export default Title;
