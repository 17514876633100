import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import _ from 'lodash';

import { Flex } from 'Components/Base';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';

import { getRedemptionOrders } from '../../GraphQL/query/Payment.query';

const columns = () => [
  {
    Header: 'OrderNo',
    accessor: 'orderNo',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'SKUCode',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      return _.get(original, 'items[0].SKUCode');
    },
  },
  {
    Header: 'SKUTitle',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      return _.get(original, 'items[0].title');
    },
  },
  {
    Header: 'Full Name',
    accessor: 'customerFullName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Email',
    accessor: 'customerEmail',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
];

class RedemptionOrderRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: {},
      order: { field: 'orderNo', type: 'DESC' },
    };
  }

  handleEdit = (id) => {
    // this.props.history.push(`/lsp/syllabuses/list/${id}`);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoPaymentRedemptionOrders.redemptionOrders;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Redemption Orders</Text.Header>
        </Flex>
        <QueryTable
          type={'sfBoPaymentRedemptionOrders'}
          resolveData={this.resolveData}
          columns={columns()}
          query={getRedemptionOrders}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(RedemptionOrderRoute);
