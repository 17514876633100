import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { Message } from 'semantic-ui-react';
import { useQuery, useMutation } from '@apollo/client';

import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';
import { publishExam } from 'GraphQL/mutation/Exam.mutation';
import { getExamById } from 'GraphQL/query/Exam.query';
import { Text, Flex } from 'Components/Base';
import ConfirmationButton from 'Components/ConfirmationButton';
import withAppConfig from 'Util/hoc/withAppConfig';

import { EXAM_GENERATOR_TYPE, EXAM_QUESTION_STATUS, EXAM_STATUS } from '../../Components/constants';
import { mapExamData } from '../utils';

const validateExamBeforePublish = (exam, isChanged) => {
  const error = {};
  if (isChanged) {
    const { type, courseCode, subtopic, fullScore, passingCriteria } = exam;
    const passingCriteriaValue = passingCriteria?.value ?? 0;
    const passingCriteriaType = passingCriteria?.type;

    if (!type) {
      error.type = 'ประเภท is required';
    }
    if (!courseCode || courseCode.length === 0) {
      error.courseCode = 'Course Code is required';
    }
    if (!subtopic || subtopic.length === 0) {
      error.subtopic = 'เรื่อง is required';
    }
    if (!fullScore) {
      error.fullScore = 'คะแนนเต็ม is required';
    }
    if (!passingCriteria || !passingCriteriaValue || !passingCriteriaType) {
      error.passingCriteria = 'เกณฑ์ผ่าน is required';
    }
  }
  return error;
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  MISMATCH_CONDITION: {
    message: `Cannot publish exam, Exam's condition is invalid`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  MISSING_DETAILS: {
    message: `Cannot publish exam, Some details is missing`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  NEED_PUBLISHED_ALL_QUESTION: {
    message: `Cannot publish exam, Some question is not published`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  NEED_PUBLISHED_CONDITION: {
    message: `Cannot publish exam, Some condition is not published`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot publish exam, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const ExamHeader = ({ examId, appConfig }) => {
  const [feedback, setFeedback] = useState({});
  const { data } = useQuery(getExamById, { variables: { examId } });

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    if (message.includes('422')) {
      setFeedback(FEEDBACK.MISMATCH_CONDITION);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const onCompleted = useCallback((response) => {
    setFeedback({
      message: `Exam published successfully`,
      variant: VARIANTS.SUCCESS,
      show: true,
    });
  }, []);

  const [publishExamExam, { error: publishError }] = useMutation(publishExam, {
    onError,
    onCompleted,
  });

  const { exam, publishValidationErrors } = useMemo(() => {
    const exam = mapExamData(data?.examExam);
    const publishValidationErrors = validateExamBeforePublish(exam, true);
    return { exam, publishValidationErrors };
  }, [data]);

  const handlePublish = useCallback(async () => {
    const errors = validateExamBeforePublish(exam, true);

    if (Object.keys(errors).length > 0) {
      setFeedback(FEEDBACK.MISSING_DETAILS);
      return;
    }

    if (exam.generatorType === EXAM_GENERATOR_TYPE.FIXED) {
      //type fixed and some question still draft
      if (exam.examItems.some((examItem) => examItem.status === EXAM_QUESTION_STATUS.DRAFT)) {
        setFeedback(FEEDBACK.NEED_PUBLISHED_ALL_QUESTION);
        return;
      }

      //type fixed and some conditions doesn't published
      const questionIds = exam.examItems.map((question) => question.questionId);
      if (questionIds.some((questionIds) => !exam.questionOrder.includes(questionIds))) {
        setFeedback(FEEDBACK.NEED_PUBLISHED_CONDITION);
        return;
      }
    }

    await publishExamExam({
      variables: { examId },
      refetchQueries: [
        {
          query: getExamById,
          variables: {
            examId: examId,
            enabledContentTeam: appConfig.enabledContentTeam,
          },
        },
      ],
    });
  }, [appConfig, exam, examId, publishExamExam]);

  const status = exam?.status;
  const examTitle = exam?.title;
  return (
    <Fragment>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
      <Flex justifyContent="space-between" alignItems="flex-end" mb={2}>
        <Text.Header>{`Edit Exam${examTitle && ` | ${examTitle}`}`}</Text.Header>
        <Flex alignItems="flex-end">
          <Text.Header mx={2} textAlign="right">
            Status: {status}
          </Text.Header>
          {status === EXAM_STATUS.DRAFT && (
            <ConfirmationButton
              buttonStyle={{
                primary: true,
                circular: true,
                size: 'medium',
                type: 'button',
              }}
              onConfirm={handlePublish}
              headerText={`Publish Exam ?`}
              confirmText="Publish"
              contentText={`Do you want to publish this exam ?`}
            >
              Publish Exam
            </ConfirmationButton>
          )}
        </Flex>
      </Flex>
      {feedback.message === FEEDBACK.MISSING_DETAILS.message &&
        Object.keys(publishValidationErrors).length >= 1 && (
          <Message negative>
            <Message.Header>This Exam cannot be published</Message.Header>
            <Message.List>
              {Object.values(publishValidationErrors).map((value, index) => (
                <Message.Item key={index}>{`${value}`}</Message.Item>
              ))}
            </Message.List>
          </Message>
        )}
      {publishError && <Message negative>{publishError.message}</Message>}
    </Fragment>
  );
};

export default withAppConfig({ configProp: 'appConfig' })(ExamHeader);
