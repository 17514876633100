import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';

// Config
import getConfig from 'Util/Config';

import { FormsyProductCourseCodeSelectorInput } from '../ProductSelectorInput';

const { personalizedDiscountCode: personalizedDiscountCodeConfig } = getConfig();

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'มูลค่า',
    inputName: 'discount',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'หน่วย',
    inputName: 'discountUnit',
    options: [
      { key: 'FIX', value: 'FIX', text: 'FIX' },
      { key: 'PERCENT', value: 'PERCENT', text: 'PERCENT' },
    ],
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'refCode',
    inputName: 'refCode',
    options: personalizedDiscountCodeConfig?.refCodeOptions ?? [],
    hidden: !personalizedDiscountCodeConfig?.enabledRefCode ?? false,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'Course Code',
    inputName: 'SKUCodes',
    customInput: (props) => <FormsyProductCourseCodeSelectorInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'สำหรับลูกค้า (บุคคล หรือ องค์กร)',
    inputName: 'customerType',
    options: [
      { key: 'ALL', value: 'ALL', text: 'ลูกค้าทุกประเภทสามารถใช้ได้' },
      { key: 'INDIVIDUAL', value: 'INDIVIDUAL', text: 'ลุกค้าบุคคลเท่านั้น' },
      { key: 'CORPORATE', value: 'CORPORATE', text: 'ลูกค้าองค์กรเท่านั้น' },
    ],
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Description',
    inputName: 'discountText',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'สกุลเงิน',
    inputName: 'currency',
    options: [{ key: 'THB', value: 'THB', text: 'THB' }],
    inputProps: {
      defaultValue: 'THB',
    },
  },
];

export default class DiscountForm extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    discountId: PropTypes.string,
    onCreateNewDiscount: PropTypes.func,
  };

  static defaultProps = {
    onCreateNewDiscount: () => {},
    isEdit: false,
  };

  state = {
    fields: [...fields],
  };

  handleSubmit = (form) => {
    const discountData = {
      ...form,
    };
    this.props.onCreateNewDiscount(discountData);
  };

  handleChange = ({ validationType }, isChanged) => {
    if (isChanged) {
      this.setState({ fields: fields });
    }
  };

  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={this.state.fields}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
