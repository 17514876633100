import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SideMenuLayout from 'Components/SideMenuLayout';
import APP_PERMISSION from 'Util/permissions/permissionKeys';
import { GenSwitchRoute } from 'Util/Route';

import CreateIndividualPDFReport from './Routes/CreateIndividualPDFReport';

const PDFReportRouteIndex = () => (
  <Redirect to={{ title: '', pathname: '/le/pdf-report/individual-report' }} />
);

const defaultRoute = {
  path: '/le/pdf-report',
  exact: true,
  roles: [],
  route: PDFReportRouteIndex,
};

const menus = [
  {
    path: '/le/pdf-report/individual-report',
    title: 'Individual Report',
    menuName: 'Individual Report',
    icon: 'university',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: CreateIndividualPDFReport,
  },
];

export default class PDFReportRoute extends Component {
  render() {
    return (
      <SideMenuLayout header={'Report Type'} menus={menus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute routes={[...menus, defaultRoute]} />
      </SideMenuLayout>
    );
  }
}
