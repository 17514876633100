import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Dropdown } from 'semantic-ui-react';

import { Flex } from 'Components/Base';

const DropdownStyle = styled(Dropdown)`
  &.selection {
    &.dropdown {
      min-width: 70px;
      height: 35px;
      min-height: 35px;
      && .icon {
        height: 33px;
        border-left: 1px solid rgba(34, 36, 38, 0.15);
      }
      > input.search {
        width: 60px;
      }
    }
  }
`;

const getNumberOptions = () => {
  const numberOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const options = numberOptions.map((number) => {
    return { text: `${number}`, value: `${number}` };
  });

  return options;
};
const numberOptions = getNumberOptions();

const getEnglishCharacterOptions = (startChar, endChar) => {
  const options = [];
  const startCode = startChar.charCodeAt();
  const endCode = endChar.charCodeAt();
  for (var i = startCode; i <= endCode; i++) {
    options.push({ text: `${String.fromCharCode(i)}`, value: `${String.fromCharCode(i)}` });
  }

  return options;
};
const englishCharacterOptions = getEnglishCharacterOptions('A', 'J');

const SubQuestionsConnectionAnswersDropdown = (props) => (
  <DropdownStyle noResultsMessage="ไม่พบ" search selection style={{ marginLeft: 8 }} {...props} />
);

const MultipleSubQuestionsConnectionAnswersDropdownForm = (props) => {
  const { onDeleteAnswer, onAnswerChange, isShowDeleteButton, answer } = props;

  const handleAnswerChange = useCallback(
    (value, index) => {
      const newAnswerChars = answer.split('');
      newAnswerChars[index] = value;
      const newAnswer = newAnswerChars.join('');
      onAnswerChange(newAnswer);
    },
    [answer, onAnswerChange]
  );

  return (
    <Flex {...props}>
      <SubQuestionsConnectionAnswersDropdown
        name="answer1"
        value={answer[0] ?? '_'}
        onChange={(_event, result) => handleAnswerChange(result.value, 0)}
        options={numberOptions}
      />
      <SubQuestionsConnectionAnswersDropdown
        name="answer2"
        value={answer[1] ?? '_'}
        onChange={(_event, result) => handleAnswerChange(result.value, 1)}
        options={numberOptions}
      />
      <SubQuestionsConnectionAnswersDropdown
        name="answer3"
        value={answer[2] ?? '_'}
        onChange={(_event, result) => handleAnswerChange(result.value, 2)}
        options={englishCharacterOptions}
      />
      {isShowDeleteButton && (
        <Button icon compact type="button" onClick={onDeleteAnswer} style={{ marginLeft: 16 }}>
          <Icon name="trash alternate outline" />
        </Button>
      )}
    </Flex>
  );
};

MultipleSubQuestionsConnectionAnswersDropdownForm.propTypes = {
  onDeleteAnswer: PropTypes.func,
  onAnswerChange: PropTypes.func,
  isShowDeleteButton: PropTypes.bool,
  indexSubQuestion: PropTypes.number,
  indexAnswer: PropTypes.number,
  answer: PropTypes.string,
};

export default MultipleSubQuestionsConnectionAnswersDropdownForm;
