import React, { useState } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';
import { DatePickerInput } from 'Components/DatePickerInput';
import Loading from 'Components/Loading';
import { Box } from 'Components/Base';

import ProductTypesCheckbox from '../DiscountCodeModal/ProductTypesCheckbox';
import { FormsyProductSelectorInput } from '../ProductSelectorInput';
import { ProductTypesCheckBoxs, DISCOUNT_UNIT } from '../../Domains/constants';
import useProductListData from '../../Domains/useProductListData';

import DiscountCodeSampler from './DiscountCodeSampler';
import DiscountUnitValueInput from './DiscountUnitValueInput';
import ModalAction from './ModalAction';

const MIN_RANDOM_LENGTH = 4;
const MAX_RANDOM_LENGTH = 10;
const MIN_RANDOM_CODE_AMOUNT = 1;
const MAX_RANDOM_CODE_AMOUNT = 1000;
const MAX_DISCOUNT_TEXT_LENGTH = 50;
const MAX_USABLE_TIME = 1000;

const generateFormFields = (prefix, randomLength) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'จุดประสงค์ในการออก Discount Code',
    inputName: 'discountText',
    inputProps: {
      required: true,
      placeholder: 'กรอกจุดประสงค์',
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Code Prefix',
    inputName: 'prefix',
    inputProps: {
      helperText: 'ตัวอักษรด้านหน้าก่อน Random Code',
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
    inputLabel: 'ความยาว Random Code ที่ต้องการ',
    inputName: 'randomLength',
    inputProps: {
      required: true,
      placeholder: 'กรอกจำนวนหลัก',
      helperText: `อย่างน้อย ${MIN_RANDOM_LENGTH} หลัก สูงสุด ${MAX_RANDOM_LENGTH} หลัก`,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    customInput: (props) => (
      <DiscountCodeSampler
        prefix={prefix}
        randomLength={randomLength}
        minRandomLength={MIN_RANDOM_LENGTH}
        maxRandomLength={MAX_RANDOM_LENGTH}
      />
    ),
  },
  {
    inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
    inputLabel: 'จำนวนโค้ดที่ต้องการ',
    inputName: 'amount',
    inputProps: {
      required: true,
      placeholder: 'กรอกจำนวนโค้ดที่ต้องการ',
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
    inputLabel: 'จำนวนครั้งที่สามารถใช้ซ้ำได้',
    inputName: 'useableTimes',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'ประเภทส่วนลด',
    inputName: 'discountUnitValue',
    customInput: (props) => <DiscountUnitValueInput {...props} />,
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'ใช้ได้กับสินค้าทั้งหมดใน...',
    inputName: 'productTypes',
    inputProps: {
      productTypes: ProductTypesCheckBoxs,
    },
    customInput: (props) => <ProductTypesCheckbox {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'สินค้าที่ใช้โค้ดได้',
    inputName: 'SKUIds',
    customInput: (props) => <FormsyProductSelectorInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'สินค้าที่ใช้โค้ดไม่ได้ (กรณีโค้ดส่วนลดใช้ได้กับทุกคอร์ส)',
    inputName: 'exceptSKUIds',
    customInput: (props) => <FormsyProductSelectorInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'วันที่ใช้โค้ดได้',
    inputName: 'startDate',
    customInput: (props) => <DatePickerInput value={props?.value} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'วันสุดท้ายที่ใช้โค้ดได้',
    inputName: 'endDate',
    customInput: (props) => <DatePickerInput isEndDate={true} value={props?.value} />,
  },
];

const defaultInitialData = {
  randomLength: MIN_RANDOM_LENGTH,
  discountUnitValue: { unit: DISCOUNT_UNIT.FIX, value: 0 },
};

const CreateRandomizedDiscountCodeForm = ({
  onSubmit: onSubmitProp,
  onCancel,
  initialData,
  isEdit = true,
  isError,
  loading: submitting,
  submitButtonRef,
}) => {
  const [formInput, setFormInput] = useState(initialData ? initialData : defaultInitialData);
  const { prefix, randomLength } = formInput;
  const formFields = generateFormFields(prefix, randomLength, isEdit);
  const { loading: preloading } = useProductListData();

  const containerHeight = isError ? 'calc(80vh - 48px)' : '80vh';

  const onChange = (form) => {
    setFormInput(form);
  };

  const customValidation = (values) => {
    const { randomLength, amount, useableTimes, discountUnitValue = {}, discountText } = values;
    const { value: discountValue } = discountUnitValue;
    const validatonErrors = {};

    if (discountText && discountText.length > MAX_DISCOUNT_TEXT_LENGTH) {
      validatonErrors.discountText = `จำกัดความยาวไม่เกิน ${MAX_DISCOUNT_TEXT_LENGTH} ตัวอักษร`;
    }

    if (randomLength < MIN_RANDOM_LENGTH || randomLength > MAX_RANDOM_LENGTH) {
      validatonErrors.randomLength = `กรอกตัวเลขตั้งแต่ ${MIN_RANDOM_LENGTH} ถึง ${MAX_RANDOM_LENGTH}`;
    }

    if (amount < MIN_RANDOM_CODE_AMOUNT || amount > MAX_RANDOM_CODE_AMOUNT) {
      validatonErrors.amount = `กรอกตัวเลขตั้งแต่ ${MIN_RANDOM_CODE_AMOUNT} ถึง ${MAX_RANDOM_CODE_AMOUNT}`;
    }

    if (useableTimes < 1) {
      validatonErrors.useableTimes = `กรอกตัวเลขตั้งแต่ 1 ถึง ${MAX_RANDOM_CODE_AMOUNT}`;
    }

    if (useableTimes > MAX_USABLE_TIME) {
      validatonErrors.useableTimes = `กรอกตัวเลขตั้งแต่ 1 ถึง ${MAX_USABLE_TIME}`;
    }

    if (isNaN(discountValue)) {
      validatonErrors.discountUnitValue = 'discount value must be a number';
    }

    return validatonErrors;
  };

  const handleSubmit = (form) => {
    onSubmitProp(form);
  };

  return (
    <Box height={containerHeight} overflowY={'scroll'} paddingRight={2}>
      {preloading || submitting ? (
        <Loading />
      ) : (
        <FormGenerator
          readOnly={!isEdit}
          fields={formFields}
          onChange={onChange}
          onSubmit={handleSubmit}
          onCancelled={onCancel}
          initialData={formInput}
          customValidation={customValidation}
          showAction={false}
          customAction={(loading, isDirty, onSubmit) => (
            <button
              ref={submitButtonRef}
              style={{ display: 'none' }}
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              submit
            </button>
          )}
        />
      )}
    </Box>
  );
};

export default CreateRandomizedDiscountCodeForm;
