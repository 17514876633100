import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

import QueryComponent from '../../GraphQL/util/QueryComponent';

import CheckMutationResponse from './Components/CheckMutationResponse';

export default class CreateEditForm extends Component {
  static propTypes = {
    onCompleted: PropTypes.func,
    onCancelled: PropTypes.func,
    id: PropTypes.string,
    idVariable: PropTypes.string,
    isEdit: PropTypes.bool,
    createMutation: PropTypes.object,
    editMutation: PropTypes.object,
    dataQuery: PropTypes.object,
    getQueryVariables: PropTypes.func,
    getCreateVariables: PropTypes.func,
    getEditVariables: PropTypes.func,
    createRefetchQueries: PropTypes.arrayOf(
      PropTypes.shape({
        query: PropTypes.object.isRequired,
        variables: PropTypes.object,
      })
    ),
    editRefetchQueries: PropTypes.arrayOf(
      PropTypes.shape({
        query: PropTypes.object.isRequired,
        variables: PropTypes.object,
      })
    ),
    getEditMutationResponse: PropTypes.func,
    getCreateMutationResponse: PropTypes.func,
    checkMutationResponse: PropTypes.bool,
    transformErrorToMessage: PropTypes.func,
    fetchPolicy: PropTypes.oneOf([
      'cache-first',
      'cache-and-network',
      'network-only',
      'no-cache',
      'cache-only',
    ]),
  };

  static defaultProps = {
    idVariable: 'id',
    onCompleted: () => {},
    onCancelled: () => {},
    getQueryVariables: (val) => val,
    getCreateVariables: (val) => val,
    getEditVariables: (val, idObj) => ({ ...val, ...idObj }),
    getEditMutationResponse: (val) => val,
    getCreateMutationResponse: (val) => val,
    checkMutationResponse: false,
    fetchPolicy: 'network-only',
  };

  renderForm(isEdit, loading, mutateFn, getVarFn, initialData) {
    const { idVariable, id } = this.props;

    return React.cloneElement(
      this.props.children({
        data: initialData,
        onSubmit: (value) => mutateFn({ variables: getVarFn(value, { [idVariable]: id }) }),
      }),
      {
        loading,
        showCancel: true,
        onCancelled: this.props.onCancelled,
        ...this.props,
      }
    );
  }

  renderEdit() {
    const {
      editMutation,
      dataQuery,
      editRefetchQueries,
      getEditVariables,
      getQueryVariables,
      id,
      idVariable,
      getEditMutationResponse,
      checkMutationResponse,
      transformErrorToMessage,
      fetchPolicy,
    } = this.props;

    return (
      <Mutation mutation={editMutation} refetchQueries={editRefetchQueries} onError={() => {}}>
        {(editData, { loading, error, data: mutateData }) => {
          return (
            <Fragment>
              <CheckMutationResponse
                checkOnlyDefined={!checkMutationResponse}
                response={getEditMutationResponse(mutateData)}
                loading={loading}
                error={error}
                transformErrorToMessage={transformErrorToMessage}
                onValid={this.props.onCompleted}
              />
              <QueryComponent
                query={dataQuery}
                variables={getQueryVariables({ [idVariable]: id })}
                fetchPolicy={fetchPolicy}
              >
                {(data) => {
                  return this.renderForm(true, loading, editData, getEditVariables, data);
                }}
              </QueryComponent>
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  renderCreate() {
    const {
      createMutation,
      createRefetchQueries,
      getCreateVariables,
      getCreateMutationResponse,
      checkMutationResponse,
      transformErrorToMessage,
    } = this.props;
    return (
      <Mutation mutation={createMutation} refetchQueries={createRefetchQueries} onError={() => {}}>
        {(createData, { loading, error, data }) => {
          return (
            <Fragment>
              <CheckMutationResponse
                checkOnlyDefined={!checkMutationResponse}
                response={getCreateMutationResponse(data)}
                loading={loading}
                error={error}
                transformErrorToMessage={transformErrorToMessage}
                onValid={this.props.onCompleted}
              />
              {this.renderForm(false, loading, createData, getCreateVariables)}
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  render() {
    const { isEdit } = this.props;
    if (!isEdit) return this.renderCreate();
    return this.renderEdit();
  }
}
