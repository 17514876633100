import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Radio, Button, Icon } from 'semantic-ui-react';

import { Flex, Box, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';
import RichTextEditor from 'Components/RichTextEditor';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

const EditorBox = styled(Box)`
  flex: 1;
  & .text-editor {
    width: 100%;
  }
`;

const ChoiceInput = withFormsy(
  ({ value: { choices = [], correctAnswer = {} }, onChange, examId, readonly }) => {
    const handleOnChange = ({ choices, correctAnswer }) => {
      onChange({}, { value: { choices, correctAnswer } });
    };

    const handleRadioChange = (correctAnswer) => {
      handleOnChange({ choices, correctAnswer: { value: `${correctAnswer + 1}` } });
    };

    const handleChoiceLabelChange = (choiceData, index) => {
      const newChoices = [...choices];
      newChoices[index] = { ...newChoices[index], label: choiceData };
      handleOnChange({ choices: newChoices, correctAnswer });
    };

    const handleAddChoice = () => {
      handleOnChange({
        choices: [...choices, { label: '', value: `${choices.length + 1}` }],
        correctAnswer,
      });
    };

    const handleDeleteChoice = () => {
      const newChoices = [...choices];
      newChoices.pop();
      handleOnChange({ choices: newChoices, correctAnswer });
    };

    const choiceRow = [];
    if (choices.length >= 1) {
      choices.forEach((choice, index) => {
        choiceRow.push(
          <Flex my={2} key={`choice_${index}`} id={`choice_${index}`}>
            <Flex minWidth="84px">
              <Radio
                label={`Choice ${index + 1}`}
                value={index}
                onChange={(_, { value }) => handleRadioChange(value)}
                checked={choice.value === (correctAnswer && correctAnswer.value)}
                style={{ height: '16px' }}
              />
            </Flex>
            <EditorBox ml={2}>
              <RichTextEditor
                onChange={(data) => handleChoiceLabelChange(data, index)}
                value={choice.label}
                contentFormat={`html`}
                returnValueAsString
                customControls={[
                  {
                    type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                    data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                  },
                ]}
              />
            </EditorBox>
          </Flex>
        );
      });
      choiceRow.push(
        <Flex justifyContent="flex-end" key={`choice_delete`}>
          <Button icon compact basic type="button" color="red" onClick={handleDeleteChoice}>
            <Icon name="trash alternate outline" />
          </Button>
        </Flex>
      );
    }
    choiceRow.push(
      <Box my={2} key={`choice_add`}>
        <Button
          disabled={readonly}
          secondary
          circular
          fluid
          type="button"
          onClick={handleAddChoice}
        >
          <Icon name="plus square" />
          Add New Choice
        </Button>
      </Box>
    );
    return choiceRow;
  }
);

const MultipleChoiceForm = (props) => {
  return (
    <Fragment>
      <Text as={'label'} fontWeight={'bold'}>
        Choice
      </Text>
      <ChoiceInput {...props} />
    </Fragment>
  );
};

MultipleChoiceForm.propTypes = {
  value: PropTypes.shape({
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: RichTextEditor.propTypes.value,
      })
    ),
    correctAnswer: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};

export { ChoiceInput };
export default MultipleChoiceForm;
