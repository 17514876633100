export const ROUTES = {
  COURSES_V1: 'COURSES_V1',
  COURSES_V2: 'COURSES_V2',
  CERTIFICATES: 'CERTIFICATES',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  PAYMENTS: 'PAYMENTS',
  SALES: 'SALES',
  REPORTS: 'REPORTS',
  WORKSHOP: 'WORKSHOP',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  EXAMS: 'EXAMS',
  PRODUCTS: 'PRODUCTS',
  LSP_FILE_IMPORT: 'LSP_FILE_IMPORT',
  LSP_USER_PROFILE: 'LSP_USER_PROFILE',
  LSP_EDUCATION: 'LSP_EDUCATION',
  LSP_SYLLABUS: 'LSP_SYLLABUS',
  LE_FILE_IMPORT: 'LE_FILE_IMPORT',
  LE_PDF_REPORT: 'LE_PDF_REPORT',
  SALES_V2: 'SALES_V2',
  SHIPMENT: 'SHIPMENT',
  SALES_V2_FILE_IMPORT: 'SALES_V2_FILE_IMPORT',
};
