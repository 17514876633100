import React from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';

import { getUserAssignmentCourseSummary } from 'GraphQL/query/Courses.query';
import { Text } from 'Components/Base';

import { getUserAssignmentReport } from '../utils';

const AssignmentTitle = () => {
  const { params } = useRouteMatch();

  const { data: queryData } = useQuery(getUserAssignmentCourseSummary, {
    variables: { sourceCourseId: params.courseId },
    skip: !params.courseId,
  });

  if (!queryData) return null;

  const assignmentReport = getUserAssignmentReport(
    queryData.userAssignmentCourseSummary,
    params.sectionId,
    params.subsectionId
  );
  return <Text.Header mb={2}>{assignmentReport?.title}</Text.Header>;
};

export default AssignmentTitle;
