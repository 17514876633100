import React from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Role Name',
    inputName: 'name',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
    inputLabel: 'Description',
    inputName: 'description',
  },
];

const RoleDetailsForm = ({ isEdit, initialData, onSubmit, onCancelled }) => (
  <FormGenerator
    fields={fields}
    onSubmit={onSubmit}
    onCancelled={onCancelled}
    submitText={isEdit ? 'Save Role Details' : 'Save Role'}
    cancelText="Cancel"
    showCancel={!isEdit}
    initialData={initialData}
  />
);

RoleDetailsForm.propTypes = {
  isEdit: PropTypes.bool,
  initialData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancelled: PropTypes.func,
};

export default RoleDetailsForm;
