import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Button, Flex } from 'Components/Base';
import RichTextEditor from 'Components/RichTextEditor';
import withFormsy from 'Components/Form/withFormsy';

const EditorBox = styled(Box)`
  flex: 1;
  & .text-editor {
    width: 100%;
  }
`;

const RichTextEditorBox = styled(RichTextEditor)`
  > .RichTextEditor__root___2QXK- {
    max-width: 60vw;
  }
  & .public-DraftEditor-content {
    min-height: 20vh;
  }
`;

const defaultValue = { type: RichTextEditor.contentFormat.HTML, content: null };

/**
 * @param {string} initValue - detail | ex. {content: '<p>HTML CONTENT</p>', type: 'HTML'}
 */
const DescriptionInput = ({ value: initValue, onChange, readOnly }) => {
  const [content, setContent] = useState(
    initValue && initValue.type === defaultValue.type ? initValue.content : defaultValue.content
  );
  const [type, setType] = useState(defaultValue.type);

  const updateValue = useCallback(() => {
    onChange({}, { value: { type, content } });
  }, [type, content, onChange]);

  useEffect(() => {
    updateValue();
  }, [updateValue]);

  return (
    <Box>
      <EditorBox>
        <RichTextEditorBox
          value={content}
          onChange={(value) => setContent(value)}
          contentFormat={type}
          returnValueAsString
          readOnly={readOnly}
        />
      </EditorBox>
    </Box>
  );
};

DescriptionInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(DescriptionInput);
