import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { Flex } from 'Components/Base';

const CourseCodeSearch = ({ onChange, value: courseCodes, options }) => {
  return (
    <Flex alignItems="center">
      <Dropdown
        fluid
        multiple
        search
        selection
        placeholder={'Course Code'}
        options={options}
        value={courseCodes}
        onChange={(_, { value }) => onChange({}, { value })}
      />
    </Flex>
  );
};

CourseCodeSearch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  options: PropTypes.array,
};

export default CourseCodeSearch;
