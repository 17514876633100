import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import { createPrice, updatePrice } from '../../../GraphQL/mutation/Sales.mutation';
import { prices } from '../../../GraphQL/query/Sales.query';
import CheckCloseComponent from '../../../Util/CheckDefinedComponent';

import PriceForm from './PriceForm';

export default class NewPriceMutation extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    priceId: PropTypes.string,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onComplete: () => {},
  };

  renderEdit() {
    const { priceId, data, variables } = this.props;
    return (
      <Mutation mutation={updatePrice} refetchQueries={[{ query: prices, variables }]}>
        {(editPrice, { loading, error, data: mutateData }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent
                checkDefinedObject={mutateData}
                onDefined={this.props.onComplete}
              />
              <PriceForm
                onCreateNewPrice={(value) =>
                  editPrice({ variables: { priceId, updatePrice: value } })
                }
                initialData={data}
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  renderCreate() {
    const { variables } = this.props;
    return (
      <Mutation mutation={createPrice} refetchQueries={[{ query: prices, variables }]}>
        {(createPrice, { loading, error, data }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent checkDefinedObject={data} onDefined={this.props.onComplete} />
              <PriceForm
                onCreateNewPrice={(value) => createPrice({ variables: { newProductPrice: value } })}
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  render() {
    const { isEdit } = this.props;
    if (!isEdit) return this.renderCreate();
    return this.renderEdit();
  }
}
