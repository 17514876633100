import gql from 'graphql-tag';

import { CertificateFragment, CertificateItemFragment } from '../query/Certificate.query';

export const createCertificateMutation = gql`
  mutation createCertificate($certificateData: CertificateNewInput!) {
    createCertificate(certificateData: $certificateData) {
      id
      ...CertificateFragment
    }
  }
  ${CertificateFragment}
`;

export const updateCertificateMutation = gql`
  mutation updateCertificate($id: ID!, $certificateData: CertificateEditInput!) {
    updateCertificate(id: $id, certificateData: $certificateData) {
      id
      ...CertificateFragment
    }
  }
  ${CertificateFragment}
`;

export const deleteCertificateMutation = gql`
  mutation deleteCertificate($id: ID!) {
    deleteCertificate(id: $id) {
      id
    }
  }
`;

export const createCertificateItemMutation = gql`
  mutation createCertificateItem($certificateItemData: CertificateItemNewInput!) {
    createCertificateItem(certificateItemData: $certificateItemData) {
      id
      ...CertificateItemFragment
    }
  }
  ${CertificateItemFragment}
`;

export const updateCertificateItemMutation = gql`
  mutation updateCertificateItem($id: ID!, $certificateItemData: CertificateItemEditInput!) {
    updateCertificateItem(id: $id, certificateItemData: $certificateItemData) {
      id
      ...CertificateItemFragment
    }
  }
  ${CertificateItemFragment}
`;

export const deleteCertificateItemMutation = gql`
  mutation deleteCertificateItem($id: ID!) {
    deleteCertificateItem(id: $id) {
      id
    }
  }
`;

export const generateManualCertificates = gql`
  mutation generateManualCertificates(
    $generateManualCertificatesInput: GenerateManualCertificatesInput!
  ) {
    generateManualCertificates(generateManualCertificatesInput: $generateManualCertificatesInput) {
      code
      success
      userCertificates {
        issuedAt
        studentId
        certificateKeyPath
        expiredAt
      }
    }
  }
`;

export const toggleActiveCertificateMutation = gql`
  mutation toggleActiveCertificate($id: ID!, $certificateData: CertificateEditInput!) {
    toggleActiveCertificate(id: $id, certificateData: $certificateData) {
      id
      ...CertificateFragment
    }
  }
  ${CertificateFragment}
`;
