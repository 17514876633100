/**
 * This utils file for support time limit feature of ANY/LNO quiz.
 *
 * It's turn second into time string like 00:00:00 or 00:00
 *
 */

const formatTime = (time) => {
  return ('0' + time).slice(-2);
};

export const secondsToTime = (s, { autoHideHour = false } = {}) => {
  if (s === 0) {
    if (autoHideHour) return `${formatTime(0)}:${formatTime(0)}`;
    return `${formatTime(0)}:${formatTime(0)}:${formatTime(0)}`;
  }
  if (!s) return '';

  let secs = Number.isInteger(s) ? s : parseInt(s);
  let min = Math.floor(secs / 60);
  secs = secs % 60;
  const hour = Math.floor(min / 60);
  min = min % 60;
  if (autoHideHour && hour === 0) return `${formatTime(min)}:${formatTime(secs)}`;
  return `${formatTime(hour)}:${formatTime(min)}:${formatTime(secs)}`;
};
