import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, Container, Segment } from 'semantic-ui-react';

import { Box } from 'Components/Base';

import { BACKOFFICE_PRODUCT } from '../../../../GraphQL/query/AppConfig.query';

import CourseUsersList from './CourseUsersList';

const USER_COURSE_PERMISSION = {
  OWNER: 'OWNER',
  SALES: 'SALES',
  EDITOR: 'EDITOR',
  TESTER: 'TESTER',
  ASSISTANCE: 'ASSISTANCE',
  VIDEOCALL_ASSISTANCE: 'VIDEOCALL_ASSISTANCE',
};

const UserPermissionList = [
  {
    id: USER_COURSE_PERMISSION.OWNER,
    name: 'Course Owners',
    permission: USER_COURSE_PERMISSION.OWNER,
    product: [BACKOFFICE_PRODUCT.SKOOLDIO],
  },
  {
    id: USER_COURSE_PERMISSION.SALES,
    name: 'Report Users',
    permission: USER_COURSE_PERMISSION.SALES,
    product: [BACKOFFICE_PRODUCT.SKOOLDIO],
  },
  {
    id: USER_COURSE_PERMISSION.EDITOR,
    name: 'Editors',
    permission: USER_COURSE_PERMISSION.EDITOR,
    product: [BACKOFFICE_PRODUCT.SKOOLDIO],
  },
  {
    id: USER_COURSE_PERMISSION.TESTER,
    name: 'Testers',
    permission: USER_COURSE_PERMISSION.TESTER,
    product: [BACKOFFICE_PRODUCT.SKOOLDIO],
  },
  {
    id: USER_COURSE_PERMISSION.ASSISTANCE,
    name: 'Instructor Assistance',
    permission: USER_COURSE_PERMISSION.ASSISTANCE,
    product: [BACKOFFICE_PRODUCT.SKOOLDIO],
  },
  {
    id: USER_COURSE_PERMISSION.VIDEOCALL_ASSISTANCE,
    name: 'Video Call User (Ignite Talk Teacher)',
    permission: USER_COURSE_PERMISSION.VIDEOCALL_ASSISTANCE,
    product: [BACKOFFICE_PRODUCT.LEARNX],
  },
];

const productFilter = (product) => (upl) =>
  upl.product.indexOf(product) >= 0 || upl.product.length === 0;

const filterList = (product) => {
  return UserPermissionList.filter(productFilter(product));
};

class Users extends Component {
  render() {
    const { product, courseId, ...restProps } = this.props;
    return (
      <Container>
        <Box mx={2} pt={2}>
          {filterList(product).map((list, index, array) => (
            <Fragment key={list.id}>
              <CourseUsersList
                header={list.name}
                emptyMessage="No Data"
                product={product}
                courseId={courseId}
                permission={list.permission}
              />
              <Divider hidden />
            </Fragment>
          ))}
        </Box>
      </Container>
    );
  }
}

Users.propTypes = {};

export default Users;
