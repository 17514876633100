import React from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';

import { getUserAssignmentCourseSummary, getUserAssignment } from 'GraphQL/query/Courses.query';

import {
  getCoursesUrl,
  getCourseOverviewUrl,
  getCourseAssignmentsUrl,
  getCourseSubsectionAssignmentsPath,
  getAssignmentsPath,
} from '../../Domain/coursePaths';
import { getCourseSection, getUserAssignmentReport } from '../utils';
import Breadcrumb from '../../Components/Breadcrumb';

function getCrumbs(params = {}, course, userAssignment) {
  const COURSES_PAGE_TITLE = 'Courses';
  const ASSIGNMENT_PAGE_TITLE = 'Assignments';
  const courseTitle = course?.title;
  let sectionTitle = '';
  let assignmentTitle = '';
  let userFullName = userAssignment?.fullName;

  if (params.sectionId) {
    const section = getCourseSection(course, params.sectionId);
    sectionTitle = section?.title;

    if (params.subsectionId) {
      const userAssignmentReport = getUserAssignmentReport(
        course,
        params.sectionId,
        params.subsectionId
      );
      assignmentTitle = userAssignmentReport?.title;
    }
  }

  let crumbs = [];
  if (params.userAssignmentId) {
    crumbs = [
      { title: COURSES_PAGE_TITLE, path: getCoursesUrl(params) },
      { title: courseTitle, path: getCourseOverviewUrl(params) },
      {
        title: ASSIGNMENT_PAGE_TITLE,
        path: getCourseAssignmentsUrl(params),
      },
      {
        title: sectionTitle,
        path: getCourseSubsectionAssignmentsPath(params),
      },
      { title: assignmentTitle, path: getAssignmentsPath(params) },
      { title: userFullName, path: null },
    ];
  } else if (params.subsectionId) {
    crumbs = [
      { title: COURSES_PAGE_TITLE, path: getCoursesUrl(params) },
      { title: courseTitle, path: getCourseOverviewUrl(params) },
      {
        title: ASSIGNMENT_PAGE_TITLE,
        path: getCourseAssignmentsUrl(params),
      },
      {
        title: sectionTitle,
        path: getCourseSubsectionAssignmentsPath(params),
      },
      { title: assignmentTitle, path: null },
    ];
  } else if (params.sectionId) {
    crumbs = [
      { title: COURSES_PAGE_TITLE, path: getCoursesUrl(params) },
      { title: courseTitle, path: getCourseOverviewUrl(params) },
      {
        title: ASSIGNMENT_PAGE_TITLE,
        path: getCourseAssignmentsUrl(params),
      },
      { title: sectionTitle, path: null },
    ];
  }

  const filteredCrumbs = crumbs.filter((crumb) => crumb.title);

  return filteredCrumbs;
}

const AssignmentBreadcrumb = () => {
  const { params } = useRouteMatch();
  const courseId = params?.courseId;
  const userAssignmentId = params?.userAssignmentId;
  let crumbs = [];

  const { data: queryUACS, loading: loadingUACS } = useQuery(getUserAssignmentCourseSummary, {
    variables: { sourceCourseId: courseId },
    skip: !courseId,
  });
  const { data: queryUA, loading: loadingUA } = useQuery(getUserAssignment, {
    variables: { userAssignmentId: userAssignmentId },
    skip: !userAssignmentId,
  });

  if (!loadingUACS && !loadingUA) {
    crumbs = getCrumbs(params, queryUACS?.userAssignmentCourseSummary, queryUA?.userAssignmentById);
  }

  if (crumbs.length === 0) return null;
  return <Breadcrumb crumbs={crumbs} my={3} />;
};

export default AssignmentBreadcrumb;
