import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Label, Popup, Input, Header, Dropdown } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import log from 'loglevel';
import _ from 'lodash';

import { Box, Text } from 'Components/Base';
import { COLOR } from 'Components/Theme';
import RichTextEditor, { convertValueToString } from 'Components/RichTextEditor';
import { SemanticButton as Button } from 'Components/Base/Button';
import {
  MultipleChoice,
  MultipleSelect,
  FillInTheBlankSkillScore,
  FillInTheBlank,
} from 'Components/Questions/QuestionForm';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';
import { QUESTION_TYPE } from 'GraphQL/query/ExamEditorV1.query';
import getConfig from 'Util/Config';

import TagSelectorInput from '../TagSelector';
import { stripHtml } from '../QuestionList/utils';
import ViewAsYAML from '../ViewAsYAML';

const {
  disableExamSolution,
  defaultExamEditorContentFormat: DEFAULT_CONTENT_FORMAT,
  defaultExistingExamItemContentFormat,
  quizSimpleMaxImageSize,
  viewAsYAML: viewAsYAMLConfig,
} = getConfig();

const VALIDATION_WARNING = {
  META: 'Score must have a value',
  QUESTION: 'Question is required',
  ANSWER: {
    DEFAULT: (
      <div>
        <div>*At least 2 answers</div>
        <div>*At least 1 correct answer</div>
      </div>
    ),
    FILL_IN_THE_BLANK_SKILLSCORE: <div>*At least 1 answer</div>,
    OPEN_ENDED_FILL_IN_THE_BLANK: <div>*Required Title</div>,
    CLOSED_ENDED_FILL_IN_THE_BLANK: (
      <div>
        <div>*Required Title</div>
        <div>*Required Answer(เฉลย)</div>
      </div>
    ),
  },
};

const Row = Grid.Row;
const Column = Grid.Column;

const getDisplayType = (type) => {
  switch (type) {
    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return 'One Answer';
    case QUESTION_TYPE.MULTIPLE_SELECTION:
      return ' Multiple Answers';
    case QUESTION_TYPE.FILL_IN_THE_BLANK:
      return 'Fill in the blank';
    case QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE:
      return 'Fill in the blank SkillScore';
    default:
      return '';
  }
};

const formatContentFormatFromGql = (format) => {
  switch (format) {
    case 'MARKDOWN':
      return RichTextEditor.contentFormat.MARKDOWN;
    case 'HTML':
      return RichTextEditor.contentFormat.HTML;
    default:
      throw new Error('Format not supported: ' + format);
  }
};

const getContentFormat = (format, isNew) => {
  if (!isNew && !format) {
    return defaultExistingExamItemContentFormat;
  } else {
    return format ? formatContentFormatFromGql(format) : DEFAULT_CONTENT_FORMAT;
  }
};

/**
 * High-order function for
 * omitting given `keys` from an object
 * (including its nest object)
 * @param {String[]} keys
 */
function omit(keys) {
  const omittedKeys = new Set(keys);
  return function omitFrom(object) {
    return Object.entries(object).reduce((result, [key, value]) => {
      if (omittedKeys.has(key)) return result;

      if (typeof value === 'object' && value !== null) {
        result[key] = omitFrom(value);
      } else {
        result[key] = value;
      }
      return result;
    }, {});
  };
}

const SectionHeader = ({ warn, ...restProps }) => (
  <Header as="span" color={warn ? 'red' : undefined} {...restProps} />
);

const DangerRow = styled(({ danger, ...rest }) => <Row {...rest} />)`
  ${({ danger }) =>
    danger &&
    css`
      border: 2px solid ${COLOR.danger};
      border-radius: 1rem;
    `};
`;

const SECTION_TYPE = {
  QUESTION: 'QUESTION',
  ANSWER_TITLE: 'ANSWER_TITLE',
  GRADE_ANSWER: 'GRADE_ANSWER',
  ANSWERS: 'ANSWERS',
  SOLUTION: 'SOLUTION',
  HINT: 'HINT',
};

const SolutionArea = ({ onTextEditorChange, solution, examId, contentFormat }) => (
  <Grid columns={1}>
    <Row>
      <Column width={6} textAlign="right">
        <SectionHeader>คำอธิบายเพิ่มเติม</SectionHeader>
      </Column>
      <Column width={10} />
    </Row>
    <Row>
      <Column width={6} textAlign="right" />
      <Column width={10}>
        <RichTextEditor
          onChange={onTextEditorChange}
          id={'solution'}
          value={solution}
          contentFormat={contentFormat}
          returnValueAsString={false}
          customControls={[
            {
              type: RichTextEditor.customControlType.IMAGE_UPLOAD,
              data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
              maxFileSize: quizSimpleMaxImageSize,
            },
          ]}
        />
      </Column>
    </Row>
  </Grid>
);

const QuestionMetaArea = ({
  displayType,
  questionTags,
  questionScore,
  metaWarning,
  onMetaChange,
  isQuizSimpleMode,
}) => (
  <Fragment>
    <DangerRow danger={metaWarning}>
      <Grid columns={2}>
        <Row>
          <Column width={6} textAlign="right">
            Type
          </Column>
          <Column width={10}>{displayType}</Column>
        </Row>
        {!isQuizSimpleMode && (
          <Row>
            <Column width={6} textAlign="right">
              Tag(s)
            </Column>
            <Column width={10}>
              <TagSelectorInput
                type={TagSelectorInput.TYPE.QUESTION}
                allowAdditions={false}
                multiple
                fluid
                value={questionTags}
                onChange={(value) => onMetaChange({ tags: value })}
              />
            </Column>
          </Row>
        )}
        {!isQuizSimpleMode && (
          <Row>
            <Column width={6} textAlign="right">
              Score
              <Popup
                trigger={<SectionHeader warn>*</SectionHeader>}
                content={VALIDATION_WARNING.META}
                size="mini"
              />
            </Column>
            <Column width={10}>
              <Input
                type="number"
                fluid
                value={questionScore}
                onChange={(event) => onMetaChange({ score: event.target.value })}
              />
            </Column>
          </Row>
        )}
      </Grid>
    </DangerRow>
    {metaWarning && (
      <Label color="red" pointing active={true}>
        <Icon name="warning circle" />
        {VALIDATION_WARNING.META}
      </Label>
    )}
  </Fragment>
);

const QuestionArea = ({ questionWarning, onTextEditorChange, question, examId, contentFormat }) => (
  <Fragment>
    <DangerRow danger={questionWarning}>
      <Grid columns={2}>
        <Row>
          <Column width={6} textAlign="right">
            <SectionHeader warn={questionWarning}>Question</SectionHeader>
            <Popup
              trigger={<SectionHeader warn>*</SectionHeader>}
              content={VALIDATION_WARNING.QUESTION}
              size="mini"
            />
          </Column>
          <Column width={10} />
        </Row>
        <Row>
          <Column width={6} textAlign="right" />
          <Column width={10}>
            <RichTextEditor
              onChange={onTextEditorChange}
              id={'question'}
              value={question}
              returnValueAsString={false}
              contentFormat={contentFormat}
              customControls={[
                {
                  type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                  data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                  maxFileSize: quizSimpleMaxImageSize,
                },
              ]}
            />
          </Column>
        </Row>
      </Grid>
    </DangerRow>
    {questionWarning && (
      <Label color="red" pointing active={true}>
        <Icon name="warning circle" />
        {VALIDATION_WARNING.QUESTION}
      </Label>
    )}
  </Fragment>
);

const getAnswerWarningText = (type, gradeAnswer) => {
  switch (type) {
    case QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE:
      return VALIDATION_WARNING.ANSWER.FILL_IN_THE_BLANK_SKILLSCORE;
    case QUESTION_TYPE.FILL_IN_THE_BLANK:
      return gradeAnswer
        ? VALIDATION_WARNING.ANSWER.CLOSED_ENDED_FILL_IN_THE_BLANK
        : VALIDATION_WARNING.ANSWER.OPEN_ENDED_FILL_IN_THE_BLANK;
    default:
      return VALIDATION_WARNING.ANSWER.DEFAULT;
  }
};

const AnswerArea = ({
  type,
  answerWarning,
  answerChoices,
  radioValue,
  examId,
  onChecked,
  onRadioChange,
  onTextEditorChange,
  onAddAnswer,
  onRemoveAnswer,
  contentFormat,
  isQuizSimpleMode,
  fillInTheBlankItems,
}) => (
  <Fragment>
    <DangerRow columns={1} danger={answerWarning}>
      <Grid columns={2}>
        <Row>
          <Column width={6} textAlign="right">
            <SectionHeader warn={answerWarning}>Answer</SectionHeader>
            <Popup trigger={<SectionHeader warn>*</SectionHeader>} size="mini">
              {type === QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE
                ? VALIDATION_WARNING.ANSWER.FILL_IN_THE_BLANK_SKILLSCORE
                : VALIDATION_WARNING.ANSWER.DEFAULT}
            </Popup>
          </Column>
          <Column width={10} />
        </Row>
        {type === QUESTION_TYPE.MULTIPLE_CHOICE ? (
          <MultipleChoice
            isQuizSimpleMode={isQuizSimpleMode}
            onRadioChange={onRadioChange}
            onAnswerChange={(data, index) => onTextEditorChange(SECTION_TYPE.ANSWERS, data, index)}
            onAnswerHintChange={(data, index) => onTextEditorChange(SECTION_TYPE.HINT, data, index)}
            answerChoices={answerChoices}
            value={radioValue}
            examId={examId}
            editorContentFormat={contentFormat}
            maxFileSize={quizSimpleMaxImageSize}
          />
        ) : type === QUESTION_TYPE.MULTIPLE_SELECTION ? (
          <MultipleSelect
            onChecked={onChecked}
            onAnswerChange={(data, index) => onTextEditorChange(SECTION_TYPE.ANSWERS, data, index)}
            answerChoices={answerChoices}
            examId={examId}
            editorContentFormat={contentFormat}
            maxFileSize={quizSimpleMaxImageSize}
          />
        ) : type === QUESTION_TYPE.FILL_IN_THE_BLANK ? (
          <FillInTheBlank
            examId={examId}
            answerChoices={answerChoices}
            onAnswerTitleChange={(data, index) => {
              onTextEditorChange(
                SECTION_TYPE.ANSWER_TITLE,
                data,
                index,
                QUESTION_TYPE.FILL_IN_THE_BLANK
              );
            }}
            onGradeAnswerChange={(data, index) => {
              onTextEditorChange(
                SECTION_TYPE.GRADE_ANSWER,
                data,
                index,
                QUESTION_TYPE.FILL_IN_THE_BLANK
              );
            }}
            onAnswerChange={(data, index) => {
              onTextEditorChange(
                SECTION_TYPE.ANSWERS,
                data,
                index,
                QUESTION_TYPE.FILL_IN_THE_BLANK
              );
            }}
            onAnswerHintChange={(data, index) =>
              onTextEditorChange(SECTION_TYPE.HINT, data, index, QUESTION_TYPE.FILL_IN_THE_BLANK)
            }
            editorContentFormat={contentFormat}
            maxFileSize={quizSimpleMaxImageSize}
            fillInTheBlankItems={fillInTheBlankItems}
          />
        ) : (
          <FillInTheBlankSkillScore
            onAnswerChange={(data, index) => onTextEditorChange(SECTION_TYPE.ANSWERS, data, index)}
            answerChoices={answerChoices}
            examId={examId}
            editorContentFormat={contentFormat}
          />
        )}
        {/* FILL_IN_THE_BLANK IS NOT SUPPORTED MULTIPLE ANSWER FOR NOW */}
        {type !== QUESTION_TYPE.FILL_IN_THE_BLANK && (
          <Row>
            <Column width={6} />
            <Column width={10}>
              <Button primary type="button" size="tiny" circular onClick={onAddAnswer}>
                <Icon name="plus" />
                Add Answer
              </Button>
              <Button icon type="button" floated="right" size="tiny" onClick={onRemoveAnswer}>
                <Icon name="trash" />
              </Button>
            </Column>
            <Column />
          </Row>
        )}
      </Grid>
    </DangerRow>
    {answerWarning && (
      <Label color="red" pointing active={true}>
        <Icon name="warning circle" />
        {getAnswerWarningText(type, fillInTheBlankItems[0]?.gradeAnswer)}
      </Label>
    )}
  </Fragment>
);

const CONTENT_FORMAT_OPTIONS = Object.values(RichTextEditor.contentFormat).map((format) => {
  return {
    key: format,
    text: format,
    value: format,
  };
});

const ContentFormatSelector = ({ value, onChange, readOnly }) => {
  return (
    <Row>
      <Grid columns={1}>
        <Row>
          <Column width={6} />
          <Column width={10} textAlign={'right'}>
            {readOnly ? (
              <Text>{`This question is saved in ${value} format`}</Text>
            ) : (
              <div>
                <Text mb={2}>Select format to save for this question</Text>
                <Dropdown
                  selection
                  value={value}
                  onChange={onChange}
                  options={CONTENT_FORMAT_OPTIONS}
                />
              </div>
            )}
          </Column>
        </Row>
      </Grid>
    </Row>
  );
};

const ActionBar = ({ onCancel, onSave, questionType, question, answerChoices, solution }) => (
  <Grid columns={1}>
    <Row>
      <Column width={6} />
      <Column width={10} textAlign="right">
        {viewAsYAMLConfig?.enabled && (
          <ViewAsYAML
            questionType={questionType}
            question={question}
            answerChoices={answerChoices}
            solution={solution}
          />
        )}
        <Button type="button" circular onClick={onCancel}>
          Cancel
        </Button>
        <Button primary type="submit" circular onClick={onSave}>
          Save
        </Button>
      </Column>
    </Row>
  </Grid>
);

class SingleQuestion extends Component {
  constructor(props) {
    super(props);
    const { question, isNew, isQuizSimpleMode } = this.props;
    const questionContentFormat = _.get(question, 'contentFormat');
    const type = _.get(question, 'type');
    this.state = {
      radioValue: -1,
      answerChoices:
        type === QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE
          ? _.get(question, 'inputs')
          : _.get(question, 'choices'),
      question: _.get(question, 'title'),
      solution: _.get(question, 'solution.value'),
      questionWarning: false,
      answerWarning: false,
      tags: _.get(question, 'tags', []),
      score: _.get(question, 'score', '1'),
      contentFormat: isQuizSimpleMode
        ? RichTextEditor.contentFormat.HTML
        : getContentFormat(questionContentFormat, isNew),
      fillInTheBlankItems: _.get(question, 'fillInTheBlankItems', [
        { answerTitle: '', gradeAnswer: true, answer: '', hint: '' },
      ]),
    };
  }

  componentDidMount() {
    const { question } = this.props;
    const choices = _.get(question, 'choices');
    if (choices) {
      this.setChoice(choices);
    }
  }

  handleSave = () => {
    const { type, questionId, isQuizSimpleMode } = this.props;
    const {
      question,
      solution,
      answerChoices,
      score: questionScore,
      tags,
      contentFormat,
      fillInTheBlankItems,
    } = this.state;

    if (type === QUESTION_TYPE.FILL_IN_THE_BLANK) {
      const data = {
        id: questionId,
        title: convertValueToString(question, contentFormat, { gfm: true }),
        solution: {
          value: convertValueToString(solution, contentFormat, { gfm: true }),
        },
        fillInTheBlankItems: fillInTheBlankItems.map((item) => ({
          answerTitle: item.answerTitle,
          gradeAnswer: item.gradeAnswer,
          answer: item.gradeAnswer ? item.answer : '',
          hint: item.gradeAnswer
            ? convertValueToString(item.hint, contentFormat, { gfm: true })
            : '',
        })),
        type,
      };
      const questionWarning = stripHtml(data.title, '<img>').length === 0;
      const answerWarning = !!data.fillInTheBlankItems.find(
        (item) => item.answerTitle === '' || (item.gradeAnswer && item.answer === '')
      );
      this.setState(
        () => ({
          answerWarning,
          questionWarning,
        }),
        () => {
          if (!questionWarning && !answerWarning) {
            this.props.onSave(data);
          }
        }
      );
    } else {
      const nowAnswerChoices = [...answerChoices];

      const choices = nowAnswerChoices
        .map((choice) => ({
          ...choice,
          value: convertValueToString(choice.value, contentFormat, { gfm: true }),
          hint: convertValueToString(choice.hint, contentFormat, { gfm: true }),
        }))
        .map(omit(['__typename', 'id']))
        .map((choice) => {
          // TODO: SA-128 Add UI for specifying grade option of each blank input
          // This `map` should be able to remove when SA-128 is finished.
          if (type === QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE) {
            return {
              ...choice,
              isAnswer: true,
              gradeOption: {
                isCaseSensitive: true,
              },
            };
          } else {
            return choice;
          }
        });
      const data = {
        id: questionId,
        title: convertValueToString(question, contentFormat, { gfm: true }),
        solution: {
          value: convertValueToString(solution, contentFormat, { gfm: true }),
        },
        choices,
        type,
        score: parseInt('0' + questionScore, 10),
        tags,
        // Convert contentFormat to uppercase to match GQL schema.
        contentFormat: this.state.contentFormat.toUpperCase(),
      };
      if (isQuizSimpleMode) {
        delete data.score;
        delete data.tags;
        delete data.contentFormat;
      }
      log.info('data', data);

      const questionWarning = stripHtml(data.title, '<img>').length === 0;

      const noAnswer = data.choices.find((choice) => choice.isAnswer) === undefined;
      const hasEmptyAnswer =
        data.choices.find((choice) => stripHtml(choice.value, '<img>').length === 0) !== undefined;
      const answerWarning =
        (type !== QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE && data.choices.length < 2) ||
        noAnswer ||
        hasEmptyAnswer;

      const metaWarning = data.score === 0;

      this.setState(
        () => ({
          answerWarning,
          questionWarning,
          metaWarning,
        }),
        () => {
          if (!questionWarning && !answerWarning && !metaWarning) {
            this.props.onSave(data);
          }
        }
      );
    }
  };

  handleTextEditorChange = (type, data, index, questionType) => {
    log.info('SingleQuestion::handleTextEditorChange', type, data, index);
    if (type === SECTION_TYPE.QUESTION) {
      this.setState(
        {
          question: data,
        },
        () => {
          log.info('data', data);
        }
      );
    } else if (type === SECTION_TYPE.SOLUTION) {
      this.setState({
        solution: data,
      });
    } else if (
      type === SECTION_TYPE.GRADE_ANSWER &&
      questionType === QUESTION_TYPE.FILL_IN_THE_BLANK
    ) {
      const nowFillInTheBlankItems = [...this.state.fillInTheBlankItems];
      const nowFillInTheBlankItemValue = { ...nowFillInTheBlankItems[index] };
      nowFillInTheBlankItemValue.gradeAnswer = data;
      nowFillInTheBlankItems[index] = nowFillInTheBlankItemValue;
      this.setState({
        fillInTheBlankItems: nowFillInTheBlankItems,
      });
    } else if (type === SECTION_TYPE.ANSWER_TITLE) {
      const nowFillInTheBlankItems = [...this.state.fillInTheBlankItems];
      const nowFillInTheBlankItemValue = { ...nowFillInTheBlankItems[index] };
      nowFillInTheBlankItemValue.answerTitle = data;
      nowFillInTheBlankItems[index] = nowFillInTheBlankItemValue;
      this.setState({
        fillInTheBlankItems: nowFillInTheBlankItems,
      });
    } else if (type === SECTION_TYPE.ANSWERS) {
      if (questionType === QUESTION_TYPE.FILL_IN_THE_BLANK) {
        const nowFillInTheBlankItems = [...this.state.fillInTheBlankItems];
        const nowFillInTheBlankItemValue = { ...nowFillInTheBlankItems[index] };
        nowFillInTheBlankItemValue.answer = data;
        nowFillInTheBlankItems[index] = nowFillInTheBlankItemValue;
        this.setState({
          fillInTheBlankItems: nowFillInTheBlankItems,
        });
      } else {
        const nowChoices = [...this.state.answerChoices];
        const nowChoiceValue = { ...nowChoices[index] };
        nowChoiceValue.value = data;
        nowChoices[index] = nowChoiceValue;
        this.setState({
          answerChoices: nowChoices,
        });
      }
    } else if (type === SECTION_TYPE.HINT) {
      if (questionType === QUESTION_TYPE.FILL_IN_THE_BLANK) {
        const nowFillInTheBlankItems = [...this.state.fillInTheBlankItems];
        const nowFillInTheBlankItemValue = { ...nowFillInTheBlankItems[index] };
        nowFillInTheBlankItemValue.hint = data;

        nowFillInTheBlankItems[index] = nowFillInTheBlankItemValue;
        this.setState({
          fillInTheBlankItems: nowFillInTheBlankItems,
        });
      } else {
        const nowChoices = [...this.state.answerChoices];
        const nowChoiceValue = { ...nowChoices[index] };
        nowChoiceValue.hint = data;
        nowChoices[index] = nowChoiceValue;
        this.setState({
          answerChoices: nowChoices,
        });
      }
    }
  };

  handleRadioChange = (value) => {
    log.info('SingleQuestion::handleRadioChange::', value);
    const nowChoices = [...this.state.answerChoices];
    if (this.state.radioValue !== -1) {
      const oldChoice = { ...nowChoices[this.state.radioValue] };
      oldChoice.isAnswer = !oldChoice.isAnswer;
      nowChoices[this.state.radioValue] = oldChoice;
    }
    const thisChoice = { ...nowChoices[value] };
    thisChoice.isAnswer = !thisChoice.isAnswer;
    nowChoices[value] = thisChoice;
    this.setState({ answerChoices: nowChoices, radioValue: value });
  };

  handleChecked = (index) => {
    log.info('SingleQuestion::handleChecked::', index);
    const nowChoices = [...this.state.answerChoices];
    if (nowChoices.length !== 0 && nowChoices[index] !== undefined) {
      const thisChoice = { ...nowChoices[index] };
      thisChoice.isAnswer = !thisChoice.isAnswer;
      nowChoices[index] = thisChoice;
    }
    this.setState({
      answerChoices: nowChoices,
    });
  };

  handleAddAnswerButtonClick = () => {
    log.info('SingleQuestion::handleAddAnswerButtonClick');
    const nowAnswerChoices = this.state.answerChoices;
    const newAnswerChoices = [
      ...nowAnswerChoices,
      {
        value: '',
        isAnswer:
          this.props.type === QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE ||
          this.props.type === QUESTION_TYPE.FILL_IN_THE_BLANK
            ? true
            : false,
      },
    ];
    this.setState({ answerChoices: newAnswerChoices });
  };

  handleRemoveAnswerButtonClick = (index) => {
    log.info('SingleQuestion::handleRemoveAnswerButtonClick');

    this.setState((prevState) => {
      const radioValue =
        prevState.radioValue === prevState.answerChoices.length - 1 ? -1 : prevState.radioValue;
      const answerChoices = [...prevState.answerChoices];
      answerChoices.splice(answerChoices.length - 1);
      return { radioValue, answerChoices };
    });
  };

  handleMetaChange = (examMeta) => {
    const { score, tags } = examMeta;
    if (score !== undefined) this.setState({ score });
    if (tags !== undefined) this.setState({ tags });
  };

  setChoice(choices) {
    log.info('SingleQuestion::setChoice::', choices);
    this.setState({
      radioValue: _.findIndex(choices, (a) => a.isAnswer === true),
    });
  }

  handleContentFormatChange = (e, data) => {
    this.setState({
      contentFormat: data.value,
    });
  };

  render() {
    const { onCancel, type, examId, readOnly, isNew, isQuizSimpleMode } = this.props;

    const {
      answerChoices,
      radioValue,
      answerWarning,
      solution,
      questionWarning,
      question,
      metaWarning,
      tags,
      score,
      contentFormat,
      fillInTheBlankItems,
    } = this.state;

    const displayType = getDisplayType(type);
    return (
      <Box>
        <Grid columns={1}>
          <QuestionMetaArea
            displayType={displayType}
            questionTags={tags}
            questionScore={score}
            onMetaChange={this.handleMetaChange}
            metaWarning={metaWarning}
            isQuizSimpleMode={isQuizSimpleMode}
          />
          <QuestionArea
            questionWarning={questionWarning}
            question={question}
            examId={examId}
            onTextEditorChange={(data) => this.handleTextEditorChange(SECTION_TYPE.QUESTION, data)}
            contentFormat={contentFormat}
          />
          <AnswerArea
            type={type}
            answerWarning={answerWarning}
            answerChoices={answerChoices}
            radioValue={radioValue}
            examId={examId}
            onChecked={this.handleChecked}
            onRadioChange={this.handleRadioChange}
            onTextEditorChange={this.handleTextEditorChange}
            onAddAnswer={this.handleAddAnswerButtonClick}
            onRemoveAnswer={this.handleRemoveAnswerButtonClick}
            contentFormat={contentFormat}
            isQuizSimpleMode={isQuizSimpleMode}
            fillInTheBlankItems={fillInTheBlankItems}
          />
          {!disableExamSolution && (
            <SolutionArea
              solution={solution}
              examId={examId}
              onTextEditorChange={(data) =>
                this.handleTextEditorChange(SECTION_TYPE.SOLUTION, data)
              }
              contentFormat={contentFormat}
            />
          )}
          {!isQuizSimpleMode && (
            <ContentFormatSelector
              readOnly={readOnly || !isNew}
              value={contentFormat}
              onChange={this.handleContentFormatChange}
            />
          )}
          {!readOnly && (
            <ActionBar
              onCancel={onCancel}
              onSave={this.handleSave}
              questionType={type}
              question={question}
              answerChoices={answerChoices}
              solution={solution}
            />
          )}
        </Grid>
      </Box>
    );
  }
}

SingleQuestion.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  question: PropTypes.shape({
    id: PropTypes.string,
    solution: PropTypes.shape({
      value: PropTypes.string,
    }),
    title: PropTypes.string,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        isAnswer: PropTypes.bool,
      })
    ),
  }),
  examId: PropTypes.string,
  isQuizSimpleMode: PropTypes.bool,
  readOnly: PropTypes.bool,
};

SingleQuestion.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  question: {
    id: null,
    solution: { value: '' },
    title: '',
    choices: [],
  },
  examId: '',
};

export default SingleQuestion;
