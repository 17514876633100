import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown as BaseDropdown, Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

import { EXAM_SCORE_TYPE } from './constants';

const Dropdown = styled(BaseDropdown)`
  &.ui.selection.dropdown {
    min-width: 84px;
  }
`;

const validate = ({ score, type }) => {
  if (type === EXAM_SCORE_TYPE.PERCENT && score >= 100) {
    return { value: 100, type };
  } else if (score < 0) {
    return { value: 0, type };
  } else if (score >= 10000) {
    return { value: 10000, type };
  }
  return { value: score, type };
};

const PassingCriteriaInput = ({ value: passingCriteria, typeOptions, readOnly, onChange }) => {
  const { type = EXAM_SCORE_TYPE.SCORE, value: score } = passingCriteria;

  const handleOnChange = ({ score, type }) => {
    onChange(
      {},
      {
        value: validate({ score, type }),
      }
    );
  };

  return (
    <Flex flexWrap="wrap" m={-2}>
      <Box p={2} width={['100%', '50%', '20%']}>
        <Input
          type="number"
          name="score"
          placeholder={type === EXAM_SCORE_TYPE.SCORE ? 'คะแนน' : 'เปอร์เซ็นต์'}
          value={`${score}`}
          onChange={(_, { value }) => handleOnChange({ score: parseInt(value), type })}
          readOnly={readOnly}
        />
      </Box>
      <Box p={2} width={['100%', '50%', '25%']}>
        <Dropdown
          name="type"
          selection
          compact
          options={typeOptions}
          onChange={(_, { value }) => handleOnChange({ score, type: value })}
          value={type}
          disabled={readOnly}
        />
      </Box>
    </Flex>
  );
};

PassingCriteriaInput.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.number,
    type: PropTypes.oneOf(Object.values(EXAM_SCORE_TYPE)),
  }),
  typeOptions: PropTypes.array,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(PassingCriteriaInput);
