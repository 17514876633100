import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import ConfirmationButton from 'Components/ConfirmationButton';
import { deleteExamItem } from 'GraphQL/mutation/Exam.mutation';

const DeleteQuestionButton = ({ onCompleted, examId, examItemId }) => {
  const [deleteQuestion, { loading }] = useMutation(deleteExamItem, {
    variables: { examId, examItemId },
    onCompleted: onCompleted,
  });

  return (
    <ConfirmationButton
      buttonStyle={{
        basic: true,
        size: 'medium',
        color: 'red',
        disabled: loading,
      }}
      onConfirm={deleteQuestion}
      headerText={`Confirm Delete`}
      confirmText="Confirm"
      contentText={'Do you want to delete this question?'}
    >
      {`Delete`}
    </ConfirmationButton>
  );
};

DeleteQuestionButton.propTypes = {
  onCompleted: PropTypes.func,
  examId: PropTypes.string,
  examItemId: PropTypes.string,
};

DeleteQuestionButton.defaultProps = {
  onCompleted: () => {},
};

export default DeleteQuestionButton;
