import { useQuery } from '@apollo/client';

import { PRODUCT_TYPE, SKU_TYPE } from 'Routes/Products/Domains/constants';
import {
  getBooks,
  getBundles,
  getListSubscriptions,
  getOnlineCourses,
  getWorkshopBatches,
} from 'Routes/Products/GraphQL/query/Products.query';
import { useAppConfig } from 'Util/hoc/withAppConfig';

const useProductListData = () => {
  const config = useAppConfig();
  const allowProductsConfig = config?.salesV2?.discount?.enabledProducts ?? [
    PRODUCT_TYPE.SUBSCRIPTION,
  ];

  const {
    data: onlineCourses,
    loading: onlineCoursesLoading,
    error: onlineCoursesError,
  } = useQuery(getOnlineCourses, {
    variables: {
      paging: { currentPage: 0, pageSize: 1000 },
      search: { SKUType: SKU_TYPE.VIRTUAL },
      order: { field: 'title', type: 'ASC' },
    },
    skip: !allowProductsConfig.includes(PRODUCT_TYPE.ONLINE_COURSE),
  });

  const { data: books, loading: booksLoading, error: booksError } = useQuery(getBooks, {
    variables: {
      paging: { currentPage: 0, pageSize: 1000 },
      search: {},
      order: { field: 'title', type: 'ASC' },
    },
    skip: !allowProductsConfig.includes(PRODUCT_TYPE.BOOK),
  });

  const { data: subscriptions, loading: subscriptionLoading, error: subscriptionError } = useQuery(
    getListSubscriptions,
    {
      variables: {
        paging: { currentPage: 0, pageSize: 1000 },
        search: {},
        order: { field: 'title', type: 'ASC' },
      },
      skip: !allowProductsConfig.includes(PRODUCT_TYPE.SUBSCRIPTION),
    }
  );

  const { data: bundles, loading: bundlesLoading, error: bundlesError } = useQuery(getBundles, {
    variables: {
      paging: { currentPage: 0, pageSize: 1000 },
      search: {},
      order: { field: 'title', type: 'ASC' },
    },
    skip: !allowProductsConfig.includes(PRODUCT_TYPE.BUNDLE),
  });

  const {
    data: workshopBatches,
    loading: workshopBatchesLoading,
    error: workshopBatchesError,
  } = useQuery(getWorkshopBatches, {
    variables: {
      paging: { currentPage: 0, pageSize: 1000 },
      search: {},
      order: { field: 'title', type: 'ASC' },
    },
    skip: !allowProductsConfig.includes(PRODUCT_TYPE.WORKSHOP),
  });

  if (
    bundlesLoading ||
    subscriptionLoading ||
    booksLoading ||
    onlineCoursesLoading ||
    workshopBatchesLoading
  ) {
    return {
      loading: true,
      data: [],
      error: null,
    };
  }

  return {
    onlineCourses: onlineCourses?.sfBoProductOnlineCourses?.onlineCourses ?? [],
    subscriptions: subscriptions?.sfBoProductSubscriptions?.subscriptions ?? [],
    books: books?.sfBoProductBooks?.books ?? [],
    bundles: bundles?.sfBoProductBundles?.bundles ?? [],
    workshopBatches: workshopBatches?.sfBoProductWorkshopBatches?.batches ?? [],
    loading: false,
    error: {
      onlineCourses: onlineCoursesError,
      books: booksError,
      subscriptions: subscriptionError,
      bundles: bundlesError,
      workshops: workshopBatchesError,
    },
  };
};

export default useProductListData;
