import * as mockEngine from './mockAdapter';
import * as byteArkAdapter from './byteArkAdapter';

export const VIDEO_TYPE = {
  YOUTUBE: 'YOUTUBE',
  BYTEARK: 'BYTEARK',
};

export const uploads = async (
  videoType,
  files,
  metadata,
  handleOnUploadProgress,
  byteArkSdkUpload
) => {
  switch (videoType) {
    case VIDEO_TYPE.BYTEARK:
      return await byteArkAdapter.uploads(
        files,
        metadata,
        handleOnUploadProgress,
        byteArkSdkUpload
      );
    default:
      return await mockEngine.uploads(files, metadata, handleOnUploadProgress);
  }
};
