import defaults from 'lodash/defaults';
import log from 'loglevel';

import { EXAM_TYPE } from '../Components/Examination/constant';
import { studentInterestOptions, teacherPositionOptions } from '../Routes/LSP/Profiles/constants';
import theme from '../Components/Theme';

let config = null;

const defaultConfig = {
  userApi: 'http://localhost:5001/api/users',
  googleLoginURL: 'http://localhost:5001/api/login/google',
  graphApi: 'http://localhost:4000/graphql',
  gateWayApi: 'http://localhost:4000/api',
  userStorageApi: `http://localhost:3005/api/storage`,
  userStorageContainer: 'batchEnrollment',
  userStorageProvider: 'filesystem',
  backofficeStorageApi: `http://localhost:3000/api/storage`,
  youtubeApi: 'https://www.googleapis.com/youtube/v3',
  wistiaApi: 'https://fast.wistia.com/oembed',
  enabledWistiaApi: false,
  vimeoApi: 'https://vimeo.com/api/oembed.json',
  backofficeV1Url: 'http://localhost:8008',
  youtubeApiKey: 'AIzaSyAgFvVf2Vds34LlTesLSyq2pFHrI19v2XE',
  enabledByteArkApi: true,
  byteArkStreamProjectKey: 'migration-ibvw94',
  enabledByteArkStream: false,
  enabledVimeoApi: false,
  byteArkApi: 'http://skooldio.qoder.byteark.com/apps',
  byteArkNamespace: '9nijCsnl37bpWa5z9Uvp',
  byteArkProjectId: 28,
  byteArkSdkUpload: true,
  courseMaterialAllowContentTypes: ['image/gif', 'image/png', 'image/jpeg', 'application/pdf'],
  courseMaterialAllowContentSize: 20,
  courseLevels: [
    { value: '0', text: 'Basic' },
    { value: '1', text: 'Advance' },
  ],
  videoUploadMaxItems: 25,
  allowedExamType: [EXAM_TYPE.POSTTEST, EXAM_TYPE.PRETEST, EXAM_TYPE.EXAM],
  featuredRoutes: ['COURSES_V2', 'USER_MANAGEMENT'],
  disableExamSolution: false,
  allowedManageUser: ['userActions', 'userRoles', 'userEnrollments', 'userTeams'],
  log: 'error',
  theme,
  lineHideColumns: false,
  externalCourseOwner: {
    enabled: false,
    courseOwners: {},
  },
  certificateTypes: ['SKOOLDIO'],
  certificateItemTypes: ['CERTIFICATE', 'EXAM', 'COURSE', 'COURSE_V1'],
  /**
   * These two configs accept only two values: markdown, html
   */
  defaultExamEditorContentFormat: 'markdown',
  defaultExistingExamItemContentFormat: 'html',
  workshopUrlPrefix: 'https://www.skooldio.com/workshops/',
  googleMapsAPIKey: 'AIzaSyDkc0et3jRc3NkPgRAuEj9GqJOwjnM9lvg',
  quizSimpleMaxImageSize: 0.8,
  // LSP Config
  lsp: {
    studentInterestOptions,
    teacherPositionOptions,
  },
  useNewUserSearch: true,
  batchEnrollmentTemplateUrl: '',
  batchEnrollment: {
    refCodeOptions: [
      {
        key: 'SALES_B2B',
        value: 'SALES_B2B',
        text: 'สำหรับการขาย B2B',
        additionalText: 'กรอกเลข INV',
        validation: /^(INV[0-9]+)$/,
      },
      {
        key: 'COURSE_CREATION',
        value: 'COURSE_CREATION',
        text: 'สำหรับการรีวิวคอร์ส, การทำคอร์ส',
      },
      {
        key: 'SALES_BUNDLE',
        value: 'SALES_BUNDLE',
        text: 'Enroll คอร์สเพิ่มสำหรับ Bundle, Bootcamp หรือ Workshop',
        additionalText: 'กรอกชื่อ Bootcamp, Bundle หรือ Workshop',
      },
      {
        key: 'PRESALES',
        value: 'PRESALES',
        text: 'Presales (เพื่องานขาย,ลองให้ใช้ระบบ,ลองเรียนเพื่อพิจารณาคอร์ส)',
        additionalText: 'กรอกชื่อลูกค้า',
      },
      {
        key: 'STUDENT',
        value: 'STUDENT',
        text: 'Enroll ให้นักเรียน นิสิต นักศึกษา สำหรับการสอน',
        additionalText: 'กรอกชื่อวิชา หรือโครงการ',
      },
      {
        key: 'NEW_JOINER',
        value: 'NEW_JOINER',
        text: 'พนักงานใหม่',
        additionalText: 'กรอกเดือนที่เริ่มงาน (YYYY-MM)',
      },
      {
        key: 'SUPPORT',
        value: 'SUPPORT',
        text: 'สำหรับการแก้ปัญหาระบบ',
        additionalText: 'กรอกเลข Ticket',
      },
      {
        key: 'OTHERS',
        value: 'OTHERS',
        text: 'อื่นๆ',
        additionalText: 'กรอกเหตุผล',
      },
    ],
  },
  salesV2: {
    discount: {
      enabledProducts: ['WORKSHOP', 'ONLINE_COURSE', 'SUBSCRIPTION', 'BOOK', 'BUNDLE'],
    },
  },
  products: {
    /**
     * Disable ordering field in Online course, Book, Subscription, Bundle and Online Course Batch overview page
     * IMPORTANT NOTE
     * should update storefront to version that implemented sellingAt field in database before set this to TRUE
     */
    disabledOrdering: false,
    enabledProducts: [
      'BUNDLE',
      'WORKSHOP',
      'ONLINE_COURSE',
      'SUBSCRIPTION',
      'COLLECTION',
      'BOOK',
      'ONLINE_COURSE_BATCH',
    ],
    WORKSHOP: {
      categoryNames: {
        CATEGORY_1: 'CATEGORY_1',
        CATEGORY_2: 'CATEGORY_2',
        CATEGORY_3: 'CATEGORY_3',
        CATEGORY_4: 'CATEGORY_4',
      },
    },
    WORKSHOP_BATCH: {
      categoryNames: {
        CATEGORY_1: 'CATEGORY_1',
        CATEGORY_2: 'CATEGORY_2',
        CATEGORY_3: 'CATEGORY_3',
        CATEGORY_4: 'CATEGORY_4',
      },
    },
    ONLINE_COURSE: {
      categoryNames: {
        CATEGORY_1: 'CATEGORY_1',
        CATEGORY_2: 'CATEGORY_2',
        CATEGORY_3: 'CATEGORY_3',
        CATEGORY_4: 'CATEGORY_4',
      },
      // skuStatusOptions config support other products also.
      skuStatusOptions: [
        {
          key: 'VISIBLE/SELLING',
          value: 'VISIBLE/SELLING',
          text: 'Visible / Selling',
        },
        {
          key: 'VISIBLE/COMING_SOON',
          value: 'VISIBLE/COMING_SOON',
          text: 'Visible / Coming Soon',
        },
        {
          key: 'VISIBLE/SOLDOUT',
          value: 'VISIBLE/SOLDOUT',
          text: 'Visible / Cannot Sell',
        },
        {
          key: 'UNLISTED/SELLING',
          value: 'UNLISTED/SELLING',
          text: 'Unlisted / Selling',
        },
        {
          key: 'UNLISTED/COMING_SOON',
          value: 'UNLISTED/COMING_SOON',
          text: 'Unlisted / Coming Soon',
        },
        {
          key: 'VISIBLE/SOLDOUT',
          value: 'VISIBLE/SOLDOUT',
          text: 'Unlisted / Cannot Sell',
        },
        {
          key: 'UNLISTED/UNPUBLISHED',
          value: 'UNLISTED/UNPUBLISHED',
          text: 'Unpublished',
        },
      ],
      /** Enable edit and display comingSoonText on online course that status coming soon  */
      enabledComingSoonText: false,
      /** Enable edit and display prescreenFormURLText on online course that status visible and selling */
      enabledPrescreenFormURLText: false,
    },
  },
  additionalDataLabels: {
    additional1: { value: 'Custom_Additional1', isSearchAble: true },
    additional2: { value: 'Custom_Additional2', isSearchAble: true },
    additional3: { value: 'Custom_Additional3', isSearchAble: true },
    additional4: { value: 'Custom_Additional4', isSearchAble: true },
    additional5: { value: 'Custom_Additional5', isSearchAble: false },
    additional6: { value: 'Custom_Additional6', isSearchAble: false },
    additional7: { value: 'Custom_Additional7', isSearchAble: false },
    additional8: { value: 'Custom_Additional8', isSearchAble: false },
    additional9: { value: 'Custom_Additional9', isSearchAble: false },
    additional10: { value: 'Custom_Additional10', isSearchAble: true },
  },
  fileImport: {
    enabledShipmentTrackingShipmentProvider: false,
  },
  shipment: {
    shipmentProviders: [
      'FLASH_EXPRESS',
      'KERRY',
      'TP_EMS',
      'TP_REG',
      'BEST',
      'DHL',
      'ALP',
      'NINJA',
      'JT',
      'SCG',
      'NIM',
      'OTHER',
      'OFFICIAL',
    ],
  },
  exam: {
    allowGeneratorTypeByUser: false,
    enabledFixedType: false,
    enabledOfficialExam: false,
    enableQuestionFieldConfigurable: false,
    question: {
      tags: [],
    },
    enabledQuestionTypes: ['MULTIPLE_CHOICE', 'FILL_NUMBER'],
  },
  appShell: {
    brandLogoImageSrc: '/assets/skooldio_logo.svg',
    // For png logo.
    // brandLogoImageSrcSet: {
    //   '1x': '/assets/skooldio_logo.png',
    //   '2x': '/assets/skooldio_logo@2x.png',
    // },
  },
  additionalDataIndex: [1, 5, 6, 7, 8, 9, 10],
  enabledResetPassword: false,
  enabledEmailLogin: true,
  enabledGmailLogin: false,
  enabledSectionGroup: false,
  allowedSubsectionTypes: [],
  allowedDefaultVideoCoverImage: true,
  enabledAppPermission: false,
  enabledContentTeam: false,
  richTextEditor: {
    uploadedImageWidth: '300',
  },
  announcement: {
    enabledNotificationAnnouncement: false, // Now this feature available in Skooldio back office
    enabledBannerAnnouncementRoute: false,
    enabledPopUpAnnouncementRoute: false, // This feature available in Anywhere back office
    enabledAnnouncementFileImportRoute: false,
    announcementStorageContainer: 'announcement',
    announcementStorageProvider: 'filesystem',
  },
  enabledCourseForumConfig: false,

  /** For features under /courses/v2 */
  courses: {
    /** Disable Course level instructors, since it's moved to Product - OC level instead */
    disableInstructors: false,

    /** Enable per course color on OCD and Classroom (originally enhanced by DegreePlus - should be deprecated?) */
    enableCourseColor: true,

    /** Enable per course logo on Classroom (originally enhanced by DegreePlus - should be deprecated?) */
    /** Enable video controller field on Backoffice's Courses page (originally enhanced by DegreePlus) */
    enableCourseSetting: false,
    enableAssetsEditor: true,

    /** Enable course duplication action on Backoffice's Courses page (originally enhanced by DegreePlus) */
    enableCourseDuplication: false,

    /** Enable assignments grading route on Backoffice's Courses page (originally enhanced by DegreePlus) */
    enableAssignmentSubsection: true,

    lessons: {
      /** Enable Mindstamp asset on subsection lesson on Backoffice's Courses page (originally enhanced by Anywhere) */
      enableMindstampAsset: false,
    },

    announcement: {
      /** Enables course announcement (originally implemented for DegreePlus) */
      enable: false,

      // Enable push notification
      enablePushNotification: false,

      // Allow attaching course cover image with push notification
      enablePushNotificationCoverImage: true,

      // Allow customizing image attached with push notification
      enablePushNotificationCustomImage: false,

      enableEmailNotification: false,
    },
  },

  productPreview: {
    enabledProductPreview: false,
    platformURL: 'https://coldbrew.soylatte.skooldio.com/',
  },

  htmlMeta: {
    title: 'Skooldio Backoffice',
    faviconPath: '/favicon',
    // deprecated please use `faviconPath` instead
    faviconURL: '/favicon/favicon.ico',
  },

  uploadVideoCompletedWaitTime: 2000,

  ERPSystem: {
    enabled: false,
    SKUCodeValidation: ['[A-Z][0-9]{5,}', '[A-Z][0-9]{5,}-[V]?[0-9]'],
  },

  discountCode: {
    // enabled to add/edit reference code for discount code
    enabledRefCode: false,
    refCodeRegEx: undefined,
    skooldioValidateBCode: false,
  },

  personalizedDiscountCode: {
    refCodeOptions: [
      {
        key: 'NEW_USER',
        value: 'NEW_USER',
        text: 'NEW_USER',
      },
    ],
    // enabled to add/edit reference code for personalized discount code
    enabledRefCode: false,
  },

  allowedIframeSourcesRegex: [
    /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/, // youtube
    /^(http(s)?:\/\/)?((w){3}.)?codesandbox\.io\/embed\/.+/, // codesandbox
    /^(http(s)?:\/\/)?((w){3}.)?streamyard.com?\/.+/, // streamyard
  ],
};
/**
 *
 * @returns {typeof defaultConfig} appConfig,
 */
function getConfig() {
  if (config === null) {
    /**
     * This will merge only 1 level deep, it will not recursivly merge values.
     * Previously, it was defaultsDeep, which array values will be merged instead
     */
    // @ts-expect-error
    config = defaults({}, window.appConfig, defaultConfig);
    log.setLevel(config.log);
    log.debug(config);
  }
  return config;
}

export { defaultConfig };

export default getConfig;
