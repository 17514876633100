import getConfig from 'Util/Config';

import { PRODUCT_TYPE } from './constants';

export const filterListedColumns = (columns) => {
  const { salesUnlistedColumns } = getConfig();
  if (salesUnlistedColumns && salesUnlistedColumns?.length !== 0) {
    return columns.filter((col) => !salesUnlistedColumns.includes(col?.Header));
  }
  return columns;
};

export const calcSalesExclVAT = (priceInclVAT, discountIncVAT, vat) => {
  return priceInclVAT - discountIncVAT - vat;
};

export const calcSalesExclWHT = (priceInclVAT, discountIncVAT, withholdingTax) => {
  return priceInclVAT - discountIncVAT - withholdingTax;
};

export const calcRemainDiscountCode = (oldTotal, newTotal, oldRemain) => {
  if (isNaN(oldTotal) || isNaN(newTotal) || isNaN(oldRemain)) {
    return 0;
  }
  const usedCode = oldTotal - oldRemain;
  return newTotal - usedCode;
};

export const genProductTypesSKUText = (productType) => {
  switch (productType) {
    case PRODUCT_TYPE.ONLINE_COURSE:
      return 'ALL COURSES';
    case PRODUCT_TYPE.BUNDLE:
      return 'ALL BUNDLES';
    default:
      return '';
  }
};
