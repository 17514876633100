import React, { Fragment, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tab } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import { getSyllabus } from 'GraphQL/LSP/query/Syllabus.query';
import { updateSyllabusMutation } from 'GraphQL/LSP/mutation/Syllabus.mutation';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import SyllabusDetails from '../../Components/SyllabusDetails';
import SyllabusModules from '../../Components/SyllabusModules';

const SyllabusDetailsRoute = (props) => {
  const id = _.get(props, 'match.params.id');
  const { loading, error, data: queryData } = useQuery(getSyllabus, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [
    updateSyllabus,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(updateSyllabusMutation);

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={false}
        response={_.get(mutationResponse, 'code')}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <SyllabusDetails
        initialData={queryData && queryData.boSyllabus}
        onSubmit={(form) => {
          updateSyllabus({
            variables: { id, syllabusData: form },
          });
        }}
      />
      <SyllabusModules syllabusId={id} />
    </Fragment>
  );
};

SyllabusDetailsRoute.propTypes = {};

export default SyllabusDetailsRoute;
