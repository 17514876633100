import React from 'react';
import PropTypes from 'prop-types';

const HTMLView = ({ rawContent, children, className }) => (
  <div dangerouslySetInnerHTML={{ __html: rawContent || children }} className={className} /> // eslint-disable-line
);

HTMLView.propTypes = {
  rawContent: PropTypes.string.isRequired,
  className: PropTypes.string,
};

HTMLView.defaultProps = {
  rawContent: '',
};

export default HTMLView;
