import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Text } from 'Components/Base';

import Card from '../Card/Card';
import TwoColumnGrid from '../TwoColumnGrid/TwoColumnGrid';

const formatDate = (date) => (date ? dayjs(date).format('DD/MM/YYYY HH:mm:ss') : null);

function AssignmentSubmissionLog({ submittedDate, reviewedDate, ...restProps }) {
  return (
    <Card {...restProps}>
      <TwoColumnGrid>
        {reviewedDate && (
          <>
            <Text>Reviewed</Text>
            <Text px={3}>{formatDate(reviewedDate)}</Text>
          </>
        )}
        <Text>Submitted</Text>
        <Text px={3}>{formatDate(submittedDate)}</Text>
      </TwoColumnGrid>
    </Card>
  );
}

AssignmentSubmissionLog.propTypes = {
  submittedDate: PropTypes.string,
  reviewedDate: PropTypes.string,
};

export default AssignmentSubmissionLog;
