import gql from 'graphql-tag';

export const fileImportJobs = gql`
  query boFileImportJobs(
    $search: BOSearchFileImportJobInput!
    $paging: PageInput!
    $order: OrderInput!
  ) {
    boFileImportJobs(search: $search, paging: $paging, order: $order) {
      total
      fileImportJobs {
        id
        type
        file {
          key
          bucket
        }
        originalFilename
        status
        createdAt
        arg1
        arg2
        arg3
        error
        result {
          resultSummaries {
            # rowNo
            # property
            # constraints
            # target
            value
            type
          }
          validationErrors {
            # rowNo
            # property
            # constraints
            # target
            value
          }
          file {
            key
            bucket
          }
        }
      }
    }
  }
`;

export const fileImportJob = gql`
  query boFileImportJob($id: ID!) {
    boFileImportJob(id: $id) {
      id
      type
      file {
        key
        bucket
      }
      originalFilename
      status
      createdAt
      arg1
      error
      result {
        resultSummaries {
          rowNo
          property
          constraints
          target
          value
          type
          status
          info
        }
        validationErrors {
          rowNo
          property
          constraints
          target
          value
        }
        file {
          key
          bucket
        }
      }
    }
  }
`;
