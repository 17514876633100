export const getRouteInfo = (props) => {
  const { match, product, courseImageValidationLogic } = props;
  const { params } = match || {};
  const { courseId, courseVersion } = params || {};
  return { courseId, product, version: courseVersion, match, courseImageValidationLogic };
};

export const getCourseSection = (userAssignmentCourseSummary = {}, sectionId) => {
  if (!userAssignmentCourseSummary?.sections) return null;
  return userAssignmentCourseSummary.sections.find((section) => section.id === sectionId);
};

export const getUserAssignmentReport = (
  userAssignmentCourseSummary = {},
  sectionId,
  subsectionId
) => {
  const section = getCourseSection(userAssignmentCourseSummary, sectionId);
  if (!section?.userAssignmentReports) return null;
  return section.userAssignmentReports.find((report) => report.sourceCourseItemId === subsectionId);
};
