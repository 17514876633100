import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { useSearchFilter } from '../Utils';
import { GET_ASSIGNED_TEAMS_BY_USER } from '../Graphql/query/Team.query';

const BASIC_COLUMN = {
  ID: {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 150,
  },
  TEAM: {
    Header: 'Team',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 250,
  },
  DESCRIPTION: {
    Header: 'Description',
    accessor: 'description',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
};

const DEFAULT_USER_COLUMNS = Object.values(BASIC_COLUMN);
const SearchTeamUserList = React.forwardRef(({ userId, columns }, ref) => {
  const {
    onSortedChange,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    order,
    paging,
    search,
  } = useSearchFilter();

  const resolveData = useCallback((data) => data?.assignedTeamsByUser?.teams, []);

  return (
    <QueryTable
      ref={ref}
      type={'assignedTeamsByUser'}
      columns={columns}
      query={GET_ASSIGNED_TEAMS_BY_USER}
      resolveData={resolveData}
      getSearchVariables={() => ({
        userId,
        search,
        order,
        paging,
      })}
      onSearchChange={onSearchChange}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortedChange={onSortedChange}
      search={search}
      paging={paging}
      order={order}
    />
  );
});

SearchTeamUserList.displayName = 'SearchTeamUserList';
SearchTeamUserList.propTypes = {
  columns: PropTypes.object,
  userId: PropTypes.string,
};

SearchTeamUserList.defaultProps = {
  columns: DEFAULT_USER_COLUMNS,
};

export { BASIC_COLUMN, DEFAULT_USER_COLUMNS };
export default SearchTeamUserList;
