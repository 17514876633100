import React, { Fragment, Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { Flex } from 'Components/Base';

import apolloClient from '../../../GraphQL';
import QueryTable from '../../../GraphQL/util/QueryTable';
import { CourseSelectorInput } from '../../../Routes/Reports/Components/SelectorInput';
import ConfirmationButton from '../../../Components/ConfirmationButton';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';
import { discounts } from '../../../GraphQL/query/Sales.query';
import Text from '../../../Components/Base/Text';
import { deleteDiscount } from '../../../GraphQL/mutation/Sales.mutation';

import DiscountModal from './DiscountModal';

const DiscountColumns = (handleDelete, variables) => [
  {
    Header: 'Promo Code',
    accessor: 'code',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'มูลค่า',
    accessor: 'discount',
    width: 150,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: 'หน่วย',
    accessor: 'discountUnit',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: 'FIX', value: 'FIX', text: 'FIX' },
      { key: 'PERCENT', value: 'PERCENT', text: 'PERCENT' },
    ],
    isSearchAble: true,
  },
  {
    Header: 'คอร์ส',
    accessor: 'courseReferIds',
    width: 100,
    type: COLUMN_TYPE.CUSTOM,
    CustomSearchComponent: (props) => (
      <CourseSelectorInput
        {...props}
        variables={{
          search: { version: CourseSelectorInput.COURSE_VERSION.SKOOLDIO_V1 },
          paging: { currentPage: 0, pageSize: 1000 }, // TODO: Workaround for Input not searchable
          order: { field: 'id', type: 'ASC' },
        }}
        placeholder="Select Courses"
      />
    ),
    Cell: ({ original }) => {
      const courseReferIds = original.courseReferIds ? original.courseReferIds.join(', ') : '';
      return courseReferIds;
    },
    isSearchAble: true,
  },
  {
    Header: 'วันที่เริ่มต้น',
    accessor: 'startDate',
    width: 100,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'วันหมดอายุ',
    accessor: 'endDate',
    width: 100,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'วันหมดอายุคอร์ส',
    accessor: 'productExpiryDate',
    width: 100,
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Description',
    accessor: 'discountText',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'สกุลเงิน',
    accessor: 'currency',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [{ key: 'THB', value: 'THB', text: 'THB' }],
    isSearchAble: true,
  },
  {
    Header: 'จำนวนตั้งต้น',
    accessor: 'total',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: 'จำนวนคงเหลือ',
    accessor: 'remain',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: 'สำหรับลูกค้า (บุคคล หรือ องค์กร)',
    accessor: 'customerType',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    isSearchAble: false,
    // enumOptions: [
    //   { key: 'ALL', value: 'ALL', text: 'ลูกค้าทุกประเภทสามารถใช้ได้' },
    //   { key: 'INDIVIDUAL', value: 'INDIVIDUAL', text: 'ลุกค้าบุคคลเท่านั้น' },
    //   { key: 'CORPORATE', value: 'CORPORATE', text: 'ลูกค้าองค์กรเท่านั้น' },
    // ],
  },
  {
    Header: 'จำนวนลูกค้าขั้นต่ำ',
    accessor: 'minimumQuantity',
    width: 50,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: false,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: ({ original }) => {
      const discountId = original.id;
      return (
        <Fragment>
          <Button.Group>
            <DiscountModal
              isEdit
              variables={variables}
              discountId={discountId}
              data={original}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="edit" />
                </Button>
              }
            />
            <ConfirmationButton
              headerText={`Delete?`}
              contentText={'This will be permanently deleted from the system.'}
              confirmText={'Delete'}
              onConfirm={() => {
                handleDelete(discountId);
              }}
            >
              <Icon name="trash alternate outline" />
            </ConfirmationButton>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class DiscountCodeSales extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'code', type: 'ASC' },
  };
  mutateDiscount = (mutation, discountId) => {
    const { search, paging, order } = this.state;
    apolloClient
      .mutate({
        mutation,
        variables: { discountId },
        refetchQueries: [
          {
            query: discounts,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  handleDelete = (discountId) => {
    this.mutateDiscount(deleteDiscount, discountId);
  };
  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Discount code</Text.Header>
          <DiscountModal
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type="discounts"
          columns={DiscountColumns(this.handleDelete, variables)}
          query={discounts}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default DiscountCodeSales;
