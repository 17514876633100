import _ from 'lodash';
import axios from 'axios';

import getConfig from '../../Config';
import apolloClient from '../../../GraphQL';
import {
  createMultipleVideos,
  updateMultipleVideos,
  deleteMultipleVideos,
  generateVideoAccessToken,
  generateVideoSignedUrl,
  byteArkStreamCreateMultipleVideos,
  byteArkStreamDeleteMultipleVideos,
  byteArkStreamGenerateVideoAccessToken,
  byteArkStreamGenerateVideoSignedUrl,
} from '../../../GraphQL/mutation/Video.mutation';
import { VIDEO_TYPE } from '../VideoAdapter';
import { courseOwnerEnabled, courseOwners } from '../../CourseOwner';

const {
  byteArkProjectId: PROJECT_ID,
  enabledByteArkStream: enabledStreamApi,
  byteArkStreamProjectKey,
} = getConfig();

const BYTEARK_CHANNEL = courseOwnerEnabled ? courseOwners : {};

const handleErrors = async (error, path) => {
  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }
  console.log(error.config);
};

const parseValue = (res, parseAsJson = true) => {
  if (res.status === 204) return {};
  return parseAsJson ? res.data : res.text();
};

const doUpload = async (uploadUrl, file, handleOnUploadProgress) => {
  const accessToken = await generateAccessToken();
  if (!accessToken) {
    console.log('Error: Cannot get ByteArk Access Token');
    return;
  }

  const data = new FormData();
  data.append('file', file);
  data.append('type', file.type);
  data.append('fileName', file.name);
  data.append('size', file.size);
  data.append('preview', file.preview);
  data.append('path', file.webkitRelativePath || '/');

  if (enabledStreamApi) {
    return axios
      .put(uploadUrl, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-byteark-team': 'skooldio',
        },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          handleOnUploadProgress(loaded, total);
        },
      })
      .catch((error) => handleErrors(error, uploadUrl))
      .then((res) => parseValue(res, { parseJson: true }));
  }
  return axios
    .post(uploadUrl, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        handleOnUploadProgress(loaded, total);
      },
    })
    .catch((error) => handleErrors(error, uploadUrl))
    .then((res) => parseValue(res, { parseJson: true }));
};

export const uploadVideos = async (files, metadata, handleOnUploadProgress) => {
  const { courseOwnerId, courseCode } = metadata;
  const courseOwner = BYTEARK_CHANNEL[courseOwnerId]?.name;
  const videos = files.map((file) => {
    const { name, size } = file;
    let video;
    if (enabledStreamApi) {
      video = {
        title: name,
        courseCode,
      };
    } else {
      video = {
        title: name,
        size,
        courseCode,
        projectId: PROJECT_ID,
      };
    }
    if (courseOwner) video['courseOwner'] = courseOwner;
    return video;
  });

  const { data } = await apolloClient.mutate({
    mutation: enabledStreamApi ? byteArkStreamCreateMultipleVideos : createMultipleVideos,
    refetchQueries: [],
    variables: enabledStreamApi
      ? {
          projectKey: byteArkStreamProjectKey,
          videos,
        }
      : {
          videos,
        },
  });

  const { createdVideos, success } = enabledStreamApi
    ? data.vdmByteArkCreateVideos
    : data.vdmCreateVideos;
  if (success) {
    return Promise.all(
      createdVideos.map(async (createdVideo, idx) => {
        const { videoKey, videoUploadUrl } = createdVideo;
        try {
          await doUpload(videoUploadUrl, files[idx], handleOnUploadProgress(idx));
          return { videoKey, fail: false };
        } catch (error) {
          await deleteVideos([videoKey]);
          return { fail: true };
        }
      })
    );
  }
  return [];
};

export const deleteVideos = async (videoKeys) => {
  const { data } = await apolloClient.mutate({
    mutation: enabledStreamApi ? byteArkStreamDeleteMultipleVideos : deleteMultipleVideos,
    refetchQueries: [],
    variables: {
      videoKeys,
    },
  });

  const { deletedVideos, success } = enabledStreamApi
    ? data.vdmByteArkDeleteVideos
    : data.vdmDeleteVideos;

  if (success) return deletedVideos;
  return [];
};

const generateAccessToken = async () => {
  const provider = VIDEO_TYPE.BYTEARK;
  const tokenDuration = 10;
  const response = await apolloClient
    .mutate({
      mutation: enabledStreamApi ? byteArkStreamGenerateVideoAccessToken : generateVideoAccessToken,
      refetchQueries: [],
      variables: {
        provider,
        tokenDuration,
      },
    })
    .catch((err) => console.error(err));
  if (response) {
    const { data } = response;
    const { accessToken } = enabledStreamApi
      ? data.vdmByteArkGenerateAccessToken
      : data.vdmGenerateAccessToken;

    return accessToken;
  }
  return null;
};

export const generateSignedUrl = async (videoKey, duration = 2000) => {
  const provider = VIDEO_TYPE.BYTEARK;
  const timeToLive = duration < 60 ? 180 : 3 * duration;

  const response = await apolloClient
    .mutate({
      mutation: enabledStreamApi ? byteArkStreamGenerateVideoSignedUrl : generateVideoSignedUrl,
      refetchQueries: [],
      variables: {
        videoSigningOptions: { videoKey, timeToLive },
      },
    })
    .catch((err) => console.error(err));
  if (response) {
    const { data } = response;
    const { signedUrl } = enabledStreamApi
      ? data.vdmByteArkGenerateOnlineVideoSignedUrl
      : data.vdmGenerateVideoSignedUrl;

    return signedUrl;
  }
  return null;
};
