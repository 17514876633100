import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tab } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';

import { Flex } from 'Components/Base';
import Text from 'Components/Base/Text';
import { getTeacherProfile } from 'GraphQL/LSP/query/Profile.query';
import Loading from 'Components/Loading';
import getDisplayName from 'Util/LSP/getDisplayName';

import TeacherStudentsDetails from '../../Components/TeacherStudentsDetails';

const panes = (userId) => [
  {
    menuItem: 'นักเรียน',
    render: () => <TeacherStudentsDetails userId={userId} />,
  },
];

const TeacherProfileDetailsRoute = (props) => {
  const userId = _.get(props, 'match.params.id');
  const { loading, error, data } = useQuery(getTeacherProfile, {
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
  });
  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  return (
    <Fragment>
      <Flex justifyContent="space-between" mb={2}>
        <Text.Header>{getDisplayName(data && data.boTeacherProfile)}</Text.Header>
      </Flex>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes(userId)} />
    </Fragment>
  );
};

TeacherProfileDetailsRoute.propTypes = {};

export default TeacherProfileDetailsRoute;
