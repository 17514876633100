import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Flex } from 'Components/Base';

class SectionTitle extends Component {
  static propTypes = {
    left: PropTypes.node,
    right: PropTypes.node,
  };
  static defaultProps = {
    left: '',
    right: '',
  };
  render() {
    const { left, right } = this.props;
    return (
      <Flex justifyContent="space-between" alignItems="center" flex="1 1 auto" /* p={1} */>
        <Box className="title-left">{left}</Box>
        <Box className="title-right">{right}</Box>
      </Flex>
    );
  }
}

export default SectionTitle;
