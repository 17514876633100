import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';
import FormGenerator from '../../../Components/Form/FormGenerator';

const fields = (typeList) => [
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Section',
    inputName: 'section',
    inputProps: {
      required: true,
      search: true,
      options: typeList,
      defaultValue: typeList.length > 0 ? typeList[0].value : null,
    },
  },
];

export default class ChangeSectionModal extends Component {
  static propTypes = {
    sectionId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    trigger: PropTypes.element,
    sections: PropTypes.array,
    onSubmit: PropTypes.func,
  };
  static defaultProps = {
    sectionId: '',
    sections: [],
    onSubmit: () => {},
  };

  render() {
    const { id, sectionId, sections, onSubmit, trigger } = this.props;
    const typeList = sections
      .filter((section) => section.id !== sectionId && !section.contentType)
      .map((section) => ({ key: section.id, value: section.id, text: section.title }));
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{'Change to section'}</Modal.Header>
            <Modal.Content>
              <FormGenerator
                fields={fields(typeList)}
                onSubmit={(formattedValues) => {
                  onSubmit(id, sectionId, formattedValues.section);
                }}
                submitText={'Change Section'}
                showCancel
                cancelText="Cancel"
                onCancelled={closeModal}
                initialData={{
                  section: typeList && typeList.length > 0 ? typeList[0].value : null,
                }}
              />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
