import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Divider, Radio } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';

import { Flex, Text } from 'Components/Base';
import { WireSyncProvider, ROLES } from 'src/Util/WireSync';

import FileUploadManagerModal from '../../Lib/UploadComponent';
import { SemanticButton as Button } from '../../Base/Button';
import MaterialMeta from '../Common/MaterialMeta';
import ActionBar from '../Common/ActionBar';
import { AssetPropTypes } from '../Common/AssetPropTypes';

import DragableList from './DragableList';
import VideoPreview from './VideoPreview';
import SlidePreview from './SlidePreview';
import { VideoSlideLayout } from './Components';

const COMPONENT_SYNC_KEY = {
  VIDEO: 'VIDEO',
  SLIDE: 'SLIDE',
};

const Toolbar = ({
  onFileListRequest,
  onFileUploadUrlRequest,
  onAddFile,
  uploadMode,
  title,
  isPreview,
  onToggleMode,
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex alignItems="center">
        <Text mr={2}>Preview Mode</Text>
        <Radio toggle onChange={onToggleMode} checked={isPreview} />
      </Flex>
      <FileUploadManagerModal
        allowedContentTypes={['image/png', 'image/jpeg', 'image/jpg', 'image/gif']}
        keyGroup={'aaaa'}
        uploadUrl={'http://localhost:5000'}
        listUrl={'http://localhost:5000'}
        customFileListUrlRequest={onFileListRequest}
        customUploadUrlRequest={onFileUploadUrlRequest}
        dimmer={'inverted'}
        mode={uploadMode}
        onFileManagerDialogClose={onAddFile}
        trigger={
          <Button primary circular>
            <Icon name="plus" size="small" />
            Slides
          </Button>
        }
      />
    </Flex>
  );
};

const convertToContentSlidesData = (slidesData = []) => {
  return {
    slideTimeList: slidesData.map((data) => {
      return {
        time: parseInt(data?.time * 1000),
        slide: {
          key: data?.key,
          bucket: data?.bucket,
        },
      };
    }),
  };
};

const convertFromRawData = (content = {}) => {
  return (
    content?.slideTimeList?.map((slideTime) => ({
      ...slideTime,
      ...slideTime?.slide,
      time: slideTime.time / 1000,
      url: slideTime?.url || '/square-image.png',
    })) || []
  );
};

const convertVideoData = (videoAsset) => {
  return { ...videoAsset, ...(videoAsset?.rawContent ?? {}) };
};

const VideoSlides = ({
  content,
  videoAsset,
  onGetSignedUrl,
  onGetVideoSignedUrl,
  onCreateMaterial,
  newAsset,
  onCancel,
  title,
  description,
  loading,
  onFileListRequest,
  onFileUploadUrlRequest,
  materialTypeString,
  uploadMode,
  hideMeta,
  disabledDragList,
}) => {
  const [formData, setFormData] = useState({});
  const [data, setData] = useState(convertFromRawData(content));
  const [videoUrl, setVideoUrl] = useState('');
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    setData(convertFromRawData(content));
  }, [content]);

  const videoKey = videoAsset?.rawContent?.videoKey;
  const url = videoAsset?.rawContent?.url;
  const slideTimeList = content?.slideTimeList;

  useEffect(() => {
    const initVideoSlideData = async () => {
      if (Array.isArray(slideTimeList) && slideTimeList.length) {
        const assetList = slideTimeList.map((slideTime) => slideTime.slide);
        const urls = await onGetSignedUrl(assetList);
        const result = urls.map((url) => {
          const data = slideTimeList.find((data) => data.slide.key === url.key);
          return { ...data?.slide, url: url.signedUrl, time: data.time / 1000 };
        });
        setData(result);
        setFormData({});
      }
      let videoSignedUrl = url;
      if (videoKey) {
        videoSignedUrl = await onGetVideoSignedUrl(videoKey);
      }
      setVideoUrl(videoSignedUrl);
    };
    initVideoSlideData();
  }, [onGetSignedUrl, onGetVideoSignedUrl, slideTimeList, url, videoKey]);

  const handleAction = () => {
    const resultObject = {
      ...formData,
      title: 'Slides',
      content: convertToContentSlidesData(data),
    };

    console.log(resultObject);
    onCreateMaterial(resultObject);
  };

  const handleAddVideoSlides = (slides = []) => {
    // const { bucket, key, url } = slides;
    if (slides && slides.length > 0) {
      setData([
        ...data,
        ...slides.map((slide) => {
          return { ...slide, time: 0 };
        }),
      ]);
    }
  };

  const videoMarkers = data.map((slide) => ({ time: slide.time }));

  const renderSlide = () => {
    if (isPreview) {
      if (!videoUrl) return <div>Please upload video</div>;
      return <SlidePreview slides={data} syncKey={COMPONENT_SYNC_KEY.SLIDE} />;
    }
    return (
      <DragableList
        syncKey={COMPONENT_SYNC_KEY.SLIDE}
        initialValue={data}
        onChange={setData}
        disabledDragList={disabledDragList ?? false}
        showActiveSlide={!!videoUrl}
      />
    );
  };

  return (
    <WireSyncProvider
      initialNodes={{
        keys: Object.values(COMPONENT_SYNC_KEY),
        defaultRole: { [COMPONENT_SYNC_KEY.VIDEO]: ROLES.MASTER },
      }}
    >
      <VideoSlideLayout
        header={
          <Fragment>
            {hideMeta && (
              <Fragment>
                <MaterialMeta title={title} description={description} onChange={setFormData} />
                <Divider hidden />
              </Fragment>
            )}
            <Toolbar
              onToggleMode={() => setIsPreview(!isPreview)}
              isPreview={isPreview}
              onFileListRequest={onFileListRequest}
              onFileUploadUrlRequest={onFileUploadUrlRequest}
              onAddFile={handleAddVideoSlides}
              uploadMode={uploadMode}
            />
            <Divider />
          </Fragment>
        }
        left={
          videoUrl && (
            <VideoPreview
              syncKey={COMPONENT_SYNC_KEY.VIDEO}
              url={videoUrl}
              markers={videoMarkers}
              {...convertVideoData(videoAsset)}
            />
          )
        }
        right={renderSlide()}
        footer={
          !isPreview && (
            <Fragment>
              <Divider />
              <ActionBar
                isCreate={newAsset}
                onAction={handleAction}
                onCancel={onCancel}
                loading={loading}
                materialTypeString={materialTypeString}
                containerProps={{ mb: 0 }}
              />
            </Fragment>
          )
        }
      />
    </WireSyncProvider>
  );
};

VideoSlides.propTypes = {
  ...AssetPropTypes,
  content: PropTypes.shape({
    slideTimeList: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        bucket: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
  hideMeta: PropTypes.bool,
  disabledDragList: PropTypes.bool,
};

VideoSlides.defaultProps = {
  onCreateMaterial: () => {},
  content: { slideTimeList: [] },
  onFileListRequest: () => {},
  onFileUploadUrlRequest: () => {},
};

export default withApollo(VideoSlides);
