import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@skooldio/skooldio-ui-components-core';

import { getSingleCourseV1 } from 'GraphQL/query/Courses.query';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';

const CourseTitle = () => {
  const { courseId } = useParams();
  const { loading, data, error } = useQuery(getSingleCourseV1, {
    variables: {
      version: productCourseVersionResolver('SKOOLDIO', 'v2'),
      courseId,
      isV1: false,
    },
  });
  const courseTitle = data ? data.course.title : null;
  return (
    <>
      <Box ml={4}>
        {loading && !error && <Skeleton />}
        {!loading && !error && <h1>{courseTitle}</h1>}
        {!loading && error && <h1>Title not found</h1>}
      </Box>
    </>
  );
};

export default CourseTitle;
