import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import omitDeep from 'Util/omitDeep';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import { getBook } from '../../GraphQL/query/Products.query';
import { updateBookDetailsMutation } from '../../GraphQL/mutation/Products.mutation';
import { MarkupTextInput } from '../MarkupTextInput';

const BookDetailsEditor = ({ book }) => {
  const { SKUCode, id } = book;
  const { loading, error, data: queryData } = useQuery(getBook, {
    variables: { SKUCode },
    fetchPolicy: 'network-only',
  });

  const [
    updateBook,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(updateBookDetailsMutation);

  const handleUpdate = (data) => {
    const clonedData = JSON.parse(JSON.stringify(data));
    const formatData = omitDeep(clonedData, ['id', '__typename', 'assetUrl']);
    if (formatData) {
      formatData.content = formatData.content ?? '';
    }
    updateBook({
      variables: {
        id,
        data: { details: formatData },
      },
    });
  };

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  const details = queryData?.sfBoProductBook?.details ?? '';

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={true}
        response={mutationResponse?.sfBoProductUpdateBookDetails}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <MarkupTextInput loading={mutationLoading} value={details} onUpdate={handleUpdate} />
    </Fragment>
  );
};

BookDetailsEditor.propTypes = {
  book: PropTypes.object.isRequired,
};

BookDetailsEditor.defaultProps = {};

export default BookDetailsEditor;
