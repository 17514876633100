import React, { Component } from 'react';
import { Card, Grid, Container } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';

import fetch2, { METHOD } from 'src/Util/fetch';
import getConfig from 'src/Util/Config';
import ConfirmationButton from 'src/Components/ConfirmationButton';

import { SemanticButton as Button } from '../../../Components/Base/Button';

const USER_API = getConfig().userApi;

class ForgetPassword extends Component {
  state = {};
  handleForgetPassword = (form) => {
    if (!form.email) {
      return this.setState({ error: 'Email is required' });
    }
    fetch2(`${USER_API}/reset`, {}, METHOD.POST, form)
      .then(() => {
        this.setState({ success: 'Reset password email already sent' });
      })
      .catch((reason) => this.setState({ error: reason.message }));
  };

  render() {
    const { error, success } = this.state;
    return (
      <Container>
        <Grid centered columns={2}>
          <Grid.Column>
            <Card style={{ width: '100%' }}>
              <Card.Content>
                <Form onValidSubmit={this.handleForgetPassword}>
                  <Form.Field>
                    <label>Email</label>
                    <Input name="email" placeholder="myname@email.com" required />
                  </Form.Field>
                  {error && (
                    <Form.Field>
                      <label style={{ color: 'red' }}>{error}</label>
                    </Form.Field>
                  )}
                  <Button primary circular type="submit" disabled={success}>
                    Comfirm
                  </Button>
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        {success && (
          <ConfirmationButton
            defaultOpen={true}
            cancelText={null}
            confirmText={'OK'}
            contentText={success}
            onConfirm={() => this.props.history.push('/login')}
          ></ConfirmationButton>
        )}
      </Container>
    );
  }
}

export default ForgetPassword;
