import gql from 'graphql-tag';

export const getStudentProfiles = gql`
  query boStudentProfiles($search: BOSearchUsersInput, $paging: PageInput, $order: OrderInput) {
    boStudentProfiles(search: $search, paging: $paging, order: $order) {
      total
      profiles {
        id
        firstName
        lastName
        parent {
          id
          prefix
          firstName
          lastName
        }
        advisorTeacher {
          id
          prefix
          firstName
          lastName
        }
        code
      }
    }
  }
`;

export const getAllStudentProfiles = gql`
  query boAllStudentProfiles {
    boAllStudentProfiles {
      id
      code
      prefix
      firstName
      lastName
      nickName
      advisorTeacher {
        id
        code
      }
    }
  }
`;

export const getStudentProfile = gql`
  query boStudentProfile($userId: ID!) {
    boStudentProfile(userId: $userId) {
      id
      prefix
      firstName
      lastName
      nickName
      schoolGradeId
      schoolProgrammeId
      careerPlanIds
      parent {
        id
        prefix
        firstName
        lastName
      }
      advisorTeacher {
        id
        prefix
        firstName
        lastName
      }
      phoneNumber
      username
      email
      code
      address {
        address1
        district
        province
        zipCode
      }
      avatar
      profileImage {
        key
        bucket
      }
      interests {
        value
        iconName
        iconType
      }
      classroomUsername
    }
  }
`;

export const getTeacherProfiles = gql`
  query boTeacherProfiles($search: BOSearchUsersInput, $paging: PageInput, $order: OrderInput) {
    boTeacherProfiles(search: $search, paging: $paging, order: $order) {
      total
      profiles {
        id
        firstName
        lastName
        role
        code
      }
    }
  }
`;

export const getTeacherStudents = gql`
  query boTeacherStudents(
    $search: BOSearchTeacherStudentsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    boTeacherStudents(search: $search, paging: $paging, order: $order) {
      total
      profiles {
        id
        prefix
        firstName
        lastName
        nickName
        code
        schoolGrade {
          id
          name
        }
        schoolProgramme {
          id
          name
        }
        careerPlans {
          id
          name
        }
      }
    }
  }
`;

// Placeholder
export const getTeacherStudent = gql`
  query boTeacherStudent($advisorTeacherId: ID!, $studentId: ID!) {
    boTeacherStudent(advisorTeacherId: $advisorTeacherId, studentId: $studentId) {
      id
      prefix
      firstName
      lastName
      nickName
      code
      schoolGrade {
        id
        name
      }
      schoolProgramme {
        id
        name
      }
      careerPlans {
        id
        name
      }
    }
  }
`;

export const getTeacherProfile = gql`
  query boTeacherProfile($userId: ID!) {
    boTeacherProfile(userId: $userId) {
      id
      prefix
      firstName
      lastName
      nickName
      phoneNumber
      username
      email
      code
      role
      address {
        address1
        district
        province
        zipCode
      }
      avatar
      profileImage {
        key
        bucket
      }
      positions
      classroomUsername
    }
  }
`;

export const getParentProfiles = gql`
  query boParentProfiles($search: BOSearchUsersInput, $paging: PageInput, $order: OrderInput) {
    boParentProfiles(search: $search, paging: $paging, order: $order) {
      total
      profiles {
        id
        firstName
        lastName
        code
      }
    }
  }
`;

export const getParentStudents = gql`
  query boParentStudents(
    $search: BOSearchParentStudentsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    boParentStudents(search: $search, paging: $paging, order: $order) {
      total
      profiles {
        id
        prefix
        firstName
        lastName
        nickName
        code
        schoolGrade {
          id
          name
        }
      }
    }
  }
`;

export const getParentProfile = gql`
  query boParentProfile($userId: ID!) {
    boParentProfile(userId: $userId) {
      id
      prefix
      firstName
      lastName
      nickName
      phoneNumber
      username
      email
      code
      address {
        address1
        district
        province
        zipCode
      }
      avatar
      profileImage {
        key
        bucket
      }
    }
  }
`;
