import React, { Component } from 'react';

import { Flex } from 'Components/Base';

import QueryTable, { DATA_FORMAT } from '../../../../GraphQL/util/QueryTable';
import COLUMN_TYPE from '../../../../Components/Search/SearchFormGenerator/constantType';
import { userExamReport as query } from '../../../../GraphQL/query/Reports.query';
import Text from '../../../../Components/Base/Text';
import { CourseSelectorInput } from '../../Components/SelectorInput';
import getConfig from '../../../../Util/Config';

const excludeColumns = () => ['userId'];
const getColumns = () => {
  const allColumns = [
    {
      Header: 'Exam Date',
      accessor: 'examStartedAt',
      type: COLUMN_TYPE.DATE,
      isSearchAble: false,
      dataFormat: DATA_FORMAT.DATE_FORMAT,
    },
    {
      Header: 'Course',
      accessor: 'courseTitle',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'Courses',
      accessor: 'courseIds',
      type: COLUMN_TYPE.CUSTOM,
      isSearchAble: true,
      show: false,
      CustomSearchComponent: (props) => (
        <CourseSelectorInput
          {...props}
          variables={{
            search: { version: CourseSelectorInput.COURSE_VERSION.SKOOLDIO_V2 },
            paging: { currentPage: 0, pageSize: 100 }, // TODO: Workaround for Input not searchable
            order: { field: 'id', type: 'ASC' },
          }}
          placeholder="Select Courses"
        />
      ),
    },

    {
      Header: 'User Id',
      accessor: 'userId',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Company',
      accessor: 'organizationName',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Score',
      accessor: 'score',
      type: COLUMN_TYPE.NUMBER,
      isSearchAble: false,
    },
    {
      Header: 'Full Score',
      accessor: 'fullScore',
      type: COLUMN_TYPE.NUMBER,
      isSearchAble: false,
    },
    {
      Header: 'Pass/Fail',
      accessor: 'isPassed',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      Cell: ({ original }) => {
        const { isPassed } = original;
        return isPassed ? 'PASS' : 'FAIL';
      },
      csvDisplay: ({ original }) => {
        const { isPassed } = original;
        return isPassed ? 'PASS' : 'FAIL';
      },
    },
    {
      Header: 'Start Time',
      accessor: 'examStartedAt',
      type: COLUMN_TYPE.DATE,
      isSearchAble: true,
      dataFormat: DATA_FORMAT.TIME_FORMAT,
    },
    {
      Header: 'End Time',
      accessor: 'examFinishedAt',
      type: COLUMN_TYPE.DATE,
      isSearchAble: true,
      dataFormat: DATA_FORMAT.TIME_FORMAT,
    },
    {
      Header: 'Passed Date',
      accessor: 'passedExamAt',
      type: COLUMN_TYPE.DATE,
      isSearchAble: false,
    },
    {
      Header: 'Valid Until',
      accessor: 'expirationDate',
      type: COLUMN_TYPE.DATE,
      isSearchAble: false,
    },
  ];
  if (getConfig().lineHideColumns) {
    return allColumns.filter((column) => !excludeColumns().includes(column.accessor));
  }
  return allColumns;
};

class UserExamReport extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 20 },
    search: {},
    order: { field: 'startExamAt', type: 'DESC' },
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Exams</Text.Header>
        </Flex>
        <QueryTable
          type="reportUserExam"
          resolveData={(data) => {
            return data.reportUserExam.userExams;
          }}
          columns={getColumns()}
          query={query}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          exportable
        />
      </div>
    );
  }
}

export default UserExamReport;
