import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { Text } from 'Components/Base';

import CollapsedRow from './CollapsedRow';

const Row = ({ data, updatePermissionList }) => {
  const { accessor, title, collapsedData } = data;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        style={{ borderBottom: '1px solid #D9D9D9', cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
      >
        <TableCell component="th" scope="row">
          <Text fontWeight={500} fontSize="14px">
            {title}
          </Text>
        </TableCell>
        <TableCell align="right" />
        <TableCell align="right" />
        <TableCell align="right" />
        <TableCell align="right">
          <IconButton size="small">
            {open ? (
              <KeyboardArrowUpIcon style={{ color: '#F1AD46' }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: '#F1AD46' }} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <CollapsedRow
        open={open}
        title={accessor}
        data={collapsedData}
        updatePermissionList={updatePermissionList}
      />
    </>
  );
};

Row.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    collapsedData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        accessor: PropTypes.string.isRequired,
        view: PropTypes.shape({
          isAllow: PropTypes.bool,
          id: PropTypes.string,
        }).isRequired,
        update: PropTypes.shape({
          isAllow: PropTypes.bool,
          id: PropTypes.string,
        }).isRequired,
        delete: PropTypes.shape({
          isAllow: PropTypes.bool,
          id: PropTypes.string,
        }).isRequired,
      })
    ),
  }),
  updatePermissionList: PropTypes.func,
};

export default Row;
