import React from 'react';
import PropTypes from 'prop-types';

import CreateEditForm from 'Containers/CreateEditForm';

import { GET_ALL_TEAMS, GET_TEAM } from '../../Graphql/query/Team.query';
import { UPDATE_TEAM, CREATE_TEAM } from '../../Graphql/mutation/Team.mutation';
import TeamDetailsForm from '../../Components/Team/TeamDetailsForm';

const formatDataToForm = (data) => ({
  name: data?.team?.name ?? '',
  description: data?.team?.description ?? '',
});

const formatToEdit = (form, { id } = {}) => {
  return { id: id, data: { name: form?.name, description: form?.description } };
};

const formatToCreate = (form, { id } = {}) => {
  return { data: { name: form?.name, description: form?.description } };
};

const TeamDetails = ({ teamId, isEdit, variables, ...props }) => (
  <CreateEditForm
    dataQuery={GET_TEAM}
    editMutation={UPDATE_TEAM}
    getEditVariables={formatToEdit}
    createMutation={CREATE_TEAM}
    createRefetchQueries={[{ query: GET_ALL_TEAMS, variables }]}
    getCreateVariables={formatToCreate}
    isEdit={isEdit}
    id={teamId}
    idVariable={'id'}
    fetchPolicy={'cache-first'}
    {...props}
  >
    {({ data, onSubmit }) => (
      <TeamDetailsForm initialData={formatDataToForm(data)} isEdit={isEdit} onSubmit={onSubmit} />
    )}
  </CreateEditForm>
);

TeamDetails.propTypes = {
  teamId: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  variables: PropTypes.shape({
    paging: PropTypes.object,
    search: PropTypes.object,
    order: PropTypes.object,
  }),
};

TeamDetails.defaultProps = {
  isEdit: true,
};

export default TeamDetails;
