import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDragList from 'react-drag-list';
import { Segment, Image, Grid, Button as SButton, Icon, Popup } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import { SemanticButton as Button } from '../../Base/Button';

const getLastUrl = (url) => {
  const splittedUrl = url.split('/');
  return splittedUrl[splittedUrl.length - 1];
};

const NPGrid = styled(Grid)`
  &.ui.grid > .column:not(.row) {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
`;

const ImageItem = ({ url, onClick }) => (
  <Segment>
    <NPGrid verticalAlign="middle">
      <Grid.Column width={1} tablet={1}>
        <Flex flexDirection="row" justifyContent="center">
          <Icon name="bars" color="grey" />
        </Flex>
      </Grid.Column>
      <Grid.Column width={3} tablet={3}>
        <Popup size="large" trigger={<Image src={url} size="tiny" />}>
          <Popup.Content>
            <Image src={url} size="big" />
          </Popup.Content>
        </Popup>
      </Grid.Column>
      <Grid.Column width={11} tablet={11}>
        <div>{getLastUrl(url)}</div>
      </Grid.Column>
      <Grid.Column width={1} tablet={1}>
        <SButton.Group>
          <Button icon compact basic onClick={onClick}>
            <Icon name="trash alternate outline" />
          </Button>
        </SButton.Group>
      </Grid.Column>
    </NPGrid>
  </Segment>
);

class DragableList extends Component {
  static propTypes = {
    initialValue: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      })
    ),
    onChange: PropTypes.func,
    customRow: PropTypes.element,
    rowKey: PropTypes.string,
  };
  static defaultProps = {
    initialValue: [],
    onChange: () => {},
  };

  state = {
    data: this.props.initialValue,
  };

  handleUpdate = (evt, updated) => {
    this.setState(
      (prevState) => ({ data: [...updated] }),
      () => {
        this.props.onChange([...updated]);
      }
    );
  };

  handleSlideRemove = (index) => {
    console.log('handle slide remove', index);
    this.setState(
      (prevState) => ({
        data: prevState.data.filter((_, idx) => {
          return idx !== index;
        }),
      }),
      () => {
        this.props.onChange(this.state.data);
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({
        data: this.props.initialValue,
      });
    }
  }

  renderCustomRow(record, index) {
    return React.cloneElement(this.props.customRow, {
      record,
      index,
      removeSlideFn: () => this.handleSlideRemove(index),
    });
  }

  renderRow = (record, index) => {
    if (this.props.customRow) {
      return <Box my={1}>{this.renderCustomRow(record, index)}</Box>;
    } else {
      return (
        <Box my={1}>
          <ImageItem
            url={record.url}
            onClick={() => {
              this.handleSlideRemove(index);
            }}
          />
        </Box>
      );
    }
  };

  render() {
    const { rowKey } = this.props;
    const { data } = this.state;
    return (
      <Fragment>
        {data.length === 0 && <Box>No data.</Box>}
        <ReactDragList
          dataSource={data}
          rowKey={rowKey ? rowKey : 'url'}
          row={(record, index) => this.renderRow(record, index)}
          handles={false}
          className="simple-drag"
          rowClassName="simple-drag-row"
          onUpdate={this.handleUpdate}
        />
      </Fragment>
    );
  }
}

export default DragableList;
