import { createAction } from 'redux-actions';

export const CALL_API = 'API/CALL';
export const CALL_API_SUCCESS = 'API/CALL/SUCCESS';
export const CALL_API_FAIL = 'API/CALL/FAIL';

export const callApi = createAction(CALL_API, (key, path, content, actionTypes) => ({
  key,
  path,
  content,
  actionTypes,
}));
// export const callApiSuccess = createAction(CALL_API_SUCCESS);
// export const callApiFail = createAction(CALL_API_FAIL);
