import React from 'react';
import { compose, mapProps } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';

import withAppConfig from 'Util/hoc/withAppConfig';

import QueryComponent from '../../../../GraphQL/util/QueryComponent';
import { getSingleCourseV1 } from '../../../../GraphQL/query/Courses.query';
import { productCourseVersionResolver } from '../../../../GraphQL/query/AppConfig.query';
import { getRouteInfo } from '../utils';

import Overview from './Overview.view';

const OverviewQuery = ({ courseId, product, version, appConfig, ...props }) => {
  return (
    <QueryComponent
      query={getSingleCourseV1}
      variables={{
        version: productCourseVersionResolver(product, version),
        courseId,
        isV1: version === 'v1',
        enabledContentTeam: appConfig?.enabledContentTeam,
      }}
      loadingOverlay
    >
      {(data) => {
        return (
          <Overview
            {...data}
            product={product}
            version={version}
            appConfig={appConfig}
            {...props}
          />
        );
      }}
    </QueryComponent>
  );
};

export default compose(
  mapProps(getRouteInfo),
  withAppConfig({ configProp: 'appConfig' })
)(OverviewQuery);
