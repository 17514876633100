import React, { useEffect, useMemo, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dropdown, Label } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';

import { myProfile } from 'Redux/user-profile/selectors';
import { GET_USER_TEAMS } from 'GraphQL/query/Users.query';
import withFormsy from 'Components/Form/withFormsy';
import withAppConfig from 'Util/hoc/withAppConfig';

const formatOptions = (teams = []) =>
  uniqBy(
    teams.map(({ id, name }) => ({ key: id, value: id, text: name })),
    'value'
  );

const ExamTeamSelectorInput = ({
  userProfile,
  initialData,
  appConfig,
  onChange,
  value: selectedTeams,
  ...props
}) => {
  const [warning, setWarning] = useState();

  useEffect(() => {
    if (warning) setTimeout(() => setWarning(null), 1500);
  }, [warning]);

  const { data, loading, error } = useQuery(GET_USER_TEAMS, {
    variables: { userId: userProfile?.id },
    skip: !appConfig.enabledContentTeam,
  });

  const { examTeams, examTeamsIds } = useMemo(
    () => ({
      examTeams: initialData?.teams ?? [],
      examTeamsIds: initialData?.teams?.map(({ id }) => id) ?? [],
    }),
    [initialData]
  );

  const { userTeams, userTeamsIds } = useMemo(
    () => ({
      userTeams: data?.user?.teamData ?? [],
      userTeamsIds: data?.user?.teamIds ?? [],
    }),
    [data]
  );

  const options = useMemo(() => formatOptions([...examTeams, ...userTeams]), [
    examTeams,
    userTeams,
  ]);

  const handleChange = (e, { value }) => {
    const fillTeam = examTeamsIds.filter((courseTeamId) => !userTeamsIds.includes(courseTeamId));

    const showPermissionWarning = fillTeam.some((teamId) => !value.includes(teamId));
    if (showPermissionWarning) {
      setWarning(`You do not have the permission to modify this team`);
      return;
    }

    const showVisibilityWarning = value.length === 0 && examTeamsIds.length !== 0;
    if (showVisibilityWarning) {
      setWarning(`Team cannot be removed, Removing team will make you unable to view this content`);
      return;
    }

    onChange(
      {},
      {
        value: uniq([...value, ...fillTeam]),
      }
    );
  };

  return (
    <>
      <Dropdown
        {...props}
        options={options}
        multiple
        placeholder="Select Teams"
        search
        selection
        fluid
        value={selectedTeams}
        onChange={handleChange}
      />

      {warning && (
        <Label color="orange" pointing>
          {warning}
        </Label>
      )}
    </>
  );
};

export default compose(
  withFormsy,
  withAppConfig({ configProp: 'appConfig' }),
  connect(
    (state) => ({
      userProfile: myProfile(state),
    }),
    {}
  )
)(ExamTeamSelectorInput);
