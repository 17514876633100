import gql from 'graphql-tag';

const TEAM_FRAGMENT = gql`
  fragment TEAM_FRAGMENT on Team {
    id
    name
    description
  }
`;

const GET_ALL_TEAMS = gql`
  query teams($search: TeamsSearchInput, $paging: PageInput, $order: OrderInput) {
    teams(search: $search, paging: $paging, order: $order) {
      teams {
        ...TEAM_FRAGMENT
      }
      total
    }
  }
  ${TEAM_FRAGMENT}
`;

const GET_TEAM = gql`
  query team($id: ID!) {
    team(id: $id) {
      ...TEAM_FRAGMENT
    }
  }
  ${TEAM_FRAGMENT}
`;

const GET_ASSIGNED_USERS_BY_TEAM = gql`
  query assignedUsersByTeam(
    $teamId: ID!
    $search: TeamSearchUserInput
    $paging: PageInput
    $order: OrderInput
  ) {
    assignedUsersByTeam(teamId: $teamId, search: $search, paging: $paging, order: $order) {
      total
      users {
        id
        username
        firstname
        lastname
        email
      }
    }
  }
`;

const GET_UNASSIGNED_USERS_BY_TEAM = gql`
  query unassignedUsersByTeam(
    $teamId: ID!
    $search: TeamSearchUserInput
    $paging: PageInput
    $order: OrderInput
  ) {
    unassignedUsersByTeam(teamId: $teamId, search: $search, paging: $paging, order: $order) {
      total
      users {
        id
        username
        firstname
        lastname
        email
      }
    }
  }
`;

const GET_ASSIGNED_TEAMS_BY_USER = gql`
  query assignedTeamsByUser(
    $userId: ID!
    $search: TeamsSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    assignedTeamsByUser(userId: $userId, search: $search, paging: $paging, order: $order) {
      total
      teams {
        ...TEAM_FRAGMENT
      }
    }
  }
  ${TEAM_FRAGMENT}
`;

const GET_UNASSIGNED_TEAMS_BY_USER = gql`
  query unassignedTeamsByUser(
    $userId: ID!
    $search: TeamsSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    unassignedTeamsByUser(userId: $userId, search: $search, paging: $paging, order: $order) {
      total
      teams {
        ...TEAM_FRAGMENT
      }
    }
  }
  ${TEAM_FRAGMENT}
`;

export { TEAM_FRAGMENT };
export {
  GET_ALL_TEAMS,
  GET_TEAM,
  GET_ASSIGNED_USERS_BY_TEAM,
  GET_UNASSIGNED_USERS_BY_TEAM,
  GET_ASSIGNED_TEAMS_BY_USER,
  GET_UNASSIGNED_TEAMS_BY_USER,
};
