import React from 'react';

import QueryComponent from 'GraphQL/util/QueryComponent';

import { getBook } from '../../GraphQL/query/Products.query';

import BookDetails from './BookDetails';

const BookDetailsRoute = (props) => {
  const { match = {} } = props;
  const { params = {} } = match;
  const { SKUCode } = params;
  return (
    <QueryComponent query={getBook} variables={{ SKUCode }} loadingOverlay>
      {(data) => {
        return <BookDetails {...props} book={data && data.sfBoProductBook} />;
      }}
    </QueryComponent>
  );
};

export default BookDetailsRoute;
