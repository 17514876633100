import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { Flex, Box, Text } from 'Components/Base';
import Switch from 'Components/Switch';
import withFormsy from 'Components/Form/withFormsy';

const FlexInput = styled(Flex)`
  & .react-datepicker__input-container {
    width: inherit;
  }
  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

const ResultOptionsInput = ({ value, onChange, readOnly }) => {
  const { isShow: initIsShow, showAfter: initShowTime } = value;

  const [showResultsChecked, setShowResultsChecked] = useState(initIsShow ?? false);
  const [timeChecked, setTimeChecked] = useState(initShowTime ? false : true);
  const [time, setTime] = useState(initShowTime ? moment(initShowTime) : moment());

  useEffect(() => {
    const dateTime = timeChecked ? null : time;
    onChange(
      {},
      {
        value: {
          isShow: showResultsChecked,
          showAfter: showResultsChecked ? dateTime : null,
        },
      }
    );
  }, [showResultsChecked, timeChecked, time]);

  const handleShowResultChange = useCallback(() => {
    setShowResultsChecked(() => !showResultsChecked);
    setTimeChecked(() => !showResultsChecked);
  }, [showResultsChecked]);

  const handleSetTimeChange = useCallback(() => {
    setTimeChecked(() => !timeChecked);
  }, [timeChecked]);

  const isShowTimeChecker = showResultsChecked;
  const isShowDateTimePicker = showResultsChecked && !timeChecked;

  return (
    <Box>
      <Flex alignItems="center" p={2}>
        <Switch checked={showResultsChecked} onChange={handleShowResultChange} />
        <Box ml={1} />
        <Text>{'เปิดแสดงเฉลย/ผลสอบ'}</Text>
      </Flex>
      {isShowTimeChecker && (
        <Flex alignItems="center" p={2}>
          <Switch checked={timeChecked} onChange={handleSetTimeChange} />
          <Box ml={1} />
          <Text>{'แสดงทันที'}</Text>
        </Flex>
      )}
      {isShowDateTimePicker && (
        <FlexInput>
          <DatePicker
            selected={time !== null ? moment(time) : null}
            placeholderText={'กรุณาเลือกวัน/เวลา'}
            dateFormat="DD/MM/YYYY HH:mm"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            value={time !== null ? moment(time) : null}
            onChange={(time) => setTime(time)}
            readOnly={readOnly || !isShowDateTimePicker}
          />
        </FlexInput>
      )}
    </Box>
  );
};

ResultOptionsInput.propTypes = {
  value: PropTypes.shape({
    isShow: PropTypes.bool,
    showAfter: PropTypes.string,
  }),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

ResultOptionsInput.defaultProps = {
  value: { isShow: true, showAfter: null },
};

export default withFormsy(ResultOptionsInput);
