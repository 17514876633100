import React, { useState, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import BatchCreationUpload from './BatchCreationUpload.view';
import BatchCreationStatus from './BatchCreationStatus.gql';

const getCurrentTabIndex = (match) => {
  return match?.params?.tab === 'status' ? 1 : 0;
};

function BatchCreationView(props) {
  const {
    organizations,
    organizationLoading,
    onUpload,
    setOrganizationId,
    uploadBatchEnrollmentStatus,
    uploadBatchEnrollmentErrorMessage,
    setUploadBatchEnrollmentStatus,
    getCoursesOptions,
    coursesOptions,
    canExpire,
    expiryDate,
    isEnableCredit,
    canOverrideCredit,
    subscriptionCreditAmount,
    canInsertEnterWebsiteUrl,
    canInsertEmailTemplateId,
    enterWebsiteUrl,
    setCoursesToEnroll,
    coursesToEnroll,
    uploadList,
    setUploadList,
    validationError,
    getSubscriptions,
    subscriptionsOption,
    enrollProductType,
    setEnrollProductType,
    setSubscriptionToEnroll,
    subscriptionToEnroll,
    refCode,
    handleRefCodeChange,
    handleEnableFields,
    handleCheckableFieldsValueChange,
    emailTemplateId,
  } = props;
  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);
  let { url } = useRouteMatch();
  let match = useRouteMatch(`${url}/:tab`);
  const [activeIndex, setActiveIndex] = useState(getCurrentTabIndex(match));

  useEffect(() => {
    if (activeIndex === 0 && uploadBatchEnrollmentStatus === 'success') {
      setTimeout(async () => {
        await setUploadBatchEnrollmentStatus('');
        await setActiveIndex(1);
      }, 3000);
    }
  }, [activeIndex, setUploadBatchEnrollmentStatus, uploadBatchEnrollmentStatus]);

  const panes = [
    {
      menuItem: {
        as: NavLink,
        id: 'upload-batch-enrollment-tab',
        content: 'Upload',
        to: url,
        exact: true,
        key: 'upload-batch-enrollment',
      },
      render: () => (
        <BatchCreationUpload
          organizations={organizations}
          organizationLoading={organizationLoading}
          onUpload={onUpload}
          setOrganizationId={setOrganizationId}
          uploadBatchEnrollmentStatus={uploadBatchEnrollmentStatus}
          uploadBatchEnrollmentErrorMessage={uploadBatchEnrollmentErrorMessage}
          getCoursesOptions={getCoursesOptions}
          coursesOptions={coursesOptions}
          canExpire={canExpire}
          expiryDate={expiryDate}
          isEnableCredit={isEnableCredit}
          canOverrideCredit={canOverrideCredit}
          subscriptionCreditAmount={subscriptionCreditAmount}
          canInsertEnterWebsiteUrl={canInsertEnterWebsiteUrl}
          canInsertEmailTemplateId={canInsertEmailTemplateId}
          enterWebsiteUrl={enterWebsiteUrl}
          setCoursesToEnroll={setCoursesToEnroll}
          coursesToEnroll={coursesToEnroll}
          uploadList={uploadList}
          setUploadList={setUploadList}
          validationError={validationError}
          getSubscriptions={getSubscriptions}
          subscriptionsOption={subscriptionsOption}
          enrollProductType={enrollProductType}
          setEnrollProductType={setEnrollProductType}
          setSubscriptionToEnroll={setSubscriptionToEnroll}
          subscriptionToEnroll={subscriptionToEnroll}
          refCode={refCode}
          handleRefCodeChange={handleRefCodeChange}
          handleEnableFields={handleEnableFields}
          handleCheckableFieldsValueChange={handleCheckableFieldsValueChange}
          emailTemplateId={emailTemplateId}
        />
      ),
    },
    {
      menuItem: {
        as: NavLink,
        id: 'status-batch-enrollment-tab',
        content: 'Status',
        to: `${url}/status`,
        exact: true,
        key: 'status-batch-enrollment',
      },
      render: () => <BatchCreationStatus />,
    },
  ];

  return (
    <Tab
      panes={panes}
      activeIndex={activeIndex}
      menu={{ secondary: true, pointing: true }}
      onTabChange={handleTabChange}
    />
  );
}

export default BatchCreationView;
