import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from 'Components/Base';

const HiddenCheckboxInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: none;
`;

HiddenCheckboxInput.defaultProps = {
  type: 'checkbox',
};

const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
`;

const SwitchDisplay = styled((props) => <Box as="span" {...props} />)`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  ${({ checked, theme }) =>
    checked &&
    css`
      background-color: ${theme?.colors?.primary ?? '#2196f3'};
      &:before {
        -webkit-transform: translateX(36px);
        -ms-transform: translateX(36px);
        transform: translateX(36px);
      }
    `}
`;

const Switch = (props) => {
  const { checked, value, disabled, onChange = () => {} } = props;
  return (
    <SwitchWrapper>
      <HiddenCheckboxInput
        checked={checked}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <SwitchDisplay checked={checked} />
    </SwitchWrapper>
  );
};

export default Switch;
