import React, { Component } from 'react';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { Divider, Tab } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core';
import { useTheme } from 'styled-components';

import { Box } from 'Components/Base';

import ActionBar from '../Common/ActionBar';
import ConfirmationButton from '../../ConfirmationButton';

import VideoUpload from './VideoUpload';

const defaultVideoData = {
  videoKey: '',
  videoType: '',
  videoDetail: '',
};

const useStyle = makeStyles({
  root: ({ fonts }) => ({
    marginBottom: '16px',
    fontFamily: `${fonts?.sans}`,
  }),
});

const Alert = (props) => {
  const theme = useTheme();
  const classes = useStyle(theme);
  return <MuiAlert classes={classes} {...props} />;
};
class VideoManagement extends Component {
  static propTypes = {
    onCreateMaterial: PropTypes.func,
    content: PropTypes.object.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    newAsset: PropTypes.bool,
    materialTypeString: PropTypes.string,
    metadata: PropTypes.object,
    defaultVideoType: PropTypes.string,
    videoUploadMaxItems: PropTypes.number,
  };

  static defaultProps = {
    onCreateMaterial: () => {},
    content: {},
  };

  state = {
    formData: {},
    confirmCancel: false,
  };

  handleCancel = () => {
    const { newAsset } = this.props;

    if (newAsset) {
      this.setState({
        confirmCancel: true,
      });
    } else {
      this.props.onCancel();
    }
  };

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
    this.props.onCustomTabChange();
  };

  render() {
    const {
      title,
      description,
      newAsset,
      loading,
      materialTypeString,
      content,
      metadata,
      defaultVideoType,
      isAllowMultiple,
      videoBrowseRenderer,
      videoEditorRenderer,
      onCompleteUpload,
      menuItem,
      onCreateMaterial,
      videoUploadMaxItems,
    } = this.props;
    const panes = newAsset
      ? [
          {
            menuItem: 'Browse',
            render: videoBrowseRenderer,
          },
          {
            menuItem: 'Upload',
            render: () => (
              <VideoUpload
                title={title}
                description={description}
                newAsset={newAsset}
                videoKey={this.state.data ? this.state.data.videoKey : ''}
                videoType={newAsset ? defaultVideoType : this.state.data.videoType}
                videoDetail={this.state.data ? this.state.data.videoDetail : ''}
                metadata={metadata}
                onDataChange={this.handleDataChange}
                isAllowMultiple={isAllowMultiple}
                onCompleteUpload={onCompleteUpload}
                videoUploadMaxItems={videoUploadMaxItems}
              />
            ),
          },
        ]
      : [
          {
            menuItem: 'Editor',
            render: () => {
              return videoEditorRenderer({
                title,
                description,
                content,
                metadata: metadata,
              });
            },
          },
        ];
    const activeIndex = menuItem
      ? panes.findIndex((pane) => pane.menuItem === menuItem)
      : this.state.activeIndex;
    return (
      <Box>
        <Box>
          <Alert severity="warning">
            <AlertTitle>อ่านก่อนอัปวีดีโอ</AlertTitle>
            <p>
              สำหรับคอร์สฟรี ให้อัปไว้ที่ YouTube และนำลิงค์มาใส่ในคอร์ส
              และสำหรับคอร์สปกติให้อัปเฉพาะวีดีโอที่ QC ผ่านหมดแล้ว พร้อมขายแล้วเท่านั้น
              เพราะการอัปวีดีโอทุกครั้งเราจะเสียเงินค่า processing เสมอ
            </p>
          </Alert>
          <Tab panes={panes} activeIndex={activeIndex} onTabChange={this.handleTabChange} />
          {this.state.confirmCancel && (
            <ConfirmationButton
              defaultOpen={true}
              onConfirm={() => {
                this.props.onCancel();
              }}
              onCancel={() => {
                this.setState({
                  confirmCancel: false,
                });
              }}
              confirmText="Confirm"
              header="Cancel video upload?"
              contentText="This new video will be cancel from Course Materials"
            />
          )}
        </Box>
        <Divider />
        <Box>
          <ActionBar
            isCreate={newAsset}
            onAction={onCreateMaterial}
            onCancel={this.handleCancel}
            loading={loading}
            materialTypeString={materialTypeString}
          />
        </Box>
      </Box>
    );
  }
}

export default VideoManagement;
