import React from 'react';
import PropTypes from 'prop-types';

import delve from 'Util/Delve';
import CreateEditForm from 'Containers/CreateEditForm';
import { createExamItem, updateExamItem } from 'GraphQL/mutation/Exam.mutation';
import { getExamById, getExamItem } from 'GraphQL/query/Exam.query';

import { EXAM_ITEM_TAG_CATEGORY, EXAM_QUESTION_TYPE, EXAM_QUESTION_STATUS } from '../constants';

import QuestionForm from './QuestionForm';
import { getTagsData } from './util';

const mapSlugsToTags = (slugs = [], category) => {
  if (slugs) {
    if (Array.isArray(slugs)) {
      return slugs.map((slug) => {
        return {
          name: slug,
          slug,
          category,
        };
      });
    }
    return [
      {
        name: slugs,
        slug: slugs,
        category,
      },
    ];
  }
  return [];
};

const mapCorrectAnswer = (formValue) => {
  const { type, choice, numberResult } = formValue;

  switch (type) {
    case EXAM_QUESTION_TYPE.MULTIPLE_CHOICE:
    case EXAM_QUESTION_TYPE.MULTIPLE_SELECT:
    case EXAM_QUESTION_TYPE.MULTIPLE_CHOICE_DIFFERENT_SCORE:
    case EXAM_QUESTION_TYPE.MULTIPLE_SELECT_WITH_CORRECT_ANSWERS:
    case EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS:
    case EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER: {
      const { correctAnswer } = choice ?? {};
      return correctAnswer;
    }
    case EXAM_QUESTION_TYPE.FILL_NUMBER: {
      return { value: Number.parseFloat(numberResult) };
    }
    default:
      break;
  }
};

const calculatedScoreRatio = (choices) => {
  const fullScore = 1;
  const calculatedScoreRatio = (fullScore / choices.length).toFixed(2);
  const scoreRatio = Number(calculatedScoreRatio);
  const choicesWithScoreRatio = choices.map((item) => ({ ...item, scoreRatio }));

  return choicesWithScoreRatio;
};

const mapChoicesWithScoreRatio = (choices) => {
  return choices.map((choice) => {
    return {
      ...choice,
      scoreRatio: choice.scoreRatio ? choice.scoreRatio : 0,
    };
  });
};

const mapMetaData = (formValue) => {
  const { type, choice, fieldNumber, maxAnswers } = formValue;

  switch (type) {
    case EXAM_QUESTION_TYPE.MULTIPLE_CHOICE: {
      const { choices } = choice ?? {};
      return { choices };
    }
    case EXAM_QUESTION_TYPE.MULTIPLE_SELECT: {
      const { choices } = choice ?? {};
      return { choices, maxAnswers: maxAnswers ? parseInt(maxAnswers) : null };
    }
    case EXAM_QUESTION_TYPE.MULTIPLE_CHOICE_DIFFERENT_SCORE: {
      const { choices: choicesData } = choice ?? {};
      const choices = mapChoicesWithScoreRatio(choicesData ?? []);
      return { choices };
    }
    case EXAM_QUESTION_TYPE.MULTIPLE_SELECT_WITH_CORRECT_ANSWERS: {
      const { choices: choicesData } = choice ?? {};
      const choices = calculatedScoreRatio(choicesData ?? []);
      return { choices };
    }
    case EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS:
    case EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER: {
      const { subQuestions } = choice ?? {};
      return { subQuestions };
    }
    case EXAM_QUESTION_TYPE.FILL_NUMBER: {
      return { precision: Number.parseInt(fieldNumber), choices: [] };
    }
    default:
      break;
  }
};

const mapFormValueToExamItem = (formValue = {}) => {
  const {
    owner,
    assignee,
    questionExamType = [],
    examCampaign = [],
    bloom = [],
    grade = [],
    indicator = [],
    objective = [],
    subject = [],
    section = [],
    subsection = [],
    subtopic = [],
    difficulty = [],
    type,
    question,
    solution,
    ...restFormValue
  } = formValue;

  const metadata = {
    choices: null,
    precision: null,
    subQuestions: null,
    maxAnswers: null,
    ...mapMetaData(formValue),
  };

  const ownerTag = mapSlugsToTags(owner, EXAM_ITEM_TAG_CATEGORY.OWNER);
  const assigneeTag = mapSlugsToTags(assignee, EXAM_ITEM_TAG_CATEGORY.ASSIGNEE);
  const questionExamTypeTag = mapSlugsToTags(
    questionExamType,
    EXAM_ITEM_TAG_CATEGORY.QUESTION_EXAM_TYPE
  );
  const examCampaignTag = mapSlugsToTags(examCampaign, EXAM_ITEM_TAG_CATEGORY.EXAM_CAMPAIGN);
  const bloomTag = mapSlugsToTags(bloom, EXAM_ITEM_TAG_CATEGORY.BLOOM);
  const gradeTag = mapSlugsToTags(grade, EXAM_ITEM_TAG_CATEGORY.GRADE);
  const indicatorTag = mapSlugsToTags(indicator, EXAM_ITEM_TAG_CATEGORY.INDICATOR);
  const objectiveTag = mapSlugsToTags(objective, EXAM_ITEM_TAG_CATEGORY.OBJECTIVE);
  const subjectTags = mapSlugsToTags(subject, EXAM_ITEM_TAG_CATEGORY.SUBJECT);
  const sectionTags = mapSlugsToTags(section, EXAM_ITEM_TAG_CATEGORY.SECTION);
  const subsectionTags = mapSlugsToTags(subsection, EXAM_ITEM_TAG_CATEGORY.SUBSECTION);
  const subtopicTags = mapSlugsToTags(subtopic, EXAM_ITEM_TAG_CATEGORY.SUBTOPIC);
  const difficultyTags = mapSlugsToTags(difficulty, EXAM_ITEM_TAG_CATEGORY.DIFFICULTY);
  const tags = [
    ...ownerTag,
    ...assigneeTag,
    ...questionExamTypeTag,
    ...examCampaignTag,
    ...bloomTag,
    ...gradeTag,
    ...indicatorTag,
    ...objectiveTag,
    ...subjectTags,
    ...sectionTags,
    ...subsectionTags,
    ...subtopicTags,
    ...difficultyTags,
  ];

  return {
    type,
    question,
    solution,
    metadata,
    correctAnswer: mapCorrectAnswer(formValue),
    ...(tags.length >= 1 && { tags }),
  };
};

const mapExamItemToFormValue = (examItem, meta = {}) => {
  const { assignee, owner } = meta;
  if (!examItem) return { assignee, owner };

  const {
    metadata: { choices, subQuestions, precision, maxAnswers },
    correctAnswer,
    tags,
    status,
    type,
    ...restExamItem
  } = examItem;
  const isDraftQuestion = status === EXAM_QUESTION_STATUS.DRAFT;
  const { assignee: examItemAssignee, owner: examItemOwner, ...other } = getTagsData(
    tags,
    isDraftQuestion
  );

  const isConnectionAnswersType =
    type === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS;
  const isYesNoAnswersType = type === EXAM_QUESTION_TYPE.MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER;

  return {
    // EXAM QUESTION MULTIPLE CHOICES
    choice:
      isConnectionAnswersType || isYesNoAnswersType
        ? { subQuestions: subQuestions ?? [], correctAnswer }
        : { choices: choices ?? [], correctAnswer },
    maxAnswers: maxAnswers || 0,
    // EXAM QUESTION FILL NUMBER
    fieldNumber: precision,
    numberResult: isNaN(parseFloat(correctAnswer?.value))
      ? undefined
      : parseFloat(correctAnswer?.value).toFixed(precision),
    status,
    type,
    assignee: examItemAssignee || assignee,
    owner: examItemOwner || owner,
    ...other,
    ...restExamItem,
    examItemTags: tags,
  };
};

const CreateEditQuestionForm = ({
  examId,
  examItemId,
  publishMode,
  onComplete,
  onCancelled,
  isEdit,
  assignee,
  owner,
  ...restProps
}) => {
  return (
    <CreateEditForm
      dataQuery={getExamItem}
      createMutation={createExamItem}
      createRefetchQueries={[{ query: getExamById, variables: { examId: examId } }]}
      editMutation={updateExamItem}
      editRefetchQueries={[
        { query: getExamItem, variables: { examId: examId, examItemId: examItemId } },
        { query: getExamById, variables: { examId: examId } },
      ]}
      isEdit={isEdit}
      id={examItemId}
      getQueryVariables={({ id }) => ({
        examId: examId,
        examItemId: id,
      })}
      getCreateVariables={({ form, isPublish }) => ({
        examId: examId,
        examItem: form,
        isPublish: isPublish,
      })}
      getEditVariables={({ form, isPublish }, { id }) => ({
        examId: examId,
        examItemId: id,
        examItem: form,
        isPublish: isPublish,
      })}
      getCreateMutationResponse={(mutateData) => delve(mutateData, 'createExamExamItem')}
      getEditMutationResponse={(mutateData) => delve(mutateData, 'updateExamExamItem')}
      onCompleted={onComplete}
      onCancelled={onCancelled}
      checkMutationResponse
    >
      {({ data, onSubmit }) => {
        const handleOnPublishSubmit = (formValue) => {
          onSubmit({ form: mapFormValueToExamItem(formValue), isPublish: true });
        };

        const handleOnDraftSubmit = (formValue) => {
          onSubmit({ form: mapFormValueToExamItem(formValue), isPublish: false });
        };

        return (
          <QuestionForm
            initialData={mapExamItemToFormValue(data?.examExamItem, { assignee, owner })}
            onCancelled={onCancelled}
            isEdit={isEdit}
            examId={examId}
            onSubmit={!publishMode ? handleOnDraftSubmit : handleOnPublishSubmit}
            publishMode={publishMode}
            {...restProps}
          />
        );
      }}
    </CreateEditForm>
  );
};

CreateEditQuestionForm.propTypes = {
  examId: PropTypes.string,
  examItemId: PropTypes.string,
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  publishMode: PropTypes.bool,
};

export default CreateEditQuestionForm;
