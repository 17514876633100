import React, { Fragment } from 'react';
import { withTheme } from 'styled-components';
import { Divider, Container } from 'semantic-ui-react';

import APP_PERMISSION from 'Util/permissions/permissionKeys';
import Menu from 'Containers/Menu';
import { GenRoute } from 'Util/Route';
import { Flex } from 'Components/Base';
import getConfig from 'Util/Config';

import ProductPreviewButton from '../../Components/ProductPreviewButton';
import OnlineCourseHeader from '../../Components/OnlineCourseHeader';
import {
  OnlineCourseOverviewEditor,
  OnlineCourseCategoriesEditor,
  OnlineCourseInstructorsEditor,
  OnlineCourseDetailsEditor,
  OnlineCoursePricingEditor,
  OnlineCourseCreditPricingEditor,
  OnlineCourseTableOfContentEditor,
  OnlineCourseHighlightEditor,
  OnlineCourseRatingEditor,
  OnlineCourseConditionsEditor,
  OnlineCourseFAQEditor,
} from '../../Components/OnlineCourseEditor';

const genMenu = (matchUrl) => {
  const menus = [
    {
      path: `${matchUrl}/overview`,
      title: 'Overview',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.OVERVIEW.VIEW,
      ],
      route: OnlineCourseOverviewEditor,
    },
    {
      path: `${matchUrl}/categories`,
      title: 'Categories',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.CATEGORIES.VIEW,
      ],
      route: OnlineCourseCategoriesEditor,
    },
    {
      path: `${matchUrl}/instructors`,
      title: 'Instructors',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.INSTRUCTORS.VIEW,
      ],
      route: OnlineCourseInstructorsEditor,
    },
    {
      path: `${matchUrl}/details`,
      title: 'Details',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.DETAILS.VIEW,
      ],
      route: OnlineCourseDetailsEditor,
    },
    {
      path: `${matchUrl}/pricing`,
      title: 'Pricing',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.PRICING.VIEW,
      ],
      route: OnlineCoursePricingEditor,
    },
    {
      path: `${matchUrl}/credit-pricing`,
      title: 'Credit Pricing',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.CREDIT_PRICING.VIEW,
      ],
      route: OnlineCourseCreditPricingEditor,
    },
    {
      path: `${matchUrl}/conditions`,
      title: 'Conditions',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.CONDITIONS.VIEW,
      ],
      route: OnlineCourseConditionsEditor,
    },
    {
      path: `${matchUrl}/table-of-contents`,
      title: 'Table Of Contents',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.TABLE_OF_CONTENTS.VIEW,
      ],
      route: OnlineCourseTableOfContentEditor,
    },
    {
      path: `${matchUrl}/highlights`,
      title: 'Highlights',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.HIGHLIGHTS.VIEW,
      ],
      route: OnlineCourseHighlightEditor,
    },
    {
      path: `${matchUrl}/faq`,
      title: 'FAQs',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.FAQ.VIEW,
      ],
      route: OnlineCourseFAQEditor,
    },
    {
      path: `${matchUrl}/ratings`,
      title: 'Ratings',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.ONLINE_COURSE.RATINGS.VIEW],
      route: OnlineCourseRatingEditor,
    },
  ];

  return menus;
};
const OnlineCourseDetails = withTheme((props) => {
  const { onlineCourse, match, theme } = props;
  const { productPreview } = getConfig();
  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: theme.colors.primary,
        }}
      >
        <Container>
          <OnlineCourseHeader {...onlineCourse} />
        </Container>
        {productPreview?.enabledProductPreview && (
          <Flex justifyContent="flex-end" width="100%" pr={2} pb={3}>
            <ProductPreviewButton
              platformURL={productPreview.platformURL}
              permalink={onlineCourse.permalink}
            />
          </Flex>
        )}
      </Container>
      <Divider hidden />
      <Menu menus={genMenu(match.url)} menuProps={{ secondary: true, pointing: true }} />
      <GenRoute menus={genMenu(match.path)} onlineCourse={onlineCourse} />
    </Fragment>
  );
});

export default OnlineCourseDetails;
