import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import { productCourseVersionResolver, BACKOFFICE_PRODUCT } from 'GraphQL/query/AppConfig.query';

import FormGenerator from '../Form/FormGenerator';
import getConfig from '../../Util/Config';

import PrerequisiteCheckbox from './PrerequisiteCheckbox';
import MaterialSectionList from './MaterialSectionList';
import {
  actionList,
  MATERIAL_TYPES,
  SUBSECTION_CONTENT_TYPE,
  getTypeList,
  filterAction,
  filterTypeList,
  mappingFn,
} from './util';

const MaterialPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.oneOf(Object.values(MATERIAL_TYPES)).isRequired,
  downloadable: PropTypes.bool.isRequired,
  content: PropTypes.shape({}),
});

const fields = (typeList = [], currentContentType) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Title',
    inputName: 'title',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Type',
    inputName: 'contentType',
    inputProps: {
      required: true,
      search: true,
      options: typeList,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputName: 'isPrerequisite',
    hidden: currentContentType !== SUBSECTION_CONTENT_TYPE.QUIZ,
    customInput: () => <PrerequisiteCheckbox />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
    inputLabel: 'Description',
    inputName: 'description',
  },
];

const noop = () => {};

export default class SubSection extends Component {
  static propTypes = {
    product: PropTypes.string,
    version: PropTypes.string,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    canPreview: PropTypes.bool,
    contentType: PropTypes.oneOf(Object.values(SUBSECTION_CONTENT_TYPE)),
    contentAssets: PropTypes.arrayOf(MaterialPropType),
    materials: PropTypes.arrayOf(MaterialPropType),
    // Subsection Action
    onUpdateMeta: PropTypes.func,
    // Material Action
    onAddMaterial: PropTypes.func,
    onMaterialDeleteClick: PropTypes.func,
    onMaterialEditClick: PropTypes.func,
  };

  static defaultProps = {
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
    updateMeta: noop,
    onMaterialDeleteClick: noop,
    onMaterialEditClick: noop,
  };

  state = {
    currentContentType: this.props.contentType,
  };

  onFormChange = (form) => {
    const { contentType } = form;
    this.setState({ currentContentType: contentType });
    return {};
  };

  componentDidUpdate(prevProps) {
    if (prevProps.contentType !== this.props.contentType) {
      this.setState({ currentContentType: this.props.contentType });
    }
  }

  render() {
    const { allowedSubsectionTypes = [] } = getConfig();
    const {
      title,
      description,
      contentType,
      isPrerequisite,
      onUpdateMeta,
      contentAssets,
      materials,
      onMaterialEditClick,
      onMaterialDeleteClick,
      onAddMaterial,
      product,
      version,
    } = this.props;
    const { currentContentType } = this.state;
    const typeList = getTypeList(allowedSubsectionTypes);
    const productVersion = productCourseVersionResolver(product, version);

    return (
      <Flex flexDirection="column">
        <Segment vertical>
          <FormGenerator
            fields={fields(
              typeList.filter(filterTypeList(product)).map(mappingFn),
              currentContentType
            )}
            submitText="Save Subsection"
            onSubmit={onUpdateMeta}
            initialData={{
              title,
              description,
              contentType,
              isPrerequisite,
            }}
            customValidation={this.onFormChange}
            compact
          />
        </Segment>
        <Segment vertical>
          <MaterialSectionList
            title="Course Materials"
            actions={actionList.filter(
              filterAction({ canBeMain: true }, currentContentType, contentAssets)
            )}
            materialList={contentAssets}
            onEditClick={onMaterialEditClick}
            onDeleteClick={onMaterialDeleteClick}
            onAddClick={(id, provider) => onAddMaterial(id, false, provider)}
          />
        </Segment>
        <Segment vertical>
          <MaterialSectionList
            productVersion={productVersion}
            title="Downloadable Materials"
            actions={actionList.filter(
              filterAction({ canBeMain: false }, currentContentType, materials)
            )}
            materialList={materials}
            onEditClick={onMaterialEditClick}
            onDeleteClick={onMaterialDeleteClick}
            onAddClick={(id) => onAddMaterial(id, true)}
          />
        </Segment>
      </Flex>
    );
  }
}
