import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Box } from 'Components/Base';
import FormGenerator from 'Components/Form/FormGenerator';
import getConfig from 'Util/Config';

import { SKU_STATUS_OPTIONS } from '../../Domains/constants';

const { products } = getConfig();

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, publishedAt) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: (
        <Box alignItems={'center'} display="inline-flex">
          <Box mr={1}>SKUCode</Box>
          <Tooltip
            arrow
            title="หลังจาก Publish สินค้านี้ (เปลี่ยน SKUStatus) จะไม่สามารถแก้ไข SKUCode ได้"
            placement={'top'}
          >
            <InfoOutlinedIcon fontSize={'small'} />
          </Tooltip>
        </Box>
      ),
      inputName: 'SKUCode',
      inputProps: {
        required: true,
        disabled: publishedAt,
      },
      mode: MODE.BOTH,
    },

    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'SKUStatus',
      inputName: 'SKUStatus',
      options: products?.BOOK?.skuStatusOptions ?? SKU_STATUS_OPTIONS,
      inputProps: {
        required: true,
        search: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Ordering',
      inputName: 'itemNo',
      inputProps: {
        required: products?.disabledOrdering ? false : true,
      },
      hidden: products?.disabledOrdering || false,
      mode: MODE.BOTH,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

const BookOverviewForm = (props) => {
  const { isEdit, onSubmit } = props;
  const publishedAt = props.initialData?.publishedAt;
  return (
    <FormGenerator
      fields={getFields(isEdit, publishedAt)}
      onSubmit={onSubmit}
      submitText={isEdit ? 'Update' : 'Add'}
      cancelText="Cancel"
      showCancel
      {...props}
    />
  );
};

BookOverviewForm.propTypes = {
  onComplete: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default BookOverviewForm;
