import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Card, Grid, Container, Icon } from 'semantic-ui-react';
import { Form, Input } from 'formsy-semantic-ui-react';
import { connect } from 'react-redux';
import { compose, branch, renderComponent } from 'recompose';
import styled from 'styled-components';

import { Flex, Text, ButtonFilled } from 'Components/Base';
import getConfig from 'src/Util/Config';

import { login } from '../../../Redux/user-profile/actions';
import { isLogin } from '../../../Redux/user-profile/selectors';

import GoogleIcon from './GoogleIcon';

const LoginButton = (props) => (
  <ButtonFilled
    px={3}
    py={2}
    height={'46px'}
    width={1}
    bg="primary"
    color="white"
    style={{ cursor: 'pointer', border: 0 }}
    fontWeight="600"
    fontSize="14px"
    borderRadius={'5px'}
    {...props}
  />
);

const GoogleButton = (props) => (
  <ButtonFilled
    {...props}
    bg="#4285F4"
    style={{
      cursor: 'pointer',
      border: 'none',
    }}
    py={0}
    px={0}
    width={1}
    borderRadius={'5px'}
  >
    <Flex
      alignItems="center"
      justifyContent="center"
      style={{ position: 'relative', height: '46px' }}
    >
      <GoogleIcon style={{ position: 'absolute', left: 0, top: 0, bottom: 0 }} />
      <Text fontSize="14px" fontWeight="600" color="white">
        Sign in with Google
      </Text>
    </Flex>
  </ButtonFilled>
);

const Divider = styled((props) => (
  <Flex justifyContent={'center'} alignItems={'center'} color="rgb(0, 0, 0,0.5)" {...props} />
))`
  ::before {
    content: '';
    flex: 1 1 0%;
    border-bottom: 1px solid rgb(229, 229, 229);
    margin-right: 10px;
  }
  ::after {
    content: '';
    flex: 1 1 0%;
    border-bottom: 1px solid rgb(229, 229, 229);
    margin-left: 10px;
  }
`;

class Login extends Component {
  state = {
    showPassword: false,
  };

  handleLogin = (form) => {
    this.props.login(form);
  };

  loginWithGmail = (loginURL) => () => {
    window.location = loginURL;
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    const {
      enabledResetPassword,
      enabledGmailLogin,
      googleLoginURL,
      enabledEmailLogin,
    } = getConfig();
    return (
      <Container>
        <Grid centered columns={1}>
          <Grid.Column style={{ width: '100%', maxWidth: '450px' }}>
            <Card style={{ width: '100%' }}>
              <Card.Content style={{ padding: '18px' }}>
                {enabledGmailLogin && (
                  <GoogleButton type="button" onClick={this.loginWithGmail(googleLoginURL)} />
                )}

                {enabledEmailLogin && enabledGmailLogin && <Divider my={3}>or</Divider>}
                {enabledEmailLogin && (
                  <Form onValidSubmit={this.handleLogin}>
                    <Form.Field>
                      <label>Email</label>
                      <Input name="username" placeholder="myname@email.com" />
                    </Form.Field>
                    <Form.Field>
                      <label>Password</label>
                      <Input
                        name="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder="At least 6 charactors"
                        icon={
                          <Icon
                            name={this.state.showPassword ? 'eye slash' : 'eye'}
                            link
                            onClick={this.handleClickShowPassword}
                          />
                        }
                        iconPosition="end"
                      />
                    </Form.Field>
                    {enabledResetPassword && (
                      <Link
                        style={{
                          color: 'rgba(0,0,0,0.5)',
                          fontSize: '12px',
                          display: 'grid',
                          placeItems: 'end',
                          marginTop: '7px',
                          marginBottom: this.props.error ? '14px' : '16px',
                          cursor: 'pointer',
                        }}
                        to={{ pathname: `/forget-password` }}
                      >
                        Forgot password?
                      </Link>
                    )}
                    {this.props.error && (
                      <Form.Field
                        style={{
                          marginBottom: '16px',
                        }}
                      >
                        <label style={{ color: 'red' }}>{this.props.error}</label>
                      </Form.Field>
                    )}
                    <LoginButton type="submit">Sign in</LoginButton>
                  </Form>
                )}
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export { Login };
export default compose(
  connect(
    (state) => {
      return { error: state.userProfile?.login?.error, isLogin: isLogin(state) };
    },
    { login }
  ),
  branch(
    (props) => props.isLogin,
    renderComponent((props) => {
      const { from } = props.location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    })
  )
)(Login);
