import React, { useState } from 'react';

import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { getAnnouncementFileImportJobs } from 'GraphQL/query/Announcement.query';

const FILE_IMPORT_STATUS = {
  NEW: 'NEW',
  IN_PROCESS: 'IN_PROCESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 100,
  },
  {
    Header: 'Announcement Title',
    accessor: 'detail.announcement.title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'File Name',
    accessor: 'originalFilename',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: Object.keys(FILE_IMPORT_STATUS).map((key) => ({ key, value: key, text: key })),
    width: 100,
  },
];

function FileImportStatus(props) {
  const [search, setSearch] = useState();
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [order, setOrder] = useState({ field: 'createdAt', type: 'DESC' });

  const genPaging = (page, pageSize = paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };

  const handleSearchChange = (search) => {
    const paging = genPaging(0);
    setSearch({ ...search });
    setPaging(paging);
  };

  const handlePageChange = (page) => {
    const paging = genPaging(page);
    setPaging(paging);
  };

  const handlePageSizeChange = (pageSize) => {
    const paging = genPaging(0, pageSize);
    setPaging(paging);
  };

  return (
    <QueryTable
      type="announcementFileImportJobs"
      columns={columns}
      query={getAnnouncementFileImportJobs}
      search={search}
      paging={paging}
      order={order}
      onSearchChange={handleSearchChange}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      pollInterval={10000}
      resolveData={(data) => {
        return data.announcementFileImportJobs.fileImportJobs;
      }}
    />
  );
}

export default FileImportStatus;
