import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { Box } from 'Components/Base';

import QueryComponent from '../QueryComponent';
import Table from '../../../Components/DataTable';
import SearchForm from '../../../Components/Search/SearchFormGenerator';
import TYPE from '../../../Components/Search/SearchFormGenerator/constantType';

import { mapFormatterTable } from './dataUtil';
import { getCSVExportOptions } from './exportUtil';
import { resolveRawData } from './utils';

const CSV_EXPORT_PAGESIZE = 10000;

const columnTypeShape = PropTypes.shape({
  Header: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPE)).isRequired,
  isSearchAble: PropTypes.bool.isRequired,
  searchField: PropTypes.string,
  enumOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
});

/**
 * React Component that using GraphQL to generate table.
 * Must use with GraphQL Schema that support pagination
 *
 *   query certificates($search: CertificateSearchInput, $paging: PageInput, $order: OrderInput) {
 *     certificates(search: $search, paging: $paging, order: $order) {
 *       total
 *       certificates {
 *        id
 *         ...CertificateFragment
 *       }
 *     }
 *  }
 */
const QueryTable = React.forwardRef((props, ref) => {
  const {
    query,
    columns,
    type,
    subComponent: SubComponent,
    status,
    search,
    paging,
    order,
    showSearchPanel,
    resolveData,
    exportable,
    alignItems,
    getSearchVariables,
    resultMapper,
    pollInterval,
    ignoreSearchParams,
    skip,
    middleContent,
    fetchPolicy,
  } = props;

  const { refetch } = useQuery(query, { skip: true });

  const handleExportCSV = async () => {
    const { status, search, order, resolveData, type, columns, resultMapper } = props;
    try {
      const response = await refetch({
        status,
        search,
        paging: { currentPage: 0, pageSize: CSV_EXPORT_PAGESIZE },
        order,
      });
      const data = resolveRawData(type, response?.data, resolveData, resultMapper);
      const options = getCSVExportOptions(columns);
      return { data, options };
    } catch (error) {
      return { error };
    }
  };

  const customProps = {
    columns: columns.filter((column) => !column.isHideColumn).map(mapFormatterTable),
    page: paging.currentPage,
    SubComponent,
    className: 'table-center',
    manual: true, // sorting with query
    showPageSizeOptions: true,
    multiSort: false,
    defaultPageSize: paging.pageSize,
    defaultSorted: order ? [{ id: order.field, desc: order.type === 'DESC' }] : [],
    onPageChange: props.onPageChange,
    onPageSizeChange: props.onPageSizeChange,
    onSortedChange: props.onSortedChange,
    alignItems: alignItems,
  };
  return (
    <Fragment>
      {showSearchPanel && (
        <SearchForm
          search={search}
          columns={columns}
          onExport={handleExportCSV}
          onSearch={props.onSearchChange}
          exportable={exportable}
          ignoreSearchParams={ignoreSearchParams}
        />
      )}
      {middleContent && (
        <Box my={4} width={1}>
          {middleContent}
        </Box>
      )}
      <Box mt={2}>
        <QueryComponent
          ref={ref}
          skip={skip}
          query={query}
          pollInterval={pollInterval}
          variables={
            getSearchVariables
              ? getSearchVariables()
              : {
                  status,
                  search,
                  paging,
                  order,
                }
          }
          fetchPolicy={fetchPolicy ?? 'network-only'}
        >
          {(rawData) => {
            const pages = Math.ceil(
              rawData[type]?.total ? rawData[type]?.total / paging.pageSize : 1
            );
            const data = resolveRawData(type, rawData, resolveData, resultMapper);
            return <Table pages={pages} data={data} alignItems="baseline" {...customProps} />;
          }}
        </QueryComponent>
      </Box>
    </Fragment>
  );
});

QueryTable.displayName = 'QueryTable';
QueryTable.propTypes = {
  type: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(columnTypeShape).isRequired,
  subComponent: PropTypes.func,
  status: PropTypes.string,
  paging: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }),
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  search: PropTypes.object,
  onSearchChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  order: PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['DESC', 'ASC']).isRequired,
  }),
  onOrderChange: PropTypes.func,
  showSearchPanel: PropTypes.bool,
  resolveData: PropTypes.func,
  exportable: PropTypes.bool,
  alignItems: PropTypes.string,
  getSearchVariables: PropTypes.func,
  resultMapper: PropTypes.func,
  client: PropTypes.any,
  pollInterval: PropTypes.number,
  skip: PropTypes.bool,
  middleContent: PropTypes.func,
  fetchPolicy: PropTypes.string,
};

QueryTable.defaultProps = {
  paging: { currentPage: 0, pageSize: 10 },
  showSearchPanel: true,
  onPageChange: () => {},
  onPageSizeChange: () => {},
  onSearchChange: () => {},
  onOrderChange: () => {},
  skip: false,
};

export default QueryTable;
