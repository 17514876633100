import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Confirm } from 'semantic-ui-react';

import { SemanticButton as Button } from '../Base/Button';

const noop = () => {};

class ConfirmationButton extends Component {
  static propTypes = {
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    headerText: PropTypes.string,
    disabled: PropTypes.bool,
    contentText: PropTypes.string,
    buttonStyle: PropTypes.shape({}),
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    children: PropTypes.node,
    defaultOpen: PropTypes.bool,
    'data-testid': PropTypes.string,
    exitWithoutConfirm: PropTypes.bool,
  };
  static defaultProps = {
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    headerText: null,
    disabled: false,
    contentText: "Are you sure what you're going to do? ",
    buttonStyle: {
      basic: true,
      size: 'mini',
      compact: true,
      icon: true,
    },
    onConfirm: noop,
    onCancel: noop,
    exitWithoutConfirm: false,
  };
  state = {
    open: this.props.defaultOpen ? this.props.defaultOpen : false,
  };
  handleClick = (e) => {
    if (!this.props.exitWithoutConfirm) {
      this.setState({ open: true });
      e.stopPropagation();
      return;
    }

    e.stopPropagation();
    this.setState(() => {
      this.props.onConfirm();
    });
  };
  handleConfirm = (e) => {
    e.stopPropagation();
    this.setState(
      () => ({ open: false }),
      () => {
        this.props.onConfirm();
      }
    );
  };
  handleCancel = (e) => {
    e.stopPropagation();
    this.setState(
      () => ({ open: false }),
      () => {
        this.props.onCancel();
      }
    );
  };

  render() {
    const {
      buttonStyle,
      loading,
      children,
      disabled,
      cancelText,
      confirmText,
      headerText,
      contentText,
      'data-testid': dataTestId,
    } = this.props;
    return (
      <Fragment>
        <Confirm
          open={this.state.open}
          cancelButton={cancelText}
          confirmButton={confirmText}
          header={headerText}
          content={contentText}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          dimmer="inverted"
        />
        <Button
          {...buttonStyle}
          loading={loading}
          disabled={disabled}
          onClick={this.handleClick}
          data-testid={dataTestId}
        >
          {children}
        </Button>
      </Fragment>
    );
  }
}

export default ConfirmationButton;
