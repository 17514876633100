import getConfig from 'Util/Config';

import { PRODUCT_TYPE } from './constants';

const enabledProducts = getConfig()?.products?.enabledProducts ?? Object.keys(PRODUCT_TYPE);

const isProductEnabled = (productType) => {
  return enabledProducts.includes(productType);
};

export default isProductEnabled;
