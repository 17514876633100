import getWireSyncError, { ERROR_TYPE, ACTION } from './util';

const ROLES = {
  MASTER: 'MASTER',
  SUBSCRIBER: 'SUBSCRIBER',
  OUTLIER: 'OUTLIER',
};

const INITIAL_NODE_VALUE = 0;

const createNode = ({ value = INITIAL_NODE_VALUE, role = ROLES.SUBSCRIBER } = {}) => ({
  value: value,
  role: role,
});

const addNode = (node = {}, keys, defaultValue, defaultRole) => {
  if (
    !keys ||
    (typeof keys !== 'string' && !Array.isArray(keys)) ||
    (Array.isArray(keys) && keys.some((key) => typeof key !== 'string'))
  ) {
    throw getWireSyncError(ERROR_TYPE.INVALID_KEY, { action: ACTION.ADD });
  }

  const newNode = { ...node };
  const updateKeys = typeof keys === 'string' ? [keys] : keys;
  if (!updateKeys.length) return node;
  updateKeys.forEach((key, index) => {
    try {
      if (newNode[key]) {
        throw getWireSyncError(ERROR_TYPE.NODE_ALREADY_EXIST, { key, action: ACTION.ADD });
      }

      const [masterKey, masterNode] = getMaster(newNode) ?? [];

      let newNodeRole = Object.values(ROLES).includes(defaultRole?.[key])
        ? defaultRole?.[key]
        : ROLES.SUBSCRIBER;
      let newNodeValue = defaultValue?.[key] ?? undefined;
      if (newNodeRole === ROLES.MASTER && masterKey) {
        newNode[masterKey].role = ROLES.SUBSCRIBER;
      }
      if (newNodeRole === ROLES.SUBSCRIBER && masterKey) {
        newNodeValue = newNode[masterKey]?.value;
      }

      newNode[key] = createNode({
        value: newNodeValue,
        role: newNodeRole,
      });
    } catch (error) {
      console.error(error);
    }
  });
  return newNode;
};

const editNodeValue = (node = {}, targetKey, value, forceRole, forceSyncAll) => {
  const newNode = { ...node };
  const target = newNode[targetKey];

  if (!target) {
    throw getWireSyncError(ERROR_TYPE.NODE_NOT_FOUND, { key: targetKey, action: ACTION.EDIT });
  }

  //master changed
  if (target.role === ROLES.MASTER) {
    let updateNodes = {};
    Object.keys(newNode).forEach((key) => {
      if (newNode[key].role === ROLES.SUBSCRIBER || key === targetKey) {
        updateNodes[key] = { ...newNode[key], value };
      }
    });
    if (forceRole === ROLES.SUBSCRIBER || forceRole === ROLES.OUTLIER) {
      updateNodes[targetKey] = { ...updateNodes[targetKey], role: forceRole };
    }
    return { ...newNode, ...updateNodes };
  }

  // subscriber changed
  if (target.role === ROLES.SUBSCRIBER) {
    let updateNodes = {};
    if (forceRole === ROLES.OUTLIER) {
      updateNodes[targetKey] = { ...target, value, role: ROLES.OUTLIER };
    }
    if (forceRole === ROLES.MASTER) {
      updateNodes[targetKey] = { value, role: ROLES.MASTER };
      const [masterKey, masterNode] = getMaster(newNode) ?? [];
      if (masterKey) {
        updateNodes[masterKey] = { ...newNode[masterKey], role: ROLES.SUBSCRIBER };
      }
      updateNodes = { ...newNode, ...updateNodes };
      updateNodes = editNode(updateNodes, targetKey, value, ROLES.MASTER, forceSyncAll);
    }
    return { ...newNode, ...updateNodes };
  }

  //outlier changed
  if (target.role === ROLES.OUTLIER) {
    let updateNodes = {};
    const [masterKey, masterNode] = getMaster(newNode) ?? [];
    if (forceRole === ROLES.MASTER) {
      updateNodes[targetKey] = { value, role: ROLES.MASTER };
      if (masterKey) {
        updateNodes[masterKey] = { ...newNode[masterKey], role: ROLES.SUBSCRIBER };
      }
      updateNodes = { ...newNode, ...updateNodes };
      updateNodes = editNode(updateNodes, targetKey, value, ROLES.MASTER, forceSyncAll);
      return { ...newNode, ...updateNodes };
    }
    if (forceRole === ROLES.SUBSCRIBER) {
      updateNodes[targetKey] = { ...target, value: masterNode?.value, role: ROLES.SUBSCRIBER };
      return { ...newNode, ...updateNodes };
    }
    updateNodes[targetKey] = { value, role: ROLES.OUTLIER };
    return { ...newNode, ...updateNodes };
  }
};

const editNode = (node = {}, targetKey, value, forceRole, forceSyncAll) => {
  let updateNodes = {};
  updateNodes = editNodeValue(node, targetKey, value, forceRole, forceSyncAll);
  if (forceSyncAll) {
    const [masterKey, masterNode] = getMaster(updateNodes) ?? [];
    Object.keys(updateNodes).forEach((key) => {
      if (updateNodes[key].role === ROLES.OUTLIER) {
        updateNodes[key] = { value: masterNode?.value, role: ROLES.SUBSCRIBER };
      }
    });
  }
  return { ...node, ...updateNodes };
};

const removeNode = (node = {}, keys) => {
  if (
    !keys ||
    (typeof keys !== 'string' && !Array.isArray(keys)) ||
    (Array.isArray(keys) && keys.some((key) => typeof key !== 'string'))
  ) {
    throw getWireSyncError(ERROR_TYPE.INVALID_KEY, { action: ACTION.REMOVE });
  }

  const newNode = { ...node };
  const updateKeys = typeof keys === 'string' ? [keys] : keys;

  if (!updateKeys.length) return node;

  updateKeys.forEach((key, index) => {
    try {
      if (!newNode[key]) {
        throw getWireSyncError(ERROR_TYPE.NODE_NOT_FOUND, { key, action: ACTION.REMOVE });
      }
      delete newNode[key];
    } catch (error) {
      console.error(error);
    }
  });
  return newNode;
};

const getMaster = (node = {}) => {
  if (!Object.keys(node).length) return null;

  const masterKey = Object.keys(node).find((key) => {
    const isMaster = node[key].role === ROLES.MASTER;
    return isMaster;
  });

  return masterKey ? [masterKey, node[masterKey]] : null;
};

export { ROLES, INITIAL_NODE_VALUE };
export { createNode, getMaster, addNode, removeNode, editNode };
