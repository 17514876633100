import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import Menu from './Menu';

const GridWithMargin = styled(Grid)`
  margin: 16px 32px !important;
`;
class SideMenuLayout extends Component {
  static defaultProps = {
    containerProps: {},
    contentProps: {},
  };

  state = {
    activeItem: '',
  };

  handleMenuItemChange = (value) => {
    this.setState({
      activeItem: value,
    });
  };

  render() {
    const { activeItem } = this.state;
    const { children, menus, header, containerProps, contentProps } = this.props;
    return (
      <Container fluid {...containerProps}>
        <GridWithMargin>
          <Grid.Column mobile={16} computer={4}>
            <Menu
              menus={menus}
              activeItem={activeItem}
              onMenuItemChange={this.handleMenuItemChange}
              header={header}
            />
          </Grid.Column>
          <Grid.Column textAlign="center" mobile={16} computer={12} {...contentProps}>
            {children}
          </Grid.Column>
        </GridWithMargin>
      </Container>
    );
  }
}

export default SideMenuLayout;
