import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';
import withAppConfig from 'Util/hoc/withAppConfig';

import {
  DescriptionInput,
  AnnouncementCategoryInput,
  AnnouncementAudienceInput,
  PublishTimeInput,
  NotificationAnnouncementDescriptionInput,
} from '../Components';
import { ANNOUNCEMENT_CATEGORY, ANNOUNCEMENT_INPUT_LABEL } from '../Components/constants';

const Form = ({
  isEdit,
  isView,
  onSubmit,
  onSubmitDraft,
  onFileListRequest,
  onFileUploadUrlRequest,
  appConfig,
  ...props
}) => {
  const getCategory = isEdit || isView ? props.initialData?.category : ANNOUNCEMENT_CATEGORY.NEWS;
  const [category, setCategory] = useState(getCategory);
  const announcementId = props.initialData?.id;
  const isPushNotificationCategory =
    category === ANNOUNCEMENT_CATEGORY.NEW_CONTENT || category === ANNOUNCEMENT_CATEGORY.MARKETING;

  const radioList = [
    {
      label: 'ข่าวสาร',
      name: ANNOUNCEMENT_CATEGORY.NEWS,
    },
    {
      label: 'เรียนรู้เพิ่มเติม',
      name: ANNOUNCEMENT_CATEGORY.REMINDER,
    },
  ];

  if (appConfig.announcement.enabledNotificationAnnouncement) {
    radioList.push(
      {
        label: 'New Content',
        name: ANNOUNCEMENT_CATEGORY.NEW_CONTENT,
      },
      {
        label: 'Promotion',
        name: ANNOUNCEMENT_CATEGORY.MARKETING, // This is PROMOTION
      }
    );
  }

  const fieldsWith = (isView, category) => [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Topic',
      inputName: 'title',
      inputProps: {
        required: true,
        readOnly: isView,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Category',
      inputName: 'category',
      inputProps: {
        radioList,
        validationErrors: {
          isDefaultRequiredValue: `Category is required`,
        },
        required: true,
        readOnly: isView,
      },
      customInput: (props) => <AnnouncementCategoryInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: ANNOUNCEMENT_INPUT_LABEL.DESCRIPTION,
      inputName: 'detail',
      inputProps: {
        required: true,
        readOnly: isView,
        showContentGeneratorButton: isPushNotificationCategory,
      },
      customInput: isPushNotificationCategory
        ? (props) => <NotificationAnnouncementDescriptionInput {...props} />
        : (props) => <DescriptionInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Audience',
      inputName: 'audiences',
      inputProps: {
        checkBoxLabel: 'All',
        validationErrors: {
          isDefaultRequiredValue: `Audience is required`,
        },
        required: true,
        readOnly: isView,
      },
      customInput: (props) => <AnnouncementAudienceInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Publish Time',
      inputName: 'publishConfig',
      inputProps: {
        // publishTimeLabel: 'กรุณาเลือกวันที่และเวลา',
        checkBoxLabel: 'Now',
        validationErrors: {
          isDefaultRequiredValue: `Publish Time is required`,
        },
        required: true,
        readOnly: isView,
      },
      customInput: (props) => <PublishTimeInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Announced by',
      inputName: 'announcedBy',
      inputProps: {
        required: true,
        readOnly: isView,
      },
    },

    ...(isPushNotificationCategory
      ? [
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Push Notification Title',
            inputName: 'pushNotificationTitle',
            inputProps: {
              required: true,
              readOnly: isView,
            },
            hidden: !isPushNotificationCategory,
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Push Notification Description',
            inputName: 'pushNotificationDescription',
            inputProps: {
              required: true,
              readOnly: isView,
            },
            hidden: !isPushNotificationCategory,
          },
        ]
      : []),
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'utm_campaign',
      inputName: 'utmCampaignId',
      inputProps: {
        readOnly: isView,
      },
      hidden: !isPushNotificationCategory,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'utm_content',
      inputName: 'utmCampaignContent',
      inputProps: {
        readOnly: isView,
      },
      hidden: !isPushNotificationCategory,
    },
  ];

  const handleFormChange = (formValue) => {
    const { category } = formValue;
    if (!category) return;

    setCategory(category);
  };

  const handleSubmit = (formValue) => {
    onSubmit({ ...formValue });
  };

  const handleSubmitDraft = (formValue) => {
    onSubmitDraft({ ...formValue });
  };

  return (
    <FormGenerator
      fields={fieldsWith(isView, category)}
      compact
      showCancel
      isRequiredConfirmation
      submitText={'Save & Publish'}
      submitContent={`This announcement will be saved and publish.`}
      cancelText="Cancel"
      showAddition
      additionText={'Save Draft'}
      additionContent={`This announcement will be saved as draft.`}
      onSubmit={handleSubmit}
      onAdditionSubmit={handleSubmitDraft}
      onChange={handleFormChange}
      {...props}
    />
  );
};

Form.propTypes = {
  isEdit: PropTypes.bool,
  isView: PropTypes.bool,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitDraft: PropTypes.func,
  onFileListRequest: PropTypes.func,
  onFileUploadUrlRequest: PropTypes.func,
};

export default withAppConfig({ configProp: 'appConfig' })(Form);
