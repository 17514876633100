import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormGenerator from '../../../Components/Form/FormGenerator';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Course Refer Id',
    inputName: 'itemId',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
    inputLabel: 'Full price',
    inputName: 'fullPrice',
    inputOptions: {
      defaultNumberValue: null,
    },
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
    inputLabel: 'Promotion Price',
    inputName: 'promotionPrice',
    inputOptions: {
      defaultNumberValue: null,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Promotion Text',
    inputName: 'promotionText',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Currency',
    inputName: 'currency',
    options: [{ key: 'THB', value: 'THB', text: 'THB' }],
  },
];

export default class PriceForm extends Component {
  static propTypes = {
    onCreateNewPrice: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateNewPrice: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateNewPrice(form);
  };

  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={fields}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
