import gql from 'graphql-tag';

export const getAllTagV1 = gql`
  query getAllTagV1($type: TG_TAG_V1_TYPES!) {
    tagsV1(type: $type) {
      id
      tagType
      tag
      tagDisplay
    }
  }
`;

export const TG_TAG_V1_TYPES = {
  COURSE_CATEGORY: 'COURSE_CATEGORY',
  INSTRUCTOR: 'INSTRUCTOR',
};
