import React, { useState } from 'react';

import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { getUserFileImportJob } from 'GraphQL/query/Users.query';

const columns = [
  {
    Header: 'Original File Name',
    accessor: 'originalFilename',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Reference Code',
    accessor: 'refCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: [
      { key: 'NEW', value: 'NEW', text: 'NEW' },
      { key: 'IN_PROCESS', value: 'IN_PROCESS', text: 'INPROCESS' },
      { key: 'SUCCESS', value: 'SUCCESS', text: 'SUCCESS' },
      { key: 'FAILED', value: 'FAILED', text: 'FAILED' },
    ],
  },
  {
    Header: 'Error',
    accessor: 'detail.error',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    width: 400,
    Cell: ({ original }) => {
      const errors = original?.detail?.error ?? '';
      if (errors.includes('\n')) {
        const errArr = errors.trim().split('\n');
        return (
          <div>
            {errArr.map((e) => (
              <>
                {e}
                <br />
              </>
            ))}
          </div>
        );
      }
      return errors;
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'File Type',
    accessor: 'type',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Provider',
    accessor: 'provider',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Id',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Created By',
    accessor: 'createdBy',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
];

function BatchCreationStatus(props) {
  const [search, setSearch] = useState();
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [order, setOrder] = useState({ field: 'createdAt', type: 'DESC' });

  const genPaging = (page, pageSize = paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };

  const handleSearchChange = (search) => {
    const paging = genPaging(0);
    setSearch({ ...search });
    setPaging(paging);
  };

  const handlePageChange = (page) => {
    const paging = genPaging(page);
    setPaging(paging);
  };

  const handlePageSizeChange = (pageSize) => {
    const paging = genPaging(0, pageSize);
    setPaging(paging);
  };

  return (
    <QueryTable
      type="userFileImportJobs"
      columns={columns}
      query={getUserFileImportJob}
      search={search}
      paging={paging}
      order={order}
      onSearchChange={handleSearchChange}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      pollInterval={10000}
      resolveData={(data) => {
        return data.userFileImportJobs.fileImportJobs;
      }}
    />
  );
}

export default BatchCreationStatus;
