import React from 'react';
import PropTypes from 'prop-types';
import { Header, Grid } from 'semantic-ui-react';

import SKUImageUpload from './SKUImageUpload';

const CourseHeader = ({ SKUId, title, subTitle, coverImageUrl, cardImageUrl, skuCode }) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <SKUImageUpload
            skuCode={skuCode}
            imageUrl={coverImageUrl && coverImageUrl !== '' ? coverImageUrl : undefined}
            accessor={'coverImage'}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <SKUImageUpload
            changeImgText="Change card image"
            skuCode={skuCode}
            imageUrl={cardImageUrl && cardImageUrl !== '' ? cardImageUrl : undefined}
            accessor={'cardImage'}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width={8}>
        <Grid.Row>
          <Header size="huge">{title}</Header>
        </Grid.Row>
        <Grid.Row>
          <Header size="large">{subTitle}</Header>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

CourseHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default CourseHeader;
