import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formsy-semantic-ui-react';
import { Grid, Icon, Label, Popup } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import log from 'loglevel';
import _ from 'lodash';

import { QUESTION_TYPE } from 'GraphQL/query/ExamEditorV1.query';
import { COLOR } from 'Components/Theme';
import LegacyHTMLEditor from 'Components/LegacyHTMLEditor';
import { SemanticButton as Button } from 'Components/Base/Button';

import { markdownToText } from '../constant';

import { MARKDOWN_EDITOR_TYPE, VALIDATION_WARNING } from './constant';
import MultipleChoice from './MultipleChoice';
import MultipleSelect from './MultipleSelect';

const Row = Grid.Row;
const Column = Grid.Column;

const DangerHeader = styled.div`
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 700;
  color: ${({ danger }) => (danger ? COLOR.danger : 'black')};
`;

const DangerRow = styled(({ danger, ...rest }) => <Row {...rest} />)`
  ${({ danger }) =>
    danger &&
    css`
      border: 2px solid ${COLOR.danger};
      border-radius: 1rem;
    `};
`;

const HiddenLabel = styled.div`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `};
`;

const RequiredAsterisk = styled.div`
  display: inline-block;
  position: relative;
  font-size: 1.2rem;
  padding-left: 2px;
  color: ${COLOR.danger};
`;

const SolutionArea = ({ onTextEditorChange, solution, examId }) => (
  <Grid columns={1}>
    <Row>
      <Column width={6} textAlign="right">
        <DangerHeader>Solution</DangerHeader>
      </Column>
      <Column width={10} />
    </Row>
    <Row>
      <Column width={6} textAlign="right" />
      <Column width={10}>
        <LegacyHTMLEditor
          onChange={onTextEditorChange}
          id={'solution'}
          value={solution}
          assetId={examId}
        />
      </Column>
    </Row>
  </Grid>
);

const QuestionMetaArea = ({ displayType }) => (
  <Row>
    <Grid columns={2}>
      <Row>
        <Column width={6} textAlign="right">
          Type
        </Column>
        <Column width={10}>{displayType}</Column>
      </Row>
    </Grid>
  </Row>
);

const QuestionArea = ({ questionWarning, onTextEditorChange, question, examId }) => (
  <Fragment>
    <DangerRow danger={questionWarning}>
      <Grid columns={2}>
        <Row>
          <Column width={6} textAlign="right">
            <DangerHeader danger={questionWarning}>Question</DangerHeader>
            <Popup
              trigger={<RequiredAsterisk>*</RequiredAsterisk>}
              content={VALIDATION_WARNING.QUESTION.EN}
              size="mini"
            />
          </Column>
          <Column width={10} />
        </Row>
        <Row>
          <Column width={6} textAlign="right" />
          <Column width={10}>
            <LegacyHTMLEditor
              onChange={onTextEditorChange}
              id={'question'}
              value={question}
              assetId={examId}
            />
          </Column>
        </Row>
      </Grid>
    </DangerRow>
    <HiddenLabel hidden={!questionWarning}>
      <Label color="red" pointing active={true}>
        <Icon name="warning circle" />
        {VALIDATION_WARNING.QUESTION.EN}
      </Label>
    </HiddenLabel>
  </Fragment>
);

const AnswerArea = ({
  type,
  answerWarning,
  answerChoices,
  radioValue,
  examId,
  onChecked,
  onRadioChange,
  onTextEditorChange,
  onAddAnswer,
  onRemoveAnswer,
}) => (
  <Fragment>
    <DangerRow columns={1} danger={answerWarning}>
      <Grid columns={2}>
        <Row>
          <Column width={6} textAlign="right">
            <DangerHeader danger={answerWarning}>Answer</DangerHeader>
            <Popup trigger={<RequiredAsterisk>*</RequiredAsterisk>} size="mini">
              {VALIDATION_WARNING.ANSWER.EN}
            </Popup>
          </Column>
          <Column width={10} />
        </Row>
        {type === QUESTION_TYPE.MULTIPLE_CHOICE ? (
          <MultipleChoice
            onRadioChange={onRadioChange}
            onAnswerChange={(data, index) =>
              onTextEditorChange(MARKDOWN_EDITOR_TYPE.ANSWER, data, index)
            }
            answerChoices={answerChoices}
            value={radioValue}
            examId={examId}
          />
        ) : (
          <MultipleSelect
            onChecked={onChecked}
            onAnswerChange={(data, index) =>
              onTextEditorChange(MARKDOWN_EDITOR_TYPE.ANSWER, data, index)
            }
            answerChoices={answerChoices}
            examId={examId}
          />
        )}
        <Row>
          <Column width={6} />
          <Column width={10}>
            <Button primary size="tiny" type="button" circular onClick={onAddAnswer}>
              <Icon name="plus" />
              Add Answer
            </Button>
            <Button icon floated="right" type="button" size="tiny" onClick={onRemoveAnswer}>
              <Icon name="trash" />
            </Button>
          </Column>
          <Column />
        </Row>
      </Grid>
    </DangerRow>
    <HiddenLabel hidden={!answerWarning}>
      <Label color="red" pointing active={true}>
        <Icon name="warning circle" />
        {VALIDATION_WARNING.ANSWER.EN}
      </Label>
    </HiddenLabel>
  </Fragment>
);

const ActionBar = ({ onCancel }) => (
  <Grid columns={1}>
    <Row>
      <Column width={6} />
      <Column width={10} textAlign="right">
        <Button type="button" circular onClick={onCancel}>
          Cancel
        </Button>
        <Button primary type="submit" circular>
          Save
        </Button>
      </Column>
    </Row>
  </Grid>
);

class QuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioValue: -1,
      answerChoices: _.get(this.props, 'question.choices'),
      question: _.get(this.props, 'question.title'),
      solution: _.get(this.props, 'question.solution.value'),
      questionWarning: false,
      answerWarning: false,
    };
  }

  componentDidMount() {
    const choices = _.get(this.props, 'question.choices');
    if (choices) {
      this.setChoice(choices);
    }
  }

  handleSubmit = (formData) => {
    log.info('QuestionForm::onValidSubmitFunc::', formData);
    const nowAnswerChoices = [...this.state.answerChoices];
    const newChoices = _.map(nowAnswerChoices, (choice) => {
      return _.omit(choice, ['__typename']);
    });
    log.info('this.props', this.props);
    const data = {
      id: _.get(this.props, 'question.id'),
      title: this.state.question,
      solution: { value: this.state.solution },
      choices: newChoices,
      type: this.props.type,
    };
    log.info('data', data);

    const questionValidation = !markdownToText(data.title);
    const answerValidation =
      data.choices.length < 2 ||
      _.findIndex(data.choices, ['isAnswer', true]) === -1 ||
      _.findIndex(data.choices, (c) => markdownToText(c.value) === '') !== -1;

    this.setState({ questionWarning: questionValidation });
    this.setState({
      answerWarning: answerValidation,
    });

    if (!questionValidation && !answerValidation) {
      this.props.onSave(data);
    }
  };

  handleTextEditorChange = (type, data, index) => {
    log.info('QuestionForm::handleTextEditorChange', type, data, index);
    if (type === MARKDOWN_EDITOR_TYPE.QUESTION) {
      this.setState(
        {
          question: data,
        },
        () => {
          log.info('data', data);
        }
      );
    } else if (type === MARKDOWN_EDITOR_TYPE.SOLUTION) {
      this.setState({
        solution: data,
      });
    } else if (type === MARKDOWN_EDITOR_TYPE.ANSWER) {
      const nowChoices = [...this.state.answerChoices];
      const nowChoiceValue = { ...nowChoices[index] };
      nowChoiceValue.value = data;
      nowChoices[index] = nowChoiceValue;
      this.setState({
        answerChoices: nowChoices,
      });
    }
  };

  handleRadioChange = (value) => {
    log.info('QuestionForm::handleRadioChange::', value);
    const nowChoices = [...this.state.answerChoices];
    if (this.state.radioValue !== -1) {
      const oldChoice = { ...nowChoices[this.state.radioValue] };
      oldChoice.isAnswer = !oldChoice.isAnswer;
      nowChoices[this.state.radioValue] = oldChoice;
    }
    const thisChoice = { ...nowChoices[value] };
    thisChoice.isAnswer = !thisChoice.isAnswer;
    nowChoices[value] = thisChoice;
    this.setState({ answerChoices: nowChoices, radioValue: value });
  };

  handleChecked = (index) => {
    log.info('QuestionForm::handleChecked::', index);
    const nowChoices = [...this.state.answerChoices];
    if (nowChoices.length !== 0 && nowChoices[index] !== undefined) {
      const thisChoice = { ...nowChoices[index] };
      thisChoice.isAnswer = !thisChoice.isAnswer;
      nowChoices[index] = thisChoice;
    }
    this.setState({
      answerChoices: nowChoices,
    });
  };

  handleAddAnswerButtonClick = () => {
    log.info('QuestionForm::handleAddAnswerButtonClick');
    const nowAnswerChoices = this.state.answerChoices;
    const newAnswerChoices = [...nowAnswerChoices, { value: '', isAnswer: false }];
    this.setState({ answerChoices: newAnswerChoices });
  };

  handleRemoveAnswerButtonClick = () => {
    log.info('QuestionForm::handleRemoveAnswerButtonClick');
    let newState = { ...this.state };
    if (newState.radioValue + 1 === newState.answerChoices.length) {
      newState.radioValue = -1;
    }
    newState.answerChoices = _.dropRight(newState.answerChoices);
    this.setState(newState);
  };

  setChoice(choices) {
    log.info('QuestionForm::setChoice::', choices);
    this.setState({
      radioValue: _.findIndex(choices, (a) => a.isAnswer === true),
    });
  }

  render() {
    const { onCancel, onSave, type, examId, readOnly } = this.props;

    const {
      answerChoices,
      radioValue,
      answerWarning,
      solution,
      questionWarning,
      question,
    } = this.state;

    const displayType = type === QUESTION_TYPE.MULTIPLE_CHOICE ? 'One Answer' : 'Multiple Answers';
    return (
      <Form onValidSubmit={this.handleSubmit}>
        <Grid columns={1}>
          <QuestionMetaArea displayType={displayType} />
          <QuestionArea
            questionWarning={questionWarning}
            question={question}
            examId={examId}
            onTextEditorChange={(data) =>
              this.handleTextEditorChange(MARKDOWN_EDITOR_TYPE.QUESTION, data)
            }
          />
          <AnswerArea
            type={type}
            answerWarning={answerWarning}
            answerChoices={answerChoices}
            radioValue={radioValue}
            examId={examId}
            onChecked={this.handleChecked}
            onRadioChange={this.handleRadioChange}
            onTextEditorChange={this.handleTextEditorChange}
            onAddAnswer={this.handleAddAnswerButtonClick}
            onRemoveAnswer={this.handleRemoveAnswerButtonClick}
          />
          <SolutionArea
            solution={solution}
            examId={examId}
            onTextEditorChange={(data) =>
              this.handleTextEditorChange(MARKDOWN_EDITOR_TYPE.SOLUTION, data)
            }
          />
          {!readOnly && <ActionBar onCancel={onCancel} />}
        </Grid>
      </Form>
    );
  }
}

QuestionForm.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  question: PropTypes.shape({
    id: PropTypes.string,
    solution: PropTypes.shape({
      value: PropTypes.string,
    }),
    title: PropTypes.string,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        isAnswer: PropTypes.bool,
      })
    ),
  }),
  examId: PropTypes.string,
  readOnly: PropTypes.bool,
};

QuestionForm.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  question: {
    id: null,
    solution: { value: '' },
    title: '',
    choices: [],
  },
  examId: '',
};

export default QuestionForm;
