import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Icon } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';

import SimpleQueryTable, { DATA_FORMAT } from 'GraphQL/util/SimpleQueryTable';
import { getWorkshopBatches, SKUText } from 'GraphQL/query/Workshop.query';
import { sfDeleteWorkshopBatch } from 'GraphQL/mutation/Workshop.mutation';
import { Flex } from 'Components/Base';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import ConfirmationButton from 'Components/ConfirmationButton';
import withAppConfig from 'src/Util/hoc/withAppConfig';

import { VenueEditorModal } from '../WorkshopVenueEditor';
import WorkshopBatchModal from '../WorkshopBatchModal';

const getColumns = (collectionSkuCode, config, onDeleteBatch) => [
  {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    width: 50,
  },
  {
    Header: 'SKUCode',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Status',
    accessor: 'SKUStatus',
    type: COLUMN_TYPE.STRING,
    width: 120,
    Cell: ({ value }) => SKUText[value],
  },
  {
    Header: 'Visibility',
    accessor: 'isVisible',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    Cell: ({ value }) => value.toString(),
    width: 50,
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Start Date',
    accessor: 'eventTime.startDate',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATE_FORMAT,
    width: 100,
  },
  {
    Header: 'End Date',
    accessor: 'eventTime.endDate',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATE_FORMAT,
    width: 100,
  },
  {
    Header: 'Start Time',
    accessor: 'eventTime.startTime',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.TIME_FORMAT,
    width: 100,
  },
  {
    Header: 'End Time',
    accessor: 'eventTime.endTime',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.TIME_FORMAT,
    width: 100,
  },
  {
    Header: 'Price (incl.VAT)',
    accessor: 'priceInclVAT.value',
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    width: 120,
  },
  {
    Header: 'Sale Price (incl.VAT)',
    accessor: 'salePriceInclVAT.value',
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    width: 120,
  },
  {
    Header: 'Maximum seats',
    accessor: 'originalStock',
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_INTEGER,
    width: 120,
  },
  {
    Header: 'Remaining seats',
    accessor: 'stock',
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_INTEGER,
    width: 120,
  },
  {
    Header: 'Venue',
    accessor: 'venueDisplayName',
    type: COLUMN_TYPE.STRING,
    width: 120,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    type: COLUMN_TYPE.CUSTOM,
    /* eslint-disable react/display-name */
    Cell: ({ original }) => {
      const skuCode = original.SKUCode;
      const batchId = original.id;
      return (
        <Button.Group>
          <WorkshopBatchModal
            isEdit
            collectionSkuCode={collectionSkuCode}
            batchSkuCode={skuCode}
            trigger={
              <Button basic size="mini" icon compact>
                <Icon name="edit" />
              </Button>
            }
          />
          <VenueEditorModal
            isEdit
            collectionSkuCode={collectionSkuCode}
            batchSkuCode={skuCode}
            trigger={
              <Button basic size="mini" icon compact>
                <Icon name="map" />
              </Button>
            }
            apiKey={config.googleMapsAPIKey}
          />
          {/* <ConfirmationButton
            headerText={`Delete Workshop Batch?`}
            contentText={`SKU "${skuCode}" will be permanently deleted from the system.`}
            confirmText={'Delete'}
            onConfirm={() => {
              onDeleteBatch(batchId);
            }}
          >
            <Icon name="trash alternate outline" />
          </ConfirmationButton> */}
        </Button.Group>
      );
    },
    /* eslint-enable react/display-name */
    width: 100,
  },
];

const WorkshopBatches = ({ skuCode, appConfig }) => {
  const [deleteWorkshopBatch, { loading, error, data }] = useMutation(sfDeleteWorkshopBatch);
  const { googleMapsAPIKey } = appConfig;
  function handleDeleteBatch(batchId) {
    deleteWorkshopBatch({
      variables: { id: batchId },
      refetchQueries: [{ query: getWorkshopBatches, variables: { SKUCode: skuCode } }],
    });
  }
  return (
    <Container>
      <Flex justifyContent="flex-end" mb={2}>
        <WorkshopBatchModal
          collectionSkuCode={skuCode}
          trigger={
            <Button primary circular>
              <Icon name="plus" size="small" />
              Add Batch
            </Button>
          }
        />
      </Flex>
      <SimpleQueryTable
        type="sfWorkshopBySKUCode"
        columns={getColumns(skuCode, { googleMapsAPIKey }, handleDeleteBatch)}
        query={getWorkshopBatches}
        getSearchVariables={() => ({ SKUCode: skuCode })}
        getResultData={(data) => data.sfWorkshopBySKUCode.batches}
        loading={loading}
      />
    </Container>
  );
};

WorkshopBatches.propTypes = {
  skuCode: PropTypes.string.isRequired,
};

WorkshopBatches.defaultProps = {};

export default withAppConfig({ configProp: 'appConfig' })(WorkshopBatches);
