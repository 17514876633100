import React from 'react';
import PropTypes from 'prop-types';

import withAppConfig from 'Util/hoc/withAppConfig';

import QueryComponent from '../../../GraphQL/util/QueryComponent';
import { singleUserQuery } from '../../../GraphQL/query/Users.query';
import UserHeader from '../Components/UserHeader';

const getEmailInfo = (email, isPassportUser) => (isPassportUser ? `${email} (OAuth)` : email);
const getEmailVerify = (emailVerified, isPassportUser) =>
  isPassportUser ? null : emailVerified === true;

const convertUserData = (user, { enabledAppPermission }) => {
  const {
    displayName,
    roles,
    appRoles,
    profilePic,
    email,
    emailVerified,
    details = {},
    referenceId,
    isPassportUser,
    additionalData,
  } = user;
  return {
    title: displayName,
    roles: enabledAppPermission ? appRoles : roles,
    imageUrl: profilePic,
    referenceId,
    email: getEmailInfo(email, isPassportUser),
    emailVerified: getEmailVerify(emailVerified, isPassportUser),
    organizationName: details.organization ? details.organization.organizationName : null,
    position: details.position,
    citizenId: details.citizenId,
    phoneNumber: details.phoneNumber,
    additionalData,
  };
};

const UserHeaderContainer = ({ userId, config }) => {
  const enabledAppPermission = config?.enabledAppPermission;
  return (
    <QueryComponent query={singleUserQuery} variables={{ userId: userId }}>
      {({ user }) => {
        return <UserHeader {...convertUserData(user, { enabledAppPermission })} />;
      }}
    </QueryComponent>
  );
};

export default withAppConfig()(UserHeaderContainer);
