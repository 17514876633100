import React from 'react';

import QueryComponent from 'GraphQL/util/QueryComponent';

import { getOnlineCourse } from '../../GraphQL/query/Products.query';

import OnlineCourseDetails from './OnlineCourseDetails';

const OnlineCourseDetailsRoute = (props) => {
  const { match = {} } = props;
  const { params = {} } = match;
  const { SKUCode } = params;
  return (
    <QueryComponent
      query={getOnlineCourse}
      variables={{
        SKUCode,
      }}
      loadingOverlay
    >
      {(data) => {
        return (
          <OnlineCourseDetails {...props} onlineCourse={data && data.sfBoProductOnlineCourse} />
        );
      }}
    </QueryComponent>
  );
};

export default OnlineCourseDetailsRoute;
