export const EXAM_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  CANCELLED: 'CANCELLED',
};

export const EXAM_SCORE_TYPE = {
  PERCENT: 'PERCENT',
  SCORE: 'SCORE',
};

export const EXAM_GENERATOR_TYPE = {
  FIXED: 'FIXED',
  FIXED_ALL: 'FIXED_ALL',
  RANDOM: 'RANDOM',
};

export const EXAM_TYPE = {
  PRETEST: 'PRETEST',
  POSTTEST: 'POSTTEST',
  EXAM: 'EXAM',
};

export const EXAM_QUESTION_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  CANCELLED: 'CANCELLED',
};

export const TAG_CATEGORY = {
  COURSE_CODE: 'COURSE_CODE',
  EXAM_TYPE: 'EXAM_TYPE',
  OWNER: 'OWNER',
  ASSIGNEE: 'ASSIGNEE',
  QUESTION_EXAM_TYPE: 'QUESTION_EXAM_TYPE',
  EXAM_CAMPAIGN: 'EXAM_CAMPAIGN',
  BLOOM: 'BLOOM',
  GRADE: 'GRADE',
  INDICATOR: 'INDICATOR',
  OBJECTIVE: 'OBJECTIVE',
  SUBJECT: 'SUBJECT',
  SECTION: 'SECTION',
  SUBSECTION: 'SUBSECTION',
  SUBTOPIC: 'SUBTOPIC',
  DIFFICULTY: 'DIFFICULTY',
};

export const EXAM_TAG_CATEGORY = {
  COURSE_CODE: TAG_CATEGORY.COURSE_CODE,
  EXAM_TYPE: TAG_CATEGORY.EXAM_TYPE,
  SUBTOPIC: TAG_CATEGORY.SUBTOPIC,
  ASSIGNEE: TAG_CATEGORY.ASSIGNEE,
  OWNER: TAG_CATEGORY.OWNER,
};

export const EXAM_ITEM_TAG_CATEGORY = {
  OWNER: TAG_CATEGORY.OWNER,
  ASSIGNEE: TAG_CATEGORY.ASSIGNEE,
  QUESTION_EXAM_TYPE: TAG_CATEGORY.QUESTION_EXAM_TYPE,
  EXAM_CAMPAIGN: TAG_CATEGORY.EXAM_CAMPAIGN,
  BLOOM: TAG_CATEGORY.BLOOM,
  GRADE: TAG_CATEGORY.GRADE,
  INDICATOR: TAG_CATEGORY.INDICATOR,
  OBJECTIVE: TAG_CATEGORY.OBJECTIVE,
  SUBJECT: TAG_CATEGORY.SUBJECT,
  SECTION: TAG_CATEGORY.SECTION,
  SUBSECTION: TAG_CATEGORY.SUBSECTION,
  SUBTOPIC: TAG_CATEGORY.SUBTOPIC,
  DIFFICULTY: TAG_CATEGORY.DIFFICULTY,
};

export const TAG_CATEGORY_DICT = {
  OWNER: 'owner',
  ASSIGNEE: 'assignee',
  QUESTION_EXAM_TYPE: 'questionExamType',
  EXAM_CAMPAIGN: 'examCampaign',
  BLOOM: 'bloom',
  GRADE: 'grade',
  INDICATOR: 'indicator',
  OBJECTIVE: 'objective',
  SUBJECT: 'subject',
  SECTION: 'section',
  SUBSECTION: 'subsection',
  SUBTOPIC: 'subtopic',
  DIFFICULTY: 'difficulty',
  EXAM_TYPE: 'examType',
};

export const TAG_CATEGORY_LABEL = {
  OWNER: 'Owner',
  ASSIGNEE: 'ผู้รับผิดชอบ',
  QUESTION_EXAM_TYPE: 'ประเภทข้อสอบ',
  EXAM_CAMPAIGN: 'สนามสอบ',
  BLOOM: 'Bloom',
  GRADE: 'ระดับชั้น',
  INDICATOR: 'ตัวชี้วัด',
  OBJECTIVE: 'จุดประสงค์',
  SUBJECT: 'วิชา',
  SECTION: 'สาระการเรียนรู้',
  SUBSECTION: 'หน่วยการเรียนรู้',
  SUBTOPIC: 'เรื่อง',
  DIFFICULTY: 'ความยาก',
  EXAM_TYPE: 'ประเภทชุดข้อสอบ',
};

export const EXAM_LOGIC_TAG_CATEGORY = {
  SECTION: {
    category: TAG_CATEGORY_DICT.SECTION,
    label: TAG_CATEGORY_LABEL.SECTION,
    columnWidth: 3,
  },
  SUBTOPIC: {
    category: TAG_CATEGORY_DICT.SUBTOPIC,
    label: TAG_CATEGORY_LABEL.SUBTOPIC,
    columnWidth: 2,
  },
  BLOOM: {
    category: TAG_CATEGORY_DICT.BLOOM,
    label: TAG_CATEGORY_LABEL.BLOOM,
    columnWidth: 2,
  },
  INDICATOR: {
    category: TAG_CATEGORY_DICT.INDICATOR,
    label: TAG_CATEGORY_LABEL.INDICATOR,
    columnWidth: 2,
  },
  OBJECTIVE: {
    category: TAG_CATEGORY_DICT.OBJECTIVE,
    label: TAG_CATEGORY_LABEL.OBJECTIVE,
    columnWidth: 3,
  },
};

export const EXAM_QUESTION_TYPE = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  FILL_NUMBER: 'FILL_NUMBER',
  MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER: 'MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER',
  MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS: 'MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS',
  MULTIPLE_CHOICE_DIFFERENT_SCORE: 'MULTIPLE_CHOICE_DIFFERENT_SCORE',
  MULTIPLE_SELECT_WITH_CORRECT_ANSWERS: 'MULTIPLE_SELECT_WITH_CORRECT_ANSWERS',
  MULTIPLE_SELECT: 'MULTIPLE_SELECT',
};

export const EXAM_QUESTION_LABEL = {
  MULTIPLE_CHOICE: 'ตัวเลือกแบบคำตอบเดียว',
  FILL_NUMBER: 'เติมตัวเลข',
  MULTIPLE_SUB_QUESTIONS_YES_NO_ANSWER: 'ปรนัยเชิงซ้อน - ตัวเลือก ใช่ / ไม่ใช่',
  MULTIPLE_SUB_QUESTIONS_CONNECTION_ANSWERS: 'TPAT1 - พาร์ทการเชื่อมโยง',
  MULTIPLE_CHOICE_DIFFERENT_SCORE: 'TGAT3 93 - ตัวเลือกแบบคำตอบเดียว',
  MULTIPLE_SELECT_WITH_CORRECT_ANSWERS: 'TGAT3 93 - ตัวเลือกแบบหลายคำตอบ',
  MULTIPLE_SELECT: 'ตัวเลือกแบบหลายคำตอบ (All or nothing)',
};

export const EXAM_TYPE_TAG = {
  OFFICIAL_EXAM: 'OFFICIAL EXAM',
};
