import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Segment, Embed, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { Form, Input, TextArea } from 'formsy-semantic-ui-react';
import debounce from 'lodash/debounce';

import { Flex, Box } from 'Components/Base';
import { FormField, RequiredLabel } from 'Components/Form/FormGenerator/FormGenerator';
import { SemanticButton as Button } from 'Components/Base/Button';

import { MINDSTAMP_URL_REGEX } from './constants';

const Divider = styled(Box)`
  border-top: 1px solid #d9d9d9;
`;

const errorLabel = <Label color="red" pointing />;

function MindstampVideoForm(
  {
    initialData = {},
    previewVideo,
    isVideoLoading,
    loading,
    onFormChange,
    onSubmit,
    onCancel,
    buttonText,
  },
  ref
) {
  return (
    <Form ref={ref} onValidSubmit={onSubmit} onChange={debounce(onFormChange, 200)}>
      <FormField>
        <RequiredLabel required>Mindstamp Link</RequiredLabel>
        <Input
          name="previewUrl"
          required
          disabled={loading}
          loading={isVideoLoading}
          validations={{ matchRegexp: MINDSTAMP_URL_REGEX }}
          data-testid={'video-mindstamp-url-input'}
          value={initialData.previewUrl}
          validationErrors={{
            isDefaultRequiredValue: 'Mindstamp Link is required',
            matchRegexp: 'Please enter a valid Mindstamp URL',
          }}
          errorLabel={errorLabel}
        />
      </FormField>
      <FormField>
        <RequiredLabel required>Title</RequiredLabel>
        <Input
          name="title"
          required
          disabled={loading || isVideoLoading}
          data-testid={'video-mindstamp-title-input'}
          value={initialData.title}
          validationErrors={{ isDefaultRequiredValue: 'Title is required' }}
          errorLabel={errorLabel}
        />
      </FormField>
      <FormField>
        <RequiredLabel>Description</RequiredLabel>
        <TextArea
          name="description"
          disabled={loading}
          data-testid={'video-mindstamp-description-input'}
          value={initialData.description}
          validationErrors={{ isDefaultRequiredValue: 'Description is required' }}
          errorLabel={errorLabel}
        />
      </FormField>
      <FormField>
        <RequiredLabel required>Total time on video</RequiredLabel>
        <Input
          name="duration"
          required
          disabled={loading || isVideoLoading}
          data-testid={'video-mindstamp-duration-input'}
          value={initialData.videoDuration}
          placeholder="hh:mm:ss"
          validations={{
            matchRegexp: /^(?=.*[1-9])\d{2}:[0-5]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/,
          }}
          validationErrors={{
            isDefaultRequiredValue: 'Total time is required',
            matchRegexp: 'define time with format hh:mm:ss',
          }}
          errorLabel={errorLabel}
        />
      </FormField>
      {previewVideo?.url && (
        <Segment>
          {previewVideo.duration && (
            <Label as="a" color="red" ribbon>
              <Icon className="clock outline" />
              {previewVideo.duration}
            </Label>
          )}
          <Embed placeholder={previewVideo.thumbnailUrl} url={previewVideo.url} />
        </Segment>
      )}
      <Divider pb="3" />
      <Flex justifyContent="flex-end" alignItems="center" mt={2} mb={3}>
        <Box>
          <Button
            primary
            circular
            type="submit"
            loading={loading}
            disabled={loading || isVideoLoading}
            data-testid={'mindstamp-submit-button'}
          >
            <Icon name="check" size="small" />
            {buttonText}
          </Button>
          <Button
            circular
            onClick={onCancel}
            loading={loading}
            disabled={loading}
            data-testid={'assignment-cancel-button'}
          >
            Cancel
          </Button>
        </Box>
      </Flex>
    </Form>
  );
}

MindstampVideoForm.propTypes = {
  initialData: PropTypes.object,
  previewVideo: PropTypes.object,
  isVideoLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onFormChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  buttonText: PropTypes.string,
};

export default forwardRef(MindstampVideoForm);
