import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import compose from 'recompose/compose';

import withServerConfig from 'GraphQL/util/withServerConfig';
import { getAssetList, getUploadUrl, ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import { updateBundleMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUImageUpload from '../SKUImageUpload';

class BundleImageUpload extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  };

  handleImageUpdate = (editFn) => ({ key, bucket, url }) => {
    const { id, field } = this.props;
    editFn({
      variables: {
        id,
        data: {
          [field]: { key, bucket },
        },
      },
    });
  };

  handleFileListRequest = async (request) => {
    const { client, id } = this.props;
    const { data } = await client.query({
      query: getAssetList,
      variables: {
        type: ASSET_TYPE.STOREFRONT_SKU,
        key: id,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetsList.assetFiles;
  };

  handleFileUploadUrlRequest = async (request) => {
    const { filename, contentType } = request;
    const { id, client } = this.props;
    const { data } = await client.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.STOREFRONT_SKU,
        key: id,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });
    return data.asRequestAssetUploadUrl.uploadUrl.url;
  };

  render() {
    const { config } = this.props;
    return (
      <Mutation mutation={updateBundleMutation}>
        {(updateOnlineCourse, { data, loading }) => (
          <SKUImageUpload
            {...this.props}
            loading={loading}
            onImageUpdate={this.handleImageUpdate(updateOnlineCourse)}
            onFileListRequest={this.handleFileListRequest}
            onFileUploadUrlRequest={this.handleFileUploadUrlRequest}
            uploadMode={config.assetUploadMode}
          />
        )}
      </Mutation>
    );
  }
}

export default compose(withApollo, withServerConfig())(BundleImageUpload);
