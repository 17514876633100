import React, { Fragment } from 'react';
import { withTheme } from 'styled-components';
import { Divider, Container } from 'semantic-ui-react';

import APP_PERMISSION from 'Util/permissions/permissionKeys';
import Menu from 'Containers/Menu';
import { GenRoute } from 'Util/Route';
import { Flex } from 'Components/Base';
import getConfig from 'Util/Config';

import WorkshopHeader from '../../Components/WorkshopHeader';
import {
  WorkshopDetailsEditor,
  WorkshopCategoriesEditor,
  WorkshopInstructorsEditor,
  WorkshopDescriptionEditor,
  WorkshopFAQEditor,
  WorkshopGalleryEditor,
  WorkshopHighlightEditor,
  WorkshopTableOfContentEditor,
  WorkshopTestimonialsEditor,
  WorkshopBatchesEditor,
} from '../../Components/WorkshopEditor';
import ProductPreviewButton from '../../Components/ProductPreviewButton';

const genMenu = (matchUrl) => {
  const menus = [
    {
      path: `${matchUrl}/overview`,
      title: 'Overview',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.OVERVIEW.VIEW],
      route: WorkshopDetailsEditor,
    },
    {
      path: `${matchUrl}/categories`,
      title: 'Categories',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.CATEGORIES.VIEW],
      route: WorkshopCategoriesEditor,
    },
    {
      path: `${matchUrl}/instructors`,
      title: 'Instructors',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.INSTRUCTORS.VIEW],
      route: WorkshopInstructorsEditor,
    },
    {
      path: `${matchUrl}/details`,
      title: 'Details',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.DETAILS.VIEW],
      route: WorkshopDescriptionEditor,
    },
    {
      path: `${matchUrl}/contents`,
      title: 'Table of Contents',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.TABLE_OF_CONTENTS.VIEW],
      route: WorkshopTableOfContentEditor,
    },
    {
      path: `${matchUrl}/highlight`,
      title: 'Highlight',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.HIGHLIGHT.VIEW],
      route: WorkshopHighlightEditor,
    },
    {
      path: `${matchUrl}/gallery`,
      title: 'Gallery',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.GALLERY.VIEW],
      route: WorkshopGalleryEditor,
    },
    {
      path: `${matchUrl}/batches`,
      title: 'Batches',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.BATCHES.VIEW],
      route: WorkshopBatchesEditor,
    },
    {
      path: `${matchUrl}/faq`,
      title: 'Frequently Asked Questions',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.FAQ.VIEW],
      route: WorkshopFAQEditor,
    },
    {
      path: `${matchUrl}/testimonials`,
      title: 'Testimonials',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.WORKSHOP.TESTIMONIALS.VIEW],
      route: WorkshopTestimonialsEditor,
    },
  ];

  return menus;
};
const WorkshopDetails = withTheme((props) => {
  const { workshop, match, theme } = props;
  const { productPreview } = getConfig();
  const contents = workshop ?? {};
  const coverImageUrl = contents?.coverImage?.assetUrl ?? '';
  const cardImageUrl = contents?.cardImage?.assetUrl ?? '';
  const { title, subtitle, SKUCode } = contents;
  const headers = { title, subtitle, SKUCode, coverImageUrl, cardImageUrl };
  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: contents.backgroundColor || theme.colors.primary,
        }}
      >
        <Container>
          <WorkshopHeader {...headers} />
        </Container>
        {productPreview?.enabledProductPreview && (
          <Flex justifyContent="flex-end" width="100%" pr={2} pb={3}>
            <ProductPreviewButton
              platformURL={productPreview.platformURL}
              permalink={workshop.permalink}
            />
          </Flex>
        )}
      </Container>
      <Divider hidden />
      <Container>
        <Menu menus={genMenu(match.url)} menuProps={{ secondary: true, pointing: true }} />
        <GenRoute menus={genMenu(match.path)} skuCode={workshop.SKUCode} id={workshop.id} />
      </Container>
    </Fragment>
  );
});

export default WorkshopDetails;
