import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * convert seconds to hh:mm:ss duration format
 * @param {number} seconds
 * @return {string}
 */
export function secondsToTextDuration(seconds) {
  return dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
}

/**
 * convert hh:mm:ss duration to seconds
 * @param {string} text
 * @return {number}
 */
export function textDurationToSeconds(text) {
  const [hours, minutes, seconds] = text.split(':').map((s) => parseInt(s));
  return dayjs.duration({ seconds, minutes, hours }).asSeconds();
}
