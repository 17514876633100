import React from 'react';

const CE2_ERROR_CODE = {
  VALIDATION_ERROR: 'CE2_VALIDATION_ERROR',
  DUPLICATE_PERMALINK: 'CE2_DUPLICATE_PERMALINK',
  DUPLICATE_COURSE_CODE: 'CE2_DUPLICATE_COURSE_CODE',
  COURSE_CODE_ALREADY_PUBLISHED: 'CE2_COURSE_CODE_ALREADY_PUBLISHED',
};

export const transformCourseEditorErrorToMessage = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map(({ message, extensions }, _i) => {
      switch (message) {
        case CE2_ERROR_CODE.COURSE_CODE_ALREADY_PUBLISHED:
          return 'Cannot edit course code after published course.';
        case CE2_ERROR_CODE.DUPLICATE_COURSE_CODE:
          return 'This Course Code already exists.';
        case CE2_ERROR_CODE.DUPLICATE_PERMALINK:
          return 'This Permalink already exists.';
        case CE2_ERROR_CODE.VALIDATION_ERROR:
          return extensions.details && extensions.details.length > 0
            ? [].concat.apply(
                [],
                extensions.details.map((detail, i) => {
                  return [`${detail.dataPath} ${detail.message}`, <br key={i} />];
                })
              )
            : message;

        default:
          return message;
      }
    });
  }
  return error.message;
};
