import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

import { Flex, Box, Text } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';
import RichTextEditor from 'Components/RichTextEditor';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import MultipleSubQuestionsConnectionAnswersDropdownForm from './MultipleSubQuestionsConnectionAnswersDropdownForm';

const EditorBox = styled(Box)`
  flex: 1;
  & .text-editor {
    width: 100%;
  }
`;

const SubQuestionInput = withFormsy(
  ({ value: { subQuestions = [], correctAnswer = { value: [] } }, onChange, examId, readonly }) => {
    const handleOnChange = ({ subQuestions, correctAnswer }) => {
      onChange({}, { value: { subQuestions, correctAnswer } });
    };

    const handleLabelChange = (subQuestionData, index) => {
      const newSubQuestions = [...subQuestions];
      newSubQuestions[index] = { ...newSubQuestions[index], label: subQuestionData };

      handleOnChange({ subQuestions: newSubQuestions, correctAnswer });
    };

    const getHandleAnswerChange = (indexSubQuestion, indexAnswer) => {
      return (answerData) => {
        const newAnswers = [...correctAnswer.value];
        const answers = newAnswers.map((answer) => {
          if (answer.subQuestionValue === `${indexSubQuestion + 1}`) {
            return {
              ...answer,
              correctAnswerValue: answer.correctAnswerValue.map((answerValue, index) => {
                if (indexAnswer === index) {
                  return answerData;
                }
                return answerValue;
              }),
            };
          }
          return answer;
        });

        handleOnChange({ subQuestions, correctAnswer: { value: answers } });
      };
    };

    const handleAddSubQuestion = () => {
      const correctAnswerValue =
        correctAnswer && Array.isArray(correctAnswer.value) ? correctAnswer.value : [];

      handleOnChange({
        subQuestions: [...subQuestions, { label: '', value: `${subQuestions.length + 1}` }],
        correctAnswer: {
          value: [
            ...correctAnswerValue,
            { subQuestionValue: `${subQuestions.length + 1}`, correctAnswerValue: ['___'] },
          ],
        },
      });
    };

    const handleDeleteSubQuestion = () => {
      const newSubQuestions = [...subQuestions];
      const newAnswers = [...correctAnswer.value];
      newSubQuestions.pop();
      newAnswers.pop();

      handleOnChange({ subQuestions: newSubQuestions, correctAnswer: { value: newAnswers } });
    };

    const handleAddAnswer = (indexSubQuestion) => {
      handleOnChange({
        subQuestions,
        correctAnswer: {
          value: correctAnswer.value.map((correctAnswer) => {
            if (correctAnswer.subQuestionValue === `${indexSubQuestion + 1}`) {
              return {
                ...correctAnswer,
                correctAnswerValue: [...correctAnswer.correctAnswerValue, '___'],
              };
            }
            return correctAnswer;
          }),
        },
      });
    };

    const getHandleDeleteAnswer = (indexSubQuestion, indexAnswer) => {
      return () => {
        const newAnswers = [...correctAnswer.value];
        const answers = newAnswers.map((answer) => {
          if (answer.subQuestionValue === `${indexSubQuestion + 1}`) {
            return {
              ...answer,
              correctAnswerValue: answer.correctAnswerValue.filter(
                (_answerValue, index) => indexAnswer !== index
              ),
            };
          }
          return answer;
        });

        handleOnChange({ subQuestions, correctAnswer: { value: answers } });
      };
    };

    const subQuestionsDefault = useMemo(() => subQuestions, [subQuestions]);
    const hasSubQuestions = subQuestionsDefault.length >= 1;
    const answersDefault = useMemo(() => correctAnswer?.value, [correctAnswer]);
    const subQuestionRow = [];

    if (hasSubQuestions) {
      subQuestionsDefault.forEach((subQuestion, indexSubQuestion) => {
        const answers = answersDefault.find(
          (item) => item.subQuestionValue === `${indexSubQuestion + 1}`
        );

        subQuestionRow.push(
          <Flex
            my={2}
            mt={indexSubQuestion > 0 ? 4 : 0}
            key={`subQuestion_${indexSubQuestion}`}
            id={`subQuestion_${indexSubQuestion}`}
          >
            <Flex minWidth="84px">
              <Text as="label" color="black" mt="10px" mr={2}>
                {`เลขกำกับ ${String(indexSubQuestion + 1).padStart(2, '0')}`}
              </Text>
            </Flex>
            <Flex ml={2} flexDirection="column">
              <EditorBox>
                <RichTextEditor
                  onChange={(data) => handleLabelChange(data, indexSubQuestion)}
                  value={subQuestion.label}
                  contentFormat={`html`}
                  returnValueAsString
                  customControls={[
                    {
                      type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                      data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                    },
                  ]}
                />
              </EditorBox>
              <Flex mt={2} alignItems="flex-start">
                <Text fontSize={14} fontWeight={'bolder'} color="black" mr={2}>
                  {'ระบุคำตอบ:'}
                </Text>
                <Box>
                  {answers?.correctAnswerValue?.map((answer, indexAnswer) => {
                    return (
                      <MultipleSubQuestionsConnectionAnswersDropdownForm
                        key={`answer_${indexAnswer + answer}`}
                        id={`answer_${indexAnswer + answer}`}
                        isShowDeleteButton={answers.correctAnswerValue?.length > 1}
                        answer={answer}
                        indexAnswer={indexAnswer}
                        indexSubQuestion={indexSubQuestion}
                        onAnswerChange={getHandleAnswerChange(indexSubQuestion, indexAnswer)}
                        onDeleteAnswer={getHandleDeleteAnswer(indexSubQuestion, indexAnswer)}
                        style={{ marginTop: indexAnswer === 0 ? 0 : 8 }}
                      />
                    );
                  })}
                </Box>
              </Flex>
              {answers?.correctAnswerValue?.length < 5 ? (
                <Box key={`answer_add`} my={16} style={{ textAlign: 'center' }}>
                  <Button
                    disabled={readonly}
                    secondary
                    circular
                    type="button"
                    onClick={() => handleAddAnswer(indexSubQuestion)}
                    style={{ width: 210 }}
                  >
                    <Icon name="plus square" />
                    เพิ่มคำตอบ
                  </Button>
                </Box>
              ) : null}
            </Flex>
          </Flex>
        );
      });
      subQuestionRow.push(
        <Flex justifyContent="flex-end" key={`subQuestion_delete`}>
          <Button icon compact basic type="button" color="red" onClick={handleDeleteSubQuestion}>
            <Icon name="trash alternate outline" />
          </Button>
        </Flex>
      );
    }
    subQuestionRow.push(
      <Box my={2} key={`subQuestion_add`}>
        <Button
          disabled={readonly}
          secondary
          circular
          fluid
          type="button"
          onClick={handleAddSubQuestion}
        >
          <Icon name="plus square" />
          เพิ่มเลขกำกับ
        </Button>
      </Box>
    );
    return subQuestionRow;
  }
);

const MultipleSubQuestionsConnectionAnswersForm = (props) => {
  return (
    <Fragment>
      <Text as="label" fontWeight="bold">
        ข้อความที่กำหนด
      </Text>
      <SubQuestionInput {...props} />
    </Fragment>
  );
};

MultipleSubQuestionsConnectionAnswersForm.propTypes = {
  value: PropTypes.shape({
    subQuestions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: RichTextEditor.propTypes.value,
      })
    ),
    correctAnswer: PropTypes.shape({
      value: PropTypes.arrayOf({
        subQuestionValue: PropTypes.string,
        correctAnswerValue: PropTypes.array,
      }),
    }),
  }),
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};

export default MultipleSubQuestionsConnectionAnswersForm;
