import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { compose, mapProps } from 'recompose';
import { Container, Divider } from 'semantic-ui-react';
import { withTheme } from 'styled-components';

import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import Menu from 'Containers/Menu';
import { getWorkshopBySKUCode } from 'GraphQL/query/Workshop.query';
import withServerConfig from 'GraphQL/util/withServerConfig';
import { GenRoute } from 'Util/Route';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import Details from './Routes/Details';
import Description from './Routes/Description';
import Batches from './Routes/WorkshopBatches';
import Highlight from './Routes/Highlight';
import TableOfContents from './Routes/TableOfContents';
import Gallery from './Routes/Gallery';
import FAQ from './Routes/FAQ';
import Testimonials from './Routes/Testimonials';
import { getRouteInfo } from './utils';
import CourseHeader from './Components/CourseHeader';
const genMenu = (matchUrl = '/workshop') => {
  const menus = [
    {
      path: `${matchUrl}/details`,
      title: 'Details',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: Details,
    },
    {
      path: `${matchUrl}/description`,
      title: 'Overview',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: Description,
    },
    {
      path: `${matchUrl}/contents`,
      title: 'Table of Contents',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: TableOfContents,
    },
    {
      path: `${matchUrl}/highlight`,
      title: 'Highlight',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: Highlight,
    },
    {
      path: `${matchUrl}/gallery`,
      title: 'Gallery',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: Gallery,
    },
    {
      path: `${matchUrl}/batches`,
      title: 'Batches',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: Batches,
    },
    {
      path: `${matchUrl}/faq`,
      title: 'Frequently Asked Questions',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: FAQ,
    },
    {
      path: `${matchUrl}/testimonials`,
      title: 'Testimonials',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: Testimonials,
    },
  ];
  return menus;
};

const EditWorkshopRoute = (props) => {
  const { skuCode, match, theme } = props;
  const { loading, error, data: queryData } = useQuery(getWorkshopBySKUCode, {
    variables: { SKUCode: skuCode },
  });

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;

  const contents = queryData?.sfWorkshopBySKUCode ?? {};
  const coverImageUrl = contents?.coverImage?.assetUrl ?? '';
  const cardImageUrl = contents?.cardImage?.assetUrl ?? '';
  const { title, subtitle } = contents;
  const headers = { title, subtitle, skuCode, coverImageUrl, cardImageUrl };
  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      <Container
        fluid
        style={{
          backgroundColor: contents.backgroundColor || theme.colors.primary,
        }}
      >
        <Container>
          <CourseHeader {...headers} />
        </Container>
      </Container>
      <Divider hidden />
      <Container>
        <Menu menus={genMenu(match.url)} menuProps={{ secondary: true, pointing: true }} />
        <GenRoute menus={genMenu(match.url)} skuCode={skuCode} />
      </Container>
    </Fragment>
  );
};

export default compose(mapProps(getRouteInfo), withTheme, withServerConfig())(EditWorkshopRoute);
