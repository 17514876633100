import React from 'react';

import QueryComponent from 'GraphQL/util/QueryComponent';

import { getBundle } from '../../GraphQL/query/Products.query';

import BundleDetails from './BundleDetails';

const BundleDetailsRoute = (props) => {
  const { match = {} } = props;
  const { params = {} } = match;
  const { SKUCode } = params;
  return (
    <QueryComponent
      query={getBundle}
      variables={{
        SKUCode,
      }}
      loadingOverlay
    >
      {(data) => {
        return <BundleDetails {...props} bundle={data && data.sfBoProductBundle} />;
      }}
    </QueryComponent>
  );
};

export default BundleDetailsRoute;
