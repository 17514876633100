import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import {
  CHOICE_VARIANTS,
  HtmlView,
  QuizBackButton,
  QuizButtonText,
  QuizChoiceLayoutWithSpace,
  QuizChoiceWithRadio,
  QuizChoiceWithCheckbox,
  QuizFooterContainer,
  QuizFooterInnerContainer,
  QuizHeader,
  QuizInnerLayout,
  QuizLayout,
  QuizNextButton,
  QuizNumberAnswer,
  QuizQuestion,
  QuizTimer,
  theme,
} from '@skooldio/skooldio-classroom-components';

import { Box, Flex, Text } from 'Components/Base';

import { EXAM_QUESTION_TYPE } from '../constants';

const SolutionPreview = ({ children }) => {
  const [showSolution, setShowSolution] = useState(true);
  return (
    <>
      {showSolution && (
        <Box mt={5} color="text">
          <Text lineHeight="20px" fontSize="18px" fontWeight="600" mb={3}>
            เฉลยละเอียด
          </Text>
          <HtmlView>{children}</HtmlView>
        </Box>
      )}
      <QuizButtonText
        mt={5}
        onClick={(e) => {
          e.preventDefault();
          setShowSolution((previousShow) => !previousShow);
        }}
        mx={'auto'}
      >
        {showSolution ? 'Hide Solution' : 'Show Solution'}
      </QuizButtonText>
    </>
  );
};

const QuestionPreview = ({
  choices = [],
  correctAnswer,
  index,
  onClose,
  precision,
  question,
  solution,
  timeRemaining,
  total,
  type,
  ...props
}) => {
  const isTimeRemaining = timeRemaining ? true : false;

  return (
    <ThemeProvider theme={theme}>
      <QuizLayout
        subHeader={isTimeRemaining}
        style={{ fontFamily: '"Noto Sans", "Noto Sans Thai", sans-serif', pointerEvents: 'auto' }}
        {...props}
      >
        <QuizHeader onBack={onClose} onClose={onClose}>
          {index} of {total}
        </QuizHeader>
        {isTimeRemaining && (
          <Flex justifyContent="flex-end" alignItems="center" px={6}>
            <QuizTimer>{timeRemaining}</QuizTimer>
          </Flex>
        )}
        <QuizInnerLayout>
          <Box py={5} px={6}>
            <QuizQuestion mb={5} pt={4}>
              {question}
            </QuizQuestion>
            {(type === EXAM_QUESTION_TYPE.MULTIPLE_CHOICE ||
              type === EXAM_QUESTION_TYPE.MULTIPLE_CHOICE_DIFFERENT_SCORE) && (
              <QuizChoiceLayoutWithSpace>
                {choices.map((choice, index) => {
                  const variant =
                    correctAnswer === choice.value
                      ? CHOICE_VARIANTS.CORRECT
                      : CHOICE_VARIANTS.DEFAULT;

                  return (
                    <QuizChoiceWithRadio
                      key={choice?.id ?? index}
                      checked={variant !== CHOICE_VARIANTS.DEFAULT}
                      label={choice?.label}
                      variant={variant}
                    />
                  );
                })}
              </QuizChoiceLayoutWithSpace>
            )}
            {(type === EXAM_QUESTION_TYPE.MULTIPLE_SELECT ||
              type === EXAM_QUESTION_TYPE.MULTIPLE_SELECT_WITH_CORRECT_ANSWERS) && (
              <QuizChoiceLayoutWithSpace>
                {choices.map((choice, index) => {
                  const isCorrectAnswer = correctAnswer.includes(choice.value);
                  const variant = isCorrectAnswer
                    ? CHOICE_VARIANTS.CORRECT
                    : CHOICE_VARIANTS.DEFAULT;

                  return (
                    <QuizChoiceWithCheckbox
                      key={choice?.id ?? index}
                      checked={variant !== CHOICE_VARIANTS.DEFAULT}
                      label={choice?.label}
                      variant={variant}
                    />
                  );
                })}
              </QuizChoiceLayoutWithSpace>
            )}
            {type === EXAM_QUESTION_TYPE.FILL_NUMBER && (
              <QuizNumberAnswer precisionNumber={precision} value={correctAnswer} readable />
            )}
            <SolutionPreview>{solution}</SolutionPreview>
          </Box>
          {onClose && (
            <QuizFooterContainer>
              <QuizFooterInnerContainer>
                <>
                  <QuizBackButton onClick={(e) => e.preventDefault()}>ข้อก่อนหน้า</QuizBackButton>
                  <QuizNextButton onClick={(e) => e.preventDefault()}>ข้อถัดไป</QuizNextButton>
                </>
              </QuizFooterInnerContainer>
            </QuizFooterContainer>
          )}
        </QuizInnerLayout>
      </QuizLayout>
    </ThemeProvider>
  );
};

QuestionPreview.propTypes = {
  choices: PropTypes.array,
  correctAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default QuestionPreview;
