import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { GenSwitchRoute } from 'Util/Route';
import SideMenuLayout from 'Components/SideMenuLayout';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import ImportDiscountCodesRoute from './Routes/ImportDiscountCodes';
import ImportShipmentTracking from './Routes/ImportShipmentTracking';

const ImportDataRouteIndex = () => (
  <Redirect to={{ title: '', pathname: '/sf/file-import/discount-codes' }} />
);

const defaultRoute = {
  path: '/sf/file-import',
  exact: true,
  roles: [],
  route: ImportDataRouteIndex,
};

const menus = [
  {
    path: '/sf/file-import/discount-codes',
    title: 'DiscountCodes',
    menuName: 'DiscountCodes',
    icon: 'university',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.COMMON.MARKETER,
      APP_PERMISSION.DISCOUNT_CODE.LIST.VIEW,
      APP_PERMISSION.FILE_IMPORT.DISCOUNT_CODE.LIST.VIEW,
    ],
    route: ImportDiscountCodesRoute,
  },
  {
    path: '/sf/file-import/shipment-tracking',
    title: 'ShipmentTracking',
    menuName: 'ShipmentTracking',
    icon: 'university',
    exact: true,
    private: true,
    roles: [
      APP_PERMISSION.COMMON.ADMINISTRATOR,
      APP_PERMISSION.FILE_IMPORT.SHIPMENT_TRACKING.LIST.VIEW,
    ],
    route: ImportShipmentTracking,
  },
];

export default class SFFileImportRoute extends Component {
  render() {
    return (
      <SideMenuLayout header={'File Types'} menus={menus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute routes={[...menus, defaultRoute]} />
      </SideMenuLayout>
    );
  }
}
