import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getWorkshop } from '../../GraphQL/query/Products.query';
import { updateWorkshopCategoriesMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUCategoriesForm from '../SKUCategoriesForm';
import { SKU_CATEGORY } from '../../Domains/constants';

class WorkshopCategoriesEditor extends Component {
  static propTypes = {
    id: PropTypes.string,
    skuCode: PropTypes.string,
  };

  render() {
    const { id, skuCode } = this.props;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getWorkshop}
          editMutation={updateWorkshopCategoriesMutation}
          isEdit={true}
          id={skuCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode: skuCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              ...form,
            },
          })}
        >
          {({ data, onSubmit }) => {
            return (
              <SKUCategoriesForm
                skuCategory={SKU_CATEGORY.WORKSHOP}
                initialData={data && data.sfBoProductWorkshop}
                onSubmit={onSubmit}
              />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default WorkshopCategoriesEditor;
