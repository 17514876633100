import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import withFormsy from 'Components/Form/withFormsy';
import { Box } from 'Components/Base';
import {
  VideoPlayerPreviewInput,
  VIDEO_TYPE as BASE_VIDEO_TYPE,
} from 'src/Routes/Workshop/Components/VideoPlayerPreviewInput';
import delve from 'Util/Delve';

import WorkshopImageUpload from '../WorkshopImageUpload';

export const VIDEO_TYPE = BASE_VIDEO_TYPE;

const genTypeOptions = (type) => {
  return Object.values(type).map((type, index) => ({
    key: `${type}-${index}`,
    text: type,
    value: type,
  }));
};

const TestimonialsMediaUpload = ({ skuCode, value, onChange, ...inputProps }) => {
  const [media, setMedia] = useState({
    content: delve(value, 'content', {
      videoType: VIDEO_TYPE.YOUTUBE,
      key: '',
      bucket: '',
    }),
    assetUrl: delve(value, 'assetUrl', ''),
    type: delve(value, 'type', MEDIA_TYPE.VIDEO),
  });

  const onTypeChange = (e, { value }) => {
    const baseMedia = { key: '', bucket: '' };
    const updatedMedia =
      value === MEDIA_TYPE.VIDEO
        ? { content: { videoType: VIDEO_TYPE.YOUTUBE, ...baseMedia }, type: value, assetUrl: '' }
        : { content: { videoType: null, ...baseMedia }, type: value, assetUrl: undefined };

    setMedia(updatedMedia);
    onChange({}, { value: updatedMedia });
  };

  const onVideoTypeChange = (e, { value }) => {
    const updatedMedia = {
      ...media,
      content: { videoType: value, key: '', bucket: '', assetUrl: '' },
    };

    setMedia(updatedMedia);
    onChange({}, { value: updatedMedia });
  };

  const onVideoIdChange = (e, { value }) => {
    const updatedMedia = {
      ...media,
      content: { ...media.content, key: value, bucket: '' },
    };

    setMedia(updatedMedia);
    onChange({}, { value: updatedMedia });
  };

  const onImageUpdate = ({ key, bucket, url: assetUrl }) => {
    const updatedMedia = {
      ...media,
      assetUrl,
      content: { videoType: null, key, bucket },
    };

    setMedia(updatedMedia);
    onChange({}, { value: updatedMedia });
  };

  const renderUploadField = () => {
    if (media.type === MEDIA_TYPE.IMAGE) {
      return (
        <Box width="344px">
          <WorkshopImageUpload
            changeImgText={'Change Testimonial Image'}
            skuCode={skuCode}
            imageUrl={media.assetUrl}
            onImageUpdate={onImageUpdate}
          />
        </Box>
      );
    } else {
      return (
        <VideoPlayerPreviewInput
          value={media.content.key}
          onChange={onVideoIdChange}
          type={media.content.videoType}
        />
      );
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <Dropdown
          upward
          search
          selection
          onChange={onTypeChange}
          value={media.type}
          options={genTypeOptions(MEDIA_TYPE)}
        />
        {media.type === MEDIA_TYPE.VIDEO && (
          <Dropdown
            upward
            search
            selection
            onChange={onVideoTypeChange}
            value={media.content.videoType}
            options={genTypeOptions(VIDEO_TYPE)}
          />
        )}
      </Box>
      {renderUploadField()}
    </Box>
  );
};

export const MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};
export default withFormsy(TestimonialsMediaUpload);
