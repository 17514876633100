import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

const MuiDateTimePicker = ({ format, value, onChange, disabled, ...props }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <KeyboardDateTimePicker
      variant="inline"
      format={format}
      margin="normal"
      id="date-picker-inline"
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...props}
    />
  </MuiPickersUtilsProvider>
);

MuiDateTimePicker.defaultProps = {
  format: 'LLL',
  onChange: () => {},
};

export default MuiDateTimePicker;
