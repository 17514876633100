import React, { Component } from 'react';

import FileImport from '../../Containers/FileImport';
import { FILE_TYPE } from '../../common';

export default class ImportDiscountCodes extends Component {
  render() {
    return <FileImport title="Create Discount Codes" type={FILE_TYPE.NEW_DISCOUNT_CODE} />;
  }
}
