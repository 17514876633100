import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, Label, List } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import { SemanticButton as Button } from '../../../Components/Base/Button';
import Table from '../../../Components/DataTable';
import { RolesLabel } from '../../../Components/UserItem';
import getConfig from '../../../Util/Config';
const { lineHideColumns, enabledAppPermission } = getConfig();
const excludeColumns = () => ['id', 'referenceId'];
const getColumns = () => {
  const allColumns = [
    {
      Header: 'Id',
      accessor: 'id',
      width: 70,
    },
    {
      Header: 'Ref. Id',
      accessor: 'referenceId',
      width: 90,
    },
    {
      Header: 'Name',
      Cell: (row) => getDisplayName(row.original.firstname, row.original.lastname),
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Verified',
      accessor: 'emailVerified',
      Cell: (row) => (
        <List>
          <List.Item>
            <List.Content verticalAlign="middle">
              {!row.original.isPassportUser && (
                <List.Description>
                  <Label color={row.original.emailVerified ? 'green' : 'red'} size="mini">
                    {row.original.emailVerified ? 'Verified' : 'Pending Verification'}
                  </Label>
                </List.Description>
              )}
            </List.Content>
          </List.Item>
        </List>
      ),
      maxWidth: 120,
    },
    {
      Header: 'Roles',
      accessor: enabledAppPermission ? 'appRoles' : 'roles',
      width: 150,
      Cell: (row) => (
        <Flex flexWrap="wrap">
          <RolesLabel roles={row.value} size={'small'} />
        </Flex>
      ),
    },
    {
      Header: 'Actions',
      Cell: (row) => (
        <Fragment>
          <Link to={{ pathname: `/users/search/${row.original.id}` }}>
            <Button size="small">
              <Icon name="user" /> View
            </Button>
          </Link>
        </Fragment>
      ),
      maxWidth: 100,
    },
  ];
  if (lineHideColumns) {
    return allColumns.filter((column) => !excludeColumns().includes(column.accessor));
  }
  return allColumns;
};

const getDisplayName = (firstname = '', lastname = '') =>
  `${firstname ? firstname : '<No Firstname>'} ${lastname ? lastname : '<No Lastname>'}`;

export default class AllUsersTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        email: PropTypes.string,
        emailVerified: PropTypes.bool,
      })
    ),
  };

  render() {
    const { data } = this.props;
    return <Table data={data} columns={getColumns()} className="-highlight" defaultPageSize={10} />;
  }
}
