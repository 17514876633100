import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';

import ActionBar from '../Common/ActionBar';
import { AssetPropTypes } from '../Common/AssetPropTypes';
import FormGenerator from '../../Form/FormGenerator';

const inputList = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Title',
    inputName: 'title',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Description',
    inputName: 'description',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Channel Name',
    inputName: 'channelName',
    inputProps: {
      required: true,
    },
  },
];

class LiveVideo extends Component {
  state = {
    channelName: this.props.content && this.props.content.channelName,
    formData: { title: this.props.title, description: this.props.description },
  };
  handleFormChange = (value) => {
    const { title, description, channelName } = value;
    this.setState({ formData: { title, description }, channelName });
  };

  handleAction = () => {
    const { formData, channelName } = this.state;

    const resultObject = {
      ...formData,
      content: { channelName, provider: this.props.defaultVideoType },
    };
    this.props.onCreateMaterial(resultObject);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ channelName: this.props.content && this.props.content.channelName });
    }
  }
  render() {
    const {
      title,
      description,
      content,
      materialTypeString,
      newAsset,
      loading,
      onCancel,
    } = this.props;
    const { channelName } = content;

    return (
      <Box>
        <Box>
          <FormGenerator
            fields={inputList}
            showAction={false}
            initialData={{ title, description, channelName }}
            onChange={this.handleFormChange}
          />
        </Box>
        <Box mt={2}>
          <ActionBar
            isCreate={newAsset}
            onAction={this.handleAction}
            materialTypeString={materialTypeString}
            onCancel={onCancel}
            loading={loading}
          />
        </Box>
      </Box>
    );
  }
}

LiveVideo.propTypes = {
  ...AssetPropTypes,
  content: PropTypes.shape({
    channelName: PropTypes.string,
    provider: PropTypes.string,
  }),
};

LiveVideo.defaultProps = {
  materialTypeString: 'Live Video',
};

export default LiveVideo;
