import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import omitDeep from 'Util/omitDeep';
import delve from 'Util/Delve';
import { getWorkshopTableOfContents } from 'GraphQL/query/Workshop.query.js';
import { sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation.js';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import TableOfContentView from '../WorkshopContent';

export default function WorkshopContent({ skuCode }) {
  const { loading, error, data: queryData } = useQuery(getWorkshopTableOfContents, {
    variables: { SKUCode: skuCode },
    fetchPolicy: 'network-only',
  });
  const [
    updateWorkshop,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(sfUpdateWorkshop);

  function handleUpdateContents(data) {
    const clonedData = JSON.parse(JSON.stringify(data));
    const formatData = omitDeep(clonedData, ['id', '__typename']);
    updateWorkshop({
      variables: {
        SKUCode: skuCode,
        data: {
          tableOfContents: formatData,
        },
      },
    });
  }

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  const contents = delve(queryData, 'sfWorkshopBySKUCode.tableOfContents', []);

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={false}
        response={delve(mutationResponse, 'sfUpdateWorkshop')}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <TableOfContentView
        loading={mutationLoading}
        contents={contents}
        onUpdateContents={handleUpdateContents}
        accessor={{ title: 'title', description: 'description' }}
      />
    </Fragment>
  );
}
