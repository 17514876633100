import React from 'react';
import PropTypes from 'prop-types';

import ConfigurableQuestionFormBody from './ConfigurableQuestionFormBody';
import ConfigurableQuestionFormHeader from './ConfigurableQuestionFormHeader';

const ConfigurableQuestionForm = (props) => {
  return (
    <>
      <ConfigurableQuestionFormHeader
        examItemId={props.question?.id}
        onComplete={props.onCancelled}
        {...props}
      />
      <ConfigurableQuestionFormBody initialData={props.question} {...props} />
    </>
  );
};

ConfigurableQuestionForm.propTypes = {
  question: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  publishMode: PropTypes.bool,
  examId: PropTypes.string,
};

ConfigurableQuestionForm.defaultProps = {
  question: {},
  onSubmit: () => {},
  onCancelled: () => {},
  isEdit: false,
  publishMode: false,
};

export default ConfigurableQuestionForm;
