import { CollectionPermissionKeys } from './collection';
import { DiscountCodePermissionKeys } from './discountCode';
import { OnlineCoursePermissionKeys } from './onlineCourse';
import { OnlineCourseCategoryPermissionKeys } from './onlineCourseCategory';
import { BundlePermissionKeys } from './bundle';
import { RedemptionOrderPermissionKeys } from './redemptionOrder';
import { SalesOrderPermissionKeys } from './salesOrder';
import { SubscriptionPermissionKeys } from './subscription';
import { SubscriptionCategoryPermissionKeys } from './subscriptionCategory';
import { TrialOrderPermissionKeys } from './trialOrder';
import { SubscriptionManagementPermissionKeys } from './subscriptionManagement';
import { CommonPermissionKeys } from './common';
import { CoursePermissionKeys } from './course';
import { AnnouncementPermissionKeys } from './announcement';
import { ExamPermissionKey } from './exams';
import { UserPermissionKey } from './user';
import { CourseTeamPermissionKeys } from './courseTeam';
import { ExamTeamPermissionKey } from './examsTeam';
import { BookPermissionKeys } from './book';
import { CollectionCategoryPermissionKeys } from './collectionCategory';
import { InstructorPermissionKeys } from './instructor';
import { SalesV1PermissionKeys } from './salesV1';
import { CertificatePermissionKeys } from './certificate';
import { WorkshopCategoryPermissionKeys } from './workshopCategory';
import { WorkshopPermissionKeys } from './workshop';
import { FileImportPermissionKeys } from './fileImport';
import { ShipmentPermissionKeys } from './shipment';

const APP_PERMISSION = {
  // Products
  COLLECTION: CollectionPermissionKeys,
  ONLINE_COURSE: OnlineCoursePermissionKeys,
  SUBSCRIPTION: SubscriptionPermissionKeys,
  BUNDLE: BundlePermissionKeys,
  BOOK: BookPermissionKeys,
  INSTRUCTOR: InstructorPermissionKeys,
  WORKSHOP: WorkshopPermissionKeys,
  ONLINE_COURSE_CATEGORY: OnlineCourseCategoryPermissionKeys,
  SUBSCRIPTION_CATEGORY: SubscriptionCategoryPermissionKeys,
  COLLECTION_CATEGORY: CollectionCategoryPermissionKeys,
  WORKSHOP_CATEGORY: WorkshopCategoryPermissionKeys,
  // Sales
  REDEMPTION_ORDER: RedemptionOrderPermissionKeys,
  SALES_ORDER: SalesOrderPermissionKeys,
  TRIAL_ORDER: TrialOrderPermissionKeys,
  DISCOUNT_CODE: DiscountCodePermissionKeys,
  SUBSCRIPTION_MANAGEMENT: SubscriptionManagementPermissionKeys,
  // CoursesEditor
  COURSE: CoursePermissionKeys,
  COURSE_TEAM: CourseTeamPermissionKeys,
  // Announcement
  ANNOUNCEMENT: AnnouncementPermissionKeys,
  // Common roles
  COMMON: CommonPermissionKeys,
  //EXAM
  EXAM: ExamPermissionKey,
  EXAM_TEAM: ExamTeamPermissionKey,
  //USER
  USER: UserPermissionKey,
  //SALESV1
  SALESV1: SalesV1PermissionKeys,
  //CERTIFICATE
  CERTIFICATE: CertificatePermissionKeys,
  //SFFileImport
  FILE_IMPORT: FileImportPermissionKeys,
  SHIPMENT: ShipmentPermissionKeys,
};
export default APP_PERMISSION;
