import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Container } from 'semantic-ui-react';

import Loading from 'Components/Loading';
import { Box } from 'Components/Base';
import NoMatchRoute from 'Routes/NoMatch/';
import { getUserAssignment, getUserAssignmentCourseSummary } from 'GraphQL/query/Courses.query';

import AssignmentBreadcrumb from '../AssignmentBreadcrumb';

import UserAssignmentDetail from './Containers/UserAssignmentDetail';
import AssignmentSubmissionLog from './Containers/AssignmentSubmissionLog';
import AssignmentReviewForm from './Containers/AssignmentReviewForm';

const UserAssignment = (props) => {
  const userAssignmentId = props?.match?.params?.userAssignmentId;
  const courseId = props?.match?.params?.courseId;

  const { data: queryData, loading: queryLoading } = useQuery(getUserAssignment, {
    variables: { userAssignmentId: userAssignmentId },
    skip: !userAssignmentId,
    fetchPolicy: 'network-only',
  });
  const { loading: loadingUACS } = useQuery(getUserAssignmentCourseSummary, {
    variables: { sourceCourseId: courseId },
    skip: !courseId,
  });
  const { loading: loadingUA } = useQuery(getUserAssignment, {
    variables: { userAssignmentId: userAssignmentId },
    skip: !userAssignmentId,
  });

  if (queryLoading || loadingUA || loadingUACS) return <Loading />;
  if (!queryData) return <NoMatchRoute />;

  return (
    <Container>
      <AssignmentBreadcrumb />
      <UserAssignmentDetail />
      <Box mt={4} />
      <AssignmentSubmissionLog />
      <Box mt={4} />
      <AssignmentReviewForm />
    </Container>
  );
};

UserAssignment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userAssignmentId: PropTypes.string,
    }),
  }),
};

export default UserAssignment;
