import React, { Fragment, Component } from 'react';
import { Button, Icon, Radio } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { Flex } from 'Components/Base';

import apolloClient from '../../../GraphQL';
import QueryTable from '../../../GraphQL/util/QueryTable';
import ConfirmationButton from '../../../Components/ConfirmationButton';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';
import { getCertificateItems } from '../../../GraphQL/query/Certificate.query';
import { deleteCertificateItemMutation } from '../../../GraphQL/mutation/Certificate.mutation';

import CertificateItemModal from './CertificateItemModal';

const getCertificateColumns = (handleDelete, handleToggleActive, variables) => [
  {
    Header: 'Certificate Item ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Type',
    accessor: 'objectType',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Code',
    accessor: 'objectCode',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Display Name',
    accessor: 'displayName',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Action',
    accessor: 'id',
    isSearchAble: false,
    Cell: ({ original }) => {
      const certificateItemId = original.id;
      return (
        <Fragment>
          <Button.Group>
            <CertificateItemModal
              isEdit
              variables={variables}
              certificateItemId={certificateItemId}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="edit" />
                </Button>
              }
            />

            <ConfirmationButton
              headerText={`Delete?`}
              contentText={'This will be permanently deleted from the system.'}
              confirmText={'Delete'}
              onConfirm={() => {
                handleDelete(certificateItemId);
              }}
            >
              <Icon name="trash alternate outline" />
            </ConfirmationButton>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class Certificates extends Component {
  static propTypes = {
    certificateId: PropTypes.string,
  };

  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: { certificateId: this.props.certificateId },
    order: { field: 'displayName', type: 'ASC' },
  };

  handleDelete = async (certificateItemId) => {
    const { search, paging, order } = this.state;
    try {
      await apolloClient.mutate({
        mutation: deleteCertificateItemMutation,
        variables: { id: certificateItemId },
        refetchQueries: [
          {
            query: getCertificateItems,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { certificateId } = this.props;
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <CertificateItemModal
            certificateId={certificateId}
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New
              </Button>
            }
          />
        </Flex>
        <QueryTable
          showSearchPanel={false}
          type="certificateItems"
          columns={getCertificateColumns(this.handleDelete, this.handleToggleActive, variables)}
          query={getCertificateItems}
          search={search}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default Certificates;
