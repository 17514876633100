import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import katex from 'katex/dist/contrib/auto-render.min.js';

import getConfig from 'Util/Config';

const defaultOnLinkClick = (node) => {
  node.target = '_blank';
};

const addOnClickEventHandler = (contentBoxRef, onLinkClick) => {
  if (contentBoxRef.current.innerHTML && typeof onLinkClick === 'function') {
    const aTagList = contentBoxRef.current.querySelectorAll('a');
    aTagList.forEach((node) => onLinkClick(node));
  }
};

const isAllowedIframeSource = (srcAttribute) => {
  const { allowedIframeSourcesRegex } = getConfig();
  return (
    srcAttribute && allowedIframeSourcesRegex.filter((regex) => regex.test(srcAttribute)).length > 0
  );
};

DOMPurify.addHook('beforeSanitizeElements', (currentNode) => {
  if (currentNode.tagName === 'IFRAME') {
    const srcAttribute = currentNode.getAttribute('src');
    if (isAllowedIframeSource(srcAttribute)) {
      return currentNode;
    }
    currentNode.remove();
  }
});

const katexConfig = {
  delimiters: [
    { left: '$$', right: '$$', display: true },
    { left: '$', right: '$', display: false },
    { left: '\\(', right: '\\)', display: false },
    { left: '\\[', right: '\\]', display: true },
  ],
  throwOnError: false,
};

const KatexView = ({
  rawContent,
  children,
  className,
  style,
  onLinkClick = defaultOnLinkClick,
}) => {
  const sanitizedMath = DOMPurify.sanitize(rawContent, { ADD_TAGS: ['iframe'] });

  const ref = useRef(document.createElement('div'));
  useEffect(() => {
    addOnClickEventHandler(ref, onLinkClick);
  }, [onLinkClick, ref]);

  useEffect(() => {
    katex(ref.current, katexConfig);
  }, []);

  return (
    <>
      <div
        ref={ref}
        className={className}
        style={{
          width: '100%',
          ...style,
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedMath || children }}
      />
    </>
  );
};

KatexView.propTypes = {
  rawContent: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  onLinkClick: PropTypes.func,
};
KatexView.defaultProps = {
  script:
    'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/MathJax.js?config=TeX-MML-AM_HTMLorMML',
};

export default KatexView;
