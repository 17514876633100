import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withFormsy from 'Components/Form/withFormsy';
import QueryComponent from 'GraphQL/util/QueryComponent';
import SelectorInput from 'Routes/Reports/Components/SelectorInput/SelectorInput';

import { getListSubscriptions } from '../../../Products/GraphQL/query/Products.query';

const parseValue = ({ sfBoProductSubscriptions }) => {
  return sfBoProductSubscriptions.subscriptions.map((subscription) => ({
    key: subscription.id,
    value: subscription.id,
    text: subscription.SKUCode,
  }));
};

class SubscriptionSelectorInputQuery extends Component {
  static propTypes = {};

  render() {
    return (
      <QueryComponent
        query={getListSubscriptions}
        variables={{
          paging: { currentPage: 0, pageSize: 1000 },
          search: {},
          order: { field: 'title', type: 'ASC' },
        }}
      >
        {(data) => {
          return <SelectorInput options={parseValue(data)} {...this.props} />;
        }}
      </QueryComponent>
    );
  }
}

export default SubscriptionSelectorInputQuery;
export const FormsySubscriptionSelectorInput = withFormsy(SubscriptionSelectorInputQuery);
