import React, { Component } from 'react';

import { Flex, Text } from 'Components/Base';
import { purchaseOrders } from 'GraphQL/query/Purchase.query';
import QueryTable, { DATA_FORMAT } from 'GraphQL/util/QueryTable';
import { default as COLUMN_TYPE } from 'Components/Search/SearchFormGenerator/constantType';

import {
  PURCHASE_ORDER_PAYMENT_METHOD,
  PURCHASE_ORDER_CUSTOMER_TYPE,
} from '../../Payments/common/constants';

const PurchaseColumns = (variables) => [
  {
    Header: 'Refer Code',
    accessor: 'referCode',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Date',
    accessor: 'updatedAt',
    width: 100,
    type: COLUMN_TYPE.DATE,
    isSearchAble: false,
  },
  {
    Header: 'E-Mail',
    accessor: 'email',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Full Name',
    accessor: 'fullname',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Mobile',
    accessor: 'mobile',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Customer Type',
    accessor: 'customerType',
    width: 150,
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: [
      {
        key: PURCHASE_ORDER_CUSTOMER_TYPE.ALL,
        value: PURCHASE_ORDER_CUSTOMER_TYPE.ALL,
        text: PURCHASE_ORDER_CUSTOMER_TYPE.ALL,
      },
      {
        key: PURCHASE_ORDER_CUSTOMER_TYPE.CORPORATE,
        value: PURCHASE_ORDER_CUSTOMER_TYPE.CORPORATE,
        text: PURCHASE_ORDER_CUSTOMER_TYPE.CORPORATE,
      },
      {
        key: PURCHASE_ORDER_CUSTOMER_TYPE.INDIVIDUAL,
        value: PURCHASE_ORDER_CUSTOMER_TYPE.INDIVIDUAL,
        text: PURCHASE_ORDER_CUSTOMER_TYPE.INDIVIDUAL,
      },
    ],
  },
  {
    Header: 'Invoice No.',
    accessor: 'accountingNumber',
    width: 120,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Course Refer Id',
    accessor: 'courseReferId',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Course Title',
    accessor: 'courseTitle',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
    searchField: 'courseTitle',
  },
  {
    Header: 'Quantity',
    accessor: 'purchaseItems[0].quantity',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    searchField: 'quantity',
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: [
      {
        key: PURCHASE_ORDER_PAYMENT_METHOD.BANKING,
        value: PURCHASE_ORDER_PAYMENT_METHOD.BANKING,
        text: 'BANKING',
      },
      {
        key: PURCHASE_ORDER_PAYMENT_METHOD.BANK_TRANSFER,
        value: PURCHASE_ORDER_PAYMENT_METHOD.BANK_TRANSFER,
        text: 'BANK TRANSFER',
      },
      {
        key: PURCHASE_ORDER_PAYMENT_METHOD.CREDIT_CARD,
        value: PURCHASE_ORDER_PAYMENT_METHOD.CREDIT_CARD,
        text: 'CREDIT CARD',
      },
    ],
  },
  {
    Header: 'Payment Amount',
    accessor: 'amount',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: true,
  },
  {
    Header: 'Fee Amount',
    accessor: 'feeAmount',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: true,
  },
  {
    Header: 'Discount Code',
    accessor: 'withCode',
    width: 100,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Discount Incl. VAT',
    accessor: 'discountWithoutHoldingAmount',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_FLOAT,
    isSearchAble: true,
  },
];

class PurchaseOrder extends Component {
  state = {
    search: {},
    paging: { currentPage: 0, pageSize: 10 },
    order: { field: 'updatedAt', type: 'DESC' },
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Purchase Order</Text.Header>
        </Flex>
        <QueryTable
          type="purchaseOrders"
          columns={PurchaseColumns(variables)}
          query={purchaseOrders}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default PurchaseOrder;
