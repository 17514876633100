import React, { Component } from 'react';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBundle } from '../../GraphQL/query/Products.query';
import { updateBundleInstructorsMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUInstructorForm from '../SKUInstructorForm';

const getInitData = (data) => {
  if (data && data.sfBoProductBundle && data.sfBoProductBundle.instructors) {
    return {
      instructorIds: data.sfBoProductBundle.instructors.map((instructor) => instructor.id),
    };
  }
  return [];
};

class BundleInstructorsEditor extends Component {
  render() {
    const { bundle = {} } = this.props;
    const { id, SKUCode } = bundle;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getBundle}
          editMutation={updateBundleInstructorsMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            instructorIds: form.instructorIds,
          })}
        >
          {({ data, onSubmit }) => {
            return <SKUInstructorForm initialData={getInitData(data)} onSubmit={onSubmit} />;
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default BundleInstructorsEditor;
