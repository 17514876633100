import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { GenRoute } from '../../Util/Route';
import SideMenuLayout from '../../Components/SideMenuLayout';

import PriceSales from './Price';
import DiscountCodeSales from './Discount';
import PurchaseOrder from './PurchaseOrder';
const SalesIndex = () => <Redirect to={{ pathname: '/sales/price' }} />;
const defaultSales = {
  path: '/sales',
  exact: true,
  private: true,
  roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.SALESV1.LIST.VIEW],
  route: SalesIndex,
};
const salesMenus = [
  {
    path: '/sales/price',
    title: 'PRICE',
    menuName: 'Price',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.SALESV1.LIST.VIEW],
    route: PriceSales,
    icon: 'dollar',
  },
  {
    path: '/sales/discount_code',
    title: 'DISCOUNT CODE',
    menuName: 'Discount Code',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.SALESV1.LIST.VIEW],
    route: DiscountCodeSales,
    icon: 'tags',
  },
  {
    path: '/sales/purchase_order',
    title: 'PURCHASE ORDER',
    menuName: 'Purchase Order',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.SALESV1.LIST.VIEW],
    route: PurchaseOrder,
    icon: 'list',
  },
];

export default class SalesRoute extends Component {
  render() {
    return (
      <SideMenuLayout menus={salesMenus} header={'Sales Management'}>
        <GenRoute menus={[...salesMenus, defaultSales]} />
      </SideMenuLayout>
    );
  }
}
