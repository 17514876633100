import gql from 'graphql-tag';

import { TEAM_FRAGMENT } from 'Routes/Users/Graphql/query/Team.query';

export const MutationResponseFragment = gql`
  fragment MutationResponseFragment on UserMutationResponse {
    code
    success
    message
  }
`;

export const UserDetailSmallFragment = gql`
  fragment UserDetailSmallFragment on User {
    id
    firstname
    lastname
    email
    emailVerified
    isPassportUser
    isDisabled
    referenceId
    roles
    appRoles
  }
`;

export const UserDetailLargeFragment = gql`
  fragment UserDetailLargeFragment on User {
    id
    ...UserDetailSmallFragment
    displayName
    profilePic
    details {
      organizationId
      organization {
        id
        organizationName
      }
      phoneNumber
      position
      citizenId
    }
    additionalData {
      additional1
      additional2
      additional3
      additional4
      additional5
      additional6
      additional7
      additional8
      additional9
      additional10
    }
  }
  ${UserDetailSmallFragment}
`;

export const RoleDataFragment = gql`
  fragment RoleDataFragment on UserRole {
    id
    principalId
    principalType
    roleId
    role {
      id
      name
    }
  }
`;

export const APP_ROLE_PERMISSION_FRAGMENT = gql`
  fragment APP_ROLE_PERMISSION_FRAGMENT on AppRole {
    id
    level
    name
  }
`;

export const SINGLE_USER_WITH_ROLE_PERMISSION = gql`
  fragment SINGLE_USER_WITH_ROLE_PERMISSION on User {
    id
    appRoles
    appRoleData {
      ...APP_ROLE_PERMISSION_FRAGMENT
    }
  }
  ${APP_ROLE_PERMISSION_FRAGMENT}
`;

export const GET_USER_APP_ROLE_PERMISSIONS = gql`
  query singleUserRolesPermission($userId: ID!) {
    user(userId: $userId) {
      ...SINGLE_USER_WITH_ROLE_PERMISSION
    }
  }
  ${SINGLE_USER_WITH_ROLE_PERMISSION}
`;

export const SINGLE_USER_WITH_TEAMS = gql`
  fragment SINGLE_USER_WITH_TEAMS on User {
    id
    teamIds
    teamData {
      ...TEAM_FRAGMENT
    }
  }
  ${TEAM_FRAGMENT}
`;

export const GET_USER_TEAMS = gql`
  query singleUserTeams($userId: ID!) {
    user(userId: $userId) {
      ...SINGLE_USER_WITH_TEAMS
    }
  }
  ${SINGLE_USER_WITH_TEAMS}
`;

export const userRolesQuery = gql`
  query singleUserRoles($userId: ID!) {
    user(userId: $userId) {
      id
      roles
      rolesData {
        id
        ...RoleDataFragment
      }
    }
  }
  ${RoleDataFragment}
`;

export const allRolesQuery = gql`
  query allRoles {
    roles {
      id
      name
    }
  }
`;

export const singleUserQuery = gql`
  query singleUser($userId: ID!) {
    user(userId: $userId) {
      ...UserDetailLargeFragment
    }
  }
  ${UserDetailLargeFragment}
`;

export const allUsersQuery = gql`
  query allUsers($searchText: String) {
    users(searchText: $searchText) {
      ...UserDetailSmallFragment
    }
  }
  ${UserDetailSmallFragment}
`;

export const getOrganizations = gql`
  query organizations($search: OrganizationSearchInput, $paging: PageInput, $order: OrderInput) {
    organizations(search: $search, paging: $paging, order: $order) {
      total
      organizations {
        id
        organizationName
        domainName
        active
      }
    }
  }
`;

export const getOrganization = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      id
      organizationName
      domainName
      active
    }
  }
`;

export const getUsersNew = gql`
  query usUsers($search: UsUserSearchInput, $paging: PageInput, $order: OrderInput) {
    usUsers(search: $search, paging: $paging, order: $order) {
      total
      users {
        ...UserDetailSmallFragment
        username
        email
        emailVerified
        roles
        appRoles
        additionalInfo
      }
    }
  }
  ${UserDetailSmallFragment}
`;

export const getUserFileImportJob = gql`
  query userFileImportJobs(
    $search: UserFileImportJobSearchInput
    $paging: PageInput
    $order: OrderInput
  ) {
    userFileImportJobs(search: $search, paging: $paging, order: $order) {
      total
      fileImportJobs {
        id
        refCode
        provider
        container
        name
        originalFilename
        type
        status
        detail {
          error
        }
      }
    }
  }
`;

export const getUserPDPAList = gql`
  query usGetUserPDPAList {
    usGetUserPDPAList {
      total
      userPDPAs {
        id
        name
        appPermissionId
        reportFields
        order
      }
    }
  }
`;
export const getUserCertificatesByUserId = gql`
  query getUserCertificatesByUserId(
    $search: GetUserCertificatesByUserIdSearchInput!
    $paging: PageInput
    $order: OrderInput
  ) {
    getUserCertificatesByUserId(search: $search, paging: $paging, order: $order) {
      userCertificates {
        id
        studentId
        certificate {
          id
          name
          code
          active
          type
          validTimePeriodInDays
          examTimeLimitInDays
          revision
        }
        user {
          id
          firstname
          lastname
          username
          email
        }
        certificateKeyPath
        issuedAt
        expiredAt
        isExpired
        certificateUrl
      }
      total
    }
  }
`;

export const getUserPDPAReport = (id) => gql`
  query usGetUserPDPAReport(
    $search: UsGetUserPDPAReportSearchInput 
    $paging: PageInput 
    $order: OrderInput) {
    usGetUserPDPAReport(id: "${id}", search: $search, paging: $paging, order: $order) {
      total
      users
    }
  }
`;
