import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import {
  USER_PROFILE_GETPROFILE_SUCCESS,
  USER_PROFILE_GETPROFILE_FAIL,
  USER_PROFILE_GETPROFILE_REQUEST,
  USER_PROFILE_LOGOUT_SUCCESS,
  USER_PROFILE_LOGIN_FAIL,
  USER_PROFILE_LOGIN_SUCCESS,
  USER_PROFILE_LOGIN_REQUEST,
} from './actions';

const data = handleActions(
  {
    // [USER_PROFILE_SET_PROFILE]: (state, { payload }) => payload,
    [USER_PROFILE_GETPROFILE_SUCCESS]: (state, { payload }) => payload.result,
    [USER_PROFILE_GETPROFILE_FAIL]: () => null,
    [USER_PROFILE_LOGOUT_SUCCESS]: () => null,
  },
  null
);

const loading = handleActions(
  {
    // [USER_PROFILE_SET_PROFILE_LOADING]: (state, { payload }) => payload,
    [USER_PROFILE_GETPROFILE_REQUEST]: () => true,
    [USER_PROFILE_GETPROFILE_SUCCESS]: () => false,
    [USER_PROFILE_GETPROFILE_FAIL]: () => false,
  },
  true
);

const login = handleActions(
  {
    [USER_PROFILE_LOGIN_REQUEST]: () => ({ error: null }),
    [USER_PROFILE_LOGIN_SUCCESS]: () => ({ error: null }),
    [USER_PROFILE_LOGIN_FAIL]: (state, { payload }) => ({ error: payload.error.message }),
  },
  { error: null }
);

export default combineReducers({
  data,
  loading,
  login,
});
