import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import { fileImport } from 'GraphQL/LE/mutation/FileImport.mutation';
import { fileImportJobs } from 'GraphQL/LE/query/FileImport.query';
import CheckCloseComponent from 'Util/CheckDefinedComponent';

import { FILE_TYPE } from '../../../common';

import CreateIndividualReportForm from './CreateIndividualReportForm';

class CreateIndividualReportMutation extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    variables: PropTypes.object,
    type: PropTypes.oneOf(Object.values(FILE_TYPE)).isRequired,
  };

  static defaultProps = {
    onComplete: () => {},
  };

  convertFormValue = (form) => {
    const data = {
      type: this.props.type,
      ...form,
    };
    return data;
  };

  render() {
    const { variables, type } = this.props;
    return (
      <Mutation mutation={fileImport} refetchQueries={[{ query: fileImportJobs, variables }]}>
        {(createData, { loading, error, data }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent checkDefinedObject={data} onDefined={this.props.onComplete} />
              <CreateIndividualReportForm
                type={type}
                onCreateNewData={(form) =>
                  createData({ variables: { input: this.convertFormValue(form) } })
                }
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default withApollo(CreateIndividualReportMutation);
