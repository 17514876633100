import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getCollection } from '../../GraphQL/query/Products.query';
import { updateCollectionMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY } from '../../Domains/constants';

import CollectionDetailsForm from './CollectionDetailsForm';

const resolveData = ({ sfBoProductCollection }) => {
  return {
    ...sfBoProductCollection,
    SKUStatus: `${
      sfBoProductCollection.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED
    }/${sfBoProductCollection.SKUStatus}`,
  };
};

class CollectionDetailsEditor extends Component {
  render() {
    const { collection = {} } = this.props;
    const { id, SKUCode } = collection;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getCollection}
          editMutation={updateCollectionMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              ...form,
              SKUStatus: form.SKUStatus.split('/')[1],
              isVisible: form.SKUStatus.split('/')[0] === SKU_VISIBILITY.VISIBLE,
            },
          })}
        >
          {({ data, onSubmit }) => {
            return <CollectionDetailsForm initialData={resolveData(data)} onSubmit={onSubmit} />;
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default CollectionDetailsEditor;
