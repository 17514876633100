import React, { Component } from 'react';
import PropTypes from 'prop-types';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { GenSwitchRoute } from '../../Util/Route';
import SideMenuLayout from '../../Components/SideMenuLayout';

import ClassroomReport from './Routes/ClassroomReport';
import CertificateReport from './Routes/UserCertificateReport';
import UserExamReport from './Routes/UserExamReport';
import UserReport from './Routes/UserReport';
import UserExamQuestionReport from './Routes/UserExamQuestionReport';
import DefaultRoute from './ReportRouteDefault';
const menus = [
  {
    path: '/reports/classrooms',
    title: 'Classroom',
    menuName: 'Classroom',
    // icon: 'user',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
    route: ClassroomReport,
  },
  {
    path: '/reports/exams',
    title: 'Exam Result',
    menuName: 'Exam Result',
    // icon: 'user',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
    route: UserExamReport,
  },
  {
    path: '/reports/examQuestions',
    title: 'Exam Question',
    menuName: 'Exam Question',
    // icon: 'user',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
    route: UserExamQuestionReport,
  },
  {
    path: '/reports/certificates',
    title: 'Certificate',
    menuName: 'Certificate',
    // icon: 'user',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
    route: CertificateReport,
  },
  {
    path: '/reports/users',
    title: 'User',
    menuName: 'User',
    // icon: 'user',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
    route: UserReport,
  },
  {
    title: 'Default',
    path: '/reports',
    hideInMenu: true,
    route: DefaultRoute,
  },
];

export default class ReportRoute extends Component {
  static propTypes = {};

  render() {
    return (
      <SideMenuLayout header={'REPORTS'} menus={menus.filter((menu) => !menu.hideInMenu)}>
        <GenSwitchRoute routes={menus} />
      </SideMenuLayout>
    );
  }
}
