import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';

import ConfigurableQuestion from './ConfigurableQuestion';

const ConfigurableQuestionModal = ({
  examId,
  examItemId,
  isEdit,
  publishMode,
  isOpen,
  trigger,
  ...restProps
}) => {
  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Modal.Content>
          <ConfigurableQuestion
            examId={examId}
            examItemId={examItemId}
            isEdit={isEdit}
            publishMode={publishMode}
            onComplete={closeModal}
            onCancelled={closeModal}
            {...restProps}
          />
        </Modal.Content>
      )}
    </StatefulModal>
  );
};

ConfigurableQuestionModal.propTypes = {
  examId: PropTypes.string,
  examItemId: PropTypes.string,
  isEdit: PropTypes.bool,
  publishMode: PropTypes.bool,
  isOpen: PropTypes.bool,
  trigger: PropTypes.element,
};
ConfigurableQuestionModal.defaultProps = {
  isEdit: false,
  publishMode: false,
};

export default ConfigurableQuestionModal;
