import urljoin from 'url-join';

import getPublicPath from './getPublicPath';

const getImagePathString = (path) => {
  if (path.startsWith('http')) {
    return path;
  } else {
    return urljoin(getPublicPath(), path);
  }
};

const getImagePath = (path) => {
  const imagePath = path || '';
  if (Array.isArray(imagePath)) {
    return imagePath.map((p) => getImagePathString(p));
  } else {
    return getImagePathString(imagePath);
  }
};

export default getImagePath;
