import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import compose from 'recompose/compose';

import { Flex, Box, Text } from 'Components/Base';
import withServerConfig from 'GraphQL/util/withServerConfig';
import withFormsy from 'Components/Form/withFormsy';

import BannerImageUpload from './BannerImageUpload';

const ImageUrlUpload = ({
  onChange,
  value: initValue,
  serverConfig,
  onFileListRequest,
  onFileUploadUrlRequest,
}) => {
  const [imageUrl, setImageUrl] = useState(initValue);

  const updateValueOnChange = useCallback(() => {
    onChange({}, { value: imageUrl });
  }, [imageUrl, onChange]);

  useEffect(() => {
    updateValueOnChange();
  }, [updateValueOnChange]);

  const handleBannerImageOnChange = (fileObj) => {
    const { url } = fileObj;
    setImageUrl(url);
  };

  return (
    <Box width={1}>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <input
          type="text"
          id={`imageUrl`}
          value={imageUrl}
          readOnly
          placeholder='อัพโหลดรูปโดยกดปุ่ม "Add Slide image"'
        />
        <Text color="g3" fontSize={12}>
          {'รูปภาพจะต้องมีขนาดเท่ากับ 800 px(แนวนอน) x 600 px(แนวตั้ง) เท่านั้น'}
        </Text>
      </Flex>
      <Flex justifyContent="center" mt={1}>
        <Box width="50%">
          <BannerImageUpload
            name="Slide"
            fileImageUrl={imageUrl}
            onFileListRequest={onFileListRequest}
            onFileUploadUrlRequest={onFileUploadUrlRequest}
            uploadMode={serverConfig.assetUploadMode}
            onImageUpdate={(fileObj) => handleBannerImageOnChange(fileObj)}
          />
        </Box>
      </Flex>
    </Box>
  );
};

ImageUrlUpload.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    imageUrl: PropTypes.string,
  }),
  announcementId: PropTypes.string,
  serverConfig: PropTypes.object,
  onFileListRequest: PropTypes.func,
  onFileUploadUrlRequest: PropTypes.func,
};

export default compose(
  withFormsy,
  withServerConfig({ configProp: 'serverConfig' })
)(ImageUrlUpload);
