import React, { Component } from 'react';
import { connect } from 'react-redux';
import { lifecycle, compose } from 'recompose';

import { showAuth, hideAuth } from '../../Redux/menu/actions';
import NoMatchView from '../../Components/NoMatch';

class NoMatchRoute extends Component {
  render() {
    const { history } = this.props;
    return (
      <NoMatchView
        onBackClick={() => {
          history.push('/');
        }}
      />
    );
  }
}

const attachAuthVisibilityTrigger = lifecycle({
  componentDidMount() {
    this.props.hideAuth();
  },
  componentWillUnmount() {
    this.props.showAuth();
  },
});

export default compose(
  connect(undefined, { showAuth, hideAuth }),
  attachAuthVisibilityTrigger
)(NoMatchRoute);
