import gql from 'graphql-tag';

import { MutationResponseFragment, AnnouncementFragment } from './Announcement.mutation';

export const createCourseAnnouncement = gql`
  mutation createCourseAnnouncement($courseAnnouncementData: CourseAnnouncementCreateInput!) {
    createCourseAnnouncement(courseAnnouncementData: $courseAnnouncementData) {
      ...MutationResponseFragment
      ...AnnouncementFragment
    }
  }
  ${MutationResponseFragment}
  ${AnnouncementFragment}
`;

export const deleteCourseAnnouncement = gql`
  mutation deleteCourseAnnouncement($announcementId: ID!) {
    deleteCourseAnnouncement(announcementId: $announcementId) {
      ...MutationResponseFragment
      ...AnnouncementFragment
    }
  }
  ${MutationResponseFragment}
  ${AnnouncementFragment}
`;

export const updateCourseAnnouncement = gql`
  mutation updateCourseAnnouncement(
    $announcementId: ID!
    $courseAnnouncementData: CourseAnnouncementUpdateInput!
  ) {
    updateCourseAnnouncement(
      announcementId: $announcementId
      courseAnnouncementData: $courseAnnouncementData
    ) {
      ...MutationResponseFragment
      ...AnnouncementFragment
    }
  }
  ${MutationResponseFragment}
  ${AnnouncementFragment}
`;
