import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps, mapProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withUserStatus } from 'Redux/user-profile/hoc';

import { authVisibility } from '../../Redux/menu/selectors';
import Menu from '../../Components/Menu';
import Navbar from '../../Components/Navbar';
import AuthButton from '../AuthButton';

const filterMenu = (menus = [], isLogin = false, myRoles = []) =>
  menus.filter(
    (menu) =>
      !menu.hideMenu &&
      (menu.private !== true ||
        (isLogin &&
          menu.roles &&
          (menu.roles.length === 0 ||
            (menu.roles.length > 0 && menu.roles.some((role) => myRoles.includes(role))))))
  );

const MapperHOC = compose(
  withRouter,
  withUserStatus,
  connect((state) => ({
    authVisibility: authVisibility(state),
  })),
  withProps(({ menus, isLogin, myRoles }) => ({
    menuItems: filterMenu(menus, isLogin, myRoles) || [],
    authComponent: <AuthButton />,
  })),
  mapProps(({ showAuth, authVisibility, ...restProps }) => ({
    showAuth: showAuth && authVisibility,
    ...restProps,
  }))
  // onlyUpdateForKeys(['menus', 'logoUrl']),
);

const NavigationBar = MapperHOC(Navbar);
export { NavigationBar as Navbar };
export default MapperHOC(Menu);
