import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBundle } from '../../GraphQL/query/Products.query';
import { updateBundleCategoriesMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUCategoriesForm from '../SKUCategoriesForm';
import { SKU_CATEGORY, SKU_TYPE } from '../../Domains/constants';

class BundleCategoriesEditor extends Component {
  render() {
    const { bundle = {} } = this.props;
    const { id, SKUCode } = bundle;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getBundle}
          editMutation={updateBundleCategoriesMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              ...form,
            },
          })}
        >
          {({ data, onSubmit }) => {
            return (
              <SKUCategoriesForm
                skuCategory={SKU_CATEGORY.COLLECTION}
                skuType={SKU_TYPE.BUNDLE}
                initialData={data && data.sfBoProductBundle}
                onSubmit={onSubmit}
              />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default BundleCategoriesEditor;
