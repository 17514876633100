import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';

import QueryComponent from 'GraphQL/util/QueryComponent';
import { question, exam, QUESTION_TYPE, EXAM_VERSION } from 'GraphQL/query/ExamEditorV1.query';
import { createQuestion, updateQuestion } from 'GraphQL/mutation/ExamEditorV1.mutation';

import SingleQuestion from './SingleQuestion';

class SingleQuestionQuery extends Component {
  static propTypes = {
    examId: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(QUESTION_TYPE)).isRequired,
    onQuestionAdded: PropTypes.func,
    onQuestionUpdated: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    onQuestionAdded: () => {},
    onQuestionUpdated: () => {},
  };

  handleSave = async (questionData) => {
    const { client, questionId, examId, readOnly } = this.props;
    const { id: _id, ...questionInput } = questionData;

    if (readOnly) return;

    const result = await client.mutate({
      mutation: updateQuestion,
      variables: {
        examId,
        questionId,
        questionInput: questionInput,
        version: EXAM_VERSION.SKOOLDIO_V2,
      },
      refetchQueries: [],
    });
    this.props.onQuestionUpdated(result);
  };

  handleAddQuestion = async (questionFormData) => {
    const { client, courseId, examId, type, readOnly } = this.props;
    const { id, ...updateFormData } = questionFormData;
    const questionReqInput = { ...updateFormData, type };

    if (readOnly) return;

    // TODO: send version to variables
    const result = await client.mutate({
      mutation: createQuestion,
      variables: {
        examId,
        questionReqInput,
        courseId,
        version: EXAM_VERSION.SKOOLDIO_V2,
      },
      refetchQueries: [
        {
          query: exam,
          variables: {
            courseId,
            examId,
            version: EXAM_VERSION.SKOOLDIO_V2,
          },
        },
      ],
    });
    this.props.onQuestionAdded(result);
  };

  render() {
    const { questionId, isNew } = this.props;
    if (!isNew) {
      return (
        <QueryComponent
          query={question}
          variables={{ questionId, version: EXAM_VERSION.SKOOLDIO_V2 }}
        >
          {(data) => {
            return (
              <SingleQuestion {...data} isNew={isNew} onSave={this.handleSave} {...this.props} />
            );
          }}
        </QueryComponent>
      );
    }

    return <SingleQuestion {...this.props} isNew={isNew} onSave={this.handleAddQuestion} />;
  }
}

export default withApollo(SingleQuestionQuery);
