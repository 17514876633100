import LegacyHTMLEditor, {
  escapeHTML,
  unescapeHTML,
  unescapeAndSanitizeHTMLSection,
  preprocessLegacyHTMLEditorInput,
  removeUnacceptedIframe,
} from './LegacyHTMLEditor';
import LegacyHTMLEditorPreviewModal from './LegacyHTMLEditorPreviewModal';

export default LegacyHTMLEditor;
export {
  escapeHTML,
  unescapeHTML,
  unescapeAndSanitizeHTMLSection,
  preprocessLegacyHTMLEditorInput,
  removeUnacceptedIframe,
  LegacyHTMLEditorPreviewModal,
};
