export const PURCHASE_ORDER_PAYMENT_METHOD = {
  BANK_TRANSFER: 'bank_transfer',
  BANKING: 'banking',
  CREDIT_CARD: 'credit_card',
};

export const PURCHASE_ORDER_CUSTOMER_TYPE = {
  ALL: 'ALL',
  CORPORATE: 'CORPORATE',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const defaultSearch = {
  paymentMethod: PURCHASE_ORDER_PAYMENT_METHOD.BANK_TRANSFER,
}; // SKOOLDIO-4154
