import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';

import { CATEGORY_TYPE, SKU_CATEGORY } from '../../Domains/constants';

import CategoryForm from './CategoryForm';

export default class CategoryModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
    skuCategory: PropTypes.oneOf(Object.values(SKU_CATEGORY)).isRequired,
    categoryType: PropTypes.oneOf(Object.values(CATEGORY_TYPE)).isRequired,
    categoryName: PropTypes.string,
    getCategories: PropTypes.func.isRequired,
    getCategoriesQueryName: PropTypes.string.isRequired,
    getCategory: PropTypes.func.isRequired,
    getCategoryQueryName: PropTypes.string.isRequired,
    createMutation: PropTypes.func.isRequired,
    updateMutation: PropTypes.func.isRequired,
    parentId: PropTypes.string.isRequired,
  };
  static defaultProps = {
    isEdit: false,
  };
  convertCreateFormData = (form) => {
    const { parentId } = this.props;
    const { icon, ...restForm } = form;
    const data = {
      ...restForm,
      parentId,
    };
    if (icon && icon.key && icon.bucket) {
      data.icon = {
        key: form.icon.key,
        bucket: form.icon.bucket,
      };
    }
    return data;
  };

  convertUpdateFormData = (form) => {
    const { icon, ...restForm } = form;
    const data = {
      ...restForm,
    };
    if (icon && icon.key && icon.bucket) {
      data.icon = {
        key: form.icon.key,
        bucket: form.icon.bucket,
      };
    }
    return data;
  };

  render() {
    const {
      isEdit,
      id,
      trigger,
      variables,
      skuCategory,
      categoryType,
      categoryName,
      parentId,
      getCategories,
      getCategoriesQueryName,
      getCategory,
      getCategoryQueryName,
      createMutation,
      updateMutation,
    } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>
              {isEdit ? `Edit ${categoryName}` : `Create ${categoryName}`}
            </Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getCategory}
                createMutation={createMutation}
                createRefetchQueries={[
                  {
                    query: getCategories,
                    variables,
                  },
                ]}
                editMutation={updateMutation}
                isEdit={isEdit}
                id={id}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  data: this.convertUpdateFormData(form),
                })}
                getCreateVariables={(form) => ({
                  data: this.convertCreateFormData(form),
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <CategoryForm
                      initialData={data && data[getCategoryQueryName]}
                      onSubmit={onSubmit}
                    />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
