import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';
import RichTextEditor from 'Components/RichTextEditor';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

const RichTextEditorBox = styled(RichTextEditor)`
  & .public-DraftEditor-content {
    min-height: 100px;
  }
`;

const RichTextEditorInput = ({ value, examId, onChange }) => {
  return (
    <Box>
      <RichTextEditorBox
        value={value}
        contentFormat={RichTextEditor.contentFormat.HTML}
        onChange={(value) => onChange({}, { value: value })}
        returnValueAsString
        customControls={[
          {
            type: RichTextEditor.customControlType.IMAGE_UPLOAD,
            data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
          },
        ]}
      />
    </Box>
  );
};

RichTextEditorInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  examId: PropTypes.string,
};

export default withFormsy(RichTextEditorInput);
