import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createTeacherProfileMutation,
  updateTeacherProfileMutation,
  updateProfileImageMutation,
} from 'GraphQL/LSP/mutation/Profile.mutation';
import { getTeacherProfiles, getTeacherProfile } from 'GraphQL/LSP/query/Profile.query';

import TeacherProfileForm from './TeacherProfileForm';

const convertFormData = (form) => ({
  code: form.code,
  firstName: form.firstName,
  lastName: form.lastName,
  prefix: form.prefix,
  nickName: form.nickName,
  phoneNumber: form.phoneNumber,
  address1: form.address1,
  district: form.district,
  province: form.province,
  zipCode: form.zipCode,
  positions: form.positions,
  role: form.role,
  classroomUsername: form.classroomUsername || null,
});

function TeacherProfileModal(props) {
  const { isEdit, id, trigger, variables } = props;
  const [updateProfileImage] = useMutation(updateProfileImageMutation);

  const handleUpdateProfileImage = ({ key, bucket }) => {
    updateProfileImage({
      variables: {
        userId: id,
        profileImage: {
          key,
          bucket,
        },
      },
    });
  };

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{isEdit ? 'แก้ไขโปรไฟล์' : 'สร้างโปรไฟล์'}</Modal.Header>
          <Modal.Content>
            <CreateEditForm
              dataQuery={getTeacherProfile}
              createMutation={createTeacherProfileMutation}
              createRefetchQueries={[
                {
                  query: getTeacherProfiles,
                  variables,
                },
              ]}
              editRefetchQueries={[
                {
                  query: getTeacherProfiles,
                  variables,
                },
              ]}
              editMutation={updateTeacherProfileMutation}
              isEdit={isEdit}
              id={id}
              getQueryVariables={({ id }) => ({
                userId: id,
              })}
              getEditVariables={(form, { id }) => ({
                userId: id,
                data: convertFormData(form),
              })}
              getCreateVariables={(form) => ({
                data: {
                  ...convertFormData(form),
                  username: form.username,
                  password: form.password,
                  email: form.email,
                },
              })}
              onCompleted={closeModal}
              onCancelled={closeModal}
            >
              {({ data, onSubmit }) => {
                const initialData = data
                  ? { ...data.boTeacherProfile, ...data.boTeacherProfile.address }
                  : {};
                return (
                  <TeacherProfileForm
                    initialData={initialData}
                    onUploadProfileImage={handleUpdateProfileImage}
                    onSubmit={onSubmit}
                  />
                );
              }}
            </CreateEditForm>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default TeacherProfileModal;
