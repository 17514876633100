import React, { Component } from 'react';
import PropTypes from 'prop-types';

import QueryComponent from '../../../../GraphQL/util/QueryComponent';
import { getAllTags } from '../../../../GraphQL/query/Tag.query';

import SelectorInput from './SelectorInput';

const parseValue = ({ tags = [] }) => {
  return tags.map((tag) => ({
    key: tag.id,
    value: tag.name,
    text: `${tag.name}`,
  }));
};
export default class TagSelectorInputQuery extends Component {
  static propTypes = {};

  render() {
    return (
      <QueryComponent query={getAllTags}>
        {(data) => <SelectorInput options={parseValue(data)} {...this.props} />}
      </QueryComponent>
    );
  }
}
