import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import { ANNOUNCEMENT_CATEGORY } from './constants';

/**
 * @param {string} initValue - category [array] | ex. ['NEWS','REMINDER']
 */
const CategorySearch = ({ onChange, value: initValue }) => {
  const [categoryChecked, setCategoryChecked] = useState(
    initValue
      ? {
          NEWS: initValue.includes(ANNOUNCEMENT_CATEGORY.NEWS),
          REMINDER: initValue.includes(ANNOUNCEMENT_CATEGORY.REMINDER),
          NEW_CONTENT: initValue.includes(ANNOUNCEMENT_CATEGORY.NEW_CONTENT),
          MARKETING: initValue.includes(ANNOUNCEMENT_CATEGORY.MARKETING),
        }
      : { NEWS: false, REMINDER: false, NEW_CONTENT: false, MARKETING: false }
  );

  useEffect(() => {
    const category = [];
    if (categoryChecked.NEWS) category.push(ANNOUNCEMENT_CATEGORY.NEWS);
    if (categoryChecked.REMINDER) category.push(ANNOUNCEMENT_CATEGORY.REMINDER);
    if (categoryChecked.NEW_CONTENT) category.push(ANNOUNCEMENT_CATEGORY.NEW_CONTENT);
    if (categoryChecked.MARKETING) category.push(ANNOUNCEMENT_CATEGORY.MARKETING);
    onChange({}, { value: [...category] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryChecked]);

  return (
    <Flex flexDirection="column">
      <Checkbox
        label="ข่าวสาร"
        checked={categoryChecked.NEWS}
        onChange={() => setCategoryChecked({ ...categoryChecked, NEWS: !categoryChecked.NEWS })}
      />
      <Box mt={2} />
      <Checkbox
        label="เรียนรู้เพิ่มเติม"
        checked={categoryChecked.REMINDER}
        onChange={() =>
          setCategoryChecked({ ...categoryChecked, REMINDER: !categoryChecked.REMINDER })
        }
      />
      <Box mt={2} />
      <Checkbox
        label="New Content"
        checked={categoryChecked.NEW_CONTENT}
        onChange={() =>
          setCategoryChecked({ ...categoryChecked, NEW_CONTENT: !categoryChecked.NEW_CONTENT })
        }
      />
      <Box mt={2} />
      <Checkbox
        label="Promotion"
        checked={categoryChecked.MARKETING}
        onChange={() =>
          setCategoryChecked({ ...categoryChecked, MARKETING: !categoryChecked.MARKETING })
        }
      />
    </Flex>
  );
};

CategorySearch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
};

export default CategorySearch;
