import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import _ from 'lodash';

import { Box } from 'Components/Base';
import FormGenerator from 'Components/Form/FormGenerator';
import StatefulModal from 'Components/Modal/StatefulModal';
import QueryTable from 'GraphQL/util/QueryTable';
import ColorSelectorInput from 'Components/ColorSelectorInput';
import { getAllCourses, COURSE_VERSION } from 'GraphQL/query/Courses.query';
import { DatePickerInput } from 'Components/DatePickerInput';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { classroomEnrollmentByCourseIdQuery } from 'GraphQL/query/Registrar.query';
import { generateManualCertificates } from 'GraphQL/mutation/Certificate.mutation';
import ErrorView from 'Components/ErrorView';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import CertificateCheckBoxInput from '../CertificateCheckBoxInput';

function addOrRemove(array, studentId) {
  const data = [...array];
  const index = _.findIndex(data, (datum) => datum === studentId);

  if (index === -1) {
    data.push(studentId);
  } else {
    data.splice(index, 1);
  }
  return data;
}

const studentColumns = (selectStudentIds = [], setSelectStudentIds) => [
  {
    Header: '',
    accessor: 'checkbox',
    width: 50,
    Cell: function ActionCell({ original }) {
      return (
        <Checkbox
          checked={selectStudentIds.find((item) => item === original.studentId)}
          onChange={() => {
            setSelectStudentIds(addOrRemove(selectStudentIds, original.studentId));
          }}
        />
      );
    },
  },
  {
    Header: 'Student Id',
    accessor: 'studentId',
    type: COLUMN_TYPE.STRING,
    Footer: `${selectStudentIds.length} student(s) selected`,
  },
  {
    Header: 'Firstname',
    accessor: 'firstname',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Lastname',
    accessor: 'lastname',
    type: COLUMN_TYPE.STRING,
  },
];

const resolveData = (rawData) => {
  const classroomStudents = rawData.classroomEnrollmentByCourseId.classroomEnrollments;
  return classroomStudents.map((classroomStudent) => {
    const { firstname, lastname } = classroomStudent.user;
    const { studentId } = classroomStudent;
    const mappedData = {
      studentId: studentId,
      firstname,
      lastname,
    };
    return mappedData;
  });
};

const GenerateManualCertificateModal = ({ certificateId, trigger }) => {
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [selectStudentIds, setSelectStudentIds] = useState([]);
  const [dataResponse, setDataResponse] = useState(null);

  const [getCoursesOptions, { data: courseOntion }] = useLazyQuery(getAllCourses, {
    variables: {
      search: { version: COURSE_VERSION.SKOOLDIO_V2 },
    },
  });

  const [
    generateManualCertificatesMutation,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(generateManualCertificates, { onCompleted: setDataResponse });

  useEffect(() => {
    getCoursesOptions();
  }, []);

  useEffect(() => {
    if (courseOntion) {
      const coursesData = courseOntion?.courses?.courses ?? [];
      setCoursesOptions(coursesData);
    }
  }, [courseOntion]);

  const formColumns = [
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Course',
      inputName: 'courseId',
      options: coursesOptions?.map((course) => ({
        key: course.id,
        value: course.id,
        text: `(${course.courseCode}) ${course.title}`,
      })),
      inputProps: {
        required: true,
        search: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'URL Link',
      inputName: 'customUrl',
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Font Color',
      inputName: 'customFontColor',
      customInput: (props) => <ColorSelectorInput {...props} defaultColor={'#FFF'} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Issued At',
      inputName: 'customIssuedAt',
      customInput: (props) => <DatePickerInput />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Students',
      inputName: 'studentIds',
      hidden: !courseId,
      customInput: (props) => (
        <QueryTable
          type={'classroomEnrollmentByCourseId'}
          columns={studentColumns(selectStudentIds, setSelectStudentIds)}
          query={classroomEnrollmentByCourseIdQuery}
          search={{ sourceCourseId: courseId, mode: 'FULL' }}
          paging={paging}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          resolveData={resolveData}
          showSearchPanel={false}
        />
      ),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputName: 'isNotifyByEmail',
      inputLabel: '',
      inputProps: {
        label: 'Notify user via Email',
        required: false,
      },
      hidden: !courseId,
      customInput: (props) => (
        <CertificateCheckBoxInput
          {...props}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        />
      ),
    },
  ];

  const genPaging = (page, pageSize = paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };

  const handlePageChange = (page) => {
    const paging = genPaging(page);
    setPaging(paging);
  };

  const handlePageSizeChange = (pageSize) => {
    setPaging(genPaging(0, pageSize));
  };

  const handleOnChange = (formData) => {
    if (courseId !== formData.courseId) {
      setSelectStudentIds([]);
      setCourseId(formData.courseId);
    }
  };

  const handleOnClose = (closeModal) => {
    setCourseId(null);
    setSelectStudentIds([]);
    setDataResponse(null);
    setPaging({ currentPage: 0, pageSize: 10 });
    closeModal();
  };

  const handleOnSubmit = (formData) => {
    const { customUrl, customFontColor, customIssuedAt, courseId, isNotifyByEmail } = formData;
    const course = coursesOptions.find((co) => co.id === courseId);
    const generateManualCertificatesInput = {
      courseCode: course.courseCode,
      certificateId,
      studentIds: selectStudentIds,
      customProps: {
        customUrl,
        customFontColor,
        customIssuedAt: new Date(customIssuedAt?.format('YYYY-MM-DD')).toISOString(),
      },
      isNotifyByEmail,
    };

    generateManualCertificatesMutation({
      variables: { generateManualCertificatesInput },
    });
  };

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <>
          <Modal.Header> Generate Manual Certificates</Modal.Header>
          <Modal.Content>
            <Box pt={4} pb={3} px={4} mx={'auto'} width={'100%'}>
              <FormGenerator
                initialData={{
                  customIssuedAt: moment(),
                }}
                fields={formColumns}
                showCancel={true}
                onCancelled={() => handleOnClose(closeModal)}
                onChange={handleOnChange}
                onSubmit={handleOnSubmit}
                submitText="Generate Certificates"
                loading={mutationLoading}
              />
              {mutationError && <ErrorView message={mutationError} />}
              {dataResponse && (
                <CheckMutationResponse
                  checkOnlyDefined={true}
                  response={mutationResponse?.generateManualCertificates}
                  loading={mutationLoading}
                  error={mutationError}
                  notificationTime={3000}
                  onValid={() => handleOnClose(closeModal)}
                />
              )}
            </Box>
          </Modal.Content>
        </>
      )}
    </StatefulModal>
  );
};

GenerateManualCertificateModal.propTypes = {
  certificateId: PropTypes.string,
  trigger: PropTypes.element,
};

export default GenerateManualCertificateModal;
