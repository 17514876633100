import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getCollectionOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateCollectionOnlineCourseMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY } from '../../Domains/constants';

import OnlineCourseOverviewForm from './OnlineCourseOverviewForm';

const resolveData = ({ sfBoProductCollectionOnlineCourse }) => {
  return {
    ...sfBoProductCollectionOnlineCourse,
    SKUStatus: `${
      sfBoProductCollectionOnlineCourse.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED
    }/${sfBoProductCollectionOnlineCourse.SKUStatus}`,
  };
};

const OnlineCourseOverviewEditor = ({ onlineCourse = {} }) => {
  const { id, SKUCode } = onlineCourse;
  return (
    <Box>
      <CreateEditForm
        dataQuery={getCollectionOnlineCourse}
        editMutation={updateCollectionOnlineCourseMutation}
        isEdit={true}
        id={SKUCode}
        idVariable={'SKUCode'}
        getQueryVariables={() => ({
          SKUCode,
        })}
        getEditVariables={(form) => ({
          id,
          data: {
            ...form,
            promotionYoutubeId: form.promotionYoutubeId || null,
            SKUStatus: form.SKUStatus.split('/')[1],
            isVisible: form.SKUStatus.split('/')[0] === SKU_VISIBILITY.VISIBLE,
          },
        })}
      >
        {({ data, onSubmit }) => {
          return <OnlineCourseOverviewForm initialData={resolveData(data)} onSubmit={onSubmit} />;
        }}
      </CreateEditForm>
    </Box>
  );
};

OnlineCourseOverviewEditor.propTypes = {
  onlineCourse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default OnlineCourseOverviewEditor;
