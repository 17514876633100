import React from 'react';
import PropTypes from 'prop-types';
import { Header, Grid } from 'semantic-ui-react';

import OnlineCourseBatchImageUpload from './OnlineCourseBatchImageUpload.gql';

const CourseHeader = ({ id, title, SKUCode, coverImageUrl, cardImageUrl, editComp }) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <OnlineCourseBatchImageUpload
            id={id}
            field={'coverImage'}
            changeImgText="Change cover image"
            fileImageUrl={coverImageUrl ?? undefined}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <OnlineCourseBatchImageUpload
            id={id}
            field={'cardImage'}
            changeImgText="Change card image"
            fileImageUrl={cardImageUrl ?? undefined}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Column width={16}>
        <Grid.Row>
          <Header size="huge">{title}</Header>
        </Grid.Row>
        <Grid.Row>
          <Header size="large">{SKUCode}</Header>
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={2}>{editComp}</Grid.Column>
    </Grid>
  );
};

CourseHeader.propTypes = {
  title: PropTypes.string,
  SKUCode: PropTypes.string,
  cardImageUrl: PropTypes.string,
  coverImageUrl: PropTypes.string,
};

export default CourseHeader;
