import React from 'react';
import styled from 'styled-components';

import { Text, Box } from 'Components/Base';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { DATA_FORMAT } from 'GraphQL/util/QueryTable';
import { getFormatter } from 'GraphQL/util/QueryTable/dataUtil';

const moneyFormatter = getFormatter(COLUMN_TYPE.NUMBER, DATA_FORMAT.NUMBER_FORMAT_FLOAT);

const Container = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 324px;
  height: 92px;
`;

const getValue = (value, loading, error) => {
  if (loading) return 'Loading...';
  if (error) {
    console.error(error);
    return error;
  }

  return value ? moneyFormatter(value) : '0.00';
};

const TotalAmountSumBox = ({ text = '', value, loading, error }) => {
  return (
    <Container>
      <Box mb={1}>
        <Text color="#787878" fontSize={16} fontWeight={400}>
          {text}
        </Text>
      </Box>
      <Text color="#231F20" fontSize={24} fontWeight={500}>
        {getValue(value, loading, error)}
      </Text>
    </Container>
  );
};

export default TotalAmountSumBox;
