import urlJoin from 'url-join';

const SHIPMENT_MANAGEMENT_PREFIX = 'shipment-management';

export const SHIPMENT_URL = '/shipments';

export const SHIPMENT_STATUS = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
  VOIDED: 'VOIDED',
};

export const SHIPMENT_STATUS_TEXT = {
  [SHIPMENT_STATUS.PENDING]: 'Pending',
  [SHIPMENT_STATUS.CONFIRMED]: 'Confirm',
  [SHIPMENT_STATUS.SHIPPED]: 'Shipped',
  [SHIPMENT_STATUS.DELIVERED]: 'Delivered',
  [SHIPMENT_STATUS.FAILED]: 'Failed',
  [SHIPMENT_STATUS.VOIDED]: 'Voided',
};

export const IMPORT_TRACKING_URL = urlJoin(
  SHIPMENT_URL,
  SHIPMENT_MANAGEMENT_PREFIX,
  'import-tracking'
);
