import gql from 'graphql-tag';

export const getAssetList = gql`
  mutation getFileList($type: AS_ASSET_TYPE!, $key: String!) {
    asRequestAssetsList(type: $type, key: $key) {
      code
      assetFiles {
        bucket
        groupKey
        list {
          key
          bucket
          size
          lastModified
          url: previewUrl
        }
      }
    }
  }
`;

export const getUploadUrl = gql`
  mutation getUploadUrl(
    $type: AS_ASSET_TYPE!
    $key: String!
    $filename: String!
    $contentType: String!
  ) {
    asRequestAssetUploadUrl(
      type: $type
      key: $key
      filename: $filename
      contentType: $contentType
    ) {
      code
      uploadUrl {
        url
      }
    }
  }
`;

export const requestAssetUrl = gql`
  mutation asRequestAssetUrl($type: AS_ASSET_TYPE!, $key: String!) {
    asRequestAssetUrl(type: $type, key: $key) {
      code
      success
      message
      url {
        url
      }
    }
  }
`;

export const ASSET_TYPE = {
  COURSE_PUBLIC: 'COURSE_PUBLIC',
  COURSE_IMAGES: 'COURSE_IMAGES',
  COURSE_MATERIAL: 'COURSE_MATERIAL',
  SF_ASSETS: 'SF_ASSETS',
  EXAM_IMAGES: 'EXAM_IMAGES',
  LSP_BACKOFFICE_UPLOAD: 'LSP_BACKOFFICE_UPLOAD',
  LE_BACKOFFICE_UPLOAD: 'LE_BACKOFFICE_UPLOAD',
  PROFILE_IMAGES: 'PROFILE_IMAGES',
  LSP_BACKOFFICE_UPLOAD_RESULT: 'LSP_BACKOFFICE_UPLOAD_RESULT',
  LE_BACKOFFICE_UPLOAD_RESULT: 'LE_BACKOFFICE_UPLOAD_RESULT', // Not being used now
  STOREFRONT_CATEGORY: 'STOREFRONT_CATEGORY',
  STOREFRONT_SKU: 'STOREFRONT_SKU',
  BACKOFFICE_STOREFRONT_DISCOUNT_CODE_UPLOAD: 'BACKOFFICE_STOREFRONT_DISCOUNT_CODE_UPLOAD',
  BACKOFFICE_STOREFRONT_DISCOUNT_CODE_UPLOAD_RESULT:
    'BACKOFFICE_STOREFRONT_DISCOUNT_CODE_UPLOAD_RESULT',
  CERTIFICATE_TEMPLATE: 'CERTIFICATE_TEMPLATE',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
};
