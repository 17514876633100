import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import Menu from './Menu';

const GridWithMargin = styled(Grid)`
  margin: 8px 4px !important;

  @media only screen and (min-width: 768px) {
    margin: 16px 32px !important;
  }
`;
class ComplexSideMenuLayout extends Component {
  static defaultProps = {
    containerProps: {},
    contentProps: {},
  };

  state = {
    activeItem: '',
  };

  handleMenuItemChange = (value) => {
    this.setState({
      activeItem: value,
    });
  };

  render() {
    const { activeItem } = this.state;
    const { children, menus, containerProps, contentProps } = this.props;
    return (
      <Container fluid {...containerProps}>
        <GridWithMargin>
          <Grid.Column mobile={16} computer={4}>
            {Object.keys(menus).map((header) => {
              return (
                <Menu
                  key={header}
                  menus={menus[header]}
                  activeItem={activeItem}
                  onMenuItemChange={this.handleMenuItemChange}
                  header={header}
                />
              );
            })}
          </Grid.Column>
          <Grid.Column textAlign="center" mobile={16} computer={12} {...contentProps}>
            {children}
          </Grid.Column>
        </GridWithMargin>
      </Container>
    );
  }
}

export default ComplexSideMenuLayout;
