import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, List } from 'semantic-ui-react';

import { RolesLabel } from './UserComp';

class UserItemView extends Component {
  render() {
    const { firstname, lastname, email, referenceId, roles, ...restProps } = this.props;
    return (
      <Card>
        <Card.Content>
          <Card.Header>{`${firstname} ${lastname}`}</Card.Header>
          <Card.Description>{<RolesLabel roles={roles} size="small" />}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <List>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>{email}</List.Content>
            </List.Item>
            {referenceId && (
              <List.Item>
                <List.Icon name="user" />
                <List.Content>{referenceId}</List.Content>
              </List.Item>
            )}
          </List>
        </Card.Content>
      </Card>
    );
  }
}
UserItemView.propTypes = {
  id: PropTypes.string.isRequired,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  email: PropTypes.string,
  referenceId: PropTypes.string,
};

UserItemView.defaultProps = {
  roles: [],
};

export default UserItemView;
