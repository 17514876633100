import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';
import reducers from './reducers';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
let enhancer;

// if (__DEV__) {
//   console.log('if dev');
enhancer = composeWithDevTools({})(applyMiddleware(...middlewares));
// } else {
//   enhancer = applyMiddleware(...middlewares);
// }

const store = createStore(
  reducers,
  {},
  enhancer
  // compose(applyMiddleware(apollo.middleware()), applyMiddleware(thunk))
);

sagaMiddleware.run(rootSaga);

if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(reducers);
  });
}

export default store;
