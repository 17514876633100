import React, { Fragment, Component } from 'react';
import { Icon, Modal } from 'semantic-ui-react';

import Text from '../../../Components/Base/Text';
import apolloClient from '../../../GraphQL';
import ConfirmationModalView from '../../../Components/Modal/ConfirmationModal';
import ImageUploadedModalView from '../../../Components/Modal/ImageUploadedModal';
import QueryTable from '../../../GraphQL/util/QueryTable';
import { purchaseOrders } from '../../../GraphQL/query/Purchase.query';
import {
  confirmPurchaseOrder,
  rejectPurchaseOrder,
} from '../../../GraphQL/mutation/Payment.mutation';
import PAYMENT from '../../../GraphQL/common/paymentStatus';
import {
  subComponentPayMent,
  Format,
  DateFormat,
  PaymentMethodFormat,
  getPurchaseReceipt,
} from '../common';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';
import StatefulModal from '../../../Components/Modal/StatefulModal';
import { Button } from '../../../Components/Base/Button/ButtonView';
import { defaultSearch } from '../common/constants';

const paymentColumns = (handleConfirm, handleReject) => [
  {
    Header: 'Fullname',
    accessor: 'fullname',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Mobile',
    accessor: 'mobile',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 200,
    Cell: ({ value }) => <Format amount={value} />,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod',
    width: 180,
    Cell: ({ original }) => <PaymentMethodFormat method={original} />,
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Receipt Uploaded At',
    accessor: 'updatedAt',
    width: 200,
    type: COLUMN_TYPE.DATE,
    Cell: ({ original }) => {
      const receipt = getPurchaseReceipt(original);
      if (receipt && receipt.dateTime) return <DateFormat date={receipt.dateTime} />;
      return '';
    },
    isSearchAble: false,
  },
  {
    Header: 'Bank',
    accessor: 'histories[0].detail.bank',
    width: 140,
    type: COLUMN_TYPE.STRING,
    //isSearchAble: true,

    // TODO: Fix query bank
    isSearchAble: false,
    sortable: false,
  },
  {
    Header: 'Pay slip',
    accessor: 'histories[0].detail.bank',
    width: 150,
    Cell: ({ original }) => {
      const receipt = getPurchaseReceipt(original);
      if (receipt) {
        return (
          <ImageUploadedModalView
            title="Pay slip"
            link={receipt.uploadedLink}
            dateTime={receipt.dateTime}
          />
        );
      } else {
        return '';
      }
    },
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 300,
    Cell: ({ original }) => {
      const { id: purchaseId, paymentMethod } = original;
      const payment = { purchaseId, paymentMethod };
      return (
        <Fragment>
          <ConfirmationModalView
            icon="check"
            triggerButtonStyle={{ positive: true, basic: true }}
            actionButtonStyle={{ positive: true }}
            actionText="Approve"
            onConfirm={handleConfirm}
            value={payment}
            headerContent={<Text.PaymentTitle>Approve Payment</Text.PaymentTitle>}
          >
            <Text>User will be enrolled to the course(s) immediately.</Text>
          </ConfirmationModalView>

          <ConfirmationModalView
            icon="times"
            triggerButtonStyle={{ negative: true, basic: true }}
            actionButtonStyle={{ negative: true }}
            actionText="Reject"
            onConfirm={handleReject}
            value={payment}
            headerContent={<Text.PaymentTitle>Reject Payment</Text.PaymentTitle>}
          >
            <Text>This action cannot be undone.</Text>
          </ConfirmationModalView>
        </Fragment>
      );
    },
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
];

class ApprovalPayment extends Component {
  state = {
    status: PAYMENT.WAITING_FOR_APPROVAL,
    paging: { currentPage: 0, pageSize: 10 },
    search: defaultSearch,
    error: null,
  };
  paymentMutate = (mutation, actionPurchaseOrder) => {
    this.setState({ error: null });
    apolloClient
      .mutate({
        mutation,
        refetchQueries: [
          {
            query: purchaseOrders,
            variables: this.state,
            fetchPolicy: 'network-only',
          },
        ],
        variables: { actionPurchaseOrder },
      })
      .then((log) => {
        console.log(log);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error });
      });
  };

  handleConfirm = (purchaseOrder) => {
    this.paymentMutate(confirmPurchaseOrder, purchaseOrder);
  };

  handleReject = (purchaseOrder) => {
    this.paymentMutate(rejectPurchaseOrder, purchaseOrder);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, ...defaultSearch }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { status, search, paging, order, error } = this.state;
    return (
      <Fragment>
        {error && (
          <StatefulModal trigger={<div />} defaultOpen>
            {(closeModal) => (
              <Fragment>
                <Modal.Header>
                  <Icon name="times circle" size="large" color="red" />
                  Error message
                </Modal.Header>
                <Modal.Content>
                  {JSON.stringify(error.message) === '"GraphQL error: NOT_VALID_PO"'
                    ? 'This purchase order has been approved/rejected. Click the button below to reload this page.'
                    : JSON.stringify(error.message)}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    onClick={() => {
                      window.location.reload();
                    }}
                    title={'Close'}
                  />
                </Modal.Actions>
              </Fragment>
            )}
          </StatefulModal>
        )}
        <QueryTable
          type="purchaseOrders"
          columns={paymentColumns(this.handleConfirm, this.handleReject)}
          query={purchaseOrders}
          subComponent={subComponentPayMent}
          status={status}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default ApprovalPayment;
