import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';

import { getOnlineCourses } from '../../GraphQL/query/Products.query';
import {
  SKU_TYPE,
  CATEGORY_TYPE,
  SKU_CATEGORY,
  SKU_VISIBILITY,
  SKU_VISIBILITY_SEARCH_OPTIONS,
  SKU_STATUS_SEARCH_OPTIONS,
  SKU_STATUS_OPTIONS,
} from '../../Domains/constants';
import resolveCategoryName from '../../Domains/resolveCategoryName';
import CategorySelectorInput from '../../Components/CategorySelectorInput';
import { getOnlineCourseDetailsOverviewUrl } from '../../Domains/resolveUrl';
import CategoryCell from '../../Components/CategoryCell';

const onlineCoursesColumns = (skuCategory, pathname, handleEdit) => [
  {
    Header: 'SKU Code',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: (row) => (
      <Link to={{ pathname: `${pathname}/${row.original.SKUCode}/overview` }}>{row.value}</Link>
    ),
  },
  {
    Header: 'Visibility',
    accessor: 'isVisible',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    isHideColumn: true,
    enumOptions: SKU_VISIBILITY_SEARCH_OPTIONS,
  },
  {
    Header: 'Status',
    accessor: 'SKUStatus',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    sortable: false,
    isSearchAble: true,
    enumOptions: SKU_STATUS_SEARCH_OPTIONS,
    Cell: ({ original }) => {
      const { SKUStatus, isVisible } = original ?? {};
      const visibleStatus = isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED;
      const status =
        SKU_STATUS_OPTIONS.find(({ value }) => value === `${visibleStatus}/${SKUStatus}`)?.text ??
        '';
      return status;
    },
  },
  {
    Header: 'Ordering',
    accessor: 'itemNo',
    width: 100,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: true,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1),
    accessor: 'category1Ids',
    type: COLUMN_TYPE.CUSTOM,
    width: 85,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_1}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_1)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category1s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2),
    accessor: 'category2Ids',
    width: 85,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_2}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_2)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category2s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3),
    accessor: 'category3Ids',
    width: 85,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_3}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_3)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category3s} />,
  },
  {
    Header: resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4),
    accessor: 'category4Ids',
    width: 85,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    sortable: false,
    CustomSearchComponent: (props) => (
      <CategorySelectorInput
        {...props}
        skuCategory={skuCategory}
        categoryType={CATEGORY_TYPE.CATEGORY_4}
        placeholder={`Select ${resolveCategoryName(skuCategory, CATEGORY_TYPE.CATEGORY_4)}`}
      />
    ),
    Cell: ({ original }) => <CategoryCell categories={original?.category4s} />,
  },
  {
    Header: 'Permalink',
    accessor: 'permalink',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Action',
    accessor: 'updatedAt',
    width: 80,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      return (
        <Fragment>
          <Button.Group>
            <Button basic size="mini" icon compact onClick={() => handleEdit(original.SKUCode)}>
              <Icon name="edit" />
            </Button>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class OnlineCoursesRoute extends Component {
  static propTypes = {
    skuCategory: PropTypes.oneOf(Object.values(SKU_CATEGORY)), // If possible, will reuse this component with Workshop
    skuType: PropTypes.oneOf(Object.values(SKU_TYPE)),
  };

  static defaultProps = {
    skuCategory: SKU_CATEGORY.ONLINE_COURSE,
    skuType: SKU_TYPE.VIRTUAL,
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { SKUType: props.skuType },
      order: { field: 'updatedAt', type: 'DESC' },
    };
  }

  handleEdit = (skuCode) => {
    this.props.history.push(getOnlineCourseDetailsOverviewUrl(skuCode));
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, SKUType: this.props.skuType }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoProductOnlineCourses.onlineCourses;

  render() {
    const { search, paging, order } = this.state;
    const { skuCategory, location } = this.props;
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Online Courses</Text.Header>
        </Flex>
        <QueryTable
          type={'sfBoProductOnlineCourses'}
          resolveData={this.resolveData}
          columns={onlineCoursesColumns(skuCategory, location.pathname, this.handleEdit)}
          query={getOnlineCourses}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(OnlineCoursesRoute);
