import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateOnlineCoursePricingMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY } from '../../Domains/constants';
import SKUPricingForm from '../SKUPricingForm';

const resolveData = ({ sfBoProductOnlineCourse }) => {
  return {
    ...sfBoProductOnlineCourse,
    SKUStatus: `${
      sfBoProductOnlineCourse.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED
    }/${sfBoProductOnlineCourse.SKUStatus}`,
  };
};

class SubscriptionPricingEditor extends Component {
  render() {
    const { onlineCourse = {} } = this.props;
    const { id, SKUCode } = onlineCourse;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getOnlineCourse}
          editMutation={updateOnlineCoursePricingMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              ...form,
              priceInclVAT: _.omit(form.priceInclVAT, '__typename'),
              salePriceInclVAT: _.omit(form.salePriceInclVAT, '__typename'),
              promotionText: form.promotionText?.content,
            },
          })}
        >
          {({ data, onSubmit }) => {
            return (
              <SKUPricingForm
                initialData={resolveData(data)}
                onSubmit={onSubmit}
                disabledFields={['promotionText']}
              />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default SubscriptionPricingEditor;
