import React, { Component } from 'react';
import PropTypes from 'prop-types';

import delve from '../../../../Util/Delve';
import QueryComponent from '../../../../GraphQL/util/QueryComponent';
import { getAllCourses, COURSE_VERSION } from '../../../../GraphQL/query/Courses.query';

import SelectorInput from './SelectorInput';

const SELECTOR_VALUE_TYPE = { REFER_ID: 'courseReferIds', COURSE_ID: 'courseId' };
const COURSE_FIELDS = {
  REFER_ID: 'meta.referId',
  LANGUAGE: 'language',
};

const parseValue = ({ courses = [] }, valueType, secondaryDisplayField) =>
  courses.map((course) => {
    const value = delve(
      course,
      valueType === SELECTOR_VALUE_TYPE.REFER_ID ? COURSE_FIELDS.REFER_ID : 'id'
    );
    const infoField = delve(course, secondaryDisplayField);
    return {
      key: course.id,
      value: value,
      text:
        infoField !== null && infoField !== undefined
          ? `${course.title} [${infoField}]`
          : `${course.title}`,
    };
  });
class CourseSelectorInputQuery extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    valueType: PropTypes.oneOf(Object.values(SELECTOR_VALUE_TYPE)),
    variables: PropTypes.shape({
      search: PropTypes.shape({
        version: PropTypes.oneOf(Object.values(COURSE_VERSION)).isRequired,
      }).isRequired,
      paging: PropTypes.shape({ currentPage: PropTypes.number, pageSize: PropTypes.number }),
      order: PropTypes.shape({ field: PropTypes.string, type: PropTypes.string }),
    }).isRequired,
    secondaryDisplayField: PropTypes.string,
  };

  static defaultProps = {
    valueType: SELECTOR_VALUE_TYPE.COURSE_ID,
    secondaryDisplayField: 'language',
  };

  render() {
    const { variables, valueType, secondaryDisplayField } = this.props;
    return (
      <QueryComponent query={getAllCourses} variables={variables}>
        {(data) => (
          <SelectorInput
            options={parseValue(data.courses, valueType, secondaryDisplayField)}
            {...this.props}
          />
        )}
      </QueryComponent>
    );
  }
}

CourseSelectorInputQuery.SELECTOR_VALUE_TYPE = SELECTOR_VALUE_TYPE;
CourseSelectorInputQuery.COURSE_VERSION = COURSE_VERSION;
CourseSelectorInputQuery.COURSE_FIELDS = COURSE_FIELDS;

export default CourseSelectorInputQuery;
