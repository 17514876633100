import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from 'semantic-ui-react';

import { Flex, Text } from 'Components/Base';
import Table from 'Components/DataTable';
import { SemanticButton as Button } from 'Components/Base/Button';
import getConfig from 'Util/Config';

const SubHeader = Text.SubHeader;

const InputContainer = styled.div`
  padding-bottom: 1rem;
`;

const percentCompare = (a, b) => {
  return parseFloat(a) - parseFloat(b);
};

const getCompletedSection = (section) => {
  //get anything infront of "/"
  return parseFloat(section.match(/^[^/]+/g));
};

const sectionCompare = (a, b) => {
  return getCompletedSection(a) - getCompletedSection(b);
};

const getApiPath = (courseReferId) =>
  `${getConfig().gateWayApi}/rp-studentProgressReports/${courseReferId}/export`;

export default class StatsTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
        completedRate: PropTypes.number,
        completedSection: PropTypes.string,
        learningHours: PropTypes.number,
        pretest: PropTypes.number,
        posttest: PropTypes.number,
        exam: PropTypes.number,
        section: PropTypes.string,
        round: PropTypes.string,
      })
    ),
    courseReferId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  state = {
    filterValue: '',
  };

  handleSearchInputChange = (value) => {
    this.setState({
      filterValue: value.trim(),
    });
  };

  render() {
    const { data, courseReferId } = this.props;
    const { filterValue } = this.state;
    const filteredTableData = data.filter((data) =>
      data.fullName.toUpperCase().includes(filterValue.toUpperCase())
    );
    return (
      <Fragment>
        <InputContainer>
          <Input
            placeholder={'Search name...'}
            fluid
            onChange={(e, { value }) => this.handleSearchInputChange(value)}
          />
        </InputContainer>
        <Table
          data={filteredTableData}
          columns={[
            {
              Header: 'ชื่อ-สกุล ผู้เรียน',
              accessor: 'fullName',
            },
            {
              Header: (
                <div>
                  <div>Learning Progress</div>
                  <SubHeader>(Completed Rate %)</SubHeader>
                </div>
              ),
              accessor: 'completedRate',
              sortMethod: percentCompare,
            },
            {
              Header: (
                <div>
                  <div>Learning Progress</div>
                  <SubHeader>(Completed Section)</SubHeader>
                </div>
              ),
              accessor: 'completedSection',
              sortMethod: sectionCompare,
            },
            {
              Header: 'Learning Hours',
              accessor: 'learningHours',
            },
            {
              Header: 'Pre-test',
              accessor: 'pretest',
              sortMethod: percentCompare,
            },
            {
              Header: 'Post-test',
              accessor: 'posttest',
              sortMethod: percentCompare,
            },
            {
              Header: 'Exam Results',
              accessor: 'exam',
              sortMethod: percentCompare,
            },
            {
              Header: 'Section',
              accessor: 'section',
            },
            {
              Header: 'Round',
              accessor: 'round',
            },
          ]}
          style={{
            textAlign: 'center',
            display: 'flex',
            alignItem: 'center',
          }}
          defaultPageSize={10}
        />
        <Flex justifyContent="flex-end" mt={3} mb={3}>
          <Button
            as="a"
            href={getApiPath(courseReferId)}
            download="StatisticExcel"
            primary
            circular
            size="medium"
            content="CSV Export"
          />
        </Flex>
      </Fragment>
    );
  }
}
