import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Icon, Radio } from 'semantic-ui-react';

import apolloClient from 'src/GraphQL';
import { getUserCertificatesByUserId } from 'GraphQL/query/Users.query';

import { SemanticButton as Button } from '../../../Components/Base/Button';
import {
  verifyEmail,
  disableStatus,
  evaluateUserCertificates,
  manualVerifyEmail,
} from '../../../GraphQL/mutation/Users.mutation';

export const VerifyEmailButton = ({ userId, isUserVerified }) => (
  <Mutation mutation={verifyEmail}>
    {(verifyUserEmail, { loading: mutationLoading, error, data }) => (
      <Button
        fluid
        disabled={isUserVerified}
        secondary
        loading={mutationLoading}
        onClick={() => {
          verifyUserEmail({ variables: { userId } });
        }}
      >
        <Icon name="shield" />
        Verify Email Account
      </Button>
    )}
  </Mutation>
);

export const ToggleUserStatusRadio = ({ userId, isDisabled }) => (
  <Mutation mutation={disableStatus}>
    {(disableStatus, { loading: mutationLoading, error, data }) => (
      <Radio
        toggle
        checked={!isDisabled}
        onChange={() => {
          disableStatus({ variables: { userId: userId, isDisabled: !isDisabled } });
        }}
      />
    )}
  </Mutation>
);

export const ManualVerifyEmailButton = ({ userId }) => (
  <Mutation mutation={manualVerifyEmail}>
    {(manualVerifyEmail, { loading: mutationLoading, error, data }) => (
      <Button
        fluid
        secondary
        loading={mutationLoading}
        onClick={() => {
          manualVerifyEmail({ variables: { userId } });
        }}
      >
        <Icon name="shield" />
        Verify Email Account
      </Button>
    )}
  </Mutation>
);

export const EvaluateCertificatesButton = ({ userId, onCompleted }) => {
  const [loading, setLoading] = useState(false);

  const evaluate = async () => {
    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: evaluateUserCertificates,
        variables: { userId },
      });

      await apolloClient.refetchQueries({ include: [getUserCertificatesByUserId] });
      onCompleted();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button loading={loading} disabled={loading} onClick={evaluate}>
      <Icon name="refresh" />
      Re-Evaluate Certificates
    </Button>
  );
};
