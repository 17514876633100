import React, { useState, useEffect } from 'react';
import { Divider } from 'semantic-ui-react';

import { Box } from 'Components/Base';

import ActionBar from '../Common/ActionBar';
import FormGenerator from '../../Form/FormGenerator';

const LANGUAGE_SELECT_OPTIONS = [
  {
    text: 'Python',
    value: 'PYTHON',
  },
  {
    text: 'R',
    value: 'R',
  },
  {
    text: 'SQL (Big Query)',
    value: 'SQL',
  },
  {
    text: 'Google Sheet',
    value: 'GOOGLE_SHEET',
  },
  {
    text: 'Golang',
    value: 'GO',
  },
  {
    text: 'Javascript',
    value: 'JAVASCRIPT',
  },
];

const LiveCodingEditor = ({
  newAsset,
  content,
  onCancel,
  onCreateMaterial,
  title,
  description,
  loading,
  materialTypeString,
}) => {
  const [formData, setFormData] = useState(null);
  const { language, courseName, hint, pec, sampleCode, sct, solution } = content ?? {};

  const selectInputList = () => {
    const { title, description, language, courseName, hint, pec, sampleCode, sct, solution } =
      formData ?? {};

    switch (formData?.language) {
      case 'GOOGLE_SHEET': {
        return [
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Title',
            inputName: 'title',
            inputProps: {
              required: true,
              value: title,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
            inputLabel: 'Description',
            inputName: 'description',
            inputProps: {
              value: description,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
            inputLabel: 'Language',
            inputName: 'language',
            inputProps: {
              required: true,
              value: language,
              options: LANGUAGE_SELECT_OPTIONS,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Google Spreadsheet ID',
            inputName: 'courseName',
            inputProps: {
              required: true,
              value: courseName,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Sheet ID (Within the specified Google Sheet)',
            inputName: 'pec',
            inputProps: {
              required: true,
              value: pec,
            },
          },
        ];
      }
      default: {
        return [
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Title',
            inputName: 'title',
            inputProps: {
              required: true,
              value: title,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
            inputLabel: 'Description',
            inputName: 'description',
            inputProps: {
              value: description,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
            inputLabel: 'Language',
            inputName: 'language',
            inputProps: {
              required: true,
              value: language,
              options: LANGUAGE_SELECT_OPTIONS,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Base Course URL',
            inputName: 'courseName',
            inputProps: {
              required: true,
              value: courseName,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
            inputLabel: 'Sample Code',
            inputName: 'sampleCode',
            inputProps: {
              value: sampleCode,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
            inputLabel: 'Hint',
            inputName: 'hint',
            inputProps: {
              value: hint,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
            inputLabel: 'Solution',
            inputName: 'solution',
            inputProps: {
              required: true,
              value: solution,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
            inputLabel: 'Pre-Exercise Code',
            inputName: 'pec',
            inputProps: {
              value: pec,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
            inputLabel: 'SCT',
            inputName: 'sct',
            inputProps: {
              value: sct,
            },
          },
        ];
      }
    }
  };

  const handleFormChange = (value) => {
    setFormData(value);
  };

  const handleAction = () => {
    const { title, description, ...content } = formData;
    onCreateMaterial({
      title,
      description,
      content,
    });
  };

  useEffect(() => {
    setFormData({ title, description, language, courseName, hint, pec, sampleCode, sct, solution });
  }, [courseName, description, hint, language, pec, sampleCode, sct, solution, title]);

  return (
    <Box>
      <Box>
        <FormGenerator
          fields={selectInputList()}
          showAction={false}
          initialData={{
            title,
            description,
            language,
            courseName,
            hint,
            pec,
            sampleCode,
            sct,
            solution,
          }}
          onChange={handleFormChange}
        />
      </Box>
      <Divider />
      <Box>
        <ActionBar
          isCreate={newAsset}
          onAction={handleAction}
          onCancel={onCancel}
          loading={loading}
          materialTypeString={materialTypeString}
        />
      </Box>
    </Box>
  );
};

export default LiveCodingEditor;
