import React, { useState } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import yaml from 'js-yaml';

import { QUESTION_TYPE } from 'GraphQL/query/ExamEditorV1.query';
import { SemanticButton as Button } from 'Components/Base/Button';
import FormGenerator from 'Components/Form/FormGenerator';
import SyntaxHighlighter from 'Components/SyntaxHighlighter';

const YAML = 'yaml';
const DEFAULT_JSON_TO_YAML_SCHEMA = (quizContent) => {
  return {
    subsection_name: `subsection_name`,
    preview: false,
    subsection_contents: [
      {
        quiz_group: [
          {
            quiz: quizContent,
          },
        ],
      },
    ],
  };
};

const feedbackInputList = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Feedback Correct',
    inputName: 'feedbackCorrect',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Fallback Feedback Incorrect',
    inputName: 'feedbackIncorrect',
  },
];

const replaceHTMLTag = (item) => item?.replace(/<(.|\n)*?>/g, '') ?? null;

const toAnswerYAMLMultipleChoice = (answerChoices = []) => {
  return answerChoices?.map((answerChoice, index) => {
    const feedback = answerChoice?.isAnswer
      ? { feedback_correct: replaceHTMLTag(answerChoice?.hint) ?? null }
      : { feedback_incorrect: replaceHTMLTag(answerChoice?.hint) ?? null };
    return {
      title: replaceHTMLTag(answerChoice?.value) ?? null,
      ...feedback,
      id: index,
      position: { top: '0%', left: '0%', width: '0%' },
    };
  });
};

const toAnswerYAMLMultipleSelection = (answerChoices = []) => {
  return answerChoices?.map((answerChoice, index) => {
    return {
      title: `Choice-${index + 1}`,
      id: index,
      position: { top: '0%', left: '0%', width: '0%' },
    };
  });
};

const toCorrectSolutionYAMLMultipleSelection = (answerChoices = [], feedback) => {
  const correctChoice = answerChoices?.reduce((accumulator, answerChoice, index) => {
    if (answerChoice.isAnswer) {
      accumulator.push({ choiceId: index, value: true });
    }
    return accumulator;
  }, []);
  return {
    rule: correctChoice,
    correct: true,
    feedback,
  };
};

const getAllSubsets = (theArray) =>
  theArray.reduce((subsets, value) => subsets.concat(subsets.map((set) => [...set, value])), [[]]);

const toIncorrectSolutionYAMLMultipleSelection = (answerChoices = []) => {
  const answerChoicesIndexIndex = answerChoices?.map((answerChoice, index) => {
    return { ...answerChoice, index };
  });
  const allAnswerChoiceSubset = getAllSubsets(answerChoicesIndexIndex);
  const correctAnswer = answerChoices?.filter((ac) => ac.isAnswer);
  const correctAnswerLength = correctAnswer?.length;
  const incorrectResult = [];
  for (const subset of allAnswerChoiceSubset) {
    const rule = [];
    if (
      (subset?.length !== 0 && subset?.length < correctAnswerLength) ||
      (subset?.length !== 0 && !subset?.every((cr) => cr.isAnswer))
    ) {
      subset.map((answerChoice) =>
        rule.push({
          choiceId: answerChoice.index,
          value: true,
        })
      );
      incorrectResult.push({
        rule,
        correct: false,
        feedback: 'incorrect answer',
      });
    }
  }
  return incorrectResult;
};

const MultipleChoiceView = (props) => {
  const { question, answerChoices, solution } = props;
  const quizContent = {
    type: 'select_one',
    title: replaceHTMLTag(question),
    answers: toAnswerYAMLMultipleChoice(answerChoices),
    explanation: replaceHTMLTag(solution),
    saveAnswer: true,
    bgimage: null,
  };
  const data = DEFAULT_JSON_TO_YAML_SCHEMA(quizContent);
  const yamlString = yaml.safeDump(data);

  return <SyntaxHighlighter codeString={yamlString} language={YAML} />;
};

const MultipleSelectionView = (props) => {
  const { question, answerChoices, solution, feedbackCorrect, feedbackIncorrect } = props;
  const quizContent = {
    version: 2,
    type: 'select_many',
    title: replaceHTMLTag(question),
    answers: toAnswerYAMLMultipleSelection(answerChoices),
    solutions: [
      ...toCorrectSolutionYAMLMultipleSelection(answerChoices, feedbackCorrect),
      ...toIncorrectSolutionYAMLMultipleSelection(answerChoices),
      { correct: false, feedback: feedbackIncorrect },
    ],
    explanation: replaceHTMLTag(solution),
    saveAnswer: true,
    bgimage: null,
  };

  const data = DEFAULT_JSON_TO_YAML_SCHEMA(quizContent);
  const yamlString = yaml.safeDump(data);

  return (
    <div>
      <SyntaxHighlighter codeString={yamlString} language={YAML} />
    </div>
  );
};

const YAMLView = (questionType, props) => {
  switch (questionType) {
    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return <MultipleChoiceView {...props} />;
    case QUESTION_TYPE.MULTIPLE_SELECTION:
      return <MultipleSelectionView {...props} />;
    default:
      return <div>Question type does not support YAML in course version 1 </div>;
  }
};

function ViewAsYAML(props) {
  const [feedbackCorrect, setFeedbackCorrect] = useState(
    'สุดยอด ถ้าอยากดูเฉลยแบบละเอียด สามารถกดปุ่มดูเฉลยตรงด้านขวาได้เลย'
  );
  const [feedbackIncorrect, setFeedbackIncorrect] = useState('ยังไม่ถูกต้อง ลองใหม่อีกครั้ง');

  const handleOnChange = ({ feedbackCorrect, feedbackIncorrect }) => {
    setFeedbackCorrect(feedbackCorrect);
    setFeedbackIncorrect(feedbackIncorrect);
  };
  const { questionType } = props;

  return (
    <Modal
      trigger={
        <Button icon circular>
          <Icon name="search" />
          VIEW AS .YAML
        </Button>
      }
      closeIcon
    >
      <Modal.Header>VIEW AS .YAML (Type: {questionType})</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {questionType === QUESTION_TYPE.MULTIPLE_SELECTION && (
            <FormGenerator
              fields={feedbackInputList}
              showAction={false}
              initialData={{ feedbackCorrect, feedbackIncorrect }}
              onChange={handleOnChange}
            />
          )}
          {YAMLView(questionType, { ...props, feedbackCorrect, feedbackIncorrect })}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default ViewAsYAML;
