import React, { Component } from 'react';
import moment from 'moment';

import { Flex } from 'Components/Base';

import QueryTable from '../../../../GraphQL/util/QueryTable';
import COLUMN_TYPE from '../../../../Components/Search/SearchFormGenerator/constantType';
import { certificateReport as query } from '../../../../GraphQL/query/Reports.query';
import Text from '../../../../Components/Base/Text';
import { CertificateSelectorInput } from '../../Components/SelectorInput';

const getColumns = () => [
  {
    Header: 'User Cert Id',
    accessor: 'userCertId',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Certificate Name',
    accessor: 'certificateName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Company',
    accessor: 'organizationName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Issued Date',
    accessor: 'issuedAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Expiry Date',
    accessor: 'expiredAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Certificate',
    accessor: 'certificateIds',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    show: false,
    CustomSearchComponent: CertificateSelectorInput,
  },
];

class UserCertificateReport extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 20 },
    search: {},
    order: { field: 'issuedAt', type: 'DESC' },
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Certificates</Text.Header>
        </Flex>
        <QueryTable
          type="reportUserCertificate"
          resolveData={(data) => {
            return data.reportUserCertificate.userCertificates;
          }}
          columns={getColumns()}
          query={query}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          exportable
        />
      </div>
    );
  }
}

export default UserCertificateReport;
