import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@skooldio/skooldio-ui-components-core';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styled from 'styled-components';
import moment from 'moment';

import FormGenerator from 'Components/Form/FormGenerator';
import withAppConfig from 'Util/hoc/withAppConfig';

import { DescriptionInput, PublishTimeInput } from '../Components';
import {
  ANNOUNCEMENT_CATEGORY,
  ANNOUNCEMENT_INPUT_LABEL,
  ANNOUNCEMENT_PUBLISH_TYPE,
  PDPA_TEXT,
} from '../Components/constants';

const PDPABox = styled(Box)`
  background-color: #fff2e1;
  color: #92744b;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
`;

const Form = ({ isEdit, isView, onSubmit, onSubmitDraft, appConfig, relationId, ...props }) => {
  const { DESCRIPTION } = PDPA_TEXT;
  const {
    courses: { announcement },
  } = appConfig;

  const {
    enablePushNotification,
    enablePushNotificationCoverImage,
    enablePushNotificationCustomImage,
    enableEmailNotification,
  } = announcement ?? {};

  const { category: defaultCategory } = props.initialData ?? {};
  const [isPushNotification, setIsPushNotification] = useState(
    defaultCategory === ANNOUNCEMENT_CATEGORY.COURSE_WITH_PUSH_NOTIFICATION ||
      defaultCategory === ANNOUNCEMENT_CATEGORY.COURSE_WITH_PUSH_NOTIFICATION_AND_EMAIL
  );
  const [isEmail, setIsEmail] = useState(
    defaultCategory === ANNOUNCEMENT_CATEGORY.COURSE_WITH_EMAIL ||
      defaultCategory === ANNOUNCEMENT_CATEGORY.COURSE_WITH_PUSH_NOTIFICATION_AND_EMAIL
  );

  const checkboxList = [
    enableEmailNotification && {
      label: 'Also send as an email',
      name: ANNOUNCEMENT_PUBLISH_TYPE.EMAIL,
      isChecked: isEmail,
      onChange: (_, { checked }) => {
        setIsEmail(checked);
      },
      show: true,
    },
    enablePushNotification && {
      label: 'Also send as a native push notification',
      name: ANNOUNCEMENT_PUBLISH_TYPE.PUSH_NOTIFICATION,
      isChecked: isPushNotification,
      onChange: (_, { checked }) => {
        setIsPushNotification(checked);
      },
      show: true,
      tooltip: (
        <Flex ml={1}>
          <Tooltip
            arrow
            placement="top"
            title="The ideal character limit for push notifications is around 40 characters for the title and 150 characters for the description."
          >
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Flex>
      ),
    },
    //  {
    //   label: 'Also send as an email',
    //   name: ANNOUNCEMENT_PUBLISH_TYPE.EMAIL,
    //   isChecked: false,
    // }
  ].filter((checkbox) => checkbox);

  const fieldsWith = (isView) => [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: '',
      inputName: 'pdpa',
      customInput: () => <PDPABox>{DESCRIPTION}</PDPABox>,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Subject',
      inputName: 'title',
      inputProps: {
        required: true,
        readOnly: isView,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: ANNOUNCEMENT_INPUT_LABEL.DESCRIPTION,
      inputName: 'detail',
      inputProps: {
        checkboxList,
        required: true,
        readOnly: isView,
      },
      customInput: (props) => <DescriptionInput {...props} />,
    },
    ...(isEmail
      ? [
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputName: 'isEmail',
            inputProps: { value: true },
            hidden: true,
          },
        ]
      : []),
    ...(isPushNotification && enablePushNotificationCoverImage
      ? [
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputName: 'includePushNotificationImage',
            inputProps: { value: 'include' },
            hidden: true,
          },
        ]
      : []),
    ...(isPushNotification
      ? [
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Push Notification Title',
            inputName: 'pushNotificationTitle',
            inputProps: {
              required: true,
              readOnly: isView,
            },
          },
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
            inputLabel: 'Push Notification Description',
            inputName: 'pushNotificationDescription',
            inputProps: {
              required: true,
              readOnly: isView,
            },
          },
        ]
      : []),
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Publish Time',
      inputName: 'publishConfig',
      inputProps: {
        checkBoxLabel: 'Now',
        required: true,
        readOnly: isView,
      },
      customInput: (props) => <PublishTimeInput {...props} />,
    },
    ...(isPushNotification && enablePushNotificationCoverImage && enablePushNotificationCustomImage
      ? [
          {
            inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
            inputLabel: 'Custom Push Notification Image URL',
            inputName: 'customPushNotificationImage',
            inputProps: {
              readOnly: isView,
              placeholder: 'Leave empty to use course cover image',
            },
          },
        ]
      : []),
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Announced by',
      inputName: 'announcedBy',
      inputProps: {
        required: true,
        readOnly: isView,
      },
    },
  ];

  const getPublishCategory = () => {
    if (isEmail && isPushNotification) {
      return ANNOUNCEMENT_CATEGORY.COURSE_WITH_PUSH_NOTIFICATION_AND_EMAIL;
    }
    if (isEmail) return ANNOUNCEMENT_CATEGORY.COURSE_WITH_EMAIL;
    if (isPushNotification) return ANNOUNCEMENT_CATEGORY.COURSE_WITH_PUSH_NOTIFICATION;
    return ANNOUNCEMENT_CATEGORY.COURSE;
  };

  const handleSubmit = (formValue) => {
    onSubmit({
      ...formValue,
      category: getPublishCategory(),
    });
  };

  const handleSubmitDraft = (formValue) => {
    onSubmitDraft({
      ...formValue,
      category: getPublishCategory(),
    });
  };

  const handleCustomValidation = (formValues) => {
    const validationErrors = {};
    const htmlEmptyRegexCheck = /\n|<\/?[^>]*>|&nbsp;|\\n|\s/g; // use for replacing empty html element
    const now = moment();
    const {
      title,
      detail,
      announcedBy,
      publishConfig,
      pushNotificationDescription,
      pushNotificationTitle,
    } = formValues;
    const isNow = publishConfig?.isNow;
    const publishedTime = publishConfig?.publishedTime;
    if (!title || title.trim().length === 0) {
      validationErrors.title = 'Title is required';
    }
    if (!detail?.content || detail?.content?.replace(htmlEmptyRegexCheck, '').trim().length === 0) {
      validationErrors.detail = 'Message is required'; // content comes with html format
    }
    if (!announcedBy || announcedBy.trim().length === 0) {
      validationErrors.announcedBy = 'Announced By is required';
    }
    if (now.isSameOrAfter(publishedTime) && !isNow) {
      validationErrors.publishConfig = 'Invalid date/time';
    }
    if (isPushNotification && !pushNotificationTitle) {
      validationErrors.pushNotificationTitle = 'Push Notification Title is required';
    }
    if (isPushNotification && !pushNotificationDescription) {
      validationErrors.pushNotificationDescription = 'Push Notification Description is required';
    }

    return validationErrors;
  };

  return (
    <FormGenerator
      fields={fieldsWith(isView)}
      compact
      showCancel
      isRequiredConfirmation
      submitText={'Save & Publish'}
      submitContent={`If the information is publish, you won't be able to edit it again.`}
      cancelText="Cancel"
      showAddition
      additionText={'Save Draft'}
      additionContent={`Drafts let you save your edits, so you can come back later.`}
      onSubmit={handleSubmit}
      onAdditionSubmit={handleSubmitDraft}
      readOnly={isView}
      customValidation={handleCustomValidation}
      {...props}
    />
  );
};

Form.propTypes = {
  appConfig: PropTypes.object,
  isEdit: PropTypes.bool,
  isView: PropTypes.bool,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitDraft: PropTypes.func,
  relationId: PropTypes.string.isRequired,
};

export default withAppConfig({ configProp: 'appConfig' })(Form);
