import React from 'react';
import styled from 'styled-components';
import { Grid, Icon, Ref } from 'semantic-ui-react';

const NPGrid = styled(Grid)`
  &.ui.grid > .column:not(.row) {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }
`;

const getDraggableStyle = (style, snapshot) => {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    transitionDuration: `0.001s`,
  };
};

const CustomSegment = styled.div`
  font-size: 1rem;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  margin: 0.5rem 0;
  padding: 1em 1em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
`;

const SectionText = styled.div`
  font-size: 16px;
  font-weight: 300;
`;

const SubsectionCustomRow = ({ subsection, provided, snapshot }) => {
  return (
    <Ref innerRef={provided.innerRef}>
      <CustomSegment
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getDraggableStyle(provided.draggableProps.style, snapshot)}
      >
        <NPGrid verticalAlign="middle">
          <Grid.Column width={1} tablet={1}>
            <Icon name="bars" color="grey" />
          </Grid.Column>
          <Grid.Column width={15} tablet={15}>
            <SectionText>{subsection?.title}</SectionText>
          </Grid.Column>
        </NPGrid>
      </CustomSegment>
    </Ref>
  );
};

export default SubsectionCustomRow;
