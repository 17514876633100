import React from 'react';
import PropTypes from 'prop-types';

import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { getOrganizationEmails } from 'GraphQL/query/Organization.query';

import { useSearchFilter } from '../../Utils';

const BASIC_COLUMN = {
  ID: {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  EMAIL: {
    Header: 'Email Address',
    accessor: 'email',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  IS_BLACKLIST: {
    Header: 'Status',
    accessor: 'isBlacklist',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: [
      { key: 'whitelist', value: false, text: 'Whitelist' },
      { key: 'blacklist', value: true, text: 'Blacklist' },
    ],
    Cell: ({ value }) => (value ? 'Blacklist' : 'Whitelist'),
  },
};

const SearchOrganizationEmails = React.forwardRef(({ organizationId, columns }, ref) => {
  const {
    onSortedChange,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    order,
    paging,
    search,
  } = useSearchFilter({
    defaultPaging: { currentPage: 0, pageSize: 10 },
    defaultSearch: {},
    defaultOrder: { field: 'createdAt', type: 'DESC' },
  });

  const getSearchVariables = () => ({
    organizationId,
    order,
    paging,
    search,
  });

  return (
    <QueryTable
      type={'organizationEmails'}
      columns={columns}
      query={getOrganizationEmails}
      resolveData={(data) => data?.organizationEmails?.organizationEmails ?? []}
      onSearchChange={onSearchChange}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortedChange={onSortedChange}
      getSearchVariables={getSearchVariables}
      paging={paging}
      ref={ref}
    />
  );
});

const DEFAULT_EMAIL_COLUMNS = Object.values(BASIC_COLUMN);

SearchOrganizationEmails.propTypes = {
  columns: PropTypes.object,
  resolveData: PropTypes.func,
  organizationId: PropTypes.string,
};

SearchOrganizationEmails.defaultProps = {
  columns: DEFAULT_EMAIL_COLUMNS,
};

export { BASIC_COLUMN, DEFAULT_EMAIL_COLUMNS };
export default SearchOrganizationEmails;
