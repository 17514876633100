import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';

import { FILE_TYPE } from '../../common';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Code',
    inputName: 'arg1',
    inputProps: {
      required: true,
    },
  },
];

export default class DataForm extends Component {
  static propTypes = {
    onCreateNewData: PropTypes.func,
    isEdit: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(FILE_TYPE)).isRequired,
  };

  static defaultProps = {
    onCreateNewData: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateNewData(form);
  };

  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={fields}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
