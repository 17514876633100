import { mapFormatterCSV } from './dataUtil';

const mapJSON2CSVField = (column) => {
  return {
    label: column.Header || column.accessor,
    value: (row, field) => {
      return column.csvDisplay({ original: row });
    },
    default: column.defaultNullValue || '-',
  };
};

export function getCSVExportOptions(columns = []) {
  const fieldsOption = columns
    .filter((c) => c.show === true || c.show === undefined)
    .map(mapFormatterCSV)
    .map(mapJSON2CSVField);

  return { fields: fieldsOption };
}
