import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import Button from '../../Base/Button';

class ConfirmationModalView extends Component {
  state = { open: false };

  static propTypes = {
    actionText: PropTypes.string,
    buttonColor: PropTypes.string,
    inverted: PropTypes.bool,
    onConfirm: PropTypes.func,
    header: PropTypes.string,
    icon: PropTypes.string,
    triggerButtonStyle: PropTypes.object,
    actionButtonStyle: PropTypes.object,
    modalSize: PropTypes.string,
  };

  static defaultProps = {
    actionText: 'Show',
    buttonColor: 'orange',
    inverted: false,
    onConfirm: () => {},
    header: 'Delete Confirmation',
    icon: '',
    triggerButtonStyle: {},
    actionButtonStyle: {},
    modalSize: 'tiny',
  };

  show = () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  handleConfirm = () => {
    const { onConfirm, value } = this.props;
    onConfirm({ ...value });
    this.close();
  };
  handleCancel = () => {
    this.close();
  };
  handleButtonClick = () => {
    this.show();
  };

  render() {
    const {
      actionText,
      buttonColor,
      inverted,
      children,
      icon,
      triggerButtonStyle,
      actionButtonStyle,
      headerContent,
      modalSize,
    } = this.props;
    return (
      <Fragment>
        <Button
          {...triggerButtonStyle}
          icon={icon}
          inverted={inverted}
          title={actionText}
          onClick={this.handleButtonClick}
        />
        <Modal size={modalSize} open={this.state.open}>
          {headerContent && <Modal.Header>{headerContent}</Modal.Header>}
          <Modal.Content>{children}</Modal.Content>
          <Modal.Actions>
            <Button basic color="grey" title="Cancel" onClick={this.handleCancel} />
            <Button
              {...actionButtonStyle}
              onClick={this.handleConfirm}
              title={actionText}
              icon={icon}
            />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default ConfirmationModalView;
