import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';

import PaymentMenu from './PaymentMenu';

class PaymentLayout extends Component {
  state = {
    activeItem: '',
  };

  handleMenuItemChange = (value) => {
    this.setState({
      activeItem: value,
    });
  };

  render() {
    const { activeItem } = this.state;
    const { children, menus } = this.props;
    return (
      <Container>
        <Grid>
          <Grid.Column mobile={16} computer={3}>
            <PaymentMenu
              menus={menus}
              activeItem={activeItem}
              onMenuItemChange={this.handleMenuItemChange}
            />
          </Grid.Column>
          <Grid.Column textAlign="center" mobile={16} computer={13}>
            {children}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default PaymentLayout;
