import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Icon } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';

import { Flex } from 'Components/Base';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import ConfirmationButton from 'src/Components/ConfirmationButton';
import { userDeviceByUserIdQuery } from 'GraphQL/query/UserDevice.query';
import { deleteAllUserDeviceMutation } from 'GraphQL/mutation/UserDevice.mutation';

import Snackbar, { VARIANTS } from '../Components/Snackbar';

const USER_DEVICE_COLUMNS = [
  {
    Header: 'No.',
    accessor: 'deviceNo',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Identity',
    accessor: 'deviceIdentity',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Model',
    accessor: 'model',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Platform',
    accessor: 'platform',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'OS',
    accessor: 'operatingSystem',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Manufacturer',
    accessor: 'manufacturer',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Last Used',
    accessor: 'latestUsedAt',
    type: COLUMN_TYPE.DATE,
  },
];

const resolveData = ({ userDeviceByUserId }) => userDeviceByUserId.devices;
const resultMapper = ({
  deviceNo,
  deviceIdentity,
  deviceInfo: { model, platform, operatingSystem, manufacturer },
  latestUsedAt,
}) => ({
  deviceNo,
  deviceIdentity,
  platform,
  operatingSystem,
  model,
  manufacturer,
  latestUsedAt,
});

const defaultPaging = { pageSize: 5, currentPage: 0 };

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Device deletion failed, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  IN_PROCESS: {
    message: `Deleting device...`,
    variant: VARIANTS.INFO,
    show: true,
  },
};

const DeleteDeviceButton = ({ userId }) => {
  const [feedback, setFeedback] = useState({ ...FEEDBACK.UNEXPECTED_ERROR, show: false });
  const [deleteAllUserDeviceLogic] = useMutation(deleteAllUserDeviceMutation, {
    onError: (error) => {
      console.error(error);
      const message = error?.message ?? '';

      if (message.includes('401')) {
        setFeedback(FEEDBACK.PROHIBITED);
        return;
      }

      setFeedback(FEEDBACK.UNEXPECTED_ERROR);
      return;
    },
    onCompleted: (data) => {
      const isSuccess = data?.deleteAllUserDevices?.success;
      if (!isSuccess) {
        setFeedback(FEEDBACK.PROHIBITED);
        return;
      }

      setFeedback({
        message: `Devices has been deleted`,
        variant: VARIANTS.SUCCESS,
        show: true,
      });
    },
  });

  const handleOnDeleteAll = () => {
    setFeedback(FEEDBACK.IN_PROCESS);
    deleteAllUserDeviceLogic({
      mutation: deleteAllUserDeviceMutation,
      variables: { userId },
      refetchQueries: [
        {
          query: userDeviceByUserIdQuery,
          variables: { userId },
          fetchPolicy: 'network-only',
        },
      ],
    });
  };

  return (
    <>
      <ConfirmationButton
        headerText={`Delete All?`}
        contentText={'All User Device will be deleted from this user.'}
        buttonStyle={{ compact: false, negative: true }}
        onConfirm={handleOnDeleteAll}
      >
        <Icon name="trash alternate outline" /> Delete All
      </ConfirmationButton>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback((prev) => ({ ...prev.feedback, show: false }))}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
};

const UserDeviceListGQL = ({ userId }) => {
  return (
    <Container>
      <Flex justifyContent="flex-end">
        <DeleteDeviceButton userId={userId} />
      </Flex>
      <QueryTable
        showSearchPanel={false}
        columns={USER_DEVICE_COLUMNS}
        type="userDeviceByUserId"
        getSearchVariables={() => ({ userId })}
        query={userDeviceByUserIdQuery}
        resolveData={resolveData}
        resultMapper={resultMapper}
        alignItems="center"
        paging={defaultPaging}
      />
    </Container>
  );
};

UserDeviceListGQL.propTypes = {
  userId: PropTypes.string,
};

export default UserDeviceListGQL;
