import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Message, Icon, Header } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import { QUESTION_TYPE } from 'GraphQL/query/ExamEditorV1.query';
import { SemanticButton as Button } from 'Components/Base/Button';
import Section from 'Components/Section';
import SingleQuestion from '../SingleQuestion';
import { EXAM_STATUS } from '../SingleExam/constant';

import QuestionListToolbar from './QuestionListToolbar';
import { stripHtml } from './utils';

const checkNewQuestion = (index, totalLength) => totalLength - 1 === index;

const forceShowActive = (showNewQuestion, isNewQuestion) => {
  if (showNewQuestion) {
    if (isNewQuestion) return true;
    else return false;
  }
  return undefined;
};

export const getQuestionIcon = (questionType) => {
  switch (questionType) {
    case QUESTION_TYPE.MULTIPLE_SELECTION:
      return 'check square outline';
    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return 'dot circle outline';
    case QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE:
    case QUESTION_TYPE.FILL_IN_THE_BLANK:
      return 'file text';
    default:
      return '';
  }
};

export default class QuestionList extends Component {
  static propTypes = {
    questionList: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    questionList: [],
  };

  state = {
    editId: null,
    newQuestion: false,
    newQuestionType: null,
  };

  handleAddQuestionClick = (newQuestionType) => {
    this.setState({ newQuestion: true, newQuestionType });
  };

  handleDone = (examId, isNewQuestion) => {
    if (isNewQuestion) {
      this.setState({ newQuestion: false, newQuestionType: null });
    } else {
      this.section.resetActiveIndex();
    }

    this.props.onUpdateQuestion();
  };

  getQuestionList(readOnlyMode = false) {
    const { newQuestion, newQuestionType } = this.state;
    const { courseId, questionList, examStatus, examId, onDeleteQuestion } = this.props;
    const showList = newQuestion
      ? [...questionList, { title: 'New Question', id: 'newquestion', type: newQuestionType }]
      : questionList;

    const list = showList.map(({ type, id, title }, index) => {
      const isNewQuestion = newQuestion && checkNewQuestion(index, showList.length);

      return {
        key: id,
        active: forceShowActive(newQuestion, isNewQuestion),
        title: {
          left: (
            <Fragment>
              <Icon name="dropdown" />
              <Icon name={getQuestionIcon(type)} />
              <Header as="span" size="small">
                {`${index + 1}. `} {stripHtml(title)}
              </Header>
            </Fragment>
          ),
          right: !readOnlyMode && !isNewQuestion && (
            <QuestionListToolbar status={examStatus} onDelete={() => onDeleteQuestion(id)} />
          ),
        },
        content: {
          content: (
            <Box px={3}>
              <SingleQuestion
                examId={examId}
                questionId={id}
                courseId={courseId}
                isNew={isNewQuestion}
                type={type}
                onCancel={() => this.handleDone(examId, isNewQuestion)}
                onQuestionAdded={() => this.handleDone(examId, isNewQuestion)}
                onQuestionUpdated={() => this.handleDone(examId, isNewQuestion)}
                readOnly={readOnlyMode}
              />
            </Box>
          ),
        },
      };
    });

    return list;
  }

  render() {
    const { questionList, examStatus, readOnly } = this.props;
    const { newQuestion } = this.state;

    const isPublished = examStatus === EXAM_STATUS.PUBLISHED;
    const readOnlyMode = isPublished || readOnly;
    return (
      <Box px={3}>
        {questionList.length === 0 && !newQuestion && <Message>{'No Question'}</Message>}
        {(questionList.length > 0 || newQuestion) && (
          <Box my={3}>
            {
              <Section
                sections={this.getQuestionList(readOnlyMode)}
                ref={(section) => {
                  this.section = section;
                }}
              />
            }
          </Box>
        )}
        {!newQuestion && !readOnlyMode && (
          <Fragment>
            <Header size={'small'}>Add Question</Header>
            <Flex>
              <Button
                primary
                circular
                fluid
                onClick={() => this.handleAddQuestionClick(QUESTION_TYPE.MULTIPLE_CHOICE)}
              >
                <Icon name={getQuestionIcon(QUESTION_TYPE.MULTIPLE_CHOICE)} />1 Answer
              </Button>
              <Box px={1} />
              <Button
                primary
                circular
                fluid
                onClick={() => this.handleAddQuestionClick(QUESTION_TYPE.MULTIPLE_SELECTION)}
              >
                <Icon name={getQuestionIcon(QUESTION_TYPE.MULTIPLE_SELECTION)} />
                Mutiple Answers
              </Button>
              <Box px={1} />
              <Button
                primary
                circular
                fluid
                onClick={() =>
                  this.handleAddQuestionClick(QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE)
                }
              >
                <Icon name={getQuestionIcon(QUESTION_TYPE.FILL_IN_THE_BLANK_SKILLSCORE)} />
                Fill in the blank
              </Button>
            </Flex>
          </Fragment>
        )}
      </Box>
    );
  }
}
