import gql from 'graphql-tag';

export const singleExamTag = gql`
  fragment singleExamTag on ExamExamTag {
    id
    name
    slug
    category
    description
  }
`;

const singleExamItemTag = gql`
  fragment singleExamItemTag on ExamExamItemTag {
    id
    name
    slug
    category
    description
  }
`;

export const singleExam = gql`
  fragment singleExam on ExamExam {
    id
    generatorType
    title
    fullScore
    metadata
    status
    totalQuestions
    createdAt
    updatedAt
  }
`;

export const singleExamItem = gql`
  fragment singleExamItem on ExamExamItem {
    id
    examId
    questionId
    type
    question
    solution
    correctAnswer
    status
    score
    metadata
    createdAt
    updatedAt
    deletedAt
  }
`;

export const singleDuplicatedExamItem = gql`
  fragment singleDuplicatedExamItem on ExamDuplicatedExamItem {
    id
    examId
    questionId
    score
    createdAt
    updatedAt
    deletedAt
  }
`;

export const EXAM_TEAM_FRAGMENT = gql`
  fragment EXAM_TEAM_FRAGMENT on ExamExam {
    teams {
      name
      id
      description
    }
  }
`;

export const getExamById = gql`
  query examExam($examId: ID!, $enabledContentTeam: Boolean = false) {
    examExam(examId: $examId) {
      ...singleExam
      ...EXAM_TEAM_FRAGMENT @include(if: $enabledContentTeam)
      tags {
        ...singleExamTag
      }
      examItems {
        ...singleExamItem
        tags {
          ...singleExamItemTag
        }
      }
    }
  }
  ${singleExam}
  ${EXAM_TEAM_FRAGMENT}
  ${singleExamTag}
  ${singleExamItem}
  ${singleExamItemTag}
`;

export const getExams = gql`
  query examExams($search: ExamExamsSearchInput, $paging: ExamPageInput, $order: ExamOrderInput) {
    examExams(search: $search, paging: $paging, order: $order) {
      exams {
        ...singleExam
        tags {
          ...singleExamTag
        }
      }
      total
    }
  }
  ${singleExamTag}
  ${singleExam}
`;

export const getExamItem = gql`
  query examExamItem($examId: ID!, $examItemId: ID!) {
    examExamItem(examId: $examId, examItemId: $examItemId) {
      ...singleExamItem
      tags {
        ...singleExamItemTag
      }
    }
  }
  ${singleExamItem}
  ${singleExamItemTag}
`;

export const singleTag = gql`
  fragment singleTag on ExamTag {
    id
    name
    slug
    category
    description
    createdAt
    updatedAt
  }
`;

export const getTags = gql`
  query examTags($search: ExamTagsSearchInput, $paging: ExamPageInput, $order: ExamOrderInput) {
    examTags(search: $search, paging: $paging, order: $order) {
      total
      examTags {
        ...singleTag
      }
    }
  }
  ${singleTag}
`;

export const getTagBySlug = gql`
  query examTagBySlug($category: EXAM_TAG_CATEGORY!, $slug: String!) {
    examTagBySlug(category: $category, slug: $slug) {
      ...singleTag
    }
  }
  ${singleTag}
`;

export const getTagById = gql`
  query examTagById($tagId: String!) {
    examTagById(tagId: $tagId) {
      ...singleTag
    }
  }
  ${singleTag}
`;

export const getAttachedExams = gql`
  query findAttachedExamExamsByExamTagId($tagId: String!) {
    findAttachedExamExamsByExamTagId(tagId: $tagId) {
      directlyAttachedExams {
        id
        title
      }
      indirectlyAttachedExams {
        id
        title
      }
    }
  }
`;
