import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

const LoadingView = ({ dimmer, loadingText, loaderProps, fullpage = false }) => (
  <div style={{ minHeight: 500 }}>
    {dimmer && (
      <Dimmer active inverted page={fullpage}>
        <Loader {...loaderProps}>{loadingText}</Loader>
      </Dimmer>
    )}
    {!dimmer && <Loader {...loaderProps}>{loadingText}</Loader>}
  </div>
);

LoadingView.defaultProps = {
  dimmer: true,
  loadingText: 'กำลังโหลด...',
  loaderProps: {},
};

export default LoadingView;
