import React, { Component } from 'react';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateOnlineCourseConditionsMutation } from '../../GraphQL/mutation/Products.mutation';
import { ONLINE_COURSE_CONDITION } from '../../Domains/constants';

import OnlineCourseConditionsForm from './OnlineCourseConditionsForm';

const resolveData = ({ sfBoProductOnlineCourse }) => {
  const watchTimeSeconds = _.get(sfBoProductOnlineCourse, 'conditions[0].watchTimeSeconds', -1);
  return {
    type: ONLINE_COURSE_CONDITION.DAYS_VALIDITY, // Only this type for now
    // -1 here means no limit
    // FE needs in Minutes but BE return as Seconds
    days: _.get(sfBoProductOnlineCourse, 'conditions[0].days', -1),
    watchTimeMinutes: watchTimeSeconds < 0 ? watchTimeSeconds : watchTimeSeconds / 60,
  };
};

class OnlineCourseConditionsEditor extends Component {
  render() {
    const { onlineCourse = {} } = this.props;
    const { id, SKUCode } = onlineCourse;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getOnlineCourse}
          editMutation={updateOnlineCourseConditionsMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={({ days, watchTimeMinutes }) => {
            return {
              id,
              condition: {
                type: ONLINE_COURSE_CONDITION.DAYS_VALIDITY,
                days,
                watchTimeSeconds: watchTimeMinutes === -1 ? -1 : watchTimeMinutes * 60,
              },
            };
          }}
        >
          {({ data, onSubmit }) => {
            return (
              <OnlineCourseConditionsForm initialData={resolveData(data)} onSubmit={onSubmit} />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default OnlineCourseConditionsEditor;
