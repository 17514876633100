import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const mapCourseCodeToOptions = (courseCodes) => {
  return courseCodes.map((courseCode, idx) => ({
    id: idx,
    key: idx,
    value: courseCode,
    text: courseCode,
  }));
};

const CourseCodeInput = ({ value: courseCodes, readOnly, onChange }) => {
  const options = mapCourseCodeToOptions(courseCodes || []);

  const handleAddCourseCode = (newCourseCode) => {
    const isNotEmptyString = newCourseCode.trim().length >= 1;
    if (isNotEmptyString) {
      options.push({
        id: options.length,
        key: options.length,
        text: newCourseCode,
        value: newCourseCode,
      });
    }
  };
  const handleOnChange = (courseCodes) => {
    onChange(
      {},
      {
        value: courseCodes,
      }
    );
  };

  return (
    <Box alignItems="center">
      <Dropdown
        fluid
        multiple
        search
        selection
        allowAdditions
        options={options}
        value={courseCodes || []}
        onChange={(_, { value }) => handleOnChange(value)}
        onAddItem={(_, { value }) => handleAddCourseCode(value)}
        disabled={readOnly}
      />
    </Box>
  );
};

CourseCodeInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(CourseCodeInput);
