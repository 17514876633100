import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import omitDeep from 'Util/omitDeep';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import { updateCollectionOnlineCourseTableOfContentsMutation } from '../../GraphQL/mutation/Products.mutation';
import { getCollectionOnlineCourse } from '../../GraphQL/query/Products.query';
import TableOfContentView from '../WorkshopContent';

export default function BundleTableOfContentEditor({ onlineCourse }) {
  const { id, SKUCode } = onlineCourse;
  const { loading, error, data: queryData } = useQuery(getCollectionOnlineCourse, {
    variables: { SKUCode: SKUCode },
    fetchPolicy: 'network-only',
  });

  const [
    updateTableOfContents,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(updateCollectionOnlineCourseTableOfContentsMutation);

  function handleUpdateContents(data) {
    const clonedData = JSON.parse(JSON.stringify(data));
    const formatData = omitDeep(clonedData, ['id', '__typename']);
    updateTableOfContents({
      variables: {
        id: id,
        data: {
          tableOfContents: formatData,
        },
      },
    });
  }

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  const contents = queryData?.sfBoProductCollectionOnlineCourse?.tableOfContents ?? [];

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={true}
        response={mutationResponse?.sfBoProductUpdateCollectionOnlineCourseTableOfContents}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <TableOfContentView
        loading={mutationLoading}
        contents={contents}
        onUpdateContents={handleUpdateContents}
        accessor={{ title: 'title', description: 'description' }}
      />
    </Fragment>
  );
}
