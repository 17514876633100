import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Divider, Header } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import FileUploadManagerModal from '../../Lib/UploadComponent';
import { SemanticButton as Button } from '../../Base/Button';
import MaterialMeta from '../Common/MaterialMeta';
import ActionBar from '../Common/ActionBar';
import { AssetPropTypes } from '../Common/AssetPropTypes';

import DragableList from './DragableList';

const Toolbar = ({ onFileListRequest, onFileUploadUrlRequest, onAddFile, uploadMode }) => (
  <Flex justifyContent="space-between" alignItems="center" mb={3}>
    <Box>
      <Header size="small">Reorder Content</Header>
    </Box>
    <FileUploadManagerModal
      keyGroup={'aaaa'}
      uploadUrl={'http://localhost:5000'}
      listUrl={'http://localhost:5000'}
      customFileListUrlRequest={onFileListRequest}
      customUploadUrlRequest={onFileUploadUrlRequest}
      dimmer={'inverted'}
      mode={uploadMode}
      onFileManagerDialogClose={onAddFile}
      trigger={
        <Button primary circular>
          <Icon name="plus" size="small" />
          Slides
        </Button>
      }
    />
  </Flex>
);

/**
 * WARNING: change this will effect SKOOLDIO and LEARNx platform. Consult with Roparat first!
 * 27 Nov 2019: Now it will break LEARNx. SKOOLDIO use for Workshop's image gallery. AN does not use
 */
const convertToRawData = (data, prefix) => {
  return {
    prefix,
    data,
  };
};

const convertFromRawData = (content = {}) => {
  return content.data || [];
};

class Slides extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      data: convertFromRawData(props.content),
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ data: convertFromRawData(this.props.content), formData: {} });
    }
  }
  handleAction = () => {
    const { formData, data } = this.state;

    const resultObject = {
      ...formData,
      content: convertToRawData(data),
    };

    console.log(resultObject);

    this.props.onCreateMaterial(resultObject);
  };
  handleDataChange = (data) => {
    this.setState({ data });
  };
  handleAddSlides = (slides = []) => {
    // const { bucket, key, url } = slides;
    if (slides && slides.length > 0) {
      this.setState((prevState) => ({ data: [...prevState.data, ...slides] }));
    }
  };
  handleFormChange = (value) => {
    this.setState({ formData: value });
  };
  render() {
    const {
      newAsset,
      onCancel,
      title,
      description,
      loading,
      onFileListRequest,
      onFileUploadUrlRequest,
      materialTypeString,
      uploadMode,
      hideMeta,
    } = this.props;
    const { data } = this.state;
    return (
      <Box>
        {hideMeta && (
          <Fragment>
            <Box>
              <MaterialMeta
                title={title}
                description={description}
                onChange={this.handleFormChange}
              />
            </Box>
            <Divider hidden />
          </Fragment>
        )}
        <Box>
          <Toolbar
            onFileListRequest={onFileListRequest}
            onFileUploadUrlRequest={onFileUploadUrlRequest}
            onAddFile={this.handleAddSlides}
            uploadMode={uploadMode}
          />
        </Box>
        <Box m={3}>
          <DragableList initialValue={data} onChange={this.handleDataChange} />
        </Box>
        <Divider />
        <Box>
          <ActionBar
            isCreate={newAsset}
            onAction={this.handleAction}
            onCancel={onCancel}
            loading={loading}
            materialTypeString={materialTypeString}
          />
        </Box>
      </Box>
    );
  }
}

Slides.propTypes = {
  ...AssetPropTypes,
  content: PropTypes.shape({
    prefix: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        bucket: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
  hideMeta: PropTypes.bool,
};

Slides.defaultProps = {
  onCreateMaterial: () => {},
  content: { data: [] },
  onFileListRequest: () => {},
  onFileUploadUrlRequest: () => {},
};

export default Slides;
