/* eslint-disable react/display-name */
import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import _ from 'lodash';
import { Button, Icon, Label } from 'semantic-ui-react';

import getConfig from 'Util/Config';
import withAppConfig from 'Util/hoc/withAppConfig';
import { Box, Flex } from 'Components/Base';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import ConfirmationButton from 'Components/ConfirmationButton';
import ErrorView from 'Components/ErrorView';
import { deletePersonalizedDiscountCode } from 'Routes/SalesV2/GraphQL/mutation/PersonalizedDiscountCode.mutation';

import { getDiscountCodeTemplates } from '../../GraphQL/query/PersonalizedDiscountCode.query';
import { PersonalizedDiscountModal } from '../../components/PersonalizedDiscountCodeModal';
import { OnlineCourseSelectorInputQuery } from '../../components/ProductSelectorInput';

const { personalizedDiscountCode: personalizedDiscountCodeConfig } = getConfig();

const columns = (handleDelete, variables) => [
  {
    Header: 'มูลค่า',
    accessor: 'discount',
    width: 150,
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: false,
  },
  {
    Header: 'หน่วย',
    accessor: 'discountUnit',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: 'FIX', value: 'FIX', text: 'FIX' },
      { key: 'PERCENT', value: 'PERCENT', text: 'PERCENT' },
    ],
    isSearchAble: false,
  },
  {
    Header: 'refCode',
    accessor: 'refCode',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: personalizedDiscountCodeConfig?.refCodeOptions ?? [],
    isSearchAble: true,
  },
  {
    Header: 'Course Code',
    accessor: 'SKUCodes',
    width: 200,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    CustomSearchComponent: (props) => <OnlineCourseSelectorInputQuery {...props} />,
    Cell: ({ original }) => (
      <Flex flexWrap="wrap" m={-1}>
        {original?.SKUCodes.map((SKUCode) => (
          <Flex key={SKUCode} p={1}>
            <Label>{SKUCode}</Label>
          </Flex>
        ))}
      </Flex>
    ),
  },
  {
    Header: 'Description',
    accessor: 'discountText',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'สกุลเงิน',
    accessor: 'currency',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [{ key: 'THB', value: 'THB', text: 'THB' }],
    isSearchAble: false,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: ({ original }) => {
      const discountId = original.id;
      return (
        <Fragment>
          <Button.Group>
            <PersonalizedDiscountModal
              isEdit
              variables={variables}
              discountId={discountId}
              data={original}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="edit" />
                </Button>
              }
            />
            <ConfirmationButton
              headerText={`Delete?`}
              contentText={'This will be permanently deleted from the system.'}
              confirmText={'Delete'}
              onConfirm={() => {
                handleDelete(discountId);
              }}
            >
              <Icon name="trash alternate outline" />
            </ConfirmationButton>
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class PersonalizedDiscountRoute extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'id', type: 'ASC' },
  };
  mutateDiscount = (mutation, discountId) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    client
      .mutate({
        mutation,
        variables: { id: discountId },
        refetchQueries: [
          {
            query: getDiscountCodeTemplates,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };
  resolveData = (data) => {
    return data.sfBoSearchPersonalizedDiscountCodeTemplates;
  };

  handleDelete = (discountId) => {
    this.mutateDiscount(deletePersonalizedDiscountCode, discountId);
  };
  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };

  handleButtonOnClick = () => this.props.history.push('/sf/file-import/discount-codes');

  render() {
    const { search, paging, order } = this.state;
    const { appConfig } = this.props;
    const variables = { search, paging, order };
    if (!appConfig?.enabledPersonalizedDiscountCode) {
      return <ErrorView message={'This feature is temporarily disabled.'} />;
    }
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Personalized Discount code</Text.Header>
          <Box>
            <PersonalizedDiscountModal
              variables={variables}
              trigger={
                <Button primary circular>
                  <Icon name="plus" size="small" />
                  New
                </Button>
              }
            />
            <Button primary circular onClick={this.handleButtonOnClick}>
              <Icon name="plus" size="small" />
              Import
            </Button>
          </Box>
        </Flex>
        <QueryTable
          type="sfBoSearchPersonalizedDiscountCodeTemplates"
          resolveData={this.resolveData}
          columns={columns(this.handleDelete, variables)}
          query={getDiscountCodeTemplates}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  withAppConfig({ configProp: 'appConfig' })
)(PersonalizedDiscountRoute);
