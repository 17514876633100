import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';

import { DateRangeInput } from '../../Components/DateRangeInput'; // Dependency

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Code',
    inputName: 'code',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ชื่อเทอม',
    inputName: 'name',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'ปีการศึกษา',
    inputName: 'schoolYear',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'วันที่เริ่มต้น ถึง วันที่สิ้นสุด',
    inputName: 'dateRange',
    customInput: ({ name }) => <DateRangeInput name={name} />,
  },
];

export default class SchoolTermForm extends Component {
  static propTypes = {
    onCreateSchoolTerm: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateSchoolTerm: () => {},
    isEdit: false,
    initialData: {},
  };

  handleSubmit = (form) => {
    this.props.onCreateSchoolTerm(form);
  };

  render() {
    const { isEdit, initialData } = this.props;
    return (
      <FormGenerator
        fields={fields}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
        initialData={{
          ...initialData,
          dateRange: { startDate: initialData.startDate, endDate: initialData.endDate },
        }}
      />
    );
  }
}
