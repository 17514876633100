import React from 'react';
import PropTypes from 'prop-types';

import {
  SFBO_SHIPMENTS,
  SFBO_SHIPMENT_METHOD,
  SFBO_SHIPMENT_PROVIDER,
} from 'GraphQL/query/Shipment.query';
//TODO: Clean up import
import { useSearchFilter } from 'Routes/Users/Utils';
import QueryTable, { DATA_FORMAT } from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import { SKU_CATEGORY_TEXT } from 'Routes/Products/Domains/constants';
import getConfig from 'Util/Config';

import { SHIPMENT_STATUS, SHIPMENT_STATUS_TEXT } from '../Domains/constant';
import useSKUCategory from '../Domains/useSKUCategory';

export const SHIPMENT_METHOD_TEXT = {
  [SFBO_SHIPMENT_METHOD.DELIVER_BY_SUPPLIER]: 'Supplier',
  [SFBO_SHIPMENT_METHOD.ONLINE]: 'Online',
};
const shipmentProviders = getConfig().shipment.shipmentProviders;
export const SHIPMENT_PROVIDER_TEXT = {
  [SFBO_SHIPMENT_PROVIDER.FLASH_EXPRESS]: 'Flash Express',
  [SFBO_SHIPMENT_PROVIDER.OFFICIAL]: 'Official',
  [SFBO_SHIPMENT_PROVIDER.KERRY]: 'Kerry Express',
  [SFBO_SHIPMENT_PROVIDER.TP_EMS]: 'Thailand Post EMS',
  [SFBO_SHIPMENT_PROVIDER.TP_REG]: 'Thailand Post Regiested',
  [SFBO_SHIPMENT_PROVIDER.BEST]: 'Best Express',
  [SFBO_SHIPMENT_PROVIDER.DHL]: 'DHL',
  [SFBO_SHIPMENT_PROVIDER.ALP]: 'Alpha',
  [SFBO_SHIPMENT_PROVIDER.NINJA]: 'Ninja Van',
  [SFBO_SHIPMENT_PROVIDER.JT]: 'J&T Express',
  [SFBO_SHIPMENT_PROVIDER.SCG]: 'SCG Express',
  [SFBO_SHIPMENT_PROVIDER.NIM]: 'NIM Express',
  [SFBO_SHIPMENT_PROVIDER.OTHER]: 'Other',
};

const BASIC_COLUMN = {
  USER_ID: {
    Header: 'User Id',
    accessor: 'userId',
    type: COLUMN_TYPE.STRING,
    width: 100,
    isSearchAble: true,
  },
  ORDER_ID: {
    Header: 'Order Id',
    accessor: 'orderId',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  ORDER_NO: {
    Header: 'Order No.',
    accessor: 'orderNo',
    type: COLUMN_TYPE.STRING,
    Cell: ({ original }) => original?.order?.orderNo ?? '',
    isSearchAble: true,
    sortable: false,
  },
  STATUS: {
    Header: 'Status',
    accessor: 'status',
    type: COLUMN_TYPE.ENUM,
    enumOptions: Object.values(SHIPMENT_STATUS).map((status) => ({
      key: status,
      text: SHIPMENT_STATUS_TEXT[status],
      value: status,
    })),
    Cell: ({ original }) => SHIPMENT_STATUS_TEXT[original.status] ?? '',
    isSearchAble: true,
  },
  TRACKING_NUMBER: {
    Header: 'Tracking no.',
    accessor: 'delivery.trackingNumber',
    Cell: ({ original }) => original?.delivery?.trackingNumber ?? '',
    isSearchAble: true,
    sortable: false,
  },
  SHIPMENT_METHOD: {
    Header: 'Method',
    accessor: 'shipmentMethod',
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      {
        key: SFBO_SHIPMENT_METHOD.DELIVER_BY_SUPPLIER,
        value: SFBO_SHIPMENT_METHOD.DELIVER_BY_SUPPLIER,
        text: SHIPMENT_METHOD_TEXT[SFBO_SHIPMENT_METHOD.DELIVER_BY_SUPPLIER],
      },
      {
        key: SFBO_SHIPMENT_METHOD.ONLINE,
        value: SFBO_SHIPMENT_METHOD.ONLINE,
        text: SHIPMENT_METHOD_TEXT[SFBO_SHIPMENT_METHOD.ONLINE],
      },
    ],
    Cell: ({ original }) => SHIPMENT_METHOD_TEXT[original.shipmentMethod] ?? '',
    isSearchAble: false,
  },
  SHIPMENT_PROVIDER: {
    Header: 'Shipment Provider',
    accessor: 'shipmentProvider',
    Cell: ({ original }) => SHIPMENT_PROVIDER_TEXT[original?.delivery?.shipmentProvider] ?? '',
    type: COLUMN_TYPE.ENUM,
    enumOptions: shipmentProviders.map((provider) => ({
      key: provider,
      value: provider,
      text: SHIPMENT_PROVIDER_TEXT[provider],
    })),
    isSearchAble: true,
  },
  SKU_CODE: {
    Header: 'SKUCode',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  SKU_CATEGORY: {
    Header: 'SKUCategory',
    accessor: 'SKUCategory',
    type: COLUMN_TYPE.ENUM,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    enumOptions: useSKUCategory().map((category) => ({
      key: category,
      text: SKU_CATEGORY_TEXT[category],
      value: category,
    })),
    Cell: ({ original }) => SKU_CATEGORY_TEXT[original.SKUCategory] ?? '',
    isSearchAble: true,
  },
  CREATE_AT: {
    Header: 'CreatedAt',
    accessor: 'createdAt',
    width: 100,
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATE_FORMAT,
    isSearchAble: true,
  },
  UPDATE_AT: {
    Header: 'UpdatedAt',
    accessor: 'updatedAt',
    width: 100,
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATE_FORMAT,
    isSearchAble: true,
  },
};

const SearchShipments = React.forwardRef(({ columns, resolveData }, ref) => {
  const {
    onSortedChange,
    onSearchChange,
    onPageChange,
    onPageSizeChange,
    search,
    order,
    paging,
  } = useSearchFilter();
  return (
    <QueryTable
      ref={ref}
      type={'sfBoShipments'}
      columns={columns}
      query={SFBO_SHIPMENTS}
      resolveData={resolveData}
      search={{ ...search, shipmentMethod: SFBO_SHIPMENT_METHOD.DELIVER_BY_SUPPLIER }}
      onSearchChange={onSearchChange}
      paging={paging}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      order={order}
      onSortedChange={onSortedChange}
    />
  );
});

const DEFAULT_SHIPMENT_COLUMNS = Object.values(BASIC_COLUMN);

SearchShipments.displayName = 'SearchShipments';

SearchShipments.propTypes = {
  columns: PropTypes.array,
  resolveData: PropTypes.func,
};

SearchShipments.defaultProps = {
  columns: DEFAULT_SHIPMENT_COLUMNS,
  resolveData: (data) => data?.sfBoShipments?.shipments ?? [],
};

export { BASIC_COLUMN, DEFAULT_SHIPMENT_COLUMNS };
export default SearchShipments;
