import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import CheckCloseComponent from '../../../../Util/CheckDefinedComponent';
import {
  createPersonalizedDiscountCodeTemplate,
  updatePersonalizedDiscountCode,
} from '../../GraphQL/mutation/PersonalizedDiscountCode.mutation';
import { getDiscountCodeTemplates } from '../../GraphQL/query/PersonalizedDiscountCode.query';

import PersonalizedDiscountForm from './PersonalizedDiscountForm';

const transformDiscount = (value) => {
  // SKOOLDIO-4096 ensure discount is string
  if (value.discount) {
    value.discount = parseFloat(value.discount);
  }
  if (value.total) {
    value.total = parseFloat(value.total);
  }
  if (value.remain) {
    value.remain = parseFloat(value.remain);
  }

  if (!value.SKUCodes) value.SKUCodes = [];
  delete value.validationType;

  return value;
};

export default class NewDiscountMutation extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    discountId: PropTypes.string,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onComplete: () => {},
  };

  renderEdit() {
    const { discountId, data, variables } = this.props;
    let initialData = { ...data };

    return (
      <Mutation
        mutation={updatePersonalizedDiscountCode}
        refetchQueries={[{ query: getDiscountCodeTemplates, variables }]}
      >
        {(updateDiscount, { loading, error, data: mutateData }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent
                checkDefinedObject={mutateData}
                onDefined={this.props.onComplete}
              />
              <PersonalizedDiscountForm
                onCreateNewDiscount={(value) => {
                  updateDiscount({
                    variables: { input: transformDiscount(value), id: discountId },
                  });
                }}
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                initialData={initialData}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  renderCreate() {
    const { variables } = this.props;
    return (
      <Mutation
        mutation={createPersonalizedDiscountCodeTemplate}
        refetchQueries={[{ query: getDiscountCodeTemplates, variables }]}
      >
        {(createDiscount, { loading, error, data }) => {
          return (
            <Fragment>
              {error && <Message negative>{JSON.stringify(error.message)}</Message>}
              <CheckCloseComponent checkDefinedObject={data} onDefined={this.props.onComplete} />
              <PersonalizedDiscountForm
                onCreateNewDiscount={(value) => {
                  createDiscount({ variables: { input: transformDiscount(value) } });
                }}
                loading={loading}
                showCancel
                onCancelled={this.props.onCancelled}
                {...this.props}
              />
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  render() {
    const { isEdit } = this.props;
    if (!isEdit) return this.renderCreate();
    return this.renderEdit();
  }
}
