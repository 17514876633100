import gql from 'graphql-tag';

export const userDeviceByUserIdQuery = gql`
  query userDeviceByUserId($userId: ID!) {
    userDeviceByUserId(userId: $userId) {
      devices {
        id
        deviceNo
        deviceIdentity
        deviceInfo {
          uuid
          model
          platform
          operatingSystem
          manufacturer
        }
        latestUsedAt
      }
    }
  }
`;
