import React, { Fragment } from 'react';
import { Modal, Icon } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import { fileImportJob } from 'GraphQL/LE/query/FileImport.query';
import Loading from 'Components/Loading';
import { Flex } from 'Components/Base';
import Table from 'Components/DataTable';
import { SemanticButton as Button } from 'Components/Base/Button';
import QueryComponent from 'GraphQL/util/QueryComponent';

import { FILE_IMPORT_STATUS } from '../../common';

function SummaryTable({ data }) {
  if (!data || !data.boFileImportJob) {
    return <Loading />;
  }

  const { result } = data.boFileImportJob;

  const sortedSummaries = [...result?.resultSummaries].sort(
    (a, b) => (b.status === FILE_IMPORT_STATUS.FAILED) - (a.status === FILE_IMPORT_STATUS.FAILED)
  );

  const columns = [
    {
      Header: 'No.',
      accessor: 'index',
      width: 100,
      style: { display: 'flex', justifyContent: 'center' },
      searchable: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
      style: { display: 'flex', justifyContent: 'center' },
    },
    {
      Header: 'Info',
      accessor: 'info',
      style: { marginLeft: '32px' },

      Cell: function ResultDetailsCell({ original }) {
        const { info } = original;
        const { fileName, studentId, studentName } = JSON.parse(info);
        return `${studentId}  |  ${studentName}${fileName ? `  |  ${fileName}` : ''}`;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={sortedSummaries.map((result, index) => ({ ...result, index: index + 1 }))}
      alignItems="baseline"
      defaultPageSize={10}
    />
  );
}

function ResultSummariesModal(props) {
  const { jobId, trigger } = props;

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>Result Details</Modal.Header>
          <Modal.Content>
            <QueryComponent
              query={fileImportJob}
              variables={{ id: jobId }}
              fetchPolicy={'network-only'}
            >
              {(data) => <SummaryTable data={data} />}
            </QueryComponent>

            <Flex mt={2} justifyContent="flex-end" alignItems="center">
              <Button primary circular onClick={closeModal}>
                <Icon name="close" size="small" />
                Close
              </Button>
            </Flex>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default ResultSummariesModal;
