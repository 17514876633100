import React, { Fragment } from 'react';
import { withTheme } from 'styled-components';
import { Divider, Container } from 'semantic-ui-react';

import APP_PERMISSION from 'Util/permissions/permissionKeys';
import Menu from 'Containers/Menu';
import { GenRoute } from 'Util/Route';
import { Flex } from 'Components/Base';
import getConfig from 'Util/Config';

import BundleHeader from '../../Components/BundleHeader';
import {
  BundleOverviewEditor,
  BundleCategoriesEditor,
  BundleInstructorsEditor,
  BundleDetailEditor,
  BundlePricingEditor,
  BundleTableOfContentEditor,
  BundleHighlightEditor,
  BundleItemsEditor,
  BundleGalleryEditor,
  BundleStockEditor,
} from '../../Components/BundleEditor';
import ProductPreviewButton from '../../Components/ProductPreviewButton';

const genMenu = (matchUrl) => {
  const menus = [
    {
      path: `${matchUrl}/overview`,
      title: 'Overview',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BUNDLE.OVERVIEW.VIEW,
      ],
      route: BundleOverviewEditor,
    },
    {
      path: `${matchUrl}/categories`,
      title: 'Categories',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BUNDLE.CATEGORIES.VIEW,
      ],
      route: BundleCategoriesEditor,
    },
    {
      path: `${matchUrl}/instructors`,
      title: 'Instructors',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BUNDLE.INSTRUCTORS.VIEW,
      ],
      route: BundleInstructorsEditor,
    },
    {
      path: `${matchUrl}/details`,
      title: 'Details',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BUNDLE.DETAILS.VIEW,
      ],
      route: BundleDetailEditor,
    },
    {
      path: `${matchUrl}/pricing`,
      title: 'Pricing',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BUNDLE.PRICING.VIEW,
      ],
      route: BundlePricingEditor,
    },
    {
      path: `${matchUrl}/contents`,
      title: 'Table of Contents',
      private: true,
      exact: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.BUNDLE.TABLE_OF_CONTENTS.VIEW],
      route: BundleTableOfContentEditor,
    },
    {
      path: `${matchUrl}/highlights`,
      title: 'Highlights',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BUNDLE.HIGHLIGHTS.VIEW,
      ],
      route: BundleHighlightEditor,
    },
    {
      path: `${matchUrl}/gallery`,
      title: 'Gallery',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BUNDLE.GALLERY.VIEW,
      ],
      route: BundleGalleryEditor,
    },
    {
      path: `${matchUrl}/items`,
      title: 'Items',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.COLLECTION.ITEMS.VIEW,
        APP_PERMISSION.BUNDLE.ITEMS.VIEW,
      ],
      route: BundleItemsEditor,
    },
    {
      path: `${matchUrl}/stock`,
      title: 'Stock',
      private: true,
      exact: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.BUNDLE.STOCK.VIEW,
      ],
      route: BundleStockEditor,
    },
  ];

  return menus;
};
const BundleDetails = withTheme((props) => {
  const { bundle, match, theme } = props;
  const { productPreview } = getConfig();
  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: theme.colors.primary,
        }}
      >
        <Container>
          <BundleHeader {...bundle} />
        </Container>
        {productPreview?.enabledProductPreview && (
          <Flex justifyContent="flex-end" width="100%" pr={2} pb={3}>
            <ProductPreviewButton
              platformURL={productPreview.platformURL}
              permalink={bundle.permalink}
            />
          </Flex>
        )}
      </Container>
      <Divider hidden />
      <Menu menus={genMenu(match.url)} menuProps={{ secondary: true, pointing: true }} />
      <GenRoute menus={genMenu(match.path)} bundle={bundle} />
    </Fragment>
  );
});

export default BundleDetails;
