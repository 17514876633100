export const RANDOM_DISCOUNT_CODE_JOB_STATUS = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
};

export const randomDiscountCodeJobOptions = Object.keys(RANDOM_DISCOUNT_CODE_JOB_STATUS).map(
  (status) => ({
    key: status,
    value: status,
    text: status,
  })
);
