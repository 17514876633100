import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { GenSwitchRoute } from 'Util/Route';
import SideMenuLayout from 'Components/SideMenuLayout';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import SchoolTermsRoute from './Routes/SchoolTerms';
import SchoolGradesRoute from './Routes/SchoolGrades';
import SchoolProgrammesRoute from './Routes/SchoolProgrammes';
import CareerPlansRoute from './Routes/CareerPlans';
const EducationsRouteIndex = () => (
  <Redirect to={{ title: '', pathname: '/lsp/educations/terms' }} />
);

const defaultRoute = {
  path: '/lsp/educations',
  exact: true,
  roles: [],
  route: EducationsRouteIndex,
};

const menus = [
  {
    path: '/lsp/educations/terms',
    title: 'เทอม ปีการศึกษา',
    menuName: 'เทอม ปีการศึกษา',
    icon: 'calendar',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: SchoolTermsRoute,
  },
  {
    path: '/lsp/educations/grades',
    title: 'ระดับชั้น',
    menuName: 'ระดับชั้น',
    icon: 'certificate',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: SchoolGradesRoute,
  },
  {
    path: '/lsp/educations/programmes',
    title: 'แผนการเรียน',
    menuName: 'แผนการเรียน',
    icon: 'cogs',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: SchoolProgrammesRoute,
  },
  {
    path: '/lsp/educations/plans',
    title: 'แผนอาชีพ',
    menuName: 'แผนอาชีพ',
    icon: 'university',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: CareerPlansRoute,
  },
];

export default class EducationsRoute extends Component {
  render() {
    return (
      <SideMenuLayout header={'หัวข้อย่อย'} menus={menus} contentProps={{ textAlign: 'left' }}>
        <GenSwitchRoute routes={[...menus, defaultRoute]} />
      </SideMenuLayout>
    );
  }
}
