import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.PASSWORD_FIELD,
    inputLabel: 'Password',
    inputName: 'password',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.PASSWORD_FIELD,
    inputLabel: 'Confirm Password',
    inputName: 'passwordVerify',
    inputProps: {
      required: true,
    },
  },
];

export default class UsersPasswordForm extends Component {
  checkPassword = (currentValues) => {
    const { password, passwordVerify } = currentValues;
    if (passwordVerify && password !== passwordVerify && passwordVerify.length > 0) {
      return { passwordVerify: 'Not Match' };
    }
    return {};
  };

  render() {
    return (
      <Fragment>
        <FormGenerator
          fields={fields}
          customValidation={this.checkPassword}
          submitText="Submit"
          cancelText="Cancel"
          showCancel
          {...this.props}
        />
      </Fragment>
    );
  }
}
