import React, {
  Fragment,
  useMemo,
  useCallback,
  useState,
  useRef,
  useImperativeHandle,
} from 'react';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Flex, Text, Box, ButtonFilled } from 'Components/Base';
import StatefulModal from 'Components/Modal/StatefulModal';

import { UNASSIGN_TEAM } from '../../Graphql/mutation/Team.mutation';
import Snackbar, { VARIANTS } from '../../Components/Snackbar';
import DeleteIconModal from '../DeleteIconModal';

import SearchTeamUser, { DEFAULT_USER_COLUMNS } from './SearchTeamUser';
import AssignUsersToTeam from './AssignUsersToTeam';

const ModalContentContainer = styled((props) => <Box {...props} />)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #f1ad46;
  }
`;

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const AssignUserModal = ({ teamId, onCompleted }) => {
  return (
    <StatefulModal
      trigger={
        <ButtonFilled
          bg="primary"
          color="white"
          borderRadius={'100px'}
          px={3}
          py={2}
          fontWeight="600"
          fontSize="14px"
        >
          <Flex alignItems="center">
            <AddIcon style={{ width: '24px' }} />
            <span>Assign User</span>
          </Flex>
        </ButtonFilled>
      }
    >
      {(closeModal, isOpen) => (
        <ModalContentContainer>
          <Text.Header py={3} px={4} fontSize="20px">
            Assign User
          </Text.Header>
          <Divider />
          <AssignUsersToTeam
            teamId={teamId}
            onCancel={closeModal}
            onCompleted={() => {
              onCompleted();
              setTimeout(closeModal, 1500);
            }}
          />
        </ModalContentContainer>
      )}
    </StatefulModal>
  );
};

AssignUserModal.propTypes = {
  teamId: PropTypes.string.isRequired,
  onCompleted: PropTypes.func,
};
AssignUserModal.defaultProps = {
  onCompleted: () => {},
};

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  USER_NOT_FOUND: {
    message: `User not found or has been unassigned already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot unassign team, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const UserList = React.forwardRef(({ teamId }, ref) => {
  const [feedback, setFeedback] = useState({});
  const SearchAssignedTeamUsersRef = useRef(null);
  useImperativeHandle(SearchAssignedTeamUsersRef, () => ref.current);

  const onCompleted = useCallback((response) => {
    const removedUserName = response?.unassignUserFromTeam?.username;
    setFeedback({
      message: `User${removedUserName ? ` "${removedUserName}" ` : ' '}has been removed`,
      variant: VARIANTS.SUCCESS,
      show: true,
    });
    const SearchAssignedTeamUsersTable = SearchAssignedTeamUsersRef.current;
    if (typeof SearchAssignedTeamUsersTable.refetch === 'function') {
      SearchAssignedTeamUsersTable.refetch();
    }
  }, []);

  const onError = useCallback((response) => {
    const message = response?.message ?? '';
    if (message.includes('404')) {
      setFeedback(FEEDBACK.USER_NOT_FOUND);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const [removeUser] = useMutation(UNASSIGN_TEAM, {
    onCompleted,
    onError,
  });

  const handleRemoveUser = useCallback(
    (userId, teamId) =>
      removeUser({
        variables: {
          userId,
          teamId,
        },
      }),
    [removeUser]
  );

  const COLUMN = useMemo(
    () => [
      ...DEFAULT_USER_COLUMNS,
      {
        Header: 'Action',
        Cell: function actionComponent(row) {
          return (
            <Flex justifyContent="center">
              <DeleteIconModal
                title={'Remove User?'}
                description={'This user will be removed from the team.'}
                onConfirmClick={() => handleRemoveUser(row.original.id, teamId)}
              />
            </Flex>
          );
        },
        width: 60,
        isSearchAble: false,
        sortable: false,
      },
    ],
    [handleRemoveUser, teamId]
  );

  return (
    <>
      <SearchTeamUser columns={COLUMN} teamId={teamId} ref={ref} />
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
});

UserList.propTypes = {
  teamId: PropTypes.string.isRequired,
};

const TeamUserPanel = ({ teamId }) => {
  const AssignedUserListRef = useRef(null);

  const handleAssignComplete = useCallback(() => {
    const AssignedUserListTable = AssignedUserListRef.current;
    if (AssignedUserListTable && typeof AssignedUserListTable.refetch === 'function') {
      AssignedUserListTable.refetch();
    }
  }, []);

  return (
    <Fragment>
      <Text>
        {`User(s) will be saved to the back office once you clicked “Assign User” in the process.`}
      </Text>
      <Flex justifyContent="space-between" alignItems="center" flexDirection={'row-reverse'} py={3}>
        <AssignUserModal teamId={teamId} onCompleted={handleAssignComplete} />
      </Flex>
      <UserList teamId={teamId} ref={AssignedUserListRef} />
    </Fragment>
  );
};

TeamUserPanel.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default TeamUserPanel;
