import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../../../Components/Modal/StatefulModal';

import CreateEditAnnouncementForm from './CreateEditAnnouncementForm';

export default class AddAnnouncementModal extends Component {
  static propTypes = {
    courseTitle: PropTypes.string,
    coverImageUrl: PropTypes.string,
    isEdit: PropTypes.bool,
    isView: PropTypes.bool,
    isOpen: PropTypes.bool,
    trigger: PropTypes.element,
    announcementId: PropTypes.string,
    variables: PropTypes.object,
  };
  static defaultProps = {
    courseTitle: '',
    isEdit: false,
    isView: false,
  };
  render() {
    const {
      variables,
      isEdit,
      isView,
      trigger,
      announcementId,
      courseTitle,
      coverImageUrl,
    } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>
              {isEdit
                ? 'Edit Course Announcement'
                : isView
                ? 'View Course Announcement'
                : 'Create New Course Announcement'}
            </Modal.Header>
            <Modal.Content>
              <CreateEditAnnouncementForm
                isEdit={isEdit}
                isView={isView}
                onComplete={closeModal}
                onCancelled={closeModal}
                courseTitle={courseTitle}
                coverImageUrl={coverImageUrl}
                searchVariables={variables}
                announcementId={announcementId}
              />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
