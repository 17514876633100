import React from 'react';

import { SKU_CATEGORY } from 'Routes/Products/Domains/constants';
import {
  createWorkshopCategoryMutation,
  updateWorkshopCategoryMutation,
} from 'Routes/Products/GraphQL/mutation/Products.mutation';
import {
  getWorkshopCategoryByPermalink,
  getWorkshopCategories,
  getWorkshopCategory,
} from 'Routes/Products/GraphQL/query/Products.query';

import CategoriesBase from './BaseComponent/CategoriesBase';
export const CategoriesWorkshopRoute = (props) => {
  return (
    <CategoriesBase
      {...props}
      skuCategory={SKU_CATEGORY.WORKSHOP}
      getCategoryByPermalinkQueryName="sfBoProductWorkshopCategoryByPermalink"
      getCategoryByPermalink={getWorkshopCategoryByPermalink}
      getCategoriesQueryName="sfBoProductWorkshopCategories"
      getCategories={getWorkshopCategories}
      getCategory={getWorkshopCategory}
      getCategoryQueryName="sfBoProductWorkshopCategory"
      createMutation={createWorkshopCategoryMutation}
      updateMutation={updateWorkshopCategoryMutation}
    />
  );
};
