import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, List } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import { BACKOFFICE_PRODUCT } from '../../GraphQL/query/AppConfig.query';
import FormGenerator from '../Form/FormGenerator';

const fields = () => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Title',
    inputName: 'title',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
    inputLabel: 'Description',
    inputName: 'description',
  },
];

const noop = () => {};

export default class SectionContent extends Component {
  static propTypes = {
    product: PropTypes.string,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    canPreview: PropTypes.bool,
    // Subsection Action
    onUpdateMeta: PropTypes.func,
  };

  static defaultProps = {
    product: BACKOFFICE_PRODUCT.SKOOLDIO,
    onUpdateMeta: noop,
  };

  render() {
    const { title, description, onUpdateMeta } = this.props;
    return (
      <Flex flexDirection="column">
        <Segment vertical>
          <FormGenerator
            fields={fields()}
            submitText="Save Section"
            onSubmit={onUpdateMeta}
            initialData={{ title, description }}
            name="section-content"
            compact
          />
        </Segment>
      </Flex>
    );
  }
}
