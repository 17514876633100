import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withFormsy from 'Components/Form/withFormsy';
import QueryComponent from 'GraphQL/util/QueryComponent';
import SelectorInput from 'Routes/Reports/Components/SelectorInput/SelectorInput';

import {
  getOnlineCourseCategoryChildrenByPermalink,
  getWorkshopCategoryChildrenByPermalink,
  getCollectionCategoryChildrenByPermalink,
  getSubscriptionCategoryChildrenByPermalink,
  getBookCategoryChildrenByPermalink,
} from '../../GraphQL/query/Products.query';
import { CATEGORY_TYPE, SKU_CATEGORY, SKU_TYPE } from '../../Domains/constants';

const parseValue = (data, queryName) => {
  const children = data[queryName].children ? data[queryName].children : [];
  return children.map((category) => ({
    key: category.id,
    value: category.id,
    text: category.title,
  }));
};

const CategorySelectorInputQuery = (props) => {
  const { categoryType, skuCategory, skuType } = props;
  let query = undefined;
  let queryName = '';
  switch (skuCategory) {
    case SKU_CATEGORY.ONLINE_COURSE: {
      query = getOnlineCourseCategoryChildrenByPermalink;
      queryName = 'sfBoProductOnlineCourseCategoryByPermalink';
      break;
    }
    case SKU_CATEGORY.SUBSCRIPTION: {
      query = getSubscriptionCategoryChildrenByPermalink;
      queryName = 'sfBoProductSubscriptionCategoryByPermalink';
      break;
    }
    case SKU_CATEGORY.WORKSHOP: {
      query = getWorkshopCategoryChildrenByPermalink;
      queryName = 'sfBoProductWorkshopCategoryByPermalink';
      break;
    }
    case SKU_CATEGORY.COLLECTION: {
      query = getCollectionCategoryChildrenByPermalink;
      queryName = 'sfBoProductCollectionCategoryByPermalink';
      break;
    }
    case SKU_CATEGORY.BOOK: {
      query = getBookCategoryChildrenByPermalink;
      queryName = 'sfBoProductBookCategoryByPermalink';
      break;
    }
    default:
  }
  return (
    <QueryComponent query={query} variables={{ permalink: categoryType }}>
      {(data) => {
        return <SelectorInput options={parseValue(data, queryName)} {...props} />;
      }}
    </QueryComponent>
  );
};

CategorySelectorInputQuery.propTypes = {
  categoryType: PropTypes.oneOf(Object.values[CATEGORY_TYPE]).isRequired,
  skuCategory: PropTypes.oneOf(Object.values[SKU_CATEGORY]).isRequired,
  skuType: PropTypes.oneOf(Object.values[SKU_TYPE]),
};

export default CategorySelectorInputQuery;
export const FormsyCategorySelectorInput = withFormsy(CategorySelectorInputQuery);
