import gql from 'graphql-tag';

export const CERTIFICATE_TYPE = {
  LINE_BASIC: 'line_basic',
  LINE_ADVANCED: 'line_advanced',
  LINE_DEFAULT: 'line_default',
  SKOOLDIO_ONLINE: 'skooldio_online',
  TAT_ONLINE: 'tat_online',
  MANUAL: 'manual',
};

export const CERTIFICATE_ITEM_OBJECT_TYPE = {
  CERTIFICATE: 'CERTIFICATE',
  EXAM: 'EXAM',
  COURSE_V1: 'COURSE_V1',
  COURSE: 'COURSE',
};

export const CERTIFICATE_ITEM_TYPE = {
  CONDITION: 'CONDITION',
};

export const CertificateFragment = gql`
  fragment CertificateFragment on Certificate {
    id
    name
    code
    type
    issuerName
    issuerSignature
    active
    validTimePeriodInDays
    examTimeLimitInDays
    template {
      key
      bucket
    }
    templateUrl

    metadata {
      shouldHideCourse
      shouldGenerateQRCode
    }
  }
`;

export const CertificateItemFragment = gql`
  fragment CertificateItemFragment on CertificateItem {
    id
    certificateId
    displayName
    objectType
    objectCode
    type
  }
`;

export const getCertificates = gql`
  query certificates($search: CertificateSearchInput, $paging: PageInput, $order: OrderInput) {
    certificates(search: $search, paging: $paging, order: $order) {
      total
      certificates {
        id
        ...CertificateFragment
      }
    }
  }
  ${CertificateFragment}
`;

export const getCertificate = gql`
  query certificate($id: ID!) {
    certificate(id: $id) {
      id
      ...CertificateFragment
    }
  }
  ${CertificateFragment}
`;

export const getCertificateItems = gql`
  query certificateItems(
    $search: CertificateItemSearchInput!
    $paging: PageInput
    $order: OrderInput
  ) {
    certificateItems(search: $search, paging: $paging, order: $order) {
      total
      certificateItems {
        id
        ...CertificateItemFragment
      }
    }
  }
  ${CertificateItemFragment}
`;

export const getCertificateItem = gql`
  query certificateItem($id: ID!) {
    certificateItem(id: $id) {
      id
      ...CertificateItemFragment
    }
  }
  ${CertificateItemFragment}
`;
