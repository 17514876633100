import React from 'react';

import QueryComponent from 'GraphQL/util/QueryComponent';
import { getWorkshopBySKUCode } from 'GraphQL/query/Workshop.query';

import WorkshopDetails from './WorkshopDetailsRoute';

const WorkshopDetailsRoute = (props) => {
  const { match = {} } = props;
  const { params = {} } = match;
  const { SKUCode } = params;
  return (
    <QueryComponent
      query={getWorkshopBySKUCode}
      variables={{
        SKUCode,
      }}
      loadingOverlay
    >
      {(data) => {
        return <WorkshopDetails {...props} workshop={data && data.sfWorkshopBySKUCode} />;
      }}
    </QueryComponent>
  );
};

export default WorkshopDetailsRoute;
