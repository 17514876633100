import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Text, ButtonFilled } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';

const UnpublishCourseWarning = ({ goToCourse }) => {
  return (
    <Flex flexDirection="column" alignItems="center" pt={4}>
      <Text fontSize={3} mb={3}>
        Cannot access the assignment report, Please publish the course first
      </Text>
      <Button primary onClick={goToCourse}>
        Go to course
      </Button>
    </Flex>
  );
};

UnpublishCourseWarning.propTypes = { goToCourse: PropTypes.func };
UnpublishCourseWarning.defaultProps = { goToCourse: () => {} };

export default UnpublishCourseWarning;
