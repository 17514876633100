import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from '../../../Components/Modal/StatefulModal';

import NewUserContainer from './NewUser.gql';

export default class NewUserModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    userId: PropTypes.string,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { isEdit, userId, trigger } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'Edit User Information' : 'Create New User'}</Modal.Header>
            <Modal.Content>
              <NewUserContainer
                isEdit={isEdit}
                userId={userId}
                onComplete={closeModal}
                onCancelled={closeModal}
                {...this.props}
              />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
