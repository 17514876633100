import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useMutation } from '@apollo/client';

import { Box, Flex, ButtonFilled, ButtonText, Text } from 'Components/Base';
import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { useSearchFilter } from '../../Utils';
import Snackbar, { VARIANTS } from '../../Components/Snackbar';
import { GET_ROLE_UNASSIGNED_USERS } from '../../Graphql/query/Role.query';
import { ASSIGN_APP_ROLE_TO_USERS } from '../../Graphql/mutation/Role.mutation';

const addOrRemove = (array, item) => {
  const data = [...array];
  const index = _.findIndex(data, (datum) => datum === item.id);

  if (index === -1) {
    data.push(item.id);
  } else {
    data.splice(index, 1);
  }
  return data;
};

const formatUserColumns = (handleCheckbox, selectedUsers) => [
  {
    Header: '',
    accessor: 'checkbox',
    width: 50,
    isSearchAble: false,
    sortable: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <Checkbox
          checked={!!selectedUsers.find((item) => item === id)}
          onChange={() => handleCheckbox(original)}
        />
      );
    },
  },
  {
    Header: 'User ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Firstname',
    accessor: 'firstname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Lastname',
    accessor: 'lastname',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
];

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  PERMISSION_LIMITATION: {
    message: 'Assigning users with your role or higher is prohibited',
    variant: VARIANTS.ERROR,
    show: true,
  },
  USER_ALREADY_EXIST: {
    message: `Some of user has been assigned already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot assign role, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const AssignUsersToRole = React.forwardRef(
  ({ appRoleId, resolveData, onCancel, onCompleted: onCompletedProps }, ref) => {
    const {
      onSearchChange,
      onPageChange,
      onSortedChange,
      onPageSizeChange,
      search,
      paging,
      order,
    } = useSearchFilter();
    const getUsersData = useCallback(resolveData, [resolveData]);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [feedback, setFeedback] = useState({});

    const onError = useCallback((response) => {
      const message = response?.message ?? '';
      if (message.includes('403')) {
        setFeedback(FEEDBACK.PERMISSION_LIMITATION);
        return;
      }

      if (message.includes('401')) {
        setFeedback(FEEDBACK.PROHIBITED);
        return;
      }

      if (message.includes('400')) {
        setFeedback(FEEDBACK.USER_ALREADY_EXIST);
        return;
      }

      setFeedback(FEEDBACK.UNEXPECTED_ERROR);
    }, []);

    const onCompleted = useCallback(
      (response) => {
        setFeedback({
          message: `Successfully assigned ${response.assignUsersToAppRole.length} user(s) to the role!`,
          variant: VARIANTS.SUCCESS,
          show: true,
        });
        setSelectedUsers([]);
        if (typeof onCompletedProps === 'function') onCompletedProps();
      },
      [onCompletedProps]
    );

    const [assignRoleToUsers] = useMutation(ASSIGN_APP_ROLE_TO_USERS, {
      refetchQueries: [
        { query: GET_ROLE_UNASSIGNED_USERS, variables: { appRoleId, order, search, paging } },
      ],
      onCompleted,
      onError,
    });

    const handleCheckbox = (item) => {
      setSelectedUsers(addOrRemove(selectedUsers, item));
    };

    const handleAddUsers = () => {
      assignRoleToUsers({
        variables: {
          userIds: selectedUsers,
          roleId: appRoleId,
        },
      });
    };

    const getSearchVariables = () => ({
      appRoleId,
      order,
      search,
      paging,
    });

    return (
      <>
        <Snackbar
          message={feedback.message}
          isOpen={feedback.show}
          onClose={() => setFeedback({ ...feedback, show: false })}
          variant={feedback.variant}
          vertical={'top'}
          horizontal={'center'}
          duration={3000}
        />
        <Box pt={4} pb={3} px={4} mx={'auto'} width={'100%'}>
          <QueryTable
            ignoreSearchParams
            ref={ref}
            type={'unassignedUsersByAppRole'}
            columns={formatUserColumns(handleCheckbox, selectedUsers)}
            query={GET_ROLE_UNASSIGNED_USERS}
            resolveData={getUsersData}
            onSearchChange={onSearchChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortedChange={onSortedChange}
            getSearchVariables={getSearchVariables}
            paging={paging}
          />
          <Flex justifyContent={'space-between'} alignItems={'center'} mt={3}>
            {`${selectedUsers.length} user(s) selected`}
            <Text>{`User(s) will be saved to the back office once you clicked “Assign User”`}</Text>
          </Flex>
        </Box>
        <Flex
          height={'80px'}
          width={1}
          px={4}
          bg={'#F2F2F2'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <ButtonText
            fontSize="16px"
            color="#787878"
            onClick={() => setSelectedUsers([])}
            style={{ textDecoration: 'none', padding: 0 }}
          >
            <Flex alignItems={'center'} justifyContent={'center'} height={'12px'}>
              <RefreshIcon style={{ marginRight: '4px' }} /> Clear Selection
            </Flex>
          </ButtonText>
          <Box>
            <ButtonText color="primary" px={3} py={2} fontSize="16px" onClick={onCancel}>
              Cancel
            </ButtonText>
            <ButtonFilled
              disabled={selectedUsers.length < 1}
              bg="primary"
              color="white"
              borderRadius={'100px'}
              px={3}
              py={2}
              fontSize="16px"
              onClick={handleAddUsers}
              style={{ border: 0 }}
            >
              Assign User
            </ButtonFilled>
          </Box>
        </Flex>
      </>
    );
  }
);

AssignUsersToRole.propTypes = {
  resolveData: PropTypes.func,
  onCancel: PropTypes.func,
  onCompleted: PropTypes.func,
  appRoleId: PropTypes.string,
};

AssignUsersToRole.defaultProps = {
  resolveData: (data) => data.unassignedUsersByAppRole.users,
  onCancel: () => {},
  onCompleted: () => {},
};

export default AssignUsersToRole;
