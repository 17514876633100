import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const MuiDatePicker = ({ format, value, onChange, disabled, ...props }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      format={format}
      margin="normal"
      id="date-picker-inline"
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...props}
    />
  </MuiPickersUtilsProvider>
);

MuiDatePicker.defaultProps = {
  format: 'DD/MM/YYYY',
  onChange: () => {},
};

export default MuiDatePicker;
