import React from 'react';
import PropTypes from 'prop-types';

import CreateEditForm from 'Containers/CreateEditForm';

import { GET_APP_ROLE } from '../Graphql/query/Role.query';
import { UPDATE_APP_ROLE, CREATE_APP_ROLE } from '../Graphql/mutation/Role.mutation';
import RoleDetailsForm from '../Components/RoleDetailsForm';

const formatDataToForm = (data) => ({
  name: data?.appRole?.name ?? '',
  description: data?.appRole?.description ?? '',
});

const formatToEdit = (form, { id } = {}) => {
  return { id: id, data: { name: form?.name, description: form?.description } };
};

const formatToCreate = (form, { id } = {}) => {
  return { data: { name: form?.name, description: form?.description } };
};

const RoleDetails = ({ roleId, isEdit, ...props }) => (
  <CreateEditForm
    dataQuery={GET_APP_ROLE}
    editMutation={UPDATE_APP_ROLE}
    getEditVariables={formatToEdit}
    createMutation={CREATE_APP_ROLE}
    getCreateVariables={formatToCreate}
    isEdit={isEdit}
    id={roleId}
    idVariable={'id'}
    fetchPolicy={'cache-first'}
    {...props}
  >
    {({ data, onSubmit }) => (
      <RoleDetailsForm initialData={formatDataToForm(data)} isEdit={isEdit} onSubmit={onSubmit} />
    )}
  </CreateEditForm>
);

RoleDetails.propTypes = {
  roleId: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
};

RoleDetails.defaultProps = {
  isEdit: true,
};

export default RoleDetails;
