import React, { Component } from 'react';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getSubscription } from '../../GraphQL/query/Products.query';
import { updateSubscriptionRedemptionMutation } from '../../GraphQL/mutation/Products.mutation';

import SubscriptionRedemptionForm from './SubscriptionRedemptionForm';

const resolveData = ({ sfBoProductSubscription = {} }) => {
  return sfBoProductSubscription.redemption ?? {};
};

class SubscriptionRedemptionEditor extends Component {
  render() {
    const { subscription = {} } = this.props;
    const { id, SKUCode } = subscription;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getSubscription}
          editMutation={updateSubscriptionRedemptionMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={({ creditAmount }) => {
            return {
              id,
              data: {
                creditAmount,
                enableCredit: !_.isNil(creditAmount),
              },
            };
          }}
        >
          {({ data, onSubmit }) => {
            return (
              <SubscriptionRedemptionForm initialData={resolveData(data)} onSubmit={onSubmit} />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default SubscriptionRedemptionEditor;
