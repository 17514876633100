import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import ConfirmationButton from 'Components/ConfirmationButton';
import { duplicateExamItem } from 'GraphQL/mutation/Exam.mutation';
import { getExamById } from 'GraphQL/query/Exam.query';

const DuplicateQuestionButton = ({ onCompleted, examId, examItemId }) => {
  const [duplicateQuestion, { loading }] = useMutation(duplicateExamItem, {
    variables: { examId, examItemId },
    onCompleted: onCompleted,
    refetchQueries: [
      {
        query: getExamById,
        variables: { examId },
      },
    ],
  });

  return (
    <ConfirmationButton
      buttonStyle={{
        basic: true,
        size: 'medium',
        color: 'primary',
        disabled: loading,
      }}
      onConfirm={duplicateQuestion}
      headerText={`Confirm Duplicate`}
      confirmText="Confirm"
      contentText={'Do you want to duplicate this question?'}
    >
      {`Duplicate`}
    </ConfirmationButton>
  );
};

DuplicateQuestionButton.propTypes = {
  onCompleted: PropTypes.func,
  examId: PropTypes.string,
  examItemId: PropTypes.string,
};

DuplicateQuestionButton.defaultProps = {
  onCompleted: () => {},
};

export default DuplicateQuestionButton;
