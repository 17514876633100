import gql from 'graphql-tag';

export const searchSubjects = gql`
  query boSyllabusSubjects($search: BOSearchSyllabusSubjectsInput!) {
    boSyllabusSubjects(search: $search) {
      subjects {
        id
        shortName
        name
        code
      }
    }
  }
`;
