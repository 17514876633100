import React from 'react';
import PropTypes from 'prop-types';

import { getCollectionOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateCollectionOnlineCourseHighlightsMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUBaseHighlightEditor from '../SKUBaseHighlightEditor';

const OnlineCourseHighlightEditor = ({ onlineCourse = {} }) => {
  return (
    <SKUBaseHighlightEditor
      dataQuery={getCollectionOnlineCourse}
      dataQueryName={'sfBoProductCollectionOnlineCourse'}
      variableQuery={{ SKUCode: onlineCourse.SKUCode }}
      handleMutation={updateCollectionOnlineCourseHighlightsMutation}
      getHandleVariables={(data) => ({
        id: onlineCourse.id,
        highlights: data,
      })}
      refetchQueries={[
        { query: getCollectionOnlineCourse, variables: { SKUCode: onlineCourse.SKUCode } },
      ]}
    />
  );
};

OnlineCourseHighlightEditor.propTypes = {
  onlineCourse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default OnlineCourseHighlightEditor;
