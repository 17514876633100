import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { isEmpty } from 'lodash';
import isUrl from 'is-url';

import FormGenerator from 'Components/Form/FormGenerator';
import TagInput from 'Components/TagInput';
import getConfig from 'Util/Config';

import DateRangeInput from '../DateRangeInput';
import TimeRangeInput from '../TimeRangeInput';
import ColorSelectorInput from '../../../../Components/ColorSelectorInput';
import { SKU_STATUS_OPTIONS, SKU_VISIBILITY, SKU_STATUS } from '../../Domains/constants';
import { validateSKUCode, checkReadyToPublish, ERP_PRODUCT } from '../../Domains/ERPUtils';
import { default as VideoPlayerPreviewInput } from '../VideoPlayerPreviewInput';
import SKUCodeInput from '../SKUCodeInput';
import ERPWarningModal from '../ERPWarningModal';

import ComingSoonTextInput from './Components/ComingSoonTextInput';
import { PrescreenFormURLTextInput } from './Components';

const { products, ERPSystem } = getConfig();

const HEX_COLOR_VALIDATION = /^#([a-f0-9]{3,4}|[a-f0-9]{4}(?:[a-f0-9]{2}){1,2})\b$/i;

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const isComingSoon = (status) => {
  const visibleComingSoon = `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.COMING_SOON}`;
  const unlistedComingSoon = `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.COMING_SOON}`;

  return status === visibleComingSoon || status === unlistedComingSoon;
};

const getFields = (isEdit, theme, publishedAt, prevComingSoontext, currentStatus) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Sub Title',
      inputName: 'subTitle',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputName: 'SKUCode',
      inputProps: {
        disabled: publishedAt,
      },
      customInput: (props) => (
        <SKUCodeInput publishedAt={publishedAt} status={currentStatus} {...props} />
      ),
      mode: MODE.BOTH,
    },

    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'SKUStatus',
      inputName: 'SKUStatus',
      options: products?.ONLINE_COURSE?.skuStatusOptions ?? SKU_STATUS_OPTIONS,
      inputProps: {
        required: true,
        search: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputName: 'prescreenFormURL',
      customInput: () => <PrescreenFormURLTextInput />,
      mode: MODE.BOTH,
      hidden: !(
        products.ONLINE_COURSE?.enabledPrescreenFormURLText &&
        currentStatus === `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.SELLING}`
      ),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputName: 'comingSoonText',
      customInput: () => {
        let _display = undefined;
        let _launchDate = undefined;

        if (prevComingSoontext !== null && prevComingSoontext !== undefined) {
          const { display, launchDate } = prevComingSoontext;
          _display = display;
          _launchDate = launchDate;
        }
        return <ComingSoonTextInput display={_display} launchDate={_launchDate} />;
      },
      inputProps: {
        prevValue: prevComingSoontext,
      },
      mode: MODE.BOTH,
      hidden: !products.ONLINE_COURSE?.enabledComingSoonText || !isComingSoon(currentStatus),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'SEO Description',
      inputName: 'description',
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'BackgroundColor',
      inputName: 'backgroundColor',
      customInput: () => <ColorSelectorInput />,
      inputProps: {
        defaultColor: `${theme.colors.primary}`,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'YouTube Video ID',
      inputName: 'promotionYoutubeId',
      customInput: () => <VideoPlayerPreviewInput />,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Online Course Date',
      inputName: 'eventDateRange',
      customInput: ({ name }) => <DateRangeInput name={name} />,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Online Course Time',
      inputName: 'eventTimeRange',
      customInput: ({ name }) => <TimeRangeInput name={name} />,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Ordering',
      inputName: 'itemNo',
      inputProps: {
        required: products?.disabledOrdering ? false : true,
      },
      hidden: products?.disabledOrdering || false,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Duration',
      inputName: 'duration',
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Tags',
      inputName: 'tags',
      customInput: (props) => <TagInput placeholderText={'Type tag and press enter'} {...props} />,
      mode: MODE.BOTH,
    },
  ];

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class OnlineCourseOverviewForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  state = {
    currentStatus: this.props.initialData?.SKUStatus,
    currentSKUCode: this.props.initialData?.SKUCode,
    openERPModal: false,
    ERPError: [],
  };

  validate = (
    { backgroundColor, comingSoonText, SKUStatus, SKUCode, prescreenFormURL },
    isChanged
  ) => {
    const error = {};
    const { SKUStatus: prevSKUStatus, salePriceInclVAT } = this.props.initialData;
    if (backgroundColor && !HEX_COLOR_VALIDATION.test(backgroundColor) && isChanged) {
      error.backgroundColor = 'Background color must be in Hex-Color format';
    }

    if (!comingSoonText?.launchDate && comingSoonText?.display && isChanged) {
      error.comingSoonText = 'กรุณาเลือกเดือนที่เปิดให้สมัคร';
    }

    if (prescreenFormURL && !isUrl(prescreenFormURL) && isChanged) {
      error.prescreenFormURL = 'กรุณาใส่ลิงค์ฟอร์มให้ถูกต้อง';
    }

    if (ERPSystem.enabled) {
      const _prevSKUSTatus = prevSKUStatus.split('/')[1];
      const _skuStatus = SKUStatus.split('/')[1];
      if (
        (_prevSKUSTatus !== SKU_STATUS.SELLING) & (_skuStatus === SKU_STATUS.SELLING) &&
        isChanged
      ) {
        const isValid = validateSKUCode(SKUCode, ERPSystem.SKUCodeValidation);
        const erpCheckResult = checkReadyToPublish(
          isValid,
          salePriceInclVAT?.value,
          ERP_PRODUCT.ONLINE_COURSE
        );

        this.setState({ ERPError: erpCheckResult.error });
      } else {
        this.setState({ ERPError: [] });
      }
    }
    return error;
  };

  handleOpenERPModal = (isOpen) => {
    this.setState({ openERPModal: isOpen });
  };

  handleSubmit = (formData) => {
    if (ERPSystem.enabled && !isEmpty(this.state.ERPError)) {
      this.handleOpenERPModal(true);
      return;
    }

    this.props.onSubmit(formData);
  };

  handleChange = (value, isChanged) => {
    if (isChanged) this.setState({ currentStatus: value.SKUStatus, currentSKUCode: value.SKUCode });
  };

  render() {
    const { isEdit, theme, initialData } = this.props;
    const overideProps = { ...this.props, onSubmit: this.handleSubmit };
    const { currentStatus, currentSKUCode, openERPModal, ERPError } = this.state;
    const publishedAt = this.props.initialData?.publishedAt;
    const prevComingSoontext = initialData?.comingSoonText;
    const { salePriceInclVAT } = initialData;
    return (
      <>
        <ERPWarningModal
          open={openERPModal}
          handleOpen={this.handleOpenERPModal}
          skuCode={currentSKUCode}
          salePriceInclVAT={salePriceInclVAT?.value}
          error={ERPError}
          erpProduct={ERP_PRODUCT.ONLINE_COURSE}
        />
        <FormGenerator
          fields={getFields(isEdit, theme, publishedAt, prevComingSoontext, currentStatus)}
          onChange={this.handleChange}
          customValidation={this.validate}
          onSubmit={this.handleSubmit}
          submitText={isEdit ? 'Update' : 'Add'}
          cancelText="Cancel"
          showCancel
          initialData={initialData}
          {...overideProps}
        />
      </>
    );
  }
}

export default withTheme(OnlineCourseOverviewForm);
