import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';

import { Box } from 'Components/Base';
import Table from 'Components/DataTable';
import TYPE from 'Components/Search/SearchFormGenerator/constantType';

import QueryComponent from '../QueryComponent';
import { resolveRawData } from '../QueryTable/utils';
import { mapFormatterTable } from '../QueryTable/dataUtil';

const columnTypeShape = PropTypes.shape({
  Header: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPE)).isRequired,
  enumOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
});

/**
 * If you want auto generate table like QueryTable, but more simpler.
 * Feature that will not support
 * - Pagination
 * - Search (Server-Side)
 */
const SimpleQueryTable = (props) => {
  const {
    query,
    columns,
    type,
    subComponent: SubComponent,
    getResultData,
    resultMapper,
    getSearchVariables,
    tableProps,
    loading,
  } = props;
  const customProps = {
    columns: columns.map(mapFormatterTable),
    SubComponent,
    className: 'table-center',
    showPageSizeOptions: false,
    multiSort: false,
    minRows: 5,
    showPagination: false,
    loading,
    ...tableProps,
  };
  return (
    <Box mt={2}>
      <QueryComponent query={query} variables={getSearchVariables()} fetchPolicy="network-only">
        {(rawData) => {
          const data = resolveRawData(type, rawData, getResultData, resultMapper);
          return <Table data={data} alignItems="baseline" {...customProps} />;
        }}
      </QueryComponent>
    </Box>
  );
};

SimpleQueryTable.propTypes = {
  type: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(columnTypeShape).isRequired,
  subComponent: PropTypes.func,
  status: PropTypes.string,
  getSearchVariables: PropTypes.func,
  tableProps: PropTypes.object,
  getResultData: PropTypes.func,
  resultMapper: PropTypes.func,
};

SimpleQueryTable.defaultProps = {
  getSearchVariables: () => {},
  tableProps: {},
};

export default withApollo(SimpleQueryTable);
