import gql from 'graphql-tag';

export const fileImport = gql`
  mutation boCreateFileImportJob($input: BOCreateFileImportInput!) {
    boCreateFileImportJob(input: $input) {
      id
      type
      file {
        key
        bucket
      }
      originalFilename
      arg1
    }
  }
`;

export const deleteJob = gql`
  mutation deleteJob($jobId: ID!) {
    deleteJob(jobId: $jobId)
  }
`;

export const requestAssetUrl = gql`
  mutation asRequestAssetUrl($type: AS_ASSET_TYPE!, $bucket: String!, $key: String!) {
    asRequestAssetUrl(type: $type, bucket: $bucket, key: $key) {
      code
      success
      message
      url {
        url
      }
    }
  }
`;
