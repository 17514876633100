export const FILE_TYPE = {
  CREATE_INDIVIDUAL_REPORT: 'CREATE_INDIVIDUAL_REPORT',
};

export const FILE_IMPORT_STATUS = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
};
