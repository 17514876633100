import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';

import { getBooks, getBook } from '../../GraphQL/query/Products.query';
import { createBookMutation, updateBookMutation } from '../../GraphQL/mutation/Products.mutation';

import BookForm from './BookForm';

export default class BookModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
  };

  static defaultProps = {
    isEdit: false,
  };

  render() {
    const { isEdit, id, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'Edit Book' : 'Create Book'}</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getBook}
                createMutation={createBookMutation}
                createRefetchQueries={[{ query: getBooks, variables }]}
                editMutation={updateBookMutation}
                isEdit={isEdit}
                id={id}
                getQueryVariables={({ id }) => ({ id })}
                getEditVariables={(form, { id }) => ({ id, data: { ...form } })}
                getCreateVariables={(form) => ({ data: { ...form } })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => (
                  <BookForm initialData={data && data.sfBoProductBook} onSubmit={onSubmit} />
                )}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
