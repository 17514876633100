import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import omitDeep from 'Util/omitDeep';
import { getWorkshopFAQ } from 'GraphQL/query/Workshop.query.js';
import { sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation.js';
import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import delve from 'Util/Delve';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';

import FAQView from '../WorkshopContent';

export default function WorkshopFAQ({ skuCode }) {
  const { loading, error, data: queryData } = useQuery(getWorkshopFAQ, {
    variables: { SKUCode: skuCode },
    fetchPolicy: 'network-only',
  });

  const [
    updateWorkshop,
    { data: mutationResponse, loading: mutationLoading, error: mutationError },
  ] = useMutation(sfUpdateWorkshop);

  const handleUpdateContents = (data) => {
    const clonedData = JSON.parse(JSON.stringify(data));
    const formatData = omitDeep(clonedData, ['id', '__typename']);
    updateWorkshop({
      variables: {
        SKUCode: skuCode,
        data: {
          FAQ: formatData,
        },
      },
    });
  };

  if (loading) return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  const contents = delve(queryData, 'sfWorkshopBySKUCode.FAQ', []);

  return (
    <Fragment>
      {error && <ErrorView message={error} />}
      {mutationError && <ErrorView message={mutationError} />}
      <CheckMutationResponse
        checkOnlyDefined={false}
        response={delve(mutationResponse, 'sfUpdateWorkshop')}
        loading={mutationLoading}
        error={mutationError}
        notificationTime={2000}
      />
      <FAQView
        loading={mutationLoading}
        contents={contents}
        accessor={{ title: 'question', description: 'answer' }}
        onUpdateContents={handleUpdateContents}
        addSectionText={'Add FAQ'}
      />
    </Fragment>
  );
}
