import gql from 'graphql-tag';

import { VideoFragment, ByteArkStreamVideoFragment } from '../common/fragment';

export const SnapshotFragment = gql`
  fragment SnapshotFragment on VDMSnapshot {
    snapshotId
    imageUrl
  }
`;

export const ByteArkStreamSnapshotFragment = gql`
  fragment ByteArkStreamSnapshotFragment on VDMByteArkStreamVideoSnapshot {
    snapshotId
    imageUrl
  }
`;

export const getAllVideos = gql`
  query getAllVideos($filters: VideoFiltersInput!) {
    vdmVideos(filters: $filters) {
      videos {
        ...VideoFragment
      }
      total
      paging {
        current
        last
      }
    }
  }
  ${VideoFragment}
`;

export const byteArkStreamGetAllVideos = gql`
  query getAllVideos($projectKey: ID!, $filters: VDMByteArkStreamVideoFiltersInput!) {
    vdmByteArkVideos(projectKey: $projectKey, filters: $filters) {
      videos {
        ...ByteArkStreamVideoFragment
      }
      total
      paging {
        current
        last
      }
    }
  }
  ${ByteArkStreamVideoFragment}
`;

export const getSingleVideo = gql`
  query getSingleVideo($videoKey: ID!) {
    vdmVideo(videoKey: $videoKey) {
      ...VideoFragment
    }
    vdmSnapshots(videoKey: $videoKey) {
      ...SnapshotFragment
    }
  }
  ${VideoFragment}
  ${SnapshotFragment}
`;

export const byteArkStreamGetSingleVideo = gql`
  query getByteArkStreamSingleVideo($videoKey: ID!) {
    vdmByteArkVideo(videoKey: $videoKey) {
      ...ByteArkStreamVideoFragment
    }
    vdmByteArkSnapshots(videoKey: $videoKey) {
      ...ByteArkStreamSnapshotFragment
    }
  }
  ${ByteArkStreamVideoFragment}
  ${ByteArkStreamSnapshotFragment}
`;

export const getMindstampVideos = gql`
  query mindstampVideos($token: String) {
    mindstampVideos(token: $token) {
      id
      token
      title
      duration
      thumbnail
      embed_url
    }
  }
`;
