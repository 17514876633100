const genPaging = (page = 0, pageSize = 10) => {
  return { currentPage: page, pageSize: pageSize };
};

const genOrder = (sorted) => {
  if (sorted.length > 0) {
    const field = sorted[0].id;
    const type = sorted[0].desc ? 'DESC' : 'ASC';
    return { field, type };
  }
  return;
};

const handleSearchChange = (search, paging, setSearch, setPaging) => {
  setSearch(search);
  setPaging({ currentPage: 0, pageSize: 10 }, paging.pageSize);
};

const handlePageChange = (page, pageSize, setPaging) => {
  const paging = genPaging(page, pageSize);
  setPaging(paging);
};

const handlePageSizeChange = (pageSize, setPaging) => {
  const paging = genPaging(0, pageSize);
  setPaging(paging);
};

const handleSortedChange = (sorted, pageSize, setOrder, setPaging) => {
  const order = genOrder(sorted);
  const paging = genPaging(0, pageSize);
  setOrder(order);
  setPaging(paging);
};

const Filter = {
  genPaging,
  genOrder,
  handlePageChange,
  handlePageSizeChange,
  handleSearchChange,
  handleSortedChange,
};

export default Filter;
