import gql from 'graphql-tag';

export const getAllSchools = gql`
  query boAllSchools {
    boAllSchools {
      id
      name
      code
    }
  }
`;
