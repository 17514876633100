import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Dimmer as BaseDimmer } from 'semantic-ui-react';
import styled from 'styled-components';

import { Box, AspectRatio, BackgroundImage } from 'Components/Base';
import FileUploadManagerModal, { UPLOAD_MODE } from 'Components/Lib/UploadComponent';

const Dimmer = styled(BaseDimmer)`
  && {
    z-index: ${({ theme }) => theme.zIndices.imageUploadOverlay};
  }
`;

const BannerImageUpload = ({
  name = 'Banner',
  fileImageUrl,
  onFileListRequest,
  onFileUploadUrlRequest,
  onImageUpdate,
  uploadMode,
}) => {
  const [isHover, setIsHover] = useState(false);
  const handleSelectImage = (files = []) => {
    if (Array.isArray(files) && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      const { key, bucket, url } = files[0];
      onImageUpdate({ key, bucket, url });
      setIsHover(false);
    }
  };
  return (
    <Box
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      style={{ cursor: 'pointer' }}
    >
      <FileUploadManagerModal
        keyGroup={`${name}Image`}
        dimmer="inverted"
        uploadUrl={'http://localhost:5000'}
        listUrl={'http://localhost:5000'}
        allowedContentTypes={['image/png', 'image/jpeg', 'image/gif']}
        defaultText={'Support JPG, PNG or GIF file with less than 5MB'}
        maxFileSize={5}
        maxSelection={1}
        customFileListUrlRequest={onFileListRequest}
        customUploadUrlRequest={onFileUploadUrlRequest}
        mode={uploadMode}
        onFileManagerDialogClose={handleSelectImage}
        trigger={
          //Put it here to allow user to click any where in picture to change image
          <Dimmer.Dimmable dimmed={isHover || !fileImageUrl}>
            <AspectRatio ratio={16 / 9}>
              <BackgroundImage imageUrl={fileImageUrl} bgPosition={'center'} bgSize={'contain'} />
              <Dimmer active={isHover || !fileImageUrl}>
                <Button type="button" primary>
                  <Icon name="image outline" />
                  {fileImageUrl ? `Change ${name} image` : `Add ${name} image`}
                </Button>
              </Dimmer>
            </AspectRatio>
          </Dimmer.Dimmable>
        }
      />
    </Box>
  );
};

BannerImageUpload.propTypes = {
  name: PropTypes.string,
  fileImageUrl: PropTypes.string,
  onFileListRequest: PropTypes.func,
  onFileUploadUrlRequest: PropTypes.func,
  onImageUpdate: PropTypes.func,
  uploadMode: PropTypes.oneOf(Object.values(UPLOAD_MODE)).isRequired,
};

BannerImageUpload.defaultProps = {
  onFileListRequest: () => {},
  onFileUploadUrlRequest: () => {},
  onImageUpdate: () => {},
};

export default BannerImageUpload;
