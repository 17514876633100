import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';

import FormGenerator from 'Components/Form/FormGenerator';
import { DatePickerInput } from 'Components/DatePickerInput';
import getConfig from 'Util/Config';

import { FormsyProductSelectorInput } from '../ProductSelectorInput';
import {
  EXPIRY_CONDITION_OPTIONS,
  EXPIRY_CONDITION,
  CUSTOMER_TYPE_OPTIONS,
  MINIMUM_QUANTITY_OPTIONS,
  CUSTOMER_TYPE,
  ProductTypesCheckBoxs,
} from '../../Domains/constants';
import { calcRemainDiscountCode } from '../../Domains/utils';

import ProductTypesCheckbox from './ProductTypesCheckbox';

const { discountCode: discountConfig } = getConfig();

const defaultFields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Promo Code (ห้ามซ้ำ)',
    inputName: 'code',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'มูลค่า',
    inputName: 'discount',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'หน่วย',
    inputName: 'discountUnit',
    options: [
      { key: 'FIX', value: 'FIX', text: 'FIX' },
      { key: 'PERCENT', value: 'PERCENT', text: 'PERCENT' },
    ],
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'ใช้ได้กับสินค้าทั้งหมดใน...',
    inputName: 'productTypes',
    inputProps: {
      productTypes: ProductTypesCheckBoxs,
    },
    customInput: (props) => <ProductTypesCheckbox {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'สินค้าที่ใช้โค้ดได้',
    inputName: 'SKUIds',
    customInput: (props) => <FormsyProductSelectorInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'สินค้าที่ใช้โค้ดไม่ได้ (กรณีโค้ดส่วนลดใช้ได้กับทุกคอร์ส)',
    inputName: 'exceptSKUIds',
    customInput: (props) => <FormsyProductSelectorInput {...props} />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'วันที่ใช้โค้ดได้',
    inputName: 'startDate',
    customInput: () => <DatePickerInput />,
  },
  {
    inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    inputLabel: 'วันสุดท้ายที่ใช้โค้ดได้',
    inputName: 'endDate',
    customInput: () => <DatePickerInput isEndDate={true} />,
  },
];

const getLowerFields = ({ customerType, isEdit, total, oldTotal, oldRemain }) => {
  const lowerFields = [
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'สำหรับลูกค้า (บุคคล หรือ องค์กร)',
      inputName: 'customerType',
      options: CUSTOMER_TYPE_OPTIONS,
      inputProps: {
        defaultValue: customerType ?? CUSTOMER_TYPE.ALL,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'เลขอ้างอิงในการออกส่วนลด (สำหรับ B2B) ถ้าไม่มีให้ใส่ "-"',
      inputName: 'refCode',
      inputProps: {
        placeholder: 'e.g. Quotation No., Reference Code, etc.',
        required: discountConfig?.enabledRefCode,
      },
      hidden: !discountConfig?.enabledRefCode,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'จุดประสงค์ในการออก Discount Code',
      inputName: 'discountText',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'สกุลเงิน',
      inputName: 'currency',
      options: [{ key: 'THB', value: 'THB', text: 'THB' }],
      inputProps: {
        defaultValue: 'THB',
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'จำนวนตั้งต้น',
      inputName: 'total',
    },
  ];

  if (isEdit) {
    lowerFields.push({
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'จำนวนคงเหลือ (preview)',
      inputName: 'remain',
      inputProps: {
        readOnly: true,
        value: `${calcRemainDiscountCode(oldTotal, total, oldRemain)}`,
      },
    });
  }
  return lowerFields;
};

const generateFormFields = ({
  validationType,
  minimumQuantity,
  customerType,
  isEdit,
  total,
  oldTotal,
  oldRemain,
}) => {
  const fields = [
    ...defaultFields,
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'ตัวเลือกการหมดอายุของคอร์ส',
      inputName: 'validationType',
      options: EXPIRY_CONDITION_OPTIONS,
      inputProps: {
        defaultValue: validationType ?? 'unselected',
      },
    },
  ];

  if (validationType === EXPIRY_CONDITION.DAYS_VALIDITY) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'จำนวนวันที่เรียนได้',
      inputName: 'daysBeforeExpiry',
    });
  } else if (validationType === EXPIRY_CONDITION.EXPIRE_DATE) {
    fields.push({
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'วันที่หมดอายุของคอร์ส',
      inputName: 'expiryDate',
      customInput: () => <DatePickerInput isEndDate={true} />,
    });
  }
  fields.push({
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel:
      'จำนวนลูกค้าขั้นต่ำที่จะใช้โค้ดได้ (ถ้ากำหนดขั้นต่ำ ระบบจะลดราคาตามมูลค่าของโค้ดคูณจำนวนคนในออเดอร์นั้น)',
    inputName: 'minimumQuantity',
    options: MINIMUM_QUANTITY_OPTIONS,
    inputProps: {
      defaultValue: minimumQuantity ?? 'unselected',
    },
  });
  fields.push(...getLowerFields({ customerType, isEdit, total, oldTotal, oldRemain }));

  return fields;
};

export default class DiscountForm extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    discountId: PropTypes.string,
    onCreateNewDiscount: PropTypes.func,
  };

  static defaultProps = {
    onCreateNewDiscount: () => {},
    isEdit: false,
  };

  state = {
    fields: [
      ...generateFormFields({
        customerType: this.props.initialData?.customerType ?? CUSTOMER_TYPE.ALL,
        validationType: this.props.initialData?.validationType,
        minimumQuantity: this.props.initialData?.minimumQuantity,
        oldTotal: this.props.initialData?.total,
        oldRemain: this.props.initialData?.remain,
        total: this.props.initialData?.total,
        isEdit: this.props.isEdit,
      }),
    ],
  };

  handleSubmit = (form) => {
    const discountData = {
      ...form,
    };
    this.props.onCreateNewDiscount(discountData);
  };

  handleChange = (value, isChanged) => {
    const { validationType, total } = value;
    const { isEdit, initialData } = this.props;
    if (isChanged) {
      if (isEdit) {
        const { total: oldTotal, remain: oldRemain } = initialData;
        this.setState({
          fields: generateFormFields({ validationType, total, isEdit, oldTotal, oldRemain }),
        });
      } else {
        this.setState({
          fields: generateFormFields({ validationType, total, isEdit }),
        });
      }
    }
  };

  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={this.state.fields}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        customValidation={(currentValues) => {
          const validationErrors = {};
          const { code, total, remain, refCode, validationType } = currentValues;

          if (/\s/.test(code)) {
            validationErrors['code'] = 'โค้ดต้องไม่มีช่องว่าง';
          }

          if (discountConfig?.skooldioValidateBCode && code?.startsWith('B_')) {
            // check expiry date condition existed
            if (!Object.values(EXPIRY_CONDITION).includes(validationType)) {
              validationErrors['validationType'] = 'กรุณาเลือกตัวเลือกการหมดอายุของคอร์ส';
            }
          }

          if (discountConfig?.enabledRefCode) {
            if (refCode?.length > 50) {
              validationErrors['refCode'] = 'เลขอ้างอิงห้ามเกิน 50 ตัวอักษร';
            }

            if (refCode?.trim() !== '-') {
              // regex for refCode. check for '-' or start with INV and numbers
              const regexRefCode = discountConfig?.refCodeRegEx ?? /^-|INV[0-9]+$/;
              if (!regexRefCode.test(refCode)) {
                // write error message that refCode is in wrong format
                validationErrors['refCode'] = 'เลขอ้างอิงไม่ถูกต้อง';
              }
            }
          }

          if (isEdit) {
            const { total: oldTotal, remain: oldRemain } = this.props.initialData;
            if (isNaN(total)) {
              validationErrors['total'] = 'จำนวนตั้งต้นต้องใส่เป็นตัวเลข';
            }
            if (parseFloat(remain) < 0) {
              const usedCode = oldTotal - oldRemain;
              validationErrors[
                'total'
              ] = `จำนวนตั้งต้นควรมากกว่าหรือเท่ากับ ${usedCode} (จำนวน code ที่ใช้ไปแล้ว)`;
            }
          }
          return validationErrors;
        }}
        {...this.props}
      />
    );
  }
}
