export const BookPermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'product.book.list-view',
    UPDATE: 'product.book.list-update',
    DELETE: 'product.book.list-delete',
  },
  OVERVIEW: {
    VIEW: 'product.book.overview-view',
    UPDATE: 'product.book.overview-update',
    DELETE: 'product.book.overview-delete',
  },
  DETAILS: {
    VIEW: 'product.book.details-view',
    UPDATE: 'product.book.details-update',
    DELETE: 'product.book.details-delete',
  },
  CATEGORIES: {
    VIEW: 'product.book.categories-view',
    UPDATE: 'product.book.categories-update',
    DELETE: 'product.book.categories-delete',
  },
  PRICING: {
    VIEW: 'product.book.pricing-view',
    UPDATE: 'product.book.pricing-update',
    DELETE: 'product.book.pricing-delete',
  },
  STOCK: {
    VIEW: 'product.book.stock-view',
    UPDATE: 'product.book.stock-update',
    DELETE: 'product.book.stock-delete',
  },
});
