import React from 'react';

import QueryComponent from 'GraphQL/util/QueryComponent';

import { getSubscription } from '../../GraphQL/query/Products.query';

import SubscriptionDetails from './SubscriptionDetails';

const SubscriptionDetailsRoute = (props) => {
  const { match = {} } = props;
  const { params = {} } = match;
  const { SKUCode } = params;
  return (
    <QueryComponent
      query={getSubscription}
      variables={{
        SKUCode,
      }}
      loadingOverlay
    >
      {(data) => {
        return (
          <SubscriptionDetails {...props} subscription={data && data.sfBoProductSubscription} />
        );
      }}
    </QueryComponent>
  );
};

export default SubscriptionDetailsRoute;
