import React from 'react';
import { useQuery } from '@apollo/client';

import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';

import { getCollectionOnlineCourse } from '../../GraphQL/query/Products.query';

import OnlineCourseDetails from './OnlineCourseDetails';

const OnlineCourseDetailsRoute = (props) => {
  const { match = {} } = props;
  const { params = {} } = match;
  const { SKUCode } = params;
  const { data, loading, error } = useQuery(getCollectionOnlineCourse, { variables: { SKUCode } });

  if (loading) return <Loading dimmer={true} />;
  if (error) return <ErrorView message={error} />;
  return (
    <OnlineCourseDetails
      {...props}
      collectionOnlineCourse={data?.sfBoProductCollectionOnlineCourse}
    />
  );
};

export default OnlineCourseDetailsRoute;
