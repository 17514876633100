import { ApolloClient, HttpLink, InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

import getConfig from '../Util/Config';

import possibleTypes from './possibleTypes.json';

const apolloPath = getConfig().graphApi;

const client = new ApolloClient({
  // uri: apolloPath,
  // fetchOptions: {
  //   //   // headers: {
  //   //   //   // 'user-agent': 'Mozilla/4.0 MDN Example',
  //   //   //   'content-type': 'application/json',
  //   //   // },
  //   // mode: 'cors',
  //   // credentials: 'include',
  // },
  link: new HttpLink({
    uri: apolloPath,
    // credentials: 'same-origin',
    credentials: 'include',
  }),
  cache: new InMemoryCache({
    possibleTypes,
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case 'RegistrarUserEnrollments':
          return object.userId;
        case 'UserCertificateUserData':
          return `${object.id}-${object.firstname}-${object.lastname}`;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    },
    cacheRedirects: {
      Query: {
        exam: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Exam', id: args.examId }),
        userDeviceTokens: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'UserDeviceTokens', id: args.userId }),
      },
    },
  }),
});

console.log('graphql endpoint init:', apolloPath);

export default client;
