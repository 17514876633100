import React, { useState, useRef } from 'react';
import { Modal, Message } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { isNil } from 'lodash';

import StatefulModal from 'Components/Modal/StatefulModal';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';
import { Box, Flex } from 'Components/Base';
import Loading from 'Components/Loading';
import { getRandomDiscountCodeJob } from 'Routes/SalesV2/GraphQL/query/DiscountCode.query';

import { createRandomizedDiscountCodeJob } from '../../GraphQL/mutation/DiscountCode.mutation';

import CreateRandomizedDiscountCodeForm from './CreateRandomizedDiscountCodeForm';
import ModalAction from './ModalAction';

const formatData = (values) => {
  const { discountUnitValue, ...restValues } = values;
  const { unit, value: DiscountValue } = discountUnitValue;
  const randomLength = parseInt(values.randomLength);
  const amount = parseInt(values.amount);
  const useableTimes = parseInt(values.useableTimes);

  const discountUnit = unit;
  const discount = parseFloat(DiscountValue);

  const formattedData = {
    ...restValues,
    randomLength,
    amount,
    useableTimes,
    discount,
    discountUnit,
  };
  if (formattedData?.productTypes === '') {
    formattedData.productTypes = [];
  }
  if (formattedData?.exceptSKUIds === '') {
    formattedData.exceptSKUIds = [];
  }
  if (formattedData?.SKUIds === '') {
    formattedData.SKUIds = [];
  }

  return formattedData;
};

const SNACKBAR = {
  SUCCESS: {
    message: `ส่งคำสั่งสำเร็จ กำลังสร้างโค้ด…`,
    variant: VARIANTS.SUCCESS,
    show: true,
  },
  ERROR: {
    message: `ส่งคำสั่งไม่สำเร็จ`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const RandomizedDiscountCodeModal = ({ trigger, initialData, isEdit, onClose, onSuccess }) => {
  const [snackbar, setSnackbar] = useState({});
  const [errorMsg, setErrorMsg] = useState();
  const submitButtonRef = useRef(null);

  const resetState = () => {
    setSnackbar({});
    setErrorMsg();
  };

  const onCreateCompleted = (data) => {
    setSnackbar(SNACKBAR.SUCCESS);
    setErrorMsg();
  };

  const onCreateError = (error) => {
    setSnackbar(SNACKBAR.ERROR);
    setErrorMsg(error?.message);
  };

  const [createRandomizedDiscountCodeMutation, { loading, error }] = useMutation(
    createRandomizedDiscountCodeJob,
    {
      onCompleted: onCreateCompleted,
      onError: onCreateError,
    }
  );

  const onSubmit = async (form, closeModal) => {
    const formattedInput = formatData(form);
    const res = await createRandomizedDiscountCodeMutation({
      variables: { input: formattedInput },
      refetchQueries: [
        {
          query: getRandomDiscountCodeJob,
          variables: {
            paging: { currentPage: 0, pageSize: 10 },
            search: {},
            order: { field: 'updatedAt', type: 'DESC' },
          },
        },
      ],
    });

    if (isNil(res?.errors)) {
      onSuccess?.();
      closeModal();
    }
  };
  const onCancel = (closeFn) => {
    resetState();
    onClose?.();
    closeFn();
  };

  const handleClickSubmit = () => {
    submitButtonRef?.current?.click();
  };

  return (
    <>
      <Snackbar
        message={snackbar?.message}
        isOpen={snackbar?.show}
        onClose={() => setSnackbar({ ...snackbar, show: false })}
        variant={snackbar?.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModalFn) => (
          <>
            <Modal.Header>
              {isEdit ? 'Create New Random Discount Code' : 'Random Discount Code'}
            </Modal.Header>
            <Modal.Content>
              {errorMsg && (
                <Message negative>
                  <p>{errorMsg}</p>
                </Message>
              )}
              <CreateRandomizedDiscountCodeForm
                initialData={initialData}
                isEdit={isEdit}
                onSubmit={(form) => onSubmit(form, closeModalFn)}
                onCancel={() => onCancel(closeModalFn)}
                isError={errorMsg ? true : false}
                loading={loading}
                submitButtonRef={submitButtonRef}
              />
              <Box></Box>
              <Flex justifyContent="flex-end" marginTop={2}>
                <ModalAction
                  onCancel={() => onCancel(closeModalFn)}
                  onConfirm={handleClickSubmit}
                  loading={loading}
                />
              </Flex>
            </Modal.Content>
          </>
        )}
      </StatefulModal>
    </>
  );
};

export default RandomizedDiscountCodeModal;
