import React, { Component } from 'react';

import { Flex } from 'Components/Base';

import QueryTable, { DATA_FORMAT } from '../../../../GraphQL/util/QueryTable';
import COLUMN_TYPE from '../../../../Components/Search/SearchFormGenerator/constantType';
import { classroomReport } from '../../../../GraphQL/query/Reports.query';
import Text from '../../../../Components/Base/Text';
import { CourseSelectorInput } from '../../Components/SelectorInput';

const getColumns = () => [
  {
    Header: 'User Id',
    accessor: 'userId',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Company',
    accessor: 'organizationName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Course',
    accessor: 'courseTitle',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Courses',
    accessor: 'courseId',
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: true,
    show: false,
    CustomSearchComponent: (props) => (
      <CourseSelectorInput
        {...props}
        variables={{
          search: { version: CourseSelectorInput.COURSE_VERSION.SKOOLDIO_V2 },
          paging: { currentPage: 0, pageSize: 100 },
          order: { field: 'id', type: 'ASC' },
        }}
        placeholder="Select Courses"
      />
    ),
  },
  {
    Header: '% Complete',
    accessor: 'completionRate',
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: false,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_PERCENT,
  },
  {
    Header: 'Start Date',
    accessor: 'firstEnteredAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Last Access Date',
    accessor: 'lastEnteredAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
  {
    Header: 'Finish Date',
    accessor: 'finishedAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
  },
];

class ClassroomReport extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 20 },
    search: {},
    order: { field: 'lastEnteredAt', type: 'DESC' },
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Classroom</Text.Header>
        </Flex>
        <QueryTable
          type="reportClassroom"
          resolveData={(data) => {
            return data.reportClassroom.classrooms;
          }}
          columns={getColumns()}
          query={classroomReport}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          exportable
        />
      </div>
    );
  }
}

export default ClassroomReport;
