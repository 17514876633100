import gql from 'graphql-tag';

export const EnrollmentDetailsFragment = gql`
  fragment EnrollmentDetailsFragment on RegistrarEnrollment {
    id
    courseId
    createdAt
    course(version: $courseVersion) {
      title
    }
  }
`;

export const userEnrollmentsQuery = gql`
  query userEnrollments($userId: ID!, $courseVersion: COURSE_VERSION = LEARNX_V1) {
    userEnrollments(userId: $userId) {
      userId
      enrollments {
        id
        ...EnrollmentDetailsFragment
      }
    }
  }
  ${EnrollmentDetailsFragment}
`;

export const classroomEnrollmentByUserIdQuery = gql`
  query classroomEnrollmentByUserId(
    $search: EnrollmentByUserIdSearchInput
    $paging: PageInput
    $order: ClassroomEnrollmentOrderInput
  ) {
    classroomEnrollmentByUserId(search: $search, paging: $paging, order: $order) {
      total
      classroomEnrollments {
        id
        createdAt
        deletedAt
        expiryDate
        studyProgress
        user {
          id
          firstname
          lastname
        }
        course {
          id
          name
          code
          owner
        }
      }
    }
  }
`;

export const classroomEnrollmentByCourseIdQuery = gql`
  query classroomEnrollmentByCourseId(
    $search: EnrollmentByCourseIdSearchInput
    $paging: PageInput
    $order: ClassroomEnrollmentOrderInput
  ) {
    classroomEnrollmentByCourseId(search: $search, paging: $paging, order: $order) {
      classroomEnrollments {
        id
        studentId
        user {
          id
          externalUsername
          firstname
          lastname
          type
        }
        createdAt
        deletedAt
      }
      total
    }
  }
`;
