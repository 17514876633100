import numeral from 'numeral';
import * as moment from 'moment-timezone';

import delve from '../../../Util/Delve';
import COLUMN_TYPE from '../../../Components/Search/SearchFormGenerator/constantType';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';
const TIME_FORMAT = 'HH:mm';

export const dateFormatter = (format = DATETIME_FORMAT, timeZone) => (jsonDate) => {
  if (timeZone) {
    return moment(jsonDate).tz(timeZone).format(format);
  }
  return moment(jsonDate).format(format);
};

const NUMBER_FORMAT_DEFAULT = {
  multiplier: 1,
  format: '0,0',
};

const NUMBER_FORMAT_FLOAT = {
  multiplier: 1,
  format: '0,0.00',
};

const NUMBER_FORMAT_PERCENT = {
  multiplier: 100,
  format: '0.00',
};

const NUMBER_FORMAT_INTEGER = {
  multiplier: 1,
  format: '0,0',
};

export const numberFormatter = ({ multiplier, format }) => (value) => {
  return numeral(value * multiplier).format(format);
};

export const defaultFormatter = (value) => value;

export function getFormatter(type, customFormat) {
  switch (type) {
    case COLUMN_TYPE.DATE:
      return dateFormatter(customFormat || DATETIME_FORMAT);
    case COLUMN_TYPE.NUMBER: {
      const numberFormat = customFormat
        ? { ...NUMBER_FORMAT_DEFAULT, ...customFormat }
        : NUMBER_FORMAT_DEFAULT;
      return numberFormatter(numberFormat);
    }
    default:
      return null;
  }
}

export function getFormatterCSV(type, customFormat) {
  switch (type) {
    case COLUMN_TYPE.DATE:
      return dateFormatter(customFormat || DATETIME_FORMAT);
    case COLUMN_TYPE.NUMBER: {
      const numberFormat = customFormat
        ? { ...NUMBER_FORMAT_DEFAULT, ...customFormat }
        : NUMBER_FORMAT_DEFAULT;
      return numberFormatter(numberFormat);
    }
    default:
      return defaultFormatter;
  }
}

export function mapFormatterTable(column) {
  const { accessor, type, Cell, dataFormat } = column;
  if (Cell) return column;
  const formatter = getFormatter(type, dataFormat);
  if (!formatter) return column;

  return {
    ...column,
    Cell: ({ original }) => {
      const data = delve(original, accessor);
      if (data === null || data === undefined) return null;
      return formatter(data);
    },
  };
}

/**
 * Get React-Table like object with csvDisplay function
 * @param {array} column - array of column configurations
 */
export function mapFormatterCSV(column) {
  const { accessor, type, csvDisplay, dataFormat } = column;
  if (csvDisplay) return column;
  const formatter = getFormatterCSV(type, dataFormat);
  if (!formatter) return column;

  return {
    ...column,
    csvDisplay: ({ original }) => {
      const data = original[accessor];
      if (data === null || data === undefined) return null;
      return formatter(data);
    },
  };
}

export const DATA_FORMAT = {
  DATE_FORMAT,
  DATETIME_FORMAT,
  TIME_FORMAT,
  NUMBER_FORMAT_DEFAULT,
  NUMBER_FORMAT_INTEGER,
  NUMBER_FORMAT_FLOAT,
  NUMBER_FORMAT_PERCENT,
};
