export const FILE_TYPE = {
  SYLLABUS: 'NEW_SYLLABUS',
  STUDENT: 'NEW_STUDENT_AND_PARENT',
  TEACHER: 'TEACHER',
};

export const FILE_IMPORT_STATUS = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
};
