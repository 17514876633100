import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'Components/Base';

import { ITEMS_POSITION } from './constant';

/**
 * SideNavItems Component
 * @param {string} position - Position of side navigation items [top or bottom]
 */
const SideNavItems = ({ children, position, ...props }) => (
  <Flex
    height={'100%'}
    flexDirection={'column'}
    flex={'1 1 auto'}
    justifyContent={position === 'bottom' ? 'flex-end' : 'flex-start'}
    {...props}
  >
    {children}
  </Flex>
);

SideNavItems.propTypes = {
  position: PropTypes.oneOf([ITEMS_POSITION.TOP, ITEMS_POSITION.BOTTOM]),
};

SideNavItems.defaultProps = {
  position: ITEMS_POSITION.TOP,
};

export default SideNavItems;
