import gql from 'graphql-tag';

export const deletePrice = gql`
  mutation deletePrice($priceId: ID!) {
    deletePrice(priceId: $priceId)
  }
`;
export const deleteDiscount = gql`
  mutation deleteDiscount($discountId: ID!) {
    deleteDiscount(discountId: $discountId)
  }
`;

export const createDiscountMutation = gql`
  mutation CreateDiscount($newDiscount: DiscountInput!) {
    createDiscount(newDiscount: $newDiscount) {
      id
    }
  }
`;

export const createPrice = gql`
  mutation createPrice($newProductPrice: PriceInput!) {
    createPrice(newProductPrice: $newProductPrice) {
      id
      itemId
      fullPrice
      promotionPrice
      promotionText
      currency
      createdAt
    }
  }
`;

export const updateDiscountMutation = gql`
  mutation UpdateDiscount($discountId: ID!, $updateDiscount: DiscountInput!) {
    updateDiscount(discountId: $discountId, updateDiscount: $updateDiscount) {
      id
    }
  }
`;

export const updatePrice = gql`
  mutation updatePrice($priceId: ID!, $updatePrice: PriceInput!) {
    updatePrice(priceId: $priceId, updatePrice: $updatePrice) {
      id
    }
  }
`;
