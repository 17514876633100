import React from 'react';
import PropTypes from 'prop-types';

import { getWorkshopHighlight } from 'GraphQL/query/Workshop.query';
import { sfUpdateWorkshop } from 'GraphQL/mutation/Workshop.mutation';

import SKUBaseHighlightEditor from '../SKUBaseHighlightEditor';

const WorkshopHighlightEditor = ({ skuCode }) => {
  return (
    <SKUBaseHighlightEditor
      dataQuery={getWorkshopHighlight}
      dataQueryName={'sfWorkshopBySKUCode'}
      variableQuery={{ SKUCode: skuCode }}
      handleMutation={sfUpdateWorkshop}
      getHandleVariables={(data) => ({
        SKUCode: skuCode,
        data: { highlights: data },
      })}
      refetchQueries={[{ query: getWorkshopHighlight, variables: { SKUCode: skuCode } }]}
    />
  );
};

WorkshopHighlightEditor.propTypes = {
  skuCode: PropTypes.string.isRequired,
};

export default WorkshopHighlightEditor;
