export const ExamTeamPermissionKey = Object.freeze({
  LIST: {
    VIEW: 'examTeam.list-view',
    UPDATE: 'examTeam.list-update',
    DELETE: ' examTeam.list-delete',
  },
  DETAIL: {
    VIEW: 'examTeam.detail-view',
    UPDATE: 'examTeam.detail-update',
    DELETE: ' examTeam.detail-delete',
  },
  EXAM_TAG: {
    VIEW: 'examTeam.examTag-view',
    UPDATE: 'examTeam.examTag-update',
    DELETE: ' examTeam.examTag-delete',
  },
});
