import { fork, take, put, call } from 'redux-saga/effects';

import fetch2, { METHOD } from '../../Util/fetch';

import { CALL_API } from './actions';

function* handleApi({ key, path, content, actionTypes }) {
  // const token = state && state.auth && state.auth.get('token');
  const { params, method, body, fetchOptions } = content;
  const [requestType, successType, errorType] = actionTypes;

  yield put({ type: requestType, payload: { key, path, content, actionTypes } });

  try {
    const result = yield call(fetch2, path, params, method, body, fetchOptions);
    yield put({ type: successType, payload: { key, result } });
  } catch (error) {
    yield put({ type: errorType, payload: { key, error } });
  }
}

export function* api() {
  while (true) {
    const nextAction = yield take(CALL_API);
    yield fork(handleApi, nextAction.payload);
  }
}

export default [api()];
