import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createSchoolProgrammeMutation,
  updateSchoolProgrammeMutation,
} from 'GraphQL/LSP/mutation/Education.mutation';
import { getSchoolProgrammes, getSchoolProgramme } from 'GraphQL/LSP/query/Education.query';

import SchoolProgrammeForm from './SchoolProgrammeForm';

export default class SchoolProgrammeModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    trigger: PropTypes.element,
  };
  static defaultProps = {
    isEdit: false,
  };
  render() {
    const { isEdit, id, trigger, variables } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{isEdit ? 'แก้ไขแผนการเรียน' : 'สร้างแผนการเรียน'}</Modal.Header>
            <Modal.Content>
              <CreateEditForm
                dataQuery={getSchoolProgramme}
                createMutation={createSchoolProgrammeMutation}
                createRefetchQueries={[
                  {
                    query: getSchoolProgrammes,
                    variables,
                  },
                ]}
                editMutation={updateSchoolProgrammeMutation}
                isEdit={isEdit}
                id={id}
                getQueryVariables={({ id }) => ({
                  id,
                })}
                getEditVariables={(form, { id }) => ({
                  id,
                  schoolProgrammeData: form,
                })}
                getCreateVariables={(form) => ({
                  schoolProgrammeData: form,
                })}
                onCompleted={closeModal}
                onCancelled={closeModal}
              >
                {({ data, onSubmit }) => {
                  return (
                    <SchoolProgrammeForm
                      initialData={data && data.boSchoolProgramme}
                      onSubmit={onSubmit}
                    />
                  );
                }}
              </CreateEditForm>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
