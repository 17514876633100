import gql from 'graphql-tag';

import { SalesOrderFragment, AdjustmentOrderFragment } from '../query/Payment.query';

export const updateSalesOrderStatus = gql`
  mutation sfBoPaymentUpdateSalesOrderStatus(
    $id: ID!
    $data: SFBOPaymentUpdateSalesOrderStatusInput!
  ) {
    sfBoPaymentUpdateSalesOrderStatus(id: $id, data: $data) {
      ...SalesOrderFragment
    }
  }
  ${SalesOrderFragment}
`;

export const changeSubscription = gql`
  mutation sfBoPaymentChangeSubscription($input: SFBoPaymentChangeSubscriptionInput!) {
    sfBoPaymentChangeSubscription(input: $input) {
      ...AdjustmentOrderFragment
    }
  }
  ${AdjustmentOrderFragment}
`;

export const cancelSubscription = gql`
  mutation sfBoPaymentChangeSubscription($data: SFBoCancelSubscriptionInput!) {
    sfBoCancelSubscription(data: $data) {
      id
      userId
      cancellationDate
    }
  }
`;
