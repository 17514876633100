import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import isUrl from 'is-url';

import { editCourse } from 'GraphQL/mutation/Courses.mutation';
import { Box } from 'Components/Base';
import FormGenerator from 'Components/Form/FormGenerator';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';

import ForumTypeInput, { FORUM_TYPE } from '../../Components/ForumTypeInput';

const DiscussionsEditor = ({ courseId, course, product, version }) => {
  const [updateCourse, { loading }] = useMutation(editCourse);
  const [form, setForm] = useState({
    type: course.meta?.forum?.type || FORUM_TYPE.DEFAULT,
    url: course.meta?.forum?.url || '',
  });

  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Forum Type',
      inputName: 'type',
      inputProps: {
        radioList: [
          { label: 'Discuss', value: FORUM_TYPE.DEFAULT },
          { label: 'Custom URL', value: FORUM_TYPE.CUSTOM },
        ],
        value: form.type,
      },
      customInput: (props) => <ForumTypeInput {...props} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Forum Url',
      inputName: 'url',
      inputProps: {
        placeholder: form.type === FORUM_TYPE.DEFAULT ? 'Default forum url' : '',
        value: form.type === FORUM_TYPE.DEFAULT ? '' : form.url,
        readOnly: form.type === FORUM_TYPE.DEFAULT,
      },
    },
  ];

  const submit = (forum) => {
    updateCourse({
      variables: {
        courseId,
        version: productCourseVersionResolver(product, version),
        courseDesc: {
          forum,
        },
      },
    });
  };

  return (
    <Box>
      <FormGenerator
        loading={loading}
        fields={fields}
        submitText="Update"
        cancelText="Cancel"
        onSubmit={submit}
        onChange={setForm}
        showCancel
        onCancel={() =>
          setForm({
            type: course.meta?.forum?.type || FORUM_TYPE.DEFAULT,
            url: course.meta?.forum?.url || '',
          })
        }
        customValidation={(form) => {
          if (form.type === FORUM_TYPE.CUSTOM && !isUrl(form.url)) {
            return { url: 'please input a valid url' };
          }
        }}
      />
    </Box>
  );
};

export default DiscussionsEditor;
