import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormGenerator from '../../../Components/Form/FormGenerator';
import {
  getTypeList,
  sectionTypeList,
  filterTypeList,
  mappingFn,
  SECTION_CONTENT_TYPE,
  MODE,
  MODE_STRING,
} from '../../../Components/SubSection/util';
import getConfig from '../../../Util/Config';

const sectionFields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Title',
    inputName: 'title',
    inputProps: {
      required: true,
    },
  },
];

const subsectionFields = (typeList) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Title',
    inputName: 'title',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Type',
    inputName: 'contentType',
    inputProps: {
      required: true,
      search: true,
      options: typeList,
      defaultValue: typeList.length > 0 ? typeList[0].value : null,
    },
  },
];

const sectionGroupFields = (sectionTypeList) => [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Title',
    inputName: 'title',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'Type',
    inputName: 'contentType',
    inputProps: {
      required: true,
      options: sectionTypeList,
      defaultValue: SECTION_CONTENT_TYPE.SECTION_GROUP,
    },
    hidden: true,
  },
];

const genFields = (mode, product, allowedSubsectionTypes) => {
  switch (mode) {
    case MODE.SECTION: {
      return sectionFields;
    }
    case MODE.SECTION_GROUP: {
      return sectionGroupFields(sectionTypeList.filter(filterTypeList(product)).map(mappingFn));
    }
    default: {
      const typeList = getTypeList(allowedSubsectionTypes);
      return subsectionFields(typeList.filter(filterTypeList(product)).map(mappingFn));
    }
  }
};

export default class AddSection extends Component {
  static propTypes = {
    mode: PropTypes.string,
    product: PropTypes.string,
  };

  static defaultProps = {
    mode: MODE.SECTION,
  };

  render() {
    const { allowedSubsectionTypes = [] } = getConfig();
    const { product, mode, ...restProps } = this.props;

    return (
      <FormGenerator
        fields={genFields(mode, product, allowedSubsectionTypes)}
        submitText={`Create ${MODE_STRING[mode]}`}
        showCancel
        cancelText="Cancel"
        name={`add-${mode.toLowerCase()}-form`}
        {...restProps}
      />
    );
  }
}
