import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateOnlineCourseMutation } from '../../GraphQL/mutation/Products.mutation';
import { SKU_VISIBILITY, SKU_STATUS } from '../../Domains/constants';

import OnlineCourseOverviewForm from './OnlineCourseOverviewForm';

const resolveData = ({ sfBoProductOnlineCourse }) => {
  return {
    ...sfBoProductOnlineCourse,
    eventDateRange: {
      startDate: sfBoProductOnlineCourse.eventTime?.startDate,
      endDate: sfBoProductOnlineCourse.eventTime?.endDate,
    },
    eventTimeRange: {
      startTime: sfBoProductOnlineCourse.eventTime?.startTime,
      endTime: sfBoProductOnlineCourse.eventTime?.endTime,
    },
    SKUStatus: `${
      sfBoProductOnlineCourse.isVisible ? SKU_VISIBILITY.VISIBLE : SKU_VISIBILITY.UNLISTED
    }/${sfBoProductOnlineCourse.SKUStatus}`,
  };
};

class OnlineCourseOverviewEditor extends Component {
  render() {
    const { onlineCourse = {} } = this.props;
    const { id, SKUCode } = onlineCourse;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getOnlineCourse}
          editMutation={updateOnlineCourseMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => {
            let eventTime = undefined;
            if (form.eventDateRange && form.eventTimeRange) {
              const eventDateRange = {};
              const eventTimeRange = {};
              const { startTime: startTimeInput, endTime: endTimeInput } = form.eventTimeRange;
              const { startDate: startDateInput, endDate: endDateInput } = form.eventDateRange;

              if (startDateInput && startTimeInput) {
                const timeInput = moment(startTimeInput);
                const startDate = moment(startDateInput).set({
                  hour: timeInput.hour(),
                  minute: timeInput.minute(),
                  second: timeInput.second(),
                  millisecond: timeInput.millisecond(),
                });
                eventDateRange.startDate = startDate.toJSON();
                eventTimeRange.startTime = startDate.toJSON();
              }

              if (endDateInput && endTimeInput) {
                const timeInput = moment(endTimeInput);
                const endDate = moment(endDateInput).set({
                  hour: timeInput.hour(),
                  minute: timeInput.minute(),
                  second: timeInput.second(),
                  millisecond: timeInput.millisecond(),
                });
                eventDateRange.endDate = endDate.toJSON();
                eventTimeRange.endTime = endDate.toJSON();
              }
              eventTime = { ...eventDateRange, ...eventTimeRange };
            }

            const updateData = {
              ..._.omit(form, ['eventDateRange', 'eventTimeRange', 'comingSoonText']),
              eventTime,
              promotionYoutubeId: form.promotionYoutubeId || null,
              SKUStatus: form.SKUStatus.split('/')[1],
              isVisible: form.SKUStatus.split('/')[0] === SKU_VISIBILITY.VISIBLE,
            };

            const { comingSoonText, SKUStatus } = form;

            if (comingSoonText && comingSoonText !== null) {
              updateData.comingSoonText = comingSoonText;
            }

            if (SKUStatus !== `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.SELLING}`) {
              updateData.prescreenFormURL = null;
            }

            return { id, data: updateData };
          }}
        >
          {({ data, onSubmit }) => {
            return <OnlineCourseOverviewForm initialData={resolveData(data)} onSubmit={onSubmit} />;
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default OnlineCourseOverviewEditor;
