import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Dropdown } from 'semantic-ui-react';

import { Flex, Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

class PriceInput extends Component {
  state = {
    value: this.props.value.value,
    unit: this.props.value.unit,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    errorMessages: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.number,
        unit: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  };

  handleChange = (name, data) => {
    this.setState({ [name]: data }, () => {
      this.props.onChange({}, { value: this.state });
    });
  };

  render() {
    const { value, unit } = this.state;
    const { name, value: formvalue, errorMessages, ...inputProps } = this.props;

    return (
      <Flex flexDirection="row">
        <Input
          name={`${name}.value`}
          defaultValue={`${value}`}
          type="number"
          {...inputProps}
          min="0"
          onChange={(e, data) =>
            this.handleChange('value', isNaN(parseFloat(data.value)) ? '' : parseFloat(data.value))
          }
        />
        <Box px={2} />
        <Dropdown
          name={`${name}.unit`}
          required
          selection
          defaultValue={unit}
          options={[{ key: 'THB', value: 'THB', text: 'THB' }]}
          {...inputProps}
          onChange={(e, data) => this.handleChange('unit', data.value)}
        />
      </Flex>
    );
  }
}
export default withFormsy(PriceInput);
