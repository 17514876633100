/* eslint-disable react/display-name */
import React, { useState, useRef } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';

import { Box, Flex } from 'Components/Base';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable, { DATA_FORMAT } from 'GraphQL/util/QueryTable';
import { getFormatter } from 'GraphQL/util/QueryTable/dataUtil';
import { RandomizedDiscountCodeModal } from 'Routes/SalesV2/components/RandomizedDiscountCodeModal';
import { getRandomDiscountCodeJob } from 'Routes/SalesV2/GraphQL/query/DiscountCode.query';

import { TextAlignRight } from '../../components/TextAlignRight';
import {
  randomDiscountCodeJobOptions,
  RANDOM_DISCOUNT_CODE_JOB_STATUS,
} from '../../Domains/discountCode';

import DownloadCSVButton from './DownloadCSVButton';

const moneyFormatter = getFormatter(COLUMN_TYPE.NUMBER, DATA_FORMAT.NUMBER_FORMAT_FLOAT);
const columns = (openModal) => [
  {
    Header: 'Prefix',
    accessor: 'prefix',
    width: 150,
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      return original?.input?.prefix;
    },
  },
  {
    Header: 'จุดประสงค์ในการออก Discount Code',
    accessor: 'discountText',
    type: COLUMN_TYPE.STRING,
    width: 250,
    isSearchAble: true,
    Cell: ({ original }) => {
      return original?.input?.discountText;
    },
  },
  {
    Header: 'มูลค่า',
    accessor: 'input.discount',
    type: COLUMN_TYPE.STRING,
    Cell: ({ original }) => {
      if (original?.input?.discountUnit === 'FIX') {
        const value = original?.input?.discount;
        return <TextAlignRight>{moneyFormatter(value)}</TextAlignRight>;
      }
      return <TextAlignRight>{original?.input?.discount}</TextAlignRight>;
    },
  },
  {
    Header: 'หน่วย',
    accessor: 'input.discountUnit',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'จำนวน',
    accessor: 'input.amount',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 150,
    type: COLUMN_TYPE.ENUM,
    enumOptions: randomDiscountCodeJobOptions,
    isSearchAble: true,
  },
  {
    Header: 'Error',
    accessor: 'result.error',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Action',
    width: 150,
    type: COLUMN_TYPE.CUSTOM,
    Cell: ({ original }) => {
      const { status, id } = original;
      const downloadable = status === RANDOM_DISCOUNT_CODE_JOB_STATUS.SUCCESSFUL;
      return (
        <Flex justifyContent="center">
          <DownloadCSVButton downloadable={downloadable} id={id} />
        </Flex>
      );
    },
  },
  {
    Header: '',
    accessor: 'input',
    width: 150,
    type: COLUMN_TYPE.CUSTOM,
    Cell: ({ original }) => {
      const { input: randomInput } = original;
      return (
        <Flex justifyContent="center">
          <Button primary circular onClick={() => openModal(randomInput)}>
            See Details
          </Button>
        </Flex>
      );
    },
  },
];

const defaultQueryTableState = {
  paging: { currentPage: 0, pageSize: 10 },
  search: {},
  order: { field: 'updatedAt', type: 'DESC' },
};

const toCreateRandomCodeFormat = (input) => {
  const { discountUnit, discount } = input;
  return {
    ...input,
    discountUnitValue: {
      unit: discountUnit,
      value: discount,
    },
  };
};

const RandomDiscountCodeJobRoute = (props) => {
  const [queryTableState, setQueryTableState] = useState(defaultQueryTableState);
  // Modal Topic State
  const [modalData, setModalData] = useState(undefined);
  const [isEdit, setIsEdit] = useState(true);
  const openModalBtnRef = useRef(null);

  const setQueryTable = (newState) => {
    setQueryTableState((prevState) => ({ ...prevState, ...newState }));
  };

  const resetModalTopicState = () => {
    setModalData(undefined);
    setIsEdit(true);
  };

  const resolveData = (data) => {
    return data.sfBoPaymentSearchRandomDiscountCodeJobs.jobs;
  };

  const genPaging = (page, pageSize = queryTableState.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };

  const genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  const handleSearchChange = (search) => {
    const paging = genPaging(0);
    setQueryTable({ search, paging });
  };
  const handlePageChange = (page) => {
    const paging = genPaging(page);
    setQueryTable({ paging });
  };
  const handlePageSizeChange = (pageSize) => {
    const paging = genPaging(0, pageSize);
    setQueryTable({ paging });
  };
  const handleSortedChange = (sorted) => {
    const order = genOrder(sorted);
    const paging = genPaging(0);
    setQueryTable({ order, paging });
  };

  const handleOpenModal = (data) => {
    const formatedData = toCreateRandomCodeFormat(data);
    setIsEdit(false);
    setModalData(formatedData);
    openModalBtnRef?.current?.ref?.click();
  };

  const handleCloseModal = () => {
    resetModalTopicState();
  };

  return (
    <div>
      <Flex justifyContent="space-between" mb={2}>
        <Text.Header>Random Discount code</Text.Header>
        <Box>
          <RandomizedDiscountCodeModal
            trigger={
              <Button primary circular ref={openModalBtnRef}>
                <Icon name="plus" size="small" />
                Random code
              </Button>
            }
            onClose={handleCloseModal}
            initialData={modalData}
            isEdit={isEdit}
          />
        </Box>
      </Flex>
      <QueryTable
        type="sfBoPaymentSearchRandomDiscountCodeJobs"
        resolveData={resolveData}
        columns={columns(handleOpenModal)}
        query={getRandomDiscountCodeJob}
        search={queryTableState.search}
        onSearchChange={handleSearchChange}
        paging={queryTableState.paging}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        order={queryTableState.order}
        onSortedChange={handleSortedChange}
        pollInterval={10000}
      />
    </div>
  );
};

export default RandomDiscountCodeJobRoute;
