export const BundlePermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'product.bundle.list-view',
    UPDATE: 'product.bundle.list-update',
    DELETE: 'product.bundle.list-delete',
  },
  OVERVIEW: {
    VIEW: 'product.bundle.overview-view',
    UPDATE: 'product.bundle.overview-update',
    DELETE: 'product.bundle.overview-delete',
  },
  CATEGORIES: {
    VIEW: 'product.bundle.categories-view',
    UPDATE: 'product.bundle.categories-update',
    DELETE: 'product.bundle.categories-delete',
  },
  TABLE_OF_CONTENTS: {
    VIEW: 'product.bundle.tableOfContents-view',
    UPDATE: 'product.bundle.tableOfContents-update',
    DELETE: 'product.bundle.tableOfContents-delete',
  },
  INSTRUCTORS: {
    VIEW: 'product.bundle.instructors-view',
    UPDATE: 'product.bundle.instructors-update',
    DELETE: 'product.bundle.instructors-delete',
  },
  DETAILS: {
    VIEW: 'product.bundle.details-view',
    UPDATE: 'product.bundle.details-update',
    DELETE: 'product.bundle.details-delete',
  },
  HIGHLIGHTS: {
    VIEW: 'product.bundle.highlights-view',
    UPDATE: 'product.bundle.highlights-update',
    DELETE: 'product.bundle.highlights-delete',
  },
  PRICING: {
    VIEW: 'product.bundle.pricing-view',
    UPDATE: 'product.bundle.pricing-update',
    DELETE: 'product.bundle.pricing-delete',
  },
  GALLERY: {
    VIEW: 'product.bundle.gallery-view',
    UPDATE: 'product.bundle.gallery-update',
    DELETE: 'product.bundle.gallery-delete',
  },
  ITEMS: {
    VIEW: 'product.bundle.items-view',
    UPDATE: 'product.bundle.items-update',
    DELETE: 'product.bundle.items-delete',
  },
  STOCK: {
    VIEW: 'product.bundle.stock-view',
    UPDATE: 'product.bundle.stock-update',
    DELETE: 'product.bundle.stock-delete',
  },
});
