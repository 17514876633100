import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';
import ROLE from 'Util/LSP/roles';
import getConfig from 'Util/Config';

import ProfileAvatarUploadForm from '../ProfileAvatarUploadForm';

const getFields = (isEdit) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Role',
      inputName: 'role',
      options: [
        { key: ROLE.ADVISOR_TEACHER, value: ROLE.ADVISOR_TEACHER, text: ROLE.ADVISOR_TEACHER },
        { key: ROLE.SUBJECT_TEACHER, value: ROLE.SUBJECT_TEACHER, text: ROLE.SUBJECT_TEACHER },
      ],
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'รหัสประจำตัวครู',
      inputName: 'code',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'คำนำหน้า',
      inputName: 'prefix',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'ชื่อ',
      inputName: 'firstName',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'นามสกุล',
      inputName: 'lastName',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'ชื่อเล่น',
      inputName: 'nickName',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Classroom Username',
      inputName: 'classroomUsername',
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Username',
      inputName: 'username',
      inputProps: {
        required: true,
        disabled: isEdit,
      },
    },
  ];
  if (!isEdit) {
    fields.push(
      {
        inputType: FormGenerator.INPUT_TYPE.PASSWORD_FIELD,
        inputLabel: 'Password',
        inputName: 'password',
        inputProps: {
          required: true,
        },
      },
      {
        inputType: FormGenerator.INPUT_TYPE.PASSWORD_FIELD,
        inputLabel: 'Confirm Password',
        inputName: 'passwordVerify',
        inputProps: {
          required: true,
        },
      }
    );
  }

  fields.push(
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Email',
      inputName: 'email',
      inputProps: {
        // @TODO: Update this
        required: true,
        disabled: isEdit,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'เบอร์โทร',
      inputName: 'phoneNumber',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
      inputLabel: 'ที่อยู่',
      inputName: 'address1',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'เขต/อำเภอ',
      inputName: 'district',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'จังหวัด',
      inputName: 'province',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'รหัสไปรษณีย์',
      inputName: 'zipCode',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'ตำแหน่ง',
      inputName: 'positions',
      options: getConfig().lsp.teacherPositionOptions.map((position) => ({
        key: position,
        value: position,
        text: position,
      })),
      inputProps: {
        required: true,
        multiple: true,
      },
    }
  );
  return fields;
};

export default class TeacherProfileForm extends Component {
  static propTypes = {
    onCreateTeacherProfile: PropTypes.func,
    onUploadProfileImage: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateTeacherProfile: () => {},
    onUploadProfileImage: () => {},
    isEdit: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
    };
  }

  handleUploadProfileImage = ({ key, bucket, url }) => {
    this.setState({ avatar: url });
    this.props.onUploadProfileImage({ key, bucket });
  };

  handleSubmit = (form) => {
    this.props.onCreateTeacherProfile(form);
  };

  checkPassword = (currentValues) => {
    const { isEdit } = this.props;
    const { password, passwordVerify } = currentValues;
    if (!isEdit && passwordVerify && password !== passwordVerify && passwordVerify.length > 0) {
      return { passwordVerify: 'Not Match' };
    }
    return {};
  };

  render() {
    const { isEdit, initialData } = this.props;
    const { avatar } = this.state;
    return (
      <Fragment>
        {isEdit && (
          <ProfileAvatarUploadForm
            userId={initialData.id}
            avatar={avatar || initialData.avatar}
            editMode={true}
            onSuccess={this.handleUploadProfileImage}
          />
        )}
        <FormGenerator
          fields={getFields(isEdit)}
          onSubmit={this.handleSubmit}
          submitText={isEdit ? 'Update' : 'Add'}
          cancelText="Cancel"
          showCancel
          customValidation={this.checkPassword}
          {...this.props}
        />
      </Fragment>
    );
  }
}
