import isUrl from 'is-url';

import getConfig from '../../Config';

const VIMEO_API = getConfig().vimeoApi;

// message ref. https://developer.vimeo.com/api/oembed/videos#table-4
const errorMessage = {
  304: "The video hasn't changed since the date given in the If-Modified-Since HTTP header.",
  403: "Embed permissions are disabled for this video, so you can't embed it.",
  404: "You aren't able to access the video because of privacy or permissions issues, or because the video is still transcoding.",
};

const responseMapper = async (response) => {
  const status = response?.status;
  switch (status) {
    case 200: {
      const data = await response.json();
      return { data, status };
    }
    case 304: {
      return { status, message: errorMessage[304] };
    }
    case 403: {
      return { status, message: errorMessage[403] };
    }
    case 404: {
      return { status, message: errorMessage[404] };
    }
    default:
      return { status, message: 'unknown error' };
  }
};

const getVideoDetailByUrl = async (url) => {
  try {
    if (!isUrl(url)) {
      return { found: false, status: 422, message: 'Please enter a valid url format' };
    }

    let encodedUrl = encodeURIComponent(url);
    const response = await fetch(`${VIMEO_API}?url=${encodedUrl}`);
    const { data, status, message } = await responseMapper(response);
    if (status === 200) {
      return { ...data, found: true };
    }
    return { found: false, status, message };
  } catch (err) {
    return { found: false, status: 500, message: err?.message };
  }
};

export const getVideoDetails = async (vimeoUrl) => {
  return await getVideoDetailByUrl(vimeoUrl);
};
