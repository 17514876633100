export const WorkshopPermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'product.workshop.list-view',
    UPDATE: 'product.workshop.list-update',
    DELETE: 'product.workshop.list-delete',
  },
  OVERVIEW: {
    VIEW: 'product.workshop.overview-view',
    UPDATE: 'product.workshop.overview-update',
    DELETE: 'product.workshop.overview-delete',
  },
  INSTRUCTORS: {
    VIEW: 'product.workshop.instructors-view',
    UPDATE: 'product.workshop.instructors-update',
    DELETE: 'product.workshop.instructors-delete',
  },
  CATEGORIES: {
    VIEW: 'product.workshop.categories-view',
    UPDATE: 'product.workshop.categories-update',
    DELETE: 'product.workshop.categories-delete',
  },
  DETAILS: {
    VIEW: 'product.workshop.details-view',
    UPDATE: 'product.workshop.details-update',
    DELETE: 'product.workshop.details-delete',
  },
  TABLE_OF_CONTENTS: {
    VIEW: 'product.workshop.tableOfContents-view',
    UPDATE: 'product.workshop.tableOfContents-update',
    DELETE: 'product.workshop.tableOfContents-delete',
  },
  HIGHLIGHT: {
    VIEW: 'product.workshop.highlight-view',
    UPDATE: 'product.workshop.highlight-update',
    DELETE: 'product.workshop.highlight-delete',
  },
  GALLERY: {
    VIEW: 'product.workshop.gallery-view',
    UPDATE: 'product.workshop.gallery-update',
    DELETE: 'product.workshop.gallery-delete',
  },
  BATCHES: {
    VIEW: 'product.workshop.batches-view',
    UPDATE: 'product.workshop.batches-update',
    DELETE: 'product.workshop.batches-delete',
  },
  FAQ: {
    VIEW: 'product.workshop.faq-view',
    UPDATE: 'product.workshop.faq-update',
    DELETE: 'product.workshop.faq-delete',
  },
  TESTIMONIALS: {
    VIEW: 'product.workshop.testimonials-view',
    UPDATE: 'product.workshop.testimonials-update',
    DELETE: 'product.workshop.testimonials-delete',
  },
});
