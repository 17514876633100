import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';

import { PopupSingleSlideForm } from '../Form';

const PopupSingleSlideModal = ({ onChange, rawSlideDetail = {}, trigger, isEdit }) => {
  const mapSlideDataIntoFormData = (slideData) => {
    return {
      ...slideData,
      buttonTitle: slideData.button?.title,
      buttonHref: slideData.button?.href,
    };
  };

  const mapFormDataToSlideData = (formData) => {
    return {
      ...formData,
      button: {
        title: formData.buttonTitle,
        href: formData.buttonHref,
      },
    };
  };

  const handleOnSubmit = (formValue) => {
    const formattedSlideData = mapFormDataToSlideData(formValue);
    onChange(formattedSlideData);
  };

  return (
    <StatefulModal trigger={trigger} dimmer="inverted" style={{ width: '650px' }}>
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{isEdit ? 'Edit Slide' : 'Create New Slide'}</Modal.Header>
          <Modal.Content>
            <PopupSingleSlideForm
              onCancelled={closeModal}
              initialData={rawSlideDetail && mapSlideDataIntoFormData(rawSlideDetail)}
              onSubmit={(formValue) => {
                handleOnSubmit(formValue);
                closeModal();
              }}
              isEdit={isEdit}
            />
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
};

PopupSingleSlideModal.propTypes = {
  onChange: PropTypes.func,
  trigger: PropTypes.element,
  rawSlideDetail: PropTypes.string,
  isEdit: PropTypes.bool,
};

export default PopupSingleSlideModal;
