import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Segment, Embed, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';
import isUrl from 'is-url';

import { Box } from 'Components/Base';

import ActionBar from '../Common/ActionBar';
import { AssetPropTypes } from '../Common/AssetPropTypes';
import { customDurationFormat, getVideoDetails } from '../../../Util/ExternalAdapter/Api/WistiaApi';
import FormGenerator from '../../Form/FormGenerator';

const defaultVideoDetail = {
  url: '',
  previewUrl: '',
  videoDetail: '',
  thumbnail: '',
  title: '',
  totalTime: '',
};

const convertUrlToStreamingUrl = (url) => {
  const hashedId = splitHasedId(url);

  return `https://fast.wistia.net/embed/medias/${hashedId}.m3u8`;
};

const splitHasedId = (url) => {
  const urlArr = url.split('/');
  return urlArr[urlArr.length - 1];
};

const convertToRawData = (data) => {
  const { url, rawDetail } = data;
  const videoDetail = _.omit(rawDetail, ['found']);
  return {
    url: convertUrlToStreamingUrl(url),
    previewUrl: url,
    videoDetail: {
      ...videoDetail.detail,
      thumbnails: { url: videoDetail.detail.thumbnail_url },
      duration: parseInt(videoDetail.detail.duration),
    },
    videoType: 'WISTIA',
    videoKey: splitHasedId(url),
  };
};

export const extractDisplayDetail = (rawDetail) => {
  return {
    thumbnail: rawDetail.thumbnails.url,
    title: rawDetail.title,
    totalTime: customDurationFormat(rawDetail.duration),
  };
};

const convertFromRawData = (content = {}) => {
  if (content.url) {
    const { previewUrl, url, videoDetail } = content;
    return {
      url,
      previewUrl,
      videoDetail,
      ...extractDisplayDetail(videoDetail),
    };
  }
  return defaultVideoDetail;
};

const Divider = styled(Box)`
  border-top: 1px solid #d9d9d9;
`;

class WistiaVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      data: convertFromRawData(props.content),
      canSubmit: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.selectInputList = this.selectInputList.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.setState({ ...this.state, data: convertFromRawData(this.props.content), formData: {} });
    }
  }

  handleAction = () => {
    const { formData, data } = this.state;
    const resultObject = {
      ...formData,
      content: convertToRawData(data),
    };
    if (this.state.canSubmit) {
      this.props.onCreateMaterial(resultObject);
    }
  };

  selectInputList = () => {
    const { formData } = this.state;
    const { title, previewUrl } = formData;
    return [
      {
        inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
        inputLabel: 'Wistia Link',
        inputName: 'previewUrl',
        inputProps: {
          required: true,
          value: previewUrl,
        },
      },
      {
        inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
        inputLabel: 'Title',
        inputName: 'title',
        inputProps: {
          required: true,
          value: title,
        },
      },
    ];
  };

  handleFormChange = async (value) => {
    const { formData, data } = this.state;
    const { previewUrl: prevLink } = formData;
    const { previewUrl: currentLink } = value;
    const { wistiaId: prevId } = data;
    this.setState({ formData: value });
    if (prevLink !== currentLink) {
      const videoDetail = await getVideoDetails(currentLink, prevId, data);
      this.setState({
        data: { ...videoDetail, videoDetail: videoDetail.rawDetail.detail },
        formData: {
          ...this.state.formData,
          title: videoDetail.title,
        },
      });
    }
  };

  getEmbedUrl = () => {
    const html = this.state.data.videoDetail?.html;
    let src = '';
    if (html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      const iframe = div.getElementsByTagName('iframe')[0];
      src = iframe.src;
      iframe.remove();
      div.remove();
    }
    return src;
  };

  render() {
    const { newAsset, onCancel, title, loading, materialTypeString } = this.props;
    const { data: videoDetail } = this.state;
    const { previewUrl } = videoDetail;
    const inputList = this.selectInputList();
    const requiredFields = inputList
      .filter((field) => field.inputProps?.required)
      .map((field) => field.inputName);

    return (
      <Box>
        <Box>
          <FormGenerator
            fields={inputList}
            showAction={false}
            initialData={{ title, previewUrl }}
            onChange={this.handleFormChange}
            customValidation={(currentValues) => {
              this.setState({ canSubmit: false });
              const validationErrors = {};

              if (!isUrl(currentValues.previewUrl)) {
                validationErrors['previewUrl'] = 'Please enter a valid url format';
              }
              requiredFields.forEach((field) => {
                if (currentValues[field] === '') {
                  validationErrors[field] = `${field} is required`;
                }
              });
              const canSubmit = _.isEmpty(validationErrors);
              this.setState({ canSubmit });
              return validationErrors;
            }}
            name="wistia-video-form"
          />
        </Box>
        {videoDetail.thumbnail && (
          <Segment>
            <Label as="a" color="red" ribbon>
              <Icon className="clock outline" />
              {videoDetail.totalTime}
            </Label>
            <Embed placeholder={videoDetail.thumbnail} url={this.getEmbedUrl()} />
          </Segment>
        )}
        <Divider pb="3" />
        <Box>
          <ActionBar
            isCreate={newAsset}
            onAction={this.handleAction}
            onCancel={onCancel}
            loading={loading}
            materialTypeString={materialTypeString}
          />
        </Box>
      </Box>
    );
  }
}

WistiaVideo.propTypes = {
  ...AssetPropTypes,
  content: PropTypes.object.isRequired,
};

WistiaVideo.defaultProps = {
  onCreateMaterial: () => {},
  content: {},
};

export default WistiaVideo;
