import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { ButtonGroup, IconButton } from '@material-ui/core';
import { ArrowDropUp as ArrowUpIcon, ArrowDropDown as ArrowDownIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { COLOR } from 'Components/Theme';
import withFormsy from 'Components/Form/withFormsy';
import ConfirmationModal from 'Components/ConfirmationModal';
import { SemanticButton } from 'Components/Base/Button';
import { AspectRatio, BackgroundImage, Flex, Text } from 'Components/Base';

import { ANNOUNCEMENT_CONTENT_TYPE } from '../Components/constants';
import PopupSingleSlideModal from '../Containers/PopupSingleSlideModal';

const SlideButton = styled(Flex)`
  height: 60px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.g2};
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.g1};
  }
`;

const SlideButtonTitle = styled(Text)`
  min-width: 100px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const useStyles = makeStyles({
  buttonGroup: {
    '& .MuiButtonGroup-grouped': {
      minWidth: 0,
    },
    '& .MuiButtonGroup-groupedHorizontal': {
      borderRadius: 0,
    },
  },
  iconButton: {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    margin: '1px',
    '&:hover': {
      borderRadius: '6px',
    },
  },
  iconButtonWithBG: {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    margin: '1px',
    backgroundColor: COLOR.g2,
    '&:hover': {
      borderRadius: '6px',
      backgroundColor: COLOR.g3,
    },
  },
});

const PopupSlideInput = ({ onChange, value: initValue }) => {
  const classes = useStyles();

  const [slideDetails, setSlideDetails] = useState(
    initValue && initValue.type === ANNOUNCEMENT_CONTENT_TYPE.SLIDE
      ? JSON.parse(initValue.content)
      : []
  );

  const [isOpenConfirmationDeleteModal, setIsOpenConfirmationDeleteModal] = useState(false);
  const selectedIndex = useRef();

  const updateValueOnChange = useCallback(() => {
    onChange(
      {},
      { value: { content: JSON.stringify(slideDetails), type: ANNOUNCEMENT_CONTENT_TYPE.SLIDE } }
    );
  }, [slideDetails, onChange]);

  useEffect(() => {
    updateValueOnChange();
  }, [updateValueOnChange]);

  const handleDeleteSlide = (itemIndex) => {
    const updatedBannerDetails = slideDetails.filter((_, ind) => itemIndex !== ind);
    setSlideDetails(updatedBannerDetails);
  };

  const handleEditSlide = (index, slideDetail) => {
    const updatedSlides = [...slideDetails];
    updatedSlides[index] = { ...slideDetail };
    setSlideDetails(updatedSlides);
  };

  const handleAddNewSlide = (slideDetail) => {
    const updatedSlides = [...slideDetails, slideDetail];
    setSlideDetails(updatedSlides);
  };

  const handleReorderSlide = (destinationIndex, currentIndex) => {
    const updatedSlides = [...slideDetails];
    const currentSlide = slideDetails[currentIndex];
    updatedSlides[currentIndex] = updatedSlides[destinationIndex];
    updatedSlides[destinationIndex] = currentSlide;
    setSlideDetails(updatedSlides);
  };

  return (
    <>
      <Flex alignItems="center">
        <Flex flexGrow="1">{`${slideDetails.length} Item(s)`}</Flex>
        <PopupSingleSlideModal
          trigger={
            <SemanticButton as="div" primary circular>
              <Icon name="plus square" />
              Add Slide
            </SemanticButton>
          }
          onChange={handleAddNewSlide}
          isEdit={false}
        />
      </Flex>
      {slideDetails.map((detail, index) => (
        <PopupSingleSlideModal
          key={`Slide${index}`}
          trigger={
            <SlideButton flex="1 0 auto" mt={1} alignItems="center" justifyContent="space-between">
              <Flex alignItems="center">
                <AspectRatio ratio={16 / 9} width={60} mr={3}>
                  <BackgroundImage imageUrl={detail.imageUrl} bgPosition={'center'} />
                </AspectRatio>
                <SlideButtonTitle>{detail?.title || `Slide ${index + 1}`}</SlideButtonTitle>
              </Flex>
              <ButtonGroup size="small" classes={{ root: classes.buttonGroup }}>
                <Flex alignItems="center" mr={2}>
                  <IconButton
                    classes={{ root: classes.iconButton }}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      selectedIndex.current = index;
                      setIsOpenConfirmationDeleteModal(true);
                    }}
                  >
                    <Icon name="trash alternate" color="red" style={{ margin: '0' }} />
                  </IconButton>
                </Flex>
                <Flex flexDirection="column" alignItems="center">
                  {index !== 0 && (
                    <IconButton
                      classes={{ root: classes.iconButtonWithBG }}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReorderSlide(index - 1, index);
                      }}
                    >
                      <ArrowUpIcon />
                    </IconButton>
                  )}
                  {index !== slideDetails.length - 1 && (
                    <IconButton
                      classes={{ root: classes.iconButtonWithBG }}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReorderSlide(index + 1, index);
                      }}
                    >
                      <ArrowDownIcon />
                    </IconButton>
                  )}
                </Flex>
              </ButtonGroup>
            </SlideButton>
          }
          onChange={(detail) => handleEditSlide(index, detail)}
          rawSlideDetail={detail}
          isEdit={true}
        />
      ))}
      <ConfirmationModal
        variant="danger"
        isOpen={isOpenConfirmationDeleteModal}
        headerText={`Delete Slide ${selectedIndex.current + 1} ?`}
        contentText={`Are you sure to delete slide: "${
          slideDetails[selectedIndex.current]?.title ?? `Slide ${selectedIndex.current + 1}`
        }"?`}
        confirmText="Yes, delete"
        onConfirm={() => {
          handleDeleteSlide(selectedIndex.current);
          setIsOpenConfirmationDeleteModal(false);
        }}
        onDismiss={() => setIsOpenConfirmationDeleteModal(false)}
      />
    </>
  );
};

PopupSlideInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    content: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default withFormsy(PopupSlideInput);
