import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';
import moment from 'moment';

import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable, { DATA_FORMAT } from 'GraphQL/util/QueryTable';
import { classroomEnrollmentByUserIdQuery } from 'GraphQL/query/Registrar.query';
import { deleteStudentEnrollmentMutation } from 'GraphQL/mutation/Registrar.mutation';
import { CLASSROOM_MODE } from 'Routes/Courses/EditCourse/Enrollment/constant';
import ConfirmationButton from 'Components/ConfirmationButton';

const unsortedColumns = ['courseName', 'courseCode', 'courseOwner', 'action'];
const getEnrollmentColumns = (handleOnDelete, variables) => [
  // {
  //   Header: 'Course ID',
  //   accessor: 'courseId',
  //   type: COLUMN_TYPE.STRING,
  //   isSearchAble: true,
  //   Cell: ({ original }) => {
  //     const courseId = original.course.id;
  //     return courseId;
  //   },
  // },
  {
    Header: 'Course Name',
    accessor: 'courseName',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      const courseName = original.course.name;
      return courseName;
    },
  },
  {
    Header: 'Course Code',
    accessor: 'courseCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    Cell: ({ original }) => {
      const courseCode = original.course.code;
      return courseCode;
    },
  },
  {
    Header: 'Study Progress',
    accessor: 'studyProgress',
    type: COLUMN_TYPE.NUMBER,
    dataFormat: DATA_FORMAT.NUMBER_FORMAT_PERCENT,
    isSearchAble: false,
    Cell: ({ original }) => {
      const studyProgress = original.studyProgress;
      return studyProgress ? Number((studyProgress * 100).toFixed(2)) : 0.0;
    },
  },
  {
    Header: 'Enrollment Date',
    accessor: 'createdAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
    Cell: ({ original }) => {
      const enrolledAt = original.createdAt;
      return enrolledAt ? moment(enrolledAt).format('DD MMM YYYY HH:mm:ss') : null;
    },
  },
  {
    Header: 'Expiry Date',
    accessor: 'expiryDate',
    type: COLUMN_TYPE.DATE,
    isSearchAble: true,
    Cell: ({ original }) => {
      const expiryDate = original.expiryDate;
      return expiryDate ? moment(expiryDate).format('DD MMM YYYY HH:mm:ss') : null;
    },
  },
  {
    Header: 'Deleted Date',
    accessor: 'deletedAt',
    type: COLUMN_TYPE.DATE,
    isSearchAble: false,
  },
  {
    Header: 'Action',
    accessor: 'action',
    width: 100,
    isSearchAble: false,
    // eslint-disable-next-line react/display-name
    Cell: ({ original }) => {
      const {
        id: classroomId,
        user: { id: userId },
        deletedAt,
      } = original;
      if (deletedAt === null) {
        return (
          <ConfirmationButton
            headerText={`Delete?`}
            contentText={'This classroom enrollment will be permanently deleted from the system.'}
            confirmText={'Delete'}
            onConfirm={() => {
              handleOnDelete(userId, classroomId);
            }}
          >
            <Icon name="trash alternate outline" />
          </ConfirmationButton>
        );
      }
      return null;
    },
  },
];
class EnrollmentListGQL extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'courseId', type: 'DESC' },
  };

  resolveData = (rawData) => {
    const data = rawData['classroomEnrollmentByUserId']['classroomEnrollments'];
    return data;
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    if (unsortedColumns.indexOf(sorted[0].id) !== -1) return;
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  handleOnDelete = async (userId, classroomId) => {
    const { search, paging, order } = this.state;
    const { client } = this.props;
    try {
      await client.mutate({
        mutation: deleteStudentEnrollmentMutation,
        variables: { userId, classroomId },
        refetchQueries: [
          {
            query: classroomEnrollmentByUserIdQuery,
            variables: { search: { ...search, userId, mode: CLASSROOM_MODE.FULL }, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { userId } = this.props;
    const { search, paging, order } = this.state;
    const variables = { search, paging, order, userId };

    return (
      <Container>
        <QueryTable
          showSearchPanel={true}
          type="classroomEnrollmentByUserId"
          columns={getEnrollmentColumns(this.handleOnDelete, variables)}
          query={classroomEnrollmentByUserIdQuery}
          search={{ ...search, userId, mode: CLASSROOM_MODE.FULL }}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
          resolveData={this.resolveData}
          alignItems="center"
        />
      </Container>
    );
  }
}

export default withApollo(EnrollmentListGQL);
