export const CATEGORY_TYPE = {
  // These are permalinks
  CATEGORY_1: 'category1',
  CATEGORY_2: 'category2',
  CATEGORY_3: 'category3',
  CATEGORY_4: 'category4',
};

export const SKU_VISIBILITY = {
  VISIBLE: 'VISIBLE',
  UNLISTED: 'UNLISTED',
};

export const SKU_STATUS = {
  //กดซื้อไม่ได้ ขายไม่ได้
  COMING_SOON: 'COMING_SOON',
  //กดซื้อได้ + ขายได้
  SELLING: 'SELLING',
  //บังคับให้ Soldout + ขายไม่ได้
  SOLDOUT: 'SOLDOUT',
  //ไม่เห็นในหน้า browser + ซื้อไม่ได้ + ขายไม่ได้
  UNPUBLISHED: 'UNPUBLISHED',
};

export const SKU_VISIBILITY_SEARCH_OPTIONS = [
  {
    key: SKU_VISIBILITY.VISIBLE,
    value: true,
    text: 'Visible',
  },
  {
    key: SKU_VISIBILITY.UNLISTED,
    value: false,
    text: 'Unlisted',
  },
];

export const SKU_STATUS_SEARCH_OPTIONS = [
  { key: SKU_STATUS.COMING_SOON, value: SKU_STATUS.COMING_SOON, text: 'Coming Soon' },
  { key: SKU_STATUS.SELLING, value: SKU_STATUS.SELLING, text: 'Selling' },
  { key: SKU_STATUS.SOLDOUT, value: SKU_STATUS.SOLDOUT, text: 'Sold Out' },
  { key: SKU_STATUS.UNPUBLISHED, value: SKU_STATUS.UNPUBLISHED, text: 'Unpublished' },
];

export const COLLECTION_STATUS_SEARCH_OPTIONS = [
  { key: SKU_STATUS.COMING_SOON, value: SKU_STATUS.COMING_SOON, text: 'Coming Soon' },
  { key: SKU_STATUS.SELLING, value: SKU_STATUS.SELLING, text: 'Selling' },
  { key: SKU_STATUS.UNPUBLISHED, value: SKU_STATUS.UNPUBLISHED, text: 'Unpublished' },
];

export const SKU_STATUS_OPTIONS = [
  {
    key: `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.SELLING}`,
    value: `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.SELLING}`,
    text: 'Visible / Selling',
  },
  {
    key: `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.COMING_SOON}`,
    value: `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.COMING_SOON}`,
    text: 'Visible / Coming Soon',
  },
  {
    key: `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.SOLDOUT}`,
    value: `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.SOLDOUT}`,
    text: 'Visible / Cannot Sell',
  },
  {
    key: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.SELLING}`,
    value: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.SELLING}`,
    text: 'Unlisted / Selling',
  },
  {
    key: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.COMING_SOON}`,
    value: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.COMING_SOON}`,
    text: 'Unlisted / Coming Soon',
  },
  {
    key: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.SOLDOUT}`,
    value: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.SOLDOUT}`,
    text: 'Unlisted / Cannot Sell',
  },
  {
    key: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.UNPUBLISHED}`,
    value: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.UNPUBLISHED}`,
    text: 'Unpublished',
  },
];

export const COLLECTION_STATUS_OPTIONS = [
  {
    key: `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.SELLING}`,
    value: `${SKU_VISIBILITY.VISIBLE}/${SKU_STATUS.SELLING}`,
    text: 'Published',
  },
  {
    key: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.COMING_SOON}`,
    value: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.COMING_SOON}`,
    text: 'Unlisted / Coming Soon',
  },
  {
    key: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.UNPUBLISHED}`,
    value: `${SKU_VISIBILITY.UNLISTED}/${SKU_STATUS.UNPUBLISHED}`,
    text: 'Unpublished',
  },
];

export const ONLINE_COURSE_CONDITION = {
  DAYS_VALIDITY: 'DAYS_VALIDITY',
  EXPIRE_DATE: 'EXPIRE_DATE', // Not being used now
};

export const SUBSCRIPTION_CONDITION = {
  DAYS_VALIDITY: 'DAYS_VALIDITY',
  EXPIRE_DATE: 'EXPIRE_DATE', // Not being used now
};

export const SUBSCRIPTION_CONDITION_OPTIONS = [
  {
    key: SUBSCRIPTION_CONDITION.DAYS_VALIDITY,
    value: SUBSCRIPTION_CONDITION.DAYS_VALIDITY,
    text: 'Day Limit',
  },
];

export const SUBSCRIPTION_PLATFORM = {
  UNSELECT: null,
  IOS: 'IOS',
  ANDROID: 'ANDROID',
};

export const SUBSCRIPTION_PLATFORM_OPTIONS = [
  {
    key: 'UNSELECT',
    value: SUBSCRIPTION_PLATFORM.UNSELECT,
    text: 'NONE',
  },
  {
    key: SUBSCRIPTION_PLATFORM.IOS,
    value: SUBSCRIPTION_PLATFORM.IOS,
    text: 'iOS',
  },
  {
    key: SUBSCRIPTION_PLATFORM.ANDROID,
    value: SUBSCRIPTION_PLATFORM.ANDROID,
    text: 'Android',
  },
];

export const CATEGORY_STATUS = {
  VISIBLE: 'VISIBLE',
  INVISIBLE: 'INVISIBLE',
};

export const CATEGORY_STATUS_OPTIONS = [
  { key: CATEGORY_STATUS.VISIBLE, value: CATEGORY_STATUS.VISIBLE, text: 'Visible' },
  { key: CATEGORY_STATUS.INVISIBLE, value: CATEGORY_STATUS.INVISIBLE, text: 'Invisible' },
];

export const SKU_TYPE = {
  /**
   * Base SKUs are parts included on bundles, and collections.
   * Base SKUs cannot be sold as standalone products.
   */
  BASE: 'BASE',

  /**
   * A standard product.
   * Standard SKUs may be sold individually or as parts of  bundles, or collections.
   */
  STANDARD: 'STANDARD',

  /**
   * A collection of associated SKUs linked for marketing purposes to upsell or cross-sell related products.
   * The SKU set to Collection acts as a sort of parent SKU for all the associated SKUs. However, the Collection SKU itself cannot actually be sold.
   */
  COLLECTION: 'COLLECTION',

  /**
   * A product that includes associated SKUs.
   * To sell bundle SKUs, all needed quantities of all associated SKUs must be available.
   */
  BUNDLE: 'BUNDLE',

  /**
   * A SKU which does not need to be physically fulfilled, such as a magazine subscription or gift wrapping fee
   * These SKUs do not require inventory, and operate with an inventory level of 0
   * Virtual SKUs do not sync catalog presently, but will associate with orders that import with a matching SKU.
   */
  VIRTUAL: 'VIRTUAL',
};

export const SKU_CATEGORY = {
  WORKSHOP: 'WORKSHOP',
  WORKSHOP_BATCH: 'WORKSHOP_BATCH',
  ONLINE_COURSE: 'ONLINE_COURSE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  COLLECTION: 'COLLECTION',
  BOOK: 'BOOK',
};

export const SKU_CATEGORY_TEXT = {
  [SKU_CATEGORY.WORKSHOP]: 'Workshop',
  [SKU_CATEGORY.WORKSHOP_BATCH]: 'Workshop batch',
  [SKU_CATEGORY.ONLINE_COURSE]: 'Online course',
  [SKU_CATEGORY.SUBSCRIPTION]: 'Subscription',
  [SKU_CATEGORY.COLLECTION]: 'Collection',
  [SKU_CATEGORY.BOOK]: 'Book',
};

export const PRODUCT_TYPE = {
  WORKSHOP: 'WORKSHOP',
  WORKSHOP_BATCH: 'WORKSHOP_BATCH',
  BUNDLE: 'BUNDLE',
  ONLINE_COURSE: 'ONLINE_COURSE',
  ONLINE_COURSE_BATCH: 'ONLINE_COURSE_BATCH',
  SUBSCRIPTION: 'SUBSCRIPTION',
  COLLECTION: 'COLLECTION',
  BOOK: 'BOOK',
};

export const PRODUCT_TYPE_TEXT = {
  [PRODUCT_TYPE.WORKSHOP]: 'Workshop',
  [PRODUCT_TYPE.WORKSHOP_BATCH]: 'Workshop batch',
  [PRODUCT_TYPE.BUNDLE]: 'Bundle',
  [PRODUCT_TYPE.ONLINE_COURSE]: 'Online course',
  [PRODUCT_TYPE.ONLINE_COURSE_BATCH]: 'Online course batch',
  [PRODUCT_TYPE.SUBSCRIPTION]: 'Subscription',
  [PRODUCT_TYPE.COLLECTION]: 'Collection',
  [PRODUCT_TYPE.BOOK]: 'Book',
};

export const SKU_CATEGORY_OPTIONS = [
  { key: SKU_CATEGORY.WORKSHOP, value: SKU_CATEGORY.WORKSHOP, text: 'Workshop' },
  { key: SKU_CATEGORY.WORKSHOP_BATCH, value: SKU_CATEGORY.WORKSHOP_BATCH, text: 'Workshop Batch' },
  { key: SKU_CATEGORY.ONLINE_COURSE, value: SKU_CATEGORY.ONLINE_COURSE, text: 'Online Course' },
];
