export const CourseTeamPermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'courseTeam.list-view',
    UPDATE: 'courseTeam.list-update',
    DELETE: 'courseTeam.list-delete',
  },
  OVERVIEW: {
    VIEW: 'courseTeam.overview-view',
    UPDATE: 'courseTeam.overview-update',
    DELETE: 'courseTeam.overview-delete',
  },
  ENROLLMENT: {
    VIEW: 'courseTeam.enrollment-view',
    UPDATE: 'courseTeam.enrollment-update',
    DELETE: 'courseTeam.enrollment-delete',
  },
  EXAM: {
    VIEW: 'courseTeam.exam-view',
    UPDATE: 'courseTeam.exam-update',
    DELETE: 'courseTeam.exam-delete',
  },
  LESSON: {
    VIEW: 'courseTeam.lesson-view',
    UPDATE: 'courseTeam.lesson-update',
    DELETE: 'courseTeam.lesson-delete',
  },
  ASSIGNMENT: {
    VIEW: 'courseTeam.assignment-view',
    UPDATE: 'courseTeam.assignment-update',
    DELETE: 'courseTeam.assignment-delete',
  },
  ANNOUNCEMENT: {
    VIEW: 'courseTeam.announcement-view',
    UPDATE: 'courseTeam.announcement-update',
    DELETE: 'courseTeam.announcement-delete',
  },
});
