import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Modal } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';

import { FILE_TYPE } from '../../common';

import CreateIndividualReportContainer from './Containers/CreateIndividualReport.gql';

export default class CreateIndividualReportModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.oneOf(Object.values(FILE_TYPE)).isRequired,
  };

  static defaultProps = {
    title: 'Create New Individual Report',
  };

  render() {
    const { id, trigger, variables, title, type } = this.props;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
              <CreateIndividualReportContainer
                type={type}
                variables={variables}
                id={id}
                onComplete={closeModal}
                onCancelled={closeModal}
                {...this.props}
              />
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
