import gql from 'graphql-tag';

export const FileImportJobSmallFragment = gql`
  fragment FileImportJobSmallFragment on SFBoSearchFileImportJobResult {
    id
    type
    file {
      key
      bucket
    }
    originalFilename
    status
    createdAt
    arg1
    error
    result {
      resultSummaries {
        # rowNo
        # property
        # constraints
        # target
        value
        type
      }
      validationErrors {
        # rowNo
        # property
        # constraints
        # target
        value
      }
      file {
        key
        bucket
      }
    }
  }
`;

export const getFileImportJobs = gql`
  query sfBoFileImportJobs(
    $search: SFBoSearchFileImportJobInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoFileImportJobs(search: $search, paging: $paging, order: $order) {
      total
      fileImportJobs {
        ...FileImportJobSmallFragment
      }
    }
  }
  ${FileImportJobSmallFragment}
`;

export const getFileImportJob = gql`
  query sfBoFileImportJob($id: ID!) {
    sfBoFileImportJob(id: $id) {
      id
      type
      file {
        key
        bucket
      }
      originalFilename
      status
      createdAt
      arg1
      error
      result {
        resultSummaries {
          # rowNo
          property
          constraints
          target
          value
          type
        }
        validationErrors {
          # rowNo
          property
          constraints
          target
          value
        }
        file {
          key
          bucket
        }
      }
    }
  }
`;
