export const STATUS = {
  PUBLISHED: 'PUBLISHED',
  SCHEDULED: 'SCHEDULED',
  DRAFT: 'DRAFT',
};

export const ANNOUNCEMENT_CATEGORY = {
  NEWS: 'NEWS',
  REMINDER: 'REMINDER',
  BROWSE_BANNERS: 'BROWSE_BANNERS',
  NEW_CONTENT: 'NEW_CONTENT',
  MARKETING: 'MARKETING',
  POPUP: 'POPUP',
};

export const ANNOUNCEMENT_INPUT_LABEL = {
  BANNER: 'Banner',
  DESCRIPTION: 'Description',
  SLIDE: 'Slide',
};

export const RELATION_TYPE = {
  GLOBAL: 'GLOBAL',
  COURSE: 'COURSE',
  PROVIDER: 'PROVIDER',
  USER: 'USER',
};

export const ANNOUNCEMENT_CONTENT_TYPE = {
  HTML: 'html',
  MARKDOWN: 'markdowm',
  BANNER: 'banner',
  SLIDE: 'slide',
};

export const ANNOUNCEMENT_FILE_IMPORT_STATUS = {
  SUCCESS: 'SUCCESS',
  IN_PROCESS: 'IN_PROCESS',
  FAILED: 'FAILED',
};
