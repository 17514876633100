import { MENU_AUTH_HIDE, MENU_AUTH_SHOW } from './actions';

const menu = (state = { authVisibility: true }, action) => {
  switch (action.type) {
    case MENU_AUTH_HIDE:
      return { ...state, authVisibility: false };
    case MENU_AUTH_SHOW:
      return { ...state, authVisibility: true };
    default:
      return state;
  }
};

export default menu;
