import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const addNewTagDefault = (tagText) => ({ name: tagText, id: tagText });

export default class TagSelectorInput extends Component {
  static propTypes = {
    tags: PropTypes.array,
    value: PropTypes.array,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func,
    onAddNewTag: PropTypes.func,
    allowAdditions: PropTypes.bool,
    fluid: PropTypes.bool,
    multiple: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    onAddNewTag: addNewTagDefault,
    onChange: () => {},
    allowAdditions: false,
    fluid: false,
    multiple: true,
    placeholder: 'Choose Tag(s)',
  };

  state = {
    options: this.props.tags,
    currentValue: this.props.defaultValue ? this.props.defaultValue : this.props.value,
  };

  handleAddition = async (e, { value }) => {
    const newTag = await this.props.onAddNewTag(value);
    this.setState({
      options: [{ key: newTag.id, text: newTag.name, value: newTag.id }, ...this.state.options],
    });
  };

  handleChange = (e, { value }) => {
    // If there is defaultValue, do the uncontrolled state
    if (this.props.defaultValue) {
      this.setState({ currentValue: value }, () => this.props.onChange(value));
    } else {
      this.props.onChange(value);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ currentValue: nextProps.value });
    }
  }

  render() {
    const { fluid, multiple, allowAdditions, placeholder } = this.props;
    const { currentValue, options } = this.state;
    return (
      <Dropdown
        options={options}
        placeholder={placeholder}
        search
        multiple={multiple}
        selection
        fluid={fluid}
        allowAdditions={allowAdditions}
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      />
    );
  }
}
