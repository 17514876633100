import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button as SemanticButtonView, Icon } from 'semantic-ui-react';
import json2csv from 'json2csv';
import { CSVLink } from 'react-csv';

import { Box } from 'Components/Base';

const getExportData = (data, options = {}) => {
  return json2csv.parse(data, options);
};

const ExportFile = ({ data, loading, onClickGenerate, onClickExport }) => {
  const haveData = data !== null && data !== undefined && data.data;
  const downloadedData = haveData ? getExportData(data.data, data.options) : [];

  return (
    <Fragment>
      <Box>
        {!haveData ? (
          <SemanticButtonView
            type="button"
            circular
            onClick={onClickGenerate}
            filename={'tabledata.csv'}
            style={{ marginBottom: '1em' }}
            loading={loading}
          >
            <Icon name="file archive outline" />
            Generate CSV
          </SemanticButtonView>
        ) : (
          <SemanticButtonView
            circular
            disabled={downloadedData.length === 0}
            onClick={onClickExport}
            as={CSVLink}
            data={downloadedData}
            filename={'tabledata.csv'}
            style={{ marginBottom: '1em' }}
            mr={3}
            color="green"
          >
            <Icon name="arrow down" />
            Download CSV
          </SemanticButtonView>
        )}
      </Box>
    </Fragment>
  );
};

ExportFile.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.array.isRequired,
    options: PropTypes.shape({
      fields: PropTypes.array,
    }),
  }),
  loading: PropTypes.bool,
  onClickGenerate: PropTypes.func,
  onClickExport: PropTypes.func,
};

ExportFile.defaultProps = {
  data: null,
};

export default ExportFile;
