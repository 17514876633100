export const CollectionPermissionKeys = Object.freeze({
  LIST: {
    VIEW: 'product.collection.list-view',
    UPDATE: 'product.collection.list-update',
    DELETE: 'product.collection.list-delete',
  },
  OVERVIEW: {
    VIEW: 'product.collection.overview-view',
    UPDATE: 'product.collection.overview-update',
    DELETE: 'product.collection.overview-delete',
  },
  ITEMS: {
    VIEW: 'product.collection.items-view',
    UPDATE: 'product.collection.items-update',
    DELETE: 'product.collection.items-delete',
  },
});
