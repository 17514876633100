import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import FormGenerator from 'Components/Form/FormGenerator';

import Editor from '../MarkupTextInput';

const noop = () => {};

const genFields = (accessor) => {
  return [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: accessor.title,
      inputName: accessor.title,
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: accessor.description,
      inputName: accessor.description,
      customInput: ({ name }) => <Editor name={name} />,
    },
  ];
};

export default class SectionContent extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
    }),
    onUpdateMeta: PropTypes.func,
    onClose: PropTypes.func,
    onFormDirty: PropTypes.func,
    accessor: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    onUpdateMeta: noop,
    onClose: noop,
    onFormDirty: noop,
    accessor: { title: 'title', description: 'description' },
  };

  render() {
    const { title, description, onUpdateMeta, onClose, onFormDirty, accessor } = this.props;
    return (
      <Flex flexDirection="column">
        <Segment vertical>
          <FormGenerator
            fields={genFields(accessor)}
            submitText="Update Section"
            onSubmit={onUpdateMeta}
            // showCancel
            cancelText="Reset"
            onCancelled={onClose}
            initialData={{ [accessor.title]: title, [accessor.description]: description }}
            onChange={onFormDirty}
            compact
          />
        </Segment>
      </Flex>
    );
  }
}
