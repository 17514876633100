import gql from 'graphql-tag';

import { TEAM_FRAGMENT } from '../query/Team.query';

const UPDATE_TEAM = gql`
  mutation updateTeam($id: ID!, $data: TeamEditInput!) {
    updateTeam(id: $id, teamData: $data) {
      ...TEAM_FRAGMENT
    }
  }
  ${TEAM_FRAGMENT}
`;

const CREATE_TEAM = gql`
  mutation createTeam($data: TeamNewInput!) {
    createTeam(teamData: $data) {
      ...TEAM_FRAGMENT
    }
  }
  ${TEAM_FRAGMENT}
`;

const DELETE_TEAM = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      ...TEAM_FRAGMENT
    }
  }
  ${TEAM_FRAGMENT}
`;

const ASSIGN_TEAM_TO_USERS = gql`
  mutation assignUsersToTeam($userIds: [ID!]!, $teamId: ID!) {
    assignUsersToTeam(userIds: $userIds, teamId: $teamId) {
      id
      username
      firstname
      lastname
      email
    }
  }
`;

const ASSIGN_TEAMS_TO_USER = gql`
  mutation assignTeamsToUser($userId: ID!, $teamIds: [ID!]!) {
    assignTeamsToUser(userId: $userId, teamIds: $teamIds) {
      ...TEAM_FRAGMENT
    }
  }
  ${TEAM_FRAGMENT}
`;

const UNASSIGN_TEAM = gql`
  mutation unassignUserFromTeam($userId: ID!, $teamId: ID!) {
    unassignUserFromTeam(userId: $userId, teamId: $teamId) {
      id
      username
      firstname
      lastname
      email
    }
  }
`;

const UNASSIGN_TEAM_FROM_USERS = gql`
  mutation unassignUsersFromTeam($userIds: [ID!]!, $teamId: ID!) {
    unassignUsersFromTeam(userIds: $userIds, teamId: $teamId) {
      id
      username
      firstname
      lastname
      email
    }
  }
`;

export {
  UPDATE_TEAM,
  CREATE_TEAM,
  DELETE_TEAM,
  ASSIGN_TEAM_TO_USERS,
  ASSIGN_TEAMS_TO_USER,
  UNASSIGN_TEAM_FROM_USERS,
  UNASSIGN_TEAM,
};
