import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import { useAppConfig } from 'Util/hoc/withAppConfig';

import FormGenerator from '../../Form/FormGenerator';
import { AssetPropTypes } from '../Common/AssetPropTypes';
import ActionBar from '../Common/ActionBar';

import PassingCriteriaInput from './PassingCriteriaInput';
import TimeLimitInput from './TimeLimitInput';
import ResultOptionsInput from './ResultOptionsInput';
import { EXAM_SCORE_TYPE, TAG_CATEGORY, EXAM_TYPE_TAG } from './constants';

const extractExamTypeFromTags = (exam) => {
  const tags = exam?.tags ?? [];
  const examTypeTag = tags.find((tag) => tag.category === TAG_CATEGORY.EXAM_TYPE);
  return examTypeTag?.slug;
};

const generateInputList = (exams, currentExamData = {}, options) => {
  const examType = extractExamTypeFromTags(currentExamData);
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Title',
      inputName: 'title',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Description',
      inputName: 'description',
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'ชุดข้อสอบ',
      inputName: 'examId',
      inputProps: {
        required: true,
      },
      options: exams.map(({ id, title }) => ({
        text: title,
        value: id,
      })),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'จำนวนครั้งที่สามารถทำได้',
      inputName: 'maxAttempts',
      inputProps: {
        required: true,
        min: 1,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'เกณฑ์ผ่าน',
      inputName: 'passingCriteria',
      inputProps: {
        required: true,
      },
      customInput: Object.assign((props) => <PassingCriteriaInput {...props} />, {
        displayName: 'PassingCriteriaInput',
      }),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'เวลา',
      inputName: 'timeLimit',
      inputProps: {
        required: true,
      },
      customInput: Object.assign((props) => <TimeLimitInput {...props} />, {
        displayName: 'TimeLimitInput',
      }),
    },
  ];

  const hiddenFields = [
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'จำนวนข้อทั้งหมด',
      inputName: 'totalQuestions',
      hidden: true,
    },
  ];

  const { examConfig } = options ?? {};
  if (
    examConfig.enabledOfficialExam &&
    examType &&
    examType.toLowerCase() === EXAM_TYPE_TAG.OFFICIAL_EXAM.toLowerCase()
  ) {
    const officialExamFields = [
      {
        inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
        inputLabel: 'แสดงเฉลย/ผลสอบ',
        inputName: 'resultOptions',
        customInput: Object.assign((props) => <ResultOptionsInput {...props} />, {
          displayName: 'ResultOptionsInput',
        }),
      },
    ];
    fields.push(...officialExamFields);
  }

  fields.push(...hiddenFields);

  return fields;
};

/**
 * @typedef QuizAssetMetadata
 * @property {string} examId ID of the exam that linked to this quiz
 * @property {number} maxAttempts Maximum times that users are allowed to do this quiz
 * @property {object} passingCriteria
 * @property {string} passingCriteria.type Type of criteria, each type might use different measurement
 * @property {number} passingCriteria.value Baseline value of the measurement for determining whether users pass this quiz
 * @property {number} timeLimit Total time for users to finish the quiz after the session has started (in seconds)
 * @property {number} totalQuestions Total questions in the quiz
 * @property {object} resultOptions
 * @property {string} resultOptions.isShow
 * @property {number} resultOptions.showAfter
 */

/**
 * @typedef QuizAsset
 * @property {string} title
 * @property {string} description
 * @property {QuizAssetMetadata} content
 */

const defaultContent = {
  examId: '',
  maxAttempts: '1',
  passingCriteria: {
    type: EXAM_SCORE_TYPE.SCORE,
    value: '0',
  },
  timeLimit: '0',
  totalQuestions: null,
  resultOptions: {
    isShow: true,
    showAfter: null,
  },
};

function mapQuizAssetToFormValue(quizAsset) {
  const { title, description, content } = quizAsset;
  return {
    title,
    description,
    ...content,
  };
}

function mapFormValueToQuizAsset(formValue, exam) {
  const { title, description, ...content } = formValue;
  return {
    title,
    description,
    content: {
      ...content,
      maxAttempts: parseInt(content.maxAttempts),
      fullScore: exam?.fullScore ?? null,
      tags: exam?.tags ?? [],
    },
  };
}

export default function Quiz(props) {
  const {
    title = '',
    description = '',
    content = {},
    materialTypeString,
    newAsset,
    loading,
    onCancel,
    onCreateMaterial,
    exams,
  } = props;
  const formElement = useRef(null);

  const appConfig = useAppConfig();
  const inputOptions = {
    examConfig: appConfig?.exam ?? {},
  };
  const [fields, setFields] = useState(generateInputList(exams, content, inputOptions));
  /** @type [QuizAsset, function(QuizAsset)] */
  const [quizAsset, setQuizAsset] = useState({
    title,
    description,
    content: { ...defaultContent, ...content },
  });

  const handleFormValueChange = (formValue) => {
    const isExamIdChanged = formValue.examId !== quizAsset.content.examId;

    if (isExamIdChanged) {
      const exam = exams.find((mockExam) => mockExam.id === formValue.examId);

      // As of now, once one of inputs is changed, this function triggered at least twice with the same `formValue`.
      // Because of `isExamIdChanged`, when we select new exam,
      // the second trigger would override `passingCriteria`, `timeLimit` and `resultOptions` that we set below, by the old `formValue`.
      //
      // To prevent these two from getting overridden by the second trigger, we force update the form value
      // so, on the second trigger, the `passingCriteria` , `timeLimit` and `resultOptions` inside the `formValue` would have been updated to the current one
      formValue.passingCriteria = exam?.metadata?.passingCriteria ?? formValue.passingCriteria;
      formValue.resultOptions = exam?.metadata?.resultOptions ?? formValue.resultOptions;
      formValue.timeLimit = exam?.metadata?.timeLimit ?? formValue.timeLimit;
      formValue.totalQuestions = exam?.totalQuestions ?? formValue.totalQuestions;
      formElement.current.getForm().resetModel(formValue);
    }

    const exam = exams.find((mockExam) => mockExam.id === formValue.examId);

    setFields(generateInputList(exams, exam, inputOptions));
    setQuizAsset({
      ...mapFormValueToQuizAsset(formValue, exam),
    });
  };

  const handleSubmit = () => {
    onCreateMaterial(quizAsset);
  };

  return (
    <Box>
      <FormGenerator
        fields={fields}
        showAction={false}
        initialData={mapQuizAssetToFormValue(quizAsset)}
        onChange={handleFormValueChange}
        onSubmit={handleSubmit}
        ref={formElement}
        dividers={[{ key: 'resultOptions', text: 'สำหรับ Official Exam' }]}
      />
      <Box mt={2}>
        <ActionBar
          isCreate={newAsset}
          onAction={(event) => formElement.current.getForm().submit(event)}
          materialTypeString={materialTypeString}
          onCancel={onCancel}
          loading={loading}
        />
      </Box>
    </Box>
  );
}

export const propTypes = {
  ...AssetPropTypes,
  content: PropTypes.shape({
    examId: PropTypes.string,
    maxAttempts: PropTypes.number,
    passingCriteria: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.number,
    }),
    timeLimit: PropTypes.number,
    totalQuestions: PropTypes.number,
    resultOptions: PropTypes.shape({
      isShow: PropTypes.bool,
      showAfter: PropTypes.string,
    }),
  }),
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      metadata: PropTypes.shape({
        timeLimit: PropTypes.number,
        totalQuestions: PropTypes.number,
        passingCriteria: PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.number,
        }),
        resultOptions: PropTypes.shape({
          isShow: PropTypes.bool,
          showAfter: PropTypes.string,
        }),
      }),
    })
  ),
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
};

Quiz.propTypes = propTypes;
