import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';

import { getMindstampVideos } from 'GraphQL/query/Video.query';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';

import { textDurationToSeconds, secondsToTextDuration } from './durationConverter';
import MindstampVideoForm from './MindstampVideoForm';
import { MINDSTAMP_URL_REGEX } from './constants';

function genPreviewUrlWithInfo(baseVideoUrl) {
  const url = new URL(baseVideoUrl);
  // when preview Mindstamp, Mindstamp will record viewer info
  // specific info to separate between user and admin (ref: https://mindstamp.readme.io/reference/variables)
  url.searchParams.append('name', 'backoffice');
  url.searchParams.append('custom_id', 'backoffice');
  return url.toString();
}

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {MaterialRawContent} props.content
 * @param {boolean} props.newAsset
 */
function MindstampVideo({
  title,
  description,
  content,
  newAsset,
  onCancel,
  loading,
  materialTypeString,
  onCreateMaterial,
}) {
  const { url, previewUrl, videoKey, videoDetail } = content ?? {};
  const { duration: videoDuration, thumbnails } = videoDetail ?? {};

  /** @type {React.MutableRefObject<import('formsy-react').default>} */
  const form = useRef();
  const previousVideoUrl = useRef(previewUrl);
  const videoDetailNotInForm = useRef({ url, videoKey, thumbnails });

  const [previewVideo, setPreviewVideo] = useState({
    url: previewUrl ? genPreviewUrlWithInfo(previewUrl) : undefined,
    thumbnailUrl: thumbnails?.url,
  });
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);

  const { refetch } = useQuery(getMindstampVideos, { skip: true });

  const handleFormChange = async (formValue) => {
    if (
      previousVideoUrl.current !== formValue.previewUrl &&
      formValue.previewUrl.match(MINDSTAMP_URL_REGEX)
    ) {
      setIsVideoLoading(true);
      const videoUrl = formValue.previewUrl;
      const videoToken = videoUrl.match(MINDSTAMP_URL_REGEX)[3];

      try {
        const response = await refetch({ token: videoToken });
        const videos = response.data?.mindstampVideos;

        if (videos && videos.length) {
          const video = videos[0];
          const durationText = secondsToTextDuration(video.duration);
          form.current.updateInputsWithValue({
            title: video.title,
            duration: durationText,
          });
          setPreviewVideo({
            url: genPreviewUrlWithInfo(video.embed_url),
            thumbnailUrl: video.thumbnail,
            duration: durationText,
          });
          videoDetailNotInForm.current = {
            url: video.embed_url,
            videoKey: video.token,
            thumbnails: { url: video.thumbnail },
          };
        } else {
          // not found video by token of video URL
          form.current.updateInputsWithError({ previewUrl: 'Not found this video' }, true);
        }
      } catch (error) {
        // cannot query Mindstamp API maybe exceed rate limited
        // https://mindstamp.readme.io/reference/getting-started-with-your-api#rate-limiting-and-pagination
        setIsWarningOpen(true);
        setPreviewVideo({ url: genPreviewUrlWithInfo(videoUrl), thumbnailUrl: '' });
        videoDetailNotInForm.current = {
          url: videoUrl,
          videoKey: videoToken,
        };
      }

      previousVideoUrl.current = videoUrl;
      setIsVideoLoading(false);
    }
  };

  const debouncedHandleFormChange = useCallback(debounce(handleFormChange, 250), []);

  const handleSubmit = (formValue) => {
    const { url, videoKey, thumbnails } = videoDetailNotInForm.current;
    const duration = textDurationToSeconds(formValue.duration);
    const videoData = {
      title: formValue.title,
      description: formValue.description,
      content: {
        url,
        previewUrl: formValue.previewUrl,
        videoDetail: { duration, thumbnails },
        videoType: 'MINDSTAMP',
        videoKey,
      },
    };
    onCreateMaterial(videoData);
  };

  return (
    <>
      <MindstampVideoForm
        ref={form}
        initialData={{
          previewUrl: content?.previewUrl,
          title,
          description,
          videoDuration: videoDuration ? secondsToTextDuration(videoDuration) : '00:00:00',
        }}
        previewVideo={previewVideo}
        isVideoLoading={isVideoLoading}
        loading={loading}
        onFormChange={debouncedHandleFormChange}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        buttonText={newAsset ? `Create ${materialTypeString}` : `Update ${materialTypeString}`}
      />
      <Snackbar
        variant={VARIANTS.WARNING}
        message="Cannot query video from Mindstamp API"
        isOpen={isWarningOpen}
        onClose={() => {
          setIsWarningOpen(false);
        }}
      />
    </>
  );
}

MindstampVideo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.object,
  newAsset: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  materialTypeString: PropTypes.string,
  onCreateMaterial: PropTypes.func,
};

export default MindstampVideo;

/**
 * @typedef MaterialRawContent
 * @property {string} previewUrl
 * @property {string} url
 * @property {string} videoKey (Mindstamp video token)
 * @property {string} videoType
 * @property {Object} videoDetail
 * @property {number} videoDetail.duration (in seconds)
 * @property {Object} videoDetail.thumbnails
 * @property {string} videoDetail.thumbnails.url
 */
