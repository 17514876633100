import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Checkbox, Radio } from 'semantic-ui-react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import { Flex } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

import { RELATION_TYPE } from './constants';
import PopupAudienceFileUploadInput from './PopupAudienceFileUploadInput';

const Wrapper = styled(Flex)`
  min-width: 150px;
`;

/**
 * CourseCode = Audience = RelationID
 * @param {string} initValue - audiences | ex. {relationIdList: ["COURSE1","COURSE2","COURSE3"], relationType: 'GLOBAL'}
 */
const AudienceInput = ({ value: initValue, isUploading, checkBoxLabel, onChange, readOnly }) => {
  const courseAudienceFile = initValue.relationType === RELATION_TYPE.COURSE ? initValue.files : [];
  const userAudienceFile = initValue.relationType === RELATION_TYPE.USER ? initValue.files : [];

  const relationIdList = initValue?.relationIdList ?? [];
  const relationType = initValue?.relationType ?? RELATION_TYPE.GLOBAL;

  const [audienceGroup, setAudienceGroup] = useState(relationType);

  const isCourseRelation = relationType === RELATION_TYPE.COURSE;
  const isProviderRelation = relationType === RELATION_TYPE.PROVIDER;
  const isUserRelation = relationType === RELATION_TYPE.USER;
  const audienceList = relationIdList.map((relationId) => (relationId ? relationId.value : null));

  const [uploadFileList, setUploadFileList] = useState({
    [RELATION_TYPE.COURSE]: courseAudienceFile ?? [],
    [RELATION_TYPE.USER]: userAudienceFile ?? [],
  });

  const [currentAudience, setCurrentAudience] = useState({
    [RELATION_TYPE.COURSE]: isCourseRelation ? audienceList : [],
    [RELATION_TYPE.PROVIDER]: isProviderRelation ? audienceList : [],
    [RELATION_TYPE.USER]: isUserRelation ? audienceList : [],
  });

  const [options, setOptions] = useState({
    [RELATION_TYPE.COURSE]: isCourseRelation ? [...relationIdList] : [],
    [RELATION_TYPE.PROVIDER]: isProviderRelation ? [...relationIdList] : [],
    [RELATION_TYPE.USER]: isUserRelation ? [...relationIdList] : [],
  });

  const handleOnAddItem = (relationType, value) => {
    if (value.trim().length >= 1) {
      setOptions({
        ...options,
        [relationType]: [
          ...options[relationType],
          {
            id: options[relationType]?.length,
            text: value,
            value: value,
            key: options[relationType]?.length,
          },
        ],
      });
    }
  };

  const handleChange = (relationType, value = []) => {
    const isAdd = value.length > currentAudience[relationType]?.length ?? 0;

    if (!isAdd || value[value.length - 1].trim().length >= 1) {
      setCurrentAudience({
        ...currentAudience,
        [relationType]: [...value],
      });

      onChange(
        {},
        {
          value: {
            relationIdList: [...value],
            relationType: audienceGroup,
          },
        }
      );
    }
  };

  const handleSetUploadFileList = (files, relationType) => {
    const newUploadFileList = {
      ...uploadFileList,
      [relationType]: files,
    };
    setUploadFileList(newUploadFileList);

    const value = {
      relationType: audienceGroup,
      relationIdList: currentAudience[audienceGroup],
      files: newUploadFileList[audienceGroup],
    };

    if (files.length >= 1) {
      const newAudience = {
        ...currentAudience,
        [relationType]: [],
      };

      setCurrentAudience(newAudience);
      value.relationIdList = newAudience[audienceGroup];
    }
    onChange({}, { value });
  };

  /** UseEffect
   * if checked => relationType = 'GLOBAL' & relationID = ''
   * if not checked => relationType = 'COURSE' & relationID = ...
   */
  useEffect(() => {
    const currentAudienceData = currentAudience?.[audienceGroup] ?? [];
    const hasAudienceList = currentAudienceData.length >= 1;
    const value = {
      relationType: audienceGroup,
      relationIdList: hasAudienceList ? [...currentAudienceData] : [],
      files: [],
    };

    const hasFile = Object.keys(uploadFileList).some((key) => !!uploadFileList[key].length);
    if (hasFile) {
      value.relationIdList = [];
      value.files = uploadFileList[audienceGroup] ?? [];
    }

    onChange({}, { value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceGroup]);

  const renderCourseAudience = () => {
    return (
      <>
        <Flex alignItems="center" mt={1}>
          <Wrapper>
            <Radio
              key={RELATION_TYPE.COURSE}
              label="Course"
              name="audience"
              value={RELATION_TYPE.COURSE}
              checked={audienceGroup === RELATION_TYPE.COURSE}
              onChange={() => setAudienceGroup(RELATION_TYPE.COURSE)}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <Tooltip arrow title={`ตัวอย่าง "AN1234-V01", "ANL1234-V01"`} placement={'top'}>
              <InfoOutlined color="action" fontSize={'small'} />
            </Tooltip>
          </Wrapper>
          <Dropdown
            fluid
            multiple
            search
            selection
            allowAdditions
            options={options[RELATION_TYPE.COURSE]}
            value={currentAudience[RELATION_TYPE.COURSE]}
            onChange={(_, { value }) => handleChange(RELATION_TYPE.COURSE, value)}
            onAddItem={(_, { value }) => handleOnAddItem(RELATION_TYPE.COURSE, value)}
            disabled={
              readOnly ||
              audienceGroup !== RELATION_TYPE.COURSE ||
              uploadFileList?.[RELATION_TYPE.COURSE]?.length
            }
          />
        </Flex>
        <Flex>
          <Wrapper />
          <PopupAudienceFileUploadInput
            acceptFileTypes={['.csv']}
            uploadDescription={'ลิสต์รหัสคอร์ส'}
            setUploadList={(files) => handleSetUploadFileList(files, RELATION_TYPE.COURSE)}
            uploadList={uploadFileList?.[RELATION_TYPE.COURSE] ?? []}
            isUploading={!!isUploading}
          />
        </Flex>
      </>
    );
  };

  const renderUserAudience = () => {
    return (
      <>
        <Flex alignItems="center" mt={1}>
          <Wrapper>
            <Radio
              key={RELATION_TYPE.USER}
              label="UserId"
              name="audience"
              value={RELATION_TYPE.USER}
              checked={audienceGroup === RELATION_TYPE.USER}
              onChange={() => setAudienceGroup(RELATION_TYPE.USER)}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </Wrapper>
          <Dropdown
            fluid
            multiple
            search
            selection
            allowAdditions
            options={options[RELATION_TYPE.USER]}
            value={currentAudience[RELATION_TYPE.USER]}
            onChange={(_, { value }) => handleChange(RELATION_TYPE.USER, value)}
            onAddItem={(_, { value }) => handleOnAddItem(RELATION_TYPE.USER, value)}
            disabled={
              readOnly ||
              audienceGroup !== RELATION_TYPE.USER ||
              uploadFileList?.[RELATION_TYPE.USER]?.length
            }
          />
        </Flex>
        <Flex>
          <Wrapper />
          <PopupAudienceFileUploadInput
            acceptFileTypes={['.csv']}
            uploadDescription={'ลิสต์รหัสผู้ใช้'}
            setUploadList={(files) => handleSetUploadFileList(files, RELATION_TYPE.USER)}
            uploadList={uploadFileList?.[RELATION_TYPE.USER] ?? []}
            isUploading={!!isUploading}
          />
        </Flex>
      </>
    );
  };

  return (
    <>
      <Checkbox
        label={checkBoxLabel}
        checked={audienceGroup === RELATION_TYPE.GLOBAL}
        onChange={() => setAudienceGroup(RELATION_TYPE.GLOBAL)}
        readOnly={readOnly}
      />
      <>
        <Flex alignItems="center">
          <Wrapper>
            <Radio
              key={RELATION_TYPE.PROVIDER}
              label="Provider(BU)"
              name="audience"
              value={RELATION_TYPE.PROVIDER}
              checked={audienceGroup === RELATION_TYPE.PROVIDER}
              onChange={() => setAudienceGroup(RELATION_TYPE.PROVIDER)}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <Tooltip arrow title={`ตัวอย่าง "ondemand", "learned"`} placement={'top'}>
              <InfoOutlined color="action" fontSize={'small'} />
            </Tooltip>
          </Wrapper>
          <Dropdown
            fluid
            multiple
            search
            selection
            allowAdditions
            options={options[RELATION_TYPE.PROVIDER]}
            value={currentAudience[RELATION_TYPE.PROVIDER]}
            onChange={(_, { value }) => handleChange(RELATION_TYPE.PROVIDER, value)}
            onAddItem={(_, { value }) => handleOnAddItem(RELATION_TYPE.PROVIDER, value)}
            disabled={readOnly || audienceGroup !== RELATION_TYPE.PROVIDER}
          />
        </Flex>
      </>
      {renderCourseAudience()}
      {renderUserAudience()}
    </>
  );
};

AudienceInput.propTypes = {
  value: PropTypes.string,
  checkBoxLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  isUploading: PropTypes.bool,
};

export default withFormsy(AudienceInput);
