import React, { useState } from 'react';
import { Button, Icon, Container } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import apolloClient from 'src/GraphQL';
import QueryTable from 'GraphQL/util/QueryTable';
import { deleteAnnouncement } from 'src/GraphQL/mutation/Announcement.mutation';
import { getAnnouncementReports } from 'GraphQL/query/Announcement.query';

import BannerModal from '../Containers/BannerModal';
import { BannerCategorySearch, BannerActionCell } from '../Components';
import { STATUS } from '../Components/constants';

const columns = (handleOnDelete) => {
  return [
    {
      Header: 'Topic Name',
      accessor: 'title',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      type: COLUMN_TYPE.ENUM,
      isSearchAble: true,
      enumOptions: [
        { key: STATUS.PUBLISHED, value: STATUS.PUBLISHED, text: 'Published' },
        { key: STATUS.SCHEDULED, value: STATUS.SCHEDULED, text: 'Scheduled' },
        { key: STATUS.DRAFT, value: STATUS.DRAFT, text: 'Draft' },
      ],
    },
    {
      Header: 'Publish Date',
      accessor: 'publishedTime',
      type: COLUMN_TYPE.DATE,
      isSearchAble: true,
      placeholder: { start: 'From', end: 'To' },
    },
    // {
    //   Header: 'Category',
    //   accessor: 'category',
    //   type: COLUMN_TYPE.CUSTOM,
    //   isSearchAble: true,
    //   CustomSearchComponent: (props) => <BannerCategorySearch {...props} />,
    // },
    {
      Header: 'Action',
      accessor: 'createdAt',
      type: COLUMN_TYPE.STRING,
      isSearchAble: false,
      Cell: (props) => <BannerActionCell handleOnDelete={handleOnDelete} {...props} />,
    },
  ];
};

const AllBanners = () => {
  const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10 });
  const [search, setSearch] = useState({ category: 'BROWSE_BANNERS' });
  const [order, setOrder] = useState({ field: 'createdAt', type: 'DESC' });

  const genPaging = (page, pageSize = paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  const handlePageChange = (page) => {
    const paging = genPaging(page);
    setPaging(paging);
  };
  const handlePageSizeChange = (pageSize) => {
    setPaging(genPaging(0, pageSize));
  };
  const handleSearchChange = (search) => {
    const paging = genPaging(0);
    setPaging(paging);
    setSearch({ ...search, category: 'BROWSE_BANNERS' });
  };
  const genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  const handleSortedChange = (sorted) => {
    const order = genOrder(sorted);
    const paging = genPaging(0);
    setOrder(order);
    setPaging(paging);
  };

  const handleOnDelete = async (announcementId) => {
    try {
      await apolloClient.mutate({
        mutation: deleteAnnouncement,
        variables: { announcementId: announcementId },
        refetchQueries: [
          {
            query: getAnnouncementReports,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Flex justifyContent="flex-end" mb={2}>
        <BannerModal
          variables={{ search, paging, order }}
          trigger={
            <Button primary circular>
              <Icon name="plus" size="small" />
              Add Banner
            </Button>
          }
        />
      </Flex>
      <QueryTable
        type="announcementReports"
        columns={columns(handleOnDelete)}
        query={getAnnouncementReports}
        paging={paging}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        search={search}
        onSearchChange={handleSearchChange}
        order={order}
        onSortedChange={handleSortedChange}
        resolveData={(data) => data.announcementReports.announcementReports}
      />
    </Container>
  );
};

export default AllBanners;
