import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';

import Loading from 'Components/Loading';
import ErrorView from 'Components/ErrorView';
import { getTags } from 'GraphQL/query/Exam.query';

import { EXAM_ITEM_TAG_CATEGORY } from '../../constants';
import { mapTagsToOptions } from '../util';

import ConfigurableQuestionFormFields from './ConfigurableQuestionFormFields';

const ConfigurableQuestionFormBody = ({
  field,
  questionTypeOptions,
  initialData: question = {},
  onCancelled,
  onSubmit,
  isEdit,
  publishMode,
  examId,
  index,
  total,
  time,
  ...props
}) => {
  const tagCategories = chunk(Object.values(EXAM_ITEM_TAG_CATEGORY), 3);
  const { loading: firstTagsLoading, error: firstTagsError, data: firstTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[0] } },
      fetchPolicy: 'network-only',
    }
  );

  const { loading: secondTagsLoading, error: secondTagsError, data: secondTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[1] } },
      fetchPolicy: 'network-only',
    }
  );

  const { loading: thirdTagsLoading, error: thirdTagsError, data: thirdTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[2] } },
      fetchPolicy: 'network-only',
    }
  );

  const { loading: fourthTagsLoading, error: fourthTagsError, data: fourthTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[3] } },
      fetchPolicy: 'network-only',
    }
  );

  const { loading: fifthTagsLoading, error: fifthTagsError, data: fifthTagsData } = useQuery(
    getTags,
    {
      variables: { search: { category: tagCategories[4] } },
      fetchPolicy: 'network-only',
    }
  );

  const isLoading =
    firstTagsLoading ||
    secondTagsLoading ||
    thirdTagsLoading ||
    fourthTagsLoading ||
    fifthTagsLoading;

  if (isLoading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }

  const tags = [
    ...(firstTagsData?.examTags?.examTags ?? []),
    ...(secondTagsData?.examTags?.examTags ?? []),
    ...(thirdTagsData?.examTags?.examTags ?? []),
    ...(fourthTagsData?.examTags?.examTags ?? []),
    ...(fifthTagsData?.examTags?.examTags ?? []),
  ];

  const errors = [
    ...(firstTagsError ?? []),
    ...(secondTagsError ?? []),
    ...(thirdTagsError ?? []),
    ...(fourthTagsError ?? []),
    ...(fifthTagsError ?? []),
  ];

  return (
    <Fragment>
      {errors.length >= 1 &&
        errors.map((error, index) => <ErrorView key={index} message={error} />)}
      <ConfigurableQuestionFormFields
        questionTypeOptions={questionTypeOptions}
        field={field}
        onValidSubmit={onSubmit}
        tags={mapTagsToOptions(tags, question.examItemTags)}
        onCancel={onCancelled}
        isEdit={isEdit}
        publishMode={publishMode}
        examId={examId}
        question={{
          ...question,
          index,
          total,
          time,
        }}
      />
    </Fragment>
  );
};

ConfigurableQuestionFormBody.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancelled: PropTypes.func,
  isEdit: PropTypes.bool,
  publishMode: PropTypes.bool,
  examId: PropTypes.string,
};

ConfigurableQuestionFormBody.defaultProps = {
  initialData: {},
  onSubmit: () => {},
  onCancelled: () => {},
  isEdit: false,
  publishMode: false,
};

export default ConfigurableQuestionFormBody;
