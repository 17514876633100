import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List, Divider, Header, Message } from 'semantic-ui-react';

import { Box } from 'Components/Base';

import { SemanticButton as Button } from '../../../Components/Base/Button';

const ListContentUpper = styled(List.Content)`
  &.content {
    text-transform: capitalize;
  }
`;

const RolesList = ({ header, buttonText, onButtonClick, roles, emptyMessage }) => (
  <Fragment>
    <Header>{header}</Header>
    <List verticalAlign="middle" size={'big'}>
      {roles.length === 0 && <Message>{emptyMessage}</Message>}
      {roles.map((role) => (
        <List.Item key={role.id}>
          <List.Content floated="right">
            <Button
              onClick={() => {
                onButtonClick(role.id, role.name);
              }}
            >
              {buttonText}
            </Button>
          </List.Content>
          <List.Icon name="user" />
          <ListContentUpper>{role.name}</ListContentUpper>
        </List.Item>
      ))}
    </List>
  </Fragment>
);

RolesList.defaultProps = {
  onButtonClick: () => {},
};

class RolesPanel extends Component {
  handleAddRole = (id, name) => {
    const { roles: assignedRoles } = this.props;
    const roleIds = assignedRoles.map((r) => r.role.id);
    const newRoles = [...roleIds, id];

    this.props.onAssignRoles(newRoles);
  };

  handleRemoveRole = (id, name) => {
    const { roles: assignedRoles } = this.props;
    const roleIds = assignedRoles.map((r) => r.role.id);
    const newRoles = roleIds.filter((r) => r !== id);

    this.props.onAssignRoles(newRoles);
  };

  render() {
    const { roles, allRoles } = this.props;
    return (
      <Box mx={2} pt={2}>
        <RolesList
          header="Assigned Roles"
          buttonText="Remove"
          emptyMessage="No Role Assigned"
          roles={roles.map((role) => ({ id: role.role.id, name: role.role.name }))}
          onButtonClick={this.handleRemoveRole}
        />
        <Divider hidden />
        <RolesList
          header="All Roles"
          buttonText="Add"
          emptyMessage="Assigned All Roles"
          roles={allRoles.filter((al) => !roles.find((r) => r.role.id === al.id))}
          onButtonClick={this.handleAddRole}
        />
      </Box>
    );
  }
}

RolesPanel.propTypes = {
  userId: PropTypes.string,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      role: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  allRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onAssignRoles: PropTypes.func,
};

RolesPanel.defaultProps = {
  roles: [],
  allRoles: [],
  onAssignRoles: () => {},
};

export default RolesPanel;
