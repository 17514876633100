import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { Message } from 'semantic-ui-react';

import CheckDefinedComponent from '../../../Util/CheckDefinedComponent';
import { enrollCourseMutation } from '../../../GraphQL/mutation/Registrar.mutation';
import { getAllCourses } from '../../../GraphQL/query/Courses.query';
import QueryComponent from '../../../GraphQL/util/QueryComponent';
import EnrollCoursePanel from '../Components/EnrollCoursePanel';

export default class NewEnrollmentQuery extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  render() {
    const { userId } = this.props;
    return (
      <QueryComponent query={getAllCourses} variables={{ version: 'LEARNX_V1' }}>
        {({ courses }, refetch) => (
          <Mutation mutation={enrollCourseMutation}>
            {(enrollCourse, { loading, error, data }) => (
              <Fragment>
                {error && <Message negative>{JSON.stringify(error.message)}</Message>}
                <CheckDefinedComponent
                  checkDefinedObject={data}
                  onDefined={this.props.onComplete}
                />
                <EnrollCoursePanel
                  courseList={courses}
                  {...this.props}
                  onEnrollCourse={(courseId) => {
                    enrollCourse({ variables: { courseId, userId } });
                  }}
                  loading={loading}
                />
              </Fragment>
            )}
          </Mutation>
        )}
      </QueryComponent>
    );
  }
}
