import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import getDisplayName from 'Util/LSP/getDisplayName';
import getInterestValue from 'Util/LSP/getInterestValue';
import StatefulModal from 'Components/Modal/StatefulModal';
import CreateEditForm from 'Containers/CreateEditForm';
import {
  createStudentProfileMutation,
  updateStudentProfileMutation,
  updateProfileImageMutation,
} from 'GraphQL/LSP/mutation/Profile.mutation';
import { getStudentProfiles, getStudentProfile } from 'GraphQL/LSP/query/Profile.query';
import {
  getAllSchoolGrades,
  getAllSchoolProgrammes,
  getAllCareerPlans,
} from 'GraphQL/LSP/query/Education.query';
import Loading from 'Components/Loading';

import StudentProfileForm from './StudentProfileForm';

const formatInterests = (interestValues) => {
  return interestValues.map((interestValue) => {
    const [value, iconName, iconType] = interestValue.split('_');
    return {
      value,
      iconName,
      iconType,
    };
  });
};

function StudentProfileModal(props) {
  const { isEdit, id, trigger, variables } = props;
  const [updateProfileImage] = useMutation(updateProfileImageMutation);

  const handleUpdateProfileImage = ({ key, bucket }) => {
    updateProfileImage({
      variables: {
        userId: id,
        profileImage: {
          key,
          bucket,
        },
      },
    });
  };

  const { loading: schoolGradeLoading, error: schoolGradeError, data: schoolGradeData } = useQuery(
    getAllSchoolGrades,
    {
      fetchPolicy: 'network-only',
    }
  );
  const {
    loading: schoolProgrammeLoading,
    error: schoolProgrammeError,
    data: schoolProgrammeData,
  } = useQuery(getAllSchoolProgrammes, {
    fetchPolicy: 'network-only',
  });
  const { loading: careerPlanLoading, error: careerPlanError, data: careerPlanData } = useQuery(
    getAllCareerPlans,
    {
      fetchPolicy: 'network-only',
    }
  );
  if (schoolGradeLoading || schoolProgrammeLoading || careerPlanLoading) {
    return <Loading dimmer={false} loaderProps={{ active: true, inline: 'centered' }} />;
  }

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal) => (
        <Fragment>
          <Modal.Header>{isEdit ? 'แก้ไขโปรไฟล์' : 'สร้างโปรไฟล์'}</Modal.Header>
          <Modal.Content>
            <CreateEditForm
              dataQuery={getStudentProfile}
              createMutation={createStudentProfileMutation}
              createRefetchQueries={[
                {
                  query: getStudentProfiles,
                  variables,
                },
              ]}
              editRefetchQueries={[
                {
                  query: getStudentProfiles,
                  variables,
                },
              ]}
              editMutation={updateStudentProfileMutation}
              isEdit={isEdit}
              id={id}
              getQueryVariables={({ id }) => ({
                userId: id,
              })}
              getEditVariables={(form, { id }) => ({
                userId: id,
                data: {
                  code: form.code,
                  firstName: form.firstName,
                  lastName: form.lastName,
                  schoolGradeId: form.schoolGradeId,
                  schoolProgrammeId: form.schoolProgrammeId,
                  careerPlanIds: form.careerPlanIds,
                  prefix: form.prefix,
                  nickName: form.nickName,
                  phoneNumber: form.phoneNumber,
                  address1: form.address1,
                  district: form.district,
                  province: form.province,
                  zipCode: form.zipCode,
                  interests: formatInterests(form.interests),
                  classroomUsername: form.classroomUsername || null,
                },
              })}
              getCreateVariables={(form) => ({
                data: form,
              })}
              onCompleted={closeModal}
              onCancelled={closeModal}
            >
              {({ data, onSubmit }) => {
                const initialData = data
                  ? {
                      ...data.boStudentProfile,
                      ...data.boStudentProfile.address,
                      parentDisplayName: getDisplayName(data.boStudentProfile.parent),
                      advisorTeacherDisplayName: getDisplayName(
                        data.boStudentProfile.advisorTeacher
                      ),
                      interests: data.boStudentProfile.interests.map((interest) =>
                        getInterestValue(interest)
                      ),
                    }
                  : {};
                return (
                  <StudentProfileForm
                    initialData={initialData}
                    schoolGradeData={schoolGradeData && schoolGradeData.boAllSchoolGrades}
                    schoolProgrammeData={
                      schoolProgrammeData && schoolProgrammeData.boAllSchoolProgrammes
                    }
                    careerPlanData={careerPlanData && careerPlanData.boAllCareerPlans}
                    onUploadProfileImage={handleUpdateProfileImage}
                    onSubmit={onSubmit}
                  />
                );
              }}
            </CreateEditForm>
          </Modal.Content>
        </Fragment>
      )}
    </StatefulModal>
  );
}

export default StudentProfileModal;
