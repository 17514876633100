import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';
import { useMutation } from '@apollo/client';

import { Button } from 'Components/Base';

import { updateSalesOrderStatus } from '../../../GraphQL/mutation/Payment.mutation';
import { getSalesOrders } from '../../../GraphQL/query/Payment.query';
import { getUserSubscriptions } from '../../../GraphQL/query/Shipment.query';
import UpdateSalesOrderStatusForm from '../../../components/UpdateSalesOrderModal/UpdateSalesOrderStatusForm';

const optimisticUpdateQuery = (proxy, variables, orderId) => {
  // Read the data from our cache
  const cacheData = proxy.readQuery({
    query: getUserSubscriptions,
    variables,
  });
  const userSubscriptions = cacheData?.sfBoShipmentUserSubscriptions?.userSubscriptions || [];
  const matchSubscription = userSubscriptions.find(
    (subscription) => orderId === subscription.orderId
  );
  const matchSubscriptionIndex = userSubscriptions.findIndex(
    (subscription) => orderId === subscription.orderId
  );
  const updatedSubscription = { ...matchSubscription };
  updatedSubscription.endDate = new Date();

  const newUserSubscriptions = [...userSubscriptions];
  newUserSubscriptions[matchSubscriptionIndex] = updatedSubscription;
  proxy.writeQuery({
    query: getUserSubscriptions,
    variables,
    data: {
      sfBoShipmentUserSubscriptions: {
        total: cacheData?.sfBoShipmentUserSubscriptions.total,
        userSubscriptions: newUserSubscriptions,
      },
    },
  });
};

const UpdateSalesOrderStatusModal = ({ client, value, variables, refetchQuery, ...props }) => {
  const [open, setOpen] = useState(false);
  const [mutation, { loading, error, data }] = useMutation(updateSalesOrderStatus, {
    update: (proxy) => {
      optimisticUpdateQuery(proxy, variables, value.id);
    },
  });
  const show = () => setOpen(true);
  const close = () => setOpen(false);

  const handleConfirm = async (formattedValues) => {
    const { ref, receiptNo } = formattedValues;
    const modifiedData = {
      status: value.status,
      log: {
        ref,
      },
    };
    if (receiptNo) {
      modifiedData.receiptNo = receiptNo;
    }
    mutation({
      variables: {
        id: value.id,
        data: modifiedData,
      },
    });
    close();
  };

  const {
    disabled,
    actionText,
    inverted,
    icon,
    triggerButtonStyle,
    headerContent,
    modalSize,
    fields,
  } = props;

  return (
    <Fragment>
      <Button
        {...triggerButtonStyle}
        icon={icon}
        inverted={inverted}
        title={actionText}
        onClick={show}
        disabled={disabled}
      />
      <Modal size={modalSize} open={open}>
        {headerContent && <Modal.Header>{headerContent}</Modal.Header>}
        <Modal.Content>
          <UpdateSalesOrderStatusForm
            fields={fields}
            onSubmit={handleConfirm}
            onCancelled={close}
          ></UpdateSalesOrderStatusForm>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

UpdateSalesOrderStatusModal.propTypes = {
  actionText: PropTypes.string,
  buttonColor: PropTypes.string,
  inverted: PropTypes.bool,
  onConfirm: PropTypes.func,
  header: PropTypes.string,
  icon: PropTypes.string,
  triggerButtonStyle: PropTypes.object,
  actionButtonStyle: PropTypes.object,
  modalSize: PropTypes.string,
};

UpdateSalesOrderStatusModal.defaultProps = {
  actionText: 'Show',
  buttonColor: 'orange',
  inverted: false,
  onConfirm: () => {},
  header: 'Delete Confirmation',
  icon: '',
  triggerButtonStyle: {},
  actionButtonStyle: {},
  modalSize: 'tiny',
};

export default UpdateSalesOrderStatusModal;
