import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import log from 'loglevel';
import { Divider, Icon, Message, Header } from 'semantic-ui-react';
import _ from 'lodash';

import { Box, Flex } from 'Components/Base';

import MaterialMeta from '../Common/MaterialMeta';
import { SemanticButton as Button } from '../../Base/Button';
import { getQuestionIcon } from '../../../Routes/Courses/EditCourse/Exams/Components/QuestionList';
import { stripHtml } from '../../../Routes/Courses/EditCourse/Exams/Components/QuestionList/utils';
import SingleQuestion from '../../../Routes/Courses/EditCourse/Exams/Components/SingleQuestion/SingleQuestion';
import QuestionListToolbar from '../../../Routes/Courses/EditCourse/Exams/Components/QuestionList/QuestionListToolbar';
import { QUESTION_TYPE } from '../../../GraphQL/query/ExamEditorV1.query';
import ActionBar from '../Common/ActionBar';
import { AssetPropTypes } from '../Common/AssetPropTypes';
import Section from '../../Section';
import { EXAM_STATUS } from '../../../Routes/Courses/EditCourse/Exams/Components/SingleExam/constant';

const checkNewQuestion = (index, totalLength) => totalLength - 1 === index;

const forceShowActive = (showNewQuestion, isNewQuestion) => {
  if (showNewQuestion) {
    if (isNewQuestion) return true;
    else return false;
  }
  return undefined;
};
class QuizSimpleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: _.get(props, 'content.items', []),
      editState: false,
      newQuestion: false,
      newQuestionType: null,
      title: _.get(props, 'title', ''),
      description: _.get(props, 'description'),
    };
  }

  handleNewQuestionClick = (newQuestionType) => {
    this.setState({ newQuestion: true, newQuestionType });
  };

  handleOnRemoveQuestion = (newQuestion, index, cb = () => {}) => {
    log.info('handleOnRemoveQuestion', newQuestion, index);
    let questions = [...this.state.questions];
    questions.splice(index, 1);
    questions = questions.map((q, i) => ({
      ...q,
      index: i,
    }));
    this.setState(
      {
        questions,
      },
      () => {
        cb(null);
      }
    );
  };

  editStateTrigger = (bool) => {
    log.info('editStateTrigger', bool);
    this.setState({
      editState: bool,
    });
  };

  handleOnSaveQuestion = (question) => {
    if (question.id === 'newquestion') {
      const id = `${this.state.questions.length + 1}`;
      const newQuestions = [
        ...this.state.questions,
        {
          ...question,
          id,
        },
      ];
      this.setState({ questions: newQuestions, newQuestion: false, newQuestionType: null });
    } else {
      const newQuestions = this.state.questions.map((q) => (question.id === q.id ? question : q));
      this.setState({ questions: newQuestions, newQuestion: false, newQuestionType: null });
      this.section.resetActiveIndex();
    }
  };

  handleAction = () => {
    const { title, description, questions } = this.state;
    this.props.onCreateMaterial({
      title,
      description,
      content: {
        items: questions,
      },
    });
  };

  handleGetQuestion = (index) => this.state.questions[index];

  handleDone = (isNewQuestion) => {
    if (isNewQuestion) {
      this.setState({ newQuestion: false, newQuestionType: null });
    } else {
      this.section.resetActiveIndex();
    }
  };

  handleCancel = () => this.setState({ newQuestion: false, newQuestionType: null });

  handleDeleteQuestion = (id) => {
    this.setState({ questions: this.state.questions.filter((question) => question.id !== id) });
  };

  handleMetaChange = ({ title, description }) => {
    this.setState({ title, description });
  };

  getQuestionList(readOnlyMode = false) {
    const { newQuestion, newQuestionType, questions } = this.state;
    const {
      metadata: { courseItemId },
    } = this.props;

    // psudo examId
    const examId = `non-gradable-exam-in-course-item-${courseItemId}`;

    const showList = newQuestion
      ? [
          ...questions,
          {
            title: 'New Question',
            id: 'newquestion',
            type: newQuestionType,
            choices: [],
            solution: { value: '' },
          },
        ]
      : questions;

    const list = showList.map((question, index) => {
      const { type, id, title, choices, solution } = question;
      const isNewQuestion = newQuestion && checkNewQuestion(index, showList.length);
      return {
        key: id,
        active: forceShowActive(newQuestion, isNewQuestion),
        title: {
          left: (
            <Fragment>
              <Icon name="dropdown" />
              <Icon name={getQuestionIcon(type)} />
              <Header as="span" size="small">
                {`${index + 1}. `} {stripHtml(title)}
              </Header>
            </Fragment>
          ),
          right: !readOnlyMode && (
            <QuestionListToolbar
              status={EXAM_STATUS.DRAFT}
              headerText="Delete Quiz?"
              contentText="This Quiz will be removed"
              onDelete={() => this.handleDeleteQuestion(id)}
            />
          ),
        },
        content: {
          content: (
            <Box px={3}>
              <SingleQuestion
                questionId={id}
                isNew={isNewQuestion}
                type={type}
                question={question}
                onCancel={() => this.handleDone(isNewQuestion)}
                onSave={this.handleOnSaveQuestion}
                readOnly={readOnlyMode}
                examId={examId}
                isQuizSimpleMode
              />
            </Box>
          ),
        },
      };
    });

    return list;
  }

  render() {
    const {
      newAsset,
      materialTypeString,
      onCancel,
      loading,
      readOnly,
      maximumQuizzes,
      error,
    } = this.props;
    const { questions, editState, newQuestion, title, description } = this.state;
    const isDisabled = questions.length >= maximumQuizzes;

    return (
      <Box px={3}>
        {error && <Message negative>{`${error}`}</Message>}

        {!editState && isDisabled && (
          <Message info>{`Maximum ${maximumQuizzes} quizzes per subsection is allowed.`}</Message>
        )}

        <Box>
          <MaterialMeta title={title} description={description} onChange={this.handleMetaChange} />
        </Box>

        {questions.length <= 0 && !newQuestion && <Message>No Quiz</Message>}

        {(questions.length > 0 || newQuestion) && (
          <Box my={3}>
            <Section
              sections={this.getQuestionList(readOnly)}
              ref={(section) => {
                this.section = section;
              }}
            />
          </Box>
        )}

        {!readOnly && (
          <Flex>
            <Button
              primary
              circular
              fluid
              disabled={isDisabled}
              onClick={() => this.handleNewQuestionClick(QUESTION_TYPE.MULTIPLE_CHOICE)}
            >
              <Icon name={getQuestionIcon(QUESTION_TYPE.MULTIPLE_CHOICE)} />
              Add Question - 1 Answer
            </Button>
            <Box px={3} />
            <Button
              primary
              circular
              fluid
              disabled={isDisabled}
              onClick={() => this.handleNewQuestionClick(QUESTION_TYPE.MULTIPLE_SELECTION)}
            >
              <Icon name={getQuestionIcon(QUESTION_TYPE.MULTIPLE_SELECTION)} />
              Add Question - Mutiple Answers
            </Button>
            <Box px={3} />
            <Button
              primary
              circular
              fluid
              disabled={isDisabled}
              onClick={() => this.handleNewQuestionClick(QUESTION_TYPE.FILL_IN_THE_BLANK)}
            >
              <Icon name={getQuestionIcon(QUESTION_TYPE.FILL_IN_THE_BLANK)} />
              Add Question - Fill in the blank
            </Button>
          </Flex>
        )}
        <Divider />
        <Box mt={2}>
          <ActionBar
            isCreate={newAsset}
            onAction={this.handleAction}
            materialTypeString={materialTypeString}
            onCancel={onCancel}
            loading={loading}
          />
        </Box>
      </Box>
    );
  }
}

QuizSimpleEditor.propTypes = {
  ...AssetPropTypes,
  examId: propTypes.string,
  readOnly: propTypes.bool,
  onCancel: propTypes.func,
  newAsset: propTypes.bool,
  materialTypeString: propTypes.string,
  loading: propTypes.bool,
  maximumQuizzes: propTypes.number,
  title: propTypes.string,
  description: propTypes.string,
  content: propTypes.object,
  onCreateMaterial: propTypes.func,
  error: propTypes.object,
  metadata: propTypes.shape({ courseItemId: propTypes.string }),
};

QuizSimpleEditor.defaultProps = {
  examId: '',
  readOnly: false,
  onCancel: () => {},
  materialTypeString: 'Quiz',
  maximumQuizzes: 5,
  title: '',
  description: '',
  content: {},
  onCreateMaterial: () => {},
  error: null,
};

export default QuizSimpleEditor;
