import React from 'react';
import PropTypes from 'prop-types';

import ButtonView from 'Components/Base/Button/ButtonView';

import ExamModal from '../Containers/ExamModal';

const AddExamModal = (props) => {
  const { variables } = props;
  return (
    <ExamModal
      variables={variables}
      trigger={<ButtonView primary circular icon="plus" title="Add New Exam" />}
    />
  );
};

AddExamModal.propTypes = {
  variables: PropTypes.object,
};

export default AddExamModal;
