import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import Menu, { Navbar } from '../../Containers/Menu';
import { GenSwitchRoute } from '../../Util/Route';
import { Login, ForgetPassword, ResetPassword } from '../Auth';
import Landing from '../Landing';
import CourseRoute from '../Courses';
import CourseRouteV1 from '../CoursesV1';
import UsersRoute from '../Users';
import PaymentsRoute from '../Payments';
import SalesRoute from '../Sales';
import CertificateRoute from '../Certificates';
import ReportRoute from '../Reports';
import NoMatchRoute from '../NoMatch';
import getConfig from '../../Util/Config';
import WorkshopRoute from '../Workshop/';
import AnnouncementsRoute from '../Announcements';
import ExamsRoute from '../Exams';
import ProductsRoute from '../Products';
import { ImportDataRoute, ProfilesRoute, EducationsRoute, SyllabusesRoute } from '../LSP';
import { ImportDataRoute as LEImportDataRoute, PDFReportRoute as LEPDFReportRoute } from '../LE';
import SalesV2Route from '../SalesV2';
import ShipmentRoute from '../Shipments';
import { SFFileImportRoute } from '../FileImport';

import { ROUTES } from './constants';

const featuredRoutes = getConfig().featuredRoutes || [];
const {
  enableHeaderV2,
  appShell: { brandLogoImageSrc, brandLogoImageSrcSet },
} = getConfig();

const courseVersionRoutes = [
  {
    name: ROUTES.COURSES_V2,
    route: {
      path: '/courses/v2',
      exact: false,
      title: 'Courses',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.COURSE.LIST.VIEW,
        APP_PERMISSION.COURSE_TEAM.LIST.VIEW,
      ],
      route: CourseRoute.component,
    },
  },
];

const availableRoutes = [
  {
    name: ROUTES.WORKSHOP,
    route: {
      path: '/workshop',
      exact: false,
      title: 'Workshop',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
      route: WorkshopRoute.component,
    },
  },
  {
    name: ROUTES.COURSES_V1,
    route: {
      path: '/courses-v1',
      exact: false,
      title: 'Courses V1',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
      route: CourseRouteV1.component,
    },
  },
  {
    name: ROUTES.CERTIFICATES,
    route: {
      path: '/certificates',
      exact: false,
      title: 'Certificate Management',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.CERTIFICATE.LIST.VIEW],
      route: CertificateRoute.component,
    },
  },
  {
    name: ROUTES.REPORTS,
    route: {
      path: '/reports',
      title: 'Reports',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.COMMON.INSTRUCTOR],
      route: ReportRoute.component,
    },
  },
  {
    name: ROUTES.USER_MANAGEMENT,
    route: {
      path: '/users',
      title: 'User Management',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.USER.LIST.VIEW,
        APP_PERMISSION.USER.ORGANIZATION.VIEW,
        APP_PERMISSION.USER.BATCH_ENROLLMENT.VIEW,
        APP_PERMISSION.USER.DETAIL.VIEW,
        APP_PERMISSION.USER.ROLE_MANAGEMENT.LIST.VIEW,
        APP_PERMISSION.USER.ROLE_MANAGEMENT.OVERVIEW.VIEW,
        APP_PERMISSION.USER.CONTENT_TEAM.LIST.VIEW,
        APP_PERMISSION.USER.CONTENT_TEAM.DETAILS.VIEW,
        APP_PERMISSION.USER.CONTENT_TEAM.USERS.VIEW,
        APP_PERMISSION.USER.DEVICE.VIEW,
      ],
      route: UsersRoute.component,
    },
  },
  {
    name: ROUTES.PAYMENTS,
    route: {
      path: '/payments',
      title: 'Payment Management',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SALESV1.LIST.VIEW,
      ],
      route: PaymentsRoute.component,
    },
  },
  {
    name: ROUTES.SALES,
    route: {
      path: '/sales',
      title: 'Sales V1',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.SALESV1.LIST.VIEW],
      route: SalesRoute.component,
    },
  },
  {
    name: ROUTES.ANNOUNCEMENT,
    route: {
      path: '/announcements',
      title: 'Announcements',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ANNOUNCEMENT.LIST.VIEW,
      ],
      route: AnnouncementsRoute.component,
    },
  },
  {
    name: ROUTES.EXAMS,
    route: {
      path: '/exams',
      exact: false,
      title: 'Exams',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.INSTRUCTOR,
        APP_PERMISSION.EXAM.DETAIL.VIEW,
        APP_PERMISSION.EXAM.EXAM_TAG.VIEW,
        APP_PERMISSION.EXAM.LIST.VIEW,
        APP_PERMISSION.EXAM_TEAM.DETAIL.VIEW,
        APP_PERMISSION.EXAM_TEAM.EXAM_TAG.VIEW,
        APP_PERMISSION.EXAM_TEAM.LIST.VIEW,
      ],
      route: ExamsRoute.component,
    },
  },
  {
    name: ROUTES.LSP_FILE_IMPORT,
    route: {
      path: '/lsp/import-data',
      exact: false,
      title: 'Import Data',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: ImportDataRoute.component,
    },
  },
  {
    name: ROUTES.LSP_USER_PROFILE,
    route: {
      path: '/lsp/users',
      exact: false,
      title: 'Profiles',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: ProfilesRoute.component,
    },
  },
  {
    name: ROUTES.LSP_EDUCATION,
    route: {
      path: '/lsp/educations',
      exact: false,
      title: 'Education',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: EducationsRoute.component,
    },
  },
  {
    name: ROUTES.LSP_SYLLABUS,
    route: {
      path: '/lsp/syllabuses',
      exact: false,
      title: 'Syllabuses',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: SyllabusesRoute.component,
    },
  },
  {
    name: ROUTES.LE_FILE_IMPORT,
    route: {
      path: '/le/import-data',
      exact: false,
      title: 'Import Data',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: LEImportDataRoute.component,
    },
  },
  {
    name: ROUTES.LE_PDF_REPORT,
    route: {
      path: '/le/pdf-report',
      exact: false,
      title: 'PDF Report',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
      route: LEPDFReportRoute.component,
    },
  },
  {
    name: ROUTES.PRODUCTS,
    route: {
      path: ProductsRoute.location,
      exact: false,
      title: 'Products',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.ONLINE_COURSE.LIST.VIEW,
        APP_PERMISSION.SUBSCRIPTION.LIST.VIEW,
        APP_PERMISSION.SUBSCRIPTION.LIST.VIEW,
        APP_PERMISSION.COLLECTION.LIST.VIEW,
        APP_PERMISSION.WORKSHOP.LIST.VIEW,
        APP_PERMISSION.ONLINE_COURSE_CATEGORY.LIST.VIEW,
        APP_PERMISSION.SUBSCRIPTION_CATEGORY.LIST.VIEW,
        APP_PERMISSION.COLLECTION_CATEGORY.LIST.VIEW,
        APP_PERMISSION.INSTRUCTOR.LIST.VIEW,
        APP_PERMISSION.WORKSHOP_CATEGORY.LIST.VIEW,
      ],
      route: ProductsRoute.component,
    },
  },
  {
    name: ROUTES.SALES_V2,
    route: {
      path: SalesV2Route.location,
      exact: false,
      title: 'Sales',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.SALES_ORDER.VOID.LIST.VIEW,
        APP_PERMISSION.SALES_ORDER.SUCCESS.LIST.VIEW,
        APP_PERMISSION.SALES_ORDER.PENDING.LIST.VIEW,
        APP_PERMISSION.SALES_ORDER.FAILED.LIST.VIEW,
        APP_PERMISSION.REDEMPTION_ORDER.LIST.VIEW,
        APP_PERMISSION.TRIAL_ORDER.LIST.VIEW,
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT.ADJUSTMENT_ORDER.LIST.VIEW,
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT.SUBSCRIPTION.LIST.VIEW,
        APP_PERMISSION.DISCOUNT_CODE.LIST.VIEW,
      ],
      route: SalesV2Route.component,
    },
  },
  {
    name: ROUTES.SHIPMENT,
    route: {
      path: ShipmentRoute.location,
      exact: false,
      title: 'Shipment',
      private: true,
      roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.SHIPMENT.LIST.VIEW],
      route: ShipmentRoute.component,
    },
  },
  {
    name: ROUTES.SALES_V2_FILE_IMPORT,
    route: {
      path: SFFileImportRoute.location,
      exact: false,
      title: 'File Import',
      private: true,
      roles: [
        APP_PERMISSION.COMMON.ADMINISTRATOR,
        APP_PERMISSION.COMMON.MARKETER,
        APP_PERMISSION.DISCOUNT_CODE.LIST.VIEW,
        APP_PERMISSION.FILE_IMPORT.DISCOUNT_CODE.LIST.VIEW,
        APP_PERMISSION.FILE_IMPORT.SHIPMENT_TRACKING.LIST.VIEW,
      ],
      route: SFFileImportRoute.component,
    },
  },
];

const generateMenu = (useParams = false) => {
  const menu = [
    { path: '/', title: 'Home', roles: [], route: Landing.component, hideMenu: true },
    { path: '/login', route: Login.component, hideMenu: true },
    { path: '/forget-password', route: ForgetPassword.component, hideMenu: true },
    { path: '/reset-password', route: ResetPassword.component, hideMenu: true },
  ];

  if (featuredRoutes.includes(ROUTES.COURSES_V2)) {
    if (useParams) {
      menu.push({
        path: '/courses/:courseVersion',
        exact: false,
        title: 'Courses',
        private: true,
        roles: [
          APP_PERMISSION.COMMON.ADMINISTRATOR,
          APP_PERMISSION.COMMON.INSTRUCTOR,
          APP_PERMISSION.COURSE.LIST.VIEW,
          APP_PERMISSION.COURSE_TEAM.LIST.VIEW,
        ],
        route: CourseRoute.component,
      });
    } else {
      const courseRoutes = courseVersionRoutes.filter(({ name }) => featuredRoutes.includes(name));
      courseRoutes.forEach(({ route }) => menu.push(route));
    }
  }

  const filteredRoute = availableRoutes.filter(({ name }) => featuredRoutes.includes(name));
  filteredRoute.forEach(({ route }) => menu.push(route));

  const noMatch = {
    noMatchRoute: NoMatchRoute,
    route: NoMatchRoute,
    hideMenu: true,
  };
  return [...menu, noMatch];
};

export default class RouteApp extends Component {
  render() {
    return (
      <Router>
        <Fragment>
          {enableHeaderV2 ? (
            <Navbar
              menus={generateMenu(false)}
              logoSrc={brandLogoImageSrc}
              logoSrcSet={brandLogoImageSrcSet}
              showAuth
              showLogo
            />
          ) : (
            <Menu
              menus={generateMenu(false)}
              showAuth
              showLogo
              menuProps={{ fixed: 'top', id: 'navbar' }}
            />
          )}
          <GenSwitchRoute routes={generateMenu(true)} />
        </Fragment>
      </Router>
    );
  }
}
