import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { lifecycle, compose, branch, renderComponent } from 'recompose';

import { isLogin, myProfile } from '../../Redux/user-profile/selectors';
import { getProfile, logout } from '../../Redux/user-profile/actions';
import { LoginButton, MyProfileButton } from '../../Components/MenuItem/AuthButton';

const mapStateToProps = (state) => ({
  isLogin: isLogin(state),
  myProfile: myProfile(state),
});

const doValidate = lifecycle({
  componentDidMount() {
    this.props.getProfile();
  },
});

export default compose(
  connect(mapStateToProps, { getProfile, logout }),
  withRouter,
  doValidate,
  branch((props) => !props.isLogin, renderComponent(LoginButton))
)(MyProfileButton);
