import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import { Icon } from 'semantic-ui-react';

import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { CATEGORY_STATUS } from 'Routes/Products/Domains/constants';

import { InstructorModal } from '../../Components/InstructorModal';
import { getInstructors } from '../../GraphQL/query/Products.query';

const columns = (variables) => [
  {
    Header: 'Name',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Permalink',
    accessor: 'permalink',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    type: COLUMN_TYPE.ENUM,
    isSearchAble: true,
    enumOptions: [
      { key: CATEGORY_STATUS.VISIBLE, value: CATEGORY_STATUS.VISIBLE, text: 'Visible' },
      { key: CATEGORY_STATUS.INVISIBLE, value: CATEGORY_STATUS.INVISIBLE, text: 'Invisible' },
    ],
  },
  {
    Header: 'Ordering',
    accessor: 'itemNo',
    type: COLUMN_TYPE.NUMBER,
    isSearchAble: false,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: ({ original }) => {
      return (
        <Fragment>
          <Button.Group>
            <InstructorModal
              isEdit
              variables={variables}
              id={original.id}
              data={original}
              trigger={
                <Button basic size="mini" icon compact>
                  <Icon name="edit" />
                </Button>
              }
            />
          </Button.Group>
        </Fragment>
      );
    },
  },
];

class InstructorRoute extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'createdAt', type: 'DESC' }, // itemNo ASC
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoProductInstructors.instructors;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };

    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Instructors</Text.Header>
          <InstructorModal
            isEdit={false}
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New Instructor
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={'sfBoProductInstructors'}
          resolveData={this.resolveData}
          columns={columns(variables)}
          query={getInstructors}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(InstructorRoute);
