import gql from 'graphql-tag';

import {
  singleExam,
  singleDuplicatedExamItem,
  singleExamItem,
  singleTag,
  EXAM_TEAM_FRAGMENT,
} from '../query/Exam.query';

export const createExam = gql`
  mutation createExamExam($exam: ExamExamCreateInput!, $enabledContentTeam: Boolean = false) {
    createExamExam(exam: $exam) {
      code
      success
      message
      exam {
        ...singleExam
        ...EXAM_TEAM_FRAGMENT @include(if: $enabledContentTeam)
      }
    }
  }
  ${singleExam}
  ${EXAM_TEAM_FRAGMENT}
`;

export const updateExam = gql`
  mutation updateExamExam(
    $examId: ID!
    $exam: ExamExamUpdateInput!
    $enabledContentTeam: Boolean = false
  ) {
    updateExamExam(examId: $examId, exam: $exam) {
      code
      success
      message
      exam {
        ...singleExam
        ...EXAM_TEAM_FRAGMENT @include(if: $enabledContentTeam)
      }
    }
  }
  ${singleExam}
  ${EXAM_TEAM_FRAGMENT}
`;

export const UPDATE_EXAM_RANDOM_RULES = gql`
  mutation updateExamExamRandomRules($examId: ID!, $rules: ExamExamRandomRulesInput!) {
    updateExamExamRandomRules(examId: $examId, rules: $rules) {
      code
      success
      message
      exam {
        ...singleExam
      }
    }
  }
  ${singleExam}
`;

export const UPDATE_EXAM_FIXED_RULES = gql`
  mutation updateExamExamFixedRules($examId: ID!, $rules: ExamExamFixedRulesInput!) {
    updateExamExamFixedRules(examId: $examId, rules: $rules) {
      code
      success
      message
      exam {
        ...singleExam
      }
    }
  }
  ${singleExam}
`;

export const publishExam = gql`
  mutation publishExamExam($examId: ID!) {
    publishExamExam(examId: $examId) {
      code
      success
      message
      exam {
        ...singleExam
      }
    }
  }
  ${singleExam}
`;

export const duplicateExam = gql`
  mutation duplicateExamExam($examId: ID!) {
    duplicateExamExam(examId: $examId) {
      code
      success
      message
      exam {
        ...singleExam
      }
    }
  }
  ${singleExam}
`;

export const deleteExam = gql`
  mutation deleteExamExam($examId: ID!) {
    deleteExamExam(examId: $examId) {
      code
      success
      message
      exam {
        ...singleExam
      }
    }
  }
  ${singleExam}
`;

export const createExamItem = gql`
  mutation createExamExamItem(
    $examId: ID!
    $examItem: ExamExamItemCreateInput!
    $isPublish: Boolean
  ) {
    createExamExamItem(examId: $examId, examItem: $examItem, isPublish: $isPublish) {
      code
      success
      message
      examItem {
        ...singleExamItem
      }
    }
  }
  ${singleExamItem}
`;

export const updateExamItem = gql`
  mutation updateExamExamItem(
    $examId: ID!
    $examItemId: ID!
    $examItem: ExamExamItemUpdateInput!
    $isPublish: Boolean
  ) {
    updateExamExamItem(
      examId: $examId
      examItemId: $examItemId
      examItem: $examItem
      isPublish: $isPublish
    ) {
      code
      success
      message
      examItem {
        ...singleExamItem
      }
    }
  }
  ${singleExamItem}
`;

export const duplicateExamItem = gql`
  mutation duplicateExamExamItem($examId: ID!, $examItemId: ID!) {
    duplicateExamExamItem(examId: $examId, examItemId: $examItemId) {
      code
      success
      message
      examItem {
        ...singleDuplicatedExamItem
      }
    }
  }
  ${singleDuplicatedExamItem}
`;

export const deleteExamItem = gql`
  mutation deleteExamExamItem($examId: ID!, $examItemId: ID!) {
    deleteExamExamItem(examId: $examId, examItemId: $examItemId) {
      code
      success
      message
      examItem {
        ...singleExamItem
      }
    }
  }
  ${singleExamItem}
`;

export const createTag = gql`
  mutation createExamTag($tag: ExamTagCreateInput!) {
    createExamTag(tag: $tag) {
      code
      success
      message
      tag {
        ...singleTag
      }
    }
  }
  ${singleTag}
`;

export const updateTag = gql`
  mutation updateExamTag($tagId: ID!, $tag: ExamTagUpdateInput!) {
    updateExamTag(tagId: $tagId, tag: $tag) {
      code
      success
      message
      tag {
        ...singleTag
      }
    }
  }
  ${singleTag}
`;

export const deleteTag = gql`
  mutation deleteExamTag($tagId: ID!) {
    deleteExamTag(tagId: $tagId) {
      code
      success
      message
      tag {
        ...singleTag
      }
    }
  }
  ${singleTag}
`;
