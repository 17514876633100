import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Text } from 'Components/Base';

const TableHeader = ({ columns }) => {
  return (
    <TableHead style={{ background: '#F2F2F2' }}>
      <TableRow>
        {Array.isArray(columns) &&
          columns.length &&
          columns.map((column, index) => (
            <TableCell
              width={index === 0 ? '368px' : '151px'}
              key={column.header}
              align={index === 0 ? 'inherit' : 'right'}
            >
              <Text fontWeight={500} fontSize="16px">
                {column.header}
              </Text>
            </TableCell>
          ))}
        <TableCell width={'151px'} align="right" />
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
    })
  ),
};

export default TableHeader;
