import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormGenerator from '../FormGenerator';

const fields = (isNewUser) => {
  const result = [];
  result.push(
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'First Name',
      inputName: 'firstname',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Last Name',
      inputName: 'lastname',
      inputProps: {
        required: true,
      },
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Reference Id',
      inputName: 'referenceId',
    }
  );
  if (isNewUser) {
    result.push(
      {
        inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
        inputLabel: 'Email',
        inputName: 'email',
        inputProps: {
          validations: 'isEmail',
          required: true,
        },
      },
      {
        inputType: FormGenerator.INPUT_TYPE.PASSWORD_FIELD,
        inputLabel: 'Password',
        inputName: 'password',
        inputProps: {
          required: true,
        },
      },
      {
        inputType: FormGenerator.INPUT_TYPE.PASSWORD_FIELD,
        inputLabel: 'Confirm Password',
        inputName: 'passwordVerify',
        inputProps: {
          required: true,
        },
      }
    );
  }

  return result;
};

export default class UserInfo extends Component {
  static propTypes = {
    onCreateNewUser: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateNewUser: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateNewUser(form);
  };

  checkPassword = (currentValues) => {
    const { password, passwordVerify } = currentValues;
    if (passwordVerify && password !== passwordVerify && passwordVerify.length > 0) {
      return { passwordVerify: 'Not Match' };
    }
    return {};
  };

  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={fields(!isEdit)}
        onSubmit={this.handleSubmit}
        customValidation={this.checkPassword}
        submitText={isEdit ? 'Edit User' : 'Add User'}
        {...this.props}
      />
    );
  }
}
