import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';
import _ from 'lodash';

import { getFileImportJobs } from 'Routes/SalesV2/GraphQL/query/FileImport.query';
import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { deleteFileImportJob } from 'Routes/SalesV2/GraphQL/mutation/FileImport.mutation';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import ImportDataModal from '../../Components/ImportDataModal';
import ResultSummariesModal from '../../Components/ResultSummariesModal';
import FileResultModal from '../../Components/FileResultModal';
import { FILE_TYPE, FILE_IMPORT_STATUS } from '../../common';
const fileImportJobsColumns = (handleOnClick, handleDelete, variables) => [
  {
    Header: 'Type',
    accessor: 'type',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'File Code',
    accessor: 'arg1',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 100,
    type: COLUMN_TYPE.ENUM,
    enumOptions: [
      { key: FILE_IMPORT_STATUS.NEW, value: FILE_IMPORT_STATUS.NEW, text: FILE_IMPORT_STATUS.NEW },
      {
        key: FILE_IMPORT_STATUS.PROCESSING,
        value: FILE_IMPORT_STATUS.PROCESSING,
        text: FILE_IMPORT_STATUS.PROCESSING,
      },
      {
        key: FILE_IMPORT_STATUS.SUCCESSFUL,
        value: FILE_IMPORT_STATUS.SUCCESSFUL,
        text: FILE_IMPORT_STATUS.SUCCESSFUL,
      },
      {
        key: FILE_IMPORT_STATUS.FAILED,
        value: FILE_IMPORT_STATUS.FAILED,
        text: FILE_IMPORT_STATUS.FAILED,
      },
    ],
    isSearchAble: true,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    width: 200,
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'ResultSummary',
    accessor: 'result',
    width: 200,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
    Cell: function ResultSummaryCell({ original }) {
      const {
        status,
        result: { resultSummaries, validationErrors },
      } = original;
      if (status === FILE_IMPORT_STATUS.SUCCESSFUL) {
        const groupedResult = _.groupBy(
          resultSummaries.filter((result) => result.type !== 'fileResult'), // FileResult separated into another column
          (result) => result.type
        );
        let stringArray = _.map(groupedResult, (value, key) => {
          return `${key}: ${value.length} record(s)`;
        });
        return stringArray.join(', ');
      } else if (status === FILE_IMPORT_STATUS.FAILED) {
        // TODO: Use Modal to show more error details
        return `Error: ${validationErrors.length} record(s)`;
      }
      return null;
    },
  },
  {
    Header: 'Result Details',
    accessor: 'result',
    width: 200,
    type: COLUMN_TYPE.CUSTOM,
    isSearchAble: false,
    Cell: function FileResultCell({ original }) {
      const {
        id,
        status,
        result: { file },
      } = original;

      if (status === FILE_IMPORT_STATUS.SUCCESSFUL || status === FILE_IMPORT_STATUS.FAILED) {
        let path;
        let bucket;
        let key;
        if (file) {
          path = file.key.split('/');
          bucket = [path[0], path[1]].join('/');
          key = [path[2], path[3]].join('/');
        }
        return (
          <Fragment>
            <Button.Group>
              <ResultSummariesModal
                status={status}
                id={id}
                trigger={
                  <Button basic size="mini" icon compact>
                    <Icon name="tasks" />
                  </Button>
                }
              />
              {file && (
                <FileResultModal
                  data={{
                    type: ASSET_TYPE.BACKOFFICE_STOREFRONT_DISCOUNT_CODE_UPLOAD_RESULT,
                    bucket: bucket,
                    key: key,
                  }}
                  trigger={
                    <Button basic size="mini" icon compact>
                      <Icon name="file outline" />
                    </Button>
                  }
                />
              )}
            </Button.Group>
          </Fragment>
        );
      }
      return null;
    },
  },
];

class FileImport extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(FILE_TYPE)).isRequired,
  };

  static defaultProps = {
    // pageSize: 10,
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: { type: props.type },
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  fileMutate = (mutation, jobId) => {
    const { search, paging, order } = this.state;
    this.props.client
      .mutate({
        mutation,
        variables: { jobId },
        refetchQueries: [
          {
            query: getFileImportJobs,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  handleDelete = (jobId) => {
    this.fileMutate(deleteFileImportJob, jobId);
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search: { ...search, type: this.props.type }, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoFileImportJobs.fileImportJobs;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    const { title, type } = this.props;
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>{title}</Text.Header>
          <ImportDataModal
            title={title}
            type={type}
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                Add
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={'sfBoFileImportJobs'}
          resolveData={this.resolveData}
          columns={fileImportJobsColumns(this.handleOnClick, this.handleDelete, variables)}
          query={getFileImportJobs}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default withApollo(FileImport);
