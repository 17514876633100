import React from 'react';

import {
  createCollectionCategoryMutation,
  updateCollectionCategoryMutation,
} from 'Routes/Products/GraphQL/mutation/Products.mutation';
import {
  getCollectionCategoryByPermalink,
  getCollectionCategories,
  getCollectionCategory,
} from 'Routes/Products/GraphQL/query/Products.query';
import { SKU_CATEGORY } from 'Routes/Products/Domains/constants';

import CategoriesBase from './BaseComponent/CategoriesBase';
export const CategoriesCollectionRoute = (props) => {
  return (
    <CategoriesBase
      {...props}
      skuCategory={SKU_CATEGORY.COLLECTION}
      getCategoryByPermalinkQueryName="sfBoProductCollectionCategoryByPermalink"
      getCategoryByPermalink={getCollectionCategoryByPermalink}
      getCategoriesQueryName="sfBoProductCollectionCategories"
      getCategories={getCollectionCategories}
      getCategory={getCollectionCategory}
      getCategoryQueryName="sfBoProductCollectionCategory"
      createMutation={createCollectionCategoryMutation}
      updateMutation={updateCollectionCategoryMutation}
    />
  );
};
