import { METHOD, handleErrors, parseValue } from '../fetch';
import getConfig from '../../Util/Config';

const USER_STORAGE_API = getConfig().userStorageApi;
const USER_STORAGE_CONTAINER = getConfig().userStorageContainer;

const BACKOFFICE_STORAGE_API = getConfig().backofficeStorageApi;
const ANNOUNCEMENT_STORAGE_CONTAINER = getConfig().announcement.announcementStorageContainer;

export const uploadBatchEnrollment = async (fileList, fetchOptions = { parseJson: true }) => {
  const callPath = `${USER_STORAGE_API}/${USER_STORAGE_CONTAINER}/upload`;
  const formData = new FormData();

  for (const file of fileList) {
    formData.append('fileResult', file);
  }

  const options = {
    method: METHOD.POST,
    body: formData,
    credentials: 'include',
  };

  return fetch(callPath, options)
    .then((res) => handleErrors(res, callPath))
    .then((res) => parseValue(res, fetchOptions.parseJson));
};

export const uploadAnnouncement = async (fileList, fetchOptions = { parseJson: true }) => {
  const callPath = `${BACKOFFICE_STORAGE_API}/${ANNOUNCEMENT_STORAGE_CONTAINER}/upload`;
  const formData = new FormData();

  for (const file of fileList) {
    formData.append('fileResult', file);
  }

  const options = {
    method: METHOD.POST,
    body: formData,
    credentials: 'include',
  };

  return fetch(callPath, options)
    .then((res) => handleErrors(res, callPath))
    .then((res) => parseValue(res, fetchOptions.parseJson));
};
