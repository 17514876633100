const ASSIGNMENT_STATUS = {
  SUBMITTED: 'SUBMITTED',
  CANCELLED: 'CANCELLED',
  GRADED: 'GRADED',
};

export const DISPLAY_ASSIGNMENT_STATUS = {
  [ASSIGNMENT_STATUS.SUBMITTED]: 'Submitted',
  [ASSIGNMENT_STATUS.CANCELLED]: 'Cancelled',
  [ASSIGNMENT_STATUS.GRADED]: 'Graded',
};

export const GRADING_TYPE = {
  PASS_FAIL: 'PASS_FAIL',
  SCORE: 'SCORE',
};

// In the front-end, the 'CANCELLED' option is hidden.
export const ASSIGNMENT_STATUS_SEARCH_OPTIONS = Object.keys(ASSIGNMENT_STATUS)
  .filter((key) => key !== ASSIGNMENT_STATUS.CANCELLED)
  .map((key) => ({
    key,
    value: ASSIGNMENT_STATUS[key],
    text: DISPLAY_ASSIGNMENT_STATUS[key],
  }));
