import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from 'Components/Base';

import { SemanticButton as Button } from '../Base/Button';
import { SPACE } from '../Theme';

const NoMatch = ({ onBackClick, ...props }) => {
  return (
    <Flex flexDirection="column" alignItems="center" p={SPACE.lg}>
      <Text.Title heading py={4} color="greyscale.grey1">
        Error 404 Page not found!
      </Text.Title>
      <Button primary onClick={onBackClick}>
        Back to Home
      </Button>
    </Flex>
  );
};

NoMatch.propTypes = {
  onBackClick: PropTypes.func,
};

NoMatch.defaultProps = {
  onBackClick: () => {},
};

export default NoMatch;
