import gql from 'graphql-tag';

import { APP_PERMISSION_FRAGMENT } from '../query/Permission.query';

const UPDATE_APP_ROLE_PERMISSION = gql`
  mutation updateAppRolePermission($id: ID!, $appPermissionIds: [String]!) {
    updateAppRolePermission(id: $id, appPermissionIds: $appPermissionIds) {
      ...APP_PERMISSION_FRAGMENT
    }
  }
  ${APP_PERMISSION_FRAGMENT}
`;

export { UPDATE_APP_ROLE_PERMISSION };
