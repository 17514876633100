import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Header, Modal, Icon, Checkbox, Accordion } from 'semantic-ui-react';
import { Mutation } from '@apollo/client/react/components';
import _ from 'lodash';

import { Box, Flex, Text } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import StatefulModal from 'Components/Modal/StatefulModal';
import QueryTable from 'GraphQL/util/QueryTable';
import CheckMutationResponse from 'Containers/CreateEditForm/Components/CheckMutationResponse';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { updateSubscriptionItemsMutation } from '../../GraphQL/mutation/Products.mutation';
import { getSubscriptionItems, getCollections } from '../../GraphQL/query/Products.query';

function addOrRemove(array, item) {
  const data = [...array];
  const index = _.findIndex(data, (datum) => datum.id === item.id);

  if (index === -1) {
    data.push({ id: item.id, SKUCode: item.SKUCode });
  } else {
    data.splice(index, 1);
  }
  return data;
}

const skuColumns = (handleCheckbox, selectedItems) => [
  {
    Header: 'SKUCode',
    accessor: 'SKUCode',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Type',
    accessor: 'SKUCategory',
    type: COLUMN_TYPE.STRING,
    isSearchAble: false,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <Fragment>
          <Button.Group>
            <Checkbox
              checked={selectedItems.find((item) => item.id === id)}
              onChange={() => handleCheckbox(original)}
            />
          </Button.Group>
        </Fragment>
      );
    },
  },
];

export default class SubscriptionItemsModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    trigger: PropTypes.element,
    onSubmit: PropTypes.func,
    title: PropTypes.string.isRequired,
  };
  static defaultProps = {
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: {},
      order: { field: 'itemNo', type: 'ASC' },
      selectedItems: [],
      activeIndex: -1,
    };
  }

  handleCheckbox = (item) => {
    this.setState({ selectedItems: addOrRemove(this.state.selectedItems, item) });
  };

  handleAddItems = (mutateFn) => {
    const { selectedItems } = this.state;
    if (selectedItems.length > 0) {
      mutateFn({
        variables: {
          id: this.props.id,
          data: { addedItemIds: selectedItems.map((item) => item.id) },
        },
      });
    }
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({
      search,
      paging,
    });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.sfBoProductCollections.collections;

  handleClickAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { trigger, title, variables } = this.props;
    const { search, paging, order, selectedItems, activeIndex } = this.state;
    return (
      <StatefulModal trigger={trigger} dimmer="inverted">
        {(closeModal) => (
          <Fragment>
            <Modal.Header>{`Add Items to ${title}`}</Modal.Header>
            <Modal.Content>
              <Mutation
                mutation={updateSubscriptionItemsMutation}
                refetchQueries={[
                  {
                    query: getSubscriptionItems,
                    variables,
                  },
                ]}
              >
                {(editData, { loading, error, data: mutateData }) => {
                  return (
                    <Box>
                      <CheckMutationResponse
                        checkOnlyDefined={true}
                        response={mutateData}
                        loading={loading}
                        error={error}
                        onValid={closeModal}
                      />
                      <QueryTable
                        type={'sfBoProductCollections'}
                        resolveData={this.resolveData}
                        columns={skuColumns(this.handleCheckbox, selectedItems)}
                        query={getCollections}
                        search={search}
                        onSearchChange={this.handleSearchChange}
                        paging={paging}
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        order={order}
                        onSortedChange={this.handleSortedChange}
                        ignoreSearchParams
                      />
                      <Flex mt={2}>
                        <Accordion styled fluid>
                          <Accordion.Title
                            active={activeIndex === 0}
                            index={0}
                            onClick={this.handleClickAccordion}
                          >
                            <Header textAlign="left">
                              <Icon name="dropdown" />
                              {`${selectedItems.length} item(s) to be added`}
                            </Header>
                          </Accordion.Title>
                          <Accordion.Content
                            active={activeIndex === 0}
                            style={{ textAlign: 'left' }}
                          >
                            {selectedItems.map(({ id, SKUCode }, index) => (
                              <Text key={id}>{`${index + 1}: ${SKUCode}`}</Text>
                            ))}
                          </Accordion.Content>
                        </Accordion>
                      </Flex>
                      <Flex justifyContent="flex-end" mt={2}>
                        <Button
                          primary
                          circular
                          size="medium"
                          type="button"
                          content="Update"
                          loading={loading}
                          disabled={loading}
                          onClick={() => this.handleAddItems(editData)}
                        />
                        <Button
                          circular
                          size="medium"
                          type="reset"
                          content="Cancel"
                          disabled={loading}
                          onClick={closeModal}
                        />
                      </Flex>
                    </Box>
                  );
                }}
              </Mutation>
            </Modal.Content>
          </Fragment>
        )}
      </StatefulModal>
    );
  }
}
