import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import styled from 'styled-components';

import SectionTitle from './SectionTitle.view';

const SAccordion = styled(Accordion)`
  &.ui.styled.accordion .title {
    display: flex;
    align-items: center;

    &:not(.active) {
      .title-right {
        opacity: 0;
      }
      &:hover {
        background-color: rgba(202, 202, 202, 0.2);
        .title-right {
          opacity: 1;
        }
      }
    }
  }
`;

const SingleSection = ({ contentComp, titleComp, active, index, onTitleClick }) => (
  <Fragment>
    <Accordion.Title onClick={onTitleClick} active={active} index={index}>
      {titleComp}
    </Accordion.Title>
    <Accordion.Content active={active}>{active && contentComp}</Accordion.Content>
  </Fragment>
);

const getActiveStatus = (isCurrentIndex, forceActive) => {
  if (forceActive !== undefined) return forceActive;
  return isCurrentIndex;
};
class Section extends Component {
  static propTypes = {
    sections: PropTypes.array,
    title: PropTypes.oneOfType([
      PropTypes.shape({
        left: PropTypes.node,
        right: PropTypes.node,
      }),
      PropTypes.string,
      PropTypes.element,
    ]),
    onSectionClick: PropTypes.func,
  };

  static defaultProps = {
    sections: [],
    onSectionClick: () => {},
  };

  state = {
    activeIndex: -1,
  };

  resetActiveIndex() {
    this.setState({ activeIndex: -1 });
  }

  sectionClick = (idx) => {
    const { sections, onSectionClick } = this.props;
    const { activeIndex } = this.state;

    const section = sections[activeIndex] || {};
    const isCurrentSectionForceActive = section.active;
    // console.log({ sections, section, idx, activeIndex, isCurrentSectionForceActive });

    if (isCurrentSectionForceActive === undefined) {
      this.setState(
        (prevState) => {
          return { activeIndex: prevState.activeIndex === idx ? -1 : idx };
        },
        () => {
          onSectionClick(idx);
        }
      );
    } else {
      onSectionClick(idx);
    }
  };

  render() {
    const { sections } = this.props;
    const { activeIndex } = this.state;
    return (
      <SAccordion styled fluid>
        {sections.map((s, index) => (
          <SingleSection
            key={`${s.key || s.id}`}
            titleComp={<SectionTitle left={s.title.left} right={s.title.right} />}
            contentComp={s.content.content}
            onTitleClick={() => {
              this.sectionClick(index);
            }}
            index={index}
            active={getActiveStatus(activeIndex === index, s.active)}
          />
        ))}
      </SAccordion>
    );
  }
}

export default Section;
