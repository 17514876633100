import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Segment as SegmentBase, Header } from 'semantic-ui-react';

import { Box, Flex, AspectRatio, BackgroundImage } from 'Components/Base';

const SnapshotBox = styled(Box)`
  cursor: pointer;
`;

const FlexScroll = styled(Flex)`
  overflow-x: scroll;
  overflow-y: hidden;
  div {
    flex: 0 0 auto;
  }
`;

const Segment = styled(SegmentBase)`
  min-height: 100px;
`;

class SnapshotPanel extends Component {
  render() {
    const {
      snapshots,
      onSelectSnapshot,
      loadingSnapshot,
      thumbnail,
      loadingThumbnail,
    } = this.props;
    return (
      <Flex width={[1, 1]} flexWrap="wrap">
        <Box width={[1, 2 / 3]} p={3}>
          <Header sub textAlign="center">
            Video Snapshots (select to be thumbnail)
          </Header>
          {loadingSnapshot && <Segment loading />}
          {!loadingSnapshot && snapshots && (
            <FlexScroll p={3} width={[1, 1]} flexWrap="nowrap">
              {snapshots.map((snapshot) => {
                const { snapshotId, imageUrl } = snapshot;
                return (
                  <SnapshotBox
                    key={snapshotId}
                    width={[1, 1 / 4]}
                    p={2}
                    onClick={() => onSelectSnapshot(snapshotId, imageUrl)}
                  >
                    <AspectRatio ratio={1}>
                      <BackgroundImage key={snapshotId} imageUrl={imageUrl} bgPosition={'center'} />
                    </AspectRatio>
                  </SnapshotBox>
                );
              })}
            </FlexScroll>
          )}
        </Box>
        <Box width={[1, 1 / 3]} p={3}>
          <Header sub textAlign="center">
            Thumbnails
          </Header>
          {loadingThumbnail && <Segment loading />}
          {!loadingThumbnail && thumbnail && (
            <AspectRatio ratio={16 / 9}>
              <BackgroundImage imageUrl={thumbnail} bgPosition={'center'} />
            </AspectRatio>
          )}
        </Box>
      </Flex>
    );
  }
}

SnapshotPanel.propTypes = {
  snapshots: PropTypes.array,
  onSelectSnapshot: PropTypes.func,
  loadingSnapshot: PropTypes.bool,
  thumbnail: PropTypes.object,
  loadingThumbnail: PropTypes.bool,
};

export default SnapshotPanel;
