import React, { Fragment, Component } from 'react';
import { Button, Icon, Radio } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';

import { Flex } from 'Components/Base';

import apolloClient from '../../../../GraphQL';
import QueryTable from '../../../../GraphQL/util/QueryTable';
import ConfirmationButton from '../../../../Components/ConfirmationButton';
import COLUMN_TYPE from '../../../../Components/Search/SearchFormGenerator/constantType';
import { getOrganizations } from '../../../../GraphQL/query/Users.query';
import Text from '../../../../Components/Base/Text';
import {
  deleteOrganizationMutation,
  updateOrganizationMutation,
} from '../../../../GraphQL/mutation/Users.mutation';
import getConfig from '../../../../Util/Config';

import OrganizationModal from './OrganizationModal';

const excludeColumns = () => ['id', 'referenceId'];
const getOrganizationColumns = (handleDelete, handleToggleActive, variables, pathname) => {
  const allColumns = [
    {
      Header: 'Organization ID',
      accessor: 'id',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Organization Name',
      accessor: 'organizationName',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
      Cell: (row) => <Link to={{ pathname: `${pathname}/${row.original.id}` }}>{row.value}</Link>,
    },
    {
      Header: 'Email Domain Name',
      accessor: 'domainName',
      type: COLUMN_TYPE.STRING,
      isSearchAble: true,
    },
    {
      Header: 'Enable/Disable',
      accessor: 'active',
      type: COLUMN_TYPE.ENUM,
      enumOptions: [
        { key: 'active', value: true, text: 'Enable' },
        { key: 'inactive', value: false, text: 'Disable' },
      ],
      isSearchAble: true,
      Cell: ({ original }) => {
        const organizationId = original.id;
        const isActive = original.active;
        return (
          <Fragment>
            <Button.Group>
              <Radio
                toggle
                defaultChecked={isActive}
                onChange={() => {
                  handleToggleActive(organizationId, !isActive);
                }}
              />
            </Button.Group>
          </Fragment>
        );
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      width: 100,
      isSearchAble: false,
      sortable: false,
      type: COLUMN_TYPE.CUSTOM,
      Cell: ({ original }) => {
        const organizationId = original.id;
        return (
          <Fragment>
            <Button.Group>
              <Link to={{ pathname: `${pathname}/${organizationId}` }}>
                <Button basic icon>
                  <Icon name="edit" color="grey" />
                </Button>
              </Link>

              <ConfirmationButton
                headerText={`Delete?`}
                contentText={'This will be permanently deleted from the system.'}
                confirmText={'Delete'}
                onConfirm={() => {
                  handleDelete(organizationId);
                }}
              >
                <Icon name="trash alternate outline" />
              </ConfirmationButton>
            </Button.Group>
          </Fragment>
        );
      },
    },
  ];
  if (getConfig().lineHideColumns) {
    return allColumns.filter((column) => !excludeColumns().includes(column.accessor));
  }
  return allColumns;
};

class Organizations extends Component {
  state = {
    paging: { currentPage: 0, pageSize: 10 },
    search: {},
    order: { field: 'organizationName', type: 'ASC' },
  };

  handleDelete = async (organizationId) => {
    const { search, paging, order } = this.state;
    try {
      await apolloClient.mutate({
        mutation: deleteOrganizationMutation,
        variables: { id: organizationId },
        refetchQueries: [
          {
            query: getOrganizations,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };
  handleToggleActive = async (organizationId, active) => {
    const { search, paging, order } = this.state;
    try {
      await apolloClient.mutate({
        mutation: updateOrganizationMutation,
        variables: { id: organizationId, organizationData: { active } },
        refetchQueries: [
          {
            query: getOrganizations,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };
  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  render() {
    const { search, paging, order } = this.state;
    const { location } = this.props;
    const variables = { search, paging, order };
    return (
      <div>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>Organizations</Text.Header>
          <OrganizationModal
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                New
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type="organizations"
          columns={getOrganizationColumns(
            this.handleDelete,
            this.handleToggleActive,
            variables,
            location.pathname
          )}
          query={getOrganizations}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </div>
    );
  }
}

export default withRouter(Organizations);
