import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { getTagBySlug, getExams } from 'GraphQL/query/Exam.query';
import Loading from 'Components/Loading';

import QuizView, { propTypes as QuizViewPropTypes } from './Quiz';
import { EXAM_STATUS } from './constants';

export default function Quiz(props) {
  const { courseCode, loading } = props;
  const { loading: isTagLoading, data: tagData } = useQuery(getTagBySlug, {
    variables: {
      slug: courseCode,
      category: 'COURSE_CODE',
    },
  });

  const courseCodeTagId = tagData?.examTagBySlug?.id ?? '';

  const { loading: isExamsLoading, data: examsData } = useQuery(getExams, {
    variables: {
      search: {
        courseCodes: [courseCodeTagId],
        status: EXAM_STATUS.PUBLISHED,
      },
    },
    skip: isTagLoading,
  });

  if (isExamsLoading) return <Loading />;

  const exams = examsData?.examExams?.exams ?? [];

  return <QuizView {...props} exams={exams} loading={loading || isTagLoading || isExamsLoading} />;
}

const { exams: _exams, ...quizViewPropTypes } = QuizViewPropTypes;

Quiz.propTypes = {
  ...quizViewPropTypes, // without `exams`
  courseCode: PropTypes.string,
};
