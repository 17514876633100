import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const MenuHeader = styled(Menu.Header)`
  text-align: center;
`;

const PaymentMenu = ({ menus, menusLog, activeItem, onMenuItemChange }) => (
  <Menu secondary vertical fluid>
    <Menu.Item>
      <Menu.Header content="Payment Status" />
      {menus.map(({ title, menuName, path, icon }, index) => (
        <Menu.Item
          key={`${menuName + path + index}`}
          as={NavLink}
          to={path}
          name={menuName}
          icon={icon}
          active={activeItem === title}
          onClick={() => onMenuItemChange(title)}
        />
      ))}
    </Menu.Item>
  </Menu>
);

PaymentMenu.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      menuName: PropTypes.string,
      path: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  activeItem: PropTypes.string,
  handleState: PropTypes.func,
};

export default PaymentMenu;
