import React, { Fragment, useState, useEffect } from 'react';
import { Box, Flex } from '@skooldio/skooldio-ui-components-core';
import { Modal, Icon } from 'semantic-ui-react';

import StatefulModal from 'Components/Modal/StatefulModal';
import { SemanticButton as Button } from 'Components/Base/Button';
import Table from 'Components/DataTable';

function ModalContent({ details, setIsOpen, isModalOpen, mutationData, closeModal }) {
  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [setIsOpen, isModalOpen]);

  const columns = [
    {
      Header: 'Type',
      accessor: 'type',
      width: 200,
      style: { display: 'flex', justifyContent: 'center', textTransform: 'capitalize' },
    },
    {
      Header: 'Details',
      accessor: 'value',
      Cell: function ValueCell({ original }) {
        const { value } = original;
        return Array.isArray(value) ? (
          <Fragment>
            <ul
              style={{
                marginLeft: '32px',
                listStyleType: 'circle',
                listStylePosition: 'inside',
              }}
            >
              {value.map((item, index) => (
                <li key={index} style={{ marginBottom: '8px' }}>
                  {item}
                </li>
              ))}
            </ul>
          </Fragment>
        ) : (
          <Box ml={4}>{value}</Box>
        );
      },
    },
  ];

  const transformedData = Object.entries(details ?? {}).map(([key, value]) => ({
    type: key,
    value,
  }));

  return (
    <Fragment>
      <Modal.Header>Report Details</Modal.Header>
      <Modal.Content>
        <Table
          columns={columns}
          data={transformedData}
          showPagination={false}
          defaultPageSize={transformedData.length}
        />
        <Flex mt={4} justifyContent="flex-end" alignItems="center">
          <Button primary circular onClick={closeModal}>
            <Icon name="close" size="small" />
            Close
          </Button>
        </Flex>
      </Modal.Content>
    </Fragment>
  );
}

function ReportDetailsModal({ trigger, details }) {
  const [, setIsOpen] = useState(false);

  return (
    <StatefulModal trigger={trigger} dimmer="inverted">
      {(closeModal, isModalOpen) => {
        return (
          <ModalContent
            details={details}
            setIsOpen={setIsOpen}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
        );
      }}
    </StatefulModal>
  );
}

export default ReportDetailsModal;
