import gql from 'graphql-tag';

const APP_PERMISSION_FRAGMENT = gql`
  fragment APP_PERMISSION_FRAGMENT on AppPermission {
    id
    name
    description
    category
    action
    order
    description
  }
`;

const GET_APP_PERMISSIONS = gql`
  query getAppPermissions {
    appPermissions {
      ...APP_PERMISSION_FRAGMENT
    }
  }
  ${APP_PERMISSION_FRAGMENT}
`;

const GET_APP_ROLE_PERMISSIONS = gql`
  query getAppRolePermissions($id: ID!) {
    appRolePermissions(id: $id) {
      ...APP_PERMISSION_FRAGMENT
    }
  }
  ${APP_PERMISSION_FRAGMENT}
`;

export { APP_PERMISSION_FRAGMENT };
export { GET_APP_PERMISSIONS, GET_APP_ROLE_PERMISSIONS };
