import React, { Component } from 'react';

import APP_PERMISSION from 'Util/permissions/permissionKeys';

import { GenRoute } from '../../Util/Route';

import Certificates from './Certificates.gql';
const menus = (matchUrl = '/certificates') => [
  {
    path: `${matchUrl}/`,
    title: 'All Certificates',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR, APP_PERMISSION.CERTIFICATE.LIST.VIEW],
    route: Certificates,
  },
];

export default class CertificateRoute extends Component {
  render() {
    const { match } = this.props;
    return <GenRoute menus={menus(match.path)} />;
  }
}
