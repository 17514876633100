export const uploads = async (files, metadata, handleOnUploadProgress) => {
  const progress = new Promise((resolve) => {
    // const { loaded, total } = progressEvent;
    // const progress = total && total > 0 ? loaded / total : 0.0;
    let i = 0;
    for (i = 0; i <= 100; i++) {
      handleOnUploadProgress(i / 100);
    }
  });
  return { videoKey: 'mockVideoKey' };
};
