import React from 'react';
import PropTypes from 'prop-types';

import { getOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateOnlineCourseHighlightsMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUBaseHighlightEditor from '../SKUBaseHighlightEditor';

const OnlineCourseHighlightEditor = (props) => {
  const { onlineCourse } = props;
  return (
    <SKUBaseHighlightEditor
      {...props}
      dataQuery={getOnlineCourse}
      dataQueryName={'sfBoProductOnlineCourse'}
      variableQuery={{ SKUCode: onlineCourse.SKUCode }}
      handleMutation={updateOnlineCourseHighlightsMutation}
      getHandleVariables={(data) => ({
        id: onlineCourse.id,
        highlights: data,
      })}
      refetchQueries={[{ query: getOnlineCourse, variables: { SKUCode: onlineCourse.SKUCode } }]}
    />
  );
};

OnlineCourseHighlightEditor.propTypes = {
  onlineCourse: PropTypes.shape({
    id: PropTypes.string.isRequired,
    SKUCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default OnlineCourseHighlightEditor;
