import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';
import FormGenerator from 'Components/Form/FormGenerator';

import { getOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateOnlineCourseRatingMutation } from '../../GraphQL/mutation/Products.mutation';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Average',
      inputName: 'avg',
      mode: MODE.BOTH,
    },
  ];
  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class SubscriptionPricingEditor extends Component {
  static propTypes = {
    onlineCourse: PropTypes.object,
    isEdit: PropTypes.bool,
  };

  render() {
    const { onlineCourse = {}, isEdit = true } = this.props;
    const { id, SKUCode } = onlineCourse;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getOnlineCourse}
          editMutation={updateOnlineCourseRatingMutation}
          isEdit={isEdit}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            data: {
              avg: form.avg,
            },
          })}
        >
          {({ data, onSubmit }) => {
            return (
              <FormGenerator
                initialData={
                  (data && data.sfBoProductOnlineCourse && data.sfBoProductOnlineCourse.ratings) ||
                  {}
                }
                fields={getFields(isEdit)}
                onSubmit={onSubmit}
                submitText={isEdit ? 'Update' : 'Add'}
                cancelText="Cancel"
                showCancel
                {...this.props}
              />
            );
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default SubscriptionPricingEditor;
