import React, { useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';

import { ButtonFilled, Box, Flex, Text } from 'Components/Base';
import StatefulModal from 'Components/Modal/StatefulModal';
import { GET_USER_APP_ROLE_PERMISSIONS, singleUserQuery } from 'GraphQL/query/Users.query';
import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';
import useDisclosure from 'Util/useDisclosure';

import SearchRoleList, { BASIC_COLUMN } from '../SearchRoleList';
import { ASSIGN_APP_ROLE } from '../../Graphql/mutation/Role.mutation';

import AssignRoleButton from './AssignRoleButton';

const Divider = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
`;

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  PERMISSION_LIMITATION: {
    message: 'Assign higher or unassign yourself is prohibited',
    variant: VARIANTS.ERROR,
    show: true,
  },
  ROLE_ALREADY_EXIST: {
    message: `Role has been assigned already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Can't assign role right now, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const AssignRoleModalButton = ({ userId }) => {
  const { isOpen, open, close } = useDisclosure();
  const [feedback, setFeedback] = useState({});
  const SearchRoleListRef = useRef(null);
  const { data: { user } = {} } = useQuery(singleUserQuery, {
    variables: { userId },
    skip: !userId,
    fetchPolicy: 'cache-only',
  });

  const onCompleted = useCallback(
    (response) => {
      const recentAssignRoleName = response?.assignAppRoleToUser?.name;
      const username = user?.username;
      setFeedback({
        message: `Role${
          recentAssignRoleName ? ` "${recentAssignRoleName}" ` : ' '
        }has been assigned to user${username ? ` "${username}"` : ''}`,
        variant: VARIANTS.SUCCESS,
        show: true,
      });
      const SearchRoleListTable = SearchRoleListRef.current;
      if (typeof SearchRoleListTable.refetch === 'function') {
        SearchRoleListTable.refetch();
      }
    },
    [user]
  );

  const onError = useCallback((response) => {
    const message = response?.message ?? '';

    if (message.includes('403')) {
      setFeedback(FEEDBACK.PERMISSION_LIMITATION);
      return;
    }

    if (message.includes('401')) {
      setFeedback(FEEDBACK.PROHIBITED);
      return;
    }

    if (message.includes('400')) {
      setFeedback(FEEDBACK.ROLE_ALREADY_EXIST);
      return;
    }

    setFeedback(FEEDBACK.UNEXPECTED_ERROR);
  }, []);

  const { data, loading, error } = useQuery(GET_USER_APP_ROLE_PERMISSIONS, {
    variables: { userId },
    skip: !userId,
  });

  const [assignAppRole] = useMutation(ASSIGN_APP_ROLE, {
    refetchQueries: [{ query: GET_USER_APP_ROLE_PERMISSIONS, variables: { userId } }],
    onCompleted,
    onError,
  });

  const handleAssignRole = useCallback(
    (roleId, userId) =>
      assignAppRole({
        variables: {
          roleId,
          userId,
        },
      }),
    [assignAppRole]
  );

  const MemoAddRoleButton = useMemo(
    () => (
      <ButtonFilled
        px={3}
        py={2}
        bg="primary"
        color="white"
        style={{ cursor: 'pointer', border: 0 }}
        onClick={open}
        fontWeight="600"
        fontSize="14px"
      >
        <Flex alignItems="center">
          <AddIcon />
          <span>Add Role</span>
        </Flex>
      </ButtonFilled>
    ),
    [open]
  );

  const columns = useMemo(
    () => [
      BASIC_COLUMN.ID,
      { ...BASIC_COLUMN.NAME, width: 120 },
      BASIC_COLUMN.DESCRIPTION,
      {
        Header: 'Action',
        Cell: function actionComponent(row) {
          return (
            <Flex justifyContent="center">
              <AssignRoleButton
                onClick={() => handleAssignRole(row.original.id, row.original.userId)}
              />
            </Flex>
          );
        },
        width: 120,
        isSearchAble: false,
      },
    ],
    [handleAssignRole]
  );

  const ModalFooter = useMemo(
    () => (
      <Flex justifyContent="flex-end" py={2} px={3}>
        <ButtonFilled
          px={3}
          py={2}
          bg="primary"
          color="white"
          style={{ cursor: 'pointer', border: 0 }}
          onClick={close}
          fontWeight="600"
          fontSize="14px"
        >
          Close
        </ButtonFilled>
      </Flex>
    ),
    [close]
  );

  const ModalHeader = useMemo(
    () => (
      <>
        <Text.Header px={4} pt={3} fontWeight="600">
          Assign role {user && `| ${user?.displayName}`}
        </Text.Header>
      </>
    ),
    [user]
  );

  const getAssignableRoles = useCallback(
    ({ appRoles }) => {
      const userAppRoles = data?.user?.appRoleData ?? [];
      if (!userAppRoles || !Array.isArray(userAppRoles)) return [];
      const userRolesId = userAppRoles.map(({ id }) => id);
      return appRoles?.appRoles
        ?.filter((appRole) => !userRolesId.includes(appRole?.id))
        .map((filteredRole) => ({ ...filteredRole, userId }));
    },
    [data, userId]
  );

  return (
    <>
      {MemoAddRoleButton}
      {isOpen && (
        <>
          <StatefulModal open={isOpen} trigger={<></>} onClose={close}>
            {() => (
              <>
                {ModalHeader}
                <Divider my={3} />
                <Box px={3}>
                  <SearchRoleList
                    columns={columns}
                    resolveData={getAssignableRoles}
                    ref={SearchRoleListRef}
                  />
                </Box>
                {ModalFooter}
              </>
            )}
          </StatefulModal>
        </>
      )}
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3000}
      />
    </>
  );
};

PropTypes.propTypes = {
  userId: PropTypes.string.isRequired,
};
export default AssignRoleModalButton;
