import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Button, Icon } from 'semantic-ui-react';
import { isNil } from 'lodash';
import urlJoin from 'url-join';
import { useTimer } from 'use-timer';

import Snackbar, { VARIANTS } from 'Routes/Users/Components/Snackbar';
import { Flex, Text } from 'Components/Base';
import QueryTable, { DATA_FORMAT } from 'GraphQL/util/QueryTable';
import ConfirmationButton from 'Components/ConfirmationButton';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import apolloClient from 'src/GraphQL';
import { getUserCertificatesByUserId } from 'GraphQL/query/Users.query';
import { deleteUserCertificate } from 'GraphQL/mutation/Users.mutation';
import getConfig from 'Util/Config';

import { EvaluateCertificatesButton } from '../ActionPanel/Buttons.gql';

const { certificateUrlPrefix } = getConfig();

const TextContainer = styled.div`
  width: 65%;
  @media only screen and (max-width: 778px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 778px) {
    flex-wrap: wrap;
  }
`;

const CertificateEvaluationBox = ({ userId, onCompleted }) => {
  return (
    <Card fluid>
      <Card.Content>
        <Content>
          <TextContainer>
            <Text fontWeight={600} fontSize="1.2em" pb={1}>
              Certificate Evaluation
            </Text>
            <Text fontWeight={300} fontSize="1em">
              Before re-creating the certificate, please delete the previous one in the dashboard
              below
            </Text>
          </TextContainer>
          <EvaluateCertificatesButton userId={userId} onCompleted={onCompleted} />
        </Content>
      </Card.Content>
    </Card>
  );
};

const getColumns = ({ onClickDelete, setSelectedUserCertificate, selectedUserCertificate }) => [
  {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Certificate Name',
    accessor: 'certificate.name',
    type: COLUMN_TYPE.STRING,
  },
  {
    Header: 'Name - Surname',
    accessor: 'user.firstname',
    type: COLUMN_TYPE.CUSTOM,
    Cell: ({ original }) => `${original?.user?.firstname}  ${original?.user?.lastname}`,
  },
  {
    Header: 'Url Certificate',
    accessor: 'certificateUrl',
    type: COLUMN_TYPE.CUSTOM,
    width: 300,
    Cell: ({ original }) => {
      if (isNil(original?.certificateUrl)) {
        return 'Generating URL';
      }
      let url;
      if (certificateUrlPrefix) {
        url = urlJoin(certificateUrlPrefix, original?.id);
      } else {
        url = original?.certificateUrl?.replace('pdf', 'png');
      }
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      );
    },
  },
  {
    Header: 'Created Date',
    accessor: 'issuedAt',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATETIME_FORMAT,
  },
  {
    Header: 'Expiry Date',
    accessor: 'expiredAt',
    type: COLUMN_TYPE.DATE,
    dataFormat: DATA_FORMAT.DATETIME_FORMAT,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    type: COLUMN_TYPE.CUSTOM,
    Cell: ({ original }) => {
      const isLoading = selectedUserCertificate === original.id;
      const clickDeleteFn = () => {
        setSelectedUserCertificate(original.id);
        onClickDelete();
      };
      return (
        <Flex justifyContent="center">
          <Button basic icon onClick={clickDeleteFn} loading={isLoading} disabled={isLoading}>
            <Icon name="trash alternate outline" />
          </Button>
        </Flex>
      );
    },
  },
];

const initSearchFilterState = {
  paging: { currentPage: 0, pageSize: 10 },
  order: { field: 'createdAt', type: 'DESC' },
};

//in seconds
const pollingTime = 3;
const retryLimit = 10;

const UserCertificatePanel = ({ userId }) => {
  const [searchFilter, setSearchFilter] = useState(initSearchFilterState);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [selectedUserCertificate, setSelectedUserCertificate] = useState();
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const refetchCertUrl = async (start, resetTime) => {
    try {
      if (retryCount < retryLimit) {
        resetTime();
        setRetryCount((prev) => prev + 1);
        const [data] = await apolloClient.refetchQueries({
          include: [getUserCertificatesByUserId],
        });
        const userCerts = data?.data?.getUserCertificatesByUserId?.userCertificates;
        const shouldRefetch = userCerts.some((c) => isNil(c.certificateUrl));

        if (shouldRefetch) start();
      } else {
        console.error('[refetch certURL]: The number of retries has reached its limit.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { time, start, reset } = useTimer({
    initialTime: 0,
    endTime: pollingTime,
    onTimeOver: async () => await refetchCertUrl(start, reset),
  });

  useEffect(() => {
    const retryable = retryCount < retryLimit;
    if (shouldRefetch && time === 0 && retryable) {
      start();
    }
  }, [shouldRefetch]);

  const setFilter = (key, value) => {
    setSearchFilter((prev) => ({ ...prev, [key]: value }));
  };

  const genPaging = (page, pageSize = searchFilter.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };

  const handlePageChange = (page) => {
    const paging = genPaging(page);
    setFilter('paging', { ...paging });
  };

  const handleConfirmDelete = async (callback = async () => {}) => {
    setOpenConfirmModal(false);
    await callback();
    setFeedback({
      message: `Delete Certificate was successful`,
      variant: VARIANTS.SUCCESS,
      show: true,
    });
  };

  const handleClickDelete = () => {
    setOpenConfirmModal(true);
  };

  const HandleDeleteUserCertificate = async () => {
    try {
      await apolloClient.mutate({
        mutation: deleteUserCertificate,
        variables: { userId, userCertificateId: selectedUserCertificate },
      });
      await apolloClient.refetchQueries({ include: [getUserCertificatesByUserId] });

      setSelectedUserCertificate(undefined);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinishedReEvaluate = () => {
    setRetryCount(0);
    setFeedback({
      message: `Re-evaluate certificates was successful`,
      variant: VARIANTS.SUCCESS,
      show: true,
    });
  };

  const resolveData = (data) => {
    const _data = data.getUserCertificatesByUserId.userCertificates;
    const shouldRefetch = _data.some((d) => isNil(d.certificateUrl));
    setShouldRefetch(shouldRefetch);

    return _data;
  };

  return (
    <>
      <Snackbar
        message={feedback.message}
        isOpen={feedback.show}
        onClose={() => setFeedback({ ...feedback, show: false })}
        variant={feedback.variant}
        vertical={'top'}
        horizontal={'center'}
        duration={3500}
      />
      <CertificateEvaluationBox userId={userId} onCompleted={onFinishedReEvaluate} />
      <QueryTable
        type={'getUserCertificatesByUserId'}
        resolveData={resolveData}
        columns={getColumns({
          onClickDelete: handleClickDelete,
          setSelectedUserCertificate,
          selectedUserCertificate,
          refetchCertUrl,
        })}
        query={getUserCertificatesByUserId}
        search={{ userId }}
        paging={searchFilter.paging}
        order={searchFilter.order}
        showSearchPanel={false}
        onPageChange={handlePageChange}
      />
      {openConfirmModal && (
        <ConfirmationButton
          headerText="Delete?"
          defaultOpen={true}
          onConfirm={() => {
            handleConfirmDelete(HandleDeleteUserCertificate);
          }}
          onCancel={() => {
            setSelectedUserCertificate(undefined);
            setOpenConfirmModal(false);
          }}
          confirmText="Delete"
          contentText="This Certificate will be permanently deleted from the system."
        />
      )}
    </>
  );
};

UserCertificatePanel.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserCertificatePanel;
