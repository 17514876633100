import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Editor from 'Routes/Workshop/Routes/EditWorkshop/Components/MarkupTextInput/MarkupTextInput';
import FormGenerator from 'Components/Form/FormGenerator';

import { FormsySubscriptionSelectorInput } from '../SubscriptionSelectorInput';
import { FormsyUserSelectorInput } from '../UserSelectorInput';

import TimeInput from './TimeInput';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, props) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'User',
      inputName: 'userId',
      inputProps: {
        required: true,
      },
      mode: MODE.CREATE,
      customInput: (props) => <FormsyUserSelectorInput {...props} multiple={false} />,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'New SKU Code',
      inputName: 'SKUId',
      inputProps: {
        required: true,
      },
      mode: MODE.CREATE,
      customInput: (props) => (
        <FormsySubscriptionSelectorInput
          {...props}
          multiple={false}
          placeholder={'select the subscription to change'}
        />
      ),
    },
    // TODO: when backend support
    // {
    //   inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
    //   inputLabel: 'Start Date',
    //   inputName: 'changedSubscriptionStartDate',
    //   mode: MODE.CREATE,
    // },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'New End Date',
      inputName: 'endDate',
      mode: MODE.CREATE,
      customInput: (props) => (
        <TimeInput {...props} timeLabel={'select end date for the subscription to change'} />
      ),
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Refer ID',
      inputName: 'referId',
      mode: MODE.CREATE,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Reason',
      inputName: 'reason',
      mode: MODE.CREATE,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Reference',
      inputName: 'reference',
      mode: MODE.BOTH,
      customInput: ({ name }) => <Editor name={name} />,
    },
  ];
  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

export default class AdjustmentOrderForm extends Component {
  static propTypes = {
    onCreateAdjustmentOrder: PropTypes.func,
    initialData: PropTypes.object,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateAdjustmentOrder: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateAdjustmentOrder(form);
  };

  render() {
    const { isEdit } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit, this.props)}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Submit'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
