import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FormGenerator from '../../../../Components/Form/FormGenerator';

const fields = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Organization Name',
    inputName: 'organizationName',
    inputProps: {
      required: true,
    },
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Email Domain Name',
    inputName: 'domainName',
    inputDescription: 'Domain name จะไม่สามารถแก้ไขได้หลังการสร้าง',
    inputProps: {
      required: true,
    },
  },
];

export default class OrganizationForm extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    onSubmit: () => {},
    isEdit: false,
  };
  render() {
    const { isEdit, onSubmit } = this.props;
    return (
      <FormGenerator
        fields={fields}
        onSubmit={onSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
