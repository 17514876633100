import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import FormGenerator from 'Components/Form/FormGenerator';

import { CATEGORY_STATUS_OPTIONS } from '../../Domains/constants';
import FileUploadInput from '../CategoryModal/FileUploadInput';
import Editor from '../MarkupTextInput';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (isEdit, id, profileImageUrl) => {
  const fields = [
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Name',
      inputName: 'name',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Permalink',
      inputName: 'permalink',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
      inputLabel: 'Status',
      inputName: 'status',
      inputProps: {
        required: true,
        search: true,
        options: CATEGORY_STATUS_OPTIONS,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.NUMBER_FIELD,
      inputLabel: 'Ordering',
      inputName: 'itemNo',
      inputProps: {
        required: true,
      },
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Position/Title',
      inputName: 'position',
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Details',
      inputName: 'description',
      customInput: ({ name }) => <Editor name={name} />,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
      inputLabel: 'Expert',
      inputName: 'expert',
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Expert Details',
      inputName: 'expertDetails',
      customInput: ({ name }) => <Editor name={name} />,
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
      inputLabel: 'Quote',
      inputName: 'quote',
      mode: MODE.BOTH,
    },
    {
      inputType: FormGenerator.INPUT_TYPE.CUSTOM_TYPE,
      inputLabel: 'Profile Image',
      inputName: 'profileImage',
      customInput: (props) => (
        <FileUploadInput id={id} {...props} imageUrl={profileImageUrl} name="profileImage" />
      ),
      mode: MODE.EDIT,
    },
    // TODO: socialMedias
  ];
  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

export default class InstructorForm extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    id: PropTypes.string,
    initialData: PropTypes.object,
    onCreateInstructor: PropTypes.func,
  };

  static defaultProps = {
    onCreateInstructor: () => {},
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateInstructor(form);
  };

  render() {
    const { id, isEdit, initialData } = this.props;
    return (
      <FormGenerator
        fields={getFields(isEdit, id, _.get(initialData, 'profileImageUrl'))}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
