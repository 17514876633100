import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';

import QueryComponent from 'GraphQL/util/QueryComponent';
import { getCourseUsers } from 'GraphQL/query/Courses.query';
import {
  addUserCoursePermission,
  removeUserCoursePermission,
} from 'GraphQL/mutation/Courses.mutation';
import { productCourseVersionResolver } from 'GraphQL/query/AppConfig.query';

import CourseUsersList from './CourseUsersList';

class CourseUsersListContainer extends Component {
  state = {
    loading: false,
    error: null,
  };
  handleRemoveUser = (id) => {
    const { product, courseId, permission } = this.props;
    console.log('remove click', id);
    this.setState({ loading: true });
    this.props
      .removeUserCoursePermission({
        variables: {
          version: productCourseVersionResolver(product),
          courseId,
          permission,
          userId: id,
        },
        refetchQueries: [
          {
            query: getCourseUsers,
            variables: { version: productCourseVersionResolver(product), courseId, permission },
          },
        ],
      })
      .then((result) => {
        setTimeout(() => {
          this.setState({ loading: false, error: null });
        }, 500);
      })
      .catch((error) => {
        this.setState({ loading: false, error });
      });
  };

  handleAddUser = (id) => {
    const { product, courseId, permission } = this.props;
    console.log('add click', id);
    this.setState({ loading: true });
    this.props
      .addUserCoursePermission({
        variables: {
          version: productCourseVersionResolver(product),
          courseId,
          permission,
          userId: id,
        },
        refetchQueries: [
          {
            query: getCourseUsers,
            variables: { version: productCourseVersionResolver(product), courseId, permission },
          },
        ],
      })
      .then((result) => {
        setTimeout(() => {
          this.setState({ loading: false, error: null });
        }, 500);
      })
      .catch((error) => {
        this.setState({ loading: false, error });
      });
  };

  render() {
    const { courseId, product, permission, ...restProps } = this.props;
    const { loading, error } = this.state;
    return (
      <QueryComponent
        query={getCourseUsers}
        variables={{ version: productCourseVersionResolver(product), courseId, permission }}
      >
        {({ courseUsers }) => (
          <CourseUsersList
            users={courseUsers}
            product={product}
            version={productCourseVersionResolver(product)}
            permission={permission}
            {...restProps}
            onRemoveUser={this.handleRemoveUser}
            onAddUser={this.handleAddUser}
            loading={loading}
          />
        )}
      </QueryComponent>
    );
  }
}

const withMutations = compose(
  graphql(addUserCoursePermission, {
    name: 'addUserCoursePermission',
  }),
  graphql(removeUserCoursePermission, {
    name: 'removeUserCoursePermission',
  })
);

export default withMutations(CourseUsersListContainer);
