import Axios from 'axios';
import { useEffect, useState } from 'react';

export const useWordpressPosts = (cards) => {
  const [wordpressPosts, setWordpressPosts] = useState({});
  const [handle, setHandle] = useState(null);

  // Use to test if new blog is added
  const sortedBlogSlugs = cards
    .filter((card) => card.type === 'BLOG' && card.slug)
    .map((card) => card.slug)
    .sort((c1, c2) => c1.localeCompare(c2))
    .join(':');
  useEffect(() => {
    setHandle((previousHandle) => {
      if (previousHandle !== null) {
        clearTimeout(previousHandle);
      }
      const newHandle = setTimeout(() => {
        const slugs = cards.filter((c) => c.type === 'BLOG' && c.slug).map((c) => c.slug);
        const fetchedSlugs = new Set(Object.entries(wordpressPosts).map(([slug]) => slug));
        const unfetchedSlugs = slugs.filter((slug) => !fetchedSlugs.has(slug));
        (async () => {
          for (const slug of unfetchedSlugs) {
            const res = await Axios.get(
              `https://blog.skooldio.com/wp-json/wp/v2/posts?slug=${slug}`
            );
            if (res.data[0]) {
              setWordpressPosts({
                ...wordpressPosts,
                [res.data[0].slug]: res.data[0],
              });
            }
          }
        })();
      }, 500);
      return newHandle;
    });
  }, [sortedBlogSlugs, cards, wordpressPosts]);

  return { wordpressPosts };
};
