import gql from 'graphql-tag';

import { SINGLE_USER_WITH_ROLE_PERMISSION } from 'GraphQL/query/Users.query';

import { APP_ROLE_FRAGMENT } from '../query/Role.query';

const UPDATE_APP_ROLE = gql`
  mutation updateAppRole($id: ID!, $data: AppRoleEditInput!) {
    updateAppRole(id: $id, appRoleData: $data) {
      ...APP_ROLE_FRAGMENT
    }
  }
  ${APP_ROLE_FRAGMENT}
`;

const CREATE_APP_ROLE = gql`
  mutation createAppRole($data: AppRoleNewInput!) {
    createAppRole(appRoleData: $data) {
      ...APP_ROLE_FRAGMENT
    }
  }
  ${APP_ROLE_FRAGMENT}
`;

const DELETE_APP_ROLE = gql`
  mutation deleteAppRole($id: ID!) {
    deleteAppRole(id: $id) {
      ...APP_ROLE_FRAGMENT
    }
  }
  ${APP_ROLE_FRAGMENT}
`;

const ASSIGN_APP_ROLE = gql`
  mutation assignAppRoleToUser($userId: ID!, $roleId: ID!) {
    assignAppRoleToUser(userId: $userId, roleId: $roleId) {
      ...APP_ROLE_FRAGMENT
    }
  }
  ${APP_ROLE_FRAGMENT}
`;

const ASSIGN_APP_ROLE_TO_USERS = gql`
  mutation assignUsersToAppRole($userIds: [ID!]!, $roleId: ID!) {
    assignUsersToAppRole(userIds: $userIds, roleId: $roleId) {
      ...SINGLE_USER_WITH_ROLE_PERMISSION
    }
  }
  ${SINGLE_USER_WITH_ROLE_PERMISSION}
`;

const UNASSIGN_APP_ROLE = gql`
  mutation unassignAppRoleFromUser($userId: ID!, $roleId: ID!) {
    unassignAppRoleFromUser(userId: $userId, roleId: $roleId) {
      ...APP_ROLE_FRAGMENT
    }
  }
  ${APP_ROLE_FRAGMENT}
`;

export {
  UPDATE_APP_ROLE,
  CREATE_APP_ROLE,
  DELETE_APP_ROLE,
  ASSIGN_APP_ROLE,
  UNASSIGN_APP_ROLE,
  ASSIGN_APP_ROLE_TO_USERS,
};
