import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormGenerator from 'Components/Form/FormGenerator';

const MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  BOTH: 'BOTH',
};

const getFields = (fieldsInput, isEdit) => {
  const fields = fieldsInput;

  return fields.filter(
    (f) =>
      f.mode === MODE.BOTH ||
      (isEdit && f.mode === MODE.EDIT) ||
      (!isEdit && f.mode === MODE.CREATE)
  );
};

class UpdateSalesOrderStatusForm extends Component {
  static propTypes = {
    onComplete: PropTypes.func,
    onCancelled: PropTypes.func,
    isEdit: PropTypes.bool,
  };

  handleSubmit = () => {};

  render() {
    const { isEdit, onSubmit, onCancelled, fields } = this.props;
    return (
      <FormGenerator
        fields={getFields(fields, isEdit)}
        onSubmit={onSubmit}
        onCancelled={onCancelled}
        submitText={'Update'}
        showCancel
        {...this.props}
      />
    );
  }
}

export default UpdateSalesOrderStatusForm;
