import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button as SButton } from 'semantic-ui-react';

import { Box, Flex } from 'Components/Base';

import ConfirmationButton from 'Components/ConfirmationButton';
import { SemanticButton as Button } from 'Components/Base/Button';
import { EXAM_MODE, EXAM_STATUS } from '../SingleExam/constant';

class QuestionListToolbar extends Component {
  render() {
    const { status, onDelete, onModeChange } = this.props;
    return (
      <Flex justifyContent="flex-end" alignItems="baseline">
        {status === EXAM_STATUS.DRAFT && (
          <SButton.Group>
            {/* <Button
              basic={true}
              size="mini"
              compact={true}
              icon={true}
              disabled={status === EXAM_STATUS.PUBLISHED}
              onClick={() => onModeChange(EXAM_MODE.EDIT)}
            >
              <Icon color="yellow" name="edit" />
            </Button> */}
            <ConfirmationButton
              onConfirm={onDelete}
              confirmText="Delete"
              header="Delete Question?"
              contentText="This Question will be removed from Exam"
            >
              <Icon name="trash alternate outline" color="red" />
            </ConfirmationButton>
          </SButton.Group>
        )}
      </Flex>
    );
  }
}

export default QuestionListToolbar;
