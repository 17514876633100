import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import { Box } from 'Components/Base';
import withFormsy from 'Components/Form/withFormsy';

const CertificateCheckBoxInput = ({ onChange, value, label, style }) => {
  const handleHideCourseCheck = (e, data) => onChange({}, { value: data.checked });
  return (
    <>
      <Box mt={2} />
      <Checkbox
        label={label}
        checked={Boolean(value)}
        onChange={handleHideCourseCheck}
        style={style}
      />
    </>
  );
};

CertificateCheckBoxInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string, // This value is a string (when checked=true, when unchecked = "" => kinda weird)
  onChange: PropTypes.func,
};

const EnhancedCertificateCheckBoxInput = withFormsy(CertificateCheckBoxInput);

export default EnhancedCertificateCheckBoxInput;
