import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

import { Flex } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Text from 'Components/Base/Text';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';
import QueryTable from 'GraphQL/util/QueryTable';
import { getCareerPlans } from 'GraphQL/LSP/query/Education.query';

import CareerPlanModal from '../../Components/CareerPlanModal';
const careerPlansColumns = (variables) => [
  {
    Header: 'Code',
    accessor: 'code',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'ชื่อแผนอาชีพ',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
  },
  {
    Header: 'Action',
    accessor: 'id',
    width: 100,
    isSearchAble: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <CareerPlanModal
          isEdit
          id={id}
          variables={variables}
          trigger={
            <Button basic size="mini" icon compact>
              <Icon name="edit" />
            </Button>
          }
        />
      );
    },
  },
];

class CareerPlansRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: { currentPage: 0, pageSize: 10 },
      search: {},
      order: { field: 'createdAt', type: 'DESC' },
    };
  }

  fileMutate = (mutation, jobId) => {
    const { search, paging, order } = this.state;
    this.props.client
      .mutate({
        mutation,
        variables: { jobId },
        refetchQueries: [
          {
            query: getCareerPlans,
            variables: { search, paging, order },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .catch((err) => console.error(err));
  };

  genPaging = (page, pageSize = this.state.paging.pageSize) => {
    return { currentPage: page, pageSize: pageSize };
  };
  genOrder = (sorted) => {
    if (sorted.length > 0) {
      const field = sorted[0].id;
      const type = sorted[0].desc ? 'DESC' : 'ASC';
      return { field, type };
    }
    return;
  };

  handleSearchChange = (search) => {
    const paging = this.genPaging(0);
    this.setState({ search, paging });
  };
  handlePageChange = (page) => {
    const paging = this.genPaging(page);
    this.setState({ paging });
  };
  handlePageSizeChange = (pageSize) => {
    const paging = this.genPaging(0, pageSize);
    this.setState({ paging });
  };
  handleSortedChange = (sorted) => {
    const order = this.genOrder(sorted);
    const paging = this.genPaging(0);
    this.setState({ order, paging });
  };
  resolveData = (data) => data.boCareerPlans.careerPlans;

  render() {
    const { search, paging, order } = this.state;
    const variables = { search, paging, order };
    return (
      <Fragment>
        <Flex justifyContent="space-between" mb={2}>
          <Text.Header>แผนอาชีพ</Text.Header>
          <CareerPlanModal
            variables={variables}
            trigger={
              <Button primary circular>
                <Icon name="plus" size="small" />
                เพิ่ม
              </Button>
            }
          />
        </Flex>
        <QueryTable
          type={'boCareerPlans'}
          resolveData={this.resolveData}
          columns={careerPlansColumns(variables)}
          query={getCareerPlans}
          search={search}
          onSearchChange={this.handleSearchChange}
          paging={paging}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          order={order}
          onSortedChange={this.handleSortedChange}
        />
      </Fragment>
    );
  }
}

export default compose(withApollo, withRouter)(CareerPlansRoute);
