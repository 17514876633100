import React, { Component } from 'react';

import { Box } from 'Components/Base';
import CreateEditForm from 'Containers/CreateEditForm';

import { getOnlineCourse } from '../../GraphQL/query/Products.query';
import { updateOnlineCourseInstructorsMutation } from '../../GraphQL/mutation/Products.mutation';
import SKUInstructorForm from '../SKUInstructorForm';

const getInitData = (data) => {
  if (data && data.sfBoProductOnlineCourse && data.sfBoProductOnlineCourse.instructors) {
    return {
      instructorIds: data.sfBoProductOnlineCourse.instructors.map((instructor) => instructor.id),
    };
  }
  return [];
};

class OnlineCourseInstructorsEditor extends Component {
  render() {
    const { onlineCourse = {} } = this.props;
    const { id, SKUCode } = onlineCourse;
    return (
      <Box>
        <CreateEditForm
          dataQuery={getOnlineCourse}
          editMutation={updateOnlineCourseInstructorsMutation}
          isEdit={true}
          id={SKUCode}
          idVariable={'SKUCode'}
          getQueryVariables={() => ({
            SKUCode,
          })}
          getEditVariables={(form) => ({
            id,
            instructorIds: form.instructorIds,
          })}
        >
          {({ data, onSubmit }) => {
            return <SKUInstructorForm initialData={getInitData(data)} onSubmit={onSubmit} />;
          }}
        </CreateEditForm>
      </Box>
    );
  }
}

export default OnlineCourseInstructorsEditor;
