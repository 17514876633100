import React from 'react';
import { Input } from 'formsy-semantic-ui-react';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Label } from 'semantic-ui-react';

import withFormsy from 'Components/Form/withFormsy';
import { FormField, RequiredLabel } from 'src/Components/Form/FormGenerator/FormGenerator';
import { Box, Text } from 'Components/Base';

import { SKU_STATUS } from '../../Domains/constants';

const errorLabel = <Label color="red" pointing />;

const shouldRenderWarning = (publishedAt, status) => {
  const _skuStatus = status.split('/')[1];
  return !publishedAt && _skuStatus !== SKU_STATUS.UNPUBLISHED;
};

const SKUCodeInput = (props) => {
  const { onChange, publishedAt, status } = props;
  const isShouldRenderWarning = shouldRenderWarning(publishedAt, status);

  const updateValue = (value) => {
    onChange({}, { value });
  };
  return (
    <FormField>
      <RequiredLabel required>
        <span>
          <Box alignItems={'center'} display="inline-flex">
            <Box mr={1}>SKUCode</Box>
            <Tooltip
              arrow
              title="หลังจาก Publish สินค้านี้ (เปลี่ยน SKUStatus) จะไม่สามารถแก้ไข SKUCode ได้"
              placement={'top'}
            >
              <InfoOutlinedIcon fontSize={'small'} />
            </Tooltip>
          </Box>
        </span>
      </RequiredLabel>
      <Input
        name={`SKUCode`}
        required
        validationErrors={{
          isDefaultRequiredValue: `SKUCode is required`,
        }}
        onChange={(data) => updateValue(data.target.value)}
        errorLabel={errorLabel}
        defaultValue={props.value}
      />
      {isShouldRenderWarning && (
        <Text fontWeight="300" color="#E84040">
          {`*หลังอัพเดท SKUStatus เป็นตัวเลือกอื่น (ที่ไม่ใช่ Unpublished) จะไม่สามารถแก้ไข SKUCode ได้อีกกรุณากรอกโค้ดที่ถูกต้องก่อนอัพเดท`}
        </Text>
      )}
    </FormField>
  );
};

export default withFormsy(SKUCodeInput);
