import React, { Component } from 'react';

import FileImport from '../../Containers/FileImport';
import { FILE_TYPE } from '../../common';

export default class ImportStudentsRoute extends Component {
  render() {
    return <FileImport title="Import Students" type={FILE_TYPE.STUDENT} />;
  }
}
