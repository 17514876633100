import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import Button from 'Components/Base/Button';

const WarningModal = ({ isOpen, close }) => {
  return (
    <Modal size="tiny" open={isOpen}>
      <Modal.Header>Check the assignment again!</Modal.Header>
      <Modal.Content>This user assignment was changed, Please check it again</Modal.Content>
      <Modal.Actions>
        <Button title="OK" onClick={close} />
      </Modal.Actions>
    </Modal>
  );
};

WarningModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};

WarningModal.defaultProps = { isOpen: 'false', close: () => {} };

export default WarningModal;
