import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Segment as BaseSegment, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { height } from 'styled-system';

import { Box, Flex } from 'Components/Base';

import FormGenerator from '../../Form/FormGenerator';
import { customDurationFormat } from '../../../Util/ExternalAdapter/VideoAdapter/utils';

import SnapshotPanel from './SnapshotPanel';
import VideoPlayerWrapper from './VideoPlayerWrapper';

const inputList = [
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Title',
    inputName: 'title',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_FIELD,
    inputLabel: 'Short Description',
    inputName: 'shortDescription',
  },
  {
    inputType: FormGenerator.INPUT_TYPE.TEXT_AREA,
    inputLabel: 'Description',
    inputName: 'description',
  },
];

const Segment = styled(BaseSegment)`
  ${height};
`;

const VideoEditor = ({ video, snapshots, onDataChange, videoSignedUrl }) => {
  const { videoKey, title, description, shortDescription, duration, coverImageUrl } = video;

  const [thumbnail, setThumbnail] = useState(coverImageUrl);
  const [thumbnailId, setThumbnailId] = useState();
  const [formData, setFormData] = useState({ title, description, shortDescription });

  const handleSelectSnapshot = (id, url) => {
    setThumbnail(url);
    setThumbnailId(id);
    onDataChange({ videoKey, ...formData, thumbnailId: id });
  };

  const handleFormChange = (value) => {
    setFormData(value);
    onDataChange({ videoKey, ...value, thumbnailId });
  };

  return (
    <Flex flexWrap="wrap">
      <Box width={[1, 2 / 3]} p={3}>
        <Segment ratio={16 / 9}>
          {duration && (
            <Label as="a" color="red" ribbon>
              <Icon className="clock outline" />
              {customDurationFormat(duration)}
            </Label>
          )}
          <VideoPlayerWrapper url={videoSignedUrl} />
        </Segment>
      </Box>
      <Box width={[1, 1 / 3]} p={3}>
        <FormGenerator
          fields={inputList}
          showAction={false}
          initialData={{ title, shortDescription, description }}
          onChange={handleFormChange}
        />
      </Box>
      <SnapshotPanel
        snapshots={snapshots}
        thumbnail={thumbnail}
        loadingSnapshot={!snapshots || snapshots.length === 0}
        loadingThumbnail={!thumbnail}
        onSelectSnapshot={handleSelectSnapshot}
      />
    </Flex>
  );
};

VideoEditor.propTypes = {
  video: PropTypes.shape({
    videoKey: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    shortDescription: PropTypes.string,
    duration: PropTypes.number,
    coverImageUrl: PropTypes.string,
  }),
  videoSignedUrl: PropTypes.string,
  snapshots: PropTypes.array,
  onDataChange: PropTypes.func,
};

export default VideoEditor;
