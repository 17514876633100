import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FormGenerator from 'Components/Form/FormGenerator';
import getDisplayName from 'Util/LSP/getDisplayName';

const fields = (studentData) => [
  {
    inputType: FormGenerator.INPUT_TYPE.DROPDOWN,
    inputLabel: 'นักเรียน',
    inputName: 'studentId',
    options: studentData.map((student) => {
      const additional = student.advisorTeacher
        ? ` (assigned to ${student.advisorTeacher.code})`
        : '';
      return {
        key: student.id,
        value: student.id,
        text: `[${student.code}] ${getDisplayName(student)}${additional}`,
      };
    }),
    inputProps: {
      required: true,
      search: true,
    },
  },
];

export default class TeacherStudentForm extends Component {
  static propTypes = {
    onCreateTeacherStudent: PropTypes.func,
    studentData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        advisorTeacher: PropTypes.shape({
          code: PropTypes.string.isRequired,
        }),
      })
    ),
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    onCreateTeacherStudent: () => {},
    studentData: [],
    isEdit: false,
  };

  handleSubmit = (form) => {
    this.props.onCreateTeacherStudent(form);
  };

  render() {
    const { isEdit, studentData } = this.props;
    return (
      <FormGenerator
        fields={fields(studentData)}
        onSubmit={this.handleSubmit}
        submitText={isEdit ? 'Update' : 'Add'}
        cancelText="Cancel"
        showCancel
        {...this.props}
      />
    );
  }
}
