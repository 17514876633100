import { connect } from 'react-redux';
import { compose } from 'redux';

import withAppConfig from 'Util/hoc/withAppConfig';

import { isLoading, isLogin, myPermission, myRoles } from './selectors';

export const withUserStatus = compose(
  withAppConfig(),
  connect((state, { config }) => {
    const enabledAppPermission = config?.enabledAppPermission;
    return {
      isLoading: isLoading(state),
      isLogin: isLogin(state),
      myRoles: enabledAppPermission ? myPermission(state) : myRoles(state),
    };
  })
);
