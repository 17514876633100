import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { GenSwitchRoute } from 'Util/Route';
import SideMenuLayout from 'Components/SideMenuLayout';
import APP_PERMISSION from 'Util/permissions/permissionKeys';

import StudentProfilesRoute from './Routes/StudentProfilesRoute';
import TeacherProfilesRoute from './Routes/TeacherProfilesRoute';
import ParentProfilesRoute from './Routes/ParentProfilesRoute';
import StudentProfileDetailsRoute from './Routes/StudentProfileDetailsRoute';
import TeacherProfileDetailsRoute from './Routes/TeacherProfileDetailsRoute';
import ParentProfileDetailsRoute from './Routes/ParentProfileDetailsRoute';
const ProfileRouteIndex = () => <Redirect to={{ title: '', pathname: '/lsp/users/students' }} />;

const defaultRoute = {
  path: '/lsp/users',
  exact: true,
  roles: [],
  route: ProfileRouteIndex,
};

const menus = [
  {
    path: '/lsp/users/students',
    title: 'Students',
    menuName: 'Students',
    icon: 'user',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: StudentProfilesRoute,
  },
  {
    path: '/lsp/users/students/:id',
    title: 'Student Profile',
    menuName: 'Student Profile',
    icon: 'user',
    exact: true,
    private: true,
    hidden: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: StudentProfileDetailsRoute,
  },
  {
    path: '/lsp/users/teachers',
    title: 'Teachers',
    menuName: 'Teachers',
    icon: 'graduation cap',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: TeacherProfilesRoute,
  },
  {
    path: '/lsp/users/teachers/:id',
    title: 'Teacher Profile',
    menuName: 'Teacher Profile',
    icon: 'user',
    exact: true,
    private: true,
    hidden: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: TeacherProfileDetailsRoute,
  },
  {
    path: '/lsp/users/parents',
    title: 'Parents',
    menuName: 'Parents',
    icon: 'male',
    exact: true,
    private: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: ParentProfilesRoute,
  },
  {
    path: '/lsp/users/parents/:id',
    title: 'Parent Profile',
    menuName: 'Parent Profile',
    icon: 'user',
    exact: true,
    private: true,
    hidden: true,
    roles: [APP_PERMISSION.COMMON.ADMINISTRATOR],
    route: ParentProfileDetailsRoute,
  },
];

export default class ProfilesRoute extends Component {
  render() {
    return (
      <SideMenuLayout
        header={'User Types'}
        menus={menus.filter(({ hidden }) => !hidden)}
        contentProps={{ textAlign: 'left' }}
      >
        <GenSwitchRoute routes={[...menus, defaultRoute]} />
      </SideMenuLayout>
    );
  }
}
