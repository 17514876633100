import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Flex } from 'Components/Base';
import RichTextEditor from 'Components/RichTextEditor';
import withFormsy from 'Components/Form/withFormsy';

import NotificationContentEditModal from '../Containers/NotificationContentEditModal';

const PreviewBox = styled.iframe`
  width: 375px;
  height: 500px;
  overflow-x: auto;
  margin: 0px 0px 16px;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
`;

const defaultValue = {
  type: RichTextEditor.contentFormat.HTML,
  content: null,
  rawContent: '[]',
};

/**
 * @param {string} initValue - detail | ex. {content: '<p>HTML CONTENT</p>', type: 'HTML'}
 */
const NotificationAnnouncementDescriptionInput = ({ value: initValue, onChange, readOnly }) => {
  const [content, setContent] = useState(
    initValue && initValue.type === defaultValue.type ? initValue.content : defaultValue.content
  );
  const [type, setType] = useState(defaultValue.type);
  const [rawContent, setRawContent] = useState(
    initValue && initValue.type === defaultValue.type && initValue.rawContent
      ? initValue.rawContent
      : defaultValue.rawContent
  );

  const updateValue = useCallback(() => {
    onChange({}, { value: { type, content, rawContent } });
  }, [type, content, rawContent, onChange]);

  useEffect(() => {
    updateValue();
  }, [updateValue]);

  return (
    <Flex flexDirection="column" alignItems="start">
      <p>
        กดปุ่ม <strong>Edit</strong> ด้านล่างเพื่อสร้าง/แก้ไขเนื้อหา
      </p>
      <PreviewBox title="announcement-preview" srcDoc={content}></PreviewBox>
      <NotificationContentEditModal
        trigger={<Button primary circular title={'Edit'}></Button>}
        onChange={({ content, rawContent }) => {
          setContent(content);
          setRawContent(rawContent);
        }}
        rawContent={rawContent}
      />
    </Flex>
  );
};

NotificationAnnouncementDescriptionInput.propTypes = {
  value: PropTypes.shape({
    content: PropTypes.string,
    rawContent: PropTypes.string,
    type: PropTypes.oneOf(
      Object.entries(RichTextEditor.contentFormat).map(([key, value]) => value)
    ),
  }),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withFormsy(NotificationAnnouncementDescriptionInput);
