import PropTypes from 'prop-types';

import { UPLOAD_MODE } from 'Components/Lib/UploadComponent';

export const AssetPropTypes = {
  onCreateMaterial: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  newAsset: PropTypes.bool,
  onFileListRequest: PropTypes.func,
  onFileUploadUrlRequest: PropTypes.func,
  uploadMode: PropTypes.oneOf(Object.values(UPLOAD_MODE)).isRequired,
  materialTypeString: PropTypes.string,
};
