import gql from 'graphql-tag';

export const DiscountCodeFragment = gql`
  fragment DiscountCodeFragment on SFBoPaymentDiscountCode {
    id
    code
    total
    remain
    userIds
    SKUIds
    productTypes
    exceptSKUIds
    SKUCodes
    exceptSKUCodes
    paymentMethods
    discount
    discountUnit
    discountText
    refCode
    startDate
    endDate
    conditions {
      ... on SFConditionDaysValidity {
        days
      }
      ... on SFConditionExpiryDate {
        expiryDate
      }
    }
    currency
    customerType
    minimumQuantity
  }
`;

export const getDiscountCodes = gql`
  query sfBoPaymentSearchDiscountCodes(
    $search: SFBoPaymentSearchDiscountCodesInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoPaymentSearchDiscountCodes(search: $search, paging: $paging, order: $order) {
      total
      discountCodes {
        ...DiscountCodeFragment
      }
    }
  }
  ${DiscountCodeFragment}
`;

export const getRandomDiscountCodeJob = gql`
  query sfBoPaymentSearchRandomDiscountCodeJobs(
    $search: SFBoPaymentSearchRandomDiscountCodeJobsInput
    $paging: PageInput
    $order: OrderInput
  ) {
    sfBoPaymentSearchRandomDiscountCodeJobs(search: $search, paging: $paging, order: $order) {
      total
      jobs {
        id
        createdAt
        updatedAt
        status
        retriesLeft
        input {
          discountText
          amount
          useableTimes
          randomLength
          prefix
          discount
          discountUnit
          SKUIds
          productTypes
          exceptSKUIds
          startDate
          endDate
        }
        result {
          error
        }
      }
    }
  }
`;
