import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useMutation } from '@apollo/client';

import { Box, Flex, ButtonFilled, ButtonText } from 'Components/Base';
import QueryTable from 'GraphQL/util/QueryTable';
import COLUMN_TYPE from 'Components/Search/SearchFormGenerator/constantType';

import { useSearchFilter } from '../Utils';
import Snackbar, { VARIANTS } from '../Components/Snackbar';
import { GET_UNASSIGNED_TEAMS_BY_USER } from '../Graphql/query/Team.query';
import { ASSIGN_TEAMS_TO_USER } from '../Graphql/mutation/Team.mutation';

const addOrRemove = (array, item) => {
  const data = [...array];
  const index = _.findIndex(data, (datum) => datum === item.id);

  if (index === -1) {
    data.push(item.id);
  } else {
    data.splice(index, 1);
  }
  return data;
};

const formatUserColumns = (handleCheckbox, selectedTeams) => [
  {
    Header: '',
    accessor: 'checkbox',
    width: 50,
    isSearchAble: false,
    sortable: false,
    Cell: function ActionCell({ original }) {
      const { id } = original;
      return (
        <Checkbox
          checked={!!selectedTeams.find((item) => item === id)}
          onChange={() => handleCheckbox(original)}
        />
      );
    },
  },
  {
    Header: 'ID',
    accessor: 'id',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 100,
  },
  {
    Header: 'Team',
    accessor: 'name',
    type: COLUMN_TYPE.STRING,
    isSearchAble: true,
    width: 250,
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: COLUMN_TYPE.STRING,
  },
];

const FEEDBACK = {
  PROHIBITED: {
    message: `You don't have permission to do this action`,
    variant: VARIANTS.ERROR,
    show: true,
  },
  TEAM_ALREADY_EXIST: {
    message: `Some of team has been assigned already, please refresh`,
    variant: VARIANTS.INFO,
    show: true,
  },
  UNEXPECTED_ERROR: {
    message: `Cannot assign team, please try again`,
    variant: VARIANTS.ERROR,
    show: true,
  },
};

const AssignTeamsToUser = React.forwardRef(
  ({ userId, onCancel, onCompleted: onCompletedProps }, ref) => {
    const {
      onSortedChange,
      onSearchChange,
      onPageChange,
      onPageSizeChange,
      paging,
      search,
      order,
    } = useSearchFilter();

    const [selectedTeams, setSelectedTeams] = useState([]);

    const [feedback, setFeedback] = useState({});

    const onError = useCallback((response) => {
      const message = response?.message ?? '';

      if (message.includes('400')) {
        setFeedback(FEEDBACK.TEAM_ALREADY_EXIST);
        return;
      }

      if (message.includes('401')) {
        setFeedback(FEEDBACK.PROHIBITED);
        return;
      }

      setFeedback(FEEDBACK.UNEXPECTED_ERROR);
    }, []);

    const onCompleted = useCallback(
      (response) => {
        setFeedback({
          message: `Successfully assigned ${response.assignTeamsToUser.length} teams to the user!`,
          variant: VARIANTS.SUCCESS,
          show: true,
        });
        setSelectedTeams([]);
        if (typeof onCompletedProps === 'function') onCompletedProps();
        const refetchUnassignedTeamListTable = ref?.current?.refetch;
        if (typeof refetchUnassignedTeamListTable === 'function') refetchUnassignedTeamListTable();
      },
      [onCompletedProps, ref]
    );

    const [assignTeamsToUser] = useMutation(ASSIGN_TEAMS_TO_USER, {
      onCompleted,
      onError,
    });

    const handleCheckbox = (item) => {
      setSelectedTeams(addOrRemove(selectedTeams, item));
    };

    const handleAddTeams = () => {
      assignTeamsToUser({
        variables: {
          userId,
          teamIds: selectedTeams,
        },
      });
    };

    const resolveData = useCallback((data) => data?.unassignedTeamsByUser?.teams, []);

    return (
      <>
        <Snackbar
          message={feedback.message}
          isOpen={feedback.show}
          onClose={() => setFeedback({ ...feedback, show: false })}
          variant={feedback.variant}
          vertical={'top'}
          horizontal={'center'}
          duration={3000}
        />
        <Box pt={4} pb={3} px={4} mx={'auto'} width={'100%'}>
          <QueryTable
            type={'unassignedTeamsByUser'}
            columns={formatUserColumns(handleCheckbox, selectedTeams)}
            query={GET_UNASSIGNED_TEAMS_BY_USER}
            resolveData={resolveData}
            onSearchChange={onSearchChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortedChange={onSortedChange}
            getSearchVariables={() => ({
              userId,
              search,
              order,
              paging,
            })}
            paging={paging}
            ref={ref}
          />
          <Flex justifyContent={'space-between'} alignItems={'center'} mt={3}>
            {`${selectedTeams.length} team(s) selected`}
          </Flex>
        </Box>
        <Flex
          height={'80px'}
          width={1}
          px={4}
          bg={'#F2F2F2'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <ButtonText
            fontSize="16px"
            color="#787878"
            onClick={() => setSelectedTeams([])}
            style={{ textDecoration: 'none', padding: 0 }}
          >
            <Flex alignItems={'center'} justifyContent={'center'} height={'12px'}>
              <RefreshIcon style={{ marginRight: '4px' }} /> Clear Selection
            </Flex>
          </ButtonText>
          <Box>
            <ButtonText color="primary" px={3} py={2} fontSize="16px" onClick={onCancel}>
              Cancel
            </ButtonText>
            <ButtonFilled
              disabled={selectedTeams.length < 1}
              bg="primary"
              color="white"
              borderRadius={'100px'}
              style={{ border: '2px solid transparent' }}
              fontSize="16px"
              onClick={handleAddTeams}
            >
              {`Assign Team${selectedTeams.length > 1 ? 's' : ''}`}
            </ButtonFilled>
          </Box>
        </Flex>
      </>
    );
  }
);

AssignTeamsToUser.propTypes = {
  onCancel: PropTypes.func,
  onCompleted: PropTypes.func,
  userId: PropTypes.string,
};

AssignTeamsToUser.defaultProps = {
  onCancel: () => {},
  onCompleted: () => {},
};

export default AssignTeamsToUser;
