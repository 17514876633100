import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Input } from 'semantic-ui-react';
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLOR } from 'Components/Theme';
import { Flex } from 'Components/Base';
import { QUESTION_TYPE } from 'GraphQL/query/ExamEditorV1.query';
import { ASSET_TYPE } from 'GraphQL/mutation/Asset.mutation';

import RichTextEditor from '../../../RichTextEditor';

const Row = Grid.Row;
const Column = Grid.Column;

const StyledCheckbox = withStyles({
  root: {
    width: 25,
    height: 25,
    marginRight: 15,
  },
  checked: { color: COLOR.primary },
})((props) => <Checkbox color="default" {...props} />);

const FillInTheBlank = ({
  examId,
  fillInTheBlankItems,
  onAnswerTitleChange,
  onGradeAnswerChange,
  onAnswerChange,
  onAnswerHintChange,
  editorContentFormat,
  maxFileSize,
}) => {
  const fillInTheBlankList = fillInTheBlankItems.map((item, index) => (
    <Fragment key={`fillInTheBlank-${index}`}>
      <Row>
        <Column width={6} textAlign="right">
          Title
        </Column>
        <Column width={10}>
          <Input
            value={item.answerTitle}
            style={{ width: '100%' }}
            onChange={(e) => onAnswerTitleChange(e.target.value, index)}
          />
        </Column>
      </Row>
      <Row>
        <Column width={6} />
        <Column width={10}>
          <Flex alignItems="center">
            <StyledCheckbox
              checked={!item.gradeAnswer}
              onChange={(e) => {
                if (e.target.checked) {
                  onGradeAnswerChange(false, index);
                } else {
                  onGradeAnswerChange(true, index);
                }
              }}
            />
            คำถามปลายเปิด
          </Flex>
        </Column>
      </Row>
      {item.gradeAnswer && (
        <Fragment>
          <Row>
            <Column width={6} textAlign="right">
              Answer (เฉลย)
            </Column>
            <Column width={10}>
              <Input
                value={item.answer}
                style={{ width: '100%' }}
                onChange={(e) => onAnswerChange(e.target.value, index)}
                disabled={!item.gradeAnswer}
              />
            </Column>
          </Row>
          <Row>
            <Column width={6} textAlign="right">
              Hint
            </Column>
            <Column width={10}>
              <RichTextEditor
                onChange={(data) => onAnswerHintChange(data, index)}
                value={item.hint}
                contentFormat={editorContentFormat}
                returnValueAsString={false}
                customControls={[
                  {
                    type: RichTextEditor.customControlType.IMAGE_UPLOAD,
                    data: { assetType: ASSET_TYPE.EXAM_IMAGES, assetKey: examId },
                    maxFileSize: maxFileSize,
                  },
                ]}
              />
            </Column>
          </Row>
        </Fragment>
      )}
    </Fragment>
  ));
  return fillInTheBlankList;
};

FillInTheBlank.propTypes = {
  examId: PropTypes.number,
  answerChoices: PropTypes.array,
  onAnswerChange: PropTypes.func,
  onAnswerHintChange: PropTypes.func,
  maxFileSize: PropTypes.number,
};

export default FillInTheBlank;
