import React, { useState, useEffect } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { Input } from 'formsy-semantic-ui-react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Dimmer, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import FileUploadManagerModal from 'Components/Lib/UploadComponent';
import { AspectRatio, BackgroundImage, Box, Text } from 'Components/Base';
import { SemanticButton as Button } from 'Components/Base/Button';
import Loading from 'Components/Loading';
import { CERTIFICATE_TYPE } from 'GraphQL/query/Certificate.query';
import {
  getAssetList,
  getUploadUrl,
  ASSET_TYPE,
  requestAssetUrl,
} from 'GraphQL/mutation/Asset.mutation';
import withServerConfig from 'GraphQL/util/withServerConfig';

const HiddenInput = styled(Input)`
  display: none;
`;

const CertificateTemplateUpload = (props) => {
  const {
    certificateId,
    client,
    defaultCertificateTemplateUrl,
    defaultValue,
    serverConfig,
    type,
  } = props;

  const [key, setKey] = useState(defaultValue?.key ?? '');
  const [bucket, setBucket] = useState(defaultValue?.bucket ?? '');
  const [imageUrl, setImageUrl] = useState(defaultCertificateTemplateUrl ?? '');
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getTemplateUrl(key) {
      if (!key) {
        setIsLoading(false);
        return '';
      }
      const { data } = await client.mutate({
        mutation: requestAssetUrl,
        variables: { key, type: ASSET_TYPE.CERTIFICATE_TEMPLATE },
        fetchPolicy: 'no-cache',
      });

      setImageUrl(data.asRequestAssetUrl.url.url);
      setIsLoading(false);
    }
    getTemplateUrl(defaultValue?.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleFileListRequest(request) {
    const { data } = await client.mutate({
      mutation: getAssetList,
      variables: {
        type: ASSET_TYPE.CERTIFICATE_TEMPLATE,
        key: certificateId,
      },
      fetchPolicy: 'no-cache',
    });

    return data.asRequestAssetsList.assetFiles;
  }

  async function handleFileUploadUrlRequest(request) {
    const { filename, contentType } = request;

    const { data } = await client.mutate({
      mutation: getUploadUrl,
      variables: {
        type: ASSET_TYPE.CERTIFICATE_TEMPLATE,
        key: certificateId,
        filename,
        contentType,
      },
      fetchPolicy: 'no-cache',
    });

    return data.asRequestAssetUploadUrl.uploadUrl.url;
  }

  async function handleSelectImage(files = []) {
    if (files && files.length > 1) {
      // Now Support only one file upload
      alert('Please select only 1 file');
    }
    if (files && files.length === 1) {
      const { key: keyFile, bucket, url } = files[0];
      setKey(keyFile);
      setBucket(bucket);
      setImageUrl(url);
    }
  }

  function handleHover(value) {
    setActive(value);
  }

  function handleUseDefaultTemplate() {
    setKey('');
    setBucket('');
    setImageUrl(defaultCertificateTemplateUrl ?? '');
  }

  if (!certificateId) {
    return null;
  } else if (isLoading) {
    return <Loading />;
  }

  // TODO: Refactor next PR for image src
  return (
    <>
      <HiddenInput value={key} name={'template.key'} placeholder="" disabled={true} />
      <HiddenInput value={bucket} name={'template.bucket'} placeholder="" disabled={true} />
      {type === CERTIFICATE_TYPE.MANUAL ? (
        <>
          <img src={imageUrl} width="300px" height="250px" alt="" />
          <br />
          <FileUploadManagerModal
            keyGroup="certificateTemplate"
            dimmer="inverted"
            uploadUrl={'http://localhost:5000'}
            listUrl={'http://localhost:5000'}
            allowedContentTypes={['image/png', 'image/jpeg', 'image/jpg']}
            defaultText={'Support JPG, PNG or JPEG file with less than 10MB'}
            maxSelection={1}
            maxFileSize={10}
            customFileListUrlRequest={handleFileListRequest}
            customUploadUrlRequest={handleFileUploadUrlRequest}
            mode={serverConfig.assetUploadMode}
            onFileManagerDialogClose={handleSelectImage}
            trigger={
              <Button primary circular type="button">
                Choose Template
              </Button>
            }
          />
        </>
      ) : (
        <>
          <Text color="gray" mb={2}>
            ขนาด 1600x1190 px และรองรับไฟล์ .jpg, .jpeg, .png เท่านั้น โดย ชื่อ-นามสกุล วันที่
            และลายเซ็น จะจัดวางที่ตำแหน่งเดิมเสมอ
          </Text>
          <Box
            onMouseOver={() => {
              handleHover(true);
            }}
            onMouseLeave={() => {
              handleHover(false);
            }}
            mb={3}
            width={'300px'}
            style={{ cursor: 'pointer' }}
          >
            <FileUploadManagerModal
              keyGroup="certificateTemplate"
              dimmer="inverted"
              uploadUrl={'http://localhost:5000'}
              listUrl={'http://localhost:5000'}
              allowedContentTypes={['image/png', 'image/jpeg', 'image/jpg']}
              defaultText={'Support JPG, PNG or GIF file with less than 10MB'}
              maxSelection={1}
              maxFileSize={10}
              customFileListUrlRequest={handleFileListRequest}
              customUploadUrlRequest={handleFileUploadUrlRequest}
              mode={serverConfig.assetUploadMode}
              onFileManagerDialogClose={handleSelectImage}
              trigger={
                //Put it here to allow user to click any where in picture to change image
                <Dimmer.Dimmable dimmed={active}>
                  <AspectRatio ratio={4 / 3}>
                    <BackgroundImage
                      imageUrl={imageUrl ?? defaultCertificateTemplateUrl}
                      bgPosition={'center'}
                    />
                    <Dimmer active={active}>
                      <Button primary type="button">
                        <Icon name="image outline" />
                        Change template
                      </Button>
                    </Dimmer>
                  </AspectRatio>
                </Dimmer.Dimmable>
              }
            />
          </Box>
          <Button type="button" onClick={handleUseDefaultTemplate}>
            Use default template
          </Button>
        </>
      )}
    </>
  );
};

CertificateTemplateUpload.propTypes = {
  certificateId: PropTypes.string.isRequired,
  defaultValue: PropTypes.object,
  defaultCertificateTemplateUrl: PropTypes.string,
  client: PropTypes.object,
  serverConfig: PropTypes.object,
  type: PropTypes.string,
};

export default compose(
  withApollo,
  withServerConfig({ configProp: 'serverConfig' })
)(CertificateTemplateUpload);
